import { TabbedLayout } from '@backstage/core-components';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import AddConfigPage from './AddConfigPage';
import RunsPage from './RunsPage';
import ReportPage from './ReportPage';
import ManageConfigPage from './ManageConfigPage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      'header + div': {
        display: 'none',
      },
      'header ~ article': {
        padding: '0px !important',
      },
    },
  }),
);

export const PTRoutes = ({ isToolAdmin }: { isToolAdmin: boolean }) => {
  useStyles();
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="./runs" replace />} />
      </Routes>

      <TabbedLayout>
        <TabbedLayout.Route path="/runs" title="Runs">
          <Routes>
            <Route path="/" element={<RunsPage isToolAdmin={isToolAdmin} />} />
            <Route path="/:immutableId" element={<ReportPage />} />
          </Routes>
        </TabbedLayout.Route>
        <TabbedLayout.Route path="/manage" title="Manage">
          <Routes>
            <Route
              path="/"
              element={<ManageConfigPage isToolAdmin={isToolAdmin} />}
            />
            <Route
              path="/add-config"
              element={<AddConfigPage isToolAdmin={isToolAdmin} />}
            />
            <Route
              path="/edit-config/:id"
              element={<AddConfigPage isToolAdmin={isToolAdmin} />}
            />
          </Routes>
        </TabbedLayout.Route>
      </TabbedLayout>
    </>
  );
};
