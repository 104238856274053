import React from 'react';

const PostQuestion = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.75 1.875H6.25C4.8625 1.875 3.75 3 3.75 4.375V21.875C3.75 23.25 4.8625 24.375 6.25 24.375H11.25L15 28.125L18.75 24.375H23.75C25.125 24.375 26.25 23.25 26.25 21.875V4.375C26.25 3 25.125 1.875 23.75 1.875ZM23.75 21.875H17.7125L15 24.5875L12.2875 21.875H6.25V4.375H23.75V21.875ZM13.75 18.125H16.25V20.625H13.75V18.125ZM15 8.125C16.375 8.125 17.5 9.25 17.5 10.625C17.5 13.125 13.75 12.8125 13.75 16.875H16.25C16.25 14.0625 20 13.75 20 10.625C20 7.8625 17.7625 5.625 15 5.625C12.2375 5.625 10 7.8625 10 10.625H12.5C12.5 9.25 13.625 8.125 15 8.125Z"
        fill="#17E88F"
      />
    </svg>
  );
};

export default PostQuestion;
