/**
 * Checks whether a node is iframe or not.
 * @param node - can be any element.
 * @returns true when node is iframe.
 */
const isIframe = (node: Element) => node.nodeName === 'IFRAME';

/**
 * Checks whether a iframe is safe or not.
 * @param node - is an iframe element.
 * @param hosts - list of allowed hosts.
 * @returns true when iframe is included in hosts.
 */
const isSafe = (node: Element, hosts: string[]) => {
  const src = node.getAttribute('src') || '';
  try {
    const { host } = new URL(src);
    return hosts.includes(host);
  } catch {
    return false;
  }
};

/**
 * Returns a function that removes unsafe iframe nodes.
 * @param node - can be any element.
 * @param hosts - list of allowed hosts.
 */
export const removeUnsafeIframes = (hosts: string[]) => (node: Element) => {
  if (isIframe(node) && !isSafe(node, hosts)) {
    node.remove();
  }
  return node;
};
