import type { Transformer } from './transformer';

type AddLinkClickListenerOptions = {
  baseUrl: string;
  onClick: (e: MouseEvent, newUrl: string) => void;
};

export const addLinkClickListener = ({
  baseUrl,
  onClick,
}: AddLinkClickListenerOptions): Transformer => {
  return dom => {
    Array.from(dom.getElementsByTagName('a')).forEach(elem => {
      elem.addEventListener('click', (e: MouseEvent) => {
        const target = elem as HTMLAnchorElement;
        const href = target.getAttribute('href');

        if (!href) return;
        if (href.startsWith(baseUrl) && !elem.hasAttribute('download')) {
          e.preventDefault();
          onClick(e, href);
        }
      });
    });

    return dom;
  };
};
