import React, { useContext, useRef } from 'react';
import { Grid, Typography, LinearProgress, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import BadgeDisplayImage from '../badgeTypePicker';
import Constants from '../Constants';
import { useCustomStyles } from './CardStyles';
import { PromotionContext } from '../../../providers/PromotionDataProvider';
import UserAvatar from '../../common/UserAvatar';
import { useApi } from '@backstage/core-plugin-api';
import { userProfileApiRef } from '../../../apis/userProfileApi';

export interface LeaderBoardCardDataProps {
  displayname: string;
  levelname: string;
  score: number;
  email: string;
}

export interface LeaderBoardCardProps {
  data?: LeaderBoardCardDataProps | any;
  isAdhocPointPage?: boolean;
  onclickHandler?: (data: LeaderBoardCardDataProps) => void;
  profEmail?: string;
  isYou?: any;
  selectedBtn?: string;
}

const LeaderBoardCard = (props: LeaderBoardCardProps) => {
  const { displayname, levelname, score, email } = props.data;
  const { selectedBtn } = props;
  const name =
    (displayname && displayname.split('@')[0]) ||
    (email && email.split('@')[0]);

  const { isAdhocPointPage, onclickHandler, profEmail } = props;
  const classes = useCustomStyles();

  const gamificationLevelsList = useContext(PromotionContext);
  const userProfileApi = useApi(userProfileApiRef);
  const usernameRef = useRef(null);

  const minValue = gamificationLevelsList?.gamificationlevelData?.filter(
    (item: any) => item?.name === levelname,
  )[0]?.minpoints;
  const maxValue = gamificationLevelsList?.gamificationlevelData?.filter(
    (item: any) => item?.name === levelname,
  )[0]?.maxpoints;

  const range = maxValue - minValue;
  const correctedStartValue = score - minValue;
  const percentage = (correctedStartValue * 100) / range;

  const progressValue = Math.round(percentage);

  const onMouseEnter = () => {
    userProfileApi.setUserProfileState({
      email,
      anchorRef: usernameRef?.current,
    });
  };

  return (
    <div className={classes.CardEntityContainer}>
      <Grid container className={isAdhocPointPage ? 'admin' : ''}>
        <Grid
          item
          xs={isAdhocPointPage || selectedBtn !== 'cumulative' ? 12 : 12}
          md={
            isAdhocPointPage || selectedBtn !== 'cumulative'
              ? isAdhocPointPage
                ? 9
                : 11
              : 6
          }
          className={classes.CardTypeContainerFirst}
        >
          <div className={classes.CardTypeImageAndText}>
            <UserAvatar
              userEmail={email}
              customStyles={{
                width: '40px',
                height: '40px',
                fontSize: '16px',
                fontWeight: 'normal',
              }}
            />

            <Typography
              component="div"
              className={`${classes.CardTypeText} fontSemibold`}
            >
              <Link
                to={`/catalog/leader-board/${email?.split('@')[0]}`}
                state={{ email: email }}
              >
                <div onMouseEnter={onMouseEnter} ref={usernameRef}>
                  <span title={name}> {name}</span>
                  <span>{props.isYou ? Constants.LabelText.YOU : ''}</span>
                </div>
              </Link>
            </Typography>
          </div>
        </Grid>
        {!isAdhocPointPage && selectedBtn === 'cumulative' && (
          <>
            <Grid
              item
              xs={10}
              md={5}
              className={`${classes.CardTypeContainer} ${classes.leaderboardCard}`}
            >
              <div className={classes.IconWithText}>
                <BadgeDisplayImage type={levelname} />
                <Typography className={classes.IconText}>
                  {levelname}
                </Typography>
              </div>
              <div
                className={`${classes.ProgressBarDiv} ${classes.progressBarStyle}`}
              >
                <LinearProgress variant="determinate" value={progressValue} />
                <div className={classes.ProgressBarLevel}>
                  <span className="levelStart">{minValue}</span>
                  <span className="levelEnd">
                    {score} /{maxValue}
                  </span>
                </div>
              </div>
            </Grid>
          </>
        )}
        <Grid
          item
          xs={isAdhocPointPage || selectedBtn !== 'cumulative' ? 5 : 2}
          md={1}
          className={classes.CardTypeContainer}
        >
          <Typography className={classes.CardTypeFontBold}>
            <b> {score}</b>
          </Typography>
          <Typography className={classes.CardTypeFont}>
            {Constants.LabelText.POINTS}
          </Typography>
        </Grid>
        {isAdhocPointPage && (
          <Grid
            item
            md={2}
            xs={7}
            className={`${classes.CardTypeContainer} ${classes.buttonContainer}`}
          >
            <Button
              onClick={() => onclickHandler?.(props.data)}
              color="primary"
              variant="contained"
              data-testid="modify-points-btn"
              disabled={
                email?.toLowerCase() === profEmail?.toLowerCase() ? true : false
              }
            >
              Modify Points
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default LeaderBoardCard;
