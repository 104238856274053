import React, { useEffect, useState } from 'react';
import { InfoCard } from '@backstage/core-components';
import { Grid } from '@material-ui/core';
import SyncIcon from '@material-ui/icons/Sync';
import OwnerCard from '../../../common/Cards/OwnerCard';
import { useCustomStyles } from '../../../common/Cards/CardStyles';
import { useApi } from '@backstage/core-plugin-api';
import { dashboardApiRef } from '../../../../apis/dashboardApi';
import {
  notificationApiRef,
  NotificationApi,
} from '../../../../apis/notificationApi';

export const TopContributors = () => {
  const classes = useCustomStyles();
  const [memberData, setMemberData] = useState<any>([]);
  const [Refresh, setRefresh] = useState<any>(false);
  const refreshClick = () => setRefresh(true);

  const dashboardApi = useApi(dashboardApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  useEffect(() => {
    if (!memberData.length || Refresh) {
      dashboardApi
        .getTopContributors()
        .then(res => {
          setMemberData(res.data || []);
          setRefresh(false);
        })
        .catch((error: any) => {
          setRefresh(false);

          notificationApi.sendNotification({
            message: `Error occurred - ${error?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });
    }
  }, [Refresh]);

  return (
    <InfoCard title="Top contributors">
      <Grid item className="topContributorsContainer">
        <button className="refreshButton" onClick={refreshClick}>
          <SyncIcon />
        </button>
        <div className={classes.OverflowContent}>
          {memberData &&
            memberData.length > 0 &&
            memberData.map((user: any) => (
              <OwnerCard
                ownerName={user.owners && user.owners.replace('user:', '')}
                picture={user.picture}
                componentCount={user.contributions}
              />
            ))}
        </div>
      </Grid>
    </InfoCard>
  );
};
