import { EntityPicker } from '../fields/EntityPicker/EntityPicker';
import { EntityNamePicker } from '../fields/EntityNamePicker/EntityNamePicker';
import { EntityTagsPicker } from '../fields/EntityTagsPicker/EntityTagsPicker';
import { OwnerPicker } from '../fields/OwnerPicker/OwnerPicker';
import { OwnedEntityPicker } from '../fields/OwnedEntityPicker/OwnedEntityPicker';
import { LoggedInUserPicker } from '../fields/LoggedInUserPicker/LoggedInUserPicker';
import { DevxRepoUrlPicker } from '../fields/DevxRepoPicker/RepoUrlPicker';
import { repoPickerValidation } from '../fields/DevxRepoPicker';
import { APMField } from '../fields/APMField';
import { apmFieldValidation } from '../fields/APMField/validation';

export const DEFAULT_SCAFFOLDER_FIELD_EXTENSIONS = [
  {
    component: EntityPicker,
    name: 'EntityPicker',
  },
  {
    component: EntityNamePicker,
    name: 'EntityNamePicker',
  },
  {
    component: EntityTagsPicker,
    name: 'EntityTagsPicker',
  },
  {
    component: OwnerPicker,
    name: 'OwnerPicker',
  },
  {
    component: OwnedEntityPicker,
    name: 'OwnedEntityPicker',
  },
  {
    component: LoggedInUserPicker,
    name: 'DevxUserPicker',
  },
  {
    component: DevxRepoUrlPicker,
    name: 'DevxRepoUrlPicker',
    validation: repoPickerValidation,
  },
  {
    component: APMField,
    name: 'APMField',
    validation: apmFieldValidation,
  },
];
