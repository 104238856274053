import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useCustomStyles = makeStyles((theme: Theme) =>
  createStyles({
    RegisterTitleStyle: {
      height: '63px',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '24px',
      background: theme.palette.background.paper,

      '& span': {
        fontFamily: 'Calibre-R-Regular',
        fontSize: '16px',
        lineHeight: '20px',
        textAlign: 'left',
      },
    },
    RegisterExtContentStyle: {
      padding: '10px 0px',
      marginTop: '-12px',
      paddingBottom: '32px',
      // height: "500px",
      overflow: 'auto',
      '& div:first-of-type a': {
        fontFamily: 'Calibre-R-Regular',
        fontSize: '15px',
        lineHeight: '20px',
        textAlign: 'left',
      },
    },

    registerExtDocPage: {
      overflowX: 'hidden',
    },

    RegisterForm: {
      '& .RegisterExtDoc': {
        background: theme.palette.background.paper,
        padding: '10px',
      },

      '& > div:first-of-type': {
        fontFamily: 'Calibre-R-Regular',
        fontSize: '12px',
        lineHeight: '16px',
      },
    },

    RegisterExtGridStyle: {
      display: 'flex',

      '& .helpTextExtDoc': {
        backgroundColor: theme.palette.background.paper,
        padding: '10px 20px',
        marginLeft: '20px',
        ['@media (max-width:920px) and (min-width: 280px)']: {
          marginTop: '20px',
          marginLeft: '-12px',
        },
      },

      '& section': {
        backgroundColor: theme.palette.background.paper,
        padding: '20px 20px 20px 0px',
        width: '50%',
        boxSizing: 'border-box',

        '& > div:first-of-type': {
          fontFamily: 'Calibre-R-Regular',
          fontSize: '12px',
          lineHeight: '16px',
        },
      },
      '& > section:nth-of-type(1)': {
        '& div': {
          marginTop: '10px',
        },
      },

      '& .register-info-title': {
        height: '40px',
        boxShadow: '0px 0px 0px black',
        fontFamily: 'Calibre-R-Regular',
        fontSize: '16px',
        lineHeight: '20px',
        textAlign: 'left',
      },
      '& .reg-spacer': {
        height: '1px',
        width: '100%',
        background: '#cccccc',
      },
      '& .register-info': {
        '& section': {
          fontFamily: 'Calibre-R-Regular',
          fontSize: '14px',
          lineHeight: '20px',
        },

        '& section:nth-child(2)': {
          marginTop: '-20px',
        },
        '& section:nth-child(3)': {
          marginTop: '-35px',
        },
        '& section:nth-child(4)': {
          marginTop: '-20px',
        },
      },
    },

    extDocMt20Style: {
      marginTop: '20px',
    },
    CardTitleStyle: {
      height: '80px',
      position: 'relative',

      '& div:nth-of-type(1)': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        background: '#003f2d',
        fontfamily: 'Calibre-R-Regular',
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '-0.015em',
        textAlign: 'left',
      },
      '& .card-icon-button': {
        position: 'absolute',
        top: '0px',
        right: '0px',
        color: 'white',
      },
    },
    CardHeader: {
      padding: '16px 36px 24px 16px',
    },
    CardColorStyle: {
      backgroundImage: 'none',
      background: '#3C73A9',
    },
    CardContentStyle: {
      fontfamily: 'Calibre-R-Regular',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0px',
      textAlign: 'left',
      color: theme.palette.text.tertiary,
    },
    CardActionsStyle: {
      display: 'flex',

      '& section:nth-of-type(1)': {
        fontFamily: 'Calibre-R-Regular',
        fontSize: '12px',
        lineHeight: '18px',
        letterSpacing: '0.005em',
        textAlign: 'left',
        color: theme.palette.text.tertiary,
        flex: '1',
      },
      '& section:nth-of-type(2)': {
        fontFamily: 'Calibre-R-Regular',
        fontSize: '16px',
        lineHeight: '16px',
        textAlign: 'left',
        color: '#435254',
      },
      '& .user': {
        color: theme.palette.primary.main,
        fontFamily: 'Calibre-R-Regular',
        fontSize: '14px',
      },
    },

    EmptyStateStyle: {
      '& > div > div > div': {
        marginTop: '190px',
        paddingLeft: '80px',
      },
    },

    /* Header search, select and button styles */
    leftSection: {
      display: 'flex',
    },
    SearchByStyle: {
      borderRadius: '4px',
      display: 'inline-block',
      background: theme.palette.background.paper,
      width: '200px',
      ['@media (max-width:600px) and (min-width: 280px)']: {
        width: 'auto',
      },
      '& > div:first-of-type': {
        width: '100%',

        '& select': {
          padding: '6px 10px',
        },
        '& option': {
          padding: '4px',
        },
      },
    },
    SearchBySelectStyle: {
      background: theme.palette.background.paper,
      display: 'inline-block',
      marginLeft: '10px',
      ['@media (max-width:912px) and (min-width: 280px)']: {
        flexGrow: 1,
      },
      '& > div:first-of-type': {
        width: '100%',
      },
    },
    externalButton: {
      float: 'right',
    },

    /* End of Header search, select and button styles */

    DocsPagination: {
      marginTop: '10px',
      marginBottom: '93px',
    },

    tablePaginationRoot: {
      overflow: 'hidden',
    },
    tablePaginationActions: {
      '& button': {
        ['@media (max-width:400px) and (min-width: 280px)']: {
          padding: '0',
        },
      },
    },
    extDocsPage: {
      padding: '0px',
    },
    RegisterExtContent: {
      padding: '24px',
    },
    docsCard: {
      height: '260px',
      overflow: 'hidden',
      wordBreak: 'break-word',
    },
    docsMargin: {
      marginTop: '20px',
      paddingTop: '20px',
    },
    backBtn: {
      paddingBottom: '10px',
    },
    registerDocsCodePage: {
      [theme.breakpoints.down('sm')]: {
        padding: '0',
        marginTop: '15px',
        overflowX: 'hidden',
      },
    },
  }),
);
