/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import { pocHubApiRef } from '../../../apis/pocHubApi';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { useNavigate } from 'react-router';

export const useDeletePocComponent = () => {
  const pocHub = useApi(pocHubApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const navigate = useNavigate();

  const deletePoc = async (data: any) => {
    try {
      const response = await pocHub.deletePocComponent(data);
      notificationApi.sendNotification({
        message: 'POC deleted successfully',
        disapperAfterMs: 2500,
        severity: 'success',
      });
      navigate('/poc-hub');
    } catch (error) {
      notificationApi.sendNotification({
        message: `Error occurred - ${error?.message}`,
        disapperAfterMs: 2500,
        severity: 'error',
      });
    }
  };

  return { deletePoc };
};
