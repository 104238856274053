/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, InputBase, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { useStyles } from '../styles';

const SearchBar = ({
  inputText,
  onTextChanged,
}: {
  inputText?: string;
  onTextChanged: Function;
}) => {
  const classes = useStyles();

  const [searchText, setSearchText] = useState('');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  useEffect(() => {
    onTextChanged(searchText);
  }, [searchText]);

  return (
    <Paper
      component="form"
      variant="outlined"
      className={classes.searchContainer}
    >
      <InputBase
        className={classes.input}
        placeholder="Search Prompts"
        value={searchText}
        inputProps={{ 'aria-label': 'search google maps' }}
        onChange={handleSearchChange}
      />
      <IconButton
        type="button"
        className={classes.iconButton}
        aria-label="search"
        onClick={() => onTextChanged(searchText)}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default SearchBar;
