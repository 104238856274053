import parseGitUrl from 'git-url-parse';
import { Issue, issuesCount } from '../snyk/types';

export const extractInfoFromGitUrl = (repoUrl: string) => {
  try {
    return parseGitUrl(repoUrl);
  } catch (e) {
    return undefined;
  }
};

const severityLevels = {
  critical: 'critical',
  high: 'high',
  medium: 'medium',
  low: 'low',
};

export const getIssuesCount = (issues: Array<Issue>): issuesCount => {
  const criticalSevCount = issues.filter(
    issue =>
      issue.attributes.effective_severity_level === severityLevels.critical,
  ).length;
  const highSevCount = issues.filter(
    issue => issue.attributes.effective_severity_level === severityLevels.high,
  ).length;
  const mediumSevCount = issues.filter(
    issue =>
      issue.attributes.effective_severity_level === severityLevels.medium,
  ).length;
  const lowSevCount = issues.filter(
    issue => issue.attributes.effective_severity_level === severityLevels.low,
  ).length;
  return {
    critical: criticalSevCount,
    high: highSevCount,
    medium: mediumSevCount,
    low: lowSevCount,
  };
};
