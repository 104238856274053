import { makeStyles, createStyles, Theme } from '@material-ui/core';

export type SignInPageClassKey = 'container' | 'item';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 0,
      listStyle: 'none',
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: '400px',
      margin: 0,
      padding: 0,
    },
    textColor: {
      color: theme.palette.text.signIn,
    },
    textCap: {
      textTransform: 'capitalize',
    },
    name: 'BackstageSignInPage',
  }),
);
