import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const NumberBaseIcon = (props: any) => (
  <SvgIcon viewBox={'48 48'} style={{ transform: 'scale(3)' }} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 3H3V21H21V3ZM7.68994 7.81995V14.9999H10.6699C12.1599 14.9999 13.0799 14.2899 13.0799 12.9599C13.0799 12.1799 12.5899 11.5099 11.9099 11.2899C12.4399 11.0999 12.8599 10.5399 12.8599 9.76995C12.8599 8.47995 11.9399 7.81995 10.4399 7.81995H7.68994ZM10.3499 10.7599H9.12994V9.07995H10.3499C11.0499 9.07995 11.4299 9.32995 11.4299 9.90995C11.4299 10.4899 11.0499 10.7599 10.3499 10.7599ZM9.12994 13.7399V11.9299H10.5499C11.2499 11.9299 11.6299 12.2799 11.6299 12.8299C11.6299 13.3799 11.2499 13.7399 10.5499 13.7399H9.12994ZM17.3603 16.9999V12.6919H16.4903V15.4159L14.5883 12.6919H13.7483V16.9999H14.6123V14.1259L16.6463 16.9999H17.3603Z"
      fill="#53DC3C"
    />
  </SvgIcon>
);

export default NumberBaseIcon;
