import React, { useState } from 'react';
import { Tool } from '@drodil/backstage-plugin-toolbox';
import {
  Box,
  Button,
  Grid,
  TextField,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import QRCode from 'qrcode';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    generateBtn: {
      marginTop: '8px',
    },
    result: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

const QrCodeGenerator = () => {
  const classes = useStyles();
  const [resultAvailable, setResultAvailable] = useState<boolean>(false);
  const [content, setContent] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const getElement = () => {
    const element = document.getElementById('qr-tool-canvas');
    return element;
  };

  const clearTheCanvas = () => {
    const element = getElement();
    // @ts-expect-error
    element?.getContext('2d').clearRect(0, 0, element?.width, element?.height);
  };

  const onClickClear = () => {
    setContent('');
    setResultAvailable(false);
    clearTheCanvas();
    setErrorMessage('');
  };

  const onClickGenerate = () => {
    setResultAvailable(false);
    const element = getElement();

    // Clear existing QR Code
    clearTheCanvas();

    // Generate new
    if (!content) {
      return;
    }
    QRCode.toCanvas(element, content || '', function (error: any) {
      if (error) {
        console.error(error);
        setResultAvailable(false);
        setErrorMessage(error?.message);
      } else {
        setResultAvailable(true);
        setErrorMessage('');
      }
    });
  };

  return (
    <Box>
      <Box sx={{ mb: 1 }}>
        <Button size="small" startIcon={<ClearIcon />} onClick={onClickClear}>
          Clear
        </Button>
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            label="Text or Link"
            variant="outlined"
            fullWidth
            value={content}
            onChange={event => {
              setContent(event?.target?.value || '');
              clearTheCanvas();
              setResultAvailable(false);
              setErrorMessage('');
            }}
          />{' '}
          <Button
            variant="contained"
            color="primary"
            className={classes.generateBtn}
            onClick={onClickGenerate}
          >
            Generate
          </Button>
        </Grid>
        {resultAvailable && (
          <Grid item xs={12}>
            <Box className={classes.result}>
              <Typography style={{ flex: 1 }}>Result:</Typography>
              {/* <Box component="div">
                <Button>Download as image (PNG)</Button>
                <Button>Download as SVG</Button>
              </Box> */}
            </Box>
          </Grid>
        )}
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
        <Box>
          <canvas id="qr-tool-canvas"></canvas>
        </Box>
      </Grid>
    </Box>
  );
};

export const qrCodeGeneratorTool: Tool = {
  id: 'qr-code',
  name: 'Generator',
  component: <QrCodeGenerator />,
  category: 'QR Code',
  description: 'Create and Share QR Codes',
  headerButtons: [],
};

export default qrCodeGeneratorTool;
