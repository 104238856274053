import React, { useState, useEffect, useMemo } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  ListItem,
  Button as MaterialButton,
  Grid,
  LinearProgress,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import {
  UserListFilter,
  useStarredEntities,
  useEntityOwnership,
  CatalogFilterLayout,
} from '@backstage/plugin-catalog-react';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import ExtensionIcon from '@material-ui/icons/Extension';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import InfoIcon from '@material-ui/icons/Info';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Progress } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { MyStuffCodeTable } from './myStuffcode/myStuffTable';
import CodeIcon from '../../Root/icons/codeIcon';
import {
  COMPONENT_TYPE,
  COMPONENT,
  API,
  IAC_COMPONENT_TYPE,
  SALESFORCE_COMPONENT_TYPE,
} from '../../utils/constant';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import BackButton from '../../utils/backButton';
import AllAssetsCardGrid from '../allAssets/cardView/AllAssetsCardGrid';
import SalesforceIcon from '../../Root/icons/salesforceIcon';
import { NoAssetsFound } from '../../noAssetsFound/NoAssetsFound';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    themeColor: {
      backgroundColor: theme.palette.background.paper,
      borderLeftColor: theme.palette.primary.main,
      '& li': {
        borderLeftColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.border}`,
        '&:hover button': {
          color: theme.palette.primary,
          backgroundColor: `${theme.palette.background.tertiary}`,
        },
        '&.active button': {
          borderLeft: `2px solid ${theme.palette.primary.main}`,
          backgroundColor: `${theme.palette.background.tertiary}`,
        },
      },
    },
    toggleButtonGroup: {
      padding: '5.5px 0px',
    },
    toggleButton: {
      padding: '0px 5px',
    },
  }),
);
const initialState = {
  isCodeFilterClick: false,
  isApiFilterClick: false,
  isAllFilterClick: true,
  isFavoriteFilterClick: false,
  isIacFilterClick: false,
  isSalesforceFilterClick: false,
};

const useCatalogEntity = () => {
  const catalogApi = useApi(catalogApiRef);
  const {
    value: entity,
    error,
    loading,
  } = useAsync(() => catalogApi.getEntities());

  return { entity, error, loading };
};

export const MyStuff = (props: any) => {
  const classes = useStyles();
  const [view, setView] = useState<any>('');

  useEffect(() => {
    const storedView = sessionStorage.getItem('assets-view');
    if (view) {
      sessionStorage.setItem('assets-view', view);
    } else {
      const newView =
        storedView === 'table' || storedView === 'card' ? storedView : 'card';
      setView(newView);
    }
  }, [view]);

  const { userName } = props;

  const entityData = useCatalogEntity();
  const { entity, error, loading } = entityData;
  const filterUserName = userName || 'guest';
  const [allAssetsData, setAllAssetsData] = useState<any>([]);
  const [checkClickItem, setCheckClickItem] = useState<any>(initialState);
  const { isStarredEntity } = useStarredEntities();

  const { isOwnedEntity } = useEntityOwnership();

  const starredFilter = useMemo(
    () => new UserListFilter('starred', isOwnedEntity, isStarredEntity),
    [isOwnedEntity, isStarredEntity],
  );

  const allCount =
    allAssetsData &&
    allAssetsData.length &&
    allAssetsData.filter(
      (item: any) =>
        (item?.kind?.toLowerCase() === COMPONENT &&
          COMPONENT_TYPE.includes(item.spec?.type?.toLowerCase())) ||
        item?.kind?.toLowerCase() === API,
    ).length;

  const codeCount =
    (allAssetsData &&
      allAssetsData.length &&
      allAssetsData.filter(
        (item: any) =>
          item.kind?.toLowerCase() === COMPONENT &&
          COMPONENT_TYPE.includes(item.spec?.type?.toLowerCase()) &&
          item?.spec?.type?.toLowerCase() !== IAC_COMPONENT_TYPE.toLowerCase(),
      ).length) ||
    0;

  const Salesforce =
    (allAssetsData &&
      allAssetsData.length &&
      allAssetsData.filter(
        (item: any) =>
          item.kind?.toLowerCase() === COMPONENT &&
          item?.spec?.type?.toLowerCase() ===
            SALESFORCE_COMPONENT_TYPE.toLowerCase(),
      ).length) ||
    0;

  const iacCount =
    (allAssetsData &&
      allAssetsData.length &&
      allAssetsData.filter(
        (item: any) =>
          item.kind?.toLowerCase() === COMPONENT &&
          item?.spec?.type?.toLowerCase() === IAC_COMPONENT_TYPE.toLowerCase(),
      ).length) ||
    0;

  const apiCount =
    (allAssetsData &&
      allAssetsData.length &&
      allAssetsData.filter((item: any) => item.kind?.toLowerCase() === API)
        .length) ||
    0;

  useEffect(() => {
    const entityItems =
      entity &&
      userName &&
      entity?.items.filter(
        (item: any) =>
          item?.spec?.owner?.toLowerCase() === filterUserName?.toLowerCase() ||
          item?.spec?.owner?.toLowerCase() ===
            `user:${filterUserName?.toLowerCase()}`,
      );

    setAllAssetsData(entityItems);
    localStorage.setItem('isReview', 'false');
  }, [entity, error, loading, userName, filterUserName]);

  useEffect(() => {}, [allAssetsData]);

  const starredFilterItem =
    allAssetsData &&
    allAssetsData.length &&
    allAssetsData.filter((item: any) => starredFilter.filterEntity(item));

  const handleOnClickForCode = () => {
    setCheckClickItem({
      ...initialState,
      isCodeFilterClick: true,
      isAllFilterClick: false,
    });
  };

  const handleOnClickForApi = () => {
    setCheckClickItem({
      ...initialState,
      isApiFilterClick: true,
      isAllFilterClick: false,
    });
  };

  const handleOnClickForIac = () => {
    setCheckClickItem({
      ...initialState,
      isIacFilterClick: true,
      isAllFilterClick: false,
    });
  };

  const handleOnClickForSalesforce = () => {
    setCheckClickItem({
      ...initialState,
      isSalesforceFilterClick: true,
      isAllFilterClick: false,
    });
  };

  const handleOnClickForFavorite = () => {
    setCheckClickItem({
      ...initialState,
      isFavoriteFilterClick: true,
      isAllFilterClick: false,
    });
  };

  const handleOnClickForAll = () => {
    setCheckClickItem(initialState);
  };

  if (loading) {
    return (
      <div>
        <Progress />
      </div>
    );
  }

  return (
    <div className="codePage noPadding">
      {loading && <LinearProgress />}
      <Grid item md={12} xs={12}>
        <BackButton />
        <div className="ToggleButtonGroup">
          <ToggleButtonGroup
            color="primary"
            value={view}
            exclusive={false}
            onChange={v => {
              setView(view === 'table' ? 'card' : 'table');
            }}
            className={classes.toggleButtonGroup}
          >
            <ToggleButton value="table" className={classes.toggleButton}>
              Table
            </ToggleButton>
            <ToggleButton value="card" className={classes.toggleButton}>
              Card
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <HelpComponent helpUrl={HELP_URL.MyAsset} />
      </Grid>
      <CatalogFilterLayout>
        <CatalogFilterLayout.Filters>
          <div className={`customFilters ${classes.themeColor}`}>
            <ListItem
              className={checkClickItem.isAllFilterClick ? 'active' : ''}
            >
              <MaterialButton
                variant="outlined"
                name="All"
                onClick={() => handleOnClickForAll()}
                startIcon={<AccountCircleIcon />}
              >
                Owned
                <span className="CountSpan">{allCount}</span>
              </MaterialButton>
            </ListItem>
            <ListItem
              className={checkClickItem.isFavoriteFilterClick ? 'active' : ''}
            >
              <MaterialButton
                variant="outlined"
                name="Favorite"
                onClick={() => handleOnClickForFavorite()}
                startIcon={<BookmarkIcon />}
              >
                My Bookmark
                <span className="CountSpan">
                  {starredFilterItem && starredFilterItem.length}
                </span>
              </MaterialButton>
            </ListItem>

            <ListItem
              className={checkClickItem.isCodeFilterClick ? 'active' : ''}
            >
              <MaterialButton
                variant="outlined"
                name="code"
                onClick={() => handleOnClickForCode()}
                startIcon={<CodeIcon />}
              >
                Code / Library<span className="CountSpan">{codeCount}</span>
              </MaterialButton>
            </ListItem>
            <ListItem
              className={checkClickItem.isApiFilterClick ? 'active' : ''}
            >
              <MaterialButton
                variant="outlined"
                name="api"
                onClick={() => handleOnClickForApi()}
                startIcon={<ExtensionIcon />}
              >
                API
                <span className="CountSpan">{apiCount}</span>
              </MaterialButton>
            </ListItem>
            <ListItem
              className={checkClickItem.isIacFilterClick ? 'active' : ''}
            >
              <MaterialButton
                variant="outlined"
                name="iac"
                onClick={() => handleOnClickForIac()}
                startIcon={<InfoIcon />}
              >
                IaC
                <span className="CountSpan">{iacCount}</span>
              </MaterialButton>
            </ListItem>
            {Salesforce > 0 && (
              <ListItem
                className={checkClickItem.isIacFilterClick ? 'active' : ''}
              >
                <MaterialButton
                  variant="outlined"
                  name="Salesforce "
                  onClick={() => handleOnClickForSalesforce()}
                  startIcon={<SalesforceIcon />}
                >
                  Salesforce
                  <span className="CountSpan">{Salesforce}</span>
                </MaterialButton>
              </ListItem>
            )}
          </div>
        </CatalogFilterLayout.Filters>
        <CatalogFilterLayout.Content>
          {view === 'table' ? (
            <MyStuffCodeTable
              entities={allAssetsData}
              error={error}
              loading={loading}
              checkFilterItem={checkClickItem}
              starredFilterItem={starredFilterItem || []}
            />
          ) : (
            <>
              {allAssetsData && allAssetsData.length > 0 ? (
                <AllAssetsCardGrid
                  loading={loading}
                  entities={allAssetsData}
                  anchorElement={null}
                  setAnchorElement={() => {}}
                  isAdmin={false}
                  checkFilterItem={checkClickItem}
                  starredFilterItem={starredFilterItem || []}
                  isMyStuffPage={true}
                />
              ) : (
                <NoAssetsFound />
              )}
            </>
          )}
        </CatalogFilterLayout.Content>
      </CatalogFilterLayout>
    </div>
  );
};
