import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ColorConverterIcon = (props: any) => (
  <SvgIcon viewBox={'48 48'} style={{ transform: 'scale(3)' }} {...props}>
    <path
      d="M15.3989 14.701C15.4016 18.3955 12.4067 21.389 8.71093 21.389C5.01515 21.389 2.02028 18.3955 2.02295 14.701C2.02028 11.0093 5.01515 8.01306 8.71093 8.01306C12.4067 8.01306 15.4016 11.0093 15.3989 14.701Z"
      fill="#FF15A1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.71098 8.3475C5.19979 8.3475 2.35485 11.194 2.35739 14.7008L2.35739 14.7011L2.35739 14.7013C2.35485 18.2107 5.1997 21.0547 8.71098 21.0547C12.2222 21.0547 15.0671 18.2107 15.0646 14.7013V14.7008C15.0671 11.194 12.2222 8.3475 8.71098 8.3475ZM1.6886 14.7011C1.68592 10.8245 4.83069 7.67871 8.71098 7.67871C12.5913 7.67871 15.736 10.8245 15.7334 14.7011C15.736 18.5805 12.5912 21.7235 8.71098 21.7235C4.83078 21.7235 1.68592 18.5805 1.6886 14.7011Z"
      fill="#373737"
    />
    <path
      d="M12.055 2.53027C10.5813 2.52961 9.14872 3.0159 7.97997 3.91351C6.81122 4.81113 5.9718 6.06976 5.59225 7.49371C5.21269 8.91765 5.31427 10.4271 5.88118 11.7874C6.44809 13.1476 7.44856 14.2825 8.72704 15.0154C8.72169 14.9111 8.71099 14.8081 8.71099 14.7024C8.71089 13.53 9.0191 12.3782 9.6047 11.3625C10.1903 10.3469 11.0327 9.50309 12.0474 8.9158C13.0621 8.32851 14.2133 8.01838 15.3857 8.01653C16.5581 8.01468 17.7104 8.32117 18.7269 8.90525C18.6488 7.18815 17.9117 5.56726 16.6691 4.37967C15.4265 3.19209 13.7738 2.52922 12.055 2.52893V2.53027Z"
      fill="#1583FF"
    />
    <path
      d="M15.3989 8.01306C14.5205 8.01271 13.6507 8.18546 12.8391 8.52144C12.0275 8.85742 11.2901 9.35004 10.669 9.97114C10.0479 10.5922 9.55529 11.3297 9.21931 12.1412C8.88334 12.9528 8.71059 13.8227 8.71094 14.701C8.71094 14.808 8.72164 14.911 8.72699 15.0154C9.74352 15.5995 10.8958 15.9059 12.0682 15.9041C13.2405 15.9022 14.3918 15.5921 15.4065 15.0048C16.4212 14.4175 17.2636 13.5737 17.8492 12.5581C18.4348 11.5424 18.743 10.3906 18.7429 9.21824C18.7429 9.11257 18.7322 9.00957 18.7269 8.90524C17.7151 8.32084 16.5673 8.01314 15.3989 8.01306Z"
      fill="#00CF2D"
    />
    <path
      d="M18.7268 8.90527C18.7321 9.00961 18.7428 9.1126 18.7428 9.21827C18.7431 10.3909 18.4349 11.5429 17.8492 12.5587C17.2636 13.5746 16.421 14.4185 15.4061 15.0058C14.3912 15.5932 13.2397 15.9032 12.0671 15.9049C10.8946 15.9066 9.74215 15.5998 8.72559 15.0154C8.80406 16.7325 9.54142 18.3533 10.7843 19.5406C12.0272 20.7279 13.68 21.3905 15.3989 21.3904C16.8725 21.391 18.3051 20.9048 19.4739 20.0071C20.6426 19.1095 21.482 17.8509 21.8616 16.427C22.2411 15.003 22.1396 13.4935 21.5727 12.1333C21.0057 10.773 20.0053 9.63821 18.7268 8.90527Z"
      fill="#F8FF15"
    />
    <path
      d="M8.72705 15.0154C8.77853 16.1334 9.10997 17.2206 9.69098 18.1771C10.272 19.1337 11.0839 19.9291 12.0523 20.4902C13.0221 19.9308 13.8354 19.1364 14.4175 18.1801C14.9995 17.2238 15.3315 16.1364 15.3829 15.0181C14.3706 15.6004 13.2229 15.9062 12.055 15.9049C10.8867 15.9052 9.73893 15.5993 8.72705 15.0154Z"
      fill="#EF0000"
    />
    <path
      d="M12.0523 8.91321C11.0358 9.49925 10.1918 10.3428 9.60523 11.3589C9.01864 12.375 8.71018 13.5277 8.71094 14.701C8.71094 14.8067 8.72164 14.9096 8.72699 15.014C9.73882 15.5986 10.8867 15.9065 12.0552 15.907C13.2238 15.9075 14.3719 15.6004 15.3842 15.0167C15.3882 14.911 15.3989 14.8067 15.3989 14.701C15.3995 13.5269 15.0904 12.3735 14.5028 11.3571C13.9152 10.3407 13.0699 9.49858 12.0523 8.91321Z"
      fill="#383027"
    />
    <path
      d="M8.71093 8.01306C7.49371 8.01306 6.35676 8.34345 5.37496 8.90925C5.31756 10.1368 5.60016 11.3563 6.19161 12.4335C6.78307 13.5107 7.66042 14.4037 8.72698 15.014C8.72163 14.911 8.71093 14.808 8.71093 14.7024C8.70993 13.5289 9.01828 12.3759 9.60488 11.3595C10.1915 10.3432 11.0356 9.49942 12.0522 8.91326C11.0373 8.32379 9.88459 8.01322 8.71093 8.01306Z"
      fill="#5100CC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3861 1.89174C9.73757 2.04188 8.18034 2.74511 6.97383 3.8975C5.69454 5.1194 4.90163 6.75835 4.73303 8.51115C4.0077 8.95941 3.36502 9.53092 2.83443 10.2008C2.2272 10.9674 1.77898 11.8475 1.516 12.7894C1.25301 13.7314 1.18055 14.7163 1.30285 15.6866C1.42514 16.6569 1.73975 17.5931 2.22825 18.4403C2.71674 19.2876 3.36932 20.0289 4.14779 20.6208C4.92626 21.2128 5.81499 21.6436 6.76196 21.8879C7.70892 22.1322 8.69511 22.1852 9.66281 22.0438C10.5022 21.9211 11.3131 21.6542 12.0599 21.2559C13.6752 22.0761 15.5416 22.2733 17.2998 21.8004C19.1834 21.2937 20.7885 20.0596 21.7622 18.3695C22.7358 16.6794 22.9982 14.6717 22.4916 12.7881C22.0164 11.0213 20.901 9.49939 19.3696 8.51393C19.2031 6.75976 18.4108 5.1191 17.1311 3.89614C15.7641 2.58965 13.946 1.86042 12.055 1.8601L11.3861 1.85999V1.89174ZM12.1821 3.19905C13.683 3.23107 15.1195 3.8238 16.207 4.86313C17.3252 5.93182 17.9884 7.39043 18.0588 8.93561L18.0754 9.30176L18.393 9.48472C19.7758 10.2813 20.7854 11.5945 21.1999 13.1355C21.6144 14.6765 21.3997 16.3191 20.6032 17.7018C19.8066 19.0845 18.4934 20.0942 16.9524 20.5087C15.4114 20.9232 13.7688 20.7085 12.3861 19.912L12.0444 19.7151L11.7068 19.9188C11.0217 20.3321 10.261 20.6045 9.46936 20.7202C8.67767 20.8359 7.87085 20.7926 7.09612 20.5927C6.32139 20.3928 5.59431 20.0404 4.95743 19.5561C4.32055 19.0718 3.78667 18.4653 3.38702 17.7722C2.98737 17.0791 2.72999 16.3132 2.62993 15.5194C2.52988 14.7255 2.58916 13.9197 2.80431 13.1491C3.01947 12.3785 3.38617 11.6585 3.88295 11.0313C4.37974 10.4041 4.99663 9.88231 5.69753 9.49643L6.02597 9.31561L6.04308 8.94107C6.11371 7.39427 6.77799 5.93425 7.8977 4.86476C9.01742 3.79527 10.5064 3.19865 12.0548 3.19902L12.1821 3.19905Z"
      fill="#373737"
    />
  </SvgIcon>
);

export default ColorConverterIcon;
