import { AxiosInstance } from 'axios';
import {
  SwTemplateGithubApi,
  GithubUserAndOrgsInfoResponse,
} from './SWTemplateGithubApi';
import { AxiosInstanceProviderApi } from '../../../../../apis/axiosInstanceApi';

export class SWTemplateGithubClient implements SwTemplateGithubApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  async getUserProfileAndOrgs(
    githubToken: string,
  ): Promise<GithubUserAndOrgsInfoResponse> {
    validateToken(githubToken);

    const instance = await this.axiosInstancePromise;
    return instance
      .get('github-api/user/info', {
        headers: {
          'X-Github-User-Token': githubToken,
        },
      })
      .then(res => res?.data)
      .catch(err => {
        throw new Error(
          `Error occurred while getting user info ${err.message}`,
        );
      });
  }

  async getRepositoryInfo(owner: string, repoName: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`github-api/repo/info/${owner}/${repoName}`)
      .then(res => res?.data)
      .catch(err => {
        throw new Error(
          `Error occurred while getting repo info ${err.message}`,
        );
      });
  }
}

function validateToken(githubToken: string | undefined): void {
  if (!githubToken || (githubToken && githubToken.trim().length === 0)) {
    throw new Error('Token is required to access the API');
  }
}
