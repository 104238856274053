import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import { CatalogApi } from '@backstage/plugin-catalog-react';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';
import { AxiosInstance, AxiosError } from 'axios';

export interface CapabilityTaxonomyApi {
  getTaxonomyDetails: (
    kind: string,
    namespace: string,
    name: string,
  ) => Promise<any>;
  createCapability: (data: any) => Promise<any>;
  updateCapability: (data: any) => Promise<any>;
  deleteCapability: (data: any) => Promise<any>;
  getUserSuggestions: (queryString: string) => Promise<any>;
  getEntitySuggestions: () => Promise<any>;
  getAssetSuggestions: () => Promise<any>;
}

export const capabilityTaxonomyApiRef = createApiRef<CapabilityTaxonomyApi>({
  id: 'cbre.devx.api.capability.taxonomy',
});

export class CapabilityTaxonomyApiImpl implements CapabilityTaxonomyApi {
  private readonly axiosIntancePromise: Promise<AxiosInstance>;
  private readonly catalogApi: CatalogApi;
  private readonly identityApi: IdentityApi;

  constructor(
    axiosInstanceProviderApi: AxiosInstanceProviderApi,
    catalogApi: CatalogApi,
    identityApi: IdentityApi,
  ) {
    this.axiosIntancePromise = axiosInstanceProviderApi.getInstance();
    this.catalogApi = catalogApi;
    this.identityApi = identityApi;
  }

  async getTaxonomyDetails(
    kind: string,
    namespace: string,
    name: string,
  ): Promise<any> {
    return this.catalogApi.getEntityByRef({ kind, namespace, name });
  }

  async createCapability(data: any) {
    const instance = await this.axiosIntancePromise;
    return instance
      .post('service/discovery/capability', data)
      .then(res => res.data);
  }

  async updateCapability(data: any) {
    const tokenResponse = await this.identityApi.getCredentials();

    const instance = await this.axiosIntancePromise;
    return instance
      .put('service/discovery/capability', data, {
        headers: {
          idToken: tokenResponse.token, // This can be removed (not now), maybe in next sprint
        },
      })
      .then(res => res.data);
  }

  async deleteCapability(data: any) {
    const tokenResponse = await this.identityApi.getCredentials();

    const instance = await this.axiosIntancePromise;
    return instance
      .delete('service/discovery/capability', {
        data,
        headers: {
          idToken: tokenResponse.token, // This can be removed (not now), maybe in next sprint
        },
      })
      .then(res => res.data);
  }

  async getUserSuggestions(queryString: string) {
    const instance = await this.axiosIntancePromise;
    return instance
      .get('ms-graph/user', {
        params: {
          q: queryString,
        },
      })
      .then(res => res.data);
  }

  async getEntitySuggestions() {
    return this.catalogApi
      .getEntities({
        filter: { kind: 'Group', 'spec.type': 'capability' },
        fields: ['metadata.name'],
      })
      .then(res => res.items);
  }

  async getAssetSuggestions() {
    return this.catalogApi
      .getEntities({
        filter: [{ kind: 'Component' }, { kind: 'API' }],
        fields: [
          'kind',
          'metadata.namespace',
          'metadata.name',
          'metadata.annotations',
          'metadata.devxstate',
        ],
      })
      .then(res => res.items);
  }
}
