import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  TextField,
  FormControl,
  MenuItem,
  makeStyles,
  createStyles,
  Theme,
  Input,
  Box,
} from '@material-ui/core';
import { urlRegex, validImageFormat } from '../../../utils/Utils';
import { IMAGE_MAX_SIZE } from '../../../utils/constant';

interface PopupFieldProp {
  onChange: any;
  onClick: any;
  title: string;
  value: any;
  type: 'select' | 'text' | 'multiline' | 'file';
  options?: any[];
  required?: boolean;
  icon?: any;
  fieldName: string;
  id?: string;
  validation: string;
  helperText: string;
  validationText: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textFields: {
      marginTop: `${theme.spacing(3)}px`,
    },
    imagePreview: {
      display: 'block',
      margin: '1rem',
      width: '200px',
      height: '180px',
    },
    errorMessage: {
      color: 'red',
      margin: 10,
    },
    uploadFile: {
      margin: '10px 0px 10px 0px',
      padding: 10,
    },
  }),
);

export const DataPopup = (props: {
  id: string;
  open: boolean;
  title: string;
  description: string;
  fields: PopupFieldProp[];
  onSubmit: any;
  onClose: any;
}) => {
  const classes = useStyles();
  const [values, setValues] = useState<any>({});
  const [imageFileName, setImageFilename] = React.useState<any>('');
  const [imagePreviewSrc, setImagePreviewSrc] = React.useState('');
  const [imageDataBase64, setImageDataBase64] = React.useState<any>('');
  const [validateImageFile, setValidateImageFile] = React.useState(false);
  const [handleImage, setHandleImage] = React.useState<any>('');
  const ref = React.useRef('');
  const [selectedFile, setSelectedFile] = useState<any>(false);

  const imageUploadHandle = (e: any, field: any) => {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      if (
        validImageFormat(e.target.files[0].type) &&
        e.target.files[0].size < IMAGE_MAX_SIZE
      ) {
        const imageFile = e.target.files[0];
        const reader = new FileReader();
        setImageFilename(imageFile.name);
        reader.onload = (x: any) => {
          setImagePreviewSrc(x.target?.result);
        };

        getBase64(imageFile).then(data => {
          setValues({
            ...values,
            [field.fieldName]: e.target.files[0].name,
            imageBase64Format: data.split(',')[1],
          });
        });

        reader.readAsDataURL(imageFile);
        setValidateImageFile(false);
        setHandleImage(imageFile);
      } else {
        setValidateImageFile(true);
      }
    } else {
      setValidateImageFile(false);
      setImagePreviewSrc('');
      setHandleImage('');
    }
  };

  function getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (props.fields && props.fields.length > 0) {
      const initialValues = props.fields.reduce(
        (a, b) => ({
          ...a,
          [b.fieldName]: b.value,
        }),
        {},
      );
      console.log(initialValues, 'initialValues');
      const errorObj = props.fields.reduce(
        (a, b) => ({
          ...a,
          [b.fieldName]: '',
        }),
        {},
      );

      setErrors(errorObj);
      setValues(initialValues);
      setSelectedFile(true);
    }
  }, []);

  const validate = () => {
    let errorObj = {};
    props.fields?.forEach((field: any) => {
      if (field.required) {
        if (values[field.fieldName] === '') {
          errorObj = {
            ...errorObj,
            ...{ [field.fieldName]: field.helperText },
          };
        } else if (
          field.fieldName === 'url' &&
          !values[field.fieldName].match(urlRegex)
        ) {
          errorObj = {
            ...errorObj,
            ...{ [field.fieldName]: field.validationText },
          };
        } else {
          errorObj = { ...errorObj, ...{ [field.fieldName]: '' } };
        }
      }
    });
    setErrors((prevState: any) => {
      return { ...prevState, ...errorObj };
    });
    return Object.values(errorObj).every((x: any) => x === '');
  };

  const onSubmitRegister = async () => {
    if (validate()) {
      if (props.id) {
        props.onSubmit({
          ...values,
          id: props.id,
        });
      } else {
        props.onSubmit(values);
      }
    }
  };
  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth>
      <DialogTitle>
        <Typography>{props.title}</Typography>
        <Typography>{props.description}</Typography>
      </DialogTitle>
      {props.fields &&
        props.fields.length > 0 &&
        values[props.fields[0].fieldName] !== undefined && (
          <DialogContent>
            <FormControl variant="outlined" fullWidth>
              {props.fields.map((f: PopupFieldProp) => (
                <>
                  {f.type === 'text' && (
                    <TextField
                      variant="outlined"
                      data-testid="cbre-admin-data-item-popup"
                      label={f.fieldName}
                      fullWidth
                      required={f.required}
                      {...(errors[f.fieldName] && {
                        error: true,
                        helperText: errors[f.fieldName],
                      })}
                      value={values[f.fieldName]}
                      className={classes.textFields}
                      onChange={event => {
                        setValues({
                          ...values,
                          [f.fieldName]: event.target.value,
                        });
                      }}
                    />
                  )}
                  {f.type === 'multiline' && (
                    <TextField
                      multiline
                      maxRows={4}
                      variant="outlined"
                      fullWidth
                      className={classes.textFields}
                      data-testid="cbre-admin-data-item-popup"
                      label={f.title}
                      required={f.required}
                      {...(errors[f.fieldName] && {
                        error: true,
                        helperText: f.helperText,
                      })}
                      value={values[f.fieldName]}
                      onChange={event => {
                        setValues({
                          ...values,
                          [f.fieldName]: event.target.value,
                        });
                      }}
                    />
                  )}
                  {f.type === 'select' && (
                    <TextField
                      id="manage-data-insights-select-label"
                      value={values[f.fieldName]}
                      fullWidth
                      variant="outlined"
                      className={classes.textFields}
                      required={f.required}
                      {...(errors[f.fieldName] && {
                        error: true,
                        helperText: f.helperText,
                      })}
                      onChange={event => {
                        setValues({
                          ...values,
                          [f.fieldName]: event.target.value,
                        });
                      }}
                      label={f.title}
                      select
                    >
                      {f.options &&
                        f.options.map(o => (
                          <MenuItem value={o.name}>{o.name}</MenuItem>
                        ))}
                    </TextField>
                  )}
                  <>
                    {f.type === 'file' && (
                      // <>
                      //   {imagePreviewSrc && imagePreviewSrc !== '' && (
                      //     <img
                      //       className={classes.imagePreview}
                      //       src={imagePreviewSrc}
                      //       style={{ width: 200, height: 150 }}
                      //       alt=""
                      //     />
                      //   )}

                      //   <TextField
                      //     type="file"
                      //     id="image"
                      //     data-testid="file-upload"
                      //     className={classes.uploadFile}
                      //     fullWidth
                      //     value={undefined}
                      //     onChange={e => {
                      //       imageUploadHandle(e, f);
                      //     }}
                      //     onClick={() => {
                      //       setValidateImageFile(false);
                      //       setImagePreviewSrc('');
                      //       setHandleImage('');
                      //     }}
                      //   />
                      //   {validateImageFile && (
                      //     <Typography className={classes.errorMessage}>
                      //       *Supported image type is jpeg or png format
                      //       (optional field/no image) and size must less than
                      //       15MB!
                      //     </Typography>
                      //   )}
                      // </>

                      <Box>
                        {selectedFile && values.imageFileName && (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                            className={classes.uploadFile}
                          >
                            {values.imageFileName && (
                              <Typography>
                                <b>Selected Image : </b>
                                {values.imageFileName.split('/')[3]}
                              </Typography>
                            )}

                            {/* <Box>
                              <Button
                                data-testid="remove-selected-image"
                                onClick={() => setSelectedFile(true)}
                              >
                                REMOVE
                              </Button>
                            </Box> */}
                          </Box>
                        )}
                        {selectedFile && (
                          <TextField
                            fullWidth
                            type="file"
                            required={f.required}
                            data-testid="upload-image"
                            className={classes.uploadFile}
                            {...(errors[f.fieldName] && {
                              error: true,
                              helperText: f.helperText,
                            })}
                            onChange={e => imageUploadHandle(e, f)}
                          />
                        )}
                        {validateImageFile && (
                          <Typography className={classes.errorMessage}>
                            *Supported image type is jpeg or png format (empty
                            field/no image) and size must less than 15MB!
                          </Typography>
                        )}
                      </Box>
                    )}
                  </>
                </>
              ))}
            </FormControl>
          </DialogContent>
        )}
      <DialogActions>
        <Button variant="outlined" onClick={props.onClose}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={onSubmitRegister}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
