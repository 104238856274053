import { createApiRef } from '@backstage/core-plugin-api';
import { AxiosInstance } from 'axios';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';
import { fetchEventSource } from '@microsoft/fetch-event-source';

export interface PromptApi {
  saveDomain: (data: CreateDomainPayload) => Promise<any>;
  getDomainDetails: (id: string) => Promise<any>;
  getDomainHierarchy: () => Promise<any>;
  getDomainHierarchyByEmail: (email: string) => Promise<any>;
  editDomain: (data: CreateDomainPayload, id: string) => Promise<any>;
  deleteDomain: (id: string) => Promise<any>;
  saveSubDomain: (data: CreateSubDomainPayload) => Promise<any>;
  editSubDomain: (data: any, id: string) => Promise<any>;
  deleteSubDomain: (id: string) => Promise<any>;
  getPersonaById: (id: string) => Promise<any>;
  savePersona: (data: any) => Promise<any>;
  savePersonaUsers: (users: any[], personaId: string) => Promise<any>;
  editPersona: (data: any, id: string) => Promise<any>;
  assignUserToPersona: (id: string, email: string) => Promise<any>;
  removeUserFromPersona: (id: string, email: string) => Promise<any>;
  deletePersona: (id: string) => Promise<any>;
  getPromptByEmail: (email: string) => Promise<any>;
  getAdminPromptByEmail: (email: string) => Promise<any>;
  createPrompt: (data: any) => Promise<any>;
  updatePrompt: (data: any, id: string) => Promise<any>;
  deletePrompt: (data: any, id: string) => Promise<any>;
  suggestedPrompt: (data: any) => Promise<any>;
  createAdmin: (email: string, domainid: string) => Promise<any>;
  removeAdmin: (email: string, domainid: string) => Promise<any>;
  getAllAdmin: (domainid: string) => Promise<any>;

  // Prompt Store
  getPromptsByDomain: (
    domainId: string,
    subDomainId: string,
    personaId: string,
  ) => Promise<any>;

  getStorePrompts: () => Promise<any>;
  getPromptTags: (domainId: string, sortBy: string) => Promise<any>;
  getPromptsUsageByDomain: (domainId: string) => Promise<any>;
  getPromptUsage: (promptId: string) => Promise<any>;
  updatePromptUsage: (domainId: string, promptId: string) => Promise<any>;
  buildPrompt: (
    data: any,
    accessToken: string,
    onStart: Function,
    onStop: Function,
    onData: Function,
    onError: Function,
  ) => Promise<any>;
  buildPromptV2: (
    data: any,
    accessToken: string,
    onStart: Function,
    onStop: Function,
    onData: Function,
    onError: Function,
  ) => Promise<any>;
  aiServiceStream: (
    data: AIServiceStreamPayload,
    accessToken: string,
    onStart: Function,
    onStop: Function,
    onData: Function,
    onError: Function,
  ) => Promise<any>;
  aiStreamWithDomain: (
    domainId: string,
    data: any,
    accessToken: string,
    onStart: Function,
    onStop: Function,
    onData: Function,
    onError: Function,
  ) => Promise<any>;
}

export interface CreateDomainPayload {
  id?: string;
  name: string;
  description: string;
  createdat?: string;
  updatedat?: string;
  productowner?: string[];
}

export interface Domain {
  createdat: string;
  description: string;
  isactive: boolean;
  name: string;
  id: string;
  updatedat: string;
}

export interface GetDomainResponse {
  message: string;
  status: number;
  data: Domain;
}

export interface CreateSubDomainPayload {
  id?: string;
  name: string;
  did: string;
  description: string;
  createdat?: string;
  updatedat?: string;
}

export type Input = {
  role: 'system' | 'assistant' | 'user';
  content: string;
};

export interface AIServiceStreamPayload {
  id: string;
  promptid: string;
  message: Input[];
  emailid: string;
}

export const promptApiRef = createApiRef<PromptApi>({
  id: 'cbre.devx.api.prompt',
});

export class PromptApiImpl implements PromptApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  async saveDomain(data: any): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance.post('aiservice/mgmt/domain', data).then(res => res.data);
  }

  async getDomainDetails(id: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance.get('aiservice/mgmt/domain/' + id).then(res => res.data);
  }

  async getDomainHierarchy(): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance.get('aiservice/mgmt/hierarchy').then(res => res.data);
  }

  async getDomainHierarchyByEmail(email: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`aiservice/mgmt/user/${email.toLocaleLowerCase()}/hierarchy`)
      .then(res => res.data);
  }

  async editDomain(data: any, id: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .put('aiservice/mgmt/domain/' + id, data)
      .then(res => res.data);
  }

  async deleteDomain(id: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance.delete('aiservice/mgmt/domain/' + id).then(res => res.data);
  }

  //sub products
  async saveSubDomain(data: any): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .post('aiservice/mgmt/subdomain', data)
      .then(res => res.data);
  }

  async editSubDomain(data: any, id: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .put('aiservice/mgmt/subdomain/' + id, data)
      .then(res => res.data);
  }

  async deleteSubDomain(id: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .delete('aiservice/mgmt/subdomain/' + id)
      .then(res => res.data);
  }

  //personas
  async getPersonaById(id: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance.get('aiservice/mgmt/persona/' + id).then(res => res.data);
  }

  async savePersona(data: any): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance.post('aiservice/mgmt/persona', data).then(res => res.data);
  }

  async savePersonaUsers(users: any[], personaId: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .put(`aiservice/mgmt/persona/${personaId}/sync_users`, {
        emails: users
      })
      .then(res => res.data);
  }

  async editPersona(data: any, id: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .put('aiservice/mgmt/persona/' + id, data)
      .then(res => res.data);
  }

  async assignUserToPersona(id: string, email: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .post(`aiservice/mgmt/persona/${id}/user/${email}`)
      .then(res => res.data);
  }

  async removeUserFromPersona(id: string, email: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .delete(`aiservice/mgmt/persona/${id}/user/${email}`)
      .then(res => res.data);
  }

  async deletePersona(id: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .delete(`aiservice/mgmt/persona/${id}`)
      .then(res => res.data);
  }

  //prompts

  async getAllPromptForProduct(productName: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`/aiservice/prompts/product/${productName}`)
      .then(res => res.data);
  }

  async updatePrompt(data: any, id: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance.put(`aiservice/prompts/${id}`, data).then(res => res.data);
  }

  async deletePrompt(data: any, id: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .delete(`aiservice/prompts/${id}`, { data })
      .then(res => res.data);
  }

  async getPromptByEmail(email: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get('aiservice/prompts/?email=' + email.toLowerCase())
      .then(res => res.data);
  }

  async getAdminPromptByEmail(email: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get('aiservice/prompts/adm?email=' + email.toLowerCase())
      .then(res => res.data);
  }

  async createPrompt(data: any): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance.post('aiservice/prompts', data).then(res => res.data);
  }

  //suggested prompt
  async suggestedPrompt(data: any): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .post(`aiservice/generate/title`, data)
      .then(res => res.data);
  }

  //admin features
  async createAdmin(email: string, domainid: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .post(`/aiservice/mgmt/admin/domain/${domainid}/user/${email}`)
      .then(res => res.data);
  }

  async removeAdmin(email: string, domainid: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .delete(`/aiservice/mgmt/admin/domain/${domainid}/user/${email}`)
      .then(res => res.data);
  }

  async getAllAdmin(domainid: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`/aiservice/mgmt/admin/domain/${domainid}`)
      .then(res => res.data);
  }

  /* Prompt Store Related Services */
  /* Get Prompts by Domain Id */
  async getPromptsByDomain(
    domainId: string,
    subDomainId: string,
    personaId: string,
  ): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`/aiservice/prompts/domain/${domainId}/${subDomainId}/${personaId}`)
      .then(res => res.data);
  }

  /* Get Store Prompts */
  async getStorePrompts(): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`/aiservice/auth/prompts/store`, {
        headers: {
          clientid: 'd4e6c1c9-35ac-4d16-ad2d-cb837eb41853',
        },
      })
      .then(res => res.data);
  }

  /* Get Prompt Tags */
  async getPromptTags(
    domainId: string,
    sortBy: string = 'frequency',
  ): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`/aiservice/prompts/${domainId}/tags/${sortBy}`)
      .then(res => res.data);
  }

  /* Get domain Prompts Usage */
  async getPromptsUsageByDomain(domainId: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`/aiservice/prompts/domain/${domainId}/usage`)
      .then(res => res.data);
  }

  /* Get Prompts Usage */
  async getPromptUsage(promptId: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`/aiservice/prompts/usage/${promptId}`)
      .then(res => res.data);
  }

  /* Update Prompts Usage */
  async updatePromptUsage(domainId: string, promptId: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .put(`/aiservice/prompts/${promptId}/${domainId}/usage`)
      .then(res => res.data);
  }

  /* build Prompt service */
  async buildPrompt(
    data: any,
    accessToken: string,
    onStart: Function,
    onStop: Function,
    onData: Function,
    onError: Function,
  ): Promise<any> {
    const instance = await this.axiosInstancePromise;
    const baseUrl = instance.getUri();
    const response = await fetchEventSource(
      `${baseUrl}aiservice/auth/genai/prompt/builder`,
      {
        method: 'POST',
        headers: {
          'api-version': '1.0',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          clientid: 'd4e6c1c9-35ac-4d16-ad2d-cb837eb41853',
        },
        body: JSON.stringify(data),
        openWhenHidden: true,
        onopen(res) {
          if (res.ok && res.status === 200) {
            console.log('Connection started');
            onStart(res);
          } else if (
            res.status >= 400 &&
            res.status < 500 &&
            res.status !== 429
          ) {
            console.log('Client-side error ', res);
            onStop();
          }
        },
        onmessage(event) {
          onData(event.data);
        },
        onclose() {
          onStop();
        },
        onerror(err) {
          console.log('Error occurred', err);
          onError(err);
          onStop();
        },
      },
    );

    return response;
  }

  /* build Prompt service */
  async buildPromptV2(
    data: any,
    accessToken: string,
    onStart: Function,
    onStop: Function,
    onData: Function,
    onError: Function,
  ): Promise<any> {
    const instance = await this.axiosInstancePromise;
    const baseUrl = instance.getUri();
    const response = await fetchEventSource(
      `${baseUrl}aiservice/auth/genai/prompt/builder`,
      {
        method: 'POST',
        headers: {
          'api-version': '1.0',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          clientid: 'devx',
        },
        body: JSON.stringify({
          ...data,
          templatize: false
        }),
        openWhenHidden: true,
        onopen(res) {
          if (res.ok && res.status === 200) {
            console.log('Connection started');
            onStart(res);
          } else if (
            res.status >= 400 &&
            res.status < 500 &&
            res.status !== 429
          ) {
            console.log('Client-side error ', res);
            onStop();
          }
        },
        onmessage(event) {
          onData(event.data);
        },
        onclose() {
          onStop();
        },
        onerror(err) {
          console.log('Error occurred', err);
          onError(err);
          onStop();
        },
      },
    );

    return response;
  }

  /* Prompt Store try it out stream feature */
  async aiServiceStream(
    data: AIServiceStreamPayload,
    accessToken: string,
    onStart: Function,
    onStop: Function,
    onData: Function,
    onError: Function,
  ): Promise<any> {
    const instance = await this.axiosInstancePromise;
    const baseUrl = instance.getUri();
    const response = await fetchEventSource(
      `${
        baseUrl.slice(-1) === '/' ? baseUrl : baseUrl + '/'
      }aiservice/chat/stream`,
      {
        method: 'POST',
        headers: {
          'api-version': '1.0',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
        openWhenHidden: true,
        onopen(res) {
          if (res.ok && res.status === 200) {
            console.log('Connection started');
            onStart();
          } else if (
            res.status >= 400 &&
            res.status < 500 &&
            res.status !== 429
          ) {
            console.log('Client-side error ', res);
            onStop();
          }
        },
        onmessage(event) {
          onData(event.data);
        },
        onclose() {
          onStop();
        },
        onerror(err) {
          console.log('Error occurred', err);
          onError(err);
          onStop();
        },
      },
    );

    return response;
  }

  async aiStreamWithDomain(
    domainId: string,
    data: any,
    accessToken: string,
    onStart: Function,
    onStop: Function,
    onData: Function,
    onError: Function,
  ){
    const instance = await this.axiosInstancePromise;
    const baseUrl = instance.getUri();
    const response = await fetchEventSource(
      `${
        baseUrl.slice(-1) === '/' ? baseUrl : baseUrl + '/'
      }aiservice/auth/genai/doc/search?domain=${domainId}`,
      {
        method: 'POST',
        headers: {
          'api-version': '1.0',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
        openWhenHidden: true,
        onopen(res) {
          if (res.ok && res.status === 200) {
            console.log('Connection started');
            onStart();
          } else if (
            res.status >= 400 &&
            res.status < 500 &&
            res.status !== 429
          ) {
            console.log('Client-side error ', res);
            onStop();
          }
        },
        onmessage(event) {
          onData(event.data);
        },
        onclose() {
          onStop();
        },
        onerror(err) {
          console.log('Error occurred', err);
          onError(err);
          onStop();
        },
      },
    );
  }
}
