import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@material-ui/core';

const NavigateOut = ({handleYes, handleClose,open}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>This will navigate out of DevX</DialogContent>
      <DialogActions>
        <Button role="button" variant="contained" color="primary" onClick={handleYes}>
          Yes
        </Button>
        <Button role="button" variant="contained" color="secondary" onClick={handleClose}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default NavigateOut;
