import React, { useState, useEffect, useMemo } from 'react';
import { MyStuffCodeTable } from './userStuffcode/myStuffTable';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import {
  ListItem,
  Button as MaterialButton,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import {
  UserListFilter,
  useEntityOwnership,
  useStarredEntities,
  CatalogFilterLayout,
} from '@backstage/plugin-catalog-react';
// import StarIcon from '@material-ui/icons/Star';
import CodeIcon from '../../../Root/icons/codeIcon';
import ExtensionIcon from '@material-ui/icons/Extension';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import InfoIcon from '@material-ui/icons/Info';
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {
  COMPONENT_TYPE,
  COMPONENT,
  API,
  IAC_COMPONENT_TYPE,
  STATUS_APPROVED,
} from '../../../utils/constant';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    themeColor: {
      backgroundColor: theme.palette.background.paper,
      borderLeftColor: theme.palette.primary.main,
      '& li': {
        borderLeftColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.border}`,
        '&:hover button': {
          color: theme.palette.primary,
          backgroundColor: `${theme.palette.background.tertiary}`,
        },
        '&.active button': {
          borderLeft: `2px solid ${theme.palette.primary.main}`,
          backgroundColor: `${theme.palette.background.tertiary}`,
        },
      },
    },
  }),
);
const initialState = {
  isCodeFilterClick: false,
  isApiFilterClick: false,
  isAllFilterClick: true,
  isFavoriteFilterClick: false,
  isIacFilterClick: false,
  isApprovedAssetsFilterClick: false,
};
export const UserStuff = (props: any) => {
  const classes = useStyles();
  const { entity, error, loading, profile } = props;
  const filterUserName = profile?.split('@')[0] || 'guest';
  const [allAssetsData, setAllAssetsData] = useState<any>([]);
  const [checkClickItem, setCheckClickItem] = useState<any>(initialState);
  const { isStarredEntity } = useStarredEntities();

  const { state } = useLocation();
  const { isOwnedEntity } = useEntityOwnership();

  const starredFilter = useMemo(
    () => new UserListFilter('starred', isOwnedEntity, isStarredEntity),
    [isOwnedEntity, isStarredEntity],
  );

  const allCount =
    allAssetsData &&
    allAssetsData.length &&
    allAssetsData.filter(
      (item: any) =>
        (item?.kind?.toLowerCase() === COMPONENT &&
          COMPONENT_TYPE.includes(item.spec?.type?.toLowerCase())) ||
        item?.kind?.toLowerCase() === API,
    ).length;

  const codeCount =
    (allAssetsData &&
      allAssetsData.length &&
      allAssetsData.filter(
        (item: any) =>
          item.kind?.toLowerCase() === COMPONENT &&
          COMPONENT_TYPE.includes(item.spec?.type?.toLowerCase()) &&
          item?.spec?.type?.toLowerCase() !== IAC_COMPONENT_TYPE.toLowerCase(),
      ).length) ||
    0;

  const iacCount =
    (allAssetsData &&
      allAssetsData.length &&
      allAssetsData.filter(
        (item: any) =>
          item.kind?.toLowerCase() === COMPONENT &&
          item?.spec?.type?.toLowerCase() === IAC_COMPONENT_TYPE.toLowerCase(),
      ).length) ||
    0;
  const ApprovedAsetsCount =
    (allAssetsData &&
      allAssetsData.length &&
      allAssetsData.filter(
        (item: any) =>
          (item.kind?.toLowerCase() === COMPONENT &&
            COMPONENT_TYPE.includes(
              item.spec?.type?.toLowerCase() &&
                item?.metadata?.devxstate?.toLowerCase() ===
                  STATUS_APPROVED.toLowerCase(),
            )) ||
          (item.kind?.toLowerCase() === API &&
            item?.metadata?.devxstate?.toLowerCase() ===
              STATUS_APPROVED.toLowerCase()),
      ).length) ||
    0;

  const apiCount =
    (allAssetsData &&
      allAssetsData.length &&
      allAssetsData.filter((item: any) => item.kind?.toLowerCase() === API)
        .length) ||
    0;

 

  const starredFilterItem =
    allAssetsData &&
    allAssetsData.length &&
    allAssetsData.filter((item: any) => starredFilter.filterEntity(item));

  const handleOnClickForCode = () => {
    setCheckClickItem({
      ...initialState,
      isCodeFilterClick: true,
      isAllFilterClick: false,
    });
  };

  const handleOnClickForApi = () => {
    setCheckClickItem({
      ...initialState,
      isApiFilterClick: true,
      isAllFilterClick: false,
    });
  };

  const handleOnClickForIac = () => {
    setCheckClickItem({
      ...initialState,
      isIacFilterClick: true,
      isAllFilterClick: false,
    });
  };
  const handleOnClickForApprovedAssets = () => {
    setCheckClickItem({
      ...initialState,
      isApprovedAssetsFilterClick: true,
      isAllFilterClick: false,
    });
  };

  const handleOnClickForFavorite = () => {
    setCheckClickItem({
      ...initialState,
      isFavoriteFilterClick: true,
      isAllFilterClick: false,
    });
  };

  const handleOnClickForAll = () => {
    setCheckClickItem(initialState);
  };


  useEffect(() => {
    if (state && state?.assetType === 'Approved Assets') {
      handleOnClickForApprovedAssets();
    }

    const entityItems =
      entity &&
      entity?.items.filter(
        (item: any) =>
          item?.spec?.owner?.replace('user:', '').toLowerCase() ===
          filterUserName?.toLowerCase(),
      );

    setAllAssetsData(entityItems);
    localStorage.setItem('isReview', 'false');
  }, [entity, error, loading, filterUserName, state]);

  useEffect(() => {}, [allAssetsData]);

  
  return (
    <div className="codePage">
      <CatalogFilterLayout>
        <CatalogFilterLayout.Filters>
          <div className={`customFilters ${classes.themeColor}`}>
            <ListItem
              className={checkClickItem.isAllFilterClick ? 'active' : ''}
            >
              <MaterialButton
                variant="outlined"
                name="All"
                onClick={() => handleOnClickForAll()}
                startIcon={<DoneAllIcon />}
              >
                All
                <span className="CountSpan">{allCount}</span>
              </MaterialButton>
            </ListItem>
            <ListItem
              className={checkClickItem.isFavoriteFilterClick ? 'active' : ''}
            >
              <MaterialButton
                variant="outlined"
                name="Favorite"
                onClick={() => handleOnClickForFavorite()}
                startIcon={<BookmarkIcon />}
              >
                Bookmark
                <span className="CountSpan">
                  {starredFilterItem && starredFilterItem.length}
                </span>
              </MaterialButton>
            </ListItem>

            <ListItem
              className={checkClickItem.isCodeFilterClick ? 'active' : ''}
            >
              <MaterialButton
                variant="outlined"
                name="code"
                onClick={() => handleOnClickForCode()}
                startIcon={<CodeIcon />}
              >
                Code/Library<span className="CountSpan">{codeCount}</span>
              </MaterialButton>
            </ListItem>
            <ListItem
              className={checkClickItem.isApiFilterClick ? 'active' : ''}
            >
              <MaterialButton
                variant="outlined"
                name="api"
                onClick={() => handleOnClickForApi()}
                startIcon={<ExtensionIcon />}
              >
                API
                <span className="CountSpan">{apiCount}</span>
              </MaterialButton>
            </ListItem>
            <ListItem
              className={checkClickItem.isIacFilterClick ? 'active' : ''}
            >
              <MaterialButton
                variant="outlined"
                name="iac"
                onClick={() => handleOnClickForIac()}
                startIcon={<InfoIcon />}
              >
                IaC
                <span className="CountSpan">{iacCount}</span>
              </MaterialButton>
            </ListItem>
            <ListItem
              className={
                checkClickItem.isApprovedAssetsFilterClick ? 'active' : ''
              }
            >
              <MaterialButton
                variant="outlined"
                name="approveAssets"
                onClick={() => handleOnClickForApprovedAssets()}
                startIcon={<CheckBoxOutlinedIcon />}
              >
                Approved
                <span className="CountSpan">{ApprovedAsetsCount}</span>
              </MaterialButton>
            </ListItem>
          </div>
        </CatalogFilterLayout.Filters>
        <CatalogFilterLayout.Content>
          <MyStuffCodeTable
            entities={allAssetsData}
            error={error}
            loading={loading}
            checkFilterItem={checkClickItem}
            starredFilterItem={starredFilterItem || []}
          />
        </CatalogFilterLayout.Content>
      </CatalogFilterLayout>
    </div>
  );
};
