import React, { useEffect, useState } from 'react';
import {
  createStyles,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
} from '@material-ui/core';
import ClearButton from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { IUtilityTool } from '../types';
import { ALL_UTILITY_TOOLS } from '../constants';

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      flex: '10',
    },
    ContentHeaderStyle: {
      width: '100%',
      padding: '0px',
      '& > form:first-of-type': {
        display: 'flex',
        '& > button': {
          flex: '0.2',
        },
        '& > div': {
          flex: '10',
        },
      },
    },
  }),
);

export const UTILITY_TOOLS_SEARCH_STATUS = {
  CLEARED: 'CLEARED',
  NOT_FOUND: 'NOT_FOUND',
  FOUND: 'FOUND',
};

export interface IUtilityToolsSearchFilterProps {
  updateFilteredTools: (tools: IUtilityTool[], status: string) => void;
}

const UtilityToolsSearchFilter = ({
  updateFilteredTools,
}: IUtilityToolsSearchFilterProps) => {
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    if (searchTerm === '' || searchTerm?.trim() === '') {
      if (updateFilteredTools) {
        updateFilteredTools([], UTILITY_TOOLS_SEARCH_STATUS.CLEARED);
      }
      return;
    }
    if (updateFilteredTools !== undefined) {
      // filter the tools
      const tools: IUtilityTool[] = ALL_UTILITY_TOOLS.filter(
        (item: IUtilityTool) =>
          `${item?.label}`
            ?.toLowerCase()
            ?.replaceAll(' ', '')
            .includes(`${searchTerm}`?.toLowerCase()?.replaceAll(' ', '')),
      );
      if (tools.length > 0) {
        updateFilteredTools(tools, UTILITY_TOOLS_SEARCH_STATUS.FOUND);
      } else {
        updateFilteredTools(tools, UTILITY_TOOLS_SEARCH_STATUS.NOT_FOUND);
      }
    }
  }, [searchTerm]);

  return (
    <div className={classes.ContentHeaderStyle}>
      <label htmlFor="search-input" hidden>
        Search Here
      </label>
      <Paper component="form">
        <IconButton disabled type="submit" aria-label="search">
          <SearchIcon />
        </IconButton>
        <InputBase
          className={classes.input}
          placeholder="Search"
          value={searchTerm}
          onChange={event => {
            setSearchTerm(event?.target?.value);
            return;
          }}
          inputProps={{ 'aria-label': 'search' }}
        />
        <IconButton
          aria-label="search"
          onClick={() => {
            setSearchTerm('');
            return;
          }}
        >
          <ClearButton />
        </IconButton>
      </Paper>
    </div>
  );
};
export default UtilityToolsSearchFilter;
