import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, makeStyles, Grid } from '@material-ui/core';
import { TechDocsPicker } from '@backstage/plugin-techdocs';
import {
  EntityListProvider,
  EntityTypePicker,
  CatalogFilterLayout,
} from '@backstage/plugin-catalog-react';
import { CustomDocsTable } from './CustomDocsTable';
import EXT_DOCS_CONSTANTS from '../Constants';
import { UserListPicker } from '../../filters/UserListPicker';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import BackButton from '../../utils/backButton';
import { EntityOwnerPicker } from '../../utils/EntityOwnerPicker';

const useStyles = makeStyles({
  ButtonStyle: {
    marginBottom: '10px',
    textAlign: 'right',
  },
  ZeroPaddingStyle: {
    padding: '0px',
  },
});

export const CustomTechDocsPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const navigateToRegisterNewDocument = () => {
    navigate('/create/templates/tech-docs-template');
  };

  return (
    <div>
      {EXT_DOCS_CONSTANTS.isShowRegisterNewTemplate && (
        <div className={classes.ButtonStyle}>
          <Button
            onClick={navigateToRegisterNewDocument}
            variant="contained"
            color="primary"
          >
            {EXT_DOCS_CONSTANTS.registerNewTemplate}
          </Button>
        </div>
      )}
      <div className={`codePage ${classes.ZeroPaddingStyle}`}>
        <Grid item md={12} xs={12}>
          <BackButton />
          <HelpComponent helpUrl={HELP_URL.TechDocs} />
        </Grid>
        <EntityListProvider>
          <CatalogFilterLayout>
            <CatalogFilterLayout.Filters>
              <TechDocsPicker />
              <EntityTypePicker />
              <UserListPicker initialFilter="all" parentName="techdocs" />
              <EntityOwnerPicker />
            </CatalogFilterLayout.Filters>
            <CatalogFilterLayout.Content>
              <CustomDocsTable />
            </CatalogFilterLayout.Content>
          </CatalogFilterLayout>
        </EntityListProvider>
      </div>
    </div>
  );
};
