import { createApiRef } from '@backstage/core-plugin-api';
import { PublishSubject } from '../components/notifications/subjects';
import { Observable } from 'react-use/lib/useObservable';

export interface UserProfileApi {
  setUserProfileState: (modal: IUserProfileModal) => void;
  publisher$: () => Observable<IUserProfileModal>;
}

export interface IUserProfileModal {
  email: string | undefined;
  anchorRef: any;
}

export const userProfileApiRef = createApiRef<UserProfileApi>({
  id: 'cbre.devx.api.user.profile',
});

export class UserProfileApiImpl implements UserProfileApi {
  private readonly subject = new PublishSubject<IUserProfileModal>();

  setUserProfileState(modal: IUserProfileModal) {
    this.subject.next(modal);
  }

  publisher$(): Observable<IUserProfileModal> {
    return this.subject;
  }
}
