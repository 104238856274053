import { Grid, IconButton, Tooltip } from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';
import ExpandMore from '../../../common/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Heading = (props: any) => {
  const { text, onDelete, onExpand, expanded } = props;
  const classes = useStyles();

  return (
    <Grid container direction="row" spacing={3}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.spaceBetween}
      >
        <span>{text}</span>
        <span>
          <Tooltip title="Delete">
            <IconButton className={classes.actionIcons} onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>

          <ExpandMore
            expand={expanded}
            onClick={onExpand}
            aria-expanded={expanded}
            aria-label="show more"
            className={classes.actionIcons}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </span>
      </Grid>
    </Grid>
  );
};

export default Heading;
