const EXT_DOCS_CONSTANTS = {
  tecDocsLabel: 'Tech Docs',
  externalDocumentsLabel: 'External Documentation',
  documentationLabel: 'Documentation',
  registerNewDocumentationLabel: 'Register external document',
  registerNewTemplate: 'Register Docs Template',
  isShowRegisterNewTemplate: false,
  docsLabel: 'Docs',
  routeNameExternalDocs: 'EXTERNAL DOCS',
  routeNameDocs: 'DOCS',
  registerPageTitle: 'Start registering an external document',
  registerBackToExternalDocs: 'Back to ExternalDocs',
  externalDocsNoMatch: `No records matched for this criteria. Please refine the
  criteria and try again`,
  registerInfo: {
    heading: 'Register an existing document',
    title: 'Title: Name or title of the document',
    url: 'URL: Link to an external document',
    urlExample: 'Example: https://github.com/CBRE/component/article',
    description: 'Description: Add a long description of the document ',
  },
  registerForm: {
    heading: 'Fill the form details',
    title: {
      label: 'Title',
      placeholder: 'Type here...',
      rules: {
        required: 'Title is required',
        minChars: 'At least 3 characters required',
        maxChars: 'Maximum 50 characters allowed',
      },
    },
    url: {
      label: 'URL',
      placeholder: 'Paste URL here...',
      rules: {
        required: 'URL is required',
        shouldBeUrl: 'It should be a URL',
      },
    },
    description: {
      label: 'Description',
      placeholder: 'Type here...',
      rules: {
        required: 'Description is required',
        minChars: 'At least 3 characters required',
        maxChars: 'Maximum 200 characters allowed',
      },
    },
    submit: 'Submit',
    close: 'Close',
  },
  successLabel: 'Success',
  registeredSuccessMessage: `New documentation added successfully`,
  errorLabel: 'Error',
  registerExternalHeading: 'Register External Documentation',
  registerContentHeader: 'Register your external document in ',
  externalFilterSelectItems: [
    {
      label: 'Title',
      value: 'title',
    },
    {
      label: 'URL',
      value: 'url',
    },
    {
      label: 'Description',
      value: 'description',
    },
    {
      label: 'User',
      value: 'user',
    },
    {
      label: 'All',
      value: 'all',
    },
  ],
};
export default EXT_DOCS_CONSTANTS;
