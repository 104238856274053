import axios, { AxiosInstance } from 'axios';
import {
  createApiRef,
  DiscoveryApi,
  IdentityApi,
  OAuthApi,
} from '@backstage/core-plugin-api';
import { getCookie } from '../components/utils/Utils';

export interface AxiosInstanceProviderApi {
  getInstance(): Promise<AxiosInstance>;
}

export const axiosInstanceProviderApiRef =
  createApiRef<AxiosInstanceProviderApi>({
    id: 'cbre.devx.axios.instance',
  });

export class AxiosInstanceProviderApiImpl implements AxiosInstanceProviderApi {
  private axiosInstance: AxiosInstance | undefined = undefined;
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;
  private readonly microsoftAuthApi: OAuthApi;

  constructor(
    discoveryApi: DiscoveryApi,
    identityApi: IdentityApi,
    microsoftAuthApi: OAuthApi,
  ) {
    this.discoveryApi = discoveryApi;
    this.identityApi = identityApi;
    this.microsoftAuthApi = microsoftAuthApi;
  }

  // Lazy initialization
  async init() {
    if (this.axiosInstance) {
      return this.axiosInstance;
    }
    const API_BASE_URL = await this.discoveryApi.getBaseUrl('');

    this.axiosInstance = axios.create({
      baseURL: API_BASE_URL,
    });
    this.axiosInstance.interceptors.request.use(async (config: any) => {
      config.timeout = 30000;
      if (
        `${config.url}`.includes('discovery/') ||
        `${config.url}`.includes('service/')
      ) {
        const token = await this.microsoftAuthApi.getAccessToken();
        config.headers = {
          Authorization: `Bearer ${token}`,
          'api-version': '1.0',
          'x-token': 'devx',
          ...config.headers,
        };
        if (`${config.url}`.includes('chat/stream')) {
          config.responseType = 'stream';
          config.headers = {
            Authorization: `Bearer ${token}`,
            'api-version': '1.0',
            'x-token': 'devx',
            ...config.headers,
          }
          config.timeout = 300000;
        }
      } 
      else {
        const backstageIdToken = getCookie('token') || (await this.identityApi.getCredentials()).token;
        config.headers = {
          Authorization: `Bearer ${backstageIdToken}`,
          'api-version': '1.0',
          ...config.headers,
        };
      }
      return config;
    });

    this.axiosInstance.interceptors.response.use(
      response => {
        //TODO handle response block by WAF based of resposne header
        if (response.headers && response.headers["x-xss-protection"] === "1; mode=block") {
          //ignore all get calls from backend
          if (response.config?.method === 'get'){
            return response
          }
          throw new Error("We're sorry for the inconvenience. Our system has identified cross-site scripting in your code snippet, which is preventing us from processing your request. Please remove them and try again.")
        }
        return response;
      },
      async error => {
        const originalConfig = error.config;
        if (error?.response?.status == 401 && !originalConfig._retry) {
          if (
            `${originalConfig.url}`.includes('discovery/') ||
            `${originalConfig.url}`.includes('service/')
          ) {
            const token = await this.microsoftAuthApi.getAccessToken();
            originalConfig.headers = {
              'api-version': '1.0',
              ...originalConfig.headers,
              Authorization: `Bearer ${token}`,
            };
          } else {
            const accessTokenResponse = await this.identityApi.getCredentials();
            originalConfig.headers = {
              'api-version': '1.0',
              ...originalConfig.headers,
              Authorization: `Bearer ${accessTokenResponse.token}`,
            };
          }

          originalConfig._retry = true;
          return this.axiosInstance?.request(originalConfig);
        }
        return Promise.reject(error);
      },
    );

    return this.axiosInstance;
  }

  getInstance() {
    return this.init();
  }
}
