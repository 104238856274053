/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

export const useGetPocComponent = () => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const catalogApi = useApi(catalogApiRef);

  const getPoc = (data: any) => {
    if (catalogApi) {
      try {
        const response = catalogApi.getEntityByRef({
          kind: 'Component',
          name: data,
          namespace: 'default',
        });
        return response;
      } catch (error) {
        notificationApi.sendNotification({
          message: `Error occurred - ${error?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };

  return { getPoc };
};
