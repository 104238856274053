import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    display: 'flex',
    gap: `${theme.spacing(2.5)}px`,
    '&:focus': {
      //     backgroundColor: theme.palette.primary.main,
      // '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      //   color: theme.palette.common.white,
      // },
    },
  },
}))(MenuItem);

const useStyles = makeStyles({
  flex: {
    display: 'flex',
    gap: '1px',
  },
  mainButton: {
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    textTransform: 'none',
  },
  dropDownButton: {
    minWidth: '42px',
    padding: '0px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
  },
});

interface ButtonDropdownProps {
  dropdownItems: any;
  mainButton: any;
}

export default function CustomizedMenus({
  mainButton,
  dropdownItems,
}: ButtonDropdownProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.flex}>
      <Button
        variant="contained"
        color="primary"
        className={classes.mainButton}
        onClick={() => {
          mainButton.onClick();
        }}
      >
        {mainButton.icon}
        {mainButton.text}
      </Button>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
        className={classes.dropDownButton}
      >
        <ArrowDropDownIcon fontSize="medium" />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {dropdownItems.map((item: any) => (
          <StyledMenuItem
            onClick={() => {
              item.onClick();
              handleClose();
            }}
          >
            <ListItemIcon
              style={{
                minWidth: '0px',
                ...(item.color && { color: item.color }),
              }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText
              primary={item.text}
              style={item.color && { color: item.color }}
            />
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}
