import React, { useState, useEffect } from 'react';
import {
  EntityKindPicker,
  EntityListProvider,
  CatalogFilterLayout,
} from '@backstage/plugin-catalog-react';
import {
  Button,
  LinearProgress,
  ListItem,
  Button as MaterialButton,
  Typography,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Alert, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import RateReviewIcon from '@material-ui/icons/RateReview';
import UpdateIcon from '@material-ui/icons/Update';
import { PageWithHeader, Content } from '@backstage/core-components';
import { useNavigate } from 'react-router-dom';
import { UserListPicker } from '../filters/UserListPicker';
import { ApiTable } from './ApiCustomTable';
import { EntityTagPicker } from '../utils/EntityTagPicker';
import { HelpComponent } from '../utils/helpComponent';
import HELP_URL from '../utils/helpLinkConstant';
import BackButton from '../utils/backButton';
import { ApiEntityTypePicker } from '../common/ApiEntityTypePicker';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';
import { ApiEntityServicePicker } from '../common/ApiEntityServicePicker';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sortButton: {
      backgroundColor: theme.palette.background.paper,
      borderLeftColor: theme.palette.primary.main,
      '& li': {
        borderLeftColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.border}`,
        '&:hover button': {
          color: theme.palette.primary,
          backgroundColor: `${theme.palette.background.tertiary}`,
        },
        '&.active button': {
          borderLeft: `2px solid ${theme.palette.primary.main}`,
          backgroundColor: `${theme.palette.background.tertiary}`,
        },
      },
    },
    registerbtn: {
      float: 'right',
      ['@media (max-width:920px) and (min-width: 280px)']: {
        width: '100%',
        marginTop: '10px',
      },
    },
    toggleGroup: {
      padding: '5.5px 0px',
    },
    cardToggleButton: {
      padding: '0px 5px',
    },
    tableToggleButton: {
      padding: '0px 5px',
    },
    alert: {
      width: '100%',
      margin: `${theme.spacing(1)}px`,
      marginLeft: '0px',
      '& svg': {
        marginRight: '5px'
      }
    }
  }),
);

const initialState = {
  isTopRatingFilterClick: false,
  isRecentlyPublishedFilterClick: false,
};

export const ApiPage = () => {
  const [view, setView] = useState<any>('');
  const classes = useStyles();
  const navigate = useNavigate();
  const [sort, setSort] = useState(initialState);
  const [loading, setLoading] = useState<boolean>(true);
  localStorage.setItem('isReview', 'false');

  useEffect(() => {
    const storedView = sessionStorage.getItem('assets-view');
    if (view) {
      sessionStorage.setItem('assets-view', view);
    } else {
      const newView =
        storedView === 'table' || storedView === 'card' ? storedView : 'card';
      setView(newView);
    }
  }, [view]);

  const navigateToRegisterAPIPage = () => {
    navigate('/API/register-api');
  };

  const [assetStore, setAssetStore] = useState('all');
  const onChangeAssetStore = (values: any) => {
    setAssetStore(
      values && Array.isArray(values) && values?.length > 0
        ? values?.[0]
        : 'all',
    );
  };

  const handleOnClickHighlyRated = () => {
    setSort({
      ...initialState,
      isTopRatingFilterClick: !sort.isTopRatingFilterClick,
    });
  };

  const handleOnClickRecentlyPublished = () => {
    setSort({
      ...initialState,
      isRecentlyPublishedFilterClick: !sort.isRecentlyPublishedFilterClick,
    });
  };

  return (
    <PageWithHeader
      title="APIs"
      themeId="home"
      subtitle={
        <DevxBreadCrumb
          routes={[
            {
              type: 'link',
              link: '/',
              text: 'Home',
            },
            {
              type: 'text',
              link: '/API',
              text: 'APIs',
            },
          ]}
        />
      }
    >
      <Content>
        {loading && <LinearProgress />}
        <div className="codePage apiPage">
          <Grid container direction="row">
            <Grid item xs={12} md={12}>
              <BackButton />

              <div className="ToggleButtonGroup">
                <ToggleButtonGroup
                  value={view}
                  exclusive={false}
                  onChange={v => {
                    setView(view === 'table' ? 'card' : 'table');
                  }}
                  className={classes.toggleGroup}
                >
                  <ToggleButton
                    value="table"
                    className={classes.tableToggleButton}
                    color="primary"
                  >
                    Table
                  </ToggleButton>
                  <ToggleButton
                    value="card"
                    className={classes.cardToggleButton}
                    color="primary"
                  >
                    Card
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>

              <HelpComponent helpUrl={HELP_URL.APIs} />
              <Button
                onClick={navigateToRegisterAPIPage}
                variant="contained"
                color="primary"
                className={classes.registerbtn}
              >
                REGISTER API
              </Button>
            </Grid>
            {/* <Alert severity="info" className={classes.alert}>
              We are currently facing an issue with the WSo2 sync and the resuable API list is not populating fully. Sorry for the inconvenience.
            </Alert> */}
            <Grid item xs={12} md={12}>
              <EntityListProvider>
                <CatalogFilterLayout>
                  <CatalogFilterLayout.Filters>
                    <EntityKindPicker initialFilter="api" hidden />
                    <ApiEntityTypePicker
                      onChangeAssetStore={onChangeAssetStore}
                    />
                    <UserListPicker
                      initialFilter={'all'}
                      parentName="api"
                      addtionalFilter={{ assetStore: assetStore }}
                    />
                    {view !== 'table' && (
                      <>
                        <Typography variant="button">Sort By</Typography>
                        <div className={`customFilters ${classes.sortButton}`}>
                          <ListItem
                            className={
                              sort.isTopRatingFilterClick ? 'active' : ''
                            }
                          >
                            <MaterialButton
                              variant="outlined"
                              name="Rating"
                              onClick={() => handleOnClickHighlyRated()}
                              startIcon={<RateReviewIcon />}
                            >
                              Ratings
                            </MaterialButton>
                          </ListItem>
                          <ListItem
                            className={
                              sort.isRecentlyPublishedFilterClick
                                ? 'active'
                                : ''
                            }
                          >
                            <MaterialButton
                              variant="outlined"
                              name="Recent"
                              onClick={() => handleOnClickRecentlyPublished()}
                              startIcon={<UpdateIcon />}
                            >
                              Recently Published
                            </MaterialButton>
                          </ListItem>
                        </div>
                      </>
                    )}
                    <EntityTagPicker />
                    <ApiEntityServicePicker />
                  </CatalogFilterLayout.Filters>
                  <CatalogFilterLayout.Content>
                    <ApiTable
                      view={view}
                      setLoading={setLoading}
                      assetStore={assetStore}
                      sort={sort}
                    />
                  </CatalogFilterLayout.Content>
                </CatalogFilterLayout>
              </EntityListProvider>
            </Grid>
          </Grid>
        </div>
      </Content>
    </PageWithHeader>
  );
};
