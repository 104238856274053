import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';

interface SnykDialogTableProps {
  vulnerabilities: any[];
}

const SnykDialogTable: React.FC<SnykDialogTableProps> = ({ vulnerabilities }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Key</TableCell>
            <TableCell>Severity</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {vulnerabilities.map((vulnerability) => (
            <TableRow key={vulnerability.id}>
              <TableCell>{vulnerability.attributes.key}</TableCell>
              <TableCell>{vulnerability.attributes.effective_severity_level}</TableCell>
              <TableCell>{vulnerability.attributes.title}</TableCell>
              <TableCell>{vulnerability.attributes.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SnykDialogTable;