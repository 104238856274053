import React, { useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useStyles } from './addReviewerStyle';
import TextField from '@material-ui/core/TextField';
import { adminApiRef } from '../../../apis/adminApi';
import { IconButton, Tooltip } from '@material-ui/core';

export const AddReviewerComponent = (props: any) => {
  const [selectedReviwer, setSelectedReviwer] = useState(
    (props.entity?.metadata?.reviewer && props.entity?.metadata?.reviewer[0]) ||
      'Unassigned',
  );
  const [isDisabled, setDisabled] = useState(true);

  const reviewerList = props?.reviewerList || [];

  const classes = useStyles();
  const adminApi = useApi(adminApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const onChangeReviwerList = (event: any, value: any) => {
    event?.preventDefault();
    setSelectedReviwer(value?.user || 'Unassigned');
    setDisabled(value?.user ? false : true);
  };

  const onClickSubmit = async () => {
    const payload = {
      repodetails: [
        {
          kind: props?.entity?.kind,
          metadata: {
            name: props.entity?.metadata?.name,
            reviewer: [selectedReviwer],
          },
        },
      ],
      action: 'add_reviewer_list',
    };

    try {
      await adminApi.addReviewerInAssets(payload);
      notificationApi.sendNotification({
        message: 'Reviewer is assigned successfully ',
        disapperAfterMs: 2500,
        severity: 'success',
      });
    } catch (error) {
      notificationApi.sendNotification({
        message: 'You cannot assigned Reviewer, please validate and resubmit.',
        disapperAfterMs: 2500,
        severity: 'error',
      });
    }
    setDisabled(true);
  };

  return (
    <div className={classes.reviewerMainContainer}>
      <Autocomplete
        freeSolo
        className={classes.reviewerContainer}
        options={
          (reviewerList &&
            reviewerList?.filter(
              (item: any) => item.kindType === props?.entity?.kind,
            )) || [{ user: '' }]
        }
        value={{ user: selectedReviwer } || 'Unassigned'}
        onChange={(event: any, value) => onChangeReviwerList(event, value)}
        getOptionLabel={item => item.user}
        getOptionDisabled={option =>
          option?.user === selectedReviwer ||
          option?.user?.split('@')[0] ===
            props?.entity?.spec?.owner?.replace('user:', '')
        }
        renderInput={params => (
          <TextField
            {...params}
            label="Reviewer"
            fullWidth
            variant="outlined"
            className={classes.addReviewerTextbox}
          />
        )}
      />
      <Tooltip title="Submit">
        <IconButton
          className={
            isDisabled ? classes.submituttonDisabled : classes.submitutton
          }
          disabled={isDisabled}
          onClick={onClickSubmit}
        >
          <CheckBoxIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};
