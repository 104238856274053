import {
  Box,
  FormControl,
  TextField,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  Button,
  Card,
  CardHeader,
  CardContent,
  InputAdornment,
  Typography,
  List,
  ListItem,
  ListItemText,
  Theme,
  useTheme,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import React, { useCallback, useEffect, useState } from 'react';
import { Tool } from '@drodil/backstage-plugin-toolbox';
import Mark from 'mark.js';
import {
  DELIMITER_OPTIONS,
  FLAGS,
  IRegExFlagsOptions,
  MatchResultPart,
  debounce,
} from './constant';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    delimiter: {
      marginLeft: '8px',
    },
    highlightText: {
      borderRadius: theme.shape.borderRadius,
      margin: 0,
      marginTop: '-11px',
      padding: 0,
      border: `1px solid ${theme.palette.border}`,
    },
    text: {
      marginLeft: '8px',
      marginRight: '8px',
      paddingRight: '8px',
    },
    regexPlayground: {
      height: '405px',
      padding: '8px',
      outline: '0px solid transparent',
    },
    regexCard: {
      height: '560px',
    },
    regexBox: {
      minHeight: '350px',
    },
    regexList: {
      overflowY: 'auto',
      height: '480px',
    },
    flags: {
      display: 'flex',
    },
  }),
);

const RegExPlaygroundTool = () => {
  const classes = useStyles();
  const [delimiter, setDelimiter] = useState<string>(DELIMITER_OPTIONS[0]);
  const [selectedFlags, setSelectedFlags] = useState<string[]>(['g', 'm']);
  const [patternText, setPatternText] = useState<string>('');
  const [textToMatch, setTextToMatch] = useState<string>('');
  const [matchInfo, setMatchInfo] = useState<MatchResultPart[]>([]);
  const [error, setError] = useState<any>(undefined);
  const [matchInfoToHighlightIndex, setMatchInfoToHighlightIndex] =
    useState<number>(-1);

  const [showHighlightText, setShowhighLightText] = useState<boolean>(false);

  const markInstance: Mark = new Mark(
    document.getElementById('regex-playground-input') || '',
  );

  const markRange = async ({
    startIndex,
    length,
    matchInfoIndex,
  }: {
    startIndex: number;
    length: number;
    matchInfoIndex: number;
  }) => {
    setShowhighLightText(true);
    setMatchInfoToHighlightIndex(matchInfoIndex);

    await markInstance.unmark({
      done: () => {
        markInstance.markRanges([{ start: startIndex, length }]);
      },
    });
  };

  const operation = ({
    textToMatch,
    patternText,
    selectedFlags,
  }: {
    patternText: string;
    selectedFlags: string[];
    textToMatch: string;
  }) => {
    let reg = undefined;
    try {
      reg = new RegExp(patternText, selectedFlags?.join(''));
    } catch (e) {
      setError(e);
    }
    if (reg) {
      setError(undefined);
    }
    if (
      reg &&
      textToMatch &&
      textToMatch.length > 0 &&
      patternText &&
      patternText.length > 0
    ) {
      // regex passed parse operation
      let arr;
      let lastIndex = 0;
      const matchArr: MatchResultPart[] = [];

      while ((arr = reg.exec(textToMatch)) !== null) {
        matchArr.push({ str: arr?.[0], index: arr.index, raw: arr });

        // this following check is required because if the global flag is not selected
        // this while loop becomes infinite loop because the lastIndex does not change
        if (reg.lastIndex === lastIndex) {
          break;
        }

        lastIndex = reg.lastIndex;
      }
      setMatchInfo(matchArr);
      setShowhighLightText(false);
      setMatchInfoToHighlightIndex(-1);
    } else {
      setMatchInfo([]);
      setShowhighLightText(false);
      setMatchInfoToHighlightIndex(-1);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCallback = useCallback(
    debounce(
      ({
        patternText,
        selectedFlags,
        textToMatch,
      }: {
        patternText: string;
        selectedFlags: string[];
        textToMatch: string;
      }) => {
        operation({ patternText, selectedFlags, textToMatch });
      },
      500,
    ),
    [],
  );

  useEffect(() => {
    debounceCallback({ patternText, selectedFlags, textToMatch });
  }, [patternText, selectedFlags, textToMatch]);

  const onClickClear = () => {
    setTextToMatch('');
    setPatternText('');
    setSelectedFlags(['g', 'm']);
    setDelimiter('/');
    setError(undefined);
    setMatchInfo([]);
    setShowhighLightText(false);
    setMatchInfoToHighlightIndex(-1);
    markInstance.unmark({
      done: () => {},
    });
  };

  const theme: Theme = useTheme();

  return (
    <Box>
      <Box sx={{ mb: 1 }}>
        <Button size="small" startIcon={<ClearIcon />} onClick={onClickClear}>
          Clear
        </Button>
      </Box>
      <Grid container spacing={1}>
        <Grid item lg={9} xs={12}>
          <Box>
            <TextField
              label=" Regular Expression"
              fullWidth
              variant="outlined"
              placeholder="Insert your regular expression here"
              value={patternText}
              onChange={event => setPatternText(event?.target?.value)}
              error={error}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{delimiter}</InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {delimiter} {selectedFlags?.join('')}
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ mt: 2 }} className={classes.flags}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="regex-flag-select-label">
                  Modifier/Flags (Multi Select)
                </InputLabel>
                <Select
                  labelId="regex-flag-select-label"
                  id="regex-flag-select"
                  label="Modifier/Flags (Multi Select)"
                  multiple
                  value={selectedFlags}
                  onChange={event => {
                    const newSelection = event?.target?.value;
                    if (
                      !newSelection ||
                      (Array.isArray(newSelection) && newSelection.length === 0)
                    ) {
                      setSelectedFlags(['g', 'm']);
                    } else {
                      setSelectedFlags(event?.target?.value);
                    }
                  }}
                  renderValue={selected => selected?.join(', ')}
                >
                  {FLAGS &&
                    FLAGS.map((f: IRegExFlagsOptions) => (
                      <MenuItem
                        key={f.value}
                        value={f.value}
                      >{`${f.name} - ${f.description}`}</MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl
                className={classes.delimiter}
                fullWidth
                variant="outlined"
              >
                <InputLabel id="regex-delimiter-select-label">
                  Delimiter
                </InputLabel>
                <Select
                  labelId="regex-delimiter-select-label"
                  id="regex-delimiter-select"
                  label="Delimiter"
                  value={delimiter}
                  onChange={event =>
                    setDelimiter(`${event.target.value}` || '/')
                  }
                >
                  {DELIMITER_OPTIONS &&
                    DELIMITER_OPTIONS.map((d: string) => (
                      <MenuItem key={d} value={d}>{`${d}`}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ mt: 2 }}>
              <fieldset
                hidden={!showHighlightText}
                className={classes.highlightText}
              >
                <legend className={classes.text}>
                  <Typography variant="caption">Text</Typography>
                </legend>

                <div
                  className={classes.regexPlayground}
                  id="regex-playground-input"
                  hidden={!showHighlightText}
                  onInput={() => {
                    setShowhighLightText(false);
                    setMatchInfoToHighlightIndex(0);
                  }}
                  dangerouslySetInnerHTML={{ __html: textToMatch }}
                  contentEditable
                ></div>
              </fieldset>

              {!showHighlightText && (
                <TextField
                  label="Text"
                  placeholder="Insert your test strings here"
                  multiline
                  minRows={20}
                  fullWidth
                  variant="outlined"
                  value={textToMatch}
                  onChange={event => {
                    setTextToMatch(event?.target?.value || '');
                    setShowhighLightText(false);
                  }}
                />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item lg={3} xs={12}>
          <Box sx={{ mt: 0 }} className={classes.regexBox}>
            <Card className={classes.regexCard} variant="outlined">
              <CardHeader title={'Match Information'}></CardHeader>
              <CardContent>
                {!matchInfo ||
                  (matchInfo && matchInfo.length === 0 && (
                    <Typography>
                      Detailed information will be displayed here automatically
                    </Typography>
                  ))}
                {matchInfo && matchInfo?.length > 0 && (
                  <List className={classes.regexList} dense>
                    {matchInfo?.map((m: MatchResultPart, index: number) => (
                      <ListItem
                        style={{ cursor: 'pointer' }}
                        key={`${m.str}-${m.index}-${index}`}
                        onClick={async () => {
                          markRange({
                            startIndex: m.raw?.index,
                            length: m.raw?.[0]?.length,
                            matchInfoIndex: index,
                          });
                        }}
                        selected={matchInfoToHighlightIndex === index}
                      >
                        <ListItemText
                          title={` ${m.raw?.index}-${
                            m.raw?.index + m.raw?.[0]?.length
                          } | ${m.str}                              
                            `}
                        >
                          Match {index + 1}
                          <> | </>
                          {m.raw?.index}-{m.raw?.index + m.raw?.[0]?.length}
                          <> | </> {m.str}
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                )}
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export const regexPlaygroundTool: Tool = {
  id: 'regex-playground',
  name: 'Playground',
  component: <RegExPlaygroundTool />,
  category: 'Regular Expressions',
  description: 'Create and test regular expressions',
  headerButtons: [],
};

export default regexPlaygroundTool;
