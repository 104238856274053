import React, { useEffect, useState } from 'react';
import {
    createStyles,
    IconButton,
    makeStyles,
    Theme
} from '@material-ui/core';

import { CarouselItem } from './CarouselItem';
import { CarouselProps } from './types';
import { Indicators } from './Indicators';
import { sanitizeProps, useInterval } from '.';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonWrapper: {
            position: "absolute",
            height: "100px",
            backgroundColor: "transparent",
            zIndex: '1',
            top: "calc(50% - 70px)",
            '&:hover': {
                '& button': {
                    backgroundColor: "black",
                    filter: "brightness(120%)",
                    opacity: "0.4"
                }
            },
        },
        fullHeightHover: {
            height: "100%",
            top: "0"
        },
        next: {
            right: '0'
        },
        prev: {
            left: '0'
        },
        iconButton: {
            margin: "0 10px",
            position: "relative",
            backgroundColor: "#494949",
            top: "calc(100% - 50px) !important",
            color: "white",
            fontSize: "30px",
            transition: "200ms",
            cursor: "pointer",
            opacity: '1',
            '&:hover': {
                opacity: "0.6 !important",
            },
        },
        itemWrapper: {
            position: 'relative',
            width: '100%',
            height: '100%',
        },
        root: {
            position: "relative",
            overflow: "hidden",
        }
    })
);

export const Carousel = (props: CarouselProps) => {

    const [state, setState] = useState({
        active: 0,
        prevActive: 0,
        next: true
    });
    const classes = useStyles();

    /** Used to set carousel's height. It is being set by the CarouselItems */
    const [childrenHeight, setChildrenHeight] = useState<number>();
    const [paused, setPaused] = useState<boolean>(false);
    
    const sanitizedProps = sanitizeProps(props);

    // componentDidMount & onIndexChange
    useEffect(() => {
        const { index, changeOnFirstRender } = sanitizedProps;
        setNext(index, true, changeOnFirstRender);
    }, [sanitizedProps.index])


    useInterval(() => {
        const { autoPlay } = sanitizedProps;

        if (autoPlay && !paused)
        {
            next(undefined);
        }

    }, sanitizedProps.interval)



    const next = (event: any) => {
        const { children, cycleNavigation } = sanitizedProps;

        let last = Array.isArray(children) ? children.length - 1 : 0;
        const nextActive = state.active + 1 > last ? (cycleNavigation ? 0 : state.active) : state.active + 1;

        setNext(nextActive, true)

        if (event)
            event.stopPropagation();
    }

    const prev = (event: any) => {
        const { children, cycleNavigation } = sanitizedProps;

        let last = Array.isArray(children) ? children.length - 1 : 0;
        const nextActive = state.active - 1 < 0 ? (cycleNavigation ? last : state.active) : state.active - 1;

        setNext(nextActive, false)

        if (event)
            event.stopPropagation();
    }

    const setNext = (index: number, isNext: boolean, runCallbacks: boolean = true) =>
    {
        const { onChange, children, strictIndexing } = sanitizedProps;

        if (Array.isArray(children))
        {
            if (strictIndexing && index > children.length - 1) index = children.length - 1;
            if (strictIndexing && index < 0) index = 0;
        }
        else
        {
            index = 0;
        }

        if (runCallbacks)
        {
            if (isNext !== undefined)
                isNext ? sanitizedProps.next(index, state.active) : sanitizedProps.prev(index, state.active);

            onChange(index, state.active);
        }

        if (isNext === undefined)
        {
            isNext = index > state.active
        }

        setState({
            active: index,
            prevActive: state.active,
            next: isNext
        })
    }

    const {
        children,
        sx,
        className,

        height,

        stopAutoPlayOnHover,
        animation,
        duration,
        swipe,

        navButtonsAlwaysInvisible,
        navButtonsAlwaysVisible,
        cycleNavigation,
        fullHeightHover,
        navButtonsProps,
        navButtonsWrapperProps,
        NavButton,

        NextIcon,
        PrevIcon,

        indicators,
        indicatorContainerProps,
        indicatorIconButtonProps,
        activeIndicatorIconButtonProps,
        IndicatorIcon,
    } = sanitizedProps;

    const showButton = (next = true) =>
    {
        if (cycleNavigation) return true;

        const last = Array.isArray(children) ? children.length - 1 : 0;

        if (next && state.active === last) return false;
        if (!next && state.active === 0) return false;

        return true;
    }   

    return (
        <div
            className={`${className} ${classes.root}`}
            // sx={sx}
            onMouseOver={() => { stopAutoPlayOnHover && setPaused(true) }}
            onMouseOut={() => { stopAutoPlayOnHover && setPaused(false) }}
            onFocus={()=>{stopAutoPlayOnHover && setPaused(true)}}
            onBlur={()=>{stopAutoPlayOnHover && setPaused(false)}}
        >
            <div className={classes.itemWrapper} style={{ height: height ? height : childrenHeight }}>
                {
                    Array.isArray(children) ?
                        children.map((child, index) =>
                        {
                            return (
                                <CarouselItem
                                    key={`carousel-item${index}`}
                                    state={state}
                                    index={index}
                                    maxIndex={children.length - 1}
                                    child={child}
                                    animation={animation}
                                    duration={duration}
                                    swipe={swipe}
                                    next={next}
                                    prev={prev}
                                    height={height}
                                    setHeight={setChildrenHeight}
                                />
                            )
                        })
                        :
                        <CarouselItem
                            key={`carousel-item0`}
                            state={state}
                            index={0}
                            maxIndex={0}
                            child={children}
                            animation={animation}
                            duration={duration}
                            height={height}
                            setHeight={setChildrenHeight}
                        />
                }
            </div>


            {!navButtonsAlwaysInvisible && showButton(true) &&
                <div {...{
                    ...navButtonsWrapperProps,
                    className: `${navButtonsWrapperProps.className} ${classes.buttonWrapper} ${classes.next} ${fullHeightHover ? classes.fullHeightHover : ''}`
                }}>
                    {NavButton !== undefined ?
                        NavButton({ onClick: next, next: true, prev: false, ...navButtonsProps })
                        :
                        <IconButton
                            onClick={next}
                            aria-label="Next"
                            {...navButtonsProps}
                            className={classes.iconButton}
                        >
                            {NextIcon}
                        </IconButton>
                    }
                </div>
            }

            {!navButtonsAlwaysInvisible && showButton(false) &&
                <div {...{
                    ...navButtonsWrapperProps,
                    className: `${navButtonsWrapperProps.className} ${classes.buttonWrapper} ${classes.prev} ${fullHeightHover ? classes.fullHeightHover : ''}`
                }}>
                    {NavButton !== undefined ?
                        NavButton({ onClick: prev, next: false, prev: true, ...navButtonsProps })
                        :
                        <IconButton
                            onClick={prev}
                            aria-label="Previous"
                            {...navButtonsProps}
                            className={classes.iconButton}
                        >
                            {PrevIcon}
                        </IconButton>
                    }
                </div>
            }

            {
                indicators ?
                    <Indicators
                        length={Array.isArray(children) ? children.length : 0}
                        active={state.active}
                        press={setNext}
                        indicatorContainerProps={indicatorContainerProps}
                        indicatorIconButtonProps={indicatorIconButtonProps}
                        activeIndicatorIconButtonProps={activeIndicatorIconButtonProps}
                        IndicatorIcon={IndicatorIcon}
                    /> : null
            }
        </div>
    )
}

export default Carousel;