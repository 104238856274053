import {
  CircularProgress,
  TextField,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useCallback, useEffect, useState } from 'react';
import { adminApiRef } from '../../../../apis/adminApi';
import { useApi } from '@backstage/core-plugin-api';
import Search from '@material-ui/icons/Search';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    autoComplete: {
      flexGrow: 1,
    },
    searchIcon: {
      '& button[class*="MuiAutocomplete-popupIndicator"]': {
        transform: 'none',
        margin: `0px ${theme.spacing(1)}px`,
      },
    },
  }),
);

const UserList = ({
  onChange,
  clear,
}: {
  onChange: Function;
  clear: number;
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [usersLoading, setUsersLoading] = useState<boolean>(false);

  const [userList, setuserList] = useState<any>([]);

  const adminApi = useApi(adminApiRef);

  const getuserList = async (user: any) => {
    try {
      setUsersLoading(true);

      const data = await adminApi.getUserList(user);
      const options = data?.value || [];
      const transFormList =
        options &&
        options.length &&
        options.map((item: any) => {
          return { user: item?.mail };
        });

      setuserList(transFormList || []);
      setUsersLoading(false);
      return data;
    } catch (error) {
      setuserList([]);
      setUsersLoading(false);

      return error;
    }
  };

  const debounce = (fn: any, delay: any) => {
    let timeoutId: any;
    // @ts-expect-error
    return function (...args) {
      clearTimeout(timeoutId);
      // @ts-expect-error
      timeoutId = setTimeout(() => fn.apply(this, args), delay);
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCallback = useCallback(
    debounce((value: any) => {
      getuserList(value);
    }, 1000),
    [],
  );

  const onInputChange = async (e: any) => {
    if (e?.target?.value?.length > 2) {
      debounceCallback(e.target.value);
    }
  };
  const [userValue, setUserValue] = useState(null);
  const handleChange = (e: any, value: any) => {
    setUserValue(value);
    onChange(value);
  };

  useEffect(() => {
    if (clear) {
      setUserValue(null);
      onChange(null);
    }
  }, [clear]);

  return (
    <Autocomplete
      classes={{ root: classes.searchIcon }}
      className={classes.autoComplete}
      id="cbre-user-list"
      data-testid="cbre-user-list"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      key="cbre-user-list"
      getOptionSelected={(option, value) => option?.user === value?.user}
      getOptionLabel={option => (option && option?.user) || ''}
      options={userList}
      loading={usersLoading}
      popupIcon={!userValue && <Search />}
      onInputChange={e => onInputChange(e)}
      filterOptions={options => options}
      onChange={(e: any, value: any) => handleChange(e, value)}
      value={userValue}
      renderInput={params => (
        <TextField
          {...params}
          label="Search user"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {usersLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default UserList;
