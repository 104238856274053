import React, { useEffect, useRef, useState } from 'react';
import {
  makeStyles,
  createStyles,
  Grid,
  Link as MatLink,
  Button,
  Typography,
  Box,
  LinearProgress,
  Theme,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { Content, Header, Page } from '@backstage/core-components';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';
import { HelpComponent } from '../utils/helpComponent';
import BackButton from '../utils/backButton';
import HELP_URL from '../utils/helpLinkConstant';
import { useParams } from 'react-router-dom';
import { useApi } from '@backstage/core-plugin-api';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import { toolboxApiRef, ToolboxApi } from '../../apis/toolboxApi';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';
import UserAvatar from '../common/UserAvatar';
import { userProfileApiRef } from '../../apis/userProfileApi';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginTop: {
      marginTop: '10px',
      marginBottom: '120px',
    },
    toolsHeader: {
      marginTop: '16px',
      marginLeft: '-24px',
      width: 'auto',
      marginRight: '-24px',
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
        marginLeft: '-16px',
        marginRight: '-16px',
      },
    },
    categoryText: {
      fontSize: '16px',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      color: '#8585E0',
      textAlign: 'center',
      fontStyle: 'normal',
      fontWeight: 'bold',
      lineHeight: '16px',
      textTransform: 'capitalize',
      marginTop: '10px',
      marginBottom: '25px',
    },
    bannerImg: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
      },
    },
    imgTitle: {
      marginTop: '10px',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '34px',
      lineHeight: '40px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      letterSpacing: '-0.01em',
      color: theme.palette.text.secondary,
      textTransform: 'capitalize',
    },
    categories: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '24px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      letterSpacing: '-0.015em',
      color: 'rgba(26, 26, 26, 0.6)',
    },
    support: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: '30px',
    },
    contactArea: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
    },
    productTitle: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      letterSpacing: '-0.015em',
      color: theme.palette.text.secondary,
      marginBottom: '15px',
      textTransform: 'capitalize',
    },
    productInfo: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.tertiary,
      //textTransform: 'capitalize',
    },
    capitalizeText: { textTransform: 'capitalize', fontWeight: 'bold' },
    normalText: {
      textTransform: 'capitalize',
      padding: '5px 2px',
      fontWeight: 'normal',
      textDecoration: 'underline',
      color: '#17E88F',
    },
    links: {
      color: '#17E88F',
    },
    details: {
      color: theme.palette.text.tertiary,
      wordBreak: 'break-all',
    },
    supportLinks: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: 'rgba(26, 26, 26, 0.6)',
      gap: '5px',
      padding: '3px',
    },
    margin: {
      marginTop: '20px',
    },
    logoImg: {
      width: '90px',
      height: '90px',
      // maxWidth: '100px',
      // maxHeight: '90px',
      marginTop: '25px',
    },
    bigImgBanner: {
      textAlign: 'center',
      padding: '0px !important',
      background: '#01011e',
      height: '232px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '10px',
      },
      '& img': {
        maxWidth: '100%',
        height: 'inherit',
        [theme.breakpoints.down('sm')]: {
          maxHeight: 'auto',
        },
      },
    },
    detailsBtns: {
      // display: 'flex',
      // gap: '12px',
    },
    btns: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      height: '36px',
      padding: '12px 16px',
      textTransform: 'capitalize',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      margin: '12px 8px',
    },
    leftbg: {
      backgroundColor: theme.palette.background.tertiary,
    },
  }),
);

const ContactComponent = ({
  email,
  displayname,
}: {
  email: string;
  displayname: string;
}) => {
  const usernameRef = useRef(null);
  const userProfileApi = useApi(userProfileApiRef);

  const onMouseEnter = () => {
    userProfileApi.setUserProfileState({
      email,
      anchorRef: usernameRef?.current,
    });
  };

  const getDisplayName = (displayName: string) => {
    if (!displayName) return email;

    const name = displayName && displayName?.split('@')[0];
    const userName = name && name?.split(',');
    if (userName && userName?.length && userName[1] && userName[0]) {
      return userName[1] + ' ' + userName[0];
    } else {
      return email;
    }
  };

  return (
    <>
      {' '}
      <UserAvatar
        userEmail={email}
        customStyles={{
          width: '32px',
          height: '32px',
          fontSize: '14px',
          fontWeight: 'normal',
          lineHeight: '16px',
          marginLeft: 'auto',
          marginRight: '10px',
          marginBottom: '10px',
          marginTop: '10px',
        }}
      />
      <Typography onMouseEnter={onMouseEnter} ref={usernameRef}>
        {(displayname && getDisplayName(displayname)) || email}
      </Typography>{' '}
    </>
  );
};

const ToolboxDetailsPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [toolsData, setToolsData] = useState<any>(undefined);
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const { nodeId } = useParams();

  const toolboxApi: ToolboxApi = useApi(toolboxApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const [imageData, setImageData] = useState<string | undefined>();
  const [imageMeta, setImageMeta] = useState<string | undefined>(
    toolsData?.imageMetaForBase64,
  );

  const [bgImageData, setBgImageData] = useState<string | undefined>();
  const [bgImageMeta, setBgImageMeta] = useState<string | undefined>(
    toolsData?.imageMetaForBase64,
  );

  const metaMap = {
    png: 'data:image/png;base64,',
    svg: 'data:image/svg+xml;base64,',
  };

  const getDefaultMetadata = (tool: any) => {
    let imageKey = tool?.imagekey;
    if (imageKey !== undefined) {
      const ext = String(imageKey).substring(
        String(imageKey)?.lastIndexOf('.') + 1,
      );
      // @ts-ignore
      return metaMap[ext];
    }
    return 'data:image/svg+xml;base64,';
  };

  const getLogoImage = (tool: any) => {
    console.log('imageMeta', tool);
    if (tool.imageMetaForBase64 === undefined) {
      setImageMeta(getDefaultMetadata(tool));
    } else {
      setImageMeta(String(tool.imageMetaForBase64).replace(',', '') + ',');
    }

    if (tool?.imagekey) {
      toolboxApi
        .getImageData(tool?.imagekey)
        .then((data: any) => {
          setImageData(data);
        })
        .catch((error: any) => {
          notificationApi.sendNotification({
            severity: 'error',
            disapperAfterMs: 2000,
            message: `Failed to load image for this tool`,
          });
        });
    }
  };

  const getBgImage = (tool: any) => {
    if (tool?.bgimagekey) {
      toolboxApi
        .getImageData(tool?.bgimagekey)
        .then((data: any) => {
          setBgImageData(data);
        })
        .catch((error: any) => {
          notificationApi.sendNotification({
            severity: 'error',
            disapperAfterMs: 2000,
            message: `Failed to load image for this tool`,
          });
        });
      if (tool.bgimageMetaForBase64 === undefined) {
        setBgImageMeta(getDefaultMetadata(tool));
      } else {
        setBgImageMeta(
          String(tool.bgimageMetaForBase64).replace(',', '') + ',',
        );
      }
    }
  };

  const getToolsById = () => {
    setLoading(true);
    toolboxApi
      .getToolById(nodeId?.toString())
      .then((value: any) => {
        setToolsData(value?.node || []);
        getLogoImage(value?.node || '');
        getBgImage(value?.node || '');
        setLoading(false);
      })
      .catch((error: any) => {
        setToolsData([]);
        setLoading(false);
        notificationApi.sendNotification({
          severity: 'error',
          disapperAfterMs: 2000,
          message: `Error occurred while getting tools details - ${error.message}`,
        });
      });
  };

  useEffect(() => {
    getToolsById();
  }, []);

  useEffect(() => {}, [bgImageData]);

  const LinkDesc = ({ linkVisible, descriptionVisible }: props) => {
    return (
      <>
        {linkVisible ? (
          <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
            <Box className={classes.detailsBtns} gridColumn={'2'}>
              {toolsData?.links?.length &&
                toolsData.links.map(
                  (item: any, key: number) =>
                    item?.displayname && (
                      <Button
                        key={key}
                        color="primary"
                        variant="contained"
                        className={classes.btns}
                        endIcon={<OpenInNewOutlinedIcon />}
                        href={item.link}
                        target={'_blank'}
                      >
                        {item.displayname}
                      </Button>
                    ),
                )}
            </Box>
            <div className={classes.support}>
              <div className={classes.productTitle}>{toolsData?.pagetitle}</div>
              <Grid container spacing={1} alignItems="center">
                {toolsData?.pageinfo?.length > 0 &&
                  toolsData?.pageinfo.map((item: any) => (
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={4}>
                        <div
                          className={`${classes.productInfo} ${classes.capitalizeText}`}
                        >
                          {item?.k}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={8}>
                        <div
                          className={`${classes.productInfo} ${classes.details}`}
                        >
                          {item.type === 'link' && (
                            <MatLink href={`${item?.v}`} target="_blank">
                              <Button
                                color="primary"
                                variant="text"
                                className={`${classes.normalText}`}
                              >
                                {item?.linkText ? item?.linkText : item?.v}
                              </Button>
                            </MatLink>
                          )}

                          {item.type === 'contact' && (
                            <ContactComponent
                              email={'' + item.v}
                              displayname={'' + item?.displayName}
                            />
                          )}
                          {item.type === 'text' && (
                            <Typography>{item?.v}</Typography>
                          )}
                        </div>
                      </Grid>
                    </>
                  ))}
              </Grid>
            </div>
          </Grid>
        ) : null}
        {descriptionVisible ? (
          <Grid item xs={12} sm={12} md={8} lg={8} xl={9}>
            <div
              dangerouslySetInnerHTML={{ __html: toolsData?.detaildescription }}
            />
          </Grid>
        ) : null}
      </>
    );
  };

  return (
    <Page themeId="home">
      <Header
        title={toolsData?.title}
        subtitle={
          <DevxBreadCrumb
            routes={[
              {
                type: 'link',
                link: '/',
                text: 'Home',
              },
              {
                type: 'link',
                link: '/toolbox',
                text: 'Toolbox',
              },
              {
                link: '',
                type: 'text',
                text: `${toolsData?.title}`,
              },
            ]}
          />
        }
      ></Header>

      <Content>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <BackButton />
            <HelpComponent helpUrl={HELP_URL.TOOLBOX_DETAILS} />
          </Grid>
        </Grid>
        {loading && <LinearProgress />}
        <Grid container className={classes.toolsHeader}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={3}
            className={classes.leftbg}
          >
            <div className={classes.bannerImg}>
              {imageData && (
                <img
                  className={classes.logoImg}
                  alt="Tool Logo"
                  src={`${imageMeta}${imageData}`}
                  loading="lazy"
                />
              )}
              <Typography className={classes.imgTitle}>
                {toolsData?.title}
              </Typography>
              {toolsData?.category !== undefined &&
                String(toolsData?.category).length > 0 && (
                  <Typography
                    className={classes.categoryText}
                    data-testid="category-subcategory"
                  >
                    {toolsData?.subcategory
                      ? `${toolsData?.category ?? ''} ${
                          toolsData?.subcategory !== undefined
                            ? '  \u2022  ' + toolsData?.subcategory
                            : ''
                        }` ?? ''
                      : `${toolsData?.category ?? ''}` ?? ''}
                  </Typography>
                )}
            </div>
          </Grid>
          <LinkDesc linkVisible={isMobile}></LinkDesc>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={9}
            className={classes.bigImgBanner}
          >
            <img
              height={'auto'}
              width={'auto'}
              alt="Tool banner"
              src={
                bgImageData
                  ? `${bgImageMeta}${bgImageData}`
                  : '/images/toolsBigImg.png'
              }
              loading="lazy"
            ></img>
          </Grid>
          <LinkDesc descriptionVisible={isMobile}></LinkDesc>
        </Grid>

        <Grid container>
          <LinkDesc
            descriptionVisible={!isMobile}
            linkVisible={!isMobile}
          ></LinkDesc>
        </Grid>
      </Content>
    </Page>
  );
};
export default ToolboxDetailsPage;
