import {
  Backdrop,
  CircularProgress,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';
import {
  InformationExchangeApi,
  informationExchangeApiRef,
  PostVoteRequest,
} from '../../../apis/informationExchange';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { useApi } from '@backstage/core-plugin-api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    votes: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      // '& div:nth-child(2)': {
      //   marginTop: '20px',
      // },
    },
    icons: {
      color: '#1A1A1A',
      padding: '0',
    },
    selectedColor: {
      color: '#50AF78',
    },
    defaultColor: {
      color: 'grey',
    },
  }),
);
const Votes = (props: any) => {
  const {
    value = 0,
    loggedInVote = 0,
    actiontype,
    id,
    onLoadingChanged,
  } = props;
  const classes = useStyles();
  const [votesCount, setVotesCount] = useState(value);
  const [loggedInUserVote, setloggedInUserVote] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // on props load/change, update the state varibales
  useEffect(() => {
    setloggedInUserVote(loggedInVote);
  }, [loggedInVote]);

  const informationExchange: InformationExchangeApi = useApi(
    informationExchangeApiRef,
  );
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const handleClick = (incDecValue: any) => {
    //  setVotesCount((prevState: any) => prevState + incDecValue);
    setIsLoading(true);
    const voteData: PostVoteRequest = {
      id: id,
      vote: incDecValue,
      actiontype: actiontype,
    };
    informationExchange
      .postVote(voteData)
      .then((data: any) => {
        setIsLoading(false);
        setVotesCount(
          (prev: number) => prev + -1 * loggedInUserVote + incDecValue,
        );
        //  setVotesCount(data.votecount);
        setloggedInUserVote(incDecValue);
        /*   notificationApi.sendNotification({
          message: data.message,
          disapperAfterMs: 2500,
          severity: 'success',
        }); */
      })
      .catch((error: any) => {
        setIsLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  const getLoggedInClass = (condValue: number) => {
    return loggedInUserVote === condValue
      ? classes.selectedColor
      : classes.defaultColor;
  };

  const getDisabled = (condValue: number) => {
    return loggedInUserVote === condValue;
  };

  useEffect(() => {
    onLoadingChanged(isLoading);
  }, [isLoading]);

  return (
    <>
      {/* {isLoading && (
        <Backdrop
          style={{
            color: '#fff',
            zIndex: 1,
          }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )} */}
      {/* Voting Section */}
      <div className={classes.votes}>
        <IconButton
          className={classes.icons}
          onClick={() => handleClick(1)}
          disabled={getDisabled(1) || isLoading}
        >
          <ThumbUpIcon className={getLoggedInClass(1)} />
        </IconButton>
        <div>{votesCount}</div>
        <IconButton
          className={classes.icons}
          onClick={() => handleClick(-1)}
          disabled={getDisabled(-1) || isLoading}
        >
          <ThumbDownAltIcon className={getLoggedInClass(-1)} />
        </IconButton>
        {/* <IconButton>
            <TurnedInNotIcon />
          </IconButton> */}
      </div>
    </>
  );
};
export default Votes;
