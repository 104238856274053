import React, { useEffect, useState, useContext } from 'react';
import * as yup from 'yup';
import { useApi } from '@backstage/core-plugin-api';
import { Button, TextField, Grid, LinearProgress } from '@material-ui/core';
import { useCustomStyles } from './CardStyles';
import {
  GamificationApi,
  gamificationApiRef,
} from '../../../../apis/gamificationApis';
import { PromotionContext } from '../../../../providers/PromotionDataProvider';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';

export const limitChars = (val: string, limit: number) => {
  if (val) {
    if (val.length > limit) {
      return val.substring(0, limit);
    }
  }
  return val;
};

export interface AddEditLevelAndBadgesProps {
  data: {
    id: any;
    type: any;
    name: any;
    description: any;
    minpoints: any;
    maxpoints: any;
  };
  isEditClicked?: any;
  onCancelClicked?: () => void;
}

const AddEditLevelAndBadges = (props: AddEditLevelAndBadgesProps) => {
  const { id, type, name, description, minpoints, maxpoints } = props.data;
  const { isEditClicked, onCancelClicked } = props;
  const onclickHelpButton = useContext(PromotionContext);

  const classes = useCustomStyles();
  const gamificationApi: GamificationApi = useApi(gamificationApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const [_levelName, setLevelName] = useState(name || '');
  const [_levelType, setLevelType] = useState(type || '');
  const [_minPoints, setMinPoints] = useState(minpoints || '');
  const [_maxPoints, setMaxPoints] = useState(maxpoints || '');
  const [_description, setDescription] = useState(description || '');

  const [_levelTypeValidationErr, setLevelTypeValidationError] = useState('');
  const [_descriptionValidationErr, setDescriptionValidationError] =
    useState('');
  const [_levelNameValidationError, setLevelNameValidationError] = useState('');
  const [_minPointsValidationError, setMinPointsValidationError] = useState('');
  const [_maxPointsValidationError, setMaxPointsValidationError] = useState('');

  const [_showDialog, setShowDialog] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    onclickHelpButton.setToggleHelpButton(false);
  }, []);

  const handleRedirect = () => {
    onCancelClicked?.();
  };

  const handleSubmit = () => {
    gamificationApi
      .addLevelAndBadges({
        type: _levelType,
        name: _levelName,
        description: _description,
        minpoints: _minPoints,
        maxpoints: _maxPoints,
      })
      .then(() => {
        setInProgress(false);
        setShowDialog(true);
        notificationApi.sendNotification({
          message: `Level Added successfully, updated list will be available shortly`,
          disapperAfterMs: 2500,
          severity: 'success',
          callback: handleRedirect,
        });
      })
      .catch((err: any) => {
        setInProgress(false);
        setShowDialog(true);
        notificationApi.sendNotification({
          message: err.message,
          disapperAfterMs: 2500,
          severity: 'error',
          callback: handleRedirect,
        });
      });
  };

  const handleSubmitById = () => {
    gamificationApi
      .updateLevelAndBadges({
        type: _levelType,
        id: id,
        name: _levelName,
        description: _description,
        minpoints: _minPoints,
        maxpoints: _maxPoints,
      })
      .then(() => {
        setInProgress(false);
        setShowDialog(true);
        notificationApi.sendNotification({
          message: `Level Updated successfully, updated list will be available shortly`,
          disapperAfterMs: 2500,
          severity: 'success',
          callback: handleRedirect,
        });
      })
      .catch((err: any) => {
        setInProgress(false);
        setShowDialog(true);
        notificationApi.sendNotification({
          message: err.message,
          disapperAfterMs: 2500,
          severity: 'error',
          callback: handleRedirect,
        });
      });
  };

  const validateInputs = async () => {
    const minPoints = yup
      .number()
      .positive()
      .integer('This must be an integer ')
      .nullable(true)
      .required('Updated points is required')
      .min(1, 'At least 1 characters required')
      .positive('This field must contain a positive number')
      .integer('This field should contain an integer')
      .validate(_minPoints)
      .then(() => {
        setMinPointsValidationError('');
        return true;
      })
      .catch(err => {
        setMinPointsValidationError(err.message);
        return false;
      });

    const maxPoints = yup
      .number()
      .positive()
      .integer('This must be an integer ')
      .nullable(true)
      .required('Updated points is required')
      .min(1, 'At least 1 characters required')
      .positive('This field must contain a positive number')
      .integer('This field should contain an integer')
      .validate(_maxPoints)
      .then(() => {
        setMaxPointsValidationError('');
        return true;
      })
      .catch(err => {
        setMaxPointsValidationError(err.message);
        return false;
      });

    const descResult = yup
      .string()
      .required('Reason is required')
      .min(3, 'At least 3 characters required')
      .max(500, 'Maximum 500 characters')
      .validate(_description)
      .then(() => {
        setDescriptionValidationError('');
        return true;
      })
      .catch(err => {
        setDescriptionValidationError(err.message);
        return false;
      });

    const levelName = yup
      .string()
      .required('Level name is required')
      .min(3, 'At least 3 characters required')
      .max(500, 'Maximum 500 characters')
      .validate(_levelName)
      .then(() => {
        setLevelNameValidationError('');
        return true;
      })
      .catch(err => {
        setLevelNameValidationError(err.message);
        return false;
      });
    const levelType = yup
      .string()
      .required('Level type is required')
      .min(3, 'At least 3 characters required')
      .max(500, 'Maximum 500 characters')
      .validate(_levelType)
      .then(() => {
        setLevelTypeValidationError('');
        return true;
      })
      .catch(err => {
        setLevelTypeValidationError(err.message);
        return false;
      });

    const allPromises = await Promise.all([
      minPoints,
      maxPoints,
      descResult,
      levelType,
      levelName,
    ]);
    return allPromises.reduce((prev, current) => {
      return prev && current;
    });
  };

  const onSubmit = async () => {
    if (await validateInputs()) {
      if (isEditClicked) {
        handleSubmitById();
      } else {
        handleSubmit();
      }

      setInProgress(true);
    }
  };

  return (
    <>
      <Grid className={classes.levelAddEditContainer} container>
        {inProgress && <LinearProgress />}
        <Grid item xs={12}>
          <h2>{isEditClicked ? 'Edit Level' : 'New Level'}</h2>
        </Grid>
        <Grid xs={12} item>
          <TextField
            disabled={false}
            style={{ width: '100%' }}
            placeholder="Type here..."
            InputLabelProps={{
              shrink: true,
            }}
            id="Name"
            data-testid="name"
            label="Level name"
            onChange={event => {
              const value = limitChars(event?.target?.value, 50);
              setLevelName(value);
              setLevelNameValidationError('');
            }}
            value={_levelName}
            variant="outlined"
            {...(_levelNameValidationError && {
              error: true,
              helperText: _levelNameValidationError,
            })}
          />
        </Grid>
        <Grid xs={12} item>
          <TextField
            disabled={false}
            style={{ width: '100%' }}
            placeholder="Type here..."
            InputLabelProps={{
              shrink: true,
            }}
            id="Type"
            data-testid="type"
            label="Level Type"
            onChange={event => {
              const value = limitChars(event?.target?.value, 50);
              setLevelType(value);
              setLevelTypeValidationError('');
            }}
            value={_levelType}
            variant="outlined"
            {...(_levelTypeValidationErr && {
              error: true,
              helperText: _levelTypeValidationErr,
            })}
          />
        </Grid>

        <Grid xs={12} item className={classes.minAndMaxContainer}>
          <TextField
            disabled={false}
            style={{ width: '100%', marginRight: '16px' }}
            placeholder="Type here..."
            InputLabelProps={{
              shrink: true,
            }}
            id="min-points"
            data-testid="min-points"
            label="Min Points"
            onChange={event => {
              const value = limitChars(event?.target?.value, 50);
              setMinPoints(value);
              setMinPointsValidationError('');
            }}
            value={_minPoints}
            variant="outlined"
            {...(_minPointsValidationError && {
              error: true,
              helperText: _minPointsValidationError,
            })}
          />

          <TextField
            disabled={false}
            style={{ width: '100%' }}
            placeholder="Type here..."
            InputLabelProps={{
              shrink: true,
            }}
            id="max-points"
            data-testid="max-points"
            label="Max Points"
            onChange={event => {
              const value = limitChars(event?.target?.value, 50);
              setMaxPoints(value);
              setMaxPointsValidationError('');
            }}
            value={_maxPoints}
            variant="outlined"
            {...(_maxPointsValidationError && {
              error: true,
              helperText: _maxPointsValidationError,
            })}
          />
        </Grid>

        <Grid xs={12} item>
          <TextField
            style={{ width: '100% ' }}
            disabled={false}
            placeholder="Type here..."
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              minRows: 4,
            }}
            id="description-text"
            data-testid="description"
            label="Description"
            multiline
            onChange={event => {
              const value = limitChars(event?.target?.value, 500);
              setDescription(value);
              setDescriptionValidationError('');
            }}
            {...(_descriptionValidationErr && {
              error: true,
              helperText: _descriptionValidationErr,
            })}
            value={_description || ''}
            variant="outlined"
          />
        </Grid>
        <Grid xs={12} item className={classes.saveCancelButtonGroup}>
          <Button
            variant="outlined"
            color="primary"
            onClick={onCancelClicked}
            className={classes.cancelButton}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            data-testid="submit-btn"
            color="primary"
            onClick={onSubmit}
            disabled={_showDialog}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default AddEditLevelAndBadges;
