const CONSTANTS = {
  versionRegex: /^(\d+)(\.\d+)*$/,

  errorMsgs: {
    info: {
      title: 'Title is required',
      version: 'Version is required',
      versionInvalid: 'Version not valid',
      description: 'Description is required',
    },
    servers: {
      url: 'Url is invalid',
    },
    params: {
      name: 'Param name is required',
    },
  },
};
export default CONSTANTS;
