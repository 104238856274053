import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const StringCaseIcon = (props: any) => (
  <SvgIcon viewBox={'48 48'} style={{ transform: 'scale(3)' }} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 3H3V21H21V3ZM11.197 14.626V15H10.2025V8.5315H11.197V10.9625C11.3925 10.716 11.826 10.4865 12.319 10.4865C13.611 10.4865 14.3675 11.532 14.3675 12.79C14.3675 14.065 13.611 15.102 12.319 15.102C11.826 15.102 11.3925 14.8725 11.197 14.626ZM11.197 11.957V13.6315C11.3585 13.9715 11.741 14.218 12.149 14.218C12.914 14.218 13.373 13.606 13.373 12.79C13.373 11.974 12.914 11.3705 12.149 11.3705C11.741 11.3705 11.3585 11.6085 11.197 11.957ZM8.077 13.827V13.4615C7.9665 13.181 7.567 13.011 7.1675 13.011C6.717 13.011 6.2495 13.198 6.2495 13.64C6.2495 14.082 6.717 14.2775 7.1675 14.2775C7.567 14.2775 7.9665 14.1075 8.077 13.827ZM9.0205 15H8.077V14.7535C7.924 14.898 7.4735 15.0765 6.9805 15.0765C6.071 15.0765 5.3315 14.541 5.3315 13.64C5.3315 12.7985 6.071 12.212 7.0485 12.212C7.448 12.212 7.89 12.3565 8.077 12.484V12.127C8.077 11.6765 7.7965 11.328 7.227 11.328C6.8275 11.328 6.581 11.498 6.4705 11.804H5.5185C5.646 11.056 6.343 10.4865 7.244 10.4865C8.3575 10.4865 9.0205 11.0985 9.0205 12.11V15ZM18.9196 13.6145H17.9251C17.8486 14.014 17.5171 14.235 17.0836 14.235C16.4121 14.235 16.0041 13.6315 16.0041 12.79C16.0041 11.957 16.4121 11.3535 17.0836 11.3535C17.5171 11.3535 17.8486 11.566 17.9251 11.9655H18.9196C18.7921 11.0815 18.1206 10.4865 17.0666 10.4865C15.8766 10.4865 15.0096 11.4215 15.0096 12.79C15.0096 14.167 15.8766 15.102 17.0666 15.102C18.1206 15.102 18.7921 14.4985 18.9196 13.6145Z"
      fill="#127F6B"
    />
  </SvgIcon>
);

export default StringCaseIcon;
