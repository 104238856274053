import React, { useEffect, useState, useContext, useRef } from 'react';
import {
    Box,
    createStyles,
    Grid,
    makeStyles,
    Theme,
    useMediaQuery,
    useTheme,
    Paper,
    FormControl,
    Input,
    Typography,
    IconButton,
    Popover,
    Dialog,
    DialogTitle,
    DialogContent,
    Select,
    MenuItem,
    DialogActions,
    Button,
    Slider,
    TextField,
    LinearProgress
} from '@material-ui/core';
import MessageIcon from '@material-ui/icons/Message';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Avatar } from '@backstage/core-components';
import CodeIcon from '@material-ui/icons/Code';
import TranslateIcon from '@material-ui/icons/Translate';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CheckIcon from '@material-ui/icons/Check';
import StorageIcon from '@material-ui/icons/Storage';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import WebIcon from '@material-ui/icons/Web';
import { encode } from 'gpt-tokenizer';
import {
    openaiApiRef
} from '../../apis/openAI';
import {
    userApiRef
} from '../../apis/userApi'
import { AuthContext } from '../../providers/AuthProvider';
import { useApi } from '@backstage/core-plugin-api';
import ClearIcon from '../Root/icons/clearIcon';
import OpenDocumentIcon from '../Root/icons/openDocument';
import CloseDocumentIcon from '../Root/icons/closeDocument';
import CreateNewIcon from '../Root/icons/createNewIcon';
import SendIcon from '../Root/icons/sendIcon';
import BotIcon from '../Root/icons/botIcon';
import { constants } from './constants';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            borderRadius: `${theme.spacing(1)}px`,
            height: '75vh',
            minHeight: '400px',
            display: 'flex',
            flexDirection: 'row',
            marginTop: `${theme.spacing(4)}px`
        },
        flexRow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginBottom: '20px',
            '& > div': {
                width: '80%',
                backgroundColor: theme.palette.chat.primary.background,
                borderRadius: `${theme.spacing(1)}px`,
                padding: '10px 15px'
            }
        },
        flexRowReverse: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '20px',
            '& > div': {
                width: '80%',
                backgroundColor: theme.palette.chat.secondary.background,
                borderRadius: `${theme.spacing(1)}px`,
                padding: '10px 15px'
            }
        },
        title: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
            '& p': {
                fontWeight: 500,
            },
            borderBottom: '1px solid rgba(0, 63, 45, 0.10)'
        },
        iconButton: {
            borderRadius: '4px',
            backgroundColor: theme.palette.chat.primary.button,
            marginRight: `${theme.spacing(1)}px`,
            padding: '3px 12px',
            color: theme.palette.primary.main
        },
        iconButton2: {
            borderRadius: '4px',
            backgroundColor: theme.palette.chat.primary.button,
            marginRight: `${theme.spacing(1)}px`,
            padding: '12px 16px',
            color: theme.palette.primary.main,
            fontSize: '16px'
        },
        submitIcon: {
            borderRadius: '4px',
            backgroundColor: theme.palette.chat.primary.button,
            marginRight: `${theme.spacing(1)}px`,
            padding: '12px 8px 12px 16px',
            color: theme.palette.primary.main,
            fontSize: '16px'
        },
        promptIcon: {
            marginRight: '8px'
        },
        clearButton: {
            fontSize: '16px',
            color: theme.palette.primary.main,
            '& svg': {
                marginRight: `${theme.spacing(1)}px`
            }
        },
        submitButton: {
            backgroundColor: theme.palette.primary.main
        },
        buttongroup: {
            display: 'flex',
            flexDirection: 'row'
        },
        inputField: {
            width: '100%',
            flex: '1',
            boxShadow: 'none',
            borderBottom: 'none'
        },
        chatBubble: {
            backgroundColor: theme.palette.chat.primary.background,
            color: theme.palette.chat.primary.text,
            height: 'fit-content'
        },
        chatBubbleUser: {
            backgroundColor: theme.palette.chat.secondary.background,
            color: theme.palette.chat.secondary.text,
            height: 'fit-content'
        },
        bubble: {
            padding: '10px 15px',
            borderRadius: '12px'
        },
        box: {
            display: 'flex',
            flex: '1 1 auto',
            overflowY: 'scroll',
            flexDirection: 'column',
            padding: '10px 20px'
        },
        childContainer: {
            flexDirection: 'column',
            display: 'flex'
        },
        popover: {
            backgroundColor: theme.palette.chat.prompt.promptBackground,
            maxWidth: '780px',
            cursor: 'pointer'
        },
        rows: {
            padding: '20px 20px 0px 20px',
            display: 'flex',
            justifyContent: 'space-between'
        },
        element: {
            display: 'flex',
            marginBottom: '20px'
        },
        active: {
            backgroundColor: theme.palette.chat.prompt.promptActive
        },
        icon: {
            padding: '10px',
            marginRight: '12px',
            '& >svg': {
                color: '#FFF'
            }
        },
        promptBold: {
            fontWeight: 600,
            fontSize: '16px'
        },
        promptText: {
            fontSize: '12px'
        },
        divider: {
            width: '100%',
            color: 'rgba(0, 0, 0, 0.26)',
            paddingBottom: '20px',
            display: 'flex',
            justifyContent: 'center'
        },
        regenerate: {
            width: '100%',
            color: 'rgba(0, 0, 0, 0.26)',
            paddingBottom: '20px',
            '& span': {
                '& svg': {
                    marginRight: '5px'
                }
            }
        }
    }),
);

const OpenAI = () => {

    const classes = useStyles();
    const userApi = useApi(userApiRef);
    const isMidSizeScreen = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('md'),
    );
    const theme = useTheme<Theme>();
    const {
        profEmail
    } = useContext(AuthContext);
    const bottomRef = useRef<HTMLDivElement>(null);
    const openAIApi = useApi(openaiApiRef);
    const [imageData, setImageData] = useState<string | undefined | any>('');
    const [chat, setChat] = useState<any>([]);
    const [search, setSearch] = useState('');
    const [option, setOption] = useState({
        roleinformation: "You are an AI assistant that helps people find information.",
        value: 'chat',
        text: 'Chat',
        desc: 'DevX related chat',
        aiResponse: 'What do you need help with today',
        Icon: AssignmentIcon,
        background: '#D2785A'
    });
    const [submitEnabled, setSubmitEnabled] = useState(true);
    const [language, setLanguage] = useState({
        from: '',
        to: '',
        open: false
    });
    const [configuration, setConfiguration] = useState({
        temperature: 1,
        top_p: 0.95,
        frequency_penalty: 0.5,
        presence_penalty: 0,
        max_tokens: 800
    });
    const max_tokens = 8192;
    const [cost, setCost] = useState(0);
    const [editor, setEditor] = useState(false);
    useEffect(() => {
        if (chat.length ===0) {
            setChat([{
                role: constants.roles.GENERATED,
                content: 'Hello! I\'m an AI assistant here to help you find the information you need. Please feel free to ask me any questions, and I will do my best to assist you.'
            }]);
        }
        fetchCost()
    }, []);

    const fetchCost = async () => {
        const newCost = await openAIApi.tokenUsage();
        if (newCost && newCost.totalcost) {
            setCost(newCost.totalcost);
        }
    };

    useEffect(() => {
        if (userApi) {
            userApi
                .getImage(profEmail)
                .then((data: any) => {
                    if (typeof data == 'object') {
                        setImageData(undefined);
                    } else {
                        setImageData(data);
                    }
                })
                .catch((err: any) => setImageData(undefined));
        }
        return () => {
            setImageData(undefined);
        };
    }, [profEmail]);

    const [showDocs, setShowDocs] = useState<any>({
        show: false,
        content: [],
        display: ''
    });

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const options = [
        {
            roleinformation: constants.roleinformation.devx,
            value: 'chat',
            text: 'Chat',
            desc: constants.description.devx,
            aiResponse: constants.aiResponse.devx,
            Icon: AssignmentIcon,
            background: constants.colors.devx
        },
        {
            roleinformation: constants.roleinformation.translate,
            value: 'translate',
            text: 'Translation',
            desc: constants.description.translate,
            aiResponse: constants.aiResponse.translate,
            Icon: TranslateIcon,
            background: constants.colors.translate

        },
        {
            roleinformation: constants.roleinformation.summarize,
            value: 'summarize',
            desc: constants.description.summarize,
            text: 'Summarization',
            aiResponse: constants.aiResponse.summarize,
            Icon: AssignmentIcon,
            background: constants.colors.summarize
        },
        {
            roleinformation: constants.roleinformation.grammar,
            value: 'grammar',
            desc: constants.description.grammar,
            text: 'Grammar correction',
            aiResponse: constants.aiResponse.grammar,
            Icon: CheckIcon,
            background: constants.colors.grammar
        },
        {
            roleinformation: constants.roleinformation.data,
            value: 'data',
            desc: constants.description.data,
            text: 'Parse unstructured data',
            aiResponse: constants.aiResponse.data,
            Icon: StorageIcon,
            background: constants.colors.data
        },
        {
            roleinformation: constants.roleinformation.code,
            value: 'improve code',
            desc: constants.description.code,
            text: 'Improve code efficiency',
            aiResponse: constants.aiResponse.code,
            Icon: CodeIcon,
            background: constants.colors.code
        },
        {
            roleinformation: constants.roleinformation.explain,
            value: 'explain code',
            desc: constants.description.explain,
            text: 'Explain code',
            aiResponse: constants.aiResponse.explain,
            Icon: CodeIcon,
            background: constants.colors.explain
        },
        {
            roleinformation: constants.roleinformation.webpage,
            value: 'webpage create',
            desc: constants.description.webpage,
            text: 'Website creator',
            aiResponse: constants.aiResponse.webpage,
            Icon: WebIcon,
            background: constants.colors.webpage
        },
        {
            roleinformation: constants.roleinformation.sql,
            value: 'sql',
            desc: constants.description.sql,
            text: 'Natural language to SQL',
            aiResponse: constants.aiResponse.sql,
            Icon: StorageIcon,
            background: constants.colors.sql
        },
    ];

    // console.log('token here ', chat.length > 1 && encode(chat.filter((c: any) => c.role !== constants.roles.GENERATED)))

    const Regenerate = () => (<div className={classes.regenerate}>
        <Button variant="outlined" onClick={onRegenerate}>
            <RefreshIcon/>
            Regerate
        </Button>
    </div>);

    const Divider = () => (<div ref={bottomRef} className={classes.divider}>
        Current prompt: {' '+option.text} {option.text == 'Translation' && `: ${language.from} to ${language.to}`}
    </div>);

    const DialogLanguage = () => (<>
        <DialogTitle>
            Please select current and target language from the drop down.
        </DialogTitle>
        <DialogContent>
            <FormControl
                fullWidth
            >
            <Select
                labelId='openAI-chat-language-from-select'
                label='From'
                fullWidth
                value={language.from}
                onChange={(event: any) => {
                    setLanguage({
                        ...language,
                        from: event?.target?.value
                    })
                }}
            >
                {
                    [constants.LANGUAGE_UNKNOWN, ...constants.language].map((l: string) => <MenuItem value={l}>{l}</MenuItem>)
                }
            </Select>
            <Select
                labelId='openAI-chat-language-from-select'
                label='From'
                fullWidth
                value={language.to}
                onChange={(event: any) => {
                    setLanguage({
                        ...language,
                        to: event?.target?.value
                    })
                }}
            >
                {
                    constants.language.map((l: string) => <MenuItem value={l}>{l}</MenuItem>)
                }
            </Select>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                if (!language.from || !language.to) {
                    return
                } else {
                    setOption( {
                        roleinformation: constants.roleinformation.translate.replace('{{from}}', language.from).replace('{{to}}', language.to),
                        value: 'translate',
                        text: 'Translation',
                        desc: constants.description.translate,
                        aiResponse: constants.aiResponse.translate,
                        Icon: TranslateIcon,
                        background: constants.colors.translate
                    });
                    setLanguage({
                        ...language,
                        open: false
                    });
                }
            }}>
                Save
            </Button>
        </DialogActions>
    </>)

    const RenderOptions = () => {
        return <Grid container className={classes.rows}>
            {
                options.map((o) => (<Grid xs={6} md={4} className={`${classes.element} ${option.value == o.value && classes.active}`} onClick={() => {
                    if (o.value == constants.TRANSLATE) {
                        setLanguage({
                            ...language,
                            open: true
                        });
                    } else {
                        setOption(o);
                    }
                    setAnchorEl(null);
                }}>
                    <div className={classes.icon} style={{
                        backgroundColor: o.background
                    }}
                    >
                        <o.Icon />
                    </div>
                    <div>
                        <Typography className={classes.promptBold}>
                            {o.text}
                        </Typography>
                        <Typography className={classes.promptText}>
                            {o.desc}
                        </Typography>
                    </div>

                </Grid>))
            }
        </Grid>
    }

    const ChatBubble = ({
        align,
        text,
        reference,
        show
    }: {
        align: 'left' | 'right';
        text?: string;
        reference?: any;
        show?: boolean;
    }) => {
        if (!show) return <></>
        let r: any;
        if (reference) {
            try {
                const p = JSON.parse(reference.content);
                r = {
                    ...p,
                    uniqueFiles: Object.values(
                        p.citations.reduce((c: any, e: any) => {
                            if (!c[e.filepath]) c[e.filepath] = e;
                            return c;
                        }, {})
                    )
                }
            } catch {

            }
        }
        const GenerateText = () => {
            if (!text) return <></>;
            if (!r) return <>{text}</>;
            const parts = text.split(/\[doc/);
            return <>
                {
                    parts.map((c: string, idx: number) => <>
                        {
                            idx > 0 ? <>
                                <sup onClick={() => {
                                    if (r) {
                                        setShowDocs({
                                            show: true,
                                            content: r.citations || []
                                        });
                                    }
                                }}>{c.charAt(0)}</sup>
                                {c.substring(2)}
                            </> : <>{c}</>
                        }
                    </>)
                }
                {
                    (r && r.citations && <div style={{ marginTop: '20px' }}>
                        <div>
                           {
                            r.component ? 'Components': 'Reference Docs:'
                           }
                        </div>
                        <div>
                            {
                               r.uniqueFiles.map((c: any) => (<div 
                                style={{
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                if (reference.component) {
                                    console.log('redirect to component ',c.filepath.split('/').pop().replace('.pdf', ''))
                                } else {
                                    setShowDocs({
                                        show: true,
                                        content: r.uniqueFiles || []
                                    });
                                }
                            }}>
                                {
                                    reference.component ? c.filepath.split('/').pop().replace('.pdf', '') : c.filepath.split('/').pop()
                                }
                               </div>)) 
                            }
                        </div>
                    </div>)
                }
            </>
        }
        return <div className={align === 'left' ? classes.flexRow : classes.flexRowReverse}>
            {
                align === 'left' && <BotIcon style={{ marginRight: '5px' }} />
            }
            <div className={align === 'left' ? classes.chatBubble : classes.chatBubbleUser}>
                {GenerateText()}
            </div>
            {
                align === 'right' && <Avatar
                    picture={imageData}
                    displayName={profEmail}
                    customStyles={{
                        width: '24px',
                        height: '24px',
                        borderRadius: '50%',
                        fontSize: '10px',
                        marginLeft: '5px',
                        padding: '0px'
                    }}
                />
            }
        </div>
    }

    const SettingBlock = () => {

        return <Grid xs={4}>
            <Paper style={{ height: '100%', maxHeight: '100%', borderRadius: '0', overflowY: 'scroll', overflowX: 'hidden', padding: '20px' }}>
                <Box style={{
                    marginBottom: '20px'
                }}>
                    <Typography component="h4">
                        Configuration
                    </Typography>
                </Box>
                <Box style={{
                    marginBottom: '20px'
                }}>
                    <Typography variant="body1">
                        Max Response
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs>
                        <Slider
                            size="small"
                            defaultValue={0}
                            value={configuration.max_tokens}
                            aria-label="Small"
                            valueLabelDisplay="auto"
                            max={8192}
                            min={100}
                            steps={1}
                            onChange={(event: Event, newValue: number | number[]) => {
                            setConfiguration({
                                ...configuration,
                                max_tokens: newValue as number
                            });
                          }}
                    />
                        </Grid>
                        <Grid item>
                        <TextField
                            value={configuration.max_tokens}
                            size="small"
                            onChange={(event: React.ChangeEvent<HTMLTextFieldElement>) => {
                                setConfiguration({
                                    ...configuration,
                                    max_tokens: event.target.value === '' ? 0 : Number(event.target.value)
                                })
                            }}
                            onBlur={(event: React.ChangeEvent<HTMLTextFieldElement>) => {
                                setConfiguration({
                                    ...configuration,
                                    max_tokens: event.target.value === '' ? 0 : Number(event.target.value)
                                })
                            }}
                            inputProps={{
                            step: 1,
                            min: 100,
                            max: 8192,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                            }}
                            variant="outlined"
                        />
                        </Grid>
                    </Grid>
                   
                </Box>
                <Box style={{
                    marginBottom: '20px'
                }}>
                    <Typography variant="body1">
                        Temperature
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs>
                        <Slider
                            size="small"
                            defaultValue={0}
                            value={configuration.temperature}
                            aria-label="Small"
                            valueLabelDisplay="auto"
                            max={1}
                            min={0}
                            step={0.01}
                            onChange={(event: Event, newValue: number | number[]) => {
                                console.log(newValue)
                            setConfiguration({
                                ...configuration,
                                temperature: newValue as number
                            });
                          }}
                    />
                        </Grid>
                        <Grid item>
                        <TextField
                            value={configuration.temperature}
                            size="small"
                            onChange={(event: React.ChangeEvent<HTMLTextFieldElement>) => {
                                setConfiguration({
                                    ...configuration,
                                    temperature: event.target.value === '' ? 0 : Number(event.target.value)
                                })
                            }}
                            onBlur={(event: React.ChangeEvent<HTMLTextFieldElement>) => {
                                setConfiguration({
                                    ...configuration,
                                    temperature: event.target.value === '' ? 0 : Number(event.target.value)
                                })
                            }}
                            inputProps={{
                            step: 0.01,
                            min: 0,
                            max: 1,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                            }}
                            variant="outlined"
                        />
                        </Grid>
                    </Grid>
                </Box>
                <Box style={{
                    marginBottom: '20px'
                }}>
                    <Typography variant="body1">
                        Frequency Penalty
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs>
                        <Slider
                            size="small"
                            defaultValue={0}
                            value={configuration.frequency_penalty}
                            aria-label="Small"
                            valueLabelDisplay="auto"
                            max={1}
                            min={0}
                            step={0.01}
                            onChange={(event: Event, newValue: number | number[]) => {
                            setConfiguration({
                                ...configuration,
                                frequency_penalty: newValue as number
                            });
                          }}
                    />
                        </Grid>
                        <Grid item>
                        <TextField
                            value={configuration.frequency_penalty}
                            size="small"
                            onChange={(event: React.ChangeEvent<HTMLTextFieldElement>) => {
                                setConfiguration({
                                    ...configuration,
                                    frequency_penalty: event.target.value === '' ? 0 : Number(event.target.value)
                                })
                            }}
                            onBlur={(event: React.ChangeEvent<HTMLTextFieldElement>) => {
                                setConfiguration({
                                    ...configuration,
                                    frequency_penalty: event.target.value === '' ? 0 : Number(event.target.value)
                                })
                            }}
                            inputProps={{
                            step: 0.01,
                            min: 0,
                            max: 1,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                            }}
                            variant="outlined"
                        />
                        </Grid>
                    </Grid>
                </Box>
                <Box style={{
                    marginBottom: '20px'
                }}>
                    <Typography variant="body1">
                        Presence Penalty
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs>
                        <Slider
                            size="small"
                            defaultValue={0}
                            value={configuration.presence_penalty}
                            aria-label="Small"
                            valueLabelDisplay="auto"
                            max={1}
                            min={0}
                            step={0.01}
                            onChange={(event: Event, newValue: number | number[]) => {
                            setConfiguration({
                                ...configuration,
                                presence_penalty: newValue as number
                            });
                          }}
                    />
                        </Grid>
                        <Grid item>
                        <TextField
                            value={configuration.presence_penalty}
                            size="small"
                            onChange={(event: React.ChangeEvent<HTMLTextFieldElement>) => {
                                setConfiguration({
                                    ...configuration,
                                    presence_penalty: event.target.value === '' ? 0 : Number(event.target.value)
                                })
                            }}
                            onBlur={(event: React.ChangeEvent<HTMLTextFieldElement>) => {
                                setConfiguration({
                                    ...configuration,
                                    presence_penalty: event.target.value === '' ? 0 : Number(event.target.value)
                                })
                            }}
                            inputProps={{
                            step: 0.01,
                            min: 0,
                            max: 1,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                            }}
                            variant="outlined"
                        />
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Typography variant="body1" style={{ fontSize: '10px' }}>
                        Input token progress indicator
                    </Typography>
                    {/* <LinearProgress value={max_tokens} */}
                </Box>
            </Paper>
        </Grid>
    }

    const updateChat = (newChat: any[], removeLast = false) => {
        setChat([
            ...(removeLast || chat[chat.length - 1].content === 'Please wait while we regenerate your response...' ? chat.slice(0, -1) : chat),
            ...newChat
        ]);
    }

    const onSubmit = (event: any) => {
        event.preventDefault()
        setSubmitEnabled(false);
        setChat([...chat, {
            role: constants.roles.USER,
            content: search
        }]);
        setSearch('');
    };

    const onRegenerate = () => {
        setChat([...chat.slice(0, -1), {
            role: constants.roles.GENERATED,
            content: 'Please wait while we regenerate your response...'
        }]);
    }

    useEffect(() => {
        if (bottomRef) {
            try {
                bottomRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
            } catch {

            }
        }
        if (chat.length > 0 && (chat[chat.length - 1]?.role === constants.roles.USER || chat[chat.length - 1]?.content === 'Please wait while we regenerate your response...')) {
            const data = {
                ...configuration,
                messages: [
                    ...(option.roleinformation === 'You are an AI assistant that helps people find information.' ? [] : [{
                        role: "system",
                        content: option.roleinformation
                    }]),
                    ...chat.filter((c: any) => c.role !== constants.roles.GENERATED).map((c: any) => ({
                    content: c.content,
                    role: c.role
                }))
                ]
            }
            if (option.roleinformation === 'You are an AI assistant that helps people find information.') {
                openAIApi.cognitiveChat(data)
                .then((res) => {
                    const { response } = res;
                    setSubmitEnabled(true);
                    fetchCost();
                    if (response[0] && Array.isArray(response[0].messages)) {
                        if (response[0].messages && response[0].messages[1] && response[0].messages[1].content) {
                            //un happy scenario for component search
                            if (!response[0].messages[1].content.includes("I'm sorry, I couldn't find any information")) {
                                updateChat([
                                    {
                                        role: constants.roles.TOOL,
                                        content: response[0].messages[0].content,
                                        component: true
                                    },
                                    {
                                        role: constants.roles.ASSISTANT,
                                        content: 'Here are some components I found in DevX',
                                    }
                                ])
                            }
                        }
                        updateChat([
                            {
                                role: constants.roles.TOOL,
                                content: response[0].messages[0].content,
                                component: !!res.component
                            },
                            {
                                role: constants.roles.ASSISTANT,
                                content: response[0].messages[1].content,
                            }
                        ])
                    } else {
                        updateChat([{
                            role: constants.roles.ASSISTANT,
                            content: res.messages
                        }])
                    }
                })
                .catch((err) => {
                    setSubmitEnabled(true);
                    updateChat([{
                        role: constants.roles.GENERATED,
                        content: 'something went wrong'
                    }])
                })
            } else {
                openAIApi.ask(data)
                .then((res) => {
                    setSubmitEnabled(true);
                    fetchCost();
                    if (res && Array.isArray(res.bot)) {
                        if (res.bot && res.bot[1] && res.bot[1].content) {
                            //un happy scenario for component search
                            if (!res.bot[1].content.includes("I'm sorry, I couldn't find any information")) {
                                updateChat([
                                    {
                                        role: constants.roles.TOOL,
                                        content: res.bot[0].content,
                                        component: true
                                    },
                                    {
                                        role: constants.roles.ASSISTANT,
                                        content: 'Here are some components I found in DevX',
                                    }
                                ])
                            }
                        }
                        updateChat([
                            {
                                role: constants.roles.TOOL,
                                content: res.bot[0].content,
                                component: !!res.component
                            },
                            {
                                role: constants.roles.ASSISTANT,
                                content: res.bot[1].content,
                            }
                        ])
                    } else {
                        updateChat([{
                            role: constants.roles.ASSISTANT,
                            content: res.response?.content
                        }])
                    }
                })
                .catch((err) => {
                    setSubmitEnabled(true);
                    updateChat([{
                        role: constants.roles.GENERATED,
                        content: 'something went wrong'
                    }])
                })
            }
            
        }
    }, [chat]);

    useEffect(() => {
        setChat([
            ...chat,
            {
                role: constants.roles.GENERATED,
                content: option.aiResponse
            }
        ])
    }, [option])

    return <Grid xs={12}>
        <Dialog open={language.open}>
            <Paper elevation={2}>
                <DialogLanguage/>
            </Paper>
        </Dialog>
        <Paper
            elevation={2}
            className={classes.paper}
        >
            <Grid xs={showDocs.show ? 9 : 12} className={classes.childContainer}>
                <Grid>
                    <div className={classes.title}>
                        <Typography>
                            New Chat Session
                        </Typography>

                        <div>
                            <IconButton className={classes.iconButton} onClick={() => {
                                setEditor(!editor);
                            }}>
                                <Typography style={{ marginRight: '5px' }}>
                                ${(Math.round(cost * 100) / 100).toFixed(2)}
                                </Typography>
                                <SettingsOutlinedIcon fill={theme.palette.primary.main} />
                            </IconButton>
                            <IconButton className={`${classes.iconButton} ${classes.clearButton}`} onClick={() => {
                                setChat([]);
                            }}>
                                <ClearIcon fill={theme.palette.primary.main} />
                                Clear
                            </IconButton>
                            <IconButton className={classes.iconButton} disabled>
                                <CreateNewIcon />
                            </IconButton>
                            <IconButton className={classes.iconButton} onClick={() => {
                                setShowDocs({
                                    ...showDocs,
                                    show: !showDocs.show,
                                    display: ''
                                });
                            }}>
                                {
                                    showDocs.show ? <OpenDocumentIcon fill={theme.palette.primary.main} /> : <CloseDocumentIcon fill={theme.palette.primary.main} />
                                }
                            </IconButton>
                        </div>
                    </div>
                </Grid>
                <Grid className={classes.box}>
                    {
                        chat.map((c: any, idx: number) => {

                            return <ChatBubble
                                align={c.role === constants.roles.ASSISTANT || c.role === constants.roles.GENERATED ? 'left' : 'right'}
                                text={c.content}
                                reference={c.content && c.content.includes('[doc') ? chat[idx - 1] : undefined}
                                show={c.role !== constants.roles.TOOL}
                            />
                        })
                    }
                    {
                        chat && chat.length > 1 && chat[chat.length-1].role === constants.roles.ASSISTANT && <Regenerate />
                    }
                    <Divider />
                </Grid>
                <Grid xs={12}
                    style={{
                        display: 'flex',
                        flex: '0 1 auto',
                        padding: '15px 20px'
                    }}
                >
                    <FormControl style={{ width: '100%' }} onSubmit={onSubmit}>
                        <Grid container style={{ width: '100%', margin: '0px' }} className={classes.buttongroup}>
                            <Input
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setSearch(event.target.value)
                                }}
                                value={search}
                                id='openai-ask-question-input'
                                className={classes.inputField}
                                disableUnderline
                                placeholder='Type here...'
                            />
                            <Popover
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                anchorEl={anchorEl}
                            >
                                <Paper elevation={2} className={classes.popover}>
                                    <RenderOptions />
                                </Paper>
                            </Popover>
                            <IconButton className={classes.iconButton2} onClick={handleClick}>
                                <MessageIcon className={classes.promptIcon} />
                                Prompts
                            </IconButton>
                            {/* <IconButton className={classes.iconButton2} onClick={onRegenerate} disabled={chat.length <= 1}>
                                <RefreshIcon />
                            </IconButton> */}
                            <IconButton className={`${classes.submitIcon} ${classes.submitButton}`} onClick={onSubmit} disabled={!submitEnabled || search.length == 0}>
                                <SendIcon />
                            </IconButton>
                        </Grid>
                    </FormControl>
                </Grid>
            </Grid>
            {
                editor && <SettingBlock />
            }
            {
                showDocs.show && !editor && <Grid xs={3}>
                    <Paper style={{ height: '100%', maxHeight: '100%', borderRadius: '0', overflowY: 'scroll', overflowX: 'hidden' }}>
                        {
                            showDocs.display ? <div>
                                <div style={{ wordBreak: 'break-word' }}>{showDocs.display.filepath.split('/').pop()}</div>
                                <div style={{ marginTop: '20px', wordBreak: 'break-word' }}>
                                    {
                                        showDocs.display.content
                                    }
                                </div>
                            </div> : <>
                                {
                                    showDocs.content.map(c => <div style={{
                                        padding: '10px',
                                        overflow: 'hidden',
                                        borderBottom: '1px solid rgba(0, 63, 45, 0.10)'
                                    }} onClick={() => {
                                        setShowDocs({
                                            ...showDocs,
                                            display: c
                                        })
                                    }}>
                                        <div style={{
                                            height: '16px',
                                            textOverflow: 'ellipsis',
                                            fontSize: '16px',
                                            lineHeight: '1',
                                            paddingBottom: '5px',
                                            overflow: 'hidden',
                                            width: '100%',
                                            whiteSpace: 'nowrap'
                                        }}>
                                            {c.filepath.split('/').pop()}
                                        </div>
                                        <div style={{
                                            height: '16px',
                                            textOverflow: 'ellipsis',
                                            fontSize: '16px',
                                            lineHeight: '1',
                                            overflow: 'hidden',
                                            width: '100%',
                                            whiteSpace: 'nowrap'
                                        }}>
                                            {c.content}
                                        </div>
                                    </div>)
                                }
                            </>
                        }
                    </Paper>
                </Grid>
            }
        </Paper>
    </Grid>
}

export default OpenAI;