import React from 'react';
import { Header, Page, TabbedLayout } from '@backstage/core-components';
import ManageTaxonomyContainer from './Manage/ManageContainer';
import BreadcrumbsNav from '../common/BreadcrumbsNav/BreadcrumbsNav';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';

const TaxonomyPage = () => {
  return (
    <Page themeId="home">
      <Header
        title="Capability Taxonomy"
        subtitle={
          <DevxBreadCrumb
            routes={[
              {
                type: 'link',
                link: '/',
                text: 'Home',
              },
              {
                type: 'text',
                link: '',
                text: 'Capability Taxonomy',
              },
            ]}
          />
        }
      ></Header>
      <TabbedLayout>
        <TabbedLayout.Route path="/manage" title="Taxonomy">
          <ManageTaxonomyContainer />
        </TabbedLayout.Route>
      </TabbedLayout>
    </Page>
  );
};
export default TaxonomyPage;
