import React, { useContext, useEffect, useState } from 'react';
import { Collapse, Grid, TextField } from '@material-ui/core';
import { useStyles } from './styles';
import REGISTER_API_CONSTANTS from '../Constants';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandMore from '../../../common/ExpandMore';
import { PromotionContext } from '../../../../providers/PromotionDataProvider';
import CONSTANTS from './Constants';

const InfoSection = (props: any) => {
  const { onChange, clicked, onError } = props;
  const classes = useStyles();

  /* Context */
  const myContext = useContext(PromotionContext);

  const [formValues, setFormValues] = useState({
    title: '',
    version: '',
    description: '',
  });

  /* Errors */
  const [errors, setErrors] = useState({
    title: '',
    version: '',
    description: '',
  });

  /* Change Handler for all form inputs */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const validate = () => {
    const errorObj = {
      ...errors,
    };
    errorObj.title = formValues.title ? '' : CONSTANTS.errorMsgs.info.title;

    const versionRegex = CONSTANTS.versionRegex;
    errorObj.version =
      // eslint-disable-next-line no-nested-ternary
      formValues.version && !new RegExp(versionRegex).test(formValues.version)
        ? CONSTANTS.errorMsgs.info.versionInvalid
        : formValues.version === ''
        ? CONSTANTS.errorMsgs.info.version
        : '';

    setErrors(errorObj);
    return Object.values(errorObj).every((x: any) => x === '');
  };

  useEffect(() => {
    if (clicked > 0) {
      const infoError = !validate();
      onError('info', infoError);
      onChange(formValues);
    }
  }, [clicked]);

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Grid container direction="row" spacing={3}>
        {/* Heading */}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={`${classes.swaggerApiHeadings} ${classes.spaceBetween} ${classes.info}`}
        >
          <span> {REGISTER_API_CONSTANTS.swaggerApiForm.infoHeading}</span>
          <span>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              className={classes.actionIcons}
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </span>
        </Grid>
      </Grid>
      <Collapse in={expanded} timeout="auto">
        <Grid container direction="row" spacing={3} className={classes.info}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              label={REGISTER_API_CONSTANTS.generateSwagger.Title.label}
              placeholder={
                REGISTER_API_CONSTANTS.generateSwagger.Title.placeholder
              }
              value={formValues.title}
              name="title"
              onChange={handleChange}
              required
              fullWidth
              variant="outlined"
              multiline
              {...(errors.title && {
                error: true,
                helperText: errors.title,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              label={REGISTER_API_CONSTANTS.generateSwagger.Version.label}
              placeholder={
                REGISTER_API_CONSTANTS.generateSwagger.Version.placeholder
              }
              value={formValues.version}
              name="version"
              onChange={handleChange}
              required
              fullWidth
              variant="outlined"
              multiline
              {...(errors.version && {
                error: true,
                helperText: errors.version,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              InputProps={{
                minRows: 4,
                maxRows: 4,
              }}
              label={REGISTER_API_CONSTANTS.generateSwagger.Description.label}
              placeholder={
                REGISTER_API_CONSTANTS.generateSwagger.Description.placeholder
              }
              value={formValues.description}
              name="description"
              onChange={handleChange}
              multiline
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};
export default InfoSection;
