import React, { useEffect } from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  Card,
  CardContent,
  makeStyles,
  createStyles,
  Typography,
  Button,
  Grid,
  TextField,
  LinearProgress,
  Theme,
} from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import Rating from '@material-ui/lab/Rating';
import { isEmpty } from 'lodash';
import { useGetConversionApi } from '../../utils/useGetConversionApi';
import { usePostConversionApi } from '../../utils/usePostConversionApi';
import {
  API_STATUS_FETCHING,
  API_STATUS_SUCCESS,
  API_STATUS_ERROR,
} from '../../utils/constant';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItemCard: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '10px',
      ['@media (max-width:920px)']: {
        marginBottom: '0px',
        position: 'relative',
        bottom: '8%',
      },
    },
    feedbackCard: {
      ['@media (max-width:1024px)and (min-width: 600px)']: {
        marginLeft: '15%',
      },
    },
    gridItemCardContent: {
      flex: 1,
    },
    label: {
      textTransform: 'uppercase',
      fontSize: '12px',
      lineHeight: 1.5,
      fontWeight: 600,
      letterSpacing: 0.5,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      fontFamily: 'Calibre-R-Regular',
      color: theme.palette.text.tertiary,
      '&::after': {
        content: '" *"',
        color: 'red',
      },
    },
    textBottomlabel: {
      fontSize: '10px',
      lineHeight: 1.5,
      fontWeight: 600,
      letterSpacing: 0.5,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      fontFamily: 'Calibre-R-Regular',
      color: theme.palette.text.tertiary,
      display: 'flex',
    },
    textBottomRight: {
      marginLeft: 'auto',
      marginRight: 0,
    },
    errorMsgText: {
      fontFamily: 'Calibre-R-Regular',
      fontSize: '10px',
      lineHeight: 1.5,
      fontWeight: 600,
      color: 'red',
    },
    ratingFeedback: {
      display: 'inline-flex',
    },
    ratingBtns: {
      paddingTop: '15px',
      paddingBottom: '20px',
    },
    ratingSubmitBtn: {
      marginRight: '4px',
      backgroundColor: '#D9D9D9',
      color: '#000',
    },
  }),
);

type RatingFeedbackCardProps = {
  userId: string;
};

export function RatingFeedbackCard({ userId }: RatingFeedbackCardProps) {
  const classes = useStyles();
  const { entity } = useEntity();
  const [ratings, setRatings] = React.useState<number | 0>(0);
  const [feedback, setFeedback] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState('');
  const [open, setOpen] = React.useState<Boolean | false>(false);
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const { localEntity } = useGetConversionApi(
    entity.metadata.name,
    entity.kind,
  );
  const { status, saveEntity } = usePostConversionApi(
    entity.metadata.name,
    entity.kind,
    localEntity,
  );
  const [buttonDisabled, setIsButtonDisabled] = React.useState(false);
  React.useEffect(() => {
    Array.isArray(localEntity?.metadata?.rating) &&
      localEntity?.metadata?.rating.forEach(item => {
        if (item.user.replace('user:', '') === userId) {
          setRatings(item.value);
          setFeedback(item.feedback || '');
        }
      });
  }, [localEntity]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    let val = event.target.value;
    if (val.length < 101) {
      setFeedback(val);
      setErrorMsg('');
    }
  };

  const handleClose = () => {
    setOpen(false);
    setIsButtonDisabled(false);
  };

  const validateRatingData = () => {
    let isValidate = false;
    if (ratings > 0 && feedback.trim().length > 0) {
      if (/^[a-zA-Z0-9.,?\s]*$/g.test(feedback.trim())) {
        isValidate = true;
      } else {
        isValidate = false;
        setErrorMsg('Invalid character found in feedback');
      }
    }
    return isValidate;
  };

  useEffect(() => {
    if (open) {
      if (status === API_STATUS_SUCCESS) {
        notificationApi.sendNotification({
          message: 'Feedback Saved! Changes may take upto 5 min to reflect.',
          severity: 'success',
          disapperAfterMs: 2500,
          callback: handleClose,
        });
      }
    }
  }, [open, status]);

  const handleSubmit = async () => {
    if (validateRatingData()) {
      setIsButtonDisabled(true);
      if (Array.isArray(localEntity.metadata.rating)) {
        let existingUser = false;
        localEntity.metadata.rating.forEach(item => {
          if (item.user === userId) {
            item.user = userId;
            item.value = ratings;
            item.feedback = feedback;
            item.date = new Date();
            existingUser = true;
          }
        });
        if (!existingUser) {
          localEntity.metadata.rating.unshift({
            user: userId,
            value: ratings,
            date: new Date(),
            feedback: feedback,
          });
        }
      } else {
        localEntity.metadata.rating = [
          {
            user: userId,
            value: ratings,
            date: new Date(),
            feedback: feedback,
          },
        ];
      }
      let sumOfRatings = 0;
      let counter = 0;
      localEntity.metadata.rating.forEach(item => {
        if (item.value !== '') {
          sumOfRatings += parseFloat(item.value);
          counter += 1;
        }
      });
      localEntity.metadata.avgrating =
        parseFloat(sumOfRatings) > 0 ? parseFloat(sumOfRatings / counter) : 0;
      saveEntity();
      setErrorMsg('');
      setOpen(true);
    }
  };

  return (
    <Card className={classes.gridItemCard}>
      {status === API_STATUS_FETCHING && <LinearProgress />}
      <CardContent className={classes.gridItemCardContent}>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs={12} md={3}>
            <Typography variant="subtitle2" className={classes.label}>
              Ratings
            </Typography>
            <div className={classes.ratingFeedback}>
              <Rating
                name="hover-feedback"
                value={ratings}
                precision={0.5}
                onChange={(event, newValue) => {
                  setRatings(newValue);
                  setErrorMsg('');
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} className={classes.feedbackCard}>
            <Typography variant="subtitle2" className={classes.label}>
              Feedback
            </Typography>
            <TextField
              placeholder="Please fill in important details you notice while reviewing."
              multiline
              fullWidth
              variant="outlined"
              minRows={4}
              value={feedback}
              onChange={handleChange}
            />
            <div className={classes.textBottomlabel}>
              <div>Maximum of 100 characters</div>
              <div className={classes.textBottomRight}>
                {feedback.length > 0 ? `${feedback.length}/100` : null}
              </div>
            </div>
            {!isEmpty(errorMsg) ? (
              <div className={classes.errorMsgText}>{errorMsg}</div>
            ) : null}
            <div></div>
            <div className={classes.ratingBtns}>
              {buttonDisabled ? (
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  onClick={handleSubmit}
                  className={classes.ratingSubmitBtn}
                  disabled
                >
                  SUBMIT
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  onClick={handleSubmit}
                  style={
                    ratings <= 0 || feedback.trim().length <= 0
                      ? {
                          marginRight: '4px',
                          backgroundColor: '#D9D9D9',
                          color: '#000',
                        }
                      : {
                          marginRight: '4px',
                        }
                  }
                  disabled={ratings <= 0 || feedback.trim().length <= 0}
                >
                  SUBMIT
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
