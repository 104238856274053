import React from 'react';

const AnswerVoteReceived = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.03406 20.532L7.84746 22.7523H6.22065L7.03406 20.532ZM9.0346 25.984H11.1231L8.12226 18.0917H6.0118L3 25.984H5.04451L5.6051 24.4561H8.474L9.0346 25.984Z"
        fill="#17E88F"
      />
      <path
        d="M18.7829 18.0917H16.7934V22.4336L13.7376 18.0917H11.814V25.984H13.8036V21.3674L17.1122 25.984H18.7829V18.0917Z"
        fill="#17E88F"
      />
      <path
        d="M19.5217 23.6427C19.8075 25.1926 21.0936 26.1159 22.9842 26.1159C24.7099 26.1159 26.0729 25.1816 26.0729 23.5657C26.0729 22.3566 25.3255 21.5982 23.9735 21.2904L22.4346 20.9277C22.0169 20.8287 21.7751 20.6419 21.7751 20.3011C21.7751 19.8724 22.1708 19.6086 22.7534 19.6086C23.4349 19.6086 23.8086 19.9384 23.9735 20.3781H25.996C25.7762 19.0041 24.6879 17.9598 22.7424 17.9598C21.0056 17.9598 19.7086 18.9711 19.7086 20.521C19.7086 21.862 20.5879 22.4665 21.7531 22.7084L23.259 23.0381C23.7646 23.159 24.0174 23.3239 24.0174 23.6977C24.0174 24.1813 23.5778 24.4561 22.9512 24.4561C22.3027 24.4561 21.841 24.1923 21.6651 23.6427H19.5217Z"
        fill="#17E88F"
      />
      <g clipPath="url(#clip0_72450_60613)">
        <path
          d="M13.8325 16.595H16.1225V9.725H13.8325V16.595ZM26.4275 10.2975C26.4275 9.66775 25.9122 9.1525 25.2825 9.1525H21.67L22.2139 6.53618L22.2311 6.35298C22.2311 6.11825 22.1338 5.9007 21.9792 5.74613L21.3723 5.145L17.6053 8.91777C17.3935 9.12387 17.2675 9.41012 17.2675 9.725V15.45C17.2675 16.0797 17.7828 16.595 18.4125 16.595H23.565C24.0402 16.595 24.4466 16.3087 24.6184 15.8965L26.3473 11.8604C26.3989 11.7287 26.4275 11.5913 26.4275 11.4425V10.2975Z"
          fill="#17E88F"
        />
      </g>
      <defs>
        <clipPath id="clip0_72450_60613">
          <rect
            width="13.74"
            height="13.74"
            fill="white"
            transform="translate(13.26 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AnswerVoteReceived;
