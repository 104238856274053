import React, { Fragment } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Avatar, Card, CardContent, Typography } from '@material-ui/core';

import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import DesignServicesIcon from '@material-ui/icons/DesignServices';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import BusinessIcon from '@material-ui/icons/Business';
import PreviewIcon from '@material-ui/icons/Preview';
import FrameSourceIcon from '../icons/FrameSourceIcon';
import { IStatisticsCard } from '../types';

const useStyles = makeStyles((theme: Theme) => ({
  statisticsCard: {
    borderRadius: '12px 12px 12px 0',
    backgroundColor: theme.palette.bgVariants.variant1,
    //    position: 'relative',
    display: 'flex',
    minWidth: 240,
    minHeight: 96,
    height: 110,
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 316,
    margin: 'auto 0',
    padding: 12,
    boxShadow: 'none',
  },
  statValue: {
    color: theme.palette.text.primary,
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.2,
    letterSpacing: -0.2,
    zIndex: 1,
  },
  statDescription: {
    color: theme.palette.textVariants.variant1,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: -0.14,
    zIndex: 1,
  },
  iconImage: {
    aspectRatio: '1',
    width: 40,
    borderRadius: 40,
    boxShadow: '-7px 3px 8px rgba(0, 0, 0, 0.3)',
    position: 'absolute',
    zIndex: 2,
    minHeight: 40,
    right: -12,
    top: -12,
    height: 40,
    transform: 'rotate(-30deg)',
    backgroundColor: theme.palette.common.white,
  },
  backgroundImage: {
    aspectRatio: '1.47',
    objectFit: 'contain',
    objectPosition: 'center',
    width: 84,
    position: 'absolute',
    zIndex: 0,
    right: 0,
    bottom: 0,
    height: 57,
  },
  cardContainer: {
    position: 'relative',
  },
  bottomRight: {
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  catergoryIcon: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    margin: theme.spacing(1),
    fill: theme.palette.textVariants.variant2,
  },
}));

export interface StatisticsCardProps extends IStatisticsCard {
  iconSrc: string;
}

const StatisticsCard: React.FC<StatisticsCardProps> = ({
  name,
  value,
  description,
  iconSrc,
  category = 'development',
}) => {
  const classes = useStyles();

  const categories: any = {
    cost: MonetizationOnIcon,
    development: FrameSourceIcon,
    users: GroupAddIcon,
    design: DesignServicesIcon,
    time: AccessTimeIcon,
    enterprise: BusinessIcon,
    prReview: PreviewIcon,
  };

  const RightGradient = () => {
    const theme = useTheme();

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="85"
        height="57"
        viewBox="0 0 85 57"
        fill="none"
        className={classes.bottomRight}
      >
        <path
          d="M84.1109 43.4454C84.1109 58.6142 72.3135 56.9285 57.1447 56.9285C41.976 56.9285 1.00024 56.9285 1.00024 56.9285C-5.99097 4.49435 69.4415 0 84.6102 0C84.6102 0 84.1109 28.2766 84.1109 43.4454Z"
          fill="url(#paint0_linear_92254_17499)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_92254_17499"
            x1="40.7383"
            y1="16.4794"
            x2="72.6659"
            y2="49.8721"
            gradientUnits="userSpaceOnUse"
          >
            {theme.palette.type === 'light' ? (
              <>
                <stop stop-color="white" stop-opacity="0" />
                <stop offset="1" stop-color="white" stop-opacity="0.6" />
              </>
            ) : (
              <>
                <stop stop-color="#102738" stop-opacity="0" />
                <stop offset="1" stop-color="white" stop-opacity="0.39" />
              </>
            )}
          </linearGradient>
        </defs>
      </svg>
    );
  };

  const CategoryIcon = categories[category];

  return (
    <div className={classes.cardContainer}>
      <Avatar aria-label="assistant" className={classes.iconImage}>
        <div dangerouslySetInnerHTML={{ __html: iconSrc }} />
      </Avatar>
      <Card className={classes.statisticsCard}>
        <CardContent>
          <Typography variant="h2" className={classes.statValue}>
            {value}
          </Typography>
          <Typography variant="body1" className={classes.statDescription}>
            {description}
          </Typography>
          {/* <img src={backgroundSrc} alt="" className={classes.backgroundImage} /> */}
        </CardContent>
      </Card>
      <RightGradient />
      {<CategoryIcon className={classes.catergoryIcon} fontSize="medium" />}
    </div>
  );
};

export default StatisticsCard;
