import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PocIcon = () => (
  <SvgIcon
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_80342_48357)">
      <path d="M25.0625 21.5625C23.1296 21.5625 21.5625 23.1296 21.5625 25.0625C21.5625 26.9954 23.1296 28.5625 25.0625 28.5625C26.9954 28.5625 28.5625 26.9954 28.5625 25.0625C28.5625 23.1296 26.9954 21.5625 25.0625 21.5625ZM24.625 26.5561L23.0032 24.9343L23.6218 24.3157L24.625 25.3189L26.5032 23.4407L27.1218 24.0593L24.625 26.5561Z" />
      <path d="M21.5625 1.4375V4.9375H25.0625L21.5625 1.4375Z" />
      <path d="M25.0625 20.6875V5.8125H20.6875V1.4375H1.4375V25.0625H20.6875C20.6875 22.6501 22.6501 20.6875 25.0625 20.6875ZM10.1875 20.25V18.0625H16.3125V20.25C16.3125 20.7334 15.9209 21.125 15.4375 21.125H11.0625C10.5791 21.125 10.1875 20.7334 10.1875 20.25ZM12.375 22.875L11.7918 22H14.7086L14.125 22.875H12.375ZM17.7759 13.1892C16.89 14.3503 16.3401 15.7302 16.3169 17.1875H15.4375V13.8686L16.75 12.5561V10.5038C17.0103 10.3524 17.1875 10.0733 17.1875 9.75C17.1875 9.26656 16.7959 8.875 16.3125 8.875C15.8291 8.875 15.4375 9.26656 15.4375 9.75C15.4375 10.0729 15.6147 10.352 15.875 10.5038V12.1939L14.5625 13.5064V17.1875H13.6875V8.31631C13.9478 8.16494 14.125 7.88581 14.125 7.5625C14.125 7.07906 13.7334 6.6875 13.25 6.6875C12.7666 6.6875 12.375 7.07906 12.375 7.5625C12.375 7.88537 12.5522 8.1645 12.8125 8.31631V17.1875H11.9375V13.5064L10.625 12.1939V10.5038C10.8853 10.3524 11.0625 10.0733 11.0625 9.75C11.0625 9.26656 10.6709 8.875 10.1875 8.875C9.70406 8.875 9.3125 9.26656 9.3125 9.75C9.3125 10.0729 9.48969 10.352 9.75 10.5038V12.5561L11.0625 13.8686V17.1875H10.1875V17.163C10.1875 15.7284 9.64544 14.37 8.76256 13.2391C7.86569 12.0902 7.40063 10.5887 7.61413 8.97475C7.95144 6.42675 10.0335 4.38844 12.5881 4.10012C16.0251 3.71162 18.9375 6.39088 18.9375 9.75C18.9375 11.0437 18.5044 12.2346 17.7759 13.1892Z" />
    </g>
    <defs>
      <clipPath id="clip0_80342_48357">
        <rect
          width="28"
          height="28"
          fill="#000000"
          transform="translate(1 1)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default PocIcon;
