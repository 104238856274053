/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useApi, microsoftAuthApiRef } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';

const useAuthHeader = () => {
  const [authHeader, setAuthHeader] = useState<any>(null);
  const msAuth = useApi(microsoftAuthApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  useEffect(() => {
    msAuth
      .getAccessToken()
      .then((token: string) => {
        setAuthHeader(token);
      })
      .catch((error: any) => {
        setAuthHeader(null);
        notificationApi.sendNotification({
          severity: 'error',
          disapperAfterMs: 2500,
          message: `Error occurred while getting access token - ${error.message}`,
        });
      });
  }, [msAuth]);
  return [authHeader];
};
export default useAuthHeader;
