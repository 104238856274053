import React, { useEffect, useRef, useState } from 'react';
import {
  Dialog,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { PageWithHeader, Content } from '@backstage/core-components';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { HelpComponent } from '../utils/helpComponent';
import HELP_URL from '../utils/helpLinkConstant';
import BackButton from '../utils/backButton';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';
import { useNavigate, useParams } from 'react-router';
import {
  DataInsightsApi,
  DataInsightsApiRef,
  Item,
} from '../../apis/dataInsights';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';
import { useApi } from '@backstage/core-plugin-api';
import { Rating } from '@material-ui/lab';
import { useStarredEntity } from '@backstage/plugin-catalog-react';
import { Alert } from '@material-ui/lab';
import { useStyles } from './common/styles';

/* Component for loading Powerbi */
const DashboardPage = () => {
  const dataInsights: DataInsightsApi = useApi(DataInsightsApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dashboardData, setDashboardData] = useState<Item>();
  const [open, setOpen] = useState<boolean>(false);
  const [iframeRefreshCounter, setIframeRefreshCounter] = useState(0);
  const [isIncognito, setIsIncognito] = useState<boolean>(false);

  const classes = useStyles();

  const { id } = useParams();

  const { toggleStarredEntity, isStarredEntity } = useStarredEntity(id);

  const navigate = useNavigate();
  const navigateToDataInsights = () => {
    navigate('/data-insights');
  };

  /* Get the Item data by id */
  const getItemData = () => {
    setIsLoading(true);
    dataInsights
      .getItemById(id)
      .then((responseData: any) => {
        if (responseData.id) {
          setDashboardData(responseData);
          setIsLoading(false);
        } else {
          navigateToDataInsights();
        }
      })
      .catch(() => {
        notificationApi.sendNotification({
          message: `Failed to load Data`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  useEffect(() => {
    getItemData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  /* use Storage quota size difference to implement the logic for incognito mode detection. */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkIncognito = async () => {
    const quota = await navigator.storage
      .estimate()
      .then(result => result.quota);
    if (quota && quota !== undefined && quota.toString().length <= 10) {
      setIsIncognito(true);
      // console.log('In Incognito mode and powerbi');
    } else {
      setIsIncognito(false);
      // console.log('Not in Incognito mode');
    }
  };

  useEffect(() => {
    checkIncognito();
  }, [checkIncognito]);

  /* Open the full screen Dialog */
  const openFullScreenDialog = () => {
    setOpen(true);
  };

  /* Close the full screen dialog */
  const handleClose = () => {
    setOpen(false);
  };

  /* Do rating on clicking star icon */
  const onClickStarIcon = (event: any) => {
    event?.stopPropagation();
    event?.preventDefault();
    toggleStarredEntity();
  };

  /*  Get element by Xpath */
  const getElementByXpath = (path: string) => {
    try {
      return document.evaluate(
        path,
        document,
        null,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null,
      ).singleNodeValue;
    } catch {
      return null;
    }
  };

  const maxCounter = useRef(0);

  useEffect(() => {
    const onChanged = (event: any) => {
      /*  const signBut = getElementByXpath(
        "//label[text()='Sign-In']/parent::button",
      );
      if (signBut) */

      // Temporary Fix
      if (maxCounter.current < 2) {
        setIframeRefreshCounter(prevState => prevState + 1);
        maxCounter.current++;
      }
    };
    document.addEventListener('visibilitychange', onChanged);
    return () => window.removeEventListener('visibilitychange', onChanged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWithHeader
      title={dashboardData?.title}
      themeId="home"
      subtitle={
        <DevxBreadCrumb
          routes={[
            { type: 'link', link: '/', text: 'Home' },
            {
              type: 'link',
              link: '/data-insights',
              text: 'Data Insights',
            },
            {
              type: 'text',
              link: ' ',
              text: `${dashboardData?.title}`,
            },
          ]}
        />
      }
    >
      <Content>
        <Dialog fullScreen open={open} onClose={handleClose}>
          <iframe
            title="Vulnerability Progress"
            width="100%"
            height="100%"
            src={dashboardData?.url}
            allowFullScreen
          />
          <IconButton className={classes.maxminBut} onClick={handleClose}>
            <FullscreenExitIcon />
          </IconButton>
        </Dialog>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <BackButton />
            <HelpComponent helpUrl={HELP_URL.DATAINSIGHTS_PAGE} />
          </Grid>
        </Grid>
        {isLoading && <LinearProgress />}
        <Grid className={`codePage ${classes.dashboardLayoutPage}`}>
          {isIncognito && (
            <Alert severity="warning" className={classes.alertBox}>
              Please allow third-party cookies for this page to function
              properly.{' '}
              <a
                href={`${window.location.origin}/help/dataInsightCookie/`}
                target="_blank"
                className={classes.anchorTag}
              >
                Learn More
              </a>
            </Alert>
          )}
        </Grid>

        <div className={`codePage ${classes.layoutPage}`}>
          <Grid container className={classes.dashboardCard}>
            <Grid item xs={12} sm={12} className={classes.dashboard}>
              <div>
                <Typography className={classes.dashboardTitle}>
                  {dashboardData?.title}
                </Typography>
                <div className={classes.dashboardDesc}>
                  {dashboardData?.description}
                </div>
              </div>
              <div onClickCapture={onClickStarIcon}>
                <Rating max={1} value={isStarredEntity ? 1 : 0} />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.dashboard}>
              <div className={classes.pbiDashboard}>
                <iframe
                  id="pbiFrame"
                  key={iframeRefreshCounter}
                  title="Report"
                  width="100%"
                  height="541.25"
                  src={dashboardData?.url}
                  allowFullScreen
                />
              </div>

              <IconButton
                className={classes.maxminBut}
                onClick={openFullScreenDialog}
              >
                <FullscreenIcon />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      </Content>
    </PageWithHeader>
  );
};
export default DashboardPage;
