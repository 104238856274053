import React, { useState } from 'react';
// @ts-ignore
import { useApi } from '@backstage/core-plugin-api';
import Suggest from './Suggest';
import * as _ from 'lodash';
import { capabilityTaxonomyApiRef } from '../../../../apis/capabilityTaxonomyApi';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';

export interface AssetSuggestProps {
  label: string;
  value: any;
  onChange: (params: any) => void;
  id: string;
}

const AssetSuggest = ({ label, value, onChange, id }: AssetSuggestProps) => {
  const [options, setOptions] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const capabilityTaxonomyApi = useApi(capabilityTaxonomyApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const getOptionsFromApi = (value: any) => {
    setLoading(true);
    capabilityTaxonomyApi
      .getAssetSuggestions()
      .then((data: any) => {
        setLoading(false);
        const finalOptions = data
          ?.filter(
            (e: any) =>
              `${e?.metadata?.name}`
                ?.toLowerCase()
                ?.indexOf(`${value}`?.toLowerCase()) >= 0 &&
              e?.metadata?.devxstate === 'Approved',
          )
          ?.map((e: any) => {
            return {
              label: e?.metadata?.name,
              value: `${e?.kind}:${e?.metadata?.namespace}/${e?.metadata?.name}`,
              configLocation:
                e?.metadata?.annotations['backstage.io/managed-by-location'],
            };
          });
        setOptions(finalOptions || []);
      })
      .catch((err: any) => {
        setOptions([]);
        setLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred while getting asset suggestions: ${err.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  const onInputChange = (value: any) => {
    getOptionsFromApi(value);
  };

  const onValueChange = (event: any, value: any, reason: string) => {
    if (onChange) {
      if (value) {
        onChange(JSON.stringify(value));
        return;
      }
      onChange('[]');
    }
  };

  if (!_.isString(value) && onChange) {
    onValueChange(undefined, value, '');
  }

  const getOptionLabel = (opt: any) => {
    if (Array.isArray(opt) && opt?.length > 0) {
      return opt[0]?.label;
    }
    return opt?.label || '';
  };

  return (
    <Suggest
      loading={loading}
      options={options}
      label={label}
      value={value}
      id={id}
      onInputChangeDebounced={onInputChange}
      onChange={onValueChange}
      getOptionLabel={getOptionLabel}
      multiple={true}
    />
  );
};
export default AssetSuggest;
