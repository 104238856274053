import React, { useEffect } from 'react';
import { DefaultEditor, Tool } from '@drodil/backstage-plugin-toolbox';
import YAML from 'yaml';

export const YamlFormatter = () => {
  const [input, setInput] = React.useState('');
  const [output, setOutput] = React.useState('');
  // const [spaces, setSpaces] = React.useState(4);
  const sample =
    '- type: car\n' +
    '  name: abc\n' +
    '  stars: 3\n\n\n' +
    '- type: plant\n' +
    '  name: xyz\n' +
    '  stars: 2\n';

  useEffect(() => {
    let obj;
    let err;
    try {
      obj = YAML.parse(input);
    } catch (e) {
      err = e.message;
    }

    if (obj) {
      setOutput(YAML.stringify(obj));
    } else if (input) {
      setOutput(err);
    } else {
      setOutput('');
    }
  }, [input]);

  return (
    <DefaultEditor
      input={input}
      setInput={setInput}
      output={output}
      sample={sample}
      allowFileUpload
      acceptFileTypes=".yaml,.yml"
      allowFileDownload
      downloadFileName="download.yaml"
      downloadFileType="application/yaml"
    />
  );
};

export const yamlFormatterTool: Tool = {
  id: 'yaml-formatter',
  name: 'YAML',
  component: <YamlFormatter />,
  category: 'Format',
  description: 'Format Yaml files',
  headerButtons: [],
};

export default yamlFormatterTool;
