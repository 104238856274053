import React from 'react';

const Asset = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 7.5H15L12.5 5H5C3.625 5 2.5125 6.125 2.5125 7.5L2.5 22.5C2.5 23.875 3.625 25 5 25H25C26.375 25 27.5 23.875 27.5 22.5V10C27.5 8.625 26.375 7.5 25 7.5ZM20 20H22.5V17.5H20V15H22.5V12.5H20V10H25V22.5H20V20ZM20 20H17.5V22.5H5V7.5H11.4625L13.9625 10H17.5V12.5H20V15H17.5V17.5H20V20Z"
        fill="#17E88F"
      />
    </svg>
  );
};

export default Asset;
