import { createApiRef } from '@backstage/core-plugin-api';
import { AxiosInstance } from 'axios';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';

export type AssetSubscriptionCountPair = {
  assetname: string;
  subscribercount: number | string;
};

export interface SubscriberApi {
  getSubscribersCount: () => Promise<any>;
  assetsSubscribe: (data: any) => Promise<any>;
  assetsUnsubscribe: (data: any) => Promise<any>;
  subscriptionSendEmail: (data: object) => Promise<any>;
  getSubscribers: () => Promise<any>;
  getSubscribersByEmail: (email: string) => Promise<any>;
  getAllAssetsSubscriberCount: () => Promise<Array<AssetSubscriptionCountPair>>;
}

export const subscriberApiRef = createApiRef<SubscriberApi>({
  id: 'cbre.devx.api.subscribers',
});

export class SubscriberApiImpl implements SubscriberApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  async getSubscribersCount(): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance.get('service/discovery/subscription').then(res => res.data);
  }

  async assetsSubscribe(data: any): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance
      .post('service/discovery/subscribe', data)
      .then(res => res.data);
  }

  async assetsUnsubscribe(data: any): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance
      .post('service/discovery/unsubscribe', data)
      .then(res => res.data);
  }

  async subscriptionSendEmail(data: object): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance
      .post(`service/discovery/subscription/sendemail`, data)
      .then(res => res.data);
  }

  async getSubscribers(): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance
      .get('service/discovery/subscriptionDashboard')
      .then(res => res.data);
  }

  async getSubscribersByEmail(email: string): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance
      .get('service/discovery/subscriptionDashboard', {
        params: {
          user: email,
        },
      })
      .then(res => res.data);
  }

  async getAllAssetsSubscriberCount(): Promise<Array<AssetSubscriptionCountPair>> {
    const instance = await this.axiosInstancePromise;

    return instance
      .get('service/discovery/cataloginfo/counts')
      .then(res => res.data);
  }
}
