const Constants = {
  pageTitle: {
    mockPage: 'Mock APIs',
  },
  requestTypes: {
    get: {
      value: 'Feature',
      color: '#DBD99A',
    },
    post: {
      value: 'Request',
      color: '#435254',
    },
    put: {
      value: 'Bug',
      color: '#D2785A',
    },
    delete: {
      value: 'Comments',
      color: '#CAD1D3',
    },
    patch: {
      value: 'Other',
      color: '#80BBAD',
    },
  },

  requestTypeOptions: ['GET', 'POST', 'PUT', 'DELETE', 'PATCH'],
};

export default Constants;
