import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CountDownTimerIcon = (props: any) => (
  <SvgIcon viewBox={'48 48'} style={{ transform: 'scale(3)' }} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 3C11 2.44772 11.4477 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 8.73398 3.56678 5.83359 5.98552 4.01023C6.42653 3.67778 7.05355 3.76578 7.38601 4.2068C7.71846 4.64781 7.63046 5.27483 7.18945 5.60728C5.25042 7.06901 4 9.38832 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.92038 16.9463 4.55399 13 4.06189V7C13 7.55228 12.5523 8 12 8C11.4477 8 11 7.55228 11 7V3ZM7.29289 8.29289C7.68342 7.90237 8.31658 7.90237 8.70711 8.29289L12.7071 12.2929C13.0976 12.6834 13.0976 13.3166 12.7071 13.7071C12.3166 14.0976 11.6834 14.0976 11.2929 13.7071L7.29289 9.70711C6.90237 9.31658 6.90237 8.68342 7.29289 8.29289Z"
      fill="#69DDC7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.20002 12C7.64185 12 8.00002 12.3582 8.00002 12.8C8.00002 15.0091 9.79089 16.8 12 16.8C12.4419 16.8 12.8 17.1582 12.8 17.6C12.8 18.0418 12.4419 18.4 12 18.4C8.90723 18.4 6.40002 15.8928 6.40002 12.8C6.40002 12.3582 6.7582 12 7.20002 12Z"
      fill="#003F2D"
    />
    <path
      d="M12.1357 16.0454L14.9534 17.6722L12.1357 19.299L12.1357 16.0454Z"
      fill="#003F2D"
    />
  </SvgIcon>
);

export default CountDownTimerIcon;
