import { Entity } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { scmIntegrationsApiRef } from '@backstage/integration-react';
import { useEffect, useState } from 'react';
import { snykApiRef } from '../../../../../apis/snykApi';
import { extractInfoFromGitUrl } from './utils';

export const useGetBranches = (
  entity: Entity | undefined,
  isOwner: boolean,
) => {
  const [branchList, setBranchList] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const scmIntegration = useApi(scmIntegrationsApiRef);
  const snykApi = useApi(snykApiRef);

  useEffect(() => {
    if (!isOwner) {
      setLoading(false);
      setBranchList([]);
      return;
    }
    if (entity) {
      let sourceLocation =
        entity?.metadata?.annotations?.['backstage.io/source-location'];
      sourceLocation = sourceLocation?.replace('url:', '');
      sourceLocation = sourceLocation?.replace('file:', '');

      const assetstoreid = entity?.metadata?.assetstoreid || '';
      const sc = scmIntegration.byUrl(`${sourceLocation}`);

      if (sc === undefined) {
        throw new Error('This is not supported');
      }

      const extractionResult = extractInfoFromGitUrl(`${sourceLocation}`);

      if (extractionResult === undefined || extractionResult.parse_failed) {
        setLoading(false);
        setBranchList([]);
        return;
      }

      if (sc !== undefined && sc.type === 'azure') {
        // make a call to azure api
        setLoading(true);

        const organization = extractionResult.organization;
        const owner = extractionResult.owner;
        const name = extractionResult.name;

        snykApi
          .getAzureRepositoryBranches(organization, owner, name, assetstoreid)
          .then(value => {
            setLoading(false);
            setBranchList(value || []);
          })
          .catch(() => {
            setLoading(false);
            setBranchList([]);
          });
      } else if (sc !== undefined && sc.type === 'github') {
        // make a call to github api
        setLoading(true);

        const owner = extractionResult.owner;
        const name = extractionResult.name;
        snykApi
          .getGithubRepositoryBranches(owner, name)
          .then(value => {
            setLoading(false);
            setBranchList(value || []);
          })
          .catch(() => {
            setLoading(false);
            setBranchList([]);
          });
      }
    }
  }, [entity, isOwner]);

  return { loading, branchList };
};
