import React from 'react';

const AcceptedAnswer = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.03406 20.532L7.84747 22.7524H6.22066L7.03406 20.532ZM9.03461 25.984H11.1231L8.12227 18.0917H6.01181L3 25.984H5.04451L5.6051 24.4561H8.47402L9.03461 25.984Z"
        fill="#17E88F"
      />
      <path
        d="M18.783 18.0917H16.7934V22.4336L13.7376 18.0917H11.814V25.984H13.8036V21.3674L17.1122 25.984H18.783V18.0917Z"
        fill="#17E88F"
      />
      <path
        d="M19.5217 23.6427C19.8075 25.1926 21.0936 26.1159 22.9842 26.1159C24.71 26.1159 26.073 25.1816 26.073 23.5658C26.073 22.3566 25.3255 21.5982 23.9735 21.2904L22.4346 20.9277C22.0169 20.8288 21.7751 20.6419 21.7751 20.3011C21.7751 19.8724 22.1708 19.6086 22.7534 19.6086C23.4349 19.6086 23.8086 19.9384 23.9735 20.3781H25.996C25.7762 19.0041 24.688 17.9598 22.7424 17.9598C21.0057 17.9598 19.7086 18.9711 19.7086 20.521C19.7086 21.862 20.588 22.4666 21.7531 22.7084L23.259 23.0381C23.7647 23.1591 24.0175 23.3239 24.0175 23.6977C24.0175 24.1813 23.5778 24.4561 22.9513 24.4561C22.3027 24.4561 21.8411 24.1923 21.6652 23.6427H19.5217Z"
        fill="#17E88F"
      />
      <g clipPath="url(#clip0_72450_60612)">
        <path
          d="M26.4275 10.87L25.0306 9.27274L25.2253 7.16021L23.1585 6.69076L22.0765 4.85876L20.13 5.69461L18.1835 4.85876L17.1015 6.68504L15.0348 7.14876L15.2294 9.26701L13.8325 10.87L15.2294 12.4673L15.0348 14.5855L17.1015 15.055L18.1835 16.8813L20.13 16.0397L22.0765 16.8755L23.1585 15.0493L25.2253 14.5798L25.0306 12.4673L26.4275 10.87ZM19.0365 13.5722L16.861 11.391L17.7083 10.5437L19.0365 11.8776L22.3857 8.51704L23.233 9.36434L19.0365 13.5722Z"
          fill="#17E88F"
        />
      </g>
      <defs>
        <clipPath id="clip0_72450_60612">
          <rect
            width="13.74"
            height="13.74"
            fill="white"
            transform="translate(13.26 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AcceptedAnswer;
