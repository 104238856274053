import React from 'react';
import { isEmpty } from 'lodash';
import { Grid, Button, makeStyles } from '@material-ui/core';
import { useNavigate, useLocation } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { addHttpOnLinks } from '../utils/addHttpsOnLinks';
import { useApi } from '@backstage/core-plugin-api';
import { entityConversionApiRef } from '../../apis/entityConversionApi';
import { notificationApiRef } from '../../apis/notificationApi';
import { EXTENSION_COMPONENT_TYPE } from '../utils/constant';

const useStyles = makeStyles(theme => ({
  buttonSaveClose: {
    marginLeft: '4px',
    marginTop: '10px',
    marginBottom: '100px',
    ['@media (max-width:920px)']: {
      marginLeft: '0',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginTop: '20px',
    },
  },
  cancelBtn: {
    ['@media (max-width:920px)']: {
      marginTop: '20px',
    },
  },
  saveBtn: {
    marginRight: '4px',
    backgroundColor: `${theme.palette.primary.main}'!important'`,
    color: `${theme.palette.text.contrast}'!important'`,
  },
  save: {
    marginRight: '4px',
  },
}));

export const ButtonSaveClose = props => {
  const {
    isPending,
    defaultEntity,
    defaultLinks,
    defaultTags,
    defaultTechdocs,
    lifCycleSelected,
    typeSelected,
    downloadLink,
    setDefaultEntity,
    setIsPending,
    setOpen,
    isButtonDisable,
    setGetStatus,
    setIsButtonDisabled,
    name,
    kind,
    description,
    supportEmail,
  } = props;

  const entityConversionApi = useApi(entityConversionApiRef);
  const notificationApi = useApi(notificationApiRef);

  const navigate = useNavigate();
  const classes = useStyles();
  const locationUrl = useLocation();
  const urlString = locationUrl.pathname;
  const replaceUrl = urlString.replace(/(\/edit)/g, '');

  const history = createBrowserHistory();
  const goToPreviousPath = () => {
    history.back();
  };
  const handleRedirect = () => {
    navigate(replaceUrl);
  };

  const handleSubmit = event => {
    setIsButtonDisabled(true);
    event.preventDefault();
    const clone = defaultEntity;
    if (!isEmpty(clone)) {
      clone.metadata.links = addHttpOnLinks(defaultLinks);
      clone.metadata.tags = defaultTags;
      clone.metadata.annotations['backstage.io/techdocs-ref'] = defaultTechdocs;

      clone.spec.lifecycle = lifCycleSelected;
      clone.spec.type = typeSelected;
      clone.spec.downloadLink = typeSelected.toLowerCase() === EXTENSION_COMPONENT_TYPE ? downloadLink : '';
      clone.metadata.description = description;
      clone.spec.supportEmail = supportEmail;
      setDefaultEntity(clone);
    }

    setIsPending(true);
    setOpen(true);

    entityConversionApi
      .saveData(name, kind, clone)
      .then(res => {
        setIsPending(false);
        setGetStatus(res?.status);
        setIsButtonDisabled(true);
        notificationApi.sendNotification({
          message: `Success.. Changes saved! Changes may take upto 5 minutes to reflect.`,
          disapperAfterMs: 2500,
          severity: 'success',
          callback: handleRedirect,
        });
      })
      .catch(err => {
        setIsPending(false);
        setIsButtonDisabled(true);
        notificationApi.sendNotification({
          message: `Error occurred - ${err?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  return (
    <>
      <Grid container className={classes.buttonSaveClose}>
        {!isPending && (
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleSubmit}
            className={isButtonDisable ? classes.saveBtn : classes.save}
            disabled={isButtonDisable}
          >
            SAVE {isButtonDisable}
          </Button>
        )}
        {isPending && (
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleSubmit}
            style={{
              marginRight: '4px',
              backgroundColor: '#D9D9D9',
              color: '#fff',
            }}
            disabled
          >
            SAVING...
          </Button>
        )}

        <Button
          variant="outlined"
          size="medium"
          color="primary"
          onClick={goToPreviousPath}
          className={classes.cancelBtn}
        >
          CANCEL
        </Button>
      </Grid>
    </>
  );
};
