import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SnippetFolderIcon = () => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    fill="#000000"
  >
    <path d="M13.2332 8.75004L14.5832 10.1V12.9167H12.0832V8.75004H13.2332ZM18.3332 6.66671V15C18.3332 15.9167 17.5832 16.6667 16.6665 16.6667H3.33317C2.4165 16.6667 1.6665 15.9167 1.6665 15L1.67484 5.00004C1.67484 4.08337 2.4165 3.33337 3.33317 3.33337H8.33317L9.99984 5.00004H16.6665C17.5832 5.00004 18.3332 5.75004 18.3332 6.66671ZM15.8332 9.58337L13.7498 7.50004H10.8332V14.1667H15.8332V9.58337Z" />
  </SvgIcon>
);

export default SnippetFolderIcon;
