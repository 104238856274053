import React, { useEffect, useState, useContext } from 'react';
import {
  Grid,
  TablePagination,
  Paper,
  LinearProgress,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { HelpComponent } from '../utils/helpComponent';
import { useCustomStyles } from './leaderBoardStyles';
import BackButton from '../utils/backButton';
import LeaderBoardContentHeader from './leaderBoardContainerHeader';
import { AuthContext } from '../../providers/AuthProvider';
import HELP_URL from '../utils/helpLinkConstant';
import { PromotionContext } from '../../providers/PromotionDataProvider';
import LeaderBoardCard from './Grid/leaderBoardCard';
import {
  GamificationApi,
  gamificationApiRef,
} from '../../apis/gamificationApis';
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';
import FilterButtons from './filterButtons';
import LeftFilterSection from './leftFilterSection';

export interface LeaderBoardPageProps {
  isAdhocPointPage: boolean;
  onclickHandler: (data: any) => void;
}

const LeaderBoardPage = (props: LeaderBoardPageProps) => {
  const { isAdhocPointPage, onclickHandler } = props;
  const classes = useCustomStyles();
  const [Data, setData] = useState<any>([]);
  const [loginUserData, setLoginUserData] = useState<any>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { profEmail } = useContext(AuthContext);
  const gamificationApi: GamificationApi = useApi(gamificationApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const promotionContextData = useContext(PromotionContext);

  const [showedContent, setShowedContent] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [filterText, setFilterText] = useState('');

  const [selectedBtn, setSelectedBtn] = useState('cumulative');
  const [selectedFilterbtn, setSelectedFilterbtn] = useState('');

  const contentPerPage = 20;

  const filterString = (str: string) => str.replace(/[^a-zA-Z0-9]/g, '');
  const [filteredData, setFilteredData] = useState([]);

  const ownerData = (data: any) => {
    const loggedinOwnerData =
      data &&
      data?.find((item: any) => {
        return item?.email?.toLowerCase() === profEmail?.toLowerCase();
      });
    setLoginUserData(loggedinOwnerData);
  };

  const getDataWithFiltter = (value: string) => {
    setIsLoading(true);
    setLoginUserData({ displayname: profEmail, email: profEmail, score: 0 });
    gamificationApi
      .getLeaderBoardWithFilter(value)
      .then((res: any) => {
        setData(res);
        ownerData(res);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsLoading(false);
        setData([]);
        ownerData('');
        notificationApi.sendNotification({
          message: `Error occurred - ${error?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  const handleYearly = (value: string) => {
    setSelectedBtn(value);
    getDataWithFiltter(value);
    setFilterText('');
  };

  const handleCumulative = (value: string) => {
    setSelectedBtn('cumulative');
    getDataWithFiltter(value);
    setFilterText('');
  };

  const handleFilter = (value: string) => {
    // setSelectedMonth(selectedItem);
    // setAnchorEl(null);
    setSelectedFilterbtn(value);
    getDataWithFiltter(value);
    setFilterText('');
  };

  const handleClick = (item: string) => {
    if (item === 'monthly') {
      getDataWithFiltter('cm');
      setSelectedFilterbtn('cm');
    } else if (item === 'quarterly') {
      getDataWithFiltter('cq');
      setSelectedFilterbtn('cq');
    } else {
      getDataWithFiltter('cy');
      setSelectedFilterbtn('cy');
    }
    setSelectedBtn(item);
    setFilterText('');
  };

  useEffect(() => {
    gamificationApi
      .getRewardsLevels()
      .then((res: any) => {
        promotionContextData.setGamificationlevelData(res);
      })
      .catch((error: any) => {
        notificationApi.sendNotification({
          message: `Error occurred - ${error?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (profEmail) {
      setLoginUserData('');
      gamificationApi
        .getLeaderBoard()
        .then((res: any) => {
          setData(res);
          ownerData(res);
          setIsLoading(false);
        })
        .catch((error: any) => {
          setIsLoading(false);
          notificationApi.sendNotification({
            message: `Error occurred - ${error?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gamificationApi, profEmail]);

  useEffect(() => {
    setCurrentIndex(0);
  }, [filteredData]);

  useEffect(() => {
    const begin = currentIndex * contentPerPage;
    const end = begin + contentPerPage;
    setShowedContent([...filteredData.slice(begin, end)]);
    setTotalCount(filteredData.length);
  }, [contentPerPage, currentIndex, filteredData]);

  const onPageChange = (
    event: React.SyntheticEvent | null,
    pageNumber: number,
  ): void => {
    if (event) {
      event.preventDefault();
    }
    setCurrentIndex(pageNumber);
  };

  useEffect(() => {
    if (filterText === '') {
      setFilteredData(Data);
    } else if (filterText.length > 1) {
      setFilteredData(
        Data.filter((c: any) => {
          let match = false;
          if (
            filterString(c.displayname)
              .toLowerCase()
              .includes(filterString(filterText).toLowerCase())
          ) {
            match = true;
          }

          return match;
        }),
      );
    }
  }, [filterText, Data]);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <div className={classes.leaderBoardPageContainer}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.leaderBoardPageSearch}
        >
          <BackButton />
          <HelpComponent
            helpUrl={
              isAdhocPointPage ? HELP_URL.adhocPoints : HELP_URL.leaderBoard
            }
          />
        </Grid>
        {!isAdhocPointPage && (
          <LeftFilterSection
            selectedBtn={selectedBtn}
            handleCumulative={handleCumulative}
            handleClick={handleClick}
            handleYearly={handleYearly}
          />
        )}

        <Grid
          item
          xs={12}
          sm={12}
          md={isAdhocPointPage ? 12 : 10}
          lg={isAdhocPointPage ? 12 : 10}
        >
          <Grid container>
            {!isAdhocPointPage && selectedBtn !== 'cumulative' && (
              <FilterButtons
                selectedBtn={selectedBtn}
                selectedFilterbtn={selectedFilterbtn}
                handleFilter={handleFilter}
              />
            )}
            {Data && Data.length === 0 && (
              <div>
                <MuiAlert severity="info">No data Available</MuiAlert>
              </div>
            )}
            {Data && Data.length > 0 && loginUserData && !isAdhocPointPage && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <LeaderBoardCard
                  data={loginUserData}
                  isYou
                  selectedBtn={selectedBtn}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <h2>Leaderboard</h2>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <LeaderBoardContentHeader setFilterText={setFilterText} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {filteredData !== undefined &&
                showedContent &&
                showedContent.length > 0 &&
                showedContent.map((item: any, index: number) => (
                  <LeaderBoardCard
                    data={item}
                    key={`LeaderBoardCard${index}`}
                    isAdhocPointPage={isAdhocPointPage}
                    onclickHandler={onclickHandler}
                    profEmail={profEmail}
                    selectedBtn={selectedBtn}
                  />
                ))}
            </Grid>

            {filteredData !== undefined && filteredData.length !== 0 && (
              <div className={classes.PagePaginationContainer}>
                <Paper>
                  <TablePagination
                    SelectProps={{
                      title: 'perPageSelect',
                      native: true,
                    }}
                    component="div"
                    page={currentIndex}
                    rowsPerPage={contentPerPage}
                    count={totalCount}
                    onPageChange={onPageChange}
                    rowsPerPageOptions={[]}
                  />
                </Paper>
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default LeaderBoardPage;
