import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  Chip,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  useTheme
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { AuthContext } from '../../../providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import InfoBadge from '../../common/InfoBadge';
import NotifyActiveIcon from '../../Root/icons/notifyActiveIcon';
import NotifyInactiveIcon from '../../Root/icons/notifyInactiveIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tagCard: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
      padding: '10px',
      marginTop: '10px',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      alignSelf: 'stretch',
      justifyContent: 'space-between',
    },
    actionIcons: {
      padding: '0px 0px 0px 8px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    metadatatext: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 6,
      WebkitBoxOrient: 'vertical',
      minHeight: '120px',
      [theme.breakpoints.down('sm')]: {
        overflow: 'auto',
        textOverflow: 'unset',
        display: 'inline',
        WebkitLineClamp: 'unset',
        WebkitBoxOrient: 'unset',
      },
    },
    moreText: {
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        cursor: 'default',
        pointerEvents: 'none',
      },
    },
    badgeContainer: {
      display: 'flex'
    },
    rightBadge: {
      marginLeft: '10px'
    }
  }),
);
const TagCard = (props: any) => {
  const { tag, handleEdit, handleDelete, handleSubscribe, subscribeData } =
    props;

  const [isOpen, setPopupOpen] = useState(false);
  const [manipulateTag, setManipulate] = useState();
  const theme = useTheme();

  const classes = useStyles();
  const { isAdmin } = useContext(AuthContext);
  const navigate = useNavigate();
  const navigateToQuestionPage = (tagName: string) => {
    navigate('/information-exchange/conversations', {
      state: { tagName: `[${tagName?.trim()}]` },
    });
  };

  useEffect(() => {
    if (tag) tag.subscribe = false;
    if (subscribeData && subscribeData.length) {
      if (subscribeData.includes(tag.id)) {
        tag.subscribe = true;
      }
    }
    setManipulate(tag);
  }, [subscribeData]);

  const handelTextPopup = () => {
    setPopupOpen(!isOpen);
  };

  return (
    <>
      <Card className={classes.tagCard}>
        <div className={classes.cardContent}>
          <Chip
            label={manipulateTag?.tag}
            clickable
            onClick={() => navigateToQuestionPage(manipulateTag?.tag)}
          />

          <div>
            <IconButton
              className={classes.actionIcons}
              onClick={() => handleSubscribe(manipulateTag)}
            >
              {subscribeData && subscribeData.includes(tag.id) ? (
                <NotifyActiveIcon color={theme.palette.primary.main} />
              ) : (
                <NotifyInactiveIcon color={theme.palette.primary.main} />
              )}
            </IconButton>
            {isAdmin && (
              <>
                <IconButton
                  className={classes.actionIcons}
                  onClick={() => handleEdit(manipulateTag)}
                >
                  <EditIcon color="primary" />
                </IconButton>
                <IconButton
                  className={classes.actionIcons}
                  onClick={() => handleDelete(manipulateTag)}
                >
                  <DeleteIcon color="primary" />
                </IconButton>
              </>
            )}
          </div>
        </div>

        <div className={classes.metadatatext}>
          <span onClick={handelTextPopup} className={classes.moreText}>
            {manipulateTag?.metadata}
          </span>
        </div>
        <div className={classes.badgeContainer}>
          <div id="subscriber-count">
          <InfoBadge label="Subscribers" value={tag?.subscribercount || '0'} />
        </div>
        <div className={classes.rightBadge}>
          <InfoBadge label="Conversations" value={manipulateTag?.conversations || 0}/>
        </div>
        </div>
        <Dialog open={isOpen} onClose={handelTextPopup} fullWidth={true}>
          <DialogTitle id="dialog-title">
            {manipulateTag?.tag}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handelTextPopup}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>{manipulateTag?.metadata} </DialogContent>
        </Dialog>
      </Card>
    </>
  );
};

export default TagCard;
