/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { Page, Header } from '@backstage/core-components';
import { TablePagination, Paper, LinearProgress } from '@material-ui/core';
import { HelpComponent } from '../utils/helpComponent';
import { useCustomStyles } from './FeedbackStyles';
import { Grid } from '@material-ui/core';
import BackButton from '../utils/backButton';
import FeedbackContentHeader from './FeedbackContainerHeader';
import FeedbackGrid from './Grid/FeedBackGrid';
import { AuthContext } from '../../providers/AuthProvider';
import Constants from './Constants';
import HELP_URL from '../utils/helpLinkConstant';
import { useApi } from '@backstage/core-plugin-api';
import { feedbackApiRef } from '../../apis/feedbackApis';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';
import { useFilteredFeedbacks } from './hooks/useFilteredFeedbacks';
import { useFilteredCategories } from './hooks/useFilteredCategories';
import { useFilteredStatus } from './hooks/useFilteredStatus';

const FeedbackPage = () => {
  const classes = useCustomStyles();
  const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showedContent, setShowedContent] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [filterText, setFilterText] = useState('');
  const { profEmail, isAdmin } = useContext(AuthContext);
  const [contentPerPage, setContentPerPage] = useState(20);

  const [filterOption, setFilterOption] = useState({
    category: 'All categories',
    status: 'All status',
  });

  const { filteredFeedbacks } = useFilteredFeedbacks(
    content,
    filterOption,
    filterText,
  );

  const { categories } = useFilteredCategories(content);
  const { status } = useFilteredStatus(content);

  const feedbackApi = useApi(feedbackApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  useEffect(() => {
    if (profEmail) {
      setIsLoading(true);
      feedbackApi
        .getAllFeedbacks(profEmail.toLocaleLowerCase())
        .then((res: any) => {
          if (res && res.data.data.length > 0) {
            setContent(res.data.data);
            setCurrentIndex(0);
            setIsLoading(false);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          notificationApi.sendNotification({
            message: `Error occurred - ${err?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });
    }
  }, [profEmail]);

  useEffect(() => {
    setCurrentIndex(0);
  }, [filteredFeedbacks]);

  useEffect(() => {
    const begin = currentIndex * contentPerPage;
    const end = begin + contentPerPage;
    setShowedContent([...filteredFeedbacks.slice(begin, end)]);
    setTotalCount(filteredFeedbacks.length);
  }, [currentIndex, filteredFeedbacks]);

  const onPageChange = (
    event: React.SyntheticEvent | null,
    pageNumber: number,
  ): void => {
    if (event) {
      event.preventDefault();
    }
    setCurrentIndex(pageNumber);
  };

  return (
    <Page themeId="feedback">
      <Header
        title={Constants.pageTitle.feedbackPage}
        pageTitleOverride="Feedback"
        subtitle={
          <DevxBreadCrumb
            routes={[
              {
                type: 'link',
                link: '/',
                text: 'Home',
              },
              {
                type: 'text',
                link: '',
                text: 'Feedback',
              },
            ]}
          />
        }
      />
      <div className={classes.feedbackPageContainer}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.feedbackPageSearch}
          >
            <BackButton />
            <HelpComponent helpUrl={HELP_URL.feedbackUrl} disabled />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FeedbackContentHeader
              setFilterText={setFilterText}
              categories={categories}
              status={status}
              filterOption={filterOption}
              onFilter={setFilterOption}
            />
          </Grid>
          {isLoading && <LinearProgress />}
          {filteredFeedbacks !== undefined && showedContent && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FeedbackGrid
                content={showedContent}
                isAdmin={isAdmin}
                allContent={filteredFeedbacks}
              />
            </Grid>
          )}
          {filteredFeedbacks && filteredFeedbacks.length !== 0 && (
            <div className={classes.PagePaginationContainer}>
              <Paper>
                <TablePagination
                  SelectProps={{
                    title: 'perPageSelect',
                    native: true,
                  }}
                  component="div"
                  page={currentIndex}
                  rowsPerPage={contentPerPage}
                  count={totalCount}
                  onPageChange={onPageChange}
                  rowsPerPageOptions={[]}
                />
              </Paper>
            </div>
          )}
        </Grid>
      </div>
    </Page>
  );
};

export default FeedbackPage;
