import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import AllProjectsPage from './components/AllProjectsPage';
import TPTabs from './TPTabs';

const TPRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AllProjectsPage />} />
      <Route path="/project/:projectId/*" element={<TPTabs />} />
    </Routes>
  );
};

export default TPRoutes;
