import React, { useContext } from 'react';
import {
  Chip,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@material-ui/core';
import { useStyles } from '../../styles';
import { IActivityLog, IAdmin } from '../../interfaces';
import { DateTime } from 'luxon';
import DeleteIcon from '@material-ui/icons/Delete';
import { AuthContext } from '../../../../providers/AuthProvider';

const AdminsTable = ({
  loading,
  admins,
  onDelete,
  showActions,
}: {
  loading: boolean;
  admins: IAdmin[];
  onDelete: Function;
  showActions: boolean;
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { profEmail } = useContext(AuthContext);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Admins</TableCell>
            <TableCell align="left">Email id</TableCell>
            <TableCell align="left">Added by</TableCell>
            <TableCell align="left">Added on</TableCell>
            {showActions && <TableCell align="left">Actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {admins?.map(row => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="left">
                <span>{row.email}</span>
              </TableCell>
              <TableCell align="left">{row.added_by_name || '-'}</TableCell>
              <TableCell align="left">
                {DateTime.fromISO(row.added_at).toFormat(
                  'dd MMM yyyy, hh:mm a',
                )}
              </TableCell>
              {showActions && (
                <TableCell align="left" className={classes.statusCell}>
                  <IconButton
                    onClick={() => onDelete(row.id)}
                    disabled={
                      profEmail.toLowerCase() === row.email.toLowerCase()
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {admins?.length === 0 && !loading && (
        <Table aria-label="simple table">
          <TableRow key="noActivity">
            <TableCell component="th" scope="row">
              No admins found
            </TableCell>
          </TableRow>
        </Table>
      )}
    </TableContainer>
  );
};

export default AdminsTable;
