import { useApi } from '@backstage/core-plugin-api';
import {
  Button,
  createStyles,
  Grid,
  LinearProgress,
  makeStyles,
  FormHelperText,
  Theme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  EditAnswerRequest,
  InformationExchangeApi,
  informationExchangeApiRef,
  PostAnswerRequest,
} from '../../../apis/informationExchange';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import RichEditor from '../../common/RichEditor';
import { POST_MAX_SIZE } from '../../utils/constant';
import { byteSize } from '../../utils/Utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ansTitle: {
      color: theme.palette.text.secondary,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '24px',
      alignItems: 'center',
      display: 'flex',
      margin: '0px 0px 0px 10px',
    },
    postAnsBtn: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      padding: '12px 16px',
      background: theme.palette.primary.main,
      borderRadius: '4px',
      textTransform: 'capitalize',
      marginTop: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    editAnsBtn: {
      padding: '12px 16px',
      background: theme.palette.primary.main,
      borderRadius: '4px',
      textTransform: 'capitalize',
      marginTop: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    cancelAnsBtn: {
      padding: '12px 16px',
      textAlign: 'center',
      color: theme.palette.primary.main,
      border: '1px solid ' + theme.palette.primary.main,
      borderRadius: '4px',
      marginTop: '15px',
      marginLeft: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginLeft: '0',
      },
    },
    descError: {
      marginLeft: '14px',
      marginRight: '14px',
      color: theme.palette.errorText,
      fontSize: ' 0.75rem',
      marginTop: '14px',
    },
  }),
);
const PostAnswer = (props: any) => {
  const { qid, onSuccess, editData = null, onEditSuccess, onCancel } = props;
  const classes = useStyles();

  const informationExchange: InformationExchangeApi = useApi(
    informationExchangeApiRef,
  );
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const [htmlValue, setHtmlValue] = useState('');
  const [answerError, setAnswerError] = useState('');
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /* Handle editor change */
  const handleEditorChange = (value: any) => {
    setHtmlValue(value);
    if (byteSize(value) > 5000000) {
      setAnswerError('File size should not exceeed 5MB');
    } else {
      setAnswerError('');
    }
  };

  const validateForm = () => {
    const errors: string[] = [];
    if (
      htmlValue == undefined ||
      htmlValue == '<p><br></p>' ||
      (htmlValue !== undefined && String(htmlValue).trim()?.length === 0)
    ) {
      setAnswerError('Answer is required');
      errors.push('Answer is required');
    }
    if (htmlValue != undefined && byteSize(htmlValue) > POST_MAX_SIZE) {
      setAnswerError('Answer must not exceed 5MB');
      errors.push('Answer must not exceed 5MB');
    }
    return errors;
  };

  /* Handler for Post Answer */
  const handlePostAnswer = () => {
    setIsLoading(true);
    const errors = validateForm();
    if (errors.length > 0) {
      setIsLoading(false);
      return;
    }

    const ansReqData: PostAnswerRequest = {
      pid: qid,
      detail: htmlValue,
    };

    informationExchange
      .postAnswer(ansReqData)
      .then((data: any) => {
        setIsLoading(false);
        setRefreshCounter(prev => prev + 1);
        notificationApi.sendNotification({
          message: `Answer posted successfully`,
          disapperAfterMs: 2500,
          severity: 'success',
        });
        onSuccess(data.answer);
      })
      .catch((error: any) => {
        setIsLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  const handleEditAnswer = () => {
    setIsLoading(true);
    const errors = validateForm();
    if (errors.length > 0) {
      setIsLoading(false);
      return;
    }

    const ansReqData: EditAnswerRequest = {
      id: editData.id,
      detail: htmlValue,
    };

    informationExchange
      .editAnswer(ansReqData)
      .then((data: any) => {
        setIsLoading(false);
        setRefreshCounter(prev => prev + 1);
        notificationApi.sendNotification({
          message: `Answer Edited successfully`,
          disapperAfterMs: 2500,
          severity: 'success',
        });
        onEditSuccess({ id: editData.id, detail: htmlValue });
      })
      .catch((error: any) => {
        setIsLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };
  const handleCancelEditAnswer = () => {
    onCancel();
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <div className={classes.ansTitle}>
          Your Answer {editData && '- Edit Mode'}
        </div>
        <RichEditor
          onChange={handleEditorChange}
          key={refreshCounter}
          data={editData?.detail}
        />
        {answerError && (
          <FormHelperText error className={classes.descError}>
            {answerError}
          </FormHelperText>
        )}
      </Grid>
      {isLoading && (
        <Grid item xs={12} sm={12}>
          <LinearProgress />
        </Grid>
      )}
      {/* Post Your Answer Button */}

      <Grid item xs={12} sm={12}>
        {editData ? (
          <>
            <Button
              className={classes.editAnsBtn}
              variant="contained"
              color="primary"
              onClick={handleEditAnswer}
              disabled={isLoading}
            >
              Save Your Answer
            </Button>
            <Button
              className={classes.cancelAnsBtn}
              variant="outlined"
              color="primary"
              onClick={handleCancelEditAnswer}
              disabled={isLoading}
            >
              Cancel
            </Button>
          </>
        ) : (
          <Button
            className={classes.postAnsBtn}
            variant="contained"
            color="primary"
            onClick={handlePostAnswer}
            disabled={isLoading}
          >
            Post Your Answer
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
export default PostAnswer;
