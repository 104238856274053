import { emailRegex } from './Utils';

export const handleApiApmID = (
  e,
  setApiApmId,
  setValidatorApiApmID,
  setIsButtonDisabled,
) => {
  e.preventDefault();
  const value = e.target.value;

  if (value.length <= 20) {
    setApiApmId(value);
    setValidatorApiApmID(false);
    setIsButtonDisabled(false);
  } else {
    setValidatorApiApmID(true);
  }
  return 'changed';
};

export const handleCatagory = (
  e,
  setApiCatagory,
  setValidatorApiCatagory,
  setIsButtonDisabled,
) => {
  e.preventDefault();

  const value = e.target.value;

  if (value.length <= 20) {
    setApiCatagory(value);
    setValidatorApiCatagory(false);
    setIsButtonDisabled(false);
  } else {
    setValidatorApiCatagory(true);
  }
  return 'changed';
};

export const handleSegment = (
  e,
  setApiSegment,
  setValidatorSegment,
  setIsButtonDisabled,
) => {
  e.preventDefault();
  const value = e.target.value;

  if (value.length <= 20) {
    setApiSegment(value);
    setValidatorSegment(false);
    setIsButtonDisabled(false);
  } else {
    setValidatorSegment(true);
  }
  return 'changed';
};

export const handleSupportEmail = (
  e,
  setApiSupportEmail,
  setValidatorApiSupportEmail,
  setIsButtonDisabled,
) => {
  e.preventDefault();

  const value = e.target.value;
  const emailReg = new RegExp(emailRegex);

  if (value === '' || emailReg.test(value)) {
    setApiSupportEmail(value);
    setValidatorApiSupportEmail(false);
    setIsButtonDisabled(false);
  } else {
    setValidatorApiSupportEmail(true);
    setIsButtonDisabled(true);
  }
  return 'changed';
};
