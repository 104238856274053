/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { useEffect, useState } from 'react';
import { promptApiRef } from '../../../apis/promptManagementApi';
import { useUserProfile } from '../../utils/useGetUserProfile';

export const useBuildPrompt = () => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const promptManagementApi = useApi(promptApiRef);
  const { accessToken } = useUserProfile();

  const [enhancedPrompt, setEnhancedPrompt] = useState<string>('');
  const [regenerationToken, setRegenerationToken] = useState<string>('');
  const [promptInprogress, setPromptInProgress] = useState<boolean>(false);
  const [promptLoaded, setPromptLoaded] = useState<boolean>(false);

  const onStart = (response: any) => {
    setRegenerationToken(response.headers.get('regeneration_token'));
  };

  const onStop = () => {
    setPromptLoaded(true);
  };
  const onData = (data: string) => {
    setEnhancedPrompt(preState => {
      const chunkData = data ? data : '\n';
      return preState + chunkData;
    });
  };

  const onError = () => {};

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    enhancedPrompt.trim()
      ? setPromptInProgress(true)
      : setPromptInProgress(false);
  }, [enhancedPrompt]);

  const generatePrompt = async (data: any) => {
    if (promptManagementApi) {
      try {
        await promptManagementApi.buildPrompt(
          data,
          accessToken,
          onStart,
          onStop,
          onData,
          onError,
        );
      } catch (error) {
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };
  const generatePromptV2 = async (data: any) => {
    if (promptManagementApi) {
      try {
        await promptManagementApi.buildPromptV2(
          data,
          accessToken,
          onStart,
          onStop,
          onData,
          onError,
        );
      } catch (error) {
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };

  return {
    generatePrompt,
    generatePromptV2,
    enhancedPrompt,
    setEnhancedPrompt,
    regenerationToken,
    setRegenerationToken,
    promptInprogress,
    promptLoaded,
    setPromptLoaded,
  } as const;
};
