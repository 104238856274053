/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Button,
  Typography,
  DialogActions,
  Chip,
  Box,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Entry } from '../../utils/types';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    entryDetailsPopup: {
      color: theme.palette.text.secondary,
    },
    actionsSection: {
      padding: theme.spacing(2),
    },
    actionButton: {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '4px',
      marginLeft: theme.spacing(2),
    },
    content: {
      padding: theme.spacing(2),
    },
    popupDetailSection: {
      gap: `${theme.spacing(2)}px`,
      margin: `${theme.spacing(0.25)}px 0px`,
      display: 'flex',
    },
    entryUrl: {
      color: theme.palette.primary.main,
    },
    labelRadar: {
      minWidth: `${theme.spacing(10)}px`,
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

const EntryDetailsPopup = ({
  open,
  onClose,
  onOkClicked,
  entry,
}: {
  open: boolean;
  onClose: Function;
  onOkClicked: Function;
  entry: Entry | null;
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      classes={{ paper: classes.entryDetailsPopup }}
    >
      <DialogTitle className={classes.title}>
        <Typography variant="h6">{entry?.title}</Typography>
        <IconButton className={classes.closeButton} onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Box component="div" className={classes.body}>
          <div className={classes.popupDetailSection}>
            <span className={classes.labelRadar}>License URL:</span>
            <span>
              {entry?.licenseurl !== '' ? (
                <a
                  className={classes.entryUrl}
                  href={entry?.licenseurl}
                  target="_blank"
                >
                  {entry?.licenseurl}
                </a>
              ) : (
                'no url'
              )}
            </span>
          </div>
          <div className={classes.popupDetailSection}>
            <span className={classes.labelRadar}>Description:</span>
            <span>
              {entry?.description !== ''
                ? entry?.description
                : 'no description'}
            </span>
          </div>
        </Box>
      </DialogContent>

      <DialogActions className={classes.actionsSection}>
        <Grid>
          <Button
            className={classes.actionButton}
            onClick={() => onOkClicked()}
          >
            OK
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default EntryDetailsPopup;
