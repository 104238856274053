import React from 'react';
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DELETE_CONFIRMATION_TITLE } from '../../../utils/constant';

const useStyles = makeStyles({
  dialogBox: {
    position: 'relative',
  },
  dialogContent: {
    width: '500px',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  toolName: {
    fontWeight: 'bold',
  },
});

export interface ConfirmDeleteProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  additionalText?: string;
}

const ConfirmDeleteCAVariable = ({
  isOpen,
  onClose,
  onConfirm,
  additionalText,
}: ConfirmDeleteProps) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      className={classes.dialogBox}
      fullWidth={false}
    >
      <DialogTitle id="dialog-title">
        {DELETE_CONFIRMATION_TITLE}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Typography>
          Are you sure, you want to delete this variable ?
        </Typography>
        {additionalText && (
          <Typography className={classes.toolName}>{additionalText}</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          data-testid="no-btn"
          variant="contained"
          autoFocus
          disableRipple
        >
          No
        </Button>
        <Button
          onClick={onConfirm}
          data-testid="yes-btn"
          color="primary"
          variant="contained"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDeleteCAVariable;
