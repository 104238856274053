import React, { useEffect, useState, useContext, useMemo } from 'react';
import {
  Box,
  Button,
  Chip,
  createStyles,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Theme,
  Tooltip,
  LinearProgress,
  FormControl,
  InputAdornment,
  OutlinedInput,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { useApi } from '@backstage/core-plugin-api';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';

import { displayTime } from '../../../utils/dateUtils';
import { promptApiRef } from '../../../../apis/promptManagementApi';
import { useNavigate } from 'react-router-dom';
import { notificationApiRef } from '../../../../apis/notificationApi';
import { AuthContext } from '../../../../providers/AuthProvider';
import { PromptContext } from '../providers/PromptProvider';
import PromptCreate from '../promptTable/PromptCreate';
import Search from '@material-ui/icons/Search';
import { userApiRef } from '../../../../apis/userApi';
import UserAvatar from '../../../common/UserAvatar';

const useRowStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
      '& td': {
        width: '30%',
      },
      '& td:nth-child(2)': {
        width: '10%',
      },
      '& td:nth-child(3)': {
        width: '30%',
      },
      '& td:nth-child(4)': {
        width: '10%',
      },
      '& td:nth-child(5)': {
        width: '20%',
      },
    },
    subdomainCollapseButton: {
      marginLeft: '8px',
    },
    subdomainPH: {
      width: '32px',
    },
    titleCell: {
      display: 'flex',
      alignItems: 'center',
    },
    titleText: {
      fontWeight: 'bold',
      fontSize: '0.875rem'
    },
    domainChip: {
      backgroundColor: theme.palette.colors.blue,
      marginTop: '4px',
      color: theme.palette.common.white,
    },
    subdomainChip: {
      backgroundColor: theme.palette.colors.sage,
      marginLeft: '5px',
      marginTop: '4px',
    },
    personaChip: {
      backgroundColor: theme.palette.colors.purple,
      marginLeft: '52px',
      marginTop: '4px',
    },
    clickableTableCell: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      lineHeight: '1.5',
    },
    domainTR: {
      backgroundColor: `transparent !important`,
    },
    domainActiveTR: {
      backgroundColor: `${theme.palette.prompt.table.domain} !important`,
    },
    subdomainTR: {
      backgroundColor: `${theme.palette.prompt.table.subdomain} !important`,
    },
    personaTR: {
      backgroundColor: `${theme.palette.prompt.table.persona} !important`,
    },
    moreOptionButton: {
      display: 'block',
      float: 'right',
    },
    sortIcon: {
      marginLeft: theme.spacing(1),
    },
    clickableCell: {
      cursor: 'pointer',
    },
    searchFormControl: {
      width: '360px',
    },
    outlinedInput: {
      '& input': {
        padding: '10px 14px !important',
        fontSize: '14px',
      },
    },
  }),
);

const UserRow = ({
  userEmails
}:
  {
    userEmails: string[];
  }) => {
  const [expand, setExpand] = useState(false);
  const userApi = useApi(userApiRef);

  const customStyles = {
    width: '32px',
    height: '32px',
    marginRight: '8px'
  }
  if (userEmails.length === 0) return <>-</>;
  const collapsedList = userEmails.length > 4 ? userEmails.slice(0, 3) : userEmails;

  return <div style={{ display: 'flex', flex: '1 0 21%', margin: '5px', justifyContent: 'flex-end' }}>
    {
      expand ? userEmails.map((email, index) => (<UserAvatar key={index} userEmail={email} customStyles={customStyles} />)) :
        collapsedList.map((email, index) => (<UserAvatar key={index} userEmail={email} customStyles={customStyles} />))
    }
    {
      !expand && userEmails.length > 4 ? <Tooltip title="Show more"><IconButton onClick={() => setExpand(true)}><MoreHorizIcon /></IconButton></Tooltip> : <></>
    }
  </div>
}

const ChildRow = (props: {
  row: any;
  open: boolean;
  handleOptionClick: any;
  onMenuClose: Function;
  handleDialog: any;
  domain: string;
}) => {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyle();
  const promptApi = useApi(promptApiRef);
  const notificationApi = useApi(notificationApiRef);
  const navigate = useNavigate();
  const promptContext = useContext(PromptContext);
  const userContext = useContext(AuthContext);

  const navigateToViewPrompts = (domainId?: string, subDomainId?: string, personaId?: string) => {
    navigate(`/admin/manage-prompts/${domainId ? domainId + '/' : ''}${subDomainId ? subDomainId + '/' : ''}${personaId ? personaId + '/' : ''}prompts`);
  }

  return (
    <>
      <TableRow
        style={{ visibility: props.open ? 'visible' : 'collapse' }}
        className={classes.subdomainTR}
      >
        <TableCell component="td" scope="row">
          <div className={classes.titleCell}>
            {row.personas && row.personas.length > 0 && (
              <Tooltip title="Expand">
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                  className={classes.subdomainCollapseButton}
                >
                  {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
              </Tooltip>
            )}
            <Chip
              size="small"
              variant="default"
              label="SUB-DOMAIN"
              style={{
                marginLeft:
                  row.personas && row.personas.length > 0 ? '0px' : '37px',
              }}
              className={classes.subdomainChip}
            />
            <Tooltip title={row.description}>
              <Typography
                component="span"
              >
                <span className={classes.titleText}>{row.name}</span>
              </Typography>
            </Tooltip>
          </div>
        </TableCell>
        <TableCell
          align="right"
          onClick={() => navigateToViewPrompts(props.domain, row.id)}
          className={classes.clickableTableCell}
        >{row.promptNo}</TableCell>
        <TableCell align="right">-</TableCell>
        <TableCell align="right">{displayTime(row.updatedat)}</TableCell>
        <TableCell align="left">
          <Button
            variant="text"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              props.handleDialog({
                open: true,
                title: 'Add Persona',
                dialogProps: {
                  title: 'Add Persona',
                  fields: [
                    {
                      fieldName: 'name',
                      label: 'Name',
                      type: 'text',
                    },
                    {
                      fieldName: 'description',
                      label: 'Description',
                      type: 'text',
                    },
                    {
                      type: 'hidden',
                      fieldName: 'sdid',
                      value: row.id,
                    },
                    {
                      type: 'hidden',
                      fieldName: 'did',
                      value: props.domain,
                    },
                  ],
                },
                onClose: () => {
                  props.handleDialog({});
                },
                onConfirm: async (data: any) => {
                  try {
                    const response = await promptApi.savePersona(data);
                    if (response) {
                      promptContext.fetchData(userContext.isAdmin, userContext.profEmail);
                      notificationApi.sendNotification({
                        message: `Successfully created persona ${data.name}`,
                        disapperAfterMs: 2500,
                        severity: 'success',
                      });
                      props.handleDialog({});
                    }
                  } catch {
                    notificationApi.sendNotification({
                      message: `Failed to create persona ${data.name}`,
                      disapperAfterMs: 2500,
                      severity: 'error',
                    });
                    props.handleDialog({});
                  }
                },
              });
            }}
          >
            Add Persona
          </Button>
          <Tooltip title="Edit sub-domain">
            <IconButton
              size="small"
              className={classes.moreOptionButton}
              onClick={ev => {
                props.handleOptionClick(ev, [
                  {
                    title: 'Edit Sub-domain',
                    onClick: () => {
                      props.onMenuClose();
                      props.handleDialog({
                        open: true,
                        title: 'Edit Sub-domain',
                        dialogProps: {
                          title: 'Edit Sub-domain',
                          fields: [
                            {
                              fieldName: 'name',
                              label: 'Title',
                              type: 'text',
                              value: row.name,
                            },
                            {
                              fieldName: 'description',
                              label: 'Description',
                              type: 'text',
                              value: row.description,
                            },
                            {
                              type: 'hidden',
                              fieldName: 'did',
                              value: props.domain,
                            },
                          ],
                        },
                        onClose: () => {
                          props.handleDialog({});
                        },
                        onConfirm: async (data: any) => {
                          try {
                            const response = await promptApi.editSubDomain(
                              data,
                              row.id,
                            );
                            if (response) {
                              promptContext.fetchData(userContext.isAdmin, userContext.profEmail);
                              notificationApi.sendNotification({
                                message: `Successfully edited subdomain ${data.name}`,
                                disapperAfterMs: 2500,
                                severity: 'success',
                              });
                              props.handleDialog({});
                            }
                          } catch {
                            notificationApi.sendNotification({
                              message: `Failed to edit subdomain ${data.name}`,
                              disapperAfterMs: 2500,
                              severity: 'error',
                            });
                            props.handleDialog({});
                          }
                        },
                      });
                    },
                  },
                  {
                    title: 'View Prompts',
                    onClick: () => {
                      navigate(
                        `/admin/manage-prompts/${props.domain}/${row.id}/prompts`,
                      );
                    },
                  },
                  {
                    title: 'Delete Sub-domain',
                    onClick: () => {
                      props.onMenuClose();
                      props.handleDialog({
                        open: true,
                        dialogProps: {
                          title: row.name,
                          description:
                            'Are you sure you want to permanently remove this item?',
                        },
                        onClose: () => props.handleDialog({}),
                        onConfirm: async () => {
                          try {
                            const response = await promptApi.deleteSubDomain(
                              row.id,
                            );
                            if (response) {
                              promptContext.fetchData(userContext.isAdmin, userContext.profEmail);
                              notificationApi.sendNotification({
                                message: `Successfully deleted subdomain ${row.name}`,
                                disapperAfterMs: 2500,
                                severity: 'success',
                              });
                              props.handleDialog({});
                            }
                          } catch {
                            notificationApi.sendNotification({
                              message: `Failed to delete subdomain ${row.name}`,
                              disapperAfterMs: 2500,
                              severity: 'error',
                            });
                          }
                        },
                      });
                    },
                  },
                ]);
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      {row.personas && row.personas.length > 0 && (
        <>
          {row.personas.map((persona: any) => (
            <TableRow
              style={{
                visibility: props.open && open ? 'visible' : 'collapse',
              }}
              className={classes.personaTR}
              key={`persona-${persona.name}`}
            >
              <TableCell component="td" scope="row">
                <div className={classes.titleCell}>
                  <Chip
                    size="small"
                    variant="default"
                    label="PERSONA"
                    className={classes.personaChip}
                  />
                  <span className={classes.titleText}>{persona.name}</span>
                </div>
              </TableCell>
              <TableCell
                align="right"
                onClick={() => { navigateToViewPrompts(props.domain, row.id, persona.id) }}
                className={classes.clickableTableCell}
              >{persona.promptNo}</TableCell>
              <TableCell align="right">
                {persona.users &&
                  <UserRow
                    userEmails={persona.users || []}
                  />
                }
              </TableCell>
              <TableCell align="right">
                {displayTime(persona.updatedat)}
              </TableCell>
              <TableCell align="left">
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    navigate(
                      `/admin/manage-prompts/${props.domain}/${row.id}/${persona.id}/prompts/add-prompt`,
                    );
                  }}
                >
                  Add Prompts
                </Button>
                <Tooltip title="Edit persona">
                  <IconButton
                    size="small"
                    className={classes.moreOptionButton}
                    onClick={ev => {
                      props.onMenuClose();
                      props.handleOptionClick(ev, [
                        {
                          title: 'Edit Persona',
                          onClick: () => {
                            props.onMenuClose();
                            props.handleDialog({
                              open: true,
                              dialogProps: {
                                title: 'Edit Persona',
                                fields: [
                                  {
                                    fieldName: 'name',
                                    label: 'Name',
                                    value: persona.name,
                                    type: 'text',
                                  },
                                  {
                                    fieldName: 'users',
                                    label: 'Users',
                                    value: persona.users.map((u: string) => ({
                                      user: u,
                                    })),
                                    type: 'user',
                                  },
                                  {
                                    fieldName: 'description',
                                    label: 'Description',
                                    value: persona.description,
                                    type: 'text',
                                  },
                                  {
                                    type: 'hidden',
                                    fieldName: 'sdid',
                                    value: row.id,
                                  },
                                  {
                                    type: 'hidden',
                                    fieldName: 'did',
                                    value: props.domain,
                                  },
                                ],
                              },
                              onClose: () => props.handleDialog({}),
                              onConfirm: async (data: any) => {
                                try {
                                  let response, response2;
                                  response = await promptApi.editPersona(
                                    {
                                      ...data,
                                      // users: data.users.map((u: any) => u.user),
                                    },
                                    persona.id,
                                  );
                                  if (data.users) {
                                    response2 = await promptApi.savePersonaUsers(
                                      data.users.map((u: any) => u.user),
                                      persona.id
                                    );
                                  } else {
                                    response2 = true;
                                  }
                                  if (response && response2) {
                                    promptContext.fetchData(userContext.isAdmin, userContext.profEmail);
                                    notificationApi.sendNotification({
                                      message: `Successfully edited persona ${row.name}`,
                                      disapperAfterMs: 2500,
                                      severity: 'success',
                                    });
                                    props.handleDialog({});
                                  }
                                } catch (err) {
                                  console.log(err);
                                  notificationApi.sendNotification({
                                    message: `Failed to edit persona ${row.name}`,
                                    disapperAfterMs: 2500,
                                    severity: 'error',
                                  });
                                  props.handleDialog({});
                                }
                              },
                            });
                          },
                        },
                        {
                          title: 'View Prompts',
                          onClick: () => {
                            navigate(
                              `/admin/manage-prompts/${props.domain}/${row.id}/${persona.id}/prompts`,
                            );
                          },
                        },
                        {
                          title: 'Delete Persona',
                          onClick: () => {
                            props.onMenuClose();
                            props.handleDialog({
                              open: true,
                              dialogProps: {
                                title: persona.name,
                                description:
                                  'Are you sure you want to permanently remove this item?',
                              },
                              onClose: () => props.handleDialog({}),
                              onConfirm: async () => {
                                try {
                                  const response = await promptApi.deletePersona(
                                    persona.id,
                                  );
                                  if (response) {
                                    promptContext.fetchData(userContext.isAdmin, userContext.profEmail);
                                    notificationApi.sendNotification({
                                      message: `Successfully deleted persona ${persona.name}`,
                                      disapperAfterMs: 2500,
                                      severity: 'success',
                                    });
                                    props.handleDialog({});
                                  }
                                } catch {
                                  notificationApi.sendNotification({
                                    message: `Failed to delete persona ${persona.name}`,
                                    disapperAfterMs: 2500,
                                    severity: 'error',
                                  });
                                  props.handleDialog({});
                                }
                              },
                            });
                          },
                        },
                      ]);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </>
      )}
    </>
  );
};

const Row = (props: {
  row: any;
  handleOptionClick: any;
  onMenuClose: Function;
  handleDialog: any;
}) => {
  const { row } = props;
  const classes = useRowStyle();
  const [open, setOpen] = React.useState(false);
  const promptContext = useContext(PromptContext);
  const promptApi = useApi(promptApiRef);
  const userContext = useContext(AuthContext);
  const notificationApi = useApi(notificationApiRef);
  const navigate = useNavigate();
  const navigateToViewPrompts = (domainId?: string, subDomainId?: string, personaId?: string) => {
    navigate(`/admin/manage-prompts/${domainId ? domainId + '/' : ''}${subDomainId ? subDomainId + '/' : ''}${personaId ? personaId + '/' : ''}prompts`);
  }

  const addOrRemoveAdmin = async (
    newAdminList: any[],
    oldAdminList: any[],
    domainId: string,
  ) => {
    try {
      // first remove all deleted admins
      const a = await Promise.all(
        oldAdminList.map(async (admin: any) => {
          if (
            !newAdminList.find(
              n => n.user.toLowerCase() === admin.useremail.toLowerCase(),
            )
          ) {
            return promptApi.removeAdmin(admin.useremail, domainId);
          }
          return;
        }),
      );
      // add any new users
      const b = await Promise.all(
        newAdminList.map(async (newAdmin: any) => {
          if (
            !oldAdminList.find(
              (o: any) => o.useremail.toLowerCase() === newAdmin.user,
            )
          ) {
            return promptApi.createAdmin(newAdmin.user, domainId);
          }
          return;
        }),
      );

      return [...a, ...b];
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  return (
    <React.Fragment>
      <TableRow
        className={`${classes.root} ${open ? classes.domainActiveTR : classes.domainTR
          }`}
      >
        <TableCell component="td" scope="row">
          <div className={classes.titleCell}>
            <Tooltip title="Expand">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>
            </Tooltip>
            <Chip
              size="small"
              variant="default"
              label="DOMAIN"
              className={classes.domainChip}
            />

            <span className={classes.titleText}>{row.name}</span>
          </div>
        </TableCell>
        <TableCell
          align="right"
          onClick={() => { navigateToViewPrompts(row.id) }}
          className={classes.clickableTableCell}
        >{row.promptNo}</TableCell>
        <TableCell align="right">
          <UserRow
            userEmails={row.admins
              .filter((admin: any) => admin.isactive)
              .map((admin: any) => admin.useremail)}
          />
        </TableCell>
        <TableCell align="right">{displayTime(row.updatedat)}</TableCell>
        <TableCell align="left">
          <Button
            variant="text"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              props.handleDialog({
                open: true,
                title: 'Add Sub-domain',
                dialogProps: {
                  title: 'Add Sub-domain',
                  fields: [
                    {
                      fieldName: 'name',
                      label: 'Title',
                      type: 'text',
                    },
                    {
                      type: 'hidden',
                      fieldName: 'did',
                      value: row.id,
                    },
                    {
                      fieldName: 'description',
                      label: 'Description',
                      type: 'text',
                      maxRow: 5,
                      minRow: 5,
                    },
                  ],
                },
                onClose: () => {
                  props.handleDialog({});
                },
                onConfirm: async (data: any) => {
                  try {
                    const response = await promptApi.saveSubDomain(data);
                    if (response) {
                      promptContext.fetchData(userContext.isAdmin, userContext.profEmail);
                      notificationApi.sendNotification({
                        message: `Successfully created subdomain ${data.name}`,
                        disapperAfterMs: 2500,
                        severity: 'success',
                      });
                      props.handleDialog({});
                    }
                  } catch {
                    notificationApi.sendNotification({
                      message: `Failed to create subdomain ${data.name}`,
                      disapperAfterMs: 2500,
                      severity: 'error',
                    });
                    props.handleDialog({});
                  }
                },
              });
            }}
          >
            Add Sub-domain
          </Button>
          <Tooltip title="Edit domain">
            <IconButton
              size="small"
              className={classes.moreOptionButton}
              onClick={ev => {
                props.onMenuClose();
                props.handleOptionClick(ev, [
                  {
                    title: 'Edit Domain',
                    onClick: () => {
                      props.onMenuClose();
                      props.handleDialog({
                        open: true,
                        title: 'Edit Domain',
                        dialogProps: {
                          title: 'Edit Domain',
                          fields: [
                            {
                              fieldName: 'name',
                              label: 'Title',
                              type: 'text',
                              value: row.name,
                            },
                            {
                              fieldName: 'productowner',
                              label: 'Administrators',
                              type: 'user',
                              value: row.admins
                                .filter((admin: any) => admin.isactive)
                                .map((admin: any) => ({
                                  user: admin.useremail,
                                })),
                            },
                            {
                              fieldName: 'description',
                              label: 'Description',
                              type: 'text',
                              value: row.description,
                              maxRow: 5,
                              minRow: 5,
                            },
                          ],
                        },
                        onClose: () => {
                          props.handleDialog({});
                        },
                        onConfirm: async (data: any) => {
                          try {
                            const editedProdctOwners = data?.productowner?.map(
                              (admin: any) => admin.user,
                            );
                            const response = await promptApi.editDomain(
                              {
                                ...data,
                                productowner: [...editedProdctOwners],
                              },
                              row.id,
                            );
                            const list = await addOrRemoveAdmin(
                              data.admin,
                              row.admins,
                              row.id,
                            );
                            if (response && list) {
                              promptContext.fetchData(userContext.isAdmin, userContext.profEmail);
                              notificationApi.sendNotification({
                                message: `Successfully edited domain ${data.name}`,
                                disapperAfterMs: 2500,
                                severity: 'success',
                              });
                              props.handleDialog({});
                            }
                          } catch {
                            notificationApi.sendNotification({
                              message: `Failed to edit domain ${data.name}`,
                              disapperAfterMs: 2500,
                              severity: 'error',
                            });
                            props.handleDialog({});
                          }
                        },
                      });
                    },
                  },
                  {
                    title: 'View Prompts',
                    onClick: () => {
                      navigate(`/admin/manage-prompts/${row.id}/prompts`);
                    },
                  },
                  {
                    title: 'Delete Domain',
                    onClick: () => {
                      props.onMenuClose();
                      props.handleDialog({
                        open: true,
                        dialogProps: {
                          title: row.name,
                          description:
                            'Are you sure you want to permanently remove this item?',
                        },
                        onClose: () => props.handleDialog({}),
                        onConfirm: async () => {
                          try {
                            const response = await promptApi.deleteDomain(row.id);
                            if (response) {
                              promptContext.fetchData(userContext.isAdmin, userContext.profEmail);
                              notificationApi.sendNotification({
                                message: `Successfully deleted domain ${row.name}`,
                                disapperAfterMs: 2500,
                                severity: 'success',
                              });
                              props.handleDialog({});
                            }
                          } catch {
                            notificationApi.sendNotification({
                              message: `Failed to delete domain ${row.name}`,
                              disapperAfterMs: 2500,
                              severity: 'error',
                            });
                            props.handleDialog({});
                          }
                        },
                      });
                    },
                  },
                ]);
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      {row.subproducts && row.subproducts.length > 0 && (
        <>
          {row.subproducts.map((subproduct: any) => (
            <ChildRow
              row={subproduct}
              open={open}
              handleOptionClick={props.handleOptionClick}
              onMenuClose={props.onMenuClose}
              handleDialog={props.handleDialog}
              domain={row.id}
              key={`child-row-${subproduct.name}`}
            />
          ))}
        </>
      )}
    </React.Fragment>
  );
};

interface SortOption {
  option: string;
  order: '' | 'desc' | 'asc';
}

const CollapsibleTable = (props: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [menuOption, setMenuOption] = useState<any[]>([]);
  const { profEmail, isAdmin } = useContext(AuthContext);
  const promptApi = useApi(promptApiRef);
  const notificationApi = useApi(notificationApiRef);
  const [popup, setPopup] = useState<any>({});
  const [sortedDomains, setSortedDomains] = useState<any[]>([]);
  const promptContext = useContext(PromptContext);
  const [sortOption, setSortOption] = useState<SortOption>({
    option: '',
    order: '',
  });
  const classes = useRowStyle();
  const [search, setSearch] = useState('');
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    menuOption: any[],
  ) => {
    setAnchorEl(event.currentTarget);
    setMenuOption(menuOption);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (props.openPrompt && !popup.open) {
      setPopup({
        open: true,
        title: 'Add Domain',
        dialogProps: {
          title: 'Add Domain',
          fields: [
            {
              fieldName: 'name',
              label: 'Title',
              type: 'text',
            },
            {
              fieldName: 'productowner',
              label: 'Administrators',
              type: 'user',
              value: [{
                user: profEmail,
              }],
            },
            {
              fieldName: 'description',
              label: 'Description',
              type: 'text',
              maxRow: 5,
              minRow: 5,
            },
          ],
        },
        onClose: () => {
          setPopup({});
          props.setOpenPrompt(false);
        },
        onConfirm: async (data: any) => {
          try {
            const newProductOwners = data?.productowner?.map(
              (admin: any) => admin.user,
            );
            const response = await promptApi.saveDomain({
              ...data,
              productowner: [...newProductOwners],
            });
            if (response) {
              promptContext.fetchData(isAdmin, profEmail);
              notificationApi.sendNotification({
                message: `Successfully created domain ${data.name}`,
                disapperAfterMs: 2500,
                severity: 'success',
              });
              setPopup({});
              props.setOpenPrompt(false);
            }
          } catch {
            notificationApi.sendNotification({
              message: `Failed to create domain ${data.name}`,
              disapperAfterMs: 2500,
              severity: 'error',
            });
            props.setOpenPrompt(false);
          }
        },
      });
    } else {
      //  props.setOpenPrompt(false);
      setPopup({
        open: false,
      });
    }
  }, [props.openPrompt]);

  useEffect(() => {
    if (props.data.domains && props.data.prompts) {
      const dom = props.data.domains.map((domain: any) => ({
        ...domain,
        promptNo: props.data.prompts.filter((d: any) => d.product === domain.id)
          .length,
        subproducts:
          domain.subproducts && domain.subproducts.length > 0
            ? domain.subproducts.map((sub: any) => ({
              ...sub,
              promptNo: props.data.prompts.filter(
                (d: any) => d.subproduct === sub.id,
              ).length,
              personas:
                sub.personas && sub.personas.length > 0
                  ? sub.personas.map((per: any) => ({
                    ...per,
                    promptNo: props.data.prompts.reduce(
                      (accumulator: any, prompt: any) =>
                        prompt.persona.includes(per.id)
                          ? accumulator + 1
                          : accumulator,
                      0,
                    ),
                  }))
                  : null,
            }))
            : null,
      }));
      setSortedDomains(dom);
    }
  }, [props.data.domains, props.data.prompts]);

  const sort = (array: any[], option: string, order: string) => {
    return array.sort(
      (a: any, b: any) =>
        (order === 'desc' ? -1 : 1) *
        String(a[option]).localeCompare(String(b[option])),
    );
  };

  const rows = [
    {
      title: 'Title',
      align: 'center',
      onClick: () => onRowClick('name'),
      option: 'name',
    },
    {
      title: 'No. of prompts',
      align: 'end',
      onClick: () => onRowClick('promptNo'),
      option: 'promptNo',
    },
    {
      title: 'Administrator/Users',
      align: 'end',
    },
    {
      title: 'Edited on',
      align: 'end',
      onClick: () => onRowClick('updatedat'),
      option: 'updatedat',
    },
    {
      title: 'Actions',
      align: 'start',
    },
  ];

  const filterDomains = () => {
    if (!search) return sortedDomains;
    return sortedDomains.filter((domain: any) => {
      if (domain.name.toLowerCase().includes(search.toLowerCase())) return true;
      if (domain.subproducts && domain.subproducts.length > 0) {
        return domain.subproducts.some((sub: any) => {
          if (sub.name.toLowerCase().includes(search.toLowerCase()))
            return true;
          if (sub.personas && sub.personas.length > 0) {
            return sub.personas.some((persona: any) => {
              if (persona.name.toLowerCase().includes(search.toLowerCase()))
                return true;
              return false;
            });
          }
          return false;
        });
      }
    });
  };

  const cachedValue = useMemo(() => {
    return sort(filterDomains(), sortOption.option, sortOption.order);
  }, [sortOption, sortedDomains, search]);

  const onRowClick = (optionName: string) => {
    setSortOption((prev: any) => {
      const order =
        optionName === prev.option
          ? prev.order === 'desc'
            ? 'asc'
            : 'desc'
          : 'desc';
      return {
        order,
        option: optionName,
      };
    });
  };

  const handleSearch = (e: any) => {
    console.log(e.target.value);
    setSearch(e.target.value);
  };

  if (props.loading) return <LinearProgress />;

  return (
    <>
      {popup.open && (
        <PromptCreate
          dialogProps={popup.dialogProps}
          isOpen={popup.open}
          onClose={popup.onClose}
          onConfirm={popup.onConfirm}
        />
      )}
      <Box
        display="flex"
        justifyContent="flex-start"
        style={{ marginBottom: '20px' }}
      >
        <FormControl variant="outlined" className={classes.searchFormControl}>
          <OutlinedInput
            id="prompt-table-search"
            value={search}
            placeholder="Search domain, subdomain, persona"
            onChange={handleSearch}
            className={classes.outlinedInput}
            endAdornment={
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
      <TableContainer component={Paper} style={{ marginBottom: '60px' }}>
        <Table aria-lable="prompt table">
          <TableHead>
            <TableRow>
              {rows.map((row: any) => (
                <TableCell
                  onClick={row.onClick || undefined}
                  key={`table-row-${row.title}`}
                  className={row.onClick ? classes.clickableCell : ''}
                >
                  <Typography
                    component="span"
                    style={{ display: 'flex', justifyContent: row.align }}
                  >
                    {row.title}
                    {sortOption.option === row.option &&
                      (sortOption.order === 'desc' ? (
                        <ArrowDownwardIcon className={classes.sortIcon} />
                      ) : (
                        <ArrowUpwardIcon className={classes.sortIcon} />
                      ))}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {cachedValue.map((row: any) => (
              <Row
                row={row}
                handleOptionClick={handleClick}
                onMenuClose={handleClose}
                handleDialog={setPopup}
                key={`row-${row.name}`}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        id="collapsible-table-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        {menuOption.map(item => (
          <MenuItem onClick={item.onClick} key={`menu-dropdown-${item.title}`}>
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default CollapsibleTable;
