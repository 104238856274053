import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import React from 'react';
import { Quadrant, Ring } from '../../utils/types';
import { RadarLegendRing } from './RadarLegendRing';
import { RadarLegendProps, Segments } from './types';
import { getSegment } from './utils';

type RadarLegendQuadrantProps = {
  segments: Segments;
  quadrant: Quadrant;
  rings: Ring[];
  classes: ClassNameMap<string>;
  onEntryMouseEnter: RadarLegendProps['onEntryMouseEnter'];
  onEntryMouseLeave: RadarLegendProps['onEntryMouseLeave'];
};

export const RadarLegendQuadrant = ({
  segments,
  quadrant,
  rings,
  classes,
  onEntryMouseEnter,
  onEntryMouseLeave,
}: RadarLegendQuadrantProps) => {
  const ring = rings.some(
    ring => getSegment(segments, quadrant, ring).length > 0,
  );

  return (
    <foreignObject
      key={quadrant.id}
      x={quadrant.legendX}
      y={quadrant.legendY}
      width={quadrant.legendWidth}
      height={quadrant.legendHeight}
      data-testid="radar-quadrant"
    >
      <h2 className={classes.quadrantHeading}>{quadrant.name}</h2>
      <div className={classes.quadrant}>
        {!ring ? (
          'No entries'
        ) : (
          <div className={classes.rings}>
            {rings.map(ring => (
              <RadarLegendRing
                key={ring.id}
                ring={ring}
                classes={classes}
                entries={getSegment(segments, quadrant, ring)}
                onEntryMouseEnter={onEntryMouseEnter}
                onEntryMouseLeave={onEntryMouseLeave}
              />
            ))}
          </div>
        )}
      </div>
    </foreignObject>
  );
};
