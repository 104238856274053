const CONSTANTS = {
  errors: {
    devxConfigName: 'Config Name is required',
    apmName: 'APM Name is required',
    apmNumber: 'APM Number is required',
    flowId: 'Flow ID is required',
    /* For Test plan section */
    repoBranchCloneUrl: 'Repo branch url is required',
    testPlan: 'Test Plan is required',
  },
  REPORT_STATUS: {
    CONFIG_CREATED: 'config created',
    PROVISIONING: 'provisioning',
    STARTED: 'started',
    DONE: 'done',
    STOPPED: 'stopped',
    FAILED: 'failed',
    ENDED: 'ended',
  },
  CONFIG_VALIDATION_STATUS: {
    VALIDATING: 'validating',
    VALIDATED: 'valid',
    VALIDATION_FAILED: 'invalid',
    BEFORE_VALIDATION: 'validation pending',
  },
};
export default CONSTANTS;
