import React from 'react';
import { Chip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  typeFontSize: {
    fontSize: '11px',

  },
});

export const LifeCycleButton = props => {
  const { apiLifecycle, setApiLifecycle, setIsButtonDisabled, editable } = props;
  const classes = useStyles();

  const handleOnclickDEPRECATED = () => {
    if (!editable) return
    setApiLifecycle('deprecated');
    setIsButtonDisabled(false);
  };
  const handleOnclickEXPERIMENTAL = () => {
    if (!editable) return
    setApiLifecycle('experimental');
    setIsButtonDisabled(false);
  };
  const handleOnclickPRODUCTION = () => {
    if (!editable) return
    setApiLifecycle('production');
    setIsButtonDisabled(false);
  };

  return (
    <>
      <Chip
        size="small"
        label="DEPRECATED"
        variant={apiLifecycle === 'deprecated' ? 'outlined' : 'default'}
        className={classes.typeFontSize}
        color={apiLifecycle === 'deprecated' ? 'primary' : 'default'}
        clickable={editable}
        onClick={handleOnclickDEPRECATED}
      />
      <Chip
        size="small"
        label="EXPERIMENTAL"
        variant={apiLifecycle === 'experimental' ? 'outlined' : 'default'}
        className={classes.typeFontSize}
        color={apiLifecycle === 'experimental' ? 'primary' : 'default'}
        clickable={editable}
        onClick={handleOnclickEXPERIMENTAL}
      />
      <Chip
        data-testid="background-production"
        size="small"
        label="PRODUCTION"
        variant={apiLifecycle === 'production' ? 'outlined' : 'default'}
        className={classes.typeFontSize}
        color={apiLifecycle === 'production' ? 'primary' : 'default'}
        clickable={editable}
        onClick={handleOnclickPRODUCTION}
      />
    </>
  );
};
