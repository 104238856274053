import { makeStyles, createStyles, Theme } from '@material-ui/core';
export const useCustomStyles = makeStyles((theme: Theme) =>
  createStyles({
    feedbackPageContainer: {
      padding: '20px 50px 120px 57px',
      gridArea: 'pageContent',
      ['@media (max-width:920px) and (min-width: 280px)']: {
        padding: '24px 8px 120px 23px',
        overflowX: 'hidden',
      },
    },
    feedbackPageSearch: {
      width: '100%',
    },
    requestTypeContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      marginTop: '10px',
    },
    flexContainer: {
      width: '100%',
      ['@media (max-width:480px)']: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '450px',
    },
    PagePaginationContainer: {
      width: '100%',
      marginTop: '24px',
      ['@media (max-width:920px) and (min-width: 280px)']: {
        padding: '0px 10px 0px 0px !important',
        marginTop: '28px',
      },
    },
    FeedbackTitleStyle: {
      height: '63px',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '24px',
      background: '#ffffff',

      '& span': {
        fontFamily: 'Calibre-R-Regular',
        fontSize: '16px',
        lineHeight: '20px',
        textAlign: 'left',
        color: '#003f2d',
      },
    },
    FeedbackExtContentStyle: {
      padding: '10px 20px',
      marginTop: '10px',
      width: '100%',

      '& div:first-of-type a': {
        fontFamily: 'Calibre-R-Regular',
        fontSize: '15px',
        lineHeight: '20px',
        textAlign: 'left',
        color: '#003f2d',
      },
    },
    FeedbackExtGridStyle: {
      display: 'flex',
      marginTop: '10px',
      width: '100%',
      justifyContent: 'space-between',
      ['@media (max-width:920px) and (min-width: 280px)']: {
        flexDirection: 'column',
      },
      '& section': {
        backgroundColor: theme.palette.background.paper,
        padding: '20px',

        '& > div': {
          marginTop: '12px',
        },

        '& > section:nth-of-type(1)': {
          flex: '1',
          minWidth: '800px',
        },

        '& > div:first-of-type': {
          fontFamily: 'Calibre-R-Regular',
          fontSize: '12px',
          lineHeight: '16px',
          color: theme.palette.text.secondary,
        },
      },

      '& > section:nth-of-type(1)': {
        flex: '1',
        '& div': {
          width: '100%',
          // minWidth: '475px',
        },
      },

      '& > section:nth-of-type(2)': {
        flex: '1',
        '& .feedback-info-title': {
          height: '40px',
          boxShadow: '0px 0px 0px black',
          fontFamily: 'Calibre-R-Regular',
          fontSize: '16px',
          lineHeight: '20px',
          textAlign: 'left',
          color: theme.palette.text.secondary,
        },
        '& .feedback-spacer': {
          height: '1px',
          width: '100%',
          background: '#cccccc',
        },
        '& .feedback-info': {
          marginLeft: '-20px',
          '& section': {
            fontFamily: 'Calibre-R-Regular',
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.text.tertiary,
          },

          '& section:nth-child(2)': {
            marginTop: '-20px',
            ['@media (max-width:920px) and (min-width: 280px)']: {
              marginTop: '-30px',
            },
          },
          '& section:nth-child(3)': {
            marginTop: '-35px',
          },
          '& section:nth-child(4)': {
            marginTop: '-20px',
            ['@media (max-width:920px) and (min-width: 280px)']: {
              marginTop: '-30px',
            },
          },
        },
      },
    },
    Feedbackfields: {
      width: '100% !important',
      InputProps: {
        height: '200px',
      },
    },
    FeedbackDesc: {
      marginLeft: '20px',
      ['@media (max-width:920px) and (min-width: 280px)']: {
        marginTop: '20px',
        marginLeft: '0',
      },
    },
    mockSubmitBtn: {
      marginTop: '30px !important',
    },
    submitRegisterBtn: {
      ['@media (max-width:920px) and (min-width: 280px)']: {
        width: '100% ',
      },
    },
    extDocMt20Style: {
      marginTop: '20px',
    },
    CardTitleStyle: {
      height: '80px',
      position: 'relative',

      '& div:nth-of-type(1)': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        background: '#3C73A9',
        fontfamily: 'Calibre-R-Regular',
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '-0.015em',
        textAlign: 'left',
      },
      '& .card-icon-button': {
        position: 'absolute',
        top: '0px',
        right: '0px',
        color: 'white',
      },
    },
    CardColorStyle: {
      backgroundImage: 'none',
      background: '#3C73A9',
    },
    CardContentStyle: {
      fontfamily: 'Calibre-R-Regular',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0px',
      textAlign: 'left',
      color: '#262626',
    },
    CardActionsStyle: {
      display: 'flex',
      textAlign: 'left',
      '& section:nth-of-type(1)': {
        textAlign: 'left',
        color: 'rgba(38, 38, 38, 0.6)',
        flex: '1',
      },
      '& section:nth-of-type(2)': {
        fontSize: '16px',
        lineHeight: '16px',
        textAlign: 'left',
        color: '#435254',
      },
    },
    ContentHeaderStyle: {
      display: 'flex',

      '& > div:first-of-type': {
        display: 'flex',
        flex: '1 1 auto',

        '& > div:first-of-type': {
          width: '186px',
          border: '1px solid #cccccc',

          '& > div > div': {
            padding: '3px',
          },
        },

        '& > div:nth-of-type(2)': {
          width: '250px',
          marginLeft: '20px',
          background: '#ffffff',
          border: '1px solid #cccccc',
          borderRadius: '3px',

          '& > div > div': {
            width: '100%',
            underline: 'none',
            padding: '4px 2px 2px 2px',
          },
        },
      },
    },
    EmptyStateStyle: {
      '& > div > div > div': {
        marginTop: '190px',
        paddingLeft: '80px',
      },
    },
    SearchByStyle: {
      background: 'white',
      '& > div:first-of-type': {
        width: '100%',
        '& select': {
          padding: '6px 10px',
          background: 'white',
        },
        '& option': {
          padding: '4px',
        },
      },
    },
  }),
);
