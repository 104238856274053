import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useCustomStyles = makeStyles((theme: Theme) =>
  createStyles({
    themeColor: {
      backgroundColor: theme.palette.background.paper,
      borderLeftColor: theme.palette.primary.main,
      '& li': {
        borderLeftColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.border}`,
        '&:hover button': {
          color: theme.palette.primary,
          backgroundColor: `${theme.palette.background.tertiary}`,
        },
        '&.active button': {
          borderLeft: `2px solid ${theme.palette.primary.main}`,
          backgroundColor: `${theme.palette.background.tertiary}`,
        },
      },
    },
    levelAndBadgesContainer: {
      padding: '24px 24px 120px 24px',
      gridArea: 'pageContent',
      ['@media (max-width:920px) and (min-width: 280px)']: {
        padding: '24px 20px 120px 20px',
      },
    },
    marginTop24: {
      marginTop: '20px',
    },
    leaderBoardPageContainer: {
      gridArea: 'pageContent',
    },
    leaderBoardPageSearch: {
      width: '100%',
    },
    PagePaginationContainer: {
      width: '100%',
      marginTop: '10px',
      marginBottom: '110px',

      ['@media (max-width:920px) and (min-width: 280px)']: {
        padding: '0px 8px 0px 8px',
      },
    },
    extDocMt20Style: {
      marginTop: '20px',
    },

    CardContentStyle: {
      fontfamily: 'Calibre-R-Regular',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0px',
      textAlign: 'left',
      color: '#26262699',
    },

    SearchByStyle: {
      background: 'white',
      '& > div:first-of-type': {
        width: '100%',
        '& select': {
          padding: '6px 10px',
          background: 'white',
        },
        '& option': {
          padding: '4px',
        },
      },
    },
    categoryCardMargin20: {
      margin: '32px 0px',
      ['@media (max-width:920px) and (min-width: 280px)']: {
        margin: '10px 0px',
      },
    },
    leaderBoardGroupButton: {
      display: 'flex',
      justifyContent: 'start',
      marginBottom: '24px',
    },
    btnDefault: {
      backgroundColor: 'transparent',
      color: theme.palette.text.secondary,
      textTransform: 'none',
      padding: '6px 10px',
      fontFamily: 'Calibre-R-Semibold',
    },
    btnSelected: {
      padding: '6px 10px',
      backgroundColor: '#CFCFCF',
      color: '#00000094',
      textTransform: 'none',
      fontFamily: 'Calibre-R-Semibold',
    },
    leftMenuSep: {
      borderBottom: '1px solid #cfcfcf',
      fontFamily: 'Calibre-R-Semibold',
    },
    dropdownbtn: {
      fontFamily: 'Calibre-R-Semibold',
      padding: '0px',
      color: '#00000061 !important',
      backgroundColor: 'transparent !important',
      textTransform: 'capitalize',
    },
  }),
);
