import { Entity } from '@backstage/catalog-model';
import { SONARQUBE_PROJECT_KEY_ANNOTATION } from '@backstage/plugin-sonarqube-react';

export const SONARQUBE_PROJECT_INSTANCE_SEPARATOR = '/';

export const SONARQUBE_PROJECT_BRANCH_ANNOTATION =
  'sonarqube.org/project-branch';

export const isSonarQubeBranchAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[SONARQUBE_PROJECT_BRANCH_ANNOTATION]);

/**
 *
 * Try to parse sonarqube information from an entity.
 *
 * If part or all info are not found, they will default to undefined
 *
 * @public
 * @param entity - entity to find the sonarqube information from.
 * @returns a ProjectInfo properly populated.
 */
export const useProjectInfo = (
  entity: Entity,
): {
  projectInstance: string | undefined;
  projectKey: string | undefined;
  branch: string | undefined;
} => {
  let projectInstance = undefined;
  let projectKey = undefined;
  let branch = undefined;
  const annotation =
    entity?.metadata.annotations?.[SONARQUBE_PROJECT_KEY_ANNOTATION];
  if (annotation) {
    const instanceSeparatorIndex = annotation.indexOf(
      SONARQUBE_PROJECT_INSTANCE_SEPARATOR,
    );
    if (instanceSeparatorIndex > -1) {
      // Examples:
      //   instanceA/projectA  -> projectInstance = "instanceA" & projectKey = "projectA"
      //   instanceA/tenantA:projectA -> projectInstance = "instanceA" & projectKey = "tenantA:projectA"
      //   instanceA/tenantA/projectA -> projectInstance = "instanceA" & projectKey = "tenantA/projectA"
      projectInstance = annotation.substring(0, instanceSeparatorIndex);
      projectKey = annotation.substring(instanceSeparatorIndex + 1);
    } else {
      projectKey = annotation;
    }
  }
  const branchAnnotation =
    entity?.metadata.annotations?.[SONARQUBE_PROJECT_BRANCH_ANNOTATION];
  if (branchAnnotation) {
    branch = branchAnnotation;
  }
  return { projectInstance, projectKey, branch };
};
