import {
  createApiRef,
  ErrorApi,
  ErrorApiError,
  ErrorApiErrorContext,
} from '@backstage/core-plugin-api';

export interface NoOpErrorApi extends ErrorApi {}

export const noOpErrorApiRef = createApiRef<NoOpErrorApi>({
  id: 'cbre.devx.api.noop.error',
});

export class NoOpErrorApiImpl implements ErrorApi {
  constructor() {}

  error$(): any {}

  post(
    error: ErrorApiError,
    context?: ErrorApiErrorContext | undefined,
  ): void {}
}
