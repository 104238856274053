import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /* Common styles */
    pagePadding: {
      padding: theme.spacing(3),
    },
    gridAreaPageHeader: {
      gridArea: 'pageHeader',
    },
    flex: {
      display: 'flex',
    },
    flexSpacebetween: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    flexCenter: {
      display: 'flex',
      verticalAlign: 'middle',
      alignItems: 'center',
    },
    heading: {
      display: 'flex',
      alignItems: 'center',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '32px',
    },
    outlined: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '4px',
    },
    btnSpacing: {
      marginRight: theme.spacing(2),
    },
    backGroundColor: {
      backgroundColor: theme.palette.background.paper,
    },

    gap1: {
      gap: theme.spacing(1),
    },

    gap2: {
      gap: theme.spacing(2),
    },

    padding1: {
      padding: theme.spacing(1),
    },
    fontBold: {
      fontWeight: 'bold',
    },

    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },

    primaryButton8Percent: {
      color: theme.palette.primary.main,
      backgroundColor: `${theme.palette.primary.main}08`,
      boxShadow: 'none',
      borderColor: '#0063cc',
      '&:hover': {
        backgroundColor: `${theme.palette.primary.main}10`,
        borderColor: '#0062cc',
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: `${theme.palette.primary.main}10`,
        borderColor: '#005cbf',
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      },
    },

    borderRadius1: {
      borderRadius: theme.spacing(1),
    },

    textAlignCenter: {
      textAlign: 'center',
    },

    width100Percent: {
      width: '100%',
    },

    /* Common styles -- Ends */

    /* Will be refactored */
    dialogBox: {
      position: 'relative',
    },
    dialogContent: {
      width: '500px',
    },
    toolName: {
      fontWeight: 'bold',
    },
    theme: {
      backgroundColor: theme.palette.background.paper,
    },
    header: {
      padding: '24px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    h2: {
      fontSize: '1.5rem',
    },

    /* Will be refactored -- Ends */

    /* ViewPrompts Page Styles */
    scopesContainer: {
      flexGrow: 1,
      flexBasis: '100%',
      marginRight: theme.spacing(1.5),
    },

    /* ViewPrompts Page Styles -- Ends */

    /* Prompt Card Styles */
    promptCard: {
      padding: '16px',
      height: theme.spacing(25),
      margin: '0px',
      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: '0px',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    mainHeader: {
      display: 'flex',
      flexDirection: 'column',
    },

    subHeader: {
      display: 'flex',
      gap: theme.spacing(2.5),
    },

    descriptionContainer: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: ' space-between',
      flexGrow: 1,
    },
    actionSection: {
      padding: '0px',
      justifyContent: 'space-between',
    },
    chipContatiner: {
      display: 'flex',
      justifyContent: 'left',
      flexWrap: 'nowrap',
      paddingTop: theme.spacing(0.5),
      margin: 0,
      overflow: 'auto',
      maxWidth: 'calc(100% - 130px)',
    },
    chip: {
      marginBottom: 0,
    },
    /* End Of -- Prompt Card Styles */

    /* Search Bar */
    searchContainer: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      backgroundColor: 'transparent',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },

    /* End of Search bar */

    /* Stylings for Scopes */

    scopesGap: {
      display: 'flex',
      gap: theme.spacing(1.5),
    },

    scopesControl: {
      flexGrow: 1,
      flexBasis: '100%',
    },

    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    /* End of -- Scopes */

    /* Add Edit Prompt */

    buttonLoaderSection: {
      gap: theme.spacing(2.5),
    },
    infoSection: {
      display: 'flex',
      color: theme.palette.info.main,
      fontWeight: 600,
      fontSize: '15px',
    },
    infoContent: {
      margin: '1px 0px 0px 10px',
    },
    description: {
      overflow: 'hidden',
      lineHeight: 1.5,
      wordBreak: 'break-word',
      fontFamily: 'Calibre-R-Regular',
      fontSize: '11px',
      fontWeight: 600,
      textDecoration: 'none',
      color: theme.palette.text.secondary,
    },
    key: {
      overflow: 'hidden',
      lineHeight: 1.5,
      wordBreak: 'break-word',
      fontFamily: 'Calibre-R-Regular',
      fontSize: '1rem',
      fontWeight: 400,
      textDecoration: 'none',
      color: theme.palette.text.secondary,
    },
    tags: {
      marginTop: theme.spacing(2.5),
    },
    /* End of -- All Card styles */

    /* Approval Popup Styles */
    approveFooter: {
      '& button': {
        width: '50%',
      },
    },

    rejectHeader: {
      textAlign: 'center',
      '& .headerTitle': {
        color: theme.palette.error.main,
      },
    },

    rejectFooter: {
      '& button': {
        width: '50%',
      },
      '& .confirmButton': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.error.main,
      },
    },

    /* End of - Approval Popup Styles */
  }),
);
