import React, { useState } from 'react';
import { Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { useStyles } from '../common/styles';
import DeleteIcon from '@material-ui/icons/Delete';

export const FileListItem = ({
  onDelete,
  onChangeTitle,
  onChangeFile,
  idx,
  error,
  title,
  disabled,
}: {
  onDelete: any;
  onChangeTitle: any;
  onChangeFile: any;
  idx: number;
  error: string;
  title: string;
  disabled: boolean;
}) => {
  const classes = useStyles();

  const [fileTitle, setFileTitle] = useState('');

  const handleTitleChange = (event: any) => {
    const title = event?.target?.value;
    if (/^[\w \s -]*$/.test(title)) {
      setFileTitle(title);
      onChangeTitle(title);
    }
  };

  return (
    <Grid container className={classes.fileFields}>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <TextField
          label="Title"
          disabled={disabled}
          fullWidth
          variant="outlined"
          value={fileTitle}
          onChange={handleTitleChange}
          // inputProps={{
          //   onBlur: (e: any) => {
          //     onChangeTitle(e?.target?.value);
          //   },
          // }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={9} lg={9} className={classes.flex}>
        <div className={classes.fileUploadContainer}>
          <TextField
            disabled={disabled}
            fullWidth
            variant="outlined"
            type="file"
            onChange={(event: any) => {
              onChangeFile(event, idx);
            }}
          />
          {error && (
            <Typography className={classes.errorMessage}>{error}</Typography>
          )}
        </div>
        <IconButton
          color="primary"
          onClick={() => onDelete()}
          disabled={disabled}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
