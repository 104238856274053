import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';

const ConfirmDelete = ({
  onConfirm,
  onReject,
  open,
  content,
  pocName,
}: {
  onConfirm: any;
  onReject: any;
  open: boolean;
  content: string;
  pocName: string;
}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button
          role="button"
          variant="contained"
          title="delete-confirm-btn"
          color="primary"
          onClick={() => onConfirm(pocName)}
        >
          Delete
        </Button>
        <Button
          role="button"
          variant="contained"
          color="secondary"
          onClick={onReject}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDelete;
