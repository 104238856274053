import React, { useEffect, useState, useContext } from 'react';
import { Page, Header } from '@backstage/core-components';
import { TablePagination, Paper, LinearProgress } from '@material-ui/core';
import { HelpComponent } from '../utils/helpComponent';
import { useCustomStyles } from './MockStyles';
import { Grid } from '@material-ui/core';
import BackButton from '../utils/backButton';
import MockContentHeader from './MockContainerHeader';
import MockGrid from './Grid/MockGrid';
import { AuthContext } from '../../providers/AuthProvider';
import HELP_URL from '../utils/helpLinkConstant';
import Constants from './Constants';
import { useApi } from '@backstage/core-plugin-api';
import { mockServiceApiRef } from '../../apis/mockServiceApi';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';

const MockPage = () => {
  const classes = useCustomStyles();
  const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showedContent, setShowedContent] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [filterText, setFilterText] = useState('');
  const { profEmail, isAdmin } = useContext(AuthContext);
  const [contentPerPage, setContentPerPage] = useState(20);
  const [filteredMocks, setFilteredMocks] = useState([]);

  const mockServiceApi = useApi(mockServiceApiRef);

  const filterString = (str: string) =>
    str.toString().replace(/[^a-zA-Z0-9]/g, '');

  useEffect(() => {
    setIsLoading(true);
    mockServiceApi
      .getAllMocks()
      .then((respData: any) => {
        if (respData && respData.data.length > 0) {
          setContent(respData.data);
          setShowedContent(respData.data);
          setCurrentIndex(0);
          setIsLoading(false);
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  }, [profEmail]);

  useEffect(() => {
    const begin = currentIndex * contentPerPage;
    const end = begin + contentPerPage;
    setShowedContent([...filteredMocks.slice(begin, end)]);
    setTotalCount(filteredMocks.length);
  }, [currentIndex, filteredMocks]);

  useEffect(() => {
    setCurrentIndex(0);
  }, [filteredMocks]);

  const onPageChange = (
    event: React.SyntheticEvent | null,
    pageNumber: number,
  ): void => {
    if (event) {
      event.preventDefault();
    }
    setCurrentIndex(pageNumber);
  };

  useEffect(() => {
    if (filterText === '') {
      setFilteredMocks(content);
    } else {
      setFilteredMocks(
        content.filter((c: { [x: string]: string }) => {
          let match = false;
          Object.keys(c).forEach((key: string) => {
            if (
              filterString(c[key])
                .toLowerCase()
                .includes(filterString(filterText).toLowerCase())
            ) {
              match = true;
            }
          });
          return match;
        }),
      );
    }
  }, [filterText, content]);

  return (
    <Page themeId="Mock API's">
      <Header
        title={Constants.pageTitle.mockPage}
        pageTitleOverride="Mock APIs"
        subtitle={
          <DevxBreadCrumb
            routes={[
              {
                type: 'link',
                link: '/',
                text: 'Home',
              },
              {
                type: 'text',
                link: '/api-mock',
                text: 'Mock APIs',
              },
            ]}
          />
        }
      />
      <div className={classes.feedbackPageContainer}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.feedbackPageSearch}
          >
            <BackButton />
            <HelpComponent helpUrl={HELP_URL.mockApiUrl} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MockContentHeader setFilterText={setFilterText} />
          </Grid>
          <div className={classes.requestTypeContainer}>
            {isLoading && <LinearProgress />}
            {filteredMocks && showedContent && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <MockGrid content={showedContent} isAdmin={isAdmin} />
              </Grid>
            )}
          </div>
          {filteredMocks && filteredMocks.length !== 0 && (
            <div className={classes.PagePaginationContainer}>
              <Paper>
                <TablePagination
                  SelectProps={{
                    title: 'perPageSelect',
                    native: true,
                  }}
                  component="div"
                  page={currentIndex}
                  rowsPerPage={contentPerPage}
                  count={totalCount}
                  onPageChange={onPageChange}
                  rowsPerPageOptions={[]}
                />
              </Paper>
            </div>
          )}
        </Grid>
      </div>
    </Page>
  );
};

export default MockPage;
