import React from 'react';
import { Grid } from '@material-ui/core';
import { PageWithHeader, Content } from '@backstage/core-components';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import BackButton from '../../utils/backButton';
import RegisterApi from './RegisterApi';
import RegisterSteps from './RegisterSteps';
import { useStyles } from './styles';
import { DevxBreadCrumb } from '../../common/BreadcrumbsNav/DevxBreadCrumb';

const RegisterApiPage = () => {
  const classes = useStyles();
  return (
    /* Register Api Page  */
    <PageWithHeader
      title="Register API "
      themeId="home"
      subtitle={
        <DevxBreadCrumb
          routes={[
            { type: 'link', link: '/', text: 'Home' },
            { type: 'link', link: '/API', text: 'APIs' },
            { type: 'text', link: '', text: 'Register API' },
          ]}
        />
      }
    >
      <Content>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <BackButton />

            <HelpComponent helpUrl={HELP_URL.REGISTER_API} />
          </Grid>
        </Grid>
        <div className="codePage">
          <Grid container direction="row" spacing={3}>
            {/* Register Api Component */}
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
              className={classes.registerApi}
            >
              <RegisterApi />
            </Grid>
            {/* Register Steps Component */}
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <RegisterSteps />
            </Grid>
          </Grid>
        </div>
      </Content>
    </PageWithHeader>
  );
};
export default RegisterApiPage;
