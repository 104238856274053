import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Base64Icon = (props: any) => (
  <SvgIcon
    viewBox={'48 48'}
    style={{ transform: 'scale(3)' }}
    fill="#000000"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 3H3V21H21V3ZM5.68994 14.9999V7.81995H8.43994C9.93994 7.81995 10.8599 8.47995 10.8599 9.76995C10.8599 10.5399 10.4399 11.0999 9.90994 11.2899C10.5899 11.5099 11.0799 12.1799 11.0799 12.9599C11.0799 14.2899 10.1599 14.9999 8.66994 14.9999H5.68994ZM7.12994 10.7599H8.34994C9.04994 10.7599 9.42994 10.4899 9.42994 9.90995C9.42994 9.32995 9.04994 9.07995 8.34994 9.07995H7.12994V10.7599ZM7.12994 11.9299V13.7399H8.54994C9.24994 13.7399 9.62994 13.3799 9.62994 12.8299C9.62994 12.2799 9.24994 11.9299 8.54994 11.9299H7.12994ZM14.6643 13.7779H13.7943C13.7103 13.5199 13.5303 13.3519 13.2183 13.3519C12.7083 13.3519 12.3903 13.9579 12.3843 14.5639V14.6239C12.5283 14.3599 12.8703 14.1739 13.2903 14.1739C14.1603 14.1739 14.7003 14.8159 14.7003 15.5839C14.7003 16.3999 14.1303 17.0719 13.1703 17.0719C12.6303 17.0719 12.1563 16.8139 11.8683 16.2919C11.6523 15.9079 11.5623 15.4879 11.5623 14.9359C11.5623 13.5979 12.1863 12.6199 13.2183 12.6199C14.0283 12.6199 14.5083 13.0579 14.6643 13.7779ZM13.1763 16.3339C13.5963 16.3339 13.8903 16.0399 13.8903 15.6079C13.8903 15.1639 13.5963 14.8639 13.1763 14.8639C12.7503 14.8639 12.4623 15.1579 12.4623 15.6079C12.4623 16.0399 12.7503 16.3339 13.1763 16.3339ZM15.9344 15.4099L17.3384 12.6919H16.4564L14.9744 15.4999V16.1419H16.8824V16.9999H17.6864V16.1419H18.3404V15.4099H17.6864V14.5159H16.8824V15.4099H15.9344Z"
      fill="#53DC3C"
    />
  </SvgIcon>
);

export default Base64Icon;
