import React, { useContext } from 'react';
import {
  TableColumn,
  Table,
  WarningPanel,
  CodeSnippet,
  TableProps,
} from '@backstage/core-components';
import {
  humanizeEntityRef,
  getEntityRelations,
  useStarredEntities,
} from '@backstage/plugin-catalog-react';
import { Link } from 'react-router-dom';
import * as columnFactories from '../../../utils/devXTableColumns';
import * as constant from '../../../utils/constant';
import { RELATION_OWNED_BY, RELATION_PART_OF } from '@backstage/catalog-model';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import SubscriptionsOutlinedIcon from '@material-ui/icons/SubscriptionsOutlined';
import { AuthContext } from '../../../../providers/AuthProvider';
import { useApi } from '@backstage/core-plugin-api';
import { subscriberApiRef } from '../../../../apis/subscriberApi';
import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    themeColor: {
      '& tr': {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.background.default + '!important',
        '& td ': {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: theme.palette.background.default + '!important',
          '& button ': {
            color: theme.palette.primary.main,
          },
        },
        '& th': {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: theme.palette.background.default + '!important',
        },
      },
      '& div::-webkit-scrollbar-thumb': {
        border: `4px solid ${theme.palette.text.tertiary}`,
      },
    },
  }),
);
const columns: TableColumn<columnFactories.EntityRow>[] = [
  columnFactories.createNameColumnCustomRoot({ rootRoute: 'catalog' }),
  columnFactories.createOwnerColumn(),
  columnFactories.createSpecTypeColumn(),
  columnFactories.createDateColumn(),
  columnFactories.createMetadataDescriptionColumn(),
  columnFactories.createTagsColumn(),
  columnFactories.createStatusColumn(),
  columnFactories.createRatingColumn(),
];

export const AllAssetsTable = ({
  loading,
  error,
  entities,
  checkFilterItem,
  starredFilterItem,
  subscribeData,
  setClickSubscribeIcon,
  isClickSubscribeIcon,
  isAdmin,
}: any) => {
  const { isStarredEntity, toggleStarredEntity } = useStarredEntities();
  let tableTitle = 'All';
  const classes = useStyles();
  const { profEmail } = useContext(AuthContext);
  const subscriberApi = useApi(subscriberApiRef);

  const userEmail = profEmail;

  const onClickSubscribe = async (data: any) => {
    const payload = {
      assetName: data?.metadata?.name,
      email: userEmail,
    };
    await subscriberApi.assetsSubscribe(payload);
    setClickSubscribeIcon(!isClickSubscribeIcon);
  };

  const onClickUnSubscribe = async (data: any) => {
    const payload = {
      assetName: data?.metadata?.name,
      email: userEmail,
    };
    await subscriberApi.assetsUnsubscribe(payload);
    setClickSubscribeIcon(!isClickSubscribeIcon);
  };

  const checkSubscribe = (data: any) => {
    let isChecksubscribe = false;
    subscribeData &&
      subscribeData.map(item => {
        if (
          data.metadata?.name.toLowerCase() === item?.assetName.toLowerCase() &&
          item?.email.toLowerCase() === userEmail!.toLowerCase()
        ) {
          isChecksubscribe = true;
        }
      });
    return isChecksubscribe;
  };

  const defaultActions: TableProps<columnFactories.EntityRow>['actions'] = [
    ({ entity }) => {
      const name = entity.metadata.name;
      const kind = entity.kind;
      const namespace = entity.metadata.namespace;
      const path = kind?.toLowerCase() === constant.API ? 'api-edit' : 'edit';
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const userProfile = profEmail?.split('@')[0].toLowerCase();
      const entityOwner = `${entity?.spec?.owner}`;
      const userOwnerShip =
        isAdmin ||
        userProfile?.toLowerCase() ===
          entityOwner.replace('user:', '')?.toLowerCase();
      return {
        icon: () => (
          <Link to={`/catalog/${namespace}/${kind}/${name}/${path}`}>
            <EditRoundedIcon fontSize="small" />
          </Link>
        ),
        tooltip: 'Edit',
        disabled: !userOwnerShip,
        onClick: () => {},
      };
    },

    ({ entity }) => {
      const isStarred = isStarredEntity(entity);
      return {
        cellStyle: { paddingLeft: '1em' },
        icon: () =>
          isStarred ? <BookmarkIcon /> : <BookmarkBorderOutlinedIcon />,
        tooltip: 'Bookmark',
        onClick: () => toggleStarredEntity(entity),
      };
    },
    ({ entity }) => {
      const userProfile = userEmail!.split('@')[0].toLowerCase();
      const entityOwner = `${entity?.spec?.owner}`;
      const userOwnerShip =
        userProfile?.toLowerCase() ===
        entityOwner.replace('user:', '')?.toLowerCase();

      const isSubscribe = checkSubscribe(entity);
      if (isSubscribe) {
        return {
          cellStyle: { paddingLeft: '1em' },
          icon: () => <SubscriptionsIcon />,
          tooltip: 'Unsubscribe',
          disabled: userOwnerShip,
          onClick: () => onClickUnSubscribe(entity),
        };
      }
      return {
        cellStyle: { paddingLeft: '1em' },
        icon: () => <SubscriptionsOutlinedIcon />,
        tooltip: 'Subscribe',
        disabled: userOwnerShip,
        onClick: () => onClickSubscribe(entity),
      };
    },
  ];

  let filterData = (entities && entities.length && entities) || [];

  if (checkFilterItem.isCodeFilterClick) {
    tableTitle = 'Code';
    filterData =
      entities &&
      entities.filter(
        (item: any) =>
          item.kind?.toLowerCase() === constant.COMPONENT.toLowerCase() &&
          item?.spec?.type?.toLowerCase() !==
            constant.IAC_COMPONENT_TYPE?.toLowerCase() &&
          item?.spec?.type?.toLowerCase() !==
            constant.SALESFORCE_COMPONENT_TYPE.toLowerCase(),
      );
  }
  if (checkFilterItem.isSalesforceFilterClick) {
    tableTitle = 'Salesforce';
    filterData =
      entities &&
      entities.filter(
        (item: any) =>
          item.kind?.toLowerCase() === constant.COMPONENT.toLowerCase() &&
          item?.spec?.type?.toLowerCase() ===
            constant.SALESFORCE_COMPONENT_TYPE?.toLowerCase(),
      );
  }

  if (checkFilterItem.isPackageFilterClick) {
    tableTitle = 'Package';
    filterData =
      entities &&
      entities.filter(
        (item: any) =>
          item.kind?.toLowerCase() === constant.COMPONENT.toLowerCase() &&
          item?.spec?.type?.toLowerCase() ===
            constant.PACKAGE_COMPONENT_TYPE?.toLowerCase(),
      );
  }

  if (checkFilterItem.isApiFilterClick) {
    tableTitle = 'API';
    filterData =
      entities &&
      entities.filter(
        (item: any) => item.kind?.toLowerCase() === constant.API.toLowerCase(),
      );
  }

  if (checkFilterItem.isFavoriteFilterClick) {
    tableTitle = 'Bookmark';
    filterData = starredFilterItem;
  }

  if (checkFilterItem.isIacFilterClick) {
    tableTitle = 'Infrastructure';
    filterData =
      entities &&
      entities.filter(
        (item: any) =>
          item.kind?.toLowerCase() === constant.COMPONENT?.toLowerCase() &&
          item?.spec?.type?.toLowerCase() ===
            constant.IAC_COMPONENT_TYPE?.toLowerCase(),
      );
  }
  if (checkFilterItem.isAIFilterClick) {
    tableTitle = 'AI/ML';
    filterData =
      entities &&
      entities.filter(
        (item: any) =>
          item.kind?.toLowerCase() === constant.COMPONENT?.toLowerCase() &&
          item?.spec?.type?.toLowerCase() ===
            constant.AI_COMPONENT_TYPE?.toLowerCase(),
      );
  }

  if (checkFilterItem.isExtensionFilterClick) {
    tableTitle = 'Extensions';
    filterData =
      entities &&
      entities.filter(
        (item: any) =>
          item.kind?.toLowerCase() === constant.COMPONENT?.toLowerCase() &&
          item?.spec?.type?.toLowerCase() ===
            constant.EXTENSION_COMPONENT_TYPE?.toLowerCase(),
      );
  }

  if (checkFilterItem.isSubscribeFilterClick) {
    tableTitle = 'Subscription';
    const subscriptionData: any[] = [];
    entities &&
      entities.map((item: any) => {
        subscribeData &&
          subscribeData.map((subscribeItem: any) => {
            if (
              item.metadata?.name.toLowerCase() ===
                subscribeItem?.assetName.toLowerCase() &&
              subscribeItem?.email.toLowerCase() === userEmail.toLowerCase()
            ) {
              subscriptionData.push(item);
            }
          });
      });

    filterData = subscriptionData;
  }

  const calcAvgScore = (a: any) => {
    if (!a?.metadata?.rating || a?.metadata?.rating?.length == 0) return 0;
    return (
      a.metadata?.rating?.reduce(
        (a: any, b: any) => (a.value || 0) + (b.value || 0),
      ) / a.metadata?.rating?.length
    );
  };

  const entityCreationDate = (a: any) => {
    if (!a.metadata?.creationTimestamp) return 0;
    return new Date(a?.metadata?.creationTimestamp).getTime();
  };

  let rows =
    filterData &&
    filterData.length &&
    filterData.map((entity: any) => {
      const partOfSystemRelations = getEntityRelations(
        entity,
        RELATION_PART_OF,
        {
          kind: 'system',
        },
      );
      const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);

      return {
        entity,
        resolved: {
          name: humanizeEntityRef(entity, {
            defaultKind: 'Component',
          }),
          ownedByRelationsTitle: ownedByRelations
            .map(r => humanizeEntityRef(r, { defaultKind: 'group' }))
            .join(', '),
          ownedByRelations,
          partOfSystemRelationTitle: partOfSystemRelations
            .map(r =>
              humanizeEntityRef(r, {
                defaultKind: 'system',
              }),
            )
            .join(', '),
          partOfSystemRelations,
        },
      };
    });
  if (checkFilterItem.isRecentlyPublishedFilterClick) {
    rows = rows.sort(
      (a: any, b: any) =>
        entityCreationDate(b.entity) - entityCreationDate(a.entity),
    );
    console.log(rows);
  }
  if (checkFilterItem.isTopRatingFilterClick) {
    rows = rows.sort(
      (a: any, b: any) => calcAvgScore(a.entity) - calcAvgScore(b.entitiy),
    );
  }

  if (error) {
    return (
      <div>
        <WarningPanel
          severity="error"
          title="Could not fetch catalog entities."
        >
          <CodeSnippet language="text" text={error.toString()} />
        </WarningPanel>
      </div>
    );
  }

  return (
    <div className={`mystuff-table ${classes.themeColor}`}>
      <Table
        isLoading={loading}
        columns={columns}
        options={{
          paging: true,
          pageSize: 20,
          search: true,
          actionsColumnIndex: -1,
          loadingType: 'linear',
          showEmptyDataSourceMessage: !loading,
          padding: 'dense',
          pageSizeOptions: [20],
        }}
        data={rows || []}
        actions={defaultActions}
        title={`${tableTitle} (${rows && rows.length})`}
      />
    </div>
  );
};
