import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /* Additional Info Page Styles Starts */
    additionalInfoPage: {
      [theme.breakpoints.down('sm')]: {
        padding: '10px',
      },
    },
    errorForAdd:{
      margin:'8px'
    },
    CardActionButton: {
      height: '100%',
      minWidth: '0px',
      padding: '3px',
      background: 'transparent',
      '&$disabled': {
        cursor: 'not-allowed',
      },
    },
    SvgColorFill: {
      fill: theme.palette.primary.main,
    },
    additionalDetailsPage: {
      marginBottom: '84px',
      padding: '26px !important',
      background: theme.palette.background.paper,
      boxShadow:
        '0px 1px 1px 0px rgba(9, 30, 66, 0.25), 0px 0px 1px 0px rgba(9, 30, 66, 0.31)',
    },
    additionalInfoHeading: {
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 500,
    },
    headingMargin: {
      marginTop: '35px',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
    },
    margin: {
      marginTop: '20px',
    },
    inputFields: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      gap: '10px',
      marginTop: '20px',
      width: '100%'
    },
    fieldIcons: {
      display: 'flex',
      borderRadius: '4px',
      border: `1px solid ${theme.palette.primary.main}`,
      justifyContent: 'center',
      alignItems: 'center',
      padding: '14px',
    },
    description: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '16px',
      color: theme.palette.text.tertiary,
    },
    docs: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    addItemButton: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '16px',
    },
    fileFields: {
      marginTop: '30px',
    },
    flex: {
      display: 'flex',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '40px 0px 10px 0px',
      gap: '10px',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    additionalPocButtons: {
      textTransform: 'capitalize',
    },
    /* Additional Info Page Styles ends */

    /* POC CARD Styles starts */

    detailsPage: {
      [theme.breakpoints.down('sm')]: {
        padding: '10px',
      },
    },
    pocCardContainer: {
      height: '350px',
    },
    listCard: {
      height: '100%',
      cursor: 'pointer',
      borderRadius: '6px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
    },
    cardContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      padding: '0px 15px',
    },
    listCardContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    cardTitle: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '22px',
      textTransform: 'capitalize',
      color: theme.palette.text.secondary,
      wordBreak: 'break-word',
      textOverflow: 'ellipsis',
      display: '-webkit-inline-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
    },
    cardOwner: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '22px',
      color: theme.palette.text.secondary,
      wordBreak: 'break-word',
      textOverflow: 'ellipsis',
      display: '-webkit-inline-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
    },
    by: {
      color: theme.palette.text.tertiary,
      marginRight: '5px',
    },
    ownerName: {
      color: theme.palette.text.secondary,
    },
    timeSince: {
      color: theme.palette.text.tertiary,
    },
    avatarContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
    },

    cardDescription: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      color: theme.palette.text.tertiary,
      wordBreak: 'break-word',
      textOverflow: 'ellipsis',
      display: '-webkit-inline-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
    },
    image: {
      width: '100%',
      height: '60%',
      objectFit: 'cover',
    },
    tags: {
      padding: '5px 15px',
      display: 'block',
      overflowX: 'hidden',
      '&:hover': {
        overflowX: 'hidden',
      },
    },
    tagChip: {
      padding: '6px',
      display: 'inline',
    },
    /* POC CARD Styles ends */

    /* UserListPicker Styles starts */
    root: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      margin: theme.spacing(1, 0, 1, 0),
    },
    title: {
      margin: theme.spacing(1, 0, 0, 1),
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 'bold',
    },
    listIcon: {
      minWidth: 30,
      color: theme.palette.text.primary,
    },
    menuItem: {
      minHeight: theme.spacing(6),
    },
    groupWrapper: {
      margin: theme.spacing(1, 1, 2, 1),
    },

    /* UserListPicker Styles ends */

    /*MultiStep JSON FORM Styles starts */
    chatbotMargin: {
      marginBottom: '84px',
    },

    templateBackBtn: {
      ['@media (max-width:920px) and (min-width: 280px)']: {
        width: '100%',
      },
    },
    templateNextBtn: {
      ['@media (max-width:920px) and (min-width: 280px)']: {
        width: '100%',
      },
    },

    /*Poc Details Page Styles starts */

    detailsPageInfo: {
      [theme.breakpoints.down('sm')]: {
        padding: '10px',
      },
    },
    bold: {
      fontWeight: 'bold'
    },
    spacedCharacter: {
      marginRight: '6px',
      marginLeft: '4px'
    },
    pocCard: {
      background: theme.palette.background.paper,
      padding: '24px',
      marginTop: '30px',
    },
    poc: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    pocTitle: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: ' 32px',
      color: theme.palette.text.secondary,
    },
    pocDesc: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: ' 20px',
      color: theme.palette.text.tertiary,
      wordBreak: 'break-word',
      // marginTop: '20px',
    },
    artifacts: {
      display: 'flex',
      padding: '10px 20px 20px 20px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      borderRadius: ' 6px',
      background: theme.palette.background.tertiary,
      width: '250px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    div: {
      width: '100%'
    },
    pocDetailimage: {
      width: '100%',
      // height: '40%',
    },
    detailsContent: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '30px',
      flex: '1 0 0',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    detailsDescription: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flex: '1 0 0',
      padding: '0px 20px',
      [theme.breakpoints.down('sm')]: {
        padding: '0px',
      },
    },
    tag: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    chip: {
      margin: '15px 0px 0px 0px',
      width: '100%',
    },
    pocHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    pocBody: {
      marginTop: '20px',
      padding: '10px',
      // display: 'flex',
      // flexDirection: 'row',
    },
    pocOptions: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    pocImage: {
      border: '1px solid',
      display: 'flex',
      justifyContent: 'center',
    },
    pocButton: {
      display: 'flex',
      gap: '10px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    titleBookmark: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    pcoDocumentTitle: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: ' 32px',
      color: theme.palette.text.secondary,
      // margin: '15px 0px 0px 0px',
    },
    link: {
      margin: '15px 0px 0px 0px',
      width: '100%',
      textDecoration: 'none',
      wordBreak: 'break-word',
    },
    pocEdit: {
      paddingLeft: '2px',
    },
    buttonDemo: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    pocIcons: {
      padding: '0px',
      margin: '0px',
    },
    preview: {
      display: 'flex',
      flexDirection: 'column',
      width: 'fit-content',
    },
    linkPreview: {
      display: 'flex',
      flexDirection: 'column',
    },
    iframe: {
      width: '100%',
      height: '150px',
    },
    demoCloneButtons: {
      display: 'flex',
      gap: '15px',
      margin: '15px 0px 0px 0px',
    },
    /*Poc Details Page Styles ends */

    /*Poc List Page Styles starts */

    listPage: {
      padding: '0px 24px',
      [theme.breakpoints.down('sm')]: {
        padding: '10px',
      },
    },
    listHeader: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    addPOCButton: {
      textTransform: 'capitalize',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      marginBottom: '20px',
    },
    addPoc: {
      padding: '6px 16px',
    },

    /*Poc List Page Styles ends */

    /*Poc List Container Styles starts */

    listContainerMargin: {
      padding: '24px',
    },
    ContentHeaderStyle: {
      width: '100%',
      padding: '0px',
      marginBottom: '10px',
      '& > form:first-of-type': {
        display: 'flex',
        '& > button': {
          flex: '0.2',
        },
        '& > div': {
          flex: '10',
        },
      },
    },
    input: {
      flex: '10',
    },
    PaginationBar: {
      marginTop: '10px',
      marginBottom: '84px',
    },
    pocContainerCard: {
      margin: '20px',
      paddingTop: '20px',
    },
    errorMessage: {
      color: 'red',
    },
    fileUploadContainer: {
      width: '100%',
    },
    fileUploadContainerMargin: {
      margin: '12px 4px'
    },
    /*Poc List Container Styles ends */

    /* Edit POC page styles Start*/
    editPocPage: {
      [theme.breakpoints.down('sm')]: {
        padding: '0px',
      },
    },
    errortext: {
      color: '#FF543E',
      padding: '7px',
      fontSize: '14px',
    },
    product: {
      background: theme.palette.background.paper,
      borderRadius: '6px',
      marginBottom: '50px',
      padding: '30px 20px 20px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '20px',
      },
    },
    pages: {
      display: 'flex',
      background: theme.palette.background.tertiary,
      padding: '10px',
      width: '100%'
    },
    btnGroupMain: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    btnGroup: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginTop: '25px',
      display: 'flex',

      '& Button': {
        textTransform: 'capitalize',
        fontStyle: 'normal',
        fontWeight: 500,
        padding: '12px 16px',
        fontSize: '16px',
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '4px',
        lineHeight: ' 16px',
      },
    },
    testButton: {
      marginLeft: '12px',
    },
    headingText: {
      fontSize: '24px',
      fontWeight: 'bold',
      marginBottom: '14px',
    },
    subHeadingText: {
      fontSize: '16px',
      fontWeight: 'bold',
      margin: '32px 0px 10px 0px',
    },
    tagsField: {
      marginTop: '15px',
    },
    inputField: {
      margin: '12px 4px',
    },
    descError: {
      marginLeft: '14px',
      marginRight: '14px',
      color: theme.palette.errorText,
      fontSize: ' 0.75rem',
      marginTop: '14px',
    },
    popper: {
      width: '100%',
    },
    helperText: {
      display: 'flex',
      justifyContent: 'end',
      color: theme.palette.text.tertiary,
    },
    doucmentsIcons: {
      borderRadius: '4px',
      border: `1px  solid ${theme.palette.primary.light}`,
      margin: '12px 0px 36px 6px',
    },
    thumbnail: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    documents: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    addLink: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '16px',
      color: theme.palette.text.secondary,
      display: 'flex',
      alignItems: 'center',
    },
    actionIcons: {
      marginLeft: '10px',
      color: theme.palette.primary.main,
    },
    inputHide: {
      display: 'none',
    },

    addPocContainer: {
      '& label': {
        fontSize: '20px !important',
        fontWeight: 'normal !important',
        transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: 'top left',
      },
    },
    checkBoxLabel: {
      transform: 'translate(0, 1.5px) scale(0.9) !important',
    },

    /* Edit POC page styles Ends*/
  }),
);
