import React from 'react';
import { SnykCountButton } from './SnykCountButton';
import { makeStyles } from '@material-ui/core';

export const SnykCountComponent = ({ issuesCount }: { issuesCount: any }) => {
  const onClickCount = () => {
    // const link = `https://${appHost}/org/${value?.orgData?.data?.attributes?.slug}/project/${projectId}`;
    // window.open(link, '_blank');
  };
  const useStyles = makeStyles(() => ({
    snykCountButtonDiv: {
      display: 'flex',
      marginBottom: '16px',
    },
  }));
  const classes = useStyles();

  return (
    <div>
      <div className={classes.snykCountButtonDiv}>
        <SnykCountButton
          count={issuesCount?.critical}
          severity="C"
          onClick={onClickCount}
        />
        <SnykCountButton
          count={issuesCount?.high}
          severity="H"
          onClick={onClickCount}
        />
        <SnykCountButton
          count={issuesCount?.medium}
          severity="M"
          onClick={onClickCount}
        />
        <SnykCountButton
          count={issuesCount?.low}
          severity="L"
          onClick={onClickCount}
        />
      </div>
    </div>
  );
};
