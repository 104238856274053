import { Content, PageWithHeader, Progress } from '@backstage/core-components';
import {
  Chip,
  Grid,
  Typography,
  Link,
  Button,
  IconButton,
  LinearProgress,
} from '@material-ui/core';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import React, { useContext, useState, useEffect } from 'react';
import { DevxBreadCrumb } from '../../common/BreadcrumbsNav/DevxBreadCrumb';
import BackButton from '../../utils/backButton';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useNavigate, useParams } from 'react-router';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { useStarredEntities } from '@backstage/plugin-catalog-react';
import { useStyles } from '../common/styles';
import noImage from '../../../../src/assest/poc-default image.jpg';
import { useDeletePocComponent } from '../hooks/useDeletePocComponent';
import { AuthContext } from '../../../providers/AuthProvider';
import ConfirmDelete from '../DeletePocPage/ConfirmDelete';
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { Entity } from '@backstage/catalog-model';
import { ToolboxApi, toolboxApiRef } from '../../../apis/toolboxApi';
import { useGetPocComponent } from '../hooks/useGetPocComponent';
import { NoPocFound } from '../NoPocFound/NoPocFound';
import { LinkPreview } from './LinkPreview';
import { pocHubApiRef } from '../../../apis/pocHubApi';
import UserAvatar from '../../common/UserAvatar';
import { timeSince } from '../../utils/dateUtils';

//refetching github link every 5 minutes due to token expiring
const REFETCH_INTERVAL = 300 * 1000;

export const PocDetailsPage = () => {
  const classes = useStyles();
  const { assetName } = useParams();
  const pocApi = useApi(pocHubApiRef);
  const { isStarredEntity, toggleStarredEntity } = useStarredEntities();
  const { isAdmin } = useContext(AuthContext);
  const { deletePoc } = useDeletePocComponent();
  const { getPoc } = useGetPocComponent();
  const { profEmail } = useContext(AuthContext);
  const userId = profEmail.split('@')[0].toLowerCase();
  const [entity, setEntity] = useState<Entity | undefined>();
  const isOwner =
    userId === `${entity?.spec?.owner}`?.toLowerCase()?.replace('user:', '');
  const canEDelete = isOwner || isAdmin;
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [deleteConfirmMessage, setDeleteConfirmMessage] = useState('');
  const [deleteConfirmPocName, setDeleteConfirmPocName] = useState('');
  const [open, setOpen] = useState(false);
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const [imageData, setImageData] = useState<string | undefined>();
  const toolboxApi: ToolboxApi = useApi(toolboxApiRef);
  const [imageMeta, setImageMeta] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState<any[]>([]);
  const [demoObj, setDemoObj] = useState<any>(null);

  const deletePOC = (name: string) => {
    setShowDeleteConfirmDialog(false);
    setOpen(true);
    deletePoc(name);
  };

  const fetchDocList = async () => {
    let l = [];
    const fl = entity?.metadata?.links || [];
    const filteredList = fl.filter(a => a.type !== 'demo');
    for (let i = 0; i < filteredList.length; i++) {
      await pocApi.readFile(encodeURI(filteredList[i].url)).then(data => {
        if (data && data?.url?.download_url) {
          l.push({
            title: filteredList[i].title,
            url: data?.url?.download_url,
          });
        } else {
          l.push({
            title: filteredList[i].title,
            url: filteredList[i].url,
          });
        }
      });
    }
    setLinks(l);
  };

  const confirmDeleteProps = {
    open: showDeleteConfirmDialog,
    onConfirm: () => deletePOC(deleteConfirmPocName),
    onReject: () => {
      setShowDeleteConfirmDialog(false);
      setOpen(false);
    },
    content: deleteConfirmMessage,
    pocName: deleteConfirmPocName,
  };

  const promptUserForConfirmation = (pocName: string) => {
    setDeleteConfirmMessage(`POC '${pocName}' will be deleted`);
    setDeleteConfirmPocName(pocName);
    setShowDeleteConfirmDialog(true);
  };

  const metaMap = {
    png: 'data:image/png;base64,',
    svg: 'data:image/svg+xml;base64,',
    jpg: 'data:image/jpeg;base64,',
    jpeg: 'data:image/jpeg;base64,'
  };
  const getDefaultMetadata = () => {
    let imageKey = entity?.metadata.image;
    if (imageKey !== undefined) {
      const ext = String(imageKey).substring(
        String(imageKey)?.lastIndexOf('.') + 1,
      );
      // @ts-ignore
      return metaMap[ext];
    }
    return 'data:image/svg+xml;base64,';
  };

  const onDemoLinkClick = () => {
    if (window) {
      const demoLink = entity?.metadata?.links.find(a => a.type === 'demo');
      window.open(demoLink.url || '', '_blank');
    }
  };
  const onEditClick = () => {
    navigate(`/poc-hub/edit-poc/${entity?.metadata?.name}`);
  };

  useEffect(() => {
    setLoading(false);
    if (
      entity?.metadata?.image !== undefined &&
      String(entity?.metadata.image).trim().length > 0
    ) {
      toolboxApi
        .getImageData(String(entity?.metadata?.image))
        .then((data: any) => {
          setImageData(data);
        })
        .catch(() => {
          notificationApi.sendNotification({
            message: `Failed to load image for tool ${entity?.metadata.title}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });

      if (entity?.metadata.imageMetaForBase64 === undefined) {
        setImageMeta(getDefaultMetadata());
      } else {
        setImageMeta(
          String(entity?.metadata?.imageMetaForBase64).replace(',', '') + ',',
        );
      }
    }
    if (
      entity?.metadata?.links !== undefined &&
      entity?.metadata?.links.length > 0
    ) {
      fetchDocList();
      setInterval(fetchDocList, REFETCH_INTERVAL);
    }

    const demoObj =
      entity?.metadata?.links?.find(
        (linkObj: any) => linkObj.type === 'demo',
      ) || null;

    setDemoObj(demoObj);
  }, [entity]);

  useEffect(() => {
    setLoading(true);
    getPoc(assetName)?.then(value => {
      setEntity(value);
      setLoading(false);
    });
  }, []);

  const navigate = useNavigate();
  const navigateToClonePage = () => {
    navigate(`/poc-hub/${assetName}/clone-poc`, { state: { data: entity } });
  };

  const ownerName = entity?.spec?.owner?.split(':')[1];

  return (
    <>
      {loading && <LinearProgress />}
      {entity ? (
        <PageWithHeader
          title={entity?.metadata?.name}
          themeId="home"
          subtitle={
            <DevxBreadCrumb
              routes={[
                { type: 'link', link: '/', text: 'Home' },
                {
                  type: 'link',
                  link: '/poc-hub',
                  text: 'POC Hub',
                },
                {
                  type: 'text',
                  link: ' ',
                  text: `${entity?.metadata?.name}`,
                },
              ]}
            />
          }
        >
          <Content>
            <Grid container direction="row" spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <BackButton />
                <HelpComponent helpUrl={HELP_URL.POC_DETAILS_PAGE} />
              </Grid>
            </Grid>
            {loading && <Progress />}
            <div
              className={`codePage ${classes.detailsPageInfo} ${classes.pocCard}`}
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {!entity && <NoPocFound />}
                  {/* <div className={classes.poc}> */}
                  <div className={classes.pocHeader}>
                    <div className={classes.titleBookmark}>
                      <Typography className={classes.pocTitle}>
                        {entity?.metadata?.name}
                      </Typography>
                      <Button className={classes.CardActionButton}>
                        {isStarredEntity(entity) ? (
                          <BookmarkIcon
                            onClick={() => {
                              toggleStarredEntity(entity);
                            }}
                            className={classes.SvgColorFill}
                          />
                        ) : (
                          <BookmarkBorderOutlinedIcon
                            onClick={() => {
                              toggleStarredEntity(entity);
                            }}
                            className={classes.SvgColorFill}
                          />
                        )}
                      </Button>
                    </div>

                    <div className={classes.pocButton}>
                      {/* <BookmarkIcon /> */}

                      {canEDelete && (
                        <>
                          <IconButton
                            className={classes.pocIcons}
                            color="primary"
                            onClick={onEditClick}
                          >
                            <EditIcon />
                          </IconButton>

                          <IconButton
                            className={classes.pocIcons}
                            color="primary"
                            onClick={() =>
                              promptUserForConfirmation(entity?.metadata?.name)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </>
                      )}
                    </div>
                  </div>{' '}
                </Grid>

                <Grid item>
                  <div className={classes.avatarContainer}>
                    <UserAvatar
                      userEmail={entity?.spec?.owneremail}
                      customStyles={{
                        width: '32px',
                        height: '32px',
                        fontSize: '12px',
                        fontWeight: 'normal',
                      }}
                    />
                    <div className={classes.ownerName}>{ownerName}</div>
                    <div className={classes.timeSince}>
                      {timeSince(entity?.metadata?.creationTimestamp)}
                    </div>
                  </div>
                </Grid>
              </Grid>

              <Grid container className={classes.pocBody}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  className={classes.pocImage}
                >
                  {entity?.metadata?.image ? (
                    <img
                      src={imageData ? `${imageMeta}${imageData}` : ''}
                      alt=""
                      className={classes.pocDetailimage}
                    />
                  ) : (
                    <img
                      src={noImage}
                      alt=""
                      className={classes.pocDetailimage}
                    />
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  className={classes.detailsContent}
                >
                  <div className={classes.detailsDescription}>
                    <div className={classes.pocDesc}>
                      {entity?.metadata?.description}
                    </div>
                    <div className={classes.tag}>
                      <div className={classes.chip}>
                        {entity?.metadata?.tags &&
                          entity?.metadata?.tags.map((tag: any) => (
                            <Chip label={tag.slice(0, 15)} key={tag} />
                          ))}
                      </div>
                    </div>
                    <div className={classes.demoCloneButtons}>
                      {demoObj ? (
                        <Button
                          variant="contained"
                          color="primary"
                          href={demoObj?.url}
                          className={classes.buttonDemo}
                          //onClick={onDemoLinkClick}
                          target="_blank"
                          size="large"
                        >
                          Demo
                        </Button>
                      ) : null}

                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.buttonDemo}
                        onClick={navigateToClonePage}
                        size="large"
                      >
                        Clone
                        {/* <ExpandMore /> */}
                      </Button>
                    </div>
                  </div>
                  <div className={classes.artifacts}>
                    <div className={classes.pcoDocumentTitle}>
                      POC Documents & Artifacts
                    </div>

                    <div className={classes.linkPreview}>
                      {links &&
                        links.length > 0 &&
                        links
                          .filter((l: any) => l.type !== 'demo')
                          .map((link: any) => {
                            console.log(link);
                            return (
                              <div key={link.url}>
                                <LinkPreview
                                  url={link.url}
                                  onClick={async () => {
                                    await pocApi.readFile(encodeURI(link.url));
                                  }}
                                >
                                  <Link
                                    className={classes.link}
                                    href={link.url}
                                    target="_blank"
                                    download
                                  >
                                    {link.title}
                                  </Link>
                                </LinkPreview>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </Grid>
              </Grid>

              {/* </div> */}
            </div>

            {showDeleteConfirmDialog && (
              <ConfirmDelete {...confirmDeleteProps}></ConfirmDelete>
            )}
          </Content>
        </PageWithHeader>
      ) : null}
    </>
  );
};
