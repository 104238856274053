import {
  Chip,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { useStyles } from '../../styles';
import { IActivityLog } from '../../interfaces';
import { DateTime } from 'luxon';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const ActivityTable = ({
  activityLog,
  loading,
}: {
  activityLog: IActivityLog[];
  loading: boolean;
}) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Data & Time</TableCell>
            <TableCell align="left">Tools/Service</TableCell>
            <TableCell align="left">Request</TableCell>
            <TableCell align="left">Requested by</TableCell>
            <TableCell align="left">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activityLog
            ?.sort((a, b) =>
              a?.requestdatetime < b?.requestdatetime
                ? 1
                : a?.requestdatetime > b?.requestdatetime
                ? -1
                : 0,
            )
            .map((row, idx) => (
              <TableRow key={`${row.name} ${idx}`}>
                <TableCell component="th" scope="row">
                  {DateTime.fromISO(row.requestdatetime).toFormat(
                    'dd MMM yyyy, hh:mm a',
                  )}
                </TableCell>
                <TableCell align="left">
                  <Chip
                    size="small"
                    label={row.type}
                    className={`${classes.activityChip} ${
                      row.type === 'service'
                        ? classes.serviceChip
                        : classes.toolChip
                    }`}
                  />
                  <span>{row.name}</span>
                </TableCell>
                <TableCell align="left">{row.action || '-'}</TableCell>
                <TableCell align="left">{row.requester}</TableCell>
                <TableCell align="left" className={classes.statusCell}>
                  <FiberManualRecordIcon
                    className={classes[`color${row.statusmsg}`]}
                  />
                  <span>{row.statusmsg}</span>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {activityLog?.length === 0 && (
        <Table aria-label="simple table">
          <TableRow key="noActivity">
            <TableCell component="th" scope="row">
              {loading ? <LinearProgress /> : 'No Activity logs found'}
            </TableCell>
          </TableRow>
        </Table>
      )}
    </TableContainer>
  );
};

export default ActivityTable;
