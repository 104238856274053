import React from 'react';
import { Route, Routes } from 'react-router';
import PromptStoreHome from './PromptStoreHome';
import PromptEnhancerPage from './PromptEnhancerPage';
import PromptStoreTabs from './PromptStoreTabs';

const PSRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<PromptStoreHome />} /> */}
      <Route path="/*" element={<PromptStoreTabs />} />
      <Route path="/prompt-enhancer" element={<PromptEnhancerPage />} />
      <Route path="/prompt-enhancer/v2" element={<PromptEnhancerPage />} />
    </Routes>
  );
};

export default PSRoutes;
