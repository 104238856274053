/* eslint-disable no-nested-ternary */
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useStyles } from '../../../../styles';
import { IApmDetails } from '../../../../interfaces';
import DeleteOutlineOutlined from '@material-ui/icons/DeleteOutlineOutlined';
import AddIcon from '@material-ui/icons/Add';
import { v4 as uuidv4 } from 'uuid';
import CONSTANTS from '../contants';

const TestPlan = ({
  isVisible,
  apmInfo,
  onPrevious,
  onNext,
  initialState,
}: {
  isVisible: boolean;
  apmInfo: IApmDetails | null;
  onPrevious: Function;
  onNext: Function;
  initialState: any;
}) => {
  const classes = useStyles();

  const [formObject, setFormObject] = useState({
    repoBranchCloneUrl: '',
    repoBranch: '',
    testPlan: '',
    userPropertyFile: '',
    configurationFiles: [],
    secrets: [],
  });

  const validateUrl = (value: string) => {
    const githubUrlRegex = new RegExp(
      'https://github.com/[a-zA-Z0-9_.-]+/[a-zA-Z0-9_.-]+',
    );
    const azureUrlRegex = new RegExp(
      'https://dev.azure.com/[a-zA-Z0-9_.-]+/[a-zA-Z 0-9_.-]+/_git/[a-zA-Z 0-9_.-]+',
    );
    const formattedValue = value.replaceAll('%20', '-');
    return (
      githubUrlRegex.test(formattedValue) || azureUrlRegex.test(formattedValue)
    );
  };

  const validateFileType = (value: string, fileType: string) => {
    return value.endsWith(fileType);
  };

  const regexValidations = {
    repoBranchCloneUrl: (value: string) => {
      return validateUrl(value);
    },
    repoBranch: (value: string) => {
      return value;
    },
    testPlan: (value: string) => {
      return validateUrl(value);
    },
  };

  const fileValidations = {
    testPlan: (value: string) => {
      return validateFileType(value, '.jmx');
    },
    userPropertyFile: (value: string) => {
      return validateFileType(value, '.properties');
    },
  };

  useEffect(() => {
    if (initialState && initialState.apmNumber) {
      setFormObject({
        ...formObject,
        repoBranchCloneUrl: initialState.repoBranchCloneUrl,
        repoBranch: initialState.repoBranch,
        testPlan: initialState.testPlan,
        userPropertyFile: initialState.userPropertyFile,
        configurationFiles: initialState.configurationFiles,
        secrets: initialState.secrets,
      });
      if (initialState.configurationFiles?.length > 0) {
        setDependencyFiles(
          initialState.configurationFiles.map((path: string) => {
            return {
              id: uuidv4(),
              path,
            };
          }),
        );
      }
      if (initialState.secrets?.length > 0) {
        setSecrets(
          initialState.secrets.map((secret: any) => {
            return {
              id: uuidv4(),
              name: secret.name,
              value: secret.value,
            };
          }),
        );
      }
    }
  }, [initialState]);

  const [errors, setErrors] = useState<any>({
    repoBranchCloneUrl: '',
    repoBranch: '',
    testPlan: '',
  });

  const emptyErrorState = {
    repoBranchCloneUrl: '',
    repoBranch: '',
    testPlan: '',
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, required } = event.target;
    setFormObject((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    if (required) {
      setErrors((prevState: any) => {
        return {
          ...prevState,
          [name]: value ? '' : CONSTANTS.errors[name as keyof Object],
        };
      });
    }
    if (errors[name]) {
      setErrors((prevState: any) => {
        return {
          ...prevState,
          [name]: '',
        };
      });
    }
  };

  const [dependencyFiles, setDependencyFiles] = useState([
    {
      id: uuidv4(),
      path: '',
    },
  ]);

  const handleAddDependency = () => {
    setDependencyFiles(prevState => [
      ...prevState,
      {
        id: uuidv4(),
        path: '',
      },
    ]);
  };

  const handleDeleteDependency = (id: string) => {
    setDependencyFiles(
      dependencyFiles.filter(
        (dependencyFile: any) => dependencyFile?.id !== id,
      ),
    );
  };

  const handleDependencyChange = (event: any, id: string) => {
    const { value } = event.target;
    setDependencyFiles((prevState: any) =>
      prevState.map((dependencyFile: any) => {
        return dependencyFile.id === id
          ? { ...dependencyFile, path: value }
          : dependencyFile;
      }),
    );
    if (errors[`dependencyFile-${id}`]) {
      setErrors((prevState: any) => {
        return {
          ...prevState,
          [`dependencyFile-${id}`]: '',
        };
      });
    }
  };

  const [secrets, setSecrets] = useState([
    {
      id: uuidv4(),
      name: '',
      value: '',
    },
  ]);

  const handleAddSecrets = () => {
    setSecrets(prevState => [
      ...prevState,
      {
        id: uuidv4(),
        name: '',
        value: '',
      },
    ]);
  };

  const handleDeleteSecret = (id: string) => {
    setSecrets(secrets.filter((secret: any) => secret?.id !== id));
  };

  const handlSecretChange = (event: any, id: string) => {
    const { name, value } = event.target;
    setSecrets((prevState: any) =>
      prevState.map((dependencyFile: any) => {
        return dependencyFile.id === id
          ? { ...dependencyFile, [name.split('-')[0]]: value }
          : dependencyFile;
      }),
    );
    if (errors[name]) {
      setErrors((prevState: any) => {
        return {
          ...prevState,
          [name]: '',
        };
      });
    }
  };

  const validate = () => {
    const errorObj: any = {};
    Object.keys(emptyErrorState).forEach(key => {
      const objKey = key as keyof Object;
      errorObj[objKey] =
        formObject[objKey] && !regexValidations[objKey](formObject[objKey])
          ? 'Url not valid'
          : !formObject[objKey]
          ? `${[key]} field is required`
          : '';
    });
    // validate file types
    errorObj.testPlan =
      formObject.testPlan &&
        !fileValidations.testPlan(formObject.testPlan)
        ? 'Test plan file should be .jmx'
        : errorObj.testPlan;
    errorObj.userPropertyFile =
      formObject.userPropertyFile &&
      !fileValidations.userPropertyFile(formObject.userPropertyFile)
        ? 'User property file should be .properties'
        : errorObj.userPropertyFile;

    // check if duplicate secrets or dependency Files;
    dependencyFiles.forEach((dependencyFile: any, index: number) => {
      dependencyFiles.forEach((file: any, fileIndex: number) => {
        if (dependencyFile.path === file.path && index !== fileIndex) {
          errorObj[`dependencyFile-${dependencyFile.id}`] = 'Duplicate file';
        }
      });
    });

    secrets.forEach((secret: any, index: number) => {
      secrets.forEach((sec: any, secIndex: number) => {
        if (secret.name === sec.name && index !== secIndex) {
          errorObj[`name-${secret.id}`] = 'Duplicate secret name';
        }
      });
    });

    setErrors(errorObj);
    return Object.values(errorObj).every((x: any) => !x);
  };

  const handleNext = () => {
    if (validate()) {
      const testPlanReqObj = {
        ...formObject,
        userPropertyFile:
          formObject.userPropertyFile === ''
            ? null
            : formObject.userPropertyFile,
        configurationFiles:
          dependencyFiles.length === 0 ||
          (dependencyFiles.length === 1 && dependencyFiles[0].path === '')
            ? []
            : dependencyFiles.map((dependencyFile: any) => dependencyFile.path),
        secrets:
          secrets.length === 0 ||
          (secrets.length === 1 &&
            secrets[0].name === '' &&
            secrets[0].value === '')
            ? null
            : secrets.filter(
                (secret: any) => secret.name !== '' && secret.value !== '',
              ),
      };
      setFormObject((prevState: any) => {
        return { ...prevState, ...testPlanReqObj };
      });
      onNext(testPlanReqObj);
    }
  };

  return (
    <Card
      className={`${classes.heigt100Percent} ${isVisible ? '' : classes.displayNone
      } `}
    >
      <CardHeader title="Test Plan" />
      <CardContent className={`${classes.overflowAuto}`}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8} lg={8}>
            <TextField
              required
              label="Repository Branch URL"
              variant="outlined"
              fullWidth
              value={formObject.repoBranchCloneUrl}
              name="repoBranchCloneUrl"
              onChange={handleChange}
              {...(errors.repoBranchCloneUrl && {
                error: true,
                helperText: errors.repoBranchCloneUrl,
              })}
            />
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <TextField
              required
              label="Repository Branch Name"
              variant="outlined"
              fullWidth
              value={formObject.repoBranch}
              name="repoBranch"
              onChange={handleChange}
              {...(errors.repoBranch && {
                error: true,
                helperText: errors.repoBranch,
              })}
            />
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <TextField
              required
              label="Test plan file path(.jmx)"
              variant="outlined"
              fullWidth
              value={formObject.testPlan}
              name="testPlan"
              onChange={handleChange}
              {...(errors.testPlan && {
                error: true,
                helperText: errors.testPlan,
              })}
            />
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <TextField
              label="User properties file path"
              variant="outlined"
              fullWidth
              value={formObject.userPropertyFile}
              name="userPropertyFile"
              onChange={handleChange}
              {...(errors.userPropertyFile && {
                error: true,
                helperText: errors.userPropertyFile,
              })}
            />
          </Grid>
        </Grid>
        <section>
          <Grid container>
            <Grid
              item
              xs={12}
              md={8}
              lg={8}
              className={`${classes.flexSpacebetween} ${classes.flexCenter}`}
            >
              <h3>Dependency files</h3>
              <IconButton
                className={classes.padding1}
                onClick={handleAddDependency}
              >
                <AddIcon fontSize="medium" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            {dependencyFiles.map((dependencyFile: any) => {
              return (
                <Fragment key={dependencyFile.id}>
                  <Grid
                    item
                    xs="auto"
                    md={8}
                    lg={8}
                    className={classes.flexGrow1}
                  >
                    <TextField
                      label="File path"
                      variant="outlined"
                      value={dependencyFile.path}
                      onChange={event =>
                        handleDependencyChange(event, dependencyFile.id)
                      }
                      fullWidth
                      name={`dependencyFile-${dependencyFile.id}`}
                      {...(errors[`dependencyFile-${dependencyFile.id}`] && {
                        error: true,
                        helperText: errors[`dependencyFile-${dependencyFile.id}`],
                      })}
                    />
                  </Grid>
                  <Grid
                    item
                    xs="auto"
                    md={4}
                    lg={4}
                    className={`${classes.flexCenter}`}
                  >
                    <IconButton
                      className={classes.padding1}
                      onClick={() => handleDeleteDependency(dependencyFile.id)}
                    >
                      <DeleteOutlineOutlined fontSize="medium" />
                    </IconButton>
                  </Grid>
                </Fragment>
              );
            })}
          </Grid>
        </section>

        <section>
          <Grid container>
            <Grid
              item
              xs={12}
              md={8}
              lg={8}
              className={`${classes.flexSpacebetween} ${classes.flexCenter}`}
            >
              <h3>Secrets</h3>
              <IconButton
                className={classes.padding1}
                onClick={handleAddSecrets}
              >
                <AddIcon fontSize="medium" />
              </IconButton>
            </Grid>
          </Grid>
          {secrets.map((secret: any) => {
            return (
              <Grid
                container
                spacing={4}
                key={secret.id}
                className={classes.flexNoWrap}
              >
                <Grid
                  item
                  xs="auto"
                  md={8}
                  lg={8}
                  className={classes.flexGrow1}
                >
                  <Grid container className={classes.paddingBottom2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        label="Name"
                        variant="outlined"
                        name={`name-${secret.id}`}
                        value={secret.name}
                        onChange={event => handlSecretChange(event, secret.id)}
                        fullWidth
                        {...(errors[`name-${secret.id}`] && {
                          error: true,
                          helperText: errors[`name-${secret.id}`],
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        label="Value"
                        variant="outlined"
                        name={`value-${secret.id}`}
                        value={secret.value}
                        onChange={event => handlSecretChange(event, secret.id)}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs="auto"
                  md={4}
                  lg={4}
                  className={`${classes.flexCenter}`}
                >
                  <IconButton
                    className={classes.padding1}
                    onClick={() => handleDeleteSecret(secret.id)}
                  >
                    <DeleteOutlineOutlined fontSize="medium" />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
        </section>
      </CardContent>
      <CardActions className={`${classes.padding2} ${classes.addConfigFooter}`}>
        <Button variant="outlined" onClick={() => onPrevious()}>
          Previous
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleNext()}
        >
          Next
        </Button>
      </CardActions>
    </Card>
  );
};

export default TestPlan;
