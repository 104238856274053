import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
} from '@material-ui/core';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import EditIcon from '@material-ui/icons/Edit';
import { useStarredEntity } from '@backstage/plugin-catalog-react';
import { DateTime } from 'luxon';
import BackButton from '../../utils/backButton';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import { useStyles } from '../styles';
import { useNavigate } from 'react-router-dom';
import { ITPContext, TPContext } from '../providers/TPProvider';
import { IProjectMiniCard } from '../interfaces';
import { SUSTAIN } from './cards/ProjectCard';
import UserAvatar from '../../common/UserAvatar';

const OverviewPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const navigateToEditDetailsPage = () => {
    navigate('../edit-details');
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { apmInfo }: ITPContext = useContext(TPContext);

  const { toggleStarredEntity, isStarredEntity } = useStarredEntity(
    `Project:${apmInfo?.ApmNumber}`,
  );

  const [detailsArr, setDetailsArr] = useState<IProjectMiniCard[]>([]);

  const loadDetailsArray = () => {
    setDetailsArr([
      {
        label: 'Tower',
        value: apmInfo?.Towers,
      },
      {
        label: 'Application Type',
        value: apmInfo?.AssetType,
      },
      {
        label: 'Hosted Location',
        value: apmInfo?.HostedRegion,
      },
      {
        label: 'Stakeholder',
        value: apmInfo?.TechnologyStakeholder,
      },
      {
        label: 'Primary Support Owner',
        value: apmInfo?.PrimarySupportOwner,
        email: apmInfo?.PrimarySupportOwnerEmail,
      },
      {
        label: 'Business Owner',
        value: apmInfo?.PrimaryBusinessOwner,
        email: apmInfo?.PrimaryBusinessOwnerEmail,
      },
      {
        label: 'Priority Level',
        value: apmInfo?.PriorityLevel,
      },
      {
        label: 'External Customer Facing',
        value: apmInfo?.ExternalCustomerFacing?.toString(),
      },
      {
        label: 'Recovery Tier',
        value: apmInfo?.RecoveryTier,
      },
      {
        label: 'APM Created Date',
        value: DateTime.fromJSDate(
          new Date(apmInfo?.ServiceNowCreatedDate!),
        ).toFormat('MM/dd/yyyy'),
      },
    ]);
  };

  useEffect(() => {
    if (apmInfo?.ApmNumber) {
      setIsLoading(false);
      loadDetailsArray();
    } else {
      setIsLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apmInfo]);

  const handleBookmarkClick = () => {
    toggleStarredEntity();
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <BackButton />
          <HelpComponent helpUrl={HELP_URL.PROVISIONING_CENTER_APMINFO} />
        </Grid>
      </Grid>
      <Grid container className={classes.pagePadding}>
        {isLoading ? (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper className={classes.overwiewCards}>
              <Grid className={classes.flexSpacebetween}>
                <div>
                  <Typography className={classes.heading}>
                    {apmInfo?.Name}
                  </Typography>
                  <Chip
                    size="small"
                    variant="default"
                    label={apmInfo?.Recommendation}
                    className={`${classes.scopeChip} ${
                      apmInfo?.Recommendation === SUSTAIN
                        ? classes.sustainChip
                        : classes.investChip
                    }`}
                  />
                </div>
                <IconButton onClick={handleBookmarkClick}>
                  {isStarredEntity ? (
                    <BookmarkIcon className={classes.bookmarkSvg} />
                  ) : (
                    <BookmarkBorderIcon className={classes.bookmarkSvg} />
                  )}
                </IconButton>
              </Grid>
              <Grid className={`${classes.flex} ${classes.flexStart}`}>
                <div>
                  <span className={classes.apmLabel}>APM type</span>
                  <Typography variant="body2" className={classes.apmValue}>
                    {apmInfo?.AssetType}
                  </Typography>
                </div>
                <div>
                  <span className={classes.apmLabel}>APM ID / APM number</span>
                  <Typography variant="body2" className={classes.apmValue}>
                    {apmInfo?.ApmId}/{apmInfo?.ApmNumber}
                  </Typography>
                </div>
              </Grid>
              {/* <Button
                startIcon={<EditIcon />}
                onClick={navigateToEditDetailsPage}
                variant="outlined"
                color="primary"
              >
                Edit Details
              </Button> */}
              <Typography
                variant="body2"
                className={classes.applicationDescription}
                dangerouslySetInnerHTML={{
                  __html: apmInfo?.Description,
                }}
              />
            </Paper>
            {/* <Paper className={classes.overwiewCards}>
              <Grid container spacing={2} className={classes.toolGrid}>
                {Array.from(new Array(3)).map((_, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Box>
                      <Typography className={classes.detailsBody}>
                        LINK TITLE
                      </Typography>
                      <Link to="">Confluence</Link>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Paper> */}
            <Paper className={classes.overwiewCards}>
              <Grid container spacing={2} className={classes.toolGrid}>
                {detailsArr?.map((item: IProjectMiniCard) => {
                  return (
                    <Grid item md={4} sm={4} xs={6} key={item.label}>
                      <Typography className={classes.apmLabel}>
                        {item?.label}
                      </Typography>
                      {item?.email ? (
                        <UserAvatar
                          userEmail={item?.email}
                          customStyles={{
                            width: '24px',
                            height: '24px',
                            fontSize: '14px',
                            fontWeight: 'normal',
                          }}
                          valueText={item?.value}
                        />
                      ) : (
                        <Typography
                          variant="body2"
                          className={`${classes.apmValue}`}
                        >
                          <span>{item?.value}</span>
                        </Typography>
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </Paper>
          </Grid>
        )}
      </Grid>
    </>
  );
};
export default OverviewPage;
