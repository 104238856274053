import React, { useEffect, useState } from 'react';
import { useMatch, useParams } from 'react-router-dom';
import { Content, PageWithHeader, InfoCard } from '@backstage/core-components';
import {
  makeStyles,
  createStyles,
  Grid,
  LinearProgress,
  Theme,
} from '@material-ui/core';
import noImage from '../../assest/no-image.jpg';
import BackButton from '../utils/backButton';
import { dateFormat } from './dateFormat';
import LinkIcon from './icons/LinkIcon';
import PromotionImage from './PromotionImage';
import { useApi } from '@backstage/core-plugin-api';
import { promotionApiRef } from '../../apis/promotionApi';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    promotionDetailCard: {
      maxWidth: 1200,
      minWidth: 600,
      minHeight: 400,
      ['@media (max-width:920px) and (min-width: 280px)']: {
        minWidth: 0,
      },
    },
    promotionPage: {
      overflowX: 'hidden',
      ['@media (max-width:920px) and (min-width: 280px)']: {
        marginRight: '48px',
      },
    },
    promotionContainerPosterType: {
      display: 'flex',
      flexDirection: 'column',
      '& .promotionImage': {
        maxWidth: '900px',
        width: '900px',
        height: '675px',
        ['@media (max-width:1024px)']: {
          width: '100%',
          height: 'auto',
        },
      },
      '& .imageContainer': {
        textAlign: 'center',
      },
    },
    promotionContainer: {
      display: 'flex',
      '& .promotionImage': {
        width: '200px',
        height: '150px',
        ['@media (max-width:950px)']: {
          width: '100%',
        },
      },
      '& .imageContainer': {
        margin: '1rem',
      },
      ['@media (max-width:950px) ']: {
        flexDirection: 'column',
      },
    },
    promotionTextContainer: {
      marginTop: '1rem',
      display: 'flex',
    },
    promotionTextDescription: {
      maxWidth: '900px',
      whiteSpace: 'pre-wrap',
    },
    promotionfooterLinkContainer: {
      display: 'flex',
      float: 'right',
      marginRight: '0 !important',
    },
    promotionFooterLink: {
      color: theme.palette.primary.main,
      fontSize: '14px',
      padding: '6px 16px',
      display: 'flex',
      alignItems: 'center',
      '& svg ': {
        marginRight: '5px',
      },
    },
  }),
);

export const PromotionDetail = (props: any) => {
  const classes = useStyles();
  const { title, id } = useParams();
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const promotionApi = useApi(promotionApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  useEffect(() => {
    setIsLoading(true);
    promotionApi
      .getPromotionDataByIdTitle(id, title)
      .then((res: any) => {
        setIsLoading(false);
        setData(res.data?.[0] || []);
      })
      .catch((err: any) => {
        setIsLoading(false);
        setData([]);
        notificationApi.sendNotification({
          message: `Error occurred - ${err?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  }, []);

  const TemplateChecker = (TemplateType: string) => {
    return TemplateType === 'Poster Image' ? true : false;
  };

  const matchForAdminRoute = useMatch('/admin/manage-promotions/:title/:id');
  const matchForDiscoverRoute = useMatch('/promotions/:title/:id');

  let routes: any[] = [];
  if (
    matchForAdminRoute !== undefined &&
    matchForAdminRoute?.pathname !== undefined
  ) {
    routes = [
      {
        type: 'link',
        link: '/',
        text: 'Home',
      },
      {
        type: 'link',
        link: '/admin',
        text: 'Admin',
      },
      {
        type: 'link',
        link: '/admin/manage-promotions',
        text: 'Manage Promotions',
      },
      {
        type: 'text',
        link: '',
        text: title ?? '',
      },
    ];
  } else if (
    matchForDiscoverRoute !== undefined &&
    matchForDiscoverRoute?.pathname !== undefined
  ) {
    routes = [
      {
        type: 'link',
        link: '/',
        text: 'Home',
      },
      {
        type: 'link',
        link: '/promotions',
        text: 'Promotions',
      },
      {
        type: 'text',
        link: '',
        text: title ?? '',
      },
    ];
  }

  return (
    <PageWithHeader
      title="Promotion detail"
      themeId="home"
      subtitle={<DevxBreadCrumb routes={routes} />}
    >
      <Content>
        <Grid container className={classes.promotionPage}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <BackButton></BackButton>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12}>
            <Content>
              {isLoading && <LinearProgress />}
              <InfoCard
                className={classes.promotionDetailCard}
                title={data?.title}
                subheader={
                  'published date: ' + dateFormat(data?.creationDate || '')
                }
              >
                <div
                  className={
                    TemplateChecker(data?.templateType)
                      ? classes.promotionContainerPosterType
                      : classes.promotionContainer
                  }
                >
                  {data?.assetURL && data?.assetURL !== '' ? (
                    <div className="imageContainer">
                      <PromotionImage
                        promotionId={id}
                        assetURL={data?.assetURL}
                        title={title}
                        altText=""
                        className="promotionImage"
                      />
                      {/* <img
                      src={`https://${data?.assetURL}`}
                      className="promotion-image"
                    ></img> */}
                    </div>
                  ) : (
                    <div className="imageContainer">
                      <img src={noImage} className="promotionImage"></img>
                    </div>
                  )}

                  <div className={classes.promotionTextContainer}>
                    <div className={classes.promotionTextDescription}>
                      {data?.description}
                    </div>
                  </div>
                </div>
                {data?.relatedURL && data?.relatedURL != '' && (
                  <div className={classes.promotionfooterLinkContainer}>
                    <a
                      href={
                        data?.relatedURL?.includes('https://')
                          ? `${data?.relatedURL}`
                          : `https://${data?.relatedURL}`
                      }
                      target="_blank"
                      className={classes.promotionFooterLink}
                    >
                      <LinkIcon />
                      <span>Link</span>
                    </a>
                  </div>
                )}
              </InfoCard>
            </Content>
          </Grid>
        </Grid>
      </Content>
    </PageWithHeader>
  );
};
