export const constants = {
    EDIT_SUCCESS_MESSAGE: 'Successfully update POC. It may take couple minutes for the changes to reflect.',
    errors: {
        INVALID_DEMO_URL: 'Demo URL is not valid',
        INVALID_FILE_TYPE: 'Invalid POC documents provided',
        FAILED_TO_UPLOAD: 'Failed to upload documents, Please make sure document size not exceed 10 Mb',
        ENTITY_NAME_TAKEN: 'That name is taken. Try another.'
    },
    success: {
        CREATE_POC: 'POC Created Successfully.'
    },
    POC: 'poc',
    APPROVED: 'Approved',
    GITHUB: 'github',
    MAIN: 'main',
    SCM: 'github'
};
