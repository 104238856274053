import React from 'react';
import { useStarredEntities } from '@backstage/plugin-catalog-react';
import { Box, Icon } from '@material-ui/core';
import Bookmark from '@material-ui/icons/Bookmark';
import BookmarkBorderOutlined from '@material-ui/icons/BookmarkBorderOutlined';

export interface BookmarkQuestionProps {
  id: string;
}

const BookmarkQuestion = ({ id }: BookmarkQuestionProps) => {
  const { isStarredEntity, toggleStarredEntity } = useStarredEntities();

  const onClickIcon = () => {
    toggleStarredEntity({ kind: 'question', namespace: 'default', name: id });
  };

  return (
    <Box>
      <Icon onClick={onClickIcon}>
        {isStarredEntity({
          kind: 'question',
          namespace: 'default',
          name: id,
        }) ? (
          <Bookmark />
        ) : (
          <BookmarkBorderOutlined style={{ color: 'grey' }} />
        )}
      </Icon>
    </Box>
  );
};
export default BookmarkQuestion;
