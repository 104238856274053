import React, { useState, useEffect } from 'react';
import {
  EmptyState,
  InfoCard,
  MissingAnnotationEmptyState,
  Progress,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { useAsyncEntity } from '@backstage/plugin-catalog-react';
import { snykApiRef } from '../../../../../apis/snykApi';
import { issuesCount, ProjectsData, UnifiedIssues } from './types';
import {
  SNYK_ANNOTATION_TARGETID,
  SNYK_ANNOTATION_TARGETNAME,
} from '.';
import { useAsync } from 'react-use';
import { Entity } from '@backstage/catalog-model';
import { getIssuesCount } from '../hooks/utils';
import { SnykCountComponent } from './SnykCountComponent';
import SnykDialog from './SnykDialog';

export const isSnykAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[SNYK_ANNOTATION_TARGETNAME]) ||
  Boolean(entity.metadata.annotations?.[SNYK_ANNOTATION_TARGETID]);

const SnykCard = ({
  selectedBranch,
  snykOrgID,
  isOwner
}: {
  selectedBranch: string | undefined;
  snykOrgID: string;
  isOwner: boolean;
}) => {
  const { entity } = useAsyncEntity();
  const snykApi = useApi(snykApiRef);
  const [fullProjectList, setFullProjectList] = useState<ProjectsData[]>([]);
  const [open, setOpen] = useState(false);

  const orgId = snykOrgID || 'null';

  //entity.metadata.annotations.["snyk.io/target-name"]="CBRE-DevOps/DevOpsPortal-Tests";

  const { value, loading, error } = useAsync(async () => {
    // eslint-disable-next-line new-cap

    let aggregatedIssuesCount: issuesCount = {
      critical: 0,
      high: 0,
      medium: 0,
      low: 0,
    };

    const fullProjectList = await snykApi.ProjectsList(
      orgId,
      entity?.metadata.annotations?.[SNYK_ANNOTATION_TARGETNAME] ||
      entity?.metadata.annotations?.[SNYK_ANNOTATION_TARGETID] ||
      '',
    );

    const orgData = await snykApi.GetOrgName(orgId);
    const projectList = fullProjectList as ProjectsData[];
    if (projectList !== undefined) {
      setFullProjectList(projectList);
    }

    let projectsCount = 0;

    let filterdProjectList: any[] = [];
    if (
      selectedBranch !== undefined &&
      String(selectedBranch).trim().length > 0
    ) {
      filterdProjectList = projectList.filter(
        p =>
          p.attributes.target_reference &&
          p.attributes.target_reference === selectedBranch,
      );
      if (filterdProjectList?.length === 0) {
        filterdProjectList = [];
      }
    } else {
      filterdProjectList = projectList;
    }

    const projectIds = filterdProjectList.map(project => project.id);

    for (let i = 0; i < projectIds.length; i++) {
      if (
        projectList?.some(
          selectedProject => selectedProject.id == projectIds[i],
        )
      ) {
        projectsCount++;

        const vulnsIssues: UnifiedIssues =
          await snykApi.ListAllAggregatedIssues(orgId, projectIds[i]);
        const currentProjectIssuesCount = getIssuesCount(vulnsIssues.data);
        aggregatedIssuesCount.critical += currentProjectIssuesCount.critical;
        aggregatedIssuesCount.high += currentProjectIssuesCount.high;
        aggregatedIssuesCount.medium += currentProjectIssuesCount.medium;
        aggregatedIssuesCount.low += currentProjectIssuesCount.low;
      }
    }

    return {
      projectList: filterdProjectList,
      aggregatedIssuesCount,
      projectsCount,
      orgData,
    };
  }, [selectedBranch, snykOrgID]);

  const linkInfo = {
    title: `View More Details`,
    // link: `https://${appHost}/org/${value?.orgData?.data?.attributes?.slug}/projects?groupBy=targets&searchQuery=${entity?.metadata.annotations?.[SNYK_ANNOTATION_TARGETNAME]}`,
    onClick: () => {
      setOpen(true);
    }
  };

  let issuesCount: issuesCount = value?.aggregatedIssuesCount || {
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
  };

  const snykAvailable = entity !== undefined ? isSnykAvailable(entity) : false;

  if (!loading && !snykAvailable) {
    return (
      <InfoCard
        title="Snyk Vulnerabilities"
        subheader={selectedBranch ? 'For default branch' : `For ${selectedBranch}` || 'For default branch'}
      >
        <MissingAnnotationEmptyState
          annotation={[SNYK_ANNOTATION_TARGETNAME]}
        />
      </InfoCard>
    );
  }

  if (loading) {
    return (
      <InfoCard
        title="Snyk Vulnerabilities"
        subheader={selectedBranch ? 'For default branch' : `For ${selectedBranch}` || 'For default branch'}
      >
        <Progress />
      </InfoCard>
    );
  } else if (error) {
    return (
      <InfoCard
        title="Snyk Vulnerabilities"
        subheader={selectedBranch ? 'For default branch' : `For ${selectedBranch}` || 'For default branch'}
      >
        <EmptyState
          missing="info"
          title="No information to display"
          description={`There is no information available from snyk ${selectedBranch ? `for branch ${selectedBranch}` : ''
            }.`}
        />
      </InfoCard>
    );
  }

  return (
    <InfoCard
      title="Snyk Vulnerabilities"
      subheader={selectedBranch ? 'For default branch' : `For ${selectedBranch}` || 'For default branch'}
      deepLink={linkInfo}
    >
     <SnykDialog
      open={open}
      onClose={() => { setOpen(false) }}
      branches={[...new Set(fullProjectList?.map(p => p.attributes.target_reference))]}
      snykVulnerabilities={fullProjectList || []}
      isOwner={isOwner}
      snykOrgId={orgId}
     />
      {
        !loading &&
        (!!value &&
          !!value?.projectList &&
          value?.projectList?.length !== 0) && (
          <div
            onClick={() => {
              setOpen(true);
            }}
          >
            <SnykCountComponent issuesCount={issuesCount} />
          </div>
        )
      }

      {!loading &&
        (value === undefined ||
          value?.projectList === undefined ||
          value?.projectList?.length === 0) && (
          <EmptyState
            missing="info"
            title="No information to display"
            description={`There is no information available from snyk ${selectedBranch ? `for branch ${selectedBranch}` : ''
              }.`}
          />
        )}
    </InfoCard>
  );
};
export default SnykCard;
