import { Typography } from '@material-ui/core';
import React from 'react';
import { IAvailableTool } from '../../interfaces';

interface ConfirmMsgProps {
  tool: IAvailableTool;
}

const ConfirmMsg: React.FC<ConfirmMsgProps> = ({ tool }) => {
  return (
    <Typography
      variant="body1"
      dangerouslySetInnerHTML={{
        __html: tool.metadata.moreinfo.confirmMessage,
      }}
    />
  );
};

export default ConfirmMsg;
