import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles, createStyles, Theme, Tooltip } from '@material-ui/core';
import { Button, Grid } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import ClearButton from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { AuthContext } from '../../providers/AuthProvider';
import { datadogRum } from '@datadog/browser-rum';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    createButton: {
      display: 'inline-block',
      position: 'relative',
      ['@media (max-width:920px) and (min-width: 280px)']: {
        width: '100% !important',
        marginTop: '4%',
      },
    },
    rightAlignDiv: {
      float: 'right',
      marginRight: '15px',
      ['@media (min-width: 1280px)']: {
        paddingTop: '6px',
      },
      ['@media (max-width:920px) and (min-width: 280px)']: {
        width: '100% ',
        marginRight: '0',
      },
    },
    SearchByStyle: {
      background: 'white',
      '& > div:first-of-type': {
        width: '100%',
        '& select': {
          padding: '6px 10px',
          background: 'white',
        },
        '& option': {
          padding: '4px',
        },
      },
    },
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      flex: 1,
    },
    ContentHeaderStyle: {
      display: 'flex',
      width: '100%',
      marginTop: '2px',
      '& > div:first-of-type': {
        display: 'flex',
        flex: '1 1 auto',

        '& > div:first-of-type': {
          width: '80%',
          minWidth: '350px',
          border: '1px solid #cccccc',
          ['@media (max-width:920px) and (min-width: 280px)']: {
            width: '100%',
            minWidth: '0px',
          },
        },
      },
    },
  }),
);

const MockContentHeader = ({
  setFilterText,
}: {
  onFilterKeyChange?: any;
  setFilterText: any;
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const classes = useStyles();
  const navigate = useNavigate();

  const { profEmail, profileName } = useContext(AuthContext);

  const navigateToRegisterPage = () => {
    datadogRum.addAction('ClickCreateMock', {
      id: profEmail,
      name: profileName,
      email: profEmail,
    });
    navigate('/api-mock/register');
  };

  return (
    <Grid container className={classes.ContentHeaderStyle}>
      <div>
        <div>
          <label htmlFor="search-input" hidden>
            Search Here
          </label>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper component="form" className={classes.root}>
              <Tooltip title="Search">
                <IconButton disabled type="submit" aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Tooltip>
              <InputBase
                className={classes.input}
                placeholder="Search Mocks"
                data-testid="search-mocks"
                value={searchTerm}
                onChange={event => {
                  setSearchTerm(event?.target?.value);
                  setFilterText(event?.target?.value);
                  return;
                }}
                inputProps={{ 'aria-label': 'search feedback' }}
              />
              <Tooltip title="Clear search">
                <IconButton
                  aria-label="search"
                  onClick={() => {
                    setSearchTerm('');
                    setFilterText('');
                    return;
                  }}
                >
                  <ClearButton />
                </IconButton>
              </Tooltip>
            </Paper>
          </Grid>
        </div>
      </div>
      <div className={classes.rightAlignDiv}>
        <Button
          onClick={navigateToRegisterPage}
          variant="contained"
          color="primary"
          className={classes.createButton}
        >
          Create Mock
        </Button>
      </div>
    </Grid>
  );
};
export default MockContentHeader;
