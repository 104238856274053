import React from 'react';

import { Grid, Typography, Button } from '@material-ui/core';

export const NoPocFound = () => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      style={{ padding: '0px 64px' }}
    >
      <Grid item>
        <Typography gutterBottom variant="h3">
          You have no POC
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Want to add an POC? Follow instructions
        </Typography>
        <Button
          style={{ textTransform: 'capitalize' }}
          variant="contained"
          color="primary"
          href="/help/assetpublish/"
        >
          Add POC Guide
        </Button>
      </Grid>
      <Grid item>
        <img
          style={{ maxWidth: '100%' }}
          alt="no asset published"
          src="/images/empty-asset-card.png"
        />
      </Grid>
    </Grid>
  );
};
