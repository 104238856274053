import { Theme } from '@material-ui/core';

export const performanceTestStyles = (theme: Theme) => {
  return {
    addConfigContainer: {
      height: '68vh',
    },

    addConfigHeader: {
      padding: `0px ${theme.spacing(3)}px`,
    },

    addConfigFormContainer: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },

    addConfigFooter: {
      border: `1px solid ${theme.palette.border}`,
    },

    /* Performace Test - Service - Ends here*/

    /* Styles for Manage Config Page */
    configDropdown: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'end',
    },
    emptyPaper: {
      height: `${theme.spacing(65)}px`,
      backgroundColor: theme.palette.background.secondary,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    emptyPageText: {
      color: theme.palette.text.tertiary,
      fontSize: `${theme.spacing(2.5)}px`,
    },
    emptyPageAction: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: `${theme.spacing(2)}px`,
    },
    manageConfigHeaderButtons: {
      display: 'flex',
      justifyContent: 'end',
      gap: `${theme.spacing(1.2)}px`,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    refreshButton: {
      background: theme.palette.chat.primary.button,
      boxShadow: 'none',
      color: theme.palette.primary.main,
      '&:hover': {
        background: theme.palette.chat.primary.button,
        boxShadow: 'none',
        color: theme.palette.primary.main,
      },
    },
    manageConfigHeaderActionButton: {
      fontSize: `${theme.spacing(2)}px`,
      textTransform: 'none',
    },
    updatedTime: {
      color: theme.palette.text.tertiary,
      margin: `${theme.spacing(1)}px`,
    },
    actionButtonInTableCell: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      gap: `${theme.spacing(1)}px`,
    },
    actionButtonRun: {
      padding: '0px',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    configTableRow: {
      backgroundColor: theme.palette.background.paper + ' !important',
      border: `1px solid ${theme.palette.border}`,
    },
    stopIcon: {
      color: theme.palette.primary.main,
    },
    iconInMoreMenu: {
      marginRight: `${theme.spacing(1)}px`,
    },
    errorDotForConfig: {
      fontSize: `${theme.spacing(1)}px`,
      color: theme.palette.errorText,
      position: 'absolute',
      top: '0px',
      right: '1px',
    },
    errorDotForViewConfigMenuOption: {
      fontSize: `${theme.spacing(1)}px`,
      color: theme.palette.errorText,
      position: 'absolute',
      top: `${theme.spacing(0.875)}px`,
      right: `${theme.spacing(5)}px`,
    },
    table: {
      minWidth: 650,
    },
    tableHead: {
      backgroundColor: theme.palette.table.headerBackground + ' !important',
    },
    tableHeadCell: {
      color: theme.palette.text.secondary,
      fontWeight: 600,
    },
    tableBodyCell: {
      color: theme.palette.text.secondary,
    },
    colorRunexecuting: {
      color: theme.palette.warning.main,
    },
    colorRunended: {
      color: theme.palette.success.main,
    },
    colorRunfailed: {
      color: theme.palette.error.main,
    },
    colorRunqueued: {
      color: theme.palette.warning.main,
    },
    colorRunprovisioning: {
      color: theme.palette.warning.main,
    },
    colorRunstarted: {
      color: theme.palette.colors.sage,
    },
    configStatus: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
    },
    emptyBoxContainer: {
      padding: '0px 24px',
    },

    clickableTableCell: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      lineHeight: '1.5',
    },

    /* Runs page styling */

    runsPageContainer: {
      padding: `0px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
      alignItems: 'end',
    },
    dateTimeTableCell: {
      display: 'flex',
      gap: `${theme.spacing(1.2)}px`,
    },
    runsPageActionButtons: {
      display: 'flex',
      gap: `${theme.spacing(1.2)}px`,
      justifyContent: 'right',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    runsPageButtonGroup: {
      display: 'flex',
      gap: `${theme.spacing(1.2)}px`,
    },
    searchInRunsPage: {
      minWidth: `${theme.spacing(35)}px`,
      '& div': {
        background: theme.palette.background.paper,
      },
      '& input': {
        padding: `${theme.spacing(1.3125)}px ${theme.spacing(1.75)}px`,
      },
    },
    tablePagination: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: `${theme.spacing(2)}px`,
      },
    },

    tablePaginationRoot: {
      overflow: 'hidden',
    },
    tablePaginationActions: {
      '& button': {
        ['@media (max-width:400px) and (min-width: 280px)']: {
          padding: '0',
        },
      },
    },
    tablePaginationSelect: {
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0px',
        marginRight: `${theme.spacing(0.525)}px`,
      },
    },
    tablePaginationBar: {
      [theme.breakpoints.down('sm')]: {
        flex: 'none',
      },
    },

    /* Report Page styling */
    reportPageIconButton: {
      backgroundColor: theme.palette.colors.green,
      borderRadius: `${theme.spacing(1)}px`,
      padding: '5px',
      '& span': {
        fontSize: '16px',
      },
      '& span > svg:nth-of-type(1)': {
        marginRight: '5px',
      },
    },
    reportPageButton: {
      marginRight: `${theme.spacing(1.2)}px`,
    },
    reportPageIconButtonOutlined: {
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '4px',
      padding: '5px',
      '& span': {
        fontSize: '16px',
        color: theme.palette.primary.main,
      },
      '& span > svg:nth-of-type(1)': {
        marginRight: '5px',
      },
    },
    reportPaper: {
      marginTop: `${theme.spacing(2)}px`,
    },
    reportBox: {
      padding: `${theme.spacing(2)}px`,
      border: `1px solid #959595`,
      borderRadius: '4px',
      flex: '1 0 21%',
      '& p:nth-of-type(1)': {
        color: `${theme.palette.text.secondary} 65%`,
        fontSize: '14px',
      },
      '& p:nth-of-type(2)': {
        color: theme.palette.text.secondary,
        fontSize: '14px',
      },
    },
    reportBoxContainer: {
      gap: `${theme.spacing(1.2)}px`,
    },
    dashboardIframe: {
      width: '100%',
      minHeight: '500px',
    },
  } as const;
};
