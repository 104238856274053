/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { swTemplateCustomApiRef } from '../../../apis/swTemplateCustomApi';
import { useEffect, useState } from 'react';

export const useGetApms = () => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const swTemplateCustomApi = useApi(swTemplateCustomApiRef);

  const [allApms, setAllApms] = useState<any>([]);

  const fetchMyAPI = async () => {
    if (swTemplateCustomApi) {
      try {
        const response = await swTemplateCustomApi.getApms();
        if (response?.status === 'SUCCESS') setAllApms(response?.data);
      } catch (error) {
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };

  useEffect(() => {
    fetchMyAPI();
  }, []);

  return [allApms];
};
