import React, { createContext, useState } from 'react';
import {
  EditAnswerRequest,
  IEQuestionItem,
  QuestionDetailsResponse,
} from '../../../apis/informationExchange';

const initialState = {
  editData: null,
  showAiAnswer: false,
  cachedQuestion: null,
  setCachedQuestion: () => {
    return null;
  },
};

export const IEContext = createContext<any>(initialState);

export const IEProvider = (props: any) => {
  const [questionData, setQuestionData] = useState<QuestionDetailsResponse>();
  const [acceptedAnswer, setAcceptedAnswer] = useState<Object | null>(null);
  const [oldEditData, setOldEditData] = useState<EditAnswerRequest | null>(
    null,
  );
  const [answerRef, setAnswerRef] = useState<any>(null);
  const [showAiAnswer, setShowAiAnswer] = useState(false);
  const [answeredByAI, setAnsweredByAI] = useState(false);

  const [cachedQuestion, setCachedQuestion] = useState<IEQuestionItem | null>(
    null,
  );

  return (
    <IEContext.Provider
      value={{
        acceptedAnswer,
        setAcceptedAnswer,
        oldEditData,
        setOldEditData,
        answerRef,
        setAnswerRef,
        showAiAnswer,
        setShowAiAnswer,
        answeredByAI,
        setAnsweredByAI,
        cachedQuestion,
        setCachedQuestion,
      }}
    >
      {props.children}
    </IEContext.Provider>
  );
};
