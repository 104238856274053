export const constants = {
    roles: {
        USER: 'user',
        GENERATED: 'generated',
        TOOL: 'tool',
        ASSISTANT: 'assistant'
    },
    colors: {
        devx: '#D2785A',
        translate: '#A388BF',
        summarize: '#DBD99A',
        grammar: '#1F3765',
        data: '#885073',
        code: '#D2785A',
        explain: '#D2785A',
        webpage: '#DBD99A',
        sql: '#885073'
    },
    roleinformation: {
        devx: "You are an AI assistant that helps people find information.",
        translate: "You will be provided with a sentence in {{from}} language, and your task is to translate it into {{to}}.",
        summarize: 'Summarize content you are provided with for a college student',
        grammar: 'You will be provided with statements, and your task is to convert them to standard English.',
        data: 'You will be provided with unstructured data, and your task is to parse it into CSV format.',
        code: 'You will be provided with a piece of code, and your task is to provide ideas for efficiency improvements.',
        explain: 'You will be provided with a piece of code, and your task is to explain it in a concise way.',
        webpage: 'You will be provided with information to create a single page website',
        sql: 'Given the following SQL tables, your job is to write queries given a user’s request.'
    },
    description: {
        devx: 'DevX related chat',
        translate: 'Text translation',
        summarize: 'Content summarization',
        grammar: 'Grammar correction',
        data: 'Parse unstructured data',
        code: 'Improve code efficiency',
        explain: 'Explain code',
        webpage: 'Website creator',
        sql: 'Natural language to SQL'
    },
    LANGUAGE_UNKNOWN: "Unknown",
    language: [
        "Arabic",
        "Armenian",
        "Basque",
        "Bulgarian",
        "Catalan",
        "Chinese Simplified",
        "Chinese Traditional",
        "Czech",
        "Danish",
        "Dutch",
        "English",
        "Finnish",
        "French",
        "Galician",
        "German",
        "Greek",
        "Hindi",
        "Hungarian",
        "Indonesian (Bahasa)",
        "Irish",
        "Italian",
        "Japanese",
        "Korean",
        "Latvian",
        "Norwegian",
        "Persian",
        "Polish",
        "Portuguese (Brazil)",
        "Portuguese (Portugal)",
        "Romanian",
        "Russian",
        "Spanish",
        "Swedish",
        "Thai",
        "Turkish"
    ],
    aiResponse: {
        devx: 'What do you need help with today',
        translate: 'Please provide the sentence',
        summarize: 'Please provide the content',
        grammar: 'Please provide the content',
        data: 'Please provide the content',
        code: 'Please provide the code',
        explain: 'Please provide the code',
        webpage: 'Please explain in detail. e.g. Make a single page website that shows off different neat javascript features for drop-downs and things to display information. The website should be an HTML file with embedded javascript and CSS.',
        sql: 'Please provide the content'
    },
    TRANSLATE: 'translate'
}