/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { useState } from 'react';
import { promptApiRef } from '../../../apis/promptManagementApi';
import { IUsageObject } from '../interfaces';

export const usePromptUsage = (domainId: string) => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const promptManagementApi = useApi(promptApiRef);

  const [usageCountObject, setUsageCountObject] = useState<IUsageObject>({});

  const getUsage = async () => {
    if (promptManagementApi) {
      try {
        const response = await promptManagementApi.getPromptsUsageByDomain(
          domainId,
        );
        setUsageCountObject(response);
      } catch (error) {
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };

  const updateUsage = async (promptId: string) => {
    if (promptManagementApi) {
      try {
        const response = await promptManagementApi.updatePromptUsage(
          domainId,
          promptId,
        );
        if (response?.message)
          setUsageCountObject((prevState: IUsageObject) => {
            return {
              ...prevState,
              [promptId]: promptId in prevState ? prevState[promptId] + 1 : 1,
            };
          });
      } catch (error) {
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };

  return [getUsage, updateUsage, usageCountObject] as const;
};
