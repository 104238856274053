import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /* Common Styles Starts */

    visualCodePage: {
      [theme.breakpoints.down('sm')]: {
        padding: '0px',
      },
    },
    result: {
      alignItems: 'center',
      marginTop: '10px',
    },
    resultHeading: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '24px',
      display: 'flex',
      alignItems: 'center',
      color: '#000000',
    },
    visualBtns: {
      display: 'flex',
      gap: '10px',
      justifyContent: 'end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        display: 'inline-grid',
      },
    },
    btnTabs: {
      borderRadius: '4px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '10px',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    btnDefault: {
      backgroundColor: 'transparent',
      color: '#00000061',
      textTransform: 'none',
      padding: '6px 10px',
    },
    btnSelected: {
      padding: '6px 10px',
      backgroundColor: '#CFCFCF',
      color: '#00000094',
      textTransform: 'none',
    },
    headingText: {
      fontSize: '24px',
    },
    product: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.secondary,
    },
    productInfo: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.tertiary,
      marginBottom: '8px',
    },

    /* Common Styles Ends */

    /* Visual Regression Page Styles Starts */

    urlFields: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '10px',
      [theme.breakpoints.down('sm')]: {
        display: 'inline-grid',
      },
    },
    fieldError: {
      color: '#AD2A2A',
    },
    errortext: {
      color: '#FF543E',
      padding: '7px',
      fontSize: '14px',
    },
    testBtn: {
      padding: '20px 13px',
      textTransform: 'capitalize',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      color: '#FFFFFF',
      width: '100%',
      maxWidth: '100%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      [theme.breakpoints.down('md')]: {
        padding: '20px 5px',
      },
    },
    downloadBtn: {
      padding: '7px 9px',
      textTransform: 'capitalize',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: ' 16px',
      display: 'flex',
      color: '#003F2D',
    },
    visualResult: {
      marginTop: '10px',
    },
    styledRadio: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.secondary,
      display: 'flex',
      flexDirection: 'column',
      padding: '8px 14px',
      margin: '16px',
      border: `1px solid ${theme.palette.border}`,
      borderRadius: '6px',
      '& img': {
        maxWidth: '100%',
      },
    },
    styledRadioAndLabel: {
      display: 'flex',
      flexDirection: 'row',
    },
    styledRadioAndLabelPad: {
      padding: '9px 0px',
    },
    radioGroup: {
      flexDirection: 'row',
    },
    products: {
      background: theme.palette.background.paper,
      borderRadius: '6px',
      marginBottom: '50px',
      padding: '30px 20px 20px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '20px',
      },
    },
    pages: {
      background: theme.palette.background.tertiary,
      padding: '15px',
      marginTop: '30px',
    },

    inputLabelBG: {
      background: theme.palette.background.tertiary,
    },
    inputLabelBG2: {
      background: theme.palette.background.paper,
    },
    referencePage: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '10px',
      marginTop: '15px',
    },
    btnGroupMain: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    btnGroup: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginTop: '25px',
      display: 'flex',
      '& Button': {
        textTransform: 'capitalize',
        fontStyle: 'normal',
        fontWeight: 500,
        padding: '12px 16px',
        fontSize: '16px',
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '4px',
        lineHeight: ' 16px',
      },
    },
    formContent: {
      display: 'flex',
      alignItems: 'center',
    },
    contentIcon: {
      border: '1px solid #003F2D',
      borderRadius: '4px',
      marginLeft: '18px',
    },
    radioGrp: {
      marginLeft: '20px',
    },
    loginText: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '21px',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.secondary,
    },
    testButton: {
      marginLeft: '12px',
    },
    testHistoryButton: {
      marginTop: '25px',
      textTransform: 'capitalize',
    },
    headingBar: {
      justifyContent: 'space-between',
      display: 'flex',
      padding: '0px 38px !important',
      justifyItems: 'center',
      alignItems: 'center',
      marginBottom: '16px',
    },
    alert: {
      '& :nth-of-type(1)': {
        alignItems: 'center',
      },
      [theme.breakpoints.up('md')]: {
        padding: `24px 36px !important`,
      },
    },
    alertBox: {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.infoBackground,
      border: `1px solid ${theme.palette.info.main}`,
      borderLeft: `3px solid ${theme.palette.info.main}`,
    },
    bold: {
      fontWeight: 'bold',
    },

    /* Visual Regression Page Styles Ends */

    /* Details Page Styles Starts */

    viewHeading: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: ' 24px',
      display: 'flex',
      color: theme.palette.text.secondary,
      margin: '40px 0px 10px 0px',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
    },
    renderImg: {
      width: '100%',
      marginTop: '10px',
    },
    renderImgArea: {
      background: '#FBFCFC',
      border: '1px solid #DFE6E5',
      boxShadow: ' 0px 3px 8px rgba(0, 0, 0, 0.2)',
      minHeight: '500px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    startText: {
      fontStyle: 'normal',
      fontSize: '24px',
      lineHeight: '32px',
      color: 'rgba(26, 26, 26, 0.46)',
    },
    loadingText: {
      fontStyle: 'normal',
      fontSize: '24px',
      lineHeight: '32px',
      color: 'rgba(26, 26, 26, 0.46)',
    },
    loadingBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
    },
    timeOuttext: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      color: ' rgba(26, 26, 26, 0.6)',
    },
    timeOutHeading: {
      color: ' #FF9742',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '32px',
    },
    anotherBtn: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end !important',
    },
    historyCard: {
      display: 'flex',
      flexDirection: 'row',
      background: theme.palette.background.paper,
      borderBottom: '1px solid rgba(0, 63, 45, 0.15)',
      padding: '15px',
      marginTop: '15px',
    },
    time: {
      display: 'flex',
    },
    date: {
      marginLeft: '18px',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '21px',
      color: 'rgba(26, 26, 26, 0.6)',
    },
    text: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '21px',
      color: '#8585E0',
    },
    downloadBtns: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '16px',
      color: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'stretch',
      justifyContent: 'flex-end',
      textTransform: 'capitalize',
      //border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '4px',
      padding: '12px 16px',
    },
    buttons: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '10px',
    },
    renderImgAreaDiv: {
      width: '100%',
      justifyContent: 'flex-end',
      display: 'flex',
      marginBottom: '16px',
    },
    active: {
      backgroundColor: '#CFCFCF',
      color: '#00000094',
      //textTransform: 'none',
    },
    imgloader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '110px',
      height: '150px',
      margin: '0 auto',
    },

    /* Details page styles Ends*/

    /* Test History styles Starts*/

    testHistory: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: ' 10px',
      gap: '8px',
      background: '#FFFFFF',
      boxShadow: 'inset 0px -1px 0px rgba(0, 63, 45, 0.15)',
    },
    historyHeading: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: ' 16px',
      display: 'flex',
      color: theme.palette.text.secondary,
      margin: '20px 0px 10px 0px',
    },
    testCard: {
      display: 'flex',
      gap: '10px',
    },
    success: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      alignSelf: 'stretch',
    },
    testURL: {
      display: 'flex',
      gap: '43px',
    },
    urlLink: {
      wordBreak: 'break-word',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: ' 16px',
      display: 'flex',
      color: 'rgba(26, 26, 26, 0.6)',
    },
    urlHeading: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: ' 16px',
      display: 'flex',
      color: 'rgba(26, 26, 26, 0.6)',
    },
    successHeading: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: ' 21px',
      display: 'flex',
      color: '#50AF78',
    },
    successHeadingFail: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: ' 21px',
      display: 'flex',
      color: '#AD2A2A',
    },
    timeStamp: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: ' 21px',
      display: 'flex',
      color: 'rgba(26, 26, 26, 0.6)',
    },
    historySecton: {
      maxHeight: '500px',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
    refreshBtn: {
      textTransform: 'capitalize',
    },
    // anotherBtn: {
    //   textTransform: 'capitalize',
    //   color: theme.palette.text.primary,
    //   background: 'none',
    //   [theme.breakpoints.down('sm')]: {
    //     display: 'block',
    //     width: '100%',
    //     margin: '12px 0px 0px 0px',
    //   },
    // },
    testHistoryCard: {
      display: 'flex',
      flexDirection: 'row',
      background: theme.palette.background.paper,
      borderBottom: '1px solid rgba(0, 63, 45, 0.15)',
      padding: '15px',
      marginTop: '15px',
      position: 'relative',
    },
    historyTime: {
      display: 'flex',
      padding: '8px',
      justifyContent: 'space-between',
    },
    historyDate: {
      marginLeft: '18px',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '21px',
      color: theme.palette.text.tertiary,
      position: 'absolute',
      bottom: '23px',
      right: '23px',
    },
    reportBtn: {
      textTransform: 'capitalize',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '16px',
      color: theme.palette.primary.main,
    },
    fullReportBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    historyText: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '21px',
    },
    Msg: {
      display: 'flex',
      width: '100%',
    },
    successClr: {
      background: theme.palette.successBackground,
      border: '1px solid #50AF78',
      borderRadius: '4px',
      borderLeft: '4px solid #50AF78',
      width: '100%',
      justifyContent: 'space-between',
    },
    successMsg: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0px 16px 0px 0px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    tickIcon: {
      color: ' #50AF78',
    },
    successBox: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        padding: '15px 0px',
      },
    },
    historyHeadingBar: {
      justifyContent: 'space-between',
      display: 'flex',
      justifyItems: 'center',
      alignItems: 'center',
      marginBottom: '16px',
    },
    hiddenDiv: {
      visibility: 'hidden',
    },
    pagePaginationContainer: {
      width: '100%',
      marginTop: '20px',
      marginBottom: '110px',
    },
    tablePaginationRoot: {
      overflow: 'hidden',
    },
    tablePaginationActions: {
      '& button': {
        [theme.breakpoints.down('sm')]: {
          padding: '0',
        },
      },
    },

    /* Test History styles Ends*/
  }),
);
