import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { useStyles } from './addReviewerStyle';
import TextField from '@material-ui/core/TextField';

export const FilterByReviewer = (props: any) => {
  const classes = useStyles();

  const uniqueEmails = new Set();
  const filteredArray =
    props?.reviewerList &&
    props?.reviewerList?.filter((obj: any) => {
      if (!uniqueEmails.has(obj.user)) {
        uniqueEmails.add(obj.user);
        return true;
      }
      return false;
    });

  const onChangeReviwerList = (event: any, value: any) => {
    event?.preventDefault();
    props?.setSelectedReviwer(value?.user || 'All');
  };

  return (
    <div className={classes.reviewerMainContainer}>
      <Autocomplete
        freeSolo
        className={classes.reviewerContainerFilter}
        options={filteredArray || []}
        value={{ user: props?.selectedReviwer } || 'All'}
        onChange={(event: any, value) => onChangeReviwerList(event, value)}
        getOptionLabel={item => item.user}
        getOptionDisabled={option => option?.user === props?.selectedReviwer}
        renderInput={params => (
          <TextField
            {...params}
            label="Reviewer"
            fullWidth
            variant="outlined"
            style={{
              fontSize: '14px',
            }}
          />
        )}
      />
    </div>
  );
};
