import React, { ComponentType } from 'react';
import { Routes, Route, useOutlet } from 'react-router-dom';
import { Entity } from '@backstage/catalog-model';
import { TemplateEntityV1beta3 } from '@backstage/plugin-scaffolder-common';
import { TaskPage } from './TaskPage';
import { SecretsContextProvider } from './SecretsContext';

import {
  customTypes,
  FIELD_EXTENSION_WRAPPER_KEY,
  FIELD_EXTENSION_KEY,
  DEFAULT_SCAFFOLDER_FIELD_EXTENSIONS,
} from './extensions';
import {
  useElementFilter,
  createRouteRef,
  createSubRouteRef,
} from '@backstage/core-plugin-api';
import { ScaffolderPage } from './ScaffolderPage/ScaffolderPage';
import { TemplatePage } from './TemplatePage/TemplatePage';

/**
 * The props for the entrypoint `ScaffolderPage` component the plugin.
 * @public
 */
export type RouterProps = {
  components?: {
    TemplateCardComponent?:
      | ComponentType<{ template: TemplateEntityV1beta3 }>
      | undefined;
    TaskPageComponent?: ComponentType<{}>;
  };
  groups?: Array<{
    title?: React.ReactNode;
    filter: (entity: Entity) => boolean;
  }>;
  defaultPreviewTemplate?: string;
};

export const rootRouteRef = createRouteRef({
  id: 'cbre.scaffolder',
});

export const selectedTemplateRouteRef = createSubRouteRef({
  id: 'cbre.scaffolder/selected-template',
  parent: rootRouteRef,
  path: '/templates/:templateName',
});

export const scaffolderTaskRouteRef = createSubRouteRef({
  id: 'cbre.scaffolder/task',
  parent: rootRouteRef,
  path: '/tasks/:taskId',
});

export const actionsRouteRef = createSubRouteRef({
  id: 'cbre.scaffolder/actions',
  parent: rootRouteRef,
  path: '/actions',
});

/**
 * The main entrypoint `Router` for the `ScaffolderPlugin`.
 *
 * @public
 */
export const ScaffolderRouter = (props: RouterProps) => {
  const { components = {} } = props;

  const { TaskPageComponent } = components;

  const outlet = useOutlet();
  const TaskPageElement = TaskPageComponent ?? TaskPage;

  const customFieldExtensions = useElementFilter(outlet, elements =>
    elements
      .selectByComponentData({
        key: FIELD_EXTENSION_WRAPPER_KEY,
      })
      .findComponentData<customTypes.FieldExtensionOptions>({
        key: FIELD_EXTENSION_KEY,
      }),
  );

  const fieldExtensions = [
    ...customFieldExtensions,
    ...DEFAULT_SCAFFOLDER_FIELD_EXTENSIONS.filter(
      ({ name }) =>
        !customFieldExtensions.some(
          customFieldExtension => customFieldExtension.name === name,
        ),
    ),
  ];

  return (
    <Routes>
      <Route path="/" element={<ScaffolderPage />}></Route>
      <Route
        path="/templates/:templateName"
        element={
          <SecretsContextProvider>
            <TemplatePage customFieldExtensions={fieldExtensions} />
          </SecretsContextProvider>
        }
      />
      <Route path="/tasks/:taskId" element={<TaskPageElement />} />
      <Route path="/actions" element={<div />} />
      <Route
        path="/preview"
        element={
          <SecretsContextProvider>
            <div></div>
          </SecretsContextProvider>
        }
      />
    </Routes>
  );
};
