import React, { useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Content, Header, Page } from '@backstage/core-components';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';
import BackButton from '../utils/backButton';
import { HelpComponent } from '../utils/helpComponent';
import HELP_URL from '../utils/helpLinkConstant';
import { UtilityToolGroup } from './toolgroup';
import {
  ALL_UTILITY_TOOLS,
  CONVERT_TYPE_OF_TOOLS,
  ENCODE_DECODE_TYPE_OF_TOOLS,
  FORMAT_TYPE_OF_TOOLS,
  GENERATE_TYPE_OF_TOOLS,
  MISCELLANEOUS_TYPE_OF_TOOLS,
  REGULAR_EXPRESSIONS_TYPE_OF_TOOLS,
} from './constants';
import { useStarredEntities } from '@backstage/plugin-catalog-react';
import UtilityToolsSearchFilter, {
  UTILITY_TOOLS_SEARCH_STATUS,
} from './filter';
import { IUtilityTool } from './types';

const UtilityToolsPage = () => {
  const { starredEntities } = useStarredEntities();
  const starredMap: any = {};
  starredEntities.forEach((value: string) => {
    starredMap[`${value}`] = true;
  });

  const filteredTools = ALL_UTILITY_TOOLS?.filter(r => starredMap[r?.id]) || [];
  const [searchResults, setSearchResults] = useState<IUtilityTool[]>([]);
  const [searchStatus, setSearchStatus] = useState<string>('');

  const updateFilteredTools = (_tools: IUtilityTool[], status: string) => {
    setSearchResults(_tools);
    setSearchStatus(status);
  };

  return (
    <Page themeId="home">
      <Header
        title="Utility Tools"
        subtitle={
          <DevxBreadCrumb
            routes={[
              {
                type: 'link',
                text: 'Home',
                link: '/',
              },
              {
                type: 'text',
                text: 'Utility Tools',
                link: '/utility-tools',
              },
            ]}
          />
        }
      ></Header>
      <Content>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <BackButton />
            <HelpComponent helpUrl={HELP_URL.UTILITY_TOOLS_HOMEPAGE} />
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <Typography style={{ paddingLeft: 2 }} variant="h6">
                    {ALL_UTILITY_TOOLS?.length} Tools
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <UtilityToolsSearchFilter
                    updateFilteredTools={updateFilteredTools}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12}>
            {searchStatus === UTILITY_TOOLS_SEARCH_STATUS.NOT_FOUND && (
              <Box sx={{ mb: 2 }}>
                <Typography>
                  No tool matched for this criteria. Please refine the criteria
                  and try again
                </Typography>
              </Box>
            )}
            {searchResults !== undefined && searchResults?.length > 0 && (
              <UtilityToolGroup title="Search Results" tools={searchResults} />
            )}
            {filteredTools !== undefined && filteredTools?.length > 0 && (
              <UtilityToolGroup title="Favorite" tools={filteredTools} />
            )}
            <UtilityToolGroup title="Format" tools={FORMAT_TYPE_OF_TOOLS} />
            <UtilityToolGroup title="Generate" tools={GENERATE_TYPE_OF_TOOLS} />
            <UtilityToolGroup
              title="Encode/Decode"
              tools={ENCODE_DECODE_TYPE_OF_TOOLS}
            />
            <UtilityToolGroup title="Convert" tools={CONVERT_TYPE_OF_TOOLS} />
            <UtilityToolGroup
              title="Regular Expressions"
              tools={REGULAR_EXPRESSIONS_TYPE_OF_TOOLS}
            />
            <UtilityToolGroup
              title="Miscellaneous"
              tools={MISCELLANEOUS_TYPE_OF_TOOLS}
            />
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
export default UtilityToolsPage;
