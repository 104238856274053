import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useCustomStyles = makeStyles((theme: Theme) =>
  createStyles({
    CardContainer: {
      width: '200px',
      height: '174px',
      padding: '8px 18px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
      marginBottom: '16px',
      marginRight: '20px',
      ['@media (max-width:920px) and (min-width: 280px)']: {
        width: '100%',
        marginRight: '0',
        marginBottom: '2px',
      },
    },
    CurrentLeaderBoardCardContainer: { marginTop: '45px' },

    CardContainerFlex: {
      width: '200px',
      height: '174px',
      padding: '30px 20px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
      marginBottom: '16px',
      marginRight: '20px',
      display: 'flex',
      flexDirection: 'column',
    },
    Container: {
      margin: '0px',
    },
    CardHeader: {
      fontWeight: '600',
      fontSize: '15px',
      lineHeight: '16px',
      color: theme.palette.text.secondary,
    },
    CardText: {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '14px',
      color: theme.palette.text.muted,
    },
    CardEntityContainer: {
      padding: '6px 14px',
      minWidth: '375px',
      width: '100%',
      minHeight: '70px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
      marginBottom: '2px',
      ['@media (max-width:1024px) and (min-width: 280px)']: {
        minWidth: '0',
      },
      ['@media (max-width:320px)']: {
        padding: '12px 0px',
      },
    },
    CardTypeContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    CardTypeFont: {
      fontWeight: 600,
      fontSize: '10px',
      lineHeight: '16px',
      margin: 'auto',
      display: 'block',
      textTransform: 'capitalize',
    },
    CardTypeImage: {
      margin: 'auto',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    },
    CardTitleText: {
      fontWeight: 600,
      fontSize: '15px',
      paddingLeft: '8px',
      lineHeight: '16px',
      color: theme.palette.text.secondary,
      ['@media (max-width:1024px) and (min-width: 280px)']: {
        wordBreak: 'break-word',
      },
    },
    CardContentText: {
      color: theme.palette.text.muted,
      fontWeight: 400,
      paddingLeft: '8px',
      fontSize: '12px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      lineHeight: '16px',
      marginTop: '4px',
    },
    CardContentOwnerText: {
      color: theme.palette.text.primary,
      fontWeight: 400,
      paddingLeft: '8px',
      fontSize: '12px',
      lineHeight: '16px',
      marginTop: '4px',
    },
    CardContentContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    CardContentRatings: {
      ['@media (max-width:320px) ']: {
        position: 'relative',
        right: '10%',
      },
    },
    CardContentTextTimestamp: {
      color: theme.palette.text.muted,
      fontWeight: 600,
      fontSize: '10px',
      lineHeight: '16px',
      marginRight: '0',
    },
    CardActionButton: {
      color: theme.palette.primary.main,
      background: 'transparent',
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 400,
      float: 'right',
      border: 'none',
      cursor: 'pointer',
    },
    OverflowContent: {
      display: 'flex',
      flexWrap: 'wrap',
      // justifyContent: 'space-between'
    },
    DocumentCardText: {
      color: theme.palette.text.grey,
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '16px',
      display: 'inline',
    },
    DocumentHeader: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    DocumentFold: {
      width: '0',
      height: '0',
      margin: '-8px -18px 0px 0px',
      borderRadius: '0px 4px 0px 4px',
      border: '10.5px solid #80BBAD',
      borderTopColor: theme.palette.customCard.foldBg,
      borderRightColor: theme.palette.customCard.foldBg,
      boxShadow:
        '0px 3px 8px rgba(0, 0, 0, 0.2), 0px -5px ' +
        theme.palette.customCard.foldBg +
        ', 5px 0px ' +
        theme.palette.customCard.foldBg,
    },
    CardTitle: {
      marginTop: '17px',
      overflow: 'hidden',
      wordBreak: 'break-word',
    },
    CardDesc: {
      marginTop: '7px',
      overflow: 'hidden',
      wordBreak: 'break-word',
    },
    seeMorePublishedButton: {
      marginRight: 0,
      float: 'right',
      color: theme.palette.primary.main,
      opacity: 0.9,
      '&:hover': {
        color: theme.palette.primary.main,
        opacity: 0.9,
      },
      '&.Mui-selected, &.Mui-selected:hover': {
        color: `${theme.palette.primary.main} !important`,
        opacity: 0.9,
        backgroundColor: 'transparent !important',
        boxShadow: 'none !important',
      },
    },
  }),
);
