import React, { useEffect, useState } from 'react';
import axios from 'axios';
import noImage from '../../assest/no-image.jpg';
import { useApi } from '@backstage/core-plugin-api';
import { promotionApiRef } from '../../apis/promotionApi';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';

const PromotionImage = ({
  promotionId,
  assetURL,
  title,
  altText,
  className,
  style,
  callback = null,
  index = null,
}: any) => {
  const [imageData, setImageData] = useState<any>('');

  const promotionApi = useApi(promotionApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  useEffect(() => {
    const source = axios.CancelToken.source();

    promotionApi
      .getPromotionImage(promotionId, title)
      .then((res: any) => {
        setImageData(res);
        if (callback && index) {
          callback(res.data, index);
        }
      })
      .catch((err: any) => {
        setImageData(undefined);
        notificationApi.sendNotification({
          message: `Error occurred - ${err?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });

    return () => {
      source.cancel('PromotionImage Component got unmounted');
    };
  }, [promotionId, title]);

  return (
    <>
      {assetURL ? (
        <img
          src={`https://${assetURL}`}
          // src={`data:${imageData.headers['content-type']};base64,${imageData.data}`}
          alt={altText}
          className={className}
          style={style}
        />
      ) : (
        <img src={noImage} alt={altText} className={className} style={style} />
      )}
    </>
  );
};
export default PromotionImage;
