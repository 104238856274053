import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import Form from '@rjsf/material-ui';
import FormField from './FormField';
import UserSuggest from '../Suggestions/UserSuggest';
import EntitySuggest from '../Suggestions/EntitySuggest';
import AssetSuggest from '../Suggestions/AssetSuggest';
const useStyles = makeStyles({
  formFields: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: 'calc(100vh - 250px)',
  },
  formButtons: {
    marginTop: '10px',
    ['@media (max-width:920px) and (min-width: 280px)']: {
      width: '100%',
      marginTop: '15px',
    },
  },
  saveFormBtn: {
    marginRight: '10px',
    ['@media (max-width:920px) and (min-width: 280px)']: {
      width: '100%',
    },
  },
  cancelBtn: {
    ['@media (max-width:920px) and (min-width: 280px)']: {
      width: '100%',
      marginTop: '10px',
    },
  },
  previewForm: {
    background: 'white',
    padding: '10px',
    overflowY: 'scroll',
    height: 'calc(100vh - 250px)',
    ['@media (max-width:920px) and (min-width: 280px)']: {
      marginTop: '15px',
    },
  },
});
export interface FormBuilderProps {
  formSchema: any;
  formUiSchema: any;
  onClickSaveForm: (schema: any, uiSchema: any) => void;
  onClickCancel: () => void;
}

const customWidgets: any = {
  userSuggest: UserSuggest,
  entitySuggest: EntitySuggest,
  assetPicker: AssetSuggest,
};

export interface FormFieldDataProps {
  field: string;
  title: string;
  type: string;
  required: boolean;
  canRemove: boolean;
  fixed: boolean;
}

const CUSTOM_FIELDS = [
  'entitySuggest',
  'userSuggest',
  'textarea',
  'assetPicker',
];

const FormBuilder = ({
  formSchema,
  formUiSchema,
  onClickSaveForm,
  onClickCancel,
}: FormBuilderProps) => {
  const [schema, setSchema] = useState(formSchema);
  const [uiSchema, setUiSchema] = useState(formUiSchema);
  const classes = useStyles();
  const [formBuilderFields, setFormBuilderFields] = useState<any[]>([]);

  useEffect(() => {
    if (formBuilderFields && formBuilderFields?.length > 0) {
      let schema = {};
      let uiSchema = {};
      let props = {};
      let required: string[] = [];
      formBuilderFields?.map((item: FormFieldDataProps) => {
        // @ts-ignore
        props[item?.field] = {
          title: item?.title,
          type: item?.type,
          canRemove: item?.canRemove,
          fixed: item?.fixed || false,
        };
        if (CUSTOM_FIELDS.includes(item?.type)) {
          uiSchema[item?.field] = {
            'ui:widget': item?.type,
          };
          props[item?.field].type = 'string';
        }
        if (item?.required) {
          required.push(`${item?.field}`);
        }
        if (item?.field === 'links') {
          props[item?.field].format = 'uri';
        }
      });
      schema.properties = props;
      schema.type = 'object';
      schema.required = required;
      setSchema(schema);
      setUiSchema(uiSchema);
    }
  }, [formBuilderFields]);

  useEffect(() => {
    if (formSchema && formUiSchema) {
      let props: any = formSchema?.properties;
      let requiredProps: any[] = formSchema?.required;

      let objData: any[] = [];
      if (props && Object.keys(props)?.length > 0) {
        Object.keys(props).forEach((propKey: any) => {
          let obj = {
            field: propKey,
            title: props[propKey]?.title,
            type:
              (Object.keys(formUiSchema)?.includes(propKey)
                ? formUiSchema[propKey]['ui:widget']
                : props[propKey]?.type) || 'string',
            required: requiredProps?.includes(propKey),
            canRemove: props[propKey]?.canRemove,
            fixed: props[propKey]?.fixed || false,
          };
          if (propKey === 'links') {
            obj.format = 'uri';
          }
          objData.push(obj);
        });
      }
      setFormBuilderFields(objData);
      setUiSchema(formUiSchema);
    }
  }, [formSchema, formUiSchema]);

  const onFieldDataChange = (newData: FormFieldDataProps, index: number) => {
    let newArr = [...formBuilderFields];
    newArr[index] = newData;
    setFormBuilderFields(newArr);
  };

  const onClickAddBelow = (index: number) => {
    const newIndex = index + 1;
    let newArr = [...formBuilderFields];
    const newItem: FormFieldDataProps = {
      canRemove: true,
      field: `field-${newArr?.length + 1}`,
      title: `Title-${newArr?.length + 1}`,
      required: false,
      type: 'string',
      fixed: false,
    };

    if (newIndex >= newArr.length) {
      newArr?.push(newItem);
    } else {
      newArr.splice(newIndex, 0, newItem);
    }
    setFormBuilderFields(newArr);
  };

  const onClickDeleteItem = (index: number) => {
    let newArr = [...formBuilderFields];
    newArr?.splice(index, 1);
    setFormBuilderFields(newArr);
  };

  const formValid = () => {
    let error = false;
    for (let i = 0; i < formBuilderFields?.length; i += 1) {
      if (formBuilderFields[i]?.error) {
        error = true;
        break;
      }
    }
    return !error;
  };

  return (
    <Grid container direction="row">
      <Grid item xs={12} sm={12} lg={6}>
        <div className={classes.formFields}>
          {formBuilderFields &&
            formBuilderFields?.length > 0 &&
            formBuilderFields?.map((f: any, index: number) => (
              <FormField
                data={f}
                formBuilderFields={formBuilderFields || []}
                index={index}
                key={index}
                onDataChange={onFieldDataChange}
                onClickAddBelow={onClickAddBelow}
                onClickDelete={onClickDeleteItem}
              />
            ))}
        </div>
        <div className={classes.formButtons}>
          <Button
            variant="contained"
            color="primary"
            className={classes.saveFormBtn}
            onClick={() => {
              if (onClickSaveForm && formValid()) {
                onClickSaveForm(schema, uiSchema);
              }
            }}
          >
            Save Form
          </Button>
          <Button
            variant="contained"
            color="default"
            className={classes.cancelBtn}
            onClick={onClickCancel}
          >
            Cancel
          </Button>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} lg={6} className={classes.previewForm}>
        <Typography>Preview</Typography>
        <Form schema={schema} widgets={customWidgets} uiSchema={uiSchema}>
          <></>
        </Form>
      </Grid>
    </Grid>
  );
};
export default FormBuilder;
