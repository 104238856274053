import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Theme,
  makeStyles,
  createStyles,
  TextField,
} from '@material-ui/core';
import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    DialogBox: {
      minWidth: 300,
    },
    margin: {
      marginTop: '20px',
    },
    btnsMargin: {
      margin: '20px 0px 15px 0px',
      textTransform: 'lowercase',
    },
    actionBtn: {
      textTransform: 'initial',
    },
    description: {
      marginTop: '10px',
    },
  }),
);

const AddEditCategory = (props: any) => {
  const {
    open,
    closeIconClicked,
    isEditClick,
    onSubmit,
    onClose,
    values: initialValues,
  } = props;
  const [values, setValues] = useState<any>({});
  const [_titleValidationErr, setTitleValidationError] = useState('');
  const [_descriptionValidationErr, setDescriptionValidationError] =
    useState('');

  const classes = useStyles();

  useEffect(() => {
    if (!open) {
      setTitleValidationError('');
      setDescriptionValidationError('');
    }
  }, [open]);

  useEffect(() => {
    if (initialValues) {
      setValues({
        description: initialValues.description,
        name: initialValues.name,
      });
    } else {
      setValues({
        description: '',
        name: '',
      });
    }
  }, [initialValues]);


  const validateInputs = async () => {
    const categoryName = yup
      .string()
      .required('Category name is required')
      .min(3, 'At least 3 characters required')
      .max(100, 'Maximum 100 characters')
      .validate(values.name)
      .then(() => {
        setTitleValidationError('');
        return true;
      })
      .catch(err => {
        setTitleValidationError(err.message);
        return false;
      });

    const categoryDescription = yup
      .string()
      .required('Category description is required')
      .min(3, 'At least 3 characters required')
      .max(500, 'Maximum 500 characters')
      .validate(values.description)
      .then(() => {
        setDescriptionValidationError('');
        return true;
      })
      .catch(err => {
        setDescriptionValidationError(err.message);
        return false;
      });
    const allPromises = await Promise.all([categoryName, categoryDescription]);
    return allPromises.reduce((prev, current) => {
      return prev && current;
    });
  };

  const onSubmitRegister = async () => {
    if (initialValues && initialValues.id) {
      if (await validateInputs()) {
        onSubmit({
          ...values,
          id: initialValues.id,
        });
      }
    } else {
      if (await validateInputs()) {
        onSubmit(values);
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={closeIconClicked}
      className={classes.DialogBox}
      fullWidth
    >
      <DialogTitle id="dialog-title">
        <div>{isEditClick ? 'Update Category' : 'Add Category'}</div>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => props.onClose()}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {values && values.name !== undefined && (
        <DialogContent>
          <div>Description Text</div>
          <div>
            <TextField
              className={classes.margin}
              label="Category Name"
              required
              {...(_titleValidationErr && {
                error: true,
                helperText: _titleValidationErr,
              })}
              fullWidth
              multiline
              variant="outlined"
              value={values.name}
              onChange={event => {
                setValues({
                  ...values,
                  name: event.target.value,
                });
              }}
            />
          </div>
          <div>
            <TextField
              className={classes.margin}
              label="Description"
              fullWidth
              multiline
              required
              {...(_descriptionValidationErr && {
                error: true,
                helperText: _descriptionValidationErr,
              })}
              minRows={3}
              variant="outlined"
              value={values.description}
            onChange={(event) => {
                setValues({
                  ...values,
                description: event.target.value
              })
              }}
            />
          </div>
        </DialogContent>
        )
      }

      <DialogActions className={classes.btnsMargin}>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={onSubmitRegister}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditCategory;
