import React, { useEffect, useState, useContext } from 'react';
import { Header, Page, Progress } from '@backstage/core-components';
import {
  Grid,
  makeStyles,
  createStyles,
  Box,
  Button,
  Drawer,
  Paper,
  IconButton,
  InputBase,
  ListItem,
  Typography,
  useMediaQuery,
  Theme,
  useTheme,
  TablePagination,
  Tooltip
} from '@material-ui/core';
import ClearButton from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import LinkIcon from '@material-ui/icons/Link';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';
import { HelpComponent } from '../../../utils/helpComponent';
import HELP_URL from '../../../utils/helpLinkConstant';

import BackButton from '../../../utils/backButton';
import BreadcrumbsNav from '../../../common/BreadcrumbsNav/BreadcrumbsNav';
import { ManageItemCard } from './ManageItemCard';
import {
  AdminApi,
  adminApiRef,
  ManageDataCategory,
  ManageDataInsightItem,
} from '../../../../apis/adminApi';
import { DataPopup } from './ManageDataItemPopup';
import DeleteCategoryConfirm from '../manageCategories/deleteCategoryConfirm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    manageCategoriesContainer: {
      padding: '24px 24px 20px 24px',
      gridArea: 'pageContent',
    },
    addItemContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '24px 24px 20px 24px',
    },
    input: {
      flex: '10',
    },
    PagePaginationContainer: {
      width: '100%',
      marginTop: '20px',
      marginBottom: '110px',
    },
    tablePaginationRoot: {
      overflow: 'hidden',
    },
    tablePaginationActions: {
      '& button': {
        [theme.breakpoints.down('sm')]: {
          padding: '0',
        },
      },
    },
    ContentHeaderStyle: {
      width: '100%',
      padding: '0px',
      marginBottom: '10px',
      '& > form:first-of-type': {
        display: 'flex',
        '& > button': {
          flex: '0.2',
        },
        '& > div': {
          flex: '10',
        },
      },
    },
    filters: {
      backgroundColor: theme.palette.background.paper,
      borderLeftColor: theme.palette.primary.main,
      '& li': {
        borderLeftColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.border}`,
        '&:hover button': {
          color: theme.palette.primary,
          backgroundColor: `${theme.palette.background.tertiary}`,
        },
        '&.active button': {
          borderLeft: `2px solid ${theme.palette.primary.main}`,
          backgroundColor: `${theme.palette.background.tertiary}`,
        },
      },
    },
    filterButton: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
  }),
);

const ManageDataInsightsItems = () => {
  const classes = useStyles();
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const adminApi: AdminApi = useApi(adminApiRef);
  const [category, setCategory] = useState<ManageDataCategory[]>([]);
  const [items, setItems] = useState<ManageDataInsightItem[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [contentPerPage, setContentPerPage] = useState(20);
  const [showedContent, setShowedContent] = useState<ManageDataInsightItem[]>(
    [],
  );
  const [totalCount, setTotalCount] = useState(0);
  const [filteredItems, setFilteredItems] = useState<ManageDataInsightItem[]>(
    [],
  );
  const [image, setImage] = useState<any>([]);
  const [loading, setLoading] = useState<any>(true);
  const [search, setSearch] = useState<any>({
    term: '',
    category: '',
  });
  const [popup, setPopup] = useState<any>({
    open: false,
    popupProps: {},
    values: {
      title: '',
      description: '',
      dashboardUrl: '',
      category: '',
      image: '',
    },
  });

  useEffect(() => {
    setCurrentIndex(0);
  }, [filteredItems]);

  useEffect(() => {
    const begin = currentIndex * contentPerPage;
    const end = begin + contentPerPage;
    setShowedContent([...filteredItems.slice(begin, end)]);
    setTotalCount(filteredItems.length);
  }, [currentIndex, contentPerPage, filteredItems]);

  useEffect(() => {
    if (!search.term && !search.category) {
      setFilteredItems(items);
    } else {
      let filterResult;
      filterResult = items.filter((item: ManageDataInsightItem) => {
        const name = item.title?.toLowerCase() || '';
        const desc = item.description?.toLowerCase() || '';
        const category = item.category_name?.toLowerCase() || '';

        return (
          (search.category.toLowerCase() === '' ||
            category === search.category.toLowerCase()) &&
          (search.term.toLowerCase() === '' ||
            name.includes(search.term.toLowerCase()) ||
            desc.includes(search.term.toLowerCase()) ||
            category.includes(search.term.toLowerCase()))
        );
      });

      setFilteredItems(filterResult);
    }
  }, [search, items]);

  const onPageChange = (
    event: React.SyntheticEvent | null,
    pageNumber: number,
  ): void => {
    if (event) {
      event.preventDefault();
    }
    setCurrentIndex(pageNumber);
  };

  const onClickAddCategoryButton = () => {
    const addEditPopupProps = {
      title: 'Add Item',
      description: 'Add item for existing categories',
      fields: [
        {
          title: 'Title',
          value: '',
          fieldName: 'title',
          type: 'text',
          required: true,
          helperText: 'Title is required',
        },
        {
          title: 'Description',
          value: '',
          fieldName: 'description',
          type: 'text',
          required: true,
          helperText: 'Item description is required',
        },
        {
          title: 'Dashboard URL',
          value: '',
          fieldName: 'url',
          type: 'text',
          required: true,
          icon: LinkIcon,
          helperText: 'URL is required',
          validationText: 'URL is not valid',
        },
        {
          title: 'Category',
          value: '',
          fieldName: 'category_name',
          type: 'select',
          required: true,
          options: category,
          helperText: 'Category is required',
        },
        {
          title: 'Image',
          value: '',
          fieldName: 'imageFileName',
          type: 'file',
          required: true,
          helperText: 'Image is required',
        },
      ],
      onClose: onClosePopup,
      onSubmit: onSubmit,
    };

    setPopup({
      open: true,
      popupProps: {
        ...addEditPopupProps,
      },
    });
  };
  const onClosePopup = () => {
    const emptyPopupState = {
      open: false,
      popupProps: {},
      values: {
        title: '',
        description: '',
        dashboardUrl: '',
        category: '',
        image: '',
      },
    };
    setPopup(emptyPopupState);
  };

  const fetchData = async () => {
    let responseItems;
    let responseCategory;
    try {
      responseItems = await adminApi.getItems();
      responseCategory = await adminApi.getCategories();
      if (responseCategory) {
        setCategory(responseCategory);
      }
      if (responseItems) {
        setLoading(false);
        setItems(responseItems);
        setFilteredItems(responseItems);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = async (data: any) => {
    try {
      const payload = {
        category_id: category.find(c => c.name === data.category_name)?.id,
        ...data,
      };
      const response = await adminApi.addItem(JSON.stringify(payload));
      onClosePopup();
      setLoading(true);
      if (response) {
        notificationApi.sendNotification({
          message: 'Successfully added item',
          disapperAfterMs: 2500,
          severity: 'success',
          callback: () => {
            setItems([...items, payload]);
            fetchData();
          },
        });
      }
    } catch {
      notificationApi.sendNotification({
        message: 'Failed to add item',
        disapperAfterMs: 2500,
        severity: 'error',
      });
    }
  };

  const findAndReplace = (newData: any) => {
    const p = items.findIndex((item: any) => item.id === newData.id);
    const a = items;
    a[p] = newData;
    setItems(a);
  };

  const onEdit = async (data: any) => {
    try {
      const payload = {
        category_id: category.find(c => c.name === data.category_name)?.id,
        ...data,
      };
      const response = await adminApi.updateItem(JSON.stringify(payload));
      onClosePopup();
      setLoading(true);
      if (response) {
        notificationApi.sendNotification({
          message: 'Successfully edited item',
          disapperAfterMs: 2500,
          severity: 'success',
          callback: () => {
            // findAndReplace(data);
            fetchData();
          },
        });
      }
    } catch {
      notificationApi.sendNotification({
        message: 'Failed to edit item',
        disapperAfterMs: 2500,
        severity: 'error',
      });
    }
  };

  const onDeleteClick = (item: ManageDataInsightItem) => {
    setPopup({
      open: true,
      popupProps: {
        id: item.id,
        fields: [],
      },
      onSubmit: () => {
        onDelete(item.id || '');
      },
      onClose: onClosePopup,
    });
  };

  const onDelete = async (id: string) => {
    try {
      const response = await adminApi.deleteItem(id);
      setLoading(true);
      if (response) {
        notificationApi.sendNotification({
          message: 'Successfully deleted item',
          disapperAfterMs: 2500,
          severity: 'success',
          // callback: () => {
          //   const a = items;
          //   a.filter(i => i.id !== id);
          //   setItems(a);
          //   onClosePopup();
          // },
          callback: () => fetchData(),
        });
      }
    } catch {
      notificationApi.sendNotification({
        message: 'Failed to delete item',
        disapperAfterMs: 2500,
        severity: 'error',
      });
    }
  };

  const Filters = (props: { children: React.ReactNode }) => {
    const isScreenSmallerThanBreakpoint = useMediaQuery<Theme>(theme =>
      theme.breakpoints.down('md'),
    );
    const theme = useTheme<Theme>();
    const [filterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(false);

    return isScreenSmallerThanBreakpoint ? (
      <>
        <Button
          className={classes.filterButton}
          onClick={() => setFilterDrawerOpen(true)}
          startIcon={<FilterListIcon />}
        >
          Filters
        </Button>
        <Drawer
          open={filterDrawerOpen}
          onClose={() => setFilterDrawerOpen(false)}
          anchor={props.options?.drawerAnchor ?? 'left'}
          disableAutoFocus
          keepMounted
          variant="temporary"
        >
          <Box m={2}>
            <Typography
              variant="h6"
              component="h2"
              style={{ marginBottom: theme.spacing(1) }}
            >
              Filters
            </Typography>
            {props.children}
          </Box>
        </Drawer>
      </>
    ) : (
      <Grid item lg={2}>
        <Typography component="span">Categories</Typography>
        {props.children}
      </Grid>
    );
  };

  return (
    <>
      {popup.open && popup?.popupProps?.fields?.length > 0 && (
        <DataPopup open={popup.open} {...popup.popupProps} />
      )}
      {popup.open && popup?.popupProps?.fields?.length === 0 && (
        <DeleteCategoryConfirm
          isOpen={popup.open}
          onConfirm={popup.onSubmit}
          onClose={popup.onClose}
        />
      )}
      <div className={classes.manageCategoriesContainer}>
        <Grid container>
          <Grid item md={12} xs={12}>
            <BackButton />
            <HelpComponent helpUrl={HELP_URL.ADMIN_DATAINSIGHT_MANAGE_ITEMS} />

            <Grid item md={12} xs={12} className={classes.addItemContainer}>
              <Typography component="h2" variant="h6">
                Manage Items
              </Typography>
              <Button
                onClick={onClickAddCategoryButton}
                color="primary"
                variant="contained"
              >
                Add Item
              </Button>
            </Grid>

            <Grid container>
              <Filters>
                <div className={`customFilters ${classes.filters}`}>
                  <ListItem className={search.category === '' ? 'active' : ''}>
                    <Button
                      variant="outlined"
                      name="All"
                      onClick={() => {
                        setSearch({
                          ...search,
                          category: '',
                        });
                      }}
                    >
                      All
                    </Button>
                  </ListItem>
                  {category.length > 0 &&
                    category
                      .filter((cat: ManageDataCategory) => cat.name !== '')
                      .map((c: any) => (
                        <ListItem
                          className={
                            search.category.toLowerCase() ===
                              c.name.toLowerCase()
                              ? 'active'
                              : ''
                          }
                        >
                          <Button
                            variant="outlined"
                            name={c.name}
                            onClick={() => {
                              setSearch({
                                ...search,
                                category: c.name,
                              });
                            }}
                          >
                            {c.name}
                          </Button>
                        </ListItem>
                      ))}
                </div>
              </Filters>
              <Grid item xs={12} lg={10}>
                <div className={classes.ContentHeaderStyle}>
                  <label htmlFor="search-input" hidden>
                    Search Here
                  </label>
                  <Paper component="form">
                    <Tooltip title="Search">
                      <IconButton disabled type="submit" aria-label="search">
                        <SearchIcon />
                      </IconButton>
                    </Tooltip>
                    <InputBase
                      className={classes.input}
                      placeholder="Search"
                      value={search.term}
                      onChange={event => {
                        setSearch({
                          ...search,
                          term: event?.target?.value,
                        });
                        return;
                      }}
                      inputProps={{ 'aria-label': 'search' }}
                    />
                    <Tooltip title="Clear">
                      <IconButton
                        aria-label="Clear"
                        onClick={() => {
                          // setSearchTerm('');
                          return;
                        }}
                      >
                        <ClearButton />
                      </IconButton>
                    </Tooltip>
                  </Paper>
                </div>
                {loading && <Progress />}
                {filteredItems &&
                  showedContent &&
                  filteredItems.map(item => {
                    return (
                      <ManageItemCard
                        onClickDeleteButton={() => {
                          setPopup({
                            open: true,
                            fields: [],
                            onSubmit: onDelete(item.id || ''),
                            onClose: onClosePopup,
                          });
                        }}
                        onClickEditButton={() => {
                          const editPopupProps = {
                            title: 'Edit Item',
                            description: 'Edit item for existing categories',
                            id: item.id,
                            fields: [
                              {
                                title: 'Title',
                                value: item.title,
                                fieldName: 'title',
                                type: 'text',
                                required: true,
                              },
                              {
                                title: 'Description',
                                value: item.description,
                                fieldName: 'description',
                                type: 'text',
                                required: true,
                              },
                              {
                                title: 'Dashboard URL',
                                value: item.url,
                                fieldName: 'url',
                                type: 'text',
                                required: true,
                                icon: LinkIcon,
                              },
                              {
                                title: 'Category',
                                value: item.category_name,
                                fieldName: 'category_name',
                                type: 'select',
                                required: true,
                                options: category,
                              },
                              {
                                title: 'Image',
                                value: item.imageurl,
                                fieldName: 'imageFileName',
                                type: 'file',
                                required: true,
                              },
                            ],
                            onClose: onClosePopup,
                            onSubmit: onEdit,
                          };
                          setPopup({
                            open: true,
                            popupProps: {
                              ...editPopupProps,
                            },
                          });
                        }}
                        {...item}
                      />
                    );
                  })}
                {filteredItems && filteredItems.length !== 0 && (
                  <div className={classes.PagePaginationContainer}>
                    <Paper>
                      <TablePagination
                        classes={{
                          root: classes.tablePaginationRoot,
                          actions: classes.tablePaginationActions,
                        }}
                        SelectProps={{
                          title: 'perPageSelect',
                          native: true,
                        }}
                        component="div"
                        page={currentIndex}
                        rowsPerPage={contentPerPage}
                        count={totalCount}
                        onPageChange={onPageChange}
                        rowsPerPageOptions={[]}
                      />
                    </Paper>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ManageDataInsightsItems;
