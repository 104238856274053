import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { Grid, Typography } from '@material-ui/core';
import Constants from './Constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    colorPanel: {
      backgroundColor: (props: any) => props.color,
      width: '12px',
      height: '10px',
      marginRight: '3px',
    },
    feedbackTypesContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ['@media (max-width:920px) and (min-width: 280px)']: {
        marginTop: '0',
        flexBasis: '0%',
      },
    },
  }),
);

const MockType = (props: any) => {
  const type = props.type?.toLocaleLowerCase() || 'other';
  const classes = useStyles({
    color: Constants.requestTypes?.[type]?.color || '#fff',
  });

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.feedbackTypesContainer}
      >
        <div className={classes.colorPanel}></div>
        <Typography>
          {!props.type && type}
          {props.type &&
            props.type?.charAt(0)?.toUpperCase() + props.type?.slice(1)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MockType;
