import { Grid, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import REGISTER_API_CONSTANTS from '../Constants';

const RequestInfo = (props: any) => {
  const { onChange } = props;
  const [formValues, setFormValues] = useState({
    subPath: '',
    operationId: '',
    description: '',
  });

  /* Change Handler for all form inputs */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    onChange(formValues);
  }, [formValues]);

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          label={REGISTER_API_CONSTANTS.swaggerApiForm.subPath.label}
          placeholder={
            REGISTER_API_CONSTANTS.swaggerApiForm.subPath.placeholder
          }
          value={formValues.subPath}
          name="subPath"
          onChange={handleChange}
          fullWidth
          variant="outlined"
          multiline
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          label={REGISTER_API_CONSTANTS.swaggerApiForm.operationId.label}
          placeholder={
            REGISTER_API_CONSTANTS.swaggerApiForm.operationId.placeholder
          }
          value={formValues.operationId}
          name="operationId"
          onChange={handleChange}
          fullWidth
          variant="outlined"
          multiline
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          InputProps={{
            minRows: 4,
            maxRows: 4,
          }}
          label={REGISTER_API_CONSTANTS.swaggerApiForm.description.label}
          placeholder={
            REGISTER_API_CONSTANTS.swaggerApiForm.description.placeholder
          }
          value={formValues.description}
          name="description"
          onChange={handleChange}
          multiline
          fullWidth
          variant="outlined"
        />
      </Grid>
    </>
  );
};

export default RequestInfo;
