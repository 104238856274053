/* eslint-disable react-hooks/exhaustive-deps */
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useStyles } from '../styles';

const SortBy = ({
  inputAction,
  onChange,
  variant = 'standard',
  disabled = false,
  required = false,
  isTransParent = true,
}: {
  inputAction: string;
  onChange: Function;
  variant?: 'filled' | 'outlined' | 'standard' | undefined;
  disabled?: boolean;
  required?: boolean;
  isTransParent?: boolean;
}) => {
  const classes = useStyles();

  useEffect(() => {}, []);

  const handleSortByChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value);
  };

  const SortByArray = [{ id: 'lastModified', name: 'Last Modified' }];

  return (
    <div className={`${classes.flexSpacebetween} ${classes.scopesGap}`}>
      <FormControl
        className={classes.scopesControl}
        disabled={disabled}
        required={required}
      >
        <InputLabel id="sortBy">Sort by</InputLabel>
        <Select
          className={
            isTransParent
              ? classes.selectEmpty
              : `${classes.selectEmpty} ${classes.backGroundColor}`
          }
          disableUnderline
          variant={variant}
          labelId="sortBy"
          id="sortBy"
          value={inputAction}
          onChange={handleSortByChange}
        >
          {SortByArray.map((sortBy: any) => (
            <MenuItem value={sortBy.id} key={sortBy.id}>
              {sortBy.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SortBy;
