import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import { useCustomStyles } from './CardStyles';
import BadgeDisplayImage from '../badgeTypePicker';
import UserAvatar from '../../common/UserAvatar';
import { useApi } from '@backstage/core-plugin-api';
import { userProfileApiRef } from '../../../apis/userProfileApi';

export interface PlayersCardProps {
  data: {
    displayname: string;
    levelname: string;
    email: string;
  };
}

const PlayersCard = (props: PlayersCardProps) => {
  const { displayname, levelname, email } = props.data;
  const name =
    (displayname && displayname.split('@')[0]) ||
    (email && email.split('@')[0]);

  const classes = useCustomStyles();
  const userProfileApi = useApi(userProfileApiRef);
  const usernameRef = useRef(null);

  const onMouseEnter = () => {
    userProfileApi.setUserProfileState({
      email,
      anchorRef: usernameRef?.current,
    });
  };

  return (
    <div className={classes.CardContainerFlex} style={{ overflow: 'hidden' }}>
      <UserAvatar
        userEmail={email}
        customStyles={{
          width: '48px',
          height: '48px',
          fontSize: '24px',
          fontWeight: 'normal',
          lineHeight: '32px',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginBottom: '10px',
        }}
      />

      <Box component="div" onMouseOver={onMouseEnter}>
        <Typography className={`${classes.CardTitleText} fontSemibold`}>
          <Link
            to={`/catalog/leader-board/${email?.split('@')[0]}`}
            state={{ email: email }}
            ref={usernameRef}
          >
            <span title={name}>{name}</span>
          </Link>
        </Typography>
      </Box>

      <div className={classes.IconWithTextPlayers}>
        <BadgeDisplayImage type={levelname} />
        <Typography>{levelname}</Typography>
      </div>
    </div>
  );
};

export default PlayersCard;
