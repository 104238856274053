import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AIAssistantCard from './AiAssistantCard';
import { Box, IconButton, Typography } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import EmblaCarousel from '../../home/Carousal/EmblaCarousel';
import { useUtils } from '../hooks/useUtils';
import { IAIAssistantCard } from '../types';

const useStyles = makeStyles((theme: Theme) => ({
  assistantContainer: {
    alignItems: 'center',
    gap: 16,
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    overflowX: 'auto',
    width: '100%',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    marginBottom: -theme.spacing(1.5),
  },
  cardList: {
    alignItems: 'center',
    overflow: 'hidden',
    justifyContent: 'start',
    scrollbarWidth: 'none',

    '& .embla__container': {
      minHeight: 112,
      marginTop: 5,
      display: 'flex',
      gap: 16,
      paddingBottom: 2,
      paddingTop: theme.spacing(1.5),
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        backgroundColor: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'transparent',
      },
    },
  },

  hedingIcon: {
    padding: theme.spacing(1),
    fontSize: '1em',
    fontFamily: 'Calibre-R-Medium',
  },
}));

const AiAssistantList = ({
  aiServices,
  isMobile,
  link,
}: {
  aiServices: IAIAssistantCard[];
  isMobile: boolean;
  link: string;
}) => {
  const classes = useStyles();

  const [emblaRef, emblaApi] = useEmblaCarousel({
    containScroll: 'trimSnaps',
    slidesToScroll: 1,
    dragFree: true,
    align: 'start',
    loop: false,
    draggable: false,
  });

  const heading = 'AI Services';
  const { navigateTo } = useUtils();

  return (
    <section className={classes.assistantContainer}>
      <Box component="section" className={classes.heading}>
        <Typography variant="subtitle1">{heading}</Typography>
        <IconButton
          onClick={() => navigateTo(link)}
          classes={{ root: classes.hedingIcon }}
        >
          <LaunchIcon fontSize="small" />
        </IconButton>
      </Box>

      <div className={classes.cardList}>
        <EmblaCarousel
          ref={emblaRef}
          slides={aiServices?.map((assistant: any, index: number) => (
            <AIAssistantCard
              {...assistant}
              key={assistant.uniqueId}
              descriptionLines={isMobile ? 3 : 4}
            />
          ))}
        ></EmblaCarousel>
      </div>
    </section>
  );
};

export default AiAssistantList;
