import { useEffect } from 'react';
import { EntityFilter, useEntityList } from '@backstage/plugin-catalog-react';

class CapabilityFilter implements EntityFilter {
  getCatalogFilters(): Record<string, string | symbol | (string | symbol)[]> {
    return {
      kind: 'Group',
      'spec.type': 'capability',
    };
  }
}

const CapabilityPicker = () => {
  const { error, updateFilters } = useEntityList<any>();

  if (error) {
    console.error(error);
  }

  useEffect(() => {
    updateFilters({
      kind: new CapabilityFilter(),
    });
  }, [updateFilters]);

  return null;
};
export default CapabilityPicker;
