import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useCustomStyles = makeStyles((theme: Theme) =>
  createStyles({
    RegisterTitleStyle: {
      height: '63px',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '24px',
      background: theme.palette.common.white,

      '& span': {
        fontFamily: 'Calibre-R-Regular',
        fontSize: '16px',
        lineHeight: '20px',
        textAlign: 'left',
        color: '#2E5881',
      },
    },
    disabled: {},
    AllAssetTimestampText: {
      fontSize: '12px',
      fontfamily: 'Calibre-R-Regular',
      lineHeight: '20px',
      letterSpacing: '0px',
      whiteSpace: 'nowrap',
    },
    AllAssetTitleText: {
      fontSize: '14px',
      fontfamily: 'Calibre-R-Regular',
      lineHeight: '20px',
      letterSpacing: '0px',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    AllAssetHeaderContainer: {
      padding: '20px',
      height: '80px',
      margin: '0px 0px 10px 0px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    AllAssetAvatar: {
      width: '42px',
      height: '42px',
      backgroundColor: '#fff',
      border: '1px solid #435254',
      '& svg:first-of-type': {
        fill: '#435254',
      },
    },
    CardTitleText: {
      width: '170px',
    },
    SvgColorFill: {
      fill: theme.palette.primary.main,
    },
    SvgColorFillDisabled: {
      fill: '#CAD1D3',
    },
    RegisterExtContentStyle: {
      padding: '10px 20px',
      marginTop: '10px',

      '& div:first-of-type a': {
        fontFamily: 'Calibre-R-Regular',
        fontSize: '15px',
        lineHeight: '20px',
        textAlign: 'left',
        color: '#2E5881',
      },
    },
    RegisterExtGridStyle: {
      display: 'flex',
      marginTop: '10px',

      '& section': {
        background: '#ffffff',
        padding: '20px',

        '& > div:first-of-type': {
          fontFamily: 'Calibre-R-Regular',
          fontSize: '12px',
          lineHeight: '16px',
          color: '#172B4D',
        },
      },
      '& > section:nth-of-type(1)': {
        '& div': {
          width: '475px',
          marginTop: '10px',
        },
      },
      '& > section:nth-of-type(2)': {
        flex: '1',
        marginLeft: '20px',

        '& .register-info-title': {
          height: '40px',
          boxShadow: '0px 0px 0px black',
          fontFamily: 'Calibre-R-Regular',
          fontSize: '16px',
          lineHeight: '20px',
          textAlign: 'left',
          color: '#2E5881',
        },
        '& .reg-spacer': {
          height: '1px',
          width: '100%',
          background: '#cccccc',
        },
        '& .register-info': {
          marginLeft: '-20px',
          '& section': {
            fontFamily: 'Calibre-R-Regular',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#172B4D',
          },

          '& section:nth-child(2)': {
            marginTop: '-20px',
          },
          '& section:nth-child(3)': {
            marginTop: '-35px',
          },
          '& section:nth-child(4)': {
            marginTop: '-20px',
          },
        },
      },
    },
    AllAssetCardTags: {
      height: '24px',
      marginTop: '12px',
    },
    CardTitleStyle: {
      height: '80px',
      position: 'relative',

      '& div:nth-of-type(1)': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        background: '#3C73A9',
        fontfamily: 'Calibre-R-Regular',
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '-0.015em',
        textAlign: 'left',
      },
      '& .card-icon-button': {
        position: 'absolute',
        top: '0px',
        right: '0px',
        color: 'white',
      },
    },
    CardColorStyle: {
      backgroundImage: 'none',
      background: '#FFFFFF',
    },
    CardContentStyle: {
      fontfamily: 'Calibre-R-Regular',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0px',
      textAlign: 'left',
      padding: '0px 8px',
      height: '112px',
    },
    CardContent: {
      padding: '0px',
    },
    CardActionsStyleTop: {
      display: 'flex',
      flexDirection: 'column',
      margin: '-10px 0 0 0',
    },
    CardStatusText: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    CardViewButton: {
      '&:hover': {
        backgroundColor: 'rgba(0, 63, 45, 0.08)',
      },
    },
    CardActionsStyle: {
      display: 'flex',
      margin: '0px',
      padding: '0px',
      '& section:nth-of-type(1)': {
        fontFamily: 'Calibre-R-Regular',
        fontSize: '12px',
        lineHeight: '18px',
        letterSpacing: '0.005em',
        textAlign: 'left',
        color: 'rgba(38, 38, 38, 0.6)',
        flex: '1',
      },
      '& section:nth-of-type(2)': {
        fontFamily: 'Calibre-R-Regular',
        fontSize: '16px',
        lineHeight: '16px',
        textAlign: 'left',
      },
      '& .user': {
        color: theme.palette.primary.main,
        fontFamily: 'Calibre-R-Regular',
        fontSize: '14px',
      },
    },
    ActionIconsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    CardActionButton: {
      height: '100%',
      minWidth: '0px',
      padding: '3px',
      background: 'transparent',
      '&$disabled': {
        cursor: 'not-allowed',
      },
    },
    ContentHeaderStyle: {
      display: 'flex',

      '& > div:first-of-type': {
        display: 'flex',
        flex: '1 1 auto',

        '& > div:first-of-type': {
          width: '186px',
          border: '1px solid #cccccc',

          '& > div > div': {
            padding: '3px',
          },
        },

        '& > div:nth-of-type(2)': {
          width: '250px',
          marginLeft: '20px',
          background: '#ffffff',
          border: '1px solid #cccccc',
          borderRadius: '3px',

          '& > div > div': {
            width: '100%',
            underline: 'none',
            padding: '4px 2px 2px 2px',
          },
        },
      },
    },
    EmptyStateStyle: {
      '& > div > div > div': {
        marginTop: '190px',
        paddingLeft: '80px',
      },
    },
    ViewOptionStyle: {
      float: 'right',
      margin: '0px 5px 0px 0px',
    },
    SearchByStyle: {
      background: 'white',
      '& > div:first-of-type': {
        width: '100%',
        '& select': {
          padding: '6px 10px',
          background: 'white',
        },
        '& option': {
          padding: '4px',
        },
      },
    },
    pointerDiv: {
      cursor: 'pointer',
    },
    exploreBtn: {
      padding: '0px',
    },
    assetcardTimeStamp: {
      color: '#7F8480',
      fontSize: '12px',
      lineHeight: '16px',
    },
    assetcardTimeStampSection: {
      padding: '4px 0px 0px 0px',
    },
    allassetCardContent: {
      fontSize: '18px',
      lineHeight: '16px',
      margin: '20px 0px 10px 0px',
      fontWeight: '600',
    },
    tagsContainer: {
      padding: '0px !important',
    },
    timeStampExplore: {
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    userName: {
      color: theme.palette.primary.main,
      fontSize: '14px',
      lineHeight: '14px',
    },
    allAssetCard: {
      height: '330px',
      margin: '0px',
      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: '6px',
    },
    assetStore: {
      fontSize: '12px',
      lineHeight: '16px',
      marginLeft: '2px',
      fontWeight: '500',
    },
    assetCardDescription: {
      fontSize: '12px',
      lineHeight: '16px',
      // color: '#1A1A1A',
      marginTop: '12px',
      wordBreak: 'break-word',
      ['@media (max-width:920px) and (min-width: 280px)']: {
        wordBreak: 'break-word',
      },
    },
    assetCardTags: {
      // backgroundColor: 'rgba(0, 63, 45, 0.1)',
      color: '#1A1A1A',
      fontSize: '12px',
      lineHeight: '16px',
      height: '24px',
    },
    entityCard: {
      height: '100%',
      padding: '18px',
      margin: '0px',
      boxShadow: 'none',
      display: 'flex',
      flexDirection: 'column',
    },
    entityCardContainer: {
      justifyContent: 'space-between',
    },
    assetStoreItems: {
      display: 'flex',
      alignItems: 'center',
    },
    assetStatusClr: {
      fontSize: '12px',
      lineHeight: '16px',
      whiteSpace: 'nowrap',
      borderLeft: '1px solid rgba(0, 63, 45, 0.15)',
      marginLeft: '4px',
      paddingLeft: '2px',
      fontWeight: 500,
    },
    userLink: {
      color: 'grey',
      fontSize: '14px',
      lineHeight: '14px',
    },
    entityRating: {
      marginTop: '10px',
      display: 'flex',
    },
    infoBadge: {
      marginLeft: '8px',
    },
  }),
);
