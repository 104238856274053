import React from 'react';
import { Entity } from '@backstage/catalog-model';
import { catalogApiRef, useAsyncEntity } from '@backstage/plugin-catalog-react';
import { isOwnerOf } from '@backstage/plugin-catalog-react/alpha';
import { genPageTheme } from '@backstage/theme';
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

import { useAsync } from 'react-use';

import {
  InfoCard,
  InfoCardVariants,
  Progress,
  ResponseErrorPanel,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { useParams } from 'react-router-dom';

type EntitiesKinds = 'Component' | 'API';
type EntitiesTypes =
  | 'package'
  | 'iac'
  | 'framework'
  | 'documentation'
  | 'api'
  | 'utility'
  | 'extdocumentation';

const createPageTheme = (theme: Theme, shapeKey: string, colorsKey: string) => {
  const { colors } = theme.getPageTheme({ themeId: colorsKey });
  const { shape } = theme.getPageTheme({ themeId: shapeKey });
  return genPageTheme({ colors: colors, shape: shape }).backgroundImage;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      border: `1px solid ${theme.palette.divider}`,
      boxShadow: theme.shadows[2],
      borderRadius: '4px',
      padding: theme.spacing(2),
      color: '#fff',
      transition: `${theme.transitions.duration.standard}ms`,
      '&:hover': {
        boxShadow: theme.shadows[4],
      },
    },
    bold: {
      fontWeight: theme.typography.fontWeightBold,
    },
    service: {
      background: createPageTheme(theme, 'home', 'service'),
    },
    website: {
      background: createPageTheme(theme, 'home', 'website'),
    },
    library: {
      background: createPageTheme(theme, 'home', 'library'),
    },
    documentation: {
      background: createPageTheme(theme, 'home', 'documentation'),
    },
    api: {
      background: createPageTheme(theme, 'home', 'home'),
    },
    tool: {
      background: createPageTheme(theme, 'home', 'tool'),
    },
  }),
);

const countEntitiesBy = (
  entities: Array<Entity>,
  kind: EntitiesKinds,
  type?: EntitiesTypes,
) =>
  entities.filter(
    e => e.kind === kind && (type ? e?.spec?.type === type : true),
  ).length;

const EntityCountTile = ({
  counter,
  className,
  name,
}: {
  counter: number;
  className: EntitiesTypes;
  name: string;
}) => {
  const classes = useStyles();
  return (
    <Box
      className={`${classes.card} ${classes[className]}`}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography className={classes.bold} variant="h6">
        {counter}
      </Typography>
      <Typography className={classes.bold} variant="h6">
        {name}
      </Typography>
    </Box>
  );
};

export const OwnershipCard = ({
  variant,
}: {
  variant?: InfoCardVariants;
}) => {
  const { entity } = useAsyncEntity();
  const catalogApi = useApi(catalogApiRef);
  const { name } = useParams();

  const {
    loading,
    error,
    value: componentsWithCounters,
  } = useAsync(async () => {
    const kinds = ['Component', 'API'];
    const entitiesList = await catalogApi.getEntities({
      filter: {
        kind: kinds,
      },
      fields: [
        'kind',
        'metadata.name',
        'metadata.namespace',
        'spec.type',
        'relations',
      ],
    });

    const ownedEntitiesList = entitiesList.items.filter(component =>
      isOwnerOf(entity, component),
    );

    return [
      {
        counter: countEntitiesBy(ownedEntitiesList, 'Component', 'package'),
        className: 'service',
        name: 'Package',
      },
      {
        counter: countEntitiesBy(
          ownedEntitiesList,
          'Component',
          'extdocumentation',
        ),
        className: 'documentation',
        name: 'Techdocs',
      },
      {
        counter: countEntitiesBy(ownedEntitiesList, 'API'),
        className: 'api',
        name: 'APIs',
      },
      {
        counter: countEntitiesBy(ownedEntitiesList, 'Component', 'framework'),
        className: 'library',
        name: 'Framework',
      },
      {
        counter: countEntitiesBy(ownedEntitiesList, 'Component', 'iac'),
        className: 'website',
        name: 'IaC',
      },
      {
        counter: countEntitiesBy(ownedEntitiesList, 'Component', 'utility'),
        className: 'tool',
        name: 'Utility',
      },
    ] as Array<{ counter: number; className: EntitiesTypes; name: string }>;
  }, [catalogApi, entity]);

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  } else if (name != 'cbre') {
    return (
      <InfoCard title="Ownership" variant={variant}>
        <Grid container>
          {componentsWithCounters?.map(c => (
            <Grid item xs={6} md={6} lg={4} key={c.name}>
              <EntityCountTile
                counter={c.counter}
                className={c.className}
                name={c.name}
              />
            </Grid>
          ))}
        </Grid>
      </InfoCard>
    );
  }
  return <></>;
};
