const Constants = {
  helpUrl: 'https://confluence.cbre.com/display/GA/Feedback',
  helpUrlEdit: 'https://confluence.cbre.com/display/GA/Edit+Feedback+Page',
  helpUrlRegister:
    'https://confluence.cbre.com/display/GA/Register+Feedback+Page',
  pageTitle: {
    registerPage: 'Register Your Feedback',
    editPage: 'Edit Feedback',
    feedbackPage: 'Feedback',
  },
  routes: {
    feedbackPageRoute: 'feedback',
    registerPageRoute: 'register',
    editPageRoute: 'edit',
  },
  feedbackInput: {
    heading: 'Edit your Feedback',
    input: {
      placeholder: 'Please enter your feedback',
      id: 'feedback-input',
      rules: {
        required: 'Please provide your feedback',
        minChars: 'At least 3 characters required',
        maxChars: 'Maximum 300 characters allowed',
      },
    },
    successText: 'Thank you for your feedback',
  },
  feedbackTypes: {
    feature: {
      value: 'Feature',
      color: '#DBD99A',
    },
    request: {
      value: 'Request',
      color: '#435254',
    },
    bug: {
      value: 'Bug',
      color: '#D2785A',
    },
    comments: {
      value: 'Comments',
      color: '#CAD1D3',
    },
    other: {
      value: 'Other',
      color: '#80BBAD',
    },
  },
  feedbackStatusOptions: [
    'Open',
    'Under Review',
    'Backlog',
    'In Development',
    'Closed',
    'N/A',
  ],
  feedbackTypeOptions: ['Feature', 'Request', 'Bug', 'Comments', 'Other'],
  feedbackFilterSelectItems: [
    {
      label: 'Title',
      value: 'title',
    },
    {
      label: 'Type',
      value: 'feedbacktype',
    },
    {
      label: 'Text',
      value: 'feedback',
    },
    {
      label: 'Name',
      value: 'username',
    },
  ],
  descriptionTexts: {
    descriptionTitle: 'Register your feedback',
    descriptionInfoTitle: 'Feedback title: Title of your feedback',
    descriptionInfoExample: {
      title: 'Feedback type: Type of your feedback',
      text: 'Example: New feature',
    },
    descrptionInfoText: 'Feedback: Add a long description of your feedback',
  },
  filterFields: [
    'id',
    'username',
    'title',
    'feedbacktype',
    'feedback',
    'status',
  ],
};

export default Constants;
