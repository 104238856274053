import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SendIcon = ({ fill = '#FFFFFF' }: { fill?: string }) => (
  <SvgIcon viewBox="0 0 30 30">
    <g id="send">
      <path
        id="Vector"
        d="M3.67124 20.4L21.1212 12.92C21.9312 12.57 21.9312 11.43 21.1212 11.08L3.67124 3.60002C3.01124 3.31002 2.28124 3.80002 2.28124 4.51002L2.27124 9.12002C2.27124 9.62002 2.64124 10.05 3.14124 10.11L17.2712 12L3.14124 13.88C2.64124 13.95 2.27124 14.38 2.27124 14.88L2.28124 19.49C2.28124 20.2 3.01124 20.69 3.67124 20.4Z"
        fill={fill}
      />
    </g>
  </SvgIcon>
);

export default SendIcon;
