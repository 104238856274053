import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import BadgeDisplayImage from '../../../leaderBoard/badgeTypePicker';
import { useCustomStyles } from './CardStyles';

const PointsCard = (props: any) => {
  const { description, name, points } = props.data;
  const { onclickHandler } = props;
  const classes = useCustomStyles();

  return (
    <div className={classes.CardEntityContainer}>
      <Grid container className={'admin'}>
        <Grid item xs={8} md={9} className={classes.CardTypeContainerFirst}>
          <div className={classes.CardTypeImageAndText}>
            <BadgeDisplayImage type={name} />
            <Grid item xs={12} md={12}>
              <Typography className={classes.CardTypeFontBold}>
                <b> {name}</b>
              </Typography>
              <Typography className={classes.CardTypeFont}>
                {description}
              </Typography>
            </Grid>
          </div>
        </Grid>

        <Grid item xs={2} md={1} className={classes.CardTypeContainer}>
          <Typography className={classes.CardTypeFontBold}>
            <b> {points}</b>
          </Typography>
          <Typography className={classes.CardTypeFont}>Points</Typography>
        </Grid>

        <Grid
          item
          md={2}
          xs={7}
          className={`${classes.CardTypeContainer} ${classes.buttonContainer}`}
        >
          <Button
            onClick={() => onclickHandler(props.data)}
            color="primary"
            variant="contained"

          >
            Modify Points
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default PointsCard;
