import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { WithLink } from '../../utils/components';
import { RadarDescription } from '../RadarDescription';
import type { EntrySnapshot } from '../../utils/types';

type RadarLegendLinkProps = {
  entryId: string;
  url?: string;
  description?: string;
  title?: string;
  classes: ClassNameMap<string>;
  active?: boolean;
  links: Array<{ url: string; title: string }>;
  timeline: EntrySnapshot[];
};

export const RadarLegendLink = ({
  entryId,
  url,
  description,
  title,
  classes,
  active,
  links,
  timeline,
}: RadarLegendLinkProps) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggle = () => {
    setOpen(!open);
  };

  if (description) {
    return (
      <>
        <Typography
          component="span"
          className={classes.entryLink}
          onClick={handleClickOpen}
          role="button"
          tabIndex={0}
          onKeyPress={toggle}
        >
          <Typography
            component="span"
            id={entryId}
            className={active ? classes.activeEntry : classes.entry}
          >
            {title}
          </Typography>
        </Typography>
        {/* {open && (
          <RadarDescription
            open={open}
            onClose={handleClose}
            title={title ? title : 'no title'}
            url={url}
            description={description}
            timeline={timeline ? timeline : []}
            links={links}
          />
        )} */}
      </>
    );
  }
  return (
    <WithLink url={url} className={classes.entryLink}>
      <Typography
        component="span"
        id={entryId}
        className={active ? classes.activeEntry : classes.entry}
      >
        {title}
      </Typography>
    </WithLink>
  );
};
