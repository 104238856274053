import * as  React from 'react';

export interface UserInfoType {
  userInfo?: any;
  accessToken?: string;
  profile?: any;
  apiBaseUrl?: string;
  allFixedRoutes?: any;
  profEmail?: any;
  profileName?: any;
  isAdmin?: any;
  isDomainAdmin?: any;
}

let userInfoType: UserInfoType = {};
const initialState: UserInfoType = {
  userInfo: userInfoType,
  accessToken: '',
  profile: {},
  apiBaseUrl: '',
  allFixedRoutes: [],
  profEmail: '',
  profileName: '',
  isAdmin: null,
  isDomainAdmin: null
};

export const AuthContext = React.createContext(initialState);
