import type { Transformer } from './transformer';
import { SHADOW_DOM_STYLE_LOAD_EVENT } from '@backstage/plugin-techdocs-react';

export const scrollIntoAnchor = (): Transformer => {
  return dom => {
    dom.addEventListener(
      SHADOW_DOM_STYLE_LOAD_EVENT,
      function handleShadowDomStyleLoad() {
        if (window.location.hash) {
          const hash = window.location.hash.slice(1);
          dom?.querySelector(`[id="${hash}"]`)?.scrollIntoView();
        }
        dom.removeEventListener(
          SHADOW_DOM_STYLE_LOAD_EVENT,
          handleShadowDomStyleLoad,
        );
      },
    );
    return dom;
  };
};