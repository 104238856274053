import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CloseDocumentIcon = ({ fill }: { fill?: string }) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M13.3333 4L6 4C5.63333 4 5.33333 4.3 5.33333 4.66667C5.33333 5.03333 5.63333 5.33333 6 5.33333L13.3333 5.33333C13.7 5.33333 14 5.03333 14 4.66667C14 4.3 13.7 4 13.3333 4ZM13.3333 7.33333L8 7.33333C7.63333 7.33333 7.33333 7.63333 7.33333 8C7.33333 8.36667 7.63333 8.66667 8 8.66667L13.3333 8.66667C13.7 8.66667 14 8.36667 14 8C14 7.63333 13.7 7.33333 13.3333 7.33333ZM14 11.3333C14 10.9667 13.7 10.6667 13.3333 10.6667L6 10.6667C5.63333 10.6667 5.33333 10.9667 5.33333 11.3333C5.33333 11.7 5.63333 12 6 12L13.3333 12C13.7 12 14 11.7 14 11.3333ZM2.46667 6.08L4.38667 8L2.46667 9.92C2.20667 10.18 2.20667 10.6 2.46667 10.86C2.72667 11.12 3.14667 11.12 3.40667 10.86L5.8 8.46667C6.06 8.20667 6.06 7.78667 5.8 7.52667L3.40667 5.13334C3.14667 4.87334 2.72667 4.87334 2.46667 5.13334C2.21333 5.39334 2.20667 5.82 2.46667 6.08Z"
      fill={fill}
    />
  </SvgIcon>
);

export default CloseDocumentIcon;
