import React, { useEffect, useContext } from 'react';
import { PromotionContext } from '../../../providers/PromotionDataProvider';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  techDocStyle: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',
  },
});

const TechDocsEmptyState = () => {
  const onclickHelpButton = useContext(PromotionContext);
  const classes = useStyles();
  useEffect(() => {
    onclickHelpButton.setToggleHelpButton(false);
  }, []);

  return (
    <div>
      <p>
        TechDocs is a docs-like-code solution in Dev X. This is to facilitate
        engineers write their documentation in MarkDown files which live with
        their code.{' '}
      </p>
      <p>
        This feature has to be enabled and the following requirements met. If it
        is not enabled there will be a "Missing Annotation" message and any
        errors will be thrown here as well.{' '}
      </p>
      <div>
        <b>Requirements and Setup</b>
      </div>
      <div>
        <p>
          A docs folder in the repository which has all the markdown files.{' '}
        </p>
        <p>
          Create an mkdocs.yml in the root of your repository with the follow
          content:
        </p>
        <div
          className={classes.techDocStyle}
        >
          <p>
            <b>site_name: 'example'</b>{' '}
          </p>
          <p>
            <b>nav:</b>
          </p>
          <ul className="techdocs-list">
            <li>
              <b>
                -Home: '
                <a target="_blank" href="http://example.md/">
                  example.md
                </a>
                '
              </b>
            </li>
          </ul>

          <p>
            <b>plugins:</b>
          </p>
          <ul className="techdocs-list">
            <li>
              <b>-techdocs-core</b>
            </li>
          </ul>
        </div>
        <div>
          <p>
            <b>Examples</b>
          </p>
          <p>
            If you need examples of how this works they can be found on{' '}
            <a
              className="techdocs-tab-link"
              target="_blank"
              href="https://github.com/CBRE-Shared-Code/PublisherRepo-Searchbar"
            >
              Github
            </a>
            &nbsp; and{' '}
            <a
              className="techdocs-tab-link"
              target="_blank"
              href="https://dev.azure.com/cbre/Inner_Sourcing/_git/DevXHelpGuide"
            >
              ADO
            </a>
            .{' '}
          </p>
          <p>
            <b>Help</b>
          </p>
          <p>
            If you are having troubles with TechDocs please{' '}
            <a
              className="techdocs-tab-link"
              target="_blank"
              href="https://backstage.io/docs/features/techdocs/techdocs-overview"
            >
              check the documentation on Backstage
            </a>
            .{' '}
          </p>
        </div>
      </div>
    </div>
  );
};
export default TechDocsEmptyState;
