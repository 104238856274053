import React from 'react';
import {
  Card,
  CardContent,
  makeStyles,
  Grid,
  Divider,
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { isEmpty } from 'lodash';

const useStyles = makeStyles({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
    ['@media (max-width:920px)']: {
      position: 'relative',
      bottom: '18%',
    },
  },
  gridItemCardContent: {
    flex: 1,
  },
  nameAndDate: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  ratingsContainer: {
    marginBottom: '10px',
  },
  divider: {
    width: '100%',
  },
});

type RatingsProps = {
  ratings: [];
};

const getFormattedDate = dateValue => {
  const date_format = new Date(dateValue);
  return `${
    date_format.getMonth() + 1
  }/${date_format.getDate()}/${date_format.getFullYear()}`;
};

export function Ratings({ ratings }: RatingsProps) {
  const classes = useStyles();
  const ratingRows: any[] = [];
  let key = 0;
  ratings.forEach(rating => {
    if (!isEmpty(rating.user)) {
      ratingRows.push(
        <Grid
          key={key++}
          container
          spacing={3}
          className={classes.ratingsContainer}
        >
          <Grid item md={12} xs={12}>
            <div className={classes.nameAndDate}>
              <div>{rating.user}</div>
              <div>{getFormattedDate(rating.date)}</div>
            </div>
            <Grid container spacing={3}>
              <Grid item md={3} xs={12}>
                <Rating
                  defaultValue={parseFloat(rating.value) || 0}
                  precision={0.5}
                  size="small"
                  readOnly
                />
              </Grid>
              <Grid item md={8} xs={12}>
                {rating.feedback}
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
        </Grid>,
      );
    }
  });

  return (
    <Card className={classes.gridItemCard}>
      <CardContent className={classes.gridItemCardContent}>
        {ratingRows}
      </CardContent>
    </Card>
  );
}
