export const envScopes = {
  internal: {
    domainId: 'public_d1a6daa6bdef436c8b0a101e7b319c5d',
    subDomainId: 'public_0b8201786db94811a5bff6c886ad3af6',
    personaId: 'public_283a29f11bcc48948a8b6d3b30125192',
  },
  dev: {
    domainId: 'public_9acb0992afb948378c13878f96ac38c4',
    subDomainId: 'public_ebd839fb92bb4a0f987d7826aced3b2d',
    personaId: 'public_4bfbebdb8f5e4ddcb8094d70846b08c2',
  },
  uat: {
    domainId: 'public_bb2bf91493784f78899bb20d37cf279f',
    subDomainId: 'public_d1db008ebc6946f89b59247da10394f9',
    personaId: 'public_e2c68a7ff8d549eab6acf492e5e2844e',
  },
  prod: {
    domainId: 'public_f57975e7a1374334bf4a1c04c042a54f',
    subDomainId: 'public_06bb0872ac1e4b8aa43b656fffcbffae',
    personaId: 'public_3033f5b67d9e49d0b766293a9adfcb40',
  },
};
