import {
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinkIcon from '@material-ui/icons/Link';
import { Autocomplete } from '@material-ui/lab';

const SupportLinksFormField = (props: any) => {
  const { classes, setLinks, links } = props;

  const handleChange = (i: any, e: any) => {
    let formValues = links;
    formValues[i][e.target.name] = e.target.value;

    if (e.target.name === 'isdefault') {
      formValues = formValues?.map((item: any, index: number) => {
        if (index == e.target.value) {
          return { ...item, isdefault: true };
        } else {
          return { ...item, isdefault: false };
        }
      });
    }

    setLinks([...formValues]);
  };

  const addFormFields = () => {
    setLinks([
      ...links,
      { isdefault: false, displayname: '', link: '', type: 'link' },
    ]);
  };

  const removeFormFields = (index: number) => {
    let formValues = links;
    console.log('splice', index);
    formValues.splice(index, 1);
    console.log('formValues', formValues);
    setLinks([...formValues]);
  };

  const [selectedLinkIndex, setSelectedLinkIndex] = useState<number>(0);
  useEffect(() => {
    setSelectedLinkIndex(links.findIndex((item: any) => item.isdefault));
  }, [links]);

  return (
    <>
      <div className={classes.toolboxLinks}>
        <div className={classes.links}>
          <div className={classes.linksHeading}>Product/Service Link</div>
        </div>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl className={classes.mainFormControl}>
              <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                value={`${selectedLinkIndex}`}
                name="isdefault"
              >
                {links &&
                  links.map((item: any, index: number) => {
                    return (
                      <div className={classes.linkFields}>
                        <Grid container>
                          <Grid item xs={1} sm={1} md={1} lg={1}>
                            <FormControlLabel
                              value={`${index}`}
                              control={<Radio />}
                              label=""
                              onChange={e => handleChange(index, e)}
                              name="isdefault"
                            />
                            {/* <FormControl fullWidth variant="outlined">
                      <InputLabel id="type">Type</InputLabel>
                      <Select
                        labelId="type"
                        name="type"
                        label="Type"
                        value={item.type || 'link'}
                        onChange={e => handleChange(index, e)}
                      >
                        <MenuItem value={'link'}>Link</MenuItem>
                        <MenuItem value={'contact'}>Email</MenuItem>
                      </Select>
                    </FormControl> */}
                          </Grid>
                          <Grid item xs={12} sm={12} md={5} lg={5}>
                            {/* <FormControlLabel value={`${index}`} control={<Radio />} label="" /> */}
                            <TextField
                              label="Display Name"
                              fullWidth
                              variant="outlined"
                              placeholder="Enter"
                              name="displayname"
                              id="displayname"
                              onChange={e => handleChange(index, e)}
                              value={item.displayname}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            className={classes.links}
                          >
                            <TextField
                              label={item.type === 'link' ? 'Link' : 'Email'}
                              id="link"
                              name="link"
                              fullWidth
                              placeholder="Enter"
                              variant="outlined"
                              multiline
                              onChange={e => handleChange(index, e)}
                              value={item.link}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {item.type === 'link' ? (
                                      <LinkIcon />
                                    ) : (
                                      <ContactMailOutlinedIcon />
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                            {index > 0 ? (
                              <Tooltip title="Delete">
                                <IconButton
                                  onClick={() => removeFormFields(index)}
                                >
                                  <DeleteIcon className={classes.actionIcons} />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Delete">
                                <IconButton
                                  disabled
                                  onClick={() => removeFormFields(index)}
                                >
                                  <DeleteIcon className={classes.actionIcons} />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    );
                  })}
              </RadioGroup>
            </FormControl>
            <span className={classes.addmore}>
              <IconButton
                disabled={links.length > 25}
                className={classes.addLink}
                onClick={addFormFields}
              >
                Add Item
                <AddIcon className={classes.actionIcons} />
              </IconButton>
            </span>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default SupportLinksFormField;
