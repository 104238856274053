import { Grid, IconButton, Tooltip } from '@material-ui/core';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import REGISTER_API_CONSTANTS from '../Constants';
import { useStyles } from './styles';
import Server from './Server';

/* Servers Array*/
const Servers = (props: any) => {
  const { onChange, onError, clicked } = props;
  const classes = useStyles();

  /* servers */
  const [servers, setServers] = useState<any>([]);

  /* Handle Add server */
  const handleAddServers = () => {
    setServers((prevState: any) => [
      ...prevState,
      { id: new Date().getTime() },
    ]);
  };
  /* Delete servers section from Array */
  const handleDeleteServers = (serverId: any) => {
    setServers((prevState: any) => {
      const filteredServers = [...prevState].filter(
        (server: any) => server.id !== serverId,
      );
      return filteredServers;
    });
  };

  const [errors, setErrors] = useState<any>([]);
  const handleServerError = (hasError: boolean) => {
    setErrors((prevState: any) => [...prevState, hasError]);
  };

  useEffect(() => {
    const validated = errors.every((v: any) => v === false);
    const hasError = !validated;
    onError('servers', hasError);
  }, [errors]);

  const [serversArray, setServersArray] = useState<any>([]);
  const accumulateServers = (server: any) => {
    setServersArray((prevState: any) => [...prevState, { ...server }]);
  };

  useLayoutEffect(() => {
    if (clicked) {
      setErrors([]);
      setServersArray([]);
    }
  }, [clicked]);

  useEffect(() => {
    onChange(serversArray);
  }, [serversArray]);

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={3}
        className={classes.swaggerApiHeadings}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={`${classes.spaceBetween}`}
        >
          {REGISTER_API_CONSTANTS.swaggerApiForm.serverHeading}
          <span>
            <Tooltip title="Add Server">
              <IconButton
                className={classes.actionIcons}
                onClick={handleAddServers}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </span>
        </Grid>
      </Grid>
      {servers.map((server: any) => {
        return (
          <Server
            key={server.id}
            onChange={accumulateServers}
            onDeleteServer={() => handleDeleteServers(server.id)}
            clicked={clicked}
            onError={handleServerError}
          />
        );
      })}
    </>
  );
};

export default Servers;
