import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import useAiPopup from './hooks/useAIPopup';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogBox: {
      position: 'relative',
      margin: '10px 0px 0px 24px',
    },
    dialogContent: {
      width: 'auto',
    },
    closeButton: {
      position: 'absolute',
      top: '10px',
      right: '10px',
    },
    dialogHeader: {
      display: 'flex',
      alignItems: 'center',
    },
    headerTitle: {
      //   margin: '10px',
    },
    bodyContent: {
      '& a': {
        color: theme.palette.alert.link,
        '&:visited': {
          color: theme.palette.info.main,
        },
      },
    },
    footerSection: {
      margin: '10px',
    },
  }),
);

export interface PopupProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  dialogProps: any;
}

const AIPopup = () => {
  const classes = useStyles();

  const { open, onConfirm } = useAiPopup();
  console.log('Open Value', open);

  return (
    <Dialog
      open={open}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      disableEscapeKeyDown
      //    className={classes.dialogBox}
      //  fullWidth={false}
      maxWidth="md"
    >
      <DialogTitle id="dialog-title">
        <div className={classes.dialogHeader}>
          <span className={classes.headerTitle}>AI Disclaimer</span>
        </div>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <div className={classes.bodyContent}>
          <Typography>
            When using any AI on DevX you must adhere to{' '}
            <a
              href="https://cbrenow.cbre.com/esc?id=kb_article_view&sysparm_article=KB0000000022487&sys_kb_id=3b2782071bb1c6906c0aed70604bcbf4&spa=1"
              target="_blank"
            >
              Acceptable Use of Policy on AI at CBRE
            </a>
            .
            <p>
              AI systems are prone to hallucinate, hence all output must be
              reviewed by a human before usage.
            </p>
          </Typography>
        </div>
      </DialogContent>
      <DialogActions className={classes.footerSection}>
        <Button
          color="primary"
          onClick={() => onConfirm()}
          data-testid="no-btn"
          variant="contained"
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AIPopup;
