//@ts-ignore
import React, { ComponentType, useEffect } from 'react';
import { Content, Header, Page } from '@backstage/core-components';
import { Entity } from '@backstage/catalog-model';
import { TemplateEntityV1beta3 } from '@backstage/plugin-scaffolder-common';
import {
  CatalogFilterLayout,
  EntityKindPicker,
  EntityListProvider,
  EntitySearchBar,
  EntityTagPicker,
} from '@backstage/plugin-catalog-react';
import { TemplateTypePicker } from '@backstage/plugin-scaffolder';
import { TemplateList } from './TemplateList';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { UserListPicker } from '../filters/UserListPicker';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import BackButton from '../../utils/backButton';
import { DevxBreadCrumb } from '../../common/BreadcrumbsNav/DevxBreadCrumb';

const useStyles = makeStyles(_theme => ({
  templatePage: {
    ['@media (max-width:920px) and (min-width: 280px)']: {
      padding: '0',
    },
  },
  templateSearch: {
    display: 'flex',
    justifyContent: 'space-between',
    ['@media (max-width:920px) and (min-width: 280px)']: {
      marginLeft: '10px',
      flexDirection: 'column',
    },
  },
  templateHeading: {
    display: 'flex',
    alignItems: 'center',
  },
  searchTemplate: {
    '& div[class^="MuiFormControl-root"]': {
      ['@media (max-width:920px) and (min-width: 280px)']: {
        width: '100% !important',
        padding: '0px 16px 0px 0px',
      },
    },
  },
}));

export type ScaffolderPageProps = {
  TemplateCardComponent?:
    | ComponentType<{ template: TemplateEntityV1beta3 }>
    | undefined;
  groups?: Array<{
    title?: React.ReactNode;
    filter: (entity: Entity) => boolean;
  }>;
};

export const ScaffolderPageContents = ({
  TemplateCardComponent,
  groups,
}: ScaffolderPageProps) => {
  const otherTemplatesGroup = {
    title: groups ? 'Other Templates' : 'Templates',
    filter: (entity: Entity) => {
      const filtered = (groups ?? []).map(group => group.filter(entity));
      return !filtered.some(result => result === true);
    },
  };

  const classes = useStyles();

  return (
    <Page themeId="home">
      <Header
        pageTitleOverride="Create a New Component"
        title="Create a New Component"
        subtitle={
          <DevxBreadCrumb
            routes={[
              {
                link: '/',
                text: 'Home',
                type: 'link',
              },
              {
                link: '',
                text: 'Software Templates',
                type: 'text',
              },
            ]}
          />
        }
      />

      <Content>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <BackButton />
            <HelpComponent helpUrl={HELP_URL.SoftwareTemplates} />
          </Grid>

          <EntityListProvider>
            <CatalogFilterLayout>
              <CatalogFilterLayout.Filters>
                <EntityKindPicker initialFilter="template" hidden />
                <UserListPicker initialFilter="all" parentName="template" />
                <TemplateTypePicker />
                <EntityTagPicker />
              </CatalogFilterLayout.Filters>
              <CatalogFilterLayout.Content>
                <div className={classes.templateSearch}>
                  <div className={classes.templateHeading}>
                    <Typography variant="h6">Select a template</Typography>
                  </div>
                  <div className={classes.searchTemplate}>
                    <EntitySearchBar />
                  </div>
                </div>

                {groups &&
                  groups.map((group, index) => (
                    <TemplateList
                      key={index}
                      TemplateCardComponent={TemplateCardComponent}
                      group={group}
                    />
                  ))}
                <TemplateList
                  key="other"
                  TemplateCardComponent={TemplateCardComponent}
                  group={otherTemplatesGroup}
                />
              </CatalogFilterLayout.Content>
            </CatalogFilterLayout>
          </EntityListProvider>
        </Grid>
      </Content>
    </Page>
  );
};

export const ScaffolderPage = ({
  TemplateCardComponent,
  groups,
}: ScaffolderPageProps) => (
  <EntityListProvider>
    <ScaffolderPageContents
      TemplateCardComponent={TemplateCardComponent}
      groups={groups}
    />
  </EntityListProvider>
);
