/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PromptStoreCard from './PromptStoreCard';
import { Grid } from '@material-ui/core';
import { IPromptData, IUsageObject } from '../interfaces';

interface PromptListProps {
  type: string;
  prompts: IPromptData[];
  onPromptClicked: Function;
  getUsage: Function;
  updateUsage: Function;
  usageCountObj: IUsageObject;
  onTryoutClick: Function;
}

const PromptList: React.FC<PromptListProps> = ({
  type,
  prompts,
  onPromptClicked,
  getUsage,
  updateUsage,
  usageCountObj,
  onTryoutClick,
}) => {
  useEffect(() => {
    getUsage();
  }, []);

  return (
    <Grid container>
      {prompts.map((prompt, index) => (
        <Grid item xs={12} sm={12} md={6} lg={4} key={prompt.id}>
          <PromptStoreCard
            type={type}
            onUpdateUsage={() => updateUsage(prompt.id)}
            usageCount={usageCountObj[prompt.id] || 0}
            promptData={prompt}
            key={index}
            onPromptClicked={() => onPromptClicked(prompt)}
            onTryoutClick={onTryoutClick}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default PromptList;
