import React, { useContext, useEffect, useState } from 'react';
import { PromotionContext } from '../../../providers/PromotionDataProvider';
import { Button } from '@material-ui/core';

export const ViewDefinitionButton = React.forwardRef((props: any, ref) => {
  const { swaggerJson, swaggerError, clickedCounter, ...rest } = props;
  const myContext = useContext(PromotionContext);
  const [clicked, setClicked] = useState(0);

  const handleClick = () => {
    setClicked(prev => prev + 1);
  };

  useEffect(() => {
    clickedCounter(clicked);
  }, [clicked]);

  useEffect(() => {
    myContext.setToggleHelpButton(false);
    /* For Initial load - keep swagger panel closed */
    if (clicked) {
      if (!swaggerError) {
        myContext.setToggleSwaggerButton(true);
        myContext.setSwaggerJson(JSON.stringify(swaggerJson, null, 2));
      } else {
        myContext.setToggleSwaggerButton(false);
      }
    }
    // }
  }, [swaggerError, swaggerJson]);

  return (
    <Button ref={ref} onClick={handleClick} {...rest}>
      {props.children}
    </Button>
  );
});
