import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  LinearProgress,
} from '@material-ui/core';
// @ts-ignore
import { useApi } from '@backstage/core-plugin-api';
import BackButton from '../../../utils/backButton';
import { HelpComponent } from '../../../utils/helpComponent';
import HELP_URL from '../../../utils/helpLinkConstant';
import AddIcon from '../../../Root/icons/addIcon';
import { useNavigate } from 'react-router-dom';
import ToolsTable from './toolsTable';
import {
  ToolboxApi,
  toolboxApiRef,
  IToolboxItemNode,
} from '../../../../apis/toolboxApi';
import ConfirmDeleteTool from './confirmDelete';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';

const ToolsListPage = () => {
  const [toolsData, setToolsData] = useState<IToolboxItemNode[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [selectedItemToDelete, setSelectedItemToDelete] = useState<any>();

  const navigate = useNavigate();
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const toolboxApi: ToolboxApi = useApi(toolboxApiRef);

  const getAllToolsFromApi = () => {
    setLoading(true);
    toolboxApi
      .getAllTools()
      .then((value: IToolboxItemNode[] | undefined) => {
        setToolsData(value || []);
        setLoading(false);
      })
      .catch((err: any) => {
        setToolsData([]);
        setLoading(false);
        notificationApi.sendNotification({
          message: `Could not get the tools - ${err?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  useEffect(() => {
    getAllToolsFromApi();
  }, []);

  const onClickAddButton = () => {
    navigate('/admin/toolbox/edit/new');
  };

  const onClickDelete = (item: any) => {
    setShowConfirmDelete(true);
    setSelectedItemToDelete(item);
  };

  const onCloseConfirmPrompt = () => {
    setShowConfirmDelete(false);
    setSelectedItemToDelete(undefined);
  };

  const onConfirmDeleteTool = () => {
    setShowConfirmDelete(false);
    if (
      selectedItemToDelete !== undefined &&
      selectedItemToDelete.nid !== undefined &&
      selectedItemToDelete.title !== undefined
    ) {
      setLoading(true);
      toolboxApi
        .deleteToolById(selectedItemToDelete.nid)
        .then(data => {
          if (data?.status === 200 && data?.message === 'Deleted') {
            notificationApi.sendNotification({
              message: `Tool with title ${selectedItemToDelete.title} is successfully deleted`,
              disapperAfterMs: 2500,
              severity: 'success',
            });
          }
          setSelectedItemToDelete(undefined);
          setLoading(false);
          getAllToolsFromApi();
        })
        .catch(err => {
          notificationApi.sendNotification({
            message: `Could not delete the tool ${selectedItemToDelete.title}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
          setSelectedItemToDelete(undefined);
          setLoading(false);
          getAllToolsFromApi();
        });
    }
  };

  return (
    <Box>
      {loading && <LinearProgress />}
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <BackButton />
          <HelpComponent helpUrl={HELP_URL.ADMIN_TOOLBOX_MANAGE_TOOLS} />
        </Grid>

        <Grid item xs={12}>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={10}>
                <Typography variant="h5">Manage Tools</Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onClickAddButton}
                    startIcon={<AddIcon />}
                  >
                    Add Tool
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box className="codePage" sx={{ p: 0 }}>
            <ToolsTable
              toolsData={toolsData}
              loading={loading}
              onClickDelete={onClickDelete}
            />
          </Box>
        </Grid>
      </Grid>
      {showConfirmDelete && (
        <ConfirmDeleteTool
          isOpen={showConfirmDelete}
          onClose={onCloseConfirmPrompt}
          onConfirm={onConfirmDeleteTool}
          additionalText={selectedItemToDelete?.title}
        />
      )}
    </Box>
  );
};
export default ToolsListPage;
