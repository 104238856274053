import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles({
  dialogBox: {
    position: 'relative',
    margin: '10px 0px 0px 24px',
  },
  dialogContent: {
    width: '500px',
    margin: '10px',
    padding: '10px',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  dialogHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  headerTitle: {
    margin: '10px',
  },
  savePromptContent: {
    margin: '10px',
  },
  footerSection: {
    margin: '10px',
  },
});

export interface PopupProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  dialogProps: any;
}

const SaveNewPromptPopup = ({
  open,
  onClose,
  onConfirm,
  dialogProps,
}: PopupProps) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      className={classes.dialogBox}
      fullWidth={false}
    >
      <DialogTitle id="dialog-title">
        <div className={classes.dialogHeader}>
          <span className={classes.headerTitle}>{dialogProps.headerText}</span>
        </div>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <div className={classes.savePromptContent}>
          <span>{dialogProps.firstText}</span>
          <Typography>{dialogProps.promptKey}</Typography>
        </div>
        <div className={classes.savePromptContent}>
          <span>{dialogProps.secondText}</span>
          <Typography>
            {`${dialogProps.domainName} / ${dialogProps.subDomainName} / ${dialogProps.personaName} /`}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions className={classes.footerSection}>
        <Button
          color="primary"
          onClick={() => onClose()}
          data-testid="no-btn"
          variant="outlined"
          autoFocus
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => onConfirm()}
          data-testid="no-btn"
          variant="contained"
          autoFocus
        >
          {dialogProps.buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveNewPromptPopup;
