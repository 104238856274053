import React from 'react';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { PromotionsBreadCrumbsStyles } from './customStyles';

const BreadcrumbItem = (props: any) => {
  const classes = PromotionsBreadCrumbsStyles();
  const { type, link, text } = props;
  return (
    <>
      {type === 'text' && <Typography>{text}</Typography>}
      {type === 'link' && (
        <Link to={link}>
          <Typography className={classes.breadCrumbText}>{text}</Typography>
        </Link>
      )}
    </>
  );
};

export const PromotionsBreadCrumb = (props: any) => {
  const { routes } = props;

  return (
    <Breadcrumbs aria-label="breadcrumb" style={{ color: 'white' }}>
      {routes.map((item: any, index: any) => (
        <BreadcrumbItem
          key={item.text + index}
          type={item.type}
          index={index}
          link={item.link}
          text={item.text}
        ></BreadcrumbItem>
      ))}
    </Breadcrumbs>
  );
};
