import {
  Button,
  ButtonGroup,
  CardContent,
  createStyles,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import remarkSlug from 'remark-slug';
import rehypeExternalLinks from 'rehype-external-links';
import remarkFrontmatter from 'remark-frontmatter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import React, { useContext, useRef, useState } from 'react';
import SmartToyOutlinedIcon from '@material-ui/icons/SmartToyOutlined';
import { IEAnswerItem, Vote } from '../../../apis/informationExchange';
import { AuthContext } from '../../../providers/AuthProvider';
import { IEContext } from '../providers/IEProvider';
import UserAvatar from '../../common/UserAvatar';
import { timeSince } from '../../utils/dateUtils';
import AcceptAnswer from './AcceptAnswer';
import Votes from './Votes';
import { userProfileApiRef } from '../../../apis/userProfileApi';
import { useApi } from '@backstage/core-plugin-api';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AIBotIcon from '../icons/aiBotIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    discussionsSection: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '15px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'normal',
      },
    },
    btnTabs: {
      borderRadius: '4px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '10px',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    btnDefault: {
      backgroundColor: 'transparent',
      color: theme.palette.text.secondary,
      textTransform: 'none',
      padding: '10px 21px',
    },
    btnSelected: {
      padding: '10px 21px',
      backgroundColor: '#CFCFCF',
      color: '#00000094',
      textTransform: 'none',
    },
    discussionTitle: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '24px',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.secondary,
    },
    discussionCard: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
      margin: '20px 0px 0px 30px',
      overflow: 'auto',
      [theme.breakpoints.down('sm')]: {
        margin: '15px 0px 0px 21px',
      },
      '& img': {
        display: 'block',
        maxWidth: '100%',
        height: 'auto',
      },
      '& a': {
        textDecoration: 'underline',
        color: '#06c',
      },
      '& .ql-syntax': {
        backgroundColor: '#23241f',
        color: '#f8f8f2',
        overflow: 'visible',
      },
    },
    discussion: {
      display: 'flex',
    },
    voteSection: {
      marginTop: '20px',
      width: theme.spacing(6),
    },

    // time styles
    date: {
      color: theme.palette.text.tertiary,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    timeStamp: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: '6px',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
        margin: '15px 0px 15px 0px',
      },
    },
    iconButton: {
      padding: theme.spacing(0.5),
    },
    ansDetails: {
      marginTop: '40px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '0px',
      },
    },
    discussionBtns: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
      },
    },
    aiBlock: {
      display: 'flex',
      color: '#17E88F',
    },
    flex: {
      display: 'flex',
      justifyContent: 'end'
    }
  }),
);

const DiscussionCard = (props: any) => {
  const theme = useTheme<Theme>();

  const { answer, answerUserData, queAuthor, onAnsDeleted } = props;

  const { profEmail, isAdmin } = useContext(AuthContext);
  const myIEContext = useContext(IEContext);
  const usernameRef = useRef(null);
  const userProfileApi = useApi(userProfileApiRef);

  const classes = useStyles();
  const name = answer?.author && answer?.author.split('.')[0];

  const isQuestionAuthorLoggedInUser = () => {
    return queAuthor.toLowerCase() === profEmail.toLowerCase();
  };

  const isAnswerAuthorLoggedInUser = () => {
    return answer?.author?.toLowerCase() === profEmail.toLowerCase();
  };

  const answerRef = useRef();
  const handleEditAnsclicked = () => {
    /* Old Answer data */
    myIEContext.setOldEditData({
      id: answer.id,
      detail: answer.detail,
    });
    myIEContext.setAnswerRef(answerRef);
  };

  const [isLoading, setIsloading] = useState(false);

  const onMouseEnter = () => {
    let email: string | undefined = answer?.author || '';
    userProfileApi.setUserProfileState({
      email,
      anchorRef: usernameRef?.current,
    });
  };

  return (
    <Grid container ref={answerRef}>
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.discussion}>
        <div className={classes.voteSection}>
          <Votes
            value={answer.vote}
            loggedInVote={answerUserData?.vote}
            actiontype="a"
            id={answer.id}
            onLoadingChanged={(value: boolean) => setIsloading(value)}
          />
          <AcceptAnswer
            aid={answer.id}
            qid={answer.pid}
            accepted={answer.accepted}
            authorIsLoggedIn={isQuestionAuthorLoggedInUser()}
          />
        </div>
        <CardContent className={classes.discussionCard}>
          {isLoading && <LinearProgress />}
          <div className={classes.flex}>
           <div className={classes.discussionBtns}>
              {isAdmin && (
                <IconButton
                  aria-label="delete"
                  onClick={onAnsDeleted}
                  color="primary"
                  className={classes.iconButton}
                >
                  <DeleteIcon />
                </IconButton>
              )}
              {isAnswerAuthorLoggedInUser() && !answer.openaicorrelationid && (
                <IconButton
                  aria-label="delete"
                  onClick={handleEditAnsclicked}
                  color="primary"
                  className={classes.iconButton}
                >
                  <EditIcon />
                </IconButton>
              )}
            </div>
          </div>
          {
            answer.openaicorrelationid ? <div
            className={
              isAnswerAuthorLoggedInUser() || isAdmin ? classes.ansDetails : ''
            }
          >
            <ReactMarkdown
              children={answer.detail}
              linkTarget="_blank"
              remarkPlugins={[remarkGfm, remarkSlug, remarkFrontmatter]}
              rehypePlugins={[rehypeRaw, rehypeExternalLinks]}
              className="react-markdown-ai-response"
              components={{
                code({ node, inline, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || '');
                  return (
                    <SyntaxHighlighter
                      children={String(children).replace(/\n$/, '')}
                      style={atomDark}
                      language={match ? match[1] : 'jsx'}
                      PreTag="div"
                      {...props}
                    />
                  );
                },
              }}
            />
          </div> : <div
            className={
              isAnswerAuthorLoggedInUser() || isAdmin ? classes.ansDetails : ''
            }
            dangerouslySetInnerHTML={{ __html: answer.detail }}
          />
          }
          <div className={classes.timeStamp}>
            {
              !answer.openaicorrelationid && <>
                <div>
                <UserAvatar
                  userEmail={answer.author}
                  customStyles={{
                    width: '32px',
                    height: '32px',
                    fontSize: '12px',
                    fontWeight: 'normal',
                  }}
                />
              </div>
              <div onMouseEnter={onMouseEnter} ref={usernameRef}>
                {name}
              </div>
              </>
            }
            {answer.openaicorrelationid && (
              <div>
              <AIBotIcon fontSize="large" style={{ marginTop: '10px' }}/>
              </div>
          )}
            <div className={classes.date}>
              {timeSince(answer.createdat, 'answered ')}
            </div>
          </div>
        </CardContent>
      </Grid>
    </Grid>
  );
};

const Discussions = (props: any) => {
  const {
    answers,
    answersUserData,
    queAuthor,
    onFilterClick,
    filterBy,
    onAnsDeleted,
  } = props;
  const classes = useStyles();

  /* Find user data by id */
  const findUserDataById = (id: String) => {
    return (
      answersUserData &&
      answersUserData.find((answer: Vote) => answer.id === id)
    );
  };

  const handleFilterClicked = (filterName: any) => {
    onFilterClick(filterName);
  };

  return (
    <>
      <div className={classes.discussionsSection}>
        <div className={classes.discussionTitle}>Discussions</div>
        <ButtonGroup
          size="large"
          variant="outlined"
          className={classes.btnTabs}
        >
          <Button
            className={
              filterBy === 'newest' ? classes.btnSelected : classes.btnDefault
            }
            onClick={() => handleFilterClicked('newest')}
          >
            Newest
          </Button>
          <Button
            className={
              filterBy === 'highestVotes'
                ? classes.btnSelected
                : classes.btnDefault
            }
            onClick={() => handleFilterClicked('highestVotes')}
          >
            Highest Votes
          </Button>
        </ButtonGroup>
      </div>
      {answers &&
        answers?.map((answer: IEAnswerItem) => (
          <DiscussionCard
            answer={answer}
            key={answer.id}
            answerUserData={findUserDataById(answer.id)}
            queAuthor={queAuthor}
            onAnsDeleted={() => onAnsDeleted(answer)}
          />
        ))}
    </>
  );
};
export default Discussions;
