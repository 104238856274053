import React from 'react';
import { useLocation } from 'react-router-dom';
import { Page, Header, TabbedLayout } from '@backstage/core-components';
import EXT_DOCS_CONSTANTS from './Constants';
import ExternalDocsContainer from './ExternalDocs/ExternalDocsContainer';
import { CustomTechDocsPage } from './TechDocs/CustomTechDocsPage';
import BreadcrumbsNav from '../common/BreadcrumbsNav/BreadcrumbsNav';

const DocsContainer = () => {
  const location = useLocation();

  return (
    <Page themeId="documentation">
      <Header
        title={
          location.pathname.indexOf('/docs/external') >= 0
            ? EXT_DOCS_CONSTANTS.externalDocumentsLabel
            : EXT_DOCS_CONSTANTS.tecDocsLabel
        }
        subtitle={
          <BreadcrumbsNav
            lastRoutes={[
              { path: '/', title: 'Tech Docs' },
              { path: '/external', title: 'External Docs' },
            ]}
          />
        }
      />
      <TabbedLayout>
        <TabbedLayout.Route path="/" title={EXT_DOCS_CONSTANTS.routeNameDocs}>
          <CustomTechDocsPage />
        </TabbedLayout.Route>
        <TabbedLayout.Route
          path="/external"
          title={EXT_DOCS_CONSTANTS.routeNameExternalDocs}
        >
          <ExternalDocsContainer />
        </TabbedLayout.Route>
      </TabbedLayout>
    </Page>
  );
};
export default DocsContainer;
