/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback } from 'react';
// @ts-ignore
import { useApi } from '@backstage/core-plugin-api';
import {
  API_STATUS_FETCHING,
  API_STATUS_SUCCESS,
  API_STATUS_ERROR,
} from './constant';
import { entityConversionApiRef } from '../../apis/entityConversionApi';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';

export const usePostConversionApi = (
  repoName: string,
  kind: string,
  entity: any,
  sendEmail = false,
) => {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState('idle');
  const [postResp, setPostResp] = useState({});

  const entityConversionApi = useApi(entityConversionApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const saveEntity = useCallback(() => {
    setStatus(API_STATUS_FETCHING);
    setLoading(true);

    return entityConversionApi
      .postDataWithEmailFlag(repoName, kind, sendEmail, entity)
      .then((res: any) => {
        setLoading(false);
        setPostResp(res.data);
        setStatus(API_STATUS_SUCCESS);
        return res.data;
      })
      .catch((error: any) => {
        setLoading(false);
        setPostResp(error);
        setStatus(API_STATUS_ERROR);
        notificationApi.sendNotification({
          severity: 'error',
          disapperAfterMs: 2500,
          message: `Error occurred while saving entity - ${error.message}`,
        });
      });
  }, [repoName, kind, entity]);

  return { loading, status, postResp, saveEntity };
};
