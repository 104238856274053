import React, { useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, LinearProgress } from '@material-ui/core';
import { useCustomStyles } from './CardStyles';
import BadgeDisplayImage from '../badgeTypePicker';
import UserAvatar from '../../common/UserAvatar';
import { PromotionContext } from '../../../providers/PromotionDataProvider';
import { useApi } from '@backstage/core-plugin-api';
import { userProfileApiRef } from '../../../apis/userProfileApi';

export interface CurrentLeaderBoardCardProps {
  data: {
    displayname: string;
    score: number;
    levelname: string;
    email: string;
  };
}

const CurrentLeaderBoardCard = (props: CurrentLeaderBoardCardProps) => {
  const { displayname, score, levelname, email } = props.data;
  const name =
    (displayname && displayname.split('@')[0]) ||
    (email && email.split('@')[0]);
  const classes = useCustomStyles();
  const gamificationLevelsList = useContext(PromotionContext);
  const userProfileApi = useApi(userProfileApiRef);
  const usernameRef = useRef(null);

  const minValue = gamificationLevelsList?.gamificationlevelData?.filter(
    (item: any) => item.name === levelname,
  )[0].minpoints;
  const maxValue = gamificationLevelsList?.gamificationlevelData?.filter(
    (item: any) => item.name === levelname,
  )[0].maxpoints;

  const range = maxValue - minValue;
  const correctedStartValue = score - minValue;
  const percentage = (correctedStartValue * 100) / range;

  const progressValue = Math.round(percentage);

  const onMouseEnter = () => {
    userProfileApi.setUserProfileState({
      email: email,
      anchorRef: usernameRef?.current,
    });
  };

  return (
    <div className={classes.CardEntityContainer}>
      <Grid container>
        <Grid
          item
          xs={12}
          lg={5}
          md={12}
          className={classes.CardTypeContainerFirst}
        >
          <div className={classes.CardTypeImageAndText}>
            <UserAvatar
              userEmail={email}
              customStyles={{
                width: '40px',
                height: '40px',
                fontSize: '16px',
                fontWeight: 'normal',
              }}
            />
            <div ref={usernameRef} onMouseEnter={onMouseEnter}>
              <Typography className={`${classes.CardTypeText} fontSemibold`}>
                <Link
                  to={`/catalog/leader-board/${email?.split('@')[0]}`}
                  state={{ email: email }}
                >
                  {' '}
                  {name}
                </Link>
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={7}
          className={`${classes.CardTypeContainer} ${classes.leaderboardCardTypeContainer}`}
        >
          <div className={classes.IconWithText}>
            <BadgeDisplayImage type={levelname} />
            <Typography component="p">{levelname}</Typography>
          </div>
          <div className={classes.ProgressBarDiv}>
            <LinearProgress variant="determinate" value={progressValue} />
            <div className={classes.ProgressBarLevel}>
              <span className="levelStart">{minValue}</span>
              <span className={classes.levelEnd}>
                {score} / {maxValue}
              </span>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CurrentLeaderBoardCard;
