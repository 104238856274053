import { EntityFilter } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';

export class ApiTypeFilter implements EntityFilter {
  constructor(readonly value: string[]) {}

  filterEntity(entity: Entity): boolean {
    // @ts-ignore
    return this.value.includes(entity?.metadata?.assetstore || 'wso2');
  }

  toQueryValue(): string[] {
    return [...this.value];
  }
}
