import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const LoremIpsumIcon = (props: any) => (
  <SvgIcon
    viewBox={'48 48'}
    style={{ transform: 'scale(3)' }}
    fill="#000000"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 3H3V21H21V3ZM5 5H19V7H5V5ZM5 9H14V11H5V9ZM17 13H5V15H17V13ZM5 17H11V19H5V17Z"
      fill="#8728FF"
    />
  </SvgIcon>
);

export default LoremIpsumIcon;
