/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { toolsProvisioningApiRef } from '../../../apis/toolsProvisioningApi';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../providers/AuthProvider';

export const useGetMyApms = (shouldcall: boolean) => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const toolsprovisioningApi = useApi(toolsProvisioningApiRef);

  const { profEmail } = useContext(AuthContext);
  const [myApms, setMyApms] = useState<string[]>();
  // const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchMyAPI = async () => {
    if (toolsprovisioningApi) {
      try {
        const response: string[] =
          await toolsprovisioningApi.getAmpKeysFromEmail(profEmail);
        setMyApms(response);
      } catch (error) {
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };

  useEffect(() => {
    if (profEmail && shouldcall) fetchMyAPI();
  }, [profEmail, shouldcall]);

  return [myApms] as const;
};
