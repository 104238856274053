import * as React from 'react';
import Chip from '@material-ui/core/Chip';
import { useStyles } from '../styles';
import { IPromptTag } from '../interfaces';
import { useOverFlowTags } from '../hooks/useOverFlowTags';

interface PromptTagProps extends IPromptTag {
  onTagClicked: Function;
}

const PromptTag: React.FC<PromptTagProps> = ({
  label,
  selected,
  onTagClicked,
}) => {
  const classes = useStyles();

  return (
    <Chip
      onClick={() => onTagClicked()}
      label={label}
      className={`${classes.promptTagWrapper} ${
        selected ? classes.tagSelected : ''
      }`}
    />
  );
};

interface PromptTagsProps {
  tags: IPromptTag[];
  onTagClicked: Function;
  classes?: any;
  onResize: Function;
}

const PromptTags: React.FC<PromptTagsProps> = ({
  tags,
  onTagClicked,
  classes,
  onResize = () => {
    return 0;
  },
}) => {
  const promptTagsclasses = useStyles();
  const [overFlowTagsCount] = useOverFlowTags(
    document.querySelector('.promptTagsContainer'),
  );

  React.useEffect(() => {
    onResize(overFlowTagsCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overFlowTagsCount]);

  return (
    <div
      className={`promptTagsContainer ${promptTagsclasses.promptTagsContainer} ${classes?.root}`}
    >
      {tags.map((tag, index) => (
        <PromptTag
          key={index}
          {...tag}
          onTagClicked={() => onTagClicked({ ...tag, selected: !tag.selected })}
        />
      ))}
    </div>
  );
};

export default PromptTags;
