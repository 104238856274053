import { createApiRef } from '@backstage/core-plugin-api';
import { PublishSubject } from '../components/notifications/subjects';
import { Observable } from 'react-use/lib/useObservable';

export interface NotificationApi {
  sendNotification: (notification: INotificationMessage) => void;
  publisher$: () => Observable<INotificationMessage>;
}

export interface INotificationMessage {
  message: string | undefined;
  disapperAfterMs?: number | undefined;
  callback?: () => void;
  severity: 'info' | 'success' | 'warning' | 'error';
}

export const notificationApiRef = createApiRef<NotificationApi>({
  id: 'cbre.devx.api.notifications',
});

export class NotificationApiImpl implements NotificationApi {
  private readonly subject = new PublishSubject<INotificationMessage>();

  sendNotification(notification: INotificationMessage) {
    this.subject.next(notification);
  }

  publisher$(): Observable<INotificationMessage> {
    return this.subject;
  }
}
