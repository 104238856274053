import { AxiosInstanceProviderApi } from './axiosInstanceApi';
import { AxiosInstance, AxiosResponse } from 'axios';
import { createApiRef } from '@backstage/core-plugin-api';

export interface RegisterApis {
  registerCustomApi(reqPayload: any): Promise<AxiosResponse>;
  getApmNumberDetails: (ampNumber: string) => Promise<AxiosResponse>;
  getApiTypes: () => Promise<AxiosResponse>;
}

export const registerApiRef = createApiRef<RegisterApis>({
  id: 'cbre.devx.api.registerapi',
});

export class RegisterApisImpl implements RegisterApis {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  getApmNumberDetails = async (ampNumber: any) => {
    const instance = await this.axiosInstancePromise;
    return instance
      .request({
        method: 'get',
        url: `service/registration/${ampNumber}`,
      })
      .then(async res => {
        return res.data;
      })
      .catch((error: any) => {
        throw new Error('Invalid APM Number');
      });
  };
  /* Register custom Api */
  registerCustomApi = async (payload: any) => {
    const instance = await this.axiosInstancePromise;
    return instance
      .request({
        method: 'post',
        url: `/service/discovery/customapi`,
        data: { ...payload },
      })
      .then(async res => {
        return res.data;
      })
      .catch(async err => {
        return err.response.data;
      });
  };
  /* Get Api Types */
  getApiTypes = async () => {
    const instance = await this.axiosInstancePromise;
    return instance
      .request({
        method: 'get',
        url: `service/discovery/customapi/types`,
      })
      .then(async res => {
        return res.data;
      })
      .catch((error: any) => {
        throw new Error('Error');
      });
  };
}
