/* istanbul ignore file */
import React, { useRef } from 'react';
import { EntityRefLink, EntityRefLinks } from '@backstage/plugin-catalog-react';
import { Chip, Box, TextField } from '@material-ui/core';
import { OverflowTooltip, TableColumn, Link } from '@backstage/core-components';
import { Entity, CompoundEntityRef } from '@backstage/catalog-model';
import OpenInNew from '@material-ui/icons/OpenInNew';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import Rating from '@material-ui/lab/Rating';
import { MAX_CHAR_COUNT_DESC } from './constant';
import { displayTime } from './dateUtils';
import { useApi } from '@backstage/core-plugin-api';
import { userProfileApiRef } from '../../apis/userProfileApi';
import { getUserName } from '../home/allAssets/util';
import { AddReviewerComponent } from '../common/AddReviewerComponent/AddReviewerComponent';

export type EntityRow = {
  entity: Entity;
  resolved: {
    name: string;
    partOfSystemRelationTitle?: string;
    partOfSystemRelations: CompoundEntityRef[];
    ownedByRelationsTitle?: string;
    ownedByRelations: CompoundEntityRef[];
  };
};

type NameColumnProps = {
  defaultKind?: string;
};

export const isExternalDocumentation = (entity: Entity) => {
  return entity?.spec?.type === 'extdocumentation';
};

const getExternalDocumentLink = (entity: Entity) => {
  return (
    entity?.metadata?.externalDocumentLink ||
    entity?.metadata?.extDocumentationLocation ||
    ''
  );
};

const renderExternalLink = (entity: Entity) => {
  return (
    <Link to={`${getExternalDocumentLink(entity)}`}>
      {entity?.metadata?.name} <OpenInNew style={{ height: '14px' }} />
    </Link>
  );
};
const goTotechDocsPage = (entity: any) => {
  window.open(
    `/docs/${entity?.metadata?.namespace}/${entity?.kind?.toLowerCase()}/${
      entity?.metadata?.name
    }`,
    '_self',
  );
};

const renderTechDocsLink = (entity: Entity) => {
  return (
    <Link to="#" onClick={() => goTotechDocsPage(entity)}>
      {entity?.metadata?.name}
    </Link>
  );
};

export function createDocumentNameColumn(
  props?: NameColumnProps,
): TableColumn<EntityRow> {
  return {
    title: 'Document',
    field: 'resolved.name',
    highlight: false,
    render: ({ entity }) =>
      isExternalDocumentation(entity)
        ? renderExternalLink(entity)
        : renderTechDocsLink(entity),
  };
}

export function createNameColumnCustomRoot(props: any): TableColumn<EntityRow> {
  return {
    title: 'Assets',
    field: 'resolved.name',
    highlight: false,
    render: ({ entity }) => (
      <Link
        to={`/${props?.rootRoute}/${
          entity?.metadata?.namespace
        }/${entity?.kind?.toLowerCase()}/${entity?.metadata?.name}`}
      >
        {entity?.metadata?.name}
      </Link>
    ),
  };
}

export function createOwnerColumnCustomRoot(
  props: any,
): TableColumn<EntityRow> {
  return {
    title: 'Owner',
    field: 'resolved.ownedByRelationsTitle',
    highlight: false,
    render: ({ entity, resolved }) => {
      const usernameRef = useRef(null);
      const userProfileApi = useApi(userProfileApiRef);

      const onMouseEnter = () => {
        let email: string | undefined = undefined;
        const ownerEmail = '' + entity?.spec?.owneremail;
        email = ownerEmail;
        if (
          ownerEmail === undefined ||
          ownerEmail === 'undefined' ||
          String(ownerEmail).trim()?.length === 0
        ) {
          const owner = getUserName(entity);
          email = `${owner}@cbre.com`;
        }
        userProfileApi.setUserProfileState({
          email,
          anchorRef: usernameRef?.current,
        });
      };

      return (
        <div onMouseEnter={onMouseEnter} ref={usernameRef}>
          <Link
            to={`/${props?.rootRoute}/${
              entity?.metadata?.namespace
            }/user/${resolved?.ownedByRelations[0]?.name?.replace(
              'user:',
              '',
            )}`}
          >
            {resolved?.ownedByRelations[0]?.name?.replace('user:', '')}
          </Link>
        </div>
      );
    },
  };
}

export function createNameColumnApi(): TableColumn<EntityRow> {
  return {
    title: 'Assets',
    field: 'resolved.name',
    highlight: false,
    render: ({ entity }) => (
      <Link
        to={`/API/${
          entity?.metadata?.namespace
        }/${entity?.kind?.toLowerCase()}/${entity?.metadata?.name}`}
      >
        {entity?.metadata?.name}
      </Link>
    ),
  };
}

export function createNameColumn(
  props?: NameColumnProps,
): TableColumn<EntityRow> {
  return {
    title: 'Assets',
    field: 'resolved.name',
    highlight: false,
    render: ({ entity }) => (
      <EntityRefLink
        entityRef={entity}
        defaultKind={entity.kind || 'Component'}
      />
    ),
  };
}

const OwnerRefLinks = ({ resolved, entity }: any) => {
  const usernameRef = useRef(null);
  const userProfileApi = useApi(userProfileApiRef);

  const onMouseEnter = () => {
    let email: string | undefined = undefined;
    const ownerEmail = '' + entity?.spec?.owneremail;
    email = ownerEmail;
    if (
      ownerEmail === undefined ||
      ownerEmail === 'undefined' ||
      String(ownerEmail).trim()?.length === 0
    ) {
      const owner = getUserName(entity);
      email = `${owner}@cbre.com`;
    }
    userProfileApi.setUserProfileState({
      email,
      anchorRef: usernameRef?.current,
    });
  };

  return (
    <div onMouseEnter={onMouseEnter} ref={usernameRef}>
      <EntityRefLinks entityRefs={resolved.ownedByRelations} defaultKind="user">
        {resolved?.ownedByRelations[0]?.name?.replace('user:', '')}
      </EntityRefLinks>
    </div>
  );
};

export function createOwnerColumn(): TableColumn<EntityRow> {
  return {
    title: 'Owner',
    field: 'resolved.ownedByRelationsTitle',
    render: ({ resolved, entity }) => (
      <OwnerRefLinks resolved={resolved} entity={entity} />
    ),
  };
}

export function createSpecTypeColumn(): TableColumn<EntityRow> {
  return {
    title: 'Type',
    field: 'entity.spec.type',
    render: ({ entity }) => {
      if (`${entity?.kind}`.toUpperCase() === 'API') {
        return entity?.metadata?.assetstore === undefined
          ? 'wso2'
          : entity?.metadata?.assetstore;
      } else {
        return entity?.spec?.type;
      }
    },
    customFilterAndSearch: (filter, rowData) => {
      if (`${rowData?.entity?.kind}`.toUpperCase() === 'API') {
        const compareValue = rowData?.entity?.metadata?.assetstore || 'wso2';
        return `${compareValue}`
          .toLowerCase()
          .includes(`${filter}`.toLowerCase());
      } else {
        const compareValue = rowData?.entity?.spec?.type;
        return `${compareValue}`
          .toLowerCase()
          .includes(`${filter}`.toLowerCase());
      }
    },
    hidden: false,
  };
}

export function createSpecLifecycleColumn(): TableColumn<EntityRow> {
  return {
    title: 'Lifecycle',
    field: 'entity.spec.lifecycle',
  };
}

export function createSpecLastCommitDateColumn(): TableColumn<EntityRow> {
  return {
    title: 'Updated',
    field: 'entity.metadata.lastModifiedTimestamp',
    render: ({ entity }) => {
      return entity.metadata.lastModifiedTimestamp
        ? displayTime(entity.metadata.lastModifiedTimestamp)
        : displayTime(entity.metadata.creationTimestamp);
    },
  };
}

export function createMetadataDescriptionColumn(): TableColumn<EntityRow> {
  return {
    title: 'Description',
    field: 'entity.metadata.description',
    render: ({ entity }) => (
      <OverflowTooltip
        title={
          entity &&
          entity.metadata &&
          entity.metadata.description &&
          entity.metadata.description.length > MAX_CHAR_COUNT_DESC
            ? `${entity.metadata?.description?.substring(
                0,
                MAX_CHAR_COUNT_DESC,
              )}...`
            : entity.metadata?.description
        }
        text={entity.metadata.description}
        placement="bottom-start"
        line={2}
      />
    ),
  };
}

export function createTagsColumn(): TableColumn<EntityRow> {
  return {
    title: 'Tags',
    field: 'entity.metadata.tags',
    cellStyle: {
      padding: '0px 16px 0px 20px',
    },
    render: ({ entity }) => (
      <>
        {entity.metadata.tags &&
          entity.metadata.tags.slice(0, 2).map(t => (
            <Chip
              key={t}
              label={t}
              size="small"
              color="primary"
              variant="outlined"
              style={{
                marginBottom: '0px',
                margin: '2px',
                height: '20px',
              }}
            />
          ))}
      </>
    ),
  };
}

export function createRatingColumn(): TableColumn<EntityRow> {
  return {
    title: 'Rating',
    field: 'entity.metadata.avgrating',
    cellStyle: {
      padding: '0px 16px 0px 20px',
    },
    render: ({ entity }) => {
      return (
        <>
          <div className="ratingIcon">
            <Rating
              name="half-rating-read"
              defaultValue={entity.metadata?.avgrating || 0}
              precision={0.5}
              size="small"
              readOnly
            />
          </div>
        </>
      );
    },
  };
}

export function createStatusColumn(): TableColumn<EntityRow> {
  return {
    title: 'Status',
    field: 'entity.metadata.devxstate',
  };
}

export const dateFormat = (user: any) => {
  const formatDate = user && user?.toString().split('T');
  const DateTime =
    formatDate && formatDate[0] + ' ' + formatDate[1].substring(0, 5);
  return DateTime;
};

export function createDateColumn(): TableColumn<EntityRow> {
  return {
    title: 'Created Date',
    field: 'entity.metadata.creationTimestamp',
    defaultSort: 'asc',
    render: ({ entity }) => {
      return dateFormat(entity.metadata.creationTimestamp);
    },
  };
}

export function createCapabilityNameColumn(): TableColumn<EntityRow> {
  return {
    title: 'Capability Name',
    field: 'entity.metadata.name',
    defaultSort: 'asc',
    customSort: (data1, data2, type) => {
      if (
        data1?.tableData?.childRows?.length > 0 &&
        data1.tableData?.path?.length == 1
      ) {
        return -1;
      } else if (
        data2?.tableData?.path?.length > 1 &&
        (data1?.tableData?.childRows == null ||
          data1?.tableData?.childRows?.length >
            data2?.tableData?.childRows?.length)
      ) {
        return data1?.entity?.metadata?.name?.localeCompare(
          data2?.entity?.metadata?.name,
          undefined,
          { numeric: true },
        );
      } else {
        if (
          data1?.tableData?.childRows == null &&
          data1?.tableData?.path?.length == 1 &&
          data2?.tableData?.childRows == null &&
          data2?.tableData?.path?.length == 1
        ) {
          return data1?.entity?.metadata?.name?.localeCompare(
            data2?.entity?.metadata?.name,
            undefined,
            { numeric: true },
          );
        }
        return 1;
      }
    },
    render: ({ tableData, entity }) => {
      return (
        <Box
          sx={{
            display: 'flex',
            marginLeft: `${
              (tableData?.path?.length > 1 ? tableData?.path?.length - 2 : 0) *
              16
            }px`,
          }}
        >
          {tableData?.path?.length > 1 && (
            <SubdirectoryArrowRightIcon
              style={{
                fontSize: '20px',
                fill: '#d1d1d1',
              }}
            />
          )}
          <Link
            to={`/taxonomy/view/${entity?.metadata?.kind || 'Group'}/${
              entity?.metadata?.namespace
            }/${entity?.metadata?.name}`}
          >
            {entity?.metadata?.name || ''}
          </Link>
        </Box>
      );
    },
  };
}

export function createCapabilityDescriptionColumn(): TableColumn<EntityRow> {
  return {
    title: 'Description',
    field: 'entity.metadata.description',
    defaultSort: 'asc',
    render: ({ entity }) => (
      <OverflowTooltip
        title={
          entity &&
          entity.metadata &&
          entity.metadata.description &&
          entity.metadata.description.length > MAX_CHAR_COUNT_DESC
            ? `${entity.metadata?.description?.substring(
                0,
                MAX_CHAR_COUNT_DESC,
              )}...`
            : entity.metadata?.description
        }
        text={entity.metadata.description}
        placement="bottom-start"
        line={2}
      />
    ),
  };
}

export function createCapabilityOwnerColumn(): TableColumn<EntityRow> {
  return {
    title: 'Capability Owner',
    field: 'entity.metadata.capFormData.owner',
    render: ({ entity }) => {
      if (
        entity?.metadata?.capFormData?.owner &&
        entity?.metadata?.capFormData?.owner?.length > 0
      ) {
        let ownerData = [];
        try {
          ownerData = JSON.parse(entity?.metadata?.capFormData?.owner);
        } catch (err) {
          ownerData = [{ value: entity?.metadata?.capFormData?.owner || '' }];
        }
        return ownerData ? (
          Array.isArray(ownerData) &&
            ownerData.map((item: any, index: number) => {
              if (index < 2)
                return (
                  <p>
                    <EntityRefLink
                      entityRef={{
                        kind: 'user',
                        namespace: 'default',
                        name: item?.value?.split('@')[0],
                      }}
                      defaultKind={entity.kind || 'User'}
                    >
                      {item?.value}
                    </EntityRefLink>
                  </p>
                );

              return null;
            })
        ) : (
          <></>
        );
      }
      return '';
    },
  };
}

export function createCapabilityParentColumn(): TableColumn<EntityRow> {
  return {
    title: 'Parent',
    field: 'entity.spec.parent',
    render: ({ entity }) => {
      const relations = entity?.relations;
      if (relations && relations?.length > 0) {
        const rel0 = relations[0];
        if (rel0 && rel0.type === 'childOf' && rel0.target) {
          return (
            <Link
              to={`/taxonomy/view/${rel0.target?.kind || 'Group'}/${
                rel0?.target?.namespace
              }/${rel0?.target?.name}`}
            >
              {rel0?.target?.name || ''}
            </Link>
          );
        }
      }
      return <></>;
    },
  };
}

export function createAssignedTo(reviewerList: any): TableColumn<EntityRow> {
  return {
    title: 'Assigned To',
    render: ({ entity }) => {
      return (
        <AddReviewerComponent entity={entity} reviewerList={reviewerList} />
      );
    },
  };
}
