/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback } from 'react';
import {
  useApi,
  identityApiRef,
  microsoftAuthApiRef,
} from '@backstage/core-plugin-api';

export const useUserProfile = () => {
  const [headerToken, setHeaderToken] = useState<any>('');
  const [accessToken, setAccessToken] = useState<any>('');
  const [profileName, setProfName] = useState<any>('');
  const [profEmail, setProfEmail] = useState<any>('');
  const [profile, setProfile] = useState<any>();
  const [displayName, setDisplayName] = useState<any>();
  const [userId, setUserId] = useState<any>();

  const identityApi = useApi(identityApiRef);
  const msAuthApi = useApi(microsoftAuthApiRef);

  const fetchProfile = async () => {
    const userProf = await identityApi.getProfileInfo();
    const disName = userProf.displayName;
    const Email = userProf.email;
    setProfName(disName);
    setProfEmail(Email);
    return userProf;
  };

  const fetchUser = async () => {
    const userIx = await identityApi.getBackstageIdentity();
    const entityRef = userIx.userEntityRef;
    return entityRef;
  };

  const fetchProfileInfo = async () => {
    const userProf = await identityApi.getProfileInfo(); // TODO: investigate the app and find the need for this
    return userProf;
  };

  const populateUserProfile = useCallback(async () => {
    await fetchProfile();
    const userId = await fetchUser();
    setUserId(userId);

    const profile = await fetchProfileInfo();
    setProfile(profile);

    const displayName = profileName ?? userId;
    setDisplayName(displayName);

    const tokenResp = await identityApi.getCredentials();
    setHeaderToken(tokenResp.token);

    const accessTokenResp = await msAuthApi.getAccessToken();
    setAccessToken(accessTokenResp);
  }, []);

  useEffect(() => {
    populateUserProfile();
  }, [profileName, identityApi]);

  return {
    headerToken,
    profile,
    displayName,
    accessToken,
    profEmail,
    profileName,
    userId,
    refreshProfileAndAuth: populateUserProfile,
  };
};
