import React from 'react';
import {
  TableColumn,
  Table,
  WarningPanel,
  CodeSnippet,
  TableProps,
} from '@backstage/core-components';
import {
  humanizeEntityRef,
  getEntityRelations,
  useEntityList,
  useStarredEntities,
} from '@backstage/plugin-catalog-react';
import * as columnFactories from '../../utils/devXTableColumns';
import { RELATION_OWNED_BY, RELATION_PART_OF } from '@backstage/catalog-model';
import ContentCopyIcon from '@material-ui/icons/ContentCopy';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import { STARRED, BOOKMARK } from '../../utils/constant';
import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    themeColor: {
      '& tr': {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.background.default + '!important',
        '& td ': {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: theme.palette.background.default + '!important',
        },
        '& th': {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: theme.palette.background.default + '!important',
        },
      },
      '& div::-webkit-scrollbar-thumb': {
        border: `4px solid ${theme.palette.text.tertiary}`,
      },
    },
  }),
);
const columns: TableColumn<columnFactories.EntityRow>[] = [
  columnFactories.createDocumentNameColumn(),
  columnFactories.createOwnerColumn(),
  columnFactories.createSpecTypeColumn(),
];

export const CustomDocsTable = () => {
  const classes = useStyles();
  const { isStarredEntity, toggleStarredEntity } = useStarredEntities();
  const { loading, error, entities, filters } = useEntityList();

  const defaultActions: TableProps<columnFactories.EntityRow>['actions'] = [
    ({ entity }) => {
      const configData = useApi(configApiRef);
      const API_BASE_URL = configData?.getString('backend.baseUrl');

      const copyUrlToClipboard = () => {
        const data = `${API_BASE_URL}/docs/${
          entity?.metadata?.namespace
        }/${entity?.kind?.toLowerCase()}/${entity?.metadata?.name}`;
        navigator?.clipboard?.writeText(data);
      };

      return {
        icon: () => <ContentCopyIcon />,
        tooltip: 'Click to copy documentation link to clipboard',
        onClick: copyUrlToClipboard,
      };
    },

    ({ entity }) => {
      const isStarred = isStarredEntity(entity);
      return {
        cellStyle: { paddingLeft: '1em' },
        icon: () =>
          isStarred ? <BookmarkIcon /> : <BookmarkBorderOutlinedIcon />,
        tooltip: 'Bookmark',
        onClick: () => toggleStarredEntity(entity),
      };
    },
  ];

  const filteredEntities = entities.filter(
    entity => entity?.spec?.type !== 'extdocumentation',
  );

  const rows = filteredEntities.map(entity => {
    const partOfSystemRelations = getEntityRelations(entity, RELATION_PART_OF, {
      kind: 'system',
    });
    const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);

    return {
      entity,
      resolved: {
        name: humanizeEntityRef(entity, {
          defaultKind: 'component',
        }),
        ownedByRelationsTitle: ownedByRelations
          .map(r => humanizeEntityRef(r, { defaultKind: 'group' }))
          .join(', '),
        ownedByRelations,
        partOfSystemRelationTitle: partOfSystemRelations
          .map(r =>
            humanizeEntityRef(r, {
              defaultKind: 'system',
            }),
          )
          .join(', '),
        partOfSystemRelations,
      },
    };
  });

  if (error) {
    return (
      <div>
        <WarningPanel severity="error" title="Could not fetch tech docs.">
          <CodeSnippet language="text" text={error.toString()} />
        </WarningPanel>
      </div>
    );
  }
  const titlePreamble = filters.user?.value ?? 'all';

  return (
    <div className={`mystuff-table ${classes.themeColor}`}>
      <Table
        isLoading={loading}
        columns={columns}
        options={{
          paging: true,
          pageSize: 10,
          search: true,
          actionsColumnIndex: -1,
          loadingType: 'linear',
          showEmptyDataSourceMessage: !loading,
          padding: 'dense',
          pageSizeOptions: [10],
        }}
        data={rows}
        actions={defaultActions}
        title={`${
          titlePreamble.toLowerCase() === STARRED ? BOOKMARK : titlePreamble
        } (${rows.length})`}
      />
    </div>
  );
};
