import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const VisualRegression = () => (
  <SvgIcon height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 3V17H11.583C11.5284 16.6748 11.5 16.3407 11.5 16C11.5 15.6593 11.5284 15.3252 11.583 15H8.84V11H12.17V13.2421C12.6382 12.3391 13.3291 11.5702 14.17 11.0081V11H14.1822C15.1325 10.3682 16.2733 10 17.5 10C18.2013 10 18.8744 10.1203 19.5 10.3414V3H1.5ZM6.83 15H3.5V5H6.83V15ZM17.5 9H8.83V5H17.5V9Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8018 12.205L18.92 11.3232L18.0356 12.2076C17.2459 11.8273 16.5714 12.0407 16.2195 12.2081L15.3297 11.3235L14.4482 12.205L15.2916 13.0484C14.9606 13.6113 14.9814 14.14 15.0265 14.4347C14.88 14.5936 14.7428 14.7657 14.6228 14.9512L13.4343 14.2642L12.8092 15.3457L14.1254 16.1058C14.0947 16.2322 14.0705 16.3603 14.0511 16.49H12.5V17.74H14.0511C14.0705 17.8697 14.0947 17.9978 14.1254 18.1242L12.8092 18.8843L13.4339 19.9651L14.6222 19.2883C15.1867 20.166 16.0937 20.745 17.125 20.745C18.1561 20.745 19.0629 20.1663 19.6274 19.2889L20.8157 19.9758L21.4408 18.8943L20.1246 18.1342C20.1553 18.0078 20.1795 17.8797 20.1989 17.75H21.75V16.495H20.1989C20.1795 16.3653 20.1553 16.2372 20.1246 16.1108L21.4408 15.3507L20.8159 14.2696L19.6283 14.951C19.5081 14.7623 19.3706 14.5899 19.2235 14.4337C19.2686 14.136 19.2894 13.6111 18.9584 13.0484L19.8018 12.205ZM17.125 13.495C16.8873 13.495 16.6573 13.5271 16.4367 13.5861C16.5943 13.3787 16.8433 13.245 17.125 13.245C17.4067 13.245 17.6557 13.3787 17.8133 13.5861C17.5927 13.5271 17.3627 13.495 17.125 13.495ZM15.25 17.12C15.25 15.7815 16.1137 14.745 17.125 14.745C18.1363 14.745 19 15.7815 19 17.12C19 18.4585 18.1363 19.495 17.125 19.495C16.1137 19.495 15.25 18.4585 15.25 17.12ZM17.75 15.495H16.5V18.745H17.75V15.495Z"
    />
  </SvgIcon>
);

export default VisualRegression;
