import type { Transformer } from './transformer';

export const rewriteDocLinks = (): Transformer => {
  return dom => {
    const updateDom = <T extends Element>(
      list: Array<T>,
      attributeName: string,
    ): void => {
      Array.from(list)
        .filter(elem => elem.hasAttribute(attributeName))
        .forEach((elem: T) => {
          const elemAttribute = elem.getAttribute(attributeName);
          if (elemAttribute) {
            // if link is external, add target to open in a new window or tab
            if (elemAttribute.match(/^https?:\/\//i)) {
              elem.setAttribute('target', '_blank');
            }

            try {
              const normalizedWindowLocation = normalizeUrl(
                window.location.href,
              );
              let generatedUrl = new URL(
                elemAttribute,
                normalizedWindowLocation,
              ).toString();
              if (generatedUrl.includes('#')) {
                generatedUrl = generatedUrl.replace(/\/([^\/]*)$/, '$1');
              }
              elem.setAttribute(attributeName, generatedUrl);
            } catch (_e) {
              // Non-parseable links should be re-written as plain text.
              elem.replaceWith(elem.textContent || elemAttribute);
            }
          }
        });
    };

    updateDom(Array.from(dom.getElementsByTagName('a')), 'href');

    return dom;
  };
};

function normalizeUrl(input: string): string {
  const url = new URL(input);

  if (!url.pathname.endsWith('/') && !url.pathname.endsWith('.html')) {
    url.pathname += '/';
  }

  return url.toString();
}
