import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';

type InfoBadgesProps = {
  label: string;
  value: string;
  valueBgColorClass?: string;
  onClick?: () => void;
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    borderRadius: '4px',
    // border: `1px solid ${theme.palette.infoExchange.badge.border}`
  },
  labelStyles: {
    background: theme.palette.infoExchange.badge.labelBg,
    padding: '0px 4px 0px 8px',
    borderRadius: '16px 0px 0px 16px',
    color: theme.palette.infoExchange.badge.text,
    borderColor: `${theme.palette.infoExchange.badge.border}`,
    borderStyle: 'solid',
    borderWidth: '1px 0px 1px 1px'
  },
  valueStyles: {
    background: theme.palette.infoExchange.badge.valueBg,
    padding: '0px 8px',
    borderRadius: '0px 16px 16px 0px',
    color: theme.palette.infoExchange.badge.text,
    borderColor: `${theme.palette.infoExchange.badge.border}`,
    borderStyle: 'solid',
    borderWidth: '1px 1px 1px 0px'
  },
}));

const InfoBadge = ({
  label,
  value,
  valueBgColorClass,
  onClick = () => {},
}: InfoBadgesProps) => {
  const classes = useStyles();

  const valueStyleArr = [classes.valueStyles];
  if (valueBgColorClass) {
    valueStyleArr.push(valueBgColorClass);
  }

  return (
    <div className={classes.root} onClick={onClick}>
      <section className={classes.labelStyles}>
        <Typography variant="caption">{label}</Typography>
      </section>
      <section className={valueStyleArr.join(' ')}>
        <Typography variant="caption">{value}</Typography>
      </section>
    </div>
  );
};
export default InfoBadge;
