import React, { useState, useEffect, useContext } from 'react';
import { Grid, LinearProgress } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { HelpComponent } from '../utils/helpComponent';
import HELP_URL from '../utils/helpLinkConstant';
import { PromotionContext } from '../../providers/PromotionDataProvider';
import BackButton from '../utils/backButton';
import {
  Page,
  Header,
  WarningPanel,
  CodeSnippet,
} from '@backstage/core-components';
import BreadcrumbsNav from '../common/BreadcrumbsNav/BreadcrumbsNav';
import { useCustomStyles } from './leaderBoardStyles';
import LeaderBoardCard from './Grid/leaderBoardCard';
import CategoryCard from './Grid/categoryCard';
import {
  GamificationApi,
  gamificationApiRef,
} from '../../apis/gamificationApis';
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';

export const LeaderBoardDetailsPage = () => {
  const { state } = useLocation();
  const classes = useCustomStyles();

  const [Data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filteredRewardDetails, setFilteredRewardDetails] = useState([]);
  const gamificationApi: GamificationApi = useApi(gamificationApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const promotionContextData = useContext(PromotionContext);
  // @ts-expect-error("")
  const userEmail = state && state?.email;

  useEffect(() => {
    gamificationApi
      .getRewardsLevels()
      .then((res: any) => {
        promotionContextData.setGamificationlevelData(res);
      })
      .catch((error: any) => {
        notificationApi.sendNotification({
          message: `Error occurred - ${error?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoading(true);
    promotionContextData.setToggleHelpButton(false);
    gamificationApi
      .getLeaderBoardByUser(userEmail)
      .then((res: any) => {
        setData(res);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gamificationApi]);

  useEffect(() => {
    if (Data.rewarddetails && Data.rewarddetails.length) {
      const filteredData = Data.rewarddetails.sort(
        (a: any, b: any) => b.points - a.points,
      );
      setFilteredRewardDetails(filteredData);
    }
  }, [Data.rewarddetails]);

  if (isLoading || (Data && Data.length === 0)) {
    return (
      <Page themeId="home">
        <Header
          title="Leader Board Details"
          pageTitleOverride="leader board Details"
          subtitle={<BreadcrumbsNav lastRoutes={[]} skipLast={false} />}
        />
        <div className={classes.levelAndBadgesContainer}>
          <LinearProgress />
        </div>
      </Page>
    );
  }

  if (!isLoading && Data && Data.length === 0) {
    return (
      <div>
        <WarningPanel
          severity="error"
          title="Could not fetch leader board list"
        >
          <CodeSnippet language="text" text="Error" />
        </WarningPanel>
      </div>
    );
  }

  return (
    <Page themeId="home">
      <Header
        title="Leader Board Details"
        pageTitleOverride="leader board Details"
        subtitle={<BreadcrumbsNav lastRoutes={[]} skipLast={false} />}
      />
      <div className={classes.levelAndBadgesContainer}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <BackButton />
            <HelpComponent helpUrl={HELP_URL.leaderBoardDetails} />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.marginTop24}
          >
            <LeaderBoardCard data={Data} selectedBtn="cumulative" />
          </Grid>
        </Grid>
        <Grid container className={classes.marginTop24}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <h2 className={classes.categoryCardMargin20}>
              Points Earned Category Wise
            </h2>
          </Grid>

          {filteredRewardDetails.map((item: any, index: number) => (
            <Grid item xs={12} md={4}>
              {item?.description === 'Published Assets' ||
              item?.description === 'Approved Assets' ? (
                <Link
                  to={`/catalog/default/user/${
                    userEmail?.split('@')[0]
                  }/user-assets`}
                  key={`category${index}`}
                  state={{ assetType: item?.description }}
                >
                  <CategoryCard data={item} key={`category${index}`} />
                </Link>
              ) : (
                <CategoryCard data={item} key={`category${index}`} />
              )}
            </Grid>
          ))}
        </Grid>
      </div>
    </Page>
  );
};
