import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const NotifyInactiveIcon = ({ color }: { color?: string }) => (
  <SvgIcon viewBox="0 0 24 24">
    <path
      d="M12.5 21.75C13.6 21.75 14.5 20.85 14.5 19.75H10.5C10.5 20.85 11.4 21.75 12.5 21.75ZM18.5 15.75V10.75C18.5 7.68 16.87 5.11 14 4.43V3.75C14 2.92 13.33 2.25 12.5 2.25C11.67 2.25 11 2.92 11 3.75V4.43C8.14 5.11 6.5 7.67 6.5 10.75V15.75L4.5 17.75V18.75H20.5V17.75L18.5 15.75ZM16.5 16.75H8.5V10.75C8.5 8.27 10.01 6.25 12.5 6.25C14.99 6.25 16.5 8.27 16.5 10.75V16.75Z"
      fill={color}
    />
  </SvgIcon>
);

export default NotifyInactiveIcon;
