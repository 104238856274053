import React, { useState, createContext, useRef } from 'react';

const initialState = {};
export const GuidedTourContext = createContext<any>(initialState);

export const GuidedTourProvider = (props: any) => {
  const [startTour, setStartTour] = useState(false);
  const [tourConfig, setTourConfig] = useState(null);

  return (
    <GuidedTourContext.Provider
      value={{
        startTour,
        setStartTour,
        tourConfig,
        setTourConfig,
      }}
    >
      {props.children}
    </GuidedTourContext.Provider>
  );
};
