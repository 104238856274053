import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const QRCodeIcon = (props: any) => (
  <SvgIcon viewBox={'48 48'} style={{ transform: 'scale(3)' }} {...props}>
    <path
      d="M3.25 9.25H9.25V3.25H3.25V9.25ZM4.75 4.75H7.75V7.75H4.75V4.75Z"
      fill="black"
    />
    <path
      d="M3.25 16.75H9.25V10.75H3.25V16.75ZM4.75 12.25H7.75V15.25H4.75V12.25Z"
      fill="black"
    />
    <path
      d="M10.75 3.25V9.25H16.75V3.25H10.75ZM15.25 7.75H12.25V4.75H15.25V7.75Z"
      fill="black"
    />
    <path d="M16.75 15.25H15.25V16.75H16.75V15.25Z" fill="black" />
    <path d="M12.25 10.75H10.75V12.25H12.25V10.75Z" fill="black" />
    <path d="M13.75 12.25H12.25V13.75H13.75V12.25Z" fill="black" />
    <path d="M12.25 13.75H10.75V15.25H12.25V13.75Z" fill="black" />
    <path d="M13.75 15.25H12.25V16.75H13.75V15.25Z" fill="black" />
    <path d="M15.25 13.75H13.75V15.25H15.25V13.75Z" fill="black" />
    <path d="M15.25 10.75H13.75V12.25H15.25V10.75Z" fill="black" />
    <path d="M16.75 12.25H15.25V13.75H16.75V12.25Z" fill="black" />
    <path
      d="M18 13C15.24 13 13 15.24 13 18C13 20.76 15.24 23 18 23C20.76 23 23 20.76 23 18C23 15.24 20.76 13 18 13ZM20.5 18.5H18.5V20.5H17.5V18.5H15.5V17.5H17.5V15.5H18.5V17.5H20.5V18.5Z"
      fill="#45B058"
    />
  </SvgIcon>
);

export default QRCodeIcon;
