import { PromptStoreProvider } from './providers/PromptStoreProvider';
import React from 'react';
import PSRoutes from './PSRoutes';

interface PromptStoreProps {
  // Add any props you need here
}

const PromptStore: React.FC<PromptStoreProps> = () => {
  return (
    <PromptStoreProvider>
      <PSRoutes />
    </PromptStoreProvider>
  );
};

export default PromptStore;
