import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import { Content, Progress } from '@backstage/core-components';
import { useParams, useNavigate } from 'react-router-dom';
import { useApi } from '@backstage/core-plugin-api';
import BackButton from '../../../utils/backButton';
import { HelpComponent } from '../../../utils/helpComponent';
import HELP_URL from '../../../utils/helpLinkConstant';
import { adminApiRef } from '../../../../apis/adminApi';
import { COST_AVOIDANCE_CONSTANTS } from '../constants';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';

// @ts-ignore
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    complexity: {
      background: theme.palette.background.paper,
      padding: '20px',
    },
    updateComplexity: {
      display: 'flex',
      alignItems: 'center',
    },
    assetName: {
      // @ts-expect-error
      fontWeight: '600',
    },
    complexitySection: {
      marginTop: '20px',
    },
    complexityForm: {
      marginBottom: '20px',
    },
    complexityBtns: {
      marginTop: '30px',
      display: 'flex',
      justifyContent: 'flex-end',
      ['@media (max-width:920px) and (min-width: 280px)']: {
        flexDirection: 'column',
        marginTop: '0',
      },
    },
    cancelBtn: {
      marginRight: '10px',
      ['@media (max-width:920px) and (min-width: 280px)']: {
        width: '100%',
      },
    },
    saveBtn: {
      ['@media (max-width:920px) and (min-width: 280px)']: {
        width: '100%',
        marginTop: '10px',
      },
    },
  }),
);

const EditAssetComplexity = () => {
  const classes = useStyles();
  const [complexity, setComplexity] = useState<string | undefined>('');
  const [reason, setReason] = useState<string | undefined>('');
  const [assetName, setAssetName] = useState<string | undefined>('');
  const [validationState, setValidationState] = useState<any>({
    complexityError: false,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const { id } = useParams();
  const adminApi = useApi(adminApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const navigate = useNavigate();

  const getComplexityValue = (complexity: string | undefined) => {
    if (!complexity) {
      return undefined;
    } else {
      switch (`${complexity}`.toLowerCase()) {
        case 'low':
          return 'Low';
        case 'medium':
          return 'Medium';
        case 'high':
          return 'High';
        default:
          return undefined;
      }
    }
  };

  useEffect(() => {
    if (id && adminApi) {
      setLoading(true);
      adminApi
        .getComplexityCatalogInfoById(id)
        .then((data: any) => {
          setComplexity(getComplexityValue(data?.complexity || ''));
          setReason(data?.complexityjustification || '');
          setAssetName(data?.assetname);
          setLoading(false);
        })
        .catch((err: any) => {
          notificationApi.sendNotification({
            message: err.message,
            disapperAfterMs: 2500,
            severity: 'error',
          });
          setLoading(false);
        });
    }
  }, [id]);

  const onClickCancelButton = () => {
    navigate('/admin/cost-avoidance/complexity');
  };

  const onClickSaveInternal = () => {
    if (!complexity) {
      setValidationState({ complexityError: true });
      return;
    }

    const data = {
      assetname: assetName,
      complexity: `${complexity}`?.toLowerCase(),
      reason,
    };

    setLoading(true);
    adminApi
      .updateAssetComplexityInCatalogInfo(data)
      .then((data: any) => {
        setLoading(false);
        if (
          `${data?.status}`.toLowerCase() ===
          COST_AVOIDANCE_CONSTANTS.COMPLEXITY_UPDATE_STATUS
        ) {
          notificationApi.sendNotification({
            message: COST_AVOIDANCE_CONSTANTS.COMPLEXITY_UPDATE_SUCCESS_MESSAGE,
            disapperAfterMs: 2500,
            severity: 'success',
            callback: onClickCancelButton,
          });
        }
      })
      .catch(err => {
        setLoading(false);
        notificationApi.sendNotification({
          message: err.message,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  const limitChars = (value: any) => {
    let newValue = value || '';
    if (newValue && newValue?.length >= 250) {
      newValue = `${newValue}`.substring(0, 250);
    }
    return newValue;
  };

  return (
    <div>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <BackButton />
        <HelpComponent helpUrl={HELP_URL.COST_AVOIDANCE_COMPLEXITY_UPDATE} />
      </Grid>
      {loading && <Progress />}
      <Content>
        <Grid container>
          <Grid
            className={classes.complexity}
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
          >
            <div>
              <section className={classes.updateComplexity}>
                <h2 style={{ flex: 1 }}>Update Complexity</h2>
              </section>
              <section className={classes.assetName}>{assetName}</section>
              <section className={classes.complexitySection}>
                <div className={classes.complexityForm}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    required
                  >
                    <InputLabel id="complexity-select-label">
                      Complexity
                    </InputLabel>
                    <Select
                      error={validationState?.complexityError || false}
                      labelId="complexity-select-label"
                      value={complexity}
                      label="Complexity"
                      data-testid="complexity-select"
                      fullWidth
                      onChange={(event: any) => {
                        setComplexity(event?.target?.value);
                        setValidationState({ complexityError: false });
                      }}
                    >
                      <MenuItem value="Low">Low</MenuItem>
                      <MenuItem value="Medium">Medium</MenuItem>
                      <MenuItem value="High">High</MenuItem>
                    </Select>
                    {validationState && validationState?.complexityError && (
                      <FormHelperText error>
                        {COST_AVOIDANCE_CONSTANTS.COMPLEXITY_FIELD_HELPER_TEXT}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div className={classes.complexityForm}>
                  <TextField
                    label="Reason"
                    fullWidth
                    data-testid="reason-input"
                    variant="outlined"
                    size="small"
                    multiline
                    minRows={4}
                    value={reason}
                    helperText="Maximum 250 characters allowed"
                    onChange={(event: any) => {
                      setReason(limitChars(event?.target?.value));
                    }}
                  ></TextField>
                </div>
              </section>
              <section className={classes.complexityBtns}>
                <Button
                  variant="outlined"
                  className={classes.cancelBtn}
                  onClick={onClickCancelButton}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  data-testid="save-btn"
                  className={classes.saveBtn}
                  onClick={onClickSaveInternal}
                  disabled={loading || !assetName}
                >
                  Save
                </Button>
              </section>
            </div>
          </Grid>
        </Grid>
      </Content>
    </div>
  );
};

export default EditAssetComplexity;
