import React, { useContext, useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { customTypes } from '../../extensions';
import { SecretsContextPoc } from '../../SecretsContext';

/**
 * APMField
 */
export const ProjectRepoUrlForClone = (
  props: customTypes.FieldExtensionComponentProps<string>,
) => {
  const {
    onChange,
    required,
    schema: {
      title = 'Repo Url For Clone',
      description = 'Project Repo Location for clone the POC',
    },
    rawErrors,
    formData,
    uiSchema: { 'ui:autofocus': autoFocus },
    idSchema,
    placeholder,
  } = props;

  const SecretsContext = useContext(SecretsContextPoc);
  if (!SecretsContext?.secrets?.checkHaveARepo) return <></>;

  return (
    <TextField
      id={idSchema?.$id}
      label={title}
      placeholder={placeholder}
      helperText={description}
      FormHelperTextProps={{
        error: false,
      }}
      required={required}
      value={formData ?? ''}
      onChange={({ target: { value } }) => onChange(value)}
      margin="normal"
      error={rawErrors?.length > 0 && !formData}
      inputProps={{ autoFocus }}
    />
  );
};
