/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Typography,
  LinearProgress,
  Theme,
  useTheme,
} from '@material-ui/core';
import BackButton from '../../../utils/backButton';
import { HelpComponent } from '../../../utils/helpComponent';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { PromptContext } from '../providers/PromptProvider';
import { useApi } from '@backstage/core-plugin-api';
import { promptApiRef } from '../../../../apis/promptManagementApi';
import { notificationApiRef } from '../../../../apis/notificationApi';
import { AuthContext } from '../../../../providers/AuthProvider';
import PromptCard from './PromptCard';
import { useStyles } from '../styles';
import { IPromptData } from '../interfaces';
import SearchBar from '../common/SearchBar';
import Scopes from '../common/Scopes';
import SortBy from '../common/SortBy';
import { DateTime } from 'luxon';
import SaveNewPromptPopup from '../SimilarPrompts/SaveNewPromptPopup';
import HELP_URL from '../../../utils/helpLinkConstant';
import { generateId } from '../../../utils/Utils';
import TryPrompt from '../../../PromptStore/components/TryItOut';
import TryItOut from './TryItOut';
import ButtonDropdown from '../common/ButtonDropdown';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import TryAiIcon from '../icons/TryAiIcon';
import { useApprovalOperations } from '../hooks/useApprovalOperations';
import CommentsPopup, { CommentPopupProps } from './CommentsPopup';

const ViewPromptsPage = () => {
  const { domainId, subDomainId, personaId } = useParams();

  const [details, setDetails] = useState<any>({
    domain: {
      id: '',
      name: '',
    },
    subDomain: {
      id: '',
      name: '',
    },
    personas: [],
  });
  const theme = useTheme<Theme>();
  const navigate = useNavigate();
  const promptApi = useApi(promptApiRef);
  const notificationApi = useApi(notificationApiRef);
  const classes = useStyles();
  const {
    updateAll,
    prompts,
    setPrompts,
    setEditModeData,
    domains,
    fetchData,
  } = useContext(PromptContext);
  const authContext = useContext(AuthContext);

  const [chat, setChat] = useState({
    props: {
      id: null,
      prompts: null,
      domainId: null,
      subomainId: null,
      personaId: null,
    },
    open: false,
  });

  const setChatOpen = (props: any) => {
    console.log(props);
    setChat({
      open: true,
      props: {
        id: generateId(),
        ...props,
      },
    });
  };

  useEffect(() => {
    if (!prompts && authContext.isAdmin !== null) {
      fetchData(authContext.isAdmin, authContext.profEmail);
    } else {
      const domain = domains.find((d: any) => d.id === domainId);
      const subDomain = domain?.subproducts?.find(
        (sd: any) => sd.id === subDomainId,
      );
      const personas = subDomain?.personas?.filter(
        (p: any) => p.id === personaId,
      );

      if (!domain && !subDomain && !personas) {
        navigate('/admin/manage-prompts');
      } else {
        setDetails({
          domain,
          subDomain,
          personas,
        });
      }
    }
  }, [prompts]);

  const emptyPromptState = {
    open: false,
    dialogProps: {},
    onClose: () => {},
    onConfirm: () => {},
  };

  const [filteredPrompts, setFilteredPrompts] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [sortByAction, setSortByAction] = useState('lastModified');
  const [deletePromptPopupProps, setDeletePromptPopupProps] = useState({
    ...emptyPromptState,
  });

  const getTimeMillis = (dateString: string) => {
    return DateTime.fromFormat(dateString, 'dd-MM-yyyy HH:mm:ss').toMillis();
  };

  useEffect(() => {
    let localscopePrompts = [];

    if (prompts) {
      const allPromptsPersonas = prompts.reduce(
        (accumulator: any, prompt: any) => [...accumulator, ...prompt.persona],
        [],
      );

      localscopePrompts = prompts.filter((promptObj: any) =>
        allPromptsPersonas.some((value: any) =>
          promptObj?.persona?.includes(value),
        ),
      );

      if (domainId && subDomainId && personaId) {
        localscopePrompts = localscopePrompts.filter((promptObj: any) =>
          promptObj?.persona?.includes(personaId),
        );
      } else if (domainId && subDomainId) {
        localscopePrompts = localscopePrompts.filter(
          (promptObj: any) => promptObj?.subproduct === subDomainId,
        );
      } else if (domainId) {
        localscopePrompts = localscopePrompts.filter(
          (promptObj: any) => promptObj?.product === domainId,
        );
      }

      /* Later implement this using the Sort By Action */
      localscopePrompts = localscopePrompts.sort(
        (a: any, b: any) =>
          getTimeMillis(b.createdat) - getTimeMillis(a.createdat),
      );

      setFilteredPrompts(
        localscopePrompts.filter(
          (p: any) =>
            p.promptkey.toLowerCase().includes(searchText.toLowerCase()) ||
            p.promptdetail.toLowerCase().includes(searchText.toLowerCase()),
        ),
      );
    }
  }, [prompts, domainId, subDomainId, personaId, searchText, sortByAction]);

  const handleEditPrompt = (promptData: IPromptData) => {
    navigate(`edit-prompt/${promptData.id}`);
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleDeletePrompt = async (
    promptData: IPromptData,
    __personaId: string,
  ) => {
    setIsLoading(true);

    const newPromptData = {
      ...promptData,
      persona: promptData.persona.filter(
        (persona: string) => persona !== __personaId,
      ),
    };

    const response = await promptApi.updatePrompt(newPromptData, promptData.id);
    //  const response = await promptApi.deletePrompt(promptData, promptId);
    if (response) {
      setIsLoading(false);
      const status = response.status;
      if (status === true) {
        notificationApi.sendNotification({
          message: 'Prompt Deleted Successfully',
          disapperAfterMs: 2500,
          severity: 'success',
        });
        setPrompts(
          prompts.map((prompt: any) =>
            prompt.id === response.prompt.id ? response.prompt : prompt,
          ),
        );
        //   setPrompts(prompts.filter((prompt: any) => prompt.id !== promptId));
      }
    } else {
      setIsLoading(false);
    }
  };

  const handleCloseDeletePromptPopup = () => {
    setDeletePromptPopupProps(prevState => {
      return {
        ...prevState,
        open: false,
      };
    });
  };

  const handleDeletePopup = (promptData: IPromptData) => {
    const __domain = details.domain;
    const __subDomain = __domain?.subproducts?.find(
      (subDomain: any) => subDomain.id === promptData.subproduct,
    );
    const __persona = __subDomain?.personas?.find(
      (subDomain: any) => subDomain.id === promptData.persona[0],
    );
    setDeletePromptPopupProps({
      open: true,
      dialogProps: {
        firstText: 'Prompt to be deleted',
        secondText: 'Delete from',
        headerText: 'Are you sure you want to delete?',
        buttonText: 'Confirm delete',
        promptKey: promptData.promptkey,
        domainName: __domain.name,
        subDomainName: __subDomain.name,
        personaName: __persona.name,
      },
      onClose: () => {
        handleCloseDeletePromptPopup();
      },
      onConfirm: () => {
        handleDeletePrompt(promptData, __persona?.id);
        handleCloseDeletePromptPopup();
      },
    });
  };

  const handleScopeChange = (scope: any) => {
    if (scope.personaId && scope.subDomainId && scope.domainId)
      navigate(
        `/admin/manage-prompts/${scope.domainId}/${scope.subDomainId}/${scope.personaId}/prompts`,
        { replace: true },
      );
    else if (scope.subDomainId && scope.domainId)
      navigate(
        `/admin/manage-prompts/${scope.domainId}/${scope.subDomainId}/prompts`,
        { replace: true },
      );
    else if (scope.domainId)
      navigate(`/admin/manage-prompts/${scope.domainId}/prompts`, {
        replace: true,
      });
  };

  const handleAddPrompt = () => {
    setEditModeData(null);
    navigate(`../${domainId}/${subDomainId}/${personaId}/prompts/add-prompt`);
  };

  const [tryoutPopup, setTryoutPopup] = useState<any>({
    open: false,
  });

  const onTryoutClick = (prommptData: any) => {
    setTryoutPopup({
      open: true,
      ...prommptData,
      actionButton:
        prommptData.status == 'Submitted' ? (
          <ActionButtons promptData={prommptData} />
        ) : null,
    });
  };

  const onTryoutClose = () => {
    setTryoutPopup({
      open: false,
    });
  };

  const { approvePrompt, sendForReview, rejectPrompt } =
    useApprovalOperations();

  const [commentPopupProps, setCommentPopupProps] = useState<CommentPopupProps>(
    {
      open: false,
      dialogProps: null,
      onClose: () => {},
      onConfirm: (_: string, _scope: any) => {},
    },
  );

  const handleCloseCommentsPrompt = () => {
    setCommentPopupProps((prevState: any) => {
      return { ...prevState, open: false };
    });
  };

  const [refreshCounter, setRefreshCounter] = useState(0);

  const ActionButtons = ({ promptData }: { promptData: IPromptData }) => {
    return (
      <ButtonDropdown
        mainButton={{
          text: 'Approve',
          icon: <CheckIcon />,
          onClick: () => {
            setRefreshCounter(prevState => prevState + 1);
            setCommentPopupProps({
              open: true,
              domains,
              promptData,
              customClasses: {
                header: classes.textAlignCenter,
                footer: classes.approveFooter,
              },
              dialogProps: {
                headerText: 'Prompt approval confirmation!',
                subHeaderText:
                  'Prompt once after approval can be used with GPT4',
                buttonText: 'Confirm Approve',
              },
              onClose: () => {
                handleCloseCommentsPrompt();
              },
              onConfirm: (comment: string, scope: any) => {
                approvePrompt(promptData, promptData.id, comment, scope).then(
                  () => {
                    handleCloseCommentsPrompt();
                    onTryoutClose();
                  },
                );
              },
            });
          },
        }}
        dropdownItems={[
          {
            icon: <EditIcon fontSize="small" />,
            text: 'Edit Prompt',
            color: theme.palette.text.primary,
            onClick: () => {
              handleEditPrompt(promptData);
            },
          },
          {
            icon: <RefreshIcon fontSize="small" />,
            text: 'Send back for review',
            color: theme.palette.text.primary,
            onClick: () => {
              setRefreshCounter(prevState => prevState + 1);
              setCommentPopupProps({
                open: true,
                dialogProps: {
                  headerText: 'Send back for review',
                  subHeaderText: `to: ${promptData.authordisplayname}`,
                  buttonText: 'Send',
                },
                onClose: () => {
                  handleCloseCommentsPrompt();
                },
                onConfirm: (comment: string) => {
                  sendForReview(promptData, promptData.id, comment).then(() => {
                    handleCloseCommentsPrompt();
                    onTryoutClose();
                  });
                },
              });
            },
          },
          {
            icon: (
              <TryAiIcon fontSize="small" fill={theme.palette.text.primary} />
            ),
            text: 'Try prompt',
            onClick: () => {
              onTryoutClick(promptData);
            },
          },
          {
            icon: <CloseIcon fontSize="small" />,
            text: 'Reject prompt',
            color: theme.palette.error.main,
            onClick: () => {
              setRefreshCounter(prevState => prevState + 1);
              setCommentPopupProps({
                open: true,
                customClasses: {
                  header: classes.rejectHeader,
                  footer: classes.rejectFooter,
                },
                dialogProps: {
                  headerText: 'Are you sure to reject this prompt?',
                  subHeaderText: (
                    <>
                      <span>This action cannot be undone.</span>
                      <br />
                      <span>
                        All values associated with this prompt will be lost
                      </span>
                    </>
                  ),
                  buttonText: 'Reject prompt',
                },
                onClose: () => {
                  handleCloseCommentsPrompt();
                },
                onConfirm: (comment: string) => {
                  rejectPrompt(promptData, promptData.id, comment).then(() => {
                    handleCloseCommentsPrompt();
                    onTryoutClose();
                  });
                },
              });
            },
          },
        ]}
      />
    );
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <BackButton />
          <HelpComponent helpUrl={HELP_URL.VIEW_PROMPTS} />
        </Grid>
      </Grid>

      <Grid
        container
        className={`${classes.pagePadding} ${classes.flexSpacebetween}`}
      >
        {isLoading && (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        )}

        <Typography component="h2" variant="h5">
          View Prompts ({filteredPrompts.length})
        </Typography>
        <div>
          <Button
            variant="contained"
            color="primary"
            disabled={!domainId || !subDomainId || !personaId}
            onClick={handleAddPrompt}
          >
            Add Prompt
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!domainId || !subDomainId || !personaId}
            style={{
              marginLeft: '8px',
            }}
            onClick={() =>
              setChatOpen({
                domainId,
                subDomainId,
                personaId,
                prompts: filteredPrompts,
              })
            }
          >
            Try It Out
          </Button>
        </div>
      </Grid>

      <Grid
        container
        className={`${classes.pagePadding} ${classes.flexSpacebetween}`}
      >
        <Grid item xs={12} md={6} lg={3}>
          <SearchBar onTextChanged={setSearchText} />
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          lg={5}
          className={`${classes.flex} ${classes.flexSpacebetween}`}
        >
          <div className={`${classes.scopesContainer}`}>
            {domains ? (
              <Scopes
                inputScope={{ domainId, subDomainId, personaId }}
                domains={domains}
                onChange={handleScopeChange}
              />
            ) : null}
          </div>
          <div>
            <SortBy inputAction={sortByAction} onChange={setSortByAction} />
          </div>
        </Grid>
      </Grid>

      <Grid container className={classes.pagePadding}>
        {filteredPrompts.map((p: IPromptData) => (
          <Grid item xs={12} sm={12} md={12} lg={6} key={p.id}>
            <PromptCard
              promptData={p}
              onEdit={handleEditPrompt}
              onDelete={() => handleDeletePopup(p)}
              onTryoutClick={onTryoutClick}
              actionButton={
                p.status == 'Submitted' ? (
                  <ActionButtons promptData={p} />
                ) : null
              }
            />
          </Grid>
        ))}
      </Grid>

      {deletePromptPopupProps?.open && (
        <SaveNewPromptPopup {...deletePromptPopupProps} />
      )}

      {tryoutPopup.open && (
        <TryPrompt {...tryoutPopup} onClose={onTryoutClose} />
      )}

      {chat.open && (
        <TryItOut
          {...chat.props}
          onClose={() =>
            setChat({
              props: {
                id: null,
                prompts: null,
                domainId: null,
                subomainId: null,
                personaId: null,
              },
              open: false,
            })
          }
        />
      )}
      <CommentsPopup {...commentPopupProps} key={refreshCounter} />
    </>
  );
};

export default ViewPromptsPage;
