import React, { useEffect, useState, useContext } from 'react';
import {
    Button,
    createStyles,
    Grid,
    makeStyles,
    Paper,
    TextField,
    Theme,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { Carousel } from './CommentCarousel';
import { useApi } from '@backstage/core-plugin-api';

import UserAvatar from '../../../common/UserAvatar';
import { addHttpOnLinks } from '../../../utils/addHttpOnLinks';
import { AuthContext } from '../../../../providers/AuthProvider';
import { entityConversionApiRef } from '../../../../apis/entityConversionApi';
import { notificationApiRef } from '../../../../apis/notificationApi';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
    carouselItem: {
        padding: '5px',
        paddingBottom: '30px'
    },
    paper: {
        padding: '12px'
    },
    commentTitleDiv: {
        marginBottom: '12px'
    },
    commentTitle: {
        fontSize: '24px',
        fontWeight: 'bold'
    },
    commentDiv: {
        marginBottom: '24px'
    },
    commentText: {
        fontSize: '16px',
        fontWeight: 'bold'
    },
    reviewTitle: {
        fontWeight: 'bold',
        fontSize: '16px',
        marginBottom: '5px'
    },
    reviewText: {
        fontSize: '16px',
        marginBottom: '5px'
    },
    reviewUser: {
        marginBottom: '10px',
        display: 'flex'
    },
    reviewUserText: {
      textTransform: 'capitalize',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    textField: {
        marginBottom: '5px'
    },
    buttonGroup: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '5px'
    },
    submitButton: {
      marginLeft: '5px'
    }
})
);

export const RateTemplate = (props: any) => {
    const classes = useStyles();
    const userContext = useContext(AuthContext);
    const entityConversionApi = useApi(entityConversionApiRef);
    const notificationApi = useApi(notificationApiRef);
  
    const [drawerOpen, setDrawerOpen] = useState(false);
    const isScreenSmallerThanBreakpoint = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('md'),
    );

    const [comments, setComments] = useState([]);
    const [commentSubmitted, setCommentSubmitted] = useState(false);

    const questions = [{
        question: 'What are you using this template for?',
        placeHolder: 'Eg: I\'m using this template for Performance testing SI7 services'
    }, {
        question: 'How easy it is for you to use this template?',
        placeHolder:'Eg: Detailed readme document made this template easy to use'
    },{
        question: 'What are the benefits of using this template?',
        placeHolder: 'Eg: I was able to easily set up my project '
    },{
        question: 'Other',
        placeHolder: 'Comment'
    }]

    useEffect(() => {
        setComments(questions.map(q => ({
            ...q,
            value: ''
        })));
    },[]);

    const handleSubmit = () => {
      const clone = props.entity;
      if (!isEmpty(clone)) {
        if (!clone.metadata?.ratings) {
          clone.metadata.ratings = [
            {
              user: userContext?.profEmail?.toLowerCase(),
              feedback: comments,
              date: new Date()
            }
          ]
        } else {
          clone.metadata.ratings = [
            ...clone.metadata.ratings,
            {
              user: userContext?.profEmail?.toLowerCase(),
              feedback: comments,
              date: new Date()
            }
          ];
        }
        entityConversionApi
          .saveData(
            props.entity.metadata?.name,
            'Template',
            clone
          ).then(res => {
            notificationApi.sendNotification({
              message: `Thank you for reviewing the template`,
              disapperAfterMs: 2500,
              severity: 'success',
            });
          }).catch((err) => {
            console.log(`failed to update entity with error ${err}`)
          })
      }
    }


    return <Grid item xs={12}>
        <Paper className={classes.paper}>
        <Grid item xs={12} className={classes.commentTitleDiv}>
            <Typography variant="span" className={classes.commentTitle}>
                Share Your Experience
            </Typography>
        </Grid>
        <Grid item xs={12} className={classes.commentDiv}>
            {
                comments && comments.map((q, idx) => <>
                <Grid item xs={12}>
                    <Typography className={classes.commentText}>
                    {q.question}
                    </Typography>
                </Grid>
                    <TextField
                        className={classes.textField}
                        id={`template-review-${idx}`}
                        fullWidth
                        variant="outlined"
                        disabled={commentSubmitted}
                        size="small"
                        value={q.value}
                        onChange={event => {
                            const temp = [...comments];
                            temp[idx] = {
                                ...temp[idx],
                                value: event?.target?.value
                            };
                            console.log(temp)
                            setComments(temp);
                        }}
                        placeholder={q.placeHolder}
                    />
                </>)
            }
            <Grid item xs={12} className={classes.buttonGroup}>
               {
                commentSubmitted ? <Typography>Thanks for your review</Typography> :  <>
                <Button color="secondary">
                  Cancel
                </Button>
                <Button color="primary" className={classes.submitButton} onClick={() => {
                  setCommentSubmitted(true);
                  handleSubmit();
                }}>
                  Submit
                </Button>
              </>
               }
            </Grid>
        </Grid>
        {
          (props.entity && props.entity.metadata?.ratings && props.entity.metadata?.ratings.length > 0) ? <Carousel>
          {
              props.entity.metadata?.ratings.map(i => (<div className={classes.carouselItem}>
                  <div className={classes.reviewUser}>
                       <UserAvatar
                        userEmail={i.user}
                        customStyles={{
                          width: '32px',
                          height: '32px',
                          fontSize: '12px',
                          fontWeight: 'normal',
                          marginRight: '5px'
                        }}
                      />
                      <Typography className={classes.reviewUserText}>
                        {i.user.split('@')[0].split('.').join(' ')}
                      </Typography>
                  </div>
                  {
                      i.feedback.map(f => (<div>
                          <div className={classes.reviewTitle}>
                              {f?.question || ''}
                          </div>
                          <div className={classes.reviewText}>
                              {f?.value || ''}
                          </div>
                      </div>))
                  }
          </div>))
          }
      </Carousel> : <Typography>Be the first to leave a review!</Typography>
        }
        </Paper>
    </Grid>
}