import { dateDisplay } from './constant';

const nth = function (d: number) {
  const { first_short, second_short, third_short, th } = dateDisplay;
  if (d > 3 && d < 21) return th;
  switch (d % 10) {
    case 1:
      return first_short;
    case 2:
      return second_short;
    case 3:
      return third_short;
    default:
      return th;
  }
};

export const displayTime = (time: any) => {
  if (isNaN(time) === false) {
    return '';
  }
  const { month_names_short } = dateDisplay;
  const date = new Date(time);

  return `${month_names_short[date.getMonth()]} ${date.getDate()}${nth(
    date.getDate(),
  )} ${date.getFullYear()}`;
};

export function timeSince(date: any, text = 'Updated ') {
  const currentTime = new Date();
  const displayTime = new Date(date);
  var seconds = Math.floor(
    (currentTime.getTime() - displayTime.getTime()) / 1000,
  );

  var interval = seconds / 31536000;
  // let text = 'Updated ';

  if (interval > 1) {
    const { month_names_short } = dateDisplay;
    const formatDate = new Date(date);
    return (
      text +
      `${month_names_short[formatDate.getMonth()]} ${formatDate.getDate()}${nth(
        formatDate.getDate(),
      )} ${formatDate.getFullYear()}`
    );
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return text + Math.floor(interval) + ' months ago';
  }
  interval = seconds / 604800;
  if (interval > 1) {
    return text + Math.floor(interval) + ' weeks ago';
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return text + Math.floor(interval) + ' days ago';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return text + Math.floor(interval) + ' hours ago';
  }
  interval = seconds / 60;
  return text + Math.floor(interval) + ' minutes ago';
}

export const formatDateDDMMYYYY = (timestamp: string) => {
  const date = new Date(timestamp);
  return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
};

export const formatDateMMDDYYYY = (timestamp: string) => {
  const date = new Date(timestamp);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export const formatDateYYYYMMDD = (timestamp: any, seperator: string = '-') => {
  const date = new Date(timestamp);
  return `${date.getFullYear()}${seperator}${
    date.getMonth() + 1 < 10 ? '0' : ''
  }${date.getMonth() + 1}${seperator}${
    date.getDate() + 1 < 10 ? '0' : ''
  }${date.getDate()}`;
};
