import React from 'react';
import { Grid, Button, makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { entityConversionApiRef } from '../../../apis/entityConversionApi';
import { useApi } from '@backstage/core-plugin-api';
import { notificationApiRef } from '../../../apis/notificationApi';

const useStyles = makeStyles(theme => ({
  apiBtns: {
    marginLeft: '4px',
    marginTop: '10px',
    marginBottom: '100px',
    ['@media (max-width:920px)']: {
      marginLeft: '0',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
  },
  apiCancelBtn: {
    ['@media (max-width:920px)']: {
      marginTop: '15px',
    },
  },
  saveBtn: {
    marginRight: '4px',
    backgroundColor: `${theme.palette.primary.main}'!important'`,
    color: `${theme.palette.text.contrast}'!important'`,
  },
  save: {
    marginRight: '4px',
  },
}));

export const ApiButtonSaveCancel = props => {
  const {
    isPending,
    defaultEntity,
    apiLOB,
    defaultTags,
    apiCatagory,
    apiService,
    apiRegion,
    apiSegment,
    apiApmId,
    apiLifecycle,
    setDefaultEntity,
    setOpen,
    setIsPending,
    setGetStatus,
    apiCountry,
    isButtonDisable,
    name,
    kind,
    setIsButtonDisabled,
    description,
    apiSupportEmail,
  } = props;
  const classes = useStyles();
  const entityConversionApi = useApi(entityConversionApiRef);
  const notificationApi = useApi(notificationApiRef);

  const locationUrl = useLocation();
  const urlString = locationUrl.pathname;
  const replaceUrl = urlString.replace(/(\/api-edit)/g, '');
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate(replaceUrl);
  };

  const handleSubmit = event => {
    setIsButtonDisabled(true);
    event.preventDefault();
    const clone = defaultEntity;
    if (!isEmpty(clone)) {
      clone.metadata.tags = defaultTags || [];
      clone.metadata.apiLOB = apiLOB || [];
      clone.metadata.apiservice = apiService || '';
      clone.metadata.apiCategory = apiCatagory || '';
      clone.metadata.apicountry = apiCountry || [];
      clone.metadata.apiregions = apiRegion || [];
      clone.metadata.apisegment = apiSegment || '';
      clone.metadata.apmid = apiApmId || '';
      clone.metadata.description = description || '';
      clone.spec.lifecycle = apiLifecycle || '';
      clone.spec.supportEmail = apiSupportEmail;
      setDefaultEntity(clone);
    }

    setIsPending(true);
    setOpen(true);

    entityConversionApi
      .saveData(name, kind, clone)
      .then(res => {
        setIsPending(false);
        setGetStatus(res?.status);
        setIsButtonDisabled(true);
        notificationApi.sendNotification({
          message:
            'Success.. Changes saved! Changes may take upto 5 minutes to reflect.',
          disapperAfterMs: 2500,
          severity: 'success',
          callback: handleRedirect,
        });
      })
      .catch(err => {
        setIsPending(false);
        setIsButtonDisabled(true);
        notificationApi.sendNotification({
          message: `Error occurred - ${err?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  const history = createBrowserHistory();
  const goToPreviousPath = () => {
    history.back();
  };
  return (
    <Grid container className={classes.apiBtns}>
      {!isPending && (
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={handleSubmit}
          className={isButtonDisable ? classes.saveBtn : classes.save}
          disabled={isButtonDisable}
        >
          SAVE
        </Button>
      )}
      {isPending && (
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={handleSubmit}
          className={classes.save}
          disabled
        >
          SAVING...
        </Button>
      )}

      <Button
        variant="outlined"
        size="medium"
        color="primary"
        onClick={goToPreviousPath}
        className={classes.apiCancelBtn}
      >
        CANCEL
      </Button>
    </Grid>
  );
};
