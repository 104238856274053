import { createApiRef } from '@backstage/core-plugin-api';
import { AxiosInstance, AxiosResponse } from 'axios';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';

export interface PromotionApi {
  getPromotionData: () => Promise<any>;
  postAddPromotion: (payload: any) => Promise<AxiosResponse>;
  deletePromotion: (id: string, assetURL: string) => Promise<AxiosResponse>;
  getPromotionDataByIdTitle: (id: any, title: any) => Promise<AxiosResponse>;
  getPromotionImage: (promotionId: any, title: any) => Promise<AxiosResponse>;
  getPromotionDataByIdTitleUpdatePage: (
    promotionId: any,
    title: any,
  ) => Promise<AxiosResponse>;
  updatePromotionById: (
    payload: any,
    promotionId: any,
  ) => Promise<AxiosResponse>;
}

export const promotionApiRef = createApiRef<PromotionApi>({
  id: 'cbre.devx.api.promotions',
});

export class PromotionApiImpl implements PromotionApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  async getPromotionData() {
    const instance = await this.axiosInstancePromise;

    return instance
      .get('service/discovery/getPromotion')
      .then((res: any) => res.data);
  }

  async postAddPromotion(payload: any): Promise<AxiosResponse> {
    const formData = new FormData();
    let stringPayload = `{ 
      "orderID": "${payload.orderID}",
      "description": ${JSON.stringify(payload.description)},
      "templateType": "${payload.templateType}",
      "isActive": "${payload.isActive}",
      "relatedURL": "${payload.relatedUrl}",
      "title": "${payload.title}",
      "imageFileName": "${payload.imageFileName}",
      "imageBase64Format": "${payload.imageBase64Format}"
      }`;

    formData.append('payload', stringPayload);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const instance = await this.axiosInstancePromise;

    return instance.post('service/discovery/createPromotion', formData, config);
  }

  async deletePromotion(id: string, assetURL: string): Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;

    return instance.delete('service/discovery/deletePromotion', {
      params: {
        promotionId: id,
        assetURL,
      },
    });
  }

  async getPromotionDataByIdTitle(id: any, title: any): Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;

    return instance.get('service/discovery/getPromotion', {
      params: {
        promotionId: id,
        title,
      },
    });
  }

  async getPromotionImage(
    promotionId: any,
    title: any,
  ): Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;

    return instance.get('service/discovery/promotions/image', {
      params: {
        promotionId,
        title,
      },
    });
  }

  async getPromotionDataByIdTitleUpdatePage(
    promotionId: any,
    title: any,
  ): Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;

    return instance.get('service/discovery/getPromotion', {
      params: {
        promotionId,
        title,
      },
    });
  }

  async updatePromotionById(
    payload: any,
    promotionId: any,
  ): Promise<AxiosResponse> {
    const formData = new FormData();
    let stringPayload = `{ 
      "orderID": "${payload.orderID}",
      "description": ${JSON.stringify(payload.description)},
      "templateType": "${payload.templateType}",
      "isActive": "${payload.isActive}",
      "relatedURL": "${payload.relatedUrl}",
      "title": "${payload.title}",
      "imageFileName": "${payload.imageFileName}",
      "imageBase64Format": "${payload.imageBase64Format}"
      }`;

    formData.append('payload', stringPayload);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const instance = await this.axiosInstancePromise;

    return instance.put(
      `service/discovery/updatePromotion?promotionId=${promotionId}`,
      formData,
      config,
    );
  }
}
