import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useApi } from '@backstage/core-plugin-api';
import {
  PageWithHeader,
  TabbedLayout,
  Progress,
} from '@backstage/core-components';
import CostAvoidance from './costAvoidance';
import ToolboxAdminIndexPage from './toolbox';
import { ManageAdmins } from './manageAdmins/manageAdmins';
import { ManageReviewer } from './manageReviewers/manageReviewers';
import { GamificationControl } from './gamificationControl/gamificationControl';
import { ManagePromotion } from './managePromotions/managePromotions';
import { AuthContext } from '../../providers/AuthProvider';
import { CHECK_USER_ROLE, IS_GAMIFICATION_ENABLE } from '../utils/constant';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';
import ReleaseNotesAdminIndexPage from './releaseNotes';
import { ExclusionList } from './ExclusionList/ExclusionList';
import { ManageDataInsights } from './manageDataInsights/manageDataInsights';
import { PageListPage } from './whatsNewTour/PageListPage';
import { StepsListPage } from './whatsNewTour/StepsListPage';
import { ManageCategories } from './manageDataInsights/manageCategories';
import ManageDataInsightsItems from './manageDataInsights/manageItems/ManageDataInsightsItems';
import PromptManagement from './promptManagement';
import { AddPromotion } from '../Promotions/AddPromotion';
import { LevelAndBadgesContainer } from './gamificationControl/levelAndBadges/levelAndbadgesContainer';
import { AdhocPointsContainer } from './gamificationControl/adhocPoints/adhocPointsContainer';
import { PointsConfigContainer } from './gamificationControl/pointsConfig/PointsConfigContainer';
import { PromptContext } from './promptManagement/providers/PromptProvider';
import { promptApiRef } from '../../apis/promptManagementApi';

export const Admin = () => {
  const { userInfo, accessToken, profEmail, isAdmin, isDomainAdmin } =
    useContext(AuthContext);
  const userEmail = profEmail?.toLowerCase();
  const promptContext = useContext(PromptContext);
  const promptApi = useApi(promptApiRef);

  const location = useLocation();
  const [routes, setRoutes] = useState<any>([]);
  const [pathName, setPathName] = useState<any>();

  const fetchData = async () => {
    // const response = await promptApi.getDomainHierarchyByEmail(profEmail);
    let response;
    if (isDomainAdmin === true) {
      response = await promptApi.getDomainHierarchyByEmail(profEmail);
    } else {
      response = await promptApi.getDomainHierarchy();
    }
    const response2 = await promptApi.getPromptByEmail(profEmail);
    if (response && response2) {
      promptContext.updateAll(response.data, [
        ...response2.prompts,
        ...response2.default_prompts,
      ]);
    }
  };

  useEffect(() => {
    if (profEmail) {
      fetchData();
    }
  }, [profEmail]);

  const allTabRoutes = () => {
    const gameification_path =
      location.pathname === '/admin/gamification-control'
        ? 'Gamification Control'
        : location.pathname === '/admin/exclusion-list'
        ? 'Exclusion List'
        : 'Reviewers';

    const second_path =
      location.pathname === '/admin/manage-promotions'
        ? 'Promotions'
        : gameification_path;

    setPathName(
      location.pathname === '/admin/manage-admins' ||
        location.pathname === '/admin'
        ? 'Admins'
        : second_path,
    );

    const GamificationControlBreadcrumbs =
      // eslint-disable-next-line no-nested-ternary
      location.pathname === '/admin/gamification-control'
        ? 'Gamification Control'
        : location.pathname === '/admin/exclusion-list'
        ? 'Exclusion List'
        : 'Manage Reviewers';

    const secondBreadcrumbs =
      location.pathname === '/admin/manage-promotions'
        ? 'Manage Promotions'
        : GamificationControlBreadcrumbs;

    const breadcrumbsPath =
      location.pathname === '/admin' ||
      location.pathname === '/admin/manage-admins'
        ? 'Manage Admins'
        : secondBreadcrumbs;

    const isCostAvoidance = location.pathname?.includes(
      '/admin/cost-avoidance',
    );

    setRoutes([
      { type: 'link', text: 'Home', link: '/' },
      { type: 'link', text: 'Admin', link: '/admin/manage-admins' },
      { type: 'text', text: breadcrumbsPath, link: '' },
    ]);

    if (isDomainAdmin) {
      setPathName('Manage Prompts');
      setRoutes([
        { type: 'link', text: 'Home', link: '/' },
        { type: 'link', text: 'Admin', link: '/admin/manage-prompts' },
        { type: 'text', text: 'Manage Prompts', link: '' },
      ]);
    }

    if (isCostAvoidance) {
      setPathName('Cost Avoidance');
      setRoutes([
        { type: 'link', text: 'Home', link: '/' },
        { type: 'link', text: 'Admin', link: '/admin/manage-admins' },
        { type: 'text', text: 'Cost Avoidance', link: '' },
      ]);

      if (location.pathname === '/admin/cost-avoidance/variables') {
        setRoutes([
          { type: 'link', text: 'Home', link: '/' },
          { type: 'link', text: 'Admin', link: '/admin/manage-admins' },
          {
            type: 'link',
            text: 'Cost Avoidance',
            link: '/admin/cost-avoidance',
          },
          { type: 'text', text: 'Variables', link: '' },
        ]);
      }
      if (
        location.pathname?.indexOf('/admin/cost-avoidance/variables/edit') > -1
      ) {
        setRoutes([
          { type: 'link', text: 'Home', link: '/' },
          { type: 'link', text: 'Admin', link: '/admin/manage-admins' },
          {
            type: 'link',
            text: 'Cost Avoidance',
            link: '/admin/cost-avoidance',
          },
          {
            type: 'link',
            text: 'Variables',
            link: '/admin/cost-avoidance/variables',
          },
          { type: 'text', text: 'Edit Variables', link: '' },
        ]);
      }
      if (
        location.pathname?.indexOf('/admin/cost-avoidance/variables/add') > -1
      ) {
        setRoutes([
          { type: 'link', text: 'Home', link: '/' },
          { type: 'link', text: 'Admin', link: '/admin/manage-admins' },
          {
            type: 'link',
            text: 'Cost Avoidance',
            link: '/admin/cost-avoidance',
          },
          {
            type: 'link',
            text: 'Variables',
            link: '/admin/cost-avoidance/variables',
          },
          { type: 'text', text: 'Add Variable', link: '' },
        ]);
      }
      if (location.pathname === '/admin/cost-avoidance/complexity') {
        setRoutes([
          { type: 'link', text: 'Home', link: '/' },
          { type: 'link', text: 'Admin', link: '/admin/manage-admins' },
          {
            type: 'link',
            text: 'Cost Avoidance',
            link: '/admin/cost-avoidance',
          },
          { type: 'text', text: 'Asset Complexity', link: '' },
        ]);
      }
      if (
        location.pathname?.indexOf('/admin/cost-avoidance/complexity/edit') > -1
      ) {
        setRoutes([
          { type: 'link', text: 'Home', link: '/' },
          { type: 'link', text: 'Admin', link: '/admin/manage-admins' },
          {
            type: 'link',
            text: 'Cost Avoidance',
            link: '/admin/cost-avoidance',
          },
          {
            type: 'link',
            text: 'Asset Complexity',
            link: '/admin/cost-avoidance/complexity',
          },
          { type: 'text', text: 'Edit Complexity', link: '' },
        ]);
      }
    }

    const isToolbox = location.pathname?.includes('/admin/toolbox');
    if (isToolbox) {
      setPathName('Toolbox');
      setRoutes([
        { type: 'link', text: 'Home', link: '/' },
        { type: 'link', text: 'Admin', link: '/admin/manage-admins' },
        { type: 'text', text: 'Toolbox', link: '' },
      ]);
      if (location.pathname.includes('/admin/toolbox/edit/new')) {
        setRoutes([
          { type: 'link', text: 'Home', link: '/' },
          { type: 'link', text: 'Admin', link: '/admin/manage-admins' },
          { type: 'link', text: 'Toolbox', link: '/admin/toolbox' },
          { type: 'text', text: 'Add Tool', link: '' },
        ]);
      } else if (location.pathname.includes('/admin/toolbox/edit/')) {
        setRoutes([
          { type: 'link', text: 'Home', link: '/' },
          { type: 'link', text: 'Admin', link: '/admin/manage-admins' },
          { type: 'link', text: 'Toolbox', link: '/admin/toolbox' },
          { type: 'text', text: 'Edit Tool', link: '' },
        ]);
      }
    }

    const isReleaseNotes = location.pathname?.includes('/release-notes');
    if (isReleaseNotes) {
      setPathName('Release Notes');

      setRoutes([
        { type: 'link', text: 'Home', link: '/' },
        { type: 'link', text: 'Admin', link: '/admin/manage-admins' },
        { type: 'text', text: 'Release Notes', link: '' },
      ]);
      if (location.pathname.includes('/add')) {
        setRoutes([
          { type: 'link', text: 'Admin', link: '/admin/manage-admins' },
          { type: 'link', text: 'Release Notes', link: '/admin/release-notes' },
          { type: 'text', text: 'Add Version', link: '' },
        ]);
        setPathName('Add Version');
      }
      if (location.pathname.includes('/edit')) {
        setRoutes([
          { type: 'link', text: 'Admin', link: '/admin/manage-admins' },
          { type: 'link', text: 'Release Notes', link: '/admin/release-notes' },
          { type: 'text', text: 'Edit Version', link: '' },
        ]);
        setPathName('Edit Version');
      }
    }

    const isManagePromotions = location.pathname?.includes(
      '/admin/manage-promotions',
    );
    if (isManagePromotions) {
      setPathName('Manage Promotions');
      setRoutes([
        { type: 'link', text: 'Home', link: '/' },
        { type: 'link', text: 'Admin', link: '/admin/manage-admins' },
        { type: 'text', text: 'Manage Promotions', link: '' },
      ]);
      if (
        location.pathname?.indexOf('/admin/manage-promotions/add-promotion') >
        -1
      ) {
        setPathName('Add Promotion');
        setRoutes([
          { type: 'link', text: 'Home', link: '/' },
          { type: 'link', text: 'Admin', link: '/admin/manage-admins' },
          {
            type: 'link',
            text: 'Manage Promotions',
            link: '/admin/manage-promotions',
          },

          { type: 'text', text: 'Add Promotion', link: '' },
        ]);
      }
    }

    const isGamificationControl = location.pathname?.includes(
      '/admin/gamification-control',
    );

    if (isGamificationControl) {
      setPathName('Gamification Control');
      setRoutes([
        { type: 'link', text: 'Home', link: '/' },
        { type: 'link', text: 'Admin', link: '/admin/manage-admins' },
        { type: 'text', text: 'Gamification Control', link: '' },
      ]);

      if (
        location.pathname === '/admin/gamification-control/level-and-badges'
      ) {
        setPathName('Level And Badges');

        setRoutes([
          { type: 'link', text: 'Home', link: '/' },
          { type: 'link', text: 'Admin', link: '/admin/manage-admins' },
          {
            type: 'link',
            text: 'Gamification Control',
            link: '/admin/gamification-control',
          },
          { type: 'text', text: 'Level And Badges', link: '' },
        ]);
      }
      if (location.pathname === '/admin/gamification-control/adhoc-points') {
        setPathName('Adhoc Points');
        setRoutes([
          { type: 'link', text: 'Home', link: '/' },
          { type: 'link', text: 'Admin', link: '/admin/manage-admins' },
          {
            type: 'link',
            text: 'Gamification Control',
            link: '/admin/gamification-control',
          },
          { type: 'text', text: 'Adhoc Points', link: '' },
        ]);
      }
      if (
        location.pathname === '/admin/gamification-control/points-configuration'
      ) {
        setPathName('Points Config');
        setRoutes([
          { type: 'link', text: 'Home', link: '/' },
          { type: 'link', text: 'Admin', link: '/admin/manage-admins' },
          {
            type: 'link',
            text: 'Gamification Control',
            link: '/admin/gamification-control',
          },
          { type: 'text', text: 'Points Config', link: '' },
        ]);
      }
    }

    const isDataInsights = location.pathname?.includes('/manage-datainsights');
    if (isDataInsights) {
      setPathName('Manage Data Insights');
      setRoutes([
        { type: 'link', text: 'Home', link: '/' },
        { type: 'link', text: 'Admin', link: '/admin' },
        {
          type: 'link',
          text: 'Manage Data Insights',
          link: '/admin/manage-datainsights',
        },
      ]);
      if (
        location.pathname?.indexOf(
          '/admin/manage-datainsights/manage-categories',
        ) > -1
      ) {
        setPathName('Manage Categories');
        setRoutes([
          { type: 'link', text: 'Home', link: '/' },
          { type: 'link', text: 'Admin', link: '/admin' },
          {
            type: 'link',
            text: 'Manage Data Insights',
            link: '/admin/manage-datainsights',
          },
          { type: 'text', text: 'Manage Categories', link: '' },
        ]);
      }
    }
    if (
      location.pathname?.indexOf('/admin/manage-datainsights/manage-items') > -1
    ) {
      setPathName('Manage Items');
      setRoutes([
        { type: 'link', text: 'Home', link: '/' },
        { type: 'link', text: 'Admin', link: '/admin' },
        {
          type: 'link',
          text: 'Manage Data Insights',
          link: '/admin/manage-datainsights',
        },
        { type: 'text', text: 'Manage Items', link: '' },
      ]);
    }

    const isWhatsNewTour = location.pathname?.includes('/admin/whats-new-tour');
    if (isWhatsNewTour) {
      setPathName('WhatsNewTour');
      setRoutes([
        { type: 'link', text: 'Home', link: '/' },
        { type: 'link', text: 'Admin', link: '/admin/manage-admins' },
        { type: 'text', text: 'WhatsNewTour', link: '' },
      ]);
    }

    const isManagePrompts = location.pathname?.includes(
      '/admin/manage-prompts',
    );
    if (isManagePrompts) {
      setPathName('Manage Prompts');

      setRoutes([
        { type: 'link', text: 'Home', link: '/' },
        { type: 'link', text: 'Admin', link: '/admin' },
        { type: 'text', text: 'Manage Prompts', link: '' },
      ]);

      if (location.pathname?.includes('/prompts')) {
        setPathName('View Prompts');
        setRoutes([
          { type: 'link', text: 'Home', link: '/' },
          { type: 'link', text: 'Admin', link: '/admin' },
          {
            type: 'link',
            text: 'Manage Prompts',
            link: '/admin/manage-prompts',
          },
          {
            type: 'text',
            text: 'View Prompts',
          },
        ]);
      }

      if (location.pathname?.includes('/prompts/add-prompt')) {
        setPathName('Add Prompt');
        setRoutes([
          { type: 'link', text: 'Home', link: '/' },
          { type: 'link', text: 'Admin', link: '/admin' },
          {
            type: 'link',
            text: 'Manage Prompts',
            link: '/admin/manage-prompts',
          },
          {
            type: 'text',
            text: 'Add Prompt',
          },
        ]);
      }
      if (location.pathname?.includes('/prompts/edit-prompt')) {
        setPathName('Edit prompt');
        setRoutes([
          { type: 'link', text: 'Home', link: '/' },
          { type: 'link', text: 'Admin', link: '/admin' },
          {
            type: 'link',
            text: 'Manage Prompts',
            link: '/admin/manage-prompts',
          },
          {
            type: 'text',
            text: 'Add Prompt',
          },
        ]);
      }
    }
  };

  useEffect(() => {
    allTabRoutes();
  }, [location]);

  const handleRouteChanged = (routesArr: any) => {
    setRoutes((prevState: any) => [...routesArr]);
  };

  if (
    userInfo &&
    userInfo?.isAdmin?.toLowerCase() !== CHECK_USER_ROLE &&
    isDomainAdmin === false
  ) {
    window.location.replace('/');
  }

  if (
    (userInfo && userInfo?.isAdmin?.toLowerCase() === CHECK_USER_ROLE) ||
    isDomainAdmin
  ) {
    return (
      <>
        {accessToken && accessToken !== '' && (
          <PageWithHeader
            title={pathName}
            themeId="admin"
            pageTitleOverride="Admin"
            subtitle={<DevxBreadCrumb routes={routes} />}
          >
            <div className="HeaderTopTabs">
              <TabbedLayout>
                {userInfo &&
                  userInfo?.isAdmin?.toLowerCase() === CHECK_USER_ROLE && (
                    <>
                      <TabbedLayout.Route
                        path="/manage-admins"
                        title="MANAGE ADMINS"
                      >
                        <ManageAdmins
                          userEmail={userEmail}
                          userInfo={userInfo}
                        />
                      </TabbedLayout.Route>
                      <TabbedLayout.Route
                        path="/manage-reviewers"
                        title="MANAGE REVIEWERS"
                      >
                        <ManageReviewer
                          userEmail={userEmail}
                          userInfo={userInfo}
                        />
                      </TabbedLayout.Route>
                      <TabbedLayout.Route
                        path="/manage-promotions"
                        title="MANAGE PROMOTIONS"
                      >
                        <Routes>
                          <Route path="/" element={<ManagePromotion />} />
                          <Route
                            path="/add-promotion"
                            element={<AddPromotion />}
                          />
                        </Routes>
                      </TabbedLayout.Route>
                      <TabbedLayout.Route
                        path="/cost-avoidance"
                        title="Cost Avoidance"
                      >
                        <CostAvoidance />
                      </TabbedLayout.Route>
                      <TabbedLayout.Route
                        path="/manage-datainsights"
                        title="MANAGE DATA INSIGHTS"
                      >
                        <Routes>
                          <Route path="/" element={<ManageDataInsights />} />
                          <Route
                            path="/manage-categories"
                            element={<ManageCategories />}
                          />
                          <Route path="/" element={<ManageDataInsights />} />
                          <Route
                            path="/manage-items"
                            element={<ManageDataInsightsItems />}
                          />
                        </Routes>
                      </TabbedLayout.Route>
                      <TabbedLayout.Route
                        path="whats-new-tour"
                        title="What's New Tour"
                      >
                        <Routes>
                          <Route path="/" element={<PageListPage />} />
                          <Route
                            path="/page/:pageId"
                            element={<StepsListPage />}
                          />
                        </Routes>
                      </TabbedLayout.Route>
                      {IS_GAMIFICATION_ENABLE && (
                        <TabbedLayout.Route
                          path="/gamification-control"
                          title="Gamification control"
                        >
                          <Routes>
                            <Route
                              path="/"
                              element={
                                <GamificationControl
                                  userEmail={userEmail}
                                  userInfo={userInfo}
                                  isAdmin={isAdmin}
                                />
                              }
                            />
                            <Route
                              path="/level-and-badges"
                              element={
                                <LevelAndBadgesContainer
                                  onRouteChanged={handleRouteChanged}
                                />
                              }
                            />

                            <Route
                              path="/adhoc-points"
                              element={
                                <AdhocPointsContainer
                                  onRouteChanged={handleRouteChanged}
                                />
                              }
                            />
                            <Route
                              path="/points-configuration"
                              element={
                                <PointsConfigContainer
                                  onRouteChanged={handleRouteChanged}
                                />
                              }
                            />
                          </Routes>
                        </TabbedLayout.Route>
                      )}
                      <TabbedLayout.Route path="toolbox" title="Toolbox">
                        <ToolboxAdminIndexPage />
                      </TabbedLayout.Route>
                      <TabbedLayout.Route
                        path="release-notes"
                        title="Release Notes"
                      >
                        <ReleaseNotesAdminIndexPage />
                      </TabbedLayout.Route>
                      <TabbedLayout.Route
                        path="exclusion-list"
                        title="Exclusion List"
                      >
                        <ExclusionList />
                      </TabbedLayout.Route>
                    </>
                  )}
                {userInfo?.isAdmin?.toLowerCase() === CHECK_USER_ROLE ||
                isDomainAdmin ? (
                  <TabbedLayout.Route
                    path="/manage-prompts"
                    title="Manage Prompts"
                  >
                    <PromptManagement />
                  </TabbedLayout.Route>
                ) : null}
              </TabbedLayout>
            </div>
          </PageWithHeader>
        )}
      </>
    );
  }
  return <Progress />;
};
