import React, { useEffect, useState } from 'react';
import { useStyles } from '../../../../../styles/popupStyles';
import { Typography } from '@material-ui/core';
import { IFailureCriteria } from '../../../../../interfaces';
interface IFailureCriteriaProps {
  failureCriteria: IFailureCriteria[];
}

const FailureCriteriaSection = ({ failureCriteria }: IFailureCriteriaProps) => {
  const classes = useStyles();

  return (
    <div className={classes.sectionContainer}>
      <Typography className={classes.sectionHeading}>
        Failure criteria
      </Typography>
      {failureCriteria?.map((failureCriteria: IFailureCriteria) => {
        return (
          <div>
            <div className={classes.labelAndValue}>
              <div className={classes.label}>Metric:</div>
              <div>{failureCriteria?.metric}</div>
            </div>
            <div className={classes.labelAndValue}>
              <div className={classes.label}>Aggregate Function:</div>
              <div>{failureCriteria?.aggregateFunction}</div>
            </div>
            <div className={classes.labelAndValue}>
              <div className={classes.label}>Condition:</div>
              <div>{failureCriteria?.condition}</div>
            </div>
            <div className={classes.labelAndValue}>
              <div className={classes.label}>Threshold:</div>
              <div>
                {failureCriteria?.threshold}
                {/* Temporary fix for unit measurement */}
                {failureCriteria?.metric === 'error' ? ' %' : ' ms'}
              </div>
            </div>
            {failureCriteria?.requestName && (
              <div className={classes.labelAndValue}>
                <div className={classes.label}>Request Name:</div>
                <div>{failureCriteria?.requestName}</div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default FailureCriteriaSection;
