/* istanbul ignore file */
import React, { useRef } from 'react';
import { Chip } from '@material-ui/core';
import { OverflowTooltip, TableColumn } from '@backstage/core-components';
import Rating from '@material-ui/lab/Rating';
import { MAX_CHAR_COUNT_DESC } from './constant';
import { useApi } from '@backstage/core-plugin-api';
import { userProfileApiRef } from '../../apis/userProfileApi';

export type EntityRow = {
  document: {
    componentType: string;
    kind: string;
    lifecycle: string;
    location: string;
    namespace: string;
    owner: string;
    text: string;
    title: string;
    status: string;
    rating: string;
  };
};

type NameColumnProps = {
  defaultKind?: string;
};

export function createNameColumn(
  props?: NameColumnProps,
): TableColumn<EntityRow> {
  return {
    title: 'Assets',
    field: 'document.title',
    highlight: false,
    render: ({ document }) => <a href={document.location}>{document.title}</a>,
  };
}

export function createOwnerColumn(): TableColumn<EntityRow> {
  return {
    title: 'Owner',
    field: 'document.owner',
    render: ({ document }) => {
      const usernameRef = useRef(null);
      const userProfileApi = useApi(userProfileApiRef);

      const onMouseEnter = () => {
        const email = `${document?.owner?.replace('user:', '')}@cbre.com`;

        userProfileApi.setUserProfileState({
          email,
          anchorRef: usernameRef?.current,
        });
      };
      return (
        <div onMouseEnter={onMouseEnter} ref={usernameRef}>
          {document?.owner?.replace('user:', '')}
        </div>
      );
    },
  };
}

export function createSpecTypeColumn(): TableColumn<EntityRow> {
  return {
    title: 'Type',
    field: 'document.componentType',
    hidden: false,
  };
}

export function createSpecLifecycleColumn(): TableColumn<EntityRow> {
  return {
    title: 'Lifecycle',
    field: 'document.lifecycle',
  };
}

export function createMetadataDescriptionColumn(): TableColumn<EntityRow> {
  return {
    title: 'Description',
    field: 'document.text',
    render: ({ document }) => (
      <OverflowTooltip
        title={
          document.text?.length > MAX_CHAR_COUNT_DESC
            ? `${document.text?.substring(0, MAX_CHAR_COUNT_DESC)}...`
            : document.text
        }
        text={document.text}
        placement="bottom-start"
        line={2}
      />
    ),
  };
}

export function createTagsColumn(): TableColumn<EntityRow> {
  return {
    title: 'Tags',
    field: 'document.tags',
    cellStyle: {
      padding: '0px 16px 0px 20px',
    },
    render: ({ document }) => (
      <>
        {document?.tags &&
          document?.tags.map(t => (
            <Chip
              key={t}
              label={t}
              size="small"
              color="primary"
              style={{
                marginBottom: '0px',
                margin: '2px',
                height: '20px',
              }}
            />
          ))}
      </>
    ),
  };
}

export function createRatingColumn(): TableColumn<EntityRow> {
  return {
    title: 'Rating',
    field: 'entity.metadata.avgrating',
    cellStyle: {
      padding: '0px 16px 0px 20px',
    },
    render: ({ document }) => {
      return (
        <>
          <div className="ratingIcon">
            <Rating
              name="half-rating-read"
              defaultValue={document?.avgrating || 0}
              precision={0.5}
              size="small"
              readOnly
            />
          </div>
        </>
      );
    },
  };
}

export function createStatusColumn(): TableColumn<EntityRow> {
  return {
    title: 'Status',
    field: 'document.devxstate',
  };
}
