import React from 'react';
import PhaseCard from './components/PhaseCard';
import AutoAwesomeIcon from '@material-ui/icons/AutoAwesome';
import { useStyles } from './styles';
import classNames from 'classnames';
import { sdlcData } from './mocks/data';
import { Grid } from '@material-ui/core';
import { IPhaseCard } from './types';

const AiInSdlc = () => {
  const classes = useStyles();

  return (
    <div className={classes.pagePadding}>
      <header className={classNames(classes.marginBottom4, classes.colorWhite)}>
        <h1
          className={classNames(
            classes.marginBottom0,
            classes.flexCenter,
            classes.gap1,
          )}
        >
          <AutoAwesomeIcon fontSize="large" />
          <span>AI in SDLC</span>
        </h1>
        <div className={classes.subHeading}>
          Integrating AI capabilities in software engineering to deliver world
          class product quality
        </div>
      </header>

      <main
        className={classNames(
          classes.padding2Point5,
          classes.bgWhiteAppha8,
          classes.borderRadius1,
        )}
      >
        <Grid container spacing={3}>
          {sdlcData?.phases?.map((phase: IPhaseCard) => (
            <Grid item xs={12} key={phase.id}>
              <PhaseCard {...phase} />
            </Grid>
          ))}
        </Grid>
      </main>
    </div>
  );
};

export default AiInSdlc;
