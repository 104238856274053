import { createApiRef } from '@backstage/core-plugin-api';
import { AxiosInstance } from 'axios';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';
import videoBytesConfig from '../components/video-bytes/mocks/video-bytes-config';

export interface VideoBytesApi {
  /* PageList Related Apis*/
  getVideoBytesConfig: () => Promise<any>;
  addVideoByte: (pageData: any) => Promise<any>;
  editVideoByte: (pageId: string, pageData: any) => Promise<any>;
  deleteVideoByte: (pageId: string) => Promise<any>;
}

export const VideoBytesApiRef = createApiRef<VideoBytesApi>({
  id: 'cbre.devx.api.videobytes',
});

export class VideoBytesImpl implements VideoBytesApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  /* ----------------------- Video Bytes Apis-------------------- */

  /* Get TourConfig */
  async getVideoBytesConfig() {
    const instance = await this.axiosInstancePromise;
    return videoBytesConfig;
    // return instance.get('service/featuretour/config').then(res => res.data);
  }

  /* Add VideoByte */
  async addVideoByte(pageData: any) {
    const instance = await this.axiosInstancePromise;
    return instance
      .post('service/featuretour/config', pageData)
      .then(res => res.data);
  }

  /* Edit VideoByte */
  async editVideoByte(pageId: string, pageData: any) {
    const instance = await this.axiosInstancePromise;
    return instance
      .put(`service/featuretour/config/${pageId}`, pageData)
      .then(res => res.data);
  }

  /* Delete VideoByte */
  async deleteVideoByte(pageId: string) {
    const instance = await this.axiosInstancePromise;
    return instance
      .delete(`service/featuretour/config/${pageId}`)
      .then(res => res.data);
  }

  /* ----------------------- End Of Video Bytes Apis-------------------- */
}
