import React, { useState } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import './EditPageStyle.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.tertiary,
      fontSize: '12px',
      fontWeight: 600,
    },

    subcontent: {
      color: '#172B4D',
      fontSize: '11px',
      fontWeight: 600,
    },
    editInputText: {
      padding: '5px 12px',
      margin: ' 8px 0',
      boxSizing: 'border-box',
      border: '1px solid #f1f1f1',
      color: theme.palette.text.secondary,
      fontSize: '11px',
      backgroundColor: theme.palette.background.paper,
    },
    linkFontStyle: {
      fontSize: '11px',
      color: '#f00',
    },
  }),
);

// 172B4D
export type Linkprops = {
  title: string;
  url: string;
  description?: string;
};

export const Link = (props: {
  title: string;
  url: string;
  icon: string;
  index: number;
  setLink: React.Dispatch<
    React.SetStateAction<
      {
        url: string;
        title: string;
        icon: string;
      }[]
    >
  >;
  defaultlink: {
    url: string;
    title: string;
    icon: string;
  }[];
  setIsButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { title, url, icon, setLink, defaultlink, index, setIsButtonDisabled } =
    props;
  const [validatorLinksUrl, setValidatorLinksUrl] = useState(false);
  const [validatorLinkTitle, setValidatorLinkTitle] = useState(false);
  const classes = useStyles();
  const handleChange = (e: {
    preventDefault: () => void;
    target: { value: any };
  }) => {
    e.preventDefault();
    const clone = defaultlink;

    const value = e.target.value.trim();
    const expression =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
    const regex = new RegExp(expression);

    if (value.match(regex) || value === '') {
      clone.splice(index, 1, {
        url: e.target.value.replace(/\s+/g, ''),
        title: title,
        icon: icon,
      });
      setIsButtonDisabled(false);
      setValidatorLinksUrl(false);
      setLink([...clone]);
    } else {
      setValidatorLinksUrl(true);
    }
  };

  const handleTitleChange = (e: any) => {
    e.preventDefault();
    const clone = defaultlink;
    const value = e.target.value.replace(/\s+/g, ' ').trim();

    if (value.length <= 20) {
      clone.splice(index, 1, {
        url: url,
        title: e.target.value.replace(/[^a-zA-Z0-9&. -]/gi, ''),
        icon: icon,
      });
      setLink([...clone]);
      setIsButtonDisabled(false);
      setValidatorLinkTitle(false);
    } else {
      setValidatorLinkTitle(true);
    }
  };

  return (
    <>
      <Grid item lg={6} md={6} sm={12}>
        <Grid item xs={12}>
          <Typography className={classes.root}>URL {index + 1}</Typography>
        </Grid>
        <input
          className={classes.editInputText}
          type="text"
          defaultValue={url}
          id={`edit-url-${index}`}
          data-testid={`edit-url-${index}`}
          onChange={handleChange}
        />
        {validatorLinksUrl && (
          <Typography className={classes.linkFontStyle}>
            *invalid url link
          </Typography>
        )}
      </Grid>
      <Grid item lg={6} md={6} sm={12}>
        <Grid item>
          <Typography className={classes.root}>
            LINK TITLE {index + 1}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.subcontent}>
            <input
              className={classes.editInputText}
              type="text"
              defaultValue={title}
              id={`edit-title-${index}`}
              data-testid={`edit-title-${index}`}
              onChange={handleTitleChange}
              maxLength={21}
            />
            {validatorLinkTitle && (
              <Typography className={classes.linkFontStyle}>
                *max no of characters 20
              </Typography>
            )}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
