import React, { useCallback, useEffect, useState } from 'react';
import {
  identityApiRef,
  ProfileInfo,
  useApi,
} from '@backstage/core-plugin-api';
import { AxiosError } from 'axios';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { Select } from '@backstage/core-components';
import { RepoUrlPickerState } from '../types';
import { useTemplateSecrets } from '../../../SecretsContext';
import { azureDevOpsMSAuthApiRef } from '../../../../../apis/azureDevOpsMSAuthApi';
import { additionalConfigApiRef } from '../../../../../apis/additionalConfigApi';
import {
  swTemplateCustomApiRef,
  AzureProjectResponse,
  AzureProject,
} from '../../../../../apis/swTemplateCustomApi';
import { makeStyles } from '@material-ui/core';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../../apis/notificationApi';
import { debounce } from '../utils';
import { useLocation } from 'react-router-dom';
import { useEntity } from '@backstage/plugin-catalog-react';

const useStyles = makeStyles(_theme => ({
  azureSelect: {
    '& div[class^="MuiFormControl-root"]': {
      [_theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
  },
  projectSelect: {
    transform: 'scale(0.75)',
    transformOrigin: 'top left',
  },
}));
const AZURE_DEVOPS_USER_IMPERSONATION_SCOPE: string =
  '499b84ac-1321-427f-aa17-267ca6975798/user_impersonation';

export const AzureRepoPicker = (props: {
  state: RepoUrlPickerState;
  onChange: (state: RepoUrlPickerState) => void;
  rawErrors: string[];
  uiSchema: any;
}) => {
  const { rawErrors, state, onChange, uiSchema } = props;
  const { organization, repoName, owner, userSpecifiedBranch } = state;
  const classes = useStyles();
  const { requestUserCredentials } = uiSchema?.['ui:options'] ?? {};
  const { setSecrets } = useTemplateSecrets();

  const [userInfo, setUserInfo] = useState<ProfileInfo | undefined>();
  const [projectData, setProjectData] = useState<AzureProject[]>([]);
  const [token, setToken] = useState<string>('');
  const [excludedProjects, setExcludedProjects] = useState<
    string[] | undefined
  >();

  const { entity } = useEntity();

  const [userLoading, setUserLoading] = useState<boolean>(true);
  const [repoLoading, setRepoLoading] = useState<boolean>(false);
  const [repoExists, setRepoExists] = useState<boolean>(false);

  const identityApi = useApi(identityApiRef);
  const adoAuthApi = useApi(azureDevOpsMSAuthApiRef);
  const additionalConfigApi = useApi(additionalConfigApiRef);
  const swTemplateCustomApi = useApi(swTemplateCustomApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const location = useLocation();

  let templateName = '';
  if (
    location !== undefined &&
    location?.pathname !== undefined &&
    location?.pathname?.length > 3
  ) {
    templateName = location?.pathname?.split('/')?.[3];
  }
  let templateAlias =
    entity?.metadata?.templateAliasForBranchName || templateName || '';

  identityApi
    .getProfileInfo()
    .then((value: ProfileInfo) => {
      if (!userInfo) {
        setUserInfo(value);
      }
    })
    .catch((err: any) => {
      notificationApi.sendNotification({
        message: `Error occurred - ${err?.message}`,
        disapperAfterMs: 2500,
        severity: 'error',
      });
    });

  useEffect(() => {
    if (
      adoAuthApi &&
      requestUserCredentials?.secretsKey &&
      onChange &&
      setSecrets
    ) {
      adoAuthApi
        ?.getAccessToken(AZURE_DEVOPS_USER_IMPERSONATION_SCOPE)
        .then((value: any) => {
          setToken(value);
          setSecrets({ [requestUserCredentials.secretsKey]: value });
        })
        // @ts-ignore
        .catch((err: any) => {
          onChange({ host: 'Select' });
          notificationApi.sendNotification({
            message: `Error occurred - ${err?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });
    }
  }, [adoAuthApi, requestUserCredentials?.secretsKey, onChange, setSecrets]);

  useEffect(() => {
    if (token && userInfo && onChange) {
      setUserLoading(true);
      swTemplateCustomApi
        .getCBREProjectsForUser(token, '' + organization)
        .then((data: AzureProjectResponse) => {
          setProjectData(data?.value || []);
          if (data?.value && data?.value?.length > 0) {
            onChange({ owner: data?.value[0].name });
          }
          setUserLoading(false);
        })
        .catch((err: AxiosError) => {
          setProjectData([]);
          onChange({ owner: '' });
          notificationApi.sendNotification({
            message: `Error occurred - ${err?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
          setUserLoading(false);
        });
    }
  }, [token, userInfo, onChange, organization]);

  useEffect(() => {
    if (additionalConfigApi) {
      additionalConfigApi
        .getScaffolderAdoConfig()
        .then((config: any) => {
          const exclusions =
            config?.data?.exclusions
              ?.split(',')
              ?.map((e: string) => e.trim()) || [];
          setExcludedProjects(exclusions);
        })
        .catch((err: any) => {
          notificationApi.sendNotification({
            message: `Error occurred - ${err?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
          setExcludedProjects([]);
        });
    }
  }, [additionalConfigApi]);

  useEffect(() => {
    if (onChange !== undefined) {
      if (
        organization === undefined ||
        String(organization).trim().length === 0 ||
        !['cbre', 'data-cbre'].includes(organization)
      ) {
        onChange({ organization: 'cbre' });
      }
    }
  }, [onChange, organization]);

  let projectItems: { label: string; value: string }[] = [];
  if (projectData) {
    projectItems = projectData
      .map(project => {
        return { label: project?.name, value: project?.name };
      })
      .sort((a, b) => {
        return a?.value?.localeCompare(b?.value);
      });
    projectItems = projectItems || [];
  }
  if (
    excludedProjects &&
    Array.isArray(excludedProjects) &&
    excludedProjects.length > 0 &&
    projectItems?.length > 0
  ) {
    projectItems = projectItems.filter(project => {
      return !excludedProjects.includes(project.value);
    });
  }

  const getRepositoryInformation = async (
    org: string,
    owner: string,
    repoName: string,
  ) => {
    if (repoName && owner) {
      setRepoLoading(true);

      try {
        const res = await swTemplateCustomApi.getRepositoryInfo(
          org,
          owner,
          repoName,
        );

        if (
          res &&
          res?.data.status === 200 &&
          res?.data?.message === 'exists'
        ) {
          setRepoExists(true);
          if (onChange) {
            onChange({
              userSpecifiedBranch: `devx-${templateAlias}-${new Date().getTime()}`,
            });
          }
        } else {
          setRepoExists(false);
          if (onChange) {
            onChange({
              userSpecifiedBranch: undefined,
            });
          }
        }
        setRepoLoading(false);
      } catch {
        setRepoExists(false);
        if (onChange) {
          onChange({
            userSpecifiedBranch: undefined,
          });
        }
        setRepoLoading(false);
      }
    } else {
      // reset errors/branch show hide
      setRepoLoading(false);
      if (onChange) {
        onChange({
          userSpecifiedBranch: undefined,
        });
      }
    }
  };

  const debouncedGetRepoApiCall = useCallback(
    debounce((org: string, owner: any, repoName: any) => {
      getRepositoryInformation(org, owner, repoName);
    }, 1000),
    [],
  );

  useEffect(() => {
    if (organization && repoName && owner) {
      // Check if repository already exists
      debouncedGetRepoApiCall(organization, owner, repoName);
    }
  }, [organization, repoName, owner]);

  return (
    <>
      <FormControl
        margin="normal"
        required
        error={rawErrors?.length > 0 && !organization}
      >
        <InputLabel htmlFor="orgInput">Organization</InputLabel>
        <Select
          label=""
          onChange={s => {
            onChange({ organization: String(s) });
            setProjectData([]);
          }}
          data-testid="orgInput"
          selected={organization}
          items={[
            {
              label: 'cbre',
              value: 'cbre',
            },
            {
              label: 'data-cbre',
              value: 'data-cbre',
            },
          ]}
        />
        <FormHelperText>
          The organization that this repo will belong to
        </FormHelperText>
      </FormControl>
      <FormControl
        margin="normal"
        required
        error={rawErrors?.length > 0 && !owner}
        className={classes.azureSelect}
      >
        <FormLabel className={classes.projectSelect}>Project</FormLabel>
        <Select
          label=""
          onChange={s => {
            onChange({ owner: String(s) });
          }}
          selected={owner}
          items={projectItems}
        />
        <FormHelperText>
          The Project that this repo will belong to
        </FormHelperText>
      </FormControl>
      <FormControl
        margin="normal"
        required
        error={rawErrors?.length > 0 && !repoName}
      >
        <InputLabel htmlFor="repoNameInput">Repository</InputLabel>
        <Input
          id="repoNameInput"
          data-testid="repoNameInput"
          onChange={e => onChange({ repoName: e.target.value })}
          value={repoName}
          disabled={userLoading}
        />

        <FormHelperText>
          {repoLoading
            ? 'Checking if repository already exists'
            : 'The name of the repository'}
        </FormHelperText>
      </FormControl>

      {repoExists && (
        <FormControl
          margin="normal"
          required
          disabled
          error={
            rawErrors?.length > 0 && !userSpecifiedBranch && !owner && !repoName
          }
        >
          <InputLabel htmlFor="userSpecifiedBranchInput">
            Branch Name
          </InputLabel>
          <Input
            id="userSpecifiedBranchInput"
            onChange={e => onChange({ userSpecifiedBranch: e.target.value })}
            value={userSpecifiedBranch || ''}
            disabled={true}
          />
          <FormHelperText>
            Content will be pushed into this branch
          </FormHelperText>
        </FormControl>
      )}
    </>
  );
};
