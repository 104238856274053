import { default as variables } from './variables';
import { default as reset } from './reset';
import { default as layout } from './layout';
import { default as typeset } from './typeset';
import { default as animations } from './animations';
import { default as extensions } from './extensions';

/**
 * A list of style rules that will be applied to an element in the order they were added.
 *
 * @remarks
 * The order of items is important, which means that a rule can override any other rule previously added to the list,
 * i.e. the rules will be applied from the first added to the last added.
 */
export const rules = [
  variables,
  reset,
  layout,
  typeset,
  animations,
  extensions,
];