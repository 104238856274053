import { CompoundEntityRef } from '@backstage/catalog-model';
import type { Transformer } from './transformer';

export type SyncResult = 'cached' | 'updated';

export interface TechDocsStorageApi {
  getApiOrigin(): Promise<string>;
  getStorageUrl(): Promise<string>;
  getBuilder(): Promise<string>;
  getEntityDocs(entityId: CompoundEntityRef, path: string): Promise<string>;
  syncEntityDocs(
    entityId: CompoundEntityRef,
    logHandler?: (line: string) => void,
  ): Promise<SyncResult>;
  getBaseUrl(
    oldBaseUrl: string,
    entityId: CompoundEntityRef,
    path: string,
  ): Promise<string>;
}

type AddBaseUrlOptions = {
  techdocsStorageApi: TechDocsStorageApi;
  entityId: CompoundEntityRef;
  path: string;
};

/**
 * TechDocs backend serves SVGs with text/plain content-type for security. This
 * helper determines if an SVG is being loaded from the backend, and thus needs
 * inlining to be displayed properly.
 */
const isSvgNeedingInlining = (
  attrName: string,
  attrVal: string,
  apiOrigin: string,
) => {
  const isSrcToSvg = attrName === 'src' && attrVal.endsWith('.svg');
  const isRelativeUrl = !attrVal.match(/^([a-z]*:)?\/\//i);
  const pointsToOurBackend = attrVal.startsWith(apiOrigin);
  return isSrcToSvg && (isRelativeUrl || pointsToOurBackend);
};

export const addBaseUrl = ({
  techdocsStorageApi,
  entityId,
  path,
}: AddBaseUrlOptions): Transformer => {
  return async dom => {
    const apiOrigin = await techdocsStorageApi.getApiOrigin();

    const updateDom = async <T extends Element>(
      list: HTMLCollectionOf<T> | NodeListOf<T>,
      attributeName: string,
    ) => {
      for (const elem of list) {
        if (elem.hasAttribute(attributeName)) {
          const elemAttribute = elem.getAttribute(attributeName);
          if (!elemAttribute) return;

          // Special handling for SVG images.
          const newValue = await techdocsStorageApi.getBaseUrl(
            elemAttribute.replace('../', ''),
            entityId,
            path,
          );

          if (isSvgNeedingInlining(attributeName, elemAttribute, apiOrigin)) {
            try {
              const svg = await fetch(newValue, { credentials: 'include' });
              const svgContent = await svg.text();
              elem.setAttribute(
                attributeName,
                `data:image/svg+xml;base64,${btoa(
                  unescape(encodeURIComponent(svgContent)),
                )}`,
              );
            } catch (e) {
              elem.setAttribute('alt', `Error: ${elemAttribute}`);
            }
          } else {
            if (newValue.endsWith('.css')) {
              elem.setAttribute('rel', 'stylesheet');
              elem.setAttribute('type', 'text/css');
            }
            elem.setAttribute(attributeName, newValue);
          }
        }
      }
    };

    await Promise.all([
      updateDom<HTMLImageElement>(dom.querySelectorAll('img'), 'src'),
      updateDom<HTMLScriptElement>(dom.querySelectorAll('script'), 'src'),
      updateDom<HTMLSourceElement>(dom.querySelectorAll('source'), 'src'),
      updateDom<HTMLLinkElement>(dom.querySelectorAll('link'), 'href'),
      updateDom<HTMLAnchorElement>(dom.querySelectorAll('a[download]'), 'href'),
    ]);

    return dom;
  };
};
