import { useApi } from '@backstage/core-plugin-api';
import { Box, Link as MatLink, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';
import { promotionApiRef } from '../../../../apis/promotionApi';
import noImage from '../../../../assest/no-image.jpg';

function debounce(func: any, timeout = 300) {
  let timer: any;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      // @ts-expect-error
      func.apply(this, args);
    }, timeout);
  };
}

const useStyles = makeStyles({
  root: {
    marginRight: '16px',
    background: '#fff',
    borderRadius: '8px',
    display: 'flex',
    zIndex: 99,
    // position: 'relative',
    transition: 'all .3s ease-in',
    filter: 'drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.14))',
    clear: 'both',
  },
  imageRoot: {
    width: '200px',
    borderRadius: '8px 0px 0px 8px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    transition: 'all .3s ease-in',
  },
  imageTag: {
    width: '200px',
    height: '100%',
    transition: 'all .3s ease-in',
    objectFit: 'cover',
    borderRadius: '8px 0px 0px 8px',
  },
  noImageTag: {
    width: 'inherit',
    height: '100%',
    transition: 'all .3s ease-in',
    objectFit: 'cover',
    borderRadius: '8px 0px 0px 8px',
  },
  textRoot: {
    padding: '16px',
    width: '275px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    transition: 'all .3s ease-in',
  },
  textTitle: {
    flex: 1,
    fontWeight: 'bold',
    color: '#000',
    fontSize: '1.2rem',
    wordBreak: 'break-word',
    transition: 'all .3s ease-in',
    lineHeight: '24px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  textDescRoot: {
    height: '95px',
    marginTop: '8px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingBottom: '4px',
  },
  textDescription: {
    height: '80px',
    color: '#000',
    lineHeight: '16px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 5,
    wordBreak: 'break-word',
    WebkitBoxOrient: 'vertical',
  },
});

const ShortImagePromotion = ({ promotion }: any) => {
  const [imageData, setImageData] = useState<any>('');
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const { promotionId, title } = promotion;

  const classes = useStyles();

  const promotionApi = useApi(promotionApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  /*   useEffect(() => {
    promotionApi
      .getPromotionImage(promotionId, title)
      .then((res: any) => {
        setImageData(res);
      })
      .catch((err: any) => {
        setImageData(undefined);
        notificationApi.sendNotification({
          message: `Error occurred - ${err?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  }, [promotionId, title]); */

  const onMouseEnter = () => {
    debounce(setIsExpanded, 100)(true);
  };

  const onMouseLeave = () => {
    debounce(setIsExpanded, 100)(false);
  };

  return (
    <Box
      className={classes.root}
      sx={{
        height: isExpanded ? '170px' : '87px',
        zIndex: isExpanded ? 110 : 10,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Box className={classes.imageRoot}>
        {promotion?.assetURL !== '' && promotion?.assetURL ? (
          <>
            {promotion?.assetURL ? (
              <img
                src={`https://${promotion?.assetURL}`}
                // src={`data:${imageData.headers['content-type']};base64,${imageData.data}`}
                className={classes.imageTag}
                loading="lazy"
              />
            ) : (
              <img
                src={noImage}
                className={classes.noImageTag}
                loading="lazy"
              />
            )}
          </>
        ) : (
          <img
            src={noImage}
            className={classes.noImageTag}
            loading="lazy"
          ></img>
        )}
      </Box>
      <Box className={classes.textRoot}>
        <MatLink
          to={`/promotions/${promotion?.title}/${promotion?.promotionId}`}
          component={Link}
        >
          <Typography className={classes.textTitle}>
            {promotion?.title}
          </Typography>
        </MatLink>

        {isExpanded && (
          <Box className={classes.textDescRoot}>
            <Typography
              variant="caption"
              component="div"
              title={promotion?.description}
              className={classes.textDescription}
            >
              {promotion?.description}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ShortImagePromotion;
