import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  makeStyles,
  createStyles,
  Theme,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import { Button, Grid } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import ClearButton from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    feedbackButton: {
      display: 'inline-block',
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    rightAlignDiv: {
      float: 'right',
      alignItems: 'center',
      display: 'flex',
      width: '100%',
      gap: `${theme.spacing(2.5)}px`,
      justifyContent: 'right',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    SearchByStyle: {
      background: 'white',
      '& > div:first-of-type': {
        width: '100%',
        '& select': {
          padding: '6px 10px',
          background: 'white',
        },
        '& option': {
          padding: '4px',
        },
      },
    },
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      flex: 1,
    },
    ContentHeaderStyle: {
      display: 'flex',
      width: '100%',
      alignItems: 'end',
      padding: '5px 0px 0px 8px',
      justifyContent: 'space-between',
    },
    categoryDropdown: {
      display: 'flex',
      flexDirection: 'column',
    },
    filterDropdown: {
      borderRadius: '4px',
      display: 'inline-block',
      background: theme.palette.background.paper,
      width: '100%',
      '& > div:first-of-type': {
        width: '100%',

        '& select': {
          padding: '6px 10px',
        },
        '& option': {
          padding: '4px',
        },
      },
    },
    dropdown: {
      display: 'flex',
      gap: `${theme.spacing(2.5)}px`,
      [theme.breakpoints.down('sm')]: {
        marginBottom: `${theme.spacing(1.5)}px`,
      },
    },
    selectDropDown: {
      '&:focus': {
        backgroundColor: theme.palette.background.paper,
      },
    },
  }),
);

const FeedbackContentHeader = ({
  setFilterText,
  categories,
  status,
  filterOption,
  onFilter,
}: {
  setFilterText: Function;
  categories: string[];
  status: string[];
  filterOption: Object;
  onFilter: Function;
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const classes = useStyles();
  const navigate = useNavigate();

  const navigateToRegisterPage = () => {
    navigate('/feedback/register');
  };

  return (
    <Grid container className={classes.ContentHeaderStyle}>
      <Grid item xs={12} sm={12} md={12} lg={5}>
        <label htmlFor="search-input" hidden>
          Search Here
        </label>
        <Paper component="form" className={classes.root}>
          <IconButton disabled type="submit" aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            className={classes.input}
            placeholder="Search Feedback"
            fullWidth
            value={searchTerm}
            onChange={event => {
              setSearchTerm(event?.target?.value);
              setFilterText(event?.target?.value);
              return;
            }}
            inputProps={{ 'aria-label': 'search feedback' }}
          />
          <IconButton
            aria-label="search"
            onClick={() => {
              setSearchTerm('');
              setFilterText('');
              return;
            }}
          >
            <ClearButton />
          </IconButton>
        </Paper>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={7}
        className={classes.rightAlignDiv}
      >
        <div className={classes.dropdown}>
          <div className={classes.filterDropdown}>
            <FormControl size="small">
              <Select
                classes={{ root: classes.selectDropDown }}
                id={`select-filter-${filterOption.status}`}
                data-testid={`select-filter-${filterOption.status}`}
                value={filterOption.status}
                onChange={(val: any) => {
                  onFilter({
                    ...filterOption,
                    status: val.target.value,
                  });
                }}
                variant="outlined"
              >
                {status &&
                  status.map((status: string) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.filterDropdown}>
            <FormControl size="small">
              <Select
                id={`select-filter-${filterOption.category}`}
                data-testid={`select-filter-${filterOption.category}`}
                value={filterOption.category}
                classes={{ root: classes.selectDropDown }}
                onChange={(val: any) => {
                  onFilter({
                    ...filterOption,
                    category: val.target.value,
                  });
                }}
                variant="outlined"
              >
                {categories &&
                  categories.map(
                    (category: string) =>
                      category !== '' && (
                        <MenuItem key={category} value={category}>
                          {category}
                        </MenuItem>
                      ),
                  )}
              </Select>
            </FormControl>
          </div>
        </div>
        <Button
          onClick={navigateToRegisterPage}
          variant="contained"
          color="primary"
          className={classes.feedbackButton}
        >
          Provide your feedback
        </Button>
      </Grid>
    </Grid>
  );
};
export default FeedbackContentHeader;
