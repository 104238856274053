import React, { useLayoutEffect, useRef, useState } from 'react';
import { DependencyGraphTypes } from '@backstage/core-components';
import { Theme, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  node: {
    fill: theme.palette.grey[300],
    stroke: theme.palette.grey[300],

    '&.primary': {
      fill: theme.palette.primary.light,
      stroke: theme.palette.primary.light,
    },
    '&.secondary': {
      fill: theme.palette.secondary.light,
      stroke: theme.palette.secondary.light,
    },
  },
  text: {
    fill: theme.palette.getContrastText(theme.palette.grey[300]),

    '&.primary': {
      fill: theme.palette.primary.contrastText,
    },
    '&.secondary': {
      fill: theme.palette.secondary.contrastText,
    },
    '&.focused': {
      fontWeight: 'bold',
    },
  },
  clickable: {
    cursor: 'pointer',
  },
}));

export function CustomNode({
  node: {
    id,
    kind,
    namespace,
    name,
    color = 'default',
    focused,
    title,
    onClick,
  },
}: DependencyGraphTypes.RenderNodeProps<any>) {
  const classes = useStyles();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const idRef = useRef<SVGTextElement | null>(null);

  useLayoutEffect(() => {
    // set the width to the length of the ID
    if (idRef.current) {
      let { height: renderedHeight, width: renderedWidth } =
        idRef.current.getBBox();
      renderedHeight = Math.round(renderedHeight);
      renderedWidth = Math.round(renderedWidth);

      if (renderedHeight !== height || renderedWidth !== width) {
        setWidth(renderedWidth);
        setHeight(renderedHeight);
      }
    }
  }, [width, height]);

  const padding = 15;
  // const iconSize = height;
  const paddedIconWidth = 0; // kind ? iconSize + padding : 0;
  const paddedWidth = paddedIconWidth + width + padding * 2;
  const paddedHeight = height + padding * 2;

  const displayTitle = title ?? (kind && name && namespace ? name : id);

  return (
    <g onClick={onClick} className={classNames(onClick && classes.clickable)}>
      <rect
        className={classNames(
          classes.node,
          color === 'primary' && 'secondary',
          color === 'secondary' && 'primary',
        )}
        width={paddedWidth}
        height={paddedHeight}
        rx={2}
      />
      <text
        ref={idRef}
        className={classNames(
          classes.text,
          focused && 'focused',
          color === 'primary' && 'primary',
          color === 'secondary' && 'secondary',
        )}
        y={paddedHeight / 2}
        x={(width + padding * 2) / 2}
        textAnchor="middle"
        alignmentBaseline="middle"
      >
        {displayTitle}
      </text>
    </g>
  );
}
