import { useApi } from '@backstage/core-plugin-api';
import { scmIntegrationsApiRef } from '@backstage/integration-react';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { GithubRepoPicker } from './github/GithubRepoPicker';
import { AzureRepoPicker } from './azure/AzureRepoPicker';
import { RepoUrlPickerHost } from './RepoUrlPickerHost';
import { parseRepoPickerUrl, serializeRepoPickerUrl } from './utils';
import { RepoUrlPickerState } from './types';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

/**
 * The input props that can be specified under `ui:options` for the
 * `RepoUrlPicker` field extension.
 *
 * @public
 */
export interface RepoUrlPickerUiOptions {
  allowedHosts?: string[];
  allowedOwners?: string[];
  requestUserCredentials?: {
    secretsKey: string;
    additionalScopes?: {
      github?: string[];
      azure?: string[];
    };
  };
}

/**
 * The underlying component that is rendered in the form for the `RepoUrlPicker`
 * field extension.
 *
 * @public
 */
export const DevxRepoUrlPicker = (
  props: FieldExtensionComponentProps<string, RepoUrlPickerUiOptions>,
) => {
  const { uiSchema, onChange, rawErrors, formData } = props;
  const [state, setState] = useState<RepoUrlPickerState>(
    parseRepoPickerUrl(formData),
  );
  const integrationApi = useApi(scmIntegrationsApiRef);

  const allowedHosts = useMemo(
    () => uiSchema?.['ui:options']?.allowedHosts ?? [],
    [uiSchema],
  );
  let hostsOptions = allowedHosts;
  if (allowedHosts && allowedHosts?.length > 0) {
    hostsOptions = ['select', ...allowedHosts];
  }
  const allowedOwners = useMemo(
    () => uiSchema?.['ui:options']?.allowedOwners ?? [],
    [uiSchema],
  );

  useEffect(() => {
    onChange(serializeRepoPickerUrl(state));
  }, [state, onChange]);

  /* we deal with calling the repo setting here instead of in each components for ease */
  useEffect(() => {
    if (allowedOwners.length === 1) {
      setState(prevState => ({ ...prevState, owner: allowedOwners[0] }));
    }
  }, [setState, allowedOwners]);

  const updateLocalState = useCallback(
    (newState: RepoUrlPickerState) => {
      setState(prevState => ({ ...prevState, ...newState }));
    },
    [setState],
  );

  const hostType =
    (state.host && integrationApi.byHost(state.host)?.type) ?? null;

  return (
    <>
      <RepoUrlPickerHost
        host={state.host}
        hosts={hostsOptions}
        onChange={host => setState(prevState => ({ ...prevState, host }))}
        rawErrors={rawErrors}
      />
      {hostType === 'github' && (
        <GithubRepoPicker
          allowedOwners={allowedOwners}
          uiSchema={uiSchema}
          rawErrors={rawErrors}
          state={state}
          onChange={updateLocalState}
        />
      )}
      {hostType === 'azure' && (
        <AzureRepoPicker
          rawErrors={rawErrors}
          state={state}
          uiSchema={uiSchema}
          onChange={updateLocalState}
        />
      )}
    </>
  );
};
