/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Constants from './Constants';
import ExpandCircleDownOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import ExpandCircleUpOutlinedIcon from '@material-ui/icons/ExpandLessOutlined';
import { PromptContext } from '../providers/PromptProvider';
import { IPromptData } from '../interfaces';
import { useParams } from 'react-router';
import { AuthContext } from '../../../../providers/AuthProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardHeader: {
      padding: '20px 10px 10px 10px',
    },
    cardContent: {
      padding: '0px 10px 10px 10px !important',
      height: '100%',
      fontfamily: 'Calibre-R-Regular',
      fontSize: '14px',
      lineHeight: '1.334',
      letterSpacing: '0px',
      textAlign: 'left',
      color: '#26262699',
      wordBreak: 'break-word',
      overflow: 'hidden',
    },
    cardTypeFeature: {
      borderColor: (props: any) => props.color,
      borderLeft: '14px solid',
      padding: '10px',
      borderWidth: '24px',
    },
    feedbackText: {
      padding: '12px 5px',
    },
    promptHeaderButton: {
      padding: '6px 16px 6px 16px',
      margin: '10px',
      minWidth: '40px',
      color: theme.palette.primary.main,
      fontWeight: 600,
      fontSize: '16px',
      boxShadow: 'none',
      backgroundColor: theme.palette.chat.primary.button,
      textTransform: 'none',
      borderRadius: '4px',
    },
    feedbackTextDiv: {
      paddingTop: '3px',
      color: theme.palette.text.secondary,
    },
    feedbackCard: {
      margin: '0px 0px 15px 0px',
      ['@media (max-width:920px) and (min-width: 280px)']: {
        padding: '13px 7px 0px 9px !important',
      },
    },
    cardContainer: {
      position: 'relative',
    },
    titleValueSection: {
      display: 'flex',
    },
    titleValueSectionColumn: {
      display: 'flex',
      flexDirection: 'column',
      margin: '10px 10px 0px 10px',
      color: theme.palette.text.tertiary,
      fontSize: '14px',
    },
    headerSection: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    infoSection: {
      display: 'flex',
      margin: '10px',
      color: theme.palette.info.main,
      fontWeight: 600,
      fontSize: '15px',
    },
    infoContent: {
      margin: '1px 0px 0px 10px',
    },
    borderText: {
      top: '43%',
      left: '0px',
      color: '#ffffff',
      display: 'inline-block',
      position: 'absolute',
      lineHeight: '24px',
      letterSpacing: '2px',
      textTransform: 'uppercase',
      transformOrigin: 'center',
      transform: 'translate(-41%) rotate(90deg)',
    },
    longText: {
      maxHeight: 0,
      overflow: 'hidden',
      display: 'inline-block',
      transition: 'max-height 0.5s',
    },
    expanded: {
      maxHeight: '50rem',
    },
    seeMoreButton: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      display: 'flex',
      float: 'right',
    },
    // ellipsisStyle: {
    //   overflow: 'hidden',
    //   textOverflow: 'ellipsis',
    //   display: '-webkit-box',
    //   WebkitLineClamp: 2,
    //   WebkitBoxOrient: 'vertical',
    // },
  }),
);
const SeeMore = () => {
  const classes = useStyles({
    color: '',
  });
  return (
    <div className={classes.seeMoreButton}>
      See more...
      <ExpandCircleDownOutlinedIcon />
    </div>
  );
};
const SeeLess = () => {
  const classes = useStyles({
    color: '',
  });
  return (
    <div className={classes.seeMoreButton}>
      See less...
      <ExpandCircleUpOutlinedIcon />
    </div>
  );
};

const SimilarPromptCard = ({
  promptData,
  onCopyExistingPrompt,
  onForceSave,
  onEditClicked,
}: {
  promptData: IPromptData;
  onCopyExistingPrompt: Function;
  onForceSave: Function;
  onEditClicked: Function;
}) => {
  const [showMore, setShowMore] = useState(false);
  const toggleVisibility = () => {
    setShowMore(!showMore);
  };

  const { personaId } = useParams();
  const [scopeDetails, setScopeDetails] = useState<any>();
  const { domains, editModeData } = useContext(PromptContext);
  const { profEmail } = useContext(AuthContext);
  const text = promptData.promptdetail;

  useEffect(() => {
    const domain = domains.find((d: any) => d.id === promptData.product);
    const subDomain = domain?.subproducts?.find(
      (sd: any) => sd.id === promptData.subproduct,
    );
    const personas = subDomain?.personas?.filter(
      (p: any) => p.id === promptData.persona[0],
    );

    setScopeDetails({
      domain,
      subDomain,
      personas: personas[0],
    });
  }, [promptData]);

  const handleCopyExisting = () => {
    const promptObj = {
      authoremail: profEmail,
      authordisplayname: profEmail.split('@')[0].split('.').join(' '),
      product: promptData.product,
      subproduct: promptData.subproduct,
      persona: [...promptData.persona, personaId],
      promptkey: promptData.promptkey,
      promptdetail: promptData.promptdetail,
    };
    const mode = editModeData ? 'edit' : 'copy';
    onCopyExistingPrompt(
      promptObj,
      promptData.id,
      mode,
      'Prompt Copied Succesfully',
    );
  };

  const handleForceSave = () => {
    const promptRequestObj = {
      authoremail: profEmail,
      authordisplayname: profEmail.split('@')[0].split('.').join(' '),
      product: promptData.product,
      subproduct: promptData.subproduct,
      persona: [...promptData.persona],
      promptkey: promptData.promptkey,
      promptdetail: promptData.promptdetail,
    };
    onForceSave({
      prompts: [promptRequestObj],
      force: true,
    });
  };

  const classes = useStyles({
    color: promptData.id
      ? Constants.promptTypes.existingPrompt.color
      : Constants.promptTypes.newPrompt.color,
  });
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.feedbackCard}
      >
        <Card square elevation={5} className={classes.cardContainer}>
          <div className={classes.borderText}>
            {promptData.id
              ? Constants.promptTypes.existingPrompt.text
              : Constants.promptTypes.newPrompt.text}
          </div>
          <Paper elevation={5} className={classes.cardTypeFeature} square>
            <CardContent>
              <Grid container className={classes.headerSection}>
                <Grid item className={classes.titleValueSection}>
                  <div className={classes.titleValueSectionColumn}>
                    <span>Domain</span>
                    <span>{scopeDetails?.domain?.name}</span>
                  </div>
                  <div className={classes.titleValueSectionColumn}>
                    <span>Subdomain</span>
                    <span>{scopeDetails?.subDomain?.name}</span>
                  </div>
                  <div className={classes.titleValueSectionColumn}>
                    <span>Persona</span>
                    <span>{scopeDetails?.personas?.name}</span>
                  </div>
                </Grid>

                {!promptData.id ? (
                  <Grid item>
                    <Button
                      variant="contained"
                      className={classes.promptHeaderButton}
                      onClick={() => onEditClicked()}
                    >
                      Modify
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.promptHeaderButton}
                      onClick={handleForceSave}
                    >
                      Save prompt
                    </Button>
                  </Grid>
                ) : (
                  <Grid item>
                    <Button
                      variant="contained"
                      className={classes.promptHeaderButton}
                      onClick={handleCopyExisting}
                    >
                      Copy this prompt instead
                    </Button>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <CardHeader
              className={classes.cardHeader}
              subheader={promptData.promptkey}
            />
            <div className={classes.infoSection}>
              <InfoOutlinedIcon />
              <span className={classes.infoContent}>
                Above response was generated by AI. AI generated answers are not
                always accurate and need to be reviewed and validated.
              </span>
            </div>
            <CardContent className={classes.cardContent}>
              <div className={classes.feedbackTextDiv}>
                <Typography
                // className={classes.ellipsisStyle}
                >
                  <span>{text.substring(0, 350)}</span>
                  <span
                    className={`${classes.longText} ${
                      showMore ? `${classes.expanded}` : ''
                    }`}
                  >
                    {text.substring(350, text.length)}
                  </span>
                  {text.length > 350 && (
                    <span onClick={toggleVisibility}>
                      {showMore ? <SeeLess /> : <SeeMore />}
                    </span>
                  )}
                </Typography>
              </div>
            </CardContent>
          </Paper>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SimilarPromptCard;
