/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext } from 'react';

import { useGetApms } from '../hooks/useGetApms';

const initialState = {
  allApms: [],
};
export const ApmListContext = createContext<any>([]);

export const ApmListProvider = (props: any) => {
  const [allApms] = useGetApms();

  return (
    <ApmListContext.Provider
      value={{
        allApms,
      }}
    >
      {props.children}
    </ApmListContext.Provider>
  );
};
