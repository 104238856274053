import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { PageWithHeader, Content, InfoCard } from '@backstage/core-components';
import {
  Grid,
  makeStyles,
  Typography,
  TextField,
  InputLabel,
  FormControl,
  Select,
  Button,
  LinearProgress,
  MenuItem,
  Input,
  Box,
  Theme,
  createStyles,
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { AuthContext } from '../../providers/AuthProvider';
import { PromotionsBreadCrumb } from './PromotionsBreadCrumb';
import BackButton from '../utils/backButton';
import { HelpComponent } from '../utils/helpComponent';
import { urlRegex, validImageFormat } from '../utils/Utils';
import HELP_URL from '../utils/helpLinkConstant';
import { useApi } from '@backstage/core-plugin-api';
import { promotionApiRef } from '../../apis/promotionApi';
import {
  notificationApiRef,
  NotificationApi,
} from '../../apis/notificationApi';
import { IMAGE_MAX_SIZE } from '../utils/constant';
import noImage from './../../assest/no-image.jpg';
import PromotionImage from './PromotionImage';

const customRegisterPromotions = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      gridArea: 'pageContent',
      //width: '95vw',
    },
    backButton: {
      margin: 10,
    },
    FillDetailsForm: {
      maxWidth: 500,
    },
    registerNewPromo: {
      padding: '20px',
      marginTop: '13px',
      marginLeft: '10px',
      fontSize: '13px',
    },
    titleForm: {
      fontSize: '1.0rem',
      fontWeight: 'normal',
    },
    titleInstructionCard: {
      padding: '20px',
      margin: '10px',
    },
    imagePreview: {
      display: 'block',
      margin: '1rem',
      width: '200px',
      height: '180px',
    },
    saveBtn: {
      margin: '10px',
      ['@media (max-width:920px) and (min-width: 280px)']: {
        width: '100%',
        margin: '0',
        marginTop: '10px',
      },
    },
    uploadFile: {
      margin: '10px 0px 10px 0px',
      padding: 10,
    },
    instructionCard: {
      minHeight: 550,
    },

    textfieldCommon: {
      marginTop: '18px',
    },
    selectField: {
      margin: 10,
      padding: 10,
      minWidth: 200,
      ['@media (max-width:920px) and (min-width: 280px)']: {
        width: '100%',
      },
    },
    errorMessage: {
      color: 'red',
      margin: 10,
    },
    buttonSave: {
      backgroundColor: '#2E5881',
      margin: 10,
      color: '#fff',
    },
    imageUpload: {
      display: 'flex',
      alignItems: 'center',
      gap: `${theme.spacing(1)}px`,
    },
    button: {
      display: 'flex',
      gap: `${theme.spacing(1)}px`,
    },
  }),
);

const routes = [
  { type: 'link', text: 'Home', link: '/' },
  { type: 'link', text: 'Admin', link: '/admin' },
  { type: 'link', text: 'Manage Promotions', link: '/admin/manage-promotions' },
  { type: 'text', text: 'Update Promotions', link: '' },
];
export const UpdatePromotions = (props: any) => {
  const { title, id } = useParams();
  const { accessToken, apiBaseUrl, isAdmin } = React.useContext(AuthContext);

  const navigate = useNavigate();

  const promotionApi = useApi(promotionApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  React.useEffect(() => {
    if (isAdmin === false) {
      window.location.replace('/');
    }
  }, [isAdmin]);

  React.useEffect(() => {
    setIsLoading(true);
    promotionApi
      .getPromotionDataByIdTitleUpdatePage(id, title)
      .then((response: any) => {
        setIsLoading(false);
        setChangeTitle(response.data?.[0].title);
        setChangeTemplateType(response.data?.[0].templateType);
        setChangeOrder(response.data?.[0].orderID);
        setChangeDescription(response.data?.[0].description);
        setPromotionId(response.data?.[0].promotionId);
        setImagePreviewSrc(response.data?.[0].assetURL);
        setChangeRelatedUrl(response.data?.[0].relatedURL);
        setSelectActiveState(response.data?.[0].isActive);
        response.data?.[0].assetURL !== '' &&
          setImageFilename(
            response.data?.[0].assetURL
              .split('Promotion_Images/')
              .pop()
              .split('-')
              .pop(),
          );
      })
      .catch((err: any) => {
        setIsLoading(false);
        setChangeTitle('');
        setChangeTemplateType('');
        setChangeOrder('');
        setChangeDescription('');
        setPromotionId('');
        setImagePreviewSrc('');
        setChangeRelatedUrl('');
        setSelectActiveState(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${err?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  }, [accessToken, apiBaseUrl]);

  const [isLoading, setIsLoading] = React.useState(false);
  const classesPromotion = customRegisterPromotions();
  const [selectedActiveState, setSelectActiveState] = React.useState(true);
  const [changeTitle, setChangeTitle] = React.useState('');
  const [changeOrder, setChangeOrder] = React.useState('');
  const [changeDescription, setChangeDescription] = React.useState('');
  const [imagePreviewSrc, setImagePreviewSrc] = React.useState('');
  const [imageFileName, setImageFilename] = React.useState<any>('');
  const [noChange, setNoChange] = React.useState(true);
  const [imageDataBase64, setImageDataBase64] = React.useState<any>('');
  const [handleImage, setHandleImage] = React.useState<any>('');
  const ref = React.useRef('');
  const [validateImageFile, setValidateImageFile] = React.useState(false);
  const [promotionId, setPromotionId] = React.useState('');
  const [changeTemplateType, setChangeTemplateType] = React.useState('');
  const [changeRelatedUrl, setChangeRelatedUrl] = React.useState('');

  //******************below is the validation part********************
  const [buttonIsDisabled, setButtonIsDisabled] = React.useState(true);
  const [titleRequiredField, setTitleRequiredField] = React.useState(false);
  const [templateTypeRequiredField, setTemplateRequiredField] =
    React.useState(false);
  const [descriptionRequiredField, setDescriptionRequiredField] =
    React.useState(false);
  const [isActiveRequiredField, setIsActiveRequiredField] =
    React.useState(false);
  const [validateRelatedUrl, setValidateRelatedUrl] = React.useState(false);

  const validateRequiredFields = (payload: any) => {
    if (payload.title === undefined || payload.title.trim() === '') {
      setTitleRequiredField(true);
    }
    if (
      payload.description === undefined ||
      payload.description.trim() === ''
    ) {
      setDescriptionRequiredField(true);
    }

    if (payload.isActive === '') {
      setIsActiveRequiredField(true);
    }
    if (payload.templateType === '') {
      setTemplateRequiredField(true);
    }

    if (
      payload.isActive !== '' &&
      payload.templateType !== '' &&
      payload.description.trim() !== '' &&
      payload.title.trim() !== ''
    ) {
      return true;
    } else if (
      payload.isActive === '' ||
      payload.templateType === '' ||
      payload.description.trim() === '' ||
      payload.title.trim() === ''
    ) {
      setButtonIsDisabled(true);
    }
    return false;
  };

  const validateUrl = (value: string) => {
    let regex = new RegExp(urlRegex);
    if (value.match(regex) || value === '') {
      return true;
    } else {
      return false;
    }
  };

  const handleTitleChange = (e: any) => {
    e.preventDefault();
    let value = e.target.value.replace(/\s+/g, ' ');
    setChangeTitle(value);
    if (value !== '' && value.length <= 50) {
      setTitleRequiredField(false);

      setButtonIsDisabled(false);
    } else if (value.length === 0 || value === undefined) {
      setTitleRequiredField(true);
    }
  };

  const handleDescription = (e: any) => {
    e.preventDefault();
    setChangeDescription(e.target.value);
    setDescriptionRequiredField(false);
    setButtonIsDisabled(false);
  };
  const handleRelatedUrl = (e: any) => {
    e.preventDefault();
    let value = e.target.value;

    let regex = new RegExp(urlRegex);
    setChangeRelatedUrl(value);

    if (value.match(regex) || value === '') {
      setButtonIsDisabled(false);
      setValidateRelatedUrl(false);
    } else {
      setValidateRelatedUrl(true);
      setButtonIsDisabled(true);
    }
  };

  function getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const imageUploadHandle = (e: any) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      if (
        validImageFormat(e.target.files[0].type) &&
        e.target.files[0].size < IMAGE_MAX_SIZE
      ) {
        let imageFile = e.target.files[0];
        const reader = new FileReader();
        setImageFilename(imageFile.name);
        setNoChange(false);

        reader.onload = (x: any) => {
          setImagePreviewSrc(x.target?.result);
        };
        getBase64(imageFile).then(data => {
          setImageDataBase64(data);
        });
        reader.readAsDataURL(imageFile);
        setValidateImageFile(false);
        setHandleImage(imageFile);
        setButtonIsDisabled(false);
      } else {
        setValidateImageFile(true);
      }
    } else {
      setValidateImageFile(false);
      setImagePreviewSrc('');
      setHandleImage('');
    }
  };

  const handleActiveField = (e: any) => {
    e.preventDefault();
    setSelectActiveState(e.target.value);
    setButtonIsDisabled(false);
  };

  const handleFormTypeField = (e: any) => {
    e.preventDefault();
    setChangeTemplateType(e.target.value);
    setButtonIsDisabled(false);
  };

  const handleSave = () => {
    const validImage =
      imageFileName && imageDataBase64 && imageDataBase64.split(',')[1];
    let payload = {
      title: changeTitle?.trim(),
      description: changeDescription?.trim(),
      templateType: changeTemplateType,
      isActive: true,
      relatedUrl: changeRelatedUrl,
      orderID: '1',
      imageFileName: imageFileName,
      imageBase64Format: noChange
        ? imageDataBase64
        : validImage && imageDataBase64.split(',')[1],
    };

    if (validateRequiredFields(payload)) {
      const path = `${apiBaseUrl}/api/service/discovery/editPromotion`;
      if (!validateUrl(changeRelatedUrl)) {
        setValidateRelatedUrl(true);
        setButtonIsDisabled(true);
      } else {
        setIsLoading(true);

        promotionApi
          .updatePromotionById(payload, id)
          .then(() => {
            setIsLoading(false);
            notificationApi.sendNotification({
              message: `Success! saved the following details`,
              disapperAfterMs: 2500,
              severity: 'success',
              callback: () => {
                navigate('/admin/manage-promotions');
              },
            });
          })
          .catch((error: any) => {
            setIsLoading(false);
            notificationApi.sendNotification({
              message: `${error?.message}`,
              disapperAfterMs: 2500,
              severity: 'error',
            });
          });
      }
    }
  };
  const HeaderTitle = (props: any) => {
    return <span className={props.titleClassName}>Update the details</span>;
  };

  const [refreshImage, setRefreshImage] = useState(0);

  useEffect(() => {
    setRefreshImage(prevState => prevState + 1);
  }, [
    changeTitle,
    changeTemplateType,
    changeDescription,
    changeRelatedUrl,
    selectedActiveState,
  ]);

  return (
    <PageWithHeader
      title={`Update Promotion: ${title}`}
      subtitle={<PromotionsBreadCrumb routes={routes}></PromotionsBreadCrumb>}
      themeId="home"
    >
      <Content>
        {isLoading && <LinearProgress></LinearProgress>}
        <Grid container className={classesPromotion.gridContainer}>
          <Grid item md={12} xs={12} className={classesPromotion.backButton}>
            <BackButton />
            <HelpComponent helpUrl={HELP_URL.UPDATE_PROMOTIONS} />
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} md={6}>
            <InfoCard
              title={
                <HeaderTitle titleClassName={classesPromotion.titleForm} />
              }
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  className={classesPromotion.textfieldCommon}
                  disabled={false}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ maxLength: 50 }}
                  placeholder={''}
                  id="promo-title"
                  label="Title"
                  data-testid="promotion-title"
                  variant="outlined"
                  value={changeTitle}
                  onChange={handleTitleChange}
                />
                {titleRequiredField && (
                  <Typography className={classesPromotion.errorMessage}>
                    *Title is required field{' '}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  className={classesPromotion.textfieldCommon}
                  variant="outlined"
                  fullWidth
                >
                  <InputLabel htmlFor="outlined-Template-Type">
                    Template Type:
                  </InputLabel>
                  <Select
                    value={changeTemplateType}
                    onChange={handleFormTypeField}
                    label="Template Type"
                    data-testid="template-type"
                    inputProps={{
                      name: 'Template Type',
                      id: 'outlined-Template-Type',
                    }}
                  >
                    <MenuItem value={'Short Image'}>Short Image</MenuItem>
                    <MenuItem value={'Poster Image'}>Poster Image</MenuItem>
                  </Select>
                </FormControl>
                {templateTypeRequiredField && (
                  <Typography className={classesPromotion.errorMessage}>
                    *Template is a required field
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  value={changeDescription}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classesPromotion.textfieldCommon}
                  InputProps={{
                    minRows: 10,
                    maxRows: 10,
                    style: { height: '200px' },
                  }}
                  multiline
                  placeholder={''}
                  id="promo-description"
                  data-testid="promo-description"
                  label="Description"
                  variant="outlined"
                  onChange={handleDescription}
                />
                {descriptionRequiredField && (
                  <Typography className={classesPromotion.errorMessage}>
                    *Description is required field
                  </Typography>
                )}
              </Grid>
              {imagePreviewSrc !== '' && noChange ? (
                <PromotionImage
                  key={refreshImage}
                  promotionId={promotionId}
                  assetURL={imagePreviewSrc}
                  title={changeTitle}
                  className={classesPromotion.imagePreview}
                  altText={changeTitle}
                  index={1}
                  callback={(data: any, index: any) => {
                    setImageDataBase64(data);
                  }}
                />
              ) : !noChange && imagePreviewSrc !== '' ? (
                <img
                  className={classesPromotion.imagePreview}
                  src={imagePreviewSrc}
                />
              ) : (
                <img className={classesPromotion.imagePreview} src={noImage} />
              )}
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={classesPromotion.imageUpload}
                >
                  {/* <Input
                    type="file"
                    id="image"
                    data-testid="file-upload"
                    fullWidth
                    className={`${classesPromotion.uploadFile}`}
                    onChange={imageUploadHandle}
                    onClick={() => {
                      setValidateImageFile(false);
                      setImagePreviewSrc('');
                      setHandleImage('');
                    }}
                    ref={ref}
                  ></Input> */}
                  <Fragment>
                    <input
                      color="primary"
                      accept="image/*"
                      type="file"
                      onChange={imageUploadHandle}
                      id="icon-button-file"
                      style={{ display: 'none' }}
                      onClick={() => {
                        setValidateImageFile(false);
                        setImagePreviewSrc('');
                        setHandleImage('');
                        setImageFilename('');
                        setImageDataBase64('');
                        setButtonIsDisabled(false);
                      }}
                    />
                    <label htmlFor="icon-button-file">
                      <Button
                        variant="contained"
                        component="span"
                        color="primary"
                      >
                        <div className={classesPromotion.button}>
                          <PublishIcon />
                          <span>Upload Image</span>
                        </div>
                      </Button>
                    </label>
                    <span>
                      {imageFileName !== '' ? imageFileName : 'No file chosen'}
                    </span>
                  </Fragment>
                </Grid>
              </>
              {validateImageFile && (
                <Typography className={classesPromotion.errorMessage}>
                  *Supported image type is jpeg or png format (optional field/no
                  image) and size must less than 15MB!
                </Typography>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classesPromotion.textfieldCommon}
                >
                  <InputLabel htmlFor="outlined-is-active" variant="outlined">
                    Active:
                  </InputLabel>
                  <Select
                    value={selectedActiveState}
                    onChange={handleActiveField}
                    label="Active"
                    data-testid="is-active"
                    inputProps={{
                      name: 'Active',
                      id: 'outlined-is-active',
                    }}
                  >
                    <MenuItem value={true}>Active</MenuItem>
                    <MenuItem value={false}> Inactive</MenuItem>
                  </Select>
                </FormControl>
                {isActiveRequiredField && (
                  <Typography className={classesPromotion.errorMessage}>
                    *Active field is required field
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={changeRelatedUrl}
                  className={classesPromotion.textfieldCommon}
                  placeholder="paste the related url for the promo..."
                  id="promo-related-url"
                  data-testid="promo-related-url"
                  label="Related Url"
                  variant="outlined"
                  onChange={handleRelatedUrl}
                ></TextField>
                {validateRelatedUrl && (
                  <Typography className={classesPromotion.errorMessage}>
                    *please provide proper url link
                  </Typography>
                )}
              </Grid>
            </InfoCard>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button
                color="primary"
                data-testid="save"
                onClick={handleSave}
                disabled={buttonIsDisabled}
                variant="contained"
                className={classesPromotion.saveBtn}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Content>
    </PageWithHeader>
  );
};
