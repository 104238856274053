import React from 'react';
import {
  makeStyles,
  createStyles,
  Typography,
  Grid,
  Theme,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    value: {
      overflow: 'hidden',
      lineHeight: 1.5,
      wordBreak: 'break-word',
      fontFamily: 'Calibre-R-Regular',
      fontSize: '11px',
      fontWeight: 600,
      textDecoration: 'none',
      color: theme.palette.text.secondary,
    },
    label: {
      textTransform: 'uppercase',
      fontSize: '12px',
      lineHeight: 1.5,
      fontWeight: 600,
      letterSpacing: 0.5,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      fontFamily: 'Calibre-R-Regular',
      color: theme.palette.text.tertiary,
    },
  }),
);

type Props = {
  label: string;
  value?: string;
  gridSizes?: Record<string, number>;
  children?: React.ReactNode;
};

export const AboutField = ({ label, value, gridSizes, children }: Props) => {
  const classes = useStyles();

  // Content is either children or a string prop `value`
  const content = React.Children.count(children) ? (
    children
  ) : (
    <Typography variant="body2" className={classes.value}>
      {value || ``}
    </Typography>
  );
  return (
    <Grid item {...gridSizes}>
      <Typography variant="subtitle2" className={classes.label}>
        {label}
      </Typography>
      {content}
    </Grid>
  );
};
