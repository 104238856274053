import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
// @ts-ignore
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// @ts-ignore
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import remarkSlug from 'remark-slug';
import rehypeExternalLinks from 'rehype-external-links';
import remarkFrontmatter from 'remark-frontmatter';
import { useUserProfile } from '../../utils/useGetUserProfile';

import { TryItOutProps } from '../interfaces';
import { promptApiRef } from '../../../apis/promptManagementApi';
import { userApiRef } from '../../../apis/userApi';
import { useStyles } from '../styles';
import { generateId } from '../../utils/Utils';
import { useApi } from '@backstage/core-plugin-api';
import SendIcon from '@material-ui/icons/Send';
import { Avatar } from '@backstage/core-components';
import AIBotIcon from '../../information-exchange/icons/aiBotIcon';
import ContentCopy from '@material-ui/icons/ContentCopy';

const Loader = () => {
  const classes = useStyles();
  return (
    <div className={classes.loader}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

const ChatInput = ({ disabled, onSend }) => {
  const [input, setInput] = useState('');
  const classes = useStyles();

  const onSubmit = () => {
    setInput('');
    onSend(input);
  };

  return (
    <div className={`${classes.flexSpacebetween} ${classes.chatInput}`}>
      <TextField
        value={input}
        onChange={e => setInput(e.target.value)}
        disabled={disabled}
        placeholder="Try type something to ask..."
        fullWidth
        variant="standard"
        InputProps={{
          disableUnderline: true,
        }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            if (input !== '') {
              onSubmit();
            }
            return;
          }
        }}
      />
      <IconButton disabled={disabled} onClick={onSubmit}>
        <SendIcon />
      </IconButton>
    </div>
  );
};

const Chat = ({ UserImage, profEmail, chatMessage, align, loading }: any) => {
  const classes = useStyles();

  const copyToClipboard = (data: string) => {
    navigator.clipboard.writeText(data);
    // TODO: handle success and error
  };

  return (
    <div
      className={`${classes.chatAlign} ${
        align === 'left' ? classes.chatAlignLeft : classes.chatAlignRight
      }`}
    >
      {align === 'left' && (
        <div style={{ marginRight: '5px' }}>
          <AIBotIcon fontSize="small" fill="inherit" />
        </div>
      )}
      <div className={align === 'left' ? classes.chatBot : classes.chatUser}>
        {chatMessage?.length > 5 && (
          <div className={classes.chatContentCopy}>
            <Tooltip title="Copy to clipboard" placement="bottom">
              <IconButton
                disabled={loading}
                onClick={() => copyToClipboard(chatMessage)}
                size="small"
                className={classes.codeBlockButton}
              >
                <ContentCopy classes={{ root: classes.copyIconInChat }} />
              </IconButton>
            </Tooltip>
          </div>
        )}
        {loading ? (
          <Loader />
        ) : (
          <ReactMarkdown
            children={chatMessage}
            linkTarget="_blank"
            remarkPlugins={[remarkGfm, remarkSlug, remarkFrontmatter]}
            rehypePlugins={[rehypeRaw, rehypeExternalLinks]}
            className="react-markdown-ai-response"
            components={{
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || '');

                return (
                  <>
                    {!inline && (
                      <div className={classes.codeBlockCopy}>
                        <Tooltip title="Copy to clipboard" placement="bottom">
                          <IconButton
                            onClick={() =>
                              copyToClipboard(
                                String(children).replace(/\n$/, ''),
                              )
                            }
                            size="small"
                            className={classes.codeBlockButton}
                          >
                            <ContentCopy />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                    <SyntaxHighlighter
                      children={String(children).replace(/\n$/, '')}
                      style={atomDark}
                      language={match ? match[1] : 'jsx'}
                      PreTag="div"
                      {...props}
                    />
                  </>
                );
              },
            }}
          />
        )}
      </div>
      {align !== 'left' && (
        <div>
          <Avatar
            picture={UserImage}
            displayName={profEmail}
            customStyles={{
              width: '24px',
              height: '24px',
              borderRadius: '50%',
              fontSize: '10px',
              marginLeft: '5px',
              padding: '0px',
            }}
          />
        </div>
      )}
    </div>
  );
};

const TryItOut = (props: TryItOutProps) => {
  const { onClose, open, actionButton, ...promptData } = props;

  const classes = useStyles();
  const [chat, setChat] = useState([]);
  const id = useMemo(() => generateId(), []);
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState('');
  const promptApi = useApi(promptApiRef);
  const userApi = useApi(userApiRef);
  const { accessToken, profEmail } = useUserProfile();
  const theme = useTheme<Theme>();

  const onData = (data: any) => {
    setChat((prev: any[]) => {
      const temp = prev;
      const lastElement = temp.pop();
      let d = data ? data : '\n';
      lastElement.content = lastElement.content += d;
      return [...temp, lastElement];
    });
  };

  const onStart = () => {
    setChat((prev: any[]) => {
      return [
        ...prev,
        {
          content: '',
          role: 'assistant',
        },
      ];
    });
  };

  const onError = () => {
    setChat((prev: any[]) => {
      const temp = prev;
      if (prev[prev.length - 1].role === 'assistant') {
        temp.pop();
        return [
          ...temp,
          {
            content: 'Error occured',
            role: 'assistant',
          },
        ];
      }
      return [
        ...prev,
        {
          content: 'Error occured',
          role: 'assistant',
        },
      ];
    });
  };

  const onStop = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (
      !loading &&
      profEmail &&
      chat.length > 0 &&
      chat[chat.length - 1].role === 'user'
    ) {
      getResponse([...chat]);
    }
  }, [chat, profEmail]);

  const getResponse = useCallback(
    async (message: any[]) => {
      if (!loading) {
        setLoading(true);
        const msg =
          promptData.systemprompt && promptData.systemprompt !== ''
            ? [
                {
                  role: 'system',
                  content: promptData.systemprompt,
                },
                ...message,
              ]
            : message;
        await promptApi.aiServiceStream(
          {
            id: id,
            promptid: promptData.id,
            message: msg,
            emailid: profEmail,
          },
          accessToken,
          onStart,
          onStop,
          onData,
          onError,
        );
      }
    },
    [loading, chat, profEmail],
  );

  useEffect(() => {
    if (
      accessToken &&
      profEmail &&
      chat[chat.length - 1].role !== 'assistant'
    ) {
      getResponse([...chat]);
    }
  }, [accessToken, profEmail]);

  useEffect(() => {
    setChat([
      {
        role: 'user',
        content: promptData.promptdetail,
      },
    ]);
  }, []);

  useEffect(() => {
    if (userApi) {
      userApi
        .getImage(profEmail)
        .then((data: any) => {
          if (typeof data == 'object') {
            setImageData(undefined);
          } else {
            setImageData(data);
          }
        })
        .catch((err: any) => setImageData(undefined));
    }
    return () => {
      setImageData(undefined);
    };
  }, [profEmail]);

  return (
    <div className={classes.baseContainer}>
      <Paper className={classes.chatPaper}>
        <div
          className={`${classes.flexSpacebetween} ${classes.tryItOutHeader}`}
        >
          <div
            className={`${classes.flex} ${classes.centerAlign} ${classes.headIconDiv}`}
          >
            <AIBotIcon fill={theme.palette.common.white} fontSize="medium" />
            <Typography className={classes.chatTitle}>DevX chatbot</Typography>
          </div>
          <div className={`${classes.flex} ${classes.centerAlign}`}>
            {actionButton ? <div>{actionButton}</div> : null}
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className={classes.tryItOutContent}>
          {chat.length > 0 &&
            chat.map((c: any, index: number) => {
              return (
                <Chat
                  key={index}
                  UserImage={imageData}
                  profEmail={profEmail}
                  chatMessage={c.content}
                  align={c.role === 'assistant' ? 'left' : 'right'}
                />
              );
            })}
          {chat.length % 2 === 1 && loading && (
            <Chat loading={true} align="left" />
          )}
        </div>
        <div className={classes.tryItOutFooter}>
          <ChatInput
            disabled={loading}
            onSend={(data: string) => {
              setChat((prev: any[]) => {
                return [
                  ...prev,
                  {
                    role: 'user',
                    content: data,
                  },
                ];
              });
            }}
          />
        </div>
      </Paper>
    </div>
  );
};

export default TryItOut;
