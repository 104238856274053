import React from 'react';
import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Tooltip,
} from '@material-ui/core';
import { useSidebarPinState } from '@backstage/core-components';

export const PinButton = () => {
  const { isPinned, toggleSidebarPinState } = useSidebarPinState();

  return (
    <ListItem>
      <ListItemText
        primary="Pin Sidebar"
        secondary="Prevent the sidebar from collapsing"
      />
      <ListItemSecondaryAction>
        <Tooltip
          placement="top"
          arrow
          title={`${isPinned ? 'Unpin' : 'Pin'} Sidebar`}
        >
          <Switch
            color="primary"
            checked={isPinned}
            onChange={() => toggleSidebarPinState()}
            name="pin"
            inputProps={{ 'aria-label': 'Pin Sidebar Switch' }}
          />
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
