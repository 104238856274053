import React, { useContext } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { AuthContext } from '../../../providers/AuthProvider';
import { Link } from '@backstage/core-components';
import { Typography } from '@material-ui/core';
import { Breadcrumbs } from './CustomBreadcrumbs';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breadcrumbs: {
      color: theme.palette.common.white,
      width: '100%',
    },
  }),
);

const toTitleCase = (str: string) => {
  return str
    ?.toLowerCase()
    ?.split(' ')
    .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
    .join(' ');
};

const BreadcrumbsNav = ({ lastRoutes, skipLast }) => {
  const location = useLocation();
  const { allFixedRoutes } = useContext(AuthContext);
  const classes = useStyles();

  let paths: any = [];
  const matchedPaths: any = [];

  const unwind = (path: string) => {
    if (path === '/') {
      paths.push('/');
      matchedPaths.push('/');
      return;
    }
    const rPathname = path;
    const filtered = allFixedRoutes.filter(fRoute => {
      const matching = matchPath(fRoute, rPathname);
      return matching;
    });
    if (filtered && filtered.length > 0) {
      matchedPaths.push(...filtered);
      paths.push(rPathname);
    }
    const lastRootIndex = rPathname.lastIndexOf('/');

    if (lastRootIndex > 0) {
      unwind(rPathname.substring(0, lastRootIndex));
    }
    if (lastRootIndex === 0) {
      paths.push('/');
      matchedPaths.push('/');
    }
  };

  const getDisplayValue = (path: string) => {
    if (path === '/') {
      return 'Home';
    }
    if (path === '/code-library') {
      return 'Components';
    }
    const lastIndex = path.lastIndexOf('/');
    return toTitleCase(path.substring(lastIndex + 1));
  };

  unwind(location.pathname);
  paths.reverse();

  if (skipLast && skipLast > 0 && skipLast < paths.length) {
    paths = paths.slice(0, paths.length - skipLast);
  }

  let filtered: any = [];

  if (matchedPaths && matchedPaths.length) {
    filtered = lastRoutes.filter(route => {
      return matchPath(`${matchedPaths[0]}${route.path}`, location.pathname);
    });
  }

  return (
    <Breadcrumbs
      itemsBeforeCollapse={4}
      maxItems={6}
      className={classes.breadcrumbs}
    >
      {paths.map(p => (
        <Link key={p} to={p}>
          <Typography> {getDisplayValue(p)}</Typography>
        </Link>
      ))}
      {filtered && filtered.length && filtered[0]?.title && (
        <Typography> {toTitleCase(filtered[0]?.title)}</Typography>
      )}
    </Breadcrumbs>
  );
};
export default BreadcrumbsNav;
