import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ExpandIcon = () => {
  return (
    <SvgIcon
        width="20" 
        height="20" 
        viewBox="0 0 20 20" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.5 9.16667V2.5H10.8333L13.575 5.24167L5.24167 13.575L2.5 10.8333V17.5H9.16667L6.425 14.7583L14.7583 6.425L17.5 9.16667Z" fill="inherit"/>
    </SvgIcon>
  );
};

export default ExpandIcon;