import React, { PropsWithChildren, useContext } from 'react';
import { useAsync } from 'react-use';
import {
  AssetSubscriptionCountPair,
  SubscriberApi,
  subscriberApiRef,
} from '../../../apis/subscriberApi';
import { useApi } from '@backstage/core-plugin-api';

type SubscriptionCountContextProps = {
  subscriptions: Array<AssetSubscriptionCountPair>;
  loading: boolean;
  error: any;
};

const SubscriptionContext = React.createContext<SubscriptionCountContextProps>({
  subscriptions: [],
  error: undefined,
  loading: false,
});

export const useSubscriptionCount = ({
  name,
}: {
  name: string | undefined;
}) => {
  const values = useContext(SubscriptionContext);

  if (
    name !== undefined &&
    String(name).trim().length > 0 &&
    values !== undefined
  ) {
    const matchedArr = values?.subscriptions?.filter(
      v => v?.assetname === name,
    );
    return matchedArr;
  }
  return undefined;
};

const SubscriptionCountProvider = ({ children }: PropsWithChildren<{}>) => {
  const subscriberApi: SubscriberApi = useApi(subscriberApiRef);

  const { value, loading, error } = useAsync(async () => {
    const data = await subscriberApi.getAllAssetsSubscriberCount();
    return data;
  });

  return (
    <SubscriptionContext.Provider
      value={{ subscriptions: value || [], loading, error }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};
export default SubscriptionCountProvider;
