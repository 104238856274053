import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useStyles } from '../styles';

interface InputCardProps {
  heading: string;
  inputText: String;
  classes: {
    cardHeader: any;
  };
  actions: React.ReactNode;
  onInputChanged: Function;
  disabled?: boolean;
  loader?: boolean;
}

const InputCard: React.FC<InputCardProps> = ({
  heading,
  inputText,
  classes,
  actions,
  onInputChanged,
  disabled = false,
  loader = false,
}) => {
  const myClasses = useStyles();

  return (
    <Card classes={{ root: myClasses.enhancerCard }}>
      <CardHeader
        title={<Typography variant="h6">{heading}</Typography>}
        classes={{ root: `${myClasses.cardHeader} ${classes.cardHeader}` }}
      />
      <CardContent>
        <TextField
          disabled={disabled}
          value={inputText}
          fullWidth
          multiline
          variant="outlined"
          classes={{
            root: myClasses.enhancerTextField,
          }}
          onChange={event => onInputChanged(event?.target.value)}
          InputProps={{
            ...(loader && { startAdornment: <CircularProgress /> }),
            classes: {
              adornedStart: myClasses.enhancerTextFieldStartAdornment,
            },
          }}
        />
      </CardContent>
      <CardActions classes={{ spacing: myClasses.cardActionsSpacing }}>
        {actions}
      </CardActions>
    </Card>
  );
};

export default InputCard;
