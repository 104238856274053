import React, { useEffect, useState, useContext } from 'react';
import { Grid, Button, LinearProgress } from '@material-ui/core';
import ReplyIcon from '@material-ui/icons/Reply';
import AddIcon from '@material-ui/icons/Add';
import { HelpComponent } from '../../../utils/helpComponent';
import HELP_URL from '../../../utils/helpLinkConstant';
import { PromotionContext } from '../../../../providers/PromotionDataProvider';
import BackButton from '../../../utils/backButton';
import LeaderAndBadgesCard from './levelAndBadgesCard';
import {
  Page,
  Header,
  WarningPanel,
  CodeSnippet,
} from '@backstage/core-components';
import BreadcrumbsNav from '../../../common/BreadcrumbsNav/BreadcrumbsNav';
import { useCustomStyles } from './CardStyles';
import {
  GamificationApi,
  gamificationApiRef,
} from '../../../../apis/gamificationApis';
import AddEditLevelAndBadges from './addEditLevelAndBadges';
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';
import { DevxBreadCrumb } from '../../../common/BreadcrumbsNav/DevxBreadCrumb';

export const LevelAndBadgesContainer = ({
  onRouteChanged,
}: {
  onRouteChanged: Function;
}) => {
  const [gamificationlevelData, setGamificationlevelData] = useState([]);

  const [inProgress, setInProgress] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isAddClicked, setAddClicked] = useState(false);
  const [isEditClicked, setEditClicked] = useState(false);
  const [getEditTableData, setEditTableData] = useState([]);

  const classes = useCustomStyles();

  const gamificationApi: GamificationApi = useApi(gamificationApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const getProviderStore = useContext(PromotionContext);

  const getRewardLevelList = () => {
    gamificationApi
      .getRewardsLevels()
      .then((res: any) => {
        setGamificationlevelData(res);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  let routes = [
    {
      type: 'link',
      link: '/',
      text: 'Home',
    },
    {
      type: 'link',
      link: '/admin',
      text: 'Admin',
    },
    {
      type: 'link',
      link: '/admin/gamification-control',
      text: 'Gamification Control',
    },
  ];

  useEffect(() => {
    setLoading(true);
    getProviderStore.setToggleHelpButton(false);
    getRewardLevelList();
  }, []);

  const handleRedirect = () => {
    routes = [
      ...routes,
      {
        type: 'text',
        link: '',
        text: 'Level and badges',
      },
    ];
    onRouteChanged(routes);
    getRewardLevelList();
    setEditClicked(false);
    setAddClicked(false);
    getProviderStore.setToggleHelpButton(false);
  };

  const onClickAddLevelButton = () => {
    routes = [
      ...routes,
      {
        type: 'link',
        link: '/admin/gamification-control/level-and-badges',
        text: 'Level and badges',
        // @ts-expect-error
        onClick: handleRedirect,
      },
      {
        type: 'text',
        link: '',
        text: 'New Level',
      },
    ];
    onRouteChanged(routes);
    setAddClicked(true);
    setEditClicked(false);
    setEditTableData([]);
  };
  const onClickEditButton = (data: any) => {
    routes = [
      ...routes,
      {
        type: 'link',
        link: '/admin/gamification-control/level-and-badges',
        text: 'Level and badges',
        // @ts-expect-error
        //  onClick: handleRedirect,
      },
      {
        type: 'text',
        link: '',
        text: 'Edit Level',
      },
    ];
    onRouteChanged(routes);
    setEditClicked(true);
    setAddClicked(false);
    setEditTableData(data);
  };
  const onClickDeleteButton = (id: string) => {
    gamificationApi
      .deleteRewardsLevels(id)
      .then(() => {
        setInProgress(false);
        notificationApi.sendNotification({
          message: `Level Deleted successfully, updated list will be available shortly`,
          disapperAfterMs: 2500,
          severity: 'success',
          callback: handleRedirect,
        });
      })
      .catch((err: any) => {
        setInProgress(false);
        notificationApi.sendNotification({
          message: `${err.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
          callback: handleRedirect,
        });
      });
  };

  if (
    isLoading ||
    inProgress ||
    (gamificationlevelData && gamificationlevelData.length === 0)
  ) {
    return (
      <div className={classes.levelAndBadgesContainer}>
        {' '}
        <LinearProgress />
      </div>
    );
  }

  if (
    !isLoading &&
    !inProgress &&
    gamificationlevelData &&
    gamificationlevelData.length === 0
  ) {
    return (
      <div>
        <WarningPanel severity="error" title="Could not fetch level list">
          <CodeSnippet language="text" text="Error" />
        </WarningPanel>
      </div>
    );
  }

  return (
    <div className={classes.levelAndBadgesContainer}>
      <Grid item md={12} xs={12}>
        {isAddClicked || isEditClicked ? (
          <Button onClick={handleRedirect} color="primary">
            <ReplyIcon /> Back
          </Button>
        ) : (
          <BackButton />
        )}
        <HelpComponent
          helpUrl={
            isAddClicked || isEditClicked
              ? HELP_URL.addEditLevelAndBadges
              : HELP_URL.levelAndBadges
          }
        />
      </Grid>
      {isAddClicked || isEditClicked ? (
        <AddEditLevelAndBadges
          isEditClicked={isEditClicked}
          onCancelClicked={handleRedirect}
          // @ts-expect-error
          data={getEditTableData}
        />
      ) : (
        <Grid container>
          <Grid item md={12} xs={12} className={classes.addLevelContainer}>
            <Button
              onClick={onClickAddLevelButton}
              color="primary"
              variant="contained"
            >
              <AddIcon />
              Add level
            </Button>
          </Grid>
          <Grid item xs={12} md={12} className={classes.marginTop24}>
            {gamificationlevelData &&
              gamificationlevelData.length > 0 &&
              gamificationlevelData.map((item: any, index: number) => (
                <LeaderAndBadgesCard
                  data={item}
                  key={`levelAndBadgesCard ${index}`}
                  onClickEditButton={onClickEditButton}
                  onClickDeleteButton={onClickDeleteButton}
                />
              ))}
          </Grid>{' '}
        </Grid>
      )}
    </div>
  );
};
