import React, { useEffect, useState } from 'react';
import { useStyles } from '../../../../../styles/popupStyles';
import { Typography } from '@material-ui/core';
import { IConfigData } from '../../../../../interfaces';

interface ProjectInfoItem {
  label: string;
  value: string;
}

const ProjectInfoSection = (props: IConfigData) => {
  const classes = useStyles();
  const [detailsArr, setDetailsArr] = useState<ProjectInfoItem[]>([]);

  useEffect(() => {
    loadDetailsArray();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const loadDetailsArray = () => {
    setDetailsArr([
      {
        label: 'Config name:',
        value: props?.configName || '',
      },
      {
        label: 'APM number:',
        value: props?.apmKey || '',
      },
      {
        label: 'Flow id:',
        value: props?.config?.flowId || '',
      },
    ]);
  };

  return (
    <div className={classes.sectionContainer}>
      <Typography className={classes.sectionHeading}>Project info</Typography>
      <div>
        {detailsArr.map((item, index) => (
          <div className={classes.labelAndValue}>
            <div className={classes.label} key={index}>
              {item.label}
            </div>
            <div key={index}>{item.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectInfoSection;
