import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CodeIcon = () => (
  <SvgIcon viewBox="0 0 24 24">
    <path d="M 14.41 4.091 C 13.888 3.911 13.319 4.189 13.139 4.712 L 8.31 18.757 C 8.13 19.279 8.408 19.848 8.93 20.028 C 9.453 20.208 10.022 19.93 10.201 19.408 L 15.03 5.362 C 15.21 4.84 14.932 4.271 14.41 4.091 Z M 8.272 12.301 L 5.692 9.8 L 8.491 7.086 C 8.888 6.701 8.898 6.068 8.513 5.672 C 8.129 5.275 7.496 5.265 7.099 5.65 L 3.559 9.082 C 3.154 9.475 3.154 10.125 3.559 10.518 L 6.88 13.737 C 7.276 14.121 7.909 14.112 8.294 13.715 C 8.678 13.319 8.668 12.685 8.272 12.301 Z M 17.171 10.65 L 20.492 13.869 C 20.897 14.262 20.897 14.912 20.492 15.305 L 16.952 18.737 C 16.555 19.121 15.922 19.112 15.538 18.715 C 15.153 18.318 15.163 17.685 15.56 17.301 L 18.359 14.587 L 15.779 12.086 C 15.383 11.701 15.373 11.068 15.757 10.672 C 16.142 10.275 16.775 10.265 17.171 10.65 Z" />
  </SvgIcon>
);

export default CodeIcon;
