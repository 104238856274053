import React from 'react';
import { WarningPanel, CodeSnippet } from '@backstage/core-components';
import EntityCard from '../../../common/Cards/EntityCard';

export const RecentlyPublishedTable = ({ data }: any) => {
  if (data.error) {
    return (
      <div>
        <WarningPanel
          severity="error"
          title="Could not fetch catalog entities."
        >
          <CodeSnippet language="text" text={data.error.toString()} />
        </WarningPanel>
      </div>
    );
  }

  return (
    <div className="discover-table recentlyPublishedTable">
      {data &&
        Array.isArray(data) &&
        data.length > 0 &&
        data
          .slice(-5)
          .map((entity: any, idx: any) => (
            <EntityCard
              type={entity.assetType || 'Component'}
              title={entity.assetName}
              description={entity.assetDesc}
              timestamp={entity.lastPublished}
              kind={entity.assetKind}
              key={`discover-recently-published-${idx}`}
            />
          ))}
    </div>
  );
};
