import { parseEntityRef } from '@backstage/catalog-model';
import {
  Content,
  ErrorPage,
  Header,
  Page,
  LogViewer,
  Progress,
} from '@backstage/core-components';
import {
  Button,
  CircularProgress,
  Paper,
  StepButton,
  StepIconProps,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Cancel from '@material-ui/icons/Cancel';
import Check from '@material-ui/icons/Check';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import classNames from 'classnames';
import { DateTime, Interval } from 'luxon';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useInterval from 'react-use/lib/useInterval';
import { ScaffolderTaskStatus } from '../types';
import { useTaskEventStream } from '../hooks/useEventStream';
import { DevxBreadCrumb } from '../../common/BreadcrumbsNav/DevxBreadCrumb';
import { useRegisterPocComponent } from '../hooks/useRegisterComponent';
import { TaskPageLinks } from './TaskPageLinks';
import { ScaffolderTaskOutput } from '../../scaffolder/types';
import { constants } from '../common/constants';

// typings are wrong for this library, so fallback to not parsing types.
const humanizeDuration = require('humanize-duration');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '70px',
      },
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
    labelWrapper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    stepWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column-reverse',
      marginTop: '-50px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row-reverse',
      },
    },
    taskPage: {
      display: 'flex',
      width: '100%',
      paddingTop: '30px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    logViewer: {
      backgroundColor: '#383838 !important',
      color: '#24cd53',
      '& input': {
        display: 'none',
        color: '#24cd53',
      },
    },
    taskStepper: {
      display: 'flex',
      flex: '1',
    },
    taskPageBtn: {
      height: '50px',
      paddingRight: '20px',
      width: 'auto',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    stepId: {
      padding: '0px',
    },
    logView: {
      height: '80vh',
    },
    stepButton: {
      [theme.breakpoints.down('sm')]: {
        margin: '0',
      },
    },
    stepperContent: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
  }),
);

type TaskStep = {
  id: string;
  name: string;
  status: ScaffolderTaskStatus;
  startedAt?: string;
  endedAt?: string;
};

const StepTimeTicker = ({ step }: { step: TaskStep }) => {
  const [time, setTime] = useState('');

  useInterval(() => {
    if (!step.startedAt) {
      setTime('');
      return;
    }

    const end = step.endedAt
      ? DateTime.fromISO(step.endedAt)
      : DateTime.local();

    const startedAt = DateTime.fromISO(step.startedAt);
    const formatted = Interval.fromDateTimes(startedAt, end)
      .toDuration()
      .valueOf();

    setTime(humanizeDuration(formatted, { round: true }));
  }, 1000);

  return <Typography variant="caption">{time}</Typography>;
};

const useStepIconStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.disabled,
      display: 'flex',
      height: 22,
      alignItems: 'center',
    },
    completed: {
      color: theme.palette.status.ok,
    },
    error: {
      color: theme.palette.status.error,
    },
  }),
);

function TaskStepIconComponent(props: StepIconProps) {
  const classes = useStepIconStyles();
  const { active, completed, error } = props;

  const getMiddle = () => {
    if (active) {
      return <CircularProgress size="24px" />;
    }
    if (completed) {
      return <Check />;
    }
    if (error) {
      return <Cancel />;
    }
    return <FiberManualRecordIcon />;
  };

  return (
    <div
      className={classNames(classes.root, {
        [classes.completed]: completed,
        [classes.error]: error,
      })}
    >
      {getMiddle()}
    </div>
  );
}

export const TaskStatusStepper = memo(
  ({
    steps,
    currentStepId,
    onUserStepChange,
  }: {
    steps: TaskStep[];
    currentStepId: string | undefined;
    onUserStepChange: (id: string) => void;
  }) => {
    const classes = useStyles();

    return (
      <div className={classes.root}>
        <Stepper
          className={classes.stepperContent}
          activeStep={steps.findIndex(s => s.id === currentStepId)}
          orientation="horizontal"
          nonLinear
        >
          {steps
            ?.filter(step => step.status !== 'skipped')
            .map((step, index) => {
              const isCompleted = step.status === 'completed';
              const isFailed = step.status === 'failed';
              const isActive = step.status === 'processing';
              const isSkipped = step.status === 'skipped';

              if (isSkipped) {
                return <></>;
              }

              return (
                <Step key={String(index)} expanded>
                  <StepButton
                    className={classes.stepButton}
                    onClick={() => onUserStepChange(step.id)}
                  >
                    <StepLabel
                      StepIconProps={{
                        completed: isCompleted,
                        error: isFailed,
                        active: isActive,
                      }}
                      StepIconComponent={TaskStepIconComponent}
                      className={classes.stepWrapper}
                    >
                      <div className={classes.labelWrapper}>
                        <div>
                          <Typography
                            variant="h6"
                            style={{ fontSize: '14px', fontWeight: '600' }}
                          >
                            {step.name}
                          </Typography>
                          {isSkipped ? (
                            <Typography variant="caption">Skipped</Typography>
                          ) : (
                            <StepTimeTicker step={step} />
                          )}
                        </div>
                      </div>
                    </StepLabel>
                  </StepButton>
                </Step>
              );
            })}
        </Stepper>
      </div>
    );
  },
);

const hasLinks = ({
  entityRef,
  remoteUrl,
  links = [],
}: ScaffolderTaskOutput): boolean =>
  !!(entityRef || remoteUrl || links.length > 0);

/**
 * TaskPageProps for constructing a TaskPage
 * @param loadingText - Optional loading text shown before a task begins executing.
 *
 * @public
 */
export type TaskPageProps = {
  loadingText?: string;
  forClone?: boolean;
};

/**
 * TaskPage for showing the status of the taskId provided as a param
 * @param loadingText - Optional loading text shown before a task begins executing.
 *
 * @public
 */
export const TaskPage = ({ loadingText, forClone }: TaskPageProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const { registerPoc } = useRegisterPocComponent();
  const [userSelectedStepId, setUserSelectedStepId] = useState<
    string | undefined
  >(undefined);
  const [lastActiveStepId, setLastActiveStepId] = useState<string | undefined>(
    undefined,
  );
  const { taskId } = useParams();
  const taskStream = useTaskEventStream(taskId);
  const completed = taskStream.completed;
  const steps = useMemo(
    () =>
      taskStream.task?.spec.steps.map(step => ({
        ...step,
        ...taskStream?.steps?.[step.id],
      })) ?? [],
    [taskStream],
  );

  const cloneOutput = taskStream;

  useEffect(() => {
    const mostRecentFailedOrActiveStep = steps.find(step =>
      ['failed', 'processing'].includes(step.status),
    );
    if (completed && !mostRecentFailedOrActiveStep) {
      const { output } = taskStream;
      const repoLocation=output && output?.remoteUrl?.replace('.git','') || state.repoUrl;
      const payload = {
        name: state?.name || "",
        description: state?.description || "",
        type: constants.POC,
        owneremail: state?.loggedInUser || '',
        repourl: repoLocation || "",
        scmrepoid: '',
        scm: constants.SCM,
        branch: constants.MAIN,
        scmuserid: "",
        links: [],
        status: constants.APPROVED,
        tags: state?.tags || [],
        
      };

      if (!forClone) registerPoc(payload, handleStartOver, repoLocation);

      setLastActiveStepId(steps[steps.length - 1]?.id);
      const filteredSteps = steps.filter(step => step.status !== 'skipped');
      if (filteredSteps && filteredSteps?.length > 0) {
        setLastActiveStepId(filteredSteps[filteredSteps.length - 1]?.id);
      }

      //if (!forClone) handleStartOver(repoLocation);
      return;
    }

    setLastActiveStepId(mostRecentFailedOrActiveStep?.id);
  }, [steps, completed]);

  const currentStepId = userSelectedStepId ?? lastActiveStepId;

  const logAsString = useMemo(() => {
    if (!currentStepId) {
      return loadingText ? loadingText : 'Loading...';
    }
    const log = taskStream.stepLogs[currentStepId];

    if (!log?.length) {
      return 'Waiting for logs...';
    }
    return log.join('\n');
  }, [taskStream.stepLogs, currentStepId, loadingText]);

  const taskNotFound =
    taskStream.completed === true &&
    taskStream.loading === false &&
    !taskStream.task;

  const handleStartOver = (remoteUrl:string) => {
    const formData = taskStream.task!.spec.parameters; 
    if(remoteUrl){               
      navigate(`/poc-hub/add-poc/additional-details`, {
        state: { ...formData, repoUrl: remoteUrl },
      });
    }else {
      navigate(`/poc-hub/add-poc/additional-details`, {
        state: { ...formData, repoUrl: remoteUrl },
      });
    }
    
  };

  const handleGoBack = () => {
    navigate(`/poc-hub`);
  };

  const getRoutes = () => {
    const routeArr = [
      {
        type: 'link',
        link: '/',
        text: 'Home',
      },
      {
        type: 'link',
        link: '/poc-hub',
        text: 'POC Hub',
      },
      {
        type: 'link',
        link: `/poc-hub/Add-poc`,
        text: 'Add Poc',
      },
      {
        type: 'text',
        link: '',
        text: 'Task Activity',
      },
    ];
    if (forClone) routeArr.splice(2, 1);
    return routeArr;
  };

  return (
    <Page themeId="home">
      <Header
        pageTitleOverride={`Task ${taskId}`}
        title={<>Task Activity</>}
        subtitle={<DevxBreadCrumb routes={getRoutes()} />}
      />
      <Content>
        {taskNotFound ? (
          <ErrorPage
            status="404"
            statusMessage="Task not found"
            additionalInfo="No task found with this ID"
          />
        ) : (
          <div>
            <Grid container>
              <Paper className={classes.taskPage}>
                <div className={classes.taskStepper}>
                  <TaskStatusStepper
                    steps={steps}
                    currentStepId={currentStepId}
                    onUserStepChange={setUserSelectedStepId}
                  />
                </div>
                <>
                  {forClone ? (
                    <div className={classes.taskPageBtn}>
                      {cloneOutput?.output && hasLinks(cloneOutput?.output) && (
                        <TaskPageLinks output={cloneOutput?.output} />
                      )}
                      <Button
                        className={classes.button}
                        onClick={handleGoBack}
                        disabled={!completed}
                        variant="contained"
                        color="primary"
                      >
                        Go back
                      </Button>
                    </div>
                  ) : null}
                </>
              </Paper>
            </Grid>
            <Grid container>
              <Grid item xs={12} className={classes.stepId}>
                {!currentStepId && <Progress />}

                <div className={classes.logView}>
                  <LogViewer
                    text={logAsString}
                    classes={{ root: classes.logViewer }}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </Content>
    </Page>
  );
};
