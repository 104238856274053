import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from '../common/styles';
import * as yup from 'yup';

const AddStepPopup = ({
  open,
  onClose,
  onAddEdit,
  data,
}: {
  open: boolean;
  onClose: Function;
  onAddEdit: Function;
  data?: any;
}) => {
  const classes = useStyles();
  /* formvalue objects */

  const emptyFormValues = useRef({
    selector: '',
    enableClick: false,
    title: '',
    description: '',
    placement: '',
  });

  const [formValues, setFormValues] = useState(emptyFormValues.current);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  /*  validation related variables */
  const [_titleValidationErr, setTitleValidationError] = useState('');
  const [_descriptionValidationErr, setDescriptionValidationError] =
    useState('');
  const [_placementValidationErr, setPlacementValidationError] = useState('');

  useEffect(() => {
    if (data) {
      setIsEditMode(true);
      setFormValues(data);
    } else {
      setIsEditMode(false);
      setFormValues(emptyFormValues.current);
    }
  }, [data]);

  /* Change Handler for all form inputs */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const myValue = name === 'enableClick' ? event.target.checked : value;
    setFormValues((prevState: any) => {
      return {
        ...prevState,
        [name]: myValue,
      };
    });
  };
  /* Function for validating inputs */
  const validateInputs = async () => {
    const valResult = yup
      .string()
      .required('Title is required')
      .min(3, 'At least 3 characters required')
      .max(100, 'Maximum 100 characters')
      .validate(formValues.title)
      .then(() => {
        setTitleValidationError('');
        return true;
      })
      .catch(err => {
        setTitleValidationError(err.message);
        return false;
      });
    const descResult = yup
      .string()
      .required('Description is required')
      .min(3, 'At least 3 characters required')
      .max(500, 'Maximum 500 characters')
      .validate(formValues.description)
      .then(() => {
        setDescriptionValidationError('');
        return true;
      })
      .catch(err => {
        setDescriptionValidationError(err.message);
        return false;
      });
    const placementResult = yup
      .string()
      .required('Placement is required')
      .min(1, 'At least 1 characters required')
      .max(30, 'Maximum 30 characters')
      .validate(formValues.placement)
      .then(() => {
        setPlacementValidationError('');
        return true;
      })
      .catch(err => {
        setPlacementValidationError(err.message);
        return false;
      });
    const allPromises = await Promise.all([
      descResult,
      valResult,
      placementResult,
    ]);
    return allPromises.reduce((prev, current) => {
      return prev && current;
    });
  };
  const handleSaveClicked = async () => {
    if (await validateInputs()) {
      onAddEdit(formValues, isEditMode);
    }
  };

  useEffect(() => {
    if (!open) {
      setPlacementValidationError('');
      setDescriptionValidationError('');
      setTitleValidationError('');
    }
  }, [open]);

  /* Placement dropdown array */
  const placements = [
    { value: 'bottom-end', label: 'Bottom-End' },
    { value: 'bottom-start', label: 'Bottom-Start' },
    { value: 'bottom', label: 'Bottom' },
    { value: 'left-end', label: 'Left-End' },
    { value: 'left-start', label: 'Left-Start' },
    { value: 'left', label: 'Left' },
    { value: 'right-end', label: 'Right-End' },
    { value: 'right-start', label: 'Right-Start' },
    { value: 'right', label: 'Right' },
    { value: 'top-end', label: 'Top-End' },
    { value: 'top-start', label: 'Top-Start' },
    { value: 'top', label: 'Top-End' },
  ];

  return (
    <Dialog open={open} className={classes.DialogBox} fullWidth>
      <DialogTitle id="dialog-title">
        <div>{isEditMode ? 'Edit Step' : 'Add Step'}</div>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => onClose()}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div>
          <TextField
            label="Title"
            required
            fullWidth
            multiline
            variant="outlined"
            name="title"
            value={formValues.title}
            onChange={handleChange}
            {...(_titleValidationErr && {
              error: true,
              helperText: _titleValidationErr,
            })}
          />
        </div>
        <div>
          <TextField
            className={classes.stepsMargin}
            label="Description"
            required
            fullWidth
            multiline
            variant="outlined"
            name="description"
            value={formValues.description}
            onChange={handleChange}
            {...(_descriptionValidationErr && {
              error: true,
              helperText: _descriptionValidationErr,
            })}
          />
        </div>
        <div className={`${classes.stepsMargin} ${classes.flex}`}>
          <Typography component="div">Click Indicator</Typography>
          <FormGroup className={classes.toggleSwitch}>
            <FormControlLabel
              control={
                <Switch
                  onChange={handleChange}
                  name="enableClick"
                  defaultChecked={formValues.enableClick}
                />
              }
              label=""
            />
          </FormGroup>
        </div>

        <div>
          <TextField
            className={classes.stepsMargin}
            label="Selector"
            fullWidth
            multiline
            minRows={3}
            variant="outlined"
            name="selector"
            value={formValues.selector}
            onChange={handleChange}
          />
        </div>
        <div className={classes.stepsMargin}>
          <Typography component="h2" className={classes.instructionsHeading}>
            Steps to copy css selector in browser:
          </Typography>
          <div>
            1. Right-click "inspect" on the item you are trying to find the
            XPath.
            <br /> 2. Right-click on the highlighted area on the HTML DOM.
            <br />
            3. Go to Copy select 'Copy Selector'
          </div>
        </div>
        <div className={classes.stepsMargin}>
          <FormControl
            fullWidth
            variant="outlined"
            required
            {...(_placementValidationErr && {
              error: true,
            })}
          >
            <InputLabel id="placement-select-label">Placement</InputLabel>
            <Select
              labelId="placement-select-label"
              label="Placement"
              fullWidth
              name="placement"
              variant="outlined"
              value={formValues.placement}
              onChange={(event: any) => {
                handleChange(event);
              }}
            >
              {placements.map((placement: any) => {
                return (
                  <MenuItem value={placement.value} key={placement.value}>
                    {placement.label}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>{_placementValidationErr}</FormHelperText>
          </FormControl>
        </div>
      </DialogContent>

      <DialogActions className={classes.stepBtnsMargin}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.popupBtns}
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.popupBtns}
          onClick={handleSaveClicked}
        >
          {isEditMode ? 'Edit' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddStepPopup;
