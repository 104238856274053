import { parseEntityRef } from '@backstage/catalog-model';
import { entityRouteRef } from '@backstage/plugin-catalog-react';
import { Box } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';
import React from 'react';
import { ScaffolderTaskOutput } from '../types';
import { IconLink } from './IconLink';
import { IconComponent, useApp, useRouteRef } from '@backstage/core-plugin-api';

type TaskPageLinksProps = {
  output: ScaffolderTaskOutput;
};

export const TaskPageLinks = ({ output }: TaskPageLinksProps) => {
  const { entityRef: entityRefOutput, remoteUrl } = output;
  let { links = [] } = output;
  const app = useApp();
  const entityRoute = useRouteRef(entityRouteRef);

  const iconResolver = (key?: string): IconComponent =>
    key ? app.getSystemIcon(key) ?? LanguageIcon : LanguageIcon;

  if (remoteUrl) {
    let newRemoteUrl = remoteUrl;
    if (remoteUrl.includes('dev.azure.com')) {
      newRemoteUrl = `https://${remoteUrl?.substring(
        remoteUrl?.indexOf('dev.azure.com'),
      )}`;
    }
    links = [{ url: newRemoteUrl, title: 'View Repository' }, ...links];
  }

  if (entityRefOutput) {
    links = [
      {
        entityRef: entityRefOutput,
        title: 'Open in catalog',
        icon: 'catalog',
      },
      ...links,
    ];
  }

  return (
    <Box px={3} pb={3}>
      {links
        .filter(({ url, entityRef }) => url || entityRef)
        .map(({ url, entityRef, title, icon }) => {
          if (entityRef) {
            const entityName = parseEntityRef(entityRef);
            const target = entityRoute(entityName);
            return { title, icon, url: target };
          }
          return { title, icon, url: url! };
        })
        .map(({ url, title, icon }, i) => (
          <IconLink
            key={`output-link-${i}`}
            href={url}
            text={title ?? url}
            Icon={iconResolver(icon)}
            target="_blank"
          />
        ))}
    </Box>
  );
};
