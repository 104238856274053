import React from 'react';
import {
  PlainApiDefinitionWidget,
  // OpenApiDefinitionWidget,
} from '@backstage/plugin-api-docs';
import { CardTab, TabbedCard } from '@backstage/core-components';
import { CustomApiDefinitionWidget } from './CustomApiDefinitionWidget';

type MockAPICard = {
  definition: any;
  title: string;
};

export const MockApiDefinitionCard = (props: MockAPICard) => {
  const prettyPrint = (str: string) => {
    try {
      const parsedString = JSON.parse(str);
      var obj = JSON.stringify(parsedString, null, 2);
      return obj;
    } catch {
      return str;
    }
  };

  return (
    <TabbedCard
      title={props.title}
      children={[
        <CardTab label="Mock environment" key="swagger">
          <CustomApiDefinitionWidget definition={props.definition} />
        </CardTab>,
        <CardTab label="OpenAPI definition" key="raw">
          <PlainApiDefinitionWidget
            definition={prettyPrint(props.definition)}
            language="Mock API"
          />
        </CardTab>,
      ]}
    />
  );
};
