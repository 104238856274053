/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { IPromptData, IPromptTag } from '../interfaces';
import { DateTime } from 'luxon';
import { useStarredEntities } from '@backstage/plugin-catalog-react';

export const useFilteredPrompts = (
  prompts: IPromptData[],
  filterOption: string,
  formatedTags: IPromptTag[],
  searchText: string,
  bookmarkSelected: boolean,
) => {
  const [filteredPrompts, setFilteredPrompts] = useState(prompts);
  const { isStarredEntity, starredEntities } = useStarredEntities();

  useEffect(() => {
    let localPrompts = [];

    const selectedTags = formatedTags
      .filter((tag: IPromptTag) => tag.selected)
      .map((tag: IPromptTag) => tag.label.toLowerCase());

    const getTimeMillis = (dateString: string) => {
      return DateTime.fromFormat(dateString, 'dd-MM-yyyy HH:mm:ss').toMillis();
    };

    const status =
      filterOption === 'All status' ? '' : filterOption.toLowerCase();

    localPrompts = prompts?.sort(
      (a: any, b: any) =>
        getTimeMillis(b.createdat) - getTimeMillis(a.createdat),
    );
    if (selectedTags.length > 0)
      localPrompts = localPrompts.filter((prompt: IPromptData) => {
        return prompt.tags.some((tag: string) =>
          selectedTags.includes(tag.toLowerCase()),
        );
      });

    localPrompts = localPrompts.filter((prompt: IPromptData) => {
      return prompt.status.toLowerCase().includes(status);
    });

    localPrompts = localPrompts.filter((prompt: IPromptData) => {
      return (
        prompt.promptkey
          .toLowerCase()
          .replace(/\s+/g, ' ')
          .trim()
          .includes(searchText.toLowerCase()) ||
        prompt.authordisplayname
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        prompt.tags.some((tag: string) =>
          tag.toLowerCase().includes(searchText.toLowerCase()),
        )
      );
    });

    if (bookmarkSelected) {
      localPrompts = localPrompts.filter((prompt: IPromptData) =>
        isStarredEntity(`PromtStore:${prompt.id}`),
      );
    }

    setFilteredPrompts(localPrompts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    prompts,
    filterOption,
    formatedTags,
    searchText,
    bookmarkSelected,
    starredEntities,
  ]);

  return [filteredPrompts];
};
