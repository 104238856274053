import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';

const RegisterSteps = () => {
  const classes = useStyles();

  return (
    /* Register Steps Component */
    <Grid container spacing={3}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={`${classes.registerApi} ${classes.registerSteps}`}
      >
        <Typography className={classes.stepsHeading} component="div">
          Self Registration of APIs
        </Typography>
        <div className={classes.divider}></div>
        <p>
          This feature was built to help register self-hosted APIs or APIs
          hosted outside of WSO2.
        </p>
        <ul className={classes.registerInfo}>
          <li className={classes.registerText}>
            Drop in the swagger file for the API.
          </li>
          <li className={classes.registerText}>
            Fill in all the fields to the best of your ability.
          </li>
          <li className={classes.registerText}>
            APM Number – It is important to keep track of source of the API and
            for any follow ups required.
          </li>
        </ul>
      </Grid>
    </Grid>
  );
};
export default RegisterSteps;
