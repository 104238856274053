const REGISTER_API_CONSTANTS = {
  registerApiForm: {
    heading: 'Registration of API',
    apiTitle: {
      label: 'API Title',
      placeholder: '',
    },
    apmNumberRegex: /APM?\d{7}/,
    versionRegex: /^(\d+)(\.\d+)*$/,
    apiDescription: {
      label: 'API Description',
      placeholder: '',
    },
    apiType: {
      label: 'API Type',
    },
    apiScope: {
      label: 'API Scope',
      placeholder: '',
    },
    tags: {
      label: 'Tags',
      placeholder: '',
    },
    lifeCycle: {
      label: 'Life Cycle',
    },
    apiVersion: {
      label: 'API Version',
      placeholder: '',
    },
    hostedLocation: {
      label: 'Hosted Location',
    },
    documentationUrl: {
      label: 'Support/Documentation URL',
      placeholder: '',
    },
    segment: {
      label: 'Segment',
    },
    region: {
      label: 'Region',
    },
    // apiCategory: {
    //   label: 'API Category',
    // },
    apmNumber: {
      label: 'APM Number',
      placeholder: '',
    },
    service: {
      label: 'Service',
      placeholder: '',
    },
    // performance: {
    //   label: 'Performance',
    //   placeholder: '',
    // },
    tagsMsg: '*special characters/space/numbers not accepted',
    errors: {
      apiTitle: 'API Title field is required',
      apiDescription: 'API Description field is required',
      apiDefinition: 'Invalid File',
      apiType: 'API Type field is required',
      apiScope: 'API Scope field is required',
      tags: 'Tags field is required',
      lifeCycle: 'Lifecycle field is required',
      apiVersion: 'API Version field is required',
      apiVersionInvalid: 'API Version is invalid',
      support: 'Enter valid url/leave it blank',
      // apiCategory: 'Api Category field is required',
      apmNumber: 'APM Number field is required',
      apmNumberInvalid: 'APM Number not valid',
      // service: 'Service field is required',
      // performance: 'Performance field is required',
    },
  },
  confirmDetails: {
    confirmHeading: 'Confirm Details',
    techOwner: {
      label: 'SUPPORT OWNER',
    },
    productOwner: {
      label: 'BUSINESS OWNER',
    },
    hostedOn: {
      label: 'HOSTED ON',
    },
  },
  registerSuccess: {
    successMsg: 'API Registered Successfully',
    failedMsg: 'API Registered Failed',
  },
  generateSwagger: {
    generateApiTitle: 'Enter API Details',
    generateApiDef: 'Generated API Definition',
    Title: {
      label: 'Title',
      placeholder: '',
    },
    basePath: {
      label: 'Base Path',
      placeholder: '',
    },
    Description: {
      label: 'Description',
      placeholder: '',
    },
    Version: {
      label: 'Version',
      placeholder: '',
    },
    host: {
      label: 'Host',
      placeholder: '',
    },
  },
  swaggerApiForm: {
    requestText: 'Request',
    subPath: {
      label: 'Method/Sub Path',
      placeholder: '',
    },
    operationId: {
      label: 'Operation Id',
      placeholder: '',
    },
    url: {
      label: 'Url',
      placeholder: '',
    },
    description: {
      label: 'Description',
      placeholder: '',
    },
    values: {
      label: 'Name',
      placeholder: '',
    },
    contentType: {
      label: 'Content Type',
    },
    paramHeading: 'Parameters (Query,Headers, Cookie)',
    serverHeading: 'Servers',
    responseHeading: 'Response',
    infoHeading: 'Info',
    code: {
      label: 'Code',
    },
    required: {
      label: 'required',
    },
    schema: {
      label: 'Schema',
      placeholder: '',
    },
  },
};
export default REGISTER_API_CONSTANTS;
