import React, { useEffect, useRef, useState } from 'react';
import {
  appThemeApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { Box, ClickAwayListener, Popover } from '@material-ui/core';
import {
  PersonCard,
  Providers,
  ProviderState,
  SimpleProvider,
} from '@microsoft/mgt-react';
import { userApiRef } from '../../../apis/userApi';
import { debounce } from 'lodash';
import {
  IUserProfileModal,
  userProfileApiRef,
} from '../../../apis/userProfileApi';
import { THEME_IDS } from '../../../App';

export interface IUserPopupProps {
  email: string | undefined;
}

const UserPopup = () => {
  const [userState, setUserState] = useState<IUserProfileModal>();
  const [userDetails, setUserDetails] = useState<any>();

  const appThemeApi = useApi(appThemeApiRef);
  const activeThemeId = appThemeApi.getActiveThemeId();

  const mousePosition = useRef({
    x: 0,
    y: 0,
  });

  const userProfileApi = useApi(userProfileApiRef);
  const graphUserApi = useApi(userApiRef);

  const microsoftApi = useApi(microsoftAuthApiRef);

  const getAccessTokenHandler = async () => {
    return await microsoftApi.getAccessToken();
  };

  const provider = new SimpleProvider(getAccessTokenHandler);
  provider.setState(ProviderState.SignedIn);
  Providers.globalProvider = provider;

  const onMouseMove = (event: any) => {
    const x = event?.clientX;
    const y = event?.clientY;
    mousePosition.current = { x, y };
  };

  const callback = debounce((value: IUserProfileModal) => {
    const anchorRef = value.anchorRef;
    if (anchorRef === undefined) {
      setUserDetails(undefined);
      setUserState(undefined);
      return;
    }
    const rect = anchorRef.getBoundingClientRect();

    const mouseXPosition = mousePosition.current.x;
    const mouseYPosition = mousePosition.current.y;

    // check within horizontal rect
    if (
      rect.left <= mouseXPosition &&
      rect.left + rect.width >= mouseXPosition
    ) {
      if (
        rect.top <= mouseYPosition &&
        rect.top + rect.height >= mouseYPosition
      ) {
        setUserState(value);
        setUserDetails(undefined);
        if (value.email) {
          graphUserApi
            .getDetails(value.email)
            .then(data => {
              setUserDetails(data);
            })
            .catch(() => {
              setUserDetails(undefined);
            });
        }
      } else {
        setUserDetails(undefined);
        setUserState(undefined);
      }
    } else {
      setUserDetails(undefined);
      setUserState(undefined);
    }
  }, 500);

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove);
    let subscription: { unsubscribe: any } | undefined = undefined;
    if (userProfileApi) {
      subscription = userProfileApi
        .publisher$()
        .subscribe((value: IUserProfileModal) => {
          callback(value);
        });
    }
    return () => {
      if (subscription !== undefined) {
        subscription.unsubscribe();
      }
      window.removeEventListener('mousemove', onMouseMove);
    };
  });

  const onClose = () => {
    setUserDetails(undefined);
    setUserState(undefined);
  };

  return (
    <>
      {userDetails !== undefined &&
        userState !== undefined &&
        userState.email !== undefined && (
          <Popover
            open={userState !== undefined && userState.email !== undefined}
            anchorEl={userState?.anchorRef}
            onMouseOut={onClose}
            onClose={onClose}
            BackdropProps={{
              style: {
                display: 'none',
              },
            }}
            hideBackdrop
          >
            <Box>
              <ClickAwayListener onClickAway={onClose}>
                <PersonCard
                  className={
                    activeThemeId === THEME_IDS.dark
                      ? 'mgt-dark custom-person-card'
                      : 'mgt-light custom-person-card'
                  }
                  personDetails={{
                    emailAddresses: [
                      {
                        address: userState.email,
                        name: 'email',
                      },
                    ],
                    ...userDetails,
                  }}
                />
              </ClickAwayListener>
            </Box>
          </Popover>
        )}
    </>
  );
};
export default UserPopup;
