import React, { useContext, useEffect } from 'react';
import { PromotionContext } from '../../providers/PromotionDataProvider';
import CancelIcon from '@material-ui/icons/Cancel';
import { Button } from '@material-ui/core';
import HelpPageRender from './helpPage';
import GenerateSwagger from '../api-doc/register-api/GenerateSwagger';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightPanel: {
      background: theme.palette.background.paper,
      padding: '10px 20px',
      width: '30%',
      overflow: 'auto',
      height: '100vh',
      animation: 'reveal 2s forwards',
      zIndex: 1,
      ['@media (max-width:600px)']: {
        position: 'fixed',
        width: '100% !important',
      },
    },
    swaggerRightPanel: {
      width: '48%',
    },
  }),
);

export const HelpContainer = () => {
  const location = useLocation();
  const myContext = useContext(PromotionContext);
  const classes = useStyles();

  const handleHelpModal = () => {
    myContext.setToggleHelpButton(!myContext.toggleHelpButton);
  };

  const handleSwaggerModal = () => {
    myContext.setToggleSwaggerButton(!myContext.toggleSwaggerButton);
  };

  useEffect(() => {
    myContext.setToggleHelpButton(false);
    myContext.setToggleSwaggerButton(false);
  }, [location]);

  if (myContext.toggleHelpButton) {
    return (
      <div className={classes.rightPanel}>
        <Button
          onClick={handleHelpModal}
          role="button"
          color="primary"
          variant="text"
          className="helpCancelButton"
        >
          <CancelIcon />
        </Button>
        <HelpPageRender pageName={myContext.helpUrl} />
      </div>
    );
  }

  if (myContext.toggleSwaggerButton) {
    return (
      <div className={`${classes.rightPanel} ${classes.swaggerRightPanel}`}>
        <Button
          onClick={handleSwaggerModal}
          role="button"
          color="primary"
          variant="text"
          className="helpCancelButton"
        >
          <CancelIcon />
        </Button>
        <GenerateSwagger />
      </div>
    );
  }
  return null;
};

export default HelpContainer;
