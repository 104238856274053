import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { BackstageTheme } from '@backstage/theme';

export const useRNStyles = makeStyles<BackstageTheme>((theme: Theme) =>
  createStyles({
    PagePaginationContainer: {
      width: '100%',
      marginTop: '10px',
      ['@media (max-width:920px) and (min-width: 280px)']: {
        padding: '0px 5px 0px 7px !important',
        marginTop: '18px',
      },
    },
    filterBox: {
      margin: '0px 0px 0px 0px',
    },
    spaceBox: {
      margin: '0px 8px 8px 8px',
    },
    filterSpaceBox: {
      margin: '0px 8px 0px 8px',
    },
    searchBox: {
      display: 'flex',
    },
    filterButton: {
      color: 'primary',
      padding: '16px 16px 15px 16px',
    },
    filter: {
      borderBottom: '1px solid #d2dbd9',
      padding: '8px 16px',
      marginLeft: theme.spacing(1),
      '&:hover': {
        backgroundColor: theme.palette.background.tertiary,
      },
      [theme.breakpoints.down('md')]: {
        minWidth: '300px',
        margin: '0',
      },
    },
    filterDesc: {
      marginTop: '8px',
    },
    filterTitle: {
      margin: '0px 16px 8px',
      fontSize: '1rem',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.25rem',
      },
    },
    filterActive: {
      backgroundColor: theme.palette.background.paper,
      marginRight: '-1px',
      zIndex: 1,
      position: 'relative',
    },
    rightAlign: {
      float: 'right',
    },
    content: {
      padding: '1.5rem',
    },
    contentDate: {
      color: '#767676',
      fontSize: '0.875rem',
    },
    flex: {
      display: 'flex',
      justifyContent: 'space-between',
      verticalAlign: 'middle',
      alignItems: 'center',
    },
    drawer: {
      paddingTop: '1rem',
    },
    adminContainer: {
      padding: '24px',
      width: '100%',
    },
    addButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.contrast,
    },
    adminCards: {
      margin: `0px ${theme.spacing(3)}px`,
      width: '100%',
      padding: '24px 16px',
    },
    iconButton: {
      color: theme.palette.primary.main,
    },
    releaseNoteBlock: {
      margin: `${theme.spacing(3)}px 0`,
    },
    lightText: {
      color: theme.palette.text.muted,
    },
    calendarColor: {
      backgroundColor: theme.palette.background.paper,
      '& input': {
        colorScheme: theme.palette.type,
        paddingRight: '39px',
      },
    },
  }),
);
