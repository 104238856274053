import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { customTypes } from '../../extensions';
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';
import { pocHubApiRef } from '../../../../apis/pocHubApi';
import { pocNameRegex } from '../../utils';

/**
 * APMField
 */

export const PocNamePicker = (
  props: customTypes.FieldExtensionComponentProps<string>,
) => {
  const {
    onChange,
    required,
    schema: { title = 'Poc Name', description = 'Poc Name' },
    rawErrors,
    formData,
    uiSchema: { 'ui:autofocus': autoFocus },
    idSchema,
    placeholder,
  } = props;

  const [repoNameList, setRepoNameList] = useState([]);
  const [repoExists, setRepoExists] = useState('');
  const [name, setName] = useState('');
  const pocHubApiCheckingRepoName = useApi(pocHubApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const onInputChange = async (value: string) => {
    setName(value);

    if (value == '') {
      onChange(value);
    }

    if (value && value?.trim()) {
      const regex = new RegExp(pocNameRegex);
      console.log('value', value.length);
      if (value.length > 100) {
        setRepoExists('Name is too long (maximum is 100 characters)');
        return;
      }

      if (regex.test(value)) {
        if (repoNameList && repoNameList?.includes(value?.toLowerCase())) {
          setRepoExists('This Repository Already Exists');
        } else {
          setRepoExists('');
          onChange(value);
        }
      } else {
        setRepoExists('Accept only alpha numerics, no special characters ');
        onChange(value);
      }
    }
  };

  useEffect(() => {
    pocHubApiCheckingRepoName
      .getEntityNameList()
      .then((data: any) => {
        console.log(data.data);
        setRepoNameList(data?.data);
      })
      .catch((err: any) => {
        setRepoNameList([]);
        notificationApi.sendNotification({
          message: `Error occurred - ${err?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  }, []);

  const helperText = (repoExists: string) => {
    return <span style={{ color: '#FF543E' }}>{repoExists}</span>;
  };

  return (
    <TextField
      id={idSchema?.$id}
      label={title}
      placeholder={placeholder}
      helperText={repoExists ? helperText(repoExists) : description}
      FormHelperTextProps={{
        error: false,
      }}
      required={required}
      value={name ?? ''}
      onChange={({ target: { value } }) => onInputChange(value)}
      margin="normal"
      error={rawErrors?.length > 0 && !name}
      inputProps={{ autoFocus }}
    />
  );
};
