import React from 'react';
import Gold from './icons/gold';
import Silver from './icons/silver';
import Bronze from './icons/bronze';
import Platinum from './icons/platinum';
import Diamond from './icons/diamond';
import Grandmaster from './icons/grandmaster';
import Feedback from './icons/feedback';
import Subscriber from './icons/subscriber';
import SubscribingAsset from './icons/subscribingasset';
import Rating from './icons/rating';
import Asset from './icons/asset';
import Prmerged from './icons/prMerged';
import Adhoc from './icons/adhoc';
import ExtDocs from './icons/extdocs';
import TechDoscs from './icons/techdocs';
import SoftwareTemplate from './icons/softwareTemplateDownload';
import RatingReceived from './icons/ratingReceived';
import ApprovedAssets from './icons/reviewApprovalReceived';
import AcceptedAnswer from './icons/accepted_answer';
import AnswerQuestion from './icons/answer_question';
import PostQuestion from './icons/post_question';
import AnswerVoteGiven from './icons/answerVoteGiven';
import AnswerVoteReceived from './icons/answerVoteReceived';
import QuestionVoteReceived from './icons/questionVoteReceived';
import QuestionVoteGiven from './icons/questionVoteGiven';

export interface BadgeDisplayImageProps {
  type?: string | undefined;
}

export const BadgeDisplayImage = (props: BadgeDisplayImageProps) => {
  switch (props?.type) {
    case 'silver':
    case 'Silver':
      return <Silver />;
    case 'gold':
    case 'Gold':
      return <Gold />;
    case 'bronze':
    case 'Bronze':
      return <Bronze />;
    case 'platinum':
    case 'Platinum':
      return <Platinum />;
    case 'diamond':
    case 'Diamond':
      return <Diamond />;
    case 'Grandmaster':
    case 'grandmaster':
      return <Grandmaster />;
    case 'FEEDBACK_GIVEN':
      return <Feedback />;
    case 'FEEDBACK_ADDED':
      return <Feedback />;
    case 'PUBLISHED_ASSET':
      return <Asset />;
    case 'PR_MERGED':
      return <Prmerged />;
    case 'SUBSCRIBER_ADDED':
      return <Subscriber />;
    case 'SUBSCRIBING_ASSET':
      return <SubscribingAsset />;
    case 'RATING_GIVEN':
      return <Rating />;
    case 'RATING_RECEIVED':
      return <RatingReceived />;
    case 'adhoc':
    case 'ADHOC':
      return <Adhoc />;
    case 'extdocs':
    case 'PUBLISHED_EXTDOCS':
      return <ExtDocs />;
    case 'PUBLISHED_TECHDOCS':
      return <TechDoscs />;
    case 'SW_TEMPLATE_DWNLD':
      return <SoftwareTemplate />;
    case 'REVIEW_APPROVAL_RECEIVED':
      return <ApprovedAssets />;
    case 'ANSWER_QUESTION':
      return <AnswerQuestion />;
    case 'ANSWER_VOTE_GIVEN':
      return <AnswerVoteGiven />;
    case 'ANSWER_VOTE_RECEIVED':
      return <AnswerVoteReceived />;
    case 'ANSWER_ACCEPTED':
      return <AcceptedAnswer />;
    case 'QUESTION_VOTE_GIVEN':
      return <QuestionVoteGiven />;
    case 'QUESTION_VOTE_RECEIVED':
      return <QuestionVoteReceived />;
    case 'POST_QUESTION':
      return <PostQuestion />;
    default:
      return <Asset />;
  }
};

export default BadgeDisplayImage;
