import React, { useState, useEffect, useRef, useContext } from 'react';
import GuidedTour from '../guided-tour';
//import tourConfig from './tourConfig';
import { useApi } from '@backstage/core-plugin-api';
import { WhatsNewApi, whatsNewApiRef } from '../../apis/whatsNew';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';
import { GuidedTourContext } from '../guided-tour/providers/GuidedTourProvider';

const DevxGuidedTour = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const whatsnew: WhatsNewApi = useApi(whatsNewApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const backendTourConfig = useRef(null);
  const [tourConfig, setTourConfig] = useState<any>(null);
  const tourContext = useContext(GuidedTourContext);

  const formatTourConfig = (pagesData: any) => {
    if (pagesData === null) {
      setTourConfig(null);
      return;
    }
    let samepagePaths = {};
    let pages = {};

    const activePages = pagesData.filter((page: any) => page.isActive);
    if (activePages.length === 0) {
      setTourConfig(null);
      return;
    }

    activePages.forEach((page: any) => {
      samepagePaths = { ...samepagePaths, [page.duplicates]: page.pageRoute };
      pages = { ...pages, [page.pageRoute]: page.steps };
    });
    setTourConfig({ samepagePaths, pages });
  };

  /* Get the tourConfig from api */
  const getTourConfig = () => {
    setIsLoading(true);
    whatsnew
      .getTourConfig()
      .then(responseData => {
        backendTourConfig.current = responseData?.data;
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        notificationApi.sendNotification({
          message: 'Failed to load data',
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  useEffect(() => {
    tourContext.setTourConfig(tourConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourConfig]);

  useEffect(() => {
    formatTourConfig(backendTourConfig.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backendTourConfig.current]);

  useEffect(() => {
    getTourConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{tourConfig ? <GuidedTour tourConfig={tourConfig} /> : null}</>;
};

export default DevxGuidedTour;
