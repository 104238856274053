import { createApiRef } from '@backstage/core-plugin-api';
import { AxiosInstance, AxiosResponse } from 'axios';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';

export interface TowerInfoApi {
  getTowerInfo: (repoName: string) => Promise<AxiosResponse>;

}

export const towerInfoApiRef = createApiRef<TowerInfoApi>({
  id: 'cbre.devx.api.tower-info',
});

export class TowerInfoApiImpl implements TowerInfoApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  async getTowerInfo(repoName: string): Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;
    return instance.get(`service/discovery/cataloginfo/asset/${repoName}`);
  }

 
}
