import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import {
  useApi,
  identityApiRef,
  microsoftAuthApiRef,
} from '@backstage/core-plugin-api';
import { customTypes } from '../../extensions';
import { useTemplateSecrets } from '../../SecretsContext';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';
import { additionalConfigApiRef } from '../../../../apis/additionalConfigApi';
import { swTemplateGithubApiRef } from '../../../scaffolder/fields/DevxRepoPicker/api/SWTemplateGithubApi';

/**
 * LoggedInUserPicker
 */
export const LoggedInUserPicker = (
  props: customTypes.FieldExtensionComponentProps<string>,
) => {
  const {
    onChange,
    required,
    schema: {
      title = 'Current user',
      description = 'Currently Logged In user',
    },
    rawErrors,
    formData,
    uiSchema: { 'ui:autofocus': autoFocus },
    idSchema,
    placeholder,
  } = props;

  const identityApi = useApi(identityApiRef);
  const msAuthApi = useApi(microsoftAuthApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const additionalConfigApi = useApi(additionalConfigApiRef);
  const githubApi = useApi(swTemplateGithubApiRef);
  const { setSecrets } = useTemplateSecrets();

  useEffect(() => {
    if (identityApi) {
      if (!formData) {
        identityApi.getProfileInfo().then((value: any) => {
          onChange(value.email?.toLowerCase());
        });
      }
    }
  }, [identityApi]);

  useEffect(() => {
    if (msAuthApi && additionalConfigApi) {
      msAuthApi
        .getAccessToken()
        .then((token: any) => {
          setSecrets({ ['AUTH_BACKEND_API_ACCESS_TOKEN']: token });
        })
        .catch((err: any) => {
          notificationApi.sendNotification({
            message: `Error occurred - ${err?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });
      additionalConfigApi
        .getPocHubConfig()
        .then((data: any) => {
          setSecrets({
            ['PUBLISHER_USER_OAUTH_TOKEN']: data?.data?.gitHubPublisherToken,
            ['PUBLISHER_GIT_REPO_NAME']: data?.data?.gitHubPulisherOrgName,
          });
          if (data?.data?.gitHubPublisherToken) {
            githubApi
              .getUserProfileAndOrgs(`${data?.data?.gitHubPublisherToken}`)
              .then((data: any) => {
                setSecrets({                
                  ['PUBLISHER_INFO']: data?.owner,
                  orgs: data?.organizations
                });
                
              })
              .catch((err: any) => {                
                notificationApi.sendNotification({
                  message: `Error occurred - ${err?.message}`,
                  disapperAfterMs: 2500,
                  severity: 'error',
                });
              });
          }
        })
        .catch((err: any) => {
          notificationApi.sendNotification({
            message: `Error occurred - ${err?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });
    }
  }, [msAuthApi]);

  return (
    <TextField
      id={idSchema?.$id}
      hidden={true}
      style={{ display: 'none' }}
      label={title}
      placeholder={placeholder}
      helperText={description}
      required={required}
      value={formData ?? ''}
      onChange={({ target: { value } }) => onChange(value)}
      margin="normal"
      error={rawErrors?.length > 0 && !formData}
      inputProps={{ autoFocus }}
    />
  );
};
