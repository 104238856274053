import React, { useContext } from 'react';
import {
  TableColumn,
  Table,
  WarningPanel,
  CodeSnippet,
  TableProps,
} from '@backstage/core-components';
import {
  humanizeEntityRef,
  getEntityRelations,
  useStarredEntities,
} from '@backstage/plugin-catalog-react';
import * as columnFactories from '../../../utils/devXTableColumns';
import { RELATION_OWNED_BY, RELATION_PART_OF } from '@backstage/catalog-model';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { Link } from 'react-router-dom';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import { AuthContext } from '../../../../providers/AuthProvider';
import {
  COMPONENT_TYPE,
  COMPONENT,
  API,
  IAC_COMPONENT_TYPE,
  SALESFORCE_COMPONENT_TYPE,
} from '../../../utils/constant';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { NoAssetsFound } from '../../../noAssetsFound/NoAssetsFound';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    themeColor: {
      '& tr': {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.background.default + '!important',
        '& td ': {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: theme.palette.background.default + '!important',
          '& button ': {
            color: theme.palette.primary.main,
          },
        },
        '& th': {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: theme.palette.background.default + '!important',
        },
      },
      '& div::-webkit-scrollbar-thumb': {
        border: `4px solid ${theme.palette.text.tertiary}`,
      },
    },
  }),
);
const columns: TableColumn<columnFactories.EntityRow>[] = [
  columnFactories.createNameColumnCustomRoot({ rootRoute: 'catalog' }),
  columnFactories.createOwnerColumn(),
  columnFactories.createSpecTypeColumn(),
  columnFactories.createSpecLifecycleColumn(),
  columnFactories.createMetadataDescriptionColumn(),
  columnFactories.createTagsColumn(),
  columnFactories.createStatusColumn(),
  columnFactories.createRatingColumn(),
];

export const MyStuffCodeTable = ({
  loading,
  error,
  entities,
  checkFilterItem,
  starredFilterItem,
}: any) => {
  const classes = useStyles();
  const { isStarredEntity, toggleStarredEntity } = useStarredEntities();
  let tableTitle = 'Owned';

  const { profEmail, isAdmin } = useContext(AuthContext);

  const defaultActions: TableProps<columnFactories.EntityRow>['actions'] = [
    ({ entity }) => {
      return {
        icon: () => <></>,
        tooltip: 'Edit',
        onClick: () => {},
      };
    },

    ({ entity }) => {
      const name = entity.metadata.name;
      const kind = entity.kind;
      const namespace = entity.metadata.namespace;
      const path =
        kind?.toLowerCase() === API.toLowerCase() ? 'api-edit' : 'edit';
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const userProfile = profEmail?.split('@')[0].toLowerCase();
      const entityOwner = `${entity?.spec?.owner}`;
      const userOwnerShip =
        isAdmin ||
        userProfile?.toLowerCase() ===
          entityOwner.replace('user:', '')?.toLowerCase();
      return {
        icon: () => (
          <Link to={`/catalog/${namespace}/${kind}/${name}/${path}`}>
            <EditRoundedIcon fontSize="small" />
          </Link>
        ),
        tooltip: 'Edit',
        disabled: !userOwnerShip,
        onClick: () => {},
      };
    },

    ({ entity }) => {
      const isStarred = isStarredEntity(entity);
      return {
        cellStyle: { paddingLeft: '1em' },
        icon: () =>
          isStarred ? <BookmarkIcon /> : <BookmarkBorderOutlinedIcon />,
        tooltip: 'Bookmark',
        onClick: () => toggleStarredEntity(entity),
      };
    },
  ];

  let filterData =
    entities && entities.length
      ? entities.filter(
          (item: any) =>
            (item.kind?.toLowerCase() === COMPONENT.toLowerCase() &&
              COMPONENT_TYPE.includes(item.spec?.type?.toLowerCase())) ||
            item.kind?.toLowerCase() === API.toLowerCase(),
        )
      : [];

  if (checkFilterItem.isCodeFilterClick) {
    tableTitle = 'Code';
    filterData =
      entities &&
      entities.length &&
      entities.filter(
        (item: any) =>
          item.kind?.toLowerCase() === COMPONENT?.toLowerCase() &&
          COMPONENT_TYPE.includes(item.spec?.type?.toLowerCase()) &&
          item?.spec?.type?.toLowerCase() !== IAC_COMPONENT_TYPE?.toLowerCase(),
      );
  }

  if (checkFilterItem.isApiFilterClick) {
    tableTitle = 'API';
    filterData =
      entities &&
      entities.length &&
      entities.filter(
        (item: any) => item.kind?.toLowerCase() === API?.toLowerCase(),
      );
  }

  if (checkFilterItem.isIacFilterClick) {
    tableTitle = 'Infrastructure';
    filterData =
      entities &&
      entities.length &&
      entities.filter(
        (item: any) =>
          item.kind?.toLowerCase() === COMPONENT?.toLowerCase() &&
          item?.spec?.type?.toLowerCase() === IAC_COMPONENT_TYPE?.toLowerCase(),
      );
  }

  if (checkFilterItem.isSalesforceFilterClick) {
    tableTitle = 'Salesforce';
    filterData =
      entities &&
      entities.length &&
      entities.filter(
        (item: any) =>
          item.kind?.toLowerCase() === COMPONENT?.toLowerCase() &&
          COMPONENT_TYPE.includes(item.spec?.type?.toLowerCase()) &&
          item?.spec?.type?.toLowerCase() ===
            SALESFORCE_COMPONENT_TYPE?.toLowerCase(),
      );
  }

  if (checkFilterItem.isFavoriteFilterClick) {
    tableTitle = 'Bookmark';
    filterData = starredFilterItem;
  }

  const rows =
    filterData &&
    filterData.map((entity: any) => {
      const partOfSystemRelations = getEntityRelations(
        entity,
        RELATION_PART_OF,
        {
          kind: 'system',
        },
      );
      const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);

      return {
        entity,
        resolved: {
          name: humanizeEntityRef(entity, {
            defaultKind: 'Component',
          }),
          ownedByRelationsTitle: ownedByRelations
            .map(r => humanizeEntityRef(r, { defaultKind: 'group' }))
            .join(', '),
          ownedByRelations,
          partOfSystemRelationTitle: partOfSystemRelations
            .map(r =>
              humanizeEntityRef(r, {
                defaultKind: 'system',
              }),
            )
            .join(', '),
          partOfSystemRelations,
        },
      };
    });

  if (error) {
    return (
      <div>
        <WarningPanel
          severity="error"
          title="Could not fetch catalog entities."
        >
          <CodeSnippet language="text" text={error.toString()} />
        </WarningPanel>
      </div>
    );
  }

  return (
    <>
      {rows.length ? (
        <div className={`mystuff-table  ${classes.themeColor}`}>
          <Table
            isLoading={loading}
            columns={columns}
            options={{
              paging: true,
              pageSize: 20,
              search: true,
              actionsColumnIndex: -1,
              loadingType: 'linear',
              showEmptyDataSourceMessage: !loading,
              padding: 'dense',
              pageSizeOptions: [20],
            }}
            data={rows}
            actions={defaultActions}
            title={`${tableTitle} (${rows && rows.length})`}
          />
        </div>
      ) : (
        !loading && <NoAssetsFound />
      )}
    </>
  );
};
