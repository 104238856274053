import React, { useContext, useEffect, useRef, useState } from 'react';
import { GuidedTourContext } from './providers/GuidedTourProvider';
import { useLocation, useNavigate } from 'react-router';
import { updateObjectValuesToSame } from './utils';
import TourConfigRunner from './TourConfigRunner';
import { TourConfig } from './interfaces';

const GuidedTour = ({ tourConfig }: { tourConfig: TourConfig }) => {
  const myContext = useContext(GuidedTourContext);

  const { samepagePaths, pages } = tourConfig;

  const visitedPages = useRef(new Set());

  /* All Current */

  // contains all current index of each page initialized to zero
  const [pagesCurrentIndexes, setPagesCurrentIndexes] = useState<any>(
    updateObjectValuesToSame(pages, 0),
  );
  const [currentPageId, setCurrentPageId] = useState('');
  const action = useRef<any>('');

  // Nav Related States
  const location = useLocation();
  const navigate = useNavigate();

  /* reset counter for all pages */
  const resetPagesIndex = () => {
    const allZeros = updateObjectValuesToSame(pagesCurrentIndexes, 0);
    setPagesCurrentIndexes((prevState: { [x: string]: number }) => {
      return {
        ...prevState,
        ...allZeros,
      };
    });
  };

  /* Handler for close button */
  const stopResetTour = () => {
    resetPagesIndex();
    myContext.setStartTour(false);
  };

  /* Update the counter for current page */
  const updateCurrentPageIndex = (
    buttonAction: string,
    currentIndex: number,
  ) => {
    action.current = buttonAction;
    let stepCount = currentIndex;
    if (buttonAction === 'back') {
      if (currentIndex === 0 && visitedPages.current.size === 1) {
        stopResetTour();
        return;
      }
      if (currentIndex === 0 && visitedPages.current.size > 1) {
        navigate(-1);
        visitedPages.current.delete(currentPageId);
        return;
      }
      stepCount = currentIndex - 1;
    }
    if (buttonAction === 'next') {
      if (
        currentIndex === pages[currentPageId].length - 1 &&
        visitedPages.current.size === 1
      ) {
        stopResetTour();
        return;
      }
      if (
        currentIndex === pages[currentPageId].length - 1 &&
        visitedPages.current.size > 1
      ) {
        navigate(-1);
        visitedPages.current.delete(currentPageId);
        return;
      }
      stepCount = currentIndex + 1;
    }
    setPagesCurrentIndexes((prevState: { [x: string]: number }) => {
      return {
        ...prevState,
        [currentPageId]: stepCount,
      };
    });
  };

  useEffect(() => {
    setTimeout(() => {
      /*     const pageId: any = getPageId();
      console.log(location.pathname); */

      const pathname = location.pathname;
      const pageId = samepagePaths.hasOwnProperty(pathname)
        ? samepagePaths[pathname]
        : pathname;

      if (!pages.hasOwnProperty(pageId) && myContext.startTour) {
        // If page id is not found in pages object,
        pages[pageId] = [
          {
            selector: '',
            enableClick: false,
            title: pageId,
            description: 'Tour is not setup for this page',
            placement: 'top',
          },
        ];
      }

      if (pages.hasOwnProperty(pageId)) {
        visitedPages.current.add(pageId);
        setCurrentPageId(pageId);
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      {currentPageId && myContext.startTour ? (
        <TourConfigRunner
          tourConfig={pages[currentPageId]}
          currentStep={pagesCurrentIndexes[currentPageId] || 0}
          onStepChange={updateCurrentPageIndex}
          key={currentPageId}
        />
      ) : null}
    </>
  );
};

export default GuidedTour;
