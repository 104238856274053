import { createApiRef } from '@backstage/core-plugin-api';
import { AxiosInstance } from 'axios';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';

export interface WhatsNewApi {
  /* PageList Related Apis*/
  getTourConfig: () => Promise<any>;
  addPageConfig: (pageData: any) => Promise<any>;
  editPageConfig: (pageId: string, pageData: any) => Promise<any>;
  deletePageConfig: (pageId: string) => Promise<any>;

  /* Steps Page Related Apis */
  getPageConfigById: (id: string) => Promise<any>;
  savePageSteps: (id: string, steps: any) => Promise<any>;
  //  saveTourConfig: (tourconfig: any) => Promise<any>;
}

export const whatsNewApiRef = createApiRef<WhatsNewApi>({
  id: 'cbre.devx.api.whatsnew',
});

export class WhatsNewImpl implements WhatsNewApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  /* Save TourConfig */
  // async saveTourConfig(tourconfig: any) {
  //   const instance = await this.axiosInstancePromise;
  //   return instance.post('service/featurehighlights', tourconfig);
  // }

  /* ----------------------- Page Related Apis-------------------- */

  /* Get TourConfig */
  async getTourConfig() {
    const instance = await this.axiosInstancePromise;
    return instance.get('service/featuretour/config').then(res => res.data);
  }

  /* Add PageConfig */
  async addPageConfig(pageData: any) {
    const instance = await this.axiosInstancePromise;
    return instance
      .post('service/featuretour/config', pageData)
      .then(res => res.data);
  }

  /* Edit PageConfig */
  async editPageConfig(pageId: string, pageData: any) {
    const instance = await this.axiosInstancePromise;
    return instance
      .put(`service/featuretour/config/${pageId}`, pageData)
      .then(res => res.data);
  }

  /* Delete PageConfig */
  async deletePageConfig(pageId: string) {
    const instance = await this.axiosInstancePromise;
    return instance
      .delete(`service/featuretour/config/${pageId}`)
      .then(res => res.data);
  }

  /* ----------------------- End Of Page Related Apis-------------------- */

  /* ----------------------- Steps Page -------------------- */

  /* Get pageConfig by Id */
  async getPageConfigById(id: string) {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`service/featuretour/config/${id}`)
      .then(res => res.data);
  }

  /* Get pageConfig by Id */
  async savePageSteps(id: string, steps: any) {
    const instance = await this.axiosInstancePromise;
    return instance
      .post(`service/featuretour/config/steps/${id}`, steps)
      .then(res => res.data);
  }
  /* ----------------------- End Steps Page -------------------- */
}
