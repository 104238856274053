import React, { useEffect, useState } from 'react';
import { useStyles } from '../../../../../styles/popupStyles';
import { Typography } from '@material-ui/core';
import { IGlobalTraffic } from '../../../../../interfaces';

interface GlobalTrafficItem {
  label: string;
  value: any[];
}

interface IGlobalTrafficProps {
  globalTraffic: IGlobalTraffic[];
}

const GlobalTrafficSection = ({ globalTraffic }: IGlobalTrafficProps) => {
  const classes = useStyles();
  const [detailsArr, setDetailsArr] = useState<GlobalTrafficItem[]>([]);

  useEffect(() => {
    loadDetailsArray();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalTraffic]);

  const loadDetailsArray = () => {
    setDetailsArr([
      {
        label: 'Region:',
        value: globalTraffic.map((globalTraffic: any) => globalTraffic.region),
      },
      {
        label: 'Thread Count:',
        value: globalTraffic.map(
          (globalTraffic: any) => globalTraffic.threadCount,
        ),
      },
      {
        label: 'Ramp Up:',
        value: globalTraffic.map(
          (globalTraffic: any) => globalTraffic.rampUpSeconds,
        ),
      },
      {
        label: 'Duration:',
        value: globalTraffic.map(
          (globalTraffic: any) => globalTraffic.durationSeconds,
        ),
      },
    ]);
  };

  return (
    <div className={classes.sectionContainer}>
      <Typography className={classes.sectionHeading}>Global Traffic</Typography>
      <div>
        {detailsArr.map((item, index) => (
          <div className={classes.labelAndValue}>
            <div className={classes.label} key={index}>
              {item.label}
            </div>
            <div key={index}>
              {item.value}
              {/* Temporary fix for unit measurement */}
              {(item.label === 'Ramp Up:' || item.label === 'Duration:') &&
                ' Seconds'}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GlobalTrafficSection;
