import React from 'react';
import { EmptyState } from '@backstage/core-components';
import { Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useCustomStyles } from './DocStyles';

const ExternalDocsEmptyState = () => {
  const classes = useCustomStyles();
  const navigate = useNavigate();

  const navigateToRegisterPage = () => {
    navigate('/docs/external/register');
  };

  return (
    <div className={classes.EmptyStateStyle}>
      <EmptyState
        missing="content"
        title="No Docs Found"
        description="Want to help us build this?"
        action={
          <Button
            color="primary"
            variant="contained"
            onClick={navigateToRegisterPage}
          >
            Register an External Document
          </Button>
        }
      />
    </div>
  );
};
export default ExternalDocsEmptyState;
