import React, { useEffect, useContext } from 'react';
import {
  Button,
  Grid,
  Typography,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import {
  EntityApiDefinitionCard,
  EntityHasApisCard,
} from '@backstage/plugin-api-docs';

import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useAsync } from 'react-use';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import {
  EntityAboutCard,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  // EntitySystemDiagramCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityHasSystemsCard,
  EntityLinksCard,
  EntitySwitch,
  isComponentType,
  isKind,
} from '@backstage/plugin-catalog';
import {
  isGithubActionsAvailable,
  EntityGithubActionsContent,
} from '@backstage/plugin-github-actions';
import { useAsyncEntity } from '@backstage/plugin-catalog-react';
import { ReadmePage } from '@internal/plugin-readme';
import { Alert } from '@material-ui/lab';
import { useSplitTreatments } from '@splitsoftware/splitio-react';

import { UserStuff } from './users/userAssets/userStuff';
import { EntityLayout } from './EntityLayout';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import { EmptyState } from '@backstage/core-components';
import { UserProfileCard } from './users/UserProfileCard';
import { OwnershipCard } from './users/OwnershipCard';
import { GroupProfileCard } from './users/GroupProfileCard';
import { MembersListCard } from './users/MembersListCard';
import { AboutCard } from './details/code/about/AboutCard';
import { ApiAboutCard } from './details/api/about/ApiAboutCard';
import { Comments } from './details/Comments';
import { Review } from './details/Review';
import { SegmentCard } from './details/api/about/SegmentCard';
import { useApi } from '@backstage/core-plugin-api';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';

import { Ratings } from './details/Ratings';
import { RatingFeedbackCard } from './details/RatingFeedbackCard';
import { useGetConversionApi } from '../utils/useGetConversionApi';
import {
  REVIEW_TITLE,
  COMMENTS_TITLE,
  REVIEWER_COMMENTS_HEADING,
  STATUS_APPROVED,
  STATUS_UNDER_REVIEW,
} from '../utils/constant';
import { HelpComponent } from '../utils/helpComponent';
import HELP_URL from '../utils/helpLinkConstant';
import BackButton from '../utils/backButton';
import TechDocsEmptyState from './techdocs/TechDocsEmptyState';
import { AuthContext } from '../../providers/AuthProvider';
import CodeQualityPage from './details/code-quality';
import { CHECK_USER_ROLE } from '../utils/constant';
import ApiDocuments from './ApiDocuments';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    MarginBottom80: {
      marginBottom: '100px !important',
      ['@media (max-width:1024px)']: {
        paddingTop: '0 !important',
      },
    },
    overviewTheme: {
      backgroundColor: theme.palette.background.paper,
    },
    overviewBgColor: {
      backgroundColor: '#f2f2f2',
    },
    bottomPadding: {
      paddingBottom: '10px',
    },
    alert: {
      marginLeft: `${theme.spacing(1)}px`,
      marginRight: `${theme.spacing(1)}px`,
      background: theme.palette.alert.background,
      border: `1px solid ${theme.palette.alert.border}`,
    },
    alertContent: {
      marginTop: `${theme.spacing(1)}px`,
      '& a': {
        color: theme.palette.alert.link,
        '&:visited': {
          color: theme.palette.info.main,
        },
      },
    },
    alertAdditionDetails: {
      marginTop: `${theme.spacing(1)}px`,
      fontWeight: 'bold',
      '& a': {
        fontSize: '1rem',
      },
    },
  }),
);
const techdocsHandle = (localEntity: any) => {
  return (
    localEntity?.metadata?.annotations?.['backstage.io/techdocs-ref'] ===
      undefined ||
    localEntity?.metadata?.annotations?.[
      'backstage.io/techdocs-ref'
    ]?.trim() === ''
  );
};

const cicdContent = (
  // This is an example of how you can implement your company's logic in entity page.
  // You can for example enforce that all components of type 'service' should use GitHubActions
  <EntitySwitch>
    <EntitySwitch.Case if={isGithubActionsAvailable}>
      <EntityGithubActionsContent />
    </EntitySwitch.Case>

    <EntitySwitch.Case>
      <EmptyState
        title="No CI/CD available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
          >
            Read more
          </Button>
        }
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);

const TechDocsContents = () => {
  return (
    <Grid xs={12}>
      <EntityTechdocsContent>
        <TechDocsAddons>
          <ReportIssue />
        </TechDocsAddons>
      </EntityTechdocsContent>
    </Grid>
  );
};

const DefinitionContents = () => {
  return <EntityApiDefinitionCard />;
};

const DocumentContents = () => {
  return <ApiDocuments />;
};

const OverviewContent = () => {
  const classes = useStyles();
  const { profEmail, isAdmin } = useContext(AuthContext);
  const userId = profEmail?.split('@')[0];
  const { entity } = useAsyncEntity();
  const entityOwner = String(entity?.spec?.owner).replace('user:', '');
  const { localEntity } = useGetConversionApi(
    entity?.metadata?.name,
    entity?.kind,
  );
  const devxStatus = localEntity?.metadata?.devxstate;

  return (
    <Grid container spacing={3} alignItems="stretch">
      <Grid item xs={12} md={6}>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item md={12} xs={12}>
            <AboutCard userId={userId!} isAdmin={isAdmin} />
          </Grid>
          <Grid item xs={12} md={12} className={classes.MarginBottom80}>
            <EntityLinksCard />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6} xs={12}>
        <Grid container spacing={3} alignItems="stretch">
          {/* <Grid item md={12} xs={12}>
            <EntityCatalogGraphCard variant="gridItem" height={400} />
          </Grid> */}

          {userId!.replace('user:', '').toLowerCase() !==
            entityOwner.replace('user:', '').toLowerCase() &&
          devxStatus?.toLowerCase() === STATUS_APPROVED ? (
            <Grid item md={12} xs={12}>
              <RatingFeedbackCard userId={userId!} />
            </Grid>
          ) : null}
          {Array.isArray(localEntity?.metadata?.rating) &&
          localEntity?.metadata?.rating.length > 0 ? (
            <Grid item md={12} xs={12}>
              <Ratings ratings={localEntity?.metadata?.rating} />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

const CommentsContent = () => {
  const { entity } = useAsyncEntity();
  const { profEmail } = useContext(AuthContext);
  const userId = profEmail?.split('@')[0];
  const entityOwner = String(entity?.spec?.owner).replace('user:', '');

  return (
    <Grid container spacing={3} alignItems="stretch">
      <Grid item md={6} xs={12}>
        <Comments userId={userId!} entityOwner={entityOwner} />
      </Grid>
    </Grid>
  );
};

const ReviewAndComments = () => {
  const { profEmail } = useContext(AuthContext);
  const userId = profEmail?.split('@')[0];

  return (
    <Grid container spacing={3} alignItems="stretch">
      <Grid item md={12} xs={12}>
        <Review userId={userId!} />
      </Grid>
    </Grid>
  );
};

const shouldCommentsTabVisible = (localEntity, userId) => {
  let check = false;
  if (
    localEntity?.metadata?.comments &&
    localEntity?.metadata?.comments.length > 0
  ) {
    localEntity?.metadata?.comments.forEach(comment => {
      if (
        comment.user.replace('user:', '').toLowerCase() ===
        userId.replace('user:', '').toLowerCase()
      ) {
        check = true;
      }
    });
    if (
      localEntity?.spec?.owner?.replace('user:', '').toLowerCase() ===
      userId.replace('user:', '').toLowerCase()
    )
      check = true;
  }
  return check;
};

const ServiceEntityPage = () => {
  const { entity } = useAsyncEntity();
  const classes = useStyles();
  const { localEntity } = useGetConversionApi(
    entity?.metadata?.name,
    entity?.kind,
  );
  const { profEmail, userInfo } = useContext(AuthContext);
  const userId = profEmail?.split('@')[0];
  const devxStatus = localEntity?.metadata?.devxstate;
  const isReviewTabShow = localStorage.getItem('isReview');
  const tecdocsState = techdocsHandle(localEntity);
  const userCheckState = shouldTecdocsTabVisiableUserCheck(localEntity, userId);
  const entityOwner = entity?.spec?.owner?.replace('user:', '');

  /* Returns true if the user is component reviewer */
  const isComponentReviewer = () => {
    //  const entityOwnerEmail: any = entity?.spec?.owneremail;
    return (
      entityOwner?.toLowerCase() !== userId?.toLowerCase() &&
      userInfo?.isComponentReviewer?.toLowerCase() ===
        CHECK_USER_ROLE.toLowerCase() &&
      userInfo?.componentReviewEnabled?.toLowerCase() ===
        CHECK_USER_ROLE.toLowerCase()
    );
  };

  return (
    <div className={classes.overviewBgColor}>
      <EntityLayout>
        <EntityLayout.Route path="/" title="Overview">
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <BackButton />
              <HelpComponent helpUrl={HELP_URL.ReviewComponentPageOverview} />
            </Grid>
            <Grid item xs={12}>
              <OverviewContent />
            </Grid>
          </Grid>
        </EntityLayout.Route>

        <EntityLayout.Route path="/readme" title="README">
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <BackButton />
              <HelpComponent helpUrl={HELP_URL.ReviewComponentPageReadme} />
            </Grid>
            <Grid item xs={12}>
              <ReadmePage entity={entity}></ReadmePage>
            </Grid>
          </Grid>
        </EntityLayout.Route>

        {!tecdocsState ? (
          <EntityLayout.Route path="/docs" title="Tech Docs">
            <Grid container spacing={3} className="techdocs-container">
              <Grid item md={12} xs={12}>
                <BackButton />
                <HelpComponent helpUrl={HELP_URL.ReviewComponentPageTECHDOC} />
              </Grid>
              <Grid item xs={12}>
                <TechDocsContents />
              </Grid>
            </Grid>
          </EntityLayout.Route>
        ) : null}

        {tecdocsState && userCheckState ? (
          <EntityLayout.Route path="/docs" title="Tech Docs">
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <BackButton />
                <HelpComponent helpUrl={HELP_URL.ReviewComponentPageTECHDOC} />
              </Grid>
              <Grid item xs={12}>
                <TechDocsEmptyState />
              </Grid>
            </Grid>
          </EntityLayout.Route>
        ) : null}

        {shouldCommentsTabVisible(localEntity, userId) ? (
          <EntityLayout.Route path="/reviewerComments" title={COMMENTS_TITLE}>
            <CommentsContent />
          </EntityLayout.Route>
        ) : null}

        {devxStatus?.toLowerCase() === STATUS_UNDER_REVIEW &&
        isComponentReviewer() ? (
          <EntityLayout.Route
            path="/reviewAndComments"
            title={REVIEWER_COMMENTS_HEADING}
          >
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <BackButton />
                <HelpComponent helpUrl={HELP_URL.ReviewComponentPageREVIEW} />
              </Grid>
              <Grid item xs={12}>
                <ReviewAndComments />
              </Grid>
            </Grid>
          </EntityLayout.Route>
        ) : null}
        <EntityLayout.Route path="/code-quality" title="Code Quality">
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <BackButton />
              <HelpComponent helpUrl={HELP_URL.CODE_QUALITY_PAGE} />
            </Grid>
            <Grid item xs={12}>
              <CodeQualityPage />
            </Grid>
          </Grid>
        </EntityLayout.Route>
      </EntityLayout>
    </div>
  );
};

const WebsiteEntityPage = () => {
  let { entity } = useAsyncEntity();

  return (
    <div>
      <EntityLayout>
        <EntityLayout.Route path="/" title="Overview">
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <BackButton />
              <HelpComponent helpUrl={HELP_URL.ReviewComponentPageOverview} />
            </Grid>
            <Grid item xs={12}>
              <OverviewContent />
            </Grid>
          </Grid>
        </EntityLayout.Route>

        <EntityLayout.Route path="/ci-cd" title="CI/CD">
          {cicdContent}
        </EntityLayout.Route>

        <EntityLayout.Route path="/dependencies" title="Dependencies">
          <Grid container spacing={3} alignItems="stretch">
            <Grid item md={6}>
              <EntityDependsOnComponentsCard variant="gridItem" />
            </Grid>
            <Grid item md={6}>
              <EntityDependsOnResourcesCard variant="gridItem" />
            </Grid>
          </Grid>
        </EntityLayout.Route>

        <EntityLayout.Route path="/readme" title="README">
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <BackButton />
              <HelpComponent helpUrl={HELP_URL.ReviewComponentPageReadme} />
            </Grid>
            <Grid item xs={12}>
              <ReadmePage entity={entity} />
            </Grid>
          </Grid>
        </EntityLayout.Route>

        <EntityLayout.Route path="/docs" title="Tech Docs">
          <Grid container spacing={3} className="techdocs-container">
            <Grid item md={12} xs={12}>
              <BackButton />
              <HelpComponent helpUrl={HELP_URL.ReviewComponentPageTECHDOC} />
            </Grid>
            <Grid item xs={12}>
              <TechDocsContents />
            </Grid>
          </Grid>
        </EntityLayout.Route>
        <EntityLayout.Route path="/code-quality" title="Code Quality">
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <BackButton />
              <HelpComponent helpUrl={HELP_URL.CODE_QUALITY_PAGE} />
            </Grid>
            <Grid item xs={12}>
              <CodeQualityPage />
            </Grid>
          </Grid>
        </EntityLayout.Route>
      </EntityLayout>
    </div>
  );
};

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the asvailable space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

const shouldTecdocsTabVisiableUserCheck = (localEntity: any, userId: any) => {
  let check = false;
  if (
    localEntity?.spec?.owner?.replace('user:', '').toLowerCase() ===
    userId.replace('user:', '').toLowerCase()
  ) {
    check = true;
  } else {
    check = false;
  }

  return check;
};

const DefaultEntityPage = () => {
  const { profEmail, userInfo } = useContext(AuthContext);
  const userId = profEmail?.split('@')[0];
  const { entity } = useAsyncEntity();
  const { localEntity } = useGetConversionApi(
    entity?.metadata?.name,
    entity?.kind,
  );
  const devxStatus = localEntity?.metadata?.devxstate;
  const tecdocsState = techdocsHandle(localEntity);
  const userCheckState = shouldTecdocsTabVisiableUserCheck(localEntity, userId);
  const classes = useStyles();

  const entityOwner = entity?.spec?.owner?.replace('user:', '');

  /* Returns true if the user is component reviewer */
  const isComponentReviewer = () => {
    //   const entityOwnerEmail: any = entity?.spec?.owneremail;
    return (
      entityOwner?.toLowerCase() !== userId?.toLowerCase() &&
      userInfo?.isComponentReviewer?.toLowerCase() ===
        CHECK_USER_ROLE.toLowerCase() &&
      userInfo?.componentReviewEnabled?.toLowerCase() ===
        CHECK_USER_ROLE.toLowerCase()
    );
  };

  return (
    <div>
      <EntityLayout>
        <EntityLayout.Route path="/" title="Overview">
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <BackButton />
              <HelpComponent helpUrl={HELP_URL.ReviewComponentPageOverview} />
            </Grid>
            <Grid item xs={12}>
              <OverviewContent />
            </Grid>
          </Grid>
        </EntityLayout.Route>

        <EntityLayout.Route path="/readme" title="README">
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <BackButton />
              <HelpComponent helpUrl={HELP_URL.ReviewComponentPageReadme} />
            </Grid>
            <Grid item xs={12}>
              <ReadmePage entity={entity} />
            </Grid>
          </Grid>
        </EntityLayout.Route>

        {!tecdocsState ? (
          <EntityLayout.Route path="/docs" title="Tech Docs">
            <Grid container spacing={3} className="techdocs-container">
              <Grid item md={12} xs={12}>
                <BackButton />
                <HelpComponent helpUrl={HELP_URL.ReviewComponentPageTECHDOC} />
              </Grid>
              <Grid item xs={12}>
                <TechDocsContents />
              </Grid>
            </Grid>
          </EntityLayout.Route>
        ) : null}

        {tecdocsState && userCheckState ? (
          <EntityLayout.Route path="/docs" title="Tech Docs">
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <BackButton />
                <HelpComponent helpUrl={HELP_URL.ReviewComponentPageTECHDOC} />
              </Grid>
              <Grid item xs={12}>
                <TechDocsEmptyState />
              </Grid>
            </Grid>
          </EntityLayout.Route>
        ) : null}

        {shouldCommentsTabVisible(localEntity, userId) ? (
          <EntityLayout.Route path="/reviewerComments" title={COMMENTS_TITLE}>
            <CommentsContent />
          </EntityLayout.Route>
        ) : null}
        {devxStatus?.toLowerCase() === STATUS_UNDER_REVIEW &&
        isComponentReviewer() ? (
          <EntityLayout.Route path="/reviewAndComments" title={REVIEW_TITLE}>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <BackButton />
                <HelpComponent helpUrl={HELP_URL.ReviewComponentPageREVIEW} />
              </Grid>
              <Grid item xs={12}>
                <ReviewAndComments />
              </Grid>
            </Grid>
          </EntityLayout.Route>
        ) : null}
        <EntityLayout.Route path="/code-quality" title="Code Quality">
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <BackButton />
              <HelpComponent helpUrl={HELP_URL.CODE_QUALITY_PAGE} />
            </Grid>
            <Grid item xs={12}>
              <CodeQualityPage />
            </Grid>
          </Grid>
        </EntityLayout.Route>
      </EntityLayout>
    </div>
  );
};

const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      <ServiceEntityPage />
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      <WebsiteEntityPage />
    </EntitySwitch.Case>

    <EntitySwitch.Case>
      <DefaultEntityPage />
    </EntitySwitch.Case>
  </EntitySwitch>
);

const ApiPage = () => {
  const { entity } = useAsyncEntity();
  const { profEmail, isAdmin, userInfo } = useContext(AuthContext);
  const classes = useStyles();
  const userId = profEmail?.split('@')[0];
  const entityOwner = entity?.spec?.owner?.replace('user:', '');
  const { localEntity } = useGetConversionApi(
    entity?.metadata?.name,
    entity?.kind,
  );
  const devxStatus = localEntity?.metadata?.devxstate;
  const isReviewTabShow = localStorage.getItem('isReview');
  const splitTreatments = useSplitTreatments({
    names: ['devx-ui-feature-enable'],
  });

  /* Returns true if the user is api reviewer */
  const userIsApiReviewer = () => {
    //  const entityOwnerEmail: any = entity?.spec?.owneremail;
    return (
      entityOwner?.toLowerCase() !== userId?.toLowerCase() &&
      userInfo?.isApiReviewer?.toLowerCase() ===
        CHECK_USER_ROLE.toLowerCase() &&
      userInfo?.apiReviewEnabled?.toLowerCase() ===
        CHECK_USER_ROLE.toLowerCase()
    );
  };

  const AlertBlock = () => {
    if (
      splitTreatments.factory &&
      splitTreatments?.treatments['devx-ui-feature-enable'].config
    ) {
      const matchedApi = JSON.parse(
        splitTreatments?.treatments['devx-ui-feature-enable'].config,
      ).aiEnabledAPI?.find(
        (aiEnabledAPI: any) =>
          (typeof aiEnabledAPI === 'object'
            ? aiEnabledAPI.name
            : aiEnabledAPI) === entity?.metadata?.name,
      );

      if (matchedApi) {
        return (
          <Grid container>
            <Grid item>
              <Alert severity="info" className={classes.alert}>
                <div>
                  <Typography component="h6">
                    <b>AI Disclaimer</b>
                  </Typography>
                </div>
                <div className={classes.alertContent}>
                  Before subscribing to this API, please ensure that your use
                  case is approved through the AI/ML Triage Process. Without
                  this you will not gain access to the AI APIs, you can raise a
                  ticket on{' '}
                  <a
                    href="https://cbre.service-now.com/nav_to.do?uri=%2Fcom.glideapp.servicecatalog_cat_item_view.do%3Fv%3D1%26sysparm_id%3Daa13290b1bf758503d88caad1e4bcb54"
                    target="_blank"
                  >
                    <b>ServiceNow</b>
                  </a>
                  . The process will include an EA and GCSO review to enable
                  optimal and secure use of Generative AI capabilities. If you
                  have any questions about this process, you can find more
                  information here (
                  <a
                    href="https://cbre.sharepoint.com/:w:/t/ProductsToPlatforms/EcnQJJWQe5tNrgU_P_2eozkBKe0za43owsSkIEwyUb9r0A?e=NH4qAU"
                    target="_blank"
                  >
                    <b>Generative AI project Guidelines for CBRE.docx</b>
                  </a>
                  ).
                </div>
                {typeof matchedApi === 'object' && matchedApi.additionalUrl && (
                  <div
                    className={`${classes.alertContent} ${classes.alertAdditionDetails}`}
                  >
                    To understand how to use the API and the various endpoints
                    please{' '}
                    <a href={`${matchedApi.additionalUrl}`}>click here</a>
                  </div>
                )}
              </Alert>
            </Grid>
          </Grid>
        );
      }
    }
    return <></>;
  };

  return (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <BackButton />
            <HelpComponent helpUrl={HELP_URL.ReviewAPIPageOVERVIEW} />
          </Grid>
          {AlertBlock()}
          <Grid item md={6} xs={12}>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <ApiAboutCard userId={userId} isAdmin={isAdmin} />
              </Grid>
              <Grid item md={12} xs={12}>
                <SegmentCard />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid container spacing={3} alignItems="stretch">
              {userId.replace('user:', '').toLowerCase() !==
                entityOwner.replace('user:', '').toLowerCase() &&
              devxStatus?.toLowerCase() === STATUS_APPROVED ? (
                <Grid item md={12} xs={12}>
                  <RatingFeedbackCard userId={userId} />
                </Grid>
              ) : null}
              {Array.isArray(localEntity?.metadata?.rating) &&
              localEntity?.metadata?.rating.length > 0 ? (
                <Grid item md={12} xs={12}>
                  <Ratings ratings={localEntity?.metadata?.rating} />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </EntityLayout.Route>

      <EntityLayout.Route path="/definition" title="Definition">
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <BackButton />
            <HelpComponent helpUrl={HELP_URL.ReviewAPIPageDEFINITION} />
          </Grid>
          <Grid item xs={12}>
            <DefinitionContents />
          </Grid>
        </Grid>
      </EntityLayout.Route>
      {localEntity?.metadata?.assetstore === 'wso2' && (
        <EntityLayout.Route path="/documents" title="Documents">
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <BackButton />
              <HelpComponent helpUrl={HELP_URL.ReviewAPIPageDocuments} />
            </Grid>
            <Grid item xs={12}>
              <DocumentContents />
            </Grid>
          </Grid>
        </EntityLayout.Route>
      )}

      {shouldCommentsTabVisible(localEntity, userId) ? (
        <EntityLayout.Route path="/reviewerComments" title={COMMENTS_TITLE}>
          <Grid item md={12} xs={12}>
            <BackButton />
            <HelpComponent helpUrl={HELP_URL.ReviewAPIPageREVIEW} />
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CommentsContent />
            </Grid>
          </Grid>
        </EntityLayout.Route>
      ) : null}

      {devxStatus?.toLowerCase() === STATUS_UNDER_REVIEW &&
      userIsApiReviewer() ? (
        <EntityLayout.Route path="/reviewAndComments" title={REVIEW_TITLE}>
          <Grid item md={12} xs={12}>
            <BackButton />
            <HelpComponent helpUrl={HELP_URL.ReviewAPIPageREVIEW} />
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ReviewAndComments />
            </Grid>
          </Grid>
        </EntityLayout.Route>
      ) : null}
    </EntityLayout>
  );
};

const useCatalogEntity = () => {
  const catalogApi = useApi(catalogApiRef);
  const {
    value: entity,
    error,
    loading,
  } = useAsync(() => catalogApi.getEntities());

  return { entity, error, loading };
};

const UserPage = () => {
  const entityData = useCatalogEntity();
  const { entity } = useAsyncEntity();
  const entityOwner = entity?.spec?.profile?.email;
  const classes = useStyles();

  return (
    <EntityLayout>
      <EntityLayout.Route path="/overview" title="Overview">
        <div className={classes.bottomPadding}>
          <BackButton />
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <UserProfileCard variant="gridItem" />
          </Grid>
          <Grid item xs={12} md={6}>
            <OwnershipCard variant="gridItem" />
          </Grid>
        </Grid>
      </EntityLayout.Route>
      <EntityLayout.Route path="/user-assets" title="User Assets">
        <div className={classes.bottomPadding}>
          <BackButton />
        </div>
        <UserStuff profile={entityOwner} {...entityData} />
      </EntityLayout.Route>
    </EntityLayout>
  );
};
const GroupPage = () => {
  const classes = useStyles();
  return (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <div className={classes.bottomPadding}>
          <BackButton />
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <GroupProfileCard variant="gridItem" />
          </Grid>
          <Grid item xs={12} md={6}>
            <OwnershipCard variant="gridItem" />
          </Grid>
          <Grid item xs={12}>
            <MembersListCard />
          </Grid>
        </Grid>
      </EntityLayout.Route>
    </EntityLayout>
  );
};

const systemPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <div style={{ paddingBottom: '10px' }}>
        <BackButton />
      </div>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <div style={{ paddingBottom: '10px' }}>
        <BackButton />
      </div>
      <EntityCatalogGraphCard />
    </EntityLayout.Route>
  </EntityLayout>
);

const domainPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <div style={{ paddingBottom: '10px' }}>
        <BackButton />
      </div>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')}>
      <ApiPage />
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('group')} children={<GroupPage />} />
    <EntitySwitch.Case if={isKind('user')} children={<UserPage />} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />

    <EntitySwitch.Case>
      <DefaultEntityPage />
    </EntitySwitch.Case>
  </EntitySwitch>
);
