import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Button,
  makeStyles,
  createStyles,
  Box,
  IconButton,
  Theme,
  Tooltip
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useApi } from '@backstage/core-plugin-api';
import { Progress } from '@backstage/core-components';
import BackButton from '../../../utils/backButton';
import { HelpComponent } from '../../../utils/helpComponent';
import HELP_URL from '../../../utils/helpLinkConstant';
import { adminApiRef } from '../../../../apis/adminApi';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';
import AddIcon from '../../../Root/icons/addIcon';
import Delete from '@material-ui/icons/Delete';
import ConfirmDeleteCAVariable from './confirmDelete';

// @ts-ignore
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rowMain: {
      display: 'flex',
      background: theme.palette.background.paper,
      margin: '3px',
      borderRadius: '4px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
      },
    },
    marginBottm: { marginBottom: '48px' },
    dataColumn: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      margin: '5px',
      padding: '5px',
      [theme.breakpoints.down('sm')]: {
        '& section:first-of-type': {
          width: '150px',
        },
        flexDirection: 'column',
        justifyContent: 'flex-start',
        margin: '0',
      },
      [theme.breakpoints.down('md')]: {
        width: '194px',
      },
    },
    columnLabel: {
      // @ts-expect-error("")
      fontWeight: '600',
      color: theme.palette.text.secondary,
    },
    actionColumn: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: '25px',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
        width: '100%',
        marginRight: '0',
      },
    },
    actionBtn: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    actionColumnIcon: {
      cursor: 'pointer',
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        border: '1px solid #003f2d',
        borderRadius: '5px',
      },
    },
    assetVariables: {
      [theme.breakpoints.down('sm')]: {
        overflowX: 'hidden',
      },
    },
    actionButton: {
      display: 'flex',
      alignItems: 'center',
    },
    label: {
      color: theme.palette.text.tertiary,
    },
  }),
);

const VariableRow = ({
  item,
  onClickEdit,
  onClickDelete,
}: {
  item: any;
  onClickEdit: (item: any) => void;
  onClickDelete: (item: any) => void;
}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.rowMain}>
      <Grid item xs={4} sm={4} className={classes.dataColumn}>
        <section className={classes.label}>Asset Kind</section>
        <section className={classes.columnLabel}>{item?.assetkind}</section>
      </Grid>
      <Grid item xs={4} sm={4} className={classes.dataColumn}>
        <section className={classes.label}>Asset Type</section>
        <section className={classes.columnLabel}>{item?.assettype}</section>
      </Grid>
      <Grid item xs={4} sm={4} className={classes.dataColumn}>
        <section className={classes.label}>Complexity</section>
        <section className={classes.columnLabel}>{item?.complexity}</section>
      </Grid>
      <Grid item xs={4} sm={4} className={classes.dataColumn}>
        <section className={classes.label}>Default</section>
        <section className={classes.columnLabel}>
          {item?.defaultcomplexity ? 'Yes' : 'No'}
        </section>
      </Grid>
      <Grid item xs={4} sm={4} className={classes.dataColumn}>
        <section className={classes.label}>Man Hours</section>
        <section className={classes.columnLabel}>{item?.manhours}</section>
      </Grid>
      <Grid item xs={4} sm={4} className={classes.dataColumn}>
        <section className={classes.label}>Blended Rate</section>
        <section className={classes.columnLabel}>{item?.blendedrate}</section>
      </Grid>
      <Grid item xs={4} sm={4} className={classes.dataColumn}>
        <section className={classes.label}>Coefficient</section>
        <section className={classes.columnLabel}>{item?.coefficient}</section>
      </Grid>
      <Grid item xs={4} sm={4} className={classes.dataColumn}>
        <section className={classes.label}>SDC</section>
        <section className={classes.columnLabel}>{item?.coefficient2}</section>
      </Grid>

      <Grid item xs={12} sm={12} md={1} lg={1}>
        <div className={classes.actionColumn}>
          <section className={classes.actionColumnIcon}>
            <Tooltip title="Edit">
              <IconButton
                className={classes.actionBtn}
                id="edit-vars-btn"
                data-testid="edit-vars-btn"
                color="primary"
                onClick={() => onClickEdit(item)}
              >
                <EditIcon color="primary" />
              </IconButton>
            </Tooltip>
          </section>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={1} lg={1}>
        <div className={classes.actionColumn}>
          <section className={classes.actionColumnIcon}>
            <Tooltip title="Delete">
              <IconButton
                className={classes.actionBtn}
                id="delete-vars-btn"
                data-testid="delete-vars-btn"
                onClick={() => onClickDelete(item)}
                color="primary"
              >
                <Delete color="primary" />
              </IconButton>
            </Tooltip>
          </section>
        </div>
      </Grid>
    </Grid>
  );
};

const ManageCAVariables = () => {
  const [variablesData, setVariablesData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [selectedItemToDelete, setSelectedItemToDelete] = useState<any>();

  const classes = useStyles();

  const navigate = useNavigate();
  const adminApi = useApi(adminApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const refresh = () => {
    setLoading(true);
    adminApi
      .getCAVariablesData()
      .then((data: any) => {
        setVariablesData(data);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        setVariablesData([]);

        notificationApi.sendNotification({
          message: err.message,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  useEffect(() => {
    if (adminApi) {
      refresh();
    }
  }, [adminApi]);

  const onClickEdit = (item: any) => {
    navigate(`edit/${item?.id}`);
  };

  const onClickAdd = () => {
    navigate('add');
  };

  const onClickDelete = (item: any) => {
    setShowConfirmDelete(true);
    setSelectedItemToDelete(item);
  };

  const onCloseConfirmPrompt = () => {
    setShowConfirmDelete(false);
    setSelectedItemToDelete(undefined);
  };

  const onConfirmDelete = () => {
    setShowConfirmDelete(false);
    if (
      selectedItemToDelete !== undefined &&
      selectedItemToDelete.id !== undefined
    ) {
      setLoading(true);
      adminApi
        .deleteCAVariable(selectedItemToDelete?.id)
        .then(data => {
          if (data?.status === 200) {
            notificationApi.sendNotification({
              message: `Variable with kind ${selectedItemToDelete?.assetkind} is successfully deleted`,
              disapperAfterMs: 2500,
              severity: 'success',
              callback: refresh,
            });
          }
          setSelectedItemToDelete(undefined);
          setLoading(false);
        })
        .catch(() => {
          notificationApi.sendNotification({
            message: `Could not delete the variable ${selectedItemToDelete?.assetkind}`,
            disapperAfterMs: 2500,
            severity: 'error',
            callback: refresh,
          });
          setSelectedItemToDelete(undefined);
          setLoading(false);
        });
    }
  };

  return (
    <Grid container spacing={0} className={classes.assetVariables}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <BackButton />
        <HelpComponent helpUrl={HELP_URL.COST_AVOIDANCE_VARIABLES} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <h2>Manage Asset's Variables</h2>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              sx={{
                height: '100%',
                display: 'flex',
              }}
              alignItems="center"
              justifyContent={'end'}
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={onClickAdd}
              >
                Add Variable
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} className={classes.marginBottm}>
            {loading && <Progress />}
            {!loading && variablesData && variablesData.length === 0 && (
              <p>No data available.</p>
            )}
            {variablesData &&
              variablesData?.map(item => (
                <VariableRow
                  key={item.id}
                  item={item}
                  onClickEdit={onClickEdit}
                  onClickDelete={onClickDelete}
                />
              ))}
          </Grid>
        </Grid>
      </Grid>
      {showConfirmDelete && (
        <ConfirmDeleteCAVariable
          isOpen={showConfirmDelete}
          onClose={onCloseConfirmPrompt}
          onConfirm={onConfirmDelete}
          additionalText={`Kind: ${selectedItemToDelete?.assetkind}, Type: ${selectedItemToDelete?.assettype}, Complexity: ${selectedItemToDelete?.complexity}`}
        />
      )}
    </Grid>
  );
};
export default ManageCAVariables;
