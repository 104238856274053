import React, { useState } from 'react';
import { Chip, Input } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffSharpIcon from '@material-ui/icons/HighlightOffSharp';

export const Tags = props => {
  const {
    defaultTags = [],
    setTags,
    numberOfTags,
    numberOfCharacters,
    setIsButtonDisabled,
    hasAddMore = true,
    hasCloseButton = true,
    editable
  } = props;
  console.log(defaultTags, editable)

  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState('');
  const [validatorForNoTags, setValidatorForNoTags] = useState(false);
  const [validateSpecialCharacter, setValidateSpecialCharacter] =
    useState(false);
  const [validateNoOfCharacter, setValidateNoOfCharacter] = useState(false);
  const [validatorDuplicate, setValidatorDuplicated] = useState(false);
  // TypeScript error as but as it is a JSX syntax.

  const handleClickPopover = event => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const useStyles = makeStyles(() => ({
    typography: {
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: '16px',
      paddingBottom: '16px',
    },
    errorMessageStyle: {
      fontSize: '11px',
      color: '#f00',
    },
  }));

  const handleDelete = h => {
    const clone = defaultTags;
    clone.splice(h, 1);

    setTags([...clone]);
    setValidatorForNoTags(false);
    setValidatorDuplicated(false);
    setIsButtonDisabled(false);
  };

  const popoverHandleclose = () => {
    setAnchorEl(null);
  };

  // handleclose will push the values to the state
  const handleClose = () => {
    const str = value !== null ? value.replace(/\s/g, '') : '';
    setAnchorEl(null);
    if (str === '' || value === undefined) {
      setTags([...defaultTags]);
    } else {
      if (
        defaultTags?.indexOf(value.toString()) === -1 &&
        defaultTags?.length <= (numberOfTags || 9)
      ) {
        setTags([...defaultTags, value.toString()]);
        setValidatorDuplicated(false);
        setValidatorForNoTags(false);
        setIsButtonDisabled(false);
      } else if (defaultTags?.indexOf(value.toString()) !== -1) {
        setValidatorDuplicated(true);
      } else if (defaultTags?.length > (numberOfTags || 9)) {
        setValidatorForNoTags(true);
      }
    }
    setValue('');
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleInput = event => {
    event.preventDefault();
    const value = event.target.value;
    const pattern = /[^a-zA-Z& -]/gi;
    const regex = new RegExp(pattern);
    if (value.length <= (numberOfCharacters || 15) && !value.match(regex)) {
      setValidateNoOfCharacter(false);
      setValidateSpecialCharacter(false);
      setValue(event.target.value.replace(/[^a-zA-Z& -]/gi, ''));
    } else if (value.length > (numberOfCharacters || 15)) {
      setValidateNoOfCharacter(true);
    } else if (value.match(regex)) {
      setValidateSpecialCharacter(true);
    }
  };
  const classes = useStyles();

  return (
    <>
      {(
        (defaultTags &&
          Array.isArray(defaultTags) &&
          defaultTags?.filter(
            defaultTag => !(defaultTag === '' || defaultTag === null),
          )) ||
        []
      ).map((item, index) => (
        <Chip
          key={index}
          label={item}
          color="primary"
          variant="outlined"
          size="small"
          {...(hasCloseButton && editable && { onDelete: () => handleDelete(index) })}
        />
      ))}

      {editable && !validatorForNoTags && hasAddMore ? (
        <Chip
          aria-describedby={id}
          icon={<AddIcon />}
          label="ADD MORE"
          onClick={handleClickPopover}
          color="primary"
          variant="outlined"
          size="small"
        />
      ) : null}

      {editable && validatorForNoTags && (
        <Chip
          aria-describedby={id}
          icon={<AddIcon />}
          label="ADD MORE"
          onClick={handleClickPopover}
          color="primary"
          variant="outlined"
          size="small"
          disabled
        />
      )}
      {validatorForNoTags && (
        <Typography className={classes.errorMessageStyle}>
          *Maximum {numberOfTags || '10'} tags supported.
        </Typography>
      )}

      {validatorDuplicate && (
        <Typography className={classes.errorMessageStyle}>
          *Duplicate Tags not allowed.
        </Typography>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={popoverHandleclose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.typography} component="div">
          {' '}
          <Input
            value={value}
            color="primary"
            onChange={handleInput}
            data-testid="type-text-for-tabs"
          />{' '}
          <CheckCircleOutlineIcon
            onClick={handleClose}
            data-testid="handle-check"
          />{' '}
          <HighlightOffSharpIcon
            onClick={popoverHandleclose}
            data-testid="handle-close"
          />
        </Typography>
        {validateSpecialCharacter && (
          <Typography className={classes.errorMessageStyle}>
            {' '}
            *special characters/space/numbers not accepted
          </Typography>
        )}
        {validateNoOfCharacter && (
          <Typography className={classes.errorMessageStyle}>
            {' '}
            *no of characters exceeded
          </Typography>
        )}
      </Popover>
    </>
  );
};
