import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
  count: {
    display: 'flex',
    marginRight: '16px',
    borderRadius: '2px',
    cursor: 'pointer',
  },
  severity: {
    padding: '2px 8px',
    fontWeight: 'bold',
    color: '#fff',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  typoCount: {
    padding: '2px 12px',
  },
}));

export const SnykCountButton = ({
  count,
  severity,
  onClick = () => {},
}: {
  count: number | undefined;
  severity: string;
  onClick: () => void;
}) => {
  const [color, setColor] = useState('#bebebe');

  const classes = useStyles();

  useEffect(() => {
    if (count === 0) {
      setColor('#bebebe');
      return;
    }
    switch (severity) {
      case 'C': {
        setColor('#ab1a1a');
        break;
      }
      case 'H': {
        setColor('#ce5019');
        break;
      }
      case 'M': {
        setColor('#d68000');
        break;
      }
      case 'L': {
        setColor('#88879e');
        break;
      }
      default:
        setColor('#bebebe');
    }
  }, [severity, count]);

  return (
    <div
      className={classes.count}
      style={{
        border: `1px solid ${color}`,
      }}
      onClick={onClick}
      role="none"
    >
      <div className={classes.typoCount}>
        <Typography>{count}</Typography>
      </div>
      <div
        className={classes.severity}
        style={{
          background: color,
        }}
      >
        {severity}
      </div>
    </div>
  );
};
