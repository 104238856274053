/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, createContext } from 'react';

const initialState = {};

export interface IPromptStoreContext {}

export interface PromptStoreProviderProps {
  children: ReactElement;
}

export const PromptStoreContext =
  createContext<IPromptStoreContext>(initialState);

export const PromptStoreProvider = (props: PromptStoreProviderProps) => {
  return (
    <PromptStoreContext.Provider value={{}}>
      {props.children}
    </PromptStoreContext.Provider>
  );
};
