import { Content, Header, Page } from '@backstage/core-components';
import { Root } from '@drodil/backstage-plugin-toolbox';
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { DevxBreadCrumb } from '../../common/BreadcrumbsNav/DevxBreadCrumb';
import BackButton from '../../utils/backButton';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import { UtilitySidebarGroup } from './sidebarGroup';
import {
  ALL_UTILITY_TOOLS,
  CONVERT_TYPE_OF_TOOLS,
  ENCODE_DECODE_TYPE_OF_TOOLS,
  FORMAT_TYPE_OF_TOOLS,
  GENERATE_TYPE_OF_TOOLS,
  MISCELLANEOUS_TYPE_OF_TOOLS,
  REGULAR_EXPRESSIONS_TYPE_OF_TOOLS,
} from '../constants';
import UtilityToolsSearchFilter, {
  UTILITY_TOOLS_SEARCH_STATUS,
} from '../filter';
import { IUtilityTool } from '../types';
import {
  useStarredEntities,
  useStarredEntity,
} from '@backstage/plugin-catalog-react';
import { Rating } from '@material-ui/lab';
import { qrCodeGeneratorTool, regexPlaygroundTool } from '../additionalTools';
import MenuCollapseIcon from '../icons/menuCollapseIcon';
import MenuExpandIcon from '../icons/menuExpandIcon';
import { fileDiffTool } from '../additionalTools/FileDiff';
import { yamlFormatterTool } from '../additionalTools/YamlFormatter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      borderRadius: '8px',
      background: theme.palette.background.paper,
      position: 'relative',
      '& #toolContainer': {
        position: 'relative',
        zIndex: 10,
        height: 'auto',
        background: theme.palette.background.paper,
        borderRadius: '8px',
      },
    },
    input: {
      flex: '10',
    },
    toggleSidebar: {
      display: 'flex',
      alignItems: 'center',
    },
    starIcon: {
      position: 'absolute',
      top: '16px',
      right: '16px',
      zIndex: 11,
    },
    sidebar: {
      display: 'flex',
    },
    sidebarContent: {
      flex: 1,
    },
    ContentHeaderStyle: {
      width: '100%',
      padding: '0px',
      position: 'relative',
      marginBottom: '10px',
      '& > form:first-of-type': {
        display: 'flex',
        '& > button': {
          flex: '0.2',
        },
        '& > div': {
          flex: '10',
        },
      },
    },
  }),
);

const UtilityToolDetailsPage = () => {
  const classes = useStyles();
  const params = useParams();
  const toolId = params['*']?.split('/')?.[1];
  const [showSidebar, setShowSidebar] = useState<boolean>(false);

  const { starredEntities } = useStarredEntities();
  const starredMap: any = {};
  starredEntities.forEach((value: string) => {
    starredMap[`${value}`] = true;
  });

  const filteredTools = ALL_UTILITY_TOOLS?.filter(r => starredMap[r?.id]) || [];
  const [searchResults, setSearchResults] = useState<IUtilityTool[]>([]);
  const [searchStatus, setSearchStatus] = useState<string>('');

  const updateFilteredTools = (_tools: IUtilityTool[], status: string) => {
    setSearchResults(_tools);
    setSearchStatus(status);
  };

  const _tool =
    ALL_UTILITY_TOOLS?.filter(item => item?.path === params['*'])?.[0] || '';

  const { isStarredEntity, toggleStarredEntity } = useStarredEntity(_tool?.id);

  const onClickStarIcon = (event: any) => {
    event?.stopPropagation();
    event?.preventDefault();
    toggleStarredEntity();
  };

  const onClickToggleSidebar = () => {
    setShowSidebar(prev => !prev);
  };

  return (
    <Box>
      <Page themeId="home">
        <Header
          title={`Utility Tools (${toolId})`}
          subtitle={
            <DevxBreadCrumb
              routes={[
                {
                  type: 'link',
                  text: 'Home',
                  link: '/',
                },
                {
                  type: 'link',
                  text: `Utility Tools`,
                  link: '/utility-tools',
                },
                {
                  type: 'text',
                  text: `${toolId}`,
                  link: `/utility-tools/details/${params['*']}`,
                },
              ]}
            />
          }
        ></Header>
        <Content>
          <Grid container spacing={2}>
            <Grid item xs={12} md={showSidebar ? 10 : 12}>
              <Box sx={{ mb: 2 }} className={classes.sidebar}>
                <Box className={classes.sidebarContent}>
                  <BackButton />
                  <HelpComponent
                    helpUrl={HELP_URL.UTILITY_TOOLS_DETAILS_PAGE}
                  />
                </Box>
                <Box className={classes.toggleSidebar}>
                  <span
                    onClick={onClickToggleSidebar}
                    style={{ cursor: 'pointer' }}
                    title={
                      showSidebar ? 'Hide Sidebar Menu' : 'Show Sidebar Menu'
                    }
                  >
                    {showSidebar ? <MenuCollapseIcon /> : <MenuExpandIcon />}
                  </span>
                </Box>
              </Box>

              <Box className={classes.content} id="utilityToolDetailsContent">
                <Box
                  className={classes.starIcon}
                  onClickCapture={onClickStarIcon}
                >
                  <Rating max={1} value={isStarredEntity ? 1 : 0}></Rating>
                </Box>
                <Root
                  extraTools={[
                    regexPlaygroundTool,
                    qrCodeGeneratorTool,
                    fileDiffTool,
                    yamlFormatterTool,
                  ]}
                />
              </Box>
            </Grid>
            {showSidebar && (
              <Grid item xs={12} md={2}>
                <Box>
                  <Box sx={{ mb: 1 }}>
                    <UtilityToolsSearchFilter
                      updateFilteredTools={updateFilteredTools}
                    />
                  </Box>

                  {searchStatus === UTILITY_TOOLS_SEARCH_STATUS.NOT_FOUND && (
                    <Box sx={{ mb: 2 }}>
                      <Typography>
                        No tool matched for this criteria. Please refine the
                        criteria and try again
                      </Typography>
                    </Box>
                  )}
                  {searchResults !== undefined && searchResults?.length > 0 && (
                    <UtilitySidebarGroup
                      title="Search Results"
                      tools={searchResults}
                    />
                  )}

                  {filteredTools !== undefined && filteredTools.length > 0 && (
                    <UtilitySidebarGroup
                      title="Favorite"
                      tools={filteredTools}
                    />
                  )}

                  <UtilitySidebarGroup
                    title="Format"
                    tools={FORMAT_TYPE_OF_TOOLS}
                  />
                  <UtilitySidebarGroup
                    title="Generate"
                    tools={GENERATE_TYPE_OF_TOOLS}
                  />
                  <UtilitySidebarGroup
                    title="Encode/Decode"
                    tools={ENCODE_DECODE_TYPE_OF_TOOLS}
                  />
                  <UtilitySidebarGroup
                    title="Convert"
                    tools={CONVERT_TYPE_OF_TOOLS}
                  />
                  <UtilitySidebarGroup
                    title="Regular Expressions"
                    tools={REGULAR_EXPRESSIONS_TYPE_OF_TOOLS}
                  />
                  <UtilitySidebarGroup
                    title="Miscellaneous"
                    tools={MISCELLANEOUS_TYPE_OF_TOOLS}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Content>
      </Page>
    </Box>
  );
};
export default UtilityToolDetailsPage;
