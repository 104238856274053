import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Theme,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { Routes, Route, Outlet } from 'react-router-dom';
import BackButton from '../../utils/backButton';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import ViewPromptsPage from './ViewPromptsPage';
import { PromptContext } from './providers/PromptProvider';
import { useApi } from '@backstage/core-plugin-api';
import { promptApiRef } from '../../../apis/promptManagementApi';
import { AuthContext } from '../../../providers/AuthProvider';
import { notificationApiRef } from '../../../apis/notificationApi';
import CollapsibleTable from './promptTableV2';
import AddPromptPage from './AddPromptPage';

export const IndexPage = () => {
  const promptContext = useContext(PromptContext);
  const [open, setOpen] = useState(false);
  const { isAdmin, profEmail } = useContext(AuthContext);

  useEffect(() => {
    if (!promptContext.data.domains && isAdmin !== null && !promptContext.loading) {
      promptContext.fetchData(isAdmin, profEmail);
    }
  }, [promptContext.data.domains, isAdmin]);

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <BackButton />
        <HelpComponent helpUrl={HELP_URL.MANAGE_PROMPTS} />
        {isAdmin ? (
          <Button
            onClick={() => {
              setOpen(!open);
            }}
            style={{ float: 'right' }}
            variant="contained"
            color="primary"
          >
            Create Domain
          </Button>
        ) : null}
      </Grid>
      <Grid item xs={12}>
        <CollapsibleTable
          data={promptContext.data}
          loading={!promptContext.data.domains}
          openPrompt={open}
          setOpenPrompt={(v: boolean) => {
            setOpen(v);
          }}
        />
      </Grid>
    </Grid>
  );
};

const PromptManagement = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route
          path="/:domainId/:subDomainId?/:personaId?/prompts"
          element={<ViewPromptsPage />}
        />
        <Route
          path="/:domainId/:subDomainId?/:personaId?/prompts/add-prompt"
          element={<AddPromptPage />}
        />
        <Route
          path="/:domainId/:subDomainId?/:personaId?/prompts/edit-prompt/:promptId"
          element={<AddPromptPage />}
        />
      </Routes>
      <Outlet />
    </div>
  );
};
export default PromptManagement;
