/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { useEffect, useState } from 'react';
import { promptApiRef } from '../../../apis/promptManagementApi';

export const useGetPromptTags = (
  domainId: string,
  sortBy: string = 'frequency',
  shouldcall: boolean,
) => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const promptManagementApi = useApi(promptApiRef);

  const [promptTags, setPromptTags] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchMyAPI = async () => {
    if (promptManagementApi) {
      setLoading(true);
      try {
        const response = await promptManagementApi.getPromptTags(
          domainId,
          sortBy,
        );
        setPromptTags(response?.tags);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };

  useEffect(() => {
    if (shouldcall) fetchMyAPI();
  }, [shouldcall]);

  return [promptTags, loading];
};
