/**
 * Utility to check if an array is not null and has length > 0
 * It will return false if passed argument is null or not an array or has a length = 0
 * Otherwise if it is an array and has length > 0, returns true
 */
import { CHECK_USER_ROLE } from './constant';

export const checkArrayHasLength = (arr: any) => {
  if (!arr) {
    return false;
  }
  if (!Array.isArray(arr)) {
    return false;
  }
  if (arr?.length === 0) {
    return false;
  }
  return true;
};

/**
 * Utility to check if passed argument is iterable
 * @param obj Argument to check
 */
export const isIterable = (obj: any) => {
  if (obj === null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === 'function';
};

/**
 * Utility to check an array before rendering in JSX for not null, not empty and iterable implementation
 * @param arr
 */
export const checkArrBeforeRendering = (arr: any) => {
  if (!checkArrayHasLength(arr)) {
    return false;
  }
  //   console.log('Is iterable = ', isIterable(arr));
  return isIterable(arr);
};

/**
 * Returns datatype of input argument
 * @param data input argument
 */
export const checkDataType = (data: any) => {
  const retValue = Object.prototype.toString.call(data);
  if (!retValue) {
    return 'undefined';
  }
  const dataType = retValue.substring(
    retValue.indexOf(' ') + 1,
    retValue.indexOf(']'),
  );
  return dataType;
};

/**
 * Returns true of input is null or undefined
 * @param obj
 */
export const isNullOrUndefined = (obj: any) => {
  return obj === null || obj === undefined;
};

export const isAdmin = (userInfo: any) => {
  // add in null state for the pending state
  if (!userInfo) {
    return null;
  }
  if (userInfo?.isAdmin?.toLowerCase() === CHECK_USER_ROLE) {
    return true;
  }

  return false;
};

export const urlRegex =
  /(https?:\/\/(?:www\d*\.|(?!www\d*\.))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\d*\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\d*\.|(?!www\d*\.))[a-zA-Z0-9]+\.[^\s]{2,}|www\d*\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

export const emailRegex = /^[\w-!\.]+@([\w-]+\.)+[\w-]{2,4}$/;

// Parses supplied JWT token and returns the payload
export const parseJwt = (token: string): { exp: number } => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export const msUntilExpiry = (token: string): number => {
  const payload = parseJwt(token);
  const remaining =
    new Date(payload.exp * 1000).getTime() - new Date().getTime();
  return remaining;
};

export const getCookie = (cookieName: string) => {
  const name = cookieName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);

  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const byteSize = (str: string) => new Blob([str]).size;

export const scrollTo = ref => {
  if (ref && ref.current /* + other conditions */) {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

export const validImageFormat = (type: string) =>
  type === 'image/jpeg' || type === 'image/png' || type === 'image/svg+xml';

export const generateId = () => {
  return 'xxxxxxxxxxxx'
  .replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, 
          v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
};