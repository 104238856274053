import React, { useEffect, useState, useContext } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import {
  createStyles,
  CardContent,
  LinearProgress,
  makeStyles,
  Theme,
  Button,
} from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
// @ts-ignore
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// @ts-ignore
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import remarkSlug from 'remark-slug';
import rehypeExternalLinks from 'rehype-external-links';
import remarkFrontmatter from 'remark-frontmatter';
import { useTheme } from '@material-ui/core/styles';
import { AuthContext } from '../../../providers/AuthProvider';
import {
  notificationApiRef,
  NotificationApi,
} from '../../../apis/notificationApi';
import {
  InformationExchangeApi,
  informationExchangeApiRef,
} from '../../../apis/informationExchange';
import CancelAIDialog from './AICloseDialog';
import RephraseAIDialog from './AIRephraseDialog';
import { CANCEL_AI_STATUS } from '../../utils/constant';
import { useUserProfile } from '../../utils/useGetUserProfile';
import AIBotIcon from '../icons/aiBotIcon';
import { IEContext } from '../providers/IEProvider';

const STOP = 'stop';
const LENGTH = 'length';
const FUNCTION_CALL = 'function_call';
const CONTENT_FILTER = 'content_filter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postqueCardSection: {
      alignItems: ' flex-start',
      padding: '20px',
      gap: '10px',
      background: theme.palette.background.paper,
      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
      margin: '21px 0px 0px 54px',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        margin: '21px 0px 0px 45px',
      },
    },
    buttons: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      flexGrow: 0,
      gap: '6px',
      [theme.breakpoints.down('sm')]: {
        gap: '4px',
        marginTop: '10px',
      },
    },
    acceptAibutton: {
      textTransform: 'none',
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    regenerateBtn: {
      textTransform: 'none',
      color: theme.palette.primary.main,
      border: '1px solid ' + theme.palette.primary.main,
      '&:hover': {
        border: '1px solid ' + theme.palette.primary.main,
      },
    },
    rephraseBtn: {
      textTransform: 'none',
      color: theme.palette.primary.main,
      border: '1px solid ' + theme.palette.primary.main,
      '&:hover': {
        border: '1px solid ' + theme.palette.primary.main,
      },
    },
    rejectAiButton: {
      textTransform: 'none',
      color: theme.palette.primary.main,
      '&:hover': {},
    },
    cancelBtn: {
      color: theme.palette.text.secondary,
    },
    loadBar: {
      width: '100%',
      marginTop: '21px',
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    postQueCard: {
      display: 'flex',
      marginTop: '28px',
    },
    containerTop: {
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
  }),
);


const AIBlock = ({
  pid,
  content,
  onCancel,
  author,
  onSuccess,
  openaicorrelationid = undefined,
  onRephrase
}: {
  pid: string;
  content: string;
  onCancel: any;
  author: any;
  onSuccess: any;
  openaicorrelationid: string | undefined;
  onRephrase: any;
}) => {
  const theme = useTheme<Theme>();

  const [loading, setLoading] = useState<boolean>(true);
  const informationExchange: InformationExchangeApi = useApi(
    informationExchangeApiRef,
  );
  const { profEmail } = useContext(AuthContext);
  const [aiId, setAIId] = useState<string | undefined>('');
  const classes = useStyles();
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const [aiResponse, setAIResponse] = useState<any>('');
  const [disabled, setDisabled] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<'cancel' | 'rephrase' | boolean>(
    false,
  );
  const { accessToken } = useUserProfile();

  const { setAnsweredByAI } = useContext(IEContext);

  const onEdit = () => {
    setShowDialog('rephrase');
  };

  const getResponse = async (c: string | null = null) => {
    const res = await informationExchange.getAnswerStream(
      {
        questionid: pid,
        prompt: [
          {
            role: 'user',
            content: c ? c : content,
          },
        ],
        openaicorrelationid: aiId,
      },
      accessToken
    );

    const reader = res.body.getReader();
    while (true) {
      const { value, done } = await reader.read();

      if (done) {
        console.log('Stream complete');
        break;
      }
      
      const text = new TextDecoder().decode(value);
      const data = text.replace('event:end\ndata:[DONE]', "").split('event:message\ndata:');
      data.shift();
      let str = '';
      for (let i = 0; i<data.length; i++) {
        const d = data[i]
        try {
          const newData = JSON.parse(d);
          if (newData.choices) {
            if (newData.choices[0]?.delta?.content) {
              str += newData.choices[0]?.delta?.content;
            }
            if ((newData.choices[0]?.finish_reason === STOP) ||
                (newData.choices[0]?.finish_reason === LENGTH) ||
                (newData.choices[0]?.finish_reason === CONTENT_FILTER)) {
              setAIId(newData.id)
              setLoading(false);
            }
          }
        } catch(err) {
          const match = d.match(/{\"content\":\"(.*?)\"},\"c/)
          if (d && match !== null && match.length > 1) {
            str += match[1];
          }
        }
      }
      // data.forEach((d: string) => {
      //   try {
      //     const newData = JSON.parse(d);
      //     if (newData.choices) {
      //       if (newData.choices[0]?.delta?.content) {
      //         str += newData.choices[0]?.delta?.content;
      //       }
      //       if (newData.choices[0]?.finish_reason === 'stop') {
      //         setAIId(newData.id)
      //         setLoading(false);
      //         return
      //       }
      //     }
      //   } catch(err) {
      //     const match = d.match(/{\"content\":\"(.*?)\"},\"c/)
      //     if (d && match !== null && match.length > 1) {
      //       str += match[1];
      //     }
      //   }
      // });
      setAIResponse((prev: any) => prev + str);
    }
  };

  const onClickRegenerate = () => {
    setLoading(true);
    setAIResponse('');
    setDisabled(false);
    getResponse();
  };

  const handleReject = () => {
    setShowDialog('cancel');
    return;
  };

  const handleCancel = () => {
    onCancel();
    return;
  };

  const onCancelConfirmed = (comment: string) => {
    informationExchange.updateStatus(
      aiId || '',
      CANCEL_AI_STATUS,
      comment
    )
      .then(() => {
        notificationApi.sendNotification({
          message: `Thanks for your response`,
          disapperAfterMs: 2500,
          severity: 'success',
        });
        setShowDialog(false);
        onCancel();
      })
  }

  const handleBotResponseSave = async () => {
    informationExchange
      .postAnswer({
        pid: pid,
        detail: aiResponse,
        openaicorrelationid: aiId,
      })
      .then((data: any) => {
        notificationApi.sendNotification({
          message: `Answer posted successfully`,
          disapperAfterMs: 2500,
          severity: 'success',
        });
        setAnsweredByAI(true);
        setDisabled(true);
        onSuccess({
          openaicorrelationid: aiId,
          pid: pid,
          detail: aiResponse,
          author: profEmail,
          id: data.answer.id,
          createdat: data.answer.createdat,
        });
      })
      .catch((error: any) => {
        notificationApi.sendNotification({
          message: `Error occurred - ${error?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  useEffect(() => {
    if (openaicorrelationid) {
      setAIId(openaicorrelationid);
    }
    if (pid && content && accessToken) {
      getResponse();
    }
  }, [accessToken]);

  //   if (loading)
  return (
    <>
      {showDialog && (
        <>
          {showDialog === 'cancel' && (
            <CancelAIDialog
              isOpen={!!showDialog}
              onClose={() => {
                setShowDialog(false);
              }}
              onConfirm={(comment: string) => {
                onCancelConfirmed(comment);
              }}
            />
          )}
          {showDialog === 'rephrase' && (
            <RephraseAIDialog
              isOpen={!!showDialog}
              onClose={() => {
                setShowDialog(false);
              }}
              onConfirm={(val: string) => {
                //update question
                onRephrase(val);
                //regenerate answer via ai
                setShowDialog(false);
                setLoading(true);
                setAIResponse('');
                setDisabled(false);
                getResponse(val);
              }}
              initialValue={content}
            />
          )}
        </>
      )}
      <CardContent className={classes.postqueCardSection}>
        <div className={classes.containerTop}>
          <AIBotIcon fill={theme.palette.primary.main} fontSize="large" />
          {loading && <LinearProgress className={classes.loadBar} />}
          <div className={classes.buttons}>
            {!loading && content ? (
              <>
                <Button
                  className={classes.acceptAibutton}
                  variant="contained"
                  color="primary"
                  onClick={handleBotResponseSave}
                  disabled={disabled}
                >
                  Accept AI answer
                </Button>
                {profEmail &&
                  author &&
                  profEmail.toLowerCase() === author.toLowerCase() && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={onEdit}
                      disabled={disabled}
                      className={classes.rephraseBtn}
                    >
                      Rephrase question
                    </Button>
                  )}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={onClickRegenerate}
                  className={classes.regenerateBtn}
                  disabled={disabled}
                >
                  Regenerate response
                </Button>
                <Button
                  variant="text"
                  onClick={handleReject}
                  className={classes.rejectAiButton}
                  disabled={disabled}
                >
                  Reject AI answer
                </Button>
              </>
            ) : (
              <Button
                variant="text"
                onClick={handleCancel}
                className={classes.cancelBtn}
                disabled={disabled}
              >
                Cancel
              </Button>
            )}
          </div>
        </div>
        <div>
          {aiResponse && aiResponse !== '' && (
            <ReactMarkdown
              children={aiResponse}
              linkTarget="_blank"
              remarkPlugins={[remarkGfm, remarkSlug, remarkFrontmatter]}
              rehypePlugins={[rehypeRaw, rehypeExternalLinks]}
              className="react-markdown-ai-response"
              components={{
                code({ node, inline, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || '');
                  return (
                    <SyntaxHighlighter
                      children={String(children).replace(/\n$/, '')}
                      style={atomDark}
                      language={match ? match[1] : 'jsx'}
                      PreTag="div"
                      {...props}
                    />
                  );
                },
              }}
            />
          )}
        </div>
      </CardContent>
    </>
  );
};

export default AIBlock;