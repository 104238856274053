import React from 'react';
import { Entity } from '@backstage/catalog-model';
import { BackstageUserIdentity } from '@backstage/core-plugin-api';

export const getUserLink = (data: Entity): string => {
  if (data?.metadata && data?.spec) {
    let user = `${data?.spec?.owner}`;
    if (user.indexOf('user:') == 0) {
      user = user.replace('user:', '');
    }
    return `/catalog/${data?.metadata?.namespace}/user/${user}`;
  }
  return '#';
};

export const getUserName = (data: Entity): string => {
  if (data?.spec?.owner) {
    let user = `${data?.spec?.owner}`;
    if (user.indexOf('user:') == 0) {
      user = user.replace('user:', '');
    }
    if (user.indexOf('undefined.') >= 0) {
      user = user.replace('undefined.', '');
    }
    return user;
  }
  return '';
};

export const getTitleElement = (name: string): React.ReactElement => {
  if (name) {
    let showName = `${name}`;
    if (name.length > 57) {
      showName = `${showName.substring(0, 57)}...`;
    }
    {
      /*
     @ts-ignore */
    }
    return <span title={name}>{showName}</span>;
  }
  {
    /*
     @ts-ignore */
  }
  return <></>;
};

export const replaceAll = (str: string, find: string, replace: string) => {
  return str.replace(new RegExp(find, 'g'), replace);
};

export const hypenateTitle = (title: string) => {
  if (title) {
    title = title.trim();
    title = title.replace(/[^\w]|_/g, ' ');
    title = replaceAll(title, '  ', ' ');
    title = replaceAll(title, ' ', '-');
    return replaceAll(title, '--', '-');
  }
  return '';
};

export const limitChars = (val: string, limit: number) => {
  if (val) {
    if (val.length > limit) {
      return val.substring(0, limit);
    }
  }
  return val;
};

export const userIdHelper = async (
  userId: Promise<BackstageUserIdentity>,
  setUserParam: React.Dispatch<React.SetStateAction<string>>,
) => {
  const res = await userId;

  const result = res.userEntityRef;

  const resLower = result.toLowerCase();
  setUserParam(resLower);

  return resLower;
};
