import React from 'react';

const Diamond = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8673 18.8443L14.8798 27.9561C14.8798 27.9979 14.838 27.9979 14.838 27.9979L7.02194 18.8443H10.8673ZM7.18913 10.4431H0C0 10.7356 0.45977 11.1118 0.45977 11.1118L7.02194 18.8443L9.65517 16.0439L7.18913 10.4431Z"
        fill="#0096D1"
      />
      <path
        d="M29.8432 10.7774C29.6342 10.9864 29.2998 10.9864 29.0908 10.7774C29.0073 10.6938 28.9237 10.5266 28.9237 10.4013H22.7795L18.9341 6.22153L22.9466 2L29.7596 9.98328C29.7596 9.98328 29.7596 10.0251 29.8014 10.0251H29.8432C30.0522 10.2759 30.0522 10.6102 29.8432 10.7774V10.7774ZM14.9634 10.4431L18.8923 6.26332L14.9634 2L11.0344 6.26332L14.9634 10.4431Z"
        fill="#76DFFF"
      />
      <path
        d="M15.1304 27.9979C15.0886 28.0397 15.0468 27.9979 15.0886 27.9561L19.1011 18.8443H22.9465L15.1304 27.9979ZM20.3133 16.0439L22.9465 18.8443L29.8012 10.8192C29.8012 10.8192 29.9684 10.5684 29.9684 10.4431H22.7793L20.3133 16.0439Z"
        fill="#59CAFC"
      />
      <path
        d="M11.0343 6.26335L7.18896 10.4431H14.9633L11.0343 6.26335Z"
        fill="#27C7FF"
      />
      <path
        d="M18.9344 6.26335L14.9636 10.4431H22.7797L18.9344 6.26335Z"
        fill="#B0E9FF"
      />
      <path
        d="M11.0345 6.26333L7.18913 10.4431H0C0 10.4431 0.0417973 10.1923 0.208986 10.0251L6.81296 2.29259C6.85475 2.2508 6.89655 2.209 6.93835 2.209C7.02194 2.08361 7.14734 2.04181 7.31452 2.04181C7.60711 2.04181 7.81609 2.29259 7.81609 2.54338C7.81609 2.62697 7.77429 2.71057 7.7325 2.79416L11.0345 6.26333ZM14.9634 10.4431L20.3135 16.0857L22.7795 10.4431H14.9634ZM7.18913 10.4431L9.65517 16.0857L15.0052 10.4431H7.18913ZM10.8673 18.8443L14.9216 28.0397C14.9634 28.0815 15.0052 28.0815 15.047 28.0397L19.1014 18.8443H10.8673ZM20.3135 16.0439L19.1014 18.8443H22.9467L20.3135 16.0439Z"
        fill="#00B1FF"
      />
      <path
        d="M18.9343 6.26332L14.9635 2H15.2561H22.9468L18.9343 6.26332ZM14.9635 2H14.6709H7.48181C7.31462 2 6.98025 2 7.10564 2.12539L11.0346 6.26332L14.9635 2Z"
        fill="#59CAFC"
      />
      <path
        d="M9.6552 16.0439L7.02197 18.8443H10.8673L9.6552 16.0439Z"
        fill="#0074A8"
      />
      <path
        d="M9.65527 16.0439L10.8674 18.8443H19.1015L20.3136 16.0439L14.9635 10.4431L9.65527 16.0439Z"
        fill="#0096D1"
      />
      <path
        d="M21.9854 10.5267C23.1139 10.8193 23.9917 11.697 24.2843 12.8255C24.326 12.9509 24.4514 12.9509 24.4932 12.8255C24.7858 11.697 25.6636 10.8193 26.7921 10.5267C26.9175 10.4849 26.9175 10.3595 26.7921 10.3177C25.6636 10.0251 24.7858 9.14736 24.4932 8.01883C24.4514 7.89344 24.326 7.89344 24.2843 8.01883C23.9917 9.14736 23.1139 10.0251 21.9854 10.3177C21.86 10.3595 21.86 10.4849 21.9854 10.5267Z"
        fill="white"
      />
    </svg>
  );
};

export default Diamond;
