import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /* common styles */

    registerApi: {
      backgroundColor: theme.palette.background.paper,
      padding: '20px !important',
    },
    successbtn: {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    cancelbtn: {
      marginLeft: '15px',
      ['@media (max-width:920px) and (min-width: 280px)']: {
        marginTop: '20px',
        marginLeft: '0',
      },
    },
    divider: {
      width: '100%',
      height: '1px',
      background: '#cccccc',
      marginTop: '5%',
    },
    columnDirection: {
      ['@media (max-width:920px) and (min-width: 280px)']: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      },
    },

    /*register api styles */
    dragHeading: {
      ['@media (max-width:320px)']: {
        margin: '0',
        padding: '0',
      },
    },
    registerFormBtns: {
      marginBottom: '1rem',
    },
    registerPageTitle: {
      fontSize: '24px',
      // color: '#000000',
      fontWeight: 500,
      fontStyle: 'normal',
    },
    hereLink: {
      textDecoration: 'underline',
      color: theme.palette.colors.gold,
      fontSize: '14px',
    },
    jsonText: {
      color: theme.palette.text.secondary,
      fontSize: '16px',
    },
    jsonTextError: {
      color: '#d2785a',
      fontSize: '16px',
    },
    jsonTextSuccess: {
      color: '#3d936c',
      fontSize: '16px',
    },
    /* register steps */
    registerSteps: {
      marginLeft: '20px',
      ['@media (max-width:920px) and (min-width: 280px)']: {
        margin: '0',
        marginTop: '20px',
      },
    },
    registerInfo: {
      marginBottom: '5%',
      fontSize: '14px',
      fontWeight: 400,
      paddingLeft: '30px',
      fontStyle: 'normal',
    },
    registerText: {
      padding: '4px 22px 4px 4px',
    },
    stepsHeading: {
      fontSize: '16px',
      fontWeight: 400,
      fontStyle: 'normal',
    },

    /* register success */
    successBox: {
      backgroundColor: theme.palette.background.paper,
      textAlign: 'center',
    },
    successMsg: {
      fontSize: '24px',
    },
    tickIcon: {
      fontSize: '70px',
      marginTop: '20px',
      color: theme.palette.success.main,
    },
    registerApiBtn: {
      marginBottom: '20px',
    },
    successLink: {
      fontSize: '20px',
      textDecoration: 'underline',
      '&:hover': {
        color: 'blue',
      },
    },

    /* generate swagger page */
    generateSwagger: {
      boxShadow:
        '0px 0px 1px rgba(9, 30, 66, 0.31), 0px 1px 1px rgba(9, 30, 66, 0.25)',
      borderRadius: '8px',
    },
    viewDefBtn: {
      float: 'right',
    },
    generateDef: {
      background: '#fff !important',
      padding: '10px 20px',
      marginBottom: '40px',
      width: '30%',
      overflow: 'auto',
      height: '100vh',
      animation: 'reveal 2s forwards',
      zIndex: 1,
      float: 'right',
    },

    /* generate swagger */
    apiRegistrationBtn: {
      fontSize: '15px',
    },
    generateHeading: {
      fontSize: '24px',
      color: theme.palette.text.secondary,
      fontWeight: 500,
      fontStyle: 'normal',
    },
    height100: {
      height: '100%',
    },
    generateInput: {
      height: '100%',
      backgroundColor: theme.palette.background.tertiary,
      border: '1px solid black',
      borderRadius: '5px',
      padding: '10px',
      overflow: 'auto',
    },
    swaggerContainer: {
      height: 'calc(100% - 135px)',
      ['@media (max-width:1368px) and (min-width: 768px)']: {
        height: 'calc(100% - 251px)',
      },
      ['@media (max-width:768px) and (min-width: 280px)']: {
        height: 'calc(100% - 288px)',
      },
    },
    /* confirm details */

    confirmHeading: {
      fontSize: '24px',
    },
    confirmTitle: {
      fontSize: '11px',
      color: theme.palette.text.tertiary,
      fontWeight: 500,
      fontStyle: 'normal',
    },
    confirmDesc: {
      fontSize: '12px',
      color: theme.palette.text.secondary,
      fontWeight: 600,
      fontStyle: 'normal',
    },
    supportDesc: {
      wordBreak: 'break-word',
    },
  }),
);
