import { createApiRef } from '@backstage/core-plugin-api';
import { AxiosInstanceProviderApi } from '../../../../packages/app/src/apis/axiosInstanceApi';
import { AxiosInstance, AxiosResponse } from 'axios';

export interface ReadmeApi {
  fetchReadmeContent: (data: any) => Promise<AxiosResponse>;
}

export const readmeApiRef = createApiRef<ReadmeApi>({
  id: 'cbre.devx.api.readme',
});

export class ReadmeApiImpl implements ReadmeApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  async fetchReadmeContent(data: any) {
    const instance = await this.axiosInstancePromise;

    return instance.post('readme/info', data);
  }
}
