import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useCustomStyles = makeStyles((theme: Theme) =>
  createStyles({
    levelAndBadgesContainer: {
      padding: '24px 24px 24px 24px',
      gridArea: 'pageContent',
    },
    CardTypeContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        marginTop: '8px',
        justifyContent: 'end',
        alignItems: 'flex-start',
      },
    },

    CardTypeFont: {
      fontSize: '11px',
      lineHeight: '16px',
    },
    CardTypeFontBold: {
      fontSize: '16px',
      lineHeight: '18px',
    },
    modifyPointsContainer: {
      padding: '24px 24px 24px 24px',
      gridArea: 'pageContent',
      backgroundColor: theme.palette.background.paper,
      margin: '24px',
    },
    saveCancelButtonGroup: {
      justifyContent: 'right',
      display: 'flex',
    },

    cancelButton: {
      marginRight: '16px',
    },
    CardContainerFlex: {
      height: '174px',
      padding: '30px 20px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
      marginBottom: '16px',
      marginRight: '10px',
      marginLeft: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    CardContentOwnerText: {},

    CardEntityContainer: {
      padding: '10px 15px',
      minWidth: '375px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
      marginBottom: '2px',
    },

    CardTypeContainerFirst: {
      display: 'flex',
      flexDirection: 'column',
      paddingRight: '16px',
    },

    CardTypeFontPoint: {
      fontSize: '11px',
      lineHeight: '16px',
      color: '#17E88F',
    },

    CardTypeImageAndText: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },

    marginTop24: {
      marginTop: '24px',
    },
    buttonContainer: {
      alignItems: 'end',
    },
  }),
);
