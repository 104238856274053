import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useCallback, useState } from 'react';
import * as _ from 'lodash';

export interface SuggestProps {
  options: any[];
  label: string;
  onInputChangeDebounced: (params: any) => void;
  onChange: (event: any, value: any, reason: string) => void;
  value: any;
  loading: any;
  id: string;
  getOptionLabel: (opt: any) => string;
  multiple: any;
}

export interface SuggestionItemType {
  label: string;
  value: string;
  configLocation?: string;
}

const Suggest = ({
  options,
  label,
  onInputChangeDebounced,
  onChange,
  value,
  loading,
  id,
  getOptionLabel,
  multiple,
}: SuggestProps) => {
  const [open, setOpen] = useState(false);

  const debounceCallback = useCallback(
    _.debounce((value: any) => {
      onInputChangeDebounced(value);
    }, 800),
    [],
  );

  const onInputChange = async (e: any) => {
    if (e?.target?.value?.length > 0) {
      debounceCallback(e.target.value);
    }
  };

  const getValue = (value: string | any[]) => {
    if (value && _.isString(value) && !_.isEmpty(value)) {
      return JSON.parse(value);
    } else if (value && (_.isArray(value) || _.isObject(value))) {
      return value;
    }
    return value;
  };

  const getOptionSelected = (
    option: SuggestionItemType,
    value: SuggestionItemType,
  ) => {
    if (
      _.isEqual(option?.label, value?.label) &&
      _.isEqual(option?.value, value?.value)
    ) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <Autocomplete
        id={id}
        loading={loading}
        multiple={multiple}
        filterSelectedOptions
        getOptionSelected={getOptionSelected}
        defaultValue={getValue(value)}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        loadingText="Loading..."
        options={options}
        onChange={onChange}
        getOptionLabel={getOptionLabel}
        renderInput={params => (
          <TextField {...params} label={label} onChange={onInputChange} />
        )}
      />
    </div>
  );
};
export default Suggest;
