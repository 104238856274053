import React, { Suspense, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  Chip,
  Button,
  CardActionArea,
  IconButton,
  CardHeader,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { IPromptData } from '../interfaces';
import PersonIcon from '@material-ui/icons/Person';
import { useStyles } from '../styles';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import ShareIcon from '@material-ui/icons/Share';
import { useStarredEntity } from '@backstage/plugin-catalog-react';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { useApi } from '@backstage/core-plugin-api';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import Edit from '@material-ui/icons/Edit';

export interface PromptStoreCardProps {
  type: string;
  promptData: IPromptData;
  onPromptClicked: Function;
  onUpdateUsage: Function;
  usageCount: number;
  onTryoutClick: Function;
}

const PromptStoreCard: React.FC<PromptStoreCardProps> = ({
  type,
  promptData,
  onPromptClicked,
  onUpdateUsage,
  usageCount,
  onTryoutClick,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const tagsToDisplay = isMobile ? 1 : 2;

  const handleTryNowClick = () => {
    onUpdateUsage();
    onTryoutClick(promptData);
  };
  const { toggleStarredEntity, isStarredEntity } = useStarredEntity(
    `PromtStore:${promptData.id}`,
  );

  const handleBookmarkClick = () => {
    toggleStarredEntity();
  };

  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const handleShareClick = () => {
    const urlToShare = `${window.location.origin}${window.location.pathname}?prompt=${promptData.id}`;
    navigator.clipboard.writeText(urlToShare);
    notificationApi.sendNotification({
      message: 'Prompt link copied to clipboard',
      disapperAfterMs: 2500,
      severity: 'success',
    });
  };

  return (
    <Card
      className={
        type === 'myPrompts'
          ? `${classes.cardRoot}`
          : `${classes.cardRootAllPrompts}`
      }
    >
      <CardHeader
        className={`${classes.flexStart} ${classes.gap1} ${classes.headerSection}`}
        classes={{ action: classes.headerAction }}
        title={
          <Typography
            variant="h6"
            component="div"
            gutterBottom
            className={classes.cardHeading}
            onClick={() => onPromptClicked()}
          >
            {promptData.promptkey}
          </Typography>
        }
        action={
          <div className={classes.buttonsContainer}>
            <IconButton
              className={`${classes.bookmarkButton}`}
              onClick={handleBookmarkClick}
            >
              {isStarredEntity ? (
                <BookmarkIcon
                  classes={{ root: classes.shareBookMarkIconSize }}
                />
              ) : (
                <BookmarkBorderIcon
                  classes={{ root: classes.shareBookMarkIconSize }}
                />
              )}
            </IconButton>
            {type === 'allPrompts' && (
              <IconButton
                className={`${classes.bookmarkButton}`}
                onClick={handleShareClick}
              >
                <ShareIcon classes={{ root: classes.shareBookMarkIconSize }} />
              </IconButton>
            )}
            {/* {promptData?.status === 'Resubmit' && (
              <IconButton className={`${classes.bookmarkButton}`}>
                <Edit />
              </IconButton>
            )} */}
          </div>
        }
      />
      <CardContent>
        <Box
          display="flex"
          alignItems="center"
          mb={3}
          gridColumnGap={8}
          className={classes.promptCardBody}
        >
          <Typography variant="body2" className={classes.promptOwner}>
            <PersonIcon fontSize="small" />
            {promptData.authordisplayname}
            <FiberManualRecord
              fontSize="small"
              classes={{ root: classes.circleIcon }}
            />
          </Typography>
          {type === 'allPrompts' ? (
            <Typography variant="body2">
              <Suspense fallback={<div>Loading...</div>}>
                {usageCount | 0} uses
              </Suspense>
            </Typography>
          ) : (
            <Typography
              variant="body2"
              className={`${
                classes[`promptStatus${promptData?.status.toLowerCase()}`]
              }`}
            >
              {promptData?.status}
            </Typography>
          )}
        </Box>
        {promptData?.comments?.additionalComments &&
          promptData?.comments?.additionalComments !== '' &&
          type === 'myPrompts' && (
            <div className={classes.reviewerComments}>
              <span className={classes.commentsInfo}>
                <InfoOutlined className={classes.infoIconComments} />
                <span className={classes.comment}>
                  {promptData?.comments?.additionalComments}
                </span>
              </span>
            </div>
          )}
      </CardContent>
      <CardActions classes={{ root: classes.actionSection }}>
        <Box display="flex">
          {promptData.tags.slice(0, tagsToDisplay).map((tag, index) => (
            <Chip
              key={index}
              size="medium"
              label={tag}
              className={classes.chip}
            />
          ))}
          {promptData.tags.length > tagsToDisplay && (
            <Chip
              size="medium"
              label={`+${promptData.tags.length - tagsToDisplay}`}
              className={classes.chip}
            />
          )}
        </Box>
        <Button
          variant="contained"
          size="medium"
          onClick={() => handleTryNowClick()}
          className={classes.tryNowButton}
        >
          Try now
        </Button>
      </CardActions>
    </Card>
  );
};

export default PromptStoreCard;
