import React from 'react';

const Subscriber = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 11.25V7.5H22.5V11.25H18.75V13.75H22.5V17.5H25V13.75H28.75V11.25H25ZM11.25 15C14.0125 15 16.25 12.7625 16.25 10C16.25 7.2375 14.0125 5 11.25 5C8.4875 5 6.25 7.2375 6.25 10C6.25 12.7625 8.4875 15 11.25 15ZM11.25 7.5C12.625 7.5 13.75 8.625 13.75 10C13.75 11.375 12.625 12.5 11.25 12.5C9.875 12.5 8.75 11.375 8.75 10C8.75 8.625 9.875 7.5 11.25 7.5ZM19.2375 18.2C17.1375 17.125 14.4125 16.25 11.25 16.25C8.0875 16.25 5.3625 17.125 3.2625 18.2C2.0125 18.8375 1.25 20.125 1.25 21.525V25H21.25V21.525C21.25 20.125 20.4875 18.8375 19.2375 18.2ZM18.75 22.5H3.75V21.525C3.75 21.05 4 20.625 4.4 20.425C5.8875 19.6625 8.2875 18.75 11.25 18.75C14.2125 18.75 16.6125 19.6625 18.1 20.425C18.5 20.625 18.75 21.05 18.75 21.525V22.5Z"
        fill="#17E88F"
      />
    </svg>
  );
};

export default Subscriber;
