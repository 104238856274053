import React from 'react';
import { Card, Theme, createStyles, makeStyles } from '@material-ui/core';

import RecursiveTreeView from './treeView';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    taxanomyCard: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      background: theme.palette.background.paper,
      //borderLeft: "3px solid #80BBAD",
      boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.14)',
      borderRadius: '4px',
      marginTop: '15px',
      '& ul:first-child > li': {
        marginLeft: '0px !important',
      },
      '& ul': {
        paddingLeft: '0px',
        marginLeft: '0px',
        width: '100%',
        '& div[class*="iconContainer"]': {
          display: 'none',
        },
      },

      '& ul li': {
        alignSelf: 'stretch',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        background: theme.palette.background.paper,
        borderLeft: '3px solid #80BBAD',
        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.14)',
        borderRadius: '4px',
        margin: '0px 5px 3px 5px !important',
      },
      '& ul li > ul  li ': {
        background: theme.palette.background.paper,
        borderLeft: '3px solid #435354',
        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.14)',
        borderRadius: '4px',
        alignSelf: 'stretch',
        margin: '0px 5px 3px 5px',
      },
      '& ul li > ul  li > ul li': {
        background: theme.palette.background.paper,
        borderLeft: '3px solid #DBD99A',
        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.14)',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        margin: '0px 5px 5px 5px',
      },
      '& ul li > ul  li > ul li > ul li ': {
        background: theme.palette.background.paper,
        borderLeft: '3px solid #D2785A',
        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.14)',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        margin: '0px 5px 5px 5px',
      },
      '& ul li > ul  li > ul li > ul li > ul li ': {
        background: theme.palette.background.paper,
        borderLeft: '3px solid #17e88f',
        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.14)',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        margin: '0px 5px 5px 5px',
      },
    },
    listHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    parentLabel: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.secondary,
    },
    childLabel: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '21px',
      color: theme.palette.text.secondary,
      padding: '16px',
    },
    listItem: {
      alignSelf: 'stretch',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      background: '#FFFFFF',
      borderLeft: '3px solid #435354',
      boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.14)',
      borderRadius: '4px',
      margin: '0px 5px 3px 5px !important',
    },
    listChild: {
      borderLeft: '3px solid #DBD99A',
      boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.14)',
      borderRadius: '4px',
      alignSelf: 'stretch',
      background: '#FFFFFF',
      margin: '0px 5px 3px 5px',
    },
    nestedGroup: {
      background: '#FFFFFF',
      borderLeft: '3px solid #DBD99A',
      boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.14)',
      borderRadius: '4px',
      alignSelf: 'stretch',
      margin: '0px 5px 3px 5px',
    },
    nestedChild: {
      background: '#FFFFFF',
      borderLeft: '3px solid #D2785A',
      boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.14)',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      margin: '0px 5px 5px 5px',
    },
  }),
);

const TaxanomyCard = (props: any) => {
  const classes = useStyles();

  return (
    <Card className={classes.taxanomyCard}>
      <RecursiveTreeView data={props?.data || []} classes={classes} />
    </Card>
  );
};
export default TaxanomyCard;
