import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const LeftArrowIcon = ({ style }: any) => (
  <SvgIcon
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill="#9aa9b3"
    style={{
      fontSize: '2rem',
      ...style,
    }}
  >
    <path
      fillRule="evenodd"
      d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
    />
  </SvgIcon>
);

export default LeftArrowIcon;
