import {
  Card,
  CardContent,
  makeStyles,
  Grid,
  Typography,
} from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { AboutField } from './AboutField';
import '../EditPageStyle.css';
import {
  handleApiApmID,
  handleCatagory,
  handleSegment,
} from '../../utils/HandleFunctionsInApiEditpage';

import * as CONSTANT from '../../utils/constant';

import { Tags } from './Tags';
import { useApi } from '@backstage/core-plugin-api';
import { userProfileApiRef } from '../../../apis/userProfileApi';

const useStyles = makeStyles(theme => ({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',
    maxWidth: 900,
    // background: '#fff',
  },
  gridItemCardContent: {
    flex: 1,
  },
  validatorSegment: {
    fontSize: '11px',
    color: '#f00',
  },
  EditInputText: {
    padding: '5px 12px',
    margin: ' 8px 0',
    boxSizing: 'border-box',
    border: '1px solid #f1f1f1',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    fontSize: '11px',
  },
}));

export const SegmentCard = props => {
  const classes = useStyles();

  const {
    defaultEntity,
    apiSegment,
    apiCatagory,
    apiReusable,

    setApiCatagory,
    setApiSegment,
    apiPerformance,

    lastUpdatedDate,

    apiApmID,
    setApiApmId,
    apiRegion,
    setApiRegion,
    apiCountry,
    setApiCountry,
    setIsButtonDisabled,
    isWso2
  } = props;

  const formatDate =
    (lastUpdatedDate &&
      lastUpdatedDate?.trim()?.length > 0 &&
      `${lastUpdatedDate?.toString()}`.split('T')) ||
    '';
  let DateTime = '';
  if (
    formatDate &&
    !formatDate.includes('no data object') &&
    formatDate.length >= 2 &&
    formatDate[1]?.length > 5
  ) {
    DateTime =
      formatDate && `${formatDate[0]}' '${formatDate[1].substring(0, 5)}`;
  }

  const [validatorSegment, setValidatorSegment] = useState(false);
  const [validatorApiCatagory, setValidatorApiCatagory] = useState(false);
  const [validatorApiApmID, setValidatorApiApmID] = useState(false);

  const calculateApiReusable =
    apiReusable?.toLowerCase() === CONSTANT.CHECK_USER_ROLE
      ? CONSTANT.TEXT_YES
      : CONSTANT.TEXT_NO;
  const finalizeApiReusable =
    apiReusable === '' || apiReusable === null || apiReusable === undefined
      ? ''
      : calculateApiReusable;

  const usernameRef = useRef(null);
  const userProfileApi = useApi(userProfileApiRef);

  const onMouseEnter = () => {
    let email = undefined;
    const ownerEmail = `''${defaultEntity?.spec?.owneremail}`;
    email = ownerEmail;
    if (
      ownerEmail === undefined ||
      ownerEmail === 'undefined' ||
      String(ownerEmail).trim()?.length === 0
    ) {
      const owner = getUserName(defaultEntity);
      email = `${owner}@cbre.com`;
    }
    userProfileApi.setUserProfileState({
      email,
      anchorRef: usernameRef?.current,
    });
  };

  return (
    <Card className={classes.gridItemCard}>
      <CardContent className={classes.gridItemCardContent}>
        <Grid container>
          <AboutField label="Segment" gridSizes={{ xs: 12, sm: 6, lg: 4 }}>
            <input
              type="text"
              className={classes.EditInputText}
              onChange={event =>
                handleSegment(
                  event,
                  setApiSegment,
                  setValidatorSegment,
                  setIsButtonDisabled,
                )
              }
              defaultValue={apiSegment}
              disabled={isWso2}
              data-testid="handle-segment"
            />
            {validatorSegment && (
              <Typography className={classes.validatorSegment}>
                *no of character exceeded
              </Typography>
            )}
          </AboutField>
          <AboutField label="Region" gridSizes={{ xs: 12, sm: 6, lg: 4 }}>
            <Tags
              defaultTags={apiRegion}
              setTags={setApiRegion}
              numberOfTags={5}
              numberOfCharacters={20}
              setIsButtonDisabled={setIsButtonDisabled}
            />
          </AboutField>
          <AboutField label="Country" gridSizes={{ xs: 12, sm: 6, lg: 4 }}>
            <Tags
              defaultTags={apiCountry}
              setTags={setApiCountry}
              numberOfTags={5}
              numberOfCharacters={20}
              setIsButtonDisabled={setIsButtonDisabled}
            />
          </AboutField>
          <AboutField label="API Category" gridSizes={{ xs: 12, sm: 6, lg: 4 }}>
            <input
              type="text"
              data-testid="test-Category"
              className={classes.EditInputText}
              disabled={isWso2}
              onChange={event =>
                handleCatagory(
                  event,
                  setApiCatagory,
                  setValidatorApiCatagory,
                  setIsButtonDisabled,
                )
              }
              defaultValue={apiCatagory}
            />
            {validatorApiCatagory && (
              <Typography className={classes.validatorSegment}>
                *no of character exceeded
              </Typography>
            )}
          </AboutField>
          {/* <AboutField label="APMID" gridSizes={{ xs: 12, sm: 6, lg: 4 }}>
            <input
              type="text"
              data-testid="test-APMID"
              className={classes.EditInputText}
              defaultValue={apiApmID}
              disabled={isWso2}
              onChange={event =>
                handleApiApmID(
                  event,
                  setApiApmId,
                  setValidatorApiApmID,
                  setIsButtonDisabled,
                )
              }
            />
            {validatorApiApmID && (
              <Typography className={classes.validatorSegment}>
                *no of character exceeded
              </Typography>
            )}
          </AboutField> */}
          <AboutField
            label="Last Updated Date"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={DateTime}
          />
          <AboutField
            label="Product Owner"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={
              <span ref={usernameRef} onMouseEnter={onMouseEnter}>
                {defaultEntity?.spec?.owner?.replace('user:', '') || 'no data'}
              </span>
            }
          />
          {/* <AboutField
            label="Reusable"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={finalizeApiReusable}
          /> */}
          {/* <AboutField
            label="Performance"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={apiPerformance}
          /> */}
        </Grid>
      </CardContent>
    </Card>
  );
};
