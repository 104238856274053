/* eslint-disable react-hooks/exhaustive-deps */
import { Content, PageWithHeader } from '@backstage/core-components';
import React, { useEffect, useState } from 'react';
import PromptTags from './components/PromptTags';
import PromptList from './components/PromptList';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';
import {
  Button,
  Chip,
  FormControl,
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useStyles } from './styles';
import BackButton from '../utils/backButton';
import { HelpComponent } from '../utils/helpComponent';
import TryItOut from './components/TryItOut';
import SearchBar from './components/SearchBar';
import SearchIcon from '@material-ui/icons/Search';
import { IPromptData, IPromptTag } from './interfaces';
import { useGetPrompts } from './hooks/useGetPrompts';
import { useGetPromptTags } from './hooks/useGetPromptTags';
import { useFilteredPrompts } from './hooks/useFilteredPrompts';
import AllTagsPopup from './components/AllTagsPopup';
import PromptDetailsPopup from './components/PromptDetailsPopup';
import { usePromptUsage } from './hooks/usePromptUsage';
import { useEnvScopes } from './hooks/useEnvScopes';
import HELP_URL from '../utils/helpLinkConstant';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AIBotIcon from './icons/PromptEnhancerIcon';
import PromptEnhancerIcon from './icons/PromptEnhancerIcon';
import useAiPopup from '../common/AIPopup/hooks/useAIPopup';
import { useFilteredStatus } from './hooks/useFilteredStatus';

interface PromptStoreHomeProps {
  type: 'allPrompts' | 'myPrompts';
  // Add any props you need here
}

const PromptStoreHome = ({ type }: PromptStoreHomeProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme<Theme>();

  const [searchParams, setSearchParams] = useSearchParams();
  const urlSearch = searchParams.get('search');
  const urlTags = searchParams.getAll('tags');
  const urlPromptId = searchParams.get('prompt');
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [searchText, setSearchText] = useState('');
  const emptyPopupState = {
    open: false,
    onClose: () => {},
    onConfirm: (data: any) => {},
    popupContent: {},
  };

  const { domainId, subDomainId, personaId } = useEnvScopes();

  const [allTagsPopupProps, setAllTagsPopupProps] = useState({
    ...emptyPopupState,
  });

  const [promptDetailsPopup, setPromptDetailsPopup] = useState({
    ...emptyPopupState,
  });

  const [prompts, myPrompts, promptsLoading] = useGetPrompts(
    domainId,
    subDomainId,
    personaId,
    true,
  );

  console.log(myPrompts);

  const [tryoutPopup, setTryoutPopup] = useState<any>({
    open: false,
  });

  const onTryoutClick = (props: any) => {
    setTryoutPopup({
      open: true,
      ...props,
    });
    handleClosePromptDetailsPopup();
  };

  const onTryoutClose = () => {
    setTryoutPopup({
      open: false,
    });
  };

  const [tags, tagsLoading] = useGetPromptTags(domainId, 'frequency', true);

  const [getUsage, updateUsage, usageCountObj] = usePromptUsage(domainId);

  const [filterOption, setFilterOption] = useState({
    status: 'All status',
  });

  const [formatedTags, setFormatedTags] = useState<IPromptTag[]>([]);
  const [bookmarkSelected, setBookmarkSelected] = useState(false);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const [filteredPrompts] = useFilteredPrompts(
    type === 'myPrompts' ? myPrompts : prompts,
    filterOption?.status,
    formatedTags,
    searchText,
    bookmarkSelected,
  );

  const { status } = useFilteredStatus(myPrompts);

  useEffect(() => {
    const __tags = tags.map((tag: string) => {
      return {
        label: tag,
        selected: urlTags.includes(tag) ? true : false,
      };
    });
    const selectedTabs = __tags.filter((tag: IPromptTag) => tag.selected);
    const notSelectedTabs = __tags.filter((tag: IPromptTag) => !tag.selected);
    setFormatedTags([...selectedTabs, ...notSelectedTabs]);
    if (urlSearch) setSearchText(urlSearch);
  }, [tags]);

  const handleTagClicked = (tag: IPromptTag) => {
    setFormatedTags(
      formatedTags?.map((localtag: IPromptTag) =>
        tag.label === localtag.label ? tag : localtag,
      ),
    );
  };

  /* Tags Popup */

  const handleCloseAllTagsPopup = () => {
    setAllTagsPopupProps(prevState => {
      return {
        ...prevState,
        open: false,
      };
    });
  };

  const handleApplyTagsPopup = (__tags: IPromptTag[]) => {
    handleCloseAllTagsPopup();
    const selectedTabs = __tags.filter((tag: IPromptTag) => tag.selected);
    const notSelectedTabs = __tags.filter((tag: IPromptTag) => !tag.selected);
    setFormatedTags([...selectedTabs, ...notSelectedTabs]);
  };

  const showAllTags = () => {
    setAllTagsPopupProps({
      ...emptyPopupState,
      open: true,
      onClose: () => {
        handleCloseAllTagsPopup();
      },
      onConfirm: (__tags: IPromptTag[]) => {
        handleApplyTagsPopup(__tags);
      },
      popupContent: {
        tags: formatedTags,
      },
    });
  };
  /* Tags Popup Ends */

  /* Details Popup - Starts */
  const handleClosePromptDetailsPopup = () => {
    setPromptDetailsPopup(prevState => {
      return {
        ...prevState,
        open: false,
      };
    });
    setSearchParams({});
  };

  const handlePromptClicked = (promptData: any) => {
    setPromptDetailsPopup({
      open: true,
      onClose: () => {
        handleClosePromptDetailsPopup();
      },
      onConfirm: (data: any) => {
        handleClosePromptDetailsPopup();
        updateUsage(promptData.id);
        onTryoutClick(promptData);
      },
      popupContent: {
        promptData,
        uses: usageCountObj[promptData.id] || 0,
      },
    });
  };
  /* Details Popup - Ends */

  const handleBookmarkClicked = () => {
    setBookmarkSelected((prevState: boolean) => !prevState);
  };

  const [overFlowTagsCount, setOverFlowTagsCount] = useState(0);

  /* For setting url params in url */
  useEffect(() => {
    if (urlPromptId) {
      return;
    }
    if (formatedTags.length > 0) {
      setSearchParams({
        ...(searchText && { search: searchText }),
        tags: formatedTags.filter(tag => tag.selected).map(tag => tag.label),
      });
    }
  }, [formatedTags, searchText]);

  const { open } = useAiPopup();
  /* For opening prompt dialog */
  useEffect(() => {
    if (!open && urlPromptId && filteredPrompts.length > 0) {
      const promptData = filteredPrompts.find(
        (prompt: IPromptData) => prompt.id === urlPromptId,
      );
      if (promptData) handlePromptClicked(promptData);
    }
  }, [filteredPrompts, urlPromptId, open]);

  return (
    <>
      {/* <PromptStoreHeader
          title="Prompt Store"
          subtitle="Domains & Prompts /"
          buttonText="Share Prompts"
          onButtonClick={handleShareClicked}
        /> */}

      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.flexSpacebetween}
        >
          <BackButton />
          <div>
            <HelpComponent helpUrl={HELP_URL.PROMPT_STORE} />
            {!isMobile ? (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => navigate('../prompt-enhancer')}
                className={classes.promptEnhancerButton}
              >
                <PromptEnhancerIcon
                  fill={theme.palette.primary.light}
                  fontSize="small"
                />
                <div>Prompt Enhancer</div>
              </Button>
            ) : null}
          </div>
        </Grid>
        {isMobile ? (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            classes={{ root: classes.pagePadding }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate('../prompt-enhancer')}
              className={classes.promptEnhancerButton}
            >
              <PromptEnhancerIcon
                fill={theme.palette.primary.light}
                fontSize="small"
              />
              <div>Prompt Enhancer</div>
            </Button>
          </Grid>
        ) : null}
      </Grid>

      {tagsLoading || promptsLoading ? (
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <LinearProgress />
          </Grid>
        </Grid>
      ) : (
        <Grid container className={!isMobile ? classes.pagePadding : ''}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            // className={classes.searchBarGrid}
          >
            <Grid container className={classes.searchStatusFilter}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                {/* <SearchBar
                  inputText={searchText}
                  onTextChanged={(value: string) => setSearchText(value)}
                  placeHolderText="Search prompts"
                /> */}
                <TextField
                  className={`${classes.backGroundColor}`}
                  fullWidth
                  variant="outlined"
                  type="search"
                  placeholder="Search prompts"
                  onChange={handleSearchChange}
                  value={searchText}
                  InputProps={{ endAdornment: !searchText && <SearchIcon /> }}
                />
              </Grid>
              {type === 'myPrompts' && (
                <Grid item xs={12} sm={6} md={2} lg={2}>
                  <FormControl className={classes.statusDropdown}>
                    <Select
                      fullWidth
                      classes={{ root: classes.selectDropDown }}
                      id={`select-filter-${filterOption.status}`}
                      data-testid={`select-filter-${filterOption.status}`}
                      value={filterOption.status}
                      onChange={(val: any) => {
                        setFilterOption({
                          ...filterOption,
                          status: val.target.value,
                        });
                      }}
                      variant="outlined"
                    >
                      {status &&
                        status.map((status: string) => (
                          <MenuItem key={status} value={status}>
                            {status}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.tagsContainer}>
            <Chip
              onClick={() => handleBookmarkClicked()}
              label="Bookmarks"
              className={`${classes.bookmarkChipButton} ${
                bookmarkSelected ? classes.tagSelected : ''
              }`}
            />
            <PromptTags
              tags={formatedTags}
              onResize={(value: number) => setOverFlowTagsCount(value)}
              onTagClicked={handleTagClicked}
              classes={{ root: classes.myPromptContainer }}
            />
            {overFlowTagsCount > 0 ? (
              <Chip
                onClick={showAllTags}
                label={`${overFlowTagsCount}+`}
                className={`${classes.overflowChipButton}`}
              />
            ) : null}
            <Button
              variant="outlined"
              className={classes.viewAllButton}
              onClick={showAllTags}
            >
              View all
            </Button>
          </Grid>

          <Grid item xs={12}>
            {filteredPrompts.length === 0 ? (
              <Typography>No prompts found.</Typography>
            ) : (
              <PromptList
                type={type}
                prompts={filteredPrompts}
                onPromptClicked={handlePromptClicked}
                getUsage={getUsage}
                updateUsage={updateUsage}
                usageCountObj={usageCountObj}
                onTryoutClick={onTryoutClick}
              />
            )}
          </Grid>
        </Grid>
      )}
      {allTagsPopupProps?.open && <AllTagsPopup {...allTagsPopupProps} />}
      {promptDetailsPopup?.open && (
        <PromptDetailsPopup {...promptDetailsPopup} />
      )}
      {filteredPrompts.length > 0 && tryoutPopup.open && (
        <TryItOut {...tryoutPopup} onClose={onTryoutClose} />
      )}
    </>
  );
};

export default PromptStoreHome;
