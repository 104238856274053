import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useCustomStyles = makeStyles((theme: Theme) =>
  createStyles({
    levelAndBadgesContainer: {
      padding: '24px 24px 120px 24px',
      gridArea: 'pageContent',
    },
    CardButtonContainer: {
      display: 'flex',
      '& button': {
        padding: '0px 5px',
        minWidth: 'auto',
        color: theme.palette.primary.main,
      },
    },
    addLevelContainer: {
      justifyContent: 'right',
      display: 'flex',
      marginTop: '24px',
    },
    minAndMaxContainer: {
      justifyContent: 'space-between',
      display: 'flex',
    },
    saveCancelButtonGroup: {
      justifyContent: 'right',
      display: 'flex',
    },

    cancelButton: {
      marginRight: '16px',
    },
    levelAddEditContainer: {
      padding: '0px 15px 15px 15px',
      gridArea: 'pageContent',
      backgroundColor: theme.palette.background.paper,
      marginTop: '10px',
    },
    CardContainerFlex: {
      height: '174px',
      padding: '30px 20px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
      marginBottom: '16px',
      marginRight: '10px',
      marginLeft: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    CardContentOwnerText: {},

    CardEntityContainer: {
      padding: '23px',
      minWidth: '320px',
      minHeight: '70px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
      marginBottom: '2px',
    },
    CardTypeContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'left',
    },
    CardTypeContainerFirst: {
      display: 'flex',
      flexDirection: 'column',
      paddingRight: '16px',
    },
    CardTypeFont: {
      fontSize: '11px',
      lineHeight: '16px',
    },

    CardTypeFontBold: {
      fontSize: '14px',
      lineHeight: '18px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
    CardTypeFontPoint: {
      fontSize: '11px',
      lineHeight: '16px',

      color: '#17E88F',
    },

    CardTypeImageAndText: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    leaderBoardProgressBar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'end',
      marginTop: '8px',
    },
    CardTypeText: {
      margin: '0px 16px 0px 0px',
      textTransform: 'capitalize',
      [theme.breakpoints.down('sm')]: {
        margin: '0px 10px 0px 0px',
        '& b': {
          fontSize: '12px',
        },
      },
    },
    IconWithText: {
      // width: '30%',
      alignItems: 'center',
      display: 'flex',
    },
    IconWithTextPlayers: {
      alignItems: 'center',
      display: 'flex',
      marginTop: '16px',
    },
    ProgressBarDiv: {
      width: '90%',
    },
    ProgressBarLevel: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '11px',
    },
    CardTitleText: {
      textTransform: 'capitalize',
    },
    marginTop24: {
      marginTop: '24px',
    },
  }),
);
