import React from 'react';

const RatingReceived = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.5 12.175L18.5125 11.4L15 3.125L11.4875 11.4125L2.5 12.175L9.325 18.0875L7.275 26.875L15 22.2125L22.725 26.875L20.6875 18.0875L27.5 12.175ZM15 19.875V8.25L17.1375 13.3L22.6125 13.775L18.4625 17.375L19.7125 22.725L15 19.875Z"
        fill="#17E88F"
      />
    </svg>
  );
};

export default RatingReceived;
