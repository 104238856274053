import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MagicWandIcon = (variant?: any) => {
  if (variant === 'filled') return (<SvgIcon width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="30" height="30" rx="4" fill-opacity="0.08" />
    <path d="M21.921 10.1259L22.3294 9.24258L23.2127 8.83424C23.5377 8.68424 23.5377 8.22591 23.2127 8.07591L22.3294 7.66758L21.921 6.79258C21.771 6.46758 21.3127 6.46758 21.1627 6.79258L20.7544 7.67591L19.8794 8.08424C19.5544 8.23424 19.5544 8.69258 19.8794 8.84258L20.7627 9.25091L21.171 10.1259C21.3127 10.4509 21.7794 10.4509 21.921 10.1259Z" />
    <path d="M12.3377 10.1259L12.746 9.24258L13.6294 8.83424C13.9544 8.68424 13.9544 8.22591 13.6294 8.07591L12.746 7.67591L12.3377 6.79258C12.196 6.46758 11.7294 6.46758 11.5877 6.79258L11.1794 7.67591L10.296 8.08424C9.97103 8.23424 9.97103 8.69258 10.296 8.84258L11.1794 9.25091L11.5877 10.1259C11.7294 10.4509 12.196 10.4509 12.3377 10.1259Z" />
    <path d="M21.171 16.3759L20.7627 17.2592L19.8794 17.6676C19.5544 17.8176 19.5544 18.2759 19.8794 18.4259L20.7627 18.8342L21.171 19.7176C21.321 20.0426 21.7794 20.0426 21.9294 19.7176L22.3377 18.8342L23.2127 18.4176C23.5377 18.2676 23.5377 17.8092 23.2127 17.6592L22.3294 17.2509L21.921 16.3676C21.7794 16.0509 21.3127 16.0509 21.171 16.3759Z" />
    <path d="M19.6377 12.7259L17.2794 10.3676C16.9544 10.0426 16.4294 10.0426 16.1044 10.3676L6.7877 19.6759C6.4627 20.0009 6.4627 20.5259 6.7877 20.8509L9.14603 23.2092C9.47103 23.5342 9.99603 23.5342 10.321 23.2092L19.6294 13.9009C19.9627 13.5842 19.9627 13.0509 19.6377 12.7259ZM16.721 14.4676L15.546 13.2926L16.696 12.1426L17.871 13.3176L16.721 14.4676Z" />
  </SvgIcon>)
  return (<SvgIcon width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.7515 8.75L23.8481 6.34667L26.2515 5.25L23.8481 4.15333L22.7515 1.75L21.6548 4.15333L19.2515 5.25L21.6548 6.34667L22.7515 8.75Z" />
    <path d="M9.33479 8.75L10.4315 6.34667L12.8348 5.25L10.4315 4.15333L9.33479 1.75L8.23812 4.15333L5.83479 5.25L8.23812 6.34667L9.33479 8.75Z" />
    <path d="M22.7515 15.1667L21.6548 17.57L19.2515 18.6667L21.6548 19.7633L22.7515 22.1667L23.8481 19.7633L26.2515 18.6667L23.8481 17.57L22.7515 15.1667Z" />
    <path d="M20.0798 11.2233L16.7781 7.92167C16.5448 7.7 16.2531 7.58333 15.9498 7.58333C15.6465 7.58333 15.3548 7.7 15.1215 7.92167L2.08979 20.9533C1.63479 21.4083 1.63479 22.1433 2.08979 22.5983L5.39145 25.9C5.62479 26.1333 5.91645 26.25 6.21979 26.25C6.52312 26.25 6.81479 26.1333 7.04812 25.9117L20.0798 12.88C20.5348 12.425 20.5348 11.6783 20.0798 11.2233ZM15.9498 10.4067L17.5948 12.0517L16.2298 13.4167L14.5848 11.7717L15.9498 10.4067ZM6.21979 23.4383L4.57479 21.7933L12.9398 13.4167L14.5848 15.0617L6.21979 23.4383Z" />
  </SvgIcon>)
}


export default MagicWandIcon;