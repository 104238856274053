import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const BotIcon = ({ fill, style = {} }: { fill?: string; style?: any }) => (
  <SvgIcon width="22" height="20" viewBox="0 0 22 20" fill="none" style={style}>
    <path
      d="M19 7.5V5.5C19 4.4 18.1 3.5 17 3.5H14C14 1.84 12.66 0.5 11 0.5C9.34 0.5 8 1.84 8 3.5H5C3.9 3.5 3 4.4 3 5.5V7.5C1.34 7.5 0 8.84 0 10.5C0 12.16 1.34 13.5 3 13.5V17.5C3 18.6 3.9 19.5 5 19.5H17C18.1 19.5 19 18.6 19 17.5V13.5C20.66 13.5 22 12.16 22 10.5C22 8.84 20.66 7.5 19 7.5ZM17 17.5H5V5.5H17V17.5ZM8 11.5C7.17 11.5 6.5 10.83 6.5 10C6.5 9.17 7.17 8.5 8 8.5C8.83 8.5 9.5 9.17 9.5 10C9.5 10.83 8.83 11.5 8 11.5ZM15.5 10C15.5 10.83 14.83 11.5 14 11.5C13.17 11.5 12.5 10.83 12.5 10C12.5 9.17 13.17 8.5 14 8.5C14.83 8.5 15.5 9.17 15.5 10ZM7 13.5H15V15.5H7V13.5Z"
      fill={fill}
    />
  </SvgIcon>
);

export default BotIcon;
