import React, { useEffect, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import useEffectOnce from 'react-use/lib/useEffectOnce';
import { GetEntitiesRequest } from '@backstage/catalog-client';
import { Entity, makeValidator } from '@backstage/catalog-model';
import { useApi, identityApiRef } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { FormControl, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { customTypes } from '../../extensions';
import {
  InformationExchangeApi,
  informationExchangeApiRef,
} from '../../../../apis/informationExchange';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';

/**
 * The input props that can be specified under `ui:options` for the
 * `EntityTagsPicker` field extension.
 *
 * @public
 */
export interface EntityTagsPickerUiOptions {
  kinds?: string[];
}

/**
 * The underlying component that is rendered in the form for the `EntityTagsPicker`
 * field extension.
 *
 * @public
 */
export const EntityTagsPicker = (
  props: customTypes.FieldExtensionComponentProps<
    string[],
    EntityTagsPickerUiOptions
  >,
) => {
  const { formData, onChange, uiSchema, required } = props;
  const catalogApi = useApi(catalogApiRef);
  const identityApi = useApi(identityApiRef);
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState(false);
  const [tagsData, setTagsData] = useState<any>([]);
  const [checkDisable, setCheckDisable] = useState<any>(false);
  const [open, setOpen] = useState(false);
  const tagValidator = makeValidator().isValidTag;
  const kinds = uiSchema['ui:options']?.kinds;

  const informationExchange: InformationExchangeApi = useApi(
    informationExchangeApiRef,
  );

  const notificationApi: NotificationApi = useApi(notificationApiRef);

  useEffect(() => {
    const params = {
      page: '',
      pagesize: '',
      sortby: '',
      search: '',
    };

    informationExchange
      .getAllTags(params)
      .then((data: any) => {
        const tagsList = data && data?.tags.map(val => val.tag);
        setTagsData(tagsList);
      })
      .catch(() => {
        notificationApi.sendNotification({
          message: `Failed to load Tags`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  }, []);

  const setTags = (_: React.ChangeEvent<{}>, values: string[] | null) => {
    // Reset error state in case all tags were removed

    let hasError = false;
    let addDuplicate = false;
    const currentTags = formData || [];
    if (values?.length === 6) {
      setCheckDisable(true);
      return;
    } else {
      setCheckDisable(false);
    }

    // If adding a new tag
    if (values?.length && currentTags.length < values.length) {
      const newTag = (values[values.length - 1] = values[values.length - 1]
        .toLocaleLowerCase('en-US')
        .trim());
      hasError = !tagValidator(newTag);
      addDuplicate = currentTags.indexOf(newTag) !== -1;
    }

    setInputError(hasError);
    setInputValue(!hasError ? '' : inputValue);
    if (!hasError && !addDuplicate) {
      onChange(values || []);
    }
  };

  const onFocus = () => {
    setOpen(true);
  };

  // Initialize field to always return an array
  useEffectOnce(() => onChange(formData || []));

  return (
    <FormControl margin="normal">
      <Autocomplete
        id="poc-tags"
        multiple
        freeSolo
        filterSelectedOptions
        getOptionDisabled={option => checkDisable}
        onChange={setTags}
        onFocus={onFocus}
        open={open}
        onClose={() => setOpen(false)}
        value={formData || []}
        inputValue={inputValue}
        options={tagsData || []}
        ChipProps={{ size: 'small' }}
        renderInput={params => (
          <TextField
            {...params}
            label="Tags"
            onChange={e => {
              setInputValue(e.target.value);
              setOpen(true);
            }}
            onFocus={onFocus}
            onClick={onFocus}
            error={inputError}
            required={required}
            helperText="Add any 5 relevant tags, hit 'Enter' to add new tags. Valid format: [a-z0-9+#] separated by [-], at most 63 characters"
          />
        )}
      />
    </FormControl>
  );
};
