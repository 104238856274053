import React from 'react';
import { makeStyles } from '@material-ui/core';

export type Props = {
  x: number;
  y: number;
  spaces?: number;
  labels?: Array<string>;
};

const onespace = '\u00a0';

const useStyles = makeStyles(theme => ({
  text: {
    pointerEvents: 'none',
    userSelect: 'none',
    fontSize: '10px',
    fill: theme.palette.text.secondary,
  },
}));

const RadarFooter = (props: Props): JSX.Element => {
  const { x, y, spaces = 5, labels } = props;
  const classes = useStyles(props);
  const space = onespace.repeat(spaces);

  return (
    <text
      data-testid="radar-footer"
      transform={`translate(${x}, ${y})`}
      className={classes.text}
    >
      {`▲ moved up${space}▼ moved down`}
      {labels && space}
      {labels?.join(space)}
    </text>
  );
};

export default RadarFooter;