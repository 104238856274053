import React from 'react';
import { Link } from '@backstage/core-components';
import { Chip, makeStyles } from '@material-ui/core';
import { dateFormat } from '../../../utils/devXTableColumns';
const useStyles = makeStyles(_theme => ({
  capabilityChip: {
    marginBottom: '0px',
    margin: '2px',
    height: '20px',
    cursor: 'pointer',
  },
  capabilityName: {
    margin: '10px',
  },
  capabilityDescription: {
    margin: '10px',
    wordBreak: 'break-word',
    ['@media (max-width:920px) and (min-width: 280px)']: {
      wordBreak: 'break-word',
    },
  },
  tools: {
    wordBreak: 'break-word',
    margin: '10px',
  },
}));
const CapabilityDetails = ({ entity }: any) => {
  const relations = entity?.relations;
  const classes = useStyles();
  const parentRelations: any[] = [];
  const childRelations: any[] = [];
  const memberRelations: any[] = [];
  if (relations && relations?.length > 0) {
    relations.forEach((rel: any) => {
      if (rel && rel.type === 'childOf') {
        parentRelations.push(rel?.target);
      } else if (rel && rel.type === 'parentOf') {
        childRelations.push(rel?.target);
      } else if (rel && rel.type === 'hasMember') {
        memberRelations.push(rel?.target);
      }
    });
  }

  const schemaProps = entity?.metadata?.capSchema?.properties;
  const formDataProps = entity?.metadata?.capFormData;
  const uiSchema = entity?.metadata?.capUiSchema;

  const renderRelatedEntity = (rel: any) => {
    return (
      <div>
        <Link
          to={`/taxonomy/view/${rel?.kind || 'Group'}/${rel?.namespace}/${
            rel?.name
          }`}
        >
          {rel?.name || ''}
        </Link>
      </div>
    );
  };

  const renderRelatedAssetEntity = (rel: any) => {
    return (
      <Link
        to={`/catalog/${rel?.namespace || 'default'}/${rel?.kind || 'Group'}/${
          rel?.name
        }`}
      >
        <Chip
          key={rel?.name}
          label={rel?.name}
          size="small"
          color="primary"
          variant="outlined"
          className={classes.capabilityChip}
        />
      </Link>
    );
  };

  const getLinks = (links: any) => {
    if (!links) {
      return <></>;
    }
    let parts: string[] = [];
    let parts1 = `${links}`.split(';');
    let parts2 = `${links}`.split(',');
    if (parts1 && parts2) {
      if (parts1.length > parts2.length) {
        parts = parts1;
      } else {
        parts = parts2;
      }
    }

    if (parts && parts.length > 0) {
      return (
        <>
          {parts?.map((l: any) => {
            let url;
            try {
              url = new URL(l);
            } catch (err) {
              return <></>;
            }
            if (url) {
              return (
                <div>
                  <a style={{ color: '#2E5881' }} href={l} target="_blank">
                    {l}
                  </a>
                </div>
              );
            }
            return <></>;
          })}
        </>
      );
    }
    return <></>;
  };

  return (
    <div>
      <section>
        <h2>
          <div className={classes.capabilityName}>{entity?.metadata?.name}</div>
        </h2>
        <div className={classes.capabilityName}>
          {entity?.metadata?.creationTimestamp
            ? dateFormat(entity?.metadata?.creationTimestamp)
            : ''}
        </div>
        <div className={classes.capabilityDescription}>
          {entity?.metadata?.description}
        </div>
      </section>
      <section>
        {schemaProps &&
          Object.keys(schemaProps)
            ?.filter(
              sc => !['name', 'description', 'parent', 'assets'].includes(sc),
            )
            ?.map(key => (
              <div key={key} style={{ margin: '10px' }}>
                {formDataProps[key] && (
                  <>
                    <section>
                      <b>{schemaProps[key]?.title}</b>
                    </section>
                    <section className={classes.tools}>
                      {['userSuggest'].includes(
                        uiSchema[key]?.[`ui:widget`],
                      ) && (
                        <>
                          {formDataProps[key] &&
                            formDataProps[key]?.length > 0 &&
                            JSON.parse(formDataProps[key])?.map((data: any) => (
                              <div>- {data?.value}</div>
                            ))}
                        </>
                      )}
                      {['entitySuggest'].includes(
                        uiSchema[key]?.[`ui:widget`],
                      ) && (
                        <>
                          {formDataProps[key] &&
                            JSON.parse(formDataProps[key]) && (
                              <>
                                <div>
                                  {renderRelatedEntity({
                                    kind: 'group',
                                    namespace: 'default',
                                    name: JSON.parse(formDataProps[key])?.value,
                                  })}
                                </div>
                              </>
                            )}
                        </>
                      )}
                      {!['userSuggest', 'entitySuggest'].includes(
                        uiSchema[key]?.[`ui:widget`],
                      ) && (
                        <>
                          {'links' == key ? (
                            <div>{getLinks(formDataProps[key])}</div>
                          ) : (
                            <div>{formDataProps[key]}</div>
                          )}
                        </>
                      )}
                    </section>
                  </>
                )}
              </div>
            ))}
      </section>
      {parentRelations && parentRelations?.length > 0 && (
        <section style={{ margin: '10px' }}>
          <b>
            <div>Parent capabilities</div>
          </b>
          {parentRelations?.map(rel => (
            <div key={rel?.name}>{renderRelatedEntity(rel)}</div>
          ))}
        </section>
      )}
      {childRelations && childRelations?.length > 0 && (
        <section style={{ margin: '10px' }}>
          <b>
            <div>Child capabilities</div>
          </b>
          {childRelations?.map(rel => (
            <div key={rel?.name}>{renderRelatedEntity(rel)}</div>
          ))}
        </section>
      )}
      {memberRelations && memberRelations?.length > 0 && (
        <section style={{ margin: '10px' }}>
          <b>
            <div>Assets</div>
          </b>
          <div>
            {memberRelations?.map(rel => (
              <span key={rel?.name}>{renderRelatedAssetEntity(rel)}</span>
            ))}
          </div>
        </section>
      )}
    </div>
  );
};
export default CapabilityDetails;
