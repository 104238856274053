import React, { useEffect, useState } from 'react';
import { useStyles } from '../../../../../styles/popupStyles';
import { Typography } from '@material-ui/core';
import { IConfigData } from '../../../../../interfaces';

interface TestPlanItem {
  label: string;
  value: string;
}

const TestPlanSection = (props: IConfigData) => {
  const classes = useStyles();
  const [detailsArr, setDetailsArr] = useState<TestPlanItem[]>([]);

  useEffect(() => {
    loadDetailsArray();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const loadDetailsArray = () => {
    setDetailsArr([
      {
        label: 'Repository Branch URL:',
        value: props.config.repoBranchCloneUrl,
      },
      {
        label: 'Repository Branch Name:',
        value: props.config.repoBranch,
      },
      {
        label: 'Test plan file path (.jmx):',
        value: props.config.testPlan,
      },
      {
        label: 'User properties file path:',
        value: props.config.userPropertyFile,
      },
    ]);
  };

  return (
    <div className={classes.sectionContainer}>
      <Typography className={classes.sectionHeading}>Test Plan</Typography>
      <div>
        {detailsArr.map(
          (item, index) =>
            item.value !== undefined &&
            item.value !== '' && (
              <div className={classes.labelAndValue}>
                <div className={classes.label} key={index}>
                  {item.label}
                </div>
                <div key={index}>{item.value}</div>
              </div>
            ),
        )}
      </div>
    </div>
  );
};

export default TestPlanSection;
