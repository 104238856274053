import {
  ApiRef,
  ConfigApi,
  createApiRef,
  DiscoveryApi,
} from '@backstage/core-plugin-api';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';
import { AxiosInstance } from 'axios';
import { TargetData } from '../components/catalog/details/code-quality/snyk/types';

const DEFAULT_PROXY_PATH_BASE = '';

type Options = {
  discoveryApi: DiscoveryApi;
  /**
   * Path to use for requests via the proxy, defaults to ''
   */
  proxyPathBase?: string;
  configApi: ConfigApi;
  axiosInstanceProviderApi: AxiosInstanceProviderApi;
};
//@ts-ignore
export const snykApiRef: ApiRef<SnykApi> = createApiRef<SnykApi>({
  id: 'devx.snyk.service',
});

export interface SnykApi {
  ListAllAggregatedIssues(orgName: string, projectId: string): Promise<any>;
  ProjectDetails(orgName: string, projectId: string): Promise<any>;
  ProjectsList(orgName: string, repoName: string): Promise<any>;
  GetDependencyGraph(orgName: string, projectId: string): Promise<any>;
  GetSnykAppHost(): string;
  GetOrgName(orgId: string): Promise<any>;
  getAzureRepositoryBranches: (
    orgName: string,
    projectName: string,
    repoName: string,
    assetstoreid:any,
  ) => Promise<any>;
  getGithubRepositoryBranches: (
    orgName: string,

    repoName: string,
  ) => Promise<any>;
}

export class SnykApiClient implements SnykApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly proxyPathBase: string;
  private readonly configApi: ConfigApi;
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  private headers = {
    'Content-Type': 'application/json',
    'User-Agent': 'tech-services/backstage-plugin/1.0',
  };

  constructor(options: Options) {
    this.discoveryApi = options.discoveryApi;
    this.configApi = options.configApi;
    this.proxyPathBase = options.proxyPathBase ?? DEFAULT_PROXY_PATH_BASE;
    this.axiosInstancePromise = options?.axiosInstanceProviderApi.getInstance();
  }

  private async getApiUrl() {
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    return `${baseUrl}${this.proxyPathBase}/snyk`;
  }

  GetSnykAppHost() {
    return this.configApi.getOptionalString('snyk.AppHost') ?? 'app.snyk.io';
  }

  async ListAllAggregatedIssues(orgId: string, projectId: string) {
    const backendBaseUrl = await this.getApiUrl();
    let v3Headers = this.headers;
    v3Headers['Content-Type'] = 'application/vnd.api+json';
    const apiUrl = `${backendBaseUrl}/rest/orgs/${orgId}/issues?version=2024-06-10&scan_item.id=${projectId}&scan_item.type=project&limit=100`;

    const apiUrl2 = `${backendBaseUrl}/rest/orgs/${orgId}/packages/pkg%3Amaven%2Fcom.fasterxml.woodstox%2Fwoodstox-core%405.0.0/issues`;

    const response2 = await fetch(`${apiUrl2}`, {
      method: 'GET',
      headers: v3Headers,
    });


    const response = await fetch(`${apiUrl}`, {
      method: 'GET',
      headers: v3Headers,
    });

    if (response.status >= 400 && response.status < 600) {
      throw new Error(
        `Error ${response.status} - Failed fetching Vuln Issues snyk data`,
      );
    }
    const jsonResponse = await response.json();
    if (
      jsonResponse !== undefined &&
      jsonResponse.data !== undefined &&
      Array.isArray(jsonResponse.data)
    ) {
      const data = jsonResponse.data.filter(
        v => v.attributes !== undefined && v.attributes.status === 'open',
      );
      return { ...jsonResponse, data };
    }
    return jsonResponse;
  }

  async ProjectDetails(orgName: string, projectId: string) {
    const backendBaseUrl = await this.getApiUrl();
    const apiUrl = `${backendBaseUrl}/v1/org/${orgName}/project/${projectId}`;
    const response = await fetch(`${apiUrl}`, {
      headers: this.headers,
    });

    if (response.status >= 400 && response.status < 600) {
      throw new Error(
        `Error ${response.status} - Failed fetching ProjectDetails snyk data`,
      );
    }
    const jsonResponse = await response.json();
    return jsonResponse;
  }

  async ProjectsList(orgId: string, repoName: string) {
    if (repoName == '') {
      throw new Error(
        `Error - Unable to find repo name. Please add github.com/project-slug or snyk.io/target-id annotation`,
      );
    }
    const backendBaseUrl = await this.getApiUrl();
    let v3Headers = this.headers;
    v3Headers['Content-Type'] = 'application/vnd.api+json';
    let targetId;
    if (
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(
        repoName,
      )
    ) {
      targetId = repoName;
    } else {
      const targetsAPIUrl = `${backendBaseUrl}/rest/orgs/${orgId}/targets?displayName=${encodeURIComponent(
        repoName,
      )}&version=2023-06-19~beta`;
      const targetResponse = await fetch(`${targetsAPIUrl}`, {
        method: 'GET',
        headers: v3Headers,
      });
      if (targetResponse.status >= 400 && targetResponse.status < 600) {
        throw new Error(
          `Error ${targetResponse.status} - Failed fetching Targets list snyk data`,
        );
      }
      const targetsList = await targetResponse.json();
      const targetsListData = targetsList.data as TargetData[];
      console.log("targetsListData", targetsListData)
      targetId = targetsListData.find(target => {
        return target.attributes.displayName == repoName;
      })?.id;
      if (!targetId) {
        throw new Error(
          `Error - Failed finding Target snyk data for repo ${repoName}`,
        );
      }
    }

    const projectsForTargetUrl = `${backendBaseUrl}/rest/orgs/${orgId}/projects?target_id=${targetId}&version=2023-06-19~beta`;
    const response = await fetch(`${projectsForTargetUrl}`, {
      method: 'GET',
      headers: v3Headers,
    });

    if (response.status >= 400 && response.status < 600) {
      throw new Error(
        `Error ${response.status} - Failed fetching Projects list snyk data`,
      );
    }
    const jsonResponse = await response.json();
    return jsonResponse.data;
  }

  async GetDependencyGraph(orgName: string, projectId: string) {
    const backendBaseUrl = await this.getApiUrl();
    const apiUrl = `${backendBaseUrl}/v1/org/${orgName}/project/${projectId}/dep-graph`;
    const response = await fetch(`${apiUrl}`, {
      headers: this.headers,
    });

    if (response.status >= 400 && response.status < 600) {
      throw new Error(
        `Error ${response.status} - Failed fetching DepGraph snyk data`,
      );
    }
    const jsonResponse = await response.json();
    return jsonResponse;
  }

  async GetOrgName(orgId: string) {
    const backendBaseUrl = await this.getApiUrl();
    const apiUrl = `${backendBaseUrl}/rest/orgs/${orgId}?version=2023-06-19`;
    const response = await fetch(`${apiUrl}`, {
      headers: this.headers,
    });

    if (response.status >= 400 && response.status < 600) {
      throw new Error(
        `Error ${response.status} - Failed fetching organization info data`,
      );
    }
    const jsonResponse = await response.json();
    return jsonResponse;
  }

  async getAzureRepositoryBranches(
    orgName: string,
    projectName: string,
    repoName: string,
    assetstoreid:string
  ): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`service/discovery/repository/branches/ado/${assetstoreid}`)
      .then(res => res?.data)
      .catch(err => {
        throw new Error(
          `Error occurred while getting repo info ${err.message}`,
        );
      });
  }

  async getGithubRepositoryBranches(
    orgName: string,
    repoName: string,
  ): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`service/discovery/repository/branches/github/${repoName}`)
      .then(res => res?.data)
      .catch(err => {
        throw new Error(
          `Error occurred while getting repo info ${err.message}`,
        );
      });
  }
}
