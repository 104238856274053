import { createApiRef } from '@backstage/core-plugin-api';
import { AxiosInstance } from 'axios';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';

export interface IToolBoxItemRequest {
  title: string;
  description: string;
  link: string | undefined;
  category?: string | undefined;
  pnid?: number | string | undefined | null;
  imageFileName?: string | undefined;
  imageBase64Format?: string | undefined;
  imageMetaForBase64?: string | undefined;
  links?:any | undefined,
  linktitle?:string | undefined,
  detaildescription?:string | undefined,
  pagetitle?:string | undefined,
  pageinfo?:any,
  bgimageBase64Format?:any,
  bgimageFileName?:any,
  bgimageMetaForBase64?:any,
}

export interface ISaveToolboxItemResponse {
  data:
    | {
        title: string;
        description: string;
        link: string | undefined;
        imageurl: string | undefined;
        imagekey: string | undefined;
        pnid: number;
        isexternal: boolean;
        isactive: boolean;
      }
    | undefined;
  status: string | undefined;
}

export interface IToolboxItem {
  nid: number;
  title: string;
  description: string;
  link: string | undefined;
  imageurl?: string | undefined;
  imagekey?: string | undefined;
  pnid?: number;
  isexternal: boolean;
  isactive: boolean;
  imageMetaForBase64?: string | undefined;
  category?: string | undefined;
  subcategory?: string | undefined;
  links?:any,
  linktitle?:string,
  detaildescription?:string | undefined,
  pagetitle?:string | undefined,
  pageinfo?:any,
  bgimageBase64Format?:any,
  bgimageFileName?:any,
  bgimageMetaForBase64?:any,
}

export interface IToolboxItemNode {
  node: IToolboxItem;
  children?: IToolboxItemNode[] | undefined;
}

export interface IToolboxAuditPayload {
  nodeId: string;
  title: string;
  link: string;
  userEmail: string;
}

export interface ToolboxApi {
  saveToolDetails: (
    data: IToolBoxItemRequest,
  ) => Promise<ISaveToolboxItemResponse>;
  updateToolDetails: (
    nid: string,
    data: IToolBoxItemRequest,
  ) => Promise<ISaveToolboxItemResponse>;
  deleteToolById: (
    nid: string
  ) => Promise<any>;
  getAllTools: () => Promise<IToolboxItemNode[] | undefined>;
  getToolById: (nodeId: string) => Promise<IToolboxItemNode | undefined>;
  getImageData: (key: string) => Promise<string | undefined>;
  getTopLinks: () => Promise<IToolboxItem[] | undefined>;
  searchTools: (searchString: string) => Promise<IToolboxItem[] | undefined>;
  sendAuditData: (auditPayload: IToolboxAuditPayload) => Promise<any>;
  getAllToolLinks: () => Promise<IToolboxItem[] | undefined>;
}

export const toolboxApiRef = createApiRef<ToolboxApi>({
  id: 'cbre.devx.api.toolbox',
});

export class ToolboxApiImpl implements ToolboxApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  async saveToolDetails(
    data: IToolBoxItemRequest,
  ): Promise<ISaveToolboxItemResponse> {
    const instance = await this.axiosInstancePromise;
    if (!data) {
      return Promise.reject('Invalid Payload');
    }
    const formData = new FormData();
    formData.append('payload', JSON.stringify(data));

    return instance
      .post('service/toolbox', formData, {
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(res => res.data);
  }

  async updateToolDetails(
    nid: string,
    data: IToolBoxItemRequest,
  ): Promise<ISaveToolboxItemResponse> {
    const instance = await this.axiosInstancePromise;
    if (!data) {
      return Promise.reject('Invalid Payload');
    }
    const formData = new FormData();
    formData.append('payload', JSON.stringify(data));

    return instance
      .put(`service/toolbox/${nid}`, formData, {
        method: 'put',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(res => res.data);
  }

  async deleteToolById(
    nid: string,
  ): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance
      .delete(`service/toolbox/${nid}`)
      .then(res => res.data);
  }

  async getAllTools(): Promise<IToolboxItemNode[] | undefined> {
    const instance = await this.axiosInstancePromise;
    return instance.get('service/toolbox').then(res => res.data);
  }

  async getToolById(nodeId: string): Promise<IToolboxItemNode | undefined> {
    const instance = await this.axiosInstancePromise;
    return instance.get(`service/toolbox/${nodeId}`).then(res => res.data);
  }

  async getImageData(imageKey: string): Promise<string | undefined> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`service/toolbox/image?key=${imageKey}`)
      .then(res => res.data);
  }

  async getTopLinks(): Promise<IToolboxItem[] | undefined> {
    const instance = await this.axiosInstancePromise;
    return instance.get('service/toolbox/toplinks').then(res => res.data);
  }

  async getAllToolLinks(): Promise<IToolboxItem[] | undefined> {
    const instance = await this.axiosInstancePromise;
    return instance.get('service/toolbox/alltools').then(res => res.data);
  }

  async searchTools(searchString: string): Promise<IToolboxItem[] | undefined> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`service/toolbox/search?query=${searchString}`)
      .then(res => res.data);
  }

  // No return needed, trigger and let it happen in backgroud
 async sendAuditData(auditPayload: IToolboxAuditPayload): Promise<any> {
    const instance = await this.axiosInstancePromise;
    if (!auditPayload) {
      return;
    }
    const data = {
      entityName: auditPayload.nodeId,
      username:
      auditPayload?.userEmail ?? '',
      actionType: 'toolboxlinkclick',
      attributes: {
        loggedInUser: auditPayload?.userEmail ?? '',
        title: auditPayload?.title ?? '',
        link : auditPayload?.link ?? ''
      },
    };

    // navigator.sendBeacon() best fits this requirement, let us do a POC on it

    return instance
      .post('service/discovery/audit', data)
      .catch(() => {
        // ingore the error
        return undefined;
      });
  }
}
