/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, createContext, useMemo, useEffect } from 'react';
import { VideoBytesApi, VideoBytesApiRef } from '../../../apis/videoBytes';
import { useApi } from '@backstage/core-plugin-api';

const initialState = {
  bytesConfig: {},
  setBytesConfig: () => {},
};
export const VideoBytesContext = createContext<any>(initialState);

export const VideoBytesProvider = (props: any) => {
  const [bytesConfig, setBytesConfig] = useState(null);
  const videoBytesApi: VideoBytesApi = useApi(VideoBytesApiRef);

  const getVideoBytesConfig = useMemo(
    () => async () => {
      videoBytesApi
        .getVideoBytesConfig()
        .then(responseData => {
          setBytesConfig(responseData);
        })
        .catch(err => {});
    },
    [],
  );

  useEffect(() => {
    getVideoBytesConfig();
  }, []);

  return (
    <VideoBytesContext.Provider
      value={{
        bytesConfig,
        setBytesConfig,
      }}
    >
      {props.children}
    </VideoBytesContext.Provider>
  );
};
