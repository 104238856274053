import React, {
  useState,
  useCallback,
  useContext,
  createContext,
  PropsWithChildren,
} from 'react';

type SecretsContextContents = {
  secrets: Record<string, string>;
  setSecrets: React.Dispatch<React.SetStateAction<Record<string, string>>>;
};

/**
 * The actual context object.
 */
export const SecretsContext = createContext<SecretsContextContents | undefined>(
  undefined,
);

/**
 * The Context Provider that holds the state for the secrets.
 *
 * @public
 */
export const SecretsContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [secrets, setSecrets] = useState<Record<string, string>>({});

  return (
    <SecretsContext.Provider value={{ secrets, setSecrets }}>
      {children}
    </SecretsContext.Provider>
  );
};

/**
 * The return type from the useTemplateSecrets hook.
 * @public
 */
export interface ScaffolderUseTemplateSecrets {
  setSecrets: (input: Record<string, string>) => void;
}

/**
 * Hook to access the secrets context.
 * @public
 */
export const useTemplateSecrets = (): ScaffolderUseTemplateSecrets => {
  const value = useContext(SecretsContext);
  if (!value) {
    throw new Error(
      'useTemplateSecrets must be used within a SecretsContextProvider',
    );
  }

  const { setSecrets: updateSecrets } = value;

  const setSecrets = useCallback(
    (input: Record<string, string>) => {
      updateSecrets(currentSecrets => ({ ...currentSecrets, ...input }));
    },
    [updateSecrets],
  );

  return { setSecrets };
};
