import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Routes, Route, Outlet } from 'react-router-dom';
import { useApi } from '@backstage/core-plugin-api';
import {
  Grid,
  Button,
  Theme,
  Typography,
  Paper,
  IconButton,
  useTheme,
  TablePagination,
  Box,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CreateIcon from '@material-ui/icons/Create';
import SearchIcon from '@material-ui/icons/Search';
import { HelpComponent } from '../../utils/helpComponent';
import { ReleaseNoteApi, releaseNoteApiRef } from '../../../apis/releaseNotes';
import BackButton from '../../utils/backButton';
import HELP_URL from '../../utils/helpLinkConstant';
import { formatDateMMDDYYYY } from '../../utils/dateUtils';
import * as Constants from '../../utils/constant';
import AddEditPage from './addEditPage';
import { useStyles } from './AdminReleaseNotesStyles';

export const IndexPage = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const releaseNoteApi: ReleaseNoteApi = useApi(releaseNoteApiRef);
  const [notes, setNotes] = useState<any>([]);
  const [isLoading, setLoading] = useState(true);
  const theme = useTheme<Theme>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayList, setDisplayList] = useState<any>([]);
  const contentPerPage = 20;
  const [filterOptions, setFilterOptions] = useState<any>([]);
  const [filterVersionOptions, setFilterVersionOptions] = useState<any>([]);
  const [filterFeature, setFilterFeature] = useState<any>({
    label: 'All',
    value: 'all',
  });
  const [filterVName, setFilterVName] = useState<any>({
    label: 'All',
    value: 'all',
  });
  const inputRef = useRef();
  const [releaseDate, setReleaseData] = useState<any>('');
  const [currentDate] = useState<any>(
    String(new Date().getFullYear()) +
    '-' +
    String(('0' + (new Date().getMonth() + 1)).slice(-2)),
  );

  const fetchNotes = async () => {
    const n = await releaseNoteApi.getAllNotes();
    if (n && n.length > 0) {
      setNotes(n);
      setDisplayList(n);
    }
    setLoading(false);
  };

  const redirect = (id?: string) => {
    if (id) {
      navigate('/admin/release-notes/edit/' + id);
    } else {
      navigate('/admin/release-notes/add');
    }
  };

  // for Pagination

  const onPageChange = (
    event: React.SyntheticEvent | null,
    pageNumber: number,
  ): void => {
    if (event) {
      event.preventDefault();
    }
    setCurrentIndex(pageNumber);
  };

  const [showedContent, setShowedContent] = useState<any>([]);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const begin = currentIndex * contentPerPage;
    const end = begin + contentPerPage;
    setShowedContent([...displayList.slice(begin, end)]);
    setTotalCount(displayList.length);
  }, [currentIndex, contentPerPage, displayList]);

  // searh

  const [searchText, setSearchText] = useState('');

  const onChangeSearchText = async (e: any) => {
    setSearchText(e?.target?.value);
  };

  useEffect(() => {
    setDisplayList(
      notes.filter((obj: any) =>
        Object.values(obj).some((objValue: any) =>
          objValue
            ?.toString()
            ?.toLowerCase()
            ?.includes(searchText.toLowerCase()),
        ),
      ),
    );
  }, [searchText]);

  // Filters
  useEffect(() => {
    setDisplayList(notes);
    fetchNotes();
  }, []);

  useEffect(() => {
    const allNotes = notes;
    let filtered = notes;

    // checks to see if filter by feature has any selection made
    if (filterFeature?.value !== undefined && filterFeature?.value !== 'all') {
      filtered = filtered.filter(
        (n: any) => n.tags && n.tags?.includes(filterFeature?.value),
      );
    }
    // checks to see if filter by version has any selection made
    if (filterVName?.value !== undefined && filterVName?.value !== 'all') {
      filtered = filtered.filter(
        (n: any) =>
          n.vname &&
          getVersionName(n.vname, filterVName?.value) ===
          String(filterVName?.value),
      );
    }
    // checks to see if filter by Month has any selection made
    if (releaseDate !== '' && releaseDate !== 'all') {
      filtered = filtered.filter(
        (n: any) =>
          n.releasedate &&
          getDateFormatted(n.releasedate, releaseDate) === String(releaseDate),
      );
    }

    setDisplayList(filtered);

    const m: any[] = [
      {
        label: 'All',
        value: 'all',
      },
    ];
    filtered.forEach((note: any) => {
      if (
        note.displaystate === Constants.TRUE &&
        note.tags &&
        note.tags.length > 0
      ) {
        note.tags.forEach((t: string) => {
          if (!m.find(o => o.label === t)) {
            m.push({
              label: t,
              value: t,
            });
          }
        });
      }
    });
    setFilterOptions(m);
    const versionOptions = filtered.map((note: { vname: any }) => ({
      label: note.vname,
      value: note.vname,
    }));
    setFilterVersionOptions(versionOptions);
  }, [filterVName, filterFeature, releaseDate, notes]);

  useEffect(() => {
    getVersionNotes();
  }, [filterVName, filterFeature]);

  function getVersionName(vname: string, filterValue: any): string {
    return String(vname);
  }

  const getVersionNotes = async () => {
    let n = [...notes];

    if (filterFeature?.value !== '' && filterFeature?.value !== 'all') {
      n = n.filter(
        (n: any) => n.tags && n.tags?.includes(filterFeature?.value),
      );
    }

    if (n && n.length > 0) {
      const filteredNotes = n.filter(
        (note: any) => note.displaystate === Constants.TRUE,
      );
      const m: any[] = [{ label: 'All', value: 'all' }];
      n.forEach(note => {
        if (note.vname && note.displaystate === Constants.TRUE) {
          m.push({
            label: note.vname,
            value: note.vname,
          });
        }
      });
      setFilterVersionOptions(m);
    }

    setLoading(false);
  };
  const handleReleaseDateChange = (value: any) => {
    if (value === null || value === '') {
      setReleaseData('');
    } else {
      setReleaseData(value);
    }
  };

  function getDateFormatted(releaseDate: any, filterValue: any): string {
    const dateList = releaseDate.split('-');
    return `${dateList[0]}-${dateList[1]}`;
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <BackButton />
        <HelpComponent helpUrl={HELP_URL.RELEASE_NOTES} />
      </Grid>
      <Grid xs={12}>
        {!isLoading && (
          <>
            <Grid
              xs={12}
              className={`${classes.flex} ${classes.adminContainer}`}
            >
              <Typography component="h6" variant="h6">
                Versions
              </Typography>

              <Button className={classes.addButton} onClick={() => redirect()}>
                Add Version
              </Button>
            </Grid>
            <Grid item xs={12} className={classes.flexSearch}>
              <Grid item xs={6} md={6}>
                <Box>
                  <TextField
                    className={classes.filterBackGround}
                    variant="outlined"
                    type="search"
                    placeholder="Search"
                    onChange={e => onChangeSearchText(e)}
                    value={searchText}
                    fullWidth
                    InputProps={{ startAdornment: <SearchIcon /> }}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid xs={12} className={`${classes.flex} ${classes.adminfilters}`}>
              <Grid item xs={3} md={3}>
                {/* Filter for feature */}
                <Box>
                  <Autocomplete
                    className={classes.filterBackGround}
                    disablePortal
                    freeSolo
                    options={filterOptions || []}
                    disabled={filterOptions?.length === 0}
                    value={filterFeature || ''}
                    onChange={(event: any, value) => {
                      event?.preventDefault();
                      setFilterFeature(value);
                    }}
                    getOptionLabel={item => (item.label ? item.label : '')}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Filter by Feature"
                        fullWidth
                        variant="outlined"
                      />
                    )}
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={3} md={3}>
                {/* filter for Version Name */}
                <Box>
                  <Autocomplete
                    className={classes.filterBackGround}
                    disablePortal
                    freeSolo
                    options={filterVersionOptions || []}
                    disabled={filterVersionOptions?.length === 0}
                    value={filterVName || ''}
                    onChange={(event: any, value) => {
                      event?.preventDefault();
                      setFilterVName(value);
                    }}
                    getOptionLabel={item => (item.label ? item.label : '')}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Filter by Version Name"
                        fullWidth
                        variant="outlined"
                      />
                    )}
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={3} md={3}>
                {/* filter for Month and Year*/}
                <Box>
                  <TextField
                    fullWidth
                    className={classes.calendarColor}
                    label="Filter by Month and Year"
                    variant="outlined"
                    ref={inputRef}
                    value={releaseDate.value}
                    onChange={(event: React.SyntheticEvent) => {
                      handleReleaseDateChange(event?.target?.value);
                    }}
                    type="month"
                    InputProps={{
                      inputProps: {
                        min: '2022-01',
                        max: currentDate,
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            {showedContent.length === 0 ? (
              <Paper className={classes.adminCards}>
                <Typography>No Release Notes Found</Typography>
              </Paper>
            ) : (
              showedContent.map((n: any) => (
                <Paper className={classes.adminCards}>
                  <Grid xs={12} className={classes.flex}>
                    <Grid xs={3}>
                      <Typography component="div" className={classes.label}>
                        Version Number
                      </Typography>
                      <Typography className={classes.labelType} component="div">
                        {n.vnum}
                      </Typography>
                    </Grid>
                    <Grid xs={3}>
                      <Typography component="div" className={classes.label}>
                        Version Name
                      </Typography>
                      <Typography component="div" className={classes.labelType}>
                        {n.vname}
                      </Typography>
                    </Grid>
                    <Grid xs={2}>
                      <Typography component="div" className={classes.label}>
                        Version Type
                      </Typography>
                      <Typography component="div" className={classes.labelType}>
                        {n.vtype}
                      </Typography>
                    </Grid>
                    <Grid xs={2}>
                      <Typography component="div" className={classes.label}>
                        Release Date
                      </Typography>
                      <Typography component="div" className={classes.labelType}>
                        {formatDateMMDDYYYY(n.releasedate)}
                      </Typography>
                    </Grid>
                    <Grid xs={2}>
                      <Typography component="div" className={classes.label}>
                        Display
                      </Typography>
                      <Typography component="div" className={classes.labelType}>
                        {n.displaystate === Constants.TRUE ? 'True' : 'False'}
                      </Typography>
                    </Grid>
                    <Grid xs={2}>
                      <Tooltip title="Create new note">
                        <IconButton
                          className={classes.iconButton}
                          onClick={() => redirect(n.id)}
                        >
                          <CreateIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Paper>
              ))
            )}
            <Grid className={classes.PagePaginationContainer}>
              <Paper>
                <TablePagination
                  classes={{
                    root: classes.tablePaginationRoot,
                    actions: classes.tablePaginationActions,
                  }}
                  SelectProps={{
                    title: 'perPageSelect',
                    native: true,
                  }}
                  component="div"
                  page={currentIndex}
                  rowsPerPage={contentPerPage}
                  count={totalCount}
                  onPageChange={onPageChange}
                  rowsPerPageOptions={[]}
                />
              </Paper>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

const ReleaseNotesAdminIndexPage = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<IndexPage />}></Route>
        <Route path="edit/:id" element={<AddEditPage />}></Route>
        <Route path="add" element={<AddEditPage />}></Route>
      </Routes>
      <Outlet />
    </div>
  );
};
export default ReleaseNotesAdminIndexPage;
