import React, { useState, useEffect, useRef } from 'react';
import BackButton from '../../../utils/backButton';
import { HelpComponent } from '../../../utils/helpComponent';
import HELP_URL from '../../../utils/helpLinkConstant';
import {
  Grid,
  IconButton,
  TablePagination,
  ListItem,
  Button as MaterialButton,
  createStyles,
  makeStyles,
  Button,
  Theme,
  Tooltip
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CodeIcon from '../../../Root/icons/codeIcon';
import ExtensionIcon from '@material-ui/icons/Extension';
import InfoIcon from '@material-ui/icons/Info';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { Paper } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import ClearButton from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { useNavigate } from 'react-router-dom';
import { useApi } from '@backstage/core-plugin-api';
import { adminApiRef } from '../../../../apis/adminApi';
import { Progress } from '@backstage/core-components';
import AssetTypeIcon from '../../../common/AssetTypeIcon';
import SalesforceIcon from '../../../Root/icons/salesforceIcon';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';
import { userProfileApiRef } from '../../../../apis/userProfileApi';

// @ts-ignore
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    assetRowMain: {
      background: theme.palette.background.paper,
      margin: '3px',
      borderRadius: '4px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    assetIconDiv: {
      display: 'flex',
      flexDirection: 'column',
      margin: '5px',
      padding: '5px',
      width: '130px',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        width: '70px',
      },
    },
    assetNameOwnerCol: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      margin: '10px',
      [theme.breakpoints.down('sm')]: {
        wordBreak: 'break-word',
      },
    },
    fontBold: {
      // @ts-expect-error
      fontWeight: '600',
    },
    assetComplexityCol: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      margin: '10px',
      alignItems: 'center',

      ['& section:first-of-type']: {
        marginRight: '20px',
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    editArea: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: '25px',
      justifyContent: 'center',

      ['& section:first-of-type']: {
        cursor: 'pointer',
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: '96%',
        border: '1px solid #003f2d',
        borderRadius: '5px',
        margin: '0px 6px 9px 6px',
      },
    },
    editAreaBtn: {
      color: theme.palette.primary.main,
      [theme.breakpoints.down('sm')]: {
        minWidth: '0',
        padding: '6px 8px',
      },
    },
    assetFilters: {
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
      },
    },
    assetComplexitySection: {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    assetForm: {
      display: 'flex',
      marginBottom: '10px',
    },
    assetSearch: {
      flex: '1',
    },
    assetRow: {
      width: '100%',
    },
    assetPagination: {
      position: 'sticky',
      bottom: '0px',
      marginTop: '50px',
      marginBottom: '85px',
    },
    editButton: {
      display: 'flex',
      alignItems: 'center',
    },
    themeColor: {
      backgroundColor: theme.palette.background.paper,
      borderLeftColor: theme.palette.primary.main,
      '& li': {
        borderLeftColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.border}`,
        '&:hover button': {
          color: theme.palette.primary,
          backgroundColor: `${theme.palette.background.tertiary}`,
        },
        '&.active button': {
          borderLeft: `2px solid ${theme.palette.primary.main}`,
          backgroundColor: `${theme.palette.background.tertiary}`,
        },
      },
    },
  }),
);

const AssetRow = ({
  item,
  onClickEdit,
}: {
  item: any;
  onClickEdit: (item: any) => void;
}) => {
  const classes = useStyles();

  const userProfileApi = useApi(userProfileApiRef);
  const usernameRef = useRef(null);

  const onMouseEnter = () => {
    let email: string | undefined = undefined;
    const ownerEmail = '' + item?.owneremail;
    email = ownerEmail;
    if (
      ownerEmail === undefined ||
      ownerEmail === 'undefined' ||
      String(ownerEmail).trim()?.length === 0
    ) {
      const owner = item?.owners?.replace('user:', '');
      email = `${owner}@cbre.com`;
    }
    userProfileApi.setUserProfileState({
      email,
      anchorRef: usernameRef?.current,
    });
  };

  return (
    <Grid container className={classes.assetRowMain}>
      <Grid item xs={2} sm={2} md={1} lg={1} className={classes.assetIconDiv}>
        <section>
          <AssetTypeIcon assetType={item?.assettype || 'package'} />
        </section>
        <section>
          {`${item?.assetkind}`.toLowerCase() === 'api'
            ? item?.assetstore || ''
            : item?.assettype}
        </section>
      </Grid>
      <Grid
        item
        xs={7}
        sm={7}
        md={5}
        lg={5}
        className={classes.assetNameOwnerCol}
      >
        <section className={classes.fontBold}>{item?.assetname}</section>
        <section onMouseEnter={onMouseEnter} ref={usernameRef}>
          {item?.owners?.replace('user:', '') || ''}
        </section>
      </Grid>
      <Grid
        item
        xs={3}
        sm={3}
        md={5}
        lg={5}
        className={classes.assetComplexityCol}
      >
        <section>Complexity</section>
        <section className={classes.fontBold}>
          {item?.complexity || 'Not Set'}
        </section>
      </Grid>

      <Grid item xs={12} sm={12} md={1} lg={1} className={classes.editButton}>
        <div className={classes.editArea}>
          <Button
            className={classes.editAreaBtn}
            onClick={() => onClickEdit(item)}
          >
            <EditIcon />
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

const initialState = {
  isCodeFilterClick: false,
  isApiFilterClick: false,
  isAllFilterClick: true,
  isIacFilterClick: false,
  isSalesforceFilterClick: false,
};

const ManageAssetComplexity = () => {
  const [complexityData, setComplexityData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string | undefined>('');
  const [searchChunkData, setSearchChunkData] = useState<any>([]);
  const [checkClickItem, setCheckClickItem] = useState<any>(initialState);
  const [allCount, setAllCount] = useState<number>(0);
  const [codeCount, setCodeCount] = useState<number>(0);
  const [apiCount, setApiCount] = useState<number>(0);
  const [iacCount, setIacCount] = useState<number>(0);
  const [salesforceCount, setSalesforceCount] = useState<number>(0);
  const classes = useStyles();
  const navigate = useNavigate();
  const adminApi = useApi(adminApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const [_paginatePageNumber, setPaginatePageNumber] = useState(0);
  const [_paginateRowsPerPage, setPaginateRowsPerPage] = useState(10);
  const [_paginateCount, setPaginateCount] = useState(0);
  const [_matchArray, setMatchArray] = useState([]);

  useEffect(() => {
    setPaginateCount(complexityData?.length || 0);
    setPaginatePageNumber(0);
    setSearchChunkData(complexityData);

    if (complexityData && complexityData.length > 0) {
      setAllCount(complexityData.length);
      let apiTotal = 0;
      let codeTotal = 0;
      let iacTotal = 0;
      let salesforceTotal = 0;
      for (let item of complexityData) {
        if (item?.assettype === 'iac') {
          iacTotal++;
        } else if (item?.assettype === 'salesforce') {
          salesforceTotal++;
        } else if (`${item?.assetkind}`.toLowerCase() === 'api') {
          apiTotal++;
        } else if (`${item?.assetkind}`.toLowerCase() === 'component') {
          codeTotal++;
        }
      }
      setCodeCount(codeTotal);
      setApiCount(apiTotal);
      setIacCount(iacTotal);
      setSalesforceCount(salesforceTotal);
    } else {
      setAllCount(0);
      setCodeCount(0);
      setApiCount(0);
      setIacCount(0);
      setSalesforceCount(0);
    }
  }, [complexityData]);

  useEffect(() => {
    if (searchTerm) {
      let count = _matchArray?.length;
      if (count >= 0) {
        if (searchChunkData && searchChunkData?.length > 0) {
          let startIndex = _paginatePageNumber * _paginateRowsPerPage;
          let endIndex = -1;
          if (startIndex + _paginateRowsPerPage < count) {
            endIndex = startIndex + _paginateRowsPerPage;
          } else {
            endIndex = count;
          }
          setFilteredData(_matchArray?.slice(startIndex, endIndex));
        }
      }
    } else if (_paginateCount >= 0) {
      if (searchChunkData && searchChunkData?.length > 0) {
        let startIndex = _paginatePageNumber * _paginateRowsPerPage;
        let endIndex = -1;
        if (startIndex + _paginateRowsPerPage < _paginateCount) {
          endIndex = startIndex + _paginateRowsPerPage;
        } else {
          endIndex = _paginateCount;
        }
        setFilteredData(searchChunkData?.slice(startIndex, endIndex));
      }
    }
  }, [
    _paginatePageNumber,
    _paginateRowsPerPage,
    _paginateCount,
    searchTerm,
    searchChunkData,
  ]);

  useEffect(() => {
    if (!searchTerm || searchTerm === '') {
      let count = searchChunkData.length;
      if (searchChunkData && count > 0) {
        let startIndex = _paginatePageNumber * _paginateRowsPerPage;
        let endIndex = -1;
        if (startIndex + _paginateRowsPerPage < count) {
          endIndex = startIndex + _paginateRowsPerPage;
        } else {
          endIndex = count;
        }
        setFilteredData(searchChunkData?.slice(startIndex, endIndex));
      }
      setPaginateCount(searchChunkData?.length);
      setPaginatePageNumber(0);
      setPaginateRowsPerPage(10);
      return;
    }
    if (searchTerm) {
      if (searchChunkData && searchChunkData?.length > 0) {
        let newArr = searchChunkData.filter((item: any): boolean => {
          return (
            item?.assetname
              ?.toLowerCase()
              .includes(searchTerm?.toLowerCase()) ||
            `${item?.complexity}`
              .toLowerCase()
              .includes(searchTerm?.toLowerCase()) ||
            `${item?.assetstore}`
              .toLowerCase()
              .includes(searchTerm?.toLowerCase()) ||
            `${item?.assettype}`
              .toLowerCase()
              .includes(searchTerm?.toLowerCase()) ||
            `${item?.owners}`.toLowerCase().includes(searchTerm?.toLowerCase())
          );
        });
        setMatchArray(newArr);
        setPaginateCount(newArr?.length);
        setPaginatePageNumber(0);
      }
    }
  }, [searchTerm, searchChunkData]);

  const onPageChange = (
    event: React.SyntheticEvent | null,
    pageNumber: number,
  ): void => {
    if (event) {
      event.preventDefault();
    }
    setPaginatePageNumber(pageNumber);
  };

  const onRowsPerPageChange = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    /*
    // @ts-ignore */
    setPaginateRowsPerPage(event?.target?.value);
    setPaginatePageNumber(0);
  };

  useEffect(() => {
    if (adminApi) {
      setLoading(true);
      adminApi
        .getComplexityCatalogInfo()
        .then(data => {
          setComplexityData(data);
          setLoading(false);
        })
        .catch(err => {
          notificationApi.sendNotification({
            message: err.message,
            disapperAfterMs: 2500,
            severity: 'error',
          });
          setComplexityData([]);
          setLoading(false);
        });
    }
  }, []);

  const onClickEdit = (item: any) => {
    navigate(`edit/${item?.id}`);
  };

  const handleOnClickForCode = () => {
    const newChunk = complexityData?.filter(
      (item: any) =>
        `${item.assetkind}`.toLowerCase() === 'component' &&
        item.assettype !== 'iac' &&
        item.assettype !== 'salesforce',
    );
    setSearchChunkData(newChunk);
    setCheckClickItem({
      ...initialState,
      isCodeFilterClick: true,
      isAllFilterClick: false,
    });
  };

  const handleOnClickForApi = () => {
    const newChunk = complexityData?.filter(
      (item: any) => `${item.assetkind}`.toLowerCase() === 'api',
    );
    setSearchChunkData(newChunk);
    setCheckClickItem({
      ...initialState,
      isApiFilterClick: true,
      isAllFilterClick: false,
    });
  };

  const handleOnClickForAll = () => {
    setSearchChunkData(complexityData || []);
    setCheckClickItem(initialState);
  };

  const handleOnClickForIac = () => {
    const newChunk = complexityData?.filter(
      (item: any) => item.assettype === 'iac',
    );
    setSearchChunkData(newChunk);
    setCheckClickItem({
      ...initialState,
      isIacFilterClick: true,
      isAllFilterClick: false,
    });
  };

  const handleOnClickForSalesforce = () => {
    const newChunk = complexityData?.filter(
      (item: any) => item.assettype === 'salesforce',
    );
    setSearchChunkData(newChunk);
    setCheckClickItem({
      ...initialState,
      isSalesforceFilterClick: true,
      isAllFilterClick: false,
    });
  };

  return (
    <div>
      <Grid item container direction="row">
        <Grid item md={12} xs={12}>
          <BackButton />
          <HelpComponent helpUrl={HELP_URL.COST_AVOIDANCE_COMPLEXITY} />
        </Grid>
      </Grid>
      <h2>Manage Asset's Complexity</h2>
      <div>{loading && <Progress />}</div>
      <Grid container className={classes.assetComplexitySection}>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          lg={2}
          className={classes.assetFilters}
        >
          <div>
            <div className={`customFilters ${classes.themeColor}`}>
              <ListItem
                className={checkClickItem.isAllFilterClick ? 'active' : ''}
              >
                <MaterialButton
                  variant="outlined"
                  name="All"
                  onClick={() => handleOnClickForAll()}
                  startIcon={<DoneAllIcon />}
                >
                  All
                  <span className="CountSpan">{allCount}</span>
                </MaterialButton>
              </ListItem>

              <ListItem
                className={checkClickItem.isCodeFilterClick ? 'active' : ''}
              >
                <MaterialButton
                  variant="outlined"
                  name="code"
                  onClick={() => handleOnClickForCode()}
                  startIcon={<CodeIcon />}
                >
                  Code / Library<span className="CountSpan">{codeCount}</span>
                </MaterialButton>
              </ListItem>
              <ListItem
                className={checkClickItem.isApiFilterClick ? 'active' : ''}
              >
                <MaterialButton
                  variant="outlined"
                  name="api"
                  onClick={() => handleOnClickForApi()}
                  startIcon={<ExtensionIcon />}
                >
                  API
                  <span className="CountSpan">{apiCount}</span>
                </MaterialButton>
              </ListItem>
              <ListItem
                className={checkClickItem.isIacFilterClick ? 'active' : ''}
              >
                <MaterialButton
                  variant="outlined"
                  name="iac"
                  onClick={() => handleOnClickForIac()}
                  startIcon={<InfoIcon />}
                >
                  IaC
                  <span className="CountSpan">{iacCount}</span>
                </MaterialButton>
              </ListItem>
              {salesforceCount > 0 && (
                <ListItem
                  className={
                    checkClickItem.isSalesforceFilterClick ? 'active' : ''
                  }
                >
                  <MaterialButton
                    variant="outlined"
                    name="salesforce"
                    onClick={() => handleOnClickForSalesforce()}
                    startIcon={<SalesforceIcon />}
                  >
                    Salesforce
                    <span className="CountSpan">{salesforceCount}</span>
                  </MaterialButton>
                </ListItem>
              )}
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={10}
          lg={10}
          className={classes.assetFilters}
        >
          <div>
            <div>
              <label htmlFor="search-input" hidden>
                Search Here
              </label>
              <Paper component="form" className={classes.assetForm}>
                <Tooltip title="Search">
                  <IconButton disabled type="submit" aria-label="search">
                    <SearchIcon />
                  </IconButton>
                </Tooltip>
                <InputBase
                  className={classes.assetSearch}
                  placeholder="Search"
                  value={searchTerm}
                  data-testid="search-field"
                  onChange={event => {
                    setSearchTerm(event?.target?.value);
                  }}
                  inputProps={{ 'aria-label': 'search asset' }}
                />
                <Tooltip title="Clear">
                  <IconButton
                    aria-label="clear"
                    onClick={() => {
                      setSearchTerm('');
                    }}
                  >
                    <ClearButton />
                  </IconButton>
                </Tooltip>
              </Paper>
            </div>
            <div className={classes.assetRow}>
              {filteredData && filteredData?.length === 0 && searchTerm && (
                <p>
                  No asset matching the search criteria found. Please clear the
                  filter and try again.
                </p>
              )}
              {!loading &&
                filteredData &&
                filteredData?.length === 0 &&
                !searchTerm && <p>No assets available.</p>}
              {loading &&
                filteredData &&
                filteredData?.length === 0 &&
                !searchTerm && <p>Loading...</p>}
              {filteredData?.map((item: any) => (
                <AssetRow key={item.id} item={item} onClickEdit={onClickEdit} />
              ))}
            </div>
          </div>
          <div id="pagination" className={classes.assetPagination}>
            <Paper>
              <TablePagination
                component="div"
                page={_paginatePageNumber}
                rowsPerPage={_paginateRowsPerPage}
                count={_paginateCount}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                rowsPerPageOptions={[]}
              ></TablePagination>
            </Paper>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default ManageAssetComplexity;
