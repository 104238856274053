/* Parameters component for handling query, header, cookie and path params */
import React, { useContext, useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { useStyles } from './styles';
import REGISTER_API_CONSTANTS from '../Constants';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { PromotionContext } from '../../../../providers/PromotionDataProvider';
import CONSTANTS from './Constants';

const QueryParams = (props: any) => {
  const {
    onChange,
    onError,
    path = '',
    classNames = {},
    title = '',
    clicked,
  } = props;
  const classes = useStyles();
  const paramTypes = [
    {
      value: 'query',
      label: 'Query',
    },
    {
      value: 'header',
      label: 'Header',
    },
    {
      value: 'cookie',
      label: 'Cookie',
    },
  ];
  const paramDataTypes = [
    {
      value: 'string',
      label: 'String',
    },
    {
      value: 'integer',
      label: 'Integer',
    },
  ];

  /* Context */
  const myContext = useContext(PromotionContext);

  /* params  */
  const [params, setParams] = useState<any>([]);

  /* Path Params */
  const [pathParams, setPathParams] = useState<any>([]);

  /* Handle Add Params */
  const handleAddParams = () => {
    setParams((prevState: any) => [
      ...prevState,
      {
        id: new Date().getTime(),
        name: '',
        nameError: false,
        required: false,
        type: 'integer',
        in: 'query',
      },
    ]);
  };
  /* Delete params section from Array */
  const handleDeleteParams = (paramId: any) => {
    setParams((prevState: any) => {
      const filteredParams = [...prevState].filter(
        (queryParam: any) => queryParam.id !== paramId,
      );
      return filteredParams;
    });
  };

  /* Handle Params input */
  const handleParams = (event: any, paramId: any) => {
    const { name } = event.target;
    const value =
      name === 'required' ? event.target.checked : event.target.value;
    const newState = params.map((obj: any) => {
      if (obj.id === paramId) {
        return { ...obj, [name]: value };
      }
      // 👇️ otherwise return object as is
      return obj;
    });
    setParams(newState);
  };

  const validate = () => {
    const newState = params.map((obj: any) => {
      return { ...obj, nameError: obj.name ? false : true };
    });
    setParams(newState);
    return params.every((element: any) => element.name);
  };

  useEffect(() => {
    if (clicked) {
      const paramsError = !validate();
      onError('params', paramsError);
      const queryParams =
        params?.length > 0
          ? params.map((param: any) => {
            return {
              name: param.name,
              required: param.required,
              in: param.in,
              schema: {
                type: param.type,
              },
            };
          })
          : [];
      /* Path Params */
      const path_Params =
        pathParams?.length > 0
          ? pathParams.map((param: any) => {
            return {
              name: param,
              required: true,
              in: 'path',
              schema: {
                type: 'string',
              },
            };
          })
          : [];
      onChange([...queryParams, ...path_Params]);
    }
  }, [clicked]);

  useEffect(() => {
    if (path) {
      const paramsPattern = /[^{\}]+(?=})/g;
      setPathParams(path.match(paramsPattern));
    }
  }, [path]);

  return (
    /* Query Params Component */
    <>
      {/* Header Section */}
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={`${classNames.header}  ${classes.spaceBetween}`}
        >
          {title}
          <span>
            <Tooltip title="Add params">
              <IconButton
                onClick={handleAddParams}
                className={classes.actionIcons}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </span>
        </Grid>
      </Grid>

      {/* Body Section */}
      <div className={classNames.body}>
        {params.map((queryParam: any) => {
          return (
            <Grid container spacing={3} key={queryParam.id}>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  name="name"
                  label={REGISTER_API_CONSTANTS.swaggerApiForm.values.label}
                  placeholder={
                    REGISTER_API_CONSTANTS.swaggerApiForm.values.placeholder
                  }
                  value={queryParam.name}
                  fullWidth
                  variant="outlined"
                  multiline
                  onChange={e => handleParams(e, queryParam.id)}
                  required
                  {...(queryParam.nameError && {
                    error: true,
                    helperText: CONSTANTS.errorMsgs.params.name,
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="param-type-label">Param In</InputLabel>
                  <Select
                    labelId="param-type-label"
                    name="in"
                    label="Param In"
                    value={queryParam.in}
                    onChange={e => handleParams(e, queryParam.id)}
                  >
                    {paramTypes?.map((paramType: any) => {
                      return (
                        <MenuItem value={paramType.value} key={paramType.value}>
                          {paramType.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className={classes.paramsSection}
              >
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="type-label">Type</InputLabel>
                  <Select
                    labelId="type-label"
                    name="type"
                    label="Type"
                    value={queryParam.type}
                    onChange={e => handleParams(e, queryParam.id)}
                  >
                    {paramDataTypes?.map((paramDataType: any) => {
                      return (
                        <MenuItem
                          value={paramDataType.value}
                          key={paramDataType.value}
                        >
                          {paramDataType.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControlLabel
                  className={classes.paramsCheckbox}
                  control={
                    <Checkbox
                      name="required"
                      checked={queryParam.required}
                      onChange={e => handleParams(e, queryParam.id)}
                    />
                  }
                  label={REGISTER_API_CONSTANTS.swaggerApiForm.required.label}
                />
                <IconButton
                  className={classes.paramDelIcon}
                  onClick={() => handleDeleteParams(queryParam.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}
      </div>
    </>
  );
};
export default QueryParams;
