import { RELATION_MEMBER_OF, UserEntity } from '@backstage/catalog-model';
import {
  EntityRefLinks,
  getEntityRelations,
  useAsyncEntity,
} from '@backstage/plugin-catalog-react';
import {
  Box,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { InfoCard, InfoCardVariants } from '@backstage/core-components';
import UserAvatar from '../../common/UserAvatar';

const CardTitle = ({ title }: { title?: string }) =>
  title ? (
    <Box display="flex" alignItems="center">
      <PersonIcon fontSize="inherit" />
      <Box ml={1}>{title}</Box>
    </Box>
  ) : null;

const useStyles = makeStyles({
  userAvatarContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const UserProfileCard = ({
  variant,
}: {
  /** @deprecated The entity is now grabbed from context instead */
  entity?: UserEntity;
  variant?: InfoCardVariants;
}) => {
  const { entity: user } = useAsyncEntity<UserEntity>();
  const classes = useStyles();
  if (!user) {
    return <Alert severity="error">User not found</Alert>;
  }

  const {
    metadata: { name: metaName },
    spec: { profile },
  } = user;
  const displayName = profile?.displayName ?? metaName;
  const emailHref = profile?.email ? `mailto:${profile.email}` : undefined;
  const memberOfRelations = getEntityRelations(user, RELATION_MEMBER_OF, {
    kind: 'Group',
  });

  return (
    <InfoCard title={<CardTitle title={displayName} />} variant={variant}>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={10}
          lg={2}
          xl={2}
          className={classes.userAvatarContainer}
        >
          <Box component="div">
            <UserAvatar
              userEmail={profile?.email}
              customStyles={{ width: '90px', height: '90px' }}
            />
          </Box>
        </Grid>

        <Grid item sm={12} md={12} lg={10} xl={10}>
          <List>
            {profile?.email && (
              <ListItem>
                <ListItemIcon>
                  <Tooltip title="Email">
                    <EmailIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText>
                  <Link href={emailHref}>{profile.email}</Link>
                </ListItemText>
              </ListItem>
            )}

            <ListItem>
              <ListItemIcon>
                <Tooltip title="Member of">
                  <GroupIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText>
                <EntityRefLinks
                  entityRefs={memberOfRelations}
                  defaultKind="Group"
                  hideIcons={true}
                />
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </InfoCard>
  );
};
