import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { DataInsightCard } from './DataInsightCard';

export interface IUToolGroupProps {
  title: string;
  tools: any;
}

export const DataCategoryGroup = ({ categoryName, tools }: any) => {
  return (
    <Box sx={{ mb: 2 }}>
      <Box>
        <Typography
          style={{
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '24px',
          }}
        >
          {categoryName === 'Favorites' ? 'Favorites' : categoryName}
        </Typography>
      </Box>

      <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap' }}>
        {tools !== undefined &&
          Array.isArray(tools) &&
          tools.length > 0 &&
          tools.map((tool: any) => {
            if (
              categoryName === tool.category_name ||
              categoryName === 'Favorites'
            ) {
              return <DataInsightCard key={tool?.id} tool={tool} />;
            }
          })}
      </Box>
    </Box>
  );
};
