import { useApi } from '@backstage/core-plugin-api';
import {
  Box,
  Link as MatLink,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';
import { promotionApiRef } from '../../../../apis/promotionApi';
import noImage from '../../../../assest/no-image.jpg';

function debounce(func: any, timeout = 300) {
  let timer: any;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      // @ts-expect-error
      func.apply(this, args);
    }, timeout);
  };
}

const useStyles = makeStyles({
  root: {
    marginRight: '16px',
    background: '#fff',
    borderRadius: '8px',
    display: 'flex',
    position: 'relative',
    transition: 'all .3s ease-in',
    filter: 'drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.14))',
    clear: 'both',
    width: '475px',
  },
  imageRoot: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    transition: 'all .3s ease-in',
    width: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    borderRadius: '8px',
  },
  imageTag: {
    width: '100%',
    objectFit: 'cover',
    transition: 'all .3s ease-in',
  },
  noImageTag: {
    width: 'inherit',
    objectFit: 'cover',
    transition: 'all .3s ease-in',
  },
  textRoot: {
    padding: '16px',
    width: '417px',
    alignItems: 'center',
    overflow: 'hidden',
    zIndex: 999,
    textOverflow: 'ellipsis',
    transition: 'all .3s ease-in',
  },
  textTitle: {
    flex: 1,
    fontWeight: 'bold',
    fontSize: '1.2rem',
    wordBreak: 'break-word',
    transition: 'all .3s ease-in',
    lineHeight: '24px',
    color: '#fff',
    textShadow: '1px 1px 1px black',
    letterSpacing: '-0.01em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  textDescRoot: {
    height: '95px',
    marginTop: '8px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingBottom: '4px',
  },
  textDescription: {
    height: '80px',
    color: '#fff',
    lineHeight: '16px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 5,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
  },
  overlay: {
    width: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    background:
      'linear-gradient(90deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.441146) 50.53%, rgba(0, 0, 0, 0) 98%)',
    borderRadius: '8px',
    opacity: '0.7',
    zIndex: 90,
  },
});

const PosterImagePromotion = ({ promotion }: any) => {
  const [imageData, setImageData] = useState<any>('');
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const { promotionId, title } = promotion;
  const classes = useStyles();

  const promotionApi = useApi(promotionApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  /*   useEffect(() => {
    promotionApi
      .getPromotionImage(promotionId, title)
      .then((res: any) => {
        setImageData(res);
      })
      .catch((err: any) => {
        setImageData(undefined);
        notificationApi.sendNotification({
          message: `Error occurred - ${err?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  }, [promotionId, title]); */

  const onMouseEnter = () => {
    debounce(setIsExpanded, 100)(true);
  };

  const onMouseLeave = () => {
    debounce(setIsExpanded, 100)(false);
  };

  return (
    <Box
      sx={{
        height: isExpanded ? '170px' : '87px',
        zIndex: isExpanded ? '99' : '10',
      }}
      className={classes.root}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Box className={classes.imageRoot}>
        {promotion?.assetURL !== '' && promotion?.assetURL ? (
          <>
            {promotion?.assetURL ? (
              <img
                src={`https://${promotion?.assetURL}`}
                // src={`data:${imageData.headers['content-type']};base64,${imageData.data}`}
                className={classes.imageTag}
                loading="lazy"
              />
            ) : (
              <img
                src={noImage}
                className={classes.noImageTag}
                loading="lazy"
              />
            )}
          </>
        ) : (
          <img
            src={noImage}
            className={classes.noImageTag}
            loading="lazy"
          ></img>
        )}
      </Box>

      <Box className={classes.overlay}>
        <Box className={classes.textRoot}>
          <MatLink
            to={`/promotions/${promotion?.title}/${promotion?.promotionId}`}
            component={Link}
          >
            <Typography className={classes.textTitle}>
              {promotion?.title}
            </Typography>
          </MatLink>

          {isExpanded && (
            <Box className={classes.textDescRoot}>
              <Typography
                variant="caption"
                component="div"
                title={promotion?.description}
                className={classes.textDescription}
              >
                {promotion?.description}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PosterImagePromotion;
