import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  FormControl,
  Select,
  MenuItem,
  TextField
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  CANCEL_AI_RESPONSE_TITLE,
  CANCEL_AI_RESPONSE_OPTIONS,
} from '../../utils/constant';

const useStyles = makeStyles({
  dialogBox: {
    position: 'relative',
  },
  dialogContent: {
    width: '500px',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  toolName: {
    fontWeight: 'bold',
  },
  otherText: {
    marginTop: '10px'
  }
});

export interface CancelAIProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (comment: string) => void;
}

const CancelAIDialog = ({
  isOpen,
  onClose,
  onConfirm,
}: CancelAIProps) => {
  const classes = useStyles();
  const [value, setValue] = useState(CANCEL_AI_RESPONSE_OPTIONS[0].value);
  const [desc, setDesc] = useState('');

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      className={classes.dialogBox}
      fullWidth={false}
    >
      <DialogTitle id="dialog-title">
        {CANCEL_AI_RESPONSE_TITLE}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
      <FormControl fullWidth variant="outlined" size="small" required>
          <Select
            labelId="ai-cancel-response-label"
            value={value}
            data-testid="ai-cancel-response-id"
            fullWidth
            onChange={(event: any) => {
                setValue(event?.target?.value);
            }}
          >
            {CANCEL_AI_RESPONSE_OPTIONS.map((option: any) => (
              <MenuItem value={option.value} key={`ai-reponse-cancel-option-${option.value}`}>{option.title}</MenuItem>
            ))}
          </Select>
          {
            value == 'other' && <TextField 
                onChange={event => {
                    setDesc(event?.target?.value);
                }}
                className={classes.otherText}
                value={desc}
            />
          }
        </FormControl>
      </DialogContent>
      <DialogActions>
      <Button
          onClick={onClose}
          data-testid="cancel-btn-ai"
          variant="contained"
          autoFocus
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            onConfirm(value === 'other' ? desc : value)
          }}
          data-testid="submit-btn-ai"
          color="primary"
          variant="contained"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default CancelAIDialog;
