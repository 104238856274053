import { scmIntegrationsApiRef } from '@backstage/integration-react';
import {

  ScaffolderClient,
} from '@backstage/plugin-scaffolder';
import {
  scaffolderApiRef
} from '@backstage/plugin-scaffolder-react';

import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './Routes';
import { swTemplateGithubApiRef } from './fields/DevxRepoPicker/api/SWTemplateGithubApi';
import { SWTemplateGithubClient } from './fields/DevxRepoPicker/api/SWTemplateGithubClient';
import { axiosInstanceProviderApiRef } from '../../apis/axiosInstanceApi';

/**
 * The main plugin export for the scaffolder.
 * @public
 */
export const cbreScaffolderPlugin = createPlugin({
  id: 'cbre.scaffolder',
  apis: [
    createApiFactory({
      api: scaffolderApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        scmIntegrationsApi: scmIntegrationsApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, scmIntegrationsApi, fetchApi }) =>
        new ScaffolderClient({
          discoveryApi,
          scmIntegrationsApi,
          fetchApi,
        }),
    }),
    createApiFactory({
      api: swTemplateGithubApiRef,
      deps: {
        axiosInstanceProviderApi: axiosInstanceProviderApiRef,
      },
      factory: ({ axiosInstanceProviderApi }) => new SWTemplateGithubClient(axiosInstanceProviderApi),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const ScaffolderPage = cbreScaffolderPlugin.provide(
  createRoutableExtension({
    name: 'ScaffolderPage',
    component: () => import('./Routes').then(m => m.ScaffolderRouter),
    mountPoint: rootRouteRef,
  }),
);
