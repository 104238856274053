/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { toolsProvisioningApiRef } from '../../../apis/toolsProvisioningApi';
import { useEffect, useState } from 'react';
import {
  IAvailableTool,
  IGetProjectTools,
  IProvisionedTool,
} from '../interfaces';

export const useGetTools = (
  apmNumber: string,
  refreshCounter: number,
  timeout: number,
) => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const toolsprovisioningApi = useApi(toolsProvisioningApiRef);

  const [availableTools, setAvailableTools] = useState<IAvailableTool[]>([]);
  const [provisionedTools, setProvisionedTools] =
    useState<IProvisionedTool[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchMyAPI = async () => {
    if (toolsprovisioningApi) {
      try {
        const response: IGetProjectTools =
          await toolsprovisioningApi.getProjectTools(apmNumber);
        if (response?.status === 'SUCCESS') {
          setIsLoading(false);
          setAvailableTools(response.data.available);
          setProvisionedTools(response?.data?.provisioned || []);
        }
      } catch (error) {
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };

  useEffect(() => {
    if (apmNumber === undefined) return;
    if (apmNumber) {
      setIsLoading(true);

      if (refreshCounter > 0)
        setTimeout(() => {
          fetchMyAPI();
        }, timeout);
      else {
        fetchMyAPI();
      }
    }
  }, [apmNumber, refreshCounter]);

  return [availableTools, provisionedTools, isLoading] as const;
};
