import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FormControl,
  Select,
  MenuItem,
  Button,
  TextField,
  InputAdornment,
  Grid,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useCustomStyles } from './DocStyles';
import EXT_DOCS_CONSTANTS from '../Constants';

const ExtDocsContentHeader = ({
  onFilterKeyChange,
  setFilterText,
  filterKey,
}: {
  onFilterKeyChange: any;
  setFilterText: any;
  filterKey: any;
}) => {
  const classes = useCustomStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigateToRegisterPage = () => {
    navigate('/docs/external/register');
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={7} className={classes.leftSection}>
        <div className={classes.SearchByStyle}>
          <label htmlFor="select-filter-key" hidden>
            select-filter-key
          </label>
          <FormControl size="small">
            <Select
              id="select-filter-key"
              data-testid="select-filter-key"
              onChange={onFilterKeyChange}
              value={filterKey}
              variant="outlined"
            >
              {EXT_DOCS_CONSTANTS.externalFilterSelectItems.map(val => (
                <MenuItem key={val.value} value={val.value}>
                  {val.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.SearchBySelectStyle}>
          <label htmlFor="search-input" hidden>
            Search Here
          </label>
          <TextField
            size="small"
            id="search-input"
            type="search"
            variant="outlined"
            onChange={event => {
              setFilterText(event?.target?.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </Grid>

      <Grid item xs={12} md={5}>
        <Button
          fullWidth={isMobile}
          className={classes.externalButton}
          onClick={navigateToRegisterPage}
          variant="contained"
          color="primary"
        >
          {EXT_DOCS_CONSTANTS.registerNewDocumentationLabel}
        </Button>
      </Grid>
    </Grid>
  );
};
export default ExtDocsContentHeader;
