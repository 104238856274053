import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import { Content, PageWithHeader } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { pocHubApiRef } from '../../../apis/pocHubApi';
import { DevxBreadCrumb } from '../../common/BreadcrumbsNav/DevxBreadCrumb';
import BackButton from '../../utils/backButton';
import { HelpComponent } from '../../utils/helpComponent';
import LinkIcon from '@material-ui/icons/Link';
import AddIcon from '@material-ui/icons/Add';
import HELP_URL from '../../utils/helpLinkConstant';
import { useStyles } from '../common/styles';
import { FileListItem } from './fileList';
import { useLocation, useNavigate } from 'react-router-dom';
import { urlRegex, validImageFormat } from '../../utils/Utils';
import { IMAGE_MAX_SIZE } from '../../utils/constant';
import { Alert } from '@material-ui/lab';
import { uid } from '../utils';
import { constants } from '../common/constants';

export const allowedExtensions =
  /(\.jpg|.gif|.png|.bmp|.pdf|.txt|.doc|.docx|.xls|.xlsx|.xlr|.mdf|.ppt|.pptx|.msg|.log|.bak|.xml|.html|.mp3|.m4a|.m3u|.mid|.wma|.wav|.mp4|.mpg|.m4v|.vob|.mnv|.rm|.mov|.flv|.wmv)$/i;

export const fileNameRegex = /^[a-zA-Z0-9_()-\s]+\.[^.]+$/;

export const AdditionalPocPage = () => {
  const classes = useStyles();
  const pocApi = useApi(pocHubApiRef);
  const [haveDemo, setHaveDemo] = useState(false);
  const [demoLink, setDemoLink] = useState('');
  const [imageDataBase64, setImageDataBase64] = React.useState<any>('');
  const [validateImageFile, setValidateImageFile] = React.useState(false);
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const [fileList, setFileList] = React.useState<any>([
    {
      id: uid(),
      title: '',
      content: '',
      error: '',
      fileName: '',
    },
  ]);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const { state: formData }: any = useLocation();

  const [errors, setErrors] = useState({
    demoLink: '',
    imageDataBase64: '',
    fileList: '',
  });

  const navigate = useNavigate();

  const navigateToAddPocPage = () => {
    navigate('/poc-hub/add-poc');
  };
  const navigateToPocPage = () => {
    navigate('/poc-hub');
  };

  useEffect(() => {
    if (!formData) {
      navigateToAddPocPage();
    }
  }, []);

  const imageUploadHandle = (e: any) => {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      if (
        validImageFormat(e.target.files[0].type) &&
        e.target.files[0].size < IMAGE_MAX_SIZE
      ) {
        let imageFile = e.target.files[0];
        const reader = new FileReader();

        getBase64(imageFile).then(data => {
          setImageDataBase64(data);
        });
        reader.readAsDataURL(imageFile);
        setValidateImageFile(false);
      } else {
        setValidateImageFile(true);
      }
    } else {
      setValidateImageFile(false);
    }
  };

  function getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const handleFileSelect = async (e: any, idx: number) => {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      
      const content = await getBase64(e.target.files[0]);
      setFileList(
        fileList.map((file: any) =>
          file.id === idx
            ? {
                ...file,
                content: content,
                error: (!allowedExtensions.exec(e.target.files[0].name) || fileList.find((f: any) => f.fileName === e.target.files[0].name))
                  ? 'File already exists or unsupported file type'
                  : !e.target.files[0].name.match(fileNameRegex)
                  ? 'File name should not have special characters'
                  : '',
                fileName: e.target.files[0].name,
              }
            : file,
        ),
      );
    }
  };

  const handleFileNameChange = (name: string, idx: string) => {
    setFileList(
      fileList.map((file: any) =>
        file.id === idx ? { ...file, title: name } : file,
      ),
    );
  };

  const validateFileList = () => {
    let valid = true;
    fileList.forEach((file: any) => {
      // check file extension and file name
      if (!file.fileName || !allowedExtensions.exec(file.fileName)) {
        valid = false;
      }
      // check if duplicate file name
      return;
    });
    return valid;
  };

  const validate = () => {
    const errorObj = {
      demoLink: '',
    };
    if (haveDemo) {
      errorObj.demoLink =
        demoLink === '' || demoLink.match(urlRegex)
          ? ''
          : constants.errors.INVALID_DEMO_URL;
    } else {
      errorObj.demoLink = '';
    }

    setErrors(prevState => {
      return { ...prevState, ...errorObj };
    });
    return Object.values(errorObj).every((x: any) => x === '');
  };

  const onSubmit = async () => {
    setSubmitEnabled(false);

    if (validate()) {
      setShowProgress(true);
      try {
        let loc: any;
        let links: any = [
          ...(haveDemo
            ? [
                {
                  title: 'devx-demo-link',
                  url: demoLink,
                  type: 'demo',
                },
              ]
            : []),
        ];
        if (fileList.length > 0 && fileList[0].fileName) {
          if (validateFileList()) {
            if (fileList.length > 0) {
              loc = await pocApi
                .fileUpload({
                  fileList,
                  repourl: formData.repoUrl,
                })
                .catch(() => {
                  throw new Error(constants.errors.FAILED_TO_UPLOAD);
                });
            }

            links = [
              ...links,
              ...((loc && loc.files) && loc?.files?.length > 0
                ? loc?.files?.map((f: any) => {
                    return {
                      title:
                        fileList.find((a: any) => a.fileName === f.name)
                          ?.title || f.name,
                      type: 'docs',
                      url: f.location,
                    };
                  })
                : []),
            ];
          } else {
            setSubmitEnabled(true);
            throw new Error(constants.errors.INVALID_FILE_TYPE);
          }
        }
        const response = await pocApi.updateRegisteredComponent({
          owneremail: formData.loggedInUser,
          repourl: formData.repoUrl,
          description: formData.description,
          type: constants.POC,
          name: formData.name,
          status: constants.APPROVED,
          tags: formData.tags,
          links: links,
          image: imageDataBase64 || '',
          scm: constants.GITHUB,
          scmrepoid: '',
          branch: constants.MAIN,
        });

        if (response) {
          setShowProgress(false);
          notificationApi.sendNotification({
            message: constants.EDIT_SUCCESS_MESSAGE,
            disapperAfterMs: 2500,
            severity: 'success',
            callback: () => navigateToPocPage(),
          });
        }
      } catch (err: any) {
        setShowProgress(false);
        notificationApi.sendNotification({
          message: `${err.message || constants.errors.FAILED_TO_UPLOAD}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };

  const handleDelete = (idx: string) => {
    const updatedList = fileList.filter((file: any) => file.id !== idx);
    setFileList(updatedList);
  };

  useEffect(() => {
    setSubmitEnabled(
      (fileList.length && fileList[0]?.fileName) || imageDataBase64 || demoLink,
    );
  }, [fileList, imageDataBase64, demoLink]);

  return (
    <PageWithHeader
      title="POC Hub"
      themeId="home"
      subtitle={
        <DevxBreadCrumb
          routes={[
            {
              type: 'link',
              link: '/',
              text: 'Home',
            },
            {
              type: 'link',
              link: '/poc-hub',
              text: 'POC Hub',
            },
            {
              type: 'link',
              link: '/poc-hub/add-poc',
              text: 'Add POC',
            },
            {
              type: 'link',
              link: '',
              text: 'Additional Details',
            },
          ]}
        />
      }
    >
      <Content>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <BackButton />
            <HelpComponent helpUrl={HELP_URL.ADDITIONAL_POC_PAGE} />
          </Grid>
        </Grid>

        <div className={`codePage ${classes.additionalInfoPage} `}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.additionalDetailsPage}
            >
              {
                <Alert className={classes.errorForAdd} severity="success">
                  {' ' + constants.success.CREATE_POC}
                </Alert>
              }
              <Typography
                component="div"
                className={classes.additionalInfoHeading}
              >
                Add POC - Additional Details
              </Typography>
              <FormGroup className={classes.margin}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={showProgress}
                      checked={haveDemo}
                      onClick={() => {
                        setHaveDemo(!haveDemo);
                        setDemoLink('');
                        setErrors({ ...errors, demoLink: '' });
                      }}
                    />
                  }
                  label="Have a POC demo link?"
                />
              </FormGroup>
              {haveDemo && (
                <TextField
                  className={classes.margin}
                  disabled={showProgress}
                  label="POC Demo Link"
                  id="POC Demo Link"
                  name="POC Demo Link"
                  fullWidth
                  variant="outlined"
                  multiline
                  value={demoLink}
                  {...(errors.demoLink && {
                    error: true,
                    helperText: errors.demoLink,
                  })}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >,
                  ) => {
                    setDemoLink(event?.target?.value);
                    setErrors({ ...errors, demoLink: '' });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              <Typography component="div" className={classes.headingMargin}>
                POC Thumbnail Image
              </Typography>
              <div className={classes.inputFields}>
                <TextField
                  // label="Upload Image"
                  disabled={showProgress}
                  fullWidth
                  type="file"
                  variant="outlined"
                  helperText="Only upload image files jpeg/png extensions"
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >,
                  ) => {
                    imageUploadHandle(event);
                  }}
                />
              </div>
              {validateImageFile && (
                <Typography className={classes.errorMessage}>
                  *Supported image type is jpeg or png format (optional field/no
                  image) and size must less than 15MB!
                </Typography>
              )}
              <div className={classes.docs}>
                <div>
                  <Typography component="div" className={classes.headingMargin}>
                    POC Documents and Artifacts (Optional)
                  </Typography>
                  <Typography component="div" className={classes.description}>
                    Document, Presentation, Spreadsheet, Image and Video files
                    allowed. Total file size during each upload should not exceed 10Mb
                  </Typography>
                </div>
                <IconButton
                  className={classes.addItemButton}
                  disabled={showProgress}
                  onClick={() => {
                    if (
                      (fileList.length === 0 ||
                        !!fileList[fileList.length - 1].content) &&
                      fileList.length < 6
                    ) {
                      const temp = {
                        id: uid(),
                        title: '',
                        content: '',
                        error: '',
                        fileName: '',
                      };
                      setFileList((prevState: any) => [...prevState, temp]);
                    }
                  }}
                >
                  Add Item
                  <AddIcon />
                </IconButton>
              </div>
              {fileList.map((file: any) => (
                <FileListItem
                  disabled={showProgress}
                  onDelete={() => {
                    handleDelete(file.id);
                  }}
                  onChangeTitle={(e: any) => {
                    handleFileNameChange(e, file.id);
                  }}
                  onChangeFile={(e: any) => {
                    handleFileSelect(e, file.id);
                  }}
                  idx={file.id}
                  error={file.error}
                  key={`file-list-${file.id}`}
                  title={file.title}
                />
              ))}
              <div className={classes.margin}>
                {showProgress && <LinearProgress />}
              </div>
              <div className={classes.buttons}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.additionalPocButtons}
                  onClick={navigateToPocPage}
                  disabled={showProgress}
                >
                  Skip
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!submitEnabled || fileList.find((f: any) => f.error)}
                  className={classes.additionalPocButtons}
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  Submit
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Content>
    </PageWithHeader>
  );
};
