import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { PageWithHeader, Content } from '@backstage/core-components';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import BackButton from '../../utils/backButton';
import RegisterSteps from './RegisterSteps';
import ConfirmDetails from './ConfirmDetails';
import { useNavigate } from 'react-router-dom';
import { PromotionContext } from '../../../providers/PromotionDataProvider';
import { useStyles } from './styles';
import { DevxBreadCrumb } from '../../common/BreadcrumbsNav/DevxBreadCrumb';

const ConfirmDetailsPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const myContext = useContext(PromotionContext);
  const [formValues, setFormValues] = useState();

  /* Set form data if the form data is there in context else go back to input form page */
  useEffect(() => {
    if (myContext.registerApiData) {
      setFormValues(myContext.registerApiData);
    } else {
      navigate('/API/register-api');
    }
  }, [myContext.registerApiData]);

  return (
    /* Confirm Details Page */
    <PageWithHeader
      title="Register API "
      themeId="home"
      subtitle={
        <DevxBreadCrumb
          routes={[
            { type: 'link', link: '/', text: 'Home' },
            { type: 'link', link: '/API', text: 'APIs' },
            { type: 'link', link: '/API/register-api', text: 'Register API' },
            { type: 'text', link: '', text: 'Confirm Details' },
          ]}
        />
      }
    >
      <Content>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} md={12}>
            <BackButton />

            <HelpComponent helpUrl={HELP_URL.REGISTER_API} />
          </Grid>
        </Grid>
        <div className="codePage">
          <Grid container direction="row" spacing={3}>
            {/* Confirm Details Component */}
            <Grid item xs={12} sm={12} md={8} className={classes.registerApi}>
              {formValues && <ConfirmDetails formValues={formValues} />}
            </Grid>
            {/* Register Steps Component */}
            <Grid item xs={12} sm={12} md={4}>
              <RegisterSteps />
            </Grid>
          </Grid>
        </div>
      </Content>
    </PageWithHeader>
  );
};
export default ConfirmDetailsPage;
