import React, { useEffect, useState, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Theme,
  Box,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Drawer,
  Paper,
  LinearProgress,
  Chip,
  TextField,
  TablePagination,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import FilterAltIcon from '@material-ui/icons/FilterAlt';
import { HelpComponent } from '../utils/helpComponent';
import HELP_URL from '../utils/helpLinkConstant';
import BackButton from '../utils/backButton';
import { PageWithHeader, Content } from '@backstage/core-components';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';
import { AuthContext } from '../../providers/AuthProvider';
import { useApi } from '@backstage/core-plugin-api';
import { ReleaseNoteApi, releaseNoteApiRef } from '../../apis/releaseNotes';
import { formatDateMMDDYYYY } from '../utils/dateUtils';
import * as Constants from '../utils/constant';
import { useRNStyles } from './ReleaseNotesStyles';

const ReleaseNotePage = () => {
  const classes = useRNStyles();
  const { id } = useParams();
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const releaseNoteApi: ReleaseNoteApi = useApi(releaseNoteApiRef);
  const isMidSizeScreen = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('md'),
  );
  const theme = useTheme<Theme>();
  const [filterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(false);
  const [notes, setNotes] = useState<any>([]);
  const [active, setActive] = useState<any>({});
  const [filterOptions, setFilterOptions] = useState<any>([]);
  const [filterVersionOptions, setFilterVersionOptions] = useState<any>([]);
  const [filterFeature, setFilterFeature] = useState<any>({
    label: 'All',
    value: 'all',
  });
  const [filterVName, setFilterVName] = useState<any>({
    label: 'All',
    value: 'all',
  });
  const [isLoading, setLoading] = useState(true);
  const [displayList, setDisplayList] = useState<any>([]);
  const { isAdmin } = useContext(AuthContext);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showedContent, setShowedContent] = useState<any>([]);
  const contentPerPage = 10;
  const [isHidden, setHidden] = useState(false);
  const inputRef = useRef();
  const [releaseDate, setReleaseData] = useState<any>('');
  const [currentDate] = useState<any>(
    String(new Date().getFullYear()) +
      '-' +
      String(('0' + (new Date().getMonth() + 1)).slice(-2)),
  );
  const fetchNotes = async () => {
    const n = await releaseNoteApi.getAllNotes();

    setNotes(n);
    setDisplayList(n);
    if (n && n.length > 0) {
      const m: any[] = [
        {
          label: 'All',
          value: 'all',
        },
      ];
      n.forEach(note => {
        if (
          note.displaystate === Constants.TRUE &&
          note.tags &&
          note.tags.length > 0
        ) {
          note.tags.forEach((t: string) => {
            if (!m.find(o => o.label === t)) {
              m.push({
                label: t,
                value: t,
              });
            }
          });
        }
      });
      setFilterOptions(m);
      setFilterVersionOptions([
        {
          label: 'All',
          value: 'all',
        },
        ...n.map(note => ({
          label: note.vname,
          value: note.vname,
        })),
      ]);
    }
    setLoading(false);
  };

  useEffect(() => {
    setDisplayList(notes);
    fetchNotes();
  }, []);

  useEffect(() => {
    const allNotes = notes;
    let filtered = notes;

    // checks to see if filter by feature has any selection made
    if (filterFeature?.value !== undefined && filterFeature?.value !== 'all') {
      filtered = filtered.filter(
        (n: any) => n.tags && n.tags?.includes(filterFeature?.value),
      );
    }
    // checks to see if filter by version has any selection made
    if (filterVName?.value !== undefined && filterVName?.value !== 'all') {
      filtered = filtered.filter(
        (n: any) =>
          n.vname &&
          getVersionName(n.vname, filterVName?.value) ===
            String(filterVName?.value),
      );
    }
    // checks to see if filter by Month has any selection made
    if (releaseDate !== '' && releaseDate !== 'all') {
      filtered = filtered.filter(
        (n: any) =>
          n.releasedate &&
          getDateFormatted(n.releasedate, releaseDate) === String(releaseDate),
      );
    }

    setDisplayList(filtered);
    setActive(filtered[0]);

    const m: any[] = [
      {
        label: 'All',
        value: 'all',
      },
    ];
    filtered.forEach((note: any) => {
      if (
        note.displaystate === Constants.TRUE &&
        note.tags &&
        note.tags.length > 0
      ) {
        note.tags.forEach((t: string) => {
          if (!m.find(o => o.label === t)) {
            m.push({
              label: t,
              value: t,
            });
          }
        });
      }
    });
    setFilterOptions(m);
    const versionOptions = filtered.map((note: { vname: any }) => ({
      label: note.vname,
      value: note.vname,
    }));
    setFilterVersionOptions(versionOptions);
  }, [filterVName, filterFeature, releaseDate, notes]);

  useEffect(() => {
    getVersionNotes();
  }, [filterVName, filterFeature]);

  function getVersionName(vname: string, filterValue: any): string {
    return String(vname);
  }

  const getVersionNotes = async () => {
    let n = [...notes];

    if (filterFeature?.value !== '' && filterFeature?.value !== 'all') {
      n = n.filter(
        (n: any) => n.tags && n.tags?.includes(filterFeature?.value),
      );
    }

    if (n && n.length > 0) {
      const filteredNotes = n.filter(
        (note: any) => note.displaystate === Constants.TRUE,
      );
      const m: any[] = [{ label: 'All', value: 'all' }];
      n.forEach(note => {
        if (note.vname && note.displaystate === Constants.TRUE) {
          m.push({
            label: note.vname,
            value: note.vname,
          });
        }
      });
      setFilterVersionOptions(m);
    }

    setLoading(false);
  };

  const handleReleaseDateChange = (value: any) => {
    if (value === null || value === '') {
      setReleaseData('');
    } else {
      setReleaseData(value);
    }
  };

  function getDateFormatted(releaseDate: any, filterValue: any): string {
    const dateList = releaseDate.split('-');
    return `${dateList[0]}-${dateList[1]}`;
  }

  // search starts
  const onChangeSearchText = async (e: any) => {
    setSearchText(e?.target?.value);
  };

  useEffect(() => {
    const searchedNotes = notes.filter((obj: any) =>
      Object.values(obj).some((objValue: any) =>
        objValue?.toString()?.toLowerCase()?.includes(searchText.toLowerCase()),
      ),
    );
    setDisplayList(searchedNotes);
    setActive(searchedNotes[0]);
  }, [searchText]);

  // new search ends here
  // pagination start

  useEffect(() => {
    setCurrentIndex(0);
  }, [displayList]);
  // Slicing the totoal contant so the start and end of the page is defined
  useEffect(() => {
    const begin = currentIndex * contentPerPage;
    const end = begin + contentPerPage;
    const slicedContent = displayList.slice(begin, end);
    setShowedContent(slicedContent);
    setTotalCount(displayList.length);
  }, [currentIndex, contentPerPage, displayList]);
  // changing  page start and end according to page number
  const onPageChange = (
    event: React.SyntheticEvent | null,
    pageNumber: number,
  ): void => {
    if (event) {
      event.preventDefault();
    }
    setCurrentIndex(pageNumber);
  };

  // pagination End

  // Hide Button for filters
  const hiddenFilter = () => {
    if (isHidden === true) {
      setHidden(false);
    } else {
      setHidden(true);
    }
  };

  return (
    <PageWithHeader
      title="Release Notes"
      themeId="home"
      subtitle={
        <DevxBreadCrumb
          routes={[
            {
              type: 'link',
              link: '/',
              text: 'Home',
            },
            {
              type: 'text',
              link: '',
              text: 'Release Notes',
            },
          ]}
        />
      }
    >
      <Content>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <BackButton />
            <HelpComponent helpUrl={HELP_URL.RELEASE_NOTES} />
          </Grid>
          {isLoading && <LinearProgress />}
          <>
            <Grid container style={{ position: 'relative' }}>
              {isMidSizeScreen ? (
                <>
                  <Button
                    style={{
                      marginTop: theme.spacing(1),
                      marginLeft: theme.spacing(1),
                    }}
                    onClick={() => setFilterDrawerOpen(true)}
                    startIcon={<FilterListIcon />}
                  >
                    Filters
                  </Button>
                  <Drawer
                    open={filterDrawerOpen}
                    onClose={() => setFilterDrawerOpen(false)}
                    anchor="left"
                    disableAutoFocus
                    keepMounted
                    variant="temporary"
                  >
                    <Box
                      sx={{
                        padding: theme.spacing(1),
                      }}
                    >
                      Searh and Filters
                    </Box>

                    <Box
                      sx={{
                        padding: theme.spacing(1),
                      }}
                    >
                      <TextField
                        variant="outlined"
                        type="search"
                        placeholder="Search"
                        onChange={e => onChangeSearchText(e)}
                        value={searchText}
                        fullWidth
                        style={{
                          backgroundColor: theme.palette.background.paper,
                        }}
                        InputProps={{ startAdornment: <SearchIcon /> }}
                      />
                    </Box>
                    <Box
                      sx={{
                        padding: theme.spacing(1),
                      }}
                    >
                      <Autocomplete
                        disablePortal
                        freeSolo
                        style={{
                          backgroundColor: theme.palette.background.paper,
                        }}
                        options={filterOptions || []}
                        disabled={filterOptions?.length === 0}
                        value={filterFeature || ''}
                        onChange={(event: any, value) => {
                          event?.preventDefault();
                          setFilterFeature(value);
                        }}
                        getOptionLabel={item => (item.label ? item.label : '')}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Filter by Feature"
                            fullWidth
                            variant="outlined"
                          />
                        )}
                        fullWidth
                      />
                    </Box>

                    <Box
                      sx={{
                        padding: theme.spacing(1),
                      }}
                    >
                      <Autocomplete
                        disablePortal
                        freeSolo
                        style={{
                          backgroundColor: theme.palette.background.paper,
                        }}
                        options={filterVersionOptions || []}
                        disabled={filterVersionOptions?.length === 0}
                        value={filterVName || ''}
                        onChange={(event: any, value) => {
                          event?.preventDefault();
                          setFilterVName(value);
                        }}
                        getOptionLabel={item => (item.label ? item.label : '')}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Filter by Version Name"
                            fullWidth
                            variant="outlined"
                          />
                        )}
                        fullWidth
                      />
                    </Box>

                    <Box
                      sx={{
                        padding: theme.spacing(1),
                      }}
                    >
                      <TextField
                        fullWidth
                        className={classes.calendarColor}
                        label="Filter by Month and Year"
                        variant="outlined"
                        ref={inputRef}
                        value={releaseDate.value}
                        onChange={(event: React.SyntheticEvent) => {
                          handleReleaseDateChange(event?.target?.value);
                        }}
                        type="month"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: { min: '2022-01', max: currentDate },
                        }}
                      />
                    </Box>

                    <Box>
                      <Typography
                        variant="h6"
                        component="h2"
                        style={{ marginBottom: theme.spacing(1) }}
                        className={`${classes.filterTitle} ${classes.drawer}`}
                      >
                        Versions
                      </Typography>
                      {showedContent.length === 0 ? (
                        <div className={classes.filter}>
                          <Typography>No Release Notes Found</Typography>
                        </div>
                      ) : (
                        showedContent.map((n: any, idx: number) => (
                          <div
                            key={`release-note-${n.id}`}
                            className={`${classes.filter} ${
                              active?.id === n.id && classes.filterActive
                            }`}
                            onClick={() => {
                              setActive(showedContent[idx]);
                            }}
                          >
                            <Typography component="span">{n.vname}</Typography>
                            <Typography
                              component="span"
                              className={classes.rightAlign}
                            >
                              {formatDateMMDDYYYY(n.releasedate)}
                            </Typography>
                            <div className={classes.filterDesc}>
                              <Typography component="span">{n.vnum}</Typography>
                            </div>
                          </div>
                        ))
                      )}
                    </Box>
                    {/* Pagination */}
                    <Grid className={classes.PagePaginationContainer}>
                      <Paper>
                        <TablePagination
                          classes={{
                            root: classes.tablePaginationRoot,
                            actions: classes.tablePaginationActions,
                          }}
                          component="div"
                          page={currentIndex}
                          rowsPerPage={contentPerPage}
                          count={totalCount}
                          onPageChange={onPageChange}
                          rowsPerPageOptions={[]}
                        />
                      </Paper>
                    </Grid>
                    {/* Pagination end */}
                  </Drawer>
                </>
              ) : (
                <Grid item lg={3}>
                  <Box m={2} className={classes.filterBox}>
                    {/* starting search */}
                    <Box className={classes.filterBox}>
                      <Box className={classes.searchBox}>
                        <Box flexGrow={2} className={classes.spaceBox}>
                          <TextField
                            variant="outlined"
                            type="search"
                            placeholder="Search"
                            onChange={e => onChangeSearchText(e)}
                            value={searchText}
                            fullWidth
                            style={{
                              backgroundColor: theme.palette.background.paper,
                            }}
                            InputProps={{ startAdornment: <SearchIcon /> }}
                          />
                        </Box>
                        <Box className={classes.spaceBox}>
                          <Button
                            className={classes.filterButton}
                            variant="outlined"
                            color="primary"
                            onClick={hiddenFilter}
                          >
                            <FilterAltIcon />
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                    {/* closing search */}
                    <Box pb={1} className={classes.filterSpaceBox}>
                      <Box pb={1} className={classes.filterBox}>
                        {isHidden && (
                          <Autocomplete
                            disablePortal
                            freeSolo
                            style={{
                              backgroundColor: theme.palette.background.paper,
                            }}
                            options={filterOptions || []}
                            disabled={filterOptions?.length === 0}
                            value={filterFeature || ''}
                            onChange={(event: any, value) => {
                              event?.preventDefault();
                              setFilterFeature(value);
                            }}
                            getOptionLabel={item =>
                              item.label ? item.label : ''
                            }
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Filter by Feature"
                                fullWidth
                                variant="outlined"
                              />
                            )}
                            fullWidth
                          />
                        )}
                      </Box>
                      <Box pb={1} className={classes.filterBox}>
                        {isHidden && (
                          <Autocomplete
                            disablePortal
                            freeSolo
                            style={{
                              backgroundColor: theme.palette.background.paper,
                            }}
                            options={filterVersionOptions || []}
                            disabled={filterVersionOptions?.length === 0}
                            value={filterVName || ''}
                            onChange={(event: any, value) => {
                              event?.preventDefault();
                              setFilterVName(value);
                            }}
                            getOptionLabel={item =>
                              item.label ? item.label : ''
                            }
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Filter by Version Name"
                                fullWidth
                                variant="outlined"
                              />
                            )}
                            fullWidth
                          />
                        )}
                      </Box>
                      <Box pb={1} className={classes.filterBox}>
                        {isHidden && (
                          <TextField
                            fullWidth
                            className={classes.calendarColor}
                            label="Filter by Month and Year"
                            variant="outlined"
                            ref={inputRef}
                            value={releaseDate.value}
                            onChange={(event: React.SyntheticEvent) => {
                              handleReleaseDateChange(event?.target?.value);
                            }}
                            type="month"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              inputProps: { min: '2022-01', max: currentDate },
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box>
                      <Typography
                        variant="h6"
                        component="h6"
                        className={classes.filterTitle}
                      >
                        Versions
                      </Typography>
                      {showedContent.length === 0 ? (
                        <div className={classes.filter}>
                          <Typography>No Release Notes Found</Typography>
                        </div>
                      ) : (
                        showedContent.map((n: any, idx: number) => (
                          <div
                            key={`release-note-${n.id}`}
                            className={`${classes.filter} ${
                              active?.id === n.id && classes.filterActive
                            }`}
                            onClick={() => {
                              setActive(showedContent[idx]);
                            }}
                          >
                            <Typography component="span">{n.vname}</Typography>
                            <Typography
                              component="span"
                              className={classes.rightAlign}
                            >
                              {formatDateMMDDYYYY(n.releasedate)}
                            </Typography>
                            <div className={classes.filterDesc}>
                              <Typography component="span">{n.vnum}</Typography>
                            </div>
                          </div>
                        ))
                      )}
                    </Box>
                    {/* Pagination */}
                    <Grid className={classes.PagePaginationContainer}>
                      <Paper>
                        <TablePagination
                          classes={{
                            root: classes.tablePaginationRoot,
                            actions: classes.tablePaginationActions,
                          }}
                          component="div"
                          page={currentIndex}
                          rowsPerPage={contentPerPage}
                          count={totalCount}
                          onPageChange={onPageChange}
                          rowsPerPageOptions={[]}
                        />
                      </Paper>
                    </Grid>
                    {/* Pagination end */}
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} lg={9}>
                <Paper
                  elevation={2}
                  style={{ minHeight: '100vh' }}
                  className={classes.content}
                >
                  <div className={classes.flex}>
                    <Typography variant="h2" component="span">
                      {active?.vname || ''}
                    </Typography>
                    <Typography
                      variant="h6"
                      component="span"
                      className={`${classes.contentDate}`}
                    >
                      {formatDateMMDDYYYY(active?.releasedate || Date.now())}
                    </Typography>
                  </div>
                  {active?.overview && (
                    <div className={classes.releaseNoteBlock}>
                      <Typography variant="h6" component="h5">
                        Overview
                      </Typography>
                      <Typography component="div">
                        {active?.overview || ''}
                      </Typography>
                    </div>
                  )}
                  {active?.tags && active.tags.length > 0 && (
                    <div className={classes.releaseNoteBlock}>
                      <Typography variant="h6" component="h5">
                        Key Feature Updates
                      </Typography>
                      {active.tags.map((tag: string) => (
                        <Chip
                          color="secondary"
                          size="small"
                          label={tag}
                          key={`key-feature-tag-${tag}`}
                        />
                      ))}
                    </div>
                  )}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: active?.description || '',
                    }}
                    className={classes.releaseNoteBlock}
                  ></div>
                  {isAdmin && active?.additionaldata && (
                    <div className={classes.releaseNoteBlock}>
                      <Typography component="h5" variant="h6">
                        Internal Use
                        <Typography
                          component="span"
                          className={classes.lightText}
                        >
                          {' '}
                          (Visible Only to Admin)
                        </Typography>
                      </Typography>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: active.additionaldata,
                        }}
                      ></div>
                    </div>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </>
        </Grid>
      </Content>
    </PageWithHeader>
  );
};
export default ReleaseNotePage;
