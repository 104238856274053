import React, { useCallback, useEffect, useState } from 'react';
import useDebounce from 'react-use/lib/useDebounce';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import { Select, SelectItem } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { scmAuthApiRef } from '@backstage/integration-react';
import { RepoUrlPickerState } from '../types';
import { useTemplateSecrets } from '../../../SecretsContext';
import { swTemplateGithubApiRef } from '../api/SWTemplateGithubApi';
import { makeStyles } from '@material-ui/core';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../../apis/notificationApi';
import { debounce } from '../utils';
import { useLocation } from 'react-router-dom';
import { useEntity } from '@backstage/plugin-catalog-react';

const useStyles = makeStyles(_theme => ({
  orgSelect: {
    '& div[class^="MuiFormControl-root"]': {
      [_theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    '& p': {
      width: '97%',
      overflow: 'hidden',
      [_theme.breakpoints.down('sm')]: {
        width: '97%',
        overflow: 'hidden',
      },
    },
  },
  projectSelect: {
    transform: 'scale(0.75)',
    transformOrigin: 'top left',
  },
}));

export const GithubRepoPicker = (props: {
  allowedOwners?: string[];
  rawErrors: string[];
  state: RepoUrlPickerState;
  uiSchema: any;
  onChange: (state: RepoUrlPickerState) => void;
  formVisible: boolean;
}) => {
  const { rawErrors, state, onChange, uiSchema, formVisible } = props;
  const { owner, repoName, userSpecifiedBranch } = state;
  const classes = useStyles();
  const [githubToken, setGithubToken] = useState<string | undefined>();
  const [githubUsername, setGithubUsername] = useState<string | undefined>();
  const [userLoading, setUserLoading] = useState<boolean>(true);
  const [repoLoading, setRepoLoading] = useState<boolean>(false);
  const [repoExists, setRepoExists] = useState<boolean>(false);
  const [orgSelectOptions, setOrgSelectOptions] = useState<SelectItem[]>([]);

  const { entity } = useEntity();
  const { setSecrets } = useTemplateSecrets();

  const scmAuthApi = useApi(scmAuthApiRef);
  const githubApi = useApi(swTemplateGithubApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const location = useLocation();

  let templateName = '';
  if (
    location !== undefined &&
    location?.pathname !== undefined &&
    location?.pathname?.length > 3
  ) {
    templateName = location?.pathname?.split('/')?.[3];
  }
  let templateAlias =
    entity?.metadata?.templateAliasForBranchName || templateName || '';

  const getDataFromApi = () => {
    if (githubToken !== null) {
      setUserLoading(true);
      githubApi
        .getUserProfileAndOrgs(`${githubToken}`)
        .then((data: any) => {
          setGithubUsername(data?.owner);
          const options = data?.organizations?.map((o: any) => {
            return { label: o.name, value: o.login };
          });
          setOrgSelectOptions(options || []);
          const firstOption =
            options && options?.length > 0 ? options[0].value : undefined;
          onChange({ ...state, owner: firstOption, organization: firstOption });
          setUserLoading(false);
        })
        .catch((err: any) => {
          setGithubUsername(undefined);
          setUserLoading(false);
          notificationApi.sendNotification({
            message: `Error occurred - ${err?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });
    }
  };

  useEffect(() => {
    if (onChange) {
      onChange({
        owner: githubUsername,
      });
    }
  }, [githubUsername]);

  const getRepositoryInformation = async (owner: string, repoName: string) => {
    if (repoName && owner) {
      setRepoLoading(true);

      try {
        const res = await githubApi.getRepositoryInfo(owner, repoName);

        if (
          res &&
          res?.data.status === 200 &&
          res?.data?.message === 'exists'
        ) {
          setRepoExists(true);
          if (onChange) {
            onChange({
              userSpecifiedBranch: `devx-${templateAlias}-${new Date().getTime()}`,
            });
          }
        } else {
          setRepoExists(false);
          if (onChange) {
            onChange({
              userSpecifiedBranch: undefined,
            });
          }
        }
        setRepoLoading(false);
      } catch {
        setRepoExists(false);
        if (onChange) {
          onChange({
            userSpecifiedBranch: undefined,
          });
        }
        setRepoLoading(false);
      }
    } else {
      // reset errors/branch show hide
      setRepoLoading(false);
      if (onChange) {
        onChange({
          userSpecifiedBranch: undefined,
        });
      }
    }
  };

  const debouncedGetRepoApiCall = useCallback(
    debounce((owner: any, repoName: any) => {
      getRepositoryInformation(owner, repoName);
    }, 1000),
    [],
  );

  useEffect(() => {
    if (repoName && owner) {
      // Check if repository already exists
      debouncedGetRepoApiCall(owner, repoName);
    }
  }, [repoName, owner]);

  useEffect(() => {
    if (githubToken && getDataFromApi) {
      getDataFromApi();
    }
  }, [githubToken]);

  useDebounce(
    async () => {
      const { requestUserCredentials } = uiSchema?.['ui:options'] ?? {};
      if (!requestUserCredentials) {
        return;
      }

      const [host, owner, repoName] = [
        state.host,
        state.owner,
        state.repoName,
        // @ts-ignore
      ].map(encodeURIComponent);

      // user has requested that we use the users credentials
      // so lets grab them using the scmAuthApi and pass through
      // any additional scopes from the ui:options
      const credResponse = await scmAuthApi
        .getCredentials({
          url:`https://${host}/${owner}/${repoName}`,
          additionalScope: {
            repoWrite: true,
            customScopes: {
              ...requestUserCredentials.additionalScopes,
            },
          },
          // @ts-ignore
        })
        .catch(err => {
          onChange({ host: 'Select' });
        });

        
      if (credResponse && credResponse?.token) {
        const { token } = credResponse;
        setGithubToken(token);

        // set the secret using the key provided in the the ui:options for use
        // in the templating the manifest with ${{ secrets[secretsKey] }}
        setSecrets({ [requestUserCredentials.secretsKey]: token });
       
      }
    },
    500,
    [state, uiSchema],
  );

  return (
    <>
      {formVisible ? (
        <>
          <FormControl margin="normal" required>
            <FormLabel className={classes.projectSelect}>Owner</FormLabel>
            <Typography variant="h6">{githubUsername || ''}</Typography>
          </FormControl>
          <FormControl
            margin="normal"
            required
            error={rawErrors?.length > 0 && !owner}
            className={classes.orgSelect}
          >
            <FormLabel className={classes.projectSelect}>
              Destination Org
            </FormLabel>
            <Select
              label=""
              onChange={s => {
                onChange({
                  ...state,
                  owner: String(s),
                  organization: String(s),
                });
              }}
              selected={owner}
              items={orgSelectOptions || []}
              disabled={userLoading}
            />
            <FormHelperText>
              The organization that this repo will belong to. If you do not have
              access to any GitHub organizations, Please contact to admin team
              at !Github@cbre.com
            </FormHelperText>
          </FormControl>
          <FormControl
            margin="normal"
            required
            error={rawErrors?.length > 0 && !repoName && !owner}
          >
            <InputLabel htmlFor="repoNameInput">Repository Name</InputLabel>
            <Input
              id="repoNameInput"
              onChange={e => onChange({ repoName: e.target.value })}
              value={repoName}
              disabled={userLoading}
            />
            <FormHelperText>
              {repoLoading && 'Checking if repository already exists'}
            </FormHelperText>
          </FormControl>

          {repoExists && (
            <FormControl
              margin="normal"
              required
              disabled
              error={
                rawErrors?.length > 0 &&
                !userSpecifiedBranch &&
                !owner &&
                !repoName
              }
            >
              <InputLabel htmlFor="userSpecifiedBranchInput">
                Branch Name
              </InputLabel>
              <Input
                id="userSpecifiedBranchInput"
                onChange={e =>
                  onChange({ userSpecifiedBranch: e.target.value })
                }
                value={userSpecifiedBranch || ''}
                disabled={true}
              />
              <FormHelperText>
                Content will be pushed into this branch
              </FormHelperText>
            </FormControl>
          )}
        </>
      ) : null}
    </>
  );
};
