import type { Transformer } from './transformer';

export const simplifyMkdocsFooter = (): Transformer => {
  return dom => {
    // Remove new mkdocs copyright
    dom.querySelector('.md-footer .md-copyright')?.remove();
    // Remove old mkdocs copyright
    dom.querySelector('.md-footer-copyright')?.remove();
    dom.querySelector('.md-footer')?.remove();
    return dom;
  };
};
