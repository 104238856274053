import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PromptStoreIcon = () => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g xmlns="http://www.w3.org/2000/svg" clip-path="url(#clip0_84932_49901)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.7 12H1.3C0.585 12 0.00649999 12.585 0.00649999 13.3L0 24L1.6 22.4H11.7C12.415 22.4 13 21.815 13 21.1V13.3C13 12.585 12.415 12 11.7 12ZM7.3125 18.1749H5.6875V16.5499H7.3125V18.1749ZM2.4375 18.1749H4.0625V16.5499H2.4375V18.1749ZM10.5625 18.1749H8.9375V16.5499H10.5625V18.1749Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.9545 3.42105V4.78947C23.0864 4.78947 24 5.70632 24 6.84211C24 7.9779 23.0864 8.89474 21.9545 8.89474V11.6316C21.9545 12.3842 21.3409 13 20.5909 13H13.9647C13.7686 11.8766 12.7803 11.0154 11.6 11.0154C11.3945 11.0154 11.211 11.0153 11.0455 11.015V8.89474C9.91364 8.89474 9 7.9779 9 6.84211C9 5.70632 9.91364 4.78947 11.0455 4.78947V3.42105C11.0455 2.66842 11.6591 2.05263 12.4091 2.05263H14.4545C14.4545 0.916842 15.3682 0 16.5 0C17.6318 0 18.5455 0.916842 18.5455 2.05263H20.5909C21.3409 2.05263 21.9545 2.66842 21.9545 3.42105ZM14.4545 5.39035C13.8886 5.39035 13.4318 5.84877 13.4318 6.41667C13.4318 6.98456 13.8886 7.44298 14.4545 7.44298C15.0205 7.44298 15.4773 6.98456 15.4773 6.41667C15.4773 5.84877 15.0205 5.39035 14.4545 5.39035ZM13.7727 9.54604C13.7727 10.4698 15.4773 10.9325 16.5 10.9325C17.5227 10.9325 19.2273 10.4298 19.2273 9.49561C19.2273 8.73846 18.8565 8.87566 18.2101 9.11491C17.762 9.28075 17.1814 9.49562 16.5 9.49562C15.8309 9.49562 15.2591 9.29662 14.8144 9.14187C14.1528 8.91165 13.7727 8.77937 13.7727 9.54604ZM17.5227 6.41667C17.5227 6.98456 17.9795 7.44298 18.5455 7.44298C19.1114 7.44298 19.5682 6.98456 19.5682 6.41667C19.5682 5.84877 19.1114 5.39035 18.5455 5.39035C17.9795 5.39035 17.5227 5.84877 17.5227 6.41667Z"
      />
    </g>
    <defs xmlns="http://www.w3.org/2000/svg">
      <clipPath id="clip0_84932_49901">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default PromptStoreIcon;
