export const POCEntity = {
  metadata: {
    namespace: 'default',
    annotations: {
      'backstage.io/managed-by-location':
        'url:https://devx3-internal-repometadata.s3.us-east-1.amazonaws.com/Software_Templates/internal_python_10JanTest.yaml',
      'backstage.io/managed-by-origin-location':
        'url:https://devx3-internal-repometadata.s3.us-east-1.amazonaws.com/Software_Templates/internal_python_10JanTest.yaml',
      'backstage.io/view-url':
        'https://devx3-internal-repometadata.s3.us-east-1.amazonaws.com/Software_Templates/internal_python_10JanTest.yaml',
    },
    name: 'internal_python_10JanTest',
    title: 'Testing Python microservice template',
    description:
      'Updated desc Software template for generating the new microservice project using python.',
    templateAliasForBranchName: 'python10jan',
    tags: ['recommended', 'python-microservice', 'python', 'django'],
    uid: '0006329a-9cff-4257-ac19-99b45f6315f3',
    etag: '2efad6c77e0126521468cff9dbef60dc49c3912d',
  },
  apiVersion: 'scaffolder.backstage.io/v1beta3',
  kind: 'Template',
  spec: {
    owner: 'group:cbre',
    type: 'service',
    parameters: [
      {
        title: 'Create new project',
        required: ['name', 'apmNumber'],
        properties: {
          name: {
            title: 'Name',
            type: 'string',
            description: 'Unique name of the project',
            maxLength: 100,
            'ui:autofocus': true,
            'ui:options': {
              rows: 5,
            },
          },
          description: {
            title: 'Description',
            type: 'string',
            description: 'Brief description of the project',
            maxLength: 250,
            'ui:autofocus': true,
            'ui:options': {
              rows: 5,
            },
          },
          loggedInUser: {
            title: 'Logged in user',
            type: 'string',
            description: 'Email Id of currently loggedIn user',
            'ui:field': 'DevxUserPicker',
            'ui:widget': 'hidden',
            'ui:backstage': {
              review: {
                show: false,
              },
            },
          },
          apmNumber: {
            title: 'APM Number',
            type: 'string',
            description: 'APM Number of the project',
            'ui:field': 'APMField',
          },
        },
      },
      {
        title: 'Choose a location',
        required: ['repoUrl'],
        properties: {
          repoUrl: {
            title: 'Repository Location',
            type: 'string',
            'ui:field': 'DevxRepoUrlPicker',
            'ui:options': {
              allowedHosts: ['dev.azure.com', 'github.com'],
              requestUserCredentials: {
                secretsKey: 'USER_OAUTH_TOKEN',
              },
            },
          },
        },
      },
    ],
    steps: [
      {
        id: 'fetch-base',
        name: 'Fetch template & Base project',
        action: 'fetch:template',
        input: {
          url: 'https://github.com/CBRE-Shared-Code-Templates/internal_python_10JanTest/tree/main/scaffold',
          values: {
            name: '${{ parameters.name }}',
            description: '${{ parameters.description }}',
          },
          copyWithoutRender: ['**/*.html'],
        },
      },
      {
        id: 'github-existing',
        name: 'Publish to GitHub Organization, into Existing Repository',
        if: "${{ (parameters.repoUrl | parseRepoUrl).host === 'github.com' and (parameters.repoUrl | parseRepoUrl).branch }}",
        action: 'github:repo:push',
        input: {
          allowedHosts: ['github.com'],
          description: '${{ parameters.description }}',
          repoUrl: '${{ parameters.repoUrl }}',
          defaultBranch: '${{ (parameters.repoUrl | parseRepoUrl).branch }}',
          token: '${{ secrets.USER_OAUTH_TOKEN }}',
        },
      },
      {
        id: 'github',
        name: 'Publish to GitHub Organization',
        if: "${{ (parameters.repoUrl | parseRepoUrl).host === 'github.com' and not (parameters.repoUrl | parseRepoUrl).branch }}",
        action: 'publish:github',
        input: {
          allowedHosts: ['github.com'],
          description: '${{ parameters.description }}',
          repoUrl: '${{ parameters.repoUrl }}',
          defaultBranch: 'main',
          token: '${{ secrets.USER_OAUTH_TOKEN }}',
        },
      },
      {
        id: 'azure',
        name: 'Publish To Azure DevOps',
        if: "${{ (parameters.repoUrl | parseRepoUrl).host === 'dev.azure.com' and not (parameters.repoUrl | parseRepoUrl).branch }}",
        action: 'publish:azure',
        input: {
          allowedHosts: ['dev.azure.com'],
          repoUrl: '${{ parameters.repoUrl }}',
          description: '${{ parameters.description }}',
          defaultBranch: 'main',
          token: '${{ secrets.USER_OAUTH_TOKEN }}',
        },
      },
      {
        id: 'azure-existing',
        name: 'Publish To Azure DevOps, into existing repository',
        if: "${{ (parameters.repoUrl | parseRepoUrl).host === 'dev.azure.com' and (parameters.repoUrl | parseRepoUrl).branch }}",
        action: 'devx:azure-existing-repo:push',
        input: {
          allowedHosts: ['dev.azure.com'],
          repoUrl: '${{ parameters.repoUrl }}',
          description: '${{ parameters.description }}',
          branchName: '${{ (parameters.repoUrl | parseRepoUrl).branch }}',
          token: '${{ secrets.USER_OAUTH_TOKEN }}',
        },
      },
      {
        id: 'collect-analytics-data',
        name: 'Collect Analytics',
        action: 'devx:template-analytics',
        input: {
          parameters: '${{ parameters | dump | string }}',
          projectSourceLocation:
            'https://github.com/CBRE-Shared-Code-Templates/internal_python_10JanTest/tree/main/scaffold',
          finalRepoLocation: '${{ steps | getOutputRepoLocation }}',
          token: '${{ secrets.AUTH_BACKEND_API_ACCESS_TOKEN }}',
        },
      },
    ],
    output: {
      remoteUrl: '${{ steps | getOutputRepoLocation }}',
    },
  },
  relations: [
    {
      type: 'ownedBy',
      targetRef: 'group:default/cbre',
      target: {
        kind: 'group',
        namespace: 'default',
        name: 'cbre',
      },
    },
  ],
};
