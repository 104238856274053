/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, InputBase, Paper } from '@material-ui/core';
import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import ClearButton from '@material-ui/icons/Clear';
import { useStyles } from '../styles';

const SearchBar = ({
  inputText,
  placeHolderText,
  onTextChanged,
}: {
  inputText?: string;
  placeHolderText?: string;
  onTextChanged: Function;
}) => {
  const classes = useStyles();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onTextChanged(event?.target?.value);
  };

  return (
    <Paper component="form" variant="outlined" className={classes.searchBar}>
      <InputBase
        className={classes.searchBarText}
        placeholder={placeHolderText}
        value={inputText}
        inputProps={{ 'aria-label': 'search prompts' }}
        onChange={handleSearchChange}
        autoFocus
        onKeyDown={ev => {
          if (ev.key === 'Enter') {
            ev.preventDefault();
          }
        }}
      />
      {inputText ? (
        <IconButton
          aria-label="clear"
          onClick={() => {
            onTextChanged('');
            return;
          }}
        >
          <ClearButton />
        </IconButton>
      ) : (
        <IconButton
          type="button"
          aria-label="search"
          onClick={() => onTextChanged(inputText)}
        >
          <SearchIcon className={classes.searchIcon} />
        </IconButton>
      )}
    </Paper>
  );
};

export default SearchBar;
