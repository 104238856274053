import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core';
import { Circle } from 'rc-progress';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
}));

export const Percentage = ({ value }: { value?: string }) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();

  return (
    <Circle
      strokeLinecap="butt"
      percent={+(value || 0)}
      strokeWidth={16}
      strokeColor={theme.palette.status.ok}
      trailColor={theme.palette.status.error}
      trailWidth={16}
      className={classes.root}
    />
  );
};
