import { PromotionContext } from '../../../../providers/PromotionDataProvider';
import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import REGISTER_API_CONSTANTS from '../Constants';
import { ViewDefinitionButton } from '../ViewDefinitionButton';
import RequestType from './RequestType';
import { useStyles } from './styles';
import Servers from './Servers';
import Info from './Info';

const SwaggerApiForm = (props: any) => {
  const myContext = useContext(PromotionContext);
  const { buttonRef } = props;
  const classes = useStyles();

  /* Clicked variable */
  const [clicked, setClicked] = useState(0);

  /* variables for paths and swagger json */
  const [paths, setPaths] = useState<any>({});
  const [swaggerJson, setSwaggerJson] = useState<any>({});
  const [swaggerError, setSwaggerError] = useState(false);

  /* Handle button clicked */
  const handleButtonClicked = (clickCounter: any) => {
    setClicked(clickCounter);
  };

  const [errors, setErrors] = useState({
    info: false,
    servers: false,
    paths: false,
  });

  /* Error Handler */
  const handleError = (name: any, hasError: boolean) => {
    setErrors((prevState: any) => {
      return {
        ...prevState,
        [name]: hasError,
      };
    });
  };

  /* Errors from Sub components */
  useEffect(() => {
    const swaggerHasError = !Object.values(errors).every(
      (x: any) => x === false,
    );
    setSwaggerError(swaggerHasError);
  }, [errors]);

  /* Handle the Path changes */
  const handlePaths = (data: any) => {
    setPaths(data);
  };
  const [info, setInfo] = useState<any>({});

  /* Handle info changes */
  const handleChangeInfo = (data: any) => {
    setInfo(data);
  };

  const [servers, setServers] = useState<any>([]);

  /* Handle servers changes */
  const handleChangeServers = (data: any) => {
    setServers(data);
  };

  useEffect(() => {
    const finalJson = {
      openapi: '3.0.0',
      info: info,
      components: {
        securitySchemes: {
          BearerAuth: {
            type: 'http',
            scheme: 'bearer',
          },
          BasicAuth: {
            type: 'http',
            scheme: 'basic',
          },
        },
      },
      security: [
        {
          BasicAuth: [],
        },
        { BearerAuth: [] },
      ],
      servers: servers,
      paths: paths,
    };
    setSwaggerJson(finalJson);
  }, [info, paths, servers]);

  return (
    /* Swagger Api Form Component */
    <>
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className={`${classes.swaggerTitle} ${classes.swagger}`}>
            {REGISTER_API_CONSTANTS.generateSwagger.generateApiTitle}
          </div>
        </Grid>
      </Grid>

      {/* Info Section */}
      <Info
        onChange={handleChangeInfo}
        onError={handleError}
        clicked={clicked}
      />

      {/* servers Section */}
      <Servers
        onChange={handleChangeServers}
        clicked={clicked}
        onError={handleError}
      />

      <Grid container direction="row" spacing={3}>
        {/* Request Type Component */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <RequestType
            onPathsChanged={handlePaths}
            clicked={clicked}
            onError={handleError}
          />
        </Grid>

        {/* generate btn */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ViewDefinitionButton
            className={`${classes.successbtn} ${classes.columnDirection}`}
            variant="contained"
            swaggerJson={swaggerJson}
            swaggerError={swaggerError}
            clickedCounter={handleButtonClicked}
            ref={buttonRef}
          >
            {myContext?.toggleSwaggerButton ? 'Refresh' : 'Generate'}
          </ViewDefinitionButton>
        </Grid>
      </Grid>
    </>
  );
};
export default SwaggerApiForm;
