/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../../../apis/notificationApi';
import { toolsProvisioningApiRef } from '../../../../../../apis/toolsProvisioningApi';
import { useEffect, useState } from 'react';
import { IPTRun } from '../../../../interfaces';

export const usePTRuns = (apmNumber: string, shouldcall: boolean) => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const toolsprovisioningApi = useApi(toolsProvisioningApiRef);

  const [runs, setRuns] = useState<IPTRun[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [callCounter, setCallCounter] = useState<number>(Number(shouldcall));

  const getAllRuns = async () => {
    setLoading(true);
    if (toolsprovisioningApi) {
      try {
        const response = await toolsprovisioningApi.getPTRuns(apmNumber);
        setLoading(false);
        if (response) {
          setRuns(response);
        }
      } catch (error) {
        setLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
    return null;
  };

  const refreshRuns = () => {
    setCallCounter(prevState => prevState + 1);
  };

  const stopRun = async (immutableId: string) => {
    setLoading(true);
    if (toolsprovisioningApi) {
      try {
        const response = await toolsprovisioningApi.stopPTRun(immutableId);
        if (response) {
          setLoading(false);

          notificationApi.sendNotification({
            message: response?.reason || 'Run queued',
            disapperAfterMs: 2500,
            severity: 'success',
          });
        }
      } catch (error) {
        setLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
    return null;
  };

  const downloadRunResults = async (immutableId: string) => {
    setLoading(true);
    if (toolsprovisioningApi) {
      try {
        const response = await toolsprovisioningApi.downloadRunResults(
          immutableId,
        );
        if (response) {
          setLoading(false);

          notificationApi.sendNotification({
            message: response?.reason || 'Run queued',
            disapperAfterMs: 2500,
            severity: 'success',
          });
        }
      } catch (error) {
        setLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
    return null;
  };

  useEffect(() => {
    if (callCounter) getAllRuns();
  }, [callCounter]);

  return {
    runs,
    refreshRuns,
    stopRun,
    downloadRunResults,
    loading,
  } as const;
};
