import { Entity } from '@backstage/catalog-model';
import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import { NOT_APPROVED } from './constant';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useMemo, useState } from 'react';
import { useEntityList, EntityFilter } from '@backstage/plugin-catalog-react';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class EntityLifecycleFilter implements EntityFilter {
  constructor(readonly values: string[]) {}

  filterEntity(entity: Entity): boolean {
    return this.values.some(v => (entity.metadata.tags ?? []).includes(v));
  }

  toQueryValue(): string[] {
    return this.values;
  }
}

export const EntityTagPicker = () => {
  const { updateFilters, backendEntities, filters, queryParameters } =
    useEntityList();

  const queryParamTags = [queryParameters.tags]
    .flat()
    .filter(Boolean) as string[];
  const [selectedTags, setSelectedTags] = useState(
    queryParamTags.length ? queryParamTags : filters.tags?.values ?? [],
  );

  useEffect(() => {
    updateFilters({
      tags: selectedTags.length
        ? new EntityLifecycleFilter(selectedTags)
        : undefined,
    });
  }, [selectedTags, updateFilters]);

  const availableTags = useMemo(
    () =>
      [
        ...new Set(
          backendEntities
            .filter(
              (e: any) =>
                e?.metadata?.devxstate?.toLowerCase() !==
                NOT_APPROVED.toLowerCase(),
            )
            .flatMap((e: Entity) => e.metadata?.tags)
            .filter(Boolean) as string[],
        ),
      ].sort(),
    [backendEntities],
  );

  if (!availableTags.length) return null;

  return (
    <Box pb={1} pt={1}>
      <Typography variant="button">Tags</Typography>
      <Autocomplete
        aria-label="Lifecycle"
        multiple
        options={availableTags}
        value={selectedTags}
        onChange={(_: object, value: string[]) => setSelectedTags(value)}
        renderOption={(option, { selected }) => (
          <FormControlLabel
            control={
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                checked={selected}
              />
            }
            label={option}
          />
        )}
        size="small"
        popupIcon={<ExpandMoreIcon data-testid="tag-picker-expand" />}
        renderInput={params => <TextField {...params} variant="outlined" />}
      />
    </Box>
  );
};
