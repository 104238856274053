import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useCallback, useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useApi } from '@backstage/core-plugin-api';
import { adminApiRef } from '../../../../apis/adminApi';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogBox: {
      position: 'relative',
    },
    dialogContent: {
      width: '500px',
    },
    closeButton: {
      position: 'absolute',
      top: '10px',
      right: '10px',
    },
    displayNone: {
      display: 'none',
    },
  }),
);

const PromptCreate = ({ isOpen, onClose, onConfirm, dialogProps }: any) => {
  const classes = useStyles();

  const [data, setData] = useState<any>(null);
  const [addAdminList, setAddAdminList] = useState<any>('');
  const [open, setOpen] = useState(false);
  const [loading, setloading] = useState<boolean>(false);
  const [usersLoading, setUsersLoading] = useState<boolean>(false);

  const [userList, setuserList] = useState<any>([]);
  const adminApi = useApi(adminApiRef);

  const getuserList = async (user: any) => {
    try {
      setUsersLoading(true);

      const data = await adminApi.getUserList(user);
      const options = data?.value || [];
      const transFormList =
        options &&
        options.length &&
        options.map((item: any) => {
          return { user: item?.mail };
        });

      setuserList(transFormList || []);
      setUsersLoading(false);
      return data;
    } catch (error) {
      setuserList([]);
      setUsersLoading(false);

      return error;
    }
  };

  const debounce = (fn: any, delay: any) => {
    let timeoutId: any;
    // @ts-expect-error
    return function (...args) {
      clearTimeout(timeoutId);
      // @ts-expect-error
      timeoutId = setTimeout(() => fn.apply(this, args), delay);
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCallback = useCallback(
    debounce((value: any) => {
      getuserList(value);
    }, 1000),
    [],
  );

  const onInputChange = async (e: any) => {
    if (e?.target?.value?.length > 2) {
      debounceCallback(e.target.value);
    }
  };

  useEffect(() => {
    if (dialogProps.fields) {
      const f: any = {};
      dialogProps.fields.forEach((a: any) => {
        f[a.fieldName] = a.value ? a.value : '';
      });
      setData(f);
    }
  }, [dialogProps.fields]);

  const onChange = (fieldName: string, e: any, value: any) => {
    e?.preventDefault();
    setData({
      ...data,
      [fieldName]: value,
    });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      className={classes.dialogBox}
      fullWidth={false}
    >
      <DialogTitle id="dialog-title">
        {dialogProps.title}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {dialogProps.description && (
          <Grid container>
            <Grid item xs={12}>
              <Typography>{dialogProps.description}</Typography>
            </Grid>
          </Grid>
        )}
        {dialogProps.fields && data && (
          <Grid container>
            {dialogProps.fields.map((f: any, idx: number) => (
              <Grid
                item
                xs={12}
                // @ts-ignore
                className={f.type === 'hidden' && classes.displayNone}
              >
                {f.type === 'text' && (
                  <TextField
                    key={`field-${idx}`}
                    value={data[f.fieldName]}
                    label={f.label}
                    variant="outlined"
                    fullWidth
                    maxRows={f.maxRow || 1}
                    minRows={f.minRow || 1}
                    size="small"
                    onChange={event => {
                      const newData = { ...data };
                      setData({
                        ...newData,
                        [f.fieldName]: event.target.value,
                      });
                    }}
                  />
                )}
                {f.type === 'user' && (
                  <Autocomplete
                    multiple
                    freeSolo
                    id="cbre-user-list"
                    data-testid="cbre-user-list"
                    open={open}
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    key="Prompt-Domain-Admin"
                    getOptionSelected={(option, value) =>
                      option?.user === value?.user
                    }
                    value={data[f.fieldName]}
                    getOptionLabel={option =>
                      (option && option?.user) || 'user'
                    }
                    options={userList}
                    loading={usersLoading}
                    onInputChange={e => onInputChange(e)}
                    filterOptions={options => options}
                    onChange={(e: any, value: any) =>
                      onChange(f.fieldName, e, value)
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        data-testid="cbre-user-list-input"
                        label={f.label}
                        InputProps={{
                          ...params.InputProps,
                          onKeyDown: e => {
                            if (e.key === 'Enter') {
                              e.stopPropagation();
                            }
                          },
                          endAdornment: (
                            <React.Fragment>
                              {usersLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        )}
        {loading ? (
          <Grid container>
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          </Grid>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          data-testid="no-btn"
          variant="contained"
          autoFocus
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={async () => {
            setloading(true);
            onConfirm(data);
          }}
          data-testid="yes-btn"
          color="primary"
          variant="contained"
          disabled={loading}
        >
          {dialogProps.fields ? 'Save' : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PromptCreate;
