import {
  createStyles,
  makeStyles,
  Theme,
  WithStyles,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { useStarredEntities } from '@backstage/plugin-catalog-react';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import { useGetImage } from './useGetImage';
import noImage from '../../../../assest/no-image.jpg';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { useGetLikes } from '../../hooks/useGetLikes';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import ToggleIconButton from '../../../common/ToggleIconButton';

export type ItemCardHeaderClassKey = 'root';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.common.white,
      backgroundImage: `url(../../../../assest/angular-template.png)`,
      backgroundPosition: 0,
      backgroundSize: 'inherit',
      position: 'relative',
      margin: '-8px 0px',
      [theme.breakpoints.up('md')]: {
        margin: '0px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
        verticalAlign: 'middle',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    },
    templateLogoImages: {
      width: '100%',
      maxHeight: '150px',
      [theme.breakpoints.up('md')]: {
        maxHeight: 'auto',
      },
    },
    title: {
      position: 'absolute',
      zIndex: 100,
      top: '10px',
      right: '10px',
      textShadow: '0 0 5px black',
    },
    subtitle: {
      position: 'absolute',
      zIndex: 100,
      top: '10px',
      left: '10px',
      textShadow: '0 0 5px black',
    },
    starButton: {
      position: 'absolute',
      top: '10px',
      right: '5px',
      zIndex: 10,
      padding: '0',
    },
    likeButton: {
      position: 'absolute',
      top: '10px',
      right: '30px',
      zIndex: 10,
      padding: '0',
    },
  });

const useStyles = makeStyles(styles, { name: 'BackstageItemCardHeader' });

export type ItemCardHeaderProps = Partial<WithStyles<typeof styles>> & {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
  image?: string | undefined;
  template: any;
  likesCount?: number;
  onLoadCall: boolean;
};

export function ItemCardHeader(props: ItemCardHeaderProps) {
  const {
    title,
    subtitle,
    children,
    image,
    template,
    likesCount = 0,
    onLoadCall = false,
  } = props;
  const classes = useStyles(props);
  const { isStarredEntity, toggleStarredEntity } = useStarredEntities();

  const [imageData] = useGetImage(image);

  const thumbsUpTemplateName = `thumbsUp:${stringifyEntityRef(template)}/`;

  const [startCall, setStartCall] = useState(onLoadCall);

  const [finalLikesCount, setFinalLikesCount] = useState(0);

  const [templateLikesCount] = useGetLikes(
    thumbsUpTemplateName,
    startCall,
    isStarredEntity(thumbsUpTemplateName),
  );

  useEffect(() => {
    setFinalLikesCount(likesCount);
  }, [likesCount]);

  useEffect(() => {
    setFinalLikesCount(templateLikesCount);
  }, [templateLikesCount]);

  const handleThumbsup = () => {
    setStartCall(true);
    toggleStarredEntity(thumbsUpTemplateName);
  };

  return (
    <div className={classes.root}>
      {imageData && imageData?.data ? (
        <img
          src={`data:${imageData?.headers['content-type']};base64,${imageData?.data}`}
          alt="software-Template"
          className={classes.templateLogoImages}
        />
      ) : (
        <img
          src={noImage}
          alt="software-Template"
          className={classes.templateLogoImages}
        />
      )}
      {subtitle && (
        <Typography
          variant="subtitle2"
          className={classes.subtitle}
          component="h3"
        >
          {subtitle}
        </Typography>
      )}
      {title && (
        <Typography variant="h6" className={classes.title} component="h4">
          {title}
        </Typography>
      )}
      <ToggleIconButton
        label="Thumbs Up"
        outlineIcon={<ThumbUpOutlinedIcon />}
        filledIcon={<ThumbUpIcon />}
        isChecked={isStarredEntity(thumbsUpTemplateName) ? true : false}
        onClick={handleThumbsup}
        classes={{ iconButton: classes.likeButton }}
        count={finalLikesCount}
      />

      {/* {isStarredEntity(template) ? (
        <BookmarkIcon
          className={classes.starButton}
          onClick={() => {
            toggleStarredEntity(template);
          }}
        />
      ) : (
        <BookmarkBorderOutlinedIcon
          className={classes.starButton}
          onClick={() => {
            toggleStarredEntity(template);
          }}
        />
      )} */}

      <ToggleIconButton
        label="Bookmark"
        outlineIcon={<BookmarkBorderOutlinedIcon />}
        filledIcon={<BookmarkIcon />}
        isChecked={isStarredEntity(template) ? true : false}
        onClick={() => toggleStarredEntity(template)}
        classes={{ iconButton: classes.starButton }}
      />

      {children}
    </div>
  );
}
