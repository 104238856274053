import { Button } from '@material-ui/core';
import ReplyIcon from '@material-ui/icons/Reply';
import React from 'react';
import * as history from 'history';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();
  const hist = history.createBrowserHistory();
  const doesAnyHistoryEntryExist = hist.location.key !== 'default';

  const onClickGoBackInHistory = () => {
    if (doesAnyHistoryEntryExist) {
      hist.back();
    } else {
      navigate('/');
    }
  };

  return (
    <>
      <Button onClick={onClickGoBackInHistory} color="primary">
        <ReplyIcon /> Back
      </Button>
    </>
  );
};
export default BackButton;
