import React from 'react';
import { TableColumn, OverflowTooltip } from '@backstage/core-components';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import { Link } from 'react-router-dom';
import { dateFormat } from '../../Promotions/dateFormat';
import PromotionImage from '../../Promotions/PromotionImage';
import noImage from '../../../assest/no-image.jpg';

export type EntityRow = {
  promotionId: string;
  title: string;
  orderID: string;
  description: string;
  templateType: string;
  assetURL: string;
  isActive: boolean;
  creationDate: string;
  relatedURL: string;
};

export function createIdColumn(): TableColumn<EntityRow> {
  return {
    title: 'promotionId',
    field: 'promotionId',
    render: ({ promotionId }) => promotionId,
  };
}

export function createOrderColumn(): TableColumn<EntityRow> {
  return {
    title: 'Order',
    field: 'orderID',
    render: ({ orderID }) => orderID,
  };
}
export function createTitleColumn(): TableColumn<EntityRow> {
  return {
    title: 'Title',
    field: 'title',
    render: ({ title, promotionId }) => {
      return (
        <Link to={`/admin/manage-promotions/${title}/${promotionId}`}>
          {title}
        </Link>
      );
    },
  };
}

export function createDescriptionColumn(): TableColumn<EntityRow> {
  return {
    title: 'Description',
    field: 'description',
    render: ({ description }) => (
      <OverflowTooltip
        title="for more go to the promotion detail page.."
        text={description}
        placement="bottom-start"
        line={2}
      />
    ),
  };
}

export function createTypeColumn(): TableColumn<EntityRow> {
  return {
    title: 'Type',
    field: 'templateType',
    render: ({ templateType }) => templateType,
  };
}

export function createAssestsUrlColumn(): TableColumn<EntityRow> {
  return {
    title: 'Asset',
    field: 'assetURL',

    render: ({ promotionId, title, assetURL }) => {
      return (
        <>
          {assetURL !== '' ? (
            <PromotionImage
              promotionId={promotionId}
              assetURL={assetURL}
              title={title}
              className=""
              altText="Asset Url"
              style={{ width: '36px', height: '36px' }}
            />
          ) : (
            <img src={noImage} style={{ width: '36px', height: '36px' }} />
          )}
        </>
      );
    },
  };
}

export function createIsActiveColumn(): TableColumn<EntityRow> {
  return {
    title: 'Active',
    field: 'isActive',
    render: ({ isActive }) =>
      isActive ? <CheckCircleOutlineIcon /> : <CancelIcon />,
  };
}

export function createRelatedUrlColumn(): TableColumn<EntityRow> {
  return {
    title: 'Related Url',
    field: 'relatedURL',
    render: ({ relatedURL }) => (
      <OverflowTooltip
        title={relatedURL}
        text={relatedURL}
        placement="bottom-start"
        line={2}
      ></OverflowTooltip>
    ),
  };
}
export function createCreationDateColumn(): TableColumn<EntityRow> {
  return {
    title: 'Creation Date',
    field: 'creationDate',
    render: ({ creationDate }) => dateFormat(creationDate),
  };
}
