import React, { useState, useContext, useEffect } from 'react';
import { Content, PageWithHeader, Progress } from '@backstage/core-components';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Grid, LinearProgress } from '@material-ui/core';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { useMatch } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { AboutCard } from './AboutCard';
import { SegmentCard } from './SegmentCard';
import { ApiButtonSaveCancel } from './ApiButtonSaveCancel';
import useAuthHeader from '../../utils/useAuthHeader';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import BackButton from '../../utils/backButton';
import { AuthContext } from '../../../providers/AuthProvider';
import { entityConversionApiRef } from '../../../apis/entityConversionApi';
import { DevxBreadCrumb } from '../../common/BreadcrumbsNav/DevxBreadCrumb';
import { DEFAULT_NAMESPACE } from '@backstage/catalog-model';
import { capitalizeFirstLetter } from '../../home/allAssets/util';

const useStyles1 = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: '#fff',
      border: '2px solid #000',
      boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%)',
      padding: '16px 32px 24px;',
      minHeight: 200,
    },
    apiEditPage: {
      ['@media (max-width:920px)']: {
        overflowX: 'hidden',
      },
    },
  }),
);
//TODO: clean up setState function as only 
export const ApiEditPage = () => {
  const baseUrl = useApi(configApiRef).getString('backend.baseUrl');
  const entityConversionApi = useApi(entityConversionApiRef);
  const [_authHeader] = useAuthHeader();
  const { name, kind } = useParams();

  const [defaultEntity, setDefaultEntity] = useState();
  const [defaultTags, setTags] = useState([]);
  const [apiLifecycle, setApiLifecycle] = useState();
  const [apiLOB, setApiLOB] = useState([]);
  const [apiService, setApiService] = useState([]);
  const [apiVersion, setApiVersion] = useState();
  const [sourceCodeLink, setSourceCodeLink] = useState();
  const [apiSegment, setApiSegment] = useState();
  const [apiRegion, setApiRegion] = useState([]);
  const [apiCountry, setApiCountry] = useState([]);
  const [apiCatagory, setApiCatagory] = useState();
  const [apiSupportEmail, setApiSupportEmail] = useState();
  const [apiApmId, setApiApmId] = useState();
  const [lastUpdatedDate, setLastUpdatedDate] = useState();
  const [apiReusable, setApiReusable] = useState();
  const [apiPerformance, setApiPerformance] = useState();
  const [open, setOpen] = useState(false);
  const [isButtonDisable, setIsButtonDisabled] = useState(true);
  const [isPending, setIsPending] = useState(false);
  const [getStatus, setGetStatus] = useState();
  const [defaultEntityDescription, setDefaultEntityDescription] = useState('');

  const { profile, profEmail, isAdmin } = useContext(AuthContext);
  const ownerName = profEmail?.split('@')?.[0];

  const { value, error } = useAsync(async () => {
    if (_authHeader) {
      const response = await entityConversionApi.getData(name, kind);

      const data = await response?.data;
      let apiLOB = data?.metadata?.apiLOB;
      let service = data?.metadata?.apiservice;
      if (data?.metadata?.assetstore && data?.metadata?.assetstore.toLowerCase() === 'wso2') {
        apiLOB = (data?.metadata?.apiLOB && data?.metadata?.apiLOB[0] !== '') ? data?.metadata?.apiLOB[0].split(',') : [];
        service = (data?.metadata?.apiservice && data?.metadata?.apiservice[0] !== '') ? data?.metadata?.apiservice[0].split(',') : [];
      }

      setDefaultEntity(data);
      setTags(data?.metadata?.tags || []);
      setApiLifecycle(data?.spec?.lifecycle || '');
      setApiVersion(data?.metadata?.apiversion || '');
      setApiSegment(data?.metadata?.apisegment || '');
      setApiReusable(data?.spec?.reusable || '');
      setApiCatagory(data?.metadata?.apiCategory || '');
      setApiSupportEmail(data?.spec?.supportEmail || '');
      setApiPerformance(data?.metadata?.apiperformance || '');
      setSourceCodeLink('no data');
      setLastUpdatedDate(data?.metadata?.creationTimestamp || 'no data object');
      setApiApmId(data?.metadata?.apmid || '');
      setApiLOB(apiLOB);
      setApiRegion(data?.metadata?.apiregions || []);
      setApiService(service);
      setApiCountry(data?.metadata?.apicountry || 'no data');
      setDefaultEntityDescription(
        data?.metadata?.description || 'No Description',
      );
      return data;
    }
    return null;
  }, [_authHeader, kind, baseUrl, name]);

  const aboutcardprops = {
    defaultTags: defaultTags,
    apiLifecycle: apiLifecycle,
    apiLOB: apiLOB,
    apiService: apiService,
    apiVersion: apiVersion,
    sourceCodeLink: sourceCodeLink,
    defaultEntity: defaultEntity,
    setIsButtonDisabled: setIsButtonDisabled,
    setTags: setTags,
    setApiLifecycle: setApiLifecycle,
    setApiLOB: setApiLOB,
    setApiService: setApiService,
    setSourceCodeLink: setSourceCodeLink,
    setApiVersion: setApiVersion,
    setDefaultEntityDescription: setDefaultEntityDescription,
    defaultEntityDescription: defaultEntityDescription,
    apiSupportEmail,
    setApiSupportEmail,
    editable: defaultEntity?.metadata?.assetstore && defaultEntity?.metadata?.assetstore.toLowerCase() !== 'wso2'
  };
  
  const segmentcardprops = {
    defaultEntity: defaultEntity,
    apiSegment: apiSegment,
    apiRegion: apiRegion,
    apiCountry: apiCountry,
    apiCatagory: apiCatagory,
    apiApmID: apiApmId,
    lastUpdatedDate: lastUpdatedDate,
    apiReusable: apiReusable,
    apiPerformance: apiPerformance,
    setIsButtonDisabled: setIsButtonDisabled,
    setApiSegment: setApiSegment,
    setApiRegion: setApiRegion,
    setApiCountry: setApiCountry,
    setApiReusable: setApiReusable,
    setApiCatagory: setApiCatagory,
    setApiApmId: setApiApmId,
    setLastUpdatedDate: setLastUpdatedDate,
    setApiPerformance: setApiPerformance,
    isWso2: defaultEntity?.metadata?.assetstore && defaultEntity?.metadata?.assetstore.toLowerCase() === 'wso2'
  };

  const classes1 = useStyles1();

  const ButtonSaveCloseProps = {
    isPending: isPending,
    defaultEntity: defaultEntity,
    apiLOB: (defaultEntity?.metadata?.assetstore && defaultEntity?.metadata?.assetstore.toLowerCase() === 'wso2') ? [apiLOB.join(',')] : apiLOB,
    defaultTags: defaultTags,
    apiCatagory: apiCatagory,
    apiService: (defaultEntity?.metadata?.assetstore && defaultEntity?.metadata?.assetstore.toLowerCase() === 'wso2') ? [apiService.join(',')] : apiService,
    apiRegion: apiRegion,
    apiSegment: apiSegment,
    apiApmId: apiApmId,
    apiLifecycle: apiLifecycle,
    apiCountry: apiCountry,
    isButtonDisable: isButtonDisable,
    description: defaultEntityDescription,
    name: name,
    kind: kind,
    baseUrl: baseUrl,
    setGetStatus: setGetStatus,
    setDefaultEntity: setDefaultEntity,
    setOpen: setOpen,
    setIsPending: setIsPending,
    setIsButtonDisabled: setIsButtonDisabled,
    apiSupportEmail,
  };

  if (
    defaultEntity &&
    defaultEntity?.spec?.owner?.replace('user:', '')?.toLowerCase() !==
      ownerName?.toLowerCase() &&
    defaultEntity?.spec?.techowner?.replace('user:', '')?.toLowerCase() !==
      ownerName?.toLowerCase() &&
    !isAdmin
  ) {
    window.location.replace('/');
  }

  const mountedOnAPIPath = useMatch('/API/:namespace/:kind/:name/api-edit');
  const mountedOnCatalogPath = useMatch(
    '/catalog/:namespace/:kind/:name/api-edit',
  );

  let routes = [
    {
      type: 'link',
      link: '/',
      text: 'Home',
    },
  ];
  if (
    mountedOnAPIPath !== undefined &&
    mountedOnAPIPath?.pathname !== undefined
  ) {
    routes = [
      ...routes,
      {
        type: 'link',
        link: '/API',
        text: 'APIs',
      },
      {
        type: 'link',
        link: `/API/${DEFAULT_NAMESPACE}/${kind}/${name}`,
        text: capitalizeFirstLetter(name),
      },
      {
        type: 'text',
        link: '',
        text: `Edit Api - ${capitalizeFirstLetter(name)}`,
      },
    ];
  } else if (
    mountedOnCatalogPath !== undefined &&
    mountedOnCatalogPath?.pathname !== undefined
  ) {
    routes = [
      ...routes,
      {
        type: 'link',
        link: '/catalog',
        text: 'Catalog',
      },
      {
        type: 'link',
        link: `/catalog/${DEFAULT_NAMESPACE}/${kind}/${name}`,
        text: capitalizeFirstLetter(name),
      },
      {
        type: 'text',
        link: '',
        text: `Edit Api - ${capitalizeFirstLetter(name)}`,
      },
    ];
  }

  if (!_authHeader) {
    return <Progress />;
  }

  if (error) {
    console.log(error)
  }

  if (
    defaultEntity &&
    (defaultEntity?.spec?.owner?.replace('user:', '')?.toLowerCase() !==
      ownerName?.toLowerCase() ||
      defaultEntity?.spec?.techowner?.replace('user:', '')?.toLowerCase() !==
        ownerName?.toLowerCase() ||
      isAdmin)
  ) {
    return (
      <PageWithHeader
        title={`Edit Api - ${name}`}
        themeId="home"
        subtitle={
          <>
            <DevxBreadCrumb routes={routes} />
          </>
        }
      >
        <Content className={classes1.apiEditPage}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <BackButton />
              <HelpComponent helpUrl={HELP_URL.EDIT_APIs} />
            </Grid>
            <Grid item>
              {!value && !error && <LinearProgress />}
              <AboutCard {...aboutcardprops} />
            </Grid>
            <Grid item>
              {!value && !error && <LinearProgress />}
              <SegmentCard {...segmentcardprops} />
            </Grid>
          </Grid>

          <ApiButtonSaveCancel {...ButtonSaveCloseProps} />
        </Content>
      </PageWithHeader>
    );
  }
  return <Progress />;
};
