import React from 'react';

const QuestionVoteReceived = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.375 24.8333H10.625V27H8.375V24.8333ZM9.5 16.1667C10.7375 16.1667 11.75 17.1417 11.75 18.3333C11.75 20.5 8.375 20.2292 8.375 23.75H10.625C10.625 21.3125 14 21.0417 14 18.3333C14 15.9392 11.9862 14 9.5 14C7.01375 14 5 15.9392 5 18.3333H7.25C7.25 17.1417 8.2625 16.1667 9.5 16.1667Z"
        fill="#17E88F"
      />
      <g clipPath="url(#clip0_72450_60615)">
        <path
          d="M14.8325 14.595H17.1225V7.725H14.8325V14.595ZM27.4275 8.29749C27.4275 7.66775 26.9122 7.1525 26.2825 7.1525H22.67L23.2139 4.53618L23.2311 4.35298C23.2311 4.11825 23.1338 3.9007 22.9792 3.74613L22.3723 3.145L18.6053 6.91777C18.3935 7.12387 18.2675 7.41012 18.2675 7.725V13.45C18.2675 14.0797 18.7828 14.595 19.4125 14.595H24.565C25.0402 14.595 25.4466 14.3087 25.6184 13.8965L27.3473 9.86042C27.3989 9.72874 27.4275 9.59134 27.4275 9.44249V8.29749Z"
          fill="#17E88F"
        />
      </g>
      <defs>
        <clipPath id="clip0_72450_60615">
          <rect
            width="13.74"
            height="13.74"
            fill="white"
            transform="translate(14.26 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default QuestionVoteReceived;
