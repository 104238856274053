import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /* Common styles */
    pagePadding: {
      padding: theme.spacing(3),
    },
    gridAreaPageHeader: {
      gridArea: 'pageHeader',
    },
    flex: {
      display: 'flex',
    },
    flexStart: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    flexSpacebetween: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    centerAlign: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    flexCenter: {
      display: 'flex',
      verticalAlign: 'middle',
      alignItems: 'center',
    },
    heading: {
      display: 'flex',
      alignItems: 'center',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '32px',
    },
    outlined: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '4px',
    },
    btnSpacing: {
      marginRight: theme.spacing(2),
    },
    backGroundColor: {
      backgroundColor: theme.palette.background.paper,
      '& input': {
        padding: `${theme.spacing(1.3125)}px ${theme.spacing(1.75)}px`,
      },
    },

    gap1: {
      gap: theme.spacing(1),
    },

    gap2: {
      gap: theme.spacing(2),
    },

    button: {
      textTransform: 'none',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    /* Common styles -- Ends */

    /* PromptStoreHeader Styles */
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: theme.spacing(2.5),
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
    },
    headerContent: {
      display: 'flex',
      flexDirection: 'column',
      lineHeight: '133%',
    },
    subtitle: {
      color: theme.palette.primary.main,
      fontWeight: 400,
      letterSpacing: '0.06px',
      fontFamily: 'Calibre-R, sans-serif',
    },
    title: {
      color: theme.palette.text.primary,
      fontWeight: 500,
      letterSpacing: '-0.24px',
      marginTop: theme.spacing(1.25),
      fontFamily: 'Calibre-R, sans-serif',
    },
    sharePromptsButton: {
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(0.75, 2),
      fontWeight: 500,
      lineHeight: '100%',
      fontFamily: 'Calibre-R, sans-serif',
      textTransform: 'none',
    },

    promptEnhancerButton: {
      textTransform: 'none',
      '& div': {
        marginLeft: `${theme.spacing(0.5)}px`,
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },

    searchStatusFilter: {
      display: 'flex',
      justifyContent: 'right',
      padding: `${theme.spacing(1.25)}px 0px`,
    },
    /* PromptStoreHeader Styles -- Ends */

    /* Prompt search bar styles */

    searchBarGrid: {
      margin: `0px auto ${theme.spacing(8)}px`,
    },
    searchBar: {
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '5px',
      display: 'flex',
      boxShadow: 'none',
    },
    searchBarText: {
      width: '100%',
      margin: '12px',
    },
    searchIcon: {
      color: theme.palette.text.secondary,
    },
    selectDropDown: {
      backgroundColor: theme.palette.background.paper,
      '&:focus': {
        backgroundColor: theme.palette.background.paper,
      },
      padding: `${theme.spacing(1.3125)}px ${theme.spacing(1.75)}px`,
    },
    statusDropdown: {
      width: '100%',
    },

    /* Prompt search bar style ends */

    /* Prompt Tags Styles */
    tagsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: theme.spacing(2),
      alignItems: 'center',
    },

    bookmarkChipButton: {
      margin: 'auto 0',
    },

    overflowChipButton: {
      margin: 'auto 0',
      minWidth: theme.spacing(6),
      maxWidth: theme.spacing(6),
    },

    viewAllButton: {
      minWidth: theme.spacing(15),
    },

    myPromptContainer: {
      height: `${theme.spacing(4)}px !important`,
    },

    promptTagsContainer: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      height: 'auto',
      overflow: 'hidden',
      gap: theme.spacing(1),
      fontSize: 12,
      color: 'var(--Text-Dark-Text-Dark, #1a1a1a)',
      fontWeight: 400,
      whiteSpace: 'nowrap',
      letterSpacing: '0.06px',
      lineHeight: '133%',
      margin: 'auto 0',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        whiteSpace: 'initial',
      },
    },
    promptTagWrapper: {
      borderRadius: 16,
      padding: theme.spacing(1, 1.5),
      [theme.breakpoints.down('sm')]: {
        whiteSpace: 'initial',
      },
    },

    tagSelected: {
      backgroundColor: theme.palette.primary.main,
      '&:hover, &:focus': {
        backgroundColor: theme.palette.primary.main,
      },
      color: theme.palette.text.contrast,
    },

    // tagNotSelected: {
    //   backgroundColor: 'inherit',
    // },

    promptTagText: {
      fontFamily: 'Calibre-R, sans-serif',
    },

    /* Prompt Tags Styles -- Ends */

    /* ViewPrompts Page Styles */
    scopesContainer: {
      flexGrow: 1,
      flexBasis: '100%',
      marginRight: theme.spacing(1.5),
    },

    /* ViewPrompts Page Styles -- Ends */

    /* PromptStoreCard Styles */
    cardRoot: {
      minHeight: theme.spacing(29.5),
      maxHeight: theme.spacing(29.5),
    },
    cardRootAllPrompts: {
      minHeight: theme.spacing(25),
      maxHeight: theme.spacing(25),
    },
    cardContent: {
      minHeight: theme.spacing(13.5),
    },
    actionSection: {
      padding: `0px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
      display: 'flex',
      justifyContent: 'space-between',
    },
    headerSection: {
      height: theme.spacing(9.375),
      cursor: 'pointer',
      background: 'rgb(0, 63, 45, 0.03)',
    },
    promptOwner: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(0.7),
    },
    buttonsContainer: {
      display: 'flex',
    },
    headerAction: {
      marginTop: '0px',
      marginRight: '0px',
    },
    bookmarkButton: {
      padding: theme.spacing(0.5),
    },
    reviewerComments: {
      background: theme.palette.alert.background,
      padding: `${theme.spacing(1)}px`,
      borderRadius: `${theme.spacing(0.5)}px`,
      color: theme.palette.text.secondary,
      // fontWeight: 600,
    },
    commentsInfo: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    infoIconComments: {
      fontSize: theme.spacing(2),
    },
    comment: {
      fontSize: theme.spacing(1.5),
    },
    promptCardBody: {
      marginBottom: theme.spacing(1.5),
      color: theme.palette.text.tertiary,
    },
    circleIcon: {
      marginLeft: theme.spacing(0.2),
      fontSize: `${theme.spacing(1)}px`,
      color: '#E3E3E3',
    },
    cardHeading: {
      overflow: 'hidden',
      fontSize: '18px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: theme.palette.text.secondary,
      // height: `${theme.spacing(8)}px`,
      '@supports (-webkit-line-clamp: 2)': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'initial',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
      },
    },
    chip: {
      marginBottom: theme.spacing(0),
    },
    tryNowButton: {
      backgroundColor: 'rgb(0, 63, 45, 0.08)',
      boxShadow: 'none',
      textTransform: 'none',
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    shareBookMarkIconSize: {
      fontSize: `${theme.spacing(2.5)}px`,
    },
    /* PromptStoreCard Styles -- Ends */

    /* All tags popup styles starts */
    dialogBox: {
      minWidth: '720px',
      maxWidth: '720px ',
      maxHeight: '468px',
      [theme.breakpoints.down('md')]: {
        minWidth: 'auto',
        maxHeight: `calc(100vh - ${theme.spacing(16)}px)`,
      },
    },
    dialogHeader: {
      display: 'flex',
      alignItems: 'center',
    },
    headerTitle: {
      margin: '10px',
    },
    dialogContent: {
      margin: '10px',
      padding: '0px 10px',
    },
    savePromptContent: {
      margin: '30px 0px 10px 0px',
    },
    footerSection: {
      margin: '10px',
    },
    /* All tags popup styles ends */

    /* Prompt details popup styles starts  */
    promptDetailBox: {
      minWidth: '960px',
      maxWidth: '960px ',
      maxHeight: '756px',
      [theme.breakpoints.down('md')]: {
        minWidth: 'auto',
        maxHeight: `calc(100vh - ${theme.spacing(16)}px)`,
      },
    },

    userDetail: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      '& div[class*="BackstageAvatar-avatar"]': {
        width: '2.5rem',
        height: '2.5rem',
      },
    },
    promptStatusapproved: {
      color: theme.palette.success.main,
    },
    promptStatusresubmit: {
      color: theme.palette.warning.main,
    },
    promptStatusrejected: {
      color: theme.palette.errorText,
    },
    iconButtonContainer: {
      marginRight: theme.spacing(0.75),
      textAlign: 'right',
    },
    iconButton: {
      padding: theme.spacing(0.75),
    },

    userDetailSubSection: {
      marginRight: theme.spacing(1),
    },
    userDetailSection: {
      color: theme.palette.text.secondary,
    },

    tabSection: {
      margin: `${theme.spacing(2)}px 0px 0px 0px`,
    },
    tabPanel: {
      padding: `${theme.spacing(1.25)}px 0px ${theme.spacing(
        1.25,
      )}px ${theme.spacing(1.25)}px !important`,
    },
    systemPromptSection: {
      padding: `${theme.spacing(1.25)}px ${theme.spacing(
        0.375,
      )}px ${theme.spacing(1.25)}px ${theme.spacing(1.25)}px`,
      margin: `${theme.spacing(1.25)}px 0px ${theme.spacing(2)}px 0px`,
      backgroundColor: theme.palette.background.default,
    },
    systemPromptHeader: {
      display: 'flex',
      alignItems: 'center',
      gap: `${theme.spacing(1)}px`,
      margin: `0px 0px ${theme.spacing(1)}px 0px`,
      color: theme.palette.text.secondary,
      fontWeight: 600,
      letterSpacing: '0.4px',
    },
    promptDetailHeader: {
      fontWeight: 600,
      margin: `0px 0px ${theme.spacing(1)}px 0px`,
      color: theme.palette.text.secondary,
    },
    systemPromptContent: {
      color: theme.palette.text.secondary,
    },
    promptDetail: {
      fontSize: '16px',
      color: theme.palette.text.secondary,
    },
    copyPromptDetail: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'end',
    },
    tagSectionInPopup: {
      margin: `${theme.spacing(1)}px 0px 0px 0px`,
    },
    authorName: {
      color: theme.palette.text.secondary,
      fontWeight: 600,
      letterSpacing: '0.5px',
    },
    promptKey: {
      color: theme.palette.text.secondary,
    },
    /* Prompt details popup styles ends  */
    /*  Prompt try it out styles */
    loader: {
      display: 'flex',
      justifyContent: 'center',
      '& > div': {
        width: '5px',
        height: '5px',
        margin: '3px',
        borderRadius: '50%',
        backgroundColor: theme.palette.prompt.loader.secondary,
        animation: '$blinker 1.5s infinite',
      },
      '& > div:nth-child(2)': {
        animationDelay: '0.5s',
      },
      '& > div:nth-child(3)': {
        animationDelay: '1s',
      },
    },
    '@keyframes blinker': {
      from: {
        backgroundColor: theme.palette.prompt.loader.secondary,
      },
      to: {
        backgroundColor: theme.palette.prompt.loader.primary,
      },
    },
    options: {
      backgroundColor: theme.palette.prompt.options.background,
      color: theme.palette.prompt.options.text,
      justifyContent: 'center',
      borderRadius: '36px',
      boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.2)',
      marginTop: '24px',
      textAlign: 'center',
      padding: '16px',
      maxWidth: '100%',
    },
    baseContainer: {
      width: '600px',
      height: `calc(100vh - ${theme.spacing(8)}px)`,
      position: 'fixed',
      bottom: '15px',
      right: '35px',
      //temporary solution to be over the avaamo chatbot
      zIndex: 999999999,
    },
    tryItOutHeader: {
      height: '64px',
      width: '100%',
      backgroundColor: theme.palette.prompt.options.header,
      padding: '9px 20px',
    },
    tryItOutCloseButton: {
      borderRadius: '50%',
      backgroundColor: theme.palette.prompt.options.button,
      width: '32px',
      height: '32px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.prompt.options.color,
    },
    headIconDiv: {
      '& > svg': {
        marginRight: `${theme.spacing(1)}px`,
      },
    },
    tryItOutContent: {
      width: '100%',
      overflow: 'hidden scroll',
      padding: '16px',
      flexGrow: 1,
    },
    tryItOutFooter: {
      height: '60px',
      width: '100%',
    },
    chatInput: {
      padding: '12px 20px 0px',
      border: `1px solid ${theme.palette.prompt.options.border}`,
      backgroundColor: theme.palette.prompt.options.inputBackground,
    },
    chatBot: {
      backgroundColor: theme.palette.prompt.options.bot.background,
      padding: '12px',
      maxWidth: '460px',
    },
    chatUser: {
      backgroundColor: theme.palette.prompt.options.user.background,
      padding: '12px',
      maxWidth: '460px',
    },
    chatAlign: {
      display: 'flex',
      marginBottom: '8px',
      padding: '12px',
    },
    chatAlignLeft: {
      justifyContent: 'flex-start',
    },
    chatAlignRight: {
      justifyContent: 'flex-end',
    },
    chatPaper: {
      backgroundColor: theme.palette.prompt.options.paper,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    chatTitle: {
      color: theme.palette.prompt.options.title,
    },
    codeBlockCopy: {
      transform: 'translateY(44px)',
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: '5px',
      fontSize: '0.65rem',
    },
    chatContentCopy: {
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: '0.65rem',
    },
    copyIconInChat: {
      color: theme.palette.text.primary,
    },
    codeBlockButton: {
      fontSize: '1rem',
      color: theme.palette.text.signIn,
      '& > svg': {
        '& > svg': {
          fontSize: '1.2rem',
        },
      },
    },
    /* Prompt try it out styles ends */

    /* Prompt enhancer styles Starts */
    cardHeader: {
      padding: theme.spacing(2),
      color: theme.palette.common.white,
    },
    enhancerContainer: {
      height: `calc(100vh - ${theme.spacing(2)}em)`,
    },
    componentContainer: {
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        height: '50%',
      },
    },
    enhancerCard: {
      height: '100%',
    },
    enhancerTextField: {
      height: '100%',
      '& div[class*="MuiInputBase-multiline"]': {
        height: '100%',
      },
      '& textarea': {
        height: '100% !important',
        overflow: 'auto !important',
      },
    },
    enhancerTextFieldStartAdornment: {
      '& [role="progressbar"]': {
        position: 'absolute',
        left: `calc(50% - 20px)`,
      },
    },
    cardActionsSpacing: {
      padding: `${theme.spacing(2)}px`,
    },

    inputCardheader: {
      backgroundColor: theme.palette.panel.varient1,
    },
    enhanceCardHeader: {
      backgroundColor: theme.palette.panel.varient2,
    },

    instructionBox: {
      padding: theme.spacing(3),
      height: '100%',
      backgroundColor: theme.palette.background.secondary,
      color: theme.palette.text.secondary,
      borderRadius: `${theme.spacing(0.75)}px`,
      overflow: 'hidden',
      overflowY: 'scroll',
    },
    instructionBoxTitle: {
      marginBottom: `${theme.spacing(1.2)}px`,
    },
    instructionInput: {
      backgroundColor: '#E3E3E3',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      padding: `${theme.spacing(1.2)}px`,
      color: '#565656',
    },
    instructionOutput: {
      backgroundColor: '#EDEDED',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
      padding: `${theme.spacing(1.2)}px`,
      color: '#565656',
    },

    instructionList: {
      paddingLeft: `${theme.spacing(2)}px`,
    },

    tooltip: {
      fontSize: `${theme.spacing(1.75)}px`,
      minWidth: theme.spacing(81.25),
      backgroundColor: theme.palette.toolTip.background,
      color: theme.palette.toolTip.textColor,
    },
    buttonSection: {
      display: 'flex',
      alignItems: 'center',
    },
    toolTipDiv: {
      marginRight: theme.spacing(1.4),
    },

    /* Prompt enhancer styles Ends */
  }),
);
