import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /* pageList index */

    pageList: {
      [theme.breakpoints.down('sm')]: {
        padding: '10px',
      },
    },
    pageHeading: {
      color: theme.palette.text.secondary,
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '16px',
    },
    pageDesc: {
      color: theme.palette.text.tertiary,
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
    },
    ContentHeaderStyle: {
      width: '100%',
      padding: '0px',
      marginBottom: '10px',
      '& > form:first-of-type': {
        display: 'flex',
        '& > button': {
          flex: '0.2',
        },
        '& > div': {
          flex: '10',
        },
      },
    },
    input: {
      flex: '10',
    },
    saveContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    heading: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '10px',
    },

    btnFonts: {
      textAlign: 'center',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '16px',
    },
    addPageBtn: {
      display: 'flex',
      padding: '6px 16px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      borderRadius: '4px',
      textTransform: 'capitalize',
      margin: '10px 0px',
    },
    pageBtn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-end',
      alignSelf: 'stretch',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    /* page list card styles */
    CardButtonContainer: {
      display: 'flex',
      '& button': {
        padding: '0px 12px',
        minWidth: 'auto',
        color: theme.palette.primary.main,
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0px 0px',
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
    addCategoryContainer: {
      justifyContent: 'right',
      display: 'flex',
      marginTop: '24px',
    },
    addBtn: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    saveCancelButtonGroup: {
      justifyContent: 'right',
      display: 'flex',
    },
    CardEntityContainer: {
      padding: '15px',
      minWidth: '320px',
      minHeight: '70px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
      display: 'flex',
    },
    CardTypeFont: {
      fontSize: '11px',
      lineHeight: '16px',
    },
    CardTypeFontBold: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '14px',
      lineHeight: '18px',
      color: theme.palette.text.tertiary,
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
    boldText: {
      color: theme.palette.text.secondary,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
    },
    center: {
      display: 'flex',
      alignItems: 'center',
    },
    toggle: {
      display: 'flex',
      alignItems: 'center',
    },
    pageToggleSwitch: {
      marginLeft: '12px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0px',
      },
    },

    /* Add Page Popup */
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    DialogBox: {
      minWidth: 300,
    },
    pageListMargin: {
      marginTop: '20px',
    },
    btnsMargin: {
      margin: '20px 15px 15px 0px',
      textTransform: 'lowercase',
    },
    actionBtn: {
      textTransform: 'initial',
    },
    description: {
      marginTop: '10px',
    },
    toggleSwitch: {
      marginLeft: '25px',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    instructionsHeading: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '20px',
      marginBottom: '8px',
    },
    popupBtns: {
      textTransform: 'capitalize',
    },

    /* Add Step Popup */

    stepsMargin: {
      marginTop: '20px',
    },
    stepBtnsMargin: {
      margin: '20px 0px 15px 0px',
      textTransform: 'lowercase',
    },

    /* Steps List Index Styles */

    resetSaveBtns: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: '10px',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },

    Btns: {
      display: 'flex',
      padding: '12px 16px',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '4px',
      border: '1px solid #003F2D',
      textTransform: 'capitalize',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    stepsListPage: {
      padding: '24px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        padding: '10px',
      },
    },

    /* Steps List Card */
    StepCardButtonContainer: {
      display: 'flex',
      '& button': {
        padding: '0px 6px',
        minWidth: 'auto',
        color: theme.palette.primary.main,
      },
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },

    StepCardEntityContainer: {
      padding: '15px',
      minWidth: '320px',
      minHeight: '70px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
      display: 'flex',
      marginTop: '6px',
    },

    handleIcon: {
      marginRight: '12px',
    },
    Description: {
      wordBreak: 'break-word',
      textOverflow: 'ellipsis',
      display: '-webkit-inline-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      width: '200px',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
  }),
);
