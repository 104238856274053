import React, { useContext } from 'react';
import { PageWithHeader, TabbedLayout } from '@backstage/core-components';
import { MyStuff } from './myStuff/myStuff';
import { Subscribe } from './subscribe/subscribe';
import { AllAssets } from './allAssets/allAssets';
import { Discover } from './discover';
import { Review } from './review';
import { CHECK_USER_ROLE, IS_GAMIFICATION_ENABLE } from '../utils/constant';
import { AuthContext } from '../../providers/AuthProvider';
import BreadcrumbsNav from '../common/BreadcrumbsNav/BreadcrumbsNav';
import LeaderBoardPage from '../leaderBoard/leaderBoardContainer';
import TopPlayers from '../leaderBoard/topPlayersContainer';

export const Home = () => {
  const { userInfo, accessToken, profile, profEmail } = useContext(AuthContext);
  let userName = profile?.displayName?.split('@')[0];
  const userProfile = profEmail?.split('@')[0].toLowerCase();

  if (userName && userName?.indexOf(',') !== -1) {
    userName = userName?.split(',')[1];
  }
  const pageHeaderTitle = `Developer Exchange Marketplace`;

  const isReviewer = () => {
    if (
      userInfo &&
      (userInfo?.isApiReviewer?.toLowerCase() === CHECK_USER_ROLE ||
        userInfo?.isComponentReviewer?.toLowerCase() === CHECK_USER_ROLE) &&
      (userInfo?.apiReviewEnabled?.toLowerCase() === CHECK_USER_ROLE ||
        userInfo?.componentReviewEnabled?.toLowerCase() === CHECK_USER_ROLE)
    ) {
      return true;
    }
    return false;
  };

  const routes = [
    {
      path: '/discover',
      title: 'discover',
    },
    {
      path: '/',
      title: 'discover',
    },
    {
      path: '/my-assets',
      title: 'my assets',
    },
    {
      path: '/all-assets',
      title: 'all assets',
    },
    {
      path: '/review',
      title: 'review',
    },
    {
      path: '/subscribe',
      title: 'Subscribed',
    },
    {
      path: '/leader-board',
      title: 'Leaderboard',
    },
    {
      path: '/top-players',
      title: 'Top Players',
    },
  ];

  return (
    <>
      {accessToken && accessToken !== '' && (
        <div className="HeaderTopTabs">
          <PageWithHeader
            title={pageHeaderTitle}
            themeId="home"
            pageTitleOverride="Home"
            subtitle={
              <BreadcrumbsNav
                lastRoutes={routes}
                skipLast={undefined}
              ></BreadcrumbsNav>
            }
          >
            <TabbedLayout>
              <TabbedLayout.Route path="/discover" title="DISCOVER">
                <Discover />
              </TabbedLayout.Route>
              <TabbedLayout.Route path="/my-assets" title="MY ASSETS">
                <MyStuff userName={userProfile} />
              </TabbedLayout.Route>
              <TabbedLayout.Route path="/all-assets" title="ALL ASSETS">
                <AllAssets />
              </TabbedLayout.Route>
              <TabbedLayout.Route path="/subscribe" title="Subscribed">
                <Subscribe userName={userProfile} />
              </TabbedLayout.Route>

              {isReviewer() && (
                <TabbedLayout.Route path="/review" title="REVIEW">
                  <Review userName={userProfile} userInfo={userInfo} />
                </TabbedLayout.Route>
              )}

              {IS_GAMIFICATION_ENABLE && (
                <>
                  <TabbedLayout.Route path="/leader-board" title="Leader Board">
                    <LeaderBoardPage
                      isAdhocPointPage={false}
                      onclickHandler={() => {}}
                    />
                  </TabbedLayout.Route>
                  <TabbedLayout.Route path="/top-players" title="Top Players">
                    <TopPlayers />
                  </TabbedLayout.Route>
                </>
              )}
            </TabbedLayout>
          </PageWithHeader>
        </div>
      )}
    </>
  );
};
