import { createApiRef } from '@backstage/core-plugin-api';
import { AxiosInstance } from 'axios';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';

export const additionalConfigApiRef = createApiRef<AdditionalConfigApi>({
  id: 'cbre.devx.api.additional.config',
});

export interface AdditionalConfigApi {
  getFrontEndConfig: () => any;
  getScaffolderAdoConfig: () => any;
  getPocHubConfig: () => any;
}

export class AdditionalConfigApiImpl implements AdditionalConfigApi {
  private readonly axiosInstance: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstance = axiosInstanceProviderApi.getInstance();
  }

  async getFrontEndConfig() {
    let instance = await this.axiosInstance;
    return await instance
      .get(`config/frontend/config`)
      .then(res => {
        return res?.data;
      })
      .catch(err => {
        throw new Error(
          `an error occurred while getting configuration - ${err?.message}`,
        );
      });
  }

  async getScaffolderAdoConfig() {
    let instance = await this.axiosInstance;
    return await instance
      .get(`config/sw-template/azure/config`)
      .then(res => {
        return res?.data;
      })
      .catch(err => {
        throw new Error(
          `an error occurred while getting configuration - ${err?.message}`,
        );
      });
  }

  async getPocHubConfig() {
    let instance = await this.axiosInstance;
    return await instance
      .get(`config/poc-hub/poc-config`)
      .then(res => {
        return res?.data;
      })
      .catch(err => {
        throw new Error(
          `an error occurred while getting configuration - ${err?.message}`,
        );
      });
  }
}
