import { createApiRef } from '@backstage/core-plugin-api';

/**
 * Utility API reference for the {@link CatalogImportApi}.
 *
 * @public
 */
export const swTemplateGithubApiRef = createApiRef<SwTemplateGithubApi>({
  id: 'devx.software-template.github',
});

export interface GithubOrgNode {
  login: string;
  name: string;
}

export interface GithubUserAndOrgsInfoResponse {
  owner: string;
  organizations: GithubOrgNode[] | undefined;
}

/**
 * API for getting user and organization information from Github
 * using API v3
 */
export interface SwTemplateGithubApi {
  getUserProfileAndOrgs(
    githubToken: string,
  ): Promise<GithubUserAndOrgsInfoResponse>;
  getRepositoryInfo(owner: string, repoName: string): Promise<any>;
}
