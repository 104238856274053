import { Content, Header, Page, InfoCard } from '@backstage/core-components';
import { Grid, LinearProgress, useMediaQuery, Theme, useTheme } from '@material-ui/core';
import { JsonObject, JsonValue } from '@backstage/types';
import { FormValidation, IChangeEvent } from '@rjsf/core';
import qs from 'qs';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import useAsync from 'react-use/lib/useAsync';
import {
  scaffolderApiRef,
  CustomFieldValidator,
  FieldExtensionOptions,
} from '@backstage/plugin-scaffolder-react';
import { SecretsContext } from '../SecretsContext';
import { rootRouteRef, scaffolderTaskRouteRef } from '../Routes';
import { MultistepJsonForm } from '../MultistepJsonForm';
import {
  ApiHolder,
  useApi,
  useApiHolder,
  useRouteRef,
} from '@backstage/core-plugin-api';
import { stringifyEntityRef, Entity } from '@backstage/catalog-model';
import { TemplateCard } from '../ScaffolderPage/TemplateCard';
import { catalogApiRef, EntityProvider } from '@backstage/plugin-catalog-react';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import BackButton from '../../utils/backButton';
import { RateTemplate } from './TemplateRating';

import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { DevxBreadCrumb } from '../../common/BreadcrumbsNav/DevxBreadCrumb';
import { ApmListProvider } from '../providers/ApmListProvider';

const useTemplateParameterSchema = (templateRef: string) => {
  const scaffolderApi = useApi(scaffolderApiRef);
  const { value, loading, error } = useAsync(
    () => scaffolderApi.getTemplateParameterSchema(templateRef),
    [scaffolderApi, templateRef],
  );
  return { schema: value, loading, error };
};

function isObject(obj: unknown): obj is JsonObject {
  return typeof obj === 'object' && obj !== null && !Array.isArray(obj);
}

export const createValidator = (
  rootSchema: JsonObject,
  validators: Record<string, undefined | CustomFieldValidator<unknown>>,
  context: {
    apiHolder: ApiHolder;
  },
) => {
  function validate(
    schema: JsonObject,
    formData: JsonObject,
    errors: FormValidation,
  ) {
    const schemaProps = schema.properties;
    if (!isObject(schemaProps)) {
      return;
    }

    for (const [key, propData] of Object.entries(formData)) {
      const propValidation = errors[key];

      if (isObject(propData)) {
        const propSchemaProps = schemaProps[key];
        if (isObject(propSchemaProps)) {
          validate(
            propSchemaProps,
            propData as JsonObject,
            propValidation as FormValidation,
          );
        }
      } else {
        const propSchema = schemaProps[key];
        const fieldName =
          isObject(propSchema) && (propSchema['ui:field'] as string);

        if (fieldName && typeof validators[fieldName] === 'function') {
          validators[fieldName]!(
            propData as JsonValue,
            propValidation,
            context,
          );
        }
      }
    }
  }

  return (formData: JsonObject, errors: FormValidation) => {
    validate(rootSchema, formData, errors);
    return errors;
  };
};

export const TemplatePage = ({
  customFieldExtensions = [],
}: {
  customFieldExtensions?: FieldExtensionOptions<any, any>[];
}) => {
  const apiHolder = useApiHolder();
  // const classes = useStyles();
  const secretsContext = useContext(SecretsContext);

  const catalogApi = useApi(catalogApiRef);
  const scaffolderApi = useApi(scaffolderApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { templateName } = useParams();
  const navigate = useNavigate();
  const scaffolderTaskRoute = useRouteRef(scaffolderTaskRouteRef);
  const rootRoute = useRouteRef(rootRouteRef);
  const { schema, loading, error } = useTemplateParameterSchema(templateName);
  const [formState, setFormState] = useState<Record<string, any>>(() => {
    const query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    try {
      return JSON.parse(query.formData as string);
    } catch (e) {
      return query.formData ?? {};
    }
  });
  const handleFormReset = () => setFormState({});
  const handleChange = useCallback(
    (e: IChangeEvent) => setFormState(e.formData),
    [setFormState],
  );
  const [entity, setEntity] = useState<Entity | undefined>();

  useEffect(() => {
    if (catalogApi) {
      catalogApi
        .getEntityByRef({
          kind: 'Template',
          name: templateName,
          namespace: 'default',
        })
        .then((value: Entity | undefined) => {
          setEntity(value);
        })
        .catch((err: any) => {
          notificationApi.sendNotification({
            message: `Error occurred - ${err?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });
    }
  }, [catalogApi, schema]);

  const handleCreate = async () => {
    const { taskId } = await scaffolderApi.scaffold({
      templateRef: stringifyEntityRef({
        name: templateName,
        kind: 'template',
        namespace: 'default',
      }),
      values: formState,
      secrets: secretsContext?.secrets,
    });

    const formParams = qs.stringify(
      { formData: formState },
      { addQueryPrefix: true },
    );
    const newUrl = `${window.location.pathname}${formParams}`;
    // We use direct history manipulation since useSearchParams and
    // useNavigate in react-router-dom cause unnecessary extra rerenders.
    // Also make sure to replace the state rather than pushing to avoid
    // extra back/forward slots.
    window.history?.replaceState(null, document.title, newUrl);

    navigate(scaffolderTaskRoute({ taskId }));
  };

  if (error) {
    notificationApi.sendNotification({
      message: `Failed to load template, ${error}`,
      disapperAfterMs: 2500,
      severity: 'error',
    });
    return <Navigate to={rootRoute()} />;
  }
  if (!loading && !schema) {
    notificationApi.sendNotification({
      message: `Template was not found.`,
      disapperAfterMs: 2500,
      severity: 'error',
    });
    return <Navigate to={rootRoute()} />;
  }

  const customFieldComponents = Object.fromEntries(
    customFieldExtensions.map(({ name, component }) => [name, component]),
  );

  const customFieldValidators = Object.fromEntries(
    customFieldExtensions.map(({ name, validation }) => [name, validation]),
  );

  return (
    <Page themeId="home">
      <Header
        pageTitleOverride="Create a New Template"
        title="Create a New Template"
        subtitle={
          <DevxBreadCrumb
            routes={[
              {
                type: 'link',
                link: '/',
                text: 'Home',
              },
              {
                type: 'link',
                link: '/software-templates',
                text: 'Software Templates',
              },
              {
                type: 'text',
                link: '',
                text: templateName ?? '',
              },
            ]}
          />
        }
      />
      <Content>
        {loading && <LinearProgress data-testid="loading-progress" />}
        <Grid container className="softwareTemplatePage">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <BackButton />
            <HelpComponent helpUrl={HELP_URL.SoftwareTemplates} />
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={8}>
            {entity && (
              <TemplateCard
                template={entity}
                deprecated={false}
                key="detailed-card"
                hideChooseButton={true}
                horizontalView={true}
                onLoadCall
              />
            )}
            {
              isMobile && <Grid item xs={12} sm={12}md={5} lg={4}>
                <RateTemplate
                  entity={entity}
                />
              </Grid>
            }
            {schema && (
              <InfoCard
                title={schema.title}
                noPadding
                titleTypographyProps={{ component: 'h2' }}
              >
                <EntityProvider entity={entity}>
                  <ApmListProvider>
                    <MultistepJsonForm
                      formData={formState}
                      fields={customFieldComponents}
                      onChange={handleChange}
                      onReset={handleFormReset}
                      onFinish={handleCreate}
                      customFieldExtensions={customFieldExtensions}
                      steps={schema.steps.map(step => {
                        return {
                          ...step,
                          validate: createValidator(
                            step.schema,
                            customFieldValidators,
                            { apiHolder },
                          ),
                        };
                      })}
                    />
                  </ApmListProvider>
                </EntityProvider>
              </InfoCard>
            )}
          </Grid>
         {
          !isMobile && <Grid item xs={12} sm={12}md={5} lg={4}>
            <RateTemplate
              entity={entity}
            />
          </Grid>
         }
        </Grid>
      </Content>
    </Page>
  );
};
