import React, { useContext } from 'react';
import { Button, Grid } from '@material-ui/core';
import { PageWithHeader, Content } from '@backstage/core-components';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import BackButton from '../../utils/backButton';
import PreviewIcon from '@material-ui/icons/Preview';
import SwaggerApiForm from './swagger-api-form';
import { useStyles } from './styles';
import { PromotionContext } from '../../../providers/PromotionDataProvider';
import { DevxBreadCrumb } from '../../common/BreadcrumbsNav/DevxBreadCrumb';

const RegisterApiPage = () => {
  const myContext = useContext(PromotionContext);
  const classes = useStyles();

  const buttonRef = React.useRef(null);

  const handleButtonClick = () => {
    buttonRef?.current?.click();
  };

  return (
    /* Generate Swagger Page */
    <PageWithHeader
      title="Create Swagger File"
      themeId="home"
      subtitle={
        <DevxBreadCrumb
          routes={[
            { type: 'link', link: '/', text: 'Home' },
            { type: 'link', link: '/API', text: 'APIs' },
            { type: 'link', link: '/API/register-api', text: 'Register API' },
            { type: 'text', link: '', text: 'Create Swagger File' },
          ]}
        />
      }
    >
      <Content>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} md={12}>
            <BackButton />

            <HelpComponent helpUrl={HELP_URL.SWAGGER_API} />
            <Button
              color="primary"
              variant="text"
              className={classes.viewDefBtn}
              onClick={handleButtonClick}
            >
              <PreviewIcon />
              {myContext?.toggleSwaggerButton ? 'Refresh' : 'View definition'}
            </Button>
          </Grid>
        </Grid>
        {/* Swagger Api Form */}
        <div className="codePage">
          <Grid container direction="row" spacing={3}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={`${classes.registerApi} ${classes.generateSwagger}`}
            >
              <SwaggerApiForm buttonRef={buttonRef} />
            </Grid>
          </Grid>
        </div>
      </Content>
    </PageWithHeader>
  );
};
export default RegisterApiPage;
