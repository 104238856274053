import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const JWTDecoderIcon = (props: any) => (
  <SvgIcon
    viewBox={'48 48'}
    style={{ transform: 'scale(3)' }}
    fill="#000000"
    {...props}
  >
    <path
      d="M10.7109 2.25H13.2109L13.2297 7.29904L11.98 9.01529L10.7297 7.29821L10.7109 2.25Z"
      fill="white"
      stroke="#D3D3D3"
      strokeWidth="0.5"
    />
    <path
      d="M13.23 21.7499H10.73V16.6813L11.98 14.9646L13.23 16.6813V21.7499Z"
      fill="white"
      stroke="#D3D3D3"
      strokeWidth="0.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4801 16.5999L16.6401 20.9599L19.0601 19.1999L15.9001 14.8399L13.4801 14.0599V16.5999Z"
      fill="#00F2E6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.48 7.38002L7.30001 3.02002L4.88 4.78002L8.04001 9.14002L10.48 9.92002V7.38002Z"
      fill="#00F2E6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.04 9.13998L2.92 7.47998L2 10.32L7.12 12L9.54 11.2L8.04 9.13998Z"
      fill="#00B9F1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4 12.78L15.9 14.84L21.02 16.5L21.94 13.66L16.82 12L14.4 12.78Z"
      fill="#00B9F1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.82 12L21.94 10.32L21.02 7.47998L15.9 9.13998L14.4 11.2L16.82 12Z"
      fill="#D63AFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.12 12L2 13.66L2.92 16.5L8.04 14.84L9.54 12.78L7.12 12Z"
      fill="#D63AFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.04001 14.8399L4.88 19.1999L7.30001 20.9599L10.48 16.5999V14.0599L8.04001 14.8399Z"
      fill="#FB015B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9001 9.14002L19.0601 4.78002L16.6401 3.02002L13.4801 7.38002V9.92002L15.9001 9.14002Z"
      fill="#FB015B"
    />
  </SvgIcon>
);

export default JWTDecoderIcon;
