import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formFileUpload: {
      height: '16rem',
      maxWidth: '100%',
      textAlign: 'center',
      position: 'relative',
    },

    inputFileUpload: {
      display: 'none',
    },
    themeColor: {},
    labelFileUpload: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: '2px',
      borderRadius: '1rem',
      borderStyle: 'dashed',
      borderColor: '#8585E0',
      backgroundColor: theme.palette.infoBackground,
    },
    dragFileElement: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      borderRadius: '1rem',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },

    errorColor: {
      backgroundColor: theme.palette.errorBackground,
      borderColor: theme.palette.errorText,
      color: theme.palette.text.secondary,
    },

    successColor: {
      backgroundColor: theme.palette.successBackground,
      borderColor: theme.palette.successText,
      color: theme.palette.text.secondary,
    },

    defaultColor: {
      backgroundColor: theme.palette.infoBackground,
      borderColor: theme.palette.infoText,
      color: theme.palette.text.secondary,
      ['@media (max-width:920px) and (min-width: 280px)']: {
        padding: '20px 10px 20px 10px',
      },
    },
  }),
);

// drag drop file component
const DragDropFile = (props: any) => {
  const { onFileDropped, error, success } = props;
  const classes = useStyles();
  const handleFile = async (files: any) => {
    // eslint-disable-next-line no-console
    onFileDropped(files[0]);
  };
  // drag state
  const [dragActive, setDragActive] = React.useState(false);

  // ref
  //  const inputRef = React.useRef(null);

  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = (e: any) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  // const onButtonClick = () => {
  //   inputRef.current.click();
  // };

  return (
    <form
      className={classes.formFileUpload}
      onDragEnter={handleDrag}
      onSubmit={e => e.preventDefault()}
    >
      <input
        ref={props.inputRef}
        type="file"
        id="input-file-upload"
        onChange={handleChange}
        className={classes.inputFileUpload}
      />
      <div
        className={`${classes.labelFileUpload} ${classes.themeColor}
        ${
          // eslint-disable-next-line no-nested-ternary
          error
            ? classes.errorColor
            : success
            ? classes.successColor
            : classes.defaultColor
        }`}
      >
        <div>{props.children}</div>
      </div>

      {dragActive && (
        <div
          id="drag-file-element"
          className={classes.dragFileElement}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      )}
    </form>
  );
};

export default DragDropFile;
