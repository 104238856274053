import React from 'react';

const ReviewApproved = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 7.5H15L12.5 5H5C3.625 5 2.5125 6.125 2.5125 7.5L2.5 22.5C2.5 23.875 3.625 25 5 25H25C26.375 25 27.5 23.875 27.5 22.5V10C27.5 8.625 26.375 7.5 25 7.5ZM5 22.5H25V10H13.9625L11.4625 7.5H5V22.5Z"
        fill="#17E88F"
      />
      <path
        d="M13.5375 20L10 16.4625L11.7625 14.7L13.525 16.4625L17.95 12.0375L19.7125 13.8L13.5375 20Z"
        fill="#17E88F"
      />
    </svg>
  );
};

export default ReviewApproved;
