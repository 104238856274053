import React from 'react';

const ExtDocs = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 2.5H7.5C6.125 2.5 5 3.625 5 5V25C5 26.375 6.125 27.5 7.5 27.5H22.5C23.875 27.5 25 26.375 25 25V10L17.5 2.5ZM22.5 25H7.5V5H17.5V10H22.5V25ZM15 21.25C13.625 21.25 12.5 20.125 12.5 18.75V11.875C12.5 11.525 12.775 11.25 13.125 11.25C13.475 11.25 13.75 11.525 13.75 11.875V18.75H16.25V11.875C16.25 10.15 14.85 8.75 13.125 8.75C11.4 8.75 10 10.15 10 11.875V18.75C10 21.5125 12.2375 23.75 15 23.75C17.7625 23.75 20 21.5125 20 18.75V13.75H17.5V18.75C17.5 20.125 16.375 21.25 15 21.25Z"
        fill="#17E88F"
      />
    </svg>
  );
};

export default ExtDocs;
