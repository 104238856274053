import React from 'react';
import { TPProvider } from './providers/TPProvider';
import TPHome from './TPHome';

const ToolsProvisioning = () => {
  return (
    <TPProvider>
      <TPHome />
    </TPProvider>
  );
};

export default ToolsProvisioning;
