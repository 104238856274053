/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { userApiRef } from '../../../apis/userApi';
import { useApi } from '@backstage/core-plugin-api';
import { Avatar } from '@backstage/core-components';
import { userProfileApiRef } from '../../../apis/userProfileApi';
import { createStyles, makeStyles } from '@material-ui/core';

const UserAvatar = ({ userEmail, customStyles, valueText }: any) => {
  const userApi = useApi(userApiRef);
  const [imageData, setImageData] = useState<string | undefined | any>('');
  const usernameRef = useRef(null);
  const userProfileApi = useApi(userProfileApiRef);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      avatarContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: `${theme.spacing(0.7)}px`,
      },
    }),
  );

  const onMouseEnter = () => {
    const email: string | undefined = userEmail;
    userProfileApi.setUserProfileState({
      email,
      anchorRef: usernameRef?.current,
    });
  };

  const classes = useStyles();

  useEffect(() => {
    if (userApi) {
      userApi
        .getImage(userEmail)
        .then((data: any) => {
          if (typeof data === 'object') {
            setImageData(undefined);
          } else {
            setImageData(data);
          }
        })
        .catch((err: any) => setImageData(undefined));
    }
    return () => {
      setImageData(undefined);
    };
  }, [userEmail]);

  return (
    <span
      className={classes.avatarContainer}
      onMouseEnter={onMouseEnter}
      ref={usernameRef}
    >
      <Avatar
        displayName={userEmail}
        picture={imageData}
        customStyles={customStyles}
      />
      {valueText ? <span>{valueText}</span> : null}
    </span>
  );
};
export default UserAvatar;
