import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SLAConverterIcon = (props: any) => (
  <SvgIcon viewBox={'48 48'} style={{ transform: 'scale(3)' }} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 1.75C9.41421 1.75 9.75 2.08579 9.75 2.5V3.34667C10.4474 3.27952 11.1973 3.25 12 3.25C12.8035 3.25 13.5533 3.27961 14.25 3.34671V2.5C14.25 2.08579 14.5858 1.75 15 1.75C15.4142 1.75 15.75 2.08579 15.75 2.5V3.56876C16.7477 3.77721 17.6079 4.09962 18.3337 4.56939C19.9004 5.5836 20.7076 7.19164 21.046 9.38566C21.1092 9.79503 20.8285 10.1781 20.4191 10.2412C20.0098 10.3044 19.6267 10.0237 19.5636 9.61434C19.264 7.67235 18.6027 6.53039 17.5186 5.8286C17.042 5.52013 16.4605 5.28059 15.75 5.10646V6.5C15.75 6.91421 15.4142 7.25 15 7.25C14.5858 7.25 14.25 6.91421 14.25 6.5V4.85461C13.5852 4.78437 12.8386 4.75 12 4.75C11.1606 4.75 10.4141 4.78447 9.75 4.85466V6.5C9.75 6.91421 9.41421 7.25 9 7.25C8.58579 7.25 8.25 6.91421 8.25 6.5V5.10652C7.29173 5.34137 6.57 5.69471 6.02333 6.17382C5.04324 7.0328 4.4837 8.42591 4.30951 10.75H9C9.41421 10.75 9.75 11.0858 9.75 11.5C9.75 11.9142 9.41421 12.25 9 12.25H4.25103C4.25034 12.3325 4.25 12.4158 4.25 12.5C4.25 14.9211 4.53608 16.5796 5.15028 17.7171C5.73632 18.8023 6.66501 19.4916 8.18447 19.8771C8.58596 19.979 8.82884 20.3871 8.72696 20.7886C8.62508 21.1901 8.21701 21.4329 7.81553 21.3311C5.97593 20.8642 4.65462 19.9561 3.83042 18.4298C3.03439 16.9556 2.75 14.9883 2.75 12.5C2.75 12.1459 2.75576 11.8036 2.76783 11.4727C2.87401 8.56308 3.46872 6.41819 5.03467 5.04576C5.8766 4.30786 6.94723 3.84088 8.25 3.56869V2.5C8.25 2.08579 8.58579 1.75 9 1.75ZM16 13.25C13.9289 13.25 12.25 14.9289 12.25 17C12.25 19.0711 13.9289 20.75 16 20.75C18.0711 20.75 19.75 19.0711 19.75 17C19.75 14.9289 18.0711 13.25 16 13.25ZM10.75 17C10.75 14.1005 13.1005 11.75 16 11.75C18.8995 11.75 21.25 14.1005 21.25 17C21.25 19.8995 18.8995 22.25 16 22.25C13.1005 22.25 10.75 19.8995 10.75 17ZM16 14.45C16.4142 14.45 16.75 14.7858 16.75 15.2V16.6024L17.4606 17.4017C17.7357 17.7113 17.7079 18.1854 17.3983 18.4606C17.0887 18.7357 16.6146 18.7079 16.3394 18.3983L15.4394 17.3858C15.3174 17.2485 15.25 17.0712 15.25 16.8875V15.2C15.25 14.7858 15.5858 14.45 16 14.45Z"
      fill="#B657A1"
    />
  </SvgIcon>
);

export default SLAConverterIcon;
