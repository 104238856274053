import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const FileDiffIcon = (props: any) => (
  <SvgIcon viewBox="48 48" style={{ transform: 'scale(3)' }} {...props}>
    <path
      d="M11.3597 9.74353C11.3597 9.66606 11.2963 9.59915 11.2188 9.59915H10.3737C10.2962 9.59915 10.2328 9.66254 10.2328 9.74353V11.4514H8.53199C8.451 11.4514 8.38057 11.5148 8.38057 11.5923V12.4374C8.38057 12.5149 8.44748 12.5783 8.53199 12.5783H10.2328V14.2862C10.2328 14.3636 10.2962 14.4306 10.3737 14.4306H11.2188C11.2963 14.4306 11.3597 14.3672 11.3597 14.2862V12.5783H13.0605C13.1415 12.5783 13.212 12.5149 13.212 12.4374V11.5923C13.212 11.5148 13.1451 11.4514 13.0605 11.4514H11.3597V9.74353ZM13.0605 15.8779H8.53199C8.451 15.8779 8.38057 15.9412 8.38057 16.0187V16.8639C8.38057 16.9413 8.44748 17.0047 8.53199 17.0047H13.0605C13.1415 17.0047 13.212 16.9413 13.212 16.8639V16.0187C13.212 15.9412 13.1451 15.8779 13.0605 15.8779ZM12.9197 5.76432C12.8 5.64459 12.6345 5.57416 12.4654 5.57416H5.64442C5.28876 5.57416 5 5.86292 5 6.21858V20.3853C5 20.7409 5.28876 21.0297 5.64442 21.0297H15.9481C16.3038 21.0297 16.5925 20.7409 16.5925 20.3853V9.70832C16.5925 9.53577 16.5256 9.37378 16.4024 9.25405L12.9197 5.76432ZM15.1452 19.5824H6.45083V7.02499H12.1309L15.1452 10.0393V19.5824ZM18.9695 7.8807L14.0818 3.17959C13.962 3.06339 13.8036 3 13.6345 3H7.73967C7.65163 3 7.57768 3.07395 7.57768 3.16199V4.28884C7.57768 4.37688 7.65163 4.45083 7.73967 4.45083H13.3141L17.7194 8.6871V18.2936C17.7194 18.3816 17.7933 18.4555 17.8814 18.4555H19.0082C19.0963 18.4555 19.1702 18.3816 19.1702 18.2936V8.34553C19.1667 8.16945 19.0963 8.00395 18.9695 7.8807Z"
      fill="#8BC34A"
    />
  </SvgIcon>
);

export default FileDiffIcon;
