import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  Button,
  LinearProgress,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  FormLabel,
  Radio,
  RadioGroup,
  TextareaAutosize,
} from '@material-ui/core';
import { PageWithHeader, Content } from '@backstage/core-components';
import BreadcrumbsNav from '../common/BreadcrumbsNav/BreadcrumbsNav';
import HistoryIcon from '@material-ui/icons/History';
import ContentCopyIcon from '@material-ui/icons/ContentCopy';
import { HelpComponent } from '../utils/helpComponent';
import HELP_URL from '../utils/helpLinkConstant';
import BackButton from '../utils/backButton';
import RenderedView from './DetailsPage';
import Crypto from 'crypto';
import TestHistory from './TestHistory';
import LinkIcon from '@material-ui/icons/Link';
import { urlRegex } from '../utils/Utils';
import { useNavigate } from 'react-router';
import {
  VisualRegressionApi,
  visualRegressionApiRef,
} from '../../apis/visualRegression';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';
import { useApi } from '@backstage/core-plugin-api';
import DetailsPage from './DetailsPage';
import { CopyToClipboardButton } from '../techdocs-utils';
import { Alert } from '@material-ui/lab';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';
import { useStyles } from './common/styles';

const VisualRegressionPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedBtn, setSelectedBtn] = useState('difference');
  const [referenceUrl, setReferenceUrl] = useState('');
  const [referenceUrlError, setReferenceUrlError] = useState('');

  const [testUrl, setTestUrl] = useState('');
  const [testUrlError, setTestUrlError] = useState('');

  const [productname, setProductname] = useState('');
  const [productNameError, setProductNameError] = useState('');

  const [testEnv, setTestEnv] = useState('');
  const [testEnvError, setTestEnvError] = useState('');

  const [referenceEnv, setReferenceEnv] = useState('');
  const [referenceEnvError, setReferenceEnvError] = useState('');

  const [serviceaccount, setServiceaccount] = useState('');
  const [serviceaccountError, setServiceaccountError] = useState('');

  const [inProgress, setInProgress] = useState(false);

  const [isauthreqd, setAuthreqd] = useState(false);
  const [ispopuplogin, setPopupLogin] = useState('false');

  const [responseData, setResponseData] = useState('');
  const [isTimeOutError, setTimeOutError] = useState<boolean>(false);

  const [checkResult, setCheckResult] = useState('');
  const [servicesAccount, setServicesAccount] = useState([]);

  const [additionalStep, setAdditionalStep] = useState('browserPopup');
  const [xPath, setXPath] = useState('');
  const [xPathError, setxPathError] = useState('');

  const visualRegressionApi: VisualRegressionApi = useApi(
    visualRegressionApiRef,
  );
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const navigateToTestHistory = (data: boolean) => {
    navigate('/visual-regression/view-history', { state: data });
  };

  useEffect(() => {
    visualRegressionApi
      .getserviceAccount()
      .then(res => {
        setServicesAccount(res?.data?.data);
        handleServiceAccountChange(res?.data.data[0]);
      })
      .catch((err: any) => {
        setInProgress(false);
        notificationApi.sendNotification({
          message: err.message,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  }, []);

  const handleRefUrlChange = (event: any) => {
    setReferenceUrl(event.target.value);
    setReferenceUrlError('');
    setCheckResult('');
  };

  const handleXpathChange = (event: any) => {
    setXPath(event.target.value);
    setxPathError('');
    setCheckResult('');
  };

  const handleProductNameChange = (event: any) => {
    setProductname(event.target.value);
    setProductNameError('');
    setCheckResult('');
  };

  const handleTestUrlChange = (event: any) => {
    setTestUrl(event.target.value);
    setTestUrlError('');
    setCheckResult('');
    setTimeOutError(false);
  };
  const handleTestEnvChange = (event: any) => {
    setTestEnv(event.target.value);
    setTestEnvError('');
    setCheckResult('');
  };
  const handleReferenceEnvChange = (event: any) => {
    setReferenceEnv(event.target.value);
    setReferenceEnvError('');
    setCheckResult('');
  };
  const handleServiceAccountChange = (value: string) => {
    setServiceaccount(value);
  };
  const handlePopupLoginChange = (event: any) => {
    setPopupLogin(event.target.value);
  };
  const onReset = () => {
    setServiceaccount('');
    setServiceaccountError('');
    setPopupLogin('');
    setReferenceEnv('');
    setReferenceEnvError('');
    setTestEnv('');
    setTestEnvError('');
    setTestUrl('');
    setTestUrlError('');
    setProductname('');
    setProductNameError('');
    setReferenceUrl('');
    setReferenceUrlError('');
    setXPath('');
    setxPathError('');
  };

  const handleSubmitClick = () => {
    setCheckResult('');
    setResponseData('');
    setTimeOutError(false);

    // const refUrl = referenceUrl.replace('https://', '').replace('http://', '');
    // const refUrlVal = `https://${refUrl}`;

    // const testUrlValue = testUrl.replace('https://', '').replace('http://', '');
    // const testUrlVal = `https://${testUrlValue}`;

    if (!testUrl.match(urlRegex)) {
      setTestUrlError('Url is not valid');
    }
    if (!referenceUrl.match(urlRegex)) {
      setReferenceUrlError('Url is not valid');
    }

    if (
      referenceUrl.match(urlRegex) &&
      testUrl.match(urlRegex) &&
      productname &&
      testEnv &&
      referenceEnv &&
      !xPathError
    ) {
      setInProgress(true);
      visualRegressionApi
        .postVisualRegression({
          referUrl: referenceUrl,
          testUrl: testUrl,
          productname: productname,
          testenv: testEnv,
          referenceenv: referenceEnv,
          isauthreqd: isauthreqd?.toString(),
          serviceaccount: serviceaccount,
          //ispopuplogin: ispopuplogin,
          additionalStep:
            (additionalStep === 'emailIdBrowserPopup' || 
              additionalStep === 'clickButtonwithPopup' ||
              additionalStep === 'clickButton')
              ? 'true'
              : 'false',
          browserPopup:
            isauthreqd && additionalStep === 'browserPopup' ? 'true' : 'false',
          clickButton:
            isauthreqd &&
            (additionalStep === 'clickButton' ||
              additionalStep === 'clickButtonwithPopup')
              ? 'true'
              : 'false',
          clickButtonwithPopup:
            isauthreqd && additionalStep === 'clickButtonwithPopup'
              ? 'true'
              : 'false',
          xpath: encodeURI(xPath),
        })
        .then(res => {
          setInProgress(false);
          setResponseData(res?.data);
          // setRenderImg(res?.data?.diffAmount)
          navigateToTestHistory(true);
          setCheckResult(res?.data.diffAmount > 0 ? 'Failure' : 'Success');
          notificationApi.sendNotification({
            message: ` Request Placed Successfully.
            Your test request has been placed successfully, you
            will receive the result of test in your email.`,
            disapperAfterMs: 5000,
            severity: 'success',
          });
        })
        .catch((err: any) => {
          setInProgress(false);
          setCheckResult('');
          setTimeOutError(true);
          notificationApi.sendNotification({
            message: err?.response?.data?.message || err.message,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });
    }
  };

  const validateForm = () => {
    // const refUrl = referenceUrl.replace('https://', '').replace('http://', '');
    // const refUrlVal = `https://${refUrl}`;

    // const testUrlValue = testUrl.replace('https://', '').replace('http://', '');
    // const testUrlVal = `https://${testUrlValue}`;

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    referenceUrl.match(urlRegex)
      ? setReferenceUrlError('')
      : setReferenceUrlError('Url is not Valid');

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    testUrl.match(urlRegex)
      ? setTestUrlError('')
      : setTestUrlError('Url is not valid');

    if (productname === '') {
      setProductNameError('Please enter product name');
    } else {
      setProductNameError('');
    }
    if (testEnv === '') {
      setTestEnvError('Please select test environment');
    } else {
      setTestEnvError('');
    }
    if (referenceEnv === '') {
      setReferenceEnvError('Please select reference environment');
    } else {
      setReferenceEnvError('');
    }
    if (isauthreqd && serviceaccount === '') {
      setServiceaccountError('Please select service account');
    } else {
      setServiceaccountError('');
    }
    if (isauthreqd && additionalStep != 'browserPopup' && xPath === '') {
      setxPathError('Please enter Xpath');
    } else {
      setxPathError('');
    }
    handleSubmitClick();
  };

  const onRadioButtonHandleChange = (event: any) => {
    setAdditionalStep(event.target.value);
  };

  const StyledRadio = (props: any) => {
    return (
      <div className={classes.styledRadio}>
        <div className={classes.styledRadioAndLabel}>
          {' '}
          <Radio label="Top" labelPlacement="top" color="default" {...props} />
          <Typography className={classes.styledRadioAndLabelPad}>
            {props.label}
          </Typography>
        </div>
        <img
          src={`/images/${props.name}.png`}
          alt={props.name}
          title={props.name}
        />
      </div>
    );
  };

  return (
    <PageWithHeader
      title="Visual Regression Testing "
      themeId="home"
      subtitle={
        <DevxBreadCrumb
          routes={[
            { type: 'link', link: '/', text: 'Home' },
            {
              type: 'text',
              link: '/Visual-regression',
              text: 'Visual-regression',
            },
          ]}
        />
      }
    >
      <Content>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <BackButton />
            <HelpComponent helpUrl={HELP_URL.VISUAL_REGRESSION} />
          </Grid>
          <Grid item xs={12} sm={12} className={classes.headingBar}>
            <Typography className={classes.headingText}>New Test</Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={() => navigateToTestHistory(false)}
              className={classes.testHistoryButton}
              startIcon={<HistoryIcon />}
            >
              View History
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.alert}>
            <Alert severity="info" className={classes.alertBox}>
              <span className={classes.bold}>Please Note. </span>This test takes
              screenshots of both the reference and test url pages and compare
              it pixel to pixel, then outputs the difference percentage. The
              percentage is calculated based on the average difference score per
              pixel, and it provides a measure of overall dissimilarity between
              the images. Extra care for pages with significant whitespace will
              see smaller percentage differences indicating large changes.
              Additionally keep in mind that the calculation is based on the
              internal algorithm and may not always align with the human
              perception. For additional questions or concerns please reach out
              to the Dev X team.
            </Alert>
          </Grid>
        </Grid>
        {inProgress && <LinearProgress />}

        <div className={`codePage ${classes.visualCodePage}`}>
          <div className={classes.products}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Product Name"
                  required
                  fullWidth
                  variant="outlined"
                  size="small"
                  multiline
                  value={productname}
                  onChange={handleProductNameChange}
                  {...(productNameError && {
                    error: true,
                    helperText: productNameError,
                  })}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12} className={classes.referencePage}>
                <Grid item xs={12} sm={12} md={9} lg={9}>
                  <TextField
                    id="link"
                    name="link"
                    fullWidth
                    required
                    label="Reference URL"
                    variant="outlined"
                    multiline
                    value={referenceUrl}
                    onChange={handleRefUrlChange}
                    {...(referenceUrlError && {
                      error: true,
                      helperText: referenceUrlError,
                    })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LinkIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    required
                    {...(referenceEnvError && {
                      error: true,
                      helperText: referenceEnvError,
                    })}
                  >
                    <InputLabel id="type" className={classes.inputLabelBG2}>
                      Reference Environment
                    </InputLabel>
                    <Select
                      labelId="type"
                      name="type"
                      label="Type"
                      value={referenceEnv}
                      onChange={e => handleReferenceEnvChange(e)}
                    >
                      <MenuItem value={'DEV'}>DEV</MenuItem>
                      <MenuItem value={'UAT'}>UAT</MenuItem>
                      <MenuItem value={'PROD'}>Production</MenuItem>
                    </Select>
                    {referenceEnvError && (
                      <Typography className={classes.fieldError}>
                        {referenceEnvError}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} className={classes.referencePage}>
                <Grid item xs={12} sm={12} md={9} lg={9}>
                  <TextField
                    id="test url"
                    name="test url"
                    fullWidth
                    required
                    label="Test URL"
                    variant="outlined"
                    multiline
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LinkIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={testUrl}
                    onChange={handleTestUrlChange}
                    {...(testUrlError && {
                      error: true,
                      helperText: testUrlError,
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <FormControl fullWidth variant="outlined" required>
                    <InputLabel id="type" className={classes.inputLabelBG2}>
                      Test Environment
                    </InputLabel>
                    <Select
                      labelId="type"
                      name="type"
                      label="Type"
                      value={testEnv}
                      {...(testEnvError && {
                        error: true,
                        helperText: testEnvError,
                      })}
                      onChange={e => handleTestEnvChange(e)}
                    >
                      <MenuItem value={'DEV'}>DEV</MenuItem>
                      <MenuItem value={'UAT'}>UAT</MenuItem>
                      <MenuItem value={'PROD'}>Production</MenuItem>
                    </Select>
                    {testEnvError && (
                      <Typography className={classes.fieldError}>
                        {testEnvError}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid container className={classes.pages}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isauthreqd}
                        onChange={e => setAuthreqd(e.target.checked)}
                      />
                    }
                    label="Requires Authentication"
                  />
                </FormGroup>
              </Grid>
              {isauthreqd && (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Alert severity="info" className={classes.alertBox}>
                      Please make sure the following service account has access
                      to your application for seamless Visual Regression
                      testing.
                    </Alert>
                  </Grid>
                  <Grid item xs={12} sm={12} className={classes.formContent}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        required={isauthreqd}
                      >
                        {servicesAccount && (
                          <TextField
                            variant="outlined"
                            disabled={!!servicesAccount}
                            value={serviceaccount}
                          />
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <CopyToClipboardButton text={serviceaccount} />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Select steps required in login page
                      </FormLabel>
                      <RadioGroup
                        aria-label="additionalStep"
                        name="additionalStep"
                        value={additionalStep}
                        onChange={onRadioButtonHandleChange}
                        className={classes.radioGroup}
                      >
                        <FormControlLabel
                          value="browserPopup"
                          control={
                            <StyledRadio
                              name={'Browser-Popup'}
                              label="Browser Popup"
                            />
                          }
                          label=""
                        />

                        <FormControlLabel
                          value="clickButton"
                          control={
                            <StyledRadio
                              name={'Click-Button'}
                              label="Click Button"
                            />
                          }
                          label=""
                        />

                        <FormControlLabel
                          value="clickButtonwithPopup"
                          control={
                            <StyledRadio
                              name={'Click-Button-Popup'}
                              label="Click Button > Browser Popup"
                            />
                          }
                          label=""
                        />
                        <FormControlLabel
                          value="emailIdBrowserPopup"
                          control={
                            <StyledRadio
                              name={'EmailId-Browser-Popup'}
                              label="Email Id > Browser Popup"
                            />
                          }
                          label=""
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  {additionalStep !== 'browserPopup' &&
                  additionalStep !== 'emailIdBrowserPopup' ? (
                    <>
                      <Grid item md={6}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          required={isauthreqd}
                        >
                          <TextareaAutosize
                            value={xPath}
                            onChange={handleXpathChange}
                            aria-label="minimum height"
                            minRows={6}
                            placeholder="XPath of Clickable Button"
                            style={xPathError ? { borderColor: 'red' } : ''}
                          />
                          {xPathError && (
                            <Typography className={classes.errortext}>
                              {xPathError}
                            </Typography>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item md={6}>
                        <Typography>Steps to copy XPath in browser:</Typography>
                        <Typography>
                          1. Right-click "inspect" on the item you are trying to
                          find the XPath.
                        </Typography>
                        <Typography>
                          2. Right-click on the highlighted area on the HTML
                          DOM.
                        </Typography>
                        <Typography>
                          3. Go to Copy and select 'Copy XPath'
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </>
              )}
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={12} className={classes.btnGroupMain}>
                <div className={classes.btnGroup}>
                  <Button color="primary" variant="outlined" onClick={onReset}>
                    Reset
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    onClick={validateForm}
                    className={classes.testButton}
                  >
                    Test UI
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Content>
    </PageWithHeader>
  );
};
export default VisualRegressionPage;
