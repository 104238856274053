import { AxiosInstance, AxiosResponse } from 'axios';
import { createApiRef } from '@backstage/core-plugin-api';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';

export interface FeedbackApi {
  setFeedbackLike: (
    feedbackId: string,
    liked: boolean,
    username: string,
  ) => Promise<AxiosResponse>;
  getAllFeedbacks: (username: any) => Promise<AxiosResponse>;
  getAllCategories: () => Promise<AxiosResponse>;
  saveFeedback: (
    username: string,
    feedbackType: string,
    feebackCategory: string,
    title: string,
    feedback: string,
  ) => Promise<AxiosResponse>;
  reviewFeedback: (
    username: string,
    status: string,
    category: string,
    feedbackid: string,
  ) => Promise<AxiosResponse>;
  getFeedbackWithId: (feedbackId: string) => Promise<any>;
}

export const feedbackApiRef = createApiRef<FeedbackApi>({
  id: 'cbre.devx.api.feedback',
});

export class FeedbackApiImpl implements FeedbackApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  async setFeedbackLike(
    feedbackId: string,
    liked: boolean,
    username: string,
  ): Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;

    return instance.post(`service/feedback/savefeedbacklike`, {
      id: Number(feedbackId),
      username: username,
      liked: liked,
    });
  }

  async getAllFeedbacks(username: any): Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;

    return instance.get(`service/feedback/getallfeedback`, {
      params: { username: username },
    });
  }

  async getAllCategories(): Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;
    return instance
      .request({
        method: 'get',
        url: `service/feedback/getallcategories`,
      })
      .then(res => res?.data)
      .catch(err => {
        throw new Error(
          `Error while getting feedback categories ${err.message}`,
        );
      });
  }

  async saveFeedback(
    username: string,
    feedbackType: string,
    category: string,
    title: string,
    feedback: string,
  ): Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;

    return instance.post(`service/feedback/savefeedback`, {
      username,
      feedbackType,
      category,
      title,
      feedback,
    });
  }

  async reviewFeedback(
    username: string,
    status: string,
    category: string,
    feedbackid: string,
  ): Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;

    return instance.put(`service/feedback/reviewfeedback/${feedbackid}`, {
      statusapprover: username,
      category,
      status,
    });
  }

  async getFeedbackWithId(feedbackId: string): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance
      .get(`service/feedback/getfeedback/${feedbackId}`)
      .then(res => res.data);
  }
}
