import React from 'react';

const SoftwareTemplate = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.75 3.75H6.25C4.8625 3.75 3.75 4.875 3.75 6.25V23.75C3.75 25.125 4.8625 26.25 6.25 26.25H23.75C25.125 26.25 26.25 25.125 26.25 23.75V6.25C26.25 4.875 25.1375 3.75 23.75 3.75ZM23.75 23.75H6.25V8.75H23.75V23.75ZM21.25 15H8.75V12.5H21.25V15ZM16.25 20H8.75V17.5H16.25V20Z"
        fill="#17E88F"
      />
    </svg>
  );
};

export default SoftwareTemplate;
