import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useGetConversionApi } from '../../utils/useGetConversionApi';
import { usePostConversionApi } from '../../utils/usePostConversionApi';
import {
  REVIEWER_COMMENTS_HEADING,
  PUBLISHER_COMMENTS_HEADING,
} from '../../utils/constant';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { useApi } from '@backstage/core-plugin-api';

const useStyles = makeStyles({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
  },
  gridItemCardContent: {
    flex: 1,
  },
  comments: {
    fontFamily: 'Calibre-R-Regular',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#5E5E5E',
  },
  commentDate: {
    fontFamily: 'Calibre-R-Regular',
    fontSize: '9px',
    lineHeight: '12px',
    letterSpacing: '0.03em',
  },
  textBottomlabel: {
    fontSize: '10px',
    lineHeight: 1.5,
    fontWeight: 600,
    letterSpacing: 0.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontFamily: 'Calibre-R-Regular',
    color: '#6B778C',
    display: 'flex',
  },
  textBottomRight: {
    marginLeft: 'auto',
    marginRight: 0,
  },
  errorMsgText: {
    fontFamily: 'Calibre-R-Regular',
    fontSize: '10px',
    lineHeight: 1.5,
    fontWeight: 600,
    color: 'red',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fff',
    border: '2px solid #000',
    boxShadow: '4px 0px 10px rgba(0, 0, 0, 0.2)',
    padding: '16px 32px 24px',
    minHeight: 200,
  },
  resubmitBtnContainer: {
    paddingTop: '15px',
    float: 'right',
    paddingBottom: '20px',
  },
  resubmitBtn: {
    marginRight: '4px',
  },
});

type CommentsCardProps = {
  userId: string;
  entityOwner: string;
};

type CommentProps = {
  userId: string;
  entityOwner: string;
};

export function Comments({ userId, entityOwner }: CommentsCardProps) {
  const { entity } = useEntity();
  const { localEntity } = useGetConversionApi(
    entity?.metadata?.name,
    entity?.kind,
  );
  const classes = useStyles();

  let key = 1;
  let commentsArray: any[] = [];

  localEntity?.metadata?.comments.forEach(comment => {
    if (!isEmpty(comment.comments)) {
      commentsArray.push(
        <Card key={key++} className={classes.gridItemCard}>
          <CardHeader
            title={
              comment.commentedBy.toLowerCase() === 'publisher'
                ? PUBLISHER_COMMENTS_HEADING
                : REVIEWER_COMMENTS_HEADING
            }
          />
          <CardContent className={classes.gridItemCardContent}>
            <div>
              <Divider />
              <p className={classes.comments}>{comment?.comments}</p>
              <p className={classes.commentDate}>
                {comment?.user + ' - ' + comment?.date}
              </p>
            </div>
          </CardContent>
        </Card>,
      );
    }
  });

  if (localEntity?.metadata?.comments && commentsArray.length > 0) {
    return (
      <>
        {commentsArray}
        <Comment userId={userId} entityOwner={entityOwner} />
      </>
    );
  } else {
    return <div></div>;
  }
}

const Comment = ({ userId, entityOwner }: CommentProps) => {
  const { entity } = useEntity();
  const classes = useStyles();
  const [publisherComments, setPublisherComments] = React.useState('');
  const [buttonDisabled, setIsButtonDisabled] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const { localEntity, refetchEntity } = useGetConversionApi(
    entity.metadata.name,
    entity.kind,
  );
  const { saveEntity } = usePostConversionApi(
    entity.metadata.name,
    entity.kind,
    localEntity,
    true,
  );
  // const [open, setOpen] = React.useState<boolean | false>(false);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const val = event.target.value;
    if (val.length < 501) {
      setPublisherComments(val);
      setErrorMsg('');
    }
  };

  const validateComments = () => {
    let isValidate = false;
    if (publisherComments.trim().length > 0) {
      if (/^[a-zA-Z0-9.,?\s]*$/g.test(publisherComments.trim())) {
        isValidate = true;
      } else {
        setErrorMsg('Invalid character found in comments');
      }
    }
    return isValidate;
  };

  const handleSubmit = async () => {
    if (validateComments()) {
      setIsButtonDisabled(true);
      localEntity.metadata.comments.push({
        user: userId,
        date: new Date(),
        comments: publisherComments,
        commentedBy: 'Publisher',
      });
      localEntity.metadata.devxstate = 'Under Review';
      await saveEntity().then((data: any) => {
        // show success message
        notificationApi.sendNotification({
          message: `Success.. Changes saved! Changes may take upto 5 minutes to reflect.`,
          disapperAfterMs: 2500,
          severity: 'success',
          callback: refetchEntity,
        });
        return data;
      });
      setErrorMsg('');
      // setOpen(true);
    }
  };

  return (
    <Card className={classes.gridItemCard}>
      {userId.replace('user:', '').toLowerCase() ===
        entityOwner.replace('user:', '').toLowerCase() &&
      localEntity?.metadata?.devxstate === 'Resubmit' ? (
        <CardContent className={classes.gridItemCardContent}>
          <Grid item md={12} xs={12}>
            <TextField
              placeholder="Please address the comments provided by reviewer and provide your response."
              multiline
              fullWidth
              variant="outlined"
              rows={4}
              value={publisherComments}
              onChange={handleChange}
            />
            <div className={classes.textBottomlabel}>
              <div>Maximum of 500 characters</div>
              <div className={classes.textBottomRight}>
                {publisherComments.length > 0
                  ? `${publisherComments.length}/500`
                  : null}
              </div>
            </div>
            {!isEmpty(errorMsg) ? (
              <div className={classes.errorMsgText}>{errorMsg}</div>
            ) : null}
            <div></div>
            <div className={classes.resubmitBtnContainer}>
              {buttonDisabled ? (
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  onClick={handleSubmit}
                  className={classes.resubmitBtn}
                  disabled
                >
                  RESUBMIT COMPONENT
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  onClick={handleSubmit}
                  style={
                    publisherComments.trim().length <= 0
                      ? {
                          marginRight: '4px',
                          backgroundColor: '#D9D9D9',
                          color: '#000',
                        }
                      : {
                          marginRight: '4px',
                        }
                  }
                  disabled={publisherComments.trim().length <= 0}
                >
                  RESUBMIT COMPONENT
                </Button>
              )}
            </div>
          </Grid>
        </CardContent>
      ) : null}
    </Card>
  );
};
