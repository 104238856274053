import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { Theme, useTheme } from '@material-ui/core';

const MenuCollapseIcon = () => {
  const theme: Theme = useTheme();

  return (
    <SvgIcon height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
      <path
        d="M21 6L8 6L8 8L21 8L21 6ZM21 11L11 11L11 13L21 13L21 11ZM21 18L21 16L8 16L8 18L21 18ZM3 8.41L6.58 12L3 15.59L4.41 17L9.41 12L4.41 7L3 8.41Z"
        fill={`${theme.palette.primary.main}`}
      />
    </SvgIcon>
  );
};

export default MenuCollapseIcon;
