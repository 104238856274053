import React, { useContext, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  Paper,
  Grid,
  Chip,
  Typography,
  useTheme,
  Theme,
  Tooltip,
  CardActions,
} from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import ExtensionIcon from '@material-ui/icons/Extension';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import InfoIcon from '@material-ui/icons/Info';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import SubscriptionsOutlinedIcon from '@material-ui/icons/SubscriptionsOutlined';
import Rating from '@material-ui/lab/Rating';
import { useStarredEntities } from '@backstage/plugin-catalog-react';
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';
import CodeIcon from '../../../Root/icons/codeIcon';
import AIIcon from '../../../Root/icons/aiIcon';
import { Entity } from '@backstage/catalog-model';
import { useCustomStyles } from '../allAssetsStyles';
import * as constant from '../../../utils/constant';
import { AuthContext } from '../../../../providers/AuthProvider';
import { getUserLink, getUserName } from '../util';
import Constants from '../../Constants';
import { timeSince } from '../../../utils/dateUtils';
import { statusColor, capitalizeFirstLetter } from '../util';
import { subscriberApiRef } from '../../../../apis/subscriberApi';
import SalesforceIcon from '../../../Root/icons/salesforceIcon';
import { userProfileApiRef } from '../../../../apis/userProfileApi';
import { useSubscriptionCount } from '../../../common/SubscriptionCount/SubscriptionCountProvider';
import InfoBadge from '../../../common/InfoBadge';

export const displayImage = (type: string) => {
  const {
    openapi,
    utility,
    framework,
    iac,
    package: typePackage,
    salesforce,
    ai,
    extension,
  } = Constants.entityType;
  switch (type) {
    case openapi:
      return <ExtensionIcon />;
    case iac:
      return <InfoIcon />;
    case salesforce:
      return <SalesforceIcon />;
    case utility:
    case framework:
    case typePackage:
      return <CodeIcon />;
    case ai:
      return <AIIcon />;
    case extension:
      return <WidgetsOutlinedIcon />;
    default:
      return <CodeIcon />;
  }
};

const AllAssetsCard = ({
  isAdmin,
  // setAnchorElement,
  // anchorElement,
  entity,
  setClickSubscribeIcon,
  subscribeData,
  isClickSubscribeIcon,
  mountOn,
}: {
  isAdmin: boolean | null;
  setAnchorElement: any;
  anchorElement: any;
  entity: Entity;
  setClickSubscribeIcon: any;
  subscribeData: any;
  isClickSubscribeIcon: any;
  mountOn?: string;
}) => {
  const classes = useCustomStyles();
  const theme = useTheme<Theme>();
  const { isStarredEntity, toggleStarredEntity } = useStarredEntities();
  const { profEmail } = useContext(AuthContext);
  const userId = profEmail.split('@')[0].toLowerCase();
  const isOwner =
    userId === `${entity?.spec?.owner}`?.toLowerCase()?.replace('user:', '');
  const canEdit = isOwner || isAdmin;
  const name = entity.metadata.name;
  const kind = entity.kind;
  const namespace = entity.metadata.namespace;
  const path = kind?.toLowerCase() === constant.API ? 'api-edit' : 'edit';
  const navigate = useNavigate();
  const subscriberApi = useApi(subscriberApiRef);
  const userProfileApi = useApi(userProfileApiRef);
  const usernameRef = useRef(null);
  const tagsToDisplay = 2;

  const type: any = entity?.spec?.type;
  const assetStore: any = entity?.metadata?.assetstore || 'wso2';

  const timeStamp =
    entity?.metadata?.lastUpdatedDateTime ||
    entity?.metadata?.creationTimestamp ||
    Date.now();

  const onClickSubscribe = async (data: any) => {
    const payload = {
      assetName: data?.metadata?.name,
      email: profEmail,
    };
    await subscriberApi.assetsSubscribe(payload);
    setClickSubscribeIcon(!isClickSubscribeIcon);
  };

  const onClickUnSubscribe = async (data: any) => {
    const payload = {
      assetName: data?.metadata?.name,
      email: profEmail,
    };
    await subscriberApi.assetsUnsubscribe(payload);
    setClickSubscribeIcon(!isClickSubscribeIcon);
  };

  const checkSubscribe = (data: any) => {
    let isChecksubscribe = false;
    subscribeData &&
      subscribeData.map((item: any) => {
        if (
          data.metadata?.name.toLowerCase() === item?.assetName.toLowerCase() &&
          item?.email.toLowerCase() === profEmail!.toLowerCase()
        ) {
          isChecksubscribe = true;
        }
      });
    return isChecksubscribe;
  };

  const redirectToEntity = () => {
    if (mountOn) {
      navigate(`/${mountOn}/${namespace}/${kind}/${name}`);
    } else {
      navigate(`/catalog/${namespace}/${kind}/${name}`);
    }
  };

  const onMouseEnter = () => {
    let email: string | undefined = undefined;
    const ownerEmail = '' + entity?.spec?.owneremail;
    email = ownerEmail;
    if (
      ownerEmail === undefined ||
      ownerEmail === 'undefined' ||
      String(ownerEmail).trim()?.length === 0
    ) {
      const owner = getUserName(entity);
      email = `${owner}@cbre.com`;
    }
    userProfileApi.setUserProfileState({
      email,
      anchorRef: usernameRef?.current,
    });
  };

  const assetSubscriptionCountArr = useSubscriptionCount({
    name: entity?.metadata?.name || '',
  });

  let subscriberCount: number = 0;
  if (
    assetSubscriptionCountArr !== undefined &&
    Array.isArray(assetSubscriptionCountArr) &&
    assetSubscriptionCountArr.length > 0 &&
    assetSubscriptionCountArr?.[0]?.assetname === entity?.metadata?.name
  ) {
    subscriberCount = +assetSubscriptionCountArr?.[0]?.subscribercount;
  }

  return (
    <Card elevation={3} className={classes.allAssetCard}>
      <Paper className={`EntityCard ${classes.entityCard}`}>
        <Grid container spacing={0} className={classes.entityCardContainer}>
          <Grid item xs={2} className={classes.assetStoreItems}>
            {displayImage(type || 'openapi')}
            <Typography className={classes.assetStore}>
              {type === 'openapi'
                ? capitalizeFirstLetter(assetStore)
                : capitalizeFirstLetter(type)}
            </Typography>
            <Typography
              style={{
                color: `${statusColor(
                  entity?.metadata?.devxstate
                    ? `${entity?.metadata?.devxstate}`?.toLowerCase()
                    : '',
                  theme,
                )}`,
              }}
              className={classes.assetStatusClr}
            >
              {entity?.metadata?.devxstate || ''}
            </Typography>
          </Grid>
          <div className={classes.ActionIconsContainer}>
            {canEdit && (
              <Tooltip title="Edit">
                <Button
                  className={classes.CardActionButton}
                  disabled={!canEdit}
                  onClick={() => {
                    if (mountOn) {
                      navigate(
                        `/${mountOn}/${namespace}/${kind}/${name}/${path}`,
                      );
                    } else {
                      navigate(`/catalog/${namespace}/${kind}/${name}/${path}`);
                    }
                  }}
                >
                  <EditRoundedIcon
                    title="Edit"
                    className={classes.SvgColorFill}
                  />
                </Button>
              </Tooltip>
            )}
            <Tooltip title="Bookmark">
              <Button className={classes.CardActionButton}>
                {isStarredEntity(entity) ? (
                  <BookmarkIcon
                    onClick={() => {
                      toggleStarredEntity(entity);
                    }}
                    className={classes.SvgColorFill}
                  />
                ) : (
                  <BookmarkBorderOutlinedIcon
                    onClick={() => {
                      toggleStarredEntity(entity);
                    }}
                    className={classes.SvgColorFill}
                  />
                )}
              </Button>
            </Tooltip>
            {!!subscribeData && (
              <Tooltip title="Subscribe">
                <Button className={classes.CardActionButton} disabled={isOwner}>
                  {subscribeData && checkSubscribe(entity) ? (
                    <SubscriptionsIcon
                      tooltip="Unsunscribe"
                      onClick={() => onClickUnSubscribe(entity)}
                      className={
                        isOwner
                          ? classes.SvgColorFillDisabled
                          : classes.SvgColorFill
                      }
                    />
                  ) : (
                    <SubscriptionsOutlinedIcon
                      tooltip="Subscribe"
                      onClick={() => onClickSubscribe(entity)}
                      className={
                        isOwner
                          ? classes.SvgColorFillDisabled
                          : classes.SvgColorFill
                      }
                    />
                  )}
                </Button>
              </Tooltip>
            )}
          </div>
        </Grid>
        <CardContent className={classes.CardContent}>
          <Typography component="h3" className={classes.allassetCardContent}>
            {entity?.metadata?.name.length > 20
              ? entity?.metadata?.name.substring(0, 20) + '...'
              : entity?.metadata?.name}
          </Typography>
          <div onMouseEnter={onMouseEnter} ref={usernameRef}>
            <Link
              className="user"
              title={getUserLink(entity)}
              to={getUserLink(entity)}
            >
              <span>
                <Typography className={classes.userLink} component="span">
                  by{' '}
                </Typography>
                <Typography className={classes.userName} component="span">
                  {getUserName(entity)}
                </Typography>
              </span>
            </Link>
          </div>

          <div className={classes.entityRating}>
            <Rating
              value={Number(entity?.metadata?.avgrating)}
              precision={0.5}
              disabled
              size="small"
              name="rating"
            />
            {/* {kind?.toLowerCase() === 'component' && (
              <div style={{ marginLeft: '8px' }}>
                <SonarScanInfoCard entity={entity} mountOn={mountOn} />
              </div>
            )} */}
            {kind?.toLowerCase() === 'api' && assetStore === 'wso2' && (
              <div className={classes.infoBadge}>
                <InfoBadge label="Consumers" value={`${subscriberCount}`} />
              </div>
            )}
          </div>

          <Typography className={classes.assetCardDescription}>
            {`${entity?.metadata?.description}`.length > 200
              ? `${entity?.metadata?.description?.substring(0, 200)}...`
              : `${entity?.metadata?.description}`}
          </Typography>
        </CardContent>
        <CardActions>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              classes={{ root: classes.tagsContainer }}
            >
              {entity?.metadata?.tags &&
              entity?.metadata?.tags.length > 0 &&
                entity?.metadata?.tags
                  .slice(0, tagsToDisplay)
                  .map((tag: any, idx: any) => (
                    <Chip
                      label={tag}
                      color="default"
                      key={`all-asset-card-tags-${entity?.metadata?.name}-${idx}`}
                      className={classes.assetCardTags}
                    />
                  ))}
              {entity?.metadata?.tags?.length > tagsToDisplay && (
                <Chip
                  label={`+${entity?.metadata?.tags?.length - tagsToDisplay}`}
                  size="small"
                  classes={{ root: classes.assetCardTags }}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container className={classes.timeStampExplore}>
                <div>
                  <Typography className={classes.assetcardTimeStamp}>
                    {`${timeSince(timeStamp)}`}
                  </Typography>
                </div>
                <div>
                  <Button
                    title={entity?.metadata?.name}
                    onClick={redirectToEntity}
                    className={classes.exploreBtn}
                  >
                    Explore ➔
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </CardActions>
      </Paper>
    </Card>
  );
};
export default AllAssetsCard;
