import React, { useContext, useEffect, useState } from 'react';
import Scopes from '../common/Scopes';
import { useNavigate, useParams } from 'react-router';
import { PromptContext } from '../providers/PromptProvider';
import { AuthContext } from '../../../../providers/AuthProvider';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import { promptApiRef } from '../../../../apis/promptManagementApi';
import { useApi } from '@backstage/core-plugin-api';
import { useStyles } from '../styles';
import { Autocomplete } from '@material-ui/lab';
import { useGetTags } from '../hooks/useGetTags';

const AddEditPrompt = ({
  hidden,
  onSave,
  onCancel,
  pageLoader,
  onEdit,
}: {
  hidden: boolean;
  onSave: Function;
  onEdit: Function;
  onCancel: Function;
  pageLoader: boolean;
}) => {
  const { domainId, subDomainId, personaId } = useParams();
  const { domains, editModeData } = useContext(PromptContext);
  const navigate = useNavigate();
  const promptApi = useApi(promptApiRef);
  const classes = useStyles();
  const { profEmail } = useContext(AuthContext);

  const [data, setData] = useState({
    formTitle: 'Add Prompt',
    submitButtonText: 'Save',
    promptKey: '',
    promptDetail: '',
    tags: [],
  });

  /* Tags related state variables */
  const [allTags] = useGetTags(true);

  useEffect(() => {
    if (editModeData)
      setData({
        formTitle: 'Edit Prompt',
        submitButtonText: 'Save',
        promptKey: editModeData.promptkey,
        promptDetail: editModeData.promptdetail,
        tags: editModeData.tags || [],
      });
  }, [editModeData]);

  const handleSaveClick = () => {
    const promptRequestObj = {
      product: domainId,
      subproduct: subDomainId,
      persona: [personaId],
      promptkey: data.promptKey,
      promptdetail: data.promptDetail,
      authoremail: profEmail,
      authordisplayname: profEmail.split('@')[0].split('.').join(' '),
      tags: data.tags,
    };
    if (editModeData) {
      onEdit(
        {
          ...editModeData,
          promptkey: data.promptKey,
          promptdetail: data.promptDetail,
          tags: data.tags,
        },
        editModeData.id,
        'edit',
        'Prompt Edited Succesfully',
      );
    } else {
      onSave({
        prompts: [promptRequestObj],
        force: false,
      });
    }
  };

  const [loading, setLoading] = useState(false);

  const generateSummary = async () => {
    setLoading(true);
    try {
      const result = await promptApi.suggestedPrompt({
        text: data.promptDetail,
        word_limit: 8,
      });

      if (result) {
        const temp = data;
        setData({
          ...temp,
          promptKey: result.text,
        });
        setLoading(false);
      }
    } catch {
      setLoading(false);
    }
  };

  const handleGetAIPrompt = () => {
    if (data.promptKey === '' || data.promptDetail !== '') {
      generateSummary();
    }
  };

  const handleScopeChange = (scope: any) => {
    if (scope.personaId && scope.subDomainId && scope.domainId)
      navigate(
        `/admin/manage-prompts/${scope.domainId}/${scope.subDomainId}/${scope.personaId}/prompts`,
        { replace: true },
      );
    else if (scope.subDomainId && scope.domainId)
      navigate(
        `/admin/manage-prompts/${scope.domainId}/${scope.subDomainId}/prompts`,
        { replace: true },
      );
    else if (scope.domainId)
      navigate(`/admin/manage-prompts/${scope.domainId}/prompts`, {
        replace: true,
      });
  };

  const onChangeTags = (_: any, selectedTags: any) => {
    //  if (selectedTags.length <= 5) {
    //   setTagsError(undefined);
    setData({ ...data, tags: selectedTags });
    //   } else {
    //     setTagsError('Maximum 5 tags');
    //  }
  };

  return (
    <Grid container style={hidden ? { display: 'none' } : { display: 'block' }}>
      <Grid
        item
        xs={12}
        className={`${classes.pagePadding} ${classes.flexSpacebetween}`}
      >
        <Typography component="h2" variant="h5">
          {data.formTitle}
        </Typography>
        <div className={`${classes.flex} ${classes.gap2}`}>
          <Button
            onClick={() => onCancel()}
            data-testid="no-btn"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSaveClick()}
            disabled={
              pageLoader ||
              !data.promptDetail ||
              !data.promptKey ||
              !data.tags.length
            }
          >
            {data.submitButtonText}
          </Button>
        </div>
      </Grid>

      {/* {pageLoader && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )} */}
      <Grid item xs={12} md={8} lg={9}>
        <Grid container>
          <Grid item xs={12}>
            <Scopes
              variant="outlined"
              inputScope={{ domainId, subDomainId, personaId }}
              domains={domains}
              onChange={handleScopeChange}
              disabled
              required
              isTransParent={false}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              value={data.promptDetail}
              label="Prompt"
              //   InputLabelProps={{ shrink: data.promptDetail !== '' }}
              variant="outlined"
              fullWidth
              size="small"
              multiline
              minRows={10}
              maxRows={10}
              onChange={(event: any) => {
                const newData = { ...data };
                setData({
                  ...newData,
                  promptDetail: event.target.value,
                });
              }}
              style={{
                marginTop: '20px',
              }}
              className={classes.backGroundColor}
            />
          </Grid>

          <Grid
            item
            xs={12}
            className={`${classes.flexCenter} ${classes.buttonLoaderSection}`}
          >
            <Button
              onClick={() => handleGetAIPrompt()}
              data-testid="yes-btn"
              color="primary"
              variant="contained"
              disabled={loading}
            >
              Get Prompt Key
            </Button>
            {loading && <CircularProgress color="inherit" size={20} />}
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              value={data.promptKey}
              label="Prompt Key"
              InputLabelProps={{ shrink: data.promptKey !== '' }}
              variant="outlined"
              fullWidth
              disabled={loading}
              size="small"
              onChange={(event: any) => {
                const newData = { ...data };
                setData({
                  ...newData,
                  promptKey: event.target.value,
                });
              }}
              style={{
                marginTop: '20px',
              }}
              className={classes.backGroundColor}
            />
          </Grid>
          <Grid item xs={12} className={classes.infoSection}>
            <InfoOutlinedIcon />
            <span className={classes.infoContent}>
              Above response was generated by AI. AI generated answers are not
              always accurate and need to be reviewed and validated.
            </span>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              className={`${classes.backGroundColor} ${classes.tags}`}
              multiple
              size="small"
              onChange={onChangeTags}
              options={allTags || []}
              getOptionLabel={(option: any) =>
                option.tag ? option.tag : option
              }
              value={data.tags}
              renderInput={params => (
                <TextField
                  required
                  {...params}
                  inputProps={{ ...params.inputProps, maxLength: 18 }}
                  variant="outlined"
                  label="Type to select tags"
                />
              )}
              disableCloseOnSelect
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddEditPrompt;
