/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { useEffect, useState } from 'react';
import { feedbackApiRef } from '../../../apis/feedbackApis';

export const useGetCategories = (shouldcall: boolean) => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const feedbackApi = useApi(feedbackApiRef);

  const [categories, setCategories] = useState(null);

  const fetchMyAPI = async () => {
    if (feedbackApi) {
      try {
        const response: any = await feedbackApi.getAllCategories();
        if (response) {
          setCategories(response.data);
        }
      } catch (error) {
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };

  useEffect(() => {
    if (shouldcall) fetchMyAPI();
  }, [shouldcall]);

  return [categories] as const;
};
