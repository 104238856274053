import React, { useEffect, useState, useContext } from 'react';
import {
  TableColumn,
  Table,
  WarningPanel,
  CodeSnippet,
  TableProps,
  Progress,
} from '@backstage/core-components';
import {
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
  Button,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

import * as columnFactories from '../../utils/devXAdminTableColumns';
import {
  CHECK_USER_ROLE,
  DELETE_CONFIRMATION_TITLE,
  DELETE_CONFIRMATION_MESSAGE,
} from '../../utils/constant';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import AddAdminReviewer from '../addAdminReviewer';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import BackButton from '../../utils/backButton';
import { PromotionContext } from '../../../providers/PromotionDataProvider';
import { useApi } from '@backstage/core-plugin-api';
import { adminApiRef } from '../../../apis/adminApi';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    DialogBox: {
      minWidth: 300,
    },
    manageAdmins: {
      ['@media (max-width:920px) and (min-width: 280px)']: {
        padding: '0',
      },
    },
    adminsTable: {
      ['@media (max-width:920px) and (min-width: 280px)']: {
        marginTop: '20px',
      },
    },
    themeColor: {
      '& tr': {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.background.default + '!important',
        '& td ': {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: theme.palette.background.default + '!important',
          '& button ': {
            color: theme.palette.primary.main,
          },
        },
        '& th': {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: theme.palette.background.default + '!important',
        },
      },
      '& div::-webkit-scrollbar-thumb': {
        border: `4px solid ${theme.palette.text.tertiary}`,
      },
    },
  }),
);

const columns: TableColumn<columnFactories.EntityRow>[] = [
  columnFactories.createUserColumn(),
  columnFactories.createFirstNameColumn(),
  columnFactories.createLastNameColumn(),
  columnFactories.createEmailColumn(),
  columnFactories.createDateCreatedColumn(),
];

export interface ManageAdminsProps {
  userEmail?: string;
}

export const ManageAdmins = (props: ManageAdminsProps) => {
  const { userEmail } = props;
  const classes = useStyles();
  const [isAdminAdded, setAdminAdded] = useState<any>(false);
  const [adminList, setAdminList] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [deleteUserInfo, setDeleteUserInfo] = useState<any>();

  const adminApi = useApi(adminApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const promotionContext = useContext(PromotionContext);
  useEffect(() => {
    promotionContext.setToggleHelpButton(false);
  }, []);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const refresh = () => {
    adminApi
      .getAdminData()
      .then(data => {
        setAdminList({
          responseData: (data && data) || {},
          status: 'fetched',
        });
      })
      // @ts-ignore
      .catch((error: any) => {
        setAdminList({
          responseData: {},
          status: 'Error',
        });
        notificationApi.sendNotification({
          message: `Error occurred - ${error.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  useEffect(() => {
    refresh();
  }, []);

  const data =
    (adminList &&
      adminList?.responseData?.filter(
        (item: any) =>
          item.isActive?.toLowerCase() === CHECK_USER_ROLE.toLowerCase(),
      )) ||
    [];

  const deleteActionButtonClicked = (user: any) => {
    setDeleteUserInfo(user);
    openDialog();
  };

  const YesButtonClicked = () => {
    closeDialog();

    adminApi
      .deleteAdmin(deleteUserInfo?.user)
      .then(data => {
        notificationApi.sendNotification({
          message: `Record has been successfully deleted`,
          disapperAfterMs: 2500,
          severity: 'success',
          callback: refresh,
        });
        return data;
      })
      .catch(() => {
        notificationApi.sendNotification({
          message: `Record cannot be deleted`,
          disapperAfterMs: 2500,
          severity: 'error',
          callback: refresh,
        });
      });
  };

  const defaultActions: TableProps<columnFactories.EntityRow>['actions'] = [
    user => {
      return {
        icon: () => <DeleteIcon data-testid={`delete-icon-${user?.user}`} />,
        tooltip: 'Delete',
        onClick: () => deleteActionButtonClicked(user),
        disabled: user?.user?.toLowerCase() === userEmail ? true : false,
      };
    },
  ];

  const loading = adminList?.status === 'idle' ? true : false;

  if (adminList?.status === 'Error') {
    return (
      <div>
        <WarningPanel severity="error" title="Could not fetch Admin list">
          <CodeSnippet language="text" text="Error" />
        </WarningPanel>
      </div>
    );
  }

  if (adminList?.status === 'idle' || !data) {
    return <Progress />;
  }

  return (
    <Grid container direction="row" className="admin-reviewer-page">
      {open && (
        <Dialog
          open={open}
          onClose={closeDialog}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
          className={classes.DialogBox}
          fullWidth={false}
        >
          <DialogTitle id="dialog-title">
            {DELETE_CONFIRMATION_TITLE}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={closeDialog}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <p>{DELETE_CONFIRMATION_MESSAGE}</p>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={closeDialog}
              color="primary"
              variant="contained"
              autoFocus
              disableRipple
            >
              No
            </Button>
            <Button
              onClick={YesButtonClicked}
              color="primary"
              variant="contained"
              data-testid="delete-confirm-yes-btn"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Grid item xs={12}>
        <Grid item md={12} xs={12}>
          <BackButton />
          <HelpComponent helpUrl={HELP_URL.MANAGE_ADMINS} />
        </Grid>
        <div className={`admin-page codePage ${classes.manageAdmins}`}>
          <AddAdminReviewer
            tabId="AdminTab"
            setAdminAdded={setAdminAdded}
            isAdminAdded={isAdminAdded}
            userListData={data}
            callback={refresh}
            {...props}
          />
          <div
            className={`mystuff-table ${classes.adminsTable} ${classes.themeColor} `}
          >
            <Table
              isLoading={loading}
              columns={columns}
              options={{
                paging: true,
                pageSize: 10,
                search: true,
                actionsColumnIndex: -1,
                loadingType: 'linear',
                showEmptyDataSourceMessage: !loading,
                padding: 'dense',
                pageSizeOptions: [5],
              }}
              data={data || []}
              actions={defaultActions}
              title={`${'Admin'} (${data && data.length})`}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
