import type { Transformer } from './transformer';

export const scrollIntoNavigation = (): Transformer => {
  return dom => {
    setTimeout(() => {
      const activeNavItems = dom?.querySelectorAll(`li.md-nav__item--active`);
      if (activeNavItems.length !== 0) {
        // expand all navigation items that are active
        activeNavItems.forEach(activeNavItem => {
          const checkbox = activeNavItem?.querySelector('input');
          if (!checkbox?.checked) {
            checkbox?.click();
          }
        });

        const lastItem = activeNavItems[activeNavItems.length - 1];
        lastItem.scrollIntoView();
      }
    }, 200);
    return dom;
  };
};