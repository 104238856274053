import React, { useEffect, useState } from 'react';
import DocumentCard from './DocumentCard';
import { Chip, Grid, LinearProgress, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useGetConversionApi } from '../../utils/useGetConversionApi';
import { useAsyncEntity } from '@backstage/plugin-catalog-react';

const ApiDocuments = () => {
  const { entity } = useAsyncEntity();
  const { loading, localEntity } = useGetConversionApi(
    entity?.metadata?.name,
    entity?.kind,
  );
  const apiDocs = localEntity?.metadata?.apidocs;

  const [tags, setTags] = useState([
    { label: 'All documents', value: '', selected: true },
    { label: 'How to', value: 'HOWTO', selected: false },
    { label: 'Support forum', value: 'SUPPORT_FORUM', selected: false },
    { label: 'Public forum', value: 'PUBLIC_FORUM', selected: false },
    { label: 'Sample & SDK', value: 'SAMPLES', selected: false },
    { label: 'Others', value: 'OTHER', selected: false },
  ]);

  const [filteredDocs, setFilteredDocs] = useState(
    localEntity?.metadata?.apidocs,
  );

  useEffect(() => {
    setFilteredDocs(localEntity?.metadata?.apidocs);
  }, [localEntity]);

  const handleTagsClick = (e: any, value: string) => {
    const localTags = tags.map(tag =>
      tag.value === value
        ? { ...tag, selected: true }
        : { ...tag, selected: false },
    );
    setTags(localTags);
    setFilteredDocs(apiDocs?.filter((item: any) => item.type.includes(value)));
  };

  const classes = useStyles();
  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <div className={classes.documentsPage}>
          <Grid container>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <Typography variant="h5">Documents</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={10}
              lg={10}
              className={classes.tagsFilter}
            >
              {tags.map((item: any) => (
                <Chip
                  label={item.label}
                  onClick={e => handleTagsClick(e, item.value)}
                  className={`${classes.filterChipButton} ${
                    item.selected ? classes.tagSelected : ''
                  }`}
                />
              ))}
            </Grid>
          </Grid>
          <Grid container>
            {filteredDocs?.map((documentDetail: any) => (
              <Grid item xs={12} sm={12} md={4} lg={3}>
                <DocumentCard documentDetail={documentDetail} />
              </Grid>
            ))}
            {filteredDocs === undefined && (
              <Grid item xs={12} sm={12} md={4} lg={3}>
                No documents found.
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </>
  );
};

export default ApiDocuments;
