import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CreateNewIcon = ({ fill }: { fill?: string }) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M12 8.66667H8.66665V12C8.66665 12.3667 8.36665 12.6667 7.99998 12.6667C7.63331 12.6667 7.33331 12.3667 7.33331 12V8.66667H3.99998C3.63331 8.66667 3.33331 8.36667 3.33331 8C3.33331 7.63333 3.63331 7.33333 3.99998 7.33333H7.33331V4C7.33331 3.63333 7.63331 3.33333 7.99998 3.33333C8.36665 3.33333 8.66665 3.63333 8.66665 4V7.33333H12C12.3666 7.33333 12.6666 7.63333 12.6666 8C12.6666 8.36667 12.3666 8.66667 12 8.66667Z"
      fill={fill}
    />
  </SvgIcon>
);

export default CreateNewIcon;
