import React, { useRef, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { AboutField } from './AboutField';
import '../EditPageStyle.css';
import { AddShowTags } from '../AddShowTags';
import { Tags } from './Tags';
import { LifeCycleButton } from './LifeCycleButtons';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useApi } from '@backstage/core-plugin-api';
import { userProfileApiRef } from '../../../apis/userProfileApi';
import { getUserName } from '../../home/allAssets/util';
import { handleSupportEmail } from '../../utils/HandleFunctionsInApiEditpage';

const useStyles = makeStyles(theme => ({
  descriptionText: {
    padding: '5px 12px',
    margin: '8px 0',
    boxSizing: 'border-box',
    border: '1px solid #f1f1f1',
    color: theme.palette.text.secondary,
    fontSize: '14px',
    fontFamily: 'Calibre-R-Regular',
    width: '100% !important',
    backgroundColor: theme.palette.background.paper,
  },
  aboutLink: {
    color: theme.palette.primary.main,
  },
  validatorSegment: {
    fontSize: '11px',
    color: '#f00',
  },
  editInputText: {
    padding: '5px 12px',
    margin: ' 8px 0',
    boxSizing: 'border-box',
    border: '1px solid #f1f1f1',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    fontSize: '11px',
    width: '100%',
  },
}));
export const AboutCardContent = props => {
  const {
    defaultEntity,
    apiSupportEmail,
    setApiSupportEmail,
    apiLifecycle,
    setApiLifecycle,
    apiVersion,
    defaultTags = [],
    setTags,
    setIsButtonDisabled,
    apiLOB,
    setApiLOB,
    apiService,
    setApiService,
    defaultEntityDescription,
    setDefaultEntityDescription,
    editable
  } = props;

  const [validatorApiSupportEmail, setValidatorApiSupportEmail] =
    useState(false);

  const classes = useStyles();
  const usernameRef = useRef(null);
  const userProfileApi = useApi(userProfileApiRef);

  const handleDescriptionChange = e => {
    e.preventDefault();
    const value = e.target.value;
    setIsButtonDisabled(false);
    setDefaultEntityDescription(value);
  };

  // const discriptionText=(description)=>{
  //   return <OverflowTooltip
  //   //  title={
  //   //    description &&
  //   //    description.length > MAX_CHAR_COUNT_DESC
  //   //      ? `${description.substring(
  //   //          0,
  //   //          MAX_CHAR_COUNT_DESC,
  //   //        )}...`
  //   //      : description
  //   //  }
  //    text={description}
  //    placement="bottom-start"
  //    line={2}
  //  />
  //  };

  const onMouseEnter = () => {
    let email = undefined;
    const ownerEmail = `''${defaultEntity?.spec?.owneremail}`;
    email = ownerEmail;
    if (
      ownerEmail === undefined ||
      ownerEmail === 'undefined' ||
      String(ownerEmail).trim()?.length === 0
    ) {
      const owner = getUserName(defaultEntity);
      email = `${owner}@cbre.com`;
    }
    userProfileApi.setUserProfileState({
      email,
      anchorRef: usernameRef?.current,
    });
  };

  return (
    <Grid container>
      {/* <AboutField
        label="Description"
        gridSizes={{ xs: 12 }}
        value={defaultEntity?.metadata?.description ? discriptionText(defaultEntity?.metadata?.description) : 'No description'}
      ></AboutField> */}
      <Grid item xs={12}>
        <AboutField
          label="DESCRIPTION"
          gridSizes={{ xs: 12, sm: 12, lg: 12 }}
          value=""
        />
        <TextareaAutosize
          id="DESCRIPTION"
          minRows={3}
          maxRows={5}
          variant="outlined"
          onChange={e => {
            if (editable) {
              handleDescriptionChange(e)
            } else {
              return
            }
          }}
          value={defaultEntityDescription || ''}
          // helperText="Maximum of 50 characters."
          className={classes.descriptionText}
        />
      </Grid>
      <AboutField
        label="Tech Owner"
        gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        value={
          <span onMouseEnter={onMouseEnter} ref={usernameRef}>
            {defaultEntity?.spec?.owner?.replace('user:', '')}
          </span>
        }
      />
      <AboutField
        label="Type"
        gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        value={defaultEntity?.spec?.type}
      />
      <AboutField label="Lifecycle" gridSizes={{ xs: 12, sm: 6, lg: 4 }}>
        <LifeCycleButton
          setApiLifecycle={setApiLifecycle}
          apiLifecycle={apiLifecycle}
          setIsButtonDisabled={setIsButtonDisabled}
          editable={editable}
        />
      </AboutField>
      <AboutField
        label="API Scope/Visibility"
        gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        value={defaultEntity?.metadata?.apiscope || 'working but no data'}
      />
      <AboutField label="Tags" gridSizes={{ xs: 12, sm: 6, lg: 4 }}>
        <AddShowTags
          defaultTags={defaultTags}
          setTags={setTags}
          setIsButtonDisabled={setIsButtonDisabled}
          editable={editable}
        />
      </AboutField>
      <AboutField
        label="API Version"
        gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        value={apiVersion}
      />
      <AboutField
        label="DOCUMENTATION URL"
        gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        value="no url"
      >
        {defaultEntity &&
        defaultEntity?.metadata &&
        defaultEntity?.metadata?.documentationLink ? (
          <a
            href={defaultEntity?.metadata?.documentationLink}
            target="_blank"
            className={classes.aboutLink}
          >
            Document Link
          </a>
        ) : (
          'No Link'
        )}
      </AboutField>
      <AboutField label="LOB" gridSizes={{ xs: 12 }} value="LOB">
        <Tags
          defaultTags={apiLOB}
          setTags={setApiLOB}
          numberOfTags={5}
          numberOfCharacters={20}
          setIsButtonDisabled={setIsButtonDisabled}
          editable={editable}
        />
      </AboutField>
      
      <AboutField label="SERVICE" gridSizes={{ xs: 12 }}>
        <Tags
          defaultTags={apiService}
          setTags={setApiService}
          numberOfTags={5}
          numberOfCharacters={20}
          setIsButtonDisabled={setIsButtonDisabled}
          hasAddMore={false}
          hasCloseButton={false}
          editable={editable}
        />
      </AboutField>
      <AboutField label="SUPPORT EMAIL" gridSizes={{ xs: 12, sm: 6, lg: 4 }}>
        <input
          type="text"
          data-testid="test-support-email"
          className={classes.editInputText}
          onKeyUp={event =>
            handleSupportEmail(
              event,
              setApiSupportEmail,
              setValidatorApiSupportEmail,
              setIsButtonDisabled,
            )
          }
          defaultValue={
            apiSupportEmail !== ''
              ? apiSupportEmail
              : defaultEntity?.spec?.owneremail
          }
        />
        {validatorApiSupportEmail && (
          <Typography className={classes.validatorSegment}>
            *email address not valid
          </Typography>
        )}
      </AboutField>
    </Grid>
  );
};
