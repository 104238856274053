import { useEffect, useState } from 'react';

export const useFilteredCategories = (feedbacks: any) => {
  const [categories, setCategories] = useState<any>([]);

  useEffect(() => {
    if (feedbacks && feedbacks.length > 0) {
      setCategories([
        'All categories',
        ...new Set(feedbacks.map((feedback: any) => feedback?.category)),
      ]);
    }
  }, [feedbacks]);
  return { categories };
};
