import { useNavigate } from 'react-router';

import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';

export const useUtils = () => {
  const navigate = useNavigate();
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const navigateTo = (link: string) => {
    navigate(link);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    notificationApi.sendNotification({
      message: 'Copied to clipboard!',
      disapperAfterMs: 500,
      severity: 'success',
    });
  };

  return { navigateTo, copyToClipboard };
};
