import React, { useEffect, useState } from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { useStarredEntities } from '@backstage/plugin-catalog-react';
import { Icon, IconButton } from '@material-ui/core';
import Bookmark from '@material-ui/icons/Bookmark';
import BookmarkBorderOutlined from '@material-ui/icons/BookmarkBorderOutlined';

export default function RecursiveTreeView(props: any) {
  const { classes, data } = props;
  const [expand, setExpand] = useState([]);
  const { isStarredEntity, toggleStarredEntity } = useStarredEntities();

  const onClickIcon = (id:string) => {
    toggleStarredEntity({ kind: 'leanIx', namespace: 'default', name: id });
  };

  const ExpandNodes: any[] | ((prevState: never[]) => never[]) = [];

  useEffect(() => {
    setExpand(ExpandNodes);
  }, [data]);

  const renderLabel = (label: string, nodes: any) => {
    return (
      <div className={classes.listHeader}>
       {!nodes?.parentid && <IconButton
          onClick={() => onClickIcon(label)}
         
        >
          {isStarredEntity({
            kind: 'leanIx',
            namespace: 'default',
            name: label,
          }) ? (
            <Bookmark />
          ) : (
            <BookmarkBorderOutlined />
          )}
        </IconButton> }
        <div
          className={nodes?.parentid ? classes.childLabel : classes.parentLabel}
        >
          {label}
        </div>
      </div>
    );
  };

  const renderTree = (nodes: any, key: number) => {
    ExpandNodes.push(nodes?.name);
    return (
      <TreeItem
        key={`node-${key+1}`}
        nodeId={nodes?.name}
        label={renderLabel(nodes?.name, nodes)}
      >
        {Array.isArray(nodes?.children)
          ? nodes?.children.map((node: any, index: number) =>
              renderTree(node, index),
            )
          : null}
      </TreeItem>
    );
  };

  return (
    <TreeView defaultExpanded={['1']} expanded={expand}>
      {renderTree(data || [], 0)}
    </TreeView>
  );
}
