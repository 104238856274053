/* istanbul ignore file */
import React from 'react';
import { TableColumn } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { Typography } from '@material-ui/core';
import { useRef } from 'react';
import { userProfileApiRef } from '../../apis/userProfileApi';

export type EntityRow = {
  user: string;
  isActive: string;
  dateCreated: string;
};

export function createUserColumn(): TableColumn<EntityRow> {
  return {
    title: 'user',
    field: 'user',
    render: ({ user }) => {
      const usernameRef = useRef(null);
      const userProfileApi = useApi(userProfileApiRef);

      const onMouseEnter = () => {
        const email: string | undefined = user;
        userProfileApi.setUserProfileState({
          email,
          anchorRef: usernameRef?.current,
        });
      };

      return (
        <Typography onMouseEnter={onMouseEnter} ref={usernameRef}>
          {(user && user.split('@')[0]) || user}
        </Typography>
      );
    },
  };
}
export function createFirstNameColumn(): TableColumn<EntityRow> {
  return {
    title: 'First Name',
    render: ({ user }) => (user && user.split('@')[0].split('.')[0]) || user,
  };
}
export function createLastNameColumn(): TableColumn<EntityRow> {
  return {
    title: 'Last Name',
    field: 'user',
    render: ({ user }) => (user && user.split('@')[0].split('.')[1]) || user,
  };
}

export function createEmailColumn(): TableColumn<EntityRow> {
  return {
    title: 'Email',
    field: 'user',
    render: ({ user }) => {
      const usernameRef = useRef(null);
      const userProfileApi = useApi(userProfileApiRef);

      const onMouseEnter = () => {
        const email: string | undefined = user;
        userProfileApi.setUserProfileState({
          email,
          anchorRef: usernameRef?.current,
        });
      };

      return (
        <Typography onMouseEnter={onMouseEnter} ref={usernameRef}>
          {user}
        </Typography>
      );
    },
  };
}

export function createEmailColumnforExclusionList(): TableColumn<EntityRow> {
  return {
    title: 'Email',
    field: 'email',
    render: ({ email }) => {
      const usernameRef = useRef(null);
      const userProfileApi = useApi(userProfileApiRef);

      const onMouseEnter = () => {
        const userEmail: string | undefined = email;
        userProfileApi.setUserProfileState({
          userEmail,
          anchorRef: usernameRef?.current,
        });
      };

      return (
        <Typography onMouseEnter={onMouseEnter} ref={usernameRef}>
          {email}
        </Typography>
      );
    },
  };
}

const dateFormat = (user: any) => {
  const formatDate =
    user?.createdDate && user?.createdDate.toString().split('T')[0];
  return formatDate;
};

export function createDateCreatedColumn(): TableColumn<EntityRow> {
  return {
    title: 'Date Created',
    field: 'createdDate',
    render: user => {
      return dateFormat(user);
    },
  };
}
export function createDateCreatedColumnExclusionList(): TableColumn<EntityRow> {
  return {
    title: 'Date Created',
    field: 'createdat',
    render: ({ createdat }) => {
      const formatDate = createdat && createdat.toString().split(' ')[0];
      return formatDate;
    },
  };
}
