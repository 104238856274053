import { PageWithHeader, TabbedLayout } from '@backstage/core-components';
import React, { useEffect, useState } from 'react';
import PromptStoreHome from './PromptStoreHome';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';
import { useLocation } from 'react-router';

const PromptStoreTabs = () => {
  const location = useLocation();
  const headerTitle = 'Prompt Store';

  const header = {
    title: 'Prompt Store',
    subTitleRoutes: [
      {
        type: 'link',
        text: 'Home',
        link: '/',
      },
      {
        type: 'text',
        text: 'Prompt Store',
      },
    ],
  };

  const [breadCrumb, setBreadCrumb] = useState(header);

  const tpBreadCrumbs = () => {
    const allPromptsPage = location.pathname.includes('/all-prompts');
    const myPromptsPage = location.pathname.includes('/my-prompts');

    let title = header.title;
    let subTitleRoutes = header.subTitleRoutes;
    let breadCrumbsToInsert = null;

    if (allPromptsPage) {
      title = headerTitle;
      breadCrumbsToInsert = [
        {
          type: 'text',
          text: 'All Prompts',
          link: '',
        },
      ];
    }
    if (myPromptsPage) {
      title = headerTitle;
      breadCrumbsToInsert = [
        {
          type: 'text',
          text: 'My Prompts',
          link: '',
        },
      ];
    }

    if (breadCrumbsToInsert !== null)
      subTitleRoutes = [...header.subTitleRoutes, ...breadCrumbsToInsert];
    else {
      subTitleRoutes[1].type = 'text';
    }
    setBreadCrumb({
      title,
      subTitleRoutes,
    });
  };

  useEffect(() => {
    tpBreadCrumbs();
  }, [location.pathname]);

  return (
    <PageWithHeader
      title={undefined}
      themeId="promptStore"
      pageTitleOverride={breadCrumb?.title}
      subtitle={<DevxBreadCrumb routes={breadCrumb?.subTitleRoutes} />}
    >
      <TabbedLayout>
        <TabbedLayout.Route path="/all-prompts" title="All prompts">
          <PromptStoreHome type="allPrompts" key="allPrompts" />
        </TabbedLayout.Route>
        <TabbedLayout.Route path="/my-prompts" title="My prompts">
          <PromptStoreHome type="myPrompts" key="myPrompts" />
        </TabbedLayout.Route>
      </TabbedLayout>
    </PageWithHeader>
  );
};

export default PromptStoreTabs;
