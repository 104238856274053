import React from 'react';

const PrMerged = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.25 25.5687L23.0125 23.8062L18.75 19.5437L16.9875 21.3062L21.25 25.5687ZM9.37505 10.0562H13.75V17.0437L6.98755 23.8062L8.75005 25.5687L16.25 18.0687V10.0562H20.625L15 4.43124L9.37505 10.0562Z"
        fill="#17E88F"
      />
    </svg>
  );
};

export default PrMerged;
