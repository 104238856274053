import { createApiRef } from '@backstage/core-plugin-api';
import { AxiosInstance } from 'axios';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';

export interface SwTemplateCustomApi {
  getImage: (fileName: string) => Promise<any>;
  getCBREProjectsForUser: (
    token: string,
    orgName: string,
  ) => Promise<AzureProjectResponse>;
  getRepositoryInfo: (
    orgName: string,
    projectName: string,
    repoName: string,
  ) => Promise<any>;
  getAllLikesCount: (allNames: string[]) => Promise<any>;
  getLikesCount: (name: string) => Promise<any>;
  getApms: () => Promise<any>;
}

export const swTemplateCustomApiRef = createApiRef<SwTemplateCustomApi>({
  id: 'cbre.devx.api.templates',
});

export interface AzureProject {
  id: string;
  name: string;
  description: string;
  lastUpdateTime: string;
  revision: number;
  state: string;
  url: string;
  visibility: string;
}

export interface AzureProjectResponse {
  count: number;
  value: AzureProject[];
}

export class SwTemplateCustomApiImpl implements SwTemplateCustomApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  async getImage(fileName: string) {
    const instance = await this.axiosInstancePromise;
    return instance.get('service/discovery/image', {
      params: {
        entityType: 'softwareTemplate',
        fileName,
      },
    });
  }

  async getCBREProjectsForUser(
    token: string,
    orgName: string,
  ): Promise<AzureProjectResponse> {
    const instance = await this.axiosInstancePromise;

    const org = orgName || 'cbre';

    return instance
      .get(`https://dev.azure.com/${org}/_apis/projects`, {
        params: {
          'api-version': '6.0',
        },
        headers: {
          Authorization: `Bearer ${token}`, // this token is the Azure Devops scoped token
        },
      })
      .then(res => res.data);
  }

  async getRepositoryInfo(
    orgName: string,
    projectName: string,
    repoName: string,
  ): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`ado-api/repo/info/${orgName}/${projectName}/${repoName}`)
      .then(res => res?.data)
      .catch(err => {
        throw new Error(
          `Error occurred while getting repo info ${err.message}`,
        );
      });
  }

  async getAllLikesCount(allNames: string[]): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`user-settings/fetch-all-likes`, {
        params: {
          allNames: allNames,
        },
      })
      .then(res => res?.data)
      .catch(err => {
        throw new Error(
          `Error occurred while getting repo info ${err.message}`,
        );
      });
  }

  async getLikesCount(name: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`user-settings/fetch-likes`, {
        params: {
          name: name,
        },
      })
      .then(res => res?.data)
      .catch(err => {
        throw new Error(
          `Error occurred while getting repo info ${err.message}`,
        );
      });
  }
  async getApms(): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`service/registration/apminfo`)
      .then(res => res?.data)
      .catch(err => {
        throw new Error(
          `Error occurred while getting repo info ${err.message}`,
        );
      });
  }
}
