export const getElementById = (selector: string) => {
  try {
    return document.getElementById(selector);
  } catch {
    return null;
  }
};

export const getElementByCssSelector = (selector: string) => {
  try {
    return document.querySelector(selector);
  } catch {
    return null;
  }
};

export const getElementByXpath = (path: string) => {
  try {
    return document.evaluate(
      path,
      document,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null,
    ).singleNodeValue;
  } catch {
    return null;
  }
};

export const getAnchorElement: any = (selector: string) => {
  /* Currently it supports ids, css selectors & xpath for elements*/
  //  const selector: any = tourConfig[currentIndex].selector;

  return (
    getElementById(selector) ||
    getElementByCssSelector(selector) ||
    getElementByXpath(selector) ||
    null
  );
};

export const updateObjectValuesToSame = (obj: any, value: any) => {
  return Object.keys(obj).reduce((finalObj, key) => {
    return {
      ...finalObj,
      [key]: value,
    };
  }, {});
};
