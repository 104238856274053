import React, { useEffect, useState, useCallback } from 'react';
import { useTheme, useMediaQuery, Theme } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useApi, useAnalytics } from '@backstage/core-plugin-api';
import { Page, Header, Progress, useSidebarPinState } from '@backstage/core-components';
import { ErrorPage } from '@backstage/core-components';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';
import { helpApiRef } from '../../apis/helpPageContent';
import {
  TechDocsShadowDom,
  techdocsStorageApiRef,
  useShadowDomStylesLoading,
} from '@backstage/plugin-techdocs-react';
import { scmIntegrationsApiRef } from '@backstage/integration-react';
import {
  addBaseUrl,
  addLinkClickListener,
  addSidebarToggle,
  onCssReady,
  removeMkdocsHeader,
  rewriteDocLinks,
  simplifyMkdocsFooter,
  scrollIntoAnchor,
  scrollIntoNavigation,
  transform as transformer,
  useSanitizerTransformer,
  useStylesTransformer,
} from '../techdocs-utils';

const MOBILE_MEDIA_QUERY = 'screen and (max-width: 76.1875em)';

export const GlobalHelpContainer = () => {
  const help = useApi(helpApiRef);
  const [loading, setLoading] = useState<boolean>(false);
  const isMobileMedia = useMediaQuery(MOBILE_MEDIA_QUERY);
  const sanitizerTransformer = useSanitizerTransformer();
  const stylesTransformer = useStylesTransformer();
  const navigate = useNavigate();
  const [entityRef, setEntityRef] = useState<any>(false);
  const [dom, setDom] = useState<HTMLElement | null>(null);
  const isStyleLoading = useShadowDomStylesLoading(dom);
  const techdocsStorageApi = useApi(techdocsStorageApiRef);
  const scmIntegrationsApi = useApi(scmIntegrationsApiRef);
  const analytics = useAnalytics();
  const theme = useTheme<Theme>();
  const [rawPage, setRawPage] = useState('');
  const { isPinned } = useSidebarPinState();

  const generateUrl = () => {
    const location = window.location.href;

    if (location.includes('#')) {
      return `${location.split('#')[0]}/`;
    }
    return location;
  };

  const getContent = async () => {
    const result = await help.getContent(
      `/techdocs/static/docs/default/component/devxhelpguide/${window.location.href.split('/help/').length > 1
        ? generateUrl().split('/help/')[1]
        : ''
      }index.html`,
    );
    const entity = await help.getEntity('DevxHelpGuide');
    if (result) {
      setRawPage(result);
      setEntityRef(entity);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  const updateSidebarPosition = useCallback(() => {
    if (!dom) return;

    const sidebars = dom.querySelectorAll<HTMLElement>('.md-sidebar');

    sidebars.forEach(element => {
      // set sidebar position to render in correct position
      if (isMobileMedia) {
        element.style.top = '0px';
      } else {
        const page = document?.querySelector('.techdocs-reader-page');
        const pageTop = page?.getBoundingClientRect().top ?? 0;
        let domTop = dom.getBoundingClientRect().top ?? 0;

        const tabs = dom.querySelector('.md-container > .md-tabs');
        const tabsHeight = tabs?.getBoundingClientRect().height ?? 0;

        // the sidebars should not scroll beyond the total height of the header and tabs
        if (domTop < pageTop) {
          domTop = pageTop;
        }
        element.style.top = `${Math.max(domTop, 0) + tabsHeight}px`;
      }

      // show the sidebar only after updating its position
      element.style.setProperty('opacity', '1');
    });
  }, [dom, isMobileMedia]);

  useEffect(() => {
    window.addEventListener('resize', updateSidebarPosition);
    window.addEventListener('scroll', updateSidebarPosition, true);
    return () => {
      window.removeEventListener('resize', updateSidebarPosition);
      window.removeEventListener('scroll', updateSidebarPosition, true);
    };
  }, [dom, updateSidebarPosition]);

  const updateFooterWidth = useCallback(() => {
    if (!dom || !entityRef) return;
    const footer = dom.querySelector<HTMLElement>('.md-footer');
    if (footer) {
      footer.style.width = `${dom.getBoundingClientRect().width}px`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dom]);

  useEffect(() => {
    window.addEventListener('resize', updateFooterWidth);
    return () => {
      window.removeEventListener('resize', updateFooterWidth);
    };
  }, [dom, updateFooterWidth]);

  // an update to "state" might lead to an updated UI so we include it as a trigger
  useEffect(() => {
    if (!isStyleLoading) {
      updateFooterWidth();
      updateSidebarPosition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStyleLoading, updateFooterWidth, updateSidebarPosition]);

  const preRender = useCallback(
    (rawContent: string, contentPath: string) =>
      transformer(rawContent, [
        sanitizerTransformer,
        addBaseUrl({
          techdocsStorageApi,
          entityId: {
            kind: entityRef?.kind,
            namespace: entityRef?.metadata?.namespace,
            name: entityRef?.metadata?.name,
          },
          path: contentPath,
        }),
        rewriteDocLinks(),
        addSidebarToggle(),
        removeMkdocsHeader(),
        simplifyMkdocsFooter(),
        stylesTransformer,
      ]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // only add dependencies that are in state or memorized variables to avoid unnecessary calls between re-renders
      entityRef,
      scmIntegrationsApi,
      techdocsStorageApi,
      sanitizerTransformer,
      stylesTransformer,
    ],
  );

  const postRender = useCallback(
    async (transformedElement: Element) =>
      transformer(transformedElement, [
        scrollIntoAnchor(),
        scrollIntoNavigation(),
        addLinkClickListener({
          baseUrl: window.location.origin,
          onClick: (event: MouseEvent, url: string) => {
            // detect if CTRL or META keys are pressed so that links can be opened in a new tab with `window.open`
            const modifierActive = event.ctrlKey || event.metaKey;
            const parsedUrl = new URL(url);
            const fullPath = `${parsedUrl.pathname}${parsedUrl.search}${parsedUrl.hash}`;

            // capture link clicks within documentation
            const linkText =
              (event.target as HTMLAnchorElement | undefined)?.innerText || url;
            const to = url.replace(window.location.origin, '');
            analytics.captureEvent('click', linkText, { attributes: { to } });

            // hash exists when anchor is clicked on secondary sidebar
            if (parsedUrl.hash) {
              if (modifierActive) {
                window.open(fullPath, '_blank');
              } else {
                navigate(fullPath);
                // Scroll to hash if it's on the current page
                transformedElement
                  ?.querySelector(`[id="${parsedUrl.hash.slice(1)}"]`)
                  ?.scrollIntoView();
              }
            } else {
              if (modifierActive) {
                window.open(fullPath, '_blank');
              } else {
                navigate(fullPath);
              }
            }
          },
        }),
        // disable MkDocs drawer toggling ('for' attribute => checkbox mechanism)
        onCssReady({
          onLoading: () => { },
          onLoaded: () => {
            transformedElement
              .querySelector('.md-nav__title')
              ?.removeAttribute('for');
          },
        }),
        // hide sidebars until their positions are updated
        onCssReady({
          onLoading: () => {
            const sidebars = Array.from(
              transformedElement.querySelectorAll<HTMLElement>('.md-sidebar'),
            );
            sidebars.forEach(element => {
              element.style.setProperty('opacity', '0');
            });
            // remove sidebar inner element to avoid scrollbar
            const sidebarInner = transformedElement.querySelector<HTMLElement>('.md-sidebar__inner');
            if (sidebarInner) {
              sidebarInner.style.setProperty('overflow', 'hidden');
            };
            // prevent code blocks from overflowing
            const codeBlocks = Array.from(
              transformedElement.querySelectorAll<HTMLElement>('div.highlight'),
            );
            codeBlocks.forEach(element => {
              const width = 'calc(100vw - 16rem - 6rem - 2.4rem -' + (isPinned ? ' 224px' : ' 0') + ')';
              element.style.setProperty('width', width);
            })
          },
          onLoaded: () => { },
        }),
      ]),
    [theme, navigate, analytics],
  );

  useEffect(() => {
    if (!rawPage) return () => { };

    // if false, there is already a newer execution of this effect
    let shouldReplaceContent = true;

    // Pre-render
    preRender(rawPage, '').then(async preTransformedDomElement => {
      if (!preTransformedDomElement?.innerHTML) {
        return; // An unexpected error occurred
      }

      // don't manipulate the shadow dom if this isn't the latest effect execution
      if (!shouldReplaceContent) {
        return;
      }

      // Scroll to top after render
      window.scroll({ top: 0 });

      // Post-render
      const postTransformedDomElement = await postRender(
        preTransformedDomElement,
      );
      setDom(postTransformedDomElement as HTMLElement);
    });

    // cancel this execution
    return () => {
      shouldReplaceContent = false;
    };
  }, [rawPage, preRender, postRender]);

  if (loading) return <Progress />;

  if (!dom && !loading)
    return <ErrorPage status="404" statusMessage="PAGE NOT FOUND" />;

  return (
    <Page themeId="user-guide">
      <Header
        title="User Guide"
        pageTitleOverride="Code"
        subtitle={
          <DevxBreadCrumb
            routes={[
              {
                type: 'link',
                link: '/',
                text: 'Home',
              },
              {
                type: 'text',
                text: 'User Guide',
              },
            ]}
          />
        }
      />

      <div className="codePage">
        <Grid item md={12} xs={12} className="globalHelpPage">
          <TechDocsShadowDom element={dom as HTMLElement} />
        </Grid>
      </div>
    </Page>
  );
};

export default GlobalHelpContainer;
