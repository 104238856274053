import React, { useState } from 'react';
import { Entity } from '@backstage/catalog-model';
import { scmIntegrationsApiRef } from '@backstage/integration-react';
import {
  getEntitySourceLocation,
  useEntity,
} from '@backstage/plugin-catalog-react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Button,
  createStyles,
  Theme,
} from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';
import { AboutContent } from './AboutContent';
import GetAppIcon from '@material-ui/icons/GetApp';
import GitHubIcon from '@material-ui/icons/GitHub';
import AzureIcon from '../../../../Root/icons/azureIcon';

import {
  HeaderIconLinkRow,
  IconLinkVerticalProps,
  InfoCardVariants,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import NavigateOut from '../../../../NavigationOutOfDevx/NavigateOut';
import { EXTENSION_COMPONENT_TYPE } from '../../../../utils/constant';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItemCard: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 10px)', // for pages without content header
      marginBottom: '10px',
      '& nav': {
        display: 'flex',
      },
    },
    gridItemCardResponsive: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginBottom: '10px',
        width: '100%',
      },
    },
    editBtnStyle: {
      marginRight: '10px',
      marginTop: '10px',
    },
    editBtnResponsive: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    gridItemCardContent: {
      flex: 1,
    },
  }),
);

type AboutCardProps = {
  variant?: InfoCardVariants;
  userId: String;
  isAdmin?: Boolean | null;
};

export function AboutCard({ userId, isAdmin }: AboutCardProps) {
  const [openViewSource, setOpenViewSource] = useState(false);
  const classes = useStyles();
  const { entity } = useEntity();
  const scmIntegrationsApi = useApi(scmIntegrationsApiRef);
  const entitySourceLocation = getEntitySourceLocation(
    entity,
    scmIntegrationsApi,
  );
  const entityOwner = entity?.spec?.owner?.replace('user:', '');

  const name = entity.metadata.name;
  const kind = entity.kind;
  const namespace = entity.metadata.namespace;
  const customLinks =
    entity.metadata?.customlinks?.length && entity.metadata?.customlinks;
  const handleClose = () => {
    setOpenViewSource(false);
  };
  const handleYes = () => {
    window.open(`${entitySourceLocation?.locationTargetUrl}`, '_blank');
    setOpenViewSource(false);
  };
  const NavigateProps = {
    handleYes: handleYes,
    handleClose: handleClose,
    open: openViewSource,
  };

  const viewInSource: IconLinkVerticalProps = {
    label: entity.spec?.type === EXTENSION_COMPONENT_TYPE ? 'Download Link' : 'View Source',
    disabled: !entitySourceLocation,
    icon:
      entity?.spec?.type === EXTENSION_COMPONENT_TYPE ? <GetAppIcon /> : 
      (entitySourceLocation?.integrationType?.toLowerCase() === 'github' ? (
        <GitHubIcon />
      ) : entitySourceLocation?.integrationType?.toLowerCase() === 'azure' ? (
        <AzureIcon />
      ) : null),
      href: entity.spec?.type === EXTENSION_COMPONENT_TYPE ? 
        (entity.spec?.downloadLink || '') : 
        entitySourceLocation?.locationTargetUrl,
    //onClick: () => handleYes(),
  };

  const customLinksSource: any = () => {
    const links = customLinks.map((link: any) => {
      return {
        label: link.title,
        icon: <LanguageIcon />,
        href: link.url,
      };
    });
    return links;
  };

  const mountedOnCodeLibraryPath = useMatch(
    '/code-library/:namespace/:kind/:name',
  );
  const mountedOnCatalogPath = useMatch('/catalog/:namespace/:kind/:name');

  const navigate = useNavigate();
  const getEditPage = () => {
    if (
      mountedOnCodeLibraryPath !== undefined &&
      mountedOnCodeLibraryPath?.pathname !== undefined
    ) {
      return `/code-library/${namespace}/${kind}/${name}/edit`;
    } else if (
      mountedOnCatalogPath !== undefined &&
      mountedOnCatalogPath?.pathname !== undefined
    ) {
      return `/catalog/${namespace}/${kind}/${name}/edit`;
    }
    return `/catalog/${namespace}/${kind}/${name}/edit`;
  };

  return (
    <>
      <Card className={classes.gridItemCard}>
        <CardHeader
          className={classes.gridItemCardResponsive}
          title="About"
          classes={{ action: classes.editBtnResponsive }}
          action={
            userId?.replace('user:', '').toLowerCase() ===
              entityOwner.replace('user:', '').toLowerCase() || isAdmin ? (
              <Button
                fullWidth
                variant="contained"
                size="small"
                color="primary"
                className={classes.editBtnStyle}
                onClick={() => navigate(getEditPage())}
              >
                Edit Component
              </Button>
            ) : null
          }
          subheader={
            <HeaderIconLinkRow
              links={customLinks ? customLinksSource() : [viewInSource]}
            />
          }
        />
        <Divider />
        <CardContent className={classes.gridItemCardContent}>
          <AboutContent entity={entity} />
        </CardContent>
      </Card>

      {openViewSource && <NavigateOut {...NavigateProps} />}
    </>
  );
}
