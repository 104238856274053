import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import StatisticsCard, { StatisticsCardProps } from './StatisticsCard';
import { Box, IconButton, Typography } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import EmblaCarousel from '../../home/Carousal/EmblaCarousel';
import { useUtils } from '../hooks/useUtils';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles((theme: Theme) => ({
  statisticsContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Calibre-R, sans-serif',
    justifyContent: 'center',
    padding: '3px 0',
    overflowX: 'auto',
    width: '100%',
  },
  cardList: {
    display: 'flex',
    overflowX: 'auto',
    alignItems: 'center',
    overflow: 'hidden',
    justifyContent: 'start',
    scrollbarWidth: 'none',

    '& .embla': {
      flexGrow: 1,
    },

    '& .embla__container': {
      minHeight: 112,
      marginTop: 5,
      display: 'flex',
      paddingBottom: 2,
      paddingTop: theme.spacing(1.5),
    },
    '& .embla__slide': {
      marginRight: theme.spacing(2),
    },
  },

  heading: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: -theme.spacing(1.5),
  },

  hedingIcon: {
    padding: theme.spacing(1),
    fontSize: '1em',
  },
}));

const StatisticsCardList = ({
  statistics,
  link,
}: {
  statistics: StatisticsCardProps[];
  link: string;
}) => {
  const classes = useStyles();

  const [emblaRef, emblaApi] = useEmblaCarousel({
    containScroll: 'trimSnaps',
    slidesToScroll: 1,
    dragFree: true,
    align: 'start',
    loop: true,
    draggable: false,
  });

  const heading = 'You might be surprised to learn';
  const { navigateTo } = useUtils();

  const onClickLeftArrow = () => {
    if (emblaApi && emblaApi?.canScrollPrev()) {
      emblaApi?.scrollPrev();
    }
  };

  const onClickRightArrow = () => {
    if (emblaApi && emblaApi?.canScrollNext()) {
      emblaApi?.scrollNext();
    }
  };

  return (
    <section className={classes.statisticsContainer}>
      <Box component="div" className={classes.heading}>
        <Typography variant="subtitle1">{heading}</Typography>
        <IconButton
          onClick={() => navigateTo(link)}
          classes={{ root: classes.hedingIcon }}
        >
          <LaunchIcon fontSize="small" />
        </IconButton>
      </Box>

      <div className={classes.cardList}>
        {emblaApi &&
          (emblaApi?.canScrollNext() || emblaApi?.canScrollPrev()) && (
            <IconButton onClick={onClickLeftArrow}>
              <ArrowBackIosIcon />
            </IconButton>
          )}

        <EmblaCarousel
          ref={emblaRef}
          slides={statistics?.map(
            (stat: StatisticsCardProps, index: number) => (
              <StatisticsCard key={index} {...stat} />
            ),
          )}
        ></EmblaCarousel>
        {emblaApi &&
          (emblaApi?.canScrollNext() || emblaApi?.canScrollPrev()) && (
            <IconButton onClick={onClickRightArrow}>
              <ArrowForwardIosIcon />
            </IconButton>
          )}
      </div>
    </section>
  );
};

export default StatisticsCardList;
