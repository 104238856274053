import { createApiRef } from '@backstage/core-plugin-api';
import { AxiosInstance, AxiosResponse } from 'axios';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';

export interface VisualRegressionRequest {
  referUrl: string;
  testUrl: string;
  productname: string;
  isauthreqd: any;
  serviceaccount: string;
 // ispopuplogin: string;
  referenceenv: string;
  testenv: string;
  additionalStep:string;
  browserPopup: string;
  clickButton: string;
  clickButtonwithPopup: string;
  xpath:string;
}

export interface VisualRegressionApi {
  postVisualRegression: (
    data: VisualRegressionRequest,
  ) => Promise<AxiosResponse>;
  getVisualRegressionHistory: () => Promise<AxiosResponse>;
  getserviceAccount: () => Promise<AxiosResponse>;
  getVisualRegressionDetails: (id:any) => Promise<AxiosResponse>;
}

export const visualRegressionApiRef = createApiRef<VisualRegressionApi>({
  id: 'cbre.devx.api.visual-regression',
});

export class VisualRegressionApiImpl implements VisualRegressionApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  async postVisualRegression(
    payload: VisualRegressionRequest,
  ): Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;
    return instance.post(
      `service/visualregression/v2`,
      {
        ...payload,
      },
      {
        timeoutErrorMessage:
          'It may take time to publish the result, Kindly check your mailbox.',
      },
    );
  }

  async getVisualRegressionHistory(): Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;
    return instance.get(`service/visualregression/history `);
  }
  async getVisualRegressionDetails(id:any): Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;
    return instance.get(`service/visualregression/${id}`);
  }
  async getserviceAccount(): Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;
    return instance.get(`service/visualregression/serviceacct`);
  }
}
