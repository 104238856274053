/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { toolsProvisioningApiRef } from '../../../apis/toolsProvisioningApi';
import { useEffect, useState } from 'react';
import { IApmDetails } from '../interfaces';

export const useGetApms = (shouldcall: boolean) => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const toolsprovisioningApi = useApi(toolsProvisioningApiRef);

  const [allApms, setAllApms] = useState<IApmDetails[]>([]);

  const fetchMyAPI = async () => {
    if (toolsprovisioningApi) {
      try {
        const response = await toolsprovisioningApi.getApms();
        if (response?.status === 'SUCCESS') setAllApms(response?.data);
      } catch (error) {
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };

  useEffect(() => {
    if (shouldcall) fetchMyAPI();
  }, [shouldcall]);

  return [allApms];
};
