import React, { useState } from 'react';
import {
  Box,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import TextTruncate from 'react-text-truncate';

export interface ExpandableContentProps {
  value: string;
  lines?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    seeMoreRoot: {
      display: 'flex',
      // justifyContent: 'flex-end',
    },
    seeMore: {
      cursor: 'pointer',
    },
    value: {
      overflow: 'hidden',
      lineHeight: 1.5,
      wordBreak: 'break-word',
      fontFamily: 'Calibre-R-Regular',
      fontSize: '11px',
      fontWeight: 600,
      textDecoration: 'none',
      color: theme.palette.text.secondary,
    },
  }),
);

const ExpandableContent = ({
  value = '',
  lines = 0,
}: ExpandableContentProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [seeMoreActionVisible, setSeeMoreActionVisible] =
    useState<boolean>(false);
  const classes = useStyles();

  const onToggled = (truncated: boolean): void => {
    setSeeMoreActionVisible(truncated);
  };

  return (
    <Box>
      <TextTruncate
        containerClassName={classes.value}
        text={value || ''}
        line={expanded ? 0 : lines}
        onToggled={onToggled}
      />
      {seeMoreActionVisible && (
        <Box className={classes.seeMoreRoot}>
          <Typography
            className={classes.seeMore}
            variant="caption"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? 'See less' : 'See more'}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
export default ExpandableContent;
