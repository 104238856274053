import React, { ComponentType, useEffect, useState } from 'react';
import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { TemplateEntityV1beta3 } from '@backstage/plugin-scaffolder-common';
import {
  Content,
  ContentHeader,
  ItemCardGrid,
  Link,
  Progress,
  WarningPanel,
} from '@backstage/core-components';
import { useEntityList } from '@backstage/plugin-catalog-react';
import { Typography } from '@material-ui/core';
import { TemplateCard } from '../TemplateCard/TemplateCard';
import { useGetAllLikes } from '../../hooks/useGetAllLikes';

/**
 * @internal
 */
export type TemplateListProps = {
  TemplateCardComponent?:
    | ComponentType<{ template: TemplateEntityV1beta3 }>
    | undefined;
  group?: {
    title?: React.ReactNode;
    filter: (entity: Entity) => boolean;
  };
};

/**
 * @internal
 */
export const TemplateList = ({
  TemplateCardComponent,
  group,
}: TemplateListProps) => {
  const { loading, error, entities } = useEntityList();
  const Card = TemplateCardComponent || TemplateCard;
  const maybeFilteredEntities = group
    ? entities.filter(e => group.filter(e))
    : entities;

  const [allthumbsUpStrings, setAllThumbsUpStrings] = useState<string[]>([]);

  const getThumbsUpString = (entity: any) => {
    return `thumbsUp:${stringifyEntityRef(entity)}/`;
  };

  useEffect(() => {
    let thumbsUpStrings: string[] = [];
    if (!loading) {
      entities?.map((entity: any) => {
        thumbsUpStrings = [...thumbsUpStrings, getThumbsUpString(entity)];
      });
    }
    //  console.log(thumbsUpStrings);
    if (
      thumbsUpStrings.length > 0 &&
      thumbsUpStrings.length !== allthumbsUpStrings.length
    )
      setAllThumbsUpStrings(thumbsUpStrings);
  }, [loading]);

  const [showLoader, setShowLoader] = useState(false);
  const [allLikesCounts, loaded] = useGetAllLikes(allthumbsUpStrings);

  useEffect(() => {
    if (!loaded) setShowLoader(true);
    else setShowLoader(false);
  }, [loaded]);

  const titleComponent: React.ReactNode = (() => {
    if (group && group.title) {
      if (typeof group.title === 'string') {
        return <ContentHeader title={group.title} />;
      }
      return group.title;
    }

    return <ContentHeader title="Other Templates" />;
  })();

  if (group && maybeFilteredEntities.length === 0) {
    return null;
  }
  return (
    <>
      {(loading || showLoader) && <Progress />}

      {error && (
        <WarningPanel title="Oops! Something went wrong loading the templates">
          {error.message}
        </WarningPanel>
      )}

      {!error && !loading && !entities.length && (
        <Typography variant="body2">
          No templates found that match your filter. Learn more about{' '}
          <Link to="https://backstage.io/docs/features/software-templates/adding-templates">
            adding templates
          </Link>
          .
        </Typography>
      )}

      <Content>
        <ItemCardGrid>
          {maybeFilteredEntities &&
            maybeFilteredEntities?.length > 0 &&
            maybeFilteredEntities.map((template: Entity) => {
              if (template?.metadata?.name?.includes('poc') || template?.metadata?.name?.includes('Poc')) {
                return;
              } else {
                return (
                  <Card
                    key={stringifyEntityRef(template)}
                    template={template as TemplateEntityV1beta3}
                    deprecated={template.apiVersion === 'backstage.io/v1beta2'}
                    likesCount={allLikesCounts?.[getThumbsUpString(template)]}
                  />
                );
              }
            })}
        </ItemCardGrid>
      </Content>
    </>
  );
};
