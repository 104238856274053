export const COST_AVOIDANCE_CONSTANTS = {
  VARIABLE_UPDATE_SUCCESS_MESSAGE: 'Variable successfully updated',
  COMPLEXITY_UPDATE_STATUS: 'updated',
  COMPLEXITY_UPDATE_SUCCESS_MESSAGE: 'Success! complexity has been updated.',
  VARIABLE_ADDED_SUCCESS_MESSAGE: 'New Variable successfully added',
  COMPLEXITY_FIELD_HELPER_TEXT: 'Complexity value is required',
  ASSET_KIND_REQUIRED_MESSAGE: 'Asset kind value is required',
  COMPLEXITY_REQUIRED_MESSAGE: 'Complexity value is required',
  MAN_HOURS_LESS_THAN_MIN_MESSAGE: 'Man hours can not be less than 1',
  MAN_HOURS_MORE_THAN_MAX_MESSAGE: 'Man hours can not be more than 10,000',
  MAN_HOURS_NOT_A_NUMBER_MESSAGE: 'Man hours value must be a number',
  MAN_HOURS_REQUIRED_MESSAGE: 'Man hours value is required',
  BLENDED_RATE_LESS_THAN_MIN_MESSAGE: 'Blended rate can not be less than 1',
  BLENDED_RATE_MORE_THAN_MAX_MESSAGE: 'Blended rate can not be more than 200',
  BLENDED_RATE_NOT_A_NUMBER_MESSAGE: 'Blended rate value must be a number',
  BLENDED_RATE_REQUIRED_MESSAGE: 'Blended rate value is required',
  COEFFICIENT_LESS_THAN_MIN_MESSAGE: 'Coefficient can not be less than 0.01',
  COEFFICIENT_MORE_THAN_MAX_MESSAGE: 'Coefficient can not be more than 2.5',
  COEFFICIENT_NOT_A_NUMBER_MESSAGE: 'Coefficient value must be a number',
  COEFFICIENT_REQUIRED_MESSAGE: 'Coefficient value is required',
  COEFFICIENT2_LESS_THAN_MIN_MESSAGE: 'Coefficient2 can not be less than 0.01',
  COEFFICIENT2_MORE_THAN_MAX_MESSAGE: 'Coefficient2 can not be more than 2.5',
  COEFFICIENT2_NOT_A_NUMBER_MESSAGE: 'Coefficient2 value must be a number',
  COEFFICIENT2_REQUIRED_MESSAGE: 'Coefficient2 value is required',
  FORMULA_REQUIRED_MESSAGE: 'Formula value is required',
};
