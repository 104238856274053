import React, { useState, useCallback, useContext, useEffect } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { IPromptData } from '../interfaces';
import { promptApiRef } from '../../../../apis/promptManagementApi';
import { notificationApiRef } from '../../../../apis/notificationApi';
import { AuthContext } from '../../../../providers/AuthProvider';
import { useLocation } from 'react-router';

export interface Prompts {
  prompts?: Prompt[];
  domains?: Product[];
}

export interface Prompt {
  id?: string;
  name?: string;
  description?: string;
  createdat?: string;
  updatedat?: string;
  productowner?: string;
  subproducts: SubPrompt[];
}
export interface SubPrompt {
  id?: string;
  name?: string;
  description?: string;
  createdAt?: string;
  updatedAt?: string;
  pid?: string;
  personas?: Persona[];
}
export interface Persona {
  id?: string;
  name?: string;
  description?: string;
  createdAt?: string;
  updatedAt?: string;
  spid?: string;
}
export interface Product {
  id: string;
  persona: string[];
  product: string;
  promptdetail: string;
  promptkey: string;
  subproduct: string;
}

export const PromptContext = React.createContext<any>({});

export const PromptProvider = (props: any) => {
  const [data, setData] = useState<Prompts>({});
  const [loading, setLoading] = useState<boolean>(false);
  const promptApi = useApi(promptApiRef);
  const notificationApi = useApi(notificationApiRef);
  const userContext = useContext(AuthContext);


  const setPrompts = (newPrompts: Prompt[]) => {
    setData({
      ...data,
      prompts: newPrompts,
    });
  };

  const setDomains = (domains: Product[]) => {
    setData({
      ...data,
      domains: domains,
    });
  };

  const updateAll = (newDomains?: any, newPrompts?: any) => {
    setData({
      prompts: newPrompts || data.prompts,
      domains: newDomains,
    });
  };

  const fetchData = useCallback(async (isAdmin: any = null, email: any = null) => {
    // wait for admin context to be loaded
    if (isAdmin === null && (userContext.isAdmin === null || loading)) {
      return
    };
    try {
      setLoading(true);
      let responseDomains;
      let responsePrompts;
      if (isAdmin === false) {
        responseDomains = await promptApi.getDomainHierarchyByEmail(email ? email : userContext.profEmail);
        responsePrompts = await promptApi.getPromptByEmail(email ? email : userContext.profEmail);
      } else {
        responseDomains = await promptApi.getDomainHierarchy();
        responsePrompts = await promptApi.getAdminPromptByEmail(email ? email : userContext.profEmail);
      }
      if (responseDomains && responsePrompts) {
        updateAll(responseDomains.data, [...responsePrompts.prompts, ...responsePrompts.default_prompts]);
        setLoading(false);
      }
    } catch (error) {
      notificationApi.sendNotification({
        message: 'Error occurred while fetching data',
        disapperAfterMs: 2500,
        severity: 'error',
      });
      setLoading(false);
    }
  },[userContext.isAdmin, userContext.profEmail, loading]);

  const [editModeData, setEditModeData] = useState<IPromptData | null>(null);

  const location = useLocation();

  /* when page loads, make the editmode data as null, except for edit-prompt */
  useEffect(() => {
    if (!location.pathname.includes('edit-prompt')) {
      setEditModeData(null);
    }
  }, [location.pathname]);

  return (
    <PromptContext.Provider
      value={{
        data: data,
        prompts: data.prompts,
        setPrompts,
        domains: data.domains,
        setDomains,
        updateAll,
        editModeData,
        setEditModeData,
        loading,
        fetchData
      }}
    >
      {props.children}
    </PromptContext.Provider>
  );
};
