/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../../../apis/notificationApi';
import { toolsProvisioningApiRef } from '../../../../../../apis/toolsProvisioningApi';
import { useEffect, useState } from 'react';

export const useStatus = (immutableId: string) => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const toolsprovisioningApi = useApi(toolsProvisioningApiRef);

  const [status, setStatus] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchMyAPI = async () => {
    if (toolsprovisioningApi) {
      try {
        const response: any = await toolsprovisioningApi.getStatus(immutableId);
        if (response?.status === 200) {
          setIsLoading(false);
          setStatus(response?.data);
        }
      } catch (error) {
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };
  useEffect(() => {
    if (immutableId) {
      setIsLoading(true);
      fetchMyAPI();
    }
  }, []);

  const refreshStatus = () => {
    setIsLoading(true);
    fetchMyAPI();
  };

  return [status, isLoading, refreshStatus] as const;
};
