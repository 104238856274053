import React, { useContext } from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutlined';
import { PromotionContext } from '../../providers/PromotionDataProvider';
import { Button, makeStyles } from '@material-ui/core';
import { VideoByteButton } from '../video-bytes/VideoByteButton';

const useStyles = makeStyles(theme => ({
  helpSection: {
    display: 'flex',
    float: 'right',
    marginRight: '0 !important',

    '& a': {
      color: '#003f2d',
      fontSize: '14px',
      textTransform: 'uppercase',
      padding: '6px 16px',
      display: 'flex',
      alignItems: 'center',
      width: '100px',
      borderRadius: '4px',
      textAlign: 'right',
    },

    '& svg': {
      marginRight: '5px',
    },

    '& a:hover': {
      backgroundColor: 'rgba(46, 119, 208, 0.04)',
    },
  },
}));

export const HelpComponent = ({ helpUrl }: any) => {
  const onclickHelpButton = useContext(PromotionContext);
  const classes = useStyles();

  const handleHelpModal = () => {
    onclickHelpButton.setToggleSwaggerButton(false);
    onclickHelpButton.setToggleHelpButton(!onclickHelpButton.toggleHelpButton);
    onclickHelpButton.setHelpUrl(helpUrl);
  };
  return (
    <div className={classes.helpSection}>
      <VideoByteButton />
      <Button
        onClick={handleHelpModal}
        role="button"
        color="primary"
        variant="text"
      >
        <HelpOutlineIcon /> Help
      </Button>
    </div>
  );
};
