import { Content, Header, Page } from '@backstage/core-components';
// @ts-ignore
import { useApi } from '@backstage/core-plugin-api';
import { Direction } from '@backstage/plugin-catalog-graph';
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Box, makeStyles, LinearProgress } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import BackButton from '../../utils/backButton';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import { EntityRelationsGraph } from './Graph/RenderGraph';
import CapabilityDetails from './Details/CapabilityDetails';
import { PromotionContext } from '../../../providers/PromotionDataProvider';
import { capabilityTaxonomyApiRef } from '../../../apis/capabilityTaxonomyApi';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { DevxBreadCrumb } from '../../common/BreadcrumbsNav/DevxBreadCrumb';

const useStyles = makeStyles(_theme => ({
  taxanomyView: {
    background: '#fff',
    position: 'relative',
    padding: '10px',
  },
  taxanomyToggleBtn: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    zIndex: 100,
  },
  taxanomyDetails: {
    background: '#fff',
    padding: '10px',
  },
}));

const TaxonomyView = () => {
  const onclickHelpButton = useContext(PromotionContext);
  useEffect(() => {
    onclickHelpButton.setToggleHelpButton(false);
  }, []);

  const capabilityTaxonomyApi = useApi(capabilityTaxonomyApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const classes = useStyles();
  const { kind, namespace, name } = useParams();
  const [entity, setEntity] = useState();
  const [direction, setDirection] = useState<Direction>(Direction.TOP_BOTTOM);
  const [graphDirection, setGraphDirection] = useState<string>('vertical');
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const onNodeClick = (node: any) => {
    navigate(`/taxonomy/view/${node?.kind}/${node?.namespace}/${node?.name}`);
  };

  useEffect(() => {
    if (graphDirection && graphDirection === 'vertical') {
      setDirection(Direction.TOP_BOTTOM);
    } else if (graphDirection && graphDirection === 'horizontal') {
      setDirection(Direction.LEFT_RIGHT);
    }
  }, [graphDirection]);

  useEffect(() => {
    if (kind && namespace && name) {
      setLoading(true);
      capabilityTaxonomyApi
        .getTaxonomyDetails(kind, namespace, name)
        .then((res: any) => {
          setLoading(false);
          setEntity(res);
        })
        .catch((error: any) => {
          setEntity(undefined);
          setLoading(false);
          notificationApi.sendNotification({
            severity: 'error',
            disapperAfterMs: 2500,
            message: `Error occurred while getting taxonomy details - ${error.message}`,
          });
        });
    }
  }, [kind, namespace, name]);

  return (
    <Page themeId="home">
      <Header
        title={name}
        subtitle={
          <DevxBreadCrumb
            routes={[
              {
                type: 'link',
                link: '/',
                text: 'Home',
              },
              {
                type: 'link',
                link: '/taxonomy',
                text: 'Capability Taxonomy',
              },
              {
                type: 'text',
                link: '',
                text: `${name}`,
              },
            ]}
          />
        }
      ></Header>
      <Content>
        {loading && <LinearProgress />}
        <Grid>
          <BackButton />
          <HelpComponent helpUrl={HELP_URL.VIEW_TAXONOMY} />
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <div className={classes.taxanomyView}>
              <Box className={classes.taxanomyToggleBtn}>
                <ToggleButtonGroup
                  exclusive
                  value={graphDirection}
                  onChange={(event, newValue) => {
                    event.stopPropagation();
                    setGraphDirection(newValue);
                  }}
                  size="small"
                >
                  <ToggleButton value="vertical">
                    <ArrowDownwardIcon />
                  </ToggleButton>
                  <ToggleButton value="horizontal">
                    <ArrowForwardIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <EntityRelationsGraph
                unidirectional={true}
                direction={direction}
                rootEntityNames={[{ kind, namespace, name }]}
                onNodeClick={onNodeClick}
                relations={['parentOf', 'childOf']}
              ></EntityRelationsGraph>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <div className={classes.taxanomyDetails}>
              <div style={{ flex: 1 }}>
                <CapabilityDetails entity={entity} />
              </div>
            </div>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
export default TaxonomyView;
