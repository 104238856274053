const Constants = {
  LabelText: {
    POINTS: 'Points',
    ASSETS: 'Assets',
    RATING: 'Rating',
    FEEDBACKS: 'Feedbacks',
    SUBSCRIBERS: 'Subscribers',
    YOU: '(You)',
    PTS: 'pts',
    LEVELSTART: 100,
    LEVELEND: 1000,
  },
  categoryName: {
    FEEDBACK_GIVEN: 'Feedback',
    FEEDBACK_ADDED: 'Feedback Added',
    PUBLISHED_ASSET: 'Asset',
    SUBSCRIBER_ADDED: 'Subscriber',
    SUBSCRIBING_ASSET: 'Subscribing Asset',
    RATING_GIVEN: 'Rating',
    TYPE_ADDED: 'Type',
    PR_MERGED: 'PR Merged',
    PUBLISHED_TECHDOCS: 'Techdocs',
    PUBLISHED_EXTDOCS: 'Extdocs',
    ADHOC: 'adhoc',
    SOFTWARE_TEMPLATES_DOWNLOAD: 'Software Template Download',
    REVIEW_APPROVAL_RECEIVED: 'Review Approval Received',
    RATING_RECEIVED: 'Rating Received',
  },
};

export default Constants;
