import React, { useContext, useEffect, useState } from 'react';
import TPRoutes from './Routes';
import { Header, Page } from '@backstage/core-components';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';
import { useLocation } from 'react-router';
import { ITPContext, TPContext } from './providers/TPProvider';
import { LinearProgress } from '@material-ui/core';
import { useStyles } from './styles';

const TPHome = () => {
  const location = useLocation();
  const classes = useStyles();
  const { apmInfo }: ITPContext = useContext(TPContext);

  const headerTitle = apmInfo?.Name || '';
  const apmNumber = apmInfo?.ApmNumber || '';

  const initialBreadcrumValue = {
    title: 'Provisioning Center',
    subTitleRoutes: [
      {
        type: 'link',
        text: 'Home',
        link: '/',
      },
      {
        type: 'link',
        text: 'Provisioning Center',
        link: '/provisioning-center',
      },
    ],
  };

  const [breadCrumb, setBreadCrumb] = useState(initialBreadcrumValue);

  const tpBreadCrumbs = () => {
    const projectDetailPage = location.pathname.includes('/project/');
    const projectToolManagementPage =
      location.pathname.includes('/tools-management');

    const servicesPage = location.pathname.includes('/services');
    const performanceTestPage = location.pathname.includes('/performance-test');

    let title = initialBreadcrumValue.title;
    let subTitleRoutes = initialBreadcrumValue.subTitleRoutes;
    let breadCrumbsToInsert = null;

    if (projectDetailPage) {
      title = headerTitle;
      breadCrumbsToInsert = [
        {
          type: 'text',
          text: headerTitle,
          link: '',
        },
      ];
    }
    if (projectToolManagementPage) {
      title = 'Tools Management';
      breadCrumbsToInsert = [
        {
          type: 'link',
          text: headerTitle,
          link: `project/${apmNumber}`,
        },
        {
          type: 'text',
          text: 'Tools Management',
          link: '',
        },
      ];
    }

    /* For Services Pages */
    if (servicesPage) {
      title = 'Services';
      breadCrumbsToInsert = [
        {
          type: 'link',
          text: headerTitle,
          link: `project/${apmNumber}`,
        },
        {
          type: 'text',
          text: 'Services',
          link: '',
        },
      ];
    }

    if (performanceTestPage) {
      title = `${headerTitle} - Performance Test`;
      breadCrumbsToInsert = [
        {
          type: 'link',
          text: headerTitle,
          link: `project/${apmNumber}`,
        },
        {
          type: 'link',
          text: 'Services',
          link: `project/${apmNumber}/services`,
        },
        {
          type: 'text',
          text: 'Performance Test',
          link: '',
        },
      ];
    }

    if (breadCrumbsToInsert !== null)
      subTitleRoutes = [
        ...initialBreadcrumValue.subTitleRoutes,
        ...breadCrumbsToInsert,
      ];
    else {
      subTitleRoutes[1].type = 'text';
    }
    setBreadCrumb({
      title,
      subTitleRoutes,
    });
  };

  useEffect(() => {
    tpBreadCrumbs();
  }, [location, apmInfo]);

  return (
    <Page themeId="home">
      <LinearProgress className={classes.flex} />
      {(location.pathname.includes('/project/') && apmNumber) ||
      location.pathname === '/provisioning-center' ||
      location.pathname === '/provisioning-center/' ? (
        <Header
          title={undefined}
          pageTitleOverride={breadCrumb?.title}
          subtitle={<DevxBreadCrumb routes={breadCrumb?.subTitleRoutes} />}
        />
      ) : (
        <LinearProgress className={classes.gridAreaPageHeader} />
      )}
      <TPRoutes />
    </Page>
  );
};

export default TPHome;
