/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';

import { promptApiRef } from '../../../../apis/promptManagementApi';
import { IPromptData } from '../interfaces';
import { useContext } from 'react';
import { PromptContext } from '../providers/PromptProvider';
import { AuthContext } from '../../../../providers/AuthProvider';

export const useApprovalOperations = () => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const promptManagementApi = useApi(promptApiRef);
  const { prompts, setPrompts } = useContext(PromptContext);
  const { profEmail } = useContext(AuthContext);

  enum status {
    APPROVED = 'Approved',
    SUBMITTED = 'Submitted',
    RESUBMIT = 'Resubmit',
    REJECTED = 'Rejected',
  }

  const approvePrompt = async (
    promptData: IPromptData,
    promptId: string,
    comment: string,
    scope: any,
  ) => {
    if (promptManagementApi) {
      try {
        const persona = [scope.personaId];
        const subproduct = scope.subDomainId;

        const response = await promptManagementApi.updatePrompt(
          {
            ...promptData,
            persona,
            subproduct,
            status: status.APPROVED,
            comments: {
              reviewer: profEmail,
              additionalComments: comment,
            },
          },
          promptId,
        );
        if (response.status) {
          setPrompts(
            prompts.map((prompt: IPromptData) =>
              prompt.id === response.prompt.id ? response.prompt : prompt,
            ),
          );
        }
        return response;

        //  setIsLoading(false);
      } catch (error) {
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };

  const sendForReview = async (
    promptData: IPromptData,
    promptId: string,
    comment: string,
  ) => {
    if (promptManagementApi) {
      try {
        const response = await promptManagementApi.updatePrompt(
          {
            ...promptData,
            status: status.RESUBMIT,
            comments: {
              reviewer: profEmail,
              additionalComments: comment,
            },
          },
          promptId,
        );
        if (response.status) {
          setPrompts(
            prompts.map((prompt: IPromptData) =>
              prompt.id === response.prompt.id ? response.prompt : prompt,
            ),
          );
        }
        return response;

        //  setIsLoading(false);
      } catch (error) {
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };

  const rejectPrompt = async (
    promptData: IPromptData,
    promptId: string,
    comment: string,
  ) => {
    if (promptManagementApi) {
      try {
        const response = await promptManagementApi.updatePrompt(
          {
            ...promptData,
            status: status.REJECTED,
            comments: {
              reviewer: profEmail,
              additionalComments: comment,
            },
          },
          promptId,
        );
        if (response.status) {
          setPrompts(
            prompts.map((prompt: IPromptData) =>
              prompt.id === response.prompt.id ? response.prompt : prompt,
            ),
          );
        }
        return response;

        //  setIsLoading(false);
      } catch (error) {
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };
  return { approvePrompt, sendForReview, rejectPrompt };
};
