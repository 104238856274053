import React, { ComponentType } from 'react';
import { Routes, Route, useOutlet } from 'react-router-dom';
import { Entity } from '@backstage/catalog-model';
import { TemplateEntityV1beta3 } from '@backstage/plugin-scaffolder-common';
import { TaskPage } from './TaskPage';
import { SecretsContextProviderPoc } from './SecretsContext';

import {
  customTypes,
  FIELD_EXTENSION_WRAPPER_KEY,
  FIELD_EXTENSION_KEY,
  DEFAULT_SCAFFOLDER_FIELD_EXTENSIONS,
} from './extensions';
import {
  useElementFilter,
  createRouteRef,
  createSubRouteRef,
} from '@backstage/core-plugin-api';
import { PocListPage } from './PocListPage';
import { PocDetailsPage } from './PocDetailsPage';
import { AddPoc } from './AddPocPage/AddPoc';
import { AdditionalPocPage } from './AdditionalPocPage';
import { EditPoc } from './EditPocPage/EditPoc';
import ClonePocPage from './ClonePocPage';

/**
 * The props for the entrypoint `ScaffolderPage` component the plugin.
 * @public
 */
export type RouterProps = {
  components?: {
    TemplateCardComponent?:
      | ComponentType<{ template: TemplateEntityV1beta3 }>
      | undefined;
    TaskPageComponent?: ComponentType<{}>;
  };
  groups?: Array<{
    title?: React.ReactNode;
    filter: (entity: Entity) => boolean;
  }>;
  defaultPreviewTemplate?: string;
};

export const rootRouteRef = createRouteRef({
  id: 'cbre.pocHub',
});

export const selectedPocRouteRef = createSubRouteRef({
  id: 'cbre.pocHub/id',
  parent: rootRouteRef,
  path: '/:id',
});

export const pocAddRouteRef = createSubRouteRef({
  id: 'cbre.pocHub/addPoc',
  parent: rootRouteRef,
  path: '/add-poc',
});

export const pocHubTaskRouteRef = createSubRouteRef({
  id: 'cbre.pocHub/task',
  parent: rootRouteRef,
  path: '/add-poc/tasks/:taskId',
});
export const pocHubAdditionalInfoRef = createSubRouteRef({
  id: 'cbre.pocHub/additional',
  parent: rootRouteRef,
  path: '/add-poc/additional-details',
});

export const actionsRouteRef = createSubRouteRef({
  id: 'cbre.pocHub/actions',
  parent: rootRouteRef,
  path: '/actions',
});

export const pocEditRouteRef = createSubRouteRef({
  id: 'cbre.pocHub/editPoc',
  parent: rootRouteRef,
  path: '/edit-poc',
});

export const pocCloneRouteRef = createSubRouteRef({
  id: 'cbre.pocHub/clonePoc',
  parent: rootRouteRef,
  path: '/:assetName/clone-poc',
});

/**
 * The main entrypoint `Router` for the `ScaffolderPlugin`.
 *
 * @public
 */
export const PocHubRouter = (props: RouterProps) => {
  const { components = {} } = props;

  const { TaskPageComponent } = components;

  const outlet = useOutlet();
  const TaskPageElement = TaskPageComponent ?? TaskPage;

  const customFieldExtensions = useElementFilter(outlet, elements =>
    elements
      .selectByComponentData({
        key: FIELD_EXTENSION_WRAPPER_KEY,
      })
      .findComponentData<customTypes.FieldExtensionOptions>({
        key: FIELD_EXTENSION_KEY,
      }),
  );

  const fieldExtensions = [
    ...customFieldExtensions,
    ...DEFAULT_SCAFFOLDER_FIELD_EXTENSIONS.filter(
      ({ name }) =>
        !customFieldExtensions.some(
          customFieldExtension => customFieldExtension.name === name,
        ),
    ),
  ];

  return (
    <Routes>
      <Route path="/" element={<PocListPage />} />
      <Route path="/:assetName" element={<PocDetailsPage />} />
      <Route
        path="/add-poc"
        element={
          <SecretsContextProviderPoc>
            <AddPoc customFieldExtensions={fieldExtensions} />
          </SecretsContextProviderPoc>
        }
      />
      <Route
        path="/add-poc/additional-details"
        element={<AdditionalPocPage />}
      />
      <Route path="/add-poc/tasks/:taskId" element={<TaskPageElement />} />
      <Route path="/actions" element={<div />} />
      <Route
        path="/:assetName/clone-poc"
        element={
          <SecretsContextProviderPoc>
            <ClonePocPage customFieldExtensions={fieldExtensions} />
          </SecretsContextProviderPoc>
        }
      />
      <Route
        path="/:assetName/clone-poc/tasks/:taskId"
        element={<TaskPageElement forClone />}
      />

      <Route
        path="/preview"
        element={
          <SecretsContextProviderPoc>
            <div />
          </SecretsContextProviderPoc>
        }
      />
      <Route
        path="/edit-poc/:assetName"
        element={
          <SecretsContextProviderPoc>
            <EditPoc />
          </SecretsContextProviderPoc>
        }
      />
    </Routes>
  );
};
