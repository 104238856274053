/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
// @ts-ignore
import { useApi } from '@backstage/core-plugin-api';
import { entityConversionApiRef } from '../../apis/entityConversionApi';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';

export const useGetConversionApi = (repoName: string, kind: string) => {
  const [loading, setLoading] = useState(true);
  const [localEntity, setLocalEntity] = useState({});

  const entityConversionApi = useApi(entityConversionApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const [shouldRefetchFromApi, refetch] = useState({});

  const refetchEntity = () => refetch({});

  useEffect(() => {
    if (repoName && kind) {
      const fetchData = async () => {
        setLoading(true);

        entityConversionApi
          .getData(repoName, kind)
          .then((res: any) => {
            setLoading(false);
            setLocalEntity(res.data);
          })
          .catch((error: any) => {
            setLoading(false);
            setLocalEntity({});
            notificationApi.sendNotification({
              severity: 'error',
              disapperAfterMs: 2500,
              message: `Error occurred while getting entity information - ${error.message}`,
            });
            return error;
          });
      };

      fetchData();
    }
  }, [repoName, kind, shouldRefetchFromApi]);

  return { loading, localEntity, refetchEntity };
};
