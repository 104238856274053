import React, { useState, useEffect } from 'react';
import { Grid, LinearProgress } from '@material-ui/core';
import { PageWithHeader, Content } from '@backstage/core-components';
import BreadcrumbsNav from '../common/BreadcrumbsNav/BreadcrumbsNav';
import { HelpComponent } from '../utils/helpComponent';
import HELP_URL from '../utils/helpLinkConstant';
import BackButton from '../utils/backButton';
import { DataCategoryGroup } from './DataCategoryGroup';
import { useApi } from '@backstage/core-plugin-api';
import {
  DataInsightsApi,
  DataInsightsApiRef,
  Item,
} from '../../apis/dataInsights';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';
import { useStarredEntities } from '@backstage/plugin-catalog-react';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';
import { useStyles } from './common/styles';

const LayoutPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<Item[]>([]);
  const [favoriteItem, setFavoriteItem] = useState<Item[]>([]);
  const [categories, setCategories] = useState([]);
  const classes = useStyles();
  const dataInsights: DataInsightsApi = useApi(DataInsightsApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const { starredEntities } = useStarredEntities();

  const getSetCategories = (localData: Item[]) => {
    const categoriesSet: any = new Set();
    localData.forEach((obj: Item) => {
      categoriesSet.add(obj.category_name);
    });
    setCategories(Array.from(categoriesSet));
  };

  const getAllItemsData = () => {
    setIsLoading(true);
    dataInsights
      .getItems()
      .then((responseData: any) => {
        setData(responseData);
        getSetCategories(responseData);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        notificationApi.sendNotification({
          message: `Failed to load Data`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  useEffect(() => {
    getAllItemsData();
  }, []);

  useEffect(() => {
    const favMap: any = {};
    starredEntities.forEach((value: string) => {
      favMap[`${value}`] = true;
    });

    const favItem = data?.filter(item => favMap[item?.id]) || [];
    setFavoriteItem(favItem);
  }, [starredEntities, data]);

  return (
    <PageWithHeader
      title="Data Insights "
      themeId="home"
      subtitle={
        <DevxBreadCrumb
          routes={[
            { type: 'link', link: '/', text: 'Home' },
            {
              type: 'text',
              link: '/data-insights',
              text: 'Data Insights',
            },
          ]}
        />
      }
    >
      <Content>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <BackButton />
            <HelpComponent helpUrl={HELP_URL.DATAINSIGHTS_PAGE} />
          </Grid>
        </Grid>
        {isLoading && <LinearProgress />}
        {!isLoading && !data && <p>No records found.</p>}
        <div className={`codePage ${classes.layoutPage}`}>
          <Grid container>
            <Grid item xs={12}>
              {favoriteItem && favoriteItem.length > 0 && (
                <DataCategoryGroup
                  categoryName="Favorites"
                  tools={favoriteItem}
                />
              )}

              {data &&
                Array.isArray(categories) &&
                categories.map(item => {
                  return (
                    <DataCategoryGroup
                      key={item}
                      categoryName={item}
                      tools={data}
                    />
                  );
                })}
            </Grid>
          </Grid>
        </div>
      </Content>
    </PageWithHeader>
  );
};
export default LayoutPage;
