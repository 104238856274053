import { SHADOW_DOM_STYLE_LOAD_EVENT } from '@backstage/plugin-techdocs-react';
import type { Transformer } from './transformer';

type OnCssReadyOptions = {
  onLoading: () => void;
  onLoaded: () => void;
};

export const onCssReady = ({
  onLoading,
  onLoaded,
}: OnCssReadyOptions): Transformer => {
  return dom => {
    onLoading();
    dom.addEventListener(
      SHADOW_DOM_STYLE_LOAD_EVENT,
      function handleShadowDomStyleLoad() {
        onLoaded();
        dom.removeEventListener(
          SHADOW_DOM_STYLE_LOAD_EVENT,
          handleShadowDomStyleLoad,
        );
      },
    );
    return dom;
  };
};