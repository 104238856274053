import { makeStyles } from '@material-ui/core';

export const preBuildedStyles = makeStyles(theme => ({
  discoverCarousalHelpGrid: {
    display: 'flex',
    [theme.breakpoints.down(600)]: {
      flex: 'colum-reverse',
    },
  },
}));

export const PromotionsBreadCrumbsStyles = makeStyles(theme => ({
  breadCrumbText: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
