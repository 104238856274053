/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../../../apis/notificationApi';
import { toolsProvisioningApiRef } from '../../../../../../apis/toolsProvisioningApi';
import { useEffect, useState } from 'react';
import { IConfigData } from '../../../../interfaces';

export const usePTOperations = (apmNumber: string, shouldcall: boolean) => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const toolsprovisioningApi = useApi(toolsProvisioningApiRef);

  const [callCounter, setCallCounter] = useState<number>(Number(shouldcall));
  const [configs, setConfigs] = useState<IConfigData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshTime, setRefreshTime] = useState<string>('');

  const getAllConfigs = async () => {
    setLoading(true);
    if (toolsprovisioningApi) {
      try {
        const response = await toolsprovisioningApi.getPTConfigs(apmNumber);
        setLoading(false);
        if (response) {
          setConfigs(response);
          setRefreshTime(new Date().toISOString());
        }
      } catch (error) {
        setLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
    return null;
  };

  const refreshConfigs = () => {
    setCallCounter(prevState => prevState + 1);
  };

  const addConfig = async (config: any) => {
    setLoading(true);
    if (toolsprovisioningApi) {
      try {
        const response = await toolsprovisioningApi.savePTConfig(config);
        if (response?.status === 200) {
          setLoading(false);
          setConfigs((prevState: any) => [...prevState, response.Data]);
          notificationApi.sendNotification({
            message: 'Config added successfully', // response?.reason || 'Success',
            disapperAfterMs: 2500,
            severity: 'success',
          });
        }
      } catch (error) {
        setLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
    return null;
  };

  const validateAndAddConfig = async (config: any) => {
    setLoading(true);
    if (toolsprovisioningApi) {
      try {
        const response = await toolsprovisioningApi.savePTConfig(config, true);
        if (response?.status === 200) {
          setLoading(false);
          setConfigs((prevState: any) => [...prevState, response.Data]);
          notificationApi.sendNotification({
            message: 'Validation in progress', // response?.reason || 'Success',
            disapperAfterMs: 2500,
            severity: 'success',
          });
        }
      } catch (error) {
        setLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
    return null;
  };

  const editConfigAndValidate = async (config: IConfigData) => {
    setLoading(true);
    if (toolsprovisioningApi) {
      try {
        const response = await toolsprovisioningApi.updatePTConfig(
          config,
          true,
        );
        if (response) {
          setLoading(false);

          notificationApi.sendNotification({
            message:
              response?.message ||
              'Edited successfully, validation in progress',
            disapperAfterMs: 2500,
            severity: 'success',
          });
        }
      } catch (error) {
        setLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
    return null;
  };
  const editConfig = async (config: IConfigData) => {
    setLoading(true);
    if (toolsprovisioningApi) {
      try {
        const response = await toolsprovisioningApi.updatePTConfig(config);
        if (response) {
          setLoading(false);

          notificationApi.sendNotification({
            message: response?.message || 'Edited successfully',
            disapperAfterMs: 2500,
            severity: 'success',
          });
        }
      } catch (error) {
        setLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
    return null;
  };

  const runConfig = async (configId: string) => {
    setLoading(true);
    if (toolsprovisioningApi) {
      try {
        const response = await toolsprovisioningApi.runPTConfig(configId);
        if (response) {
          setLoading(false);

          notificationApi.sendNotification({
            message: response?.reason || 'Run queued',
            disapperAfterMs: 2500,
            severity: 'success',
          });
        }
      } catch (error) {
        setLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
    return null;
  };
  const validateConfig = async (configId: string) => {
    setLoading(true);
    if (toolsprovisioningApi) {
      try {
        const response = await toolsprovisioningApi.validatePTConfig(configId);
        if (response) {
          setLoading(false);

          notificationApi.sendNotification({
            message: response?.reason || 'Validation in progress',
            disapperAfterMs: 2500,
            severity: 'success',
          });
        }
      } catch (error) {
        setLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
    return null;
  };

  const deleteConfig = async (configId: string) => {
    setLoading(true);
    if (toolsprovisioningApi) {
      try {
        const response = await toolsprovisioningApi.deletePTConfig(configId);
        if (response) {
          setLoading(false);
          setConfigs((prevState: any) =>
            prevState.filter((config: any) => config.devxConfigId !== configId),
          );

          notificationApi.sendNotification({
            message: response?.message || 'Deleted successfully',
            disapperAfterMs: 2500,
            severity: 'success',
          });
        }
      } catch (error) {
        setLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
    return null;
  };

  const duplicateConfig = async (seledtedConfig: any) => {
    setLoading(true);
    if (toolsprovisioningApi) {
      const { config } = seledtedConfig;
      const { devxConfigId, devxConfigName, ...rest } = config;

      const newConfig = {
        ...rest,
        devxConfigName: `${devxConfigName}${Math.floor(Date.now() % 100000)}`,
      };
      try {
        const response = await toolsprovisioningApi.savePTConfig(newConfig);
        if (response) {
          setLoading(false);
          notificationApi.sendNotification({
            message: response?.message || 'Duplicated successfully',
            disapperAfterMs: 2500,
            severity: 'success',
          });
        }
      } catch (error) {
        setLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
    return null;
  };

  useEffect(() => {
    if (callCounter) getAllConfigs();
  }, [callCounter]);

  return {
    configs,
    refreshTime,
    refreshConfigs,
    addConfig,
    validateAndAddConfig,
    editConfig,
    editConfigAndValidate,
    runConfig,
    validateConfig,
    deleteConfig,
    duplicateConfig,
    loading,
  } as const;
};
