import React from 'react';
// import { Entity } from '@backstage/catalog-model';
import { useAsyncEntity } from '@backstage/plugin-catalog-react';
import { Chip, Card, CardContent, makeStyles, Grid } from '@material-ui/core';
import { AboutField } from '../../AboutField';
import { isEmpty } from 'lodash';

const useStyles = makeStyles({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)',
    marginBottom: '10px',
    ['@media (max-width:920px) and (min-width: 280px)']: {
      position: 'relative',
      bottom: '3%',
    },
  },
  gridItemCardContent: {
    flex: 1,
  },
  regionCard: {
    marginBottom: '0px',
    margin: '2px',
    height: '20px',
  },
  servicesField: {
    marginBottom: '0px',
    margin: '2px',
    height: '20px',
  },
  countryCard: {
    marginBottom: '0px',
    margin: '2px',
    height: '20px',
  },
});

export function SegmentCard() {
  const classes = useStyles();
  const { entity } = useAsyncEntity();

  const tempDate = entity?.metadata?.lastUpdatedDateTime ? entity?.metadata?.lastUpdatedDateTime as string : entity?.metadata?.creationTimestamp as string;
  const formatDate = tempDate && tempDate?.toString().split('T');
  const DateTime =
    formatDate && formatDate[0] + ' ' + formatDate[1].substring(0, 5);

  // @ts-ignore
  let apiService = entity?.metadata?.apiservice ? entity?.metadata?.apiservice : [];
  // @ts-ignore
  if (entity?.metadata?.assetstore && entity?.metadata?.assetstore.toLowerCase() === 'wso2') {
    // @ts-ignore
    apiService = entity?.metadata?.apiservice ? entity?.metadata?.apiservice[0].split(',') : [];
  }

  return (
    <Card className={classes.gridItemCard}>
      <CardContent className={classes.gridItemCardContent}>
        <Grid container>
          <AboutField
            label="Segment"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={entity?.metadata?.apisegment as string}
          />
          {isEmpty(entity?.metadata?.apiregions?.[0]) ? (
            <AboutField
              label="Region"
              value="No Region"
              gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            ></AboutField>
          ) : (
            <AboutField
              label="Region"
              value="No Region"
              gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            >
              {(entity?.metadata?.apiregions || []).map(t => (
                <Chip
                  key={t}
                  color="primary"
                  variant="outlined"
                  size="small"
                  label={t}
                  className={classes.regionCard}
                />
              ))}
            </AboutField>
          )}
          {isEmpty(entity?.metadata?.apiregions?.[0]) ? (
            <AboutField
              label="Country"
              value="No Country"
              gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            ></AboutField>
          ) : (
            <AboutField
              label="Country"
              value="No Country"
              gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            >
              {(entity?.metadata?.apicountry || []).map(t => (
                <Chip
                  key={t}
                  color="primary"
                  variant="outlined"
                  size="small"
                  label={t}
                  className={classes.countryCard}
                />
              ))}
            </AboutField>
          )}
          <AboutField
            label="API Category"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={entity?.metadata?.apiCategory as string}
          />
          {/* <AboutField
            label="APMID"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={entity?.metadata?.apmid as string}
          /> */}
          {/* <AboutField
            label="APM Number"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={entity?.metadata?.apmnumber as string}
          /> */}
          <AboutField
            label="Service"
            gridSizes={{ xs: 12, sm: 12, lg: 12 }}
            // value={entity?.metadata?.apiservice as string}
            value="No service"
          >
            {apiService.map(t => t === '' ? <></> : (
              <Chip
                key={t}
                size="small"
                color="primary"
                variant="outlined"
                label={t}
                className={classes.servicesField}
              />
            ))}
          </AboutField>
          {/* <AboutField
            label="Performance"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={entity?.metadata?.apiperformance as string}
          /> */}
          {/* <AboutField
            label="Reusable"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={entity?.spec?.reusable as string}
          /> */}
          <AboutField
            label="Last Updated Date"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={DateTime}
          />
        </Grid>
      </CardContent>
    </Card>
  );
}
