import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
} from '@material-ui/core';
import { AboutCardContent } from './AboutCardContent';

const useStyles = makeStyles({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',

    // background: '#fff',
    maxWidth: 900,
  },
  gridItemCardContent: {
    flex: 1,
  },
});

export const AboutCard = props => {
  const classes = useStyles();

  return (
    <Card className={classes.gridItemCard}>
      <CardHeader title="About" />
      <Divider />
      <CardContent className={classes.gridItemCardContent}>
        <AboutCardContent {...props} />
      </CardContent>
    </Card>
  );
};
