import React, { useState } from 'react';
import { TablePagination } from '@material-ui/core';

export function usePagination(data: any, itemsPerPage: any) {
  const [currentPage, setCurrentPage] = useState(0);
  const maxPage = Math.ceil(data.length / itemsPerPage);

  function currentData() {
    const begin = currentPage * itemsPerPage;
    const end = begin + itemsPerPage;
    return data.slice(begin, end);
  }

  function next() {
    setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
  }

  function jump(page: any) {
    const pageNumber = Math.max(0, page);
    setCurrentPage(currentPage => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentData, currentPage, maxPage };
}

export const Pagination = (props: any) => {
  const { data } = props;
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const PER_PAGE = 5;

  const count = Math.ceil(data.length / PER_PAGE);
  const _DATA = usePagination(data, PER_PAGE);
  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
    _DATA.jump(newPage);
  };
  const handleChangeRowsPerPage = (event: any) => {
    setRecordsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <TablePagination
        component="div"
        count={count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={recordsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
