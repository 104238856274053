/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { swTemplateCustomApiRef } from '../../../apis/swTemplateCustomApi';
import { useEffect, useState } from 'react';

export const useGetLikes = (
  name: string,
  startCall: boolean,
  backendRefresh: boolean,
) => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const swTemplateCustomApi = useApi(swTemplateCustomApiRef);

  const [templateLikesCount, setTemplateLikesCount] = useState(0);
  const fetchMyAPI = async () => {
    if (swTemplateCustomApi) {
      try {
        const response = await swTemplateCustomApi.getLikesCount(name);
        setTemplateLikesCount(response.likesCount);
      } catch (error) {
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };

  useEffect(() => {
    if (startCall) {
      fetchMyAPI();
    }
  }, [name, startCall, backendRefresh]);

  return [templateLikesCount];
};
