import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { CatalogEntityPage, catalogPlugin } from '@backstage/plugin-catalog';
import { SearchPage } from '@backstage/plugin-search';
import {
  TechDocsIndexPage,
  TechDocsReaderPage,
  EntityTechdocsContent,
  techdocsPlugin,
} from '@backstage/plugin-techdocs';
import {
  CatalogGraphPage,
  catalogGraphPlugin,
} from '@backstage/plugin-catalog-graph';

import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_OWNED_BY,
  RELATION_OWNER_OF,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';
import MockPage from './components/api-mock/MockContainer';
import RegisterMock from './components/api-mock/RegisterMock';
import RegisterApiPage from './components/api-doc/register-api/RegisterApiPage';
import GenerateSwaggerPage from './components/api-doc/register-api/GenerateSwaggerPage';
import ConfirmDetailsPage from './components/api-doc/register-api/ConfirmDetailsPage';
import RegisterSuccess from './components/api-doc/register-api/RegisterSuccess';
import MockSwaggerContainer from './components/api-mock/swagger-generation/MockSwaggerContainer';
import { SettingsPage } from './components/settingsPage/SettingsPage';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { Home } from './components/home';
import { Root } from './components/Root';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInProviderConfig,
  AutoLogout,
  // SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';

import {
  microsoftAuthApiRef,
  discoveryApiRef,
  useApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import cbreTheme from './components/cbreTheme';
import darkCbreTheme from './components/darkTheme';
import { Code } from './components/code';
import { ApiPage } from './components/api-doc';
import { Admin } from './components/admin';
import { PromotionPage } from './components/Promotions/Promotions';
import { ComponentEditPage } from './components/EntityEditPage/ComponentEditPage';
import { ReadmePage } from '@internal/plugin-readme';
import { ApiEditPage } from './components/EntityEditPage/ApiEditPage/ApiEditPage';
import { searchPage } from './components/search/SearchPage';
import FeedbackPage from './components/feedback/FeedBackContainer';
import DocsContainer from './components/docs/DocsContainer';
import RegisterExternalDocs from './components/docs/ExternalDocs/RegisterExternalDocs';
import RegisterFeedback from './components/feedback/RegisterFeedback';
import { PromotionProvider } from './providers/PromotionDataProvider';
import { GuidedTourProvider } from './components/guided-tour/providers/GuidedTourProvider';
import TaxonomyPage from './components/taxonomy/TaxonomyPage';
import TaxonomyEditPage from './components/taxonomy/Manage/TaxonomyEditPage';
import { PromotionDetail } from './components/Promotions/PromotionDetail';
import { UpdatePromotions } from './components/Promotions/UpdatePromotions';

import TaxonomyView from './components/taxonomy/View/TaxonomyView';
import GlobalHelpContainer from './components/globalHelp/globalHelpContainer';
import HelpContainer from './components/help/helpContainer';
import {
  cbreScaffolderPlugin,
  ScaffolderPage,
} from './components/scaffolder/plugin';
import { LeaderBoardDetailsPage } from './components/leaderBoard/leaderBoardDetails';
import ToolboxContainer from './components/toolbox';
import InformationExchange from './components/information-exchange';

import ReleaseNotePage from './components/releaseNotePage';

import './App.css';
import AppNotifications from './components/notifications';
import VisualRegressionPage from './components/visual-regression';
import TestHistory from './components/visual-regression/TestHistory';
import LeanIXPage from './components/leanIx';
import { ChatBotProvider } from './providers/ChatBotProvider';
import Chatbot from './components/chatbot';
import ToolboxDetailsPage from './components/toolboxDetailsPage';
import ToolsProvisioning from './components/tools-provisioning';

import UserPopup from './components/common/UserPopup/UserPopup';
import {
  UtilityToolDetailsPage,
  UtilityToolsPage,
} from './components/utility-tools';
import LightMode from '@material-ui/icons/LightMode';
import DarkMode from '@material-ui/icons/DarkMode';
import DetailsPage from './components/visual-regression/DetailsPage';
import { UnifiedThemeProvider } from '@backstage/theme';
import Chat from './components/openai';

import { SignInPage } from './components/common/SignInPage';
import DevxGuidedTour from './components/devx-guided-tour';
import LayoutPage from './components/data-insights';
import PromptEnhancerPage from './components/promptEnhancer/PromptEnhancer';
import DashboardPage from './components/data-insights/DashboardPage';
import { PocHubRouter } from './components/poc-hub';
import { cbrePocHubPlugin } from './components/poc-hub/plugin';
import { VideoBytesProvider } from './components/video-bytes/providers/VideoBytesProvider';
import { PromptProvider } from './components/admin/promptManagement/providers/PromptProvider';
import { FrontendConfigProvider } from './providers/FrontendConfigProvider';
import PromptStore from './components/PromptStore';
import { AIPopupProvider } from './components/common/AIPopup/providers/AIPopupProvider';
import AIPopup from './components/common/AIPopup';
import { RadarPage as TechRadarPage } from './components/techRadar';
import AiInSdlc from './components/ai-in-sdlc';

const microsoftProvider: SignInProviderConfig = {
  id: 'microsoft-auth-provider',
  title: 'Microsoft Azure AD',
  message: 'Sign in using SSO credentials',
  apiRef: microsoftAuthApiRef,
};

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    background: TypeBackground;
    primary: PaletteColor;
    secondary: PaletteColor;
    error: PaletteColor;
    warning: PaletteColor;
    info: PaletteColor;
    success: PaletteColor;
    alert: {
      background: string;
      border: string;
      link: string;
      color: string;
    };
    toolTip: {
      background: string;
      textColor: string;
    };
    banner: TypeBanner;
    border: string;
    successBackground: string;
    errorBackground: string;
    warningBackground: string;
    infoBackground: string;
    successText: string;
    errorText: string;
    infoText: string;
    warningText: string;
    navigation: {
      background: string;
      indicator: string;
      color: string;
      selectedColor: string;
      navItem: {
        hoverBackground: string;
      };
      submenu: {
        background: string;
      };
    };
    text: TypeText;
    customCard: {
      cardHeader: string;
      tabHighlight: string;
      foldBg: string;
    };
    table: {
      headerBackground: string;
    };
    textEditor: {
      color: string;
    };
    chat: {
      primary: {
        background: string;
        text: string;
        hyperText: string;
        button: string;
      };
      secondary: {
        background: string;
        text: string;
      };
    };
    prompt: {
      table: {
        domain: string;
        subdomain: string;
        persona: string;
      };
      loader: {
        primary: string;
        secondary: string;
      };
      options: {
        background: string;
        text: string;
        button: string;
        color: string;
        header: string;
        paper: string;
        title: string;
        border: string;
        inputBackground: string;
        bot: {
          background: string;
          text: string;
        };
        user: {
          background: string;
          text: string;
        };
      };
      chat: {
        background: string;
      }
    };
    panel: {
      varient1: string;
      varient2: string;
    };
    bgVariants: {
      variant1: string;
      variant2: string;
      variant3: string;
      variant4: string;
    };
    textVariants: {
      variant1: string;
      variant2: string;
      variant3: string;
      variant4: string;
    };
    colors: {
      purple: string;
      darkblue: string;
      greencyan: string;
      sage: string;
      blue: string;
      gold: string;
      green: string;
      white: string;
      richBlack: string;
    };
  }
  interface TypeBackground {
    default: string;
    paper: string;
    tertiary: string;
    secondary: string;
  }
  interface PaletteColor {
    light: string;
    main: string;
    dark: string;
    contrastText: string;
    active: string;
  }
  interface TypeBanner {
    info: string;
    error: string;
    text: string;
    link: string;
  }
  interface TypeText {
    primary: string;
    contrast: string;
    secondary: string;
    tertiary: string;
    muted: string;
    hint: string;
    disabled: string;
    signIn: string;
    buttonText: string;
  }
}

async function setTokenCookie(url: string, token: string | undefined) {
  if (!token) {
    return;
  }

  await fetch(url, {
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export const THEME_IDS = {
  light: 'cbre-theme',
  dark: 'dark-theme',
};

const app = createApp({
  apis,
  components: {
    // SignInPage: props => <SignInPage {...props} auto providers={['guest']} />,

    SignInPage: props => {
      const discoveryApi = useApi(discoveryApiRef);
      return (
        <SignInPage
          {...props}
          // auto
          provider={microsoftProvider}
          onSignInSuccess={async (identityApi: IdentityApi) => {
            // When logged in, set a token cookie
            if (typeof identityApi.getCredentials !== 'undefined') {
              const { token } = await identityApi.getCredentials();
              const cookieEndpointUrl = await discoveryApi.getBaseUrl('cookie');

              setTokenCookie(cookieEndpointUrl, token);
            }
            // forward results
            props.onSignInSuccess(identityApi);
          }}
        />
      );
    },
  },
  themes: [
    {
      id: THEME_IDS.light,
      title: 'CBRE LIGHT',
      variant: 'light',
      icon: <LightMode />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={cbreTheme} children={children} />
      ),
    },
    {
      id: THEME_IDS.dark,
      title: 'CBRE DARK',
      variant: 'dark',
      icon: <DarkMode />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={darkCbreTheme} children={children} />
      ),
    },
  ],
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: cbreScaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(catalogPlugin.externalRoutes, {
      createComponent: cbrePocHubPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(catalogGraphPlugin.externalRoutes, {
      catalogEntity: catalogPlugin.routes.catalogEntity,
    });
  },
  plugins: [cbreScaffolderPlugin, cbrePocHubPlugin],
});

const windowVerticleSpace = window.innerHeight - 250;
const windowHorizontalSpace = window.innerWidth - 250;

// where we would inject script and style tags with nonce
const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="catalog" />} />
    <Route path="/techdocs" element={<EntityTechdocsContent />} />

    <Route path="/catalog" element={<Home />} />
    <Route
      path="/catalog/:namespace/:kind/:name/edit"
      element={<ComponentEditPage />}
    />
    <Route
      path="/catalog/:namespace/:kind/:name/api-edit"
      element={<ApiEditPage />}
    />
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <DocsContainer />
    </Route>
    <Route path="/docs/external/register" element={<RegisterExternalDocs />} />
    <Route
      path="/docs/external/edit/:docName"
      element={<RegisterExternalDocs />}
    />

    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    />
    <Route path="/software-templates" element={<ScaffolderPage />} />

    {/* Api Related Routes */}
    <Route path="/API" element={<ApiPage />} />
    <Route path="/API/:namespace/:kind/:name" element={<CatalogEntityPage />}>
      {entityPage}
    </Route>
    <Route
      path="/API/:namespace/:kind/:name/api-edit"
      element={<ApiEditPage />}
    />
    <Route path="/API/register-api" element={<RegisterApiPage />} />
    <Route path="/API/register-api/confirm" element={<ConfirmDetailsPage />} />
    <Route path="/API/register-api/success" element={<RegisterSuccess />} />
    <Route
      path="/API/register-api/generate-swagger"
      element={<GenerateSwaggerPage />}
    />
    <Route
      path="/tech-radar"
      element={
        <TechRadarPage
          width={windowHorizontalSpace}
          height={windowVerticleSpace}
        />
      }
    />
    {/* Ap Related Routes ----Ends here*/}

    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    {/* <Route path="/settings" element={<SettingsPage />} /> */}
    <Route path="/admin" element={<Admin />} />

    <Route path="/code-library" element={<Code />} />
    <Route
      path="/code-library/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route
      path="/code-library/:namespace/:kind/:name/edit"
      element={<ComponentEditPage />}
    />
    <Route path="/readme" element={<ReadmePage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/feedback" element={<FeedbackPage />} />
    <Route path="/feedback/register" element={<RegisterFeedback />} />
    <Route path="/feedback/edit/:id" element={<RegisterFeedback />} />

    {/* Code for new API page, there will be a mockApi-test Page, and also have an entity page that has the mockAPI definitions*/}
    <Route path="/api-mock" element={<MockPage />} />
    <Route path="/api-mock/register" element={<RegisterMock />} />
    {/* Routing for mock swagger definition */}
    <Route path="/api-mock/:id" element={<MockSwaggerContainer />} />
    {/* Test case for edit - name functionality  */}
    <Route path="/api-mock/edit/:id" element={<RegisterMock />} />

    <Route path="/promotions" element={<PromotionPage />} />

    <Route path="/prompt-store" element={<PromptStore />} />

    <Route path="/ai-in-sdlc" element={<AiInSdlc />} />
    <Route path="/prompt-enhancer" element={<PromptEnhancerPage />} />

    <Route
      path="/catalog-graph"
      element={
        <CatalogGraphPage
          initialState={{
            selectedKinds: ['component', 'domain', 'system', 'api', 'group'],
            selectedRelations: [
              RELATION_OWNER_OF,
              RELATION_OWNED_BY,
              RELATION_CONSUMES_API,
              RELATION_API_CONSUMED_BY,
              RELATION_PROVIDES_API,
              RELATION_API_PROVIDED_BY,
              RELATION_HAS_PART,
              RELATION_PART_OF,
              RELATION_DEPENDS_ON,
              RELATION_DEPENDENCY_OF,
            ],
          }}
        />
      }
    />
    <Route path="/taxonomy" element={<TaxonomyPage />} />
    <Route
      path="/taxonomy/view/:kind/:namespace/:name"
      element={<TaxonomyView />}
    />
    <Route
      path="/taxonomy/edit/:kind/:namespace/:name"
      element={<TaxonomyEditPage />}
    />
    <Route
      path="/admin/manage-promotions/:title/:id"
      element={<PromotionDetail />}
    />
    <Route
      path="/admin/manage-promotions/update-promotion/:title/:id"
      element={<UpdatePromotions />}
    />
    <Route path="/promotions/:title/:id" element={<PromotionDetail />} />

    <Route path="/help" element={<GlobalHelpContainer />} />

    <Route
      path="/catalog/leader-board/:email"
      element={<LeaderBoardDetailsPage />}
    />
    <Route path="/toolbox" element={<ToolboxContainer />} />
    <Route path="/toolbox/:nodeId" element={<ToolboxDetailsPage />} />

    {/* IE Related Routes */}
    <Route path="/information-exchange" element={<InformationExchange />} />
    {/* IE Related Routes ----Ends here*/}

    <Route path="/openai-search" element={<Chat />} />

    <Route
      path="/utility-tools/details/"
      element={<UtilityToolDetailsPage />}
    />
    <Route path="/utility-tools" element={<UtilityToolsPage />} />

    {/* Visual Regression Routes */}

    <Route path="/visual-regression" element={<VisualRegressionPage />} />
    <Route path="/visual-regression/view-history" element={<TestHistory />} />
    <Route
      path="/visual-regression/details-page/:id"
      element={<DetailsPage />}
    />

    {/* Visual Regression Routes ----Ends here*/}

    {/* Data Insights Routes */}

    <Route path="/data-insights" element={<LayoutPage />} />
    <Route path="/data-insights/:id" element={<DashboardPage />} />

    {/* Data Insights Routes----Ends here*/}

    {/* POC HUB Routes */}

    <Route path="/poc-hub" element={<PocHubRouter />} />

    {/* POC HUB ----Ends here*/}

    {/* LeanIX Routes */}

    <Route path="/capability-mapping" element={<LeanIXPage />} />
    <Route path="/release-notes" element={<ReleaseNotePage />} />
    <Route path="/release-notes/:id" element={<ReleaseNotePage />} />
    <Route path="/settings" element={<SettingsPage />} />

    {/* LeanIX Routes ----Ends here*/}

    {/* Provisioning Center Routes */}
    <Route path="/provisioning-center" element={<ToolsProvisioning />} />

    {/* Tools Provisioning Routes ----Ends here */}
  </FlatRoutes>
);

/* Regex for the Pages for which the Ai Popup is required */
const aiPopupPathsRegex: RegExp[] = [/prompt-store/, /.*add-prompt/];

export default app.createRoot(
  <>
    <AlertDisplay />
    <AppNotifications />
    <OAuthRequestDialog />
    <UserPopup />
    <AutoLogout
      idleTimeoutMinutes={30}
      logoutIfDisconnected={false}
      useWorkerTimers={false}
    />
    <AppRouter>
      <FrontendConfigProvider>
        <PromptProvider>
          <PromotionProvider>
            <ChatBotProvider>
              <AIPopupProvider paths={aiPopupPathsRegex}>
                <VideoBytesProvider>
                  <GuidedTourProvider>
                    <Root>{routes}</Root>
                    <HelpContainer />
                    <Chatbot />
                    <DevxGuidedTour />
                    <AIPopup />
                  </GuidedTourProvider>
                </VideoBytesProvider>
              </AIPopupProvider>
            </ChatBotProvider>
          </PromotionProvider>
        </PromptProvider>
      </FrontendConfigProvider>
    </AppRouter>
  </>,
);
