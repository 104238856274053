import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
  Tooltip,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
// @ts-ignore TODO: Keep track if backstage removes it
import { useApi } from '@backstage/core-plugin-api';
import { useNavigate } from 'react-router-dom';
import {
  ToolboxApi,
  toolboxApiRef,
  IToolboxItem,
} from '../../../../apis/toolboxApi';
import TextTruncate from 'react-text-truncate';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';
import LeftArrowIcon from '../icons/leftArrowIcon';
import RightArrowIcon from '../icons/rightArrowIcon';
import EmblaCarousel from '../../Carousal/EmblaCarousel';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tool: {
      cursor: 'pointer',
      userSelect: 'none',
      zIndex: 100,
      width: '90px',
      flex: 1,
    },
    imageData: {
      textAlign: 'center',
      height: '90px',
    },
    toolImg: {
      height: '90px',
      width: '90px',
    },
    toolTitle: {
      textAlign: 'center',
      height: '50px',
      marginTop: '5px',
    },
    titleFont: {
      fontSize: '12px',
    },
    toolbox: {
      marginTop: '4px',
      // marginBottom: '-4px',
      color: '#fff',
      boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.14)',
      background: 'linear-gradient(145deg, #01644d , #042660)',
      borderRadius: '8px',
    },
    toolServices: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    toolboxArrowIcons: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '2px',
    },
    arrowIcons: {
      marginRight: '5px',
    },
    arrowIconClrs: {
      cursor: 'pointer',
      color: '#9aa9b3',
    },
    seeMoreBtn: {
      color: '#17e88f',
      cursor: 'pointer',
    },
    toolBoxHeader: {
      display: 'flex',
    },
    infoIconMargin: {
      margin: '5px',
    },
    toolboxHeight: {
      height: '140px',
    },
    emblaCarousel: {
      display: 'flex',
      // height: '100%',
      position: 'relative',
    },
    tooltipLabel: {
      fontSize: '14px',
    },
  }),
);

const ToolViewForWidget = ({ tool }: { tool: IToolboxItem }) => {
  const classes = useStyles();
  const toolboxApi: ToolboxApi = useApi(toolboxApiRef);
  const [imageData, setImageData] = useState<string | undefined>();
  const [imageMeta, setImageMeta] = useState<string | undefined>(
    tool.imageMetaForBase64,
  );

  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const navigate = useNavigate();

  const metaMap = {
    png: 'data:image/png;base64,',
    svg: 'data:image/svg+xml;base64,',
  };

  const getDefaultMetadata = () => {
    let imageKey = tool?.imagekey;
    if (imageKey !== undefined) {
      const ext = String(imageKey).substring(
        String(imageKey)?.lastIndexOf('.') + 1,
      );
      // @ts-ignore
      return metaMap[ext];
    }
    return 'data:image/svg+xml;base64,';
  };

  const onClickTool = () => {
    navigate(`/toolbox?q=${tool?.title}`);
  };

  useEffect(() => {
    if (
      tool !== undefined &&
      tool.imagekey !== undefined &&
      String(tool.imagekey).trim().length > 0
    ) {
      toolboxApi
        .getImageData(tool?.imagekey)
        .then((data: any) => {
          setImageData(data);
        })
        .catch(() => {
          notificationApi.sendNotification({
            message: `Failed to load image for tool ${tool?.title}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });

      if (tool.imageMetaForBase64 === undefined) {
        setImageMeta(getDefaultMetadata());
      } else {
        setImageMeta(String(tool.imageMetaForBase64).replace(',', '') + ',');
      }
    }
  }, []);

  return (
    <Box
      sx={{
        mr: 3,
      }}
      className={classes.tool}
      title={tool.title ?? ''}
      onClick={onClickTool}
      component="div"
    >
      <Box className={classes.imageData}>
        {imageData && (
          <img
            src={imageData ? `${imageMeta}${imageData}` : ''}
            className={classes.toolImg}
            draggable={false}
          />
        )}
      </Box>
      <Box className={classes.toolTitle} draggable={false}>
        <Typography component="div" className={classes.titleFont}>
          <TextTruncate text={tool?.title ?? ''} line={2} />
        </Typography>
      </Box>
    </Box>
  );
};

const ToolboxMostUsedLinksWidget = ({ id }: { id: any }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [toolsToRender, setToolsToRender] = useState<
    IToolboxItem[] | undefined
  >([]);
  const toolboxApi: ToolboxApi = useApi(toolboxApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const toolBoxToolTipTitle =
    "Find a collection of the various available and commonly used tools at CBRE's D&T. You find an overview of each tool with information about owners, support and request forms.";
  const [emblaRef, emblaApi] = useEmblaCarousel({
    // containScroll: 'trimSnaps',
    slidesToScroll: 1,
    align: 'start',
    dragFree: true,
    loop: true,
    draggable: false,
  });

  const navigate = useNavigate();

  const onClickSeeMore = () => {
    navigate('/toolbox');
  };

  const getCBREMostUsedLinks = () => {
    setLoading(true);
    toolboxApi
      .getTopLinks()
      .then((value: IToolboxItem[] | undefined) => {
        if (value !== undefined && value.length > 5) {
          setToolsToRender(value || []);
        } else {
          setToolsToRender(value || []);
        }
        setLoading(false);
      })
      .catch((error: any) => {
        setToolsToRender([]);
        setLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  useEffect(() => {
    getCBREMostUsedLinks();
  }, []);

  const onClickLeftArrow = () => {
    if (emblaApi && emblaApi?.canScrollPrev()) {
      emblaApi?.scrollPrev();
    }
  };

  const onClickRightArrow = () => {
    if (emblaApi && emblaApi?.canScrollNext()) {
      emblaApi?.scrollNext();
    }
  };

  return (
    <Box
      sx={{
        // @ts-ignore
        pt: 1,
        pl: 2,
        pr: 2,
      }}
      className={classes.toolbox}
      id={id}
    >
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item className={classes.toolBoxHeader} xs={8}>
              <Typography variant="h6">Most Viewed Tools </Typography>
              <Tooltip
                title={toolBoxToolTipTitle}
                placement="top-start"
                classes={{ tooltip: classes.tooltipLabel }}
              >
                <InfoOutlinedIcon
                  className={classes.infoIconMargin}
                  fontSize="small"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <Box
                sx={{
                  mb: 1,
                  mt: 1,
                }}
                className={classes.toolServices}
              >
                {!isMobile ? (
                  <Box className={classes.toolboxArrowIcons}>
                    <div
                      className={classes.arrowIcons}
                      onClick={onClickLeftArrow}
                    >
                      <LeftArrowIcon className={classes.arrowIconClrs} />
                    </div>
                    <div
                      className={classes.arrowIcons}
                      onClick={onClickRightArrow}
                    >
                      <RightArrowIcon className={classes.arrowIconClrs} />
                    </div>
                  </Box>
                ) : null}
                <Typography
                  className={classes.seeMoreBtn}
                  onClick={onClickSeeMore}
                >
                  See more
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Box className={classes.toolboxHeight}>
                {loading && <Typography>Loading ...</Typography>}
                {(loading === false && toolsToRender === undefined) ||
                  (toolsToRender?.length === 0 && (
                    <Typography>No tools available</Typography>
                  ))}
                <Box className={classes.emblaCarousel}>
                  {loading === false &&
                    toolsToRender !== undefined &&
                    Array.isArray(toolsToRender) &&
                    toolsToRender.length > 0 && (
                      <EmblaCarousel
                        ref={emblaRef}
                        slides={toolsToRender.map(item => (
                          <ToolViewForWidget key={item.nid} tool={item} />
                        ))}
                      />
                    )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ToolboxMostUsedLinksWidget;
