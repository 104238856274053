import { FieldValidation } from '@rjsf/core';
import { ApiHolder } from '@backstage/core-plugin-api';
/* import { registerApiRef, RegisterApis } from '../../../../apis/registerApis'; */

const apmNumberRegex = /APM?\d{7}/;

export const apmFieldValidation = async (
  value: string,
  validation: FieldValidation,
  context: { apiHolder: ApiHolder },
) => {
  if (value === undefined || String(value).trim().length === 0) {
    // validation.addError('is a required property'); commenting as already covered by the template
    return;
  }

  /*   try {
    const registerApis: RegisterApis | undefined =
      context?.apiHolder?.get(registerApiRef);

    if (!apmNumberRegex.test(value)) {
      validation.addError('APM Number not valid');
    } else {
      await registerApis?.getApmNumberDetails(value);
    }
  } catch (e) {
    validation.addError('APM Number not valid');
  } */
};
