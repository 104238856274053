import React from 'react';
import {
    Grid,
} from '@material-ui/core';
import { HelpComponent } from '../utils/helpComponent';
import HELP_URL from '../utils/helpLinkConstant';
import BackButton from '../utils/backButton';
import { PageWithHeader, Content } from '@backstage/core-components';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';
import OpenAI from './openai';

const Chat = () => {
   

    return (
        <PageWithHeader
            title="Chat"
            themeId="home"
            subtitle={
                <DevxBreadCrumb
                    routes={[
                        {
                            type: 'link',
                            link: '/',
                            text: 'Home',
                        },
                        {
                            type: 'text',
                            link: '',
                            text: 'Chat',
                        },
                    ]}
                />
            }
        >
            <Content>
                <Grid container direction="row" spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <BackButton />
                        <HelpComponent helpUrl={HELP_URL.RELEASE_NOTES} />
                    </Grid>
                </Grid>
                <OpenAI />
            </Content>
        </PageWithHeader>
    );
};
export default Chat;
