/* eslint-disable react-hooks/exhaustive-deps */
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useStyles } from '../styles';

interface IinputScope {
  domainId: string | undefined;
  subDomainId: string | undefined;
  personaId: string | undefined;
}

const Scopes = ({
  inputScope,
  onChange,
  domains,
  variant = 'standard',
  disabled = false,
  required = false,
  isTransParent = true,
  domainVisible = true,
  exceptionPersonas = [],
}: {
  inputScope: IinputScope;
  onChange: Function;
  domains: any;
  variant?: 'filled' | 'outlined' | 'standard' | undefined;
  disabled?: boolean;
  required?: boolean;
  isTransParent?: boolean;
  domainVisible?: boolean;
  exceptionPersonas?: string[];
}) => {
  const classes = useStyles();

  const [subDomains, setSubDomains] = useState([]);
  const [personas, setPersonas] = useState([]);

  const { domainId, subDomainId, personaId } = inputScope;

  useEffect(() => {
    const domain = domains?.find((d: any) => d.id === domainId);
    const subDomain = domain?.subproducts?.find(
      (sd: any) => sd.id === subDomainId,
    );

    setSubDomains(domain?.subproducts || []);
    setPersonas(subDomain?.personas || []);
  }, [domainId, subDomainId, personaId]);

  const handleDomainChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange({
      domainId: event.target.value,
      subDomainId: undefined,
      personaId: undefined,
    });
  };

  const handleSubDomainChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    onChange({
      domainId: domainId,
      subDomainId: event.target.value,
      personaId: undefined,
    });
  };

  const handlePersonaChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    onChange({
      domainId: domainId,
      subDomainId: subDomainId,
      personaId: event.target.value,
    });
  };

  return (
    <div
      className={`${classes.flexSpacebetween} ${classes.scopesGap} ${classes.width100Percent}`}
    >
      {domainVisible ? (
        <FormControl
          className={classes.scopesControl}
          disabled={disabled}
          required={required}
          variant={variant}
        >
          <InputLabel id="domain">Domain</InputLabel>
          <Select
            label="Domain"
            className={isTransParent ? '' : `${classes.backGroundColor}`}
            disableUnderline
            labelId="domain"
            id="domain"
            value={domainId}
            onChange={handleDomainChange}
          >
            {domains?.map((domain: any) => (
              <MenuItem value={domain.id} key={domain.id}>
                {domain.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null}

      <FormControl
        className={classes.scopesControl}
        disabled={disabled}
        required={required}
        variant={variant}
      >
        <InputLabel id="subdomain">Sub Domain</InputLabel>
        <Select
          className={isTransParent ? '' : `${classes.backGroundColor}`}
          disableUnderline
          labelId="subdomain"
          id="subdomain"
          value={subDomainId}
          onChange={handleSubDomainChange}
          label="Sub Domain"
        >
          {subDomains?.map((subDomain: any) => (
            <MenuItem value={subDomain.id} key={subDomain.id}>
              {subDomain.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl
        className={classes.scopesControl}
        disabled={disabled}
        required={required}
        variant={variant}
      >
        <InputLabel id="persona">Persona</InputLabel>
        <Select
          className={isTransParent ? '' : `${classes.backGroundColor}`}
          disableUnderline
          labelId="persona"
          id="persona"
          value={personaId}
          onChange={handlePersonaChange}
          label="Persona"
        >
          {personas?.map((persona: any) => (
            <MenuItem value={persona.id} key={persona.id}>
              {persona.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default Scopes;
