import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MarkdownPreviewIcon = (props: any) => (
  <SvgIcon viewBox={'48 48'} style={{ transform: 'scale(3)' }} {...props}>
    <path
      d="M2.25 15.75V7.75H4.25L7.25 10.75L10.25 7.75H12.25V15.75H10.25V10.58L7.25 13.58L4.25 10.58V15.75H2.25ZM16.25 7.75H19.25V11.75H21.75L17.75 16.25L13.75 11.75H16.25V7.75Z"
      fill="#42A5F5"
    />
  </SvgIcon>
);

export default MarkdownPreviewIcon;
