import React, { useState, useEffect } from 'react';
import { useStarredEntity } from '@backstage/plugin-catalog-react';
import {
  Box,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { Link as RouterLink } from 'react-router-dom';
import { useStyles } from './common/styles';

export interface IToolCardProps {
  tool: any;
}

export const DataInsightCard = ({ tool }: IToolCardProps) => {
  const classes = useStyles();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [image, setImage] = useState<any>('');

  /* adminApi.getImagebyItemId(id) an dediated API to fetch only images by Item Id */
  const { toggleStarredEntity, isStarredEntity } = useStarredEntity(tool?.id);

  const onClickStarIcon = (event: any) => {
    event?.stopPropagation();
    event?.preventDefault();
    toggleStarredEntity();
  };

  const metaMap = {
    png: 'data:image/png;base64,',
    svg: 'data:image/svg+xml;base64,',
    jpg: 'data:image/jpeg;base64,',
    jpeg: 'data:image/jpeg;base64,',
  };

  const getDefaultMetadata = () => {
    const imageurl = tool?.imageurl;
    if (imageurl !== undefined) {
      const ext = String(imageurl).substring(
        String(imageurl)?.lastIndexOf('.') + 1,
      );
      // @ts-ignore
      return metaMap[ext];
    }
    return 'data:image/svg+xml;base64,';
  };

  useEffect(() => {
    if (tool.id) {
      setImage(`${getDefaultMetadata()}${tool.imageBase64Format}`);
    }
  }, [tool]);

  useEffect(() => {}, [image]);

  return (
    <Box
      sx={{
        p: 2,
        mr: 2,
        mb: 2,
        flexGrow: isMobile ? 1 : 0,
        // @ts-expect-error
      }}
      className={classes.utilityCard}
    >
      <div className={classes.placeholderImage}>
        {image ? (
          <img src={image} alt="" className={classes.image} />
        ) : (
          <img
            src="/images/placeholderimage.png"
            alt=""
            className={classes.image}
          />
        )}
      </div>
      <Box sx={{ flex: 1, mr: 2 }}>
        <Typography className={classes.dataInsightTool}>
          <Link component={RouterLink} to={`/data-insights/${tool?.id}`}>
            {tool?.title}
          </Link>
        </Typography>
      </Box>
      <Box onClickCapture={onClickStarIcon}>
        <Rating max={1} value={isStarredEntity ? 1 : 0} />
      </Box>
    </Box>
  );
};
