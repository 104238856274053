import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const NotifyActiveIcon = ({ color }: { color?: string }) => (
  <SvgIcon viewBox="0 0 24 24">
    <g id="notifications_active">
      <path
        id="Vector"
        d="M7.83 3.83L6.4 2.4C4 4.23 2.42 7.05 2.28 10.25H4.28C4.43 7.6 5.79 5.28 7.83 3.83ZM20.22 10.25H22.22C22.07 7.05 20.49 4.23 18.1 2.4L16.68 3.83C18.7 5.28 20.07 7.6 20.22 10.25ZM18.25 10.75C18.25 7.68 16.61 5.11 13.75 4.43V3.75C13.75 2.92 13.08 2.25 12.25 2.25C11.42 2.25 10.75 2.92 10.75 3.75V4.43C7.88 5.11 6.25 7.67 6.25 10.75V15.75L4.25 17.75V18.75H20.25V17.75L18.25 15.75V10.75ZM12.25 21.75C12.39 21.75 12.52 21.74 12.65 21.71C13.3 21.57 13.83 21.13 14.09 20.53C14.19 20.29 14.24 20.03 14.24 19.75H10.24C10.25 20.85 11.14 21.75 12.25 21.75Z"
        fill={color}
      />
    </g>
  </SvgIcon>
);

export default NotifyActiveIcon;
