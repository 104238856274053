import React from 'react';

const QuestionVoteGiven = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 2.5H5C3.625 2.5 2.5 3.625 2.5 5V27.5L7.5 22.5H25C26.375 22.5 27.5 21.375 27.5 20V5C27.5 3.625 26.375 2.5 25 2.5ZM25 20H6.4625L5 21.4625V5H25V20Z"
        fill="#17E88F"
      />
      <path
        d="M8.86994 17.44H10.4899V19H8.86994V17.44ZM9.67994 11.2C10.5709 11.2 11.2999 11.902 11.2999 12.76C11.2999 14.32 8.86994 14.125 8.86994 16.66H10.4899C10.4899 14.905 12.9199 14.71 12.9199 12.76C12.9199 11.0362 11.47 9.64 9.67994 9.64C7.88984 9.64 6.43994 11.0362 6.43994 12.76H8.05994C8.05994 11.902 8.78894 11.2 9.67994 11.2Z"
        fill="#17E88F"
      />
      <g clipPath="url(#clip0_72450_60616)">
        <path
          d="M14.5193 14.0684H16.1681V9.122H14.5193V14.0684ZM23.5877 9.53419C23.5877 9.08078 23.2167 8.7098 22.7633 8.7098H20.1623L20.5539 6.82605L20.5662 6.69414C20.5662 6.52514 20.4962 6.3685 20.3849 6.25721L19.948 5.8244L17.2357 8.54079C17.0832 8.68919 16.9925 8.89529 16.9925 9.122V13.244C16.9925 13.6974 17.3635 14.0684 17.8169 14.0684H21.5267C21.8688 14.0684 22.1615 13.8623 22.2851 13.5655L23.53 10.6595C23.5671 10.5647 23.5877 10.4658 23.5877 10.3586V9.53419Z"
          fill="#17E88F"
        />
      </g>
      <defs>
        <clipPath id="clip0_72450_60616">
          <rect
            width="9.89278"
            height="9.89278"
            fill="white"
            transform="translate(14.1072 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default QuestionVoteGiven;
