import React, { useContext, useRef } from 'react';
import {
  Paper,
  Card,
  Typography,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  IconButton,
  Grid,
  Tooltip
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import EditRounded from '@material-ui/icons/EditRounded';
import { AuthContext } from '../../../providers/AuthProvider';
import { datadogRum } from '@datadog/browser-rum';
import Constants from '../Constants';
import { useApi } from '@backstage/core-plugin-api';
import { userProfileApiRef } from '../../../apis/userProfileApi';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightAlignItems: {
      marginLeft: 'auto',
      display: 'flex',
      gap: '8px',
    },
    flexContainer: {
      width: '100%',
      ['@media (max-width:480px)']: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '1200px',
    },
    headerSection: {
      marginTop: '12px',
    },
    versionSection: {
      width: '100%',
      float: 'right',
      marginRight: '12px',
      marginTop: '12px',
      justifyContent: 'space-between',
    },
    leftAlignItems: {
      float: 'left',
      marginLeft: '5px',
    },
    cardHeader: {
      padding: '20px 0px 10px 10px',
    },
    cardTypeTitle: {
      overflow: 'hidden',
      lineHeight: 1.5,
      wordBreak: 'break-word',
      fontFamily: 'inherit',
      fontSize: '18px',
      fontWeight: 400,
      textDecoration: 'none',
      marginTop: '-13px',
    },
    cardContent: {
      padding: '0px 10px 0px 10px',
      height: '100%',
      fontfamily: 'Calibre-R-Regular',
      fontSize: '14px',
      lineHeight: '1.334',
      letterSpacing: '0px',
      textAlign: 'left',
      color: '#26262699',
      overflow: 'hidden',
      wordBreak: 'break-word',
    },
    cardTypeText: {
      overflow: 'hidden',
      lineHeight: 1.5,
      wordBreak: 'break-word',
      fontFamily: 'inherit',
      fontSize: '12px',
      fontWeight: 600,
      textDecoration: 'none',
    },
    cardTypeFeature: {
      borderColor: (props: any) => props.color,
      borderLeft: '14px solid',
    },
    cardTypeRequest: {
      borderColor: '#435254',
      borderLeft: '14px solid',
    },
    cardTypeBug: {
      borderColor: '#D2785A',
      borderLeft: '14px solid',
    },
    cardTypeComments: {
      borderColor: '#CAD1D3',
      borderLeft: '14px solid',
    },
    cardTypeOther: {
      borderColor: '#80BBAD',
      borderLeft: '14px solid',
    },
    feedbackText: {
      padding: '12px 5px',
    },
    actionRightContainer: {
      display: 'inline-block',
      marginRight: '5%',
      marginBottom: '1%',
    },
    actionTimeContainer: {
      display: 'inline-block',
      marginRight: '1%',
      marginBottom: '1%',
      marginTop: '-6px',
    },
    actionClickableContainer: {
      cursor: 'pointer',
      display: 'inline-block',
      marginRight: '1%',
      marginBottom: '1%',
      marginTop: '3px',
    },
    actionContainer: {
      display: 'inline-block',
      marginRight: '1%',
      marginBottom: '1%',
    },
    likeButtonGroupContainer: {
      width: '30px',
    },
    likeButtonGroup: {
      border: 'none',
    },
    likeButtonSelected: {
      color: '#012A2D',
      border: 'none',
      padding: '5px',
      '&:disabled': {
        border: 'none',
      },
    },
    editStatusText: {
      overflow: 'hidden',
      lineHeight: '1.334',
      wordBreak: 'break-word',
      fontFamily: 'inherit',
      fontSize: '12px',
      fontWeight: 600,
      whiteSpace: 'nowrap',
      textDecoration: 'none',
      color: '#6B778C',
    },
    editButton: {
      padding: '0px',
      marginTop: '-2px',
      minWidth: '40px',
      color: theme.palette.primary.main,
    },
    feedbackTextDiv: {
      paddingTop: '3px',
      color: theme.palette.text.tertiary,
    },
    feedbackCard: {
      ['@media (max-width:920px) and (min-width: 280px)']: {
        padding: '13px 22px 0px 9px !important',
      },
    },
    editMock: {
      marginRight: '12px',
    },
  }),
);

const MockCard = (props: any) => {
  const navigate = useNavigate();
  const type = props.mock.requesttype;
  const classes = useStyles({
    color:
      Constants.requestTypes[type.toLocaleLowerCase()].color ||
      Constants.requestTypes.patch.color,
  });
  const { profEmail, profileName } = useContext(AuthContext);
  const userId = profEmail?.toLowerCase();
  let isEdit = false;
  if (userId == props.mock.username) {
    isEdit = true;
  }

  const navigateToSwagger = () => {
    datadogRum.addAction('ClickTestMock', {
      id: profEmail,
      name: profileName,
      email: profEmail,
      apiname: props.mock.name,
      endpoint: props.mock.endpoint,
      requesttype: props.mock.requesttype,
    });
    navigate(`/api-mock/${props.mock.id}`);
  };

  const handleEditClick = () => {
    navigate(`edit/${props.mock.id}`);
  };

  const usernameRef = useRef(null);
  const userProfileApi = useApi(userProfileApiRef);

  const onMouseEnter = () => {
    let email = props.mock.username;

    userProfileApi.setUserProfileState({
      email,
      anchorRef: usernameRef?.current,
    });
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.feedbackCard}
      >
        <Card square elevation={5}>
          <Paper elevation={5} className={classes.cardTypeFeature} square>
            <div>
              <CardHeader
                title={props.mock.name}
                classes={{
                  title: classes.cardTypeTitle,
                }}
                className={classes.cardHeader}
                action={
                  <div className={classes.versionSection}>
                    {props.isAdmin && (
                      <>
                        <Tooltip title="Edit">
                          <IconButton
                            onClick={handleEditClick}
                            size="small"
                            className={classes.editMock}
                          >
                            <EditRounded color="primary" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}

                    {isEdit && !props.isAdmin && (
                      <>
                        <Tooltip title="Edit">
                          <IconButton
                            onClick={handleEditClick}
                            size="small"
                            className={classes.editMock}
                          >
                            <EditRounded color="primary" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    {`version `}
                    {props.mock.version}
                  </div>
                }
              />
            </div>

            <CardContent className={classes.cardContent}>
              <div className={classes.feedbackTextDiv}>
                <Typography>{props.mock.description}</Typography>
              </div>
            </CardContent>
            <CardActions disableSpacing>
              <div className={classes.leftAlignItems}>
                <Typography className={classes.cardTypeText}>
                  <span onMouseEnter={onMouseEnter} ref={usernameRef}>
                    {props.mock.username}
                  </span>
                </Typography>
              </div>

              <div className={classes.rightAlignItems}>
                <Button color="primary" onClick={navigateToSwagger}>
                  Test mock
                </Button>
              </div>
            </CardActions>
          </Paper>
        </Card>
      </Grid>
    </Grid>
  );
};

export default MockCard;
