import type { Transformer } from './transformer';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import ReactDOM from 'react-dom';

export const addSidebarToggle = (): Transformer => {
  return dom => {
    // attempting to use selectors that are more likely to be static as MkDocs updates over time
    const mkdocsToggleSidebar = dom.querySelector(
      '.md-header label[for="__drawer"]',
    ) as HTMLLabelElement;
    const article = dom.querySelector('article') as HTMLElement;

    // Fail gracefully
    if (!mkdocsToggleSidebar || !article) {
      return dom;
    }

    const toggleSidebar = mkdocsToggleSidebar.cloneNode() as HTMLLabelElement;
    ReactDOM.render(React.createElement(MenuIcon), toggleSidebar);
    toggleSidebar.id = 'toggle-sidebar';
    toggleSidebar.title = 'Toggle Sidebar';
    toggleSidebar.classList.add('md-content__button');
    toggleSidebar.style.setProperty('padding', '0 0 0 5px');
    toggleSidebar.style.setProperty('margin', '0.4rem 0 0.4rem 0.4rem');
    article?.prepend(toggleSidebar);
    return dom;
  };
};
