import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';

// @ts-ignore
const useTreeItemStyles = makeStyles(theme => ({
  root: {
    color: 'var(--tree-view-color)',
    borderBottom: '1px solid #5c7585',
    backgroundColor: `var(--tree-view-bg-color)`,
    position: 'relative',
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-selected-color)`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label':
      {
        backgroundColor: 'transparent',
      },
  },
  content: {
    color: 'var(--tree-view-color)',
    paddingRight: theme.spacing(1),
    padding: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {
    color: 'var(--tree-view-color)',
  },
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
    backgroundColor: 'transparent !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
    textOverflow: 'ellipsis',
    flexWrap: 'wrap',
    breakWord: 'break-all',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  labelInfo: {
    border: '1px solid #fff',
    padding: '0px 15px',
    borderRadius: '15px',
    position: 'absolute',
    right: '20px',
  },
  iconContainer: {
    color: 'var(--tree-view-color)',
    position: 'absolute',
    right: '5px',
    zIndex: 99,
  },
  iconContainerHidden: {
    display: 'none',
  },
}));

export function StyledTreeItem(props: any) {
  const classes = useTreeItemStyles();
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    hasParent,
    hasGrandchildren,
    ...other
  } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          {LabelIcon && (
            <LabelIcon color="inherit" className={classes.labelIcon} />
          )}

          <Box className={classes.labelText} textOverflow="ellipsis">
            <Typography
              variant="body2"
              component={'div'}
              style={{
                width: '70%',
                wordBreak: 'break-all',
              }}
            >
              {labelText}
            </Typography>
          </Box>

          {labelInfo && (
            <Typography
              variant="caption"
              color="inherit"
              className={classes.labelInfo}
            >
              {labelInfo}
            </Typography>
          )}
        </div>
      }
      style={{
        '--tree-view-color': '#fff',
        '--tree-view-bg-color': hasParent ? '#607477' : '#435254',
        '--tree-view-bg-selected-color': '#232c2d',
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
        iconContainer: hasGrandchildren
          ? classes.iconContainer
          : classes.iconContainerHidden,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};
