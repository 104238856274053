import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { PageWithHeader, Content } from '@backstage/core-components';
import REGISTER_API_CONSTANTS from './Constants';
import { useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useStyles } from './styles';
import { PromotionContext } from '../../../providers/PromotionDataProvider';
import { DevxBreadCrumb } from '../../common/BreadcrumbsNav/DevxBreadCrumb';

const RegisterSuccess = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const myContext = useContext(PromotionContext);
  const apiTitle =
    myContext?.registerApiData?.apiTitle.replace(/\s/g, '_') || '';

  /* navigate to register api page */
  const navigateToRegisterAPIPage = () => {
    // Clear the register
    myContext.setRegisterFormState('clear');
    navigate('/API/register-api', { replace: true });
    /* Complete Refresh of Url */
    // location.href = '/API/register-api';
  };

  /* Set form data if the form data is there in context else go back to input form page */
  useEffect(() => {
    if (myContext.registerApiData) {
      myContext.setRegisterFormState('clear');
    } else {
      navigateToRegisterAPIPage();
    }
  }, [myContext.registerApiData]);

  return (
    <PageWithHeader
      title="Register Non-WSO2 API "
      themeId="home"
      subtitle={
        <DevxBreadCrumb
          routes={[
            { type: 'link', link: '/', text: 'Home' },
            { type: 'link', link: '/API', text: 'APIs' },
            { type: 'link', link: '/API/register-api', text: 'Register API' },
            { type: 'text', link: '', text: 'Success' },
          ]}
        />
      }
    >
      <Content>
        {/* Register Success Component */}
        <div className="codePage">
          <Grid
            container
            direction="row"
            spacing={3}
            className={classes.successBox}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CheckCircleOutlineIcon className={classes.tickIcon} />

              <div className={classes.successMsg}>
                {REGISTER_API_CONSTANTS.registerSuccess.successMsg}
              </div>

              <p>Please find the page link below</p>

              <div>
                <a
                  className={classes.successLink}
                  href={`catalog/default/API/${apiTitle}`}
                >
                  {apiTitle}
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button
                variant="outlined"
                className={classes.registerApiBtn}
                onClick={navigateToRegisterAPIPage}
              >
                Register Another API
              </Button>
            </Grid>
          </Grid>
        </div>
      </Content>
    </PageWithHeader>
  );
};
export default RegisterSuccess;
