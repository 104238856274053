import React from 'react';
import {
  TableColumn,
  Table,
  WarningPanel,
  CodeSnippet,
  TableProps,
} from '@backstage/core-components';

import {
  humanizeEntityRef,
  getEntityRelations,
  useEntityList,
  useStarredEntities,
} from '@backstage/plugin-catalog-react';

import * as columnFactories from '../../utils/devXTableColumns';
import {
  RELATION_OWNED_BY,
  RELATION_PART_OF,
  Entity,
} from '@backstage/catalog-model';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import { STARRED, BOOKMARK } from '../../utils/constant';
import { useNavigate } from 'react-router-dom';

const columns: TableColumn<columnFactories.EntityRow>[] = [
  columnFactories.createCapabilityNameColumn(),
  columnFactories.createCapabilityDescriptionColumn(),
  columnFactories.createCapabilityOwnerColumn(),
  // columnFactories.createCapabilityParentColumn(),
];

export interface TaxonomyTableProps {
  onDeleteCapability: (entity: Entity) => void;
  isAdmin: boolean | null | Boolean | undefined;
}

const TaxonomyTable = ({ onDeleteCapability, isAdmin }: TaxonomyTableProps) => {
  const { isStarredEntity, toggleStarredEntity } = useStarredEntities();
  const { loading, error, entities, filters } = useEntityList();

  const navigate = useNavigate();

  const defaultActions: TableProps<columnFactories.EntityRow>['actions'] = [];
  if (isAdmin) {
    defaultActions.push(({ entity }) => {
      const navigateToEditPage = () => {
        navigate(
          `/taxonomy/edit/${entity?.kind}/${entity?.metadata?.namespace}/${entity?.metadata?.name}`,
        );
      };

      return {
        icon: () => <EditIcon />,
        tooltip: 'Edit Capability',
        onClick: navigateToEditPage,
      };
    });
    defaultActions.push(({ entity }) => {
      const handleOnClick = () => {
        if (onDeleteCapability) {
          onDeleteCapability(entity);
        }
      };

      return {
        icon: () => <DeleteIcon />,
        tooltip: 'Delete Capability',
        onClick: handleOnClick,
      };
    });
  }
  defaultActions.push(({ entity }) => {
    const isStarred = isStarredEntity(entity);
    return {
      cellStyle: { paddingLeft: '1em' },
      icon: () =>
        isStarred ? <BookmarkIcon /> : <BookmarkBorderOutlinedIcon />,
      tooltip: 'Bookmark',
      onClick: () => toggleStarredEntity(entity),
    };
  });

  const filteredEntities = entities.filter(
    entity => entity?.spec?.type === 'capability',
  );

  const rows = filteredEntities.map(entity => {
    const partOfSystemRelations = getEntityRelations(entity, RELATION_PART_OF, {
      kind: 'system',
    });
    const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);

    return {
      entity,
      resolved: {
        name: humanizeEntityRef(entity, {
          defaultKind: 'component',
        }),
        ownedByRelationsTitle: ownedByRelations
          .map(r => humanizeEntityRef(r, { defaultKind: 'group' }))
          .join(', '),
        ownedByRelations,
        partOfSystemRelationTitle: partOfSystemRelations
          .map(r =>
            humanizeEntityRef(r, {
              defaultKind: 'system',
            }),
          )
          .join(', '),
        partOfSystemRelations,
      },
    };
  });

  if (error) {
    return (
      <div>
        <WarningPanel severity="error" title="Could not fetch capabilities.">
          <CodeSnippet language="text" text={error.toString()} />
        </WarningPanel>
      </div>
    );
  }
  const titlePreamble = filters.user?.value ?? 'all';

  const getParentChildData = (row: any, rows: any) => {
    const parentRow = rows.find(r => {
      if (!r.entity?.relations) {
        return false;
      }
      let relations = r?.entity?.relations;
      if (relations && Array.isArray(relations) && relations?.length > 0) {
        let parentRelations = relations?.filter(
          rel => rel?.type === 'parentOf',
        );
        if (
          parentRelations &&
          Array.isArray(parentRelations) &&
          parentRelations?.length > 0
        ) {
          let filteredRows = parentRelations.filter(p => {
            if (
              p?.target?.kind?.toLowerCase() ===
                row?.entity?.kind?.toLowerCase() &&
              p?.target?.namespace?.toLowerCase() ===
                row?.entity?.metadata?.namespace?.toLowerCase() &&
              p?.target?.name?.toLowerCase() ===
                row?.entity?.metadata?.name?.toLowerCase()
            ) {
              return true;
            }
            return false;
          });
          return filteredRows && filteredRows?.length > 0;
        }
      }
      return false;
    });
    return parentRow;
  };

  return (
    <div className="mystuff-table">
      <Table
        isLoading={loading}
        columns={columns}
        options={{
          paging: true,
          pageSize: 20,
          search: true,
          actionsColumnIndex: -1,
          loadingType: 'linear',
          showEmptyDataSourceMessage: !loading,
          padding: 'dense',
          pageSizeOptions: [20],
        }}
        parentChildData={getParentChildData}
        data={rows || []}
        actions={defaultActions}
        title={`${
          titlePreamble.toLowerCase() === STARRED ? BOOKMARK : titlePreamble
        } (${rows.length})`}
      />
    </div>
  );
};

export default TaxonomyTable;
