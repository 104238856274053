import React, { useState } from 'react';
// @ts-ignore
import { useApi } from '@backstage/core-plugin-api';
import * as _ from 'lodash';
import Suggest from './Suggest';
import { capabilityTaxonomyApiRef } from '../../../../apis/capabilityTaxonomyApi';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';

export interface EntitySuggestProps {
  label: string;
  value: any;
  onChange: (params: any) => void;
  id: string;
}

const EntitySuggest = ({ label, value, onChange, id }: EntitySuggestProps) => {
  const [options, setOptions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const capabilityTaxonomyApi = useApi(capabilityTaxonomyApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const getOptionsFromApi = (value: any) => {
    setLoading(true);
    capabilityTaxonomyApi
      .getEntitySuggestions()
      .then((data: any) => {
        setLoading(false);
        const finalOptions = data
          ?.filter(
            (e: any) =>
              e?.metadata?.name?.toLowerCase()?.indexOf(value?.toLowerCase()) >=
              0,
          )
          ?.map((e: any) => {
            return { label: e?.metadata?.name, value: e?.metadata?.name };
          });
        setOptions(finalOptions || []);
      })
      .catch((err: any) => {
        setOptions([]);
        setLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred while getting entity suggestions: ${err.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  const onInputChange = (value: any) => {
    getOptionsFromApi(value);
  };

  // @ts-ignore
  const onValueChange = (event: any, value: any, reason: string) => {
    if (onChange) {
      if (value) {
        onChange(JSON.stringify(value));
        return;
      }
      onChange('');
    }
  };

  if (!_.isString(value) && onChange) {
    onValueChange(undefined, value, '');
  }

  const getOptionLabel = (opt: any) => {
    if (Array.isArray(opt) && opt?.length > 0) {
      return opt[0]?.label;
    }
    return opt?.label || '';
  };

  return (
    <Suggest
      loading={loading}
      options={options}
      label={label}
      value={value}
      id={id}
      onInputChangeDebounced={onInputChange}
      onChange={onValueChange}
      getOptionLabel={getOptionLabel}
      multiple={false}
    />
  );
};
export default EntitySuggest;
