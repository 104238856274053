import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { StepDataInterface } from '../interfaces';
import { useStyles } from '../common/styles';

interface StepCardProps {
  stepData: StepDataInterface;
  onEditClick: Function;
  onDeleteButton: Function;
}

export const StepsListCard = ({
  stepData,
  onEditClick,
  onDeleteButton,
}: StepCardProps) => {
  const classes = useStyles();

  return (
    <div className={classes.StepCardEntityContainer}>
      <Grid container>
        <Grid
          item
          xs={6}
          sm={6}
          md={6}
          lg={3}
          className={`${classes.row} ${classes.center}`}
        >
          <DragHandleIcon className={classes.handleIcon} />
          <Typography className={classes.CardTypeFontBold}>
            Title <b className={classes.boldText}>{stepData.title}</b>
          </Typography>
        </Grid>

        <Grid
          item
          xs={6}
          sm={6}
          md={6}
          lg={3}
          className={`${classes.row} ${classes.center}`}
        >
          <Typography className={classes.CardTypeFontBold}>
            Description
            <b className={`${classes.boldText} ${classes.Description}`}>
              {stepData.description}
            </b>
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={6}
          lg={3}
          className={`${classes.row} ${classes.center}`}
        >
          <Typography className={classes.CardTypeFontBold}>
            Selector{' '}
            <b className={classes.boldText} data-testid="selector">
              {stepData?.selector || '-'}
            </b>
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={6}
          lg={2}
          className={`${classes.row} ${classes.center}`}
        >
          <Typography className={classes.CardTypeFontBold}>
            Click Indicator{' '}
            <b className={classes.boldText}>
              {stepData?.enableClick?.toString()}
            </b>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={1}
          className={classes.StepCardButtonContainer}
        >
          <Button onClick={() => onEditClick(stepData)}>
            <EditIcon />
          </Button>
          <Button onClick={() => onDeleteButton()}>
            <DeleteIcon />
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
