import { createApiRef } from '@backstage/core-plugin-api';
import { AxiosInstance } from 'axios';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';



export interface LeanixApi {
  getLeanix: () => Promise<any>; 
}

export const leanixApiRef = createApiRef<LeanixApi>({
  id: 'cbre.devx.api.leanix',
});

export class LeanixApiImpl implements LeanixApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  async getLeanix(
    
  ): Promise<any[] | undefined> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get('service/leanix')
      .then(res => res.data);
  }

  
}
