import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Typography,
} from '@material-ui/core';
import OpenInNew from '@material-ui/icons/OpenInNew';
import React from 'react';
import { useStyles } from './styles';

export interface IDocumentDetail {
  name: string;
  type: string;
  summary: string;
  sourceUrl: string;
}

const DocumentCard = ({
  documentDetail,
}: {
  documentDetail: IDocumentDetail;
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.documentCard}>
      <CardContent>
        <div>
          <div className={classes.cardHeader}>
            <Typography variant="h6">{documentDetail.name}</Typography>
            <Chip label={documentDetail.type} />
          </div>
          <div className={classes.documentSummary}>
            {documentDetail.summary}
          </div>
        </div>
      </CardContent>
      <CardActions classes={{ root: classes.cardActionButton }}>
        {documentDetail.sourceUrl && (
          <Button
            variant="outlined"
            href={documentDetail.sourceUrl}
            target="_blank"
            classes={{ root: classes.openUrlButton }}
          >
            <OpenInNew className={classes.openUrlIcon} />
            <Typography className={classes.buttonText}>
              View document
            </Typography>
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default DocumentCard;
