import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const RightArrowIcon = ({ style }: any) => (
  <SvgIcon
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill="#9aa9b3"
    style={{
      fontSize: '2rem',
      ...style,
    }}
  >
    <path
      fillRule="evenodd"
      d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
    />
  </SvgIcon>
);

export default RightArrowIcon;
