import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Form from '@rjsf/material-ui';
import {
  Button,
  Grid,
  Paper,
  makeStyles,
  LinearProgress,
} from '@material-ui/core';
import { Content, Header, Page } from '@backstage/core-components';
// @ts-ignore
import { useApi } from '@backstage/core-plugin-api';
import * as _ from 'lodash';
import UserSuggest from './Suggestions/UserSuggest';
import EntitySuggest from './Suggestions/EntitySuggest';
import HELP_URL from '../../utils/helpLinkConstant';
import BackButton from '../../utils/backButton';
import { HelpComponent } from '../../utils/helpComponent';
import { AuthContext } from '../../../providers/AuthProvider';
import BreadcrumbsNav from '../../common/BreadcrumbsNav/BreadcrumbsNav';
import FormBuilder from '../Manage/FormBuilder/FormBuilder';
import AssetSuggest from './Suggestions/AssetSuggest';
import { PromotionContext } from '../../../providers/PromotionDataProvider';
import { capabilityTaxonomyApiRef } from '../../../apis/capabilityTaxonomyApi';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { DevxBreadCrumb } from '../../common/BreadcrumbsNav/DevxBreadCrumb';
const useStyles = makeStyles({
  editForm: {
    float: 'right',
    marginLeft: '10px',
    ['@media (max-width:920px) and (min-width:280px) ']: {
      width: '100%',
    },
  },
  addCapability: {
    padding: '16px',
    ['@media (max-width:920px) and (min-width:280px) ']: {
      marginTop: '10px',
    },
    '& button[type="submit"]': {
      ['@media (max-width:920px) and (min-width:280px) ']: {
        width: '100%',
      },
    },
  },
});
const DEFAULT_FORM = {
  title: 'Add/Edit Capability',
  description: 'Please fill details',
  type: 'object',
  required: ['name', 'description'],
  properties: {
    name: {
      type: 'string',
      title: 'Capability Name',
      default: '',
      canRemove: false,
      fixed: true,
    },
    description: {
      type: 'string',
      title: 'Description',
      default: '',
      canRemove: false,
      fixed: true,
    },
    owner: {
      type: 'string',
      title: 'Owner',
      canRemove: false,
      fixed: true,
    },
    tools: {
      type: 'string',
      title: 'Tools',
      canRemove: true,
      fixed: false,
    },
    support: {
      type: 'string',
      title: 'Support',
      canRemove: true,
      fixed: false,
    },
    links: {
      type: 'string',
      title: 'Links',
      canRemove: false,
      fixed: true,
      format: 'uri',
    },
    parent: {
      type: 'string',
      title: 'Parent Capability',
      canRemove: false,
      fixed: true,
    },
    assets: {
      type: 'string',
      title: 'Assets',
      canRemove: false,
      fixed: true,
    },
  },
};

const DEFAULT_UI_SCHEMA = {
  description: {
    'ui:widget': 'textarea',
  },
  owner: {
    'ui:widget': 'userSuggest',
  },
  support: {
    'ui:widget': 'userSuggest',
  },
  // "links": {
  //   "ui:widget": "textarea"
  // },
  parent: {
    'ui:widget': 'entitySuggest',
  },
  assets: {
    'ui:widget': 'assetPicker',
  },
};

const customWidgets: any = {
  userSuggest: UserSuggest,
  entitySuggest: EntitySuggest,
  assetPicker: AssetSuggest,
};

const TaxonomyEditPage = () => {
  const onclickHelpButton = useContext(PromotionContext);
  useEffect(() => {
    onclickHelpButton.setToggleHelpButton(false);
  }, []);

  const params = useParams();
  const { kind, namespace, name } = params;
  const classes = useStyles();
  const [isNew, setIsNew] = useState<any>(false);
  const [formObj, setFormObj] = useState<any>({});
  const [uiSchema, setUiSchema] = useState<any>();
  const [formData, setFormData] = useState<any>({});

  const [inProgress, setInProgress] = useState<boolean>(false);
  const [editFormFlag, setEditFormFlag] = useState(false);

  const navigate = useNavigate();
  const capabilityTaxonomyApi = useApi(capabilityTaxonomyApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const {
    profEmail,
    isAdmin,
  }: {
    profEmail: any;
    isAdmin: any;
  } = useContext(AuthContext);

  const loggedInUser = `${profEmail}`
    ? `${profEmail}`?.substring(0, `${profEmail}`?.indexOf('@'))
    : '';

  const hydrateFormSchema = (schema: any) => {
    let newSchema = { ...schema };
    let properties = schema?.properties;
    if (properties != null && Object.keys(properties).length > 0) {
      const fixedComponents = [
        'name',
        'description',
        'owner',
        'parent',
        'assets',
        'links',
      ];
      let newProperties = { ...properties };
      Object.keys(properties).map(key => {
        if (fixedComponents.includes(key)) {
          newProperties[key].canRemove = false;
          newProperties[key].fixed = true;
          if (key === 'links') {
            newProperties[key].format = 'uri';
          }
        }
      });
      if (newProperties && !Object.keys(newProperties).includes('assets')) {
        newProperties['assets'] = {
          type: 'string',
          title: 'Assets',
          canRemove: false,
          fixed: true,
        };
      }
      newSchema.properties = newProperties;
    }
    return newSchema;
  };

  const getEntityByName = () => {
    setInProgress(true);
    capabilityTaxonomyApi
      .getTaxonomyDetails(kind, namespace, name)
      .then((res: any) => {
        setInProgress(false);
        if (res != null) {
          if (res.metadata?.capSchema != null) {
            let capSchema = res.metadata.capSchema;
            capSchema = hydrateFormSchema(capSchema);
            setFormObj(capSchema);
            setFormData(res.metadata?.capFormData);
            let uiSchema = res?.metadata?.capUiSchema;
            if (uiSchema) {
              uiSchema = {
                ...uiSchema,
                name: { 'ui:readonly': true },
                assets: { 'ui:widget': 'assetPicker' },
              };
            }
            setUiSchema(uiSchema);
          }
        }
      })
      .catch((error: any) => {
        setInProgress(false);
        notificationApi.sendNotification({
          severity: 'error',
          disapperAfterMs: 2500,
          message: `Error occurred whlie getting the entity: ${error.message}`,
        });
      });
  };

  useEffect(() => {
    if (isAdmin === false) {
      window.location.replace('/');
    }
  }, [isAdmin]);

  useEffect(() => {
    if (kind && namespace && name) {
      if ('_add_newcap' === name) {
        setIsNew(true);
        setFormObj(DEFAULT_FORM);
        setUiSchema(DEFAULT_UI_SCHEMA);
        setFormData({});
      } else {
        setIsNew(false);
        // setFormData(formData);
        // setFormObj(formObj);
        // setUiSchema(uiSchema);
        getEntityByName();
      }
    }
  }, [kind, namespace, name]);

  const onFormSubmit = (params: any) => {
    setUiSchema(params?.uiSchema);
    setFormData(params?.formData);
    setFormObj(params?.schema);

    const data = {
      formData: params?.formData || {},
      schema: params?.schema,
      uiSchema: params?.uiSchema,
      name: params?.formData?.name,
      user: `${loggedInUser}`.toLowerCase(),
    };

    if (isNew) {
      setInProgress(true);
      capabilityTaxonomyApi
        .createCapability(data)
        .then((respData: any) => {
          setInProgress(false);
          notificationApi.sendNotification({
            severity: 'success',
            disapperAfterMs: 2500,
            message: `Capability ${respData?.name} created successfully.`,
            callback: () => {
              navigate('/taxonomy');
            },
          });
        })
        .catch((err: any) => {
          setInProgress(false);
          const errorMessage =
            err?.response?.data &&
            err?.response?.data !== '' &&
            err?.response?.data.message
              ? err?.response?.data.message
              : err?.message;
          notificationApi.sendNotification({
            severity: 'error',
            disapperAfterMs: 2500,
            message: `Error occurred : ${errorMessage}`,
          });
        });
    } else {
      setInProgress(true);
      capabilityTaxonomyApi
        .updateCapability(data)
        .then((respData: any) => {
          setInProgress(false);
          notificationApi.sendNotification({
            severity: 'success',
            disapperAfterMs: 2500,
            message: `Capability ${respData?.name} updated successfully.`,
            callback: () => {
              navigate('/taxonomy');
            },
          });
        })
        .catch((err: any) => {
          setInProgress(false);
          notificationApi.sendNotification({
            severity: 'error',
            disapperAfterMs: 2500,
            message: `Error occurred whlie updating capability: ${err?.message}`,
          });
        });
    }
  };

  const onClickEditForm = () => {
    setEditFormFlag(true);
  };

  const onClickFormBuilderSaveForm = (newSchema: any, newUiSchema: any) => {
    let formObj = newSchema;
    formObj.title = 'Add/Edit Capability';
    formObj.description = 'Please fill details';
    setFormObj(formObj);
    let uiSchema = newUiSchema;
    if (!isNew && uiSchema) {
      uiSchema = {
        ...uiSchema,
        name: { 'ui:readonly': true },
        assets: { 'ui:widget': 'assetPicker' },
      };
    }
    setUiSchema(uiSchema);
    setEditFormFlag(false);
  };

  const onClickFormBuilderCancel = () => {
    setEditFormFlag(false);
  };

  const validate = (formData: any, errors: any) => {
    let name = formData.name;
    if (name && !_.isEmpty(name)) {
      let regex = new RegExp(/^[a-z A-Z0-9_-]*$/);
      let valid = regex.test(name);
      if (!valid) {
        errors.name.addError(
          'Only alphabets, numbers, hyphen and underscore allowed.',
        );
      }
    }
    if (!_.isEmpty(name) && !_.isEmpty(formData.parent)) {
      try {
        let parent = JSON.parse(formData.parent);
        if (parent && _.isObject(parent)) {
          if (_.isEqual(parent?.label?.toLowerCase(), name?.toLowerCase())) {
            errors.parent.addError(
              'A capability cannot be a parent capability of itself.',
            );
          }
        }
      } catch (err) {}
    }
    return errors;
  };

  return (
    <Page themeId="home">
      <Header
        title="Add/Edit Capability"
        subtitle={
          <DevxBreadCrumb
            routes={[
              {
                type: 'link',
                link: '/',
                text: 'Home',
              },
              {
                type: 'link',
                link: '/taxonomy',
                text: 'Capability Taxonomy',
              },
              {
                type: 'text',
                link: '',
                text: 'Add/Edit Capability',
              },
            ]}
          />
        }
      />
      <Content>
        {inProgress && <LinearProgress />}
        {editFormFlag ? (
          <>
            <Grid item xs={12} md={12} xl={12}>
              <BackButton />
              <HelpComponent helpUrl={HELP_URL.TAXANOMYEDIT_CAPABILITY} />
            </Grid>
            <FormBuilder
              formSchema={formObj}
              formUiSchema={uiSchema}
              onClickSaveForm={onClickFormBuilderSaveForm}
              onClickCancel={onClickFormBuilderCancel}
            />
          </>
        ) : (
          <>
            <Grid item xs={12} md={12} xl={12}>
              <BackButton />
              <HelpComponent helpUrl={HELP_URL.ADD_CAPABILITY} />
              <Button
                variant="contained"
                color="primary"
                className={classes.editForm}
                onClick={onClickEditForm}
              >
                Edit Form
              </Button>
            </Grid>
            <Grid container direction="row">
              <Grid item xl={6} lg={6} xs={12}>
                <Paper className={classes.addCapability}>
                  <Form
                    schema={formObj}
                    formData={formData}
                    widgets={customWidgets}
                    uiSchema={uiSchema}
                    onSubmit={onFormSubmit}
                    validate={validate}
                  ></Form>
                </Paper>
              </Grid>
            </Grid>
          </>
        )}
      </Content>
    </Page>
  );
};
export default TaxonomyEditPage;
