import React, { useContext, useEffect, useState } from 'react';
import {
  Chip,
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  TablePagination,
  TextField,
  Typography,
} from '@material-ui/core';
import { Content } from '@backstage/core-components';
import BackButton from '../../utils/backButton';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import SearchIcon from '@material-ui/icons/Search';
import { useStyles } from '../styles';
import { useNavigate } from 'react-router-dom';
import ProjectCard from './cards/ProjectCard';
import { ITPContext, TPContext } from '../providers/TPProvider';
import { IApmDetails } from '../interfaces';
import { useFilteredProjects } from '../hooks/useFilteredProjects';
import { useGetMyApms } from '../hooks/useGetMyApms';

const AllProjectsPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const navigateToDetails = (apmNumber: string) => {
    navigate(`project/${apmNumber}`);
  };

  const [isLoading, setIsLoading] = useState(true);

  const { allApms }: ITPContext = useContext(TPContext);

  const [searchText, setSearchText] = React.useState('');
  const _rowsPerPage = 20;
  const [_paginateCount, setPaginateCount] = useState(0);
  const [paginatedData, setPaginatedData] = useState([]);

  const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const [bookmarkSelected, setBookmarkSelected] = useState(false);

  useEffect(() => {
    if (allApms.length > 0) setIsLoading(false);
  }, [allApms]);

  const handleBookmarkClicked = () => {
    setBookmarkSelected((prevState: boolean) => !prevState);
  };

  const [myProjectsSelected, setMyProjectsSelected] = useState(false);

  const handleMyProjectsClicked = () => {
    setMyProjectsSelected((prevState: boolean) => !prevState);
  };

  const [filteredProjectData, type, setFilterOption, filterOption] =
    useFilteredProjects(
      allApms,
      searchText,
      bookmarkSelected,
      myProjectsSelected,
    );

  useEffect(() => {
    if (filteredProjectData && filteredProjectData.length > 0) {
      const begin = _paginateCount * _rowsPerPage;
      const end = begin + _rowsPerPage;
      setPaginatedData([...filteredProjectData.slice(begin, end)]);
    } else {
      setPaginatedData([]);
    }
  }, [_paginateCount, filteredProjectData]);
  useEffect(() => {
    setPaginateCount(0);
  }, [filteredProjectData]);
  const onPageChange = (
    event: React.SyntheticEvent | null,
    pageNumber: number,
  ): void => {
    if (event) {
      event.preventDefault();
    }
    setPaginateCount(pageNumber);
  };

  return (
    <Content>
      <Grid container direction="row">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <BackButton />
          <HelpComponent helpUrl={HELP_URL.PROVISIONING_CENTER_LANDINGPAGE} />
        </Grid>
      </Grid>
      <div className={`codePage ${classes.layoutPage}`}>
        <Grid container className={classes.header}>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <Typography variant="h4">
              Applications({filteredProjectData?.length || 0})
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <TextField
              className={classes.backGroundColor}
              variant="outlined"
              type="search"
              placeholder="Search Applications"
              onChange={onChangeSearchText}
              value={searchText}
              fullWidth
              InputProps={{
                endAdornment: !searchText && <SearchIcon />,
              }}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            {isLoading && <LinearProgress />}
          </Grid>
        </Grid>

        {!isLoading ? (
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.flexSpacebetween}
            >
              <div>
                <Chip
                  onClick={() => handleBookmarkClicked()}
                  label="Bookmarks"
                  className={`${classes.filterChipButton} ${bookmarkSelected ? classes.tagSelected : ''
                    }`}
                />
                <Chip
                  onClick={() => handleMyProjectsClicked()}
                  label="My Projects"
                  className={`${classes.filterChipButton} ${myProjectsSelected ? classes.tagSelected : ''
                    }`}
                />
              </div>
              <div className={classes.flexCenter}>
                <div
                  className={`${classes.content} ${classes.assetTypeDropdown}`}
                >
                  <span className={classes.filterProject}>Asset type</span>
                  <Select
                    className={classes.filterProjectDropDown}
                    disableUnderline
                    displayEmpty
                    value={filterOption.assetType}
                    onChange={(val: any) => {
                      setFilterOption({
                        ...filterOption,
                        assetType: val.target.value,
                      });
                    }}
                  >
                    <MenuItem value="All">All asset types</MenuItem>
                    {type &&
                      type.map((t: string) => (
                        <MenuItem key={`type-option-${t}`} value={t}>
                          {t}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
                <div className={classes.content}>
                  <span className={classes.filterProject}>Scope</span>
                  <Select
                    className={classes.filterProjectDropDown}
                    disableUnderline
                    displayEmpty
                    value={filterOption.scope}
                    onChange={(val: any) => {
                      setFilterOption({
                        ...filterOption,
                        scope: val.target.value,
                      });
                    }}
                  >
                    <MenuItem value="All">All scopes</MenuItem>
                    <MenuItem value="Invest">Invest</MenuItem>
                    <MenuItem value="Sustain">Sustain</MenuItem>
                  </Select>
                </div>
              </div>
            </Grid>
            {paginatedData.map((project: IApmDetails) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={project.ApmNumber}>
                <ProjectCard
                  key={project.ApmNumber}
                  project={project}
                  onClick={() => navigateToDetails(project.ApmNumber)}
                />
              </Grid>
            ))}
            {
              paginatedData.length > 0 && <Grid xs={12}>
              <TablePagination
                component='div'
                page={_paginateCount}
                rowsPerPage={_rowsPerPage}
                count={filteredProjectData.length}
                onPageChange={onPageChange}
                rowsPerPageOptions={[]}
              />
            </Grid>
            }

            {paginatedData.length === 0 ? (
              <Grid item xs={12} sm={12} md={6} lg={4}>
                No Projects found
              </Grid>
            ) : null}

          </Grid>
        ) : null}
      </div>
    </Content>
  );
};
export default AllProjectsPage;
