import React, { ComponentProps } from 'react';
import { useStarredEntities } from '@backstage/plugin-catalog-react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Entity } from '@backstage/catalog-model';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';

type Props = ComponentProps<typeof IconButton> & { entity: Entity };

export const favoriteEntityTooltip = (isStarred: boolean) =>
  isStarred ? 'Remove from bookmarks' : 'Add to bookmark';

export const favoriteEntityIcon = (isStarred: boolean) =>
  isStarred ? <BookmarkIcon /> : <BookmarkBorderOutlinedIcon />;

/**
 * IconButton for showing if a current entity is starred and adding/removing it from the favorite entities
 * @param props MaterialUI IconButton props extended by required `entity` prop
 */
export const FavoriteEntity = (props: Props) => {
  const { toggleStarredEntity, isStarredEntity } = useStarredEntities();
  const isStarred = isStarredEntity(props.entity);
  return (
    <IconButton
      color="inherit"
      {...props}
      onClick={() => toggleStarredEntity(props.entity)}
    >
      <Tooltip title={favoriteEntityTooltip(isStarred)}>
        {favoriteEntityIcon(isStarred)}
      </Tooltip>
    </IconButton>
  );
};
