import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import remarkSlug from 'remark-slug';
import rehypeExternalLinks from 'rehype-external-links';
import remarkFrontmatter from 'remark-frontmatter';
import { Progress } from '@backstage/core-components';

export const HelpPageRender = ({ pageName }: any) => {
  const file_name = `${pageName}.md`;
  const [post, setPost] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    import(`../../helpMarkdownFile/${file_name}`)
      .then(res => {
        fetch(res.default)
          .then(Response => Response.text())
          .then(data => {
            setPost(data);
            setLoading(false);
          });
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  if (loading) return <Progress />;

  return (
    <ReactMarkdown
      children={post}
      linkTarget="_blank"
      remarkPlugins={[remarkGfm, remarkSlug, remarkFrontmatter]}
      rehypePlugins={[rehypeRaw, rehypeExternalLinks]}
    />
  );
};

export default HelpPageRender;
