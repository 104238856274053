import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const EmraldIcon = () => (
  <SvgIcon height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
    <path d="M 21.421 2.495 L 2.298 2.495 L 2.298 21.618 L 21.421 2.495 Z" />
    <path d="M 21.421 2.495 L 2.298 2.495 L 2.298 21.618 L 21.421 2.495 Z" />
    <path d="M 21.421 2.495 L 2.298 2.495 L 2.298 21.618 L 21.421 2.495 Z" />
    <path d="M 7.876 18.431 L 18.234 18.431 L 18.234 8.072 L 7.876 18.431 Z" />
    <path d="M 7.876 18.431 L 18.234 18.431 L 18.234 8.072 L 7.876 18.431 Z" />
    <path d="M 7.876 18.431 L 18.234 18.431 L 18.234 8.072 L 7.876 18.431 Z" />
  </SvgIcon>
);

export default EmraldIcon;
