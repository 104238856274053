import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import REGISTER_API_CONSTANTS from './Constants';
import { Grid, Button, TextField } from '@material-ui/core';
import { PromotionContext } from '../../../providers/PromotionDataProvider';
import { useStyles } from './styles';

const GenerateSwagger = () => {
  const myContext = useContext(PromotionContext);
  const classes = useStyles();
  const navigate = useNavigate();
  /* navigate to register api page */
  const navigateToRegisterAPIPage = () => {
    navigate('/API/register-api');
  };

  const exportData = () => {
    const swaggerJson = JSON.parse(myContext.swaggerJson);
    const infoTitle = swaggerJson?.info?.title;
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      myContext.swaggerJson,
    )}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = `${infoTitle}.json` || 'SwaggerFile.json';

    link.click();
  };
  return (
    /* Generate Swagger Component */
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h1 className={classes.generateHeading}>
            {REGISTER_API_CONSTANTS.generateSwagger.generateApiDef}
          </h1>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Button
            className={`${classes.apiRegistrationBtn} ${classes.columnDirection}`}
            onClick={exportData}
            variant="outlined"
          >
            Download Swagger JSON
          </Button>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Button
            className={`${classes.apiRegistrationBtn} ${classes.columnDirection}`}
            onClick={navigateToRegisterAPIPage}
            variant="outlined"
          >
            Go to API Registration
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.swaggerContainer}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.height100}
        >
          <div className={classes.generateInput}>
            <TextField
              value={myContext.swaggerJson}
              label=""
              placeholder=""
              required
              multiline
              fullWidth
              InputProps={{ disableUnderline: true }}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default GenerateSwagger;
