import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import REGISTER_API_CONSTANTS from './Constants';
import { useStyles } from './styles';
import { registerApiRef } from '../../../apis/registerApis';
import { capitalizeFirstLetter } from '../../home/allAssets/util';
import {
  notificationApiRef,
  NotificationApi,
} from '../../../apis/notificationApi';

const ConfirmDetails = (props: any) => {
  const registerApis = useApi(registerApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const { formValues } = props;
  const classes = useStyles();
  const navigate = useNavigate();

  /* navigate to register success page */
  const navigateToRegisterSuccessPage = () => {
    navigate('/API/register-api/success', { replace: true });
  };
  /* navigate to register api page */
  const navigateToRegisterAPIPage = () => {
    navigate('/API/register-api');
  };

  const createRegisterPayload = () => {
    const requestPayload = {
      id: `${formValues.apiTitle}_${new Date().getTime()}`,
      name: formValues.apiTitle,
      description: formValues.apiDescription,
      version: formValues.apiVersion,
      apiDefinition: formValues.apiDefinition,
      tags: formValues.tags,
      businessInformation: {
        technicalOwnerEmail: formValues.apmDetails.PrimarySupportOwnerEmail,
        businessOwnerEmail: formValues.apmDetails.PrimaryBusinessOwnerEmail,
      },
      additionalProperties: {
        apmNumber: formValues.apmNumber,
        apiscope: formValues.apiScope,
        businessservices: formValues.service,
        region: formValues.apmDetails.Region,
        segment: formValues.apmDetails.Segment,
        reusable: 'Y',
      },
      assetstore: formValues.apiType,
      redirectUrl: formValues.support,
    };
    return requestPayload;
  };

  const handleSubmit = () => {
    const reqPayload = createRegisterPayload();

    registerApis.registerCustomApi(reqPayload).then((resData: any) => {
      if (resData?.status == 200) {
        notificationApi.sendNotification({
          message: REGISTER_API_CONSTANTS.registerSuccess.successMsg,
          disapperAfterMs: 2500,
          severity: 'success',
          callback: navigateToRegisterSuccessPage,
        });
      } else {
        notificationApi.sendNotification({
          message: `${resData?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    });
  };

  return (
    /* Confirm Details Component */
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <div className={classes.confirmHeading}>
          {REGISTER_API_CONSTANTS.confirmDetails.confirmHeading}
        </div>
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        <div className={classes.confirmTitle}>
          {REGISTER_API_CONSTANTS.registerApiForm.apiTitle.label}
        </div>
        <div className={classes.confirmDesc}>{formValues.apiTitle}</div>
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        <div className={classes.confirmTitle}>
          {REGISTER_API_CONSTANTS.registerApiForm.apiDescription.label}
        </div>
        <div className={classes.confirmDesc}>{formValues.apiDescription}</div>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <div className={classes.confirmTitle}>
          {REGISTER_API_CONSTANTS.confirmDetails.techOwner.label}
        </div>
        <div className={classes.confirmDesc}>
          {formValues.apmDetails.PrimarySupportOwner}
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <div className={classes.confirmTitle}>
          {REGISTER_API_CONSTANTS.confirmDetails.productOwner.label}
        </div>
        <div className={classes.confirmDesc}>
          {formValues.apmDetails.PrimaryBusinessOwner}
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <div className={classes.confirmTitle}>
          {REGISTER_API_CONSTANTS.registerApiForm.tags.label}
        </div>
        <div className={classes.confirmDesc}>{formValues.tags.join(', ')}</div>
      </Grid>

      <Grid item xs={12} sm={12} md={4} lg={4}>
        <div className={classes.confirmTitle}>
          {REGISTER_API_CONSTANTS.registerApiForm.apiType.label}
        </div>
        <div className={classes.confirmDesc}>
          {capitalizeFirstLetter(formValues.apiType)}
        </div>
      </Grid>

      <Grid item xs={12} sm={12} md={4} lg={4}>
        <div className={classes.confirmTitle}>
          {REGISTER_API_CONSTANTS.registerApiForm.apiScope.label}
        </div>
        <div className={classes.confirmDesc}>{formValues.apiScope} </div>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <div className={classes.confirmTitle}>
          {REGISTER_API_CONSTANTS.registerApiForm.apiVersion.label}
        </div>
        <div className={classes.confirmDesc}>{formValues.apiVersion} </div>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <div className={classes.confirmTitle}>
          {REGISTER_API_CONSTANTS.registerApiForm.lifeCycle.label}
        </div>
        <div className={classes.confirmDesc}>{formValues.lifeCycle}</div>
      </Grid>

      <Grid item xs={12} sm={12} md={4} lg={4}>
        <div className={classes.confirmTitle}>
          {REGISTER_API_CONSTANTS.registerApiForm.hostedLocation.label}
        </div>
        <div className={classes.confirmDesc}>
          {formValues.apmDetails.HostedLocation}
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <div className={classes.confirmTitle}>
          {REGISTER_API_CONSTANTS.registerApiForm.documentationUrl.label}
        </div>
        <div className={`${classes.confirmDesc} ${classes.supportDesc}`}>
          <a href="#">{formValues.support}</a>
        </div>
      </Grid>

      <Grid item xs={12} sm={12} md={4} lg={4}>
        <div className={classes.confirmTitle}>
          {REGISTER_API_CONSTANTS.registerApiForm.segment.label}
        </div>
        <div className={classes.confirmDesc}>
          {formValues.apmDetails.Segment}
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <div className={classes.confirmTitle}>
          {REGISTER_API_CONSTANTS.registerApiForm.region.label}
        </div>
        <div className={classes.confirmDesc}>
          {formValues.apmDetails.Region}
        </div>
      </Grid>

      {/* <Grid item xs={12} sm={12} md={4} lg={4}>
        <div className={classes.confirmTitle}>
          {REGISTER_API_CONSTANTS.registerApiForm.apiCategory.label}
        </div>
        <div className={classes.confirmDesc}>{formValues.apiCategory}</div>
      </Grid> */}
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <div className={classes.confirmTitle}>
          {REGISTER_API_CONSTANTS.registerApiForm.apmNumber.label}
        </div>
        <div className={classes.confirmDesc}>{formValues.apmNumber} </div>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <div className={classes.confirmTitle}>
          {REGISTER_API_CONSTANTS.registerApiForm.service.label}
        </div>
        <div className={classes.confirmDesc}>
          {formValues.service.join(', ')}
        </div>
      </Grid>
      {/* <Grid item xs={12} sm={12} md={4} lg={4}>
        <div className={classes.confirmTitle}>
          {REGISTER_API_CONSTANTS.registerApiForm.performance.label}
        </div>
        <div className={classes.confirmDesc}>{formValues.performance}</div>
      </Grid> */}
      {/* submit and cancel btns */}
      <Grid
        item
        xs={12}
        sm={12}
        className={`${classes.registerFormBtns} ${classes.columnDirection}`}
      >
        <Button
          className={classes.successbtn}
          onClick={handleSubmit}
          variant="contained"
        >
          Submit
        </Button>
        <Button
          variant="outlined"
          className={classes.cancelbtn}
          onClick={navigateToRegisterAPIPage}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};
export default ConfirmDetails;
