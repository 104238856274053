import React, { useContext, useEffect } from 'react';
import { Header, Page, TabbedLayout } from '@backstage/core-components';
import UserGroupsPage from './components/UserGroupsPage';
import ActivityPage from './components/ActivityPage';
import ToolsManagementPage from './components/ToolsManagementPage';
import { Navigate, Route, Routes, useParams } from 'react-router';
import { ITPContext, TPContext } from './providers/TPProvider';
import OverviewPage from './components/OverviewPage';
import EditDetailsPage from './components/EditDetailsPage';
import { useGetApmInfoById } from './hooks/useGetApmInfoById';
import { AuthContext } from '../../providers/AuthProvider';
import AdminsPage from './components/AdminsPage';
import { ServiceRoutes } from './components/services/ServiceRoutes';

const TPTabs = () => {
  const { projectId } = useParams();
  const { allApms, setApmInfo, apmInfo, myApms }: ITPContext =
    useContext(TPContext);

  const [apmDetails] = useGetApmInfoById(projectId, allApms);

  useEffect(() => {
    setApmInfo(apmDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apmDetails]);

  const { profEmail, isAdmin } = useContext(AuthContext);

  const isToolAdmin =
    isAdmin ||
    profEmail?.toLowerCase() ===
      apmInfo?.PrimaryBusinessOwnerEmail?.toLowerCase() ||
    profEmail?.toLowerCase() ===
      apmInfo?.PrimarySupportOwnerEmail?.toLowerCase() ||
    apmInfo?.admins?.includes(profEmail) ||
    myApms?.includes(projectId!)
      ? true
      : false;

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="./services" replace />} />
      </Routes>

      {apmInfo ? (
        <TabbedLayout>
          <TabbedLayout.Route path="/services" title="Services">
            <ServiceRoutes isToolAdmin={isToolAdmin} />
          </TabbedLayout.Route>
          <TabbedLayout.Route path="/apm-info" title="Apm Info">
            <Routes>
              <Route path="/" element={<OverviewPage />} />
              <Route path="/edit-details" element={<EditDetailsPage />} />
            </Routes>
          </TabbedLayout.Route>

          {/* <TabbedLayout.Route path="/tools" title="Tools">
                <ToolsManagementPage type="tool" key="tool" />
              </TabbedLayout.Route> */}
          {/* <TabbedLayout.Route
          path="/users-&-user-groups"
          title="Users & User Groups"
        >
          <UserGroupsPage />
        </TabbedLayout.Route> */}
          <TabbedLayout.Route path="/activity" title="Activity">
            <ActivityPage />
          </TabbedLayout.Route>
          <TabbedLayout.Route path="/admins" title="Admins">
            <AdminsPage isToolAdmin={isToolAdmin} />
          </TabbedLayout.Route>
        </TabbedLayout>
      ) : null}
    </>
  );
};
export default TPTabs;
