import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import Scopes from '../common/Scopes';
import { useParams } from 'react-router';
import { IPromptData } from '../interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    width100Percent: {
      width: '100%',
    },
    dialogBox: {
      // position: 'relative',
      //    margin: '10px 0px 0px 24px',
    },
    dialogContent: {
      minWidth: '500px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '500px',
      [theme.breakpoints.down('sm')]: {
        minWidth: '0px',
      },
      //  margin: '10px',
      //  padding: '10px',
    },
    closeButton: {
      position: 'absolute',
      top: '10px',
      right: '10px',
    },
    dialogHeader: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: '0px',
      justifyContent: 'center',
    },
    personaErrorMessage: {
      color: theme.palette.error.main,
    },
    headerTitle: {
      //    margin: '10px',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
      },
    },
    savePromptContent: {
      //   margin: '10px',
    },
    footerSection: {
      margin: '10px',
      alignItems: 'normal',
    },
  }),
);

export interface CommentPopupProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (comment: string, scope: any) => void;
  dialogProps: {
    headerText: string;
    subHeaderText: string | React.ReactNode;
    bodyText?: string;
    buttonText: string;
  } | null;
  customClasses?: {
    header: string | '';
    footer: string | '';
  };
  domains?: any;
  promptData?: IPromptData;
}

const CommentsPopup = ({
  open,
  onClose,
  onConfirm,
  dialogProps,
  customClasses = { header: '', footer: '' },
  domains,
  promptData,
}: CommentPopupProps) => {
  const classes = useStyles();

  const [comment, setComment] = useState('');

  const [showLoader, setShowLoader] = useState<boolean>(false);

  const handleChange = (event: any): void => {
    setComment(event.target.value);
  };

  const [inputScope, setInputScope] = useState({
    domainId: promptData?.product,
    subDomainId: '',
    personaId: '',
  });

  const handleScopeChange = (scope: any) => {
    setInputScope((prevState: any) => {
      return {
        ...prevState,
        personaId: scope.personaId,
        subDomainId: scope.subDomainId,
      };
    });
  };

  const handleConfirm = () => {
    setShowLoader(true);
    onConfirm(comment, inputScope);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      //   className={classes.dialogBox}
      fullWidth={false}
    >
      <Card elevation={0} classes={{ root: customClasses?.header }}>
        <CardHeader
          title={
            <Typography className={classes.headerTitle} variant="h6">
              {dialogProps?.headerText}
            </Typography>
          }
          subheader={
            <Typography color="secondary" component="span" className="">
              {dialogProps?.subHeaderText}
            </Typography>
          }
          action={
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          }
        ></CardHeader>
      </Card>

      <CardContent className={classes.dialogContent}>
        {/* {scopes} */}
        <Grid container>
          {domains ? (
            <Grid item xs={12}>
              <Scopes
                inputScope={inputScope}
                domains={domains}
                onChange={handleScopeChange}
                required
                domainVisible={false}
                variant="outlined"
                exceptionPersonas={promptData?.persona}
              />
            </Grid>
          ) : null}
          {promptData?.persona.includes(inputScope.personaId) && (
            <Grid item xs={12}>
              <span className={classes.personaErrorMessage}>
                You cannot approve a prompt into the same persona !
              </span>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Add comment/feedback for the publisher"
              value={comment}
              onChange={handleChange}
            ></TextField>
            {showLoader && (
              <LinearProgress className={classes.width100Percent} />
            )}
          </Grid>
        </Grid>
      </CardContent>
      <DialogActions
        className={`${customClasses?.footer} ${classes.footerSection}`}
      >
        <Button
          color="primary"
          onClick={() => onClose()}
          data-testid="no-btn"
          variant="outlined"
          autoFocus
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={handleConfirm}
          data-testid="no-btn"
          variant="contained"
          autoFocus
          className="confirmButton"
          disabled={
            (domains && (!inputScope.subDomainId || !inputScope.personaId)) ||
            promptData?.persona.includes(inputScope.personaId)
          }
        >
          {dialogProps?.buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommentsPopup;
