import React, { useEffect, useState, useContext } from 'react';
import * as yup from 'yup';
import ReplyIcon from '@material-ui/icons/Reply';
import {
  Button,
  TextField,
  Grid,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { HelpComponent } from '../../../utils/helpComponent';
import { useCustomStyles } from './CardStyles';
import { gamificationApiRef } from '../../../../apis/gamificationApis';
import HELP_URL from '../../../utils/helpLinkConstant';
import { PromotionContext } from '../../../../providers/PromotionDataProvider';
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';

export const limitChars = (val: string, limit: number) => {
  if (val) {
    if (val.length > limit) {
      return val.substring(0, limit);
    }
  }
  return val;
};

const ModifyPoints = (props: any) => {
  const { name, description, points, id } = props.data;
  const { onBackClicked } = props;
  const onclickHelpButton = useContext(PromotionContext);
  const classes = useCustomStyles();
  const updatedPoints = useApi(gamificationApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const [_updatedPoints, setUpdatedPoints] = useState();
  const [_pointsValidationErr, setPointsValidationError] = useState('');
  const [_showDialog, setShowDialog] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    onclickHelpButton.setToggleHelpButton(false);
  }, []);

  const handleRedirect = () => {
    onBackClicked();
  };

  const handleSubmit = () => {
    updatedPoints
      .updateRewardsCriteria({
        points: _updatedPoints,
        id: id,
      })
      .then((res: any) => {
        setInProgress(false);
        setShowDialog(true);
        notificationApi.sendNotification({
          message: `Points updated successfully, updated version will be available shortly`,
          disapperAfterMs: 2500,
          severity: 'success',
          callback: handleRedirect,
        });
      })
      .catch((err: any) => {
        setInProgress(false);
        setShowDialog(true);
        notificationApi.sendNotification({
          message: err.message,
          disapperAfterMs: 2500,
          severity: 'error',
          callback: handleRedirect,
        });
      });
  };

  const validateInputs = async () => {
    const valResult = yup
      .number()
      .positive()
      .integer('This must be an integer ')
      .nullable(true)
      .required('Updated points is required')
      .min(1, 'At least 1 characters required')
      .positive('This field must contain a positive number')
      .integer('This field should contain an integer')
      .validate(_updatedPoints)
      .then(() => {
        setPointsValidationError('');
        return true;
      })
      .catch(err => {
        setPointsValidationError(err.message);
        return false;
      });

    const allPromises = await Promise.all([valResult]);
    return allPromises.reduce((prev, current) => {
      return prev && current;
    });
  };

  const onSubmit = async () => {
    if (await validateInputs()) {
      handleSubmit();
      setInProgress(true);
    }
  };

  return (
    <>
      <Grid xs container md={12}>
        <Grid md={12}>
          <Button onClick={handleRedirect} color="primary">
            <ReplyIcon /> Back
          </Button>
          <HelpComponent helpUrl={HELP_URL.updateRewardsPoints} />
        </Grid>

        {inProgress && <LinearProgress />}

        <Grid className={classes.modifyPointsContainer} xs={12} container>
          <Grid xs={12} item>
            <Typography variant="h6">Modify Points</Typography>
          </Grid>

          <Grid xs={12} item>
            <Typography>{name}</Typography>
            <Typography>{description}</Typography>
          </Grid>
          <Grid xs={12} item className={classes.marginTop24}>
            <TextField
              disabled={true}
              style={{ width: '100%' }}
              placeholder="Type here..."
              InputLabelProps={{
                shrink: true,
              }}
              id="modify-points"
              label="Existing Points"
              value={points || 0}
              variant="outlined"
            />
          </Grid>
          <Grid xs={12} item>
            <TextField
              disabled={false}
              style={{ width: '100%' }}
              placeholder="Type here..."
              InputLabelProps={{
                shrink: true,
              }}
              id="updated-points"
              label="Points to be updated"
              helperText={_pointsValidationErr}
              onChange={event => {
                const value = limitChars(event?.target?.value, 5);
                setUpdatedPoints(value);
                setPointsValidationError('');
              }}
              value={_updatedPoints}
              variant="outlined"
              FormHelperTextProps={
                _pointsValidationErr
                  ? { style: { color: 'red' } }
                  : { style: { color: 'black' } }
              }
            />
          </Grid>

          <Grid xs={12} item className={classes.saveCancelButtonGroup}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleRedirect}
              className={classes.cancelButton}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={onSubmit}
              disabled={_showDialog}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default ModifyPoints;
