/* istanbul ignore file */
import React, { useRef } from 'react';
import { TableColumn, Link } from '@backstage/core-components';
import { generatePath } from 'react-router-dom';
import { DEFAULT_NAMESPACE } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { userProfileApiRef } from '../../apis/userProfileApi';

export type EntityRow = {
  assetName: string;
  email: string;
  status: string;
  creationDateTime: string;
};

export function createAssetColumn(): TableColumn<EntityRow> {
  return {
    title: 'Assets',
    field: 'assetName',
    render: ({ assetName, assetKind }) => (
      <Link
        to={generatePath(
          `/catalog/${DEFAULT_NAMESPACE}/${assetKind}/${assetName}`,
        )}
      >
        {assetName}
      </Link>
    ),
  };
}

export function createCountColumn(): TableColumn<EntityRow> {
  return {
    title: 'No. of Subscriber(s)',
    field: 'count',
    render: ({ count }) => count,
  };
}

export function createEmailColumn(): TableColumn<EntityRow> {
  return {
    title: 'owner(s)',
    field: 'ownerName',
    render: ({ ownerName }) => {
      const user = `${ownerName}@cbre.com`;
      const userProfileApi = useApi(userProfileApiRef);
      const usernameRef = useRef(null);

      const onMouseEnter = () => {
        userProfileApi.setUserProfileState({
          email: user,
          anchorRef: usernameRef?.current,
        });
      };

      return (
        <Link
          to={generatePath(`/catalog/${DEFAULT_NAMESPACE}/user/${ownerName}`)}
        >
          <span onMouseEnter={onMouseEnter} ref={usernameRef}>
            {user}
          </span>
        </Link>
      );
    },
  };
}

export function createDateCreatedColumn(): TableColumn<EntityRow> {
  return {
    title: 'Date Subscribed',
    field: 'creationDateTime',
    render: ({ creationDateTime }) => creationDateTime,
  };
}
