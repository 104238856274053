import { Content, PageWithHeader } from '@backstage/core-components';
import {
  Box,
  Button,
  createStyles,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  Paper,
  TablePagination,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import React, { useEffect, useState } from 'react';
import BackButton from '../utils/backButton';
import { HelpComponent } from '../utils/helpComponent';
import HELP_URL from '../utils/helpLinkConstant';
import BreadcrumbsNav from '../common/BreadcrumbsNav/BreadcrumbsNav';
import ImageSearchOutlinedIcon from '@material-ui/icons/ImageSearchOutlined';
import { useNavigate, useLocation } from 'react-router';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { useStyles } from './common/styles';
import {
  VisualRegressionApi,
  visualRegressionApiRef,
} from '../../apis/visualRegression';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';
import { useApi } from '@backstage/core-plugin-api';

const TestHistory = ({ data }: any) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [inProgress, setInProgress] = useState(true);
  const [isRefresh, setRefresh] = useState(false);
  const [isCloseButonClick, setCloseButonClick] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [contentPerPage, setContentPerPage] = useState(20);
  const [filteredHistory, setFilteredHistory] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [showedContent, setShowedContent] = useState([]);
  const visualRegressionApi: VisualRegressionApi = useApi(
    visualRegressionApiRef,
  );
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const onClickCloseButton = () => {
    setCloseButonClick(true);
  };
  const dateConvert = (item: any) => {
    const date = new Date(item);
    //console.log(date.toLocaleString());
    const formatDate = date.toLocaleTimeString() + ' ' + date.toDateString();
    return formatDate;
  };

  const navigateToDetailsPage = id => {
    navigate(`/visual-regression/details-page/${id}`, { state: id });
  };

  const navigateToVisualRegressionPage = () => {
    navigate('/visual-regression/');
  };

  const onclickRefresh = () => {
    setRefresh(!isRefresh);
    setInProgress(true);
  };

  useEffect(() => {
    setCurrentIndex(0);
  }, [historyData]);

  useEffect(() => {
    const begin = currentIndex * contentPerPage;
    const end = begin + contentPerPage;
    setShowedContent([...historyData.slice(begin, end)]);
    setTotalCount(historyData.length);
  }, [currentIndex, contentPerPage, historyData]);

  useEffect(() => {
    visualRegressionApi
      .getVisualRegressionHistory()
      .then(res => {
        setInProgress(false);
        setHistoryData(res?.data);
      })
      .catch((err: any) => {
        setInProgress(false);
        notificationApi.sendNotification({
          message: err.message,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  }, [isRefresh]);

  const onPageChange = (
    event: React.SyntheticEvent | null,
    pageNumber: number,
  ): void => {
    if (event) {
      event.preventDefault();
    }
    setCurrentIndex(pageNumber);
  };

  const getStatusColor = (status: any) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return theme.palette.success.main;
      case 'in progress':
        return theme.palette.infoText;
      case 'failed':
        return theme.palette.errorText;
      default:
        return theme.palette.text.tertiary;
    }
  };

  return (
    <>
      <PageWithHeader
        title="Visual Regression Testing "
        themeId="home"
        subtitle={<BreadcrumbsNav lastRoutes={[]} />}
      >
        <Content>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <BackButton />
              <HelpComponent helpUrl={HELP_URL.VISUAL_REGRESSION} />
            </Grid>
          </Grid>

          <div className={`codePage ${classes.visualCodePage}`}>
            <Grid container>
              <Grid item xs={12} sm={12} className={classes.historyHeadingBar}>
                <Typography className={classes.headingText}>
                  Test History
                </Typography>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => onclickRefresh()}
                  startIcon={<RefreshIcon />}
                  className={classes.refreshBtn}
                >
                  Refresh History
                </Button>
              </Grid>
            </Grid>
            {inProgress && <LinearProgress />}
            {showedContent &&
              showedContent?.map((item: any) => (
                <Grid
                  container
                  className={classes.testHistoryCard}
                  key={item.id}
                >
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <Grid>
                      <div className={classes.product}>Product Name</div>
                      <div className={classes.productInfo}>
                        {item?.productname || '-'}
                      </div>
                    </Grid>
                    <Grid>
                      <div className={classes.product}>Reference URL</div>
                      <div className={classes.productInfo}>
                        {item?.referenceurl}
                      </div>
                    </Grid>
                    <Grid>
                      <div className={classes.product}>
                        Reference Environment
                      </div>
                      <div className={classes.productInfo}>
                        {item?.referenceenv || '-'}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <Grid>
                      <div className={classes.product}>Difference</div>
                      <div className={classes.productInfo}>
                        {item?.diffamount ? item?.diffamount + '%' : '-'}
                      </div>
                    </Grid>
                    <Grid>
                      <div className={classes.product}>Test URL</div>
                      <div className={classes.productInfo}>{item?.testurl}</div>
                    </Grid>
                    <Grid>
                      <div className={classes.product}>Test Environment</div>
                      <div className={classes.productInfo}>
                        {item?.testenv || '-'}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    className={classes.fullReportBtn}
                  >
                    <Button
                      className={classes.reportBtn}
                      startIcon={<ImageSearchOutlinedIcon />}
                      onClick={() => navigateToDetailsPage(item?.id)}
                      disabled={item?.status === 'Completed' ? false : true}
                    >
                      View Full Report
                    </Button>
                  </Grid>
                  <Grid item xs={12} className={classes.historyTime}>
                    <div
                      className={classes.historyText}
                      style={{ color: getStatusColor(item?.status) }}
                    >
                      <div>{item?.status || '-'}</div>
                      {String(item?.status).toLowerCase() === 'failed' && (
                        <div>{`${String(item?.statusmessage || '')}`}</div>
                      )}
                    </div>
                    <div className={classes.historyDate}>
                      {dateConvert(item?.createdat)}
                    </div>
                  </Grid>
                </Grid>
              ))}

            {historyData && historyData.length !== 0 && (
              <Grid container>
                <div className={classes.pagePaginationContainer}>
                  <Paper>
                    <TablePagination
                      classes={{
                        root: classes.tablePaginationRoot,
                        actions: classes.tablePaginationActions,
                      }}
                      SelectProps={{
                        title: 'perPageSelect',
                        native: true,
                      }}
                      component="div"
                      page={currentIndex}
                      rowsPerPage={contentPerPage}
                      count={totalCount}
                      onPageChange={onPageChange}
                      rowsPerPageOptions={[]}
                    />
                  </Paper>
                </div>
              </Grid>
            )}
          </div>
        </Content>
      </PageWithHeader>
    </>
  );
};
export default TestHistory;
