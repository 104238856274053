import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  makeStyles,
  FormControl,
  FormControlLabel,
  Checkbox,
  Theme,
} from '@material-ui/core';
import { useParams, useNavigate } from 'react-router-dom';
import { Content, Progress } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import BackButton from '../../../utils/backButton';
import { HelpComponent } from '../../../utils/helpComponent';
import HELP_URL from '../../../utils/helpLinkConstant';
import { adminApiRef } from '../../../../apis/adminApi';
import _ from 'lodash';
import { COST_AVOIDANCE_CONSTANTS } from '../constants';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';

// @ts-ignore
const useStyles = makeStyles((theme: Theme) => ({
  editArea: {
    padding: '20px !important',
    background: theme.palette.background.paper,
  },
  boldText: {
    fontWeight: '600',
  },
  formArea: {
    marginTop: '20px',
  },
  formField: {
    marginBottom: '20px',
  },
  actionArea: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
    ['@media (max-width:920px) and (min-width: 280px)']: {
      flexDirection: 'column',
      marginTop: '0',
    },
  },
  cancelBtn: {
    marginRight: '10px',
    ['@media (max-width:920px) and (min-width: 280px)']: {
      width: '100%',
    },
  },
  saveBtn: {
    ['@media (max-width:920px) and (min-width: 280px)']: {
      width: '100%',
      marginTop: '15px',
    },
  },
}));

export interface EditCAVarsValidationState {
  manHoursErrorFlag: boolean;
  manHoursErrorMessage: string;
  blendedRateErrorFlag: boolean;
  blendedRateErrorMessage: string;
  coefficientErrorFlag: boolean;
  coefficientErrorMessage: string;
  coefficient2ErrorFlag: boolean;
  coefficient2ErrorMessage: string;
}

const initialValidationState: EditCAVarsValidationState = {
  manHoursErrorFlag: false,
  manHoursErrorMessage: '',
  blendedRateErrorFlag: false,
  blendedRateErrorMessage: '',
  coefficientErrorFlag: false,
  coefficientErrorMessage: '',
  coefficient2ErrorFlag: false,
  coefficient2ErrorMessage: '',
};

const EditCAVariables = () => {
  const [manHours, setManHours] = useState<any>('');
  const [blendedRate, setBlendedRate] = useState<any>('');
  const [coefficient, setCoefficient] = useState<any>('');
  const [coefficient2, setCoefficient2] = useState<any>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [variableRecord, setVariableRecord] = useState<any>();
  const [validationState, setValidationState] =
    useState<EditCAVarsValidationState>({ ...initialValidationState });

  const { id } = useParams();
  const adminApi = useApi(adminApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    if (id && adminApi) {
      setLoading(true);
      adminApi
        .getCAVariableById(id)
        .then(data => {
          setManHours(data?.manhours);
          setBlendedRate(data?.blendedrate);
          setCoefficient(data?.coefficient);
          setCoefficient2(data?.coefficient2);
          setIsActive(data?.active);
          setValidationState({
            ...initialValidationState,
          });
          setVariableRecord(data);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          setValidationState({
            ...initialValidationState,
          });
          setVariableRecord({});
          notificationApi.sendNotification({
            message: err.message,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });
    }
  }, [id, adminApi]);

  const onClickCancel = (event: any) => {
    event?.stopPropagation();
    navigate('/admin/cost-avoidance/variables');
  };

  const validateManHoursField = () => {
    let errorFlag = false;
    let errorMessage = '';

    if (!manHours) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.MAN_HOURS_REQUIRED_MESSAGE;
    } else if (isNaN(+manHours)) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.MAN_HOURS_NOT_A_NUMBER_MESSAGE;
    } else if (+manHours < 1) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.MAN_HOURS_LESS_THAN_MIN_MESSAGE;
    } else if (+manHours > 10000) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.MAN_HOURS_MORE_THAN_MAX_MESSAGE;
    }
    return { errorFlag, errorMessage };
  };

  const validateBlendedRateField = () => {
    let errorFlag = false;
    let errorMessage = '';

    if (!blendedRate) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.BLENDED_RATE_REQUIRED_MESSAGE;
    } else if (isNaN(+blendedRate)) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.BLENDED_RATE_NOT_A_NUMBER_MESSAGE;
    } else if (+blendedRate < 1) {
      errorFlag = true;
      errorMessage =
        COST_AVOIDANCE_CONSTANTS.BLENDED_RATE_LESS_THAN_MIN_MESSAGE;
    } else if (+blendedRate > 200) {
      errorFlag = true;
      errorMessage =
        COST_AVOIDANCE_CONSTANTS.BLENDED_RATE_MORE_THAN_MAX_MESSAGE;
    }
    return { errorFlag, errorMessage };
  };

  const validateCoefficientField = () => {
    let errorFlag = false;
    let errorMessage = '';

    if (!coefficient) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.COEFFICIENT_REQUIRED_MESSAGE;
    } else if (isNaN(+coefficient)) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.COEFFICIENT_NOT_A_NUMBER_MESSAGE;
    } else if (+coefficient < 0.01) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.COEFFICIENT_LESS_THAN_MIN_MESSAGE;
    } else if (+coefficient > 2.5) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.COEFFICIENT_MORE_THAN_MAX_MESSAGE;
    }
    return { errorFlag, errorMessage };
  };
  const validateCoefficient2Field = () => {
    let errorFlag = false;
    let errorMessage = '';

    if (!coefficient2) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.COEFFICIENT2_REQUIRED_MESSAGE;
    } else if (isNaN(+coefficient)) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.COEFFICIENT_NOT_A_NUMBER_MESSAGE;
    } else if (+coefficient2 < 0.01) {
      errorFlag = true;
      errorMessage =
        COST_AVOIDANCE_CONSTANTS.COEFFICIENT2_LESS_THAN_MIN_MESSAGE;
    } else if (+coefficient2 > 2.5) {
      errorFlag = true;
      errorMessage =
        COST_AVOIDANCE_CONSTANTS.COEFFICIENT2_MORE_THAN_MAX_MESSAGE;
    }
    return { errorFlag, errorMessage };
  };

  const validateFields = () => {
    let newValidationState: EditCAVarsValidationState = { ...validationState };

    const manHoursResult = validateManHoursField();
    newValidationState.manHoursErrorFlag = manHoursResult.errorFlag;
    newValidationState.manHoursErrorMessage = manHoursResult.errorMessage;

    const blendedRateResult = validateBlendedRateField();
    newValidationState.blendedRateErrorFlag = blendedRateResult.errorFlag;
    newValidationState.blendedRateErrorMessage = blendedRateResult.errorMessage;

    const coefficientResults = validateCoefficientField();
    newValidationState.coefficientErrorFlag = coefficientResults.errorFlag;
    newValidationState.coefficientErrorMessage =
      coefficientResults.errorMessage;

    const coefficientResults2 = validateCoefficient2Field();
    newValidationState.coefficient2ErrorFlag = coefficientResults2.errorFlag;
    newValidationState.coefficient2ErrorMessage =
      coefficientResults2.errorMessage;

    setValidationState(newValidationState);
    return (
      !newValidationState.manHoursErrorFlag &&
      !newValidationState.blendedRateErrorFlag &&
      !newValidationState.coefficientErrorFlag &&
      !newValidationState.coefficient2ErrorFlag
    );
  };

  const onChangeIsActive = (event: any, checked: boolean) => {
    event?.preventDefault();
    setIsActive(checked);
  };

  const onClickSave = () => {
    if (validateFields()) {
      const data = {
        manhours: +manHours,
        blendedrate: +blendedRate,
        coefficient: +coefficient,
        coefficient2: +coefficient2,
        active: isActive,
      };
      setLoading(true);
      adminApi
        .updateCAVariable(id, data)
        .then((data: any) => {
          setLoading(false);
          if (data?.status == 200) {
            notificationApi.sendNotification({
              message: COST_AVOIDANCE_CONSTANTS.VARIABLE_UPDATE_SUCCESS_MESSAGE,
              disapperAfterMs: 2500,
              severity: 'success',
              callback: () => {
                navigate('/admin/cost-avoidance/variables');
              },
            });
          } else {
            notificationApi.sendNotification({
              message: data?.message,
              disapperAfterMs: 2500,
              severity: 'warning',
            });
          }
        })
        .catch(err => {
          notificationApi.sendNotification({
            message: err?.message,
            disapperAfterMs: 2500,
            severity: 'error',
          });
          setLoading(false);
        });
    }
  };

  const limitToIntNumbers = (event: any): number | undefined | string => {
    let value = event.target.value;
    if (value && !_.isEmpty(value)) {
      let newValue = value.replace(/[^0-9]/g, '');
      return newValue;
    }
    return '';
  };

  const limitToNumbers = (event: any): number | undefined | string => {
    let value = event.target.value;
    if (value && !_.isEmpty(value)) {
      let newValue = value.replace(/[^0-9.,_]/g, '');
      if (newValue.indexOf('.') !== -1) {
        let partAfterDecimal = newValue.substring(newValue.indexOf('.'));
        if (partAfterDecimal.length > 3) {
          newValue =
            newValue.substring(0, newValue.indexOf('.')) +
            partAfterDecimal.substring(0, 3);
        }
      }
      return newValue;
    }
    return '';
  };

  return (
    <div>
      <Grid item md={12} xs={12}>
        <BackButton />
        <HelpComponent helpUrl={HELP_URL.COST_AVOIDANCE_VARIABLES_UPDATE} />
      </Grid>
      {loading && <Progress />}
      <Content>
        <Grid container>
          <Grid item className={classes.editArea} xs={12} sm={12} md={6} lg={6}>
            <h2>Update Variables</h2>
            <p className={classes.boldText}>
              {variableRecord?.assetkind}{' '}
              {variableRecord && variableRecord?.assettype
                ? `( ${variableRecord?.assettype} ) `
                : ' '}
              {variableRecord && variableRecord?.complexity
                ? `, Complexity - ${variableRecord?.complexity}`
                : ''}
            </p>

            <section className={classes.formArea}>
              <div className={classes.formField}>
                <TextField
                  id="man-hours-tf"
                  data-testid="man-hours-tf"
                  label="Man Hours"
                  fullWidth
                  variant="outlined"
                  size="small"
                  required
                  value={manHours}
                  error={validationState.manHoursErrorFlag}
                  helperText={
                    validationState.manHoursErrorFlag
                      ? validationState.manHoursErrorMessage
                      : ''
                  }
                  onChange={event => {
                    const value = limitToIntNumbers(event);
                    setManHours(value);
                    setValidationState({
                      ...validationState,
                      manHoursErrorFlag: false,
                      manHoursErrorMessage: '',
                    });
                  }}
                ></TextField>
              </div>
              <div className={classes.formField}>
                <TextField
                  id="blended-rate-tf"
                  data-testid="blended-rate-tf"
                  label="Blended Rate"
                  fullWidth
                  variant="outlined"
                  size="small"
                  required
                  value={blendedRate}
                  error={validationState.blendedRateErrorFlag}
                  helperText={
                    validationState.blendedRateErrorFlag
                      ? validationState.blendedRateErrorMessage
                      : ''
                  }
                  onChange={event => {
                    const value = limitToIntNumbers(event);
                    setBlendedRate(value);
                    setValidationState({
                      ...validationState,
                      blendedRateErrorFlag: false,
                      blendedRateErrorMessage: '',
                    });
                  }}
                ></TextField>
              </div>
              <div className={classes.formField}>
                <TextField
                  id="coefficient-tf"
                  data-testid="coefficient-tf"
                  label="Coefficient"
                  fullWidth
                  variant="outlined"
                  size="small"
                  required
                  value={coefficient}
                  error={validationState.coefficientErrorFlag}
                  helperText={
                    validationState.coefficientErrorFlag
                      ? validationState.coefficientErrorMessage
                      : ''
                  }
                  onChange={event => {
                    const value = limitToNumbers(event);
                    setCoefficient(value);
                    setValidationState({
                      ...validationState,
                      coefficientErrorFlag: false,
                      coefficientErrorMessage: '',
                    });
                  }}
                ></TextField>
              </div>
              <div className={classes.formField}>
                <TextField
                  required
                  id="coefficient-tf2"
                  data-testid="coefficient-tf2"
                  label="SDC"
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={coefficient2}
                  error={validationState.coefficient2ErrorFlag}
                  helperText={
                    validationState.coefficient2ErrorFlag
                      ? validationState.coefficient2ErrorMessage
                      : ''
                  }
                  onChange={event => {
                    const value = limitToNumbers(event);
                    setCoefficient2(value);
                    setValidationState({
                      ...validationState,
                      coefficient2ErrorFlag: false,
                      coefficient2ErrorMessage: '',
                    });
                  }}
                ></TextField>
              </div>

              {/* <div className={classes.formField}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isActive}
                          onChange={onChangeIsActive}
                          name="active"
                          color="primary"
                        />
                      }
                      label="Active"
                    />
                  </FormControl>
                </div> */}
            </section>
            <section className={classes.actionArea}>
              <Button
                id="edit-vars-cancel-btn"
                data-testid="edit-vars-cancel-btn"
                variant="outlined"
                className={classes.cancelBtn}
                onClick={onClickCancel}
              >
                Cancel
              </Button>
              <Button
                id="edit-vars-save-btn"
                data-testid="edit-vars-save-btn"
                variant="contained"
                color="primary"
                className={classes.saveBtn}
                onClick={onClickSave}
              >
                Save
              </Button>
            </section>
          </Grid>
        </Grid>
      </Content>
    </div>
  );
};

export default EditCAVariables;
