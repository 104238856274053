import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const FolderZipIcon = () => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    fill="#000000"
  >
    <path d="M16.6665 4.99998H9.99984L8.33317 3.33331H3.33317C2.4165 3.33331 1.67484 4.08331 1.67484 4.99998L1.6665 15C1.6665 15.9166 2.4165 16.6666 3.33317 16.6666H16.6665C17.5832 16.6666 18.3332 15.9166 18.3332 15V6.66665C18.3332 5.74998 17.5832 4.99998 16.6665 4.99998ZM14.9998 9.99998H13.3332V11.6666H14.9998V13.3333H13.3332V15H11.6665V13.3333H13.3332V11.6666H11.6665V9.99998H13.3332V8.33331H11.6665V6.66665H13.3332V8.33331H14.9998V9.99998Z" />
  </SvgIcon>
);

export default FolderZipIcon;
