/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { swTemplateCustomApiRef } from '../../../apis/swTemplateCustomApi';
import { useEffect, useState } from 'react';

export const useGetAllLikes = (allNames: string[]) => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const swTemplateCustomApi = useApi(swTemplateCustomApiRef);

  const [allLikesCounts, setAllLikesCounts] = useState<any>(null);
  const [loaded, setLoaded] = useState(false);

  const fetchMyAPI = async () => {
    if (swTemplateCustomApi) {
      try {
        setLoaded(false);
        const response = await swTemplateCustomApi.getAllLikesCount(allNames);
        setAllLikesCounts(response.data);
        setLoaded(true);
      } catch (error) {
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };

  useEffect(() => {
    if (allNames?.length > 0) fetchMyAPI();
  }, [allNames]);

  return [allLikesCounts, loaded];
};
