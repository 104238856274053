/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import { NotificationApi, notificationApiRef } from '../apis/notificationApi';
import { additionalConfigApiRef } from '../apis/additionalConfigApi';
import { useEffect, useState } from 'react';

export interface IFrontendConfigData {
  authorization: string;
  apiVersion: string;
  chatBot: {
    show: string;
    botId: string;
  };
  applicationId: string;
  clientToken: string;
  isGamificationEnable: string;
  survey: {
    surveyInstance: string;
  };
  openai: {
    show: string;
  };
  snykOrgID: string;
  splitio: {
    authorizationKey: string;
  }
}

export interface IFrontendConfig {
  data: IFrontendConfigData;
  context: string;
  prefix: string;
  notifiedFilteredKeys: Object;
}

export const useFrontendConfig = (shouldcall: boolean) => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const additionalConfigApi = useApi(additionalConfigApiRef);
  const [frontendConfig, setFrontendConfig] = useState<IFrontendConfig | null>(
    null,
  );

  const fetchMyAPI = async () => {
    if (additionalConfigApi) {
      try {
        const response = await additionalConfigApi.getFrontEndConfig();
        setFrontendConfig(response?.frontendConfig);
      } catch (error) {
        setFrontendConfig(undefined);
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };

  useEffect(() => {
    if (shouldcall) fetchMyAPI();
  }, [shouldcall]);

  return [
    frontendConfig?.data,
    frontendConfig?.context,
    frontendConfig?.prefix,
    frontendConfig?.notifiedFilteredKeys
  ] as const;
};
