import React from 'react';
import {
  Box,
  Link,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { IUtilityTool } from '../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sidebarToolCard: {
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      height: '40px',
      alignItems: 'center',
      boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.14)',
      cursor: 'pointer',
    },
    sidebarToolIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    sidebarToolLabel: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
    },
    label: {
      flex: 1,
    },
  }),
);
export interface ISidebarToolNavProps {
  tool: IUtilityTool;
}

export const SidebarToolNav = ({ tool }: ISidebarToolNavProps) => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        p: 2,
        mb: 0.2,
        // @ts-expect-error
      }}
      className={classes.sidebarToolCard}
    >
      <Box
        sx={{
          mr: 1,
        }}
        className={classes.sidebarToolIcon}
      >
        {tool?.sidebarIcon}
      </Box>
      <Box className={classes.label}>
        <Typography className={classes.sidebarToolLabel}>
          <Link component={RouterLink} to={`${tool.path}`}>
            {tool?.label}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};
