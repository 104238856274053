import { useEffect, useState } from 'react';

export const useFilteredFeedbacks = (
  content: any,
  dropdownFilter: Object,
  filterText: string,
) => {
  const [filteredFeedbacks, setFilteredFeedbacks] = useState([]);
  const filterString = (str: string) => str.replace(/[^a-zA-Z0-9]/g, '');

  useEffect(() => {
    let localFeedbacks = [];

    const category =
      dropdownFilter.category === 'All categories'
        ? ''
        : dropdownFilter.category.toLowerCase();
    const status =
      dropdownFilter.status === 'All status'
        ? ''
        : dropdownFilter.status.toLowerCase();
    const filteredText =
      filterText === '' ? '' : filterString(filterText?.toLowerCase());

    console.log('category', category);
    console.log('status', status);

    localFeedbacks = content.filter((feedback: any) => {
      const feedbackCategory =
        feedback?.category && feedback?.category?.toLowerCase();
      const feedbackStatus = feedback?.status?.toLowerCase();
      //   const feedbackText = feedback?.feedback?.toLowerCase();

      const feedbackFieldsValues = Object.values(feedback);
      return (
        feedbackCategory?.includes(category) &&
        feedbackStatus?.includes(status) &&
        feedbackFieldsValues.some(
          value =>
            typeof value === 'string' &&
            filterString(value?.toLowerCase())?.includes(
              filteredText.toLowerCase(),
            ),
        )
      );
    });

    setFilteredFeedbacks(localFeedbacks);
  }, [content, dropdownFilter, filterText]);

  return { filteredFeedbacks };
};
