import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

type AIBotIconInputProps = {
  fill: string;
  stroke?: string;
  fontSize: 'inherit' | 'medium' | 'default' | 'large' | 'small' | undefined;
};

const AIBotIcon = ({ fontSize, fill, stroke }: AIBotIconInputProps) => {
  return (
    <SvgIcon
      viewBox="0 0 48 48"
      style={{ width: '1.27em', height: '1.27em' }}
      fontSize={fontSize}
    >
      <path
        d="M40 19V15C40 12.8 38.2 11 36 11H30C30 7.68 27.32 5 24 5C20.68 5 18 7.68 18 11H12C9.8 11 8 12.8 8 15V19C4.68 19 2 21.68 2 25C2 28.32 4.68 31 8 31V39C8 41.2 9.8 43 12 43H36C38.2 43 40 41.2 40 39V31C43.32 31 46 28.32 46 25C46 21.68 43.32 19 40 19ZM15 24C15 22.34 16.34 21 18 21C19.66 21 21 22.34 21 24C21 25.66 19.66 27 18 27C16.34 27 15 25.66 15 24ZM24 37.2C21 37.2 16 35.8476 16 33.1474C16 29.4 19.1176 33 24 33C28.8824 33 32 29.2526 32 33C32 35.7307 27 37.2 24 37.2ZM30 27C28.34 27 27 25.66 27 24C27 22.34 28.34 21 30 21C31.66 21 33 22.34 33 24C33 25.66 31.66 27 30 27Z"
        fill={fill}
        stroke={stroke}
      />
    </SvgIcon>
  );
};

export default AIBotIcon;
