import React from 'react';
import {
  IconButton,
  SvgIconProps,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      display: 'flex',
      alignItems: 'end',
    },
    countText: {
      fontWeight: 'bold',
      color: 'inherit',
    },
    button: {
      padding: '0px 0px 0px 5px',
      color: 'inherit',
    },
  }),
);

interface ToggleIconButtonProps {
  label?: string;
  isChecked: boolean;
  onClick: Function;
  outlineIcon: SvgIconProps;
  filledIcon: SvgIconProps;
  classes?: any;
  count?: number;
}

const ToggleIconButton = ({
  label,
  isChecked = false,
  onClick,
  outlineIcon,
  filledIcon,
  classes,
  count = undefined,
}: ToggleIconButtonProps) => {
  const myClasses = useStyles();

  return (
    <>
      <div className={`${myClasses.iconButton} ${classes.iconButton}`}>
        {count !== undefined ? (
          <div className={myClasses.countText}>{count}</div>
        ) : null}
        {isChecked ? (
          <IconButton
            aria-label={label}
            className={myClasses.button}
            onClick={() => {
              onClick();
            }}
          >
            {filledIcon}
          </IconButton>
        ) : (
          <IconButton
            aria-label={label}
            className={myClasses.button}
            onClick={() => {
              onClick();
            }}
          >
            {outlineIcon}
          </IconButton>
        )}
      </div>
    </>
  );
};

export default ToggleIconButton;
