/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';
import {
  Tag,
  TagsResponse,
  informationExchangeApiRef,
} from '../../../../apis/informationExchange';
import { useEffect, useState } from 'react';

export const useGetTags = (shouldcall: boolean) => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const informationExchangeApi = useApi(informationExchangeApiRef);

  const [tagObjects, setTagsObjects] = useState<Tag[] | []>([]);
  const [tags, setTags] = useState<String[] | []>([]);

  const fetchMyAPI = async () => {
    if (informationExchangeApi) {
      try {
        const data = await informationExchangeApi.getDefaultTags();
        if (data) {
          setTagsObjects(data.tags);
          setTags(data.tags.map(tag => tag.tag));
        }

        //  setIsLoading(false);
      } catch (error) {
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };

  useEffect(() => {
    if (shouldcall) fetchMyAPI();
  }, [shouldcall]);

  return [tags];
};
