import React, { useEffect, useState, useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Box,
  IconButton,
} from '@mui/material';
import { useApi } from '@backstage/core-plugin-api';
import CloseIcon from '@material-ui/icons/Close';
import { AuthContext } from '../../../../../providers/AuthProvider';
import { snykApiRef } from '../../../../../apis/snykApi';
import SnykDialogTable from './SnykDialogTable';
import { EmptyState } from '@backstage/core-components';
import { FormControl, InputLabel } from '@material-ui/core';

interface SnykDialogProps {
  open: boolean;
  onClose: () => void;
  branches: string[];
  snykVulnerabilities: any[];
  isOwner: boolean;
  snykOrgId: string;
}

const SnykDialog: React.FC<SnykDialogProps> = ({ open, onClose, branches, snykVulnerabilities, isOwner, snykOrgId }) => {
  const [selectedBranch, setSelectedBranch] = useState(branches[0]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [files, setFiles] = useState<any[]>([]);
  const { isAdmin } = useContext(AuthContext);
  const [vulnerabilities, setVulnerabilities] = useState<any[]>([]);
  const snykApi = useApi(snykApiRef);

  const handleBranchChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedBranch(event.target.value as string);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (branches.length > 0) {
      if (isAdmin || isOwner) {
        setSelectedBranch(branches[0]);
      } else {
        setSelectedBranch('default_branch');
      }
    }
  }, []);

  useEffect(() => {
    if (selectedBranch) {
      const fileList = snykVulnerabilities.filter((vulnerability) => vulnerability.attributes.target_reference === selectedBranch);
      if (fileList) {
        setFiles(fileList);
      }
    }
  }, [selectedBranch]);

  useEffect(() => {
    if (files.length > 0) {
      fetchVulnerabilities();
    }
  }, [selectedTab]);

  const fetchVulnerabilities = async () => {
    let response;
    try {
      response = await snykApi.ListAllAggregatedIssues(snykOrgId, files[selectedTab].id);
      if (response) {
        setVulnerabilities(response.data);
      }
    }
    catch (error) {
      console.log('Error fetching vulnerabilities', error);
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>
        Snyk Vulnerabilities
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '16px' }}>
          <FormControl variant="standard" style={{ width: '200px'}}>
            <InputLabel id='snykl-branch-select-label'>Branch</InputLabel>
            <Select value={selectedBranch} onChange={handleBranchChange} label='Branch' id='snykl-branch-select-label'>
              {branches.map((branch) => (
                <MenuItem key={`snyk-branch-${branch}`} value={branch}>
                  {branch}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Snyk tabs"
          style={{
            marginBottom: '8px',
            marginTop: '8px',
          }}>
          {files.map((file, index) => (
            <Tab key={`tab-${index}`} label={file.attributes.name.split(':')[1] || file.attributes.name.split(':')[0]} />
          ))}
        </Tabs>
        <Box p={3}>
          {files.map((file, index) => (
            <div
              key={`tabpanel-${index}`}
              role="tabpanel"
              hidden={selectedTab !== index}
              id={`tabpanel-${index}`}
              aria-labelledby={`tab-${index}`}
            >
              {selectedTab === index && (
                <Box>
                  {
                    vulnerabilities && vulnerabilities.length > 0 ? <SnykDialogTable
                      vulnerabilities={vulnerabilities}
                    /> : <EmptyState
                      missing="info"
                      title="No information to display"
                      description={`There is no information available from snyk ${selectedBranch ? `for branch ${selectedBranch}` : ''
                        }.`}
                    />
                  }
                </Box>
              )}
            </div>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SnykDialog;