import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const OpenDocumentIcon = ({ fill }: { fill?: string }) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M3 18H16V16H3V18ZM3 13H13V11H3V13ZM3 6V8H16V6H3ZM21 15.59L17.42 12L21 8.41L19.59 7L14.59 12L19.59 17L21 15.59Z"
      fill={fill}
    />
  </SvgIcon>
);

export default OpenDocumentIcon;
