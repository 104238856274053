const Constants = {
  promptTypes: {
    newPrompt: {
      value: 'newPrompt',
      text: 'New Prompt',
      color: '#D2785A',
    },
    existingPrompt: {
      value: 'existingPrompt',
      text: 'Similar Prompt',
      color: '#50AF78',
    },
  },
};

export default Constants;
