import React from 'react';

const Techdocs = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 20H20V22.5H10V20ZM10 15H20V17.5H10V15ZM17.5 2.5H7.5C6.125 2.5 5 3.625 5 5V25C5 26.375 6.1125 27.5 7.4875 27.5H22.5C23.875 27.5 25 26.375 25 25V10L17.5 2.5ZM22.5 25H7.5V5H16.25V11.25H22.5V25Z"
        fill="#17E88F"
      />
    </svg>
  );
};

export default Techdocs;
