import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  LinearProgress,
  Theme,
  useTheme,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import defaultImg from '../visual-regression/assest/default.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Content, PageWithHeader } from '@backstage/core-components';
import BreadcrumbsNav from '../common/BreadcrumbsNav/BreadcrumbsNav';
import BackButton from '../utils/backButton';
import { HelpComponent } from '../utils/helpComponent';
import HELP_URL from '../utils/helpLinkConstant';
import CollectionsOutlinedIcon from '@material-ui/icons/CollectionsOutlined';
import { useLocation, useParams } from 'react-router-dom';
import {
  VisualRegressionApi,
  visualRegressionApiRef,
} from '../../apis/visualRegression';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';
import { useApi } from '@backstage/core-plugin-api';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useStyles } from './common/styles';

const DetailsPage = (props: any) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const { state } = useLocation();
  let { id } = useParams();

  const [inProgress, setInProgress] = useState(true);
  const [data, setData] = useState({});
  const [renderImg, setRenderImg] = useState(defaultImg);
  const [isImagesRender, setImagesRender] = useState(true);
  const [selectedBtn, setSelectedBtn] = useState('diff');

  const visualRegressionApi: VisualRegressionApi = useApi(
    visualRegressionApiRef,
  );
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  useEffect(() => {
    const payloadItem = id + '?filter=summary';
    visualRegressionApi
      .getVisualRegressionDetails(payloadItem)
      .then(res => {
        setData(res?.data);
        getRenderImages('diff');
        setInProgress(false);
        // if (res?.data?.diffBase64Code && res?.data?.diffBase64Code != '') {
        //   setRenderImg(res?.data?.diffBase64Code);
        //   setInProgress(false);
        // }
      })
      .catch((err: any) => {
        setInProgress(false);
        notificationApi.sendNotification({
          message: err.message,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  }, []);

  const getRenderImages = (filtername = 'diff') => {
    setImagesRender(true);
    const payload = id + '?filter=' + filtername;
    visualRegressionApi
      .getVisualRegressionDetails(payload)
      .then(res => {
        // setData(res?.data);
        // if (res?.data?.diffBase64Code && res?.data?.diffBase64Code != '') {
        setRenderImg(
          res?.data?.diffBase64Code ||
            res?.data?.referBase64Code ||
            res?.data?.testBase64Code,
        );
        setImagesRender(false);
        //}
      })
      .catch((err: any) => {
        setImagesRender(false);
        notificationApi.sendNotification({
          message: err.message,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  const onClickGroupBtn = (name: any) => {
    setSelectedBtn(name);
    getRenderImages(name);
  };

  const onDownLoad = (name: any) => {
    if (name) {
      let url = data?.diffBase64Code;
      if (name === 'Test') {
        url = data?.testBase64Code;
      } else if (name === 'Reference') {
        url = data?.referBase64Code;
      }

      let xhr = new XMLHttpRequest();
      xhr.open('GET', renderImg, true);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(this?.response);
        const tag = document.createElement('a');
        tag.href = imageUrl;
        tag.download = selectedBtn;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      };
      xhr.send();
    }
  };

  const getStatusColor = (status: any) => {
    switch (status?.toLowerCase()) {
      case 'completed':
        return theme.palette.success.main;
      case 'in progress':
        return theme.palette.infoText;
      case 'failed':
        return theme.palette.errorText;
      default:
        return theme.palette.text.tertiary;
    }
  };

  return (
    <>
      <PageWithHeader
        title="Visual Regression Testing "
        themeId="home"
        subtitle={<BreadcrumbsNav lastRoutes={[]} />}
      >
        <Content>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <BackButton />
              <HelpComponent helpUrl={HELP_URL.VISUAL_REGRESSION} />
            </Grid>
          </Grid>
          {inProgress && <LinearProgress />}
          <div className={`codePage ${classes.visualCodePage}`}>
            <Grid container className={classes.historyCard}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className={classes.product}>Transaction ID</div>
                <div className={classes.productInfo}>{data?.id || '-'}</div>
              </Grid>

              <Grid item xs={12} sm={12} md={5} lg={5}>
                <Grid>
                  <div className={classes.product}>Product Name</div>
                  <div className={classes.productInfo}>
                    {data?.productname || '-'}
                  </div>
                </Grid>
                <Grid>
                  <div className={classes.product}>Reference URL</div>
                  <div className={classes.productInfo}>
                    {data?.referenceurl || '-'}
                  </div>
                </Grid>
                <Grid>
                  <div className={classes.product}>Reference Environment</div>
                  <div className={classes.productInfo}>
                    {' '}
                    {data?.referenceenv || '-'}
                  </div>
                </Grid>

                <Grid className={classes.time}>
                  <div
                    className={classes.text}
                    style={{ color: getStatusColor(data?.status) }}
                  >
                    {data?.status || '-'}
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid>
                  <div className={classes.product}>Difference</div>
                  <div className={classes.productInfo}>
                    {data?.diffAmount ? data?.diffAmount + '%' : '-'}
                  </div>
                </Grid>
                <Grid>
                  <div className={classes.product}>Test URL</div>
                  <div className={classes.productInfo}>
                    {data?.testurl || '-'}
                  </div>
                </Grid>
                <Grid>
                  <div className={classes.product}>Test Environment</div>
                  <div className={classes.productInfo}>
                    {' '}
                    {data?.testenv || '-'}
                  </div>
                </Grid>
              </Grid>
              {/* <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className={classes.buttons}
              >
                <Button
                  className={classes.downloadBtns}
                  startIcon={<CollectionsOutlinedIcon />}
                  onClick={e => onDownLoad('Reference')}
                  name="Reference"
                >
                  Download Reference
                </Button>
                <Button
                  className={classes.downloadBtns}
                  startIcon={<CollectionsOutlinedIcon />}
                  onClick={e => onDownLoad('Test')}
                  name="Test"
                >
                  Download Test
                </Button>
                <Button
                  className={classes.downloadBtns}
                  startIcon={<CollectionsOutlinedIcon />}
                  onClick={e => onDownLoad('Difference')}
                  name="Difference"
                >
                  Download Difference
                </Button>
              </Grid> */}
            </Grid>

            <Grid container>
              <div className={classes.viewHeading}>
                <Typography> Rendered View</Typography>

                <ButtonGroup
                  color="primary"
                  aria-label="outlined primary button group"
                >
                  <Button
                    disabled={isImagesRender}
                    onClick={e => onClickGroupBtn('diff')}
                    className={selectedBtn === 'diff' ? classes.active : ''}
                  >
                    Difference
                  </Button>
                  <Button
                    disabled={isImagesRender}
                    onClick={e => onClickGroupBtn('ref')}
                    className={selectedBtn === 'ref' ? classes.active : ''}
                  >
                    Reference
                  </Button>
                  <Button
                    disabled={isImagesRender}
                    onClick={e => onClickGroupBtn('test')}
                    className={selectedBtn === 'test' ? classes.active : ''}
                  >
                    Test
                  </Button>
                </ButtonGroup>
              </div>
              <div className={classes.renderImgAreaDiv}>
                <Button
                  className={classes.downloadBtns}
                  startIcon={<GetAppIcon />}
                  onClick={e => onDownLoad('Difference')}
                  name="Difference"
                  variant="text"
                  disabled={isImagesRender}
                >
                  Download Image
                </Button>
              </div>
              {isImagesRender ? (
                <div className={classes.imgloader}>
                  <CircularProgress /> Loading{' '}
                </div>
              ) : (
                <img
                  className={classes.renderImg}
                  src={`${renderImg}`}
                  alt={'visual regression'}
                  title="visual regression"
                />
              )}
            </Grid>
          </div>
        </Content>
      </PageWithHeader>
    </>
  );
};
export default DetailsPage;
