import React from 'react';

const Rating = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 2.5H5C3.625 2.5 2.5 3.625 2.5 5V27.5L7.5 22.5H25C26.375 22.5 27.5 21.375 27.5 20V5C27.5 3.625 26.375 2.5 25 2.5ZM25 20H6.4625L5 21.4625V5H25V20Z"
        fill="#17E88F"
      />
      <path
        d="M15 18.75L16.9625 14.4625L21.25 12.5L16.9625 10.5375L15 6.25L13.0375 10.5375L8.75 12.5L13.0375 14.4625L15 18.75Z"
        fill="#17E88F"
      />
    </svg>
  );
};

export default Rating;
