import React, { useState, useEffect } from 'react';
import { Entity } from '@backstage/catalog-model';
import { ItemCardGrid } from '@backstage/core-components';
import { Paper, TablePagination } from '@material-ui/core';
import ExtDocCard from './ExtDocCard';
import EXT_DOCS_CONSTANTS from '../../Constants';
import { useCustomStyles } from '../DocStyles';

const ExtDocCardGrid = ({
  filterText,
  filterKey,
  docsData,
  promptUserForConfirmation,
  anchorElement,
  setAnchorElement,
  showCardItemMenu,
  isAdmin,
  onDocumentEditBtnClicked,
}: {
  filterText: string;
  filterKey: string;
  docsData: Entity[] | any[];
  promptUserForConfirmation: any;
  anchorElement: any;
  setAnchorElement: any;
  showCardItemMenu: boolean;
  isAdmin: boolean | null;
  onDocumentEditBtnClicked: (str: string) => void;
}) => {
  const classes = useCustomStyles();
  const [filteredDocsData, setFilteredDocsData] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState('');
  const [selectedDocumentUser, setSelectedDocumentUser] = useState('');

  const [_paginatePageNumber, setPaginatePageNumber] = useState(0);
  const [_paginateRowsPerPage, setPaginateRowsPerPage] = useState(10);
  const [_paginateCount, setPaginateCount] = useState(0);
  const [_matchArray, setMatchArray] = useState([]);

  useEffect(() => {
    setFilteredDocsData(docsData || []);
    setPaginateCount(docsData?.length);
    setPaginatePageNumber(0);
  }, [docsData]);

  useEffect(() => {
    if (filterKey && filterText) {
      let count = _matchArray?.length;
      if (count >= 0) {
        if (docsData && docsData?.length > 0) {
          let startIndex = _paginatePageNumber * _paginateRowsPerPage;
          let endIndex = -1;
          if (startIndex + _paginateRowsPerPage < count) {
            endIndex = startIndex + _paginateRowsPerPage;
          } else {
            endIndex = count;
          }
          setFilteredDocsData(_matchArray?.slice(startIndex, endIndex));
        }
      }
    } else if (_paginateCount >= 0) {
      if (docsData && docsData?.length > 0) {
        let startIndex = _paginatePageNumber * _paginateRowsPerPage;
        let endIndex = -1;
        if (startIndex + _paginateRowsPerPage < _paginateCount) {
          endIndex = startIndex + _paginateRowsPerPage;
        } else {
          endIndex = _paginateCount;
        }
        setFilteredDocsData(docsData?.slice(startIndex, endIndex));
      }
    }
  }, [
    _paginatePageNumber,
    _paginateRowsPerPage,
    _paginateCount,
    filterKey,
    filterText,
  ]);

  useEffect(() => {
    if (!filterText || filterText === '') {
      let count = docsData.length;
      if (docsData && count > 0) {
        let startIndex = _paginatePageNumber * _paginateRowsPerPage;
        let endIndex = -1;
        if (startIndex + _paginateRowsPerPage < count) {
          endIndex = startIndex + _paginateRowsPerPage;
        } else {
          endIndex = count;
        }
        setFilteredDocsData(docsData?.slice(startIndex, endIndex));
      }
      setPaginateCount(docsData?.length);
      setPaginatePageNumber(0);
      setPaginateRowsPerPage(10);
      return;
    }
    if (filterKey && filterText) {
      if (docsData && docsData?.length > 0) {
        let newArr = docsData.filter((item: Entity): boolean => {
          if (filterKey === 'title') {
            return item?.metadata?.name
              ?.toLowerCase()
              .includes(filterText?.toLowerCase());
          } else if (filterKey === 'url') {
            return `${item?.metadata?.externalDocumentLink}`
              .toLowerCase()
              .includes(filterText?.toLowerCase());
          } else if (filterKey === 'description') {
            return `${item?.metadata?.description}`
              .toLowerCase()
              .includes(filterText?.toLowerCase());
          } else if (filterKey === 'user') {
            return `${item?.spec?.owner}`
              .toLowerCase()
              .includes(filterText?.toLowerCase());
          } else if (filterKey === 'all') {
            return (
              item?.metadata?.name
                ?.toLowerCase()
                .includes(filterText?.toLowerCase()) ||
              `${item?.metadata?.externalDocumentLink}`
                .toLowerCase()
                .includes(filterText?.toLowerCase()) ||
              `${item?.metadata?.description}`
                .toLowerCase()
                .includes(filterText?.toLowerCase()) ||
              `${item?.spec?.owner}`
                .toLowerCase()
                .includes(filterText?.toLowerCase())
            );
          }
          return false;
        });
        setMatchArray(newArr);
        setPaginateCount(newArr?.length);
        setPaginatePageNumber(0);
      }
    }
  }, [filterKey, filterText]);

  const onPageChange = (
    event: React.SyntheticEvent | null,
    pageNumber: number,
  ): void => {
    if (event) {
      event.preventDefault();
    }
    setPaginatePageNumber(pageNumber);
  };

  const onRowsPerPageChange = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    /*
    // @ts-ignore */
    setPaginateRowsPerPage(event?.target?.value);
    setPaginatePageNumber(0);
  };

  const onDeleteDocsClicked = () => {
    promptUserForConfirmation(selectedDocument);
    setSelectedDocument('');
  };

  const onClickEdit = () => {
    onDocumentEditBtnClicked(selectedDocument);
    setSelectedDocument('');
  };

  return (
    <div>
      {filteredDocsData && filteredDocsData?.length ? (
        <>
          <ItemCardGrid style={{ marginTop: '10px' }}>
            {filteredDocsData &&
              filteredDocsData.map(
                (doc: Entity, index: number): React.ReactElement => (
                  <ExtDocCard
                    key={index}
                    isAdmin={isAdmin}
                    anchorElement={anchorElement}
                    setAnchorElement={setAnchorElement}
                    doc={{ ...doc }}
                    showCardItemMenu={showCardItemMenu}
                    setSelectedDocument={setSelectedDocument}
                    onDeleteDocsClicked={onDeleteDocsClicked}
                    onClickEdit={onClickEdit}
                    setSelectedDocumentUser={setSelectedDocumentUser}
                    selectedDocumentUser={selectedDocumentUser}
                  />
                ),
              )}
          </ItemCardGrid>
          <div className={classes.DocsPagination}>
            <Paper>
              <TablePagination
                classes={{
                  root: classes.tablePaginationRoot,
                  actions: classes.tablePaginationActions,
                }}
                SelectProps={{
                  title: 'perPageSelect',
                  native: true,
                }}
                component="div"
                page={_paginatePageNumber}
                rowsPerPage={_paginateRowsPerPage}
                count={_paginateCount}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            </Paper>
          </div>
        </>
      ) : (
        <div className={classes.docsMargin}>
          {EXT_DOCS_CONSTANTS.externalDocsNoMatch}
        </div>
      )}
    </div>
  );
};
export default ExtDocCardGrid;
