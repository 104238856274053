import { useEffect, useState } from 'react';

export const useFilteredStatus = (feedbacks: any) => {
  const [status, setStatus] = useState<any>([]);

  useEffect(() => {
    if (feedbacks && feedbacks.length > 0) {
      setStatus([
        'All status',
        ...new Set(feedbacks.map((feedback: any) => feedback?.status)),
      ]);
    }
  }, [feedbacks]);
  return { status };
};
