const videoBytesConfig = {
  // samepagePaths: {
  //   '/catalog/discover': '/catalog',
  //   '/information-exchange/conversations': '/information-exchange',
  // },
  // pages: {
  //   '/catalog': {
  //     title: 'Home Help Video',
  //     videoLink: 'HomePage.mp4',
  //   },
  //   '/information-exchange': {
  //     title: 'Information Exchange Help Video',
  //     videoLink: 'InformationExchange.mp4',
  //   },
  //   '/software-templates': {
  //     title: 'Software Templates Help Video',
  //     videoLink: 'SoftwareTemplates.mp4',
  //   },
  // },
};

export default videoBytesConfig;
