/* It is always preffered not to use many ids in react.
but in case id for an element is required, please use this as Common Place to keep ids */

export const discoverIds = {
  recentDiscussions: 'recentDiscussions',
  toolboxRecent: 'toolboxRecent',
};

export const sidebarIds = {
  sideNavInformationExchange: 'sideNavInformationExchange',
  sideNavServicetools: 'sideNavServicetools',
};

const idSelectors: any = {
  ...discoverIds,
  ...sidebarIds,
};

export default idSelectors;
