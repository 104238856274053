import React from 'react';
import { Button, IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { DELETE_TYPES } from '../Constants';

export interface ConfirmSubTreeDeleteProps {
  selectedEntity: any;
  onClose: (event: any, action?: any) => void;
}

const ConfirmSubTreeDelete = ({
  selectedEntity,
  onClose,
}: ConfirmSubTreeDeleteProps) => {
  return (
    <div>
      <section style={{ position: 'relative' }}>
        <h3>Are you sure ?</h3>
        <section style={{ position: 'absolute', top: '-20px', right: '0px' }}>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </section>
      </section>
      <p>
        to delete Capability &apos; {selectedEntity?.metadata?.name} &apos;, and
        complete hierarchy of capabilities under this capability.
      </p>
      <div>
        <section style={{ display: 'flex' }}>
          <section style={{ marginRight: '10px' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                onClose(DELETE_TYPES.DELETE_AND_DELETE_COMPLETE_SUBTREE)
              }
              id="yes-btn-confirm-delete"
            >
              Yes
            </Button>
          </section>
          <section>
            <Button variant="contained" onClick={onClose}>
              No
            </Button>
          </section>
        </section>
      </div>
    </div>
  );
};
export default ConfirmSubTreeDelete;
