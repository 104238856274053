import React from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Link,
  makeStyles,
  Paper,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { LinkButton, MarkdownContent } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';
import { ItemCardHeader } from '../ItemCardHeader/ItemCardHeader';
import { selectedTemplateRouteRef } from '../../Routes';
import { Entity } from '@backstage/catalog-model';
import { JsonObject, JsonArray } from '@backstage/types';

const useStyles = makeStyles(theme => ({
  cardHeader: {
    position: 'relative',
  },
  title: {
    backgroundImage: ({ backgroundImage }: any) => backgroundImage,
  },
  cardImage: {
    backgroundColor: '#003f2d',
    '& img[class*="templateLogoImages"]': {
      height: 'auto',
      maxHeight: 'none',
    },
  },
  box: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 10,
    '-webkit-box-orient': 'vertical',
    paddingBottom: '0.8em',
    background: 'white',
  },
  paper: {
    padding: '8px',
    marginLeft: '5px'
  },
  horizontalViewMargin: {
    marginBottom: '12px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px',
    },
  },
  label: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontSize: '0.65rem',
    fontWeight: 'bold',
    letterSpacing: 0.5,
    lineHeight: 1,
    paddingBottom: '0.2rem',
  },
  labelBrowser: {
    marginBottom: '8px',
    fontSize: '0.5rem'
  },
  leftButton: {
    marginRight: 'auto',
  },
  starButton: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    zIndex: 10,
  },
  CardContentBgColor: {
    display: 'flex',
    // background: '#ffffff',
    flexWrap: 'wrap',
    alignContent: 'space-around',
    padding: '16px',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'column',
    wordBreak: 'break-word',
  },
  CardActionAreaStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid #efefef',
  },
  flex: {
    display: 'flex',
    flexDirection: 'row'
  },
  box: {
    width: '100%'
  }
}));

const useDeprecationStyles = makeStyles(theme => ({
  deprecationIcon: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(3.5),
    padding: '0.25rem',
  },
  link: {
    color: theme.palette.warning.light,
  },
}));

export type TemplateCardProps = {
  template: Entity;
  deprecated?: boolean;
  hideChooseButton?: boolean;
  horizontalView?: boolean;
  likesCount?: number;
  onLoadCall?: boolean;
};

type TemplateProps = {
  description: string;
  tags: string[];
  title: string;
  type: string | number | boolean | JsonObject | JsonArray;
  name: string;
};

const getTemplateCardProps = (
  template: Entity,
): TemplateProps & { key: string } => {
  return {
    key: template.metadata.uid!,
    name: template.metadata.name,
    title: `${(template.metadata.title || template.metadata.name) ?? ''}`,
    type: template?.spec?.type ?? '',
    description: template.metadata.description ?? '-',
    tags: (template.metadata?.tags as string[]) ?? [],
  };
};

const DeprecationWarning = () => {
  const styles = useDeprecationStyles();

  const Title = (
    <Typography style={{ padding: 10, maxWidth: 300 }}>
      This template uses a syntax that has been deprecated, and should be
      migrated to a newer syntax. Click for more info.
    </Typography>
  );

  return (
    <div className={styles.deprecationIcon}>
      <Tooltip title={Title}>
        <Link
          href="https://backstage.io/docs/features/software-templates/migrating-from-v1beta2-to-v1beta3"
          className={styles.link}
        >
          <WarningIcon />
        </Link>
      </Tooltip>
    </div>
  );
};

const TEXT_LIMIT = 200;

export const TemplateCard = ({
  template,
  deprecated,
  hideChooseButton,
  horizontalView = false,
  likesCount = 0,
  onLoadCall = false,
}: TemplateCardProps) => {
  const backstageTheme = useTheme<Theme>();
  const isScreenBiggerThanMobile = useMediaQuery<Theme>(theme =>
    theme.breakpoints.up('md'),
  );
  const templateRoute = useRouteRef(selectedTemplateRouteRef);
  const templateProps = getTemplateCardProps(template);
  const themeId = backstageTheme.getPageTheme({
    themeId: `${templateProps.type}`,
  })
    ? `${templateProps.type}`
    : 'other';
  const theme = backstageTheme.getPageTheme({ themeId });
  const classes = useStyles({ backgroundImage: theme.backgroundImage });
  const href = templateRoute({ templateName: templateProps.name });

  const descriptionText =
    !hideChooseButton && templateProps?.description?.length > TEXT_LIMIT
      ? `${templateProps?.description?.substring(0, TEXT_LIMIT)}...`
      : templateProps?.description || '';

  return (
    <>
      {
        (isScreenBiggerThanMobile && horizontalView) ? <Grid item xs={12} className={`${classes.flex} ${classes.horizontalViewMargin}`}>
          <Grid item xs={5} className={classes.cardImage}>
            <ItemCardHeader
              subtitle={`${templateProps.type}`}
              image={templateProps.key}
              template={template}
              likesCount={likesCount}
              onLoadCall={onLoadCall}
            />
          </Grid>
          <Grid item xs={7} className={classes.cardDescription}>
            <Paper className={classes.paper}>
          <Box>
            <Typography variant="h6">{templateProps.title}</Typography>
            <MarkdownContent content={descriptionText || ''} />
          </Box>
          {
            horizontalView && template.spec?.owneremail && <Box>
              <Typography variant="h6">Owner</Typography>
              <Typography
                onClick={() => {
                  // @ts-ignore
                  window.location = `mailto:${template.spec?.owneremail}`
                }}
                style={{
                  cursor: 'pointer',
                  marginBottom: '8px'
                }}
              >
                {template.spec?.owneremail}
              </Typography>
            </Box>
          }
          <Box>
            <Typography variant="body2" className={`${classes.label} ${classes.labelBrowser}`}>
              Dependencies
            </Typography>
            {templateProps.tags?.map(tag => (
              <Chip
                size="small"
                label={tag}
                key={tag}
                color="primary"
                variant="outlined"
              />
            ))}
          </Box>
          </Paper>
          </Grid>
        </Grid> : <Card>
        <CardMedia className={classes.cardHeader}>
          {deprecated && <DeprecationWarning />}
          <ItemCardHeader
            subtitle={`${templateProps.type}`}
            image={templateProps.key}
            template={template}
            likesCount={likesCount}
            onLoadCall={onLoadCall}
          />
        </CardMedia>
        <CardContent className={classes.CardContentBgColor}>
          <Box className={classes.box}>
            <Typography variant="h6">{templateProps.title}</Typography>
            <MarkdownContent content={descriptionText || ''} />
          </Box>

          {
            horizontalView && template.spec?.owneremail && <Box>
              <Typography variant="h6">Owner</Typography>
              <Typography
                onClick={() => {
                  // @ts-ignore
                  window.location = `mailto:${template.spec?.owneremail}`
                }}
                style={{
                  cursor: 'pointer',
                  marginBottom: '8px'
                }}
              >
                {template.spec?.owneremail}
              </Typography>
            </Box>
          }
  
          <Box>
            <Typography variant="body2" className={classes.label}>
              Dependencies
            </Typography>
            {templateProps.tags?.map(tag => (
              <Chip
                size="small"
                label={tag}
                key={tag}
                color="primary"
                variant="outlined"
              />
            ))}
          </Box>
        </CardContent>
  
        {!hideChooseButton && (
          <CardActionArea className={classes.CardActionAreaStyle}>
            <LinkButton
              color="primary"
              to={href}
              aria-label={`Choose ${templateProps.title}`}
            >
              Choose
            </LinkButton>
          </CardActionArea>
        )}
      </Card>
      }
    </>
  );
};
