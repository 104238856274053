import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useStyles } from '../styles';
import { IPromptData } from '../interfaces';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import EventIcon from '@material-ui/icons/Event';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { DateTime } from 'luxon';
import TextTruncate from 'react-text-truncate';
import ContentCopy from '@material-ui/icons/ContentCopy';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

interface PromptCardProps {
  promptData: IPromptData;
  onEdit: Function;
  onDelete: Function;
  actionButton: null | React.ReactNode;
  onTryoutClick: Function;
}

const PromptCard = ({
  promptData,
  onEdit,
  onDelete,
  actionButton = null,
  onTryoutClick,
}: PromptCardProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleTryNowClick = () => {
    onTryoutClick(promptData);
  };

  const [copyTooltip, setCopyTooltip] = useState('Copy to clipboard');

  const copyToClipboard = (data: string) => {
    navigator.clipboard.writeText(data);
    // TODO: handle success and error
  };

  return (
    <Card className={classes.promptCard}>
      <CardContent className={classes.cardContent}>
        <div className={`${classes.flexSpacebetween} ${classes.flexCenter}`}>
          <div className={classes.mainHeader}>
            <TextTruncate
              containerClassName={classes.key}
              text={promptData.promptkey}
              line={1}
            />
            <div className={classes.subHeader}>
              <Box display="flex" alignItems="center" gridGap={4}>
                <PermIdentityIcon fontSize="small" />
                <span>{promptData?.authordisplayname || 'Author Name'}</span>
              </Box>
              <Box display="flex" alignItems="center" gridGap={4}>
                <EventIcon fontSize="small" />
                <span>
                  {DateTime.fromFormat(
                    promptData?.createdat,
                    'dd-MM-yyyy HH:mm:ss',
                    { zone: 'utc' },
                  )
                    .toLocal()
                    .toFormat('dd MMM yyyy HH:mm')}
                </span>
                {(promptData.status === 'Rejected' ||
                  promptData.status === 'Resubmit') && (
                  <>
                    <FiberManualRecordIcon fontSize="small" />
                    <span>{promptData.status}</span>
                  </>
                )}
              </Box>
            </div>
          </div>

          <div className={`${classes.flex} ${classes.gap2}`}>
            <Button
              variant="contained"
              className={`${classes.primaryButton8Percent} ${classes.fontBold}`}
              onClick={() => {
                handleTryNowClick();
              }}
            >
              Try prompt
            </Button>
            <Tooltip title={copyTooltip} placement="bottom">
              <IconButton
                className={`${classes.primaryButton8Percent} ${classes.borderRadius1} ${classes.padding1}`}
                onClick={() => {
                  copyToClipboard(
                    String(promptData.promptdetail).replace(/\n$/, ''),
                  );
                  setCopyTooltip('Copied');
                }}
                size="small"
                onMouseEnter={() => setCopyTooltip('Copy to clipboard')}
              >
                <ContentCopy />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div className={classes.descriptionContainer}>
          <TextTruncate
            containerClassName={classes.description}
            text={promptData.promptdetail}
            line={isMobile ? 2 : 4}
          />
        </div>
      </CardContent>
      <CardActions className={classes.actionSection}>
        <div className={classes.chipContatiner}>
          {promptData.tags.map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              size="small"
              className={classes.chip}
            />
          ))}
        </div>
        {actionButton ? (
          <div>{actionButton}</div>
        ) : (
          <div className="card-buttons">
            <Tooltip title="Edit">
              <IconButton onClick={() => onEdit(promptData)}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton onClick={() => onDelete()}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </CardActions>
    </Card>
  );
};

export default PromptCard;
