import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { customTypes } from '../../extensions';
import { useLocation } from 'react-router-dom';

/**
 * SourceLocationPicker
 */
export const SourceLocationPicker = (
  props: customTypes.FieldExtensionComponentProps<string>,
) => {
  const {
    onChange,
    required,
    schema: {
      title = 'Current user',
      description = 'Currently Logged In user',
    },
    rawErrors,
    formData,
    uiSchema: { 'ui:autofocus': autoFocus },
    idSchema,
    placeholder,
  } = props;

  const { state } = useLocation();

  useEffect(() => {
    const sourceLocation =
      state?.data?.metadata?.annotations['backstage.io/source-location'];
    const sourceLocationPath = sourceLocation.substring(4);
    setTimeout(() => {
      onChange(sourceLocationPath);
    }, 100);
  }, [state]);

  return (
    <TextField
      id={idSchema?.$id}
      hidden={true}
      style={{ display: 'none' }}
      label={title}
      placeholder={placeholder}
      helperText={description}
      required={required}
      value={formData || ''}
      onChange={({ target: { value } }) => onChange(value)}
      margin="normal"
      error={rawErrors?.length > 0 && !formData}
      inputProps={{ autoFocus }}
    />
  );
};
