export function addHttpOnLinks(links) {
  const localLinks = links;
  if (links) {
    for (const link in localLinks) {
      if (
        localLinks[link]?.url.search('https://') === -1 &&
        localLinks[link]?.url.search('http://') === -1
      ) {
        localLinks[link] = {
          url: `http://${localLinks[link]?.url}`,
          title: localLinks[link]?.title,
          icon: localLinks[link]?.icon,
        };
      }
    }

    return localLinks;
  }
  return {};
}
