import React, { useEffect, useRef, useState } from 'react';
import { Entity, RELATION_OWNED_BY } from '@backstage/catalog-model';
import {
  EntityRefLinks,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import { Chip, Grid, Theme, createStyles, makeStyles } from '@material-ui/core';
import { AboutField } from '../../AboutField';
import { Link } from 'react-router-dom';
import { getPathToViewTaxonomyPage } from '../../../../utils/DevXUtils';
import { timeSince } from '../../../../utils/dateUtils';
import ExpandableContent from '../../../../common/ExpandableContent';
import { TowerInfoApi, towerInfoApiRef } from '../../../../../apis/towerInfo';
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../../apis/notificationApi';
import { getUserName } from '../../../../home/allAssets/util';
import { userProfileApiRef } from '../../../../../apis/userProfileApi';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    links: {
      '& a': {
        fontFamily: 'Calibre-R-Regular',
        color: theme.palette.primary.main,
        fontSize: '11px',
        fontWeight: 600,
      },
    },
    taxonomyStyle: {
      marginBottom: '0px',
      margin: '2px',
      height: '20px',
      cursor: 'pointer',
    },
    tagsStyle: {
      marginBottom: '0px',
      margin: '2px',
      height: '20px',
    },
  }),
);

type Props = {
  entity: Entity;
};

export const AboutContent = ({ entity }: Props) => {
  const [towerData, setToweInfo] = useState('');

  const towerInfo: TowerInfoApi = useApi(towerInfoApiRef);
  const usernameRef = useRef(null);
  const userProfileApi = useApi(userProfileApiRef);

  const notificationApi: NotificationApi = useApi(notificationApiRef);

  useEffect(() => {
    towerInfo
      .getTowerInfo(entity?.metadata?.name)
      .then((data: any) => {
        setToweInfo(data);
      })
      .catch(() => {
        notificationApi.sendNotification({
          message: `Failed to load Data`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  }, []);

  const classes = useStyles();
  const isSystem = entity.kind.toLocaleLowerCase('en-US') === 'system';
  const isDomain = entity.kind.toLocaleLowerCase('en-US') === 'domain';
  const isResource = entity.kind.toLocaleLowerCase('en-US') === 'resource';
  const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);
  const supportEmail = entity?.spec?.supportEmail;

  const renderAssets = () => {
    const memberRelations: any[] = [];
    const relations = entity?.relations;

    if (relations && relations?.length > 0) {
      relations.forEach((rel: any) => {
        if (rel && rel.type === 'memberOf') {
          memberRelations.push(rel?.target);
        }
      });
    }

    return (
      <>
        {memberRelations &&
          memberRelations?.map((rel: any) => {
            return (
              <Link to={getPathToViewTaxonomyPage(rel)}>
                <Chip
                  key={rel?.name}
                  label={rel?.name}
                  size="small"
                  color="primary"
                  variant="outlined"
                  className={classes.taxonomyStyle}
                />
              </Link>
            );
          })}
      </>
    );
  };

  const onMouseEnter = () => {
    let email: string | undefined = undefined;
    const ownerEmail = '' + entity?.spec?.owneremail;
    email = ownerEmail;
    if (
      ownerEmail === undefined ||
      ownerEmail === 'undefined' ||
      String(ownerEmail).trim()?.length === 0
    ) {
      const owner = getUserName(entity);
      email = `${owner}@cbre.com`;
    }
    userProfileApi.setUserProfileState({
      email,
      anchorRef: usernameRef?.current,
    });
  };

  return (
    <Grid container>
      <AboutField label="Description" gridSizes={{ xs: 12, sm: 12 }}>
        <ExpandableContent
          lines={2}
          value={entity?.metadata?.description || 'No description'}
        />
      </AboutField>
      <AboutField label="Owner" gridSizes={{ xs: 12, sm: 6, md: 6, lg: 6 }}>
        <div
          className={classes.links}
          ref={usernameRef}
          onMouseEnter={onMouseEnter}
        >
          <EntityRefLinks entityRefs={ownedByRelations} defaultKind="group">
            {`${entity?.spec?.owner}`?.replace('user:', '')}
          </EntityRefLinks>
        </div>
      </AboutField>
      {!isSystem && !isDomain && (
        <AboutField
          label="Type"
          value={entity?.spec?.type as string}
          gridSizes={{ xs: 12, sm: 6, md: 6, lg: 6 }}
        />
      )}
      {!isSystem && !isDomain && !isResource && (
        <AboutField
          label="Lifecycle"
          value={entity?.spec?.lifecycle as string}
          gridSizes={{ xs: 12, sm: 6, md: 6, lg: 6 }}
        />
      )}
      <AboutField
        label="Tags"
        value="No Tags"
        gridSizes={{ xs: 12, sm: 6, md: 6, lg: 6 }}
      >
        {(entity?.metadata?.tags || []).map(t => (
          <Chip
            key={t}
            size="small"
            label={t}
            color="primary"
            variant="outlined"
            className={classes.tagsStyle}
          />
        ))}
      </AboutField>
      {!isSystem && !isDomain && (
        <AboutField
          label="Updated"
          value={timeSince(
            entity?.metadata?.lastModifiedTimestamp ||
              entity?.metadata?.creationTimestamp,
          )}
          gridSizes={{ xs: 12, sm: 6, md: 6, lg: 6 }}
        />
      )}

      <AboutField
        label="Tower"
        value={towerData?.data?.tower || ' '}
        gridSizes={{ xs: 12, sm: 6, md: 6, lg: 6 }}
      />

      <AboutField
        label="Support Email"
        value={(supportEmail as string) || (entity?.spec?.owneremail as string)}
        gridSizes={{ xs: 12, sm: 6, md: 6, lg: 6 }}
      />

      <AboutField
        label="Capabilities"
        gridSizes={{ xs: 12, sm: 12, lg: 12, xl: 12 }}
        value=""
      >
        {renderAssets()}
      </AboutField>
    </Grid>
  );
};
