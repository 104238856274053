/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import VideocamIcon from '@material-ui/icons/Videocam';
import { Button } from '@material-ui/core';
import VideoRenderModal from './VideoRenderModal';
import { useLocation } from 'react-router';

import { VideoBytesContext } from './providers/VideoBytesProvider';

export const VideoByteButton = () => {
  const videoBytesContext = useContext(VideoBytesContext);

  const [openVideoRender, setOpenVideoRender] = useState(false);
  const [videoByteEnabled, setVideoByteEnabled] = useState(false);

  const [bytesConfig, setBytesConfig] = useState<any>();

  const getVideoBytesConfig = () => {
    setBytesConfig(videoBytesContext.bytesConfig);
  };

  useEffect(() => {
    getVideoBytesConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoBytesContext.bytesConfig]);

  const handleVideoByte = () => {
    setOpenVideoRender(true);
  };

  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    // setTimeout(() => {
    const samepagePaths = bytesConfig?.samepagePaths;
    const pages = bytesConfig?.pages;

    if (
      samepagePaths?.hasOwnProperty(pathname) ||
      pages?.hasOwnProperty(pathname)
    ) {
      setVideoByteEnabled(true);
    }
    // }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, bytesConfig]);

  const getPathData = () => {
    const finalPath = bytesConfig?.samepagePaths?.hasOwnProperty(pathname)
      ? bytesConfig?.samepagePaths[pathname]
      : pathname;

    return bytesConfig.pages[finalPath];
  };

  return (
    <>
      {videoByteEnabled ? (
        <div>
          <Button
            onClick={handleVideoByte}
            role="button"
            color="primary"
            variant="text"
          >
            <VideocamIcon /> Help Video
          </Button>
          <VideoRenderModal
            data={getPathData()}
            open={openVideoRender}
            setDeleteModal={setOpenVideoRender}
          />
        </div>
      ) : null}
    </>
  );
};
