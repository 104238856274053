import React, { useEffect, useContext, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { RecentlyPublished } from './recentlyPublished';
import { LeaderBoardAndTopPlayers } from './leaderBoardAndTopPlayers';
import { TopContributors } from './topContributors';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import { IS_GAMIFICATION_ENABLE } from '../../utils/constant';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import ToolboxMostUsedLinksWidget from './toolboxmostused';
import InformationExchangeLinksWidget from './informationexchange';
import PromotionsWidget from './promotions';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import { GuidedTourContext } from '../../guided-tour/providers/GuidedTourProvider';
import { discoverIds } from '../../../idSelectors';
import { useLocation } from 'react-router';
import PromptEnhancerWidget from './promptEnhancer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    discoverPageContent: {
      ['@media (max-width:920px) and (min-width: 280px)']: {
        overflowX: 'hidden',
      },
    },
    carousalGreyContainer: {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      borderRadius: '8px',
    },
    themeColor: {
      '& div[class^="MuiCardHeader-"]': {
        '& span': {
          color: theme.palette.customCard.cardHeader,
        },
      },
      '& button[class*="Mui-selected"]': {
        backgroundColor: theme.palette.customCard.tabHighlight,
      },
    },
    topButtonContainer: {
      display: 'flex',
      justifyContent: 'end',
    },
    whatsNewButton: {
      textTransform: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  }),
);

export const Discover = () => {
  const tourContext = useContext(GuidedTourContext);
  const classes = useStyles();

  const handleTourClick = () => {
    tourContext.setStartTour(true);
  };

  const [showWhatsNew, setShowWhatsNew] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const tourConfig = tourContext?.tourConfig;
    if (tourConfig) {
      if (
        location.pathname in tourConfig?.pages ||
        location.pathname in tourConfig?.samepagePaths
      ) {
        setShowWhatsNew(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourContext.tourConfig]);

  return (
    <div className={`discover-page  ${classes.themeColor}`}>
      <Grid container direction="row" className={classes.discoverPageContent}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.topButtonContainer}
        >
          {showWhatsNew ? (
            <Button
              onClick={handleTourClick}
              variant="contained"
              color="primary"
              className={classes.whatsNewButton}
              startIcon={<StarBorderOutlinedIcon />}
            >
              What's New
            </Button>
          ) : null}
          <HelpComponent helpUrl={HELP_URL.Discover} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <PromptEnhancerWidget/>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <ToolboxMostUsedLinksWidget id={discoverIds.toolboxRecent} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {/* <CustomCarousal /> */}
              <PromotionsWidget />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} className="discoverBlocks">
          <RecentlyPublished />
        </Grid>
        {IS_GAMIFICATION_ENABLE && (
          <Grid item xs={12} sm={12} md={6} className="discoverBlocks">
            <LeaderBoardAndTopPlayers />
          </Grid>
        )}
        {/* Commented Highest Rated Section */}
        {/* <Grid item xs={12} sm={12} md={6} className="discoverBlocks">
          <HighestRated />
        </Grid> */}

        {/* Commented Recent Articles */}
        {/* <Grid item xs={12} sm={12} md={6} className="discoverBlocks">
          <RecentArticles />
        </Grid> */}
        {!IS_GAMIFICATION_ENABLE && (
          <Grid
            item
            xs={12}
            md={6}
            className={
              !IS_GAMIFICATION_ENABLE
                ? 'discoverBlocks paddingBottom10'
                : 'discoverBlocks'
            }
          >
            <TopContributors />
          </Grid>
        )}
      </Grid>
    </div>
  );
};
