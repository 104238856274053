import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import RequestForm from './RequestForm';
import AddIcon from '@material-ui/icons/Add';
import { useStyles } from './styles';
import { PromotionContext } from '../../../../providers/PromotionDataProvider';

/* Request Types */
const RequestType = (props: any) => {
  const { onPathsChanged, clicked, onError } = props;
  const classes = useStyles();

  /* Context */
  const myContext = useContext(PromotionContext);

  const [requestTypes, setRequestTypes] = useState<any>([]);
  const [paths, setPaths] = useState<any>({});
  /* req type buttons array */
  const buttonsArr = ['GET', 'POST', 'PUT', 'DELETE', 'PATCH'];

  const handleBtnClick = (requestType: any) => {
    setRequestTypes((prevState: any) => [
      ...prevState,
      { requestType, id: new Date().getTime() },
    ]);
  };

  const handleReqInput = (reqData: any, uniqueKey: any) => {
    const path = Object.keys(reqData)[0]; //get the dynamic path

    setPaths((prevState: any) => {
      const req = { ...prevState[path], ...reqData[path] };
      return {
        ...prevState,
        [path]: req,
      };
    });
  };

  const handleReqDelete = (path: any, reqType: any, uniqueKey: any) => {
    /* Delete added requestTypes section from Array */
    const index = requestTypes.findIndex(
      (requestObj: any) => requestObj.id === uniqueKey,
    );
    setRequestTypes((prevState: any) => {
      const newArr = [...prevState];
      newArr.splice(index, 1);
      return newArr;
    });
  };

  const clearPaths = () => {
    setPaths({});
  };

  useEffect(() => {
    onPathsChanged(paths);
  }, [paths]);

  const [errors, setErrors] = useState<any>([]);

  const handleRequestError = (hasError: boolean) => {
    setErrors((prevState: any) => [...prevState, hasError]);
  };

  useEffect(() => {
    const validated = errors.every((v: any) => v === false);
    const hasError = !validated;
    onError('paths', hasError);
  }, [errors]);

  useEffect(() => {
    setErrors([]);
    clearPaths();
  }, [clicked]);

  return (
    <Grid container direction="row" spacing={1}>
      {/* Request Types */}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <div className={`${classes.swaggerTitle} ${classes.request}`}>
          Add Request Types
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.requestTypeBtns}
      >
        {buttonsArr.map(btnValue => {
          return (
            <Button
              key={btnValue}
              variant="outlined"
              color="primary"
              onClick={() => handleBtnClick(btnValue)}
              className={classes.btnRequestType}
            >
              <AddIcon /> {btnValue}
            </Button>
          );
        })}
      </Grid>

      {requestTypes.map((requestObj: any) => {
        return (
          <Grid item xs={12} sm={12} md={12} lg={12} key={requestObj.id}>
            {/* Request Form */}
            <RequestForm
              type={requestObj.requestType}
              onReqInput={(data: any) => handleReqInput(data, requestObj.id)}
              onReqDelete={(path: any, reqType: any) =>
                handleReqDelete(path, reqType, requestObj.id)
              }
              onError={handleRequestError}
              clicked={clicked}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
export default RequestType;
