import { Button, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import BodyContent from './BodyContent';
import { useStyles } from './styles';

const Responses = (props: any) => {
  const { onReponses, clicked } = props;
  const classes = useStyles();

  const [responseArray, setResponseArray] = useState<any>([]);
  const [responses, setResponses] = useState<any>();
  const [buttonsArr, setButonsArr] = useState([
    { code: '200', display: true },
    { code: '304', display: true },
    { code: '400', display: true },
    { code: '404', display: true },
    { code: '500', display: true },
  ]);

  const updateBtnsArr = (codeId: any, display: any) => {
    const newState = buttonsArr.map((obj: any) => {
      if (obj.code === codeId) {
        return { ...obj, display: display };
      }
      // 👇️ otherwise return object as is
      return obj;
    });
    setButonsArr(newState);
  };

  const handleAddResponse = (codeId: any) => {
    setResponseArray((prevState: any) => [...prevState, { id: codeId }]);
    updateBtnsArr(codeId, false);
  };

  const handleRespInput = (respData: any, responseId: any) => {
    setResponses((prevState: any) => {
      return {
        ...prevState,
        ...{ [responseId]: respData },
      };
    });
  };

  const handleRespDelete = (code: any, responseId: any) => {
    updateBtnsArr(code, true);

    setResponses((prevState: any) => {
      const newCopy = { ...prevState };
      delete newCopy[code];
      return newCopy;
    });

    setResponseArray((prevState: any) => {
      const filteredResponses = [...prevState].filter(
        (response: any) => response.id !== responseId,
      );
      return filteredResponses;
    });
  };

  useEffect(() => {
    onReponses(responses);
  });

  return (
    <Grid
      container
      direction="row"
      spacing={2}
      className={classes.responseSection}
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        Responses
      </Grid>

      {responseArray.map((responseObj: any) => {
        return (
          <Grid item xs={12} sm={12} md={12} lg={12} key={responseObj.id}>
            <BodyContent
              className={`${classes.swaggerForm} ${classes.response} `}
              data={{ code: responseObj.id }}
              onSecDelete={(code: any) =>
                handleRespDelete(code, responseObj.id)
              }
              onContentInput={(data: any) =>
                handleRespInput(data, responseObj.id)
              }
              clicked={clicked}
            />
          </Grid>
        );
      })}
      {/* add response button */}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {buttonsArr.map(btnObj => {
          return (
            <Button
              disabled={!btnObj.display}
              key={btnObj.code}
              variant="outlined"
              color="primary"
              onClick={() => handleAddResponse(btnObj.code)}
              className={classes.btnRequestType}
            >
              <AddIcon /> {btnObj.code}
            </Button>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default Responses;
