import React, { useRef } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Link,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { ItemCardHeader } from '@backstage/core-components';
import { Entity } from '@backstage/catalog-model';
import OpenInNew from '@material-ui/icons/OpenInNew';
import MoreVertOutlined from '@material-ui/icons/MoreVertOutlined';
import DeleteOutlineOutlined from '@material-ui/icons/DeleteOutlineOutlined';
import { useCustomStyles } from '../DocStyles';
import { getTitleElement, getUserLink, getUserName } from '../util';
import EditRounded from '@material-ui/icons/EditRounded';
import { useContext } from 'react';
import { AuthContext } from '../../../../providers/AuthProvider';
import { extarctProfileName } from '../../../utils/convertUserName';
import { useApi } from '@backstage/core-plugin-api';
import { userProfileApiRef } from '../../../../apis/userProfileApi';

const ExtDocCard = ({
  isAdmin,
  setAnchorElement,
  showCardItemMenu,
  anchorElement,
  doc,
  onDeleteDocsClicked,
  setSelectedDocument,
  onClickEdit,
  setSelectedDocumentUser,
  selectedDocumentUser,
}: {
  isAdmin: boolean | null;
  setAnchorElement: any;
  showCardItemMenu: boolean;
  anchorElement: any;
  doc: Entity;
  onDeleteDocsClicked: any;
  setSelectedDocument: any;
  onClickEdit: any;
  setSelectedDocumentUser: (str: string) => void;
  selectedDocumentUser: string;
}) => {
  const classes = useCustomStyles();
  const { profileName } = useContext(AuthContext);
  const usernameRef = useRef(null);
  const userProfileApi = useApi(userProfileApiRef);

  const enabledEdit =
    extarctProfileName(profileName) ===
    `${doc?.spec?.owner}`?.toLowerCase()?.replace('user:', '');

  const onMouseEnter = () => {
    let email: string | undefined = undefined;
    const ownerEmail = '' + doc?.spec?.owneremail;
    email = ownerEmail;
    if (
      ownerEmail === undefined ||
      ownerEmail === 'undefined' ||
      String(ownerEmail).trim()?.length === 0
    ) {
      const owner = getUserName(doc);
      email = `${owner}@cbre.com`;
    }
    userProfileApi.setUserProfileState({
      email,
      anchorRef: usernameRef?.current,
    });
  };

  return (
    <Card className={classes.docsCard} color="primary">
      <CardMedia className={classes.CardTitleStyle}>
        <ItemCardHeader
          classes={{ root: classes.CardHeader }}
          title={getTitleElement(doc?.metadata?.name)}
        ></ItemCardHeader>
        {isAdmin || enabledEdit ? (
          <>
            <label htmlFor="menuButton" hidden>
              Menu Button
            </label>
            <IconButton
              id="menuButton"
              className="card-icon-button"
              data-id={doc.metadata.name}
              onClick={event => {
                setAnchorElement(event?.target);
                setSelectedDocument(doc.metadata.name);
                setSelectedDocumentUser(
                  `${doc?.spec?.owner}`.toLowerCase().replace('user:', ''),
                );
              }}
            >
              <MoreVertOutlined />
            </IconButton>
            <Menu
              onClose={() => {
                setAnchorElement(null);
              }}
              open={showCardItemMenu}
              anchorEl={anchorElement}
            >
              {selectedDocumentUser === extarctProfileName(profileName) ? (
                <MenuItem id="edit-menu" onClick={onClickEdit}>
                  <EditRounded />
                  Edit
                </MenuItem>
              ) : (
                <div></div>
              )}
              {isAdmin ? (
                <MenuItem id="delete-menu" onClick={onDeleteDocsClicked}>
                  <DeleteOutlineOutlined />
                  Delete
                </MenuItem>
              ) : (
                <div></div>
              )}
            </Menu>
          </>
        ) : (
          <></>
        )}
      </CardMedia>
      <CardContent>
        <div className={classes.CardContentStyle}>
          {`${doc?.metadata?.description}`.length > 200
            ? `${doc?.metadata?.description?.substring(0, 200)}...`
            : `${doc?.metadata?.description}`}
        </div>
      </CardContent>
      <CardActions className={classes.CardActionsStyle}>
        <section>
          On {`${doc?.metadata?.creationTimestamp}`.substring(0, 10)} By{' '}
          <span onMouseEnter={onMouseEnter} ref={usernameRef}>
            <Link
              className="user"
              title={getUserLink(doc)}
              href={getUserLink(doc)}
            >
              {getUserName(doc)}
            </Link>
          </span>
        </section>
        <section>
          <Button
            title={
              `${doc?.metadata?.externalDocumentLink}` ||
              `${doc?.metadata?.extDocumentationLocation}` ||
              ''
            }
            onClick={() => {
              if (doc?.metadata?.externalDocumentLink) {
                window.open(`${doc?.metadata?.externalDocumentLink}`, '_blank');
              } else if (doc?.metadata?.extDocumentationLocation) {
                window.open(
                  `${doc?.metadata?.extDocumentationLocation}`,
                  '_blank',
                );
              }
            }}
          >
            View <OpenInNew></OpenInNew>
          </Button>
        </section>
      </CardActions>
    </Card>
  );
};
export default ExtDocCard;
