import React, { useEffect, useContext, useState, useMemo } from 'react';
import {
  TableColumn,
  Table,
  WarningPanel,
  CodeSnippet,
  TableProps,
} from '@backstage/core-components';
import {
  humanizeEntityRef,
  getEntityRelations,
  useEntityList,
  useStarredEntities,
  useEntityOwnership,
  UserListFilter,
  catalogApiRef,
} from '@backstage/plugin-catalog-react';
import * as columnFactories from '../../utils/devXTableColumns';
import * as constant from '../../utils/constant';
import { RELATION_OWNED_BY, RELATION_PART_OF } from '@backstage/catalog-model';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { Link } from 'react-router-dom';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import SubscriptionsOutlinedIcon from '@material-ui/icons/SubscriptionsOutlined';
import AllAssetsCardGrid from '../../home/allAssets/cardView/AllAssetsCardGrid';
import { AuthContext } from '../../../providers/AuthProvider';
import { useApi } from '@backstage/core-plugin-api';
import { subscriberApiRef } from '../../../apis/subscriberApi';
import { useAsync } from 'react-use';
import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    themeColor: {
      '& tr': {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.background.default + '!important',
        '& td ': {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: theme.palette.background.default + '!important',
          '& button ': {
            color: theme.palette.primary.main,
          },
        },
        '& th': {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: theme.palette.background.default + '!important',
        },
      },
      '& div::-webkit-scrollbar-thumb': {
        border: `4px solid ${theme.palette.text.tertiary}`,
      },
    },
  }),
);

const columns: TableColumn<columnFactories.EntityRow>[] = [
  columnFactories.createNameColumnCustomRoot({ rootRoute: 'API' }),
  columnFactories.createOwnerColumnCustomRoot({ rootRoute: 'API' }),
  columnFactories.createSpecTypeColumn(),
  columnFactories.createSpecLifecycleColumn(),
  columnFactories.createMetadataDescriptionColumn(),
  columnFactories.createTagsColumn(),
  columnFactories.createStatusColumn(),
  columnFactories.createRatingColumn(),
];

// const useCatalogEntity = (pageNumber = 1, recordsPerPage = 50) => {
//   const catalogApi = useApi(catalogApiRef);
//   const { value, error, loading } = useAsync(() =>
//     catalogApi.getEntities({
//       filter: {
//         kind: 'api',
//       },
//     }),
//   );

//   const entities = value?.items || [];
//   return { entities, error, loading };
// };

export const ApiTable = (props: any) => {
  const classes = useStyles();
  const { assetStore } = props;
  const { isStarredEntity, toggleStarredEntity } = useStarredEntities();
  const { filters } = useEntityList();
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(20);
  // const { loading, error, entities } = useCatalogEntity(
  //   currentPageNumber,
  //   recordsPerPage,
  // );
  const { loading, error, entities } = useEntityList();

  const [isClickSubscribeIcon, setClickSubscribeIcon] = useState<any>(false);
  const [isError, setIsError] = useState<any>(false);
  const [subscribeData, setSubscribeData] = useState<any>([]);
  const { profEmail, isAdmin } = useContext(AuthContext);
  const userEmail = profEmail;
  const { isOwnedEntity } = useEntityOwnership();
  const starredFilter = useMemo(
    () => new UserListFilter('starred', isOwnedEntity, isStarredEntity),
    [isOwnedEntity, isStarredEntity],
  );

  const subscriberApi = useApi(subscriberApiRef);

  useEffect(() => {
    subscriberApi
      .getSubscribersCount()
      .then(data => {
        setSubscribeData(data || []);
      })
      .catch(() => {
        setIsError(true);
      });
  }, [isClickSubscribeIcon]);

  useEffect(() => {
    if (loading != true && props.setLoading) {
      props.setLoading(false);
    }
  }, [loading]);

  const checkSubscribe = (data: any) => {
    let isChecksubscribe = false;
    subscribeData &&
      subscribeData?.map(item => {
        if (
          data.metadata?.name.toLowerCase() === item?.assetName.toLowerCase() &&
          item?.email.toLowerCase() === userEmail!.toLowerCase()
        ) {
          isChecksubscribe = true;
        }
      });
    return isChecksubscribe;
  };

  const onClickSubscribe = async (data: any) => {
    const payload = {
      assetName: data?.metadata?.name,
      email: userEmail,
    };
    await subscriberApi.assetsSubscribe(payload);
    setClickSubscribeIcon(!isClickSubscribeIcon);
  };

  const onClickUnSubscribe = async (data: any) => {
    const payload = {
      assetName: data?.metadata?.name,
      email: userEmail,
    };
    await subscriberApi.assetsUnsubscribe(payload);
    setClickSubscribeIcon(!isClickSubscribeIcon);
  };

  const defaultActions: TableProps<columnFactories.EntityRow>['actions'] = [
    ({ entity }) => {
      //const url = entity.metadata.annotations?.[ANNOTATION_VIEW_URL];
      const name = entity.metadata.name;
      const kind = entity.kind;
      const namespace = entity.metadata.namespace;
      const path = kind?.toLowerCase() === constant.API ? 'api-edit' : 'edit';
      const userProfile = profEmail?.split('@')[0].toLowerCase();
      const entityOwner = `${entity?.spec?.owner}`;
      const userOwnerShip =
        isAdmin || userProfile === entityOwner?.replace('user:', '');
      return {
        icon: () => (
          <Link to={`/API/${namespace}/${kind}/${name}/${path}`}>
            <EditRoundedIcon fontSize="small" />
          </Link>
        ),
        tooltip: 'View',
        disabled: !userOwnerShip,
        onClick: () => {},
      };
    },

    ({ entity }) => {
      const isStarred = isStarredEntity(entity);
      return {
        cellStyle: { paddingLeft: '1em' },
        icon: () =>
          isStarred ? <BookmarkIcon /> : <BookmarkBorderOutlinedIcon />,
        tooltip: 'Bookmark',
        onClick: () => toggleStarredEntity(entity),
      };
    },
    ({ entity }) => {
      const userProfile = userEmail!.split('@')[0].toLowerCase();
      const entityOwner = `${entity?.spec?.owner}`;
      const userOwnerShip =
        userProfile?.toLowerCase() ===
        entityOwner.replace('user:', '')?.toLowerCase();

      const isSubscribe = checkSubscribe(entity);
      if (isSubscribe) {
        return {
          cellStyle: { paddingLeft: '1em' },
          icon: () => <SubscriptionsIcon />,
          tooltip: 'Unsubscribe',
          disabled: userOwnerShip,
          onClick: () => onClickUnSubscribe(entity),
        };
      }
      return {
        cellStyle: { paddingLeft: '1em' },
        icon: () => <SubscriptionsOutlinedIcon />,
        tooltip: 'Subscribe',
        disabled: userOwnerShip,
        onClick: () => onClickSubscribe(entity),
      };
    },
  ];
  const showedContent = entities?.filter((item: any) => {
    const itemAssetStore = item?.metadata?.assetstore || 'wso2';
    return assetStore === 'all'
      ? item?.metadata?.devxstate !== constant.NOT_APPROVED
      : item?.metadata?.devxstate !== constant.NOT_APPROVED &&
          itemAssetStore === assetStore;
  });
  const starredFilterItem =
    showedContent &&
    showedContent.length &&
    showedContent?.filter((item: any) => starredFilter.filterEntity(item));
  const rows = showedContent?.map((entity: any) => {
    const partOfSystemRelations = getEntityRelations(entity, RELATION_PART_OF, {
      kind: 'system',
    });
    const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);

    return {
      entity,
      resolved: {
        name: humanizeEntityRef(entity, {
          defaultKind: 'Component',
        }),
        ownedByRelationsTitle: ownedByRelations
          ?.map(r => humanizeEntityRef(r, { defaultKind: 'group' }))
          .join(', '),
        ownedByRelations,
        partOfSystemRelationTitle: partOfSystemRelations
          ?.map(r =>
            humanizeEntityRef(r, {
              defaultKind: 'system',
            }),
          )
          .join(', '),
        partOfSystemRelations,
      },
    };
  });

  if (error) {
    return (
      <div>
        <WarningPanel
          severity="error"
          title="Could not fetch catalog entities."
        >
          <CodeSnippet language="text" text={error.toString()} />
        </WarningPanel>
      </div>
    );
  }

  const titlePreamble = 'all';
  // const titlePreamble = filters.user?.value ?? 'all';
  return (
    <>
      {props.view === 'table' ? (
        <div className={`mystuff-table  ${classes.themeColor}`}>
          <Table
            isLoading={loading}
            columns={columns}
            options={{
              paging: true,
              pageSize: 20,
              search: true,
              actionsColumnIndex: -1,
              loadingType: 'linear',
              showEmptyDataSourceMessage: !loading,
              padding: 'dense',
              pageSizeOptions: [20],
            }}
            data={rows}
            actions={defaultActions}
            onPageChange={e => {
              setCurrentPageNumber(e);
            }}
            title={`${
              titlePreamble.toLowerCase() === constant.STARRED
                ? constant.BOOKMARK
                : titlePreamble
            } (${rows.length})`}
          />
        </div>
      ) : (
        <>
          {showedContent && (
            <AllAssetsCardGrid
              entities={showedContent}
              anchorElement={null}
              setAnchorElement={() => {}}
              isAdmin={isAdmin}
              checkFilterItem={{
                ...props.sort,
                isCodeFilterClick: false,
                isApiFilterClick: false,
                isAllFilterClick: true,
                isFavoriteFilterClick: false,
                isIacFilterClick: false,
                isSubscribeFilterClick: false,
              }}
              starredFilterItem={starredFilterItem || []}
              subscribeData={subscribeData || []}
              setClickSubscribeIcon={setClickSubscribeIcon}
              isClickSubscribeIcon={isClickSubscribeIcon}
              loading={loading}
              mountOn="API"
            />
          )}
        </>
      )}
    </>
  );
};
