/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Grid, LinearProgress } from '@material-ui/core';
import BackButton from '../../../utils/backButton';
import { HelpComponent } from '../../../utils/helpComponent';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { PromptContext } from '../providers/PromptProvider';
import { useApi } from '@backstage/core-plugin-api';
import { promptApiRef } from '../../../../apis/promptManagementApi';
import { notificationApiRef } from '../../../../apis/notificationApi';
import { AuthContext } from '../../../../providers/AuthProvider';
import { useStyles } from '../styles';
import { IPromptData, IPromptDataRequest } from '../interfaces';
import AddEditPrompt from './AddEditPrompt';
import SimilarPrompts from '../SimilarPrompts';
import SimilarPromptPopup from '../SimilarPrompts/SimilarPromptPopup';
import SaveNewPromptPopup from '../SimilarPrompts/SaveNewPromptPopup';
import HELP_URL from '../../../utils/helpLinkConstant';

const AddPromptPage = () => {
  const { domainId, subDomainId, personaId, promptId } = useParams();

  const [details, setDetails] = useState<any>({
    domain: {
      id: '',
      name: '',
    },
    subDomain: {
      id: '',
      name: '',
    },
    personas: [],
  });
  const navigate = useNavigate();
  const promptApi = useApi(promptApiRef);
  const notificationApi = useApi(notificationApiRef);
  const classes = useStyles();
  const {
    domains,
    prompts,
    updateAll,
    setPrompts,
    setDomains,
    setEditModeData,
  } = useContext(PromptContext);
  const { profEmail } = useContext(AuthContext);

  const fetchData = async () => {
    const response = await promptApi.getDomainHierarchy();
    const response2 = await promptApi.getPromptByEmail(profEmail);
    if (response && response2) {
      updateAll(response.data, response2.prompts);
    }
  };

  useEffect(() => {
    if (!domains) {
      fetchData();
    } else {
      const domain = domains.find((d: any) => d.id === domainId);
      const subDomain = domain?.subproducts?.find(
        (sd: any) => sd.id === subDomainId,
      );
      const personas = subDomain?.personas?.filter(
        (p: any) => p.id === personaId,
      );

      if (!domain && !subDomain && !personas) {
        navigate('/admin/manage-prompts');
      } else {
        setDetails({
          domain,
          subDomain,
          personas,
        });
      }
    }
  }, [prompts]);

  const onClose = () => {
    //  setPrompt(emptyPromptState);
  };

  const emptyPromptState = {
    open: false,
    dialogProps: {},
    onClose: () => {},
    onConfirm: () => {},
  };

  const [similarPrompts, setSimilarPromts] = useState([]);
  const [draftPrompt, setDraftPrompt] = useState<IPromptData | []>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [showSimiLarPrompts, setShowSimiLarPrompts] = useState(false);
  const [similarPromptsPopupProps, setSimilarPromptsPopupProps] = useState({
    ...emptyPromptState,
  });
  const [savePromptPopupProps, setSavePromptPopupProps] = useState({
    ...emptyPromptState,
  });

  const handleCancel = () => {
    navigate(
      `/admin/manage-prompts/${domainId}/${subDomainId}/${personaId}/prompts`,
      { replace: true },
    );
  };

  const handleCancelSimilarPrompts = () => {
    setShowSimiLarPrompts(false);
  };

  const handleCloseSimilarPopup = () => {
    setSimilarPromptsPopupProps(prevState => {
      return {
        ...prevState,
        open: false,
      };
    });
  };

  const handleCloseSavePromptPopup = () => {
    setSavePromptPopupProps(prevState => {
      return {
        ...prevState,
        open: false,
      };
    });
  };

  const handleSave = async (data: any) => {
    setIsLoading(true);
    const response = await promptApi.createPrompt(data);
    if (response) {
      setIsLoading(false);
      const status = response.status[0];
      if (status.status === 'failure') {
        setIsLoading(false);

        setSimilarPromptsPopupProps({
          open: true,
          onClose: () => {
            handleCloseSimilarPopup();
          },
          onConfirm: () => {
            setSimilarPromts(status.similar_prompts);
            setShowSimiLarPrompts(true);
            handleCloseSimilarPopup();
          },
          dialogProps: {},
        });

        setDraftPrompt(status.prompt);
      } else {
        notificationApi.sendNotification({
          message: 'Prompt created Successfully',
          disapperAfterMs: 2500,
          severity: 'success',
          callback: () =>
            navigate(
              `/admin/manage-prompts/${domainId}/${subDomainId}/${personaId}/prompts`,
              { replace: true },
            ),
        });
        setPrompts([status.prompt, ...prompts]);
      }
    }
    return;
  };

  const handleEditPrompt = async (
    promptData: IPromptDataRequest,
    __promptId: string,
    mode: string = 'copy',
    message: string,
  ) => {
    setIsLoading(true);
    const response = await promptApi.updatePrompt(
      {
        ...promptData,
        // authoremail: profEmail,
        // authordisplayname: profEmail.split('@')[0].split('.').join(' '),
      },
      __promptId,
    );
    if (response) {
      setIsLoading(false);
      const status = response.status;
      if (status === true) {
        notificationApi.sendNotification({
          message: message,
          disapperAfterMs: 2500,
          severity: 'success',
          callback: () => navigate(-1),
        });
        if (mode === 'copy') {
          setPrompts([response.prompt, ...prompts]);
        } else {
          setPrompts(
            prompts.map((prompt: any) =>
              prompt.id === response.prompt.id ? response.prompt : prompt,
            ),
          );
        }
      }
    }
    return;
  };

  const handleSavePopup = (data: any) => {
    setSavePromptPopupProps({
      open: true,
      onClose: () => {
        handleCloseSavePromptPopup();
      },
      onConfirm: () => {
        handleSave(data);
        handleCloseSavePromptPopup();
      },
      dialogProps: {
        firstText: 'Prompt to be saved',
        secondText: 'Save to',
        headerText: 'Save new prompt?',
        buttonText: 'Save',
        promptKey: data.prompts[0].promptkey,
        domainName: details.domain.name,
        subDomainName: details.subDomain.name,
        personaName: details.personas[0].name,
      },
    });
  };

  const handleCopyPopup = (...params: any) => {
    setSavePromptPopupProps({
      open: true,
      onClose: () => {
        handleCloseSavePromptPopup();
      },
      onConfirm: () => {
        handleEditPrompt(params[0], params[1], params[2], params[3]);
        handleCloseSavePromptPopup();
      },
      dialogProps: {
        firstText: 'Prompt to be copied',
        secondText: 'Copied to',
        headerText: 'Copy similar prompt?',
        buttonText: 'Copy',
        promptKey: params[0].promptkey,
        domainName: details.domain.name,
        subDomainName: details.subDomain.name,
        personaName: details.personas[0].name,
      },
    });
  };

  useEffect(() => {
    if (promptId)
      setEditModeData(
        prompts.find((prompt: IPromptData) => prompt.id === promptId),
      );
    else {
      setEditModeData(null);
    }
  }, [promptId]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <BackButton />
          <HelpComponent
            helpUrl={
              !showSimiLarPrompts
                ? HELP_URL.ADD_PROMPTS
                : HELP_URL.SIMILAR_PROMPTS
            }
          />
        </Grid>

        <Grid container className={classes.pagePadding}>
          {isLoading && (
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          )}

          <Grid item>
            <AddEditPrompt
              hidden={showSimiLarPrompts}
              onSave={handleSavePopup}
              onCancel={handleCancel}
              pageLoader={isLoading}
              onEdit={handleEditPrompt}
            />
          </Grid>
          {similarPrompts.length > 0 && showSimiLarPrompts ? (
            <>
              <Grid item xs={12} md={12} lg={12}>
                <SimilarPrompts
                  draftPrompt={draftPrompt}
                  similarPrompts={similarPrompts}
                  onForceSave={handleSavePopup}
                  onCopyExistingPrompt={handleCopyPopup}
                  onEditClicked={handleCancelSimilarPrompts}
                  onCancel={handleCancelSimilarPrompts}
                />
              </Grid>
            </>
          ) : null}
        </Grid>
      </Grid>
      {similarPromptsPopupProps?.open && (
        <SimilarPromptPopup {...similarPromptsPopupProps} />
      )}
      {savePromptPopupProps?.open && (
        <SaveNewPromptPopup {...savePromptPopupProps} />
      )}
    </>
  );
};

export default AddPromptPage;
