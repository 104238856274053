import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { useCustomStyles } from './CardStyles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { AddEditCategory } from './AddEditCategory';

interface CategoryData {
  id: string;
  name: string;
  description: string;
}

export interface ManageCategoryCardProps {
  categoryData: CategoryData;
  onClickDeleteButton?: (params: any) => void;
  onEditClick?: (params: any) => void;
}

export const ManageCategoryCard = (props: ManageCategoryCardProps) => {
  //const { id, name, description } = props.data;
  const { categoryData, onClickDeleteButton, onEditClick } = props;
  const classes = useCustomStyles();
  return (
    <div className={classes.CardEntityContainer}>
      <Grid container>
        <Grid
          item
          md={4}
          sm={4}
          xs={6}
          className={`${classes.row} ${classes.center}`}
        >
          <Typography className={classes.CardTypeFontBold}>
            Category<b className={classes.boldText}>{categoryData?.name}</b>
          </Typography>
        </Grid>

        <Grid
          item
          md={7}
          sm={4}
          xs={6}
          className={`${classes.row} ${classes.center}`}
        >
          <Typography className={classes.CardTypeFontBold}>
            Description
            <b className={`${classes.boldText} ${classes.Description}`}>
              {categoryData?.description}
            </b>
          </Typography>
        </Grid>

        <Grid item md={1} sm={2} xs={3} className={classes.CardButtonContainer}>
          <Button onClick={onEditClick}>
            <EditIcon />
          </Button>
          <Button onClick={onClickDeleteButton}>
            <DeleteIcon />
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
