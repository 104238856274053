import React, { useState, useEffect } from 'react';
import { Content, Header, Page } from '@backstage/core-components';
import {
  Button,
  Checkbox,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  LinearProgress
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useNavigate, useParams } from 'react-router';
import { useApi } from '@backstage/core-plugin-api';
import { Entity } from '@backstage/catalog-model';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import BackButton from '../../utils/backButton';
import { DevxBreadCrumb } from '../../common/BreadcrumbsNav/DevxBreadCrumb';
import LinkIcon from '../../Promotions/icons/LinkIcon';
import { urlRegex } from '../../utils/Utils';
import AddIcon from '@material-ui/icons/Add';
import { Autocomplete } from '@material-ui/lab';
import { MAX_DESCRIPION, MAX_TITLE } from '../../utils/constant';
import { useStyles } from '../common/styles';
import { NotificationApi, notificationApiRef } from '../../../apis/notificationApi';
import { pocHubApiRef } from '../../../apis/pocHubApi';
import { ToolboxApi, toolboxApiRef } from '../../../apis/toolboxApi';
import { useGetPocComponent } from '../hooks/useGetPocComponent';
import { POCEntity } from '../PocDetailsPage/constant/pocEntity';
import { allowedExtensions } from '../AdditionalPocPage';
import { validImageFormat } from '../../utils/Utils';
import { IMAGE_MAX_SIZE } from '../../utils/constant';
import { constants } from '../common/constants';
import {
  InformationExchangeApi,
  informationExchangeApiRef,
} from '../../../apis/informationExchange';
import { removeLineBreaks } from '../common/regex';

export const EditPoc = () => {
  const classes = useStyles();
  const { assetName } = useParams();
  const { getPoc } = useGetPocComponent();
  const [entity, setEntity] = useState<Entity | undefined>(POCEntity);
  const [loading, setLoading] = useState<boolean>(false);

  const [demoLink, setDemoLink] = useState('');
  const [demoLinkError, setDemoLinkError] = useState('');

  const [productname, setProductname] = useState('');
  const [productNameError, setProductNameError] = useState('');

  const [productDesc, setProductDesc] = useState('');
  const [productDescError, setProductDescError] = useState('');

  const [imageDataBase64, setImageDataBase64] = React.useState<any>('');
  const [validateImageFile, setValidateImageFile] = React.useState(false);

  const [artifacts, setArtifacts] = useState<any>([{
    title: '',
    content: '',
    error: false,
    fileName: '',
    uploaded: false
  }]);

  const [tags, setTags] = useState<any>([]);
  const [tagsError, setTagsError] = useState<string | undefined>(tags.er);
  const [defaultTags, setDefaultTags] = useState<any>([]);
  const [demoLinkChecked, setDemoLinkChecked] = useState<boolean>(false);
  const [documentChecked, setDocumentChecked] = useState<boolean>(false);
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const pocApi = useApi(pocHubApiRef);
  const toolboxApi: ToolboxApi = useApi(toolboxApiRef);
  const navigate = useNavigate();

  const informationExchange: InformationExchangeApi = useApi(
    informationExchangeApiRef,
  );

  const handleProductNameChange = (event: any) => {
    if (productname.length == MAX_TITLE) {
      setProductNameError('Max 100 characters');
      setProductname(event.target.value);
    } else {
      setProductname(event.target.value);
      setProductNameError('');
    }
  };
  const handleProductDescChange = (event: any) => {
    if (productDesc.length === MAX_DESCRIPION) {
      setProductDescError('Max 350 characters');
      setProductDesc(event.target.value);
    } else {
      setProductDesc(event.target.value);
      setProductDescError('');
    }
  };

  const handleDemoLinkChange = (event: any) => {
    setDemoLink(event.target.value);
    setDemoLinkError('');
  };

  const onCancel = () => {
    navigate('/poc-hub');
  };
  const metaMap = {
    png: 'data:image/png;base64,',
    svg: 'data:image/svg+xml;base64,',
    jpg: 'data:image/jpeg;base64,',
    jpeg: 'data:image/jpeg;base64,'
  };
  const getDefaultMetadata = () => {
    let imageKey = entity?.metadata.image;
    if (imageKey !== undefined) {
      const ext = String(imageKey).substring(
        String(imageKey)?.lastIndexOf('.') + 1,
      );
      // @ts-ignore
      return metaMap[ext];
    }
    return 'data:image/svg+xml;base64,';
  };


  const validateFileList = () => {
    if (!artifacts || artifacts.length ===0 ) return true;
    let valid = true;
    artifacts.forEach((file: any) => {
      if (!file.uploaded) {
        return
      }
      if (!file.fileName || !allowedExtensions.exec(file.fileName)) {
        valid = false;
      }
      return;
    });
    return valid;
  }

  const navigateToPocPage = () => {
    navigate('/poc-hub');
  }

  const handleSubmitClick = async () => {
    try {
      let loc;
      let links;
      let image;
      if (validateFileList()) {
        if (artifacts.length > 0) {
          loc = await pocApi.fileUpload({
            fileList: artifacts,
            repourl: entity?.metadata?.annotations ? entity?.metadata?.annotations['backstage.io/source-location'].substring(4) : ''
          });
        }
        links = [
          ...(loc && loc.files.length > 0 ? loc.files.map((f: any) => {
            return {
              title: f.title || f.name,
              type: 'docs',
              url: f.location
            }
          }) : []),
          ...(demoLinkChecked ? [{
            title: 'devx-demo-link',
            url: demoLink,
            type: 'demo'
          }] : [])
        ];
        if (!imageDataBase64 && entity?.metadata.image) {
          image = await toolboxApi.getImageData(String(entity?.metadata?.image))
          .then((data: any) => {
            if (data) return `${getDefaultMetadata()}${data}`;
            return '';
          }).catch(() => {
            return ''
          })
        }
        const response = await pocApi.updateRegisteredComponent({
          owneremail: String(entity?.spec?.owneremail) || '',
          repourl: entity?.metadata?.annotations ? entity?.metadata?.annotations['backstage.io/source-location'].substring(4) : '',
          description: removeLineBreaks(productDesc),
          type: constants.POC,
          name: entity?.metadata?.name,
          status: constants.APPROVED,
          tags: tags,
          links: links,
          image: image || imageDataBase64 || '',
          scm: constants.GITHUB,
          scmrepoid: '',
          branch: constants.MAIN
        });
        if (response) {
          notificationApi.sendNotification({
            message: constants.EDIT_SUCCESS_MESSAGE,
            disapperAfterMs: 2500,
            severity: 'success',
            callback: () => navigateToPocPage()
          })
        }
      } else {
        setDisableSubmit(false);
        throw new Error(
          constants.errors.INVALID_FILE_TYPE
        )
      }
    } catch(err: any) {
      notificationApi.sendNotification({
        message: `${err.message || constants.errors.FAILED_TO_UPLOAD}`,
        disapperAfterMs: 2500,
        severity: 'error'
      });
    }
  };

  const validateDemoLink = () => {
    let demoLinkError = '';
    if (demoLinkChecked) {
      demoLinkError = demoLink.match(urlRegex)
        ? ''
        : constants.errors.INVALID_DEMO_URL;
    } else {
      demoLinkError = '';
    }
    setDemoLinkError(demoLinkError);
    return demoLinkError ? false : true;
  };

  const validateForm = () => {
    if (productname === '') {
      setProductNameError('Please enter product name');
    } else {
      setProductNameError('');
    }
    if (productDesc === '') {
      setProductDescError('Please enter product Description');
    } else {
      setProductDescError('');
    }
    if (validateDemoLink()) {
      handleSubmitClick();
    }
  };
  const onChangeTags = (_: any, tagValues: any) => {
    if (tagValues.length <= 5) {
      setTagsError(undefined);
      setTags(tagValues);
    } else {
      setTagsError('Maximum 5 tags');
    }
  };

  const onChangeDemoLinkChecked = (event: any, checked: boolean) => {
    event?.preventDefault();
    setDemoLinkChecked(checked);
    setDemoLink('');
    setDemoLinkError('');
  };

  const onDocumentCheckedChecked = (event: any, checked: boolean) => {
    event?.preventDefault();
    setDocumentChecked(checked);
  };

  const onAddNewDocument = () => {
    if ((artifacts.length === 0 ||
      (!!artifacts[artifacts.length - 1].content ||
        artifacts[artifacts.length - 1].uploaded)) &&
      artifacts.length < 6) {
      const temp = Array.from(artifacts);
      temp[artifacts.length] = {
        title: '',
        content: '',
        error: false,
        fileName: '',
        uploaded: false
      }
      setArtifacts(temp);
    }
  };
  const onDeleteDocument = (idx: number) => {
    setArtifacts(artifacts.filter((_: any, i: number) => i!==idx));
  }
  function getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };
  const handleFileSelect = async (e: any, idx: number) => {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      if (artifacts[idx]) {
        const temp = [
          ...artifacts.slice(0, idx),
          {
            title: artifacts[idx].title || e.target.files[0].name,
            content: await getBase64(e.target.files[0]),
            error: !allowedExtensions.exec(e.target.files[0].name),
            fileName: e.target.files[0].name,
            uploaded: false
          },
          ...(artifacts.length > idx - 1 ? artifacts.slice(idx + 1) : [])
        ]
        setArtifacts(temp);
      }
    }
  };

  const handleFileNameChange = (name: string, idx: number) => {
    if (artifacts[idx]) {
      const temp = Array.from(artifacts);

      temp[idx] = {
        title: name,
        content: artifacts[idx].content,
        error: artifacts[idx].error,
        fileName: artifacts[idx].fileName,
        uploaded: false
      }
      setArtifacts(temp);
    } else {
      const temp = Array.from(artifacts);
      temp[artifacts.length] = {
        title: name,
        content: '',
        error: false,
        fileName: '',
        uploaded: false
      }
      setArtifacts(temp)
    }
  };

  const imageUploadHandle = (e: any) => {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      if (
        validImageFormat(e.target.files[0].type) &&
        e.target.files[0].size < IMAGE_MAX_SIZE
      ) {
        let imageFile = e.target.files[0];
        const reader = new FileReader();

        getBase64(imageFile).then(data => {
          setImageDataBase64(data);
        });
        reader.readAsDataURL(imageFile);
        setValidateImageFile(false);
      } else {
        setValidateImageFile(true);
      }
    } else {
      setValidateImageFile(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getPoc(assetName)?.then(value => setEntity(value));
  }, []);

  useEffect(() => {
    if (entity) {
      setLoading(false);
    }
    if (entity?.metadata) {
      const {
        links,
        tags,
        description,
        name
      } = entity?.metadata;
      if (links && links.length > 0) {
        const demoLink = links.find((link) => link.type === 'demo');
        const artifactLinks = links.filter((link) => link.type !== 'demo');

        if (demoLink) {
          setDemoLinkChecked(true);
          setDemoLink(demoLink.url);
        }
        if (artifactLinks) {
          setDocumentChecked(true);
          setArtifacts(artifactLinks.map(a => ({
            title: a.title,
            content: '',
            error: false,
            fileName: a.url.split('/').slice(-1)[0],
            uploaded: true
          })));
        }
      }
      if (tags) {
        setTags(tags);
      }
      if (name) {
        setProductname(name);
      }
      if (description) {
        setProductDesc(description);
      }
    }
  }, [entity]);

  useEffect(() => {
    const params = {
      page: '',
      pagesize: '',
      sortby: '',
      search: '',
    };

    informationExchange
      .getAllTags(params)
      .then((data: any) => {
        const tagsList = data && data?.tags.map(val => val.tag);
        setDefaultTags(tagsList);
      })
      .catch(() => {
        notificationApi.sendNotification({
          message: `Failed to load Tags`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  }, []);

  return (
    <Page themeId="home">
      <Header
        pageTitleOverride="Edit POC"
        title="Edit POC"
        subtitle={
          <DevxBreadCrumb
            routes={[
              {
                type: 'link',
                link: '/',
                text: 'Home',
              },
              {
                type: 'link',
                link: '/poc-hub',
                text: 'POC Hub',
              },
              {
                type: 'text',
                link: '',
                text: 'Edit POC',
              },
            ]}
          />
        }
      />
      <Content>
        {loading && <LinearProgress />}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <BackButton />
          <HelpComponent helpUrl={HELP_URL.EDIT_POC_PAGE} />
        </Grid>
        <Grid className={`codePage ${classes.editPocPage}`}>
          <div className={classes.product}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Typography className={classes.headingText}>
                  Edit POC - {productname}
                </Typography>
                <TextField
                  label="POC Title"
                  disabled
                  required
                  fullWidth                  
                  variant="outlined"
                  size="small"
                  multiline
                  value={productname}
                  inputProps={{ maxLength: 100 }}
                  helperText={`${productname.length}/${MAX_TITLE}`}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  onChange={handleProductNameChange}
                  {...(productNameError && {
                    error: true,
                    helperText: productNameError,
                  })}
                />
                <TextField
                  label="POC Description"
                  required
                  fullWidth
                  variant="outlined"
                  size="small"
                  multiline
                  rows={5}
                  inputProps={{ maxLength: 350 }}
                  value={productDesc}
                  helperText={`${productDesc.length}/${MAX_DESCRIPION}`}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  onChange={handleProductDescChange}
                  {...(productDescError && {
                    error: true,
                    helperText: productDescError,
                  })}
                />
                <div>
                  {tags !== undefined && (
                    <Autocomplete
                      className={classes.tagsField}
                      freeSolo
                      multiple
                      size={'small'}
                      onChange={onChangeTags}
                      options={defaultTags || []}
                      getOptionLabel={(option: any) =>
                        option.tag ? option.tag : option
                      }
                      value={tags}
                      renderInput={params => (
                        <TextField
                          {...params}
                          inputProps={{ ...params.inputProps, maxLength: 18 }}
                          variant="outlined"
                          error={
                            tagsError !== undefined &&
                            String(tagsError)?.trim().length > 0
                          }
                          label="Type To select tags"
                        />
                      )}
                      disableCloseOnSelect
                    />
                  )}
                  {tagsError && (
                    <FormHelperText error className={classes.descError}>
                      {tagsError}
                    </FormHelperText>
                  )}
                </div>
                <Typography className={classes.subHeadingText}>
                  <Checkbox
                    checked={demoLinkChecked}
                    onChange={onChangeDemoLinkChecked}
                    name="default-complexity"
                    color="primary"
                  />
                  Have a POC Demo link?
                </Typography>

                {demoLinkChecked && (
                  <TextField
                    className={classes.inputField}
                    fullWidth
                    label="POC Demo Link"
                    variant="outlined"
                    value={demoLink}
                    onChange={handleDemoLinkChange}
                    {...(demoLinkError && {
                      error: true,
                      helperText: demoLinkError,
                    })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LinkIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}

                <Typography className={classes.subHeadingText}>
                  POC Thumbnail Image
                </Typography>

                <Grid className={classes.thumbnail}>
                  <div className={classes.inputFields}>
                    <TextField
                      className={classes.inputField}
                      fullWidth
                      // label="Upload Image"
                      variant="outlined"
                      type="file"
                      onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        imageUploadHandle(event)
                      }}
                    />
                  </div>
                  {validateImageFile && (
                    <Typography className={classes.errorMessage}>
                      *Supported image type is jpeg or png format (optional
                      field/no image) and size must less than 15MB!
                    </Typography>
                  )}
                </Grid>

                <Grid className={classes.documents}>
                  <Typography className={classes.subHeadingText}>
                    <Checkbox
                      checked={documentChecked}
                      onChange={onDocumentCheckedChecked}
                      name="default-complexity"
                      color="primary"
                    />
                    POC Documents and Artifacts (optional)
                  </Typography>
                  <IconButton className={classes.addLink} onClick={onAddNewDocument}>
                    Add Item
                    <AddIcon className={classes.actionIcons} />
                  </IconButton>
                </Grid>

                {documentChecked && (
                  <>
                    {
                      artifacts.map((a: any, idx: number) => (
                        <Grid container className={classes.pages} key={`edit-poc-field-${idx}`}>
                          <Grid item xs={12} sm={12} md={3} lg={3}>
                            <TextField
                              className={classes.inputField}
                              label="Title"
                              variant="outlined"
                              disabled={a.uploaded}
                              value={a.title}
                              onChange={e => handleFileNameChange(e?.target?.value, idx)}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={9}
                            lg={9}
                            className={classes.flex}
                          >
                            <div className={`${classes.fileUploadContainer} ${classes.fileUploadContainerMargin}`}>
                              {
                                a.uploaded ? <TextField
                                  id="upload-document"
                                  variant="outlined"
                                  fullWidth
                                  disabled
                                  label="File Name"
                                  value={a.fileName}
                                /> : <TextField
                                  id="upload-document"
                                  type="file"
                                  variant="outlined"
                                  fullWidth
                                  onChange={e => handleFileSelect(e, idx)}
                                />
                              }
                              {a.error && (
                                <Typography className={classes.errorMessage}>
                                  Unsupported File type
                                </Typography>
                              )}
                            </div>
                            <IconButton color="primary" onClick={() => onDeleteDocument(idx)}>
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))
                    }
                    <Grid container className={classes.div}>
                    <Typography component="div" className={`${classes.pages} ${classes.description}`}>
                      *{' '}Document, Presentation, Spreadsheet, Image and Video files
                      allowed. Total file size during each upload should not exceed 10Mb
                    </Typography>
                    </Grid>
                  </>
                )}

                <Grid item xs={12} sm={12} className={classes.btnGroupMain}>
                  <div className={classes.btnGroup}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={onCancel}
                    >
                      Cancel
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      onClick={validateForm}
                      className={classes.testButton}
                      disabled={disableSubmit}
                    >
                      Update
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Content>
    </Page>
  );
};
