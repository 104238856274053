import { createPlugin, createRoutableExtension, createApiFactory } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { readmeApiRef, ReadmeApiImpl } from './apis/readmeApi';
import { axiosInstanceProviderApiRef } from '../../../packages/app/src/apis/axiosInstanceApi';

export const readmePlugin = createPlugin({
  id: 'readme',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: readmeApiRef,
      deps: {
        axiosInstanceProviderApi: axiosInstanceProviderApiRef,
      },
      factory: ({ axiosInstanceProviderApi }) =>
        new ReadmeApiImpl(axiosInstanceProviderApi),
    })
  ]
});

export const ReadmePage = readmePlugin.provide(
  createRoutableExtension({
    name: 'ReadMePageExtension',
    component: () =>
      import('./components/MdRenderer').then(m => m.default),
    mountPoint: rootRouteRef,
  }),
);
