import React, { useState, createContext, useEffect } from 'react';
import { useLocation } from 'react-router';

interface ExtraConfigProps {
  callback?: Function;
}

const initialState = {
  open: false,
};

export const AIPopupContext = createContext({
  ...initialState,
  onConfirm: () => {},
  showAiPopup: () => {},
});

export const AIPopupProvider = ({
  paths,
  children,
}: {
  paths: RegExp[];
  children: any;
}) => {
  const [urlRegexPaths, setUrlRegexPaths] = useState<RegExp[]>(paths);
  const [open, setOpen] = useState<boolean>(false);
  const [callback, setCallback] = useState<Function>();

  /* Can be used from component */
  const showAiPopup = (config?: ExtraConfigProps) => {
    setOpen(true);
    if (config?.callback) setCallback(config.callback);
  };

  const location = useLocation();

  /* on Confirm is used by the ui popup */
  const onConfirm = () => {
    setOpen(false);
    // Remove the path once user click okay
    setUrlRegexPaths(urlRegexPaths?.filter(rx => !rx.test(location.pathname)));
    if (callback) {
      callback();
    }
  };

  useEffect(() => {
    const isMatch = urlRegexPaths?.some(rx => rx.test(location.pathname));
    if (isMatch) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <AIPopupContext.Provider
      value={{
        open,
        onConfirm,
        showAiPopup,
      }}
    >
      {children}
    </AIPopupContext.Provider>
  );
};
