import React, { useState, useEffect } from 'react';
import { Button, Chip, Typography } from '@material-ui/core';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { useStyles } from './styles';
import { useNavigate } from 'react-router';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import { useStarredEntities } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { ToolboxApi, toolboxApiRef } from '../../../apis/toolboxApi';

import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import noImage from '../../../../src/assest/poc-default image.jpg';

export const PocCard = (props: any) => {
  const { item } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const { isStarredEntity, toggleStarredEntity } = useStarredEntities();
  const [imageData, setImageData] = useState<string | undefined>();

  const toolboxApi: ToolboxApi = useApi(toolboxApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const [imageMeta, setImageMeta] = useState<string | undefined>(
    item.imageMetaForBase64,
  );

  const navigateToDetailsPage = (item: string) => {
    navigate(`/poc-hub/${item?.metadata?.name}`);
  };

  const metaMap = {
    png: 'data:image/png;base64,',
    svg: 'data:image/svg+xml;base64,',
    jpg: 'data:image/jpeg;base64,',
    jpeg: 'data:image/jpeg;base64,'
  };
  
  const getDefaultMetadata = () => {
    let imageKey = item?.metadata.image;
    console.log(imageKey, item?.metadata?.name)
    if (imageKey !== undefined) {
      const ext = String(imageKey).substring(
        String(imageKey)?.lastIndexOf('.') + 1,
      );
      console.log(ext)
      // @ts-ignore
      return metaMap[ext];
    }
    return 'data:image/svg+xml;base64,';
  };

  useEffect(() => {
    if (
      item !== undefined &&
      item.metadata.image !== undefined &&
      String(item.metadata.image).trim().length > 0
    ) {
      toolboxApi
        .getImageData(item?.metadata?.image)
        .then((data: any) => {
          setImageData(data);
        })
        .catch(() => {
          notificationApi.sendNotification({
            message: `Failed to load image for tool ${item?.metadata.title}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });

      if (item.metadata.imageMetaForBase64 === undefined) {
        setImageMeta(getDefaultMetadata());
      } else {
        setImageMeta(
          String(item.meatdata.imageMetaForBase64).replace(',', '') + ',',
        );
      }
    }
  }, []);

  const ownerName = item?.spec?.owner?.split(':')[1];

  return (
    <div className={classes.listCard}>
      {item?.metadata?.image ? (
        <img
          src={imageData ? `${imageMeta}${imageData}` : ''}
          alt=""
          className={classes.image}
          onClick={() => navigateToDetailsPage(item)}
        />
      ) : (
        <img
          src={noImage}
          alt=""
          className={classes.image}
          onClick={() => navigateToDetailsPage(item)}
        />
      )}
      <div className={classes.cardContent}>
        <div className={classes.listCardContent}>
          <Typography
            component="div"
            className={classes.cardTitle}
            onClick={() => navigateToDetailsPage(item)}
          >
            {item?.metadata?.name}
          </Typography>

          <Typography
            component="div"
            className={classes.cardOwner}
            onClick={() => navigateToDetailsPage(item)}
          >
            <span className={classes.by}>by</span>
            <span className={classes.ownerName}>{ownerName}</span>
          </Typography>

          <Typography
            component="div"
            className={classes.cardDescription}
            onClick={() => navigateToDetailsPage(item)}
          >
            {item?.metadata?.description}
          </Typography>
        </div>
        <div>
          <Button className={classes.CardActionButton}>
            {isStarredEntity(item) ? (
              <BookmarkIcon
                onClick={() => {
                  toggleStarredEntity(item);
                }}
                className={classes.SvgColorFill}
              />
            ) : (
              <BookmarkBorderOutlinedIcon
                onClick={() => {
                  toggleStarredEntity(item);
                }}
                className={classes.SvgColorFill}
              />
            )}
          </Button>
        </div>
      </div>
      <div className={classes.tags}>
        {item?.metadata?.tags &&
          item?.metadata?.tags.slice(0,2).map((tag: any) => (
            <Chip
              label={tag.slice(0, 15)}
              key={tag}
              className={classes.tagChip}
            />
          ))}
          {
            item?.metadata?.tags && item?.metadata?.tags.length > 2 && <Chip
              label="..."
              className={classes.tagChip}
            />
          }
      </div>
    </div>
  );
};
