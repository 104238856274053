import { withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import MaterialBreadcrumbs from '@material-ui/core/Breadcrumbs';
import React, { ComponentProps, Fragment } from 'react';

type Props = ComponentProps<typeof MaterialBreadcrumbs>;

export type BreadcrumbsClickableTextClassKey = 'root';

const ClickableText = withStyles(
  {
    root: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  { name: 'BackstageBreadcrumbsClickableText' },
)(Typography);

export type BreadcrumbsStyledBoxClassKey = 'root';

const StyledBox = withStyles(
  {
    root: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
      color: 'inherit',
    },
  },
  { name: 'BackstageBreadcrumbsStyledBox' },
)(Box);

export type BreadcrumbsItalicBoxClassKey = 'root';

const ItalicBox = withStyles(
  {
    root: { fontStyle: 'italic' },
  },
  { name: 'BackstageBreadcrumbsItalicBox' },
)(Box);

export function Breadcrumbs(props: Props) {
  const { children, ...restProps } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const childrenArray = React.Children.toArray(children);

  const [firstPage, secondPage, thirdPage, ...expandablePages] = childrenArray;
  const currentPage = expandablePages.length
    ? expandablePages.pop()
    : childrenArray[childrenArray.length - 1];
  const hasHiddenBreadcrumbs = childrenArray.length > 5;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Fragment>
      <MaterialBreadcrumbs aria-label="breadcrumb" {...restProps}>
        {childrenArray.slice(0, childrenArray?.length - 1).map(page => (
          <StyledBox clone key={`custom-breadcrumbs-${page}`}>
            {page}
          </StyledBox>
        ))}
        {/* {childrenArray.length > 1 && <StyledBox clone>{firstPage}</StyledBox>}
        {childrenArray.length > 2 && <StyledBox clone>{secondPage}</StyledBox>}
        { childrenArray.length > 3 && <StyledBox clone>{thirdPage}</StyledBox>}
        {hasHiddenBreadcrumbs && (
          <ClickableText onClick={handleClick}>...</ClickableText>
        )} */}
        {currentPage && <ItalicBox>{currentPage}</ItalicBox>}
      </MaterialBreadcrumbs>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List>
          {expandablePages.map((pageLink, index) => (
            <ListItem key={`custom-breadcrumbs-${index}`} button>
              <StyledBox clone>{pageLink}</StyledBox>
            </ListItem>
          ))}
        </List>
      </Popover>
    </Fragment>
  );
}
