import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const RegexIcon = (props: any) => (
  <SvgIcon viewBox={'48 48'} style={{ transform: 'scale(3)' }} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8832 7.375H13.5542V9.51519L15.3142 8.44475L15.6484 9.05319L13.8856 10.125L15.6484 11.1968L15.3149 11.8053L13.5542 10.7348V12.875H12.8832V10.7348L11.1232 11.8053L10.7891 11.1968L12.5519 10.125L10.7891 9.05319L11.1226 8.44475L12.8832 9.51519V7.375ZM7.375 12.875H10.125V15.625H7.375V12.875Z"
      fill="#FF9742"
    />
    <path d="M6 6H3V18H6" stroke="#FF9742" />
    <path d="M17 18L20 18L20 6L17 6" stroke="#FF9742" />
  </SvgIcon>
);

export default RegexIcon;
