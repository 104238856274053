import React, { useState, createContext } from 'react';

const initialState = {
  data: [],
  setData: '',
  deleteModalOpen: false,
  setDeleteModalopen: '',
  gamificationlevelData: [],
  registerFormState: 'clear',
};
export const PromotionContext = createContext<any>(initialState);

export const PromotionProvider = (props: any) => {
  const [data, setData] = useState<any>(initialState.data);
  const [gamificationlevelData, setGamificationlevelData] = useState<any>([]);
  const [deleteModalOpen, setDeleteModal] = useState(false);
  const [toggleSwaggerButton, setToggleSwaggerButton] = useState(false);
  const [swaggerJson, setSwaggerJson] = useState();
  const [toggleHelpButton, setToggleHelpButton] = useState(false);
  const [helpUrl, setHelpUrl] = useState("");
  const [id, setId] = useState('');
  const [assestURL, setAssetURL] = useState('');
  const [registerApiData, setRegisterApiData] = useState(null);
  const [registerFormState, setRegisterFormState] = useState(
    initialState.registerFormState,
  );
  return (
    <PromotionContext.Provider
      value={{
        data: data,
        setData: setData,
        deleteModalOpen: deleteModalOpen,
        setDeleteModal: setDeleteModal,
        setId: setId,
        id: id,
        assestURL: assestURL,
        setAssetURL: setAssetURL,
        registerApiData,
        setRegisterApiData,
        registerFormState,
        setRegisterFormState,
        swaggerJson,
        setSwaggerJson,
        toggleSwaggerButton,
        setToggleSwaggerButton,
        setToggleHelpButton:setToggleHelpButton,
        toggleHelpButton:toggleHelpButton,
        setHelpUrl:setHelpUrl,
        helpUrl:helpUrl,
        setGamificationlevelData,
        gamificationlevelData
      }}
    >
      {props.children}
    </PromotionContext.Provider>
  );
};
