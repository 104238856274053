import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  createStyles,
  Grid,
  IconButton,
  InputBase,
  LinearProgress,
  ListItem,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core';
import { Content, PageWithHeader } from '@backstage/core-components';
import { HelpComponent } from '../utils/helpComponent';
import BackButton from '../utils/backButton';
import HELP_URL from '../utils/helpLinkConstant';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';

import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';

import DoneAllIcon from '@material-ui/icons/DoneAll';
import SearchIcon from '@material-ui/icons/Search';
import ClearButton from '@material-ui/icons/Clear';
import ViewListIcon from '@material-ui/icons/ViewList';
import TaxanomyCard from './TaxanomyCard';
import { useApi } from '@backstage/core-plugin-api';
import { leanixApiRef, LeanixApi } from '../../apis/leanixApi';
import {
  notificationApiRef,
  NotificationApi,
} from '../../apis/notificationApi';
import { useStarredEntities } from '@backstage/plugin-catalog-react';

import { RenderGraphView } from './renderGraphView';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leanixCodePage: {
      [theme.breakpoints.down('sm')]: {
        padding: '0',
        marginTop: '20px',
      },
    },

    root: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '10px',
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        marginRight: '0px',
      },
    },
    themeColor: {
      backgroundColor: theme.palette.background.paper,
      borderLeftColor: theme.palette.primary.main,
      '& li': {
        borderLeftColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.border}`,
        '&:hover button': {
          color: theme.palette.primary,
          backgroundColor: `${theme.palette.background.tertiary}`,
        },
        '&.active button': {
          borderLeft: `2px solid ${theme.palette.primary.main}`,
          backgroundColor: `${theme.palette.background.tertiary}`,
        },
      },
    },
    btnTabs: {
      borderRadius: '4px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '10px',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    btnDefault: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '36px',
      backgroundColor: 'transparent',
      color: '#00000061',
      textTransform: 'none',
      padding: '6px 10px',
      [theme.breakpoints.down('sm')]: {
        flex: '1',
      },
    },
    btnSelected: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '36px',
      padding: '6px 10px',
      backgroundColor: '#CFCFCF',
      color: '#00000094',
      textTransform: 'none',
      [theme.breakpoints.down('sm')]: {
        flex: '1',
      },
    },
    leanixPage: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flexStart',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    icons: {
      marginLeft: '5px',
      color: '#323232',
    },
    loading: {
      margin: '10px 0px',
    },
    listHeading: {
      margin: '5px 0px 5px 0px',
    },
    CountSpan: {
      border: '1px solid rgba(26, 26, 26, 0.46)',
      borderRadius: '16px',
      padding: '0px 12px',
      position: 'absolute',
      right: '10px',
    },
  }),
);

const LeanIXPage = () => {
  const classes = useStyles();
  const [selectedBtn, setSelectedBtn] = useState('list');
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [bookmarkCount, setBookmarkCount] = useState(0);
  const [allCount, seAllCount] = useState(0);
  const [searchText, setSearchText] = useState<string | undefined>();

  const leanix: LeanixApi = useApi(leanixApiRef);

  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const { starredEntities } = useStarredEntities();

  const getAllData = () => {
    setIsLoading(true);
    leanix
      .getLeanix()
      .then((data: any) => {
        setData(data);
        seAllCount(data.length);
        setIsLoading(false);
      })
      .catch(() => {
        notificationApi.sendNotification({
          message: `Failed to load Data`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    if (starredEntities !== undefined && starredEntities?.size > 0) {
      const ids = [...starredEntities]
        ?.filter(i => i?.indexOf('leanix:') === 0)
        .map(j => `${j}`.substring(j?.indexOf('/') + 1));
      setBookmarkCount(ids?.length);
    }
    if (selectedFilter === 'bookmark') {
      getAllBookmarkedData(data);
    }
  }, [starredEntities]);

  const handleBookMark = () => {
    setSelectedFilter('bookmark');
    getAllBookmarkedData(data);
  };
  const handleAll = () => {
    setSelectedFilter('all');
    getAllData();
  };

  const getAllBookmarkedData = (data: any) => {
    let ids: any = [];
    if (starredEntities !== undefined && starredEntities?.size > 0) {
      ids = [...starredEntities]
        ?.filter(i => i?.indexOf('leanix:') === 0)
        .map(j => `${j}`.substring(j?.indexOf('/') + 1));
    }
    const filterBookmarkData: any = [];
    ids &&
      ids?.map((bookmark: any) => {
        const filterData = searchPages(data, bookmark);
        filterBookmarkData.push([...filterData]);
      });

    var merged = [].concat.apply([], filterBookmarkData);
    const filterUnique = [
      ...new Map(merged.map(item => [item['id'], item])).values(),
    ];
    setData(filterUnique || []);
  };

  const searchPages = (data: any[], nameToFind: string) => {
    const find = ({ name, children }: any) =>
      name?.toLowerCase().includes(nameToFind?.toLowerCase()) ||
      children?.some(find);
    return data?.filter(find);
  };

  const onChangeSearchText = async (e: any) => {
    setSearchText(e?.target?.value?.trim());
    if (e?.target?.value?.trim().length) {
      let searchResults: any = searchPages(data, e?.target?.value?.trim());
      setData(searchResults);
    } else {
      getAllData();
    }
  };

  return (
    <PageWithHeader
      title="Capability Mapping"
      themeId="home"
      subtitle={
        <DevxBreadCrumb
          routes={[
            { type: 'link', link: '/', text: 'Home' },
            {
              type: 'text',
              link: ' ',
              text: 'Capability Mapping',
            },
          ]}
        />
      }
    >
      <Content>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <BackButton />
            <HelpComponent helpUrl={HELP_URL.CAPABILITY_MAPPING} />
          </Grid>
        </Grid>
        <div className={`codePage ${classes.leanixCodePage}`}>
          <Grid container>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <div className={classes.listHeading}>Personal</div>
              <div className={`customFilters ${classes.themeColor}`}>
                <ListItem
                  className={selectedFilter === 'bookmark' ? 'active' : ''}
                >
                  <Button
                    variant="outlined"
                    name="bookmark"
                    startIcon={<BookmarkBorderIcon />}
                    onClick={() => handleBookMark()}
                  >
                    Bookmark
                    <span className={classes.CountSpan}>{bookmarkCount}</span>
                  </Button>
                </ListItem>
              </div>
              <div className={classes.listHeading}>CBRE DEVX MarketPlace</div>
              <div className={`customFilters ${classes.themeColor}`}>
                <ListItem className={selectedFilter === 'all' ? 'active' : ''}>
                  <Button
                    variant="outlined"
                    name="All"
                    startIcon={<DoneAllIcon />}
                    onClick={() => handleAll()}
                  >
                    All
                    <span className={classes.CountSpan}>{allCount}</span>
                  </Button>
                </ListItem>
              </div>
              {/* <div className="customFilters">
                <ListItem>
                  <Button
                    color="primary"
                    variant="outlined"
                    name="All"
                    startIcon={<BusinessCenterIcon />}
                  >
                    Business
                    <span className={classes.CountSpan}>{data?.length}</span>
                  </Button>
                </ListItem>
              </div> */}
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className={classes.leanixPage}>
                  <Paper component="form" className={classes.root}>
                    <IconButton disabled type="submit" aria-label="search">
                      <SearchIcon />
                    </IconButton>
                    <InputBase
                      placeholder="Search "
                      fullWidth
                      inputProps={{ 'aria-label': 'search ' }}
                      value={searchText}
                      onChange={e => onChangeSearchText(e)}
                    />
                    <IconButton
                      aria-label="search"
                      onClick={() => {
                        setSearchText('');
                        getAllData();
                      }}
                    >
                      <ClearButton />
                    </IconButton>
                  </Paper>
                  <ButtonGroup
                    disableElevation
                    size="large"
                    variant="outlined"
                    className={classes.btnTabs}
                  >
                    <Button
                      className={
                        selectedBtn === 'list'
                          ? classes.btnSelected
                          : classes.btnDefault
                      }
                      onClick={() => setSelectedBtn('list')}
                    >
                      List
                      <ViewListIcon className={classes.icons} />
                    </Button>
                    {/* <Button
                      className={
                        selectedBtn === "chart"
                          ? classes.btnSelected
                          : classes.btnDefault
                      }
                      onClick={() => setSelectedBtn("chart")}
                    >
                      Chart
                      <AccountTreeOutlinedIcon className={classes.icons} />
                    </Button> */}
                  </ButtonGroup>
                </div>
              </Grid>
              {isLoading && <LinearProgress className={classes.loading} />}
              {selectedBtn === 'list' ? (
                <Grid container>
                  {data &&
                    data.map((item, index) => (
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <TaxanomyCard data={item} key={index} />
                      </Grid>
                    ))}
                </Grid>
              ) : (
                <Grid container style={{ marginTop: '16px' }}>
                  {data &&
                    data.map((item, index) => (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <RenderGraphView data={item} key={index} />
                      </Grid>
                    ))}
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
      </Content>
    </PageWithHeader>
  );
};
export default LeanIXPage;
