import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Paper,
  Card,
  Chip,
  Typography,
  CardHeader,
  CardContent,
  CardActions,
  ButtonGroup,
  Button,
  Grid,
  Divider,
} from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import { useNavigate } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import EditRounded from '@material-ui/icons/EditRounded';
import ThumbUpIcon from '../icons/thumbUp';
import ThumbDownIcon from '../icons/thumbDown';
import { AuthContext } from '../../../providers/AuthProvider';
import Constants from '../Constants';
import { displayTime } from '../../utils/dateUtils';
import { feedbackApiRef } from '../../../apis/feedbackApis';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { userProfileApiRef } from '../../../apis/userProfileApi';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import UserAvatar from '../../common/UserAvatar';

type Feedback = {
  username: string;
  liked: boolean;
  title: string;
  feedback: string;
  feedbackType: string;
  Creationdatetime: string;
  status: string;
  statusapprover?: string;
  Approveddatetime?: string;
  Totalcount: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightAlignItems: {
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'end',
    },
    leftAlignItems: {
      // float: 'left',
      marginLeft: '5px',
      marginBottom: `${theme.spacing(1.65)}px`,
      display: 'flex',
      gap: `${theme.spacing(0.625)}px`,
      alignItems: 'center',
    },
    cardHeader: {
      padding: '20px 10px 10px 10px',
    },
    cardTypeTitle: {
      overflow: 'hidden',
      lineHeight: 1.5,
      wordBreak: 'break-word',
      fontFamily: 'inherit',
      fontSize: '18px',
      fontWeight: 600,
      textDecoration: 'none',
      marginTop: '-13px',
    },
    cardContent: {
      padding: '0px 10px 0px 10px',
      height: '100%',
      fontfamily: 'Calibre-R-Regular',
      fontSize: '14px',
      lineHeight: '1.334',
      letterSpacing: '0px',
      textAlign: 'left',
      color: '#26262699',
      wordBreak: 'break-word',
      overflow: 'hidden',
    },
    cardTypeText: {
      overflow: 'hidden',
      lineHeight: 1.5,
      wordBreak: 'break-word',
      fontFamily: 'inherit',
      fontSize: '12px',
      textDecoration: 'none',
    },
    feedbackText: {
      padding: '12px 5px',
    },
    actionRightContainer: {
      display: 'inline-block',
      marginRight: '5%',
      marginBottom: '1%',
    },
    actionTimeContainer: {
      display: 'inline-block',
      marginRight: '1%',
      marginBottom: '1%',
      marginTop: '-6px',
    },
    actionClickableContainer: {
      cursor: 'pointer',
      display: 'inline-block',
      marginRight: '1%',
      marginBottom: '1%',
      marginTop: '3px',
    },
    actionContainer: {
      display: 'inline-block',
      marginRight: '1%',
      marginBottom: '1%',
    },
    chipUnderReview: {
      marginTop: '6px',
      width: '125.5px',
    },
    likeButtonGroupContainer: {
      width: '30px',
    },
    likeButtonGroup: {
      border: 'none',
    },
    likeButtonSelected: {
      color: theme.palette.primary.main,
      border: 'none',
      padding: '5px',
      '&:disabled': {
        border: 'none',
      },
    },
    editStatusText: {
      overflow: 'hidden',
      lineHeight: '1.334',
      wordBreak: 'break-word',
      fontFamily: 'inherit',
      fontSize: '12px',
      fontWeight: 600,
      whiteSpace: 'nowrap',
      textDecoration: 'none',
      color: '#6B778C',
    },
    editButton: {
      padding: '0px',
      marginTop: '-2px',
      minWidth: '40px',
      color: theme.palette.primary.main,
    },
    feedbackTextDiv: {
      paddingTop: '3px',
      color: theme.palette.text.tertiary,
    },
    feedbackCard: {
      ['@media (max-width:920px) and (min-width: 280px)']: {
        padding: '13px 7px 0px 9px !important',
      },
    },
    disableFlex: {
      display: 'block',
    },
    circleIcon: {
      fontSize: `${theme.spacing(0.8)}px`,
      marginRight: `${theme.spacing(0.5)}px`,
      color: theme.palette.primary.main,
    },
    feedbackTypeSection: {
      marginLeft: `${theme.spacing(1)}px`,
    },
    feedbackTypeLabel: {
      color: theme.palette.text.tertiary,
    },
    divider: {
      height: `${theme.spacing(3.75)}px`,
      margin: `0px ${theme.spacing(1.25)}px`,
      backgroundColor: theme.palette.primary.main,
    },
    userName: {
      color: theme.palette.text.secondary,
      fontWeight: 600,
    },
    feedbackTypeValue: {
      color: theme.palette.infoText,
      fontWeight: 600,
    },
    cardPadding: {
      padding: `${theme.spacing(0.75)}px`,
    },
  }),
);

const FeedbackCard = (props: any) => {
  const navigate = useNavigate();
  const type = props.feedback.feedbacktype;
  const classes = useStyles();
  const [isLiked, setIsLiked] = useState<boolean | undefined | null>(null);
  const [totalLikes, setTotalLikes] = useState(0);
  const [totalDisLikes, setTotalDisLikes] = useState(0);
  const [inProgress, setInProgress] = useState(false);
  const { profEmail } = useContext(AuthContext);
  const userId = profEmail?.toLowerCase();
  //   const feedbackAxiosApi = feedbackApi(apiBaseUrl, accessToken);

  const feedbackApi = useApi(feedbackApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const userProfileApi = useApi(userProfileApiRef);
  const usernameRef = useRef(null);

  const onMouseEnter = () => {
    const email: string | undefined = props.feedback.username;

    userProfileApi.setUserProfileState({
      email,
      anchorRef: usernameRef?.current,
    });
  };

  useEffect(() => {
    setTotalLikes(props.feedback.likecount);
    setTotalDisLikes(props.feedback.dislikecount);
    setIsLiked(props.feedback.liked);
  }, []);

  const handleEditClick = () => {
    navigate(`/feedback/edit/${props.feedback.id}`);
  };

  const displayName = (email: string) => {
    try {
      const userName = email.split('@')[0];
      const [firstName, lastName] = userName.split('.');
      return `${firstName || 'Anonymous'} ${lastName || ''}`;
    } catch {
      return 'Anonymous User';
    }
  };

  const handleLikes = (value?: boolean) => {
    // todo add in save like feature
    if (value !== isLiked && value !== null) {
      setInProgress(true);

      feedbackApi
        .setFeedbackLike(props.feedback.id, value, userId || '')
        .then(() => {
          if (value) {
            setTotalLikes(totalLikes + 1);
            if (isLiked !== null) {
              setTotalDisLikes(totalDisLikes - 1);
            }
          } else {
            setTotalDisLikes(totalDisLikes + 1);
            if (isLiked !== null) {
              setTotalLikes(totalLikes - 1);
            }
          }
          setIsLiked(value);
          setInProgress(false);
        })
        .catch((err: any) => {
          setInProgress(false);
          notificationApi.sendNotification({
            message: `Error occurred - ${err?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });
    }
    return;
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.feedbackCard}
      >
        <Card className={classes.cardPadding}>
          <CardHeader
            title={props.feedback.title}
            classes={{
              title: classes.cardTypeTitle,
            }}
            className={classes.cardHeader}
            action={
              <div>
                {props.isAdmin && (
                  <Button
                    className={classes.editButton}
                    onClick={handleEditClick}
                  >
                    <EditRounded />
                  </Button>
                )}
              </div>
            }
          />
          <CardContent className={classes.cardContent}>
            <div className={classes.feedbackTextDiv}>
              <Typography>{props.feedback.feedback}</Typography>
            </div>
          </CardContent>
          <CardActions disableSpacing classes={{ root: classes.disableFlex }}>
            <div className={classes.leftAlignItems}>
              <UserAvatar
                userEmail={props.feedback.username}
                customStyles={{
                  width: '24px',
                  height: '24px',
                  fontSize: '14px',
                  fontWeight: 'normal',
                }}
              />
              <Typography className={classes.cardTypeText}>
                <span
                  className={classes.userName}
                  onMouseEnter={onMouseEnter}
                  ref={usernameRef}
                >
                  {displayName(props.feedback.username)}
                </span>
                {' - '}
                <span className={classes.feedbackTypeLabel}>
                  {displayTime(props.feedback.Creationdatetime)}
                </span>
                <span className={classes.feedbackTypeSection}>
                  <FiberManualRecord
                    fontSize="small"
                    classes={{ root: classes.circleIcon }}
                  />
                  <span className={classes.feedbackTypeLabel}>
                    Feedback type :{' '}
                  </span>
                  <span className={classes.feedbackTypeValue}>
                    {props.feedback.feedbacktype}
                  </span>
                </span>
              </Typography>
            </div>
            <div className={classes.rightAlignItems}>
              <ButtonGroup
                className={classes.likeButtonGroup}
                aria-label="like button group"
              >
                <Button
                  className={classes.likeButtonSelected}
                  onClick={() => {
                    handleLikes(true);
                  }}
                  disabled={inProgress}
                >
                  <ThumbUpIcon
                    fill={isLiked !== null && isLiked && '#003F2D'}
                  />
                  <Typography color="primary">{`(${totalLikes})`}</Typography>
                </Button>
                <Button
                  className={classes.likeButtonSelected}
                  onClick={() => {
                    handleLikes(false);
                  }}
                  disabled={inProgress}
                >
                  <ThumbDownIcon
                    fill={isLiked !== null && !isLiked && '#003F2D'}
                  />
                  <Typography color="primary">
                    {`(${totalDisLikes})`}
                  </Typography>
                </Button>
              </ButtonGroup>
              <Divider
                orientation="vertical"
                variant="fullWidth"
                flexItem
                classes={{ root: classes.divider }}
              />
              {props.feedback.category && (
                <Chip size="small" label={props.feedback.category} />
              )}
              <Chip size="small" label={props.feedback.status} />
            </div>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

export default FeedbackCard;
