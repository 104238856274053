import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Theme,
  makeStyles,
  createStyles,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    DialogBox: {
      minWidth: 300,
    },
    dialogContent: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '-0.01em',
      color: theme.palette.text.secondary,
    },
    dialogTitle: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: ' -0.015em',
      color: theme.palette.text.secondary,
      display: 'flex',
      alignItems: 'center',
    },
    reasonField: {
      marginTop: '15px',
    },
  }),
);

const IEDeleteModal = (props: any) => {
  const {
    title = '',
    message = '',
    onNoClicked,
    onYesClicked,
    opened = false,
    onCloseDialog,
  } = props;

  const [open, setOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState<string | undefined>('');
  const [otherReasonText, setOtherReasonText] = useState('');

  const classes = useStyles();

  const [errors, setErrors] = useState<any>({
    selectedReaon: '',
    otherReasonText: '',
  });

  useEffect(() => {
    setOpen(opened);
  }, [opened]);

  const closeDialog = () => {
    setOpen(false);
    onCloseDialog();
  };

  const validateForm = () => {
    if (selectedReason === '') {
      setErrors((prevState: any) => {
        return {
          ...prevState,
          selectedReason: 'Please select a reason',
        };
      });
      return false;
    }
    if (selectedReason === 'Other' && otherReasonText === '') {
      setErrors((prevState: any) => {
        return {
          ...prevState,
          otherReasonText: 'Please enter a reason',
        };
      });
      return false;
    }

    return true;
  };

  const handleYesClicked = () => {
    if (validateForm()) {
      closeDialog();
      onYesClicked(
        selectedReason === 'Other' ? otherReasonText : selectedReason,
      );
    }
  };

  const handleNoClicked = () => {
    closeDialog();
    // onNoClicked();
  };

  const handleDropdownChanged = (event: any) => {
    setSelectedReason(event?.target?.value);
    setErrors((prevState: any) => {
      return {
        ...prevState,
        selectedReason: '',
      };
    });
  };

  const handleChange = (event: any) => {
    const value = event.target.value;
    setOtherReasonText(value);
    setErrors((prevState: any) => {
      return {
        ...prevState,
        otherReasonText: '',
      };
    });
  };
  const reasonList = [
    {
      label: 'Duplicate/Similar Question',
      value: 'Duplicate/Similar Question',
    },
    {
      label: 'Accidentally Posted',
      value: 'Accidentally Posted',
    },
    {
      label: 'No Longer needed',
      value: 'No Longer needed',
    },
    {
      label: 'Other',
      value: 'Other',
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      className={classes.DialogBox}
      fullWidth={false}
    >
      <DialogTitle id="dialog-title">
        <div className={classes.dialogTitle}>{title}</div>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleNoClicked}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <p className={classes.dialogContent}>{message}</p>
        <FormControl
          fullWidth
          variant="outlined"
          required
          {...(errors.selectedReason && {
            error: true,
          })}
        >
          <InputLabel id="demo-simple-select-label">Reason</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedReason}
            label="Reason"
            onChange={handleDropdownChanged}
          >
            {reasonList?.map((reason: any) => {
              return (
                <MenuItem value={reason.value} key={reason.value}>
                  {reason.label}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>{errors.selectedReason}</FormHelperText>
        </FormControl>
        {selectedReason === 'Other' && (
          <TextField
            className={classes.reasonField}
            label="Other Reason"
            required
            multiline
            minRows={4}
            value={otherReasonText}
            variant="outlined"
            onChange={handleChange}
            fullWidth
            {...(errors.otherReasonText && {
              error: true,
              helperText: errors.otherReasonText,
            })}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          autoFocus
          onClick={handleNoClicked}
        >
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={handleYesClicked}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IEDeleteModal;
