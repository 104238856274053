import { Grid, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import BackButton from '../../utils/backButton';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import { useStyles } from '../styles';
import { useGetActivityLog } from '../hooks/useGetActivityLog';
import { useParams } from 'react-router';
import ActivityTable from './cards/ActivityTable';
import SearchIcon from '@material-ui/icons/Search';
import { IActivityLog } from '../interfaces';

const ActivityPage = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState('');

  const { projectId } = useParams();
  const [activityLogs, loading] = useGetActivityLog(projectId!, true);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const fiteredActivityLogs = activityLogs.filter(
    (activityLog: IActivityLog) => {
      const logValues = Object.values(activityLog);
      return logValues.some(
        value =>
          typeof value === 'string' &&
          value?.toLowerCase().includes(searchText.toLowerCase()),
      );
    },
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.flexSpacebetween}
        >
          <BackButton />
          <div className={classes.flex}>
            <TextField
              variant="outlined"
              type="search"
              placeholder="Search activity"
              onChange={handleSearchChange}
              value={searchText}
              fullWidth
              InputProps={{ endAdornment: <SearchIcon /> }}
            />
            <HelpComponent helpUrl={HELP_URL.PROVISIONING_CENTER_ACTIVITY} />
          </div>
        </Grid>
      </Grid>

      <Grid container className={classes.pagePadding}>
        <Grid item xs={12}>
          <ActivityTable activityLog={fiteredActivityLogs} loading={loading} />
        </Grid>
      </Grid>
    </>
  );
};

// <Grid container className={classes.pagePadding}>
//   <Grid item xs={12} sm={12} md={12} lg={12}>
//     {filteredActivityData.length === 0 ? (
//       <Paper className={classes.activityCard}>
//         <Typography>No results found.</Typography>
//       </Paper>
//     ) : (
//       filteredActivityData
//         .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//         .map(activity => <ActivityCard activity={activity} />)
//     )}

//     <Paper className={classes.activityCard}>
//       <TablePagination
//         rowsPerPageOptions={[5, 10, 15]}
//         component="div"
//         count={filteredActivityData.length}
//         rowsPerPage={rowsPerPage}
//         page={page}
//         onPageChange={handleChangePage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//       />
//     </Paper>
//   </Grid>
// </Grid>

export default ActivityPage;
