import { JsonObject } from '@backstage/types';
import {
  Button,
  Step as StepUI,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import {
  useApi,
  featureFlagsApiRef,
  useApiHolder,
} from '@backstage/core-plugin-api';
import {
  FormProps,
  IChangeEvent,
  UiSchema,
  withTheme,
  FieldValidation,
} from '@rjsf/core';
import { Theme as MuiTheme } from '@rjsf/material-ui';
import React, { useMemo, useState } from 'react';
import { transformSchemaToProps } from './schema';
import cloneDeep from 'lodash/cloneDeep';
import * as fieldOverrides from './FieldOverrides';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { FieldExtensionOptions } from '../extensions/types';
import { createAsyncValidators } from './createAsyncValidators';
import { useStyles } from '../common/styles';
import { useNavigate } from 'react-router';
import { Alert } from '@material-ui/lab';
import { pocNameRegex } from '../utils';

const Form = withTheme(MuiTheme);
type Step = {
  schema: JsonObject;
  title: string;
} & Partial<Omit<FormProps<any>, 'schema'>>;

type Props = {
  /**
   * Steps for the form, each contains title and form schema
   */
  steps: Step[];
  formData: Record<string, any>;
  onChange: (e: IChangeEvent) => void;
  onReset: () => void;
  onFinish?: () => Promise<void>;
  widgets?: FormProps<any>['widgets'];
  fields?: FormProps<any>['fields'];
  customFieldExtensions: FieldExtensionOptions<any, any>[];
  errortext?: string;
  errortextForSource?: string;
  errorDesctext?: string;
  errorNametext?: String;
};

export function getUiSchemasFromSteps(steps: Step[]): UiSchema[] {
  const uiSchemas: Array<UiSchema> = [];
  steps.forEach(step => {
    const schemaProps = step.schema.properties as JsonObject;
    for (const key in schemaProps) {
      if (schemaProps.hasOwnProperty(key)) {
        const uiSchema = schemaProps[key] as UiSchema;
        uiSchema.name = key;
        uiSchemas.push(uiSchema);
      }
    }
  });
  return uiSchemas;
}

export function getReviewData(formData: Record<string, any>, steps: Step[]) {
  const uiSchemas = getUiSchemasFromSteps(steps);
  const reviewData: Record<string, any> = {};
  for (const key in formData) {
    if (formData.hasOwnProperty(key)) {
      const uiSchema = uiSchemas.find(us => us.name === key);

      if (!uiSchema) {
        reviewData[key] = formData[key];
        continue;
      }

      if (uiSchema['ui:widget'] === 'password') {
        reviewData[key] = '******';
        continue;
      }

      if (!uiSchema['ui:backstage'] || !uiSchema['ui:backstage'].review) {
        reviewData[key] = formData[key];
        continue;
      }

      const review = uiSchema['ui:backstage'].review as JsonObject;
      if (!review.show) {
        continue;
      }

      if (review.mask) {
        reviewData[key] = review.mask;
        continue;
      }
      reviewData[key] = formData[key];
    }
  }

  return reviewData;
}

export const MultistepJsonForm = (props: Props) => {
  const {
    formData,
    onChange,
    onReset,
    onFinish,
    fields,
    widgets,
    errortext,
    errortextForSource,
    errorDesctext,
    errorNametext,
  } = props;
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles();
  const navigate = useNavigate();
  const [disableButtons, setDisableButtons] = useState(false);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const featureFlagApi = useApi(featureFlagsApiRef);
  const featureFlagKey = 'backstage:featureFlag';

  const [isValidating, setValidating] = useState<boolean>(false);

  const [errors, setErrors] = useState<
    undefined | Record<string, FieldValidation>
  >();

  const filterOutProperties = (step: Step): Step => {
    const filteredStep = cloneDeep(step);
    const removedPropertyKeys: Array<string> = [];
    if (filteredStep.schema.properties) {
      filteredStep.schema.properties = Object.fromEntries(
        Object.entries(filteredStep.schema.properties).filter(
          ([key, value]) => {
            if (value[featureFlagKey]) {
              if (featureFlagApi.isActive(value[featureFlagKey])) {
                return true;
              }
              removedPropertyKeys.push(key);
              return false;
            }
            return true;
          },
        ),
      );

      // remove the feature flag property key from required if they are not active
      filteredStep.schema.required = Array.isArray(filteredStep.schema.required)
        ? filteredStep.schema.required?.filter(
            r => !removedPropertyKeys.includes(r as string),
          )
        : filteredStep.schema.required;
    }
    return filteredStep;
  };

  const steps = props.steps
    .filter(step => {
      const featureFlag = step.schema[featureFlagKey];
      return (
        typeof featureFlag !== 'string' || featureFlagApi.isActive(featureFlag)
      );
    })
    .map(filterOutProperties);

  const validators = useMemo(() => {
    return Object.fromEntries(
      props.customFieldExtensions.map(({ name, validation }) => [
        name,
        validation,
      ]),
    );
  }, [props.customFieldExtensions]);

  const apiHolder = useApiHolder();

  const validation = useMemo(() => {
    if (!steps || !steps[activeStep] || !steps[activeStep]?.schema) {
      return;
    }
    const { schema } = steps[activeStep];
    return createAsyncValidators(schema, validators, {
      apiHolder,
    });
  }, [steps, activeStep, validators, apiHolder]);

  const handleNext = async () => {
    // Get the validation for the fields
    setErrors(undefined);
    setValidating(true);

    let returnedValidation = {};
    if (validation !== undefined) {
      // invoke and wait for the results
      returnedValidation = await validation?.(formData);
    }

    setValidating(false);

    // after successful validation, move to next screen
    const hasErrors = Object.values(returnedValidation).some(i => {
      // @ts-expect-error
      return i?.__errors?.length > 0;
    });

    if (hasErrors) {
      setErrors(returnedValidation);
    } else {
      setErrors(undefined);
      //setActiveStep(prevActiveStep => prevActiveStep + 1);
      // handleCreate()
    }
  };

  const handleBack = () => {
    navigate(`/poc-hub`);
  };
  const handleCreate = async () => {
    if (!onFinish) {
      return;
    }

    setDisableButtons(true);
    try {
      await onFinish();
    } catch (err) {
      setDisableButtons(false);
      notificationApi.sendNotification({
        // @ts-expect-error
        message: err?.message,
        severity: 'error',
        disapperAfterMs: 2500,
      });
    }
  };

  const checkDisabled = () => {
    const regex = new RegExp(pocNameRegex);
    const pocName = formData?.name;

    if (
      pocName?.trim() &&
      regex.test(pocName) &&
      formData?.description?.trim() &&
      formData?.tags?.length > 0
    ) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map(({ title, schema, ...formProps }, index) => {
          return (
            <StepUI key={title}>
              <StepLabel
                aria-label={`Step ${index + 1} ${title}`}
                aria-disabled="false"
                tabIndex={0}
              >
                <Typography variant="h6" component="h3">
                  {title}
                </Typography>
              </StepLabel>
              <StepContent key={title}>
                <Form
                  extraErrors={errors}
                  formData={formData}
                  showErrorList={false}
                  fields={{ ...fieldOverrides, ...fields }}
                  widgets={widgets}
                  noHtml5Validate
                  onChange={onChange}
                  onSubmit={handleNext}
                  {...formProps}
                  {...transformSchemaToProps(schema)}
                >
                  {errortext && (
                    <Alert className={classes.errorForAdd} severity="error">
                      {errortext}
                    </Alert>
                  )}
                  {errortextForSource && (
                    <Alert className={classes.errorForAdd} severity="error">
                      {errortextForSource}
                    </Alert>
                  )}
                  {errorDesctext && (
                    <Alert className={classes.errorForAdd} severity="error">
                      {errorDesctext}
                    </Alert>
                  )}
                  {errorNametext && (
                    <Alert className={classes.errorForAdd} severity="error">
                      {errorNametext}
                    </Alert>
                  )}

                  {/* <FormHelperText error>{errortext}</FormHelperText>
                  <FormHelperText error>{errortextForSource}</FormHelperText>
                  <FormHelperText error>{errorDesctext}</FormHelperText>
                  <FormHelperText error>{errorNametext}</FormHelperText> */}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '16px',
                    }}
                  >
                    <Button onClick={handleBack}>Cancel</Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCreate}
                      disabled={checkDisabled()}
                    >
                      Continue
                    </Button>
                  </div>
                </Form>
              </StepContent>
            </StepUI>
          );
        })}
      </Stepper>
    </>
  );
};
