import { RuleOptions } from './types';

export default ({ theme }: RuleOptions) => `
/*==================  Reset  ==================*/
body {
  --md-text-color: var(--md-default-fg-color);
  --md-text-link-color: var(--md-accent-fg-color);
  --md-text-font-family: ${theme.typography.fontFamily};
  font-family: var(--md-text-font-family);
  background-color: unset;
}
`;
