import { useApi } from '@backstage/core-plugin-api';
import {
  CardContent,
  Chip,
  createStyles,
  Grid,
  LinearProgress,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userProfileApiRef } from '../../../apis/userProfileApi';
import UserAvatar from '../../common/UserAvatar';
import { timeSince } from '../../utils/dateUtils';
import BookmarkQuestion from './bookmarkQuestion';
import Votes from './Votes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postqueCardSection: {
      alignItems: ' flex-start',
      padding: '20px',
      gap: '10px',
      background: theme.palette.background.paper,
      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
      margin: '0px 0px 0px 30px',
      overflow: 'auto',
      [theme.breakpoints.down('sm')]: {
        margin: '0px 0px 0px 21px',
      },
      '& img': {
        display: 'block',
        maxWidth: '100%',
        height: 'auto',
      },
      '& a': {
        textDecoration: 'underline',
        color: '#06c',
      },
      '& .ql-syntax': {
        backgroundColor: '#23241f',
        color: '#f8f8f2',
        overflow: 'visible',
      },
    },
    chip: {
      margin: '10px 0px 0px 0px',
      width: '100%',
    },
    tag: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    timeStamp: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: '6px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
        margin: '15px 0px 15px 0px',
      },
    },
    time: {
      color: theme.palette.text.tertiary,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    postQueCard: {
      display: 'flex',
      marginTop: '28px',
    },
    bookmark: {
      marginTop: '8px',
    },
  }),
);

const PostQuestionCard = (props: any) => {
  const { queData, queUserData } = props;
  const classes = useStyles();
  const name = queData?.author && queData?.author.split('.')[0];

  const navigate = useNavigate();
  const usernameRef = useRef(null);
  const userProfileApi = useApi(userProfileApiRef);

  const [isLoading, setIsloading] = useState(false);

  const navigateToQuestionPage = (tagName: string) => {
    navigate('/information-exchange/conversations', {
      state: { tagName: `[${tagName?.trim()}]` },
    });
  };

  const onMouseEnter = () => {
    let email: string | undefined = queData?.author || '';
    userProfileApi.setUserProfileState({
      email,
      anchorRef: usernameRef?.current,
    });
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.postQueCard}
      >
        <div>
          <Votes
            value={queData.votes}
            loggedInVote={queUserData?.vote}
            actiontype="q"
            id={queData.id}
            onLoadingChanged={(value: boolean) => setIsloading(value)}
          />
          <div className={classes.bookmark}>
            <BookmarkQuestion id={queData.id} />
          </div>
        </div>

        <CardContent className={classes.postqueCardSection}>
          {isLoading && <LinearProgress />}
          <div dangerouslySetInnerHTML={{ __html: queData.description }} />
          <div className={classes.tag}>
            <div className={classes.chip}>
              {queData?.tags &&
                queData?.tags.map((tag: any) => (
                  <Chip
                    label={tag?.slice(0, 15)}
                    key={tag}
                    onClick={() => navigateToQuestionPage(tag)}
                  />
                ))}
            </div>
            <div className={classes.timeStamp}>
              <div>
                <UserAvatar
                  userEmail={queData.author}
                  customStyles={{
                    width: '32px',
                    height: '32px',
                    fontSize: '12px',
                    fontWeight: 'normal',
                  }}
                />
              </div>
              <div onMouseEnter={onMouseEnter} ref={usernameRef}>
                {name}
              </div>
              <div className={classes.time}>
                {timeSince(queData.createdat, 'asked ')}
              </div>
            </div>
          </div>
        </CardContent>
      </Grid>
    </Grid>
  );
};
export default PostQuestionCard;
