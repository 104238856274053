import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { compact } from 'lodash';
import {
  configApiRef,
  IconComponent,
  // @ts-ignore
  useApi,
} from '@backstage/core-plugin-api';
import {
  useEntityList,
  useStarredEntities,
  UserListFilter,
  UserListFilterKind,
} from '@backstage/plugin-catalog-react';
import {
  Card,
  List,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  MenuItem,
  Theme,
  Typography,
} from '@material-ui/core';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { identityApiRef } from '@backstage/core-plugin-api';
import { reduceEntityFilters } from './filters';
import { Entity } from '@backstage/catalog-model';

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    margin: theme.spacing(1, 0, 1, 0),
  },
  title: {
    margin: theme.spacing(1, 0, 0, 1),
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 'bold',
  },
  listIcon: {
    minWidth: 30,
    color: theme.palette.text.primary,
  },
  menuItem: {
    minHeight: theme.spacing(6),
  },
  groupWrapper: {
    margin: theme.spacing(1, 1, 2, 1),
  },
}));

export type ButtonGroup = {
  name: string;
  items: {
    id: 'owned' | 'starred' | 'all';
    label: string;
    icon?: IconComponent;
  }[];
};

function getFilterGroups(orgName: string | undefined): ButtonGroup[] {
  return [
    {
      name: 'Personal',
      items: [
        {
          id: 'starred',
          label: 'Bookmark',
          icon: BookmarkIcon,
        },
      ],
    },
    {
      name: orgName ?? 'Company',
      items: [
        {
          id: 'all',
          label: 'All',
        },
      ],
    },
  ];
}

type CapUserListPickerProps = {
  initialFilter?: UserListFilterKind;
};

export const CapUserListPicker = ({
  initialFilter,
}: CapUserListPickerProps) => {
  const classes = useStyles();
  const configApi = useApi(configApiRef);
  const orgName = configApi.getOptionalString('organization.name') ?? 'Company';
  const filterGroups = getFilterGroups(orgName);

  const [selectedUserFilter, setSelectedUserFilter] = useState(initialFilter);
  const [currentUser, setCurrentUser] = useState('');

  const identityApi = useApi(identityApiRef);
  identityApi.getProfileInfo().then((profile: any) => {
    let email = profile?.email;
    let user = email?.substring(0, email.indexOf('@'))?.toLowerCase();
    setCurrentUser(`user:${user}`);
  });

  const { isStarredEntity } = useStarredEntities();
  const isOwnedEntity = useCallback(
    (entity: Entity) => {
      return (
        currentUser !== undefined &&
        `${entity?.spec?.owner}`?.toLowerCase() === currentUser
      );
    },
    [currentUser],
  );

  // Static filters; used for generating counts of potentially unselected kinds
  const ownedFilter = useMemo(
    () => new UserListFilter('owned', isOwnedEntity, isStarredEntity),
    [isOwnedEntity, isStarredEntity],
  );

  const starredFilter = useMemo(
    () => new UserListFilter('starred', isOwnedEntity, isStarredEntity),
    [isOwnedEntity, isStarredEntity],
  );

  const { filters, updateFilters, backendEntities } = useEntityList();

  useEffect(() => {
    updateFilters({
      user: selectedUserFilter
        ? new UserListFilter(selectedUserFilter, isOwnedEntity, isStarredEntity)
        : undefined,
    });
  }, [selectedUserFilter, isOwnedEntity, isStarredEntity, updateFilters]);

  // To show proper counts for each section, apply all other frontend filters _except_ the user
  // filter that's controlled by this picker.
  const [entitiesWithoutUserFilter, setEntitiesWithoutUserFilter] =
    useState(backendEntities);
  useEffect(() => {
    const filterFn = reduceEntityFilters(
      compact(Object.values({ ...filters, user: undefined })),
    );
    setEntitiesWithoutUserFilter(backendEntities.filter(filterFn));
  }, [filters, backendEntities]);

  function getFilterCount(id: UserListFilterKind) {
    switch (id) {
      case 'owned':
        return entitiesWithoutUserFilter.filter(entity =>
          ownedFilter.filterEntity(entity),
        ).length;
      case 'starred':
        return entitiesWithoutUserFilter.filter(entity =>
          starredFilter.filterEntity(entity),
        ).length;
      default:
        return entitiesWithoutUserFilter.length;
    }
  }

  return (
    <Card className={classes.root}>
      {filterGroups.map(group => (
        <Fragment key={group.name}>
          <Typography variant="subtitle2" className={classes.title}>
            {group.name}
          </Typography>
          <Card className={classes.groupWrapper}>
            <List disablePadding dense>
              {group.items.map(item => (
                <MenuItem
                  key={item.id}
                  button
                  divider
                  onClick={() => setSelectedUserFilter(item.id)}
                  selected={item.id === filters.user?.value}
                  className={classes.menuItem}
                >
                  {item.icon && (
                    <ListItemIcon className={classes.listIcon}>
                      <item.icon fontSize="small" />
                    </ListItemIcon>
                  )}
                  <ListItemText>
                    <Typography
                      variant="body1"
                      data-testid={`user-picker-${item.id}`}
                    >
                      {item.label}
                    </Typography>
                  </ListItemText>
                  <ListItemSecondaryAction>
                    {getFilterCount(item.id) ?? '-'}
                  </ListItemSecondaryAction>
                </MenuItem>
              ))}
            </List>
          </Card>
        </Fragment>
      ))}
    </Card>
  );
};
