import React, { useState, useEffect, useMemo } from 'react';
import {
  makeStyles,
  ListItem,
  Button as MaterialButton,
  Theme,
  createStyles,
} from '@material-ui/core';
import CodeIcon from '.././Root/icons/codeIcon';
import * as constant from '.././utils/constant';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import InfoIcon from '@material-ui/icons/Info';
import AIIcon from '../Root/icons/aiIcon';
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined'
import SalesforceIcon from '.././Root/icons/salesforceIcon';
import RateReviewIcon from '@material-ui/icons/RateReview';
import UpdateIcon from '@material-ui/icons/Update';
import {
  UserListFilter,
  useEntityList,
  useEntityOwnership,
  useStarredEntities,
} from '@backstage/plugin-catalog-react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sortButton: {
      backgroundColor: theme.palette.background.paper,
      borderLeftColor: theme.palette.primary.main,
      '& li': {
        borderLeftColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.border}`,
        '&:hover button': {
          color: theme.palette.primary,
          backgroundColor: `${theme.palette.background.tertiary}`,
        },
        '&.active button': {
          borderLeft: `2px solid ${theme.palette.primary.main}`,
          backgroundColor: `${theme.palette.background.tertiary}`,
        },
      },
    },
  }),
);

const Filters = ({ view, sort, onClick }: any) => {
  const classes = useStyles();
  const { loading, error, entities, filters } = useEntityList();
  const [allAssetsData, setAllAssetsData] = useState<any>([]);
  const { isStarredEntity, toggleStarredEntity } = useStarredEntities();

  const salesforceCount =
    entities &&
    entities.filter(
      (item: any) =>
        item?.metadata?.devxstate?.toLowerCase() !==
          constant.NOT_APPROVED.toLowerCase() &&
        item.kind?.toLowerCase() === constant.COMPONENT &&
        item?.spec?.type?.toLowerCase() ===
          constant.SALESFORCE_COMPONENT_TYPE.toLowerCase(),
    ).length;

  const allCount = entities?.filter(
    (item: any) =>
      item.kind?.toLowerCase() === constant.COMPONENT.toLowerCase() &&
      constant.COMPONENT_TYPE.includes(item.spec?.type?.toLowerCase()) &&
      item?.metadata?.devxstate?.toLowerCase() !==
        constant.NOT_APPROVED.toLowerCase(),
  ).length;

  const iacCount =
    entities &&
    entities.filter(
      (item: any) =>
        item?.metadata?.devxstate?.toLowerCase() !==
          constant.NOT_APPROVED.toLowerCase() &&
        item.kind?.toLowerCase() === constant.COMPONENT &&
        item?.spec?.type?.toLowerCase() ===
          constant.IAC_COMPONENT_TYPE.toLowerCase(),
    ).length;

  const packageCount =
    entities &&
    entities.filter(
      (item: any) =>
        item?.metadata?.devxstate?.toLowerCase() !==
          constant.NOT_APPROVED.toLowerCase() &&
        item.kind?.toLowerCase() === constant.COMPONENT &&
        item?.spec?.type?.toLowerCase() ===
          constant.PACKAGE_COMPONENT_TYPE.toLowerCase(),
    ).length;

  const aiCount =
    entities &&
    entities.filter(
      (item: any) =>
        item?.metadata?.devxstate?.toLowerCase() !==
          constant.NOT_APPROVED.toLowerCase() &&
        item.kind?.toLowerCase() === constant.COMPONENT &&
        item?.spec?.type?.toLowerCase() ===
          constant.AI_COMPONENT_TYPE.toLowerCase(),
    ).length;

  const extensionCount = 
      entities.filter(
        (item: any) =>
        item?.metadata?.devxstate?.toLowerCase() !==
          constant.NOT_APPROVED.toLowerCase() &&
        item.kind?.toLowerCase() === constant.COMPONENT &&
        item?.spec?.type?.toLowerCase() ===
          constant.EXTENSION_COMPONENT_TYPE.toLowerCase(),
    ).length;

  useEffect(() => {
    const entityItems =
      entities &&
      entities.filter(
        (item: any) =>
          item.kind?.toLowerCase() === constant.COMPONENT.toLowerCase() &&
          constant.COMPONENT_TYPE.includes(item.spec?.type?.toLowerCase()) &&
          item?.metadata?.devxstate?.toLowerCase() !==
            constant.NOT_APPROVED.toLowerCase(),
      );
    setAllAssetsData(entityItems);

    localStorage.setItem('isReview', 'false');
  }, [entities, error, loading]);

  useEffect(() => {}, [allAssetsData]);
  const { isOwnedEntity } = useEntityOwnership();
  const starredFilter = useMemo(
    () => new UserListFilter('starred', isOwnedEntity, isStarredEntity),
    [isOwnedEntity, isStarredEntity],
  );

  const starredFilterItem =
    allAssetsData &&
    allAssetsData.length &&
    allAssetsData.filter((item: any) => starredFilter.filterEntity(item));

  return (
    <div className={`customFilters ${classes.sortButton}`}>
      <ListItem className={sort.isAllFilterClick ? 'active' : ''}>
        <MaterialButton
          variant="outlined"
          name="All"
          onClick={() => onClick('isAllFilterClick')}
          startIcon={<DoneAllIcon />}
        >
          All
          <span className="CountSpan">{allCount}</span>
        </MaterialButton>
      </ListItem>
      <ListItem className={sort.isFavoriteFilterClick ? 'active' : ''}>
        <MaterialButton
          variant="outlined"
          name="Favorite"
          onClick={() => onClick('isFavoriteFilterClick')}
          startIcon={<BookmarkIcon />}
        >
          Bookmark
          <span className="CountSpan">
            {starredFilterItem && starredFilterItem.length}
          </span>
        </MaterialButton>
      </ListItem>
      <ListItem className={sort.isPackageFilterClick ? 'active' : ''}>
        <MaterialButton
          variant="outlined"
          name="package"
          onClick={() => onClick('isPackageFilterClick')}
          startIcon={<CodeIcon />}
        >
          Package
          <span className="CountSpan">{packageCount}</span>
        </MaterialButton>
      </ListItem>
      <ListItem className={sort.isIacFilterClick ? 'active' : ''}>
        <MaterialButton
          variant="outlined"
          name="iac"
          onClick={() => onClick('isIacFilterClick')}
          startIcon={<InfoIcon />}
        >
          IaC
          <span className="CountSpan">{iacCount}</span>
        </MaterialButton>
      </ListItem>
      {salesforceCount > 0 && (
        <ListItem className={sort.isSalesforceFilterClick ? 'active' : ''}>
          <MaterialButton
            variant="outlined"
            name="salesforce"
            onClick={() => onClick('isSalesforceFilterClick')}
            startIcon={<SalesforceIcon />}
          >
            Salesforce
            <span className="CountSpan">{salesforceCount}</span>
          </MaterialButton>
        </ListItem>
      )}
      {aiCount > 0 && (
        <ListItem className={sort.isAIFilterClick ? 'active' : ''}>
          <MaterialButton
            variant="outlined"
            name="salesforce"
            onClick={() => onClick('isAIFilterClick')}
            startIcon={<AIIcon fontSize="large" />}
          >
            AI/ML
            <span className="CountSpan">{aiCount}</span>
          </MaterialButton>
        </ListItem>
      )}
      {
        extensionCount > 0 && (
          <ListItem className={sort.isExtensionFilterClick ? 'active' : ''}>
            <MaterialButton
              variant="outlined"
              name="extension"
              onClick={() => onClick('isExtensionFilterClick')}
              startIcon={<WidgetsOutlinedIcon />}
            >
              Extensions
              <span className="CountSpan">{extensionCount}</span>
            </MaterialButton>
          </ListItem>
        )
      }
      {view !== 'table' && (
        <>
          <ListItem className={sort.isTopRatingFilterClick ? 'active' : ''}>
            <MaterialButton
              variant="outlined"
              name="Rating"
              onClick={() => onClick('isTopRatingFilterClick')}
              startIcon={<RateReviewIcon />}
            >
              Ratings
            </MaterialButton>
          </ListItem>
          <ListItem
            className={sort.isRecentlyPublishedFilterClick ? 'active' : ''}
          >
            <MaterialButton
              variant="outlined"
              name="Recent"
              onClick={() => onClick('isRecentlyPublishedFilterClick')}
              startIcon={<UpdateIcon />}
            >
              Recently Published
            </MaterialButton>
          </ListItem>
        </>
      )}
    </div>
  );
};

export default Filters;
