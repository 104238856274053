import {
  Backdrop,
  CircularProgress,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import {
  InformationExchangeApi,
  informationExchangeApiRef,
} from '../../../apis/informationExchange';
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { IEContext } from '../providers/IEProvider';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icons: {
      padding: '0',
    },
    selectedColor: {
      color: '#50AF78',
    },
    defaultColor: {
      color: 'grey',
    },
    btnPadding: {
      padding: '0px',
      marginTop: '10px',
    },
    cursor: {
      cursor: 'pointer',
    },
    noCursor: {
      cursor: 'unset',
    },
    answer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);
const AcceptAnswer = (props: any) => {
  const { qid, aid, accepted, authorIsLoggedIn = false } = props;
  const classes = useStyles();

  const informationExchange: InformationExchangeApi = useApi(
    informationExchangeApiRef,
  );

  const myIEContext = useContext(IEContext);
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  // const [isLoading, setIsLoading] = useState<boolean>(false);

  /* Handler for Post Answer */
  const handleAcceptAnswer = () => {
    //  setIsLoading(true);
    informationExchange
      .acceptAnswer(qid, aid)
      .then((data: any) => {
        /*  setIsLoading(false);
        notificationApi.sendNotification({
          message: data,
          disapperAfterMs: 2500,
          severity: 'success',
        }); */
        myIEContext.setAcceptedAnswer({ qid, aid });
      })
      .catch((error: any) => {
        //  setIsLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  const iconBtnClasses = () => {
    const cursorClass = authorIsLoggedIn ? classes.cursor : classes.noCursor;
    return `${classes.btnPadding} ${cursorClass}`;
  };

  return (
    <div className={classes.answer}>
      {/*  {isLoading && (
        <Backdrop
          style={{
            color: '#fff',
            zIndex: 1,
          }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )} */}
      <div>
        <IconButton
          {...(authorIsLoggedIn && { onClick: handleAcceptAnswer })}
          disabled={!authorIsLoggedIn}
          className={iconBtnClasses()}
        >
          {accepted ? (
            <CheckCircleIcon className={classes.selectedColor} />
          ) : (
            <CheckCircleOutline className={classes.defaultColor} />
          )}
        </IconButton>
      </div>
      <div>{accepted ? 'Accepted Answer' : ' Accept Answer'}</div>
    </div>
  );
};
export default AcceptAnswer;
