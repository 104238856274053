import { RepoUrlPickerState } from './types';

export function serializeRepoPickerUrl(data: RepoUrlPickerState) {
  if (!data.host) {
    return undefined;
  }

  const params = new URLSearchParams();
  if (data.owner) {
    params.set('owner', data.owner);
  }
  if (data.repoName) {
    params.set('repo', data.repoName);
  }
  if (data.organization) {
    params.set('organization', data.organization);
  }
  if (data.workspace) {
    params.set('workspace', data.workspace);
  }
  if (data.project) {
    params.set('project', data.project);
  }
  if (data.userSpecifiedBranch) {
    params.set('branch', data.userSpecifiedBranch);
  }

  return `${data.host}?${params.toString()}`;
}

export function parseRepoPickerUrl(
  url: string | undefined,
): RepoUrlPickerState {
  let host = '';
  let owner = '';
  let repoName = '';
  let organization = '';
  let workspace = '';
  let project = '';
  let userSpecifiedBranch = '';

  try {
    if (url) {
      const parsed = new URL(`https://${url}`);
      host = parsed.host;
      owner = parsed.searchParams.get('owner') || '';
      repoName = parsed.searchParams.get('repo') || '';
      organization = parsed.searchParams.get('organization') || '';
      workspace = parsed.searchParams.get('workspace') || '';
      project = parsed.searchParams.get('project') || '';
      userSpecifiedBranch = parsed.searchParams.get('branch') || '';
    }
  } catch {
    /* ok */
  }

  return {
    host,
    owner,
    repoName,
    organization,
    workspace,
    project,
    userSpecifiedBranch,
  };
}

export const debounce = (fn: any, delay: any) => {
  let timeoutId: any;
  // @ts-expect-error
  return function (...args) {
    clearTimeout(timeoutId);
    // @ts-expect-error
    timeoutId = setTimeout(() => fn.apply(this, args), delay);
  };
};
