import React, { useState } from 'react';
import { Button, makeStyles, TablePagination, Paper } from '@material-ui/core';
import { InfoCard } from '@backstage/core-components';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteModal from '../../Promotions/DeleteModal';
import CancelIcon from '@material-ui/icons/Cancel';
import noImage from '../../../assest/no-image.jpg';
import PromotionImage from '../../Promotions/PromotionImage';
import { dateFormat } from '../../Promotions/dateFormat';
import { usePagination } from '../../common/Pagination/Pagination';

const useStyles = makeStyles(theme => ({
  infocardItem: {
    minWidth: '320px',
    marginBottom: '20px',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '& div:nth-child(1)': {
      wordBreak: 'break-all',
    },
    '& div:nth-child(2)': {
      display: 'flex',
    },
    '& button': {
      margin: '0px',
      padding: '0px',
      minWidth: 'auto',
      color: theme.palette.primary.main,
    },
    '& button:nth-child(2)': {
      marginTop: '-4px',
    },
  },
  promotionContainer: {
    display: 'flex',
    [theme.breakpoints.down(900)]: {
      flexDirection: 'column',
    },
  },
  imageContainer: {
    width: 200,
    height: 200,
    margin: 5,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
  image: {
    width: 200,
    height: 180,
    margin: 10,
  },
  promotionDescription: {
    margin: 10,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  promotionsIsActiveRow: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    ['@media (max-width:920px) and (min-width:280px) ']: {
      marginLeft: '20px',
    },
  },
  promotionsSubheader: {
    display: 'flex',
    marginTop: '-15px',
  },
  tablePaginationRoot: {
    overflow: 'hidden',
  },
  actionIcons: {
    color: theme.palette.primary.main,
  },
  tablePaginationActions: {
    '& button': {
      ['@media (max-width:400px) and (min-width: 280px)']: {
        padding: '0',
      },
    },
  },
}));

const InfoCardSubheader = (props: any) => {
  const classes = useStyles();
  return (
    <div className={classes.promotionsSubheader}>
      <div>{'creation date: ' + dateFormat(props.item.creationDate)}</div>
      <div className={classes.promotionsIsActiveRow}>
        <span>&nbsp;Active:</span>
        <span>
          {props.item.isActive ? <CheckCircleOutlineIcon /> : <CancelIcon />}
        </span>
      </div>
    </div>
  );
};
const InfoCardHeader = (props: any) => {
  const classes = useStyles();
  const { handleDeleteModal } = props;

  return (
    <div className={classes.headerContainer}>
      <div>{props.item.title}</div>
      <div>
        <Button className={classes.actionIcons}>
          <Link
            to={`/admin/manage-promotions/update-promotion/${props.item.title}/${props.item.promotionId}`}
          >
            <EditIcon></EditIcon>
          </Link>
        </Button>
        <Button onClick={handleDeleteModal}>
          <DeleteIcon></DeleteIcon>
        </Button>
      </div>
    </div>
  );
};
const ListItem = (props: any) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleDeleteModal = () => {
    setOpen(true);
  };
  const stringWordHandle = (str: any, count: number) => {
    let str1;

    if (str.length > count) {
      str1 = str.substring(0, count);
    } else {
      str1 = str;
    }
    return str1;
  };
  const SeeMore = (
    <>
      &nbsp;...
      <Link
        to={`/admin/manage-promotions/${props.item.title}/${props.item.promotionId}`}
      >
        <a className="see-more-link" href="">
          see more.
        </a>
      </Link>
    </>
  );
  const stringSeeMore = (str: any, count: number) => {
    if (str.length >= count) return SeeMore;
    else return null;
  };

  return (
    <>
      <InfoCard
        title={
          <InfoCardHeader
            item={props.item}
            handleDeleteModal={handleDeleteModal}
          />
        }
        subheader={<InfoCardSubheader item={props.item} />}
        className={classes.infocardItem}
      >
        <div className={classes.promotionContainer}>
          <div className={classes.imageContainer}>
            {props.item?.assetURL && props.item?.assetURL !== '' ? (
              <PromotionImage
                promotionId={props.item.promotionId}
                assetURL={props.item?.assetURL}
                title={props.item.title}
                className={classes.image}
                altText={`${props.item.title}`}
              />
            ) : (
              <img className={classes.image} src={noImage}></img>
            )}
          </div>
          <div className={classes.promotionDescription}>
            {stringWordHandle(props.item.description, 1000)}
            {stringSeeMore(props.item.description, 1000)}
          </div>
        </div>
      </InfoCard>
      <DeleteModal
        promotionData={props.promotionData}
        setPromotionData={props.setPromotionData}
        id={props.item.promotionId}
        deleteModalOpen={open}
        setDeleteModal={setOpen}
        assestURL={props.item.assestURL}
      />
    </>
  );
};

export const ListView = (props: any) => {
  const classes = useStyles();
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const PER_PAGE = recordsPerPage;

  // const count = Math.ceil(props.data.length / PER_PAGE);
  const _DATA = usePagination(props.data, PER_PAGE);
  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
    _DATA.jump(newPage);
  };
  const handleChangeRowsPerPage = (event: any) => {
    setRecordsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      {props.data.length === 0 && (
        <div style={{ textAlign: 'center', maxHeight: 900 }}>
          no data to display
        </div>
      )}
      <>
        {' '}
        {_DATA.currentData()?.map((item: any) => (
          <ListItem
            promotionData={props.data}
            key={item.creationDate}
            item={item}
            setPromotionData={props.setPromotionData}
          />
        ))}
        {props.data.length !== 0 && (
          <Paper>
            <TablePagination
              classes={{
                root: classes.tablePaginationRoot,
                actions: classes.tablePaginationActions,
              }}
              SelectProps={{
                title: 'perPageSelect',
                native: true,
              }}
              component="div"
              count={props.data.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={recordsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        )}
      </>
    </div>
  );
};
