import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import REGISTER_API_CONSTANTS from './Constants';
import DragDropFile from '../../common/DragDropFile';
import { PromotionContext } from '../../../providers/PromotionDataProvider';
import { useStyles } from './styles';
import { Autocomplete } from '@material-ui/lab';
import { registerApiRef } from '../../../apis/registerApis';
import { useApi } from '@backstage/core-plugin-api';
import { urlRegex } from '../../utils/Utils';
import { Link } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../home/allAssets/util';

const RegisterApi = () => {
  const registerApis = useApi(registerApiRef);

  const classes = useStyles();
  const navigate = useNavigate();
  const navigateToapiindexPage = () => {
    navigate('/API');
  };
  const myContext = useContext(PromotionContext);

  const inputRef = React.useRef(null);

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  /* error objects */
  const [errors, setErrors] = useState({
    apiTitle: '',
    apiDescription: '',
    apiType: '',
    apiScope: '',
    tags: [],
    lifeCycle: '',
    apiVersion: '',
    support: '',
    // apiCategory: '',
    apmNumber: '',
    apiDefinition: '',
    service: [],
    // performance: '',
  });

  /* formvalue objects */
  const initialValues = {
    apiTitle: '',
    apiDescription: '',
    apiType: '',
    apiScope: '',
    tags: [],
    lifeCycle: '',
    apiVersion: '',
    support: '',
    // apiCategory: '',
    apmNumber: '',
    apmDetails: '',
    apiDefinition: '',
    service: [],
    // performance: '',
  };

  /* formvalue objects */
  const [formValues, setFormValues] = useState({
    ...initialValues,
  });

  const onBlur = async () => {
    if (
      !REGISTER_API_CONSTANTS.registerApiForm.apmNumberRegex.test(
        formValues.apmNumber,
      )
    ) {
      setErrors({
        ...errors,
        apmNumber:
          REGISTER_API_CONSTANTS.registerApiForm.errors.apmNumberInvalid,
      });
    } else {
      try {
        const response = await registerApis.getApmNumberDetails(
          formValues.apmNumber,
        );
        if (response) {
          setErrors({
            ...errors,
            apmNumber: '',
          });
        }
      } catch {
        setErrors({
          ...errors,
          apmNumber:
            REGISTER_API_CONSTANTS.registerApiForm.errors.apmNumberInvalid,
        });
      }
    }
  };

  /* Set form data if the form data is there in context */
  useEffect(() => {
    if (myContext.registerFormState === 'clear') {
      setFormValues(initialValues);
    } else {
      setFormValues(myContext.registerApiData);
    }
  }, [myContext.registerApiData, myContext.registerFormState]);

  /* Save the register api form data */
  const saveDataToContext = () => {
    myContext.setRegisterApiData(formValues);
    myContext.setRegisterFormState('preserve');
  };

  /* Navigate to confirm details page */
  const navigateToConfirmDetailsPage = () => {
    saveDataToContext();
    navigate('/API/register-api/confirm');
  };

  /* Change Handler for all form inputs */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevState: any) => {
      return {
        ...prevState,
        [name]: name === 'apmNumber' ? value.trim() : value,
      };
    });
    setErrors((prevState: any) => {
      return {
        ...prevState,
        [name]: value
          ? ''
          : REGISTER_API_CONSTANTS.registerApiForm.errors[name as keyof Object],
      };
    });
    /* For support */
    if (name === 'support') {
      setErrors((prevState: any) => {
        return {
          ...prevState,
          support: '',
        };
      });
    }
  };

  /* error objects validation */
  const validate = () => {
    const errorObj: any = {};
    Object.keys(errors).forEach(key => {
      errorObj[key as keyof Object] = formValues[key as keyof Object]
        ? ''
        : REGISTER_API_CONSTANTS.registerApiForm.errors[key as keyof Object];
    });
    /* For Api version */
    const versionRegex = REGISTER_API_CONSTANTS.registerApiForm.versionRegex;
    if (
      formValues.apiVersion &&
      !new RegExp(versionRegex).test(formValues.apiVersion)
    ) {
      errorObj.apiVersion =
        REGISTER_API_CONSTANTS.registerApiForm.errors.apiVersionInvalid;
    }
    /* For supportUrl */
    errorObj.support =
      formValues.support === '' || formValues.support.match(urlRegex)
        ? ''
        : REGISTER_API_CONSTANTS.registerApiForm.errors.support;

    setErrors(errorObj);
    return Object.values(errorObj).every((x: any) => x === '');
  };

  const handleNextClicked = () => {
    if (validate()) {
      registerApis
        .getApmNumberDetails(formValues.apmNumber)
        .then((resData: any) => {
          if (resData?.data) {
            formValues.apmDetails = resData.data;
            navigateToConfirmDetailsPage();
          }
        })
        .catch(() => {
          setErrors({
            ...errors,
            apmNumber:
              REGISTER_API_CONSTANTS.registerApiForm.errors.apmNumberInvalid,
          });
        });
    }
  };

  /* Handler, once the file is browsed/dragger to the dragdrop component */
  const handleFileDrop = async (file: File) => {
    setFormValues((prevState: any) => {
      return {
        ...prevState,
        apiDefinition: '',
      };
    });
    try {
      if (!file.type.includes('json')) {
        throw new Error('Invalid Type');
      }
      const fileText = await file.text();
      if (!JSON.parse(fileText)) {
        throw new Error('Invalid JSON');
      }
      setFormValues((prevState: any) => {
        return {
          ...prevState,
          apiDefinition: JSON.stringify(JSON.parse(fileText)),
        };
      });
      setErrors({
        ...errors,
        apiDefinition: '',
      });
    } catch {
      setErrors({
        ...errors,
        apiDefinition:
          REGISTER_API_CONSTANTS.registerApiForm.errors.apiDefinition,
      });
    }
  };

  /* tags */
  const handleTags = (tagValues: any) => {
    const regex = new RegExp(/^[a-zA-Z]+$/);
    const tagValuesFiltered = tagValues.filter((tagValue: string) =>
      regex.test(tagValue),
    );
    setFormValues((prevState: any) => {
      return {
        ...prevState,
        tags: [...tagValuesFiltered],
      };
    });
  };

  // Services
  const handleServices = (serviceValues: any) => {
    const regex = new RegExp(/^[a-zA-Z]+$/);
    const serviceValuesFiltered = serviceValues.filter((serviceValue: string) =>
      regex.test(serviceValue),
    );
    setFormValues((prevState: any) => {
      return {
        ...prevState,
        service: [...serviceValuesFiltered],
      };
    });
  };

  /* Apitype dropdown array */
  const [apiTypeArray, setApiTypeArray] = useState([]);

  /* lifecycle dropdown array */
  const lifeCycleArray = [
    {
      value: 'Production',
      label: 'Production',
    },
    {
      value: 'Experimental',
      label: 'Experimental',
    },
    {
      value: 'Deprecated',
      label: 'Deprecated',
    },
  ];

  /* On Render call the getApiTypes */
  useEffect(() => {
    registerApis
      .getApiTypes()
      .then((resData: any) => {
        setApiTypeArray(resData);
      })
      .catch(() => {});
  }, []);

  return (
    /* Register Api Component */
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <div className={classes.registerPageTitle}>
          {REGISTER_API_CONSTANTS.registerApiForm.heading}
        </div>
      </Grid>

      <Grid item xs={12} sm={12}>
        <DragDropFile
          inputRef={inputRef}
          onFileDropped={handleFileDrop}
          error={errors.apiDefinition}
          success={formValues.apiDefinition}
        >
          <h2 className={classes.dragHeading}>
            Drag and Drop the swagger File of the API*
          </h2>
          <h2 className={classes.dragHeading}>or</h2>
          <Button
            className="upload-button"
            onClick={onButtonClick}
            variant="contained"
            color="primary"
          >
            Browse From Computer
          </Button>
          <p className={classes.jsonText}>Swagger Json File (.json format)</p>
          {errors.apiDefinition && (
            <p className={classes.jsonTextError}>{errors.apiDefinition}</p>
          )}
          {formValues.apiDefinition && (
            <p className={classes.jsonTextSuccess}>File Added</p>
          )}
          {/* will enable it in next sprint */}
          <h5>
            If you do not have a swagger file, please generate it{' '}
            <Link
              onClick={saveDataToContext}
              to="/API/register-api/generate-swagger"
              state={formValues}
              className={classes.hereLink}
            >
              here
            </Link>
          </h5>
        </DragDropFile>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
          label={REGISTER_API_CONSTANTS.registerApiForm.apiTitle.label}
          placeholder={
            REGISTER_API_CONSTANTS.registerApiForm.apiTitle.placeholder
          }
          value={formValues.apiTitle}
          name="apiTitle"
          onChange={handleChange}
          required
          fullWidth
          variant="outlined"
          multiline
          {...(errors.apiTitle && {
            error: true,
            helperText: errors.apiTitle,
          })}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
          label={REGISTER_API_CONSTANTS.registerApiForm.apmNumber.label}
          placeholder={
            REGISTER_API_CONSTANTS.registerApiForm.apmNumber.placeholder
          }
          value={formValues.apmNumber}
          name="apmNumber"
          onChange={handleChange}
          fullWidth
          required
          variant="outlined"
          multiline
          onBlur={onBlur}
          {...(errors.apmNumber && {
            error: true,
            helperText: errors.apmNumber,
          })}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          label={REGISTER_API_CONSTANTS.registerApiForm.apiDescription.label}
          placeholder={
            REGISTER_API_CONSTANTS.registerApiForm.apiDescription.placeholder
          }
          value={formValues.apiDescription}
          name="apiDescription"
          onChange={handleChange}
          required
          fullWidth
          variant="outlined"
          multiline
          {...(errors.apiDescription && {
            error: true,
            helperText: errors.apiDescription,
          })}
        />
      </Grid>
      {/* Api type field */}
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <FormControl
          required
          fullWidth
          variant="outlined"
          {...(errors.apiType && {
            error: true,
          })}
        >
          <InputLabel id="api-type-label">API Type</InputLabel>
          <Select
            labelId="api-type-label"
            label={REGISTER_API_CONSTANTS.registerApiForm.apiType.label}
            value={formValues.apiType}
            name="apiType"
            onChange={(event: any) => {
              handleChange(event);
            }}
          >
            {apiTypeArray?.map((apiType: any) => {
              return (
                <MenuItem value={apiType}>
                  {capitalizeFirstLetter(apiType)}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>{errors.apiType}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
          label={REGISTER_API_CONSTANTS.registerApiForm.apiScope.label}
          placeholder={
            REGISTER_API_CONSTANTS.registerApiForm.apiScope.placeholder
          }
          value={formValues.apiScope}
          name="apiScope"
          onChange={handleChange}
          required
          fullWidth
          variant="outlined"
          multiline
          {...(errors.apiScope && {
            error: true,
            helperText: errors.apiScope,
          })}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormControl
          fullWidth
          {...(errors.tags && {
            error: true,
          })}
        >
          <Autocomplete
            multiple
            id="tags-outlined"
            open={false}
            options={[]}
            freeSolo
            onChange={(e, values) => handleTags(values)}
            value={formValues.tags}
            renderInput={params => (
              <TextField variant="outlined" {...params} label="Tags" />
            )}
          />
        </FormControl>
        <FormHelperText>
          {REGISTER_API_CONSTANTS.registerApiForm.tagsMsg}
        </FormHelperText>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <FormControl
          required
          fullWidth
          variant="outlined"
          {...(errors.lifeCycle && {
            error: true,
          })}
        >
          <InputLabel id="api-type-label">Life Cycle</InputLabel>

          <Select
            labelId="life-cycle-label"
            label={REGISTER_API_CONSTANTS.registerApiForm.lifeCycle.label}
            value={formValues.lifeCycle}
            name="lifeCycle"
            onChange={(event: any) => {
              handleChange(event);
            }}
          >
            {lifeCycleArray?.map((lifeCycle: any) => {
              return (
                <MenuItem value={lifeCycle.value} key={lifeCycle.value}>
                  {lifeCycle.label}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>{errors.lifeCycle}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
          label={REGISTER_API_CONSTANTS.registerApiForm.apiVersion.label}
          placeholder={
            REGISTER_API_CONSTANTS.registerApiForm.apiVersion.placeholder
          }
          value={formValues.apiVersion}
          name="apiVersion"
          onChange={handleChange}
          required
          fullWidth
          variant="outlined"
          multiline
          {...(errors.apiVersion && {
            error: true,
            helperText: errors.apiVersion,
          })}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormControl
          fullWidth
          {...(errors.service && {
            error: true,
          })}
        >
          <Autocomplete
            multiple
            id="service-outlined"
            open={false}
            options={[]}
            freeSolo
            onChange={(e, values) => handleServices(values)}
            value={formValues.service}
            renderInput={params => (
              <TextField
                variant="outlined"
                {...params}
                label={REGISTER_API_CONSTANTS.registerApiForm.service.label}
              />
            )}
          />
        </FormControl>
        <FormHelperText>
          {REGISTER_API_CONSTANTS.registerApiForm.tagsMsg}
        </FormHelperText>
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          label={REGISTER_API_CONSTANTS.registerApiForm.documentationUrl.label}
          placeholder={
            REGISTER_API_CONSTANTS.registerApiForm.documentationUrl.placeholder
          }
          value={formValues.support}
          name="support"
          onChange={handleChange}
          fullWidth
          variant="outlined"
          multiline
          {...(errors.support && {
            error: true,
            helperText: errors.support,
          })}
        />
      </Grid>
      {/* <Grid item xs={12} sm={12} md={6} lg={6}>
        <FormControl
          required
          fullWidth
          variant="outlined"
          {...(errors.apiCategory && {
            error: true,
          })}
        >
          <InputLabel id="api-category-label">API Category</InputLabel>

          <Select
            labelId="api-category-label"
            label={REGISTER_API_CONSTANTS.registerApiForm.apiCategory.label}
            value={formValues.apiCategory}
            name="apiCategory"
            onChange={(event: any) => {
              handleChange(event);
            }}
          >
            {registrationData?.apiCategoryArray?.map((apiCategory: any) => {
              return (
                <MenuItem value={apiCategory.value}>
                  {apiCategory.label}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>{errors.apiCategory}</FormHelperText>
        </FormControl>
      </Grid> */}

      {/* <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
          label={REGISTER_API_CONSTANTS.registerApiForm.performance.label}
          placeholder={
            REGISTER_API_CONSTANTS.registerApiForm.performance.placeholder
          }
          value={formValues.performance}
          name="performance"
          onChange={handleChange}
          fullWidth
          required
          variant="outlined"
          multiline
          {...(errors.performance && {
            error: true,
            helperText: errors.performance,
          })}
        />
      </Grid> */}
      {/* //next and cancel btns */}
      <Grid
        item
        xs={12}
        sm={12}
        className={`${classes.registerFormBtns} ${classes.columnDirection}`}
      >
        <Button
          className={classes.successbtn}
          onClick={handleNextClicked}
          variant="contained"
        >
          Next
        </Button>
        <Button
          variant="outlined"
          className={classes.cancelbtn}
          onClick={navigateToapiindexPage}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};
export default RegisterApi;
