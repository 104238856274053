import React, { useEffect, useRef, useState } from 'react';
import { Button, Grid, LinearProgress, Typography } from '@material-ui/core';
import BackButton from '../../../utils/backButton';
import { HelpComponent } from '../../../utils/helpComponent';
import HELP_URL from '../../../utils/helpLinkConstant';
import { useParams } from 'react-router';
import AddIcon from '@material-ui/icons/Add';
import { StepsListCard } from './StepsListCard';
import AddStepPopup from './AddStepPopup';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DeletePopup from '../common/DeletePopup';
import { WhatsNewApi, whatsNewApiRef } from '../../../../apis/whatsNew';
import { useApi } from '@backstage/core-plugin-api';
import { uid } from '../utils';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';
import { useStyles } from '../common/styles';

export const StepsListPage = () => {
  const whatsNew: WhatsNewApi = useApi(whatsNewApiRef);

  const classes = useStyles();
  const { pageId } = useParams();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [selectedStepObj, setSelectedStepObj] = useState<any>();
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const savedPageSteps = useRef([]);
  const [pageSteps, setPageSteps] = useState<any>([]);
  const [pageDetails, setPageDetails] = useState([]);

  /* Open AppStep Popup */
  const handleClickOpen = () => {
    setOpen(true);
  };

  /* Delete Popup Methods */

  const handleCloseDeletePopup = () => {
    setSelectedStepObj(null);
    setShowConfirmDelete(false);
  };

  const handleConfirmDelete = () => {
    setPageSteps(
      pageSteps.filter((step: any) => step.id !== selectedStepObj.id),
    );
    handleCloseDeletePopup();
  };

  /* Steps Card methods */

  const handleDeleteClicked = (stepData: any) => {
    setSelectedStepObj(stepData);
    setShowConfirmDelete(true);
  };

  const handleEditClicked = (stepData: any) => {
    setSelectedStepObj(stepData);
    handleClickOpen();
  };

  /* Add Edit Popup Methods */
  /* ---------------------------------------------------------------------- */
  const handleCloseAddEditPopup = () => {
    setSelectedStepObj(null);
    setOpen(false);
  };

  /* Handle for Add/Edit button */
  const handleAddEditStep = (stepData: any, isEditMode: boolean) => {
    if (isEditMode) {
      setPageSteps(
        pageSteps.map((el: any) =>
          el.id === stepData.id ? { ...el, ...stepData } : el,
        ),
      );
    } else {
      setPageSteps((prevState: any) => {
        return [...prevState, { id: uid(), ...stepData }];
      });
    }
    handleCloseAddEditPopup();
  };

  /* ---------------------------------------------------------------------- */

  /* Get TourConfig */
  const getPageConfig = () => {
    setIsLoading(true);
    whatsNew.getPageConfigById(pageId).then((responseData: any) => {
      /* This line is being used as PageRoute and isActive is not in camel case */
      const pageDataResponse = responseData?.data;
      setPageDetails(pageDataResponse);
      savedPageSteps.current = pageDataResponse?.steps || [];
      setPageSteps(pageDataResponse?.steps || []);
      setIsLoading(false);
    });
  };

  /* Save TourConfig with latest page steps*/
  const savePageSteps = () => {
    setIsLoading(true);
    whatsNew.savePageSteps(pageId, pageSteps).then(() => {
      savedPageSteps.current = pageSteps;
      notificationApi.sendNotification({
        message: `Steps saved successfully`,
        disapperAfterMs: 2500,
        severity: 'success',
      });
      setIsLoading(false);
    });
  };

  const handleReset = () => {
    setPageSteps(savedPageSteps.current);
    notificationApi.sendNotification({
      message: `Steps Reset successfully`,
      disapperAfterMs: 2500,
      severity: 'success',
    });
  };

  useEffect(() => {
    getPageConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Handle drag end */
  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const newItems = [...pageSteps];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setPageSteps(newItems);
  };

  return (
    <>
      <Grid container direction="row">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <BackButton />
          <HelpComponent helpUrl={HELP_URL.Whats_New_Steplist} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {isLoading && <LinearProgress />}
        </Grid>
      </Grid>

      <div className={classes.stepsListPage}>
        <Grid container direction="row" className={classes.saveContainer}>
          <Grid item xs={12} sm={12} md={6} lg={6} className={classes.heading}>
            <Typography component="h2" className={classes.pageHeading}>
              Components of the Page ({pageDetails?.pageRoute})
            </Typography>
            <Typography component="div" className={classes.pageDesc}>
              Add, Edit and Remove components to showcase in the page. Rearrange
              it to any specific order required. Save it to update the changes.
              Reset to revert to the last saved state.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={classes.resetSaveBtns}
          >
            <Button
              variant="outlined"
              className={`${classes.Btns} ${classes.btnFonts}`}
              onClick={handleReset}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              className={`${classes.Btns} ${classes.btnFonts}`}
              color="primary"
              onClick={() => {
                savePageSteps();
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>

        <Grid container direction="row">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.pageBtn}
          >
            <Button
              onClick={handleClickOpen}
              startIcon={<AddIcon />}
              className={`${classes.addPageBtn} ${classes.btnFonts}`}
            >
              Add Step
            </Button>
          </Grid>
        </Grid>
        {!isLoading && pageSteps.length === 0 && (
          <p>No Steps added for this page.</p>
        )}
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {pageSteps.map((stepObj: any, index: any) => (
                      <Draggable
                        draggableId={stepObj.id}
                        key={stepObj.id}
                        index={index}
                      >
                        {(dragProvided, dragSnapshot) => (
                          <div
                            ref={dragProvided.innerRef}
                            {...dragProvided.draggableProps}
                            {...dragProvided.dragHandleProps}
                          >
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <StepsListCard
                                onEditClick={handleEditClicked}
                                onDeleteButton={() =>
                                  handleDeleteClicked(stepObj)
                                }
                                stepData={stepObj}
                              />
                            </Grid>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
        </Grid>

        <AddStepPopup
          open={open}
          onClose={handleCloseAddEditPopup}
          onAddEdit={handleAddEditStep}
          data={selectedStepObj}
        />

        {showConfirmDelete && (
          <DeletePopup
            isOpen={showConfirmDelete}
            onClose={handleCloseDeletePopup}
            onConfirm={handleConfirmDelete}
            msg="Are you sure you want to delete"
          />
        )}
      </div>
    </>
  );
};
