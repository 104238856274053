import React from 'react';
import { DependencyGraphTypes } from '@backstage/core-components';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    fill: theme.palette.textContrast,
  },
  secondary: {
    fill: theme.palette.textSubtle,
  },
}));

export function CustomLabel({
  edge: { relations },
}: DependencyGraphTypes.RenderLabelProps<any>) {
  const classes = useStyles();
  return (
    <text className={classes.text} textAnchor="middle">
      {relations.map((r: any, i: any) => (
        <tspan key={r} className={classNames(i > 0 && classes.secondary)}>
          {/* {i > 0 && <tspan> / </tspan>}
          {r} */}
        </tspan>
      ))}
    </text>
  );
}
