import React, { useEffect, useState } from 'react';
import {  useApi } from '@backstage/core-plugin-api';
import { swTemplateCustomApiRef } from '../../../../apis/swTemplateCustomApi';
import { NotificationApi, notificationApiRef } from '../../../../apis/notificationApi';

export const useGetImage = (uid: string | undefined) => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const [imageData, setImageData] = useState<any>();
  
  const swTemplateCustomApi = useApi(swTemplateCustomApiRef);

  useEffect(() => {
    if (uid) {
      swTemplateCustomApi.getImage(`${uid}.png`)
        .then((res: any) => {
          if (res?.status >= 200 && res?.status < 400) {
            if (res?.headers['Content-Type'] === 'text/html' || res?.headers['content-type'] === 'text/html') {
              // This means error/not found and an error page is served from cloudfront. we need to discard it.
              setImageData(undefined);
              return;
            }
            // Setting the axios response itself, because the consumer component needs to know the response headers
            setImageData(res);
          } else {
            setImageData(undefined);
          }
        })
        .catch((err: any) => {
          notificationApi.sendNotification({
            message: `Error occurred - ${err?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
          setImageData(undefined);
        });
    }
  }, [uid]);

  return [imageData];
};
