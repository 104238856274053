import React, { useState } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import analyticsImage from './icons/Analytics.svg';
import aiImage from './icons/Artificial Intelligence.svg';
import knowledgebase from './icons/Knowledgebase.svg';
import linkIcon from './icons/Link.svg';
import onboardIcon from './icons/Onboard.svg';
import supportIcon from './icons/Support.svg';
import testingIcon from './icons/Testing.svg';

const useStyles = makeStyles({
  imageContainer: {
    flex: 1,
    height: '90px',
    width: '90px',
    cursor: 'pointer',
    border: '1px solid #efefef',
  },
  selectedImage: {
    background: '#efefef',
    border: '1px solid #373737',
  },
});

const DEFAULT_IMAGES = [
  analyticsImage,
  aiImage,
  knowledgebase,
  linkIcon,
  onboardIcon,
  supportIcon,
  testingIcon,
];

export interface ToolboxDefaultImageSelectorProps {
  selectedFile: any;
  setSelectedFile: any;
}

const ToolboxDefaultImageSelector = (
  props: ToolboxDefaultImageSelectorProps,
) => {
  const { selectedFile, setSelectedFile } = props;
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    selectedFile !== undefined && selectedFile.index !== undefined
      ? selectedFile.index
      : -1,
  );

  const classes = useStyles();

  const onClick = (index: number) => {
    setSelectedIndex(index);

    fetch(DEFAULT_IMAGES[index])
      .then(res => {
        return res.blob();
      })
      .then(blob => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const result = reader.result;
          if (
            result !== undefined &&
            String(result).indexOf('base64') >= 0 &&
            String(result).length > 7
          ) {
            const fileContent = String(result).substring(
              String(result).indexOf('base64,') + 7,
            );

            const data = {
              fileName: `default-image-${index}`,
              type: 'image/svg+xml',
              content: fileContent,
              metadata: String(result).substring(
                0,
                String(result).indexOf('base64,') + 7,
              ),
              index,
            };
            setSelectedFile(data);
          }
        };
        reader.readAsDataURL(blob);
      });
  };

  return (
    <Grid container data-testid="selector-root">
      {DEFAULT_IMAGES.map((imageSrc, index) => (
        <Grid item key={index}>
          <Box
            // @ts-ignore
            className={[
              classes.imageContainer,
              selectedIndex === index && selectedFile !== undefined
                ? classes.selectedImage
                : '',
            ]}
            onClick={() => onClick(index)}
          >
            <img src={imageSrc} height="90px" />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
export default ToolboxDefaultImageSelector;
