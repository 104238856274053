import React, { ReactNode, useCallback, useMemo, useRef, useEffect } from "react";
import {
    createStyles,
    IconButton,
    makeStyles,
    Theme
} from '@material-ui/core';
import {
    SxProps
} from '@material-ui/system';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { CarouselNavProps, CarouselProps } from './types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fiberManualRecordIcon: {
            fontSize: "15px",
        },
        indicatorIconButton: {
            cursor: "pointer",
            transition: "200ms",
            padding: 0,
            color: "#afafaf",
            '&:hover': {
                color: "#1f1f1f",
            },
            '&:active': {
                color: "#1f1f1f",
            }
        },
        active: {
            color:"#494949",
            '&:hover': {
                color:"#494949",
            },
            '&:active': {
                color:"#494949",
            }
        },
        indicator:{
            width: "100%",
            marginTop: "10px",
            textAlign: "center"
        }
    })
);

export interface SanitizedCarouselProps extends CarouselProps {
    sx: SxProps<Theme>,
    className: string,
    children: ReactNode,

    height: number | string | undefined,

    index: number,
    strictIndexing: boolean,

    autoPlay: boolean,
    stopAutoPlayOnHover: boolean,
    interval: number,

    animation: "fade" | "slide",
    duration: number,

    swipe: boolean,

    navButtonsAlwaysInvisible: boolean,
    navButtonsAlwaysVisible: boolean,
    cycleNavigation: boolean,
    fullHeightHover: boolean,
    navButtonsWrapperProps: SanitizedCarouselNavProps,
    navButtonsProps: SanitizedCarouselNavProps,
    NavButton: (({ onClick, next, className, style, prev }: { onClick: Function; className: string; style: React.CSSProperties; next: boolean; prev: boolean; }) => ReactNode) | undefined,

    NextIcon: ReactNode,
    PrevIcon: ReactNode,

    indicators: boolean,
    indicatorContainerProps: SanitizedCarouselNavProps,
    indicatorIconButtonProps: SanitizedCarouselNavProps,
    activeIndicatorIconButtonProps: SanitizedCarouselNavProps,
    IndicatorIcon: ReactNode,

    onChange: (now?: number, previous?: number) => any,
    changeOnFirstRender: boolean,
    next: (now?: number, previous?: number) => any,
    prev: (now?: number, previous?: number) => any
}

export interface SanitizedCarouselNavProps extends CarouselNavProps {
    style: React.CSSProperties,
    className: string
};


export const sanitizeNavProps = (props: CarouselNavProps | undefined): SanitizedCarouselNavProps => {
    const { className, style, ...rest } = props || {};

    return props !== undefined ? {
        style: props.style !== undefined ? props.style : {},
        className: props.className !== undefined ? props.className : "",
        ...rest
    } : { style: {}, className: "", ...rest }
}

export const sanitizeProps = (props: CarouselProps): SanitizedCarouselProps => {
    const animation = props.animation !== undefined ? props.animation : "fade";
    const duration = props.duration !== undefined ? props.duration : (animation === "fade" ? 500 : 200);

    return {
        sx: props.sx !== undefined ? props.sx : {},
        className: props.className !== undefined ? props.className : "",
        children: props.children ? props.children : [],

        height: props.height,

        index: props.index !== undefined ? props.index : 0,
        strictIndexing: props.strictIndexing !== undefined ? props.strictIndexing : true,

        autoPlay: props.autoPlay !== undefined ? props.autoPlay : true,
        stopAutoPlayOnHover: props.stopAutoPlayOnHover !== undefined ? props.stopAutoPlayOnHover : true,
        interval: props.interval !== undefined ? props.interval : 4000,

        animation: animation,
        duration: duration,

        swipe: props.swipe !== undefined ? props.swipe : true,

        navButtonsAlwaysInvisible: props.navButtonsAlwaysInvisible !== undefined ? props.navButtonsAlwaysInvisible : false,
        navButtonsAlwaysVisible: props.navButtonsAlwaysVisible !== undefined ? props.navButtonsAlwaysVisible : false,
        cycleNavigation: props.cycleNavigation !== undefined ? props.cycleNavigation : true,
        fullHeightHover: props.fullHeightHover !== undefined ? props.fullHeightHover : true,
        navButtonsWrapperProps: sanitizeNavProps(props.navButtonsWrapperProps),
        navButtonsProps: sanitizeNavProps(props.navButtonsProps),
        NavButton: props.NavButton,

        NextIcon: props.NextIcon !== undefined ? props.NextIcon : <NavigateNextIcon />,
        PrevIcon: props.PrevIcon !== undefined ? props.PrevIcon : <NavigateBeforeIcon />,

        indicators: props.indicators !== undefined ? props.indicators : true,
        indicatorContainerProps: sanitizeNavProps(props.indicatorContainerProps),
        indicatorIconButtonProps: sanitizeNavProps(props.indicatorIconButtonProps),
        activeIndicatorIconButtonProps: sanitizeNavProps(props.activeIndicatorIconButtonProps),
        IndicatorIcon: props.IndicatorIcon,

        onChange: props.onChange !== undefined ? props.onChange : () => { },
        changeOnFirstRender: props.changeOnFirstRender !== undefined ? props.changeOnFirstRender : false,
        next: props.next !== undefined ? props.next : () => { },
        prev: props.prev !== undefined ? props.prev : () => { },

    }
}

export const useInterval = (callback: Function, delay: number) => {
    const savedCallback = useRef<Function>(() => { });

    // Remember the latest callback.
    useEffect(() =>
    {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() =>
    {
        function tick()
        {
            savedCallback.current();
        }
        if (delay !== null)
        {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }

        return () => { };
    }, [delay]);
}

export interface IndicatorProps {
    IndicatorIcon?: ReactNode,
    length: number,
    active: number,
    press: Function,
    indicatorContainerProps: SanitizedCarouselNavProps,
    indicatorIconButtonProps: SanitizedCarouselNavProps,
    activeIndicatorIconButtonProps: SanitizedCarouselNavProps,
}

export const Indicators = (props: IndicatorProps) => {
    const classes = useStyles();
    const IndicatorIcon = useMemo(() => props.IndicatorIcon !== undefined ? props.IndicatorIcon : <FiberManualRecordIcon className={classes.fiberManualRecordIcon}/>, [props.IndicatorIcon]);
    const completeListIfRequired = useCallback((arrayOfIcons: Array<ReactNode>) =>
    {
        while (arrayOfIcons.length < props.length)
        {
            let index = 0;
            arrayOfIcons.push(arrayOfIcons[index]);
            index += 1;
        }
    }, [props.length])

    const { className: indicatorIconButtonClass, style: indicatorIconButtonStyle, ...indicatorIconButtonProps } = props.indicatorIconButtonProps;
    const { style: activeIndicatorIconButtonStyle, ...activeIndicatorIconButtonProps } = props.activeIndicatorIconButtonProps;

    let indicators = [];

    for (let i = 0; i < props.length; i++) {
        const className = i === props.active ?
            `${indicatorIconButtonClass} ${classes.active}` :
            `${indicatorIconButtonClass}`;

        const style = i === props.active ?
            Object.assign({}, indicatorIconButtonStyle, activeIndicatorIconButtonStyle) :
            indicatorIconButtonStyle;

        let restProps = i === props.active ?
            Object.assign({}, indicatorIconButtonProps, activeIndicatorIconButtonProps) :
            indicatorIconButtonProps;

        if (restProps['aria-label'] === undefined) restProps['aria-label'] = 'carousel indicator';

        const createIndicator = (IndicatorIcon: ReactNode) => {
            return (
                <IconButton
                    key={i}
                    className={`${className} ${classes.indicatorIconButton} `}
                    style={style}
                    onClick={() => { props.press(i) }}
                    {...restProps}
                    aria-label={`${restProps['aria-label']} ${i + 1}`}
                >
                    {IndicatorIcon}
                </IconButton>
            )
        }

        Array.isArray(IndicatorIcon)
            ? indicators.push(createIndicator(IndicatorIcon[i])) && completeListIfRequired(IndicatorIcon)
            : indicators.push(createIndicator(IndicatorIcon))

    }

    const { className: indicatorContainerClass, style: indicatorContainerStyle, ...indicatorContainerProps } = props.indicatorContainerProps;

    return (
        <div className={`${indicatorContainerClass} ${classes.indicator}`} style={indicatorContainerStyle} {...indicatorContainerProps}>
            {indicators}
        </div>
    )
}