//import * as crossFetch from 'cross-fetch';
import { fetch as crossFetch } from 'cross-fetch';

import { FetchApi, IdentityApi } from '@backstage/core-plugin-api';
import { getCookie } from '../components/utils/Utils';

export class FetchApiImpl implements FetchApi {
  private readonly identityApi: IdentityApi;

  constructor(identityApi: IdentityApi) {
    this.identityApi = identityApi;
  }

  // @ts-ignore
  async fetch(...args) {
    const headerToken =
      getCookie('token') || (await this.identityApi.getCredentials()).token;

    let [input, options] = args;
    let resourceUrl: string | undefined = undefined;
    let isString: boolean = false;
    if (input && typeof input === 'string') {
      resourceUrl = input;
      isString = true;
    } else if (input && typeof input === 'object' && input.url) {
      resourceUrl = input.url;
    }
    let newOptions = options ? { ...options } : {};
    if (resourceUrl) {
      //scenarios for microsoft auth token for fetch
      if (
        !`${resourceUrl}`.includes('discovery/') &&
        !`${resourceUrl}`.includes('service/')
      ) {
        // Add backstage id token
        let headers: any = {};
        if (isString) {
          if (newOptions.headers) {
            headers = {
              ...newOptions?.headers,
              ['api-version']: '1.0',
              Authorization: `Bearer ${headerToken}`,
            };
          } else {
            headers = {
              ['api-version']: '1.0',
              Authorization: `Bearer ${headerToken}`,
            };
          }
        } else {
          // @ts-ignore
          if (input && input.headers) {
            // @ts-ignore
            let inputHeaders: Headers = input.headers;
            headers = {
              ...inputHeaders,
              ['api-version']: '1.0',
              Authorization: `Bearer ${headerToken}`,
            };
          } else {
            headers = {
              ['api-version']: '1.0',
              Authorization: `Bearer ${headerToken}`,
            };
          }
        }
        if (
          !Object.keys(headers).includes('Content-Type') ||
          (!Object.keys(headers).includes('content-type') &&
            !isString &&
            `${input?.method}`.toLowerCase() !== 'get')
        ) {
          headers['content-type'] = 'application/json';
        }

        newOptions = {
          ...newOptions,
          mode: 'cors',
          headers,
        };
      }
    }

    return await crossFetch(input, newOptions);
  }
}
