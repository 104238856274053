import React from 'react';
import { makeStyles, Theme, Grid, Paper } from '@material-ui/core';
import {
  SearchBar,
  SearchFilter,
  SearchResult,
  SearchResultPager,
} from '@backstage/plugin-search-react';
import { Content, Header, Page } from '@backstage/core-components';

import * as columnFactories from '../utils/devXSearchTableColumns';

import { TableColumn, Table } from '@backstage/core-components';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';

const columns: TableColumn<columnFactories.EntityRow>[] = [
  columnFactories.createNameColumn(),
  columnFactories.createOwnerColumn(),
  columnFactories.createSpecTypeColumn(),
  columnFactories.createSpecLifecycleColumn(),
  columnFactories.createMetadataDescriptionColumn(),
  // columnFactories.createTagsColumn(),
  // columnFactories.createStatusColumn(),
  // columnFactories.createRatingColumn(),
];

const useStyles = makeStyles((theme: Theme) => ({
  bar: {
    padding: theme.spacing(1, 0),
  },
  filters: {
    padding: theme.spacing(2),
  },
  filter: {
    '& + &': {
      marginTop: theme.spacing(2.5),
    },
  },
  search: {
    marginBottom: '60px',
  },
  searchTable: {
    marginBottom: '0px',
  },
}));

const SearchPage = () => {
  const classes = useStyles();

  const manipulateSearchObject = (obj: any) => {
    return (
      obj
        ?.filter((item: any) => item?.document?.kind !== 'Location')
        .map((item: any) => {
          if (item?.document?.kind === 'Template') {
            item.document.location = item.document.location.replace(
              '/catalog/default/template',
              'software-templates/templates',
            );
          }
          if (item?.document?.type === 'capability') {
            item.document.location = `taxonomy/view/Group/default/${item?.document?.title}`;
            item.document.lifecycle = '';
          }
          if (item?.document?.type === 'extdocumentation') {
            item.document.location = '/docs/external';
          }
          if (item?.document?.type === 'toolbox-item') {
            item.document.location = `/toolbox?q=${item.document?.title}`;
          }
          return item;
        }) || []
    );
  };

  return (
    <Page themeId="home">
      <Header
        title="Search"
        subtitle={
          <DevxBreadCrumb
            routes={[
              {
                type: 'link',
                link: '/',
                text: 'Home',
              },
              {
                type: 'text',
                link: '',
                text: 'Search',
              },
            ]}
          />
        }
      />
      <Content>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Paper className={classes.bar}>
              <SearchBar debounceTime={100} />
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.filters}>
              <SearchFilter.Select
                className={classes.filter}
                name="kind"
                values={['Component', 'API', 'Template', 'Tool']}
              />
              <SearchFilter.Checkbox
                className={classes.filter}
                name="lifecycle"
                values={['experimental', 'production']}
              />
            </Paper>
          </Grid>
          <Grid item xs={9} className={classes.search}>
            <SearchResult>
              {({ results }) => {
                return (
                  <div className="search-page codePage">
                    <div className={`mystuff-table ${classes.searchTable}`}>
                      <Table
                        columns={columns}
                        options={{
                          paging: false,
                          pageSize: 25,
                          search: true,
                          actionsColumnIndex: -1,
                          loadingType: 'linear',
                          padding: 'dense',
                          pageSizeOptions: [25],
                        }}
                        data={manipulateSearchObject(results)}
                        title={`${'Search Results'} (${
                          results &&
                          results?.filter(
                            result => result?.document?.kind !== 'Location',
                          ).length
                        })`}
                      />
                    </div>
                  </div>
                );
              }}
            </SearchResult>
            <SearchResultPager />
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};

export default SearchPage;
export const searchPage = <SearchPage />;
