import React, { useState, useEffect, useRef } from 'react';
import { Entity, RELATION_OWNED_BY } from '@backstage/catalog-model';
import {
  EntityRefLinks,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import { Chip, Grid, Theme, createStyles, makeStyles } from '@material-ui/core';
import { AboutField } from '../../AboutField';
import { Link } from 'react-router-dom';
import { getPathToViewTaxonomyPage } from '../../../../utils/DevXUtils';
import ExpandableContent from '../../../../common/ExpandableContent';
import { TowerInfoApi, towerInfoApiRef } from '../../../../../apis/towerInfo';
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../../apis/notificationApi';
import { getUserName } from '../../../../home/allAssets/util';
import { userProfileApiRef } from '../../../../../apis/userProfileApi';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    links: {
      '& a': {
        fontFamily: 'Calibre-R-Regular',
        color: theme.palette.primary.main,
        fontSize: '11px',
        fontWeight: 600,
      },
    },
    tagsField: {
      marginBottom: '0px',
      margin: '2px',
      height: '20px',
    },
    lifecycleField: {
      marginBottom: '0px',
      margin: '2px',
      height: '20px',
    },
    chipField: {
      marginBottom: '0px',
      margin: '2px',
      height: '20px',
      cursor: 'pointer',
    },
    aboutLink: {
      color: theme.palette.primary.main,
    },
  }),
);

type Props = {
  entity: Entity;
};

export const AboutContent = ({ entity }: Props) => {
  const classes = useStyles();
  const isSystem = entity.kind.toLocaleLowerCase('en-US') === 'system';
  const isDomain = entity.kind.toLocaleLowerCase('en-US') === 'domain';
  const isResource = entity.kind.toLocaleLowerCase('en-US') === 'resource';
  const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);
  const supportEmail = entity?.spec?.supportEmail;
  let apiLOB = entity?.metadata?.apiLOB ? entity?.metadata?.apiLOB : [];
  //@ts-ignore
  if (
    entity?.metadata?.assetstore &&
    entity?.metadata?.assetstore.toLowerCase() === 'wso2'
  ) {
    //@ts-ignore
    apiLOB =
      entity?.metadata?.apiLOB && entity?.metadata?.apiLOB[0] !== ''
        ? entity?.metadata?.apiLOB[0].split(',')
        : [];
  }

  const [towerData, setToweInfo] = useState('');

  const towerInfo: TowerInfoApi = useApi(towerInfoApiRef);
  const userProfileApi = useApi(userProfileApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const usernameTechOwnerRef = useRef(null);
  const usernameBusinessOwnerRef = useRef(null);

  const onMouseEnterTechOwner = () => {
    if (
      entity?.spec?.techowner === undefined ||
      String(entity?.spec?.techowner).trim().length === 0
    ) {
      return;
    }
    const ownerEmail = `${entity?.spec?.techowner}`?.replace('user:', '');
    let email: string | undefined = ownerEmail;
    if (ownerEmail !== undefined && ownerEmail?.indexOf('@') === -1) {
      email = `${ownerEmail}@cbre.com`;
    }

    userProfileApi.setUserProfileState({
      email,
      anchorRef: usernameTechOwnerRef?.current,
    });
  };

  const onMouseEnterBusinessOwner = () => {
    if (
      entity?.spec?.businessowner === undefined ||
      String(entity?.spec?.businessowner).trim().length === 0
    ) {
      return;
    }
    const ownerEmail = `${entity?.spec?.businessowner}`?.replace('user:', '');
    let email: string | undefined = ownerEmail;
    if (ownerEmail !== undefined && ownerEmail?.indexOf('@') === -1) {
      email = `${ownerEmail}@cbre.com`;
    }

    userProfileApi.setUserProfileState({
      email,
      anchorRef: usernameBusinessOwnerRef?.current,
    });
  };

  useEffect(() => {
    towerInfo
      .getTowerInfo(entity?.metadata?.name)
      .then((data: any) => {
        setToweInfo(data);
      })
      .catch(() => {
        notificationApi.sendNotification({
          message: `Failed to load Data`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  }, []);

  const renderAssets = () => {
    const memberRelations: any[] = [];
    const relations = entity?.relations;

    if (relations && relations?.length > 0) {
      relations.forEach((rel: any) => {
        if (rel && rel.type === 'memberOf') {
          memberRelations.push(rel?.target);
        }
      });
    }
    return (
      <>
        {memberRelations &&
          memberRelations?.map((rel: any) => {
            return (
              <Link to={getPathToViewTaxonomyPage(rel)}>
                <Chip
                  color="primary"
                  key={rel?.name}
                  label={rel?.name}
                  variant="outlined"
                  size="small"
                  className={classes.chipField}
                />
              </Link>
            );
          })}
      </>
    );
  };

  return (
    <Grid container>
      <AboutField label="Description" gridSizes={{ xs: 12, sm: 12 }}>
        <ExpandableContent
          lines={2}
          value={entity?.metadata?.description || 'No description'}
        />
      </AboutField>
      <AboutField label="Tech Owners" gridSizes={{ xs: 12, sm: 6, lg: 4 }}>
        <div className={classes.links}>
          <EntityRefLinks entityRefs={ownedByRelations} defaultKind="group">
            <span
              onMouseEnter={onMouseEnterTechOwner}
              ref={usernameTechOwnerRef}
            >
              {`${entity?.spec?.techowner}`?.replace('user:', '')}
            </span>
          </EntityRefLinks>
        </div>
      </AboutField>
      <AboutField label="Product Owners" gridSizes={{ xs: 12, sm: 6, lg: 4 }}>
        <div className={classes.links}>
          <EntityRefLinks entityRefs={ownedByRelations} defaultKind="group">
            <span
              onMouseEnter={onMouseEnterBusinessOwner}
              ref={usernameBusinessOwnerRef}
            >
              {`${entity?.spec?.businessowner}`?.replace('user:', '')}
            </span>
          </EntityRefLinks>
        </div>
      </AboutField>
      {!isSystem && !isDomain && (
        <AboutField
          label="Type"
          value={entity?.spec?.type as string}
          gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        />
      )}
      <AboutField
        label="API Scope/Visibility"
        gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        value={entity?.metadata?.apiscope as string}
      />
      <AboutField
        label="Tags"
        value="No Tags"
        gridSizes={{ xs: 12, sm: 6, lg: 4 }}
      >
        {(entity?.metadata?.tags || []).map(t => (
          <Chip
            key={t}
            size="small"
            color="primary"
            variant="outlined"
            label={t}
            className={classes.tagsField}
          />
        ))}
      </AboutField>
      {!isSystem && !isDomain && !isResource && (
        <AboutField
          label="Lifecycle"
          value="No Lifecycle"
          gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        >
          <Chip
            size="small"
            color="primary"
            variant="outlined"
            label={entity?.spec?.lifecycle as string}
            className={classes.lifecycleField}
          />
        </AboutField>
      )}
      <AboutField
        label="API Version"
        gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        value={entity?.metadata?.apiversion as string}
      />
      <AboutField
        label="SUBSCRIPTION URL"
        gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        value="No Link"
      >
        {entity && entity?.metadata && entity?.metadata?.documentationLink ? (
          <a
            href={`${entity?.metadata?.documentationLink}`}
            target="_blank"
            className={classes.aboutLink}
          >
            Link
          </a>
        ) : (
          'No Link'
        )}
      </AboutField>
      {/* <AboutField
        label="Tower"
        value={towerData?.data?.tower || ' '}
        gridSizes={{ xs: 12, sm: 6, md: 6, lg: 4 }}
      /> */}
      <AboutField
        label="LOB"
        gridSizes={{ xs: 12, sm: 12, lg: 12 }}
        value={entity?.metadata?.apiLOB as string}
      >
        {apiLOB.map(l =>
          l === '' ? (
            <></>
          ) : (
            <Chip
              size="small"
              color="primary"
              variant="outlined"
              label={l as string}
              className={classes.lifecycleField}
            />
          ),
        )}
      </AboutField>

      <AboutField
        label="Support Email"
        value={(supportEmail as string) || (entity?.spec?.owneremail as string)}
        gridSizes={{ xs: 12, sm: 6, md: 6, lg: 4 }}
      />

      {
        entity?.metadata?.tenant && <AboutField 
          label="Tenant"
          value={entity?.metadata?.tenant as string}
          gridSizes={{ xs: 12, sm: 6, md: 6, lg: 4 }}
        />
      }

      {/* <AboutField
        label="Capabilities"
        gridSizes={{ xs: 12, sm: 12, lg: 12, xl: 12 }}
        value=""
      >
        {renderAssets()}
      </AboutField> */}
    </Grid>
  );
};
