/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Grid, Theme, createStyles, makeStyles } from '@material-ui/core';
import {
  TableColumn,
  Table,
  WarningPanel,
  CodeSnippet,
  TableProps,
} from '@backstage/core-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import * as columnFactories from '../../../utils/subscribersTableColumns';
import {
  SUBSCRIBES_SUCCESS_MESSAGE,
  SUBSCRIBES_ERROR_MESSAGE,
} from '../../../utils/constant';
import { useApi } from '@backstage/core-plugin-api';
import { subscriberApiRef } from '../../../../apis/subscriberApi';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notifyArea: {
      // background: '#fff',
      padding: '16px',
      marginBottom: '20px',
    },
    notifyAreaBtn: {
      marginTop: '20px',
      ['@media (max-width:920px)']: {
        marginTop: '0',
        width: '100%',
      },
    },
    themeColor: {
      '& tr': {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.background.default + '!important',
        '& td ': {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: theme.palette.background.default + '!important',
        },
        '& th': {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: theme.palette.background.default + '!important',
        },
      },
      '& div::-webkit-scrollbar-thumb': {
        border: `4px solid ${theme.palette.text.tertiary}`,
      },
    },
  }),
);
const columnsForAdmin: TableColumn<columnFactories.EntityRow>[] = [
  columnFactories.createAssetColumn(),
  columnFactories.createEmailColumn(),
  columnFactories.createCountColumn(),
];

const columns: TableColumn<columnFactories.EntityRow>[] = [
  columnFactories.createAssetColumn(),
  columnFactories.createCountColumn(),
];

export const SubscribeTable = ({
  error,
  loading,
  entities,
  userHaveAssets,
  isAdmin,
  userEmail,
  setRefresh,
  isRefresh,
  setAllAssetsData,
  setLoding,
}: any) => {
  const classes = useStyles();
  const [checkedValue, setChecked] = useState({});
  const [TextBoxValue, setValue] = useState('');
  const [isSubmitButton, setSubmitButton] = useState(true);
  // const [isApiStatus, setApiStatus] = useState(false);
  // const [apiStatus, setStatus] = useState<any>(null);
  const [isError, setError] = useState<any>(false);
  const [isSelectAll, setSelectAll] = useState<any>(false);

  const subscriberApi = useApi(subscriberApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const checkSubmitButtonForEnabled = () => {
    if (
      checkedValue &&
      Object.keys(checkedValue).some(val => checkedValue[val]) &&
      TextBoxValue.length > 0
    ) {
      setSubmitButton(false);
    } else {
      setSubmitButton(true);
    }
  };

  const handleChangeForText = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val = event.target.value;

    if (val.length < 501) {
      setValue(event.target.value);
      // setErrorMsg('');
    }

    checkSubmitButtonForEnabled();
  };

  useEffect(() => {
    const checkval = {};
    entities.map((items: any) => {
      checkval[items.assetName] = false;
    });
    setChecked(checkval);
  }, []);

  const handleChange = (event, name) => {
    setSelectAll(false);
    if (checkedValue[name]) {
      checkedValue[name] = event.target.checked;
    } else {
      checkedValue[name] = event.target.checked;
    }
    setChecked({ ...checkedValue });
    if (!Object.keys(checkedValue).some(k => !checkedValue[k])) {
      setSelectAll(true);
    }

    checkSubmitButtonForEnabled();
  };

  const handleOnChangeSelectAll = () => {
    setSelectAll(!isSelectAll);
    const checkval = {};
    entities.map((items: any) => {
      checkval[items.assetName] = !isSelectAll;
    });
    setChecked(checkval);
    checkSubmitButtonForEnabled();
  };

  const handleClose = () => {
    // setApiStatus(false);
    setError(false);
    // setStatus('');
    setAllAssetsData([]);
    setRefresh(!isRefresh);
    setChecked({});
    setValue('');
    setLoding(true);
  };

  const handleSubmit = async () => {
    setSubmitButton(true);
    const assetNameList = [];
    Object.keys(checkedValue).some(val => {
      if (checkedValue[val]) {
        assetNameList.push(val);
      }
    });

    const payLoads = {
      assetNames: assetNameList,
      message: TextBoxValue,
      user: userEmail,
    };

    subscriberApi
      .subscriptionSendEmail(payLoads)
      .then(() => {
        // setApiStatus(true);
        // setStatus(SUBSCRIBES_SUCCESS_MESSAGE);
        notificationApi.sendNotification({
          message: SUBSCRIBES_SUCCESS_MESSAGE,
          disapperAfterMs: 2500,
          severity: 'success',
          callback: () => {
            handleClose();
          },
        });
      })
      .catch(() => {
        // setApiStatus(true);
        setError(true);
        // setStatus(SUBSCRIBES_ERROR_MESSAGE);
        notificationApi.sendNotification({
          message: SUBSCRIBES_ERROR_MESSAGE,
          disapperAfterMs: 2500,
          severity: 'error',
          callback: () => {
            handleClose();
          },
        });
      });
  };

  const handleSubmitAll = () => {
    const payLoads = {
      assetNames: [],
      message: TextBoxValue,
      user: userEmail,
    };

    subscriberApi
      .subscriptionSendEmail(payLoads)
      .then(() => {
        // setApiStatus(true);
        // setStatus(SUBSCRIBES_SUCCESS_MESSAGE);
        notificationApi.sendNotification({
          message: SUBSCRIBES_SUCCESS_MESSAGE,
          disapperAfterMs: 2500,
          severity: 'success',
          callback: () => {
            handleClose();
          },
        });
      })
      .catch(() => {
        // setApiStatus(true);
        setError(true);
        // setStatus(SUBSCRIBES_ERROR_MESSAGE);
        notificationApi.sendNotification({
          message: SUBSCRIBES_ERROR_MESSAGE,
          disapperAfterMs: 2500,
          severity: 'error',
          callback: () => {
            handleClose();
          },
        });
      });
  };

  const selectAllCheckBox = () => {
    return (
      <FormControlLabel
        control={
          <input
            type="checkbox"
            checked={isSelectAll}
            onChange={() => handleOnChangeSelectAll()}
            color="primary"
            name={'SelectAll'}
            id={'selectAll'}
            className="customCheckboxforSelectAll"
          />
        }
        label="Select All Subscribers"
      />
    );
  };

  const defaultActions: TableProps<columnFactories.EntityRow>['actions'] = [
    entity => {
      return {
        icon: () => (
          <input
            type="checkbox"
            checked={checkedValue[entity?.assetName]}
            onChange={e => handleChange(e, entity?.assetName)}
            color="primary"
            aria-label="primary checkbox"
            name={entity && entity?.assetName}
            id={entity && entity?.assetName}
            // disableFocusRipple={isSelectAll}
            // disableRipple={isSelectAll}
            // disabled={isSelectAll}
            // disableTouchRipple={isSelectAll}
            className="primary checkbox customCheckbox"
          />
        ),
        tooltip: '',
        onClick: () => {},
      };
    },
  ];

  if (error) {
    return (
      <div>
        <WarningPanel
          severity="error"
          title="Could not fetch catalog entities."
        >
          <CodeSnippet language="text" text={error.toString()} />
        </WarningPanel>
      </div>
    );
  }

  return (
    <>
      {entities.length ? (
        <div>
          <div className={classes.notifyArea}>
            <Grid container direction="row">
              <Grid item xs={12} sm={12} md={10} lg={10}>
                <TextField
                  id="Message-for-subscribers"
                  label="Message for subscribers"
                  multiline
                  fullWidth
                  minRows={4}
                  variant="filled"
                  onChange={handleChangeForText}
                  value={TextBoxValue}
                  helperText={`Maximum of 500 characters.  ${
                    TextBoxValue.length > 0 ? `${TextBoxValue.length}/500` : ''
                  }`}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                {isSelectAll && TextBoxValue?.trim()?.length > 0 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmitAll}
                    className={classes.notifyAreaBtn}
                  >
                    NOTIFY
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={isSubmitButton}
                    className={classes.notifyAreaBtn}
                  >
                    NOTIFY
                  </Button>
                )}
              </Grid>
              {/* <Grid item md={2} xs={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleSubmitAll}
                  disabled={TextBoxValue.length > 0 ? false : true}
                >
                  NOTIFY ALL
                </Button>
              </Grid> */}
            </Grid>
          </div>
          <div
            className={`mystuff-table subscriberTable ${classes.themeColor}`}
          >
            <Table
              columns={isAdmin ? columnsForAdmin : columns}
              isLoading={loading}
              options={{
                paging: true,
                pageSize: 20,
                search: true,
                actionsColumnIndex: 0,
                showEmptyDataSourceMessage: !loading,
                loadingType: 'linear',
                padding: 'dense',
                pageSizeOptions: [20],
                showSelectAllCheckbox: true,
                checkboxSelection: true,
              }}
              data={entities}
              actions={defaultActions}
              subtitle={selectAllCheckBox()}
              title="Please choose below assets to notify."
            />
          </div>
        </div>
      ) : !loading && userHaveAssets ? (
        <p>You have no subscribers yet.</p>
      ) : (
        <p>You have not published any repos. Please start publishing.</p>
      )}
    </>
  );
};
