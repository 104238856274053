import color from 'color';
import { forceCollide, forceSimulation } from 'd3-force';
import Segment from '../../utils/segment';
import type { Entry, Quadrant, Ring } from '../../utils/types';

export const adjustQuadrants = (
  quadrants: Quadrant[],
  radius: number,
  width: number,
  height: number,
) => {
  const margin = 16;
  const xStops = [
    margin,
    width / 2 - radius - margin,
    width / 2 + radius + margin,
    width - margin,
  ];
  const yStops = [margin, height / 2 - margin, height / 2, height - margin];

  const legendParams = [
    {
      x: xStops[2],
      y: yStops[2],
      width: xStops[3] - xStops[2],
      height: yStops[3] - yStops[2],
    },
    {
      x: xStops[0],
      y: yStops[2],
      width: xStops[1] - xStops[0],
      height: yStops[3] - yStops[2],
    },
    {
      x: xStops[0],
      y: yStops[0],
      width: xStops[1] - xStops[0],
      height: yStops[1] - yStops[0],
    },
    {
      x: xStops[2],
      y: yStops[0],
      width: xStops[3] - xStops[2],
      height: yStops[1] - yStops[0],
    },
  ];

  return quadrants.map((quadrant, index) => {
    const legendParam = legendParams[index % 4];

    return {
      name: quadrant,
      index,
      radialMin: (index * Math.PI) / 2,
      radialMax: ((index + 1) * Math.PI) / 2,
      offsetX: index % 4 === 0 || index % 4 === 3 ? 1 : -1,
      offsetY: index % 4 === 0 || index % 4 === 1 ? 1 : -1,
      legendX: legendParam.x,
      legendY: legendParam.y,
      legendWidth: legendParam.width,
      legendHeight: legendParam.height,
    };
  });
};

export const adjustEntries = (
  entries: Entry[],
  quadrants: Quadrant[],
  rings: Ring[],
  radius: number,
  activeEntry?: Entry,
): Entry[] => {
  let seed = 42;
  const adjustedEntries = entries.map((entry, index) => {
    const quadrant = quadrants.find(q => {
      const match =
        typeof entry.quadrant === 'object' ? entry.quadrant.id : entry.quadrant;
      return q.name === match;
    });
    const ring = rings.find(r => {
      const match = typeof entry.ring === 'object' ? entry.ring.id : entry.ring;
      return r.name === match;
    });

    if (!quadrant) {
      throw new Error(
        `Unknown quadrant ${entry.quadrant} for entry ${entry.title}!`,
      );
    }
    if (!ring) {
      throw new Error(`Unknown ring ${entry.ring} for entry ${entry.title}!`);
    }
    const segment = new Segment(quadrant, ring, radius, () => seed++);
    const point = segment?.random();

    return {
      ...entry,
      index: index,
      quadrant: quadrant,
      ring: ring,
      segment,
      x: point.x,
      y: point.y,
      color:
        activeEntry && entry.id === activeEntry?.id
          ? ring.color
          : color(ring.color).desaturate(0.2).lighten(0.1).string(),
      active: activeEntry && entry.id === activeEntry?.id ? true : false,
    };
  });

  const simulation = forceSimulation()
    .nodes(adjustedEntries)
    .velocityDecay(0.19)
    .force('collision', forceCollide().radius(12).strength(0.85))
    .stop();

  for (
    let i = 0,
      n = Math.ceil(
        Math.log(simulation.alphaMin()) / Math.log(1 - simulation.alphaDecay()),
      );
    i < n;
    ++i
  ) {
    simulation.tick();

    for (const entry of adjustedEntries) {
      if (entry.segment) {
        entry.x = entry.segment.clipx(entry);
        entry.y = entry.segment.clipy(entry);
      }
    }
  }

  return adjustedEntries;
};

enum myColor {
  '#FF543E',
  '#7774d8',
  '#FF9742',
  '#50AF78',
}

export const adjustRings = (rings: Ring[], radius: number) =>
  rings.map((ring, index) => ({
    name: ring,
    index,
    outerRadius: ((index + 2) / (rings.length + 1)) * radius,
    innerRadius: ((index === 0 ? 0 : index + 1) / (rings.length + 1)) * radius,
    color: myColor[index] || '#fff',
  }));
