import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Theme,
  makeStyles,
  createStyles,
  TextField,
} from '@material-ui/core';
import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    DialogBox: {
      minWidth: 300,
    },
    margin: {
      marginTop: '15px',
    },
    actionBtn: {
      textTransform: 'initial',
    },
    description: {
      marginTop: '10px',
    },
  }),
);

const IEDeleteModal = (props: any) => {
  const {
    open,
    handleSaveTags,
    closeIconClicked,
    tags,
    isEditClick,
    isDeleteClick,
    deletetag,
  } = props;
  const { tag, metadata, owner, id } = tags;

  useEffect(() => {
    if (isEditClick && tag) {
      setSupportEmail(owner);
      setDescription(metadata);
      setTitle(tag);
    } else {
      setSupportEmail('');
      setDescription('');
      setTitle('');
      setTitleError(undefined);
      setEmailError(undefined);
    }
  }, [tags]);

  const [description, setDescription] = useState<string | undefined>(
    metadata || '',
  );

  const [emailError, setEmailError] = useState<string | undefined>('');
  const [title, setTitle] = useState<string | undefined>(tag || '');
  const [titleError, setTitleError] = useState<string | undefined>('');
  const [descriptionError, setDescriptionError] = useState<string | undefined>(
    '',
  );

  const [supportEmail, setSupportEmail] = useState<string | undefined>(
    owner || '',
  );

  const oncancel = () => {
    closeIconClicked();
    setSupportEmail('');
    setDescription('');
    setTitle('');
  };

  const onDelete = () => {
    deletetag(id);
  };

  const validateForm = () => {
    const errors: string[] = [];
    if (
      title == undefined ||
      (title !== undefined && String(title).trim()?.length === 0)
    ) {
      setTitleError('Tag name is required');
      errors.push('Tag name is required');
    }
    if (
      description == undefined ||
      (description !== undefined && String(description).trim()?.length === 0)
    ) {
      setDescriptionError('Description is required');
      errors.push('Description is required');
    }

    try {
      if (
        !yup
          .string()
          .email('You have entered an invalid email address!')
          .validateSync(supportEmail)
      ) {
        setEmailError('You have entered an invalid email address!');
        errors.push('You have entered an invalid email address!');
      } else if (supportEmail) {
        setEmailError(undefined);
      }
    } catch {
      setEmailError('You have entered an invalid email address!');
      errors.push('You have entered an invalid email address!');
    }

    return errors;
  };

  const onTitleTextChange = (event: any) => {
    if (event?.target?.value.length <= 18) {
      setTitleError(undefined);
      setTitle(event?.target?.value);
    } else {
      setTitleError('Maximum 18 character allowed');
    }
  };
  const onDescriptionChange = (event: any) => {
    setDescription(event?.target?.value);
  };

  const onSupportEmailChange = (event: any) => {
    setSupportEmail(event?.target?.value);

    setEmailError(undefined);
  };

  const onClickSavebtn = () => {
    const formValues = {
      tag: title,
      metadata: description,
      owner: supportEmail,
    };

    const editFormValues = { ...formValues, id: id };

    const errors = validateForm();
    if (
      errors !== undefined &&
      Array.isArray(errors) &&
      errors?.length === 0 &&
      !titleError &&
      !emailError
    ) {
      isEditClick ? handleSaveTags(editFormValues) : handleSaveTags(formValues);
    }
  };

  const classes = useStyles();

  if (isDeleteClick) {
    return (
      <Dialog
        open={open}
        onClose={oncancel}
        className={classes.DialogBox}
        fullWidth={true}
      >
        <DialogTitle id="dialog-title">
          <div>Delete {tag}</div>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={oncancel}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div>Do You Want To Delete this tag?</div>
        </DialogContent>

        <DialogActions className={classes.margin}>
          <Button variant="outlined" color="primary" onClick={oncancel}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onDelete}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  if (open) {
    isEditClick;
    return (
      <Dialog
        open={open}
        onClose={oncancel}
        className={classes.DialogBox}
        fullWidth={true}
      >
        <DialogTitle id="dialog-title">
          <div>{isEditClick ? 'Modify Tag' : 'Add Tag'}</div>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={oncancel}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div>
            <TextField
              label="Tag Name"
              required
              fullWidth
              multiline
              variant="outlined"
              value={title ?? ''}
              onChange={e => onTitleTextChange(e)}
              error={Boolean(titleError)}
              helperText={Boolean(titleError) && titleError}
            />
          </div>
          <div>
            <TextField
              className={classes.margin}
              label="Description"
              fullWidth
              multiline
              minRows={3}
              required
              variant="outlined"
              value={description ?? ''}
              onChange={e => onDescriptionChange(e)}
              helperText={Boolean(descriptionError) && descriptionError}
            />
          </div>
          <div>
            <TextField
              className={classes.margin}
              multiline
              fullWidth
              label="Support Email"
              variant="outlined"
              value={supportEmail ?? ''}
              onChange={e => onSupportEmailChange(e)}
              error={Boolean(emailError)}
              helperText={Boolean(emailError) && emailError}
            />
          </div>
        </DialogContent>

        <DialogActions className={classes.margin}>
          <Button variant="outlined" color="primary" onClick={oncancel}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onClickSavebtn}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return null;
};

export default IEDeleteModal;
