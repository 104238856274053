import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const BackslashEscapeIcon = (props: any) => (
  <SvgIcon
    viewBox={'48 48'}
    style={{ transform: 'scale(3)' }}
    fill="#000000"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 3H3V21H21V3ZM14.3001 15.98L11.4001 7.39001H10.0601L12.9601 15.98H14.3001Z"
      fill="#5E49CD"
    />
  </SvgIcon>
);

export default BackslashEscapeIcon;
