import { scmIntegrationsApiRef } from '@backstage/integration-react';
import {
  scaffolderApiRef,
  ScaffolderClient,
} from '@backstage/plugin-scaffolder';

import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './Routes';
import { swTemplateGithubApiRef } from './fields/DevxRepoPicker/api/SWTemplateGithubApi';
import { SWTemplateGithubClient } from './fields/DevxRepoPicker/api/SWTemplateGithubClient';
import { axiosInstanceProviderApiRef } from '../../apis/axiosInstanceApi';

/**
 * The main plugin export for the scaffolder.
 * @public
 */
export const cbrePocHubPlugin = createPlugin({
  id: 'cbre.pocHub',
  apis: [],
  routes: {
    root: rootRouteRef,
  },
});

export const PocHubpage = cbrePocHubPlugin.provide(
  createRoutableExtension({
    name: 'PocHubpage',
    component: () => import('./Routes').then(m => m.PocHubRouter),
    mountPoint: rootRouteRef,
  }),
);
