import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  LinearProgress,
} from '@material-ui/core';
import { SonarQubeCard } from './sonarQube';
import { SnykCard } from './snyk';
import { useAsyncEntity } from '@backstage/plugin-catalog-react';
import { useGetBranches } from './hooks/useGetBranches';
import { AuthContext } from '../../../../providers/AuthProvider';
import { additionalConfigApiRef } from '../../../../apis/additionalConfigApi';
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';

const CodeQualityPage = () => {
  const [branch, setBranch] = useState<string>('');
  const [snykOrgId, setSnykOrgId] = useState<any>('');

  const [selectedBranch, setSelectedBranch] = useState<string>('');

  const { entity } = useAsyncEntity();

  const { profEmail, isAdmin } = useContext(AuthContext);

  const userId = profEmail.split('@')[0].toLowerCase();
  const isOwner =
    userId === `${entity?.spec?.owner}`?.toLowerCase()?.replace('user:', '');

  const { loading: branchesLoading, branchList } = useGetBranches(
    entity,
    isOwner,
  );
  const additionalConfigApi = useApi(additionalConfigApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  useEffect(() => {
    if (additionalConfigApi) {
      additionalConfigApi
        .getFrontEndConfig()
        .then((config: any) => {
          setSnykOrgId(config?.frontendConfig?.data?.snykOrgID);
        })
        .catch((err: any) => {
          notificationApi.sendNotification({
            message: `Error occurred - ${err?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });
    }
  }, []);

  return (
    <Grid container spacing={2}>
      {branchesLoading && <LinearProgress />}

      <Grid item xs={12} md={6} lg={6}>
        <SonarQubeCard variant="gridItem" selectedBranch={selectedBranch} />
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <SnykCard
          snykOrgID={snykOrgId}
          // selectedBranch={selectedBranch || 'default_branch'}
          selectedBranch='default_branch'
          isOwner={isOwner}
        />
      </Grid>
    </Grid>
  );
};
export default CodeQualityPage;
