/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Button,
  Typography,
  DialogActions,
  Chip,
  Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from '../../styles';
import { IAvailableTool } from '../../interfaces';

const ToolDetails = ({
  open,
  onClose,
  onOkClicked,
  tool,
}: {
  open: boolean;
  onClose: Function;
  onOkClicked: Function;
  tool: IAvailableTool | undefined;
}) => {
  const classes = useStyles();

  const metaData = tool?.metadata;

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography variant="h6">{metaData?.name}</Typography>
        <IconButton className={classes.closeButton} onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className={classes.cardDescription}>
          <Typography
            variant="body2"
            className={classes.toolCardDescription}
            dangerouslySetInnerHTML={{
              __html: metaData?.description,
            }}
          />
        </div>

        <Box component="div" className={classes.marginTop2}>
          {metaData?.tags?.map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              size="small"
              className={classes.chip}
            />
          ))}
        </Box>
      </DialogContent>

      <DialogActions>
        <Grid>
          <Button
            className={classes.actionButton}
            onClick={() => onOkClicked()}
          >
            OK
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ToolDetails;
