import React from 'react';
import { Avatar } from '@backstage/core-components';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useCustomStyles } from './CardStyles';
import { DEFAULT_NAMESPACE } from '@backstage/catalog-model';

const OwnerCard = ({
  ownerName,
  picture,
  componentCount,
}: {
  ownerName: string;
  picture: string;
  componentCount: string;
}) => {
  const classes = useCustomStyles();

  return (
    <div className={classes.CardContainerFlex}>
      <Avatar
        displayName={ownerName}
        picture={picture}
        customStyles={{
          width: '48px',
          height: '48px',
          fontSize: '24px',
          fontWeight: 'normal',
          lineHeight: '32px',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginBottom: '10px',
        }}
      />
      <Link
        to={`/catalog/${DEFAULT_NAMESPACE}/user/${ownerName}`}
        style={{ margin: '0px auto 27px' }}
      >
        <Typography className={classes.CardTitleText}>{ownerName}</Typography>
      </Link>
      <Link
        to={`/catalog/${DEFAULT_NAMESPACE}/user/${ownerName}/user-assets`}
        style={{ margin: '0px auto' }}
      >
        <Typography className={classes.CardContentOwnerText}>
          {`${componentCount} components`}
        </Typography>
      </Link>
    </div>
  );
};

export default OwnerCard;
