import React, { useEffect, useState, useContext } from 'react';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { Page, Header } from '@backstage/core-components';
import {
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  FormHelperText,
} from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import * as history from 'history';
import { HelpComponent } from '../utils/helpComponent';
import BackButton from '../utils/backButton';
import { useCustomStyles } from './FeedbackStyles';
import { AuthContext } from '../../providers/AuthProvider';
import { feedbackApiRef } from '../../apis/feedbackApis';
import Constants from './Constants';
import HELP_URL from '../utils/helpLinkConstant';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';
import { useGetCategories } from './hooks/useGetCategories';

export const limitChars = (val: string, limit: number) => {
  if (val) {
    if (val.length > limit) {
      return val.substring(0, limit);
    }
  }
  return val;
};

const RegisterFeedback = () => {
  const classes = useCustomStyles();
  const { userInfo, profEmail, isAdmin } = useContext(AuthContext);

  const feedbackApi = useApi(feedbackApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const params = useParams();

  const [_title, setTitle] = useState('');
  const [_feedbackType, setFeedbackType] = useState<string>('');
  const [_feedbackCategory, setFeedbackCategory] = useState<string>('');
  const [_description, setDescription] = useState('');
  const [_feedbackUser, setFeedbackUser] = useState('');
  const [_statusReview, setStatusReview] = useState<string>('');
  const [isEdit, setIsEdit] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState(true);

  // Validation fields
  const [_titleValidationErr, setTitleValidationError] = useState('');
  const [_typeValidationErr, setTypeValidationError] = useState('');
  const [_categoryValidationErr, setCategoryValidationErr] = useState('');
  const [_descriptionValidationErr, setDescriptionValidationError] =
    useState('');

  const [inProgress, setInProgress] = useState(false);

  const hist = history.createBrowserHistory();

  const handleRedirect = () => {
    hist.back();
  };

  const userEmail = profEmail?.toLowerCase();
  const [categories] = useGetCategories(true);

  const handleSubmit = () => {
    feedbackApi
      .saveFeedback(
        userEmail || '',
        _feedbackType || '',
        _feedbackCategory || '',
        _title,
        _description,
      )
      .then((res: any) => {
        setInProgress(false);

        notificationApi.sendNotification({
          message: `Feedback ${_title} updated successfully, updated version will be available shortly`,
          disapperAfterMs: 2500,
          severity: 'success',
          callback: handleRedirect,
        });
      })
      .catch((err: any) => {
        notificationApi.sendNotification({
          message: `Error occurred - ${err?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  const handleEdit = async () => {
    if (params.id) {
      feedbackApi
        .reviewFeedback(
          userEmail || '',
          _statusReview,
          _feedbackCategory,
          params.id,
        )
        .then((res: any) => {
          setInProgress(false);

          notificationApi.sendNotification({
            message: `Feedback ${_title} updated successfully, updated version will be available shortly`,
            disapperAfterMs: 2500,
            severity: 'success',
            callback: handleRedirect,
          });
        })
        .catch((err: any) => {
          notificationApi.sendNotification({
            message: `Error occurred - ${err?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });
    }
  };

  useEffect(() => {
    if (params.id && userInfo) {
      if (isAdmin === false) {
        window.location.replace('/');
        return;
      }
      setIsLoading(true);

      feedbackApi
        .getFeedbackWithId(params.id)
        .then((respData: any) => {
          const responseData = respData?.data?.[0];
          setIsLoading(false);
          setStatusReview(responseData?.status || '');
          setFeedbackUser(responseData?.username || '');
          setTitle(responseData?.title || '');
          setDescription(responseData?.feedback || '');
          setFeedbackType(responseData?.feedbacktype || '');
          setFeedbackCategory(responseData?.category || '');
        })
        .catch((err: any) => {
          setIsLoading(false);
          notificationApi.sendNotification({
            message: `Error occurred - ${err?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });

      setIsEdit(true);
    }
    return;
  }, [userInfo]);

  const validateInputs = async () => {
    const valResult = yup
      .string()
      .required('Title is required')
      .min(3, 'At least 3 characters required')
      .max(100, 'Maximum 100 characters')
      .validate(_title)
      .then(() => {
        setTitleValidationError('');
        return true;
      })
      .catch(err => {
        setTitleValidationError(err.message);
        return false;
      });
    const typeResult = yup
      .string()
      .required('Type is required')
      .validate(_feedbackType)
      .then(() => {
        setTypeValidationError('');
        return true;
      })
      .catch(err => {
        setTypeValidationError(err.message);
        return false;
      });
    const category = yup
      .string()
      .required('Category is required')
      .validate(_feedbackCategory)
      .then(() => {
        setCategoryValidationErr('');
        return true;
      })
      .catch(err => {
        setCategoryValidationErr(err.message);
        return false;
      });

    const descResult = yup
      .string()
      .required('Feedback is required')
      .min(3, 'At least 3 characters required')
      .max(500, 'Maximum 500 characters')
      .validate(_description)
      .then(() => {
        setDescriptionValidationError('');
        return true;
      })
      .catch(err => {
        setDescriptionValidationError(err.message);
        return false;
      });
    const allPromises = await Promise.all([
      valResult,
      typeResult,
      category,
      descResult,
    ]);
    return allPromises.reduce((prev, current) => {
      return prev && current;
    });
  };

  const onSubmitRegister = async () => {
    if (isEdit) {
      handleEdit();
      setInProgress(true);
    } else {
      if (await validateInputs()) {
        handleSubmit();
        setInProgress(true);
      }
    }
  };

  return (
    <Page themeId="feedback-register">
      <Header
        title={
          isEdit
            ? Constants.pageTitle.editPage
            : Constants.pageTitle.registerPage
        }
        subtitle={
          <DevxBreadCrumb
            routes={[
              {
                type: 'link',
                link: '/',
                text: 'Home',
              },
              {
                type: 'link',
                link: '/feedback',
                text: 'Feedback',
              },
              {
                type: 'text',
                text: isEdit ? 'Edit' : 'Register',
              },
            ]}
          />
        }
      />
      <div className={classes.feedbackPageContainer}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.feedbackPageSearch}
          >
            <BackButton />
            <HelpComponent
              helpUrl={
                isEdit ? HELP_URL.editFeedback : HELP_URL.registerFeedback
              }
            />
          </Grid>
        </Grid>

        {(isLoading || inProgress) && <LinearProgress />}
        <Grid
          container
          direction="row"
          className={classes.FeedbackExtContentStyle}
        >
          <div className={classes.FeedbackExtGridStyle}>
            <section>
              <div>Fill the form details</div>
              <Grid item xs={12} sm={12}>
                <TextField
                  className={classes.Feedbackfields}
                  disabled={isEdit}
                  required
                  placeholder="Type here..."
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="feedback-title"
                  label="Title"
                  onChange={event => {
                    const value = limitChars(event?.target?.value, 200);
                    setTitle(value);
                  }}
                  {...(_titleValidationErr && {
                    error: true,
                    helperText: _titleValidationErr,
                  })}
                  value={_title}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl
                  required
                  className={classes.Feedbackfields}
                  {...(_typeValidationErr && {
                    error: true,
                  })}
                >
                  <InputLabel shrink variant="outlined">
                    Type
                  </InputLabel>
                  <Select
                    label="Type"
                    id="feedback-register-types"
                    multiline
                    onChange={event => {
                      setFeedbackType(event?.target?.value || '');
                    }}
                    disabled={isEdit}
                    value={_feedbackType || ''}
                    variant="outlined"
                  >
                    {Constants.feedbackTypeOptions.map(option => (
                      <MenuItem value={option}>{option}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl
                  required
                  className={classes.Feedbackfields}
                  {...(_categoryValidationErr && {
                    error: true,
                  })}
                >
                  <InputLabel shrink variant="outlined">
                    Category
                  </InputLabel>
                  <Select
                    label="Category"
                    id="feedback-register-categories"
                    multiline
                    disabled={
                      _feedbackCategory !== '' && disabled ? true : false
                    }
                    onChange={event => {
                      setFeedbackCategory(event?.target?.value || '');
                      setDisabled(false);
                    }}
                    value={_feedbackCategory || ''}
                    variant="outlined"
                  >
                    {categories?.map(option => (
                      <MenuItem value={option}>{option}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  className={classes.Feedbackfields}
                  disabled={isEdit}
                  multiline
                  required
                  placeholder="Feedback here..."
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    minRows: 10,
                    maxRows: 10,
                    style: { height: '200px' },
                  }}
                  id="feedback-text"
                  label="Feedback"
                  onChange={event => {
                    const value = limitChars(event?.target?.value, 500);
                    setDescription(value);
                  }}
                  {...(_descriptionValidationErr && {
                    error: true,
                    helperText: _descriptionValidationErr,
                  })}
                  value={_description || ''}
                  variant="outlined"
                />
              </Grid>

              {isEdit && (
                <>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      className={classes.Feedbackfields}
                      disabled={isEdit}
                      style={{ width: '100%' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      id="feedback-username"
                      label="Created by"
                      onChange={event => {
                        const value = limitChars(event?.target?.value, 200);
                        setFeedbackUser(value);
                      }}
                      value={_feedbackUser}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl className={classes.Feedbackfields}>
                      <InputLabel shrink variant="outlined">
                        Status
                      </InputLabel>
                      <Select
                        style={{ width: '100% ' }}
                        id="feedback-register-options"
                        label="Status"
                        multiline
                        onChange={event => {
                          setStatusReview(event?.target?.value || '');
                        }}
                        value={_statusReview || ''}
                        variant="outlined"
                      >
                        {Constants.feedbackStatusOptions.map(option => (
                          <MenuItem value={option}>{option}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={12} className={classes.feedbackSubmitBtn}>
                <Button
                  className={classes.submitRegisterBtn}
                  variant="contained"
                  color="primary"
                  onClick={onSubmitRegister}
                >
                  Submit
                </Button>
              </Grid>
            </section>

            <section className={classes.FeedbackDesc}>
              <div className="feedback-info-title">
                {Constants.descriptionTexts.descriptionTitle}
              </div>
              <div className="feedback-spacer" />
              <div className="feedback-info">
                <section>
                  {Constants.descriptionTexts.descriptionInfoTitle}
                </section>
                <section className="reg-mt20">
                  {Constants.descriptionTexts.descriptionInfoExample.title}
                </section>
                <section>
                  {Constants.descriptionTexts.descriptionInfoExample.text}
                </section>
                <section className="reg-mt20">
                  {Constants.descriptionTexts.descrptionInfoText}
                </section>
              </div>
            </section>
          </div>
        </Grid>
      </div>
    </Page>
  );
};
export default RegisterFeedback;
