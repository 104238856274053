import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SearchBar from './SearchBar';
import CloseIcon from '@material-ui/icons/Close';
import PromptTags from './PromptTags';
import { useStyles } from '../styles';
import { IPromptTag } from '../interfaces';

export interface PopupProps {
  open: boolean;
  onClose: () => void;
  onConfirm: Function;
  popupContent: any;
}

const AllTagsPopup = ({
  open,
  onClose,
  onConfirm,
  popupContent,
}: PopupProps) => {
  const [searchText, setSearchText] = useState('');

  const [tags, setTags] = useState<IPromptTag[]>([]);

  useEffect(() => {
    if (popupContent.tags.length > 0) {
      setTags(popupContent.tags);
    }
  }, [popupContent.tags]);

  const [filteredTags, setFilterTags] = useState<IPromptTag[]>([]);

  useEffect(() => {
    if (tags.length > 0) {
      setFilterTags(
        tags?.filter((tag: any) => {
          return tag.label.toLowerCase().includes(searchText.toLowerCase());
        }),
      );
    }
  }, [tags, searchText]);

  const handleTagClicked = (tag: IPromptTag) => {
    setTags(
      tags?.map((localtag: IPromptTag) =>
        tag.label === localtag.label ? tag : localtag,
      ),
    );
  };

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.dialogBox }}
    >
      <DialogTitle>
        <div>
          <span>All tags</span>
        </div>
        <IconButton onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div>
          <SearchBar
            inputText={searchText}
            placeHolderText="Search tags"
            onTextChanged={(value: string) => setSearchText(value)}
          />
        </div>
        <div className={classes.savePromptContent}>
          {filteredTags?.length === 0 ? (
            <Typography>No tags found</Typography>
          ) : (
            <PromptTags
              tags={filteredTags}
              onTagClicked={handleTagClicked}
              onResize={() => {}}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions className={classes.footerSection}>
        <Button
          onClick={() => onClose()}
          color="primary"
          data-testid="no-btn"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          data-testid="no-btn"
          variant="contained"
          autoFocus
          onClick={() => onConfirm(tags)}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AllTagsPopup;
