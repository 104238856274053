import React from 'react';
import { useNavigate, Routes, Route, Outlet } from 'react-router-dom';
import {
  Grid,
  Button,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { HelpComponent } from '../../utils/helpComponent';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import BackButton from '../../utils/backButton';
import HELP_URL from '../../utils/helpLinkConstant';
import ToolsListPage from './tools';
import AddEditTool from './tools/addEditTool';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    theme: {
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

export const IndexPage = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <BackButton />
        <HelpComponent helpUrl={HELP_URL.ADMIN_TOOLBOX_LANDING_PAGE} />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <div className={`infocard-admin ${classes.theme}`}>
          <h1>Tools</h1>
          <p>View and manage tools in toolbox</p>

          <div className="button-see-more">
            <Button
              id="adm-edit-tools-card-btn"
              data-testid="adm-edit-tools-card-btn"
              color="primary"
              variant="contained"
              onClick={() => {
                navigate('tools');
              }}
              endIcon={<NavigateNextOutlinedIcon />}
            >
              Go
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

const ToolboxAdminIndexPage = () => {
  return (
    <div>
      <Routes>
        <Route path="/" caseSensitive element={<ToolsListPage />}></Route>
        <Route path="/edit/:id" element={<AddEditTool />}></Route>
      </Routes>
      <Outlet />
    </div>
  );
};
export default ToolboxAdminIndexPage;
