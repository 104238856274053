import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const HashIcon = (props: any) => (
  <SvgIcon
    viewBox={'48 48'}
    style={{ transform: 'scale(3)' }}
    fill="#000000"
    {...props}
  >
    <path
      d="M14.785 14.28L14.291 16.386H12.354L12.8479 14.28H14.785Z"
      fill="#28CBFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 3H3V21H21V3ZM14.018 17.53L13.42 20H14.759L15.344 17.53H16.956L17.177 16.386H15.617L16.124 14.28H17.593L17.84 13.123H16.3969L16.982 10.666H15.656L15.071 13.123H13.134L13.719 10.666H12.38L11.795 13.123H10.183L9.96195 14.28H11.5219L11.0149 16.386H9.54595L9.29895 17.53H10.742L10.1569 20H11.483L12.081 17.53H14.018Z"
      fill="#28CBFF"
    />
  </SvgIcon>
);

export default HashIcon;
