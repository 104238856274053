export type IRegExFlagsOptions = {
  name: string;
  value: string;
  description?: string;
};

export const FLAGS: IRegExFlagsOptions[] = [
  {
    name: 'global',
    value: 'g',
    description: "Don't return after first match",
  },
  {
    name: 'multi line',
    value: 'm',
    description: '^ and $ match start/end of the line',
  },
  {
    name: 'insensitive',
    value: 'i',
    description: 'Case insensitive match',
  },
  {
    name: 'sticky',
    value: 'y',
    description:
      'Anchor to start of pattern, or at the end of the most recent match',
  },
  {
    name: 'unicode',
    value: 'u',
    description: 'Match with full unicode',
  },
  {
    name: 'vnicode',
    value: 'v',
    description: 'Enable all unicode and character set features',
  },
  {
    name: 'single line',
    value: 's',
    description: 'Dot matches newline',
  },
  {
    name: 'indices',
    value: 'd',
    description: 'The regex engine returns match indices',
  },
];

export const DELIMITER_OPTIONS = [
  '/',
  //  '~', '@', ';', '%', '`', '#'  // JavaScript does not support other delimiters
];

export type MatchResultPart = {
  str: string;
  index: number;
  raw: any;
};

export const debounce = (fn: any, delay: any) => {
  let timeoutId: any;
  // @ts-expect-error
  return function (...args) {
    clearTimeout(timeoutId);
    // @ts-expect-error
    timeoutId = setTimeout(() => fn.apply(this, args), delay);
  };
};
