import { AxiosInstance, AxiosResponse } from 'axios';
import { createApiRef } from '@backstage/core-plugin-api';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';

export interface GamificationApi {
  getLeaderBoard: () => Promise<AxiosResponse>;
  getLeaderBoardWithFilter: (payload:string) => Promise<AxiosResponse>;
  getCurrentLeaderBoard: () => Promise<AxiosResponse>;
  getLeaderBoardByUser: (username:string) => Promise<AxiosResponse>;
  getRewardsTopPlayers: () => Promise<AxiosResponse>;
  updateAdhocPoints: (payload:any) => Promise<AxiosResponse>;
  addLevelAndBadges: (payload:any) => Promise<AxiosResponse>;
  updateLevelAndBadges: (payload:any) => Promise<AxiosResponse>;
  getRewardsLevels: () => Promise<AxiosResponse>;
  deleteRewardsLevels: (id:string) => Promise<AxiosResponse>; 
  updateRewardsCriteria: (payload:any) => Promise<AxiosResponse>;
  getRewardsCriteria: () => Promise<AxiosResponse>;
}

export const gamificationApiRef = createApiRef<GamificationApi>({
  id: 'cbre.devx.api.gamification',
});

export class GamificationApiImpl implements GamificationApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }


  async getLeaderBoard():Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;
    return instance.get(`service/rewards/leaderboard`).then(res => res.data);
  }

  async getLeaderBoardWithFilter(org:String):Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;
    let url = org === "cumulative" ? `service/rewards/leaderboard` : `service/rewards/leaderboard/${org}`
    return instance.get(url).then(res => res.data);
  }

  async getCurrentLeaderBoard():Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;
    return instance.get(`service/rewards/leaderboard/top`).then(res => res.data);
      
  }

  async getLeaderBoardByUser(username:string):Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance.get(`service/rewards?user=${username}`).then(res => res.data);
  }

  async getRewardsTopPlayers():Promise<AxiosResponse>{
    const instance = await this.axiosInstancePromise;
    return instance.get(`service/rewards/players`).then(res => res.data);
  }

  async updateAdhocPoints(payload:any):Promise<AxiosResponse>{
    const instance = await this.axiosInstancePromise;
    return instance.post(`service/rewards`, {
        ...payload,
      })
      
  }

  async addLevelAndBadges(payload:any):Promise<AxiosResponse>{
    const instance = await this.axiosInstancePromise;
    return instance.post(`service/rewards/levels`, {
        ...payload,
      })
      
  }

  async updateLevelAndBadges(payload:any):Promise<AxiosResponse>{
    const instance = await this.axiosInstancePromise;
    return instance.post(`service/rewards/levels/${payload.id}`, {
        ...payload,
      })
      
  }

  async getRewardsLevels():Promise<AxiosResponse>{
    const instance = await this.axiosInstancePromise;
    return instance.get(`service/rewards/levels`).then(res => res.data);
      
  }

  async updateRewardsCriteria(payload:any):Promise<AxiosResponse>{
    const instance = await this.axiosInstancePromise;
    return instance.put(`service/rewards/criteria/${payload.id}`, {
        ...payload,
      })
      
  }
  async  getRewardsCriteria():Promise<AxiosResponse>{
    const instance = await this.axiosInstancePromise;
    return instance.get(`service/rewards/criteria`).then(res => res.data);
      
  }

  async deleteRewardsLevels(id:string):Promise<AxiosResponse>{
    const instance = await this.axiosInstancePromise;
    return instance.delete(`service/rewards/levels/${id}`).then(res => res.data);
      
  }

}
