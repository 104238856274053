import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { generatePath } from 'react-router-dom';
import { displayImage } from '../../home/allAssets/cardView/AllAssetsCard';
import { useCustomStyles } from './CardStyles';
import { DEFAULT_NAMESPACE } from '@backstage/catalog-model';

const EntityCard = ({
  type,
  title,
  description,
  timestamp,
  kind,
}: {
  type: string;
  title: string;
  description: string;
  timestamp: string;
  kind: string;
}) => {
  const classes = useCustomStyles();
  return (
    <div className={classes.CardEntityContainer}>
      <Grid container className={classes.Container}>
        <Grid item xs={2} sm={2} md={2} lg={1}>
          <div className={classes.CardTypeContainer}>
            <div className={classes.CardTypeImage}>
              {kind === 'API' ? displayImage('openapi') : displayImage(type)}
            </div>
            <Typography className={classes.CardTypeFont}>{type}</Typography>
          </div>
        </Grid>
        <Grid item xs={7} sm={7} md={7} lg={9}>
          <Link
            to={generatePath(`/catalog/${DEFAULT_NAMESPACE}/${kind}/${title}`)}
          >
            <Typography className={classes.CardTitleText}>{title}</Typography>
          </Link>
          <Typography className={classes.CardContentText}>
            {description && description.length > 100
              ? `${description.substring(0, 100)}...`
              : description}
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={2}
          className={classes.CardContentContainer}
        >
          <Typography
            className={classes.CardContentTextTimestamp}
            component="span"
          >
            {timestamp}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default EntityCard;
