import React, { useState, useContext, useEffect } from 'react';
import {
  EntityKindPicker,
  EntityListProvider,
  CatalogFilterLayout,
  useEntityList,
} from '@backstage/plugin-catalog-react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  Button,
  Grid,
  LinearProgress,
  makeStyles,
  Button as MaterialButton,
  Theme,
  createStyles,
} from '@material-ui/core';
import { Page, Header, Content } from '@backstage/core-components';
import { EntityTagPicker } from '../utils/EntityTagPicker';
import { HelpComponent } from '../utils/helpComponent';
import HELP_URL from '../utils/helpLinkConstant';
import { CodeTable } from './codeTable';
import BackButton from '../utils/backButton';
import { PromotionContext } from '../../providers/PromotionDataProvider';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';
import Filters from './Filters';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    codePage: {
      ['@media (max-width:920px) and (min-width: 280px)']: {
        padding: '0',
      },
    },
    toggleBtnGroup: {
      padding: '5.5px 0px',
    },
    toggleBtn: {
      padding: '0px 5px',
    },
    assetPublishingGuideStyle: {
      textTransform: 'capitalize',
      float: 'right',
    },
  }),
);

const initialState = {
  isTopRatingFilterClick: false,
  isRecentlyPublishedFilterClick: false,
  isAllFilterClick: true,
  isFavoriteFilterClick: false,
  isIacFilterClick: false,
  isPackageFilterClick: false,
  isSalesforceFilterClick: false,
  isAIFilterClick: false,
};

export const Code = () => {
  const [view, setView] = useState<any>('');
  const classes = useStyles();
  const [sort, setSort] = useState<any>(initialState);
  const [loading, setLoading] = useState(true);
  const onclickHelpButton = useContext(PromotionContext);

  useEffect(() => {
    onclickHelpButton.setToggleHelpButton(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const storedView = sessionStorage.getItem('assets-view');
    if (view) {
      sessionStorage.setItem('assets-view', view);
    } else {
      const newView =
        storedView === 'table' || storedView === 'card' ? storedView : 'card';
      setView(newView);
    }
  }, [view]);

  const handleFilterButtonClicked = (filterName: string) => {
    setSort({
      ...initialState,
      [filterName]:
        filterName === 'isRecentlyPublishedFilterClick' ||
        filterName === 'isTopRatingFilterClick'
          ? !sort[filterName]
          : true,
      isAllFilterClick:
        filterName === 'isAllFilterClick' ||
        filterName === 'isRecentlyPublishedFilterClick' ||
        filterName === 'isTopRatingFilterClick'
          ? true
          : false,
    });
  };

  /*  const FetchData = ({ onChange }: { onChange: any }) => {
    const { loading } = useEntityList();
    onChange(loading);
    return <EntityKindPicker initialFilter="component" hidden />;
  }; */

  return (
    <Page themeId="home">
      <Header
        title="Components"
        pageTitleOverride="Code"
        subtitle={
          <DevxBreadCrumb
            routes={[
              {
                type: 'link',
                link: '/',
                text: 'Home',
              },
              {
                type: 'text',
                link: '',
                text: 'Components',
              },
            ]}
          />
        }
      />
      <Content>
        {loading && <LinearProgress />}
        <div className={`codePage ${classes.codePage}`}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <BackButton />
            <div className="ToggleButtonGroup">
              <ToggleButtonGroup
                value={view}
                exclusive={false}
                onChange={() => {
                  setView(view === 'table' ? 'card' : 'table');
                }}
                className={classes.toggleBtnGroup}
              >
                <ToggleButton
                  value="table"
                  className={classes.toggleBtn}
                  color="primary"
                >
                  Table
                </ToggleButton>
                <ToggleButton
                  value="card"
                  className={classes.toggleBtn}
                  color="primary"
                >
                  Card
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <HelpComponent helpUrl={HELP_URL.Code_Library} />
            <Button
              className={classes.assetPublishingGuideStyle}
              variant="contained"
              color="primary"
              href="/help/assetpublish/"
            >
              Asset Publishing Guide
            </Button>
          </Grid>
          <EntityListProvider>
            <CatalogFilterLayout>
              <CatalogFilterLayout.Filters>
                <EntityKindPicker initialFilter="component" hidden />
                <Filters
                  view={view}
                  sort={sort}
                  onClick={handleFilterButtonClicked}
                />
                <EntityTagPicker />
              </CatalogFilterLayout.Filters>
              <CatalogFilterLayout.Content>
                <CodeTable
                  view={view}
                  mountedOn="code-library"
                  setLoading={setLoading}
                  sort={sort}
                />
              </CatalogFilterLayout.Content>
            </CatalogFilterLayout>
          </EntityListProvider>
        </div>
      </Content>
    </Page>
  );
};
