import React from 'react';

const Feedback = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 2.5H5C3.625 2.5 2.5125 3.625 2.5125 5L2.5 27.5L7.5 22.5H25C26.375 22.5 27.5 21.375 27.5 20V5C27.5 3.625 26.375 2.5 25 2.5ZM25 20H6.4625L5 21.4625V5H25V20ZM13.125 17.5H22.5V15H15.625L13.125 17.5ZM17.95 10.1625C18.2 9.9125 18.2 9.525 17.95 9.275L15.7375 7.0625C15.4875 6.8125 15.1 6.8125 14.85 7.0625L7.5 14.4125V17.5H10.5875L17.95 10.1625Z"
        fill="#17E88F"
      />
    </svg>
  );
};

export default Feedback;
