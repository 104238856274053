import React from 'react';
import { Typography } from '@material-ui/core';
import BadgeDisplayImage from '../badgeTypePicker';
import { useCustomStyles } from './CardStyles';

export interface CategoryCardProps {
  data: {
    name: string;
    count: number;
    totalpoints: number;
    description: string;
  };
}

const CategoryCard = (props: CategoryCardProps) => {
  const { name, count, totalpoints, description } = props.data;
  const classes = useCustomStyles();

  return (
    <div className={classes.categoryCard}>
      <div className={classes.categoryCardLeft}>
        <BadgeDisplayImage type={name} />
        <div className={classes.categoryCardLabel}>
          <Typography>{description}</Typography>
          <Typography>{count}</Typography>
        </div>
      </div>
      <div className="categoryCardRight">
        <Typography>{`${totalpoints} Points`}</Typography>
      </div>
    </div>
  );
};

export default CategoryCard;
