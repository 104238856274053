import { AxiosInstance, AxiosResponse } from 'axios';
import { createApiRef } from '@backstage/core-plugin-api';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';

export interface HelpApi {
    getContent: (path: String) => Promise<any>;
    getEntity: (entityName: String) => Promise<any>;
}

export const helpApiRef = createApiRef<HelpApi>({
  id: 'cbre.devx.api.help',
});

export class HelpApiImpl implements HelpApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  async getContent(path: String) {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`${path}`)
      .then(res => res.data);
  }

  async getEntity(entityName: String) {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`catalog/entities/by-name/Component/default/${entityName}`)
      .then(res => res.data);
  }
}