import React from 'react';
import { General } from './General';
import { Header, Page, TabbedLayout } from '@backstage/core-components';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';

export const SettingsPage = () => {
  return (
    <Page themeId="home">
      <Header
        title="User Setting"
        subtitle={
          <DevxBreadCrumb
            routes={[
              {
                type: 'link',
                link: '/',
                text: 'Home',
              },
              {
                type: 'text',
                link: '',
                text: 'User Setting',
              },
            ]}
          />
        }
      />

      <TabbedLayout>
        <TabbedLayout.Route path="/" title="General">
          <General />
        </TabbedLayout.Route>
      </TabbedLayout>
    </Page>
  );
};
