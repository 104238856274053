import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import BackButton from '../../utils/backButton';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import { Content, PageWithHeader } from '@backstage/core-components';
import { DevxBreadCrumb } from '../../common/BreadcrumbsNav/DevxBreadCrumb';
import { useNavigate } from 'react-router';
import { PocListContainer } from './PocListContainer';
import { EntityListProvider } from '@backstage/plugin-catalog-react';
import { useStyles } from '../common/styles';

export const PocListPage = () => {
  const classes = useStyles();

  const navigate = useNavigate();

  const navigateToAddPocPage = () => {
    navigate('/poc-hub/add-poc');
  };

  return (
    <PageWithHeader
      title="POC Hub"
      themeId="home"
      subtitle={
        <DevxBreadCrumb
          routes={[
            {
              type: 'link',
              link: '/',
              text: 'Home',
            },
            {
              type: 'text',
              link: '',
              text: 'POC Hub',
            },
          ]}
        />
      }
    >
      <Content>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <BackButton />
            <HelpComponent helpUrl={HELP_URL.POC_LANDING_PAGE} />
          </Grid>
        </Grid>

        <div className={classes.listPage}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.listHeader}
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.addPOCButton}
                onClick={navigateToAddPocPage}
              >
                Add POC
              </Button>
            </Grid>
          </Grid>
        </div>

        <EntityListProvider>
          <PocListContainer />
        </EntityListProvider>
      </Content>
    </PageWithHeader>
  );
};
