import React, { forwardRef } from 'react';

type PropType = {
  slides: any[];
  ref: any;
};

const EmblaCarousel: React.FC<PropType> = forwardRef((props, ref) => {
  const { slides } = props;

  return (
    <div className="embla">
      <div className="embla__viewport" ref={ref}>
        <div className="embla__container">
          {slides.map((item, index) => (
            <div className="embla__slide" key={index}>
              {item}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default EmblaCarousel;
