import React, { useEffect, useState } from 'react';
import {
  InfoCard,
  WarningPanel,
  CodeSnippet,
} from '@backstage/core-components';
import PlayersCard from './Grid/playersCard';
import { Grid, LinearProgress } from '@material-ui/core';
import { HelpComponent } from '../utils/helpComponent';
import BackButton from '../utils/backButton';
import HELP_URL from '../utils/helpLinkConstant';
import { makeStyles, createStyles } from '@material-ui/core';
import { gamificationApiRef } from '../../apis/gamificationApis';
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';

const useStyles = makeStyles(() =>
  createStyles({
    playerCards: {
      padding: '0 !important',
    },
    topPlayerCards: {
      ['@media (max-width:920px) and (min-width: 280px)']: {
        marginBottom: '100px',
      },
    },
    title: {
      fontWeight: 'normal',
      padding: '16px 16px 23px 0px',
      ['@media (max-width:920px) and (min-width: 280px)']: {
        padding: '0px 0px 20px 0px !important',
      },
    },
    infoCard: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
    topPlayersHeader: {
      marginBottom: '15px !important',
    },
  }),
);

const TopPlayers = () => {
  const classes = useStyles();
  const [Data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const getTopPlayers = useApi(gamificationApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  useEffect(() => {
    setIsLoading(true);
    getTopPlayers
      .getRewardsTopPlayers()
      .then((res: any) => {
        setData(res);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTopPlayers]);

  if (isLoading) {
    return <LinearProgress />;
  }

  if (!isLoading && Data && Data.length === 0) {
    return (
      <div>
        <WarningPanel severity="error" title="Could not fetch top Players list">
          <CodeSnippet language="text" text="Error" />
        </WarningPanel>
      </div>
    );
  }

  return (
    <div className="TopPlayersDiv">
      <Grid container className={classes.topPlayersHeader}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <BackButton />
          <HelpComponent helpUrl={HELP_URL.topPlayers} />
        </Grid>
      </Grid>

      <InfoCard
        title="Top Players"
        headerProps={{ className: classes.title }}
        divider={false}
        className={classes.infoCard}
      >
        <Grid container className={classes.topPlayerCards}>
          {Data &&
            Data.length &&
            Data.map((item: any, index: number) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={2}
                key={`PlayersCards${index}`}
                className={classes.playerCards}
              >
                <PlayersCard data={item} />
              </Grid>
            ))}
        </Grid>
      </InfoCard>
    </div>
  );
};

export default TopPlayers;
