import { Grid, List } from '@material-ui/core';
import React from 'react';
import { PinButton } from './PinButton';
import { Profile } from './Profile';
import { InfoCard } from '@backstage/core-components';
import { UserSettingsThemeToggle } from '@backstage/plugin-user-settings';

export const General = () => {
  return (
    <Grid container direction="row" spacing={3}>
      <Grid item sm={12} md={6}>
        <Profile />
      </Grid>
      <Grid item sm={12} md={6}>
        <InfoCard title="Appearance">
          <List dense>
            <UserSettingsThemeToggle />
            <PinButton />
          </List>
        </InfoCard>
      </Grid>
    </Grid>
  );
};
