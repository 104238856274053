import React, { useEffect, useState, useContext } from 'react';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Page,
  Header,
  Link,
  Breadcrumbs,
  Content,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import {
  Button,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  LinearProgress,
} from '@material-ui/core';
import { useCustomStyles } from './DocStyles';
import EXT_DOCS_CONSTANTS from '../Constants';
import { IsBreadCrumbsDisabled } from '../../utils/constant';
import { hypenateTitle, limitChars } from './util';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import { Grid } from '@material-ui/core';
import BackButton from '../../utils/backButton';
import { AuthContext } from '../../../providers/AuthProvider';
import { extarctProfileName } from '../../utils/convertUserName';
import { externalDocsApiRef } from '../../../apis/externalDocsApi';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { DevxBreadCrumb } from '../../common/BreadcrumbsNav/DevxBreadCrumb';

const RegisterExternalDocs = () => {
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useCustomStyles();
  const navigate = useNavigate();
  const params = useParams();
  const [_title, setTitle] = useState('');
  const [_url, setUrl] = useState('');
  const [_description, setDescription] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  // Validation fields
  const [_titleValidationErr, setTitleValidationError] = useState('');
  const [_urlValidationErr, setUrlValidationError] = useState('');
  const [_descriptionValidationErr, setDescriptionValidationError] =
    useState('');

  const [_showDialog, setShowDialog] = useState(false);
  const [_dialogContent, setDialogContent] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [status, setStatus] = useState('info');

  const { profileName, profEmail } = useContext(AuthContext);

  const externalDocsApi = useApi(externalDocsApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  useEffect(() => {
    if (params.docName) {
      externalDocsApi
        .getDocumentByName(params.docName)
        .then(data => {
          setTitle(data?.title);
          setUrl(data?.url);
          setDescription(data?.description);
          setIsEdit(true);
        })
        .catch((err: any) => {
          notificationApi.sendNotification({
            message: `Error occurred - ${err?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
            callback: () => {
              navigate('/docs/external');
            },
          });
        });
    }
  }, [params.docName]);

  const validateInputs = async () => {
    const valResult = yup
      .string()
      .required(EXT_DOCS_CONSTANTS.registerForm.title.rules.required)
      .min(3, EXT_DOCS_CONSTANTS.registerForm.title.rules.minChars)
      .max(50, EXT_DOCS_CONSTANTS.registerForm.title.rules.maxChars)
      .validate(_title)
      .then(() => {
        setTitleValidationError('');
        return true;
      })
      .catch(err => {
        setTitleValidationError(err.message);
        return false;
      });
    const urlResult = yup
      .string()
      .required(EXT_DOCS_CONSTANTS.registerForm.url.rules.required)
      .url(EXT_DOCS_CONSTANTS.registerForm.url.rules.shouldBeUrl)
      .validate(_url)
      .then(() => {
        setUrlValidationError('');
        return true;
      })
      .catch(err => {
        setUrlValidationError(err.message);
        return false;
      });

    const descResult = yup
      .string()
      .required(EXT_DOCS_CONSTANTS.registerForm.description.rules.required)
      .min(3, EXT_DOCS_CONSTANTS.registerForm.description.rules.minChars)
      .max(200, EXT_DOCS_CONSTANTS.registerForm.description.rules.maxChars)
      .validate(_description)
      .then(() => {
        setDescriptionValidationError('');
        return true;
      })
      .catch(err => {
        setDescriptionValidationError(err.message);
        return false;
      });
    const allPromises = await Promise.all([valResult, urlResult, descResult]);
    return allPromises.reduce((prev, current) => {
      return prev && current;
    });
  };

  const onSubmitRegister = async () => {
    if (await validateInputs()) {
      const data = {
        title: hypenateTitle(_title),
        url: _url,
        description: _description,
        user: extarctProfileName(profileName),
        email: profEmail,
      };

      setInProgress(true);
      if (isEdit) {
        externalDocsApi
          .updateDocument(data)
          .then((data: any) => {
            setInProgress(false);
            setStatus('success');
            notificationApi.sendNotification({
              message: `Document ${data?.name} updated successfully, updated version will be available shortly`,
              disapperAfterMs: 2500,
              severity: 'success',
              callback: () => {
                navigate('/docs/external');
              },
            });
          })
          .catch((err: any) => {
            setInProgress(false);
            setStatus('error');
            notificationApi.sendNotification({
              message: `Error occurred - ${err?.message}`,
              disapperAfterMs: 2500,
              severity: 'error',
              callback: () => {
                navigate('/docs/external');
              },
            });
          });
      } else {
        externalDocsApi
          .addDocument(data)
          .then(() => {
            setInProgress(false);
            setStatus('success');
            notificationApi.sendNotification({
              message: EXT_DOCS_CONSTANTS.registeredSuccessMessage,
              disapperAfterMs: 2500,
              severity: 'success',
              callback: () => {
                navigate('/docs/external');
              },
            });
          })
          .catch((err: any) => {
            setInProgress(false);
            setStatus('error');
            notificationApi.sendNotification({
              message: `Error occurred - ${err?.message}`,
              disapperAfterMs: 2500,
              severity: 'error',
              callback: () => {
                navigate('/docs/external');
              },
            });
          });
      }
    }
  };

  let routes = [
    {
      type: 'link',
      link: '/',
      text: 'Home',
    },
    {
      type: 'link',
      link: '/docs',
      text: 'Docs',
    },
    {
      type: 'link',
      link: '/docs/external',
      text: 'External Docs',
    },
  ];

  if (isEdit) {
    routes = [
      ...routes,
      {
        type: 'text',
        link: '',
        text: 'Edit',
      },
    ];
  } else {
    routes = [
      ...routes,
      {
        type: 'text',
        link: '',
        text: 'Register',
      },
    ];
  }

  return (
    <Page themeId="external-docs-register">
      <Header
        title={EXT_DOCS_CONSTANTS.registerExternalHeading}
        type={
          IsBreadCrumbsDisabled ? (
            <span></span>
          ) : (
            <Breadcrumbs style={{ color: 'white' }}>
              <Link to="../../">Docs</Link>
              <Link to="../">External Docs</Link>
              <Typography>Register</Typography>
            </Breadcrumbs>
          )
        }
        subtitle={<DevxBreadCrumb routes={routes} />}
      />

      <Content className={classes.extDocsPage}>
        {inProgress && <LinearProgress />}
        <Grid
          item
          md={12}
          xs={12}
          sm={12}
          className={classes.RegisterTitleStyle}
        >
          {EXT_DOCS_CONSTANTS.registerPageTitle}{' '}
        </Grid>

        <div className={`codePage ${classes.registerDocsCodePage}`}>
          <Grid item md={12} xs={12}>
            <BackButton />
            <HelpComponent helpUrl={HELP_URL.RegisterExternalDocumentation} />
          </Grid>

          <Grid container spacing={3} className={classes.RegisterExtContent}>
            {/* Left side  */}
            <Grid item xs={12} sm={12} md={6} className={classes.RegisterForm}>
              <Grid container spacing={3} className="RegisterExtDoc">
                <Grid item xs={12}>
                  <div>{EXT_DOCS_CONSTANTS.registerForm.heading}</div>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={isEdit}
                    fullWidth
                    required
                    placeholder={
                      EXT_DOCS_CONSTANTS.registerForm.title.placeholder
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="ext-doc-title"
                    label={EXT_DOCS_CONSTANTS.registerForm.title.label}
                    onChange={event => {
                      const value = limitChars(event?.target?.value, 200);
                      setTitle(value);
                    }}
                    {...(_titleValidationErr && {
                      error: true,
                      helperText: _titleValidationErr,
                    })}
                    value={_title}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    placeholder={
                      EXT_DOCS_CONSTANTS.registerForm.url.placeholder
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="ext-doc-url"
                    label={EXT_DOCS_CONSTANTS.registerForm.url.label}
                    onChange={event => {
                      const value = limitChars(event?.target?.value, 200);
                      setUrl(value);
                    }}
                    {...(_urlValidationErr && {
                      error: true,
                      helperText: _urlValidationErr,
                    })}
                    value={_url || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    placeholder={
                      EXT_DOCS_CONSTANTS.registerForm.description.placeholder
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      minRows: 10,
                      maxRows: 10,
                      style: { height: '200px' },
                    }}
                    id="ext-doc-desc"
                    label={EXT_DOCS_CONSTANTS.registerForm.description.label}
                    multiline
                    onChange={event => {
                      const value = limitChars(event?.target?.value, 200);
                      setDescription(value);
                    }}
                    {...(_descriptionValidationErr && {
                      error: true,
                      helperText: _descriptionValidationErr,
                    })}
                    value={_description || ''}
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth={isMobile ? true : false}
                    variant="contained"
                    color="primary"
                    onClick={onSubmitRegister}
                    style={{ marginTop: '10px' }}
                  >
                    {EXT_DOCS_CONSTANTS.registerForm.submit}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {/* Right side help */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              className={classes.RegisterExtGridStyle}
            >
              <Grid container spacing={3} className="helpTextExtDoc">
                <Grid item xs={12}>
                  <div className="register-info-title">
                    {EXT_DOCS_CONSTANTS.registerInfo.heading}
                  </div>
                  <hr className="reg-spacer" />
                  <div className="register-info">
                    <section>{EXT_DOCS_CONSTANTS.registerInfo.title}</section>
                    <section className="reg-mt20">
                      {EXT_DOCS_CONSTANTS.registerInfo.url}
                    </section>
                    <section>
                      {EXT_DOCS_CONSTANTS.registerInfo.urlExample}
                    </section>
                    <section className="reg-mt20">
                      {EXT_DOCS_CONSTANTS.registerInfo.description}
                    </section>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Content>
    </Page>
  );
};
export default RegisterExternalDocs;
