import React, { useState, useContext } from 'react';
import {
  TextField,
  Button,
  Grid,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { Content, Progress } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import BackButton from '../../../utils/backButton';
import { HelpComponent } from '../../../utils/helpComponent';
import HELP_URL from '../../../utils/helpLinkConstant';
import {
  adminApiRef,
  ICreateCAVariableRequest,
} from '../../../../apis/adminApi';
import _ from 'lodash';
import { COST_AVOIDANCE_CONSTANTS } from '../constants';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';
import { AuthContext } from '../../../../providers/AuthProvider';

const useStyles = makeStyles(theme => ({
  editArea: {
    padding: '20px !important',
    background: theme.palette.background.paper,
    marginBottom: '48px',
  },
  formField: {
    marginBottom: '10px',
  },
  actionArea: {
    display: 'flex',
    justifyContent: 'flex-end',
    ['@media (max-width:920px) and (min-width: 280px)']: {
      flexDirection: 'column',
      marginTop: '0',
    },
  },
  cancelBtn: {
    marginRight: '10px',
    ['@media (max-width:920px) and (min-width: 280px)']: {
      width: '100%',
    },
  },
  saveBtn: {
    ['@media (max-width:920px) and (min-width: 280px)']: {
      width: '100%',
      marginTop: '15px',
    },
  },
}));

export interface AddCAVarsValidationState {
  complexityErrorFlag: boolean;
  complexityErrorMessage: string;
  assetKindErrorFlag: boolean;
  assetKindErrorMessage: string;
  assetTypeErrorFlag: boolean;
  assetTypeErrorMessage: string;
  manHoursErrorFlag: boolean;
  manHoursErrorMessage: string;
  blendedRateErrorFlag: boolean;
  blendedRateErrorMessage: string;
  coefficientErrorFlag: boolean;
  coefficient2ErrorFlag: boolean;
  coefficientErrorMessage: string;
  coefficient2ErrorMessage: string;
  formulaErrorFlag: boolean;
  formulaErrorMessage: string;
}

const initialValidationState: AddCAVarsValidationState = {
  complexityErrorFlag: false,
  complexityErrorMessage: '',
  assetKindErrorFlag: false,
  assetKindErrorMessage: '',
  assetTypeErrorFlag: false,
  assetTypeErrorMessage: '',
  manHoursErrorFlag: false,
  manHoursErrorMessage: '',
  blendedRateErrorFlag: false,
  blendedRateErrorMessage: '',
  coefficientErrorFlag: false,
  coefficient2ErrorFlag: false,
  coefficientErrorMessage: '',
  coefficient2ErrorMessage: '',
  formulaErrorFlag: false,
  formulaErrorMessage: '',
};

const AddCAVariable = () => {
  const [assetKind, setAssetKind] = useState<string>('');
  const [assetType, setAssetType] = useState<string | undefined>('');
  const [isDefaultComplexity, setIsDefaultComplexity] =
    useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [complexity, setComplexity] = useState<string>('');
  const [manHours, setManHours] = useState<any>('');
  const [blendedRate, setBlendedRate] = useState<any>('');
  const [coefficient, setCoefficient] = useState<any>('');
  const [coefficient2, setCoefficient2] = useState<any>('');
  const [formula, setFormula] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [validationState, setValidationState] =
    useState<AddCAVarsValidationState>({ ...initialValidationState });

  const adminApi = useApi(adminApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const userContext = useContext(AuthContext);

  const classes = useStyles();
  const navigate = useNavigate();

  const onClickCancel = (event: any) => {
    event?.stopPropagation();
    navigate('/admin/cost-avoidance/variables');
  };

  const validateAssetKindField = () => {
    let errorFlag = false;
    let errorMessage = '';

    if (!assetKind) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.ASSET_KIND_REQUIRED_MESSAGE;
    } else if (
      assetKind !== undefined &&
      String(assetKind).trim().length === 0
    ) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.ASSET_KIND_REQUIRED_MESSAGE;
    }
    return { errorFlag, errorMessage };
  };

  const validateComplexityField = () => {
    let errorFlag = false;
    let errorMessage = '';

    if (!complexity) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.COMPLEXITY_REQUIRED_MESSAGE;
    } else if (
      complexity !== undefined &&
      String(complexity).trim().length === 0
    ) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.COMPLEXITY_REQUIRED_MESSAGE;
    }
    return { errorFlag, errorMessage };
  };

  const validateManHoursField = () => {
    let errorFlag = false;
    let errorMessage = '';

    if (!manHours) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.MAN_HOURS_REQUIRED_MESSAGE;
    } else if (isNaN(+manHours)) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.MAN_HOURS_NOT_A_NUMBER_MESSAGE;
    } else if (+manHours < 1) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.MAN_HOURS_LESS_THAN_MIN_MESSAGE;
    } else if (+manHours > 10000) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.MAN_HOURS_MORE_THAN_MAX_MESSAGE;
    }
    return { errorFlag, errorMessage };
  };

  const validateBlendedRateField = () => {
    let errorFlag = false;
    let errorMessage = '';

    if (!blendedRate) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.BLENDED_RATE_REQUIRED_MESSAGE;
    } else if (isNaN(+blendedRate)) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.BLENDED_RATE_NOT_A_NUMBER_MESSAGE;
    } else if (+blendedRate < 1) {
      errorFlag = true;
      errorMessage =
        COST_AVOIDANCE_CONSTANTS.BLENDED_RATE_LESS_THAN_MIN_MESSAGE;
    } else if (+blendedRate > 200) {
      errorFlag = true;
      errorMessage =
        COST_AVOIDANCE_CONSTANTS.BLENDED_RATE_MORE_THAN_MAX_MESSAGE;
    }
    return { errorFlag, errorMessage };
  };

  const validateCoefficientField = () => {
    let errorFlag = false;
    let errorMessage = '';

    if (!coefficient) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.COEFFICIENT_REQUIRED_MESSAGE;
    } else if (isNaN(+coefficient)) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.COEFFICIENT_NOT_A_NUMBER_MESSAGE;
    } else if (+coefficient < 0.01) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.COEFFICIENT_LESS_THAN_MIN_MESSAGE;
    } else if (+coefficient > 2.5) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.COEFFICIENT_MORE_THAN_MAX_MESSAGE;
    }
    return { errorFlag, errorMessage };
  };

  const validateCoefficient2Field = () => {
    let errorFlag = false;
    let errorMessage = '';

    if (!coefficient2) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.COEFFICIENT2_REQUIRED_MESSAGE;
    } else if (isNaN(+coefficient2)) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.COEFFICIENT2_NOT_A_NUMBER_MESSAGE;
    } else if (+coefficient2 < 0.01) {
      errorFlag = true;
      errorMessage =
        COST_AVOIDANCE_CONSTANTS.COEFFICIENT2_LESS_THAN_MIN_MESSAGE;
    } else if (+coefficient2 > 2.5) {
      errorFlag = true;
      errorMessage =
        COST_AVOIDANCE_CONSTANTS.COEFFICIENT2_MORE_THAN_MAX_MESSAGE;
    }
    return { errorFlag, errorMessage };
  };

  const validateFormulaField = () => {
    let errorFlag = false;
    let errorMessage = '';

    if (!formula) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.FORMULA_REQUIRED_MESSAGE;
    } else if (formula !== undefined && String(formula).trim().length === 0) {
      errorFlag = true;
      errorMessage = COST_AVOIDANCE_CONSTANTS.FORMULA_REQUIRED_MESSAGE;
    }
    return { errorFlag, errorMessage };
  };

  const validateFields = () => {
    let newValidationState: AddCAVarsValidationState = { ...validationState };

    const assetKindResults = validateAssetKindField();
    newValidationState.assetKindErrorFlag = assetKindResults.errorFlag;
    newValidationState.assetKindErrorMessage = assetKindResults.errorMessage;

    const complexityResults = validateComplexityField();
    newValidationState.complexityErrorFlag = complexityResults.errorFlag;
    newValidationState.complexityErrorMessage = complexityResults.errorMessage;

    const manHoursResult = validateManHoursField();
    newValidationState.manHoursErrorFlag = manHoursResult.errorFlag;
    newValidationState.manHoursErrorMessage = manHoursResult.errorMessage;

    const blendedRateResult = validateBlendedRateField();
    newValidationState.blendedRateErrorFlag = blendedRateResult.errorFlag;
    newValidationState.blendedRateErrorMessage = blendedRateResult.errorMessage;

    const coefficientResults = validateCoefficientField();
    newValidationState.coefficientErrorFlag = coefficientResults.errorFlag;
    newValidationState.coefficientErrorMessage =
      coefficientResults.errorMessage;

    const coefficient2Results = validateCoefficient2Field();
    newValidationState.coefficient2ErrorFlag = coefficient2Results.errorFlag;
    newValidationState.coefficient2ErrorMessage =
      coefficient2Results.errorMessage;

    const formulaResults = validateFormulaField();
    newValidationState.formulaErrorFlag = formulaResults.errorFlag;
    newValidationState.formulaErrorMessage = formulaResults.errorMessage;

    setValidationState(newValidationState);
    return (
      !newValidationState.manHoursErrorFlag &&
      !newValidationState.blendedRateErrorFlag &&
      !newValidationState.coefficientErrorFlag &&
      !newValidationState.assetKindErrorFlag &&
      !newValidationState.complexityErrorFlag &&
      !newValidationState.formulaErrorFlag
    );
  };

  const onClickSave = () => {
    if (validateFields()) {
      const data: ICreateCAVariableRequest = {
        assetkind: assetKind,
        assettype: assetType,
        complexity: complexity?.toLowerCase() ?? '',
        defaultcomplexity: isDefaultComplexity,
        costavoidanceformula: formula,
        useremail: userContext?.profEmail?.toLowerCase(),
        manhours: +manHours,
        blendedrate: +blendedRate,
        coefficient: +coefficient,
        coefficient2: +coefficient2,
        active: isActive,
      };

      setLoading(true);
      adminApi
        .addCAVariable(data)
        .then((data: any) => {
          setLoading(false);
          if (data?.status == 200) {
            notificationApi.sendNotification({
              message: COST_AVOIDANCE_CONSTANTS.VARIABLE_ADDED_SUCCESS_MESSAGE,
              disapperAfterMs: 2500,
              severity: 'success',
              callback: () => {
                navigate('/admin/cost-avoidance/variables');
              },
            });
          } else {
            notificationApi.sendNotification({
              message: data?.message,
              disapperAfterMs: 2500,
              severity: 'warning',
            });
          }
        })
        .catch(err => {
          const message = err?.response?.data?.message || err?.message;
          notificationApi.sendNotification({
            message,
            disapperAfterMs: 2500,
            severity: 'error',
          });
          setLoading(false);
        });
    }
  };

  const limitToIntNumbers = (event: any): number | undefined | string => {
    let value = event.target.value;
    if (value && !_.isEmpty(value)) {
      let newValue = value.replace(/[^0-9]/g, '');
      return newValue;
    }
    return '';
  };

  const limitText = (event: any, limit: number): string => {
    let value = event.target.value;
    if (value && !_.isEmpty(value) && String(value).length > limit) {
      let newValue = String(value).substring(0, limit);
      return newValue;
    }
    return value;
  };

  const limitToNumbers = (event: any): number | undefined | string => {
    let value = event.target.value;
    if (value && !_.isEmpty(value)) {
      let newValue = value.replace(/[^0-9.,_]/g, '');
      if (newValue.indexOf('.') !== -1) {
        let partAfterDecimal = newValue.substring(newValue.indexOf('.'));
        if (partAfterDecimal.length > 3) {
          newValue =
            newValue.substring(0, newValue.indexOf('.')) +
            partAfterDecimal.substring(0, 3);
        }
      }
      return newValue;
    }
    return '';
  };

  const onChangeDefaultComplexity = (event: any, checked: boolean) => {
    event?.preventDefault();
    setIsDefaultComplexity(checked);
  };
  const onChangeIsActive = (event: any, checked: boolean) => {
    event?.preventDefault();
    setIsActive(checked);
  };

  return (
    <div>
      <Grid item md={12} xs={12}>
        <BackButton />
        <HelpComponent helpUrl={HELP_URL.COST_AVOIDANCE_VARIABLES_ADD} />
      </Grid>
      {loading && <Progress />}
      <Content>
        <Grid container>
          <Grid item xs={12} xl={6}>
            <Grid container className={classes.editArea}>
              <Grid item xs={12}>
                <Typography variant="h6">Add Variable</Typography>
                <Typography variant="caption">
                  Please remember to maintain a only single complexity as
                  default complexity for one kind of variable.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.formField}>
                  <TextField
                    id="kind-tf"
                    data-testid="kind-tf"
                    label="Asset Kind"
                    fullWidth
                    variant="outlined"
                    size="small"
                    required
                    value={assetKind}
                    error={validationState.assetKindErrorFlag}
                    helperText={
                      validationState.assetKindErrorFlag
                        ? validationState.assetKindErrorMessage
                        : ''
                    }
                    onChange={event => {
                      const value = limitText(event, 80);
                      setAssetKind(value);
                      setValidationState({
                        ...validationState,
                        assetKindErrorFlag: false,
                        assetKindErrorMessage: '',
                      });
                    }}
                  ></TextField>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.formField}>
                  <TextField
                    id="type-tf"
                    data-testid="type-tf"
                    label="Asset Type"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={assetType}
                    error={validationState.assetTypeErrorFlag}
                    helperText={
                      validationState.assetTypeErrorFlag
                        ? validationState.assetTypeErrorMessage
                        : ''
                    }
                    onChange={event => {
                      const value = limitText(event, 80);
                      setAssetType(value);
                      setValidationState({
                        ...validationState,
                        assetTypeErrorFlag: false,
                        assetTypeErrorMessage: '',
                      });
                    }}
                  ></TextField>
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div className={classes.formField}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    required
                  >
                    <InputLabel id="complexity-select-label">
                      Complexity
                    </InputLabel>
                    <Select
                      error={validationState?.complexityErrorFlag || false}
                      labelId="complexity-select-label"
                      value={complexity}
                      label="Complexity"
                      data-testid="complexity-select"
                      fullWidth
                      onChange={(event: any) => {
                        setComplexity(event?.target?.value);
                        setValidationState({
                          ...validationState,
                          complexityErrorFlag: false,
                          complexityErrorMessage: '',
                        });
                      }}
                    >
                      <MenuItem value="Low">Low</MenuItem>
                      <MenuItem value="Medium">Medium</MenuItem>
                      <MenuItem value="High">High</MenuItem>
                    </Select>
                    {validationState &&
                      validationState?.complexityErrorFlag && (
                        <FormHelperText error>
                          {validationState.complexityErrorFlag
                            ? validationState.complexityErrorMessage
                            : ''}
                        </FormHelperText>
                      )}
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.formField}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isDefaultComplexity}
                          onChange={onChangeDefaultComplexity}
                          name="default-complexity"
                          color="primary"
                        />
                      }
                      label="Default complexity for this kind of asset"
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.formField}>
                  <TextField
                    id="man-hours-tf"
                    data-testid="man-hours-tf"
                    label="Man Hours"
                    fullWidth
                    variant="outlined"
                    size="small"
                    required
                    value={manHours}
                    error={validationState.manHoursErrorFlag}
                    helperText={
                      validationState.manHoursErrorFlag
                        ? validationState.manHoursErrorMessage
                        : ''
                    }
                    onChange={event => {
                      const value = limitToIntNumbers(event);
                      setManHours(value);
                      setValidationState({
                        ...validationState,
                        manHoursErrorFlag: false,
                        manHoursErrorMessage: '',
                      });
                    }}
                  ></TextField>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.formField}>
                  <TextField
                    id="blended-rate-tf"
                    data-testid="blended-rate-tf"
                    label="Blended Rate"
                    fullWidth
                    variant="outlined"
                    size="small"
                    required
                    value={blendedRate}
                    error={validationState.blendedRateErrorFlag}
                    helperText={
                      validationState.blendedRateErrorFlag
                        ? validationState.blendedRateErrorMessage
                        : ''
                    }
                    onChange={event => {
                      const value = limitToIntNumbers(event);
                      setBlendedRate(value);
                      setValidationState({
                        ...validationState,
                        blendedRateErrorFlag: false,
                        blendedRateErrorMessage: '',
                      });
                    }}
                  ></TextField>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.formField}>
                  <TextField
                    id="coefficient-tf"
                    data-testid="coefficient-tf"
                    label="Coefficient"
                    fullWidth
                    variant="outlined"
                    size="small"
                    required
                    value={coefficient}
                    error={validationState.coefficientErrorFlag}
                    helperText={
                      validationState.coefficientErrorFlag
                        ? validationState.coefficientErrorMessage
                        : ''
                    }
                    onChange={event => {
                      const value = limitToNumbers(event);
                      setCoefficient(value);
                      setValidationState({
                        ...validationState,
                        coefficientErrorFlag: false,
                        coefficientErrorMessage: '',
                      });
                    }}
                  ></TextField>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.formField}>
                  <TextField
                    required
                    id="coefficient2"
                    data-testid="coefficient2"
                    label="SDC"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={coefficient2}
                    error={validationState.coefficient2ErrorFlag}
                    helperText={
                      validationState.coefficient2ErrorFlag
                        ? validationState.coefficient2ErrorMessage
                        : ''
                    }
                    onChange={event => {
                      const value = limitToNumbers(event);
                      setCoefficient2(value);
                      setValidationState({
                        ...validationState,
                        coefficient2ErrorFlag: false,
                        coefficient2ErrorMessage: '',
                      });
                    }}
                  ></TextField>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.formField}>
                  <TextField
                    id="formula-tf"
                    data-testid="formula-tf"
                    label="Cost Avoidance Calculation Formula"
                    fullWidth
                    required
                    variant="outlined"
                    size="small"
                    multiline
                    value={formula}
                    onChange={event => {
                      const value = limitText(event, 1500);
                      setFormula(value);
                      setValidationState({
                        ...validationState,
                        formulaErrorFlag: false,
                        formulaErrorMessage: '',
                      });
                    }}
                    error={validationState.formulaErrorFlag}
                    helperText={
                      validationState.formulaErrorFlag
                        ? validationState.formulaErrorMessage
                        : ''
                    }
                  ></TextField>
                </div>
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <div className={classes.formField}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isActive}
                          onChange={onChangeIsActive}
                          name="active"
                          color="primary"
                        />
                      }
                      label="Active"
                    />
                  </FormControl>
                </div>
              </Grid> */}
              <Grid item xs={12}>
                <section className={classes.actionArea}>
                  <Button
                    id="add-vars-cancel-btn"
                    data-testid="add-vars-cancel-btn"
                    variant="outlined"
                    className={classes.cancelBtn}
                    onClick={onClickCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    id="add-vars-save-btn"
                    data-testid="add-vars-save-btn"
                    variant="contained"
                    color="primary"
                    className={classes.saveBtn}
                    onClick={onClickSave}
                  >
                    Save
                  </Button>
                </section>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Content>
    </div>
  );
};

export default AddCAVariable;
