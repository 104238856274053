import React from 'react';
import {
  Box,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { IUtilityTool } from '../types';
import { SidebarToolNav } from './sidebarTool';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sidebarGroupTitle: {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '24px',
    },
  }),
);
export interface IUtilitySidebarGroupProps {
  title: string;
  tools: IUtilityTool[];
}

export const UtilitySidebarGroup = ({
  title,
  tools,
}: IUtilitySidebarGroupProps) => {
  const classes = useStyles();
  return (
    <Box sx={{ mb: 2 }}>
      <Box>
        <Typography className={classes.sidebarGroupTitle}>{title}</Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        {tools !== undefined &&
          Array.isArray(tools) &&
          tools.length > 0 &&
          tools.map((tool: IUtilityTool) => (
            <SidebarToolNav key={tool?.id} tool={tool} />
          ))}
      </Box>
    </Box>
  );
};
