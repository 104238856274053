import React from 'react';
import { Breadcrumbs, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

export const useStyles = makeStyles(theme => ({
  breadCrumbText: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  text: {
    fontStyle: 'italic',
  },
}));

export interface IBreadcrumbItemProps {
  text?: string;
  type?: string;
  link?: string;
  onClick?: any;
}

const BreadcrumbItem = (props: IBreadcrumbItemProps) => {
  const classes = useStyles();
  const { type, link, text, onClick } = props;
  return (
    <>
      {type === 'text' && (
        <Typography className={classes.text} onClick={onClick}>
          {text}
        </Typography>
      )}
      {type === 'link' && (
        <Link to={link ?? ''} onClick={onClick}>
          <Typography className={classes.breadCrumbText}>{text}</Typography>
        </Link>
      )}
    </>
  );
};

export interface IDevxBreadCrumbProps {
  routes: IBreadcrumbItemProps[];
}

export const DevxBreadCrumb = (props: IDevxBreadCrumbProps) => {
  const { routes } = props;

  return (
    <Breadcrumbs aria-label="breadcrumb" style={{ color: 'white' }}>
      {routes.map((item: any, index: any) => (
        <BreadcrumbItem
          key={item.text + index}
          type={item.type}
          index={index}
          onClick={item?.onClick}
          link={item.link}
          text={item.text}
        />
      ))}
    </Breadcrumbs>
  );
};
