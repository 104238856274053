import React, { useState, useEffect, useContext } from 'react';
import { SubscribeTable } from './subscribeCode/subscribeTable';
import { Grid } from '@material-ui/core';
import { Progress } from '@backstage/core-components';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import BackButton from '../../utils/backButton';
import { AuthContext } from '../../../providers/AuthProvider';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { COMPONENT_TYPE, COMPONENT, API } from '../../utils/constant';
import { subscriberApiRef } from '../../../apis/subscriberApi';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';

const useCatalogEntity = () => {
  const catalogApiForAssets = useApi(catalogApiRef);
  const {
    value: entity,
    error,
    loading,
  } = useAsync(() => catalogApiForAssets.getEntities());

  return { entity, error, loading };
};

export const Subscribe = (props: any) => {
  const entityData = useCatalogEntity();
  const { entity } = entityData;
  const [allAssetsData, setAllAssetsData] = useState<any>([]);
  const [userHaveAssets, setUserHaveAssets] = useState<any>(false);
  const [isError, setError] = useState<any>(false);
  const [isLoading, setLoding] = useState<any>(true);
  const [isRefresh, setRefresh] = useState<any>(true);

  const { userInfo, profEmail, isAdmin } = useContext(AuthContext);

  const subscriberApi = useApi(subscriberApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const userEmail = profEmail.toLowerCase();

  const getSubscribers = () => {
    subscriberApi
      .getSubscribers()
      .then((data: any) => {
        setAllAssetsData(data || []);
        setLoding(false);
      })
      .catch((error: any) => {
        setError(true);
        setLoding(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  const getSubscribersForUser = () => {
    subscriberApi
      .getSubscribersByEmail(userEmail.split('@')[0]?.toLowerCase())
      .then((data: any) => {
        setAllAssetsData(data || []);
        setLoding(false);
      })
      .catch((error: any) => {
        setError(true);
        setLoding(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  useEffect(() => {
    if (entity && userEmail) {
      if (isAdmin) {
        getSubscribers();
      } else {
        getSubscribersForUser();
      }
    }

    const entityItems =
      entity &&
      entity?.items.filter(
        (item: any) =>
          (item?.kind?.toLowerCase() === COMPONENT &&
            COMPONENT_TYPE.includes(item.spec?.type?.toLowerCase()) &&
            item?.spec?.owner?.toLowerCase() ===
              userEmail.split('@')[0]?.toLowerCase()) ||
          item?.spec?.owner?.toLowerCase() ===
            `user:${userEmail.split('@')[0]?.toLowerCase()}` ||
          (item?.kind?.toLowerCase() === API &&
            item?.spec?.owner?.toLowerCase() ===
              userEmail.split('@')[0]?.toLowerCase()) ||
          item?.spec?.owner?.toLowerCase() ===
            `user:${userEmail.split('@')[0]?.toLowerCase()}`,
      );

    setUserHaveAssets(entityItems && entityItems.length ? true : false);

    localStorage.setItem('isReview', 'false');
  }, [userInfo, entity, userEmail, isRefresh]);

  // const [unusedState, setUnusedState] = useState();
  // useCallback(() => setUnusedState({}), [isRefresh])

  if (isLoading) {
    return (
      <div>
        <Progress />
      </div>
    );
  }

  return (
    <div className="codePage noPadding">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <BackButton />
        <HelpComponent helpUrl={HELP_URL.SUBSCRIBED} />
      </Grid>

      <SubscribeTable
        entities={allAssetsData}
        error={isError}
        loading={isLoading}
        userHaveAssets={userHaveAssets}
        isAdmin={isAdmin}
        userEmail={userEmail}
        setRefresh={setRefresh}
        isRefresh={isRefresh}
        setAllAssetsData={setAllAssetsData}
        setLoding={setLoding}
      />
    </div>
  );
};
