import React from 'react';

const AnswerQuestion = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_72450_60618)">
        <path
          d="M23.75 1.875H6.25C4.8625 1.875 3.75 3 3.75 4.375V21.875C3.75 23.25 4.8625 24.375 6.25 24.375H11.25L15 28.125L18.75 24.375H23.75C25.125 24.375 26.25 23.25 26.25 21.875V4.375C26.25 3 25.125 1.875 23.75 1.875ZM23.75 21.875H17.7125L15 24.5875L12.2875 21.875H6.25V4.375H23.75V21.875Z"
          fill="#17E88F"
        />
        <path
          d="M9.46872 12.032L10.0607 13.648H8.87672L9.46872 12.032ZM10.9247 16H12.4447L10.2607 10.256H8.72471L6.53271 16H8.02071L8.42871 14.888H10.5167L10.9247 16Z"
          fill="#17E88F"
        />
        <path
          d="M18.0196 10.256H16.5716V13.416L14.3476 10.256H12.9476V16H14.3956V12.64L16.8036 16H18.0196V10.256Z"
          fill="#17E88F"
        />
        <path
          d="M18.5573 14.296C18.7653 15.424 19.7013 16.096 21.0773 16.096C22.3333 16.096 23.3253 15.416 23.3253 14.24C23.3253 13.36 22.7813 12.808 21.7973 12.584L20.6773 12.32C20.3733 12.248 20.1973 12.112 20.1973 11.864C20.1973 11.552 20.4853 11.36 20.9093 11.36C21.4053 11.36 21.6773 11.6 21.7973 11.92H23.2693C23.1093 10.92 22.3173 10.16 20.9013 10.16C19.6373 10.16 18.6933 10.896 18.6933 12.024C18.6933 13 19.3333 13.44 20.1813 13.616L21.2773 13.856C21.6453 13.944 21.8293 14.064 21.8293 14.336C21.8293 14.688 21.5093 14.888 21.0533 14.888C20.5813 14.888 20.2453 14.696 20.1173 14.296H18.5573Z"
          fill="#17E88F"
        />
      </g>
      <defs>
        <clipPath id="clip0_72450_60618">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AnswerQuestion;
