import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    documentsPage: {
      padding: `0px ${theme.spacing(3)}px`,
    },
    pageHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
    },
    tagsFilter: {
      display: 'flex',
      justifyContent: 'end',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        justifyContent: 'start',
      },
    },
    documentCard: {
      minHeight: `${theme.spacing(28)}px`,
      minWidth: `${theme.spacing(42.5)}px`,
    },
    cardHeader: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    documentSummary: {
      wordWrap: 'break-word',
    },
    cardActionButton: {
      justifyContent: 'left',
      margin: `${theme.spacing(1)}px`,
    },
    openUrlIcon: {
      marginRight: `${theme.spacing(0.5)}px`,
      color: theme.palette.primary.main,
    },
    openUrlButton: {
      border: `${theme.spacing(0.15)}px solid ${theme.palette.primary.main}`,
      textTransform: 'none',
    },
    buttonText: {
      fontWeight: 600,
      letterSpacing: `${theme.spacing(0.025)}px`,
      color: theme.palette.primary.main,
    },
    filterChipButton: {
      //   margin: 'auto 0',
    },
    tagSelected: {
      backgroundColor: theme.palette.primary.main,
      '&:hover, &:focus': {
        backgroundColor: theme.palette.primary.main,
      },
      color: theme.palette.text.contrast,
    },
  }),
);
