import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@material-ui/core';

import { HelpComponent } from '../../../utils/helpComponent';
import HELP_URL from '../../../utils/helpLinkConstant';

import BackButton from '../../../utils/backButton';
import { ManageCategoryCard } from './manageCategoryCard';
import { useCustomStyles } from './CardStyles';
import { Header, Page } from '@backstage/core-components';
import BreadcrumbsNav from '../../../common/BreadcrumbsNav/BreadcrumbsNav';
import AddEditCategory from './AddEditCategory';
import { AdminApi, adminApiRef } from '../../../../apis/adminApi';
import { useApi } from '@backstage/core-plugin-api';
import DeleteCategoryConfirm from './deleteCategoryConfirm';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     manageCategoriesContainer: {
//       padding: '24px 24px 120px 24px',
//       gridArea: 'pageContent',
//     },
//   }),
// );

export const ManageCategories = () => {
  const classes = useCustomStyles();
  const initialPopupState = {
    open: false,
    popupProps: {
      values: {},
      onSubmit: () => {},
      onClose: () => {},
    },
  };
  const [open, setOpen] = useState(initialPopupState);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [selectedItemToDelete, setSelectedItemToDelete] = useState<any>();
  const [categories, setCategories] = useState<any[]>([]);

  const adminApi: AdminApi = useApi(adminApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  /* Add Tag Modal Popup */
  const handleOpen = (onSubmit: any, onClose: any, values?: any) => {
    setOpen({
      open: true,
      popupProps: {
        onSubmit,
        onClose,
        values,
      },
    });
  };

  const closeIconClicked = () => {
    handleClose();
  };
  const handleClose = () => {
    setOpen(initialPopupState);
  };

  const onAdd = async (data: any) => {
    const trimmedData = { ...data, name: data.name.trim() };
    try {
      const response = await adminApi.addCategory(trimmedData);
      if (response) {
        notificationApi.sendNotification({
          message: 'Successfully added category',
          disapperAfterMs: 2500,
          severity: 'success',
          callback: () => {
            setCategories([...categories, data]);
            handleClose();
          },
        });
      }
    } catch (error) {
      notificationApi.sendNotification({
        message: error?.response?.data?.message,
        disapperAfterMs: 2500,
        severity: 'error',
      });
    }
  };

  const onEdit = async (data: any) => {
    const trimmedData = { ...data, name: data.name.trim() };
    try {
      const response = await adminApi.updateCategory(trimmedData);
      if (response) {
        notificationApi.sendNotification({
          message: 'Successfully edited category',
          disapperAfterMs: 2500,
          severity: 'success',
          callback: () => {
            const p = categories.findIndex((item: any) => item.id === data.id);
            const a = categories;
            a[p] = trimmedData;
            setCategories(a);
            handleClose();
          },
        });
      }
    } catch {
      notificationApi.sendNotification({
        message: 'Failed to edit category',
        disapperAfterMs: 2500,
        severity: 'error',
      });
    }
  };

  const handleEditClicked = (data: any) => {
    handleOpen(onEdit, handleClose, data);
  };

  const handleDelete = (item: any) => {
    setShowConfirmDelete(true);
    setSelectedItemToDelete(item);
  };

  const onCloseConfirmPrompt = () => {
    setShowConfirmDelete(false);
    setSelectedItemToDelete(undefined);
  };

  // const onConfirmDelete = async () => {
  //   try {
  //     const response = await adminApi.deleteCategory(selectedItemToDelete);
  //     if (response) {
  //       notificationApi.sendNotification({
  //         message: 'Successfully deleted category',
  //         disapperAfterMs: 2500,
  //         severity: 'success',
  //         callback: () => {
  //           console.log(selectedItemToDelete)
  //           const a = categories.filter((c) => c.id !== selectedItemToDelete.id)
  //           setCategories(a);
  //           setShowConfirmDelete(false);
  //         },
  //       });
  //     }
  //   } catch(error: any) {
  //     let errorMessage = 'Failed to delete category';
  //     if (error?.response?.data?.message) {
  //       errorMessage += `: ${error?.response?.data?.message}`
  //     }
  //     notificationApi.sendNotification({
  //       message: errorMessage,
  //       disapperAfterMs: 2500,
  //       severity: 'error',
  //       callback: () => {
  //         setShowConfirmDelete(false);
  //       },
  //     })
  //   }
  // };

  const fetchData = async () => {
    let responseCategory: any;
    try {
      responseCategory = await adminApi.getCategories();
      if (responseCategory) {
        setCategories(responseCategory);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onConfirmDelete = async () => {
    try {
      const response = await adminApi.deleteCategory(selectedItemToDelete);
      if (response) {
        notificationApi.sendNotification({
          message: 'Successfully deleted category',
          disapperAfterMs: 2500,
          severity: 'success',
          callback: () => {
            console.log(selectedItemToDelete);
            const a = categories.filter(c => c.id !== selectedItemToDelete.id);
            setCategories(a);
            setShowConfirmDelete(false);
          },
        });
      }
    } catch (error) {
      let errorMessage = 'Failed to delete category';
      if (error?.response?.data?.message) {
        errorMessage += `: ${error?.response?.data?.message}`;
      }
      notificationApi.sendNotification({
        message: errorMessage,
        disapperAfterMs: 2500,
        severity: 'error',
        callback: () => {
          setShowConfirmDelete(false);
        },
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Grid item md={12} xs={12}>
        <BackButton />
        <HelpComponent helpUrl={HELP_URL.ADMIN_DATAINSIGHT_MANAGE_CATEGORY} />
      </Grid>

      <AddEditCategory
        open={open.open}
        closeIconClicked={closeIconClicked}
        isEditClick={open.popupProps.values}
        {...open.popupProps}
      />

      <Grid container>
        <Grid item md={12} xs={12} className={classes.addCategoryContainer}>
          <Button
            onClick={() => {
              handleOpen(onAdd, handleClose);
            }}
            color="primary"
            variant="contained"
            className={classes.addBtn}
          >
            Add Category
          </Button>
        </Grid>
        {categories?.map((item: any) => (
          <Grid item xs={12} md={12}>
            <ManageCategoryCard
              categoryData={item}
              onClickDeleteButton={() => handleDelete(item)}
              onEditClick={() => {
                handleEditClicked(item);
              }}
            />
          </Grid>
        ))}
      </Grid>
      {showConfirmDelete && (
        <DeleteCategoryConfirm
          isOpen={showConfirmDelete}
          onClose={onCloseConfirmPrompt}
          onConfirm={onConfirmDelete}
        />
      )}
    </>
  );
};
