import React from 'react';
import {
  Grid,
  Button,
  Theme,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import { Routes, Route, Outlet, useNavigate } from 'react-router-dom';
import ManageCAVariables from './variables/manageCAVariables';
import ManageAssetComplexity from './complexity/manageAssetComplexity';
import EditCAVariables from './variables/editCAVariables';
import EditAssetComplexity from './complexity/editAssetComplexity';
import BackButton from '../../utils/backButton';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import AddCAVariable from './variables/addCAVariable';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    theme: {
      backgroundColor: theme.palette.background.paper,
    },
  }),
);
export const IndexPage = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <BackButton />
        <HelpComponent helpUrl={HELP_URL.COST_AVOIDANCE_MAIN} />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <div className={`infocard-admin ${classes.theme}`}>
          <h1>Variables</h1>
          <p>Modify variables for cost avoidance formula</p>

          <div className="button-see-more">
            <Button
              id="adm-edit-vars-card-btn"
              data-testid="adm-edit-vars-card-btn"
              color="primary"
              variant="contained"
              onClick={() => {
                navigate('variables');
              }}
              endIcon={<EditOutlinedIcon />}
            >
              Edit
            </Button>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <div className={`infocard-admin ${classes.theme}`}>
          <h1>Manage Asset's complexity</h1>
          <p>Modify asset's complexity</p>

          <div className="button-see-more">
            <Button
              id="adm-update-complexity-card-btn"
              data-testid="adm-update-complexity-card-btn"
              color="primary"
              variant="contained"
              onClick={() => {
                navigate('complexity');
              }}
              endIcon={<NavigateNextOutlinedIcon />}
            >
              Go
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

const CostAvoidance = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<IndexPage />}></Route>
        <Route
          path="variables"
          caseSensitive
          element={<ManageCAVariables />}
        ></Route>
        <Route path="variables/edit/:id" element={<EditCAVariables />}></Route>
        <Route path="variables/add" element={<AddCAVariable />}></Route>
        <Route path="complexity" element={<ManageAssetComplexity />}></Route>
        <Route
          path="complexity/edit/:id"
          element={<EditAssetComplexity />}
        ></Route>
      </Routes>
      <Outlet />
    </div>
  );
};
export default CostAvoidance;
