import React from 'react';
import { useStarredEntity } from '@backstage/plugin-catalog-react';
import {
  Box,
  Link,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { Link as RouterLink } from 'react-router-dom';
import { IUtilityTool } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    utilityCard: {
      backgroundColor: theme.palette.background.paper,
      cursor: 'pointer',
      borderRadius: '8px',
      display: 'flex',
      height: '80px',
      width: '256px',
      alignItems: 'center',
      boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.14)',
    },
    label: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '20px',
    },
    icon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);
export interface IUtilityToolCardProps {
  tool: IUtilityTool;
}

export const UtilityToolCard = ({ tool }: IUtilityToolCardProps) => {
  const classes = useStyles();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { toggleStarredEntity, isStarredEntity } = useStarredEntity(tool?.id);

  const onClickStarIcon = (event: any) => {
    event?.stopPropagation();
    event?.preventDefault();
    toggleStarredEntity();
  };

  return (
    <Box
      sx={{
        p: 2,
        mr: 2,
        mb: 2,
        // @ts-expect-error
        flexGrow: isMobile ? 1 : 0,
      }}
      className={classes.utilityCard}
    >
      <Box
        sx={{
          p: 2,
          mr: 3,
        }}
        className={classes.icon}
      >
        {tool?.icon}
      </Box>
      <Box sx={{ flex: 1, mr: 2 }}>
        <Typography className={classes.label}>
          <Link component={RouterLink} to={`details/${tool.path}`}>
            {tool?.label}
          </Link>
        </Typography>
      </Box>
      <Box onClickCapture={onClickStarIcon}>
        <Rating max={1} value={isStarredEntity ? 1 : 0} />
      </Box>
    </Box>
  );
};
