import { createApiRef } from '@backstage/core-plugin-api';
import { AxiosInstance, AxiosResponse } from 'axios';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';

export interface DashboardApi {
  getDataForHighestRated: (dashboardName: string) => Promise<AxiosResponse>;
  getRecentlyPublishedDocs: () => Promise<AxiosResponse>;
  getDataForRecentlyPublished: (
    dashboardName: string,
  ) => Promise<AxiosResponse>;
  getTopContributors: () => Promise<AxiosResponse>;
}

export const dashboardApiRef = createApiRef<DashboardApi>({
  id: 'cbre.devx.api.dashboard',
});

export class DashboardApiImpl implements DashboardApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  async getDataForHighestRated(dashboardName: string): Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;

    return instance.get(`service/discovery/data`, {
      params: {
        dashboardName,
      },
    });
  }

  async getRecentlyPublishedDocs(): Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;

    return instance.get(`service/discovery/data`, {
      params: {
        dashboardName: 'devxrecentlypublisheddocs',
      },
    });
  }

  async getDataForRecentlyPublished(
    dashboardName: string,
  ): Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;

    return instance.get(`service/discovery/data`, {
      params: {
        dashboardName,
      },
    });
  }

  async getTopContributors(): Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;

    return instance.get(`service/discovery/data`, {
      params: {
        dashboardName: 'devxtopcontributors',
      },
    });
  }
}
