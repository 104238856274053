import fetch from 'cross-fetch';
import {
  FindingSummary,
  Metrics,
  SonarQubeApi,
} from '@backstage/plugin-sonarqube-react';
import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';

import {
  MetricKey,
} from '@backstage/plugin-sonarqube-react';

export interface InstanceUrlWrapper {
  instanceUrl: string;
}

export interface FindingsWrapper {
  analysisDate: string;
  measures: Measure[];
  componentBugs:any;
}

export interface Measure {
  metric: MetricKey;
  value: string;
}

/** @public */
export class ExtendedSonarQubeClient implements SonarQubeApi {
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  private async callApi<T>(
    path: string,
    query: { [key in string]: any },
  ): Promise<T | undefined> {
    const { token: idToken } = await this.identityApi.getCredentials();

    const apiUrl = `${await this.discoveryApi.getBaseUrl('sonarqube')}`;
    const response = await fetch(
      `${apiUrl}/${path}?${new URLSearchParams(query).toString()}`,
      {
        headers: {
          'Content-Type': 'application/json',
          ...(idToken && { Authorization: `Bearer ${idToken}` }),
        },
      },
    );
    if (response.status === 200) {
      return (await response.json()) as T;
    }
    return undefined;
  }

  async getFindingSummary({
    componentKey,
    projectInstance,
    branch,
  }: {
    componentKey?: string;
    projectInstance?: string;
    branch?: string;
  } = {}): Promise<FindingSummary | undefined> {
    if (!componentKey) {
      return undefined;
    }

    const instanceKey = projectInstance || '';

    const metrics: Metrics = {
      alert_status: undefined,
      bugs: undefined,
      reliability_rating: undefined,
      vulnerabilities: undefined,
      security_rating: undefined,
      security_hotspots_reviewed: undefined,
      security_review_rating: undefined,
      code_smells: undefined,
      sqale_rating: undefined,
      coverage: undefined,
      duplicated_lines_density: undefined,
    };

    const baseUrlWrapper = await this.callApi<InstanceUrlWrapper>(
      'instanceUrl',
      {
        instanceKey,
      },
    );
    let baseUrl = baseUrlWrapper?.instanceUrl;
    if (!baseUrl) {
      return undefined;
    }
    // ensure trailing slash for later on
    if (!baseUrl.endsWith('/')) {
      baseUrl += '/';
    }

    const findings = await this.callApi<FindingsWrapper>('findings-new', {
      componentKey,
      instanceKey,
      branch: branch || '',
    });
    if (!findings) {
      return undefined;
    }

    findings.measures.forEach(m => {
      metrics[m.metric] = m.value;
    });

    return {
      lastAnalysis: findings.analysisDate,
      metrics,
      componentBugs:findings?.componentBugs,
      projectUrl: `${baseUrl}dashboard?branch=${branch}&id=${encodeURIComponent(
        componentKey,
      )}`,
      getIssuesUrl: identifier =>
        `${baseUrl}project/issues?branch=${branch}&id=${encodeURIComponent(
          componentKey,
        )}&types=${identifier.toLocaleUpperCase('en-US')}&resolved=false`,
      getComponentMeasuresUrl: identifier =>
        `${baseUrl}component_measures?branch=${branch}&id=${encodeURIComponent(
          componentKey,
        )}&metric=${identifier.toLocaleLowerCase(
          'en-US',
        )}&resolved=false&view=list`,
      getSecurityHotspotsUrl: () =>
        `${baseUrl}${
          baseUrl === 'https://sonarcloud.io/' ? 'project/' : ''
        }security_hotspots?id=${encodeURIComponent(componentKey)}`,
    };
  }
}
