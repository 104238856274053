import React, { useState, useContext, useEffect } from 'react';
import {
  Button,
  Grid,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import {
  TableColumn,
  Table,
  WarningPanel,
  CodeSnippet,
  TableProps,
} from '@backstage/core-components';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import BackButton from '../../utils/backButton';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import * as columnFactories from './managePromotionsColumns';
import DeleteIcon from '@material-ui/icons/Delete';
import { PromotionContext } from '../../../providers/PromotionDataProvider';
import { AuthContext } from '../../../providers/AuthProvider';
import ListIcon from '@material-ui/icons/List';
import TableChartIcon from '@material-ui/icons/TableChart';
import { ListView } from './ManageListView';
import DeleteModal from '../../Promotions/DeleteModal';
import { useApi } from '@backstage/core-plugin-api';
import { promotionApiRef } from '../../../apis/promotionApi';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toggleGrid: {
      marginBottom: '10px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    themeColor: {
      '& tr': {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.background.default + '!important',
        '& td ': {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: theme.palette.background.default + '!important',
          color: theme.palette.primary.main,
          '& a': {
            color: theme.palette.primary.main,
          },
        },
        '& th': {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: theme.palette.background.default + '!important',
        },
      },
      '& div::-webkit-scrollbar-thumb': {
        border: `4px solid ${theme.palette.text.tertiary}`,
      },
    },
  }),
);
const columns: TableColumn<columnFactories.EntityRow>[] = [
  columnFactories.createTitleColumn(),
  columnFactories.createDescriptionColumn(),
  columnFactories.createTypeColumn(),
  columnFactories.createAssestsUrlColumn(),
  columnFactories.createRelatedUrlColumn(),
  columnFactories.createIsActiveColumn(),
  columnFactories.createCreationDateColumn(),
];

const defaultActions: TableProps<columnFactories.EntityRow>['actions'] = [
  entity => {
    return {
      icon: () => (
        <Link
          to={`/admin/manage-promotions/update-promotion/${entity.title}/${entity.promotionId}`}
        >
          <EditIcon />
        </Link>
      ),
      tooltip: 'Edit',
      onClick: () => {
        console.log(entity);
      },
    };
  },
  entity => {
    const promoctx = useContext(PromotionContext);
    return {
      icon: () => <DeleteIcon />,
      tooltip: 'Delete',
      onClick: () => {
        console.log(entity);
        promoctx.setId(entity.promotionId);
        promoctx.setAssetURL(entity.assetURL);
        promoctx.setDeleteModal(true);
      },
    };
  },
];

export const ManagePromotion = () => {
  const [loading, setLoading] = useState(false);
  const [promotionData, setPromotionData] = useState<any[]>([]);
  const [handleView, setHandleView] = useState('Table View');
  const promocontext = useContext(PromotionContext);
  const { isAdmin } = useContext(AuthContext);

  const promotionApi = useApi(promotionApiRef);

  useEffect(() => {
    promocontext.setToggleHelpButton(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    promotionApi
      .getPromotionData()
      .then(data => {
        setLoading(false);
        if (Array.isArray(data)) {
          setPromotionData(data);
        } else {
          setPromotionData([]);
        }
      })
      .catch(error => {
        setLoading(false);
        setPromotionData([]);
      });
  }, []);

  const classes = useStyles();

  const handleViewEvent = (e: any, view: any) => {
    setHandleView(view);
  };

  if (!isAdmin) {
    const body = 'You dont have the right access for the page !';
    console.log(
      `%c${body}`, // everything after the %c is styled
      `color: red; font-weight: bold; font-size: 1rem;`,
    );
    return (
      <div>
        <WarningPanel severity="error" title="Could not fetch Promotion list">
          <CodeSnippet language="text" text="Error! You dont have the access" />
        </WarningPanel>
      </div>
    );
  }
  return (
    <Grid container direction="row" className="admin-reviewer-page">
      <Grid item md={12} xs={12}>
        <BackButton />
        <HelpComponent helpUrl={HELP_URL.MANAGE_PROMOTIONS} />
      </Grid>

      <Grid item xs={12}>
        <div className="admin-page">
          <Grid className={classes.toggleGrid} item md={12} xs={12}>
            <Link to="/admin/manage-promotions/add-promotion">
              <Button color="primary" variant="contained">
                Add a new promotion
              </Button>
            </Link>
            <ToggleButtonGroup
              value={handleView}
              exclusive
              onChange={handleViewEvent}
              aria-label="View Change"
            >
              <ToggleButton value="Table View" aria-label="table view">
                <TableChartIcon />
              </ToggleButton>
              <ToggleButton value="List View" aria-label="list view">
                <ListIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>

          <div
            className={`mystuff-table managePromotionsListView ${classes.themeColor}`}
          >
            {handleView === 'Table View' ? (
              <Table
                isLoading={loading}
                columns={columns}
                options={{
                  paging: true,
                  pageSize: 20,
                  search: true,
                  actionsColumnIndex: -1,
                  loadingType: 'linear',
                  showEmptyDataSourceMessage: !loading,
                  padding: 'dense',
                  pageSizeOptions: [5],
                }}
                data={promotionData || []}
                actions={defaultActions}
                title={`${'Promotion'} (${
                  promotionData && promotionData.length
                })`}
              />
            ) : (
              <ListView
                data={promotionData}
                setPromotionData={setPromotionData}
              />
            )}
          </div>
        </div>
      </Grid>
      <DeleteModal
        promotionData={promotionData}
        setPromotionData={setPromotionData}
        id={promocontext.id}
        assestURL={promocontext.assestURL}
        deleteModalOpen={promocontext.deleteModalOpen}
        setDeleteModal={promocontext.setDeleteModal}
      />
    </Grid>
  );
};
