import { createApiRef } from '@backstage/core-plugin-api';
import { AxiosInstance } from 'axios';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';

export interface OpenAIApi {
  cognitiveChat: (data: any) => Promise<any>;
  ask: (data: any) => Promise<any>;
  tokenUsage: () => Promise<any>;
}

export const openaiApiRef = createApiRef<OpenAIApi>({
  id: 'cbre.devx.api.openai',
});

export class OpenAIApiImpl implements OpenAIApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  async cognitiveChat(
    data: any
  ): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .post('service/openai/cognitivesearch', data)
      .then(res => res.data)
  }

  async ask(
    data: any
  ): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .post('service/openai', data)
      .then(res => res.data)
  }


  async tokenUsage(): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get('service/openai/tokensusage')
      .then(res => res.data)
  }
}
