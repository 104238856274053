import { useEffect, useState } from 'react';

export const useOverFlowTags = (contentElement: HTMLDivElement | null) => {
  const [overFlowTagsCount, setOverFlowTagsCount] = useState<number>(0);

  const handleResize = (entries: ResizeObserverEntry[]) => {
    const element = entries[0].target as HTMLElement;
    // Handle resize logic here
    const tagsContainerBottom = element.getBoundingClientRect().bottom;

    const __overflowTagsCount = Array.from(element.children).reduce(
      (accumulator: any, childElement: any) => {
        // Handle child element logic here
        if (childElement.getBoundingClientRect().bottom > tagsContainerBottom)
          return accumulator + 1;
        return accumulator;
      },
      0,
    );
    setOverFlowTagsCount(__overflowTagsCount);
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);
    if (contentElement) {
      resizeObserver.observe(contentElement);
    }

    return () => {
      resizeObserver.disconnect();
      if (contentElement) {
        resizeObserver.unobserve(contentElement);
      }
    };
  }, [contentElement]);

  return [overFlowTagsCount];
};
