/* eslint-disable react-hooks/exhaustive-deps */
import { Content, PageWithHeader } from '@backstage/core-components';
import React, { useEffect, useState } from 'react';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';
import { Button, Grid, Tooltip, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import BackButton from '../utils/backButton';
import { HelpComponent } from '../utils/helpComponent';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import HELP_URL from '../utils/helpLinkConstant';
import InputCard from './components/InputCard';
import { useBuildPrompt } from './hooks/useBuildPrompt';
import InstructionsBox from './components/InstructionsBox';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';
import { useApi } from '@backstage/core-plugin-api';

interface PromptEnhancerPageProps {
  // Add any props you need here
}

const PromptEnhancerPage: React.FC<PromptEnhancerPageProps> = () => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const isV2 = window.location.href.includes('v2');
  console.log(isV2)

  const classes = useStyles();
  const header = {
    title: 'Prompt Store',
    subTitleRoutes: [
      {
        type: 'link',
        text: 'Home',
        link: '/',
      },
      {
        type: 'link',
        text: 'Prompt Store',
        link: '/prompt-store',
      },
      {
        type: 'text',
        text: 'Prompt Enhancer',
      },
    ],
  };

  const [inputPrompt, setInputPrompt] = useState('');
  const [showInstruction, setShowInstruction] = useState(true);

  const {
    generatePrompt,
    generatePromptV2,
    enhancedPrompt,
    setEnhancedPrompt,
    regenerationToken,
    setRegenerationToken,
    promptInprogress,
    promptLoaded,
    setPromptLoaded,
  } = useBuildPrompt();

  const handleGenerateClick = () => {
    setShowInstruction(false);
    if (isV2) {
      generatePromptV2({
        prompt: inputPrompt
      })
    } else {
      generatePrompt({
        prompt: inputPrompt,
      });
    }
  };

  const handleReGenerateClick = () => {
    setEnhancedPrompt('');
    setPromptLoaded(false);
    if (isV2) {
      generatePromptV2({
        prompt: inputPrompt,
        regenerate: true,
        regeneration_token: regenerationToken,
      });
    } else {
      generatePrompt({
        prompt: inputPrompt,
        regenerate: true,
        regeneration_token: regenerationToken,
      });
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(enhancedPrompt);
    notificationApi.sendNotification({
      message: 'Copied to clipboard!',
      disapperAfterMs: 2500,
      severity: 'success',
    });
  };

  const handleClear = () => {
    setShowInstruction(true);
    setInputPrompt('');
    setEnhancedPrompt('');
    setPromptLoaded(false);
    setRegenerationToken('');
  };

  const instructions = [
    'Give Direction - Describe the desired style in detail, or reference a relevant persona.',
    'Specify Format - Define what rules to follow, and the required structure of the response',
    'Provide Examples - Insert a diverse set of test cases where the task was done correctly',
    'Evaluate Quality - Identify errors and rate responses, testing what drives performance',
    'Divide Labor - Split tasks into multiple steps, chained together for complex goals'
  ];

  const keywords = [
    "Java -> {programming_language}",
    "Cucumber -> {testing_framework}",
    "scenario 'As a user, I want to be able to filter search results by categories to find products I am interested in more easily' -> {user_story}",
    "valid categories -> {positive_test_case}",
    "no results -> {negative_test_case_no_results}",
    "invalid categories -> {negative_test_case_invalid_input}"
  ]
  const instructionsToolTip = [
    <Grid>
      <Grid>
        <Typography>
          What is prompt enhancer?
        </Typography>
      </Grid>
      <Grid>
        A prompt enhancer helps you craft clearer instructions for AI tasks, boosting the accuracy and quality of the results. This enhancer is targeted for D&T community and will help with Software related inputs. The output will add context and templatize the prompt so that you can re-use it again as needed and share it with others . It will also call out the intent and the keywords that can be converted to placeholders.
      </Grid>
      <ol>
        {instructions.map((instruction, index) => (
          <li key={index}>{instruction}</li>
        ))}
      </ol>
      <Grid>
        <Typography>
        Here is an example:
        </Typography>
      </Grid>
      <Grid>
        Input : <br />
        Create Java BDD test cases using Cucumber for the scenario 'As a user, I want to be able to filter search results by categories to find products I am interested in more easily'. Define scenarios for filtering with valid categories, filtering with no results, and attempts to filter with invalid categories. Each scenario should have detailed Given, When, Then steps, including assertions for expected outcomes and handling of no results or errors
      </Grid>
      <Grid>
        Output:<br />
        Intent: The intent of the original input prompt is to create detailed Java BDD test cases using the Cucumber framework, specifically for a feature that allows users to filter search results by categories.
      </Grid>
      <Grid>
        Keywords and Placeholder Texts:<br />
        <ul>
          {
            keywords.map((keyword, index) => (
              <li key={keyword + index}>{keyword}</li>
            ))
          }
        </ul>
      </Grid>
      <Grid>
        Enhanced Prompt:
      </Grid>
      <Grid>
        {`Create {programming_language} BDD test cases using {testing_framework} for the {user_story}. Define scenarios for {positive_test_case}, {negative_test_case_no_results}, and attempts to {negative_test_case_invalid_input}. Each scenario should have detailed Given, When, Then steps, including assertions for expected outcomes and handling of no results or errors.`}
      </Grid>
    </Grid>,
  ];

  return (
    <PageWithHeader
      title={header.title}
      themeId="promptStore"
      pageTitleOverride="Prompt Enhancer"
      subtitle={<DevxBreadCrumb routes={header.subTitleRoutes} />}
    >
      <Content>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.flexSpacebetween}
          >
            <BackButton />
            <div className={classes.buttonSection}>
              {!showInstruction && (
                <div className={classes.toolTipDiv}>
                  <Tooltip
                    title={instructionsToolTip}
                    placement="bottom-end"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <InfoOutlinedIcon fontSize="medium" />
                  </Tooltip>
                </div>
              )}
              <Button variant="outlined" color="primary" onClick={handleClear}>
                Clear
              </Button>
              <HelpComponent helpUrl={HELP_URL.PROMPT_STORE} />
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          classes={{ root: classes.enhancerContainer }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={classes.componentContainer}
          >
            <InputCard
              inputText={inputPrompt}
              heading="Your Prompt"
              classes={{ cardHeader: classes.inputCardheader }}
              disabled={!showInstruction && promptInprogress}
              actions={
                <Button
                  size="medium"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleGenerateClick}
                  disabled={!inputPrompt || (!showInstruction && promptInprogress)}
                >
                  Enhance
                </Button>
              }
              onInputChanged={(value: string) => setInputPrompt(value)}
            />
          </Grid>

          {showInstruction ? (
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className={classes.componentContainer}
            >
              <InstructionsBox
                title="Instructions"
                instructions={instructions}
              />
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className={classes.componentContainer}
            >
              <InputCard
                loader={!promptInprogress}
                inputText={enhancedPrompt}
                heading="Enhanced Prompt"
                classes={{ cardHeader: classes.enhanceCardHeader }}
                actions={
                  <>
                    {regenerationToken !== '' ? (
                      <Button
                        size="medium"
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        onClick={handleReGenerateClick}
                        disabled={!promptLoaded}
                      >
                        Regenerate
                      </Button>
                    ) : null}
                    <Button
                      size="medium"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={handleCopyToClipboard}
                      disabled={!promptLoaded}
                    >
                      Copy to clipboard
                    </Button>
                  </>
                }
                onInputChanged={() => { }}
              />
            </Grid>
          )}
        </Grid>
      </Content>
    </PageWithHeader>
  );
};

export default PromptEnhancerPage;
