/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  createStyles,
  Grid,
  LinearProgress,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core';
import { Content } from '@backstage/core-components';
import BackButton from '../../utils/backButton';
import { useNavigate } from 'react-router-dom';
import PostQuestionCard from './PostQuestionCard';
// import SimilarQuestions from './SimilarQuestions';
import AIBlock from './AI';
import PostAnswer from './PostAnswer';
import Discussions from './Discussions';
import {
  DeleteAnswerRequest,
  DeleteQuestionRequest,
  EditAnswerRequest,
  IEAnswerItem,
  InformationExchangeApi,
  informationExchangeApiRef,
  QuestionDetailsResponse,
  UserDetailsResponse,
} from '../../../apis/informationExchange';
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { useParams } from 'react-router-dom';
import { IEContext } from '../providers/IEProvider';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import { timeSince } from '../../utils/dateUtils';
import { AuthContext } from '../../../providers/AuthProvider';
import { scrollTo } from '../../utils/Utils';
import IEDeleteModal from './IEDeleteModal';
import AIBotIcon from '../icons/aiBotIcon';
import { FrontendConfigContext } from '../../../providers/FrontendConfigProvider';
import { useGetQuestionDetails } from '../hooks/useGetQuestionDetails';

interface BotProps {
  show: boolean;
  disabled: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postDetailsCodePage: {
      [theme.breakpoints.down('sm')]: {
        padding: '0',
      },
    },
    allQuestions: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    card: {
      display: 'flex',
      alignItems: 'center',
      '& h3': {
        marginLeft: '10px',
      },
    },
    para: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      color: 'rgba(26, 26, 26, 0.6)',
      marginTop: '10px',
      cursor: 'pointer',
      marginLeft: '45px',
    },
    postQueHeading: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '24px',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.secondary,
      wordBreak: 'break-word',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        marginTop: '20px',
      },
    },
    askQueBtn: {
      textTransform: 'none',
      padding: '8px 16px',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '4px',
      float: 'right',
      margin: '0px 0px 0px 7px',
      [theme.breakpoints.down('sm')]: {
        margin: '0',
        padding: '4px 16px',
      },
    },
    editQueBtn: {
      textTransform: 'none',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '4px',
      [theme.breakpoints.down('sm')]: {
        lineHeight: 'normal',
      },
    },
    delQtnBtn: {
      textTransform: 'none',
      color: '#FF543E',
      border: ' 1px solid #FF543E',
      borderRadius: '4px',
      marginLeft: '10px',
      [theme.breakpoints.down('sm')]: {
        lineHeight: 'normal',
        marginLeft: '0',
      },
    },
    timeStamp: {
      display: 'flex',
      gap: '7px',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      color: theme.palette.text.tertiary,
      marginTop: '8px',
    },

    divider: {
      border: '1px solid rgba(0, 63, 45, 0.15)',
      width: '100%',
      height: '1px',
      margin: '40px 0px 30px 0px',
      [theme.breakpoints.down('sm')]: {
        margin: '25px 0px 20px 0px',
      },
    },
    buttons: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      flexGrow: 0,
      [theme.breakpoints.down('sm')]: {
        gap: '4px',
        marginTop: '10px',
      },
    },
    backHelpBtns: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'row',
      justifyContent: ' space-between',
      alignItems: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'stretch',
      },
    },
    aiButton: {
      backgroundColor: '#17E88F',
      color: '#012A2D',
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',

      '& div': {
        marginLeft: '5px',
        marginTop: '1px',
      },
    },
  }),
);
const QuestionDetailsPage = (props: any) => {
  const theme = useTheme<Theme>();
  const discussionRef = useRef();
  const PostEditAnswerRef = useRef();

  const classes = useStyles();
  const navigate = useNavigate();
  const navigateToAskQuestionPage = () => {
    navigate('/information-exchange/ask-question', { replace: true });
  };
  const informationExchange: InformationExchangeApi = useApi(
    informationExchangeApiRef,
  );
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const { questionId } = useParams();
  const {
    oldEditData,
    setOldEditData,
    answerRef,
    acceptedAnswer,
    showAiAnswer,
    answeredByAI,
    setAnsweredByAI,
    cachedQuestion,
  } = useContext(IEContext);

  /* State variables */
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [questionData, setQuestionData] = useState<QuestionDetailsResponse>();
  const [views, setViews] = useState(0);
  // LoggedIn user data
  const [userData, setUserData] = useState<UserDetailsResponse>();
  const [doServiceCall, setDoServiceCall] = useState<Boolean>(false);
  const [botContent, setBotContent] = useState<BotProps>({
    disabled: true,
    show: false,
  });

  /* Select Btn */
  const [selectedBtn, setSelectedBtn] = useState('newest');

  /* Page Refreshed */
  const [pageRefreshed, setPageRefreshed] = useState(false);

  /* use FrontendConfig Context */
  const { frontendConfigData } = useContext(FrontendConfigContext);

  const onRephrase = (val: string) => {
    informationExchange.editQuestion({
      id: questionData?.question.id,
      title: questionData?.question.title,
      description: val,
      tags: questionData?.question.tags
    });
    if (questionData) {
      // @ts-ignore
      setQuestionData({
        ...questionData.answers,
        question: {
          ...questionData?.question,
          description: val
        }
      })
    }
  }

  useEffect(() => {
    if (frontendConfigData?.openai?.show == 'true') {
      setBotContent({
        ...botContent,
        disabled: false,
        show: showAiAnswer && !answeredByAI,
      });
    }
  }, [frontendConfigData?.openai?.show]);

  /* Get Loggedin User Details */
  const getUserDetails = (qid: String) => {
    setIsLoading(true);
    informationExchange
      .getUserDetails(qid)
      .then((data: any) => {
        setUserData(data);
        setPageRefreshed(true);
        setIsLoading(false);
      })
      .catch((error: any) => {
        notificationApi.sendNotification({
          message: `Error occurred - ${error?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  const navigateToAllQuestionPage = () => {
    navigate(`/information-exchange`, {
      replace: true,
    });
  };

  useEffect(() => {
    setIsLoading(true);
  }, []);

  /* If Cached question is present with 0 discussions and 0 votes */
  useEffect(() => {
    if (
      cachedQuestion &&
      cachedQuestion?.votes === 0 &&
      cachedQuestion.discussions === 0
    ) {
      setQuestionData({
        question: {
          ...cachedQuestion,
        },
        answers: [],
      });
      setIsLoading(false);
      setViews(cachedQuestion?.views);
      setPageRefreshed(true); // For updating the views
    } else {
      setDoServiceCall(true);
    }
  }, [cachedQuestion]);

  /* On Page Load call the service */
  /* Question Details */
  const [questionDetails] = useGetQuestionDetails(
    questionId,
    selectedBtn,
    doServiceCall,
  );

  useEffect(() => {
    if (questionDetails) {
      if (questionDetails?.question?.id === '') navigateToAllQuestionPage();
      setQuestionData(questionDetails);
      setViews(questionDetails?.question?.views!);
    }
  }, [questionDetails]);
  /* Question Details - Ends here */

  /* Get UserDetails once the questionData is loaded */
  useEffect(() => {
    if (questionData && doServiceCall) getUserDetails(questionId);
  }, [questionData]);

  const handlePostAnswerSuccess = (newAnswer: IEAnswerItem) => {
    setDoServiceCall(false);
    if (selectedBtn !== 'newest') setSelectedBtn('newest');
    setQuestionData((prev: any) => {
      const newAnswers = prev.answers ? [...prev.answers] : [];
      newAnswers.unshift(newAnswer);
      return {
        ...prev,
        answers: newAnswers,
      };
    });
    setBotContent({
      ...botContent,
      show: false
    })
    scrollTo(discussionRef);
  };

  const handleEditAnswerSuccess = (editedAnswer: EditAnswerRequest) => {
    setDoServiceCall(false);
    setQuestionData((prev: any) => {
      const newAnswers = prev.answers
        ? [...prev.answers].map(answer => {
            return answer.id === editedAnswer.id
              ? { ...answer, detail: editedAnswer.detail }
              : { ...answer };
          })
        : [];
      return {
        ...prev,
        answers: newAnswers,
      };
    });
    setOldEditData(null);
    scrollTo(answerRef);
  };

  const updateAcceptedAnswer = (aid: any) => {
    setDoServiceCall(false);
    const questionDataClone: QuestionDetailsResponse = { ...questionData };
    questionDataClone?.answers?.map(answer => {
      return answer.id === aid
        ? (answer.accepted = true)
        : (answer.accepted = false);
    });
    setQuestionData(questionDataClone);
  };

  useEffect(() => {
    if (acceptedAnswer?.qid === questionId) {
      updateAcceptedAnswer(acceptedAnswer.aid);
    }
  }, [acceptedAnswer]);

  const handleFilterClick = (value: any) => {
    setDoServiceCall(true);
    setSelectedBtn(value);
  };

  const { profEmail, isAdmin } = useContext(AuthContext);
  const isAuthorLoggedInUser = () => {
    return (
      questionData?.question?.author.toLowerCase() === profEmail.toLowerCase()
    );
  };

  useEffect(() => {
    if (oldEditData) scrollTo(PostEditAnswerRef);
  }, [oldEditData]);

  const handleCancel = () => {
    setOldEditData(null);
    scrollTo(answerRef);
  };

  const navigateToEditQuestionPage = () => {
    navigate(`/information-exchange/edit-question/${questionId}`);
  };

  const isLoggedinUserAdmin = () => {
    return isAdmin;
  };

  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  /* Insert View Api */
  const insertView = (qid: String) => {
    informationExchange
      .insertView(qid)
      .then(() => {
        setViews(preValue => preValue + 1);
      })
      .catch(() => {
        notificationApi.sendNotification({
          message: `Error Occured`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  useEffect(() => {
    if (pageRefreshed) insertView(questionId);
  }, [pageRefreshed]);

  /* Delete Question */
  const deleteQuestion = (qid: any, reasonText: string) => {
    setIsLoading(true);

    const deleteQuesData: DeleteQuestionRequest = {
      id: qid,
      reasonfordelete: reasonText,
      isadmin: isAdmin,
    };
    informationExchange
      .deleteQuestion(deleteQuesData)
      .then((data: any) => {
        setUserData(data);
        setIsLoading(false);
        notificationApi.sendNotification({
          message: `Question Deleted`,
          disapperAfterMs: 2500,
          severity: 'success',
          callback: navigateToAllQuestionPage,
        });
      })
      .catch((error: any) => {
        notificationApi.sendNotification({
          message: `Error occurred - ${error?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  const [deleteAnsModalOpened, setDeleteAnsModalOpened] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState<IEAnswerItem | null>(
    null,
  );

  const handleDeleteAnsClicked = (answer: IEAnswerItem) => {
    setSelectedAnswer(answer);
    setDeleteAnsModalOpened(true);
  };

  const handleDeleteSuccess = (aid: string) => {
    setDoServiceCall(false);
    setQuestionData((prev: any) => {
      const newAnswers = prev.answers
        ? [...prev.answers].filter(answer => answer.id !== aid)
        : [];
      return {
        ...prev,
        answers: newAnswers,
      };
    });
  };

  const deleteAnswer = (aid: string, reasonText: any) => {
    setIsLoading(true);

    const deleteAnsData: DeleteAnswerRequest = {
      id: aid,
      reasonfordelete: reasonText,
      isadmin: isAdmin,
    };
    informationExchange
      .deleteAnswer(deleteAnsData)
      .then((data: any) => {
        setUserData(data);
        handleDeleteSuccess(aid);
        setIsLoading(false);
        notificationApi.sendNotification({
          message: `Answer Deleted`,
          disapperAfterMs: 2500,
          severity: 'success',
        });
      })
      .catch((error: any) => {
        notificationApi.sendNotification({
          message: `Error occurred - ${error?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  const handleCloseAnsDialog = () => {
    setSelectedAnswer(null);
    setDeleteAnsModalOpened(false);
  };

  useEffect(() => {
    setAnsweredByAI(
      !!questionData?.answers?.find((q: IEAnswerItem) => q.openaicorrelationid),
    );
  }, [questionData?.answers]);

  return (
    <Content>
      {/* Delete Question Modal */}
      <IEDeleteModal
        title="Delete Conversation"
        message={`Are you sure, you want to delete "${questionData?.question?.title}"`}
        opened={deleteModalOpened}
        onCloseDialog={() => setDeleteModalOpened(false)}
        onYesClicked={(reasonText: string) =>
          deleteQuestion(questionData?.question?.id, reasonText)
        }
      />
      {/* Delete Answer Modal*/}
      {selectedAnswer && (
        <IEDeleteModal
          title="Delete Answer"
          message="Are you sure, you want to delete Answer"
          opened={deleteAnsModalOpened}
          onCloseDialog={() => handleCloseAnsDialog()}
          onYesClicked={(reasontext: string) =>
            deleteAnswer(selectedAnswer.id, reasontext)
          }
        />
      )}

      <Grid container direction="row">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.allQuestions}
        >
          <div className={classes.backHelpBtns}>
            <BackButton />
            <div className={classes.buttons}>
            {isAuthorLoggedInUser() && (
              <Button
                className={classes.editQueBtn}
                onClick={navigateToEditQuestionPage}
                variant="outlined"
              >
                Edit Conversation
              </Button>
            )}
            {((isAuthorLoggedInUser() &&
              questionData?.question?.discussions === 0) ||
              isLoggedinUserAdmin()) && (
                <Button
                  className={`${classes.editQueBtn} ${classes.delQtnBtn}`}
                  onClick={() => setDeleteModalOpened(true)}
                  variant="outlined"
                >
                  Delete Conversation
                </Button>
              )}
              {/*  <Button
                className={classes.askQueBtn}
                onClick={navigateToAskQuestionPage}
                variant="contained"
                color="primary"
              >
                Start a Conversation
              </Button> */}
              <HelpComponent
                helpUrl={HELP_URL.INFO_EXCHANGE_POST_DETAILS_PAGE}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <div className={`codePage ${classes.postDetailsCodePage}`}>
        {isLoading && <LinearProgress />}
        {questionData && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className={classes.postQueHeading}>
                {questionData?.question?.title}
                {isAuthorLoggedInUser() &&
                  !botContent.disabled &&
                  !botContent.show &&
                  !answeredByAI && (
                    <Button
                      variant="contained"
                      className={classes.aiButton}
                      onClick={() => {
                        setBotContent({
                          ...botContent,
                          show: true,
                        });
                      }}
                      disabled={botContent.show}
                    >
                      <AIBotIcon
                        fill={theme.palette.common.black}
                        fontSize="small"
                      />
                      <div>Try AI Answer?</div>
                    </Button>
                  )}
              </div>
              <div className={classes.timeStamp}>
                <div>
                  {timeSince(questionData?.question?.createdat, 'Asked ')}
                </div>
                <div>
                  {timeSince(questionData?.question?.updatedat, 'Modified ')}
                </div>
                <div>{views || 0} Views</div>
              </div>
              {/* Question Section */}
              <PostQuestionCard
                queData={questionData?.question}
                queUserData={userData}
              />
              {!botContent.disabled && botContent.show && (
                <AIBlock
                  pid={questionData?.question.id}
                  content={questionData?.question.description}
                  onCancel={() => {
                    setBotContent({
                      ...botContent,
                      show: false,
                    });
                  }}
                  author={questionData?.question.author}
                  onSuccess={handlePostAnswerSuccess}
                  openaicorrelationid={
                    questionData?.question.openaicorrelationid
                  }
                  onRephrase={onRephrase}
                />
              )}

              {/* Discussions Section */}
              {questionData?.answers && questionData?.answers.length > 0 ? (
                <>
                  <hr className={classes.divider} ref={discussionRef} />
                  <Discussions
                    answers={questionData?.answers}
                    answersUserData={userData?.answers}
                    queAuthor={questionData?.question?.author}
                    onFilterClick={handleFilterClick}
                    filterBy={selectedBtn}
                    onAnsDeleted={handleDeleteAnsClicked}
                  />
                </>
              ) : null}
              {/* Post Your Answer Section */}
              <hr className={classes.divider} ref={PostEditAnswerRef} />
              <PostAnswer
                qid={questionData?.question?.id}
                onSuccess={handlePostAnswerSuccess}
                onEditSuccess={handleEditAnswerSuccess}
                onCancel={handleCancel}
                editData={oldEditData}
              />
            </Grid>

            {/* Similar Questions Section */}
            {/* <Grid item xs={12} sm={12} md={3} lg={3}>
              <SimilarQuestions />
            </Grid> */}
          </Grid>
        )}
      </div>
    </Content>
  );
};
export default QuestionDetailsPage;
