import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

type ThumbDownIconInputProps = {
  fill: string;
};

const ThumbDownIcon = (props: ThumbDownIconInputProps) => {
  return (
    <>
      {!props.fill ? (
        <SvgIcon viewBox="0 0 24 24" fill="#000000">
          <path path="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none" />
          <path d="M15 3H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 23l6.59-6.59c.36-.36.58-.86.58-1.41V5c0-1.1-.9-2-2-2zm0 12l-4.34 4.34L12 14H3v-2l3-7h9v10zm4-12h4v12h-4z" />
        </SvgIcon>
      ) : (
        <SvgIcon viewBox="0 0 24 24" fill="#003F2D">
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M15 3H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 23l6.59-6.59c.36-.36.58-.86.58-1.41V5c0-1.1-.9-2-2-2zm4 0v12h4V3h-4z" />
        </SvgIcon>
      )}
    </>
  );
};

export default ThumbDownIcon;
