import React from 'react';
import { IEProvider } from './providers/IEProvider';
import IEHome from './IEHome';

const InformationExchange = () => {
  return (
    <IEProvider>
      <IEHome />
    </IEProvider>
  );
};

export default InformationExchange;
