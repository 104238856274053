import { Button, Grid, LinearProgress, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import BackButton from '../../utils/backButton';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import { useStyles } from '../styles';
import { useParams } from 'react-router';
import { useAdminCrud } from '../hooks/useAdminCrud';
import AdminsTable from './cards/AdminsTable';
import UserList from './common/UserList';

const AdminsPage = ({ isToolAdmin }: { isToolAdmin: boolean }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { projectId } = useParams();

  const [admins, loading, createAdmin, deleteAdmin] = useAdminCrud(
    projectId!,
    true,
  );

  const [user, setUser] = useState<any>();
  const [clearUserList, setClearUserList] = useState(0);

  const handleCreateAdmin = () => {
    createAdmin(user.user).then(() => setClearUserList(clearUserList + 1));
  };

  const handleDelete = (id: string) => {
    deleteAdmin(id);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.flexSpacebetween}
        >
          <BackButton />

          <HelpComponent helpUrl={HELP_URL.PROVISIONING_CENTER_ADMINS} />
        </Grid>
      </Grid>

      <Grid container className={classes.pagePadding}>
        <Grid item xs={12}>
          {loading && <LinearProgress />}
        </Grid>
        {isToolAdmin && (
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={6}
            className={`${classes.flex} ${classes.gap1}`}
          >
            <UserList onChange={setUser} clear={clearUserList} />
            <Button
              onClick={handleCreateAdmin}
              variant="contained"
              color="primary"
              disabled={!user}
              className={classes.sentenceCaseText}
            >
              Add Admin
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <AdminsTable
            showActions={isToolAdmin}
            loading={loading}
            admins={admins}
            onDelete={(id: string) => handleDelete(id)}
          />
        </Grid>
      </Grid>
    </>
  );
};

// <Grid container className={classes.pagePadding}>
//   <Grid item xs={12} sm={12} md={12} lg={12}>
//     {filteredActivityData.length === 0 ? (
//       <Paper className={classes.activityCard}>
//         <Typography>No results found.</Typography>
//       </Paper>
//     ) : (
//       filteredActivityData
//         .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//         .map(activity => <ActivityCard activity={activity} />)
//     )}

//     <Paper className={classes.activityCard}>
//       <TablePagination
//         rowsPerPageOptions={[5, 10, 15]}
//         component="div"
//         count={filteredActivityData.length}
//         rowsPerPage={rowsPerPage}
//         page={page}
//         onPageChange={handleChangePage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//       />
//     </Paper>
//   </Grid>
// </Grid>

export default AdminsPage;
