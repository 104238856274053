import { createApiRef } from '@backstage/core-plugin-api';
import { AxiosInstance, AxiosResponse } from 'axios';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';

export interface EntityConversionApi {
  saveData: (
    repoName: string,
    kindType: string,
    data: any,
  ) => Promise<AxiosResponse>;
  getData: (repoName: string, kindType: string) => Promise<AxiosResponse>;
  postDataWithEmailFlag: (
    repoName: string,
    kindType: string,
    sendEmail: any,
    data: any,
  ) => Promise<AxiosResponse>;
}

export const entityConversionApiRef = createApiRef<EntityConversionApi>({
  id: 'cbre.devx.api.entity-conversion',
});

export class EntityConversionApiImpl implements EntityConversionApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  async saveData(
    repoName: string,
    kindType: string,
    data: any,
  ): Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;

    return instance.post(`service/discovery/conversion`, data, {
      params: {
        repoName,
        kindType,
      },
    });
  }

  async getData(repoName: string, kindType: string): Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;

    return instance.get(`service/discovery/conversion`, {
      params: {
        repoName,
        kindType,
      },
    });
  }

  async postDataWithEmailFlag(
    repoName: string,
    kindType: string,
    sendEmail: any,
    data: any,
  ): Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;

    return instance.post(`service/discovery/conversion`, data, {
      params: {
        repoName,
        kindType,
        sendEmail,
      },
    });
  }
}
