import React from 'react';
import {
  Button,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { DELETE_TYPES } from '../Constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteModal: {
      position: 'relative',
    },
    closeIcon: {
      position: 'absolute',
      top: '-20px',
      right: '0px',
    },
    deletePromptBtns: {
      marginRight: '10px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    noBtn: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginTop: '15px',
      },
    },
    subtreeBtn: {
      marginRight: '10px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginTop: '15px',
      },
    },
  }),
);

export interface DeletePromptProps {
  selectedEntity: any;
  onClose: (event: any, action?: any) => void;
}

const DeletePrompt = ({ selectedEntity, onClose }: DeletePromptProps) => {
  const classes = useStyles();
  return (
    <div>
      <section className={classes.deleteModal}>
        <h3>Are you sure ?</h3>
        <section className={classes.closeIcon}>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </section>
      </section>
      <p>
        to delete Capability &apos; {selectedEntity?.metadata?.name} &apos;, It
        will be permanently deleted
      </p>

      {/* Buttons Section */}
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {selectedEntity &&
            selectedEntity?.spec?.parent &&
            selectedEntity?.spec?.parent?.length > 0 &&
            selectedEntity?.spec?.children &&
            selectedEntity?.spec?.children?.length > 0 && (
              <>
                <Button
                  className={classes.deletePromptBtns}
                  variant="contained"
                  color="primary"
                  onClick={(event: any) => {
                    onClose(
                      event,
                      DELETE_TYPES.DELETE_AND_DELETE_COMPLETE_SUBTREE,
                    );
                  }}
                >
                  Yes, and Delete all children
                </Button>
                <Button
                  className={classes.subtreeBtn}
                  variant="contained"
                  color="primary"
                  onClick={(event: any) => {
                    onClose(
                      event,
                      DELETE_TYPES.DELETE_AND_ASSIGN_CHILDREN_TO_PARENT,
                    );
                  }}
                >
                  Yes, and assign subtree to parent node
                </Button>
              </>
            )}

          {selectedEntity &&
            selectedEntity?.spec?.parent &&
            selectedEntity?.spec?.parent?.length > 0 &&
            selectedEntity?.spec?.children &&
            selectedEntity?.spec?.children?.length == 0 && (
              <Button
                className={classes.deletePromptBtns}
                variant="contained"
                color="primary"
                onClick={(event: any) => {
                  onClose(event, DELETE_TYPES.DELETE_CAPABILITY);
                }}
              >
                Yes
              </Button>
            )}

          {selectedEntity &&
            !selectedEntity?.spec?.parent &&
            selectedEntity?.spec?.children &&
            selectedEntity?.spec?.children?.length >= 0 && (
              <Button
                className={classes.deletePromptBtns}
                variant="contained"
                color="primary"
                onClick={(event: any) => {
                  onClose(event, DELETE_TYPES.DELETE_CAPABILITY);
                }}
              >
                Yes
              </Button>
            )}

          <Button
            className={classes.noBtn}
            variant="contained"
            onClick={onClose}
          >
            No
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
export default DeletePrompt;
