import React, { useEffect, useState, useContext } from 'react';
import { InfoCard } from '@backstage/core-components';
import { useNavigate } from 'react-router-dom';
import { useCustomStyles } from '../../../common/Cards/CardStyles';
import CurrentLeaderBoardCard from '../../../leaderBoard/Grid/currentLeaderBoardCard';
import { gamificationApiRef } from '../../../../apis/gamificationApis';
import { PromotionContext } from '../../../../providers/PromotionDataProvider';
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';

export const LeaderBoardAndTopPlayers = () => {
  const classes = useCustomStyles();
  const navigate = useNavigate();
  const [Data, setData] = useState<any>([]);
  const [isLevelList, setLevelList] = useState<any>(false);
  const getCurrentLeaderBoard = useApi(gamificationApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const gamificationLevelsList = useContext(PromotionContext);

  useEffect(() => {
    if (isLevelList) {
      setLevelList(false);
      getCurrentLeaderBoard
        .getCurrentLeaderBoard()
        .then((res: any) => setData(res))
        .catch((error: any) => {
          notificationApi.sendNotification({
            message: `Error occurred - ${error?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });
    }
  }, [getCurrentLeaderBoard, isLevelList]);

  useEffect(() => {
    if (getCurrentLeaderBoard)
      getCurrentLeaderBoard
        .getRewardsLevels()
        .then((res: any) => {
          gamificationLevelsList.setGamificationlevelData(res);
          setLevelList(true);
        })
        .catch((error: any) => {
          notificationApi.sendNotification({
            message: `Error occurred - ${error?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });
  }, [getCurrentLeaderBoard]);

  return (
    <InfoCard
      title="Current Leaderboard"
      // className="recentArticle LeaderBoardHomepage"
      headerStyle={{
        marginBottom: '2px',
      }}
    >
      <button
        className={classes.CardActionButton}
        onClick={() => {
          navigate('/catalog/leader-board');
        }}
      >
        See more ➔
      </button>

      <div className={classes.CurrentLeaderBoardCardContainer}>
        {Data &&
          Array.isArray(Data) &&
          Data.length > 0 &&
          Data.slice(0, 5).map((item: any, index: number) => (
            <CurrentLeaderBoardCard
              data={item}
              key={`CurrentLeaderBoardCard${index}`}
            />
          ))}
      </div>
    </InfoCard>
  );
};
