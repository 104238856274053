import React from 'react';

const Silver = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0001 2.12978L19.1869 10.6133L19.3032 10.849L19.5633 10.8868L28.9255 12.2473L22.151 18.8508L21.9628 19.0343L22.0072 19.2934L23.6065 28.6177L15.2328 24.2153L15.0001 24.093L14.7674 24.2153L6.39358 28.6177L7.9928 19.2934L8.03724 19.0343L7.84901 18.8508L1.07448 12.2473L10.4367 10.8868L10.6968 10.849L10.8131 10.6133L15.0001 2.12978Z"
        fill="#F5F7F8"
        stroke="#EBE7E7"
      />
      <path
        d="M21.2547 14.8517L16.933 14.2234L15 10.3065L13.0668 14.2234L8.74512 14.8517L11.8721 17.9L11.1343 22.2048L15 20.1725L18.8655 22.2048L18.1277 17.9L21.2547 14.8517Z"
        fill="#D2D9DB"
      />
      <path
        d="M15.0001 24.6579V1L10.3648 10.392L0 11.8982L7.5 19.2089L5.72951 29.5317L15.0001 24.6579Z"
        fill="#CAD1D3"
      />
      <path
        d="M15 20.1725V10.3065L13.0668 14.2234L8.74512 14.8517L11.8721 17.9L11.1343 22.2048L15 20.1725Z"
        fill="#FAFAFA"
      />
    </svg>
  );
};

export default Silver;
