import React, { useEffect, useContext, useState, useMemo } from 'react';
import { isEmpty } from 'lodash';
import {
  TableColumn,
  Table,
  WarningPanel,
  CodeSnippet,
  TableProps,
} from '@backstage/core-components';
import {
  humanizeEntityRef,
  getEntityRelations,
  useEntityList,
  useStarredEntities,
  useEntityOwnership,
  UserListFilter,
} from '@backstage/plugin-catalog-react';

import * as columnFactories from '../../utils/devXTableColumns';
import { RELATION_OWNED_BY, RELATION_PART_OF } from '@backstage/catalog-model';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { Link } from 'react-router-dom';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import { COMPONENT_TYPE, NOT_APPROVED, API } from '../../utils/constant';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import SubscriptionsOutlinedIcon from '@material-ui/icons/SubscriptionsOutlined';
import { AuthContext } from '../../../providers/AuthProvider';
import { useApi } from '@backstage/core-plugin-api';
import AllAssetsCardGrid from '../../home/allAssets/cardView/AllAssetsCardGrid';
import { subscriberApiRef } from '../../../apis/subscriberApi';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { AllAssetsTable } from '../../home/allAssets/allAssetsTable/allAssetsTable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    themeColor: {
      '& tr': {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: `${theme.palette.background.default} !important`,
        '& td ': {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: `${theme.palette.background.default} !important`,
          '& button ': {
            color: theme.palette.primary.main,
          },
        },
        '& th': {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: `${theme.palette.background.default} !important`,
        },
      },
      '& div::-webkit-scrollbar-thumb': {
        border: `4px solid ${theme.palette.text.tertiary}`,
      },
    },
  }),
);
const columns: TableColumn<columnFactories.EntityRow>[] = [
  columnFactories.createNameColumnCustomRoot({ rootRoute: 'code-library' }),
  columnFactories.createOwnerColumn(),
  columnFactories.createSpecTypeColumn(),
  columnFactories.createSpecLastCommitDateColumn(),
  columnFactories.createMetadataDescriptionColumn(),
  columnFactories.createTagsColumn(),
  columnFactories.createStatusColumn(),
  columnFactories.createRatingColumn(),
];

export const CodeTable = ({
  isApi = false,
  view,
  mountedOn,
  setLoading,
  sort = {},
}: any) => {
  const classes = useStyles();
  const { isStarredEntity, toggleStarredEntity } = useStarredEntities();
  const { loading, error, entities, filters } = useEntityList();
  const [isClickSubscribeIcon, setClickSubscribeIcon] = useState<any>(false);
  const [isError, setError] = useState<any>(false);
  const [subscribeData, setSubscribeData] = useState<any>([]);
  const { profEmail, isAdmin } = useContext(AuthContext);

  const subscriberApi = useApi(subscriberApiRef);

  const userEmail = profEmail;
  const { isOwnedEntity } = useEntityOwnership();
  const starredFilter = useMemo(
    () => new UserListFilter('starred', isOwnedEntity, isStarredEntity),
    [isOwnedEntity, isStarredEntity],
  );
  useEffect(() => {
    if (loading !== true && setLoading) {
      setLoading(false);
    }
  }, [loading, setLoading]);

  useEffect(() => {
    subscriberApi
      .getSubscribersCount()
      .then(data => {
        setSubscribeData(data || []);
      })
      .catch(() => setError(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClickSubscribeIcon]);

  const checkSubscribe = (data: any) => {
    let isChecksubscribe = false;
    subscribeData &&
      subscribeData.map((item: any) => {
        if (
          data.metadata?.name.toLowerCase() === item?.assetName.toLowerCase() &&
          item?.email.toLowerCase() === userEmail!.toLowerCase()
        ) {
          isChecksubscribe = true;
        }
      });
    return isChecksubscribe;
  };

  const onClickSubscribe = async (data: any) => {
    const payload = {
      assetName: data?.metadata?.name,
      email: userEmail,
    };
    await subscriberApi.assetsSubscribe(payload);
    setClickSubscribeIcon(!isClickSubscribeIcon);
  };

  const onClickUnSubscribe = async (data: any) => {
    const payload = {
      assetName: data?.metadata?.name,
      email: userEmail,
    };
    await subscriberApi.assetsUnsubscribe(payload);
    setClickSubscribeIcon(!isClickSubscribeIcon);
  };

  const defaultActions: TableProps<columnFactories.EntityRow>['actions'] = [
    ({ entity }) => {
      const name = entity.metadata.name;
      const kind = entity.kind;
      const namespace = entity.metadata.namespace;
      const path = kind?.toLowerCase() === API ? 'api-edit' : 'edit';
      const userProfile = profEmail?.split('@')[0].toLowerCase();
      const entityOwner = `${entity?.spec?.owner}`;
      const userOwnerShip =
        userProfile === entityOwner?.replace('user:', '') || isAdmin;
      return {
        icon: () => (
          <Link to={`/code-library/${namespace}/${kind}/${name}/${path}`}>
            <EditRoundedIcon fontSize="small" />
          </Link>
        ),
        tooltip: 'View',
        disabled: !userOwnerShip,
        onClick: () => {},
      };
    },

    ({ entity }) => {
      const isStarred = isStarredEntity(entity);
      return {
        cellStyle: { paddingLeft: '1em' },
        icon: () =>
          isStarred ? <BookmarkIcon /> : <BookmarkBorderOutlinedIcon />,
        tooltip: 'Bookmark',
        onClick: () => toggleStarredEntity(entity),
      };
    },
    ({ entity }) => {
      const userProfile = userEmail!.split('@')[0].toLowerCase();
      const entityOwner = `${entity?.spec?.owner}`;
      const userOwnerShip =
        userProfile?.toLowerCase() ===
        entityOwner.replace('user:', '')?.toLowerCase();

      const isSubscribe = checkSubscribe(entity);
      if (isSubscribe) {
        return {
          cellStyle: { paddingLeft: '1em' },
          icon: () => <SubscriptionsIcon />,
          tooltip: 'Unsubscribe',
          disabled: userOwnerShip,
          onClick: () => onClickUnSubscribe(entity),
        };
      }
      return {
        cellStyle: { paddingLeft: '1em' },
        icon: () => <SubscriptionsOutlinedIcon />,
        tooltip: 'Subscribe',
        disabled: userOwnerShip,
        onClick: () => onClickSubscribe(entity),
      };
    },
  ];
  const showedContent = entities.filter(
    (item: any) =>
      !isEmpty(item?.metadata?.devxstate) &&
      COMPONENT_TYPE.includes(item?.spec?.type.toLowerCase()) &&
      item?.metadata?.devxstate !== NOT_APPROVED,
  );
  const starredFilterItem =
    showedContent &&
    showedContent.length &&
    showedContent.filter((item: any) => starredFilter.filterEntity(item));
  const rows = showedContent.map(entity => {
    const partOfSystemRelations = getEntityRelations(entity, RELATION_PART_OF, {
      kind: 'system',
    });
    const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);

    return {
      entity,
      resolved: {
        name: humanizeEntityRef(entity, {
          defaultKind: 'Component',
        }),
        ownedByRelationsTitle: ownedByRelations
          .map(r => humanizeEntityRef(r, { defaultKind: 'group' }))
          .join(', '),
        ownedByRelations,
        partOfSystemRelationTitle: partOfSystemRelations
          .map(r =>
            humanizeEntityRef(r, {
              defaultKind: 'system',
            }),
          )
          .join(', '),
        partOfSystemRelations,
      },
    };
  });

  if (error) {
    return (
      <div>
        <WarningPanel
          severity="error"
          title="Could not fetch catalog entities."
        >
          <CodeSnippet language="text" text={error.toString()} />
        </WarningPanel>
      </div>
    );
  }
  const titlePreamble = filters.user?.value ?? 'all';

  return (
    <>
      {view === 'table' ? (
        <div className={`mystuff-table  ${classes.themeColor}`}>
          {/* <Table
            isLoading={loading}
            columns={columns}
            options={{
              paging: true,
              pageSize: 20,
              search: true,
              actionsColumnIndex: -1,
              loadingType: 'linear',
              showEmptyDataSourceMessage: !loading,
              padding: 'dense',
              pageSizeOptions: [20],
            }}
            data={isApi ? [] : rows}
            actions={defaultActions}
            title={`${
              titlePreamble.toLowerCase() === STARRED ? BOOKMARK : titlePreamble
            } (${rows.length})`}
          /> */}
          <AllAssetsTable
            entities={showedContent}
            error={error}
            loading={loading}
            checkFilterItem={{
              ...sort,
            }}
            starredFilterItem={starredFilterItem || []}
            subscribeData={subscribeData || []}
            setClickSubscribeIcon={setClickSubscribeIcon}
            isClickSubscribeIcon={isClickSubscribeIcon}
            isAdmin={isAdmin}
          />
        </div>
      ) : (
        <>
          {showedContent && (
            <AllAssetsCardGrid
              entities={showedContent}
              anchorElement={null}
              setAnchorElement={() => {}}
              isAdmin={isAdmin}
              checkFilterItem={{
                ...sort,
              }}
              starredFilterItem={starredFilterItem || []}
              subscribeData={subscribeData || []}
              setClickSubscribeIcon={setClickSubscribeIcon}
              isClickSubscribeIcon={isClickSubscribeIcon}
              loading={loading}
              mountOn={mountedOn}
            />
          )}
        </>
      )}
    </>
  );
};
