import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Paper,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinkIcon from '@material-ui/icons/Link';

import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';
import { useApi } from '@backstage/core-plugin-api';
import { adminApiRef } from '../../../../apis/adminApi';
import {
  ERROR_MESSAGE,
  ERROR_MESSAGE_FOR_USER_LIST,
} from '../../../utils/constant';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

const userListMock = [
  {
    id: '6d33ddf6-d68b-4319-ae86-0d6c4b1c36d6',
    displayName: 'Bagade, Pushan @ Pune',
    mail: 'Pushan.Bagade@cbre.co.in',
  },
  {
    id: '12902feb-19c7-46e9-ae22-127cf21f463b',
    displayName: 'Gangulag, Pushpalatha @ GPC',
    mail: 'Pushpalatha.Gangulagari@cbre.co.in',
  },
  {
    id: 'a10b3a2d-f033-46ac-84c1-b464b3ecb94d',
    displayName: 'K, Pushpa Latha @ Bangalore',
    mail: 'PushpaLatha.K@cbre.com',
  },
  {
    id: '3c2f95df-64ac-4a62-8cb5-a752e7b7bd74',
    displayName: 'Kumar, Pushpender @ Global D&T',
    mail: 'Pushpender.Kumar@cbre.com',
  },
  {
    id: '213b6a4f-dbf1-40db-b539-9d53d4284a9d',
    displayName: 'Kumar, PushpRaj @ Global D&T Contractor',
    mail: 'PushpRaj.Kumar@cbre.com',
  },
  {
    id: '1d2add07-5143-4c85-93c8-7c85a16c06c7',
    displayName: 'P, Pushparaj @ Chennai',
    mail: 'Pushparaj.P@cbre.com',
  },
  {
    id: 'e9267d22-4379-4d29-9544-9fb3c23be21c',
    displayName: 'Pal, Pushpendra @ Gurgaon',
    mail: 'Pushpendra.Pal@cbre.com',
  },
  {
    id: '1cae7eee-cee0-4e6f-bfa4-05fa4f1de368',
    displayName: 'Pandey, Pushpendra @ Lucknow',
    mail: 'Pushpendra.Pandey@cbre.com',
  },
  {
    id: 'e339ec6f-b873-4b6b-9a08-d79442313abb',
    displayName: 'Pushkina, Ekaterina @ Moscow',
    mail: 'Ekaterina.Pushkina@cbre.com',
  },
  {
    id: '75a62edd-7291-4c7b-9d40-90dfb57dab71',
    displayName: 'Pushpanathan, Rajiv @ Singapore',
    mail: 'Rajiv.Pushpanathan@cbre.com',
  },
  {
    id: '65d22905-51c2-46bf-b924-577d14338ecd',
    displayName: 'Pushparaj, Susraj @ Mumbai',
    mail: 'Susraj.Pushparaj@cbre.com',
  },
  {
    id: 'b4c6453f-f70d-4078-a59a-8d7bc089bd74',
    displayName: 'Raj, Pushkar @ Gurgaon',
    mail: 'Pushkar.Raj@cbre.com',
  },
  {
    id: '5b835c19-8d82-41cd-a9a1-ee5dd3bf31d8',
    displayName: 'Raorane, Pushkar @ Mumbai',
    mail: 'Pushkar.Raorane@cbre.com',
  },
];

const debounce = (fn: any, delay: any) => {
  let timeoutId: any;
  // @ts-expect-error
  return function (...args) {
    clearTimeout(timeoutId);
    // @ts-expect-error
    timeoutId = setTimeout(() => fn.apply(this, args), delay);
  };
};

const PageInfoFormField = (props: any) => {
  const { classes, inititalValue, links } = props;
  // console.log('links', links);

  const handleChange = (i: any, e: any) => {
    let formValues = links;
    console.log('formValues', formValues);
    formValues[i][e.target.name] = e.target.value;

    inititalValue([...formValues]);
  };

  const onChange = (index: any, e: any, value: any) => {
    console.log('index', index);
    console.log('mail', value);
    if (value) {
      e?.preventDefault();
      let formValues = links;
      formValues[index]['v'] = value?.mail;
      formValues[index]['displayName'] = value?.displayName;
      inititalValue([...formValues]);
    }
  };

  const addFormFields = () => {
    inititalValue([...links, { type: 'link', k: '', v: '', linkText: '' }]);
  };

  const removeFormFields = (index: number) => {
    let formValues = links;
    // console.log('splice', index);
    formValues.splice(index, 1);
    // console.log('formValues', formValues);
    inititalValue([...formValues]);
  };

  const [loading, setloading] = useState(false);
  const [userList, setuserList] = useState<any>([]);
  const [selectedVal, setSelectedVal] = useState("");

  let defaultValue = [];

  useEffect(() => {
    if (userList.length == 0) {
      defaultValue = links && links.filter((el: any) => el.v.includes('@')).map((el2: any) => { return { ...el2, mail: el2.v } });
      setuserList(defaultValue)
      setSelectedVal(defaultValue)
      console.log("defaultValue", defaultValue)
    }

  }, [links])


  useEffect(() => {

  }, [selectedVal])

  const adminApi = useApi(adminApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  let ERROR_Text = ERROR_MESSAGE;

  const getuserList = async (user: any) => {
    try {
      setloading(true);
      const data = await adminApi.getUserList(user);
      const options = data?.value || [];
      setuserList([...userList, ...options] || []);
      setloading(false);
      return data;
    } catch (error) {
      ERROR_Text = ERROR_MESSAGE_FOR_USER_LIST;
      //setuserList([]);
      setloading(false);

      return error;
    }
  };

  //eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCallback = useCallback(
    debounce((value: any) => {
      getuserList(value);
    }, 1000),
    [],
  );

  const onInputChange = async (e: any) => {
    if (e?.target?.value?.length > 2) {
      debounceCallback(e.target.value);
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: any) => option.mail,
  });


  const setSelectedValue = (value: string) => {
    const selectedval = userList && value && userList.filter((element: any) => element.mail === value)[0];
    // setSelectedVal(selectedval);
    return selectedval
  }

  return (
    <>
      <div className={classes.links}>
        <div className={classes.linksHeading + ' ' + classes.margin24}>
          At a Glance
        </div>
      </div>
      {links &&
        links.map((item: any, index: number) => {
          return (
            <div className={classes.linkFields}>
              <Grid container>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="type">Type</InputLabel>
                    <Select
                      labelId="type"
                      name="type"
                      label="Type"
                      value={item.type || 'link'}
                      onChange={e => handleChange(index, e)}
                    >
                      <MenuItem value={'link'}>Link</MenuItem>
                      <MenuItem value={'contact'}>Email</MenuItem>
                      <MenuItem value={'text'}>Text</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={item.type === 'link' ? 3 : 4}
                  lg={item.type === 'link' ? 3 : 4}
                >
                  <TextField
                    label="Title"
                    fullWidth
                    variant="outlined"
                    placeholder="Enter"
                    name="k"
                    id="title"
                    onChange={e => handleChange(index, e)}
                    value={item.k}
                  />
                </Grid>

                {item.type === 'link' && (
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextField
                      label="Link text"
                      fullWidth
                      variant="outlined"
                      placeholder="Enter"
                      name="linkText"
                      id="linkText"
                      onChange={e => handleChange(index, e)}
                      value={item.linkText}
                    />
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={item.type === 'link' ? 4 : 6}
                  lg={item.type === 'link' ? 4 : 6}
                  className={classes.links}
                >
                  {item.type === 'contact' ? (
                    <Paper className={classes.paperWidth}>
                      <Autocomplete
                        fullWidth
                        freeSolo
                        id={`email-value-${index}`}
                        data-testid="cbre-user-list"
                        value={setSelectedValue(item?.v)}
                        //value={item?.v && { mail: item.v }}
                        key={`userList${index}`}
                        getOptionSelected={option => option?.mail === item.v}
                        getOptionLabel={option => option && option?.mail}
                        options={userList}
                        loading={loading}
                        onInputChange={e => onInputChange(e)}
                        filterOptions={options => options}
                        onChange={(e, value) => onChange(index, e, value)}
                        renderInput={params => (
                          <TextField
                            {...params}
                            fullWidth
                            variant="outlined"
                            name="email"
                            data-testid="cbre-user-list-input"
                            label={'Email'}
                            placeholder={item?.v || "enter"}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <ContactMailOutlinedIcon />
                                </InputAdornment>
                              ),

                              endAdornment: (
                                <React.Fragment>
                                  {loading && !item?.v ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Paper>
                  ) : (
                    <TextField
                      label={item.type === 'text' ? 'Content' : 'Value'}
                      id="value"
                      name="v"
                      fullWidth
                      placeholder="Enter"
                      variant="outlined"
                      multiline
                      onChange={e => handleChange(index, e)}
                      value={item.v}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {item.type === 'link' && <LinkIcon />}
                            {item.type === 'contact' && (
                              <ContactMailOutlinedIcon />
                            )}
                            {item.type === 'text' && <></>}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  {index > 0 ? (
                    <Tooltip title="Remove item">
                      <IconButton onClick={() => removeFormFields(index)}>
                        <DeleteIcon className={classes.actionIcons} />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Remove item">
                      <IconButton onClick={() => removeFormFields(index)}>
                        <DeleteIcon className={classes.actionIcons} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </div>
          );
        })}
      <span className={classes.addmore}>
        <Tooltip title="Add item">
          <IconButton
            disabled={links.length > 6}
            className={classes.addLink}
            onClick={addFormFields}
          >
            Add Item
            <AddIcon className={classes.actionIcons} />
          </IconButton>
        </Tooltip>
      </span>
    </>
  );
};

export default PageInfoFormField;
