import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  LinearProgress,
  IconButton,
  createStyles,
  makeStyles,
  Theme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
// @ts-ignore
import { useApi } from '@backstage/core-plugin-api';
import SaveIcon from '@material-ui/icons/Save';
import BackButton from '../../../utils/backButton';
import { HelpComponent } from '../../../utils/helpComponent';
import HELP_URL from '../../../utils/helpLinkConstant';
import ToolCard from '../../../toolbox/toolcard';
import {
  toolboxApiRef,
  ToolboxApi,
  ISaveToolboxItemResponse,
} from '../../../../apis/toolboxApi';
import { useNavigate, useParams } from 'react-router-dom';
import ToolboxDefaultImageSelector from './imageSelector';

import ParentCategoryDropdown from './parentCategoryDropdown';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';
import SupportLinksFormField from './supportLinksFormField';
import { validImageFormat } from '../../../utils/Utils';
import RichEditor from '../../../common/RichEditor';
import { POST_MAX_SIZE } from '../../../utils/constant';
import { byteSize } from '../../../utils/Utils';
import PageInfoFormField from './pageInfoFormField';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    links: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    linkFields: {
      display: 'flex',
      marginTop: '20px',
    },
    actionIcons: {
      marginLeft: '10px',
      color: theme.palette.primary.main,
    },
    addmore: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '24px',
    },
    margin24: { marginTop: '24px' },
    toolboxLinks: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: '4px',
      padding: '10px',
      marginTop: '12px',
    },
    linksHeading: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: '-0.01em',
      color: theme.palette.text.secondary,
      display: 'flex',
      alignItems: 'center',
    },
    addLink: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '16px',
      color: theme.palette.text.secondary,
      display: 'flex',
      alignItems: 'center',
    },
    descError: {
      marginLeft: '14px',
      marginRight: '14px',
      color: theme.palette.errorText,
      fontSize: ' 0.75rem',
      marginTop: '14px',
    },
    infoHeading: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '24px',
      margin: '15px 0px 15px 0px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '-0.015em',
      color: theme.palette.text.secondary,
    },
    description: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '-0.01em',
      color: theme.palette.text.secondary,
      marginTop: '10px',
    },
    descContent: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '21px',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.tertiary,
      marginTop: '5px',
    },
    paperWidth: {
      width: '100%',
    },
    radio: {
      '&$checked': {
        color: theme.palette.primary.main,
      },
    },
    mainFormControl: { width: '100%' },
    checked: {},
  }),
);
export interface IFileSelectType {
  fileName: string | undefined;
  type: string | undefined;
  content: string | undefined;
  metadata: string | undefined;
  index?: number | undefined;
}

const regExpAlphaNumericWithSymbols = new RegExp(
  /[^a-zA-Z0-9'()[],-_ ]\s/,
  'g',
);

const limitText = (text: string, limit: number = 255) => {
  if (text !== undefined && String(text).length > limit) {
    return String(text).substring(0, limit);
  }
  return text;
};

const sanitizeText = (text: string | undefined) => {
  if (!text) {
    return '';
  }
  let newValue = limitText(text, 250);
  // check for the allowed characters
  if (text != undefined && typeof text === 'string') {
    newValue = text.replace(regExpAlphaNumericWithSymbols, '');
  }
  return newValue;
};

const AddEditTool = () => {
  const classes = useStyles();
  const [title, setTitle] = useState<string | undefined>('');
  const [linkTitle, setLinkTitle] = useState<string | undefined>('');
  const [pageTitle, setPageTitle] = useState<string | undefined>('');
  const [contactTitle, setContactTitle] = useState<string | undefined>('');
  const [contact, setContact] = useState<string | undefined>('');
  const [links, setLinks] = useState<any>([
    { isdefault: true, displayname: '', link: '', type: 'link' },
  ]);
  const [pageInfo, setPageInfo] = useState<any>([
    { k: '', v: '', type: 'link', linkText: '' },
  ]);

  const [titleError, setTitleError] = useState<string | undefined>(undefined);
  const [description, setDescription] = useState<string | undefined>('');
  const [descriptionError, setDescriptionError] = useState<string | undefined>(
    undefined,
  );
  const [detaildescription, setDetaildescription] = useState<
    string | undefined
  >('');
  const [detailDescriptionError, setDetailDescriptionError] = useState<
    string | undefined
  >(undefined);
  const [link, setLink] = useState<string | undefined>('');
  const [linkError, setLinkError] = useState<string | undefined>(undefined);
  const [pnid, setPnid] = useState<string | number | undefined>('');
  const [pnidError, setPnidError] = useState<string | undefined>(undefined);
  const [selectedFile, setSelectedFile] = useState<
    IFileSelectType | undefined
  >();
  const [selectedBGFile, setSelectedBGFile] = useState<
    IFileSelectType | undefined
  >();
  const [loading, setLoading] = useState<boolean>();

  const [firstLevelCategory, setFirstLevelCategory] = useState<string>();
  const [secondLevelCategory, setSecondaLevelCategory] = useState<string>();

  const toolboxApi: ToolboxApi = useApi(toolboxApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    if (id !== undefined && String(id).length > 0 && id !== 'new') {
      setLoading(true);
      toolboxApi
        .getToolById(id)
        .then(value => {
          setTitle(value?.node.title);
          setDescription(value?.node.description);
          setLink(value?.node.link);
          setPnid(value?.node.pnid);
          setLoading(false);
          setFirstLevelCategory(value?.node?.category);
          setSecondaLevelCategory(value?.node?.subcategory);
          setLinkTitle(value?.node?.linktitle);
          //     setLinks(value?.node?.links || links);
          // setContactTitle(value?.node?.contacttitle || links);
          setDetaildescription(value?.node?.detaildescription || '');
          setPageTitle(value?.node?.pagetitle || '');
          // setContact(value?.node?.contact || links);
          setPageInfo(value?.node?.pageinfo || []);

          /* if the link is not added, add it in the links array - Support for Older link feature, that was removed */
          const __link = value?.node?.link;
          const __links = value?.node?.links || [];

          if (!__link && !__links) {
            setLinks(links);
          }
          if (__link) {
            let isLinkAdded = true;

            isLinkAdded = __links.find(
              (linkItem: any) => linkItem.link === __link,
            );

            const linkobj = {
              isdefault: true,
              displayname: 'Main',
              link: __link,
              type: 'link',
            };
            if (!isLinkAdded) {
              setLinks([{ ...linkobj }, ...__links]);
            } else {
              setLinks(__links);
            }
          }

          if (value?.node.imagekey !== undefined) {
            const ext = String(value?.node.imagekey).substring(
              String(value?.node.imagekey)?.lastIndexOf('.') + 1,
            );
            setLoading(true);
            toolboxApi
              .getImageData(value?.node?.imagekey)
              .then(data => {
                setLoading(false);
                const imageKey = value.node.imagekey;
                let fileName = '';
                if (imageKey !== undefined) {
                  const newStr = imageKey.replace('toolbox/icons/', '');
                  if (newStr !== undefined) {
                    const firstDashIndex = newStr.indexOf('-');
                    if (firstDashIndex !== undefined && firstDashIndex >= 0) {
                      fileName = newStr.substring(firstDashIndex + 1);
                    } else {
                      fileName = newStr;
                    }
                  }
                }
                const imageData = {
                  fileName: fileName,
                  type: ext === 'png' ? 'image/png' : 'image/svg+xml',
                  content: data,
                  metadata: value.node.imageMetaForBase64
                    ? String(value.node.imageMetaForBase64).replace(',', '') +
                      ','
                    : 'data:image/svg+xml;base64,',
                };
                setSelectedFile(imageData);
              })
              .catch((err: any) => {
                setLoading(false);
                notificationApi.sendNotification({
                  message: `Cannot get image for tool with id = ${id} - ${err.message}`,
                  disapperAfterMs: 2500,
                  severity: 'error',
                });
              });
          }
          if (value?.node?.bgimagekey !== undefined) {
            const ext = String(value?.node?.bgimagekey).substring(
              String(value?.node?.bgimagekey)?.lastIndexOf('.') + 1,
            );
            setLoading(true);
            toolboxApi
              .getImageData(value?.node?.bgimagekey)
              .then(data => {
                setLoading(false);
                const BgImageKey = value.node.bgimagekey;
                let BgFileName = '';
                if (BgImageKey !== undefined) {
                  const newStr = BgImageKey.replace('toolbox/icons/', '');
                  if (newStr !== undefined) {
                    const BgfirstDashIndex = newStr.indexOf('-');
                    if (
                      BgfirstDashIndex !== undefined &&
                      BgfirstDashIndex >= 0
                    ) {
                      BgFileName = newStr.substring(BgfirstDashIndex + 1);
                    } else {
                      BgFileName = newStr;
                    }
                  }
                }
                const BgImageData = {
                  fileName: BgFileName,
                  type: ext === 'png' ? 'image/png' : 'image/svg+xml',
                  content: data,
                  metadata: value?.node?.bgimageMetaForBase64
                    ? String(value?.node?.bgimageMetaForBase64).replace(
                        ',',
                        '',
                      ) + ','
                    : 'data:image/svg+xml;base64,',
                };
                setSelectedBGFile(BgImageData);
              })
              .catch((err: any) => {
                setLoading(false);
                notificationApi.sendNotification({
                  message: `Cannot get image for tool with id = ${id} - ${err.message}`,
                  disapperAfterMs: 2500,
                  severity: 'error',
                });
              });
          }
        })
        .catch(err => {
          setLoading(false);
          notificationApi.sendNotification({
            message: `Cannot get tool with id = ${id} - ${err.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
            callback: () => {
              navigate('/admin/toolbox');
            },
          });
        });
    }
  }, [id]);

  const validateForm = () => {
    const errors: string[] = [];
    if (
      title == undefined ||
      (title !== undefined && String(title).trim()?.length === 0)
    ) {
      setTitleError('Title is required');
      errors.push('Title is required');
    }
    if (
      description == undefined ||
      (description !== undefined && String(description).trim()?.length === 0)
    ) {
      setDescriptionError('Description is required');
      errors.push('Description is required');
    }
    // if (
    //   detaildescription == undefined ||
    //   (detaildescription !== undefined &&
    //     String(detaildescription).trim()?.length === 0)
    // ) {
    //   setDetailDescriptionError('Details Description is required');
    //   errors.push('Details Description is required');
    // }
    if (
      pnid == undefined ||
      (pnid !== undefined && String(pnid).trim()?.length === 0)
    ) {
      setPnidError('PNID is required');
      errors.push('PNID is required');
    }
    return errors;
  };

  const onClickSaveBtn = () => {
    const errors = validateForm();
    if (errors !== undefined && Array.isArray(errors) && errors?.length === 0) {
      if (id !== undefined && id === 'new') {
        setLoading(true);
        toolboxApi
          .saveToolDetails({
            title: title || '',
            description: description || '',
            pnid: pnid ? `${pnid}` : null,
            link,
            imageFileName: selectedFile?.fileName,
            imageBase64Format: selectedFile?.content,
            imageMetaForBase64: selectedFile?.metadata,
            links: links,
            linktitle: linkTitle,
            detaildescription: detaildescription,
            pagetitle: pageTitle,
            // contacttitle: contactTitle,
            //contact: contact,
            bgimageBase64Format: selectedBGFile?.content,
            bgimageFileName: selectedBGFile?.fileName,
            bgimageMetaForBase64: selectedBGFile?.metadata,
            pageinfo: pageInfo,
          })
          .then((value: ISaveToolboxItemResponse) => {
            setLoading(false);
            if (value?.status?.toLowerCase() === 'created') {
              notificationApi.sendNotification({
                message: `Tool - ${value?.data?.title} is created successfully`,
                disapperAfterMs: 2500,
                severity: 'success',
                callback: () => {
                  navigate('/admin/toolbox');
                },
              });
            }
          })
          .catch((err: any) => {
            setLoading(false);
            notificationApi.sendNotification({
              message: `Error occurred - ${err?.message}`,
              disapperAfterMs: 2500,
              severity: 'error',
            });
          });
      } else {
        // Update
        setLoading(true);
        toolboxApi
          .updateToolDetails(id, {
            title: title || '',
            description: description || '',
            pnid: pnid !== undefined ? `${pnid}` : '',
            link,
            imageFileName: selectedFile?.fileName,
            imageBase64Format: selectedFile?.content,
            imageMetaForBase64: selectedFile?.metadata,
            links: links,
            linktitle: linkTitle,
            detaildescription: detaildescription,
            pagetitle: pageTitle,
            // contacttitle: contactTitle,
            //contact: contact,
            bgimageBase64Format: selectedBGFile?.content,
            bgimageFileName: selectedBGFile?.fileName,
            bgimageMetaForBase64: selectedBGFile?.metadata,
            pageinfo: pageInfo,
          })
          .then((value: ISaveToolboxItemResponse) => {
            setLoading(false);
            if (value?.status?.toLowerCase() === 'updated') {
              notificationApi.sendNotification({
                message: `Tool - ${value?.data?.title} is updated successfully`,
                disapperAfterMs: 2500,
                severity: 'success',
                callback: () => {
                  navigate('/admin/toolbox');
                },
              });
            }
          })
          .catch(err => {
            setLoading(false);
            notificationApi.sendNotification({
              message: `Error occurred - ${err?.message}`,
              disapperAfterMs: 2500,
              severity: 'error',
            });
          });
      }
    }
  };

  const onTitleTextChange = (event: any) => {
    let newValue = sanitizeText(event?.target?.value);
    setTitle(newValue);
    setTitleError(undefined);
  };
  const onContactTextChange = (event: any) => {
    let newValue = sanitizeText(event?.target?.value);
    setContact(newValue);
  };

  const onLinkTitleTextChange = (event: any) => {
    let newValue = sanitizeText(event?.target?.value);
    setLinkTitle(newValue);
  };
  const onPageTitleTextChange = (event: any) => {
    let newValue = sanitizeText(event?.target?.value);
    setPageTitle(newValue);
  };
  const onContactTitleTextChange = (event: any) => {
    let newValue = sanitizeText(event?.target?.value);
    setContactTitle(newValue);
  };

  const onDescriptionTextChange = (event: any) => {
    let newValue = sanitizeText(event?.target?.value);
    setDescription(newValue);
    setDescriptionError(undefined);
  };

  const onBGFileSelectChange = (event: any) => {
    const files = event.target?.files;
    if (
      files !== undefined &&
      files.length > 0 &&
      validImageFormat(event?.target?.files[0].type)
    ) {
      let file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = ev => {
        const result = ev.target?.result;
        if (
          result !== undefined &&
          String(result).length > 0 &&
          String(result).indexOf('base64') >= 0
        ) {
          const fileContent = String(result).substring(
            String(result).indexOf('base64,') + 7,
          );
          setSelectedBGFile({
            fileName: '' + file.name,
            type: '' + file.type,
            content: '' + fileContent,
            metadata: String(result).substring(
              0,
              String(result).indexOf('base64,') + 7,
            ),
          });
        }
      };
    }
  };

  const onFileSelectChange = (event: any) => {
    const files = event.target?.files;
    if (
      files !== undefined &&
      files.length > 0 &&
      validImageFormat(event?.target?.files[0].type)
    ) {
      let file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = ev => {
        const result = ev.target?.result;
        if (
          result !== undefined &&
          String(result).length > 0 &&
          String(result).indexOf('base64') >= 0
        ) {
          const fileContent = String(result).substring(
            String(result).indexOf('base64,') + 7,
          );
          setSelectedFile({
            fileName: '' + file.name,
            type: '' + file.type,
            content: '' + fileContent,
            metadata: String(result).substring(
              0,
              String(result).indexOf('base64,') + 7,
            ),
          });
        }
      };
    }
  };

  const onPnidChange = (event: any, selectedItem: any) => {
    if (selectedItem && selectedItem?.id !== undefined) {
      setPnid(`${selectedItem?.id}`);
      setFirstLevelCategory(selectedItem?.firstLevelCategory);
      setSecondaLevelCategory(selectedItem?.secondLevelCategory);
      setPnidError('');
    }
  };

  const handleEditorChange = (value: any) => {
    let newValue = sanitizeText(value);
    setDetaildescription(newValue);
    setDetailDescriptionError(undefined);
    if (byteSize(value) > POST_MAX_SIZE) {
      setDetailDescriptionError('File size should not exceeed 5MB');
    } else {
      setDetailDescriptionError('');
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <BackButton />
          <HelpComponent helpUrl={HELP_URL.ADMIN_TOOLBOX_ADD_EDIT_TOOL} />
        </Grid>

        {loading && <LinearProgress />}

        <Grid item xs={12}>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={10}>
                <Typography variant="h5">Add/Edit Tool</Typography>
                <Typography variant="caption">
                  Note: Please do not select an image and a link for a Category
                  or subcategory. Input these values only for leaf node tool.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Paper>
                <Box sx={{ p: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box>
                        <TextField
                          variant="outlined"
                          fullWidth
                          label="Title*"
                          data-testid="title"
                          value={title}
                          onChange={onTitleTextChange}
                          error={Boolean(titleError)}
                          helperText={Boolean(titleError) && titleError}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ mt: 1 }}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          multiline
                          data-testid="description"
                          minRows={4}
                          label="Description*"
                          value={description ?? ''}
                          onChange={onDescriptionTextChange}
                          error={Boolean(descriptionError)}
                          helperText={
                            Boolean(descriptionError) && descriptionError
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <ParentCategoryDropdown
                          data-testid="parent-category-comp"
                          onChange={onPnidChange}
                          error={pnidError}
                          value={pnid}
                        />
                      </Box>
                    </Grid>
                    {/* <Grid item xs={12}>
                      <Box>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Link"
                          data-testid="link"
                          value={link ?? ''}
                          onChange={() => {
                            // @ts-ignore
                            setLink(limitText(event?.target?.value ?? '', 250));
                            setLinkError(undefined);
                          }}
                          error={Boolean(linkError)}
                          helperText={Boolean(linkError) && linkError}
                          required
                        />
                      </Box>
                    </Grid> */}
                    <Grid item xs={12}>
                      <SupportLinksFormField
                        classes={classes}
                        setLinks={setLinks}
                        links={links}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        {selectedFile && selectedFile.fileName && (
                          <Box sx={{ display: 'flex' }}>
                            <Box sx={{ flex: 1 }}>
                              <Typography variant="h6">
                                Selected Image : {selectedFile.fileName}
                              </Typography>
                            </Box>
                            <Box>
                              <Button
                                data-testid="remove-selected-image"
                                onClick={() => setSelectedFile(undefined)}
                              >
                                Remove
                              </Button>
                            </Box>
                          </Box>
                        )}
                        {selectedFile === undefined && (
                          <TextField
                            fullWidth
                            type="file"
                            data-testid="upload-image"
                            label="Upload Image"
                            onChange={onFileSelectChange}
                          />
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <ToolboxDefaultImageSelector
                          data-testid="image-selector"
                          setSelectedFile={setSelectedFile}
                          selectedFile={selectedFile}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      {/* <Grid item xs={12}>
                        <Box>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Link Title"
                            data-testid="linktitle"
                            value={linkTitle}
                            onChange={onLinkTitleTextChange}
                            required                            
                          />
                        </Box>
                      </Grid> */}
                      <Box className={classes.infoHeading}>
                        Information Section
                      </Box>
                      <Grid item xs={12}>
                        <Box>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Page Title"
                            data-testid="pagetitle"
                            value={pageTitle}
                            onChange={onPageTitleTextChange}
                          />
                        </Box>
                      </Grid>
                      <PageInfoFormField
                        classes={classes}
                        inititalValue={setPageInfo}
                        links={pageInfo}
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                    <Box className={classes.infoHeading}>Contact Details</Box>
                      <Box>
                        <TextField
                          variant="outlined"
                          fullWidth
                          label="Contact Title"
                          data-testid="contacttitle"
                          value={contactTitle}
                          onChange={onContactTitleTextChange}
                        />
                      </Box>
                    </Grid> 
                     <Grid item xs={12}>
                      <Box>
                        <TextField
                          variant="outlined"
                          fullWidth
                          label="Contact"
                          data-testid="contact"
                          value={contact}
                          onChange={onContactTextChange}
                        />
                      </Box>
                    </Grid>  */}
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box className={classes.infoHeading}>Content Section</Box>
                      <Box>
                        {selectedBGFile && selectedBGFile.fileName && (
                          <Box sx={{ display: 'flex' }}>
                            <Box sx={{ flex: 1 }}>
                              <Typography variant="h6">
                                Selected Image : {selectedBGFile?.fileName}
                              </Typography>
                            </Box>
                            <Box>
                              <Button
                                data-testid="remove-selected-image"
                                onClick={() => setSelectedBGFile(undefined)}
                              >
                                Remove
                              </Button>
                            </Box>
                          </Box>
                        )}
                        {selectedBGFile === undefined && (
                          <TextField
                            required
                            fullWidth
                            type="file"
                            data-testid="upload-image"
                            label=""
                            onChange={onBGFileSelectChange}
                            helperText={'Upload Background Image'}
                          />
                        )}
                      </Box>

                      {/* <Box>
                        {selectedFile && selectedFile.fileName && (
                          <Box sx={{ display: 'flex' }}>
                            <Box sx={{ flex: 1 }}>
                              <Typography variant="h6">
                                Selected Image : {selectedFile.fileName}
                              </Typography>
                            </Box>
                            <Box>
                              <Button
                                data-testid="remove-selected-image"
                                onClick={() => setSelectedFile(undefined)}
                              >
                                Remove
                              </Button>
                            </Box>
                          </Box>
                        )}
                        {selectedFile === undefined && (
                          <TextField
                            fullWidth
                            type="file"
                            data-testid="upload-image"
                            label="Upload Image"
                            onChange={onFileSelectChange}
                          />
                        )}
                      </Box> */}
                    </Grid>

                    <Grid item xs={12}>
                      <Box className={classes.description}>
                        Detail Description
                      </Box>
                      <Box className={classes.descContent}>
                        Please explain the product/service details with
                        screenshot, images and video embeds
                      </Box>
                      <RichEditor
                        className="error"
                        onChange={handleEditorChange}
                        onError={setDetailDescriptionError}
                        error={Boolean(detailDescriptionError)}
                        helperText={
                          Boolean(detailDescriptionError) &&
                          detailDescriptionError
                        }
                        data={detaildescription}
                      />
                      {detailDescriptionError && (
                        <FormHelperText error className={classes.descError}>
                          {detailDescriptionError}
                        </FormHelperText>
                      )}
                    </Grid>

                    {/* <Grid item xs={12}>
                      <PageInfoFormField
                        classes={classes}
                        setLinks={setPageInfo}
                        links={pageInfo}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <TextField
                          variant="outlined"
                          fullWidth
                          label="Contact Title"
                          data-testid="contacttitle"
                          value={contactTitle}
                          onChange={onContactTitleTextChange}
                        />
                      </Box>
                    </Grid> */}
                    {/* <Grid item xs={12}>
                      <Box>
                        <TextField
                          variant="outlined"
                          fullWidth
                          label="Contact"
                          data-testid="contact"
                          value={contact}
                          onChange={onContactTextChange}
                        />
                      </Box>
                    </Grid> */}
                    {/* <Grid item xs={12}>
                      <Box>
                        {selectedBGFile && selectedBGFile.fileName && (
                          <Box sx={{ display: 'flex' }}>
                            <Box sx={{ flex: 1 }}>
                              <Typography variant="h6">
                                Selected Image : {selectedBGFile?.fileName}
                              </Typography>
                            </Box>
                            <Box>
                              <Button
                                data-testid="remove-selected-image"
                                onClick={() => setSelectedBGFile(undefined)}
                              >
                                Remove
                              </Button>
                            </Box>
                          </Box>
                        )}
                        {selectedBGFile === undefined && (
                          <TextField
                           required
                            fullWidth
                            type="file"
                            data-testid="upload-image"
                            label="Upload Background Image"
                            onChange={onBGFileSelectChange}
                          />
                        )}
                      </Box>
                    </Grid> */}
                    <Grid item xs={12}>
                      <Box sx={{ mt: 1, mb: 1 }}>
                        <Button
                          data-testid="submit-btn"
                          role="submit"
                          type="button"
                          title="submit"
                          variant="contained"
                          startIcon={<SaveIcon />}
                          color="primary"
                          onClick={onClickSaveBtn}
                        >
                          Save
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ p: 2 }}>
                <Box>
                  <Typography>Live Preview</Typography>
                </Box>
                <Box sx={{ m: 2 }}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      <ToolCard
                        nodeId="0"
                        userEmail=""
                        categoryName={firstLevelCategory ?? ''}
                        title={title}
                        subCategoryName={secondLevelCategory ?? ''}
                        link={undefined}
                        isPreview
                        imgBase64Content={
                          selectedFile
                            ? `${selectedFile?.metadata}${selectedFile?.content}`
                            : undefined
                        }
                      ></ToolCard>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default AddEditTool;
