/* eslint-disable no-nested-ternary */
import React, { useState, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import {
  REVIEWER_tAB,
  SUCCESS_MESSAGE_FOR_ADMIN,
  ADMIN_TAB,
  ADD_ADMIN,
  ADD_REVIEWER,
  ERROR_MESSAGE,
  ERROR_MESSAGE_FOR_USER_LIST,
  EXCLUSION_TAB,
  ADD_EXCLUSION,
} from '../utils/constant';
import {
  Grid,
  Paper,
  CircularProgress,
  Button,
  makeStyles,
} from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import { adminApiRef } from '../../apis/adminApi';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';

const useStyles = makeStyles({
  manageReviewersBtn: {
    ['@media (max-width:920px) and (min-width: 280px)']: {
      width: '100%',
    },
  },
});

interface payload {
  createdDate?: Date;
  isActive: string;
  kindType?: string;
  profileImageUrl?: string;
  user: string;
}

const debounce = (fn: any, delay: any) => {
  let timeoutId: any;
  // @ts-expect-error
  return function (...args) {
    clearTimeout(timeoutId);
    // @ts-expect-error
    timeoutId = setTimeout(() => fn.apply(this, args), delay);
  };
};

export interface AddAdminReviewerProps {
  setAdminAdded?: (params: any) => void;
  isAdminAdded?: boolean | undefined;
  tabId?: any;
  clickGoBack?: () => void;
  kind?: string;
  userEmail?: string;
  userListData?: any;
  callback?: any;
}

export default function AddAdminReviewer(props: AddAdminReviewerProps) {
  const {
    setAdminAdded,
    isAdminAdded,
    tabId,
    clickGoBack,
    kind,
    userEmail,
    userListData,
    callback,
  } = props;

  const [addAdminList, setAddAdminList] = useState<any>('');
  const [userList, setuserList] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [loading, setloading] = useState(false);
  const classes = useStyles();
  let ERROR_Text = ERROR_MESSAGE;

  const adminApi = useApi(adminApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const getuserList = async (user: any) => {
    try {
      setloading(true);

      const data = await adminApi.getUserList(user);
      const options = data?.value || [];
      const transFormList =
        options &&
        options.length &&
        options.map((item: any) => {
          return { user: item?.mail };
        });

      setuserList(transFormList || []);
      setloading(false);
      return data;
    } catch (error) {
      ERROR_Text = ERROR_MESSAGE_FOR_USER_LIST;
      setuserList([]);
      setloading(false);

      return error;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCallback = useCallback(
    debounce((value: any) => {
      getuserList(value);
    }, 1000),
    [],
  );

  const onInputChange = async (e: any) => {
    if (e?.target?.value?.length > 2) {
      debounceCallback(e.target.value);
    }
  };

  const onChange = (e: any, value: any) => {
    e?.preventDefault();
    setAddAdminList(value);
  };

  const clickOnAddButton = async () => {
    const today = new Date();
    const payload: Array<payload> = addAdminList.map((item: any) => {
      return {
        createdDate: today,
        isActive: 'Y',
        kindType: tabId === REVIEWER_tAB ? kind : '',
        profileImageUrl: '',
        user: item.user?.toLowerCase(),
      };
    });
    const payloadForExclusionList: Array<payload> = addAdminList.map(
      (item: any) => {
        return {
          email: item.user?.toLowerCase(),
        };
      },
    );

    setAddAdminList('');

    try {
      let data = undefined;

      if (tabId === REVIEWER_tAB) {
        data = await adminApi.addReviewer(payload);
      } else if (tabId === EXCLUSION_TAB) {
        data = await adminApi.addExclusionList(payloadForExclusionList);
      } else {
        data = await adminApi.addAdmin(payload);
      }

      setAdminAdded?.(!isAdminAdded);

      notificationApi.sendNotification({
        message: SUCCESS_MESSAGE_FOR_ADMIN,
        disapperAfterMs: 2500,
        severity: 'success',
        callback: callback,
      });

      return data;
    } catch (error) {
      setAdminAdded?.(!isAdminAdded);

      notificationApi.sendNotification({
        message: ERROR_Text,
        disapperAfterMs: 2500,
        severity: 'error',
      });
    }
  };

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={12} md={6}>
        {tabId === ADMIN_TAB || tabId === EXCLUSION_TAB ? (
          ''
        ) : (
          <Button
            color="primary"
            variant="contained"
            onClick={clickGoBack}
            startIcon={<ArrowBackIcon />}
            className={`goBackButton ${classes.manageReviewersBtn}`}
          >
            Go Back to Manage Reviewers
          </Button>
        )}
      </Grid>
      <Grid item xs={12} md={5}>
        <Paper>
          <Autocomplete
            multiple
            freeSolo
            id="cbre-user-list"
            data-testid="cbre-user-list"
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            key={`${isAdminAdded}`}
            getOptionDisabled={option =>
              !!userListData.find(
                (item: any) =>
                  option?.user?.toLowerCase() === item?.user?.toLowerCase() ||
                  option?.user?.toLowerCase() === item?.email?.toLowerCase(),
              ) ||
              option?.user?.toLowerCase() === userEmail ||
              addAdminList.length > 4
            }
            getOptionSelected={(option, value) => option?.user === value?.user}
            getOptionLabel={option => (option && option?.user) || 'user'}
            options={userList}
            loading={loading}
            onInputChange={e => onInputChange(e)}
            filterOptions={options => options}
            onChange={onChange}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                data-testid="cbre-user-list-input"
                label={
                  tabId === ADMIN_TAB
                    ? ADD_ADMIN
                    : tabId === EXCLUSION_TAB
                    ? ADD_EXCLUSION
                    : ADD_REVIEWER
                }
                InputProps={{
                  ...params.InputProps,
                  onKeyDown: e => {
                    if (e.key === 'Enter') {
                      e.stopPropagation();
                    }
                  },
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={1}>
        <Button
          color="primary"
          variant="contained"
          role="button"
          data-testid="add-btn"
          onClick={clickOnAddButton}
          className="addButton"
          disabled={addAdminList && addAdminList.length ? false : true}
        >
          Add
        </Button>
      </Grid>
    </Grid>
  );
}
