import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import MockType from '../MockType';
import MockCard from './MockCard';
import Constants from '../Constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    feedbackContainer: {
      paddingTop: '1%',
    },
    feedbackTypeContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      marginTop: '10px',
      ['@media (max-width:920px) and (min-width: 280px)']: {
        marginBottom: '6px ',
      },
    },
    feedbackTypes: {
      ['@media (max-width:920px) and (min-width: 280px)']: {
        paddingTop: '8px',
        flexBasis: '33%',
      },
    },
    flexContainer: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '60%',
      },
      ['@media (max-width:920px) and (min-width: 280px)']: {
        flexWrap: 'wrap',
        justifyContent: 'left',
        marginLeft: '3%',
      },
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '450px',
    },
  }),
);

const MockGrid = ({
  content,
  isAdmin,
}: {
  content: any;
  isAdmin: boolean | null;
}) => {
  const classes = useStyles();
  const types = Object.keys(Constants.requestTypes);

  return (
    <>
      <Grid container className={classes.feedbackContainer} alignItems="center">
        <div className={classes.feedbackTypeContainer}>
          <div className={classes.flexContainer}>
            {types.map((type: string, idx: any) => (
              <Grid
                key={`feedback-type-${type}${idx}`}
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.feedbackTypes}
              >
                <MockType type={type} key={`feedback-type-${type}${idx}`} />{' '}
              </Grid>
            ))}
          </div>
        </div>
        {content &&
          content.map((mock: any, idx: string) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              key={`feedback-card-${idx}`}
            >
              <MockCard mock={mock} isAdmin={isAdmin} />
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default MockGrid;
