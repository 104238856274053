export type Transformer = (dom: Element) => Element | Promise<Element>;

export const transform = async (
  html: string | Element,
  transformers: Transformer[],
): Promise<Element> => {
  let dom: Element;

  if (typeof html === 'string') {
    dom = new DOMParser().parseFromString(html, 'text/html').documentElement;
  } else if (html instanceof Element) {
    dom = html;
  } else {
    throw new Error('dom is not a recognized type');
  }

  for (const transformer of transformers) {
    dom = await transformer(dom);
  }

  return dom;
};
