import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import UserAvatar from '../../common/UserAvatar';
import { timeSince } from '../../utils/dateUtils';
import { useStyles } from '../styles';
import { TabContext, TabPanel } from '@material-ui/lab';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { CopyTextButton } from '@backstage/core-components';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import ShareIcon from '@material-ui/icons/Share';
import { useStarredEntity } from '@backstage/plugin-catalog-react';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { useApi } from '@backstage/core-plugin-api';

interface PopupProps {
  open: boolean;
  onClose: () => void;
  onConfirm: Function;
  popupContent: any;
}

const PromptDetailsPopup = ({
  open,
  onClose,
  onConfirm,
  popupContent,
}: PopupProps) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const { toggleStarredEntity, isStarredEntity } = useStarredEntity(
    `PromtStore:${popupContent.promptData.id}`,
  );

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const handleShareClick = () => {
    const urlToShare = `${window.location.origin}${window.location.pathname}?prompt=${popupContent.promptData.id}`;
    navigator.clipboard.writeText(urlToShare);
    notificationApi.sendNotification({
      message: 'Prompt link copied to clipboard',
      disapperAfterMs: 2500,
      severity: 'success',
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.promptDetailBox }}
    >
      <DialogTitle>
        <div>
          <span className={classes.promptKey}>
            {popupContent.promptData.promptkey}
          </span>
        </div>
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.flexSpacebetween}>
          <div className={classes.userDetail}>
            <UserAvatar userEmail={popupContent.promptData.authoremail} />
            <div>
              <span className={classes.authorName}>
                {popupContent.promptData.authordisplayname}
              </span>
              <div className={classes.userDetailSection}>
                <span className={classes.userDetailSubSection}>
                  {timeSince(
                    DateTime.fromFormat(
                      popupContent.promptData.createdat,
                      'dd-MM-yyyy HH:mm:ss',
                      { zone: 'utc' },
                    )
                      .toLocal()
                      .toFormat('MM-dd-yyyy HH:mm:ss'),
                  )}
                </span>
                <span>{popupContent.uses} uses</span>
              </div>
            </div>
          </div>
          <div className={classes.iconButtonContainer}>
            <IconButton
              onClick={toggleStarredEntity}
              className={`${classes.iconButton}`}
            >
              {isStarredEntity ? <BookmarkIcon /> : <BookmarkBorderIcon />}
            </IconButton>
            <IconButton
              onClick={handleShareClick}
              className={`${classes.iconButton}`}
            >
              <ShareIcon />
            </IconButton>
          </div>
        </div>
        <Tabs
          className={classes.tabSection}
          value={value}
          indicatorColor="primary"
          // textColor="primary"
          onChange={handleChange}
          aria-label="promptDetailTabs"
        >
          <Tab label="Details" value={0} />
          {/* <Tab label="Python" value={1} /> */}
        </Tabs>
        {value === 0 ? (
          <TabContext value="0">
            <TabPanel value="0" classes={{ root: classes.tabPanel }}>
              <div className={classes.promptDetail}>
                {popupContent.promptData.systemprompt !== '' && (
                  <div className={classes.systemPromptSection}>
                    <div className={classes.copyPromptDetail}>
                      <Typography className={classes.systemPromptHeader}>
                        <InfoOutlinedIcon fontSize="small" />
                        System prompt
                      </Typography>
                      <CopyTextButton
                        text={popupContent.promptData.systemprompt}
                        tooltipText="Copied!"
                      />
                    </div>
                    <Typography className={classes.systemPromptContent}>
                      {popupContent.promptData.systemprompt}
                    </Typography>
                  </div>
                )}
                <div className={classes.copyPromptDetail}>
                  <Typography className={classes.promptDetailHeader}>
                    User prompt
                  </Typography>
                  <CopyTextButton
                    text={popupContent.promptData.promptdetail}
                    tooltipText="Copied!"
                  />
                </div>
                {popupContent.promptData.promptdetail}
              </div>
            </TabPanel>
          </TabContext>
        ) : (
          <TabContext value="1">
            <TabPanel value="1">
              <SyntaxHighlighter
                language="python"
                style={atomDark}
                showLineNumbers={true}
                wrapLines={true}
              >
                {`path.lineTo(0, 50); \npath.lineTo(0, size.height - 50); \npath.quadraticBezierTo( \n  size.width / 2, \n  size.height, \n  size.width, \n  size.height - 50 \n); \npath.lineTo(size.width, 50); \npath.quadraticBezierTo(size.width / 2, 0, 0, 50);`}
              </SyntaxHighlighter>
            </TabPanel>
          </TabContext>
        )}
        {popupContent.promptData.tags.length !== 0 && (
          <div className={classes.tagSectionInPopup}>
            {popupContent.promptData.tags.map((label: any) => (
              <Chip label={label} />
            ))}
          </div>
        )}
      </DialogContent>
      <DialogActions className={classes.footerSection}>
        <Button
          color="primary"
          onClick={() => onClose()}
          data-testid="no-btn"
          variant="outlined"
        >
          Close
        </Button>
        <Button
          color="primary"
          onClick={() => onConfirm()}
          data-testid="no-btn"
          variant="contained"
          autoFocus
        >
          Try now
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PromptDetailsPopup;
