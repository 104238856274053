import { FieldValidation } from '@rjsf/core';
import { ApiHolder } from '@backstage/core-plugin-api';
import { scmIntegrationsApiRef } from '@backstage/integration-react';
import { swTemplateGithubApiRef } from './api/SWTemplateGithubApi';
import { swTemplateCustomApiRef } from '../../../../apis/swTemplateCustomApi';

/**
 * The validation function for the `repoUrl` that is returned from the
 * field extension. Ensures that you have all the required fields filled for
 * the different providers that exist.
 *
 * @public
 */
export const repoPickerValidation = async (
  value: string,
  validation: FieldValidation,
  context: { apiHolder: ApiHolder },
) => {
  try {
    const { host, searchParams } = new URL(`https://${value}`);

    if (
      !host ||
      host == 'undefined' ||
      (host && host?.toLowerCase() === 'select')
    ) {
      validation.addError('Target Host is not selected');
      return;
    }

    const integrationApi = context.apiHolder.get(scmIntegrationsApiRef);
    const githubApi = context.apiHolder.get(swTemplateGithubApiRef);
    const adoApi = context.apiHolder.get(swTemplateCustomApiRef);

    if (!host) {
      validation.addError(
        'Incomplete repository location provided, host not provided',
      );
    } else {
      if (integrationApi?.byHost(host)?.type === 'bitbucket') {
        // workspace is only applicable for bitbucket cloud
        if (host === 'bitbucket.org' && !searchParams.get('workspace')) {
          validation.addError(
            'Incomplete repository location provided, workspace not provided',
          );
        }

        if (!searchParams.get('project')) {
          validation.addError(
            'Incomplete repository location provided, project not provided',
          );
        }
      }
      // For anything other than bitbucket
      else {
        if (!searchParams.get('owner')) {
          validation.addError(
            'Please select the any one value from target drop down list',
          );
        }
      }

      // Do this for all hosts
      if (!searchParams.get('repo')) {
        validation.addError('Please provide The repo or project name');
      } else {
        if (
          integrationApi?.byHost(host)?.type === 'github' &&
          `${searchParams.get('repo')}`.length > 100
        ) {
          validation.addError('Repo name must be maximum 100 characters long');
        } else if (
          integrationApi?.byHost(host)?.type === 'azure' &&
          `${searchParams.get('repo')}`.length > 64
        ) {
          validation.addError('Repo name must be maximum 64 characters long');
        }
        if (integrationApi?.byHost(host)?.type === 'github') {
          try {
            const res = await githubApi?.getRepositoryInfo(
              '' + searchParams.get('owner'),
              '' + searchParams.get('repo'),
            );

            if (
              res &&
              res?.data &&
              res?.data?.message === 'exists' &&
              res?.data?.status === 200 &&
              (!searchParams.get('branch') ||
                String(searchParams.get('branch')).trim()?.length === 0)
            ) {
              validation.addError('Branch name is required');
            }
          } catch (e) {
            console.log('error = ', e);
          }
        } else if (integrationApi?.byHost(host)?.type === 'azure') {
          try {
            const res = await adoApi?.getRepositoryInfo(
              '' + searchParams.get('organization'),
              '' + searchParams.get('owner'),
              '' + searchParams.get('repo'),
            );

            if (
              res &&
              res?.data &&
              res?.data?.message === 'exists' &&
              res?.data?.status === 200 &&
              (!searchParams.get('branch') ||
                String(searchParams.get('branch')).trim()?.length === 0)
            ) {
              validation.addError('Branch name is required');
            }
          } catch (e) {
            console.log('error = ', e);
          }
        }

        if (
          searchParams.get('branch') &&
          String(searchParams.get('branch')).trim()?.length > 64
        ) {
          validation.addError('Branch name should be of maximum 64 characters');
        }
      }
    }
  } catch {
    validation.addError('Unable to parse the Repository URL');
  }
};
