import React from 'react';

const Grandmaster = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.11048 11.3684C5.47891 13.579 7.72101 14.3053 8.6368 14.5263C9.89996 14.8421 11.7947 12.3158 11.7947 12.3158L17.7947 12.9474L13.0579 16.7369C12.1105 18 9.26838 20.5263 10.2157 25.8947L9.89996 27.4737H9.26838L8.95259 30H24.7421L24.4263 27.4737H23.7947L23.4789 25.8947H22.5315C22.5315 25.8947 21.5842 23.6842 22.8473 20.5263C24.1105 17.3684 25.0579 13.8948 24.7421 11.3684C24.4263 8.84212 20.9526 4.42107 17.7947 4.42107C14.6368 4.42107 12.4263 4.73686 11.4789 6.00001C10.5315 7.26317 6.11048 11.3684 6.11048 11.3684Z"
        fill="#666666"
      />
      <path
        d="M10.2156 25.8947H23.4788L23.7946 27.4737H9.89983L10.2156 25.8947ZM18.7419 16.4211C17.163 20.2105 17.0998 23.0211 16.8472 24C16.5314 25.2632 13.5946 25.8632 14.0051 23.6842C14.9525 18.6316 18.1104 17.0526 18.7419 16.4211ZM11.7946 24C11.7946 24.6316 11.163 25.2632 10.2156 24.9474C9.26825 24.6316 10.5314 21.7895 11.7946 19.8947C13.0577 18 16.5314 15.4737 18.4261 14.8421C15.8998 17.0526 13.3735 18.9474 11.7946 24ZM6.11035 11.6842C6.11035 10.4211 6.11035 10.4211 8.63667 8.52632C10.0577 7.45263 10.8472 6.94737 11.163 6C11.163 5.05263 13.9104 4.23158 15.2682 3.78947L15.584 0C15.584 0 17.7946 1.89474 17.7946 2.84211V3.78947C17.7946 3.78947 23.163 4.42105 23.7946 11.3684C23.7946 11.3684 21.584 6 18.1104 5.68421C15.8998 5.49474 15.2682 6.94737 14.0051 7.26316C12.7419 7.57895 14.6051 5.90526 13.3735 5.68421C11.4788 5.36842 10.8156 6.69474 11.7946 6.94737C13.0577 7.26316 12.7419 7.89474 12.1104 7.89474C10.8156 7.89474 10.8472 8.52632 10.2156 9.47368C9.58404 10.4211 7.05772 11.6842 6.11035 11.6842Z"
        fill="#CCCCCC"
      />
      <path
        d="M8.95277 13.8947C10.5317 12.6316 11.4791 11.3684 12.4265 11.6842C13.7528 12.1263 17.7949 12.3158 18.4265 11.3684C19.6896 9.47368 19.6896 9.4421 19.6896 11.6842C19.6896 13.8947 19.058 13.8947 16.5317 14.8421C14.0054 15.7895 13.058 16.7368 13.058 16.7368C13.3738 15.1579 15.5843 14.2105 16.2159 13.5789C16.8475 12.9474 14.3212 13.2632 12.7422 12.6316C11.258 12.0316 10.5317 14.5263 9.58435 14.5263C8.28961 14.5263 8.95277 13.8947 8.95277 13.8947ZM17.7949 2.8421C17.7949 2.8421 23.4791 2.8421 24.7422 11.3684L24.1107 12.3158C24.1107 12.3158 23.4791 4.73684 17.7949 3.78947V2.8421ZM14.3212 4.10526L15.2686 3.78947V2.8421H13.6896L14.3212 4.10526ZM11.4791 6.31579C12.4265 6.94737 13.058 6.31579 13.058 6.31579C12.4265 5.36842 11.4791 6.31579 11.4791 6.31579Z"
        fill="#222222"
      />
    </svg>
  );
};

export default Grandmaster;
