import React from 'react';
import {
  Button,
  Checkbox,
  MenuItem,
  Select,
  TextField,
  makeStyles,
  Grid,
} from '@material-ui/core';
import { FormFieldDataProps } from './FormBuilder';
const useStyles = makeStyles({
  taxanomyFormFields: {
    marginTop: '20px',
    padding: '10px',
    background: 'white',
    OverflowX: 'hidden',
    '&:first-child': {
      marginTop: '0px',
    },
  },
  checkbox: {
    ['@media (max-width:450px) and (min-width: 280px)']: {
      width: '63%',
    },
  },
  taxanomyBtns: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addbelowBtn: {
    marginRight: '10px',
  },
});
export interface FormFieldProps {
  data: FormFieldDataProps;
  formBuilderFields: FormFieldDataProps[];
  onDataChange: (newData: FormFieldDataProps, index: number) => void;
  index: number;
  onClickAddBelow: (index: number) => void;
  onClickDelete: (index: number) => void;
}

const FORM_FIELD_TYPES = [
  'string',
  'number',
  'entitySuggest',
  'userSuggest',
  'textarea',
  'assetPicker',
];

const FormField = ({
  data,
  formBuilderFields,
  onDataChange,
  index,
  onClickAddBelow,
  onClickDelete,
}: FormFieldProps) => {
  const classes = useStyles();
  const validateFormField = (value: any) => {
    let result = { error: false, helperText: '' };
    if (!value) {
      return { error: true, helperText: 'field value is required' };
    }
    let exists = false;
    if (formBuilderFields && formBuilderFields?.length > 0) {
      for (let i = 0; i < formBuilderFields?.length; i += 1) {
        let data = formBuilderFields[i];
        if (i == index) {
          continue;
        }
        if (`${data?.field}`.toLowerCase() === `${value}`.toLowerCase()) {
          exists = true;
          break;
        }
      }
    }
    if (exists) {
      return {
        error: true,
        helperText: 'field already exists. Please provide unique field name',
      };
    }
    return result;
  };

  return (
    <Grid container className={classes.taxanomyFormFields}>
      <Grid item xs={12} sm={12}>
        <TextField
          label="Title *"
          disabled={data?.fixed}
          value={data?.title}
          fullWidth
          onChange={(event: any) => {
            const newData = { ...data, title: event?.target?.value };
            if (onDataChange) {
              onDataChange(newData, index);
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          label="Field *"
          disabled={data?.fixed}
          value={data?.field}
          fullWidth
          error={data?.error}
          helperText={data?.helperText}
          onChange={(event: any) => {
            let newValue = event?.target?.value || '';
            newValue = newValue?.replace(/[^a-zA-Z_$0-9]/g, '');
            newValue = newValue?.replace(/^[^a-zA-Z_$][^0-9a-zA-Z_$]*$/, '');
            let validationResult = validateFormField(newValue);

            const newData = { ...data, field: newValue, ...validationResult };
            if (onDataChange) {
              onDataChange(newData, index);
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Select
          label="Type *"
          fullWidth
          disabled={data?.fixed}
          value={data?.type}
          onChange={(event: any, option: string) => {
            event?.stopPropagation();
            // @ts-ignore
            const newValue = option?.props?.value || '';
            const newData = { ...data, type: newValue };
            if (onDataChange) {
              onDataChange(newData, index);
            }
          }}
        >
          {FORM_FIELD_TYPES?.map((ft: any) => (
            <MenuItem key={ft} value={ft}>
              {ft}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid className={classes.checkbox}>
        <Checkbox
          checked={data?.required}
          disabled={data?.fixed}
          onChange={(event: any, checked: any) => {
            event?.stopPropagation();
            const newData = { ...data, required: checked };
            if (onDataChange) {
              onDataChange(newData, index);
            }
          }}
        />
        Required to have a value?
        <Checkbox
          checked={!data?.canRemove}
          disabled={data?.fixed}
          onChange={(event: any, checked: any) => {
            event?.stopPropagation();
            const newData = { ...data, canRemove: !checked };
            if (onDataChange) {
              onDataChange(newData, index);
            }
          }}
        />
        Can Not be removed?
      </Grid>
      <Grid item xs={12} sm={12} className={classes.taxanomyBtns}>
        <Button
          variant="contained"
          color="default"
          className={classes.addbelowBtn}
          onClick={() => onClickAddBelow(index)}
        >
          Add Below
        </Button>
        <Button
          variant="contained"
          color="default"
          disabled={!data?.fixed ? !data?.canRemove : true}
          onClick={() => onClickDelete(index)}
        >
          Delete
        </Button>
      </Grid>
    </Grid>
  );
};
export default FormField;
