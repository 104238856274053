import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  FormHelperText,
  createStyles,
  Theme
} from '@material-ui/core';
import RichEditor from '../../common/RichEditor';
import CloseIcon from '@material-ui/icons/Close';
import { byteSize } from '../../utils/Utils';
import { POST_MAX_SIZE } from '../../utils/constant';
import {
  REPHRASE_AI_TITLE,
} from '../../utils/constant';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  dialogBox: {
    position: 'relative',
  },
  dialogContent: {
    width: '500px',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  toolName: {
    fontWeight: 'bold',
  },
  otherText: {
    marginTop: '10px'
  },
  descError: {
    marginLeft: '14px',
    marginRight: '14px',
    color: theme.palette.errorText,
    fontSize: ' 0.75rem',
    marginTop: '14px',
  },
}));

export interface CancelAIProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (comment: string) => void;
  initialValue: string;
}

const RephraseAIDialog = ({
  isOpen,
  onClose,
  onConfirm,
  initialValue
}: CancelAIProps) => {
  const classes = useStyles();
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState('');

  useEffect(() => {
    setDescription(initialValue);
  },[]);

  const handleEditorChange = (value: any) => {
    setDescription(value);
    if (
      value === undefined ||
      value === '<p><br></p>' ||
      (value !== undefined && String(value).trim()?.length === 0)
    ) {
      setDescriptionError('Description is required');
    } else if (byteSize(value) > POST_MAX_SIZE) {
      setDescriptionError('File size should not exceeed 5MB');
    } else {
      setDescriptionError('');
    }
  };

  const onConfirmClick = () => {
    if (description && description.length > 0 && descriptionError === '') {
      onConfirm(description);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      className={classes.dialogBox}
      fullWidth={false}
    >
      <DialogTitle id="dialog-title">
        {REPHRASE_AI_TITLE}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <RichEditor
          className="error"
          onChange={handleEditorChange}
          onError={setDescriptionError}
          error={Boolean(descriptionError)}
          helperText={Boolean(descriptionError) && descriptionError}
          data={description}
        />
        {descriptionError && (
          <FormHelperText error className={classes.descError}>
            {descriptionError}
          </FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          data-testid="cancel-btn-ai"
          variant="contained"
          autoFocus
        >
          Cancel
        </Button>
        <Button
          onClick={onConfirmClick}
          data-testid="submit-btn-ai"
          color="primary"
          variant="contained"
          disabled={descriptionError}
        >
          Regenerate AI Response
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default RephraseAIDialog;
