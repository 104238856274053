import { useEffect, useState } from 'react';

export const useFilteredEntries = (
  data: any,
  category: string,
  legends: any,
  searchText: string,
) => {
  const [filteredEntries, setFilteredEntries] = useState();

  useEffect(() => {
    let localEntries;

    if (category === 'All categories' && data?.length > 0) {
      localEntries = [...data];
    } else {
      localEntries = data?.filter((entry: any) => entry.category === category);
    }
    const legendsFilter = legends
      ?.filter((legend: any) => legend.selected)
      .map((legend: any) => legend.name);

    if (legendsFilter?.length > 0) {
      localEntries = localEntries?.filter((entry: any) =>
        legendsFilter.includes(entry.stage),
      );
    } else {
      localEntries = [];
    }

    function matchFilter(filter?: string): (entry: any) => boolean {
      const terms = filter
        ?.toLocaleLowerCase('en-US')
        .split(/\s/)
        .map(e => e.trim())
        .filter(Boolean);

      if (!terms?.length) {
        return () => true;
      }

      return entry => {
        const text = `${entry.title} ${
          entry?.description || ''
        }`.toLocaleLowerCase('en-US');
        return terms.every(term => text.includes(term));
      };
    }
    localEntries = localEntries?.filter(matchFilter(searchText));
    setFilteredEntries(localEntries);
  }, [data, category, legends, searchText]);

  return { filteredEntries };
};
