import React, { useEffect, useState } from 'react';
import {
  useEntity,
  getEntitySourceLocation,
} from '@backstage/plugin-catalog-react';
import { scmIntegrationsApiRef } from '@backstage/integration-react';
import {
  HeaderIconLinkRow,
  IconLinkVerticalProps,
} from '@backstage/core-components';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import LanguageIcon from '@material-ui/icons/Language';
import { useApi } from '@backstage/core-plugin-api';
import {
  Card,
  CardHeader,
  CardContent,
  makeStyles,
  createStyles,
  Typography,
  Button,
  Grid,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Box,
  Theme,
  Select,
  MenuItem,
  InputLabel,
  // LinearProgress,
} from '@material-ui/core';
import { isEmpty, capitalize } from 'lodash';
import { createBrowserHistory } from 'history';
import { useGetConversionApi } from '../../utils/useGetConversionApi';
import { usePostConversionApi } from '../../utils/usePostConversionApi';
import GitHubIcon from '@material-ui/icons/GitHub';
import AzureIcon from '../../Root/icons/azureIcon';
import { adminApiRef } from '../../../apis/adminApi';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import {
  COMPONENT_TYPE,
  COMPONENT,
  IS_ADD_Reviewer_IN_ASSETS,
} from '../../utils/constant';
import { Autocomplete } from '@material-ui/lab';
import { AddReviewerComponent } from '../../common/AddReviewerComponent/AddReviewerComponent';
import { useGetReviwerListHook } from '../../common/AddReviewerComponent/useGetReviwerListHook';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItemCard: {
      //display: 'flex',
      //flexDirection: 'row',
      marginBottom: '10px',
      '& nav': {
        gridAutoColumns: 'max-content !important',
      },
    },
    gridItemCardContent: {
      //flex: 1,
    },
    label: {
      textTransform: 'uppercase',
      fontSize: '12px',
      lineHeight: 1.5,
      fontWeight: 600,
      letterSpacing: 0.5,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      fontFamily: 'Calibre-R-Regular',
      color: theme.palette.text.tertiary,
    },
    textBottomlabel: {
      fontSize: '10px',
      lineHeight: 1.5,
      fontWeight: 600,
      letterSpacing: 0.5,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      fontFamily: 'Calibre-R-Regular',
      color: theme.palette.text.tertiary,
      display: 'flex',
    },
    textBottomRight: {
      marginLeft: 'auto',
      marginRight: 0,
    },
    errorMsgText: {
      fontFamily: 'Calibre-R-Regular',
      fontSize: '10px',
      lineHeight: 1.5,
      fontWeight: 600,
      color: 'red',
    },
    radio: {
      '&$checked': {
        color: '#003f2d',
      },
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: '#fff',
      border: '2px solid #000',
      boxShadow: '4px 0px 10px rgba(0, 0, 0, 0.2)',
      padding: '16px 32px 24px',
      minHeight: 200,
    },
    checked: {},
    statusStyle: {
      fontWeight: 'bold',
    },
    complexityContainer: {
      marginTop: '15px',
    },
    submitBtnContainer: {
      paddingTop: '15px',
      paddingBottom: '20px',
    },
    submitBtn: {
      marginRight: '4px',
    },
  }),
);

type ReviewProps = {
  userId: string;
};

export function Review({ userId }: ReviewProps) {
  const classes = useStyles();
  const [status, setStatus] = React.useState<String | ''>('');
  const [complexity, setComplexity] = React.useState<String | ''>('');
  const [reviewerComment, setReviewerComment] = React.useState('');
  const [buttonDisabled, setIsButtonDisabled] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const { entity } = useEntity();
  const { reviewerList } = useGetReviwerListHook();
  const scmIntegrationsApi = useApi(scmIntegrationsApiRef);
  const entitySourceLocation = getEntitySourceLocation(
    entity,
    scmIntegrationsApi,
  );
  const { localEntity } = useGetConversionApi(
    entity.metadata.name,
    entity.kind,
  );

  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const { postResp, saveEntity } = usePostConversionApi(
    entity.metadata.name,
    entity.kind,
    localEntity,
    true,
  );
  // const [open, setOpen] = React.useState<Boolean | false>(false);
  const adminApi = useApi(adminApiRef);
  const customLinks =
    entity.metadata?.customlinks?.length && entity.metadata?.customlinks;

  // Componenent Type
  const [componentType, setComponentType] = useState('package');

  useEffect(() => {
    if (
      entity &&
      Object.keys(entity).length > 0 &&
      localEntity &&
      Object.keys(localEntity).length > 0
    ) {
      setLoading(true);
      adminApi
        .getComplexityCatalogInfoByAssetName(entity?.metadata?.name)
        .then((data: any) => {
          setLoading(false);
          if (data?.complexity) {
            setComplexity(`${data?.complexity}`.trim());
          } else {
            setComplexity('');
          }
        })
        .catch((error: any) => {
          setLoading(false);
          notificationApi.sendNotification({
            message: `Error occurred - ${error?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });
    }
  }, [localEntity]);

  const viewInSource: IconLinkVerticalProps = {
    label: 'View Source',
    disabled: !entitySourceLocation,
    icon:
      entitySourceLocation?.integrationType?.toLowerCase() === 'github' ? (
        <GitHubIcon />
      ) : entitySourceLocation?.integrationType?.toLowerCase() === 'azure' ? (
        <AzureIcon />
      ) : null,
    href: entitySourceLocation?.locationTargetUrl,
  };

  const customLinksSource: any = () => {
    const links = customLinks.map((link: any) => {
      return {
        label: link.title,
        icon: <LanguageIcon />,
        href: link.url,
      };
    });

    return links;
  };

  const handleReviewComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val = event.target.value;
    if (val.length < 501) {
      setReviewerComment(val);
      setErrorMsg('');
    }
  };

  const handleStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus((event.target as HTMLInputElement).value);
  };

  const handleComplexity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComplexity((event.target as HTMLInputElement).value);
  };

  const validateReviewData = () => {
    let isValidate = false;
    if (status !== '' && reviewerComment.trim().length > 0) {
      if (/^[a-zA-Z0-9.,?\s]*$/g.test(reviewerComment.trim())) {
        isValidate = true;
      } else {
        isValidate = false;
        setErrorMsg('Invalid character found in Comments');
      }
    }
    return isValidate;
  };

  const handleSubmit = async () => {
    if (validateReviewData()) {
      setIsButtonDisabled(true);
      localEntity.metadata.comments.push({
        user: userId,
        date: new Date(),
        comments: reviewerComment,
        commentedBy: 'Reviewer',
      });
      localEntity.metadata.devxstate = status;
      localEntity.spec.type = componentType;

      setLoading(true);
      await saveEntity()
        .then(async data => {
          if (data && data?.status === 'SUCCESS' && status === 'Approved') {
            await adminApi
              .updateAssetComplexityInCatalogInfo({
                assetname: localEntity?.metadata?.name,
                complexity,
                reason: '',
              })
              .catch((error: any) => {
                notificationApi.sendNotification({
                  message: `Error occurred - ${error?.message}`,
                  disapperAfterMs: 2500,
                  severity: 'error',
                });
              });
          }
          setLoading(false);
          notificationApi.sendNotification({
            message: `Success.. Changes Saved! Changes may take upto 5 min to reflect.`,
            disapperAfterMs: 2500,
            severity: 'success',
            callback: goToPreviousPath,
          });
        })
        .catch((error: any) => {
          setLoading(false);
          notificationApi.sendNotification({
            message: `Error occurred - ${error?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });

      setErrorMsg('');
      // setOpen(true);
    }
  };

  let history = createBrowserHistory();
  const goToPreviousPath = () => {
    history.back();
  };

  // const ApiStatusPopUpProps = {
  //   open: open,
  //   getStatus: postResp.status,
  //   setOpen: setOpen,
  //   isPending: isEmpty(postResp.status),
  //   classes: classes,
  //   setIsButtonDisabled: setIsButtonDisabled,
  // };

  const componentTypes = COMPONENT_TYPE.map((type: string) => ({
    value: type,
    label: capitalize(type),
  }));

  const componentTypeVisible =
    localEntity?.spec?.type && localEntity?.kind?.toLowerCase() === COMPONENT;

  useEffect(() => {
    setComponentType(localEntity?.spec?.type);
  }, [componentTypeVisible]);

  function handleChange(event: any) {
    setComponentType(event.target.value);
  }

  return (
    <Grid container>
      <Grid item xs={12} lg={6}>
        <Card className={classes.gridItemCard}>
          <CardHeader title="Review" />
          {/* <Grid item md={12} xs={12}>
        <BackButton />
        <HelpComponent helpUrl={HELP_URL.ReviewComponentPageREVIEW} />
      </Grid> */}
          <Divider />
          {/* {loading && <LinearProgress />} */}
          <CardContent className={classes.gridItemCardContent}>
            <Grid container spacing={3} alignItems="stretch" direction="column">
              {entity.kind.toLowerCase() === 'api' ? (
                ''
              ) : (
                <Grid item md={8} xs={12}>
                  <HeaderIconLinkRow
                    links={customLinks ? customLinksSource() : [viewInSource]}
                  />
                </Grid>
              )}
              <Grid item md={8} xs={12}>
                <Typography variant="subtitle2" className={classes.label}>
                  Reviewer Comments
                </Typography>
                <TextField
                  placeholder="Please fill in important details you notice while reviewing."
                  multiline
                  fullWidth
                  variant="outlined"
                  minRows={4}
                  value={reviewerComment}
                  onChange={handleReviewComment}
                />
                <div className={classes.textBottomlabel}>
                  <div>Maximum of 500 characters</div>
                  <div className={classes.textBottomRight}>
                    {reviewerComment.length > 0
                      ? `${reviewerComment.length}/500`
                      : null}
                  </div>
                </div>
              </Grid>
              {/* Grid with Dropdrown for component type - visible only for components */}
              {!loading && componentTypeVisible ? (
                <Grid item md={8} xs={12}>
                  <Typography variant="subtitle2" className={classes.label}>
                    Component Type
                  </Typography>

                  <FormControl fullWidth variant="outlined">
                    <Select
                      displayEmpty
                      value={componentType}
                      onChange={handleChange}
                    >
                      {componentTypes?.map((typeObj: any) => {
                        return (
                          <MenuItem value={typeObj.value} key={typeObj.value}>
                            {typeObj.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}
              {/* End of Component Types */}

              <Grid item md={8} xs={12}>
                <Box
                  component="div"
                  sx={{ display: 'flex', flexDirection: 'column' }}
                >
                  <FormControl component="fieldset">
                    <Typography component="p" className={classes.statusStyle}>
                      Status
                    </Typography>
                    <RadioGroup
                      aria-label="Approval Status"
                      name="approvalStatusRadios"
                      value={status}
                      onChange={handleStatus}
                    >
                      <FormControlLabel
                        value="Approved"
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="Approved"
                      />
                      <FormControlLabel
                        value="Not Approved"
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="Not Approved"
                      />
                      <FormControlLabel
                        value="Resubmit"
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="Resubmit"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl
                    component="fieldset"
                    className={classes.complexityContainer}
                    disabled={status !== 'Approved'}
                  >
                    <Typography component="p" className={classes.statusStyle}>
                      Complexity
                    </Typography>
                    <RadioGroup
                      aria-label="complexity"
                      name="complexity"
                      value={complexity}
                      onChange={handleComplexity}
                    >
                      <FormControlLabel
                        value="low"
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="Low"
                      />
                      <FormControlLabel
                        value="medium"
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="Medium"
                      />
                      <FormControlLabel
                        value="high"
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="High"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                {!isEmpty(errorMsg) ? (
                  <div className={classes.errorMsgText}>{errorMsg}</div>
                ) : null}
                <div className={classes.submitBtnContainer}>
                  {buttonDisabled ? (
                    <Button
                      variant="contained"
                      size="medium"
                      color="default"
                      onClick={handleSubmit}
                      role="button"
                      className={classes.submitBtn}
                      disabled
                    >
                      SUBMIT
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size="medium"
                      color={
                        status === '' || reviewerComment.trim().length <= 0
                          ? 'primary'
                          : 'default'
                      }
                      onClick={handleSubmit}
                      role="button"
                      className={classes.submitBtn}
                      disabled={
                        status === '' || reviewerComment.trim().length <= 0
                      }
                    >
                      SUBMIT
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    size="medium"
                    color="primary"
                    onClick={goToPreviousPath}
                  >
                    CANCEL
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {IS_ADD_Reviewer_IN_ASSETS && (
        <Grid item xs={12} lg={6}>
          <Card className={classes.gridItemCard}>
            <CardHeader title="Assigned To" />
            <Divider />
            <CardContent className={classes.gridItemCardContent}>
              <AddReviewerComponent
                entity={entity}
                reviewerList={reviewerList || []}
              />
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
}
