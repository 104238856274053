import React, { useState, useEffect, useContext } from 'react';
import { Content, PageWithHeader, Progress } from '@backstage/core-components';
import { makeStyles } from '@material-ui/core';
import { Grid, LinearProgress } from '@material-ui/core';
import { AboutCard } from './AboutCard';

import { SpecsCard } from './SpecsCard';
import { useAsync } from 'react-use';
import { useMatch, useParams } from 'react-router-dom';

import { ButtonSaveClose } from './ButtonSaveClose';

import { HelpComponent } from '../utils/helpComponent';
import HELP_URL from '../utils/helpLinkConstant';
import BackButton from '../utils/backButton';

import { AuthContext } from '../../providers/AuthProvider';
import { PromotionContext } from '../../providers/PromotionDataProvider';
import { useApi } from '@backstage/core-plugin-api';
import { entityConversionApiRef } from '../../apis/entityConversionApi';
import { notificationApiRef } from '../../apis/notificationApi';
import { DEFAULT_NAMESPACE } from '@backstage/catalog-model';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';
import { capitalizeFirstLetter } from '../home/allAssets/util';

const useStyles = makeStyles({
  AboutCard: {
    // background: '#fff',

    minHeight: 400,
  },
  Check: {
    fontSize: '40px',
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fff',
    border: '2px solid #000',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%)',
    padding: '16px 32px 24px',
    minHeight: 200,
  },
  BreadcrumbStyle: {
    padding: '10px',
    paddingLeft: '20px',
  },
});

export const EntityEditPage = props => {
  const { baseUrl } = props;

  const onclickHelpButton = useContext(PromotionContext);
  useEffect(() => {
    onclickHelpButton.setToggleHelpButton(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [defaultTags, setTags] = useState([]);
  const [defaultTechdocs, setTechdocs] = useState('');
  const [defaultLinks, setLinks] = useState([]);
  const [lifCycleSelected, setLifCycleSelected] = useState('');
  const [typeSelected, setTypeSelected] = useState('');
  const [downloadLink, setDownloadLink] = useState('');
  const [isPending, setIsPending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [getStatus, setGetStatus] = useState();
  const [isButtonDisable, setIsButtonDisabled] = useState(true);
  const [defaultEntityDescription, setDefaultEntityDescription] = useState('');
  const [supportEmail, setSupportEmail] = useState();

  const [checkedTechdocs, setCheckedTechdocs] = useState(false);

  const { name, kind } = useParams();

  const { profile, profEmail, isAdmin } = useContext(AuthContext);
  const ownerName = profEmail?.split('@')[0];

  const [defaultEntity, setDefaultEntity] = useState();
  const entityConversionApi = useApi(entityConversionApiRef);
  const notificationApi = useApi(notificationApiRef);

  const { value, error } = useAsync(async () => {
    if (name && kind) {
      setIsLoading(true);
      const response = await entityConversionApi.getData(name, kind);

      const data = await response?.data;
      setDefaultEntity(data);
      setDefaultEntityDescription(
        data?.metadata?.description || 'No Description',
      );
      setTags(data?.metadata?.tags || []);
      setLinks(data?.metadata?.links || []);
      setTechdocs(data?.metadata.annotations['backstage.io/techdocs-ref']);
      setSupportEmail(data?.spec?.supportEmail || '');

      if (
        data?.metadata.annotations['backstage.io/techdocs-ref'] !== '' &&
        data?.metadata.annotations['backstage.io/techdocs-ref'] !== undefined
      ) {
        setCheckedTechdocs(true);
      }

      setTypeSelected(data?.spec.type);
      setLifCycleSelected(data?.spec.lifecycle);
      setDownloadLink(data?.spec.downloadLink);

      return data;
    }
    return null;
  }, [kind, name]);

  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const ButtonSaveCloseProps = {
    isPending: isPending,
    defaultEntity: defaultEntity,
    defaultLinks: defaultLinks,
    defaultTags: defaultTags,
    defaultTechdocs: defaultTechdocs,
    lifCycleSelected: lifCycleSelected,
    typeSelected: typeSelected,
    downloadLink: downloadLink,
    isButtonDisable: isButtonDisable,
    baseUrl: baseUrl,
    description: defaultEntityDescription,
    setDefaultEntity: setDefaultEntity,
    setOpen: setOpen,
    setIsPending: setIsPending,
    isLoading: isLoading,
    setGetStatus: setGetStatus,
    setIsButtonDisabled: setIsButtonDisabled,
    name: name,
    kind: kind,
    supportEmail,
  };

  const mountedOnCodeLibraryPath = useMatch(
    '/code-library/:namespace/:kind/:name/edit',
  );
  const mountedOnCatalogPath = useMatch('/catalog/:namespace/:kind/:name/edit');

  if (isAdmin === null) {
    return <Progress />;
  }

  if (
    defaultEntity &&
    defaultEntity?.spec?.owner?.replace('user:', '').toLowerCase() !==
      ownerName?.toLowerCase() &&
    isAdmin !== null &&
    !isAdmin
  ) {
    window.location.replace('/');
  }

  let routes = [
    {
      type: 'link',
      link: '/',
      text: 'Home',
    },
  ];
  if (
    mountedOnCodeLibraryPath !== undefined &&
    mountedOnCodeLibraryPath?.pathname !== undefined
  ) {
    routes = [
      ...routes,
      {
        type: 'link',
        link: '/code-library',
        text: 'Components',
      },
      {
        type: 'link',
        link: `/code-library/${DEFAULT_NAMESPACE}/${kind}/${name}`,
        text: capitalizeFirstLetter(name),
      },
      {
        type: 'text',
        link: '',
        text: `Edit - ${capitalizeFirstLetter(name)}`,
      },
    ];
  } else if (
    mountedOnCatalogPath !== undefined &&
    mountedOnCatalogPath?.pathname !== undefined
  ) {
    routes = [
      ...routes,
      {
        type: 'link',
        link: '/catalog',
        text: 'Catalog',
      },
      {
        type: 'link',
        link: `/catalog/${DEFAULT_NAMESPACE}/${kind}/${name}`,
        text: capitalizeFirstLetter(name),
      },
      {
        type: 'text',
        link: '',
        text: `Edit - ${capitalizeFirstLetter(name)}`,
      },
    ];
  }

  if (
    defaultEntity &&
    (defaultEntity?.spec?.owner?.replace('user:', '').toLowerCase() ===
      ownerName?.toLowerCase() ||
      isAdmin)
  ) {
    return (
      <PageWithHeader
        title={`Edit - ${name}`}
        themeId="home"
        subtitle={<DevxBreadCrumb routes={routes} />}
      >
        <Content>
          <Grid container direction="row">
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <BackButton />
              <HelpComponent helpUrl={HELP_URL.EDIT_Code_Library} />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              {!value && !error && <LinearProgress />}
              <AboutCard
                defaultEntityName={defaultEntity?.metadata?.name}
                defaultEntityDescription={defaultEntityDescription}
                setDefaultEntityDescription={setDefaultEntityDescription}
                defaultLinks={defaultLinks}
                setLinks={setLinks}
                className={classes.AboutCard}
                defaultTags={defaultTags}
                setTags={setTags}
                defaultTechdocs={defaultTechdocs}
                setTechdocs={setTechdocs}
                setIsButtonDisabled={setIsButtonDisabled}
                checkedTechdocs={checkedTechdocs}
                setCheckedTechdocs={setCheckedTechdocs}
                defaultEntity={defaultEntity}
                supportEmail={supportEmail}
                setSupportEmail={setSupportEmail}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {!value && !error && <LinearProgress />}
              <SpecsCard
                defaultEntityOwner={defaultEntity?.spec?.owner}
                lifCycleSelected={lifCycleSelected}
                setLifCycleSelected={setLifCycleSelected}
                setTypeSelected={setTypeSelected}
                typeSelected={typeSelected}
                className={classes.AboutCard}
                setIsButtonDisabled={setIsButtonDisabled}
                type={defaultEntity?.spec?.type}
                downloadLink={downloadLink}
                setDownloadLink={setDownloadLink}
              />
            </Grid>
          </Grid>

          <ButtonSaveClose {...ButtonSaveCloseProps} />
        </Content>
      </PageWithHeader>
    );
  }
  return <Progress />;
};
