import React from 'react';
import { EntityPicker } from '../EntityPicker/EntityPicker';
import { customTypes } from '../../extensions';

/**
 * The input props that can be specified under `ui:options` for the
 * `OwnerPicker` field extension.
 *
 * @public
 */
export interface OwnerPickerUiOptions {
  allowedKinds?: string[];
}

/**
 * The underlying component that is rendered in the form for the `OwnerPicker`
 * field extension.
 *
 * @public
 */
export const OwnerPicker = (
  props: customTypes.FieldExtensionComponentProps<string, OwnerPickerUiOptions>,
) => {
  const {
    schema: { title = 'Owner', description = 'The owner of the component' },
    uiSchema,
    ...restProps
  } = props;

  const ownerUiSchema = {
    ...uiSchema,
    'ui:options': {
      allowedKinds: (uiSchema['ui:options']?.allowedKinds || [
        'Group',
        'User',
      ]) as string[],
      defaultKind: 'Group',
    },
  };

  return (
    <EntityPicker
      {...restProps}
      schema={{ title, description }}
      uiSchema={ownerUiSchema}
    />
  );
};
