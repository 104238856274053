import React from 'react';
import { makeStyles, Avatar } from '@material-ui/core';
import { useUserProfile } from '../useUserProfileInfo';
import { sidebarConfig } from '@backstage/core-components';

const useStyles = makeStyles<{ size: number }>(theme => ({
  avatar: {
    width: ({ size }) => size,
    height: ({ size }) => size,
    fontSize: ({ size }) => size * 0.7,
    border: `1px solid ${theme.palette.textSubtle}`,
  },
}));

type Props = { size?: number };

export const SignInAvatar = ({ size }: Props) => {
  const { iconSize } = sidebarConfig;
  const classes = useStyles(size ? { size } : { size: iconSize });
  const { profile } = useUserProfile();

  return <Avatar src={profile?.picture} className={classes.avatar} />;
};
