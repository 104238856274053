import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { useCustomStyles } from '../manageCategories/CardStyles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { AdminApi, adminApiRef } from '../../../../apis/adminApi';
import { useApi } from '@backstage/core-plugin-api';

export interface ManageItemCardProps {
  id?: string;
  title?: string;
  description?: string;
  url?: string;
  category_name?: string;
  category_id?: string;
  imageurl?: string;
  imageFileName?: string;
  imageBase64Format?: string;
  onClickDeleteButton?: (params: any) => void;
  onClickEditButton?: (params: any) => void;
}

export const ManageItemCard = (props: ManageItemCardProps) => {
  // console.log(props, 'props');
  const { onClickDeleteButton, onClickEditButton } = props;
  const classes = useCustomStyles();
  const [image, setImage] = useState<any>('');
  const adminApi: AdminApi = useApi(adminApiRef);

  /* adminApi.getImagebyItemId(id) an dediated API to fetch only images by Item Id */

  const metaMap = {
    png: 'data:image/png;base64,',
    svg: 'data:image/svg+xml;base64,',
    jpg: 'data:image/jpeg;base64,',
    jpeg: 'data:image/jpeg;base64,',
  };

  const getDefaultMetadata = () => {
    const imageurl = props?.imageurl;
    if (imageurl !== undefined) {
      const ext = String(imageurl).substring(
        String(imageurl)?.lastIndexOf('.') + 1,
      );
      // @ts-ignore
      return metaMap[ext];
    }
    return 'data:image/svg+xml;base64,';
  };

  useEffect(() => {
    if (props.id) {
      setImage(`${getDefaultMetadata()}${props.imageBase64Format}`);
    }
  }, [props]);

  useEffect(() => {}, [image]);

  return (
    <div className={classes.CardEntityContainer}>
      <Grid container>
        <Grid item md={3} sm={4} xs={6} className={classes.column}>
          <Typography className={classes.CardTypeFontBold} component="div">
            <b className={classes.boldText}>{props.title}</b>
          </Typography>
          <Typography className={classes.CardTypeFontBold} component="div">
            <span className={classes.Description}>{props.description}</span>
          </Typography>
        </Grid>

        <Grid
          item
          md={6}
          sm={4}
          xs={6}
          className={` ${classes.row} ${classes.center}`}
        >
          <Typography className={classes.CardTypeFontBold}>
            Category
            <b className={classes.boldText}>{props.category_name}</b>
          </Typography>
        </Grid>

        <Grid
          item
          md={2}
          sm={4}
          xs={6}
          className={` ${classes.row} ${classes.center}`}
        >
          <Typography className={classes.CardTypeFontBold}>
            {image ? (
              <img src={image} alt="" className={classes.ItemImage} />
            ) : (
              <img
                src="/images/placeholderimage.png"
                alt=""
                className={classes.ItemImage}
              />
            )}
          </Typography>
        </Grid>

        <Grid item md={1} sm={2} xs={3} className={classes.CardButtonContainer}>
          <Button onClick={onClickEditButton}>
            <EditIcon />
          </Button>
          <Button onClick={onClickDeleteButton}>
            <DeleteIcon />
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
