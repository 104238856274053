import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { useNavigate } from 'react-router';
import BackButton from '../../utils/backButton';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import { PageWithHeader, Content } from '@backstage/core-components';
import BreadcrumbsNav from '../../common/BreadcrumbsNav/BreadcrumbsNav';
import { useStyles } from '../styles';
import Add from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { uid } from '../utils';

export interface AdditionalDetailsProps {
  id: string;
  type: 'text' | 'link' | 'contact';
  title?: string;
  link?: string;
  email?: string;
  url?: string;
  content?: string;
}

const EditDetailsPage = () => {
  const classes = useStyles();
  const [items, setItems] = useState<AdditionalDetailsProps[]>([]);
  let [updatedItems, setUpdatedItems] = useState<AdditionalDetailsProps[]>([
    ...items,
  ]);

  const handleOptionChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    updatedItems = [...items];
    updatedItems[index].type = event.target.value as
      | 'text'
      | 'link'
      | 'contact';
    setUpdatedItems(updatedItems);
  };

  const handleAddItem = () => {
    setItems([...items, { id: uid(), type: 'text', title: '', content: '' }]);
  };

  const handleDeleteItem = (index: number) => {
    updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  const navigate = useNavigate();
  const onClickCancle = () => {
    navigate('../');
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <BackButton />
        <HelpComponent helpUrl={HELP_URL} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper className={classes.editPage}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography
                className={`${classes.boldText} ${classes.headerText}`}
              >
                Project Details
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.flexSpacebetween}>
              <Grid item xs={8} md={8}>
                <TextField
                  required
                  id="outlined-required"
                  label="Project Title"
                  defaultValue=""
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={3} md={3}>
                <TextField
                  required
                  id="outlined-required"
                  label="Project Catalog"
                  defaultValue=""
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                fullWidth
                required
                size="medium"
                label="Project Description"
                variant="outlined"
                minRows={3}
                maxRows={3}
                multiline
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.flexSpacebetween}
            >
              <Grid>
                <Typography
                  className={`${classes.boldText} ${classes.bodyText}`}
                >
                  Additional Details
                </Typography>
                <Typography>
                  Contact Person, Manager and other details
                </Typography>
              </Grid>
              <Button
                endIcon={<Add />}
                variant="outlined"
                onClick={handleAddItem}
              >
                Add Item
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {items.map((_item, index) => (
                <Box className={classes.editPaper} key={index}>
                  <Grid className={classes.flexSpacebetween}>
                    <Grid item xs={1} md={1}>
                      <TextField
                        label="Type"
                        value={_item.type}
                        variant="outlined"
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        onChange={event => handleOptionChange(event, index)}
                      >
                        <option value="text">Text</option>
                        <option value="link">Link</option>
                        <option value="contact">Contact</option>
                      </TextField>
                    </Grid>
                    {_item.type === 'text' && (
                      <>
                        <Grid item xs={2} md={2}>
                          <TextField
                            label="Title"
                            defaultValue=""
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={8} md={8}>
                          <TextField
                            label="Content"
                            defaultValue=""
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                      </>
                    )}
                    {_item.type === 'link' && (
                      <>
                        <Grid item xs={2} md={2}>
                          <TextField
                            label="Title"
                            defaultValue=""
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={2} md={2}>
                          <TextField
                            label="Link Text"
                            defaultValue=""
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <TextField
                            label="URL"
                            defaultValue=""
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                      </>
                    )}
                    {_item.type === 'contact' && (
                      <>
                        <Grid item xs={2} md={2}>
                          <TextField
                            label="Title"
                            defaultValue=""
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={8} md={8}>
                          <TextField
                            label="Email"
                            defaultValue=""
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                      </>
                    )}
                    <Button onClick={() => handleDeleteItem(index)}>
                      <DeleteIcon />
                    </Button>
                  </Grid>
                </Box>
              ))}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box className={classes.buttonGroup}>
                <Grid>
                  <Button variant="outlined" onClick={onClickCancle}>
                    Cancel
                  </Button>
                </Grid>
                <Grid>
                  <Button
                    className={classes.saveButton}
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EditDetailsPage;
