import React from 'react';
import { Link } from './Link';

export const Links = (props: {
  setLinks: React.Dispatch<
    React.SetStateAction<
      {
        url: string;
        title: string;
        icon: string;
      }[]
    >
  >;
  defaultLinks: {
    url: string;
    title: string;
    icon: string;
  }[];
  setIsButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { defaultLinks, setLinks, setIsButtonDisabled } = props;

  return (
    <>
      {(defaultLinks || []).map((item, index) => (
        <Link
          defaultlink={defaultLinks}
          key={index}
          url={item.url}
          title={item.title}
          icon={item.icon}
          setLink={setLinks}
          index={index}
          setIsButtonDisabled={setIsButtonDisabled}
        />
      ))}
    </>
  );
};
