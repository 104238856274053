import React from 'react';
import {
  Grid,
  Typography,
  Button,
  FormControlLabel,
  Switch,
  FormGroup,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useNavigate } from 'react-router';
import { useStyles } from '../common/styles';

export const PageListCard = (props: any) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { onEditClick, onToggleClicked, onDeleteClicked, pageData } = props;

  const navigateToStepsListPage = (pageId: string) => {
    navigate(`page/${pageId}`);
  };
  return (
    <div className={classes.CardEntityContainer}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={3}
          className={`${classes.row} ${classes.center}`}
        >
          <FormGroup className={classes.pageToggleSwitch}>
            <FormControlLabel
              control={
                <Switch
                  checked={pageData.isActive}
                  onClick={() =>
                    onToggleClicked({
                      ...pageData,
                      isActive: !pageData.isActive,
                    })
                  }
                />
              }
              label="Active"
            />
          </FormGroup>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          className={`${classes.row} ${classes.center}`}
        >
          <Typography className={classes.CardTypeFontBold}>
            Route <b className={classes.boldText}>{pageData.pageRoute}</b>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={4}
          className={`${classes.row} ${classes.center}`}
        >
          <Typography className={classes.CardTypeFontBold}>
            Duplicates
            <b className={classes.boldText}>{pageData?.duplicates || '-'}</b>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={2}
          className={classes.CardButtonContainer}
        >
          <Button onClick={() => onEditClick(pageData)}>
            <EditIcon />
          </Button>
          <Button onClick={() => onDeleteClicked()}>
            <DeleteIcon />
          </Button>
          <Button onClick={() => navigateToStepsListPage(pageData?.id)}>
            <ArrowForwardIcon />
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
