import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Page, Header, Content } from '@backstage/core-components';
import ListIcon from '@material-ui/icons/List';
import {
  Grid,
  Box,
  Typography,
  LinearProgress,
  Paper,
  IconButton,
  InputBase,
  useMediaQuery,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { TreeView } from '@material-ui/lab';
import { useApi } from '@backstage/core-plugin-api';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SearchIcon from '@material-ui/icons/Search';
import ClearButton from '@material-ui/icons/Clear';
import AssessmentIcon from '@material-ui/icons/Assessment';
import Drawer from '@material-ui/core/Drawer';
import { StyledTreeItem } from './treeItem';
import ToolCard from './toolcard';
import {
  IToolboxItemNode,
  toolboxApiRef,
  ToolboxApi,
  IToolboxItem,
} from '../../apis/toolboxApi';
import BackButton from '../utils/backButton';
import { HelpComponent } from '../utils/helpComponent';
import HELP_URL from '../utils/helpLinkConstant';
import { AuthContext } from '../../providers/AuthProvider';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolboxPage: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    treeView: {
      minWidth: '300px',
      maxWidth: '300px',
      [theme.breakpoints.down('md')]: {
        minWidth: '0',
      },
    },
    gridTool: {
      flex: '0 0 calc(100% * (1/5) - 10px - 1px)',
      maxWidth: 'calc(100% * (1/5) - 10px - 1px)',
      ['@media (max-width:962px)']: {
        flex: '0 0 100%',
        maxWidth: '100%',
      },
      ['@media (max-width:1200px) and (min-width: 963px)']: {
        flex: '0 0 calc(100% * (1/4) - 10px - 1px)',
        maxWidth: 'calc(100% * (1/4) - 10px - 1px)',
      },
    },
    toolboxGrid: {
      paddingBottom: '150px',
    },
    treeViewbox: {
      width: '100%',
      display: 'flex',
      position: 'relative',
      boxSizing: 'border-box',
    },
    treeItem: {
      borderRadius: '4px',
      overflow: 'hidden',
    },
    renderTree: {
      borderRadius: '4px',
      overflow: 'hidden',
    },
    smallScreenBox: {
      flexGrow: 1,
      paddingLeft: '16px',
    },
    flex: {
      display: 'flex',
    },
    toggleDraweBtn: {
      background: 'rgb(223,230,229)',
      borderRadius: '4px',
      border: '1px solid rgb(223,230,229)',
      textAlign: 'center',
      height: '50px',
      marginRight: '8px',
    },
    listIcon: {
      fontSize: '30px',
    },
    drawer: {
      width: '100%',
      background: 'transparent !important',
    },
    treeViewWidth: {
      width: '300px',
    },
    toolsCategories: {
      fontWeight: 'bold',
      marginLeft: '8px',
    },
  }),
);
const CBRE_MOST_VIEWED_NID = 'cbre-most-used';
const CBRE_MOST_VIEWED_TEXT = 'CBRE Most Viewed';
const CBRE_VIEW_ALL_TOOLS_NID = 'cbre-all-tools';
const CBRE_VIEW_ALL_TOOLS_TEXT = 'All Tools & Services';

const Tool = ({
  tool,
  userEmail,
}: {
  tool: IToolboxItem;
  userEmail: string;
}) => {
  if (tool === undefined) {
    return <></>;
  }
  const toolboxApi: ToolboxApi = useApi(toolboxApiRef);
  const [imageData, setImageData] = useState<string | undefined>();
  const [imageMeta, setImageMeta] = useState<string | undefined>(
    tool.imageMetaForBase64,
  );

  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const metaMap = {
    png: 'data:image/png;base64,',
    svg: 'data:image/svg+xml;base64,',
  };

  const getDefaultMetadata = () => {
    let imageKey = tool?.imagekey;
    if (imageKey !== undefined) {
      const ext = String(imageKey).substring(
        String(imageKey)?.lastIndexOf('.') + 1,
      );
      // @ts-ignore
      return metaMap[ext];
    }
    return 'data:image/svg+xml;base64,';
  };

  useEffect(() => {
    if (
      tool !== undefined &&
      tool.imagekey !== undefined &&
      String(tool.imagekey).trim().length > 0
    ) {
      toolboxApi
        .getImageData(tool?.imagekey)
        .then((data: any) => {
          setImageData(data);
        })
        .catch((error: any) => {
          notificationApi.sendNotification({
            severity: 'error',
            disapperAfterMs: 2000,
            message: `Failed to load image for tool ${tool?.title} - ${error.message}`,
          });
        });
      if (tool.imageMetaForBase64 === undefined) {
        setImageMeta(getDefaultMetadata());
      } else {
        setImageMeta(String(tool.imageMetaForBase64).replace(',', '') + ',');
      }
    }
  }, []);

  return (
    <ToolCard
      nodeId={`${tool?.nid}`}
      userEmail={userEmail}
      title={tool.title ?? ''}
      categoryName={tool?.category ?? ''}
      subCategoryName={tool.subcategory ?? ''}
      imgBase64Content={imageData ? `${imageMeta}${imageData}` : ''}
      link={tool.link}
      description={tool?.description}
      links={tool?.links}
      linktitle={tool?.linktitle}
    />
  );
};

const debounce = (fn: any, delay: any) => {
  let timeoutId: any;
  // @ts-expect-error("")
  return function (...args) {
    clearTimeout(timeoutId);
    // @ts-expect-error("")
    timeoutId = setTimeout(() => fn.apply(this, args), delay);
  };
};

const ToolboxContainer = () => {
  const classes = useStyles();
  const [toolsData, setToolsData] = useState<IToolboxItemNode[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedCategoryNid, setselectedCategoryNid] = useState<
    string | undefined
  >(undefined);
  const [toolsToRender, setToolsToRender] = useState<IToolboxItem[]>([]);
  const [expandedIds, setExpandedIds] = useState<string[] | undefined>(['1']);
  const [contentAreaTextHeading, setContentAreaTextHeading] = useState<
    string | undefined
  >(CBRE_MOST_VIEWED_TEXT);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedNode, setSelectedNode] = useState<any>(undefined);
  const [searchText, setSearchText] = useState<string | undefined>();
  const [mostViewedCount, setMostViewedCount] = useState<number>(0);
  const [allToolsCount, setAllToolsCount] = useState<number>(0);
  const { profEmail } = useContext(AuthContext);
  const userEmail = profEmail?.toLowerCase();

  const toolboxApi: ToolboxApi = useApi(toolboxApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const location = useLocation();

  // const theme = useTheme();
  const onlySmallScreen = useMediaQuery('(max-width: 1050px)'); //useMediaQuery(theme.breakpoints.down('sm'));

  const dfs = (
    root: IToolboxItemNode,
    matchId: string,
  ): IToolboxItemNode | undefined => {
    if (root === undefined) {
      return undefined;
    }
    if (String(root.node?.nid) === String(matchId)) {
      return root;
    }
    if (root.children !== undefined && root.children?.length > 0) {
      let result = undefined;
      for (let child of root.children) {
        result = dfs(child, matchId);
        if (result !== undefined) {
          break;
        }
      }
      return result;
    }
    return undefined;
  };

  const getLeafNodes = (item: IToolboxItemNode): any[] => {
    if (item == undefined) {
      return [];
    }
    const arr = [];
    if (
      item?.children === undefined ||
      (item?.children !== undefined && item?.children?.length === 0)
    ) {
      if (
        item.node !== undefined &&
        item.node.link !== undefined &&
        String(item.node?.link).trim().length > 0
      ) {
        return [item.node];
      }
      return [];
    } else {
      for (let child of item.children) {
        arr.push(getLeafNodes(child));
      }
    }
    return arr.flat();
  };

  const getAllToolsFromApi = () => {
    setLoading(true);
    toolboxApi
      .getAllTools()
      .then((value: IToolboxItemNode[] | undefined) => {
        setToolsData(value || []);
        setLoading(false);
      })
      .catch((error: any) => {
        setToolsData([]);
        setLoading(false);
        notificationApi.sendNotification({
          severity: 'error',
          disapperAfterMs: 2000,
          message: `Error occurred while getting all tools and categories - ${error.message}`,
        });
      });
  };

  const getCBREMostViewedLinks = () => {
    setLoading(true);
    setselectedCategoryNid(CBRE_MOST_VIEWED_NID);
    toolboxApi
      .getTopLinks()
      .then((value: IToolboxItem[] | undefined) => {
        setToolsToRender(value || []);
        setMostViewedCount(value?.length || 0);
        setContentAreaTextHeading(CBRE_MOST_VIEWED_TEXT);
        setLoading(false);
      })
      .catch((error: any) => {
        setToolsToRender([]);
        setMostViewedCount(0);
        setLoading(false);
        notificationApi.sendNotification({
          severity: 'error',
          disapperAfterMs: 2000,
          message: `Error occurred while getting most viewed tools - ${error.message}`,
        });
      });
  };

  const getCBREAllToolsFromApi = async () => {
    setLoading(true);
    try {
      const data: IToolboxItem[] | undefined =
        await toolboxApi.getAllToolLinks();
      setAllToolsCount(data?.length || 0);
      setLoading(false);
      return data;
    } catch (error) {
      setAllToolsCount(0);
      setLoading(false);
      notificationApi.sendNotification({
        severity: 'error',
        disapperAfterMs: 2000,
        message: `Error occurred while getting all tools - ${
          error?.message || ''
        }`,
      });
      return [];
    }
  };

  const getCBREAllTools = async () => {
    setselectedCategoryNid(CBRE_VIEW_ALL_TOOLS_NID);
    setContentAreaTextHeading(CBRE_VIEW_ALL_TOOLS_TEXT);
    const toolsToRender: IToolboxItem[] | undefined =
      await getCBREAllToolsFromApi();
    setToolsToRender(toolsToRender || []);
  };

  useEffect(() => {
    if (selectedCategoryNid) {
      if (toolsData !== undefined && toolsData?.length > 0) {
        if (selectedCategoryNid === CBRE_MOST_VIEWED_NID) {
          setSelectedNode(undefined);
          setSearchText('');
          setContentAreaTextHeading(CBRE_MOST_VIEWED_TEXT);
          getCBREMostViewedLinks();
          return;
        }
        if (selectedCategoryNid === CBRE_VIEW_ALL_TOOLS_NID) {
          setSelectedNode(undefined);
          setSearchText('');
          setContentAreaTextHeading(CBRE_VIEW_ALL_TOOLS_TEXT);
          getCBREAllTools();
          return;
        }
        const node = dfs(toolsData?.[0], `${selectedCategoryNid}`);
        setSelectedNode(node);
        setSearchText('');
        setContentAreaTextHeading('Services & Tools under category - ');
        let nodeList = [];
        if (node !== undefined) {
          nodeList = getLeafNodes(node);
        }
        setToolsToRender(nodeList);
      }
    }
  }, [selectedCategoryNid]);

  useEffect(() => {
    getAllToolsFromApi();
    getCBREAllToolsFromApi();
    if (window.location.href) {
      const params = new URLSearchParams(window.location.search);
      const initialQuerySearch = params.get('q');
      if (
        initialQuerySearch !== undefined &&
        initialQuerySearch !== null &&
        String(initialQuerySearch).trim().length > 0
      ) {
        onChangeSearchText({
          target: { value: initialQuerySearch },
        });
      } else {
        getCBREMostViewedLinks();
      }
    }
  }, [location]);

  const countChildren = (item: IToolboxItemNode) => {
    let count = 0;
    if (item !== undefined && item.children != undefined) {
      for (let child of item.children) {
        if (child.children === undefined || child.children.length === 0) {
          // this is leaf node
          if (
            child.node !== undefined &&
            child.node.link !== undefined &&
            String(child.node?.link).trim().length > 0
          ) {
            count += 1;
          }
        } else {
          count += countChildren(child);
        }
      }
    }
    return count;
  };

  const renderTree = (item: IToolboxItemNode): any => {
    if (!item || item.node === undefined) {
      return undefined;
    }
    let count = countChildren(item);
    let hasParent = false;
    if (item?.node?.nid !== 1 && item?.node?.pnid !== 1) {
      hasParent = item?.node?.pnid !== undefined;
    }
    let hasGrandchildren = false;
    if (
      item != undefined &&
      item.children !== undefined &&
      item?.children?.length > 0 &&
      item.children?.[0]?.children !== undefined &&
      item.children?.[0]?.children?.length > 0
    ) {
      hasGrandchildren = true;
    }

    const onClickIcon = (nodeId: any) => {
      if (nodeId === CBRE_MOST_VIEWED_NID) {
        return;
      }
      // @ts-ignore
      setExpandedIds((prev: (string | number)[]) => {
        return [`1`, `${nodeId}`];
      });
    };

    if (item.node.title === 'root') {
      return (
        <>
          {Array.isArray(item.children)
            ? item.children.map(node => {
                if (
                  node.children === undefined ||
                  node.children?.length === 0
                ) {
                  return <></>;
                }
                return renderTree(node);
              })
            : null}
        </>
      );
    }

    return (
      // @ts-ignore
      <StyledTreeItem
        key={item.node.nid}
        nodeId={'' + item.node.nid}
        labelText={item.node.title !== 'root' ? item?.node?.title : 'All'}
        labelInfo={'' + (count || 0)}
        labelIcon={AssessmentIcon}
        hasParent={hasParent}
        hasGrandchildren={hasGrandchildren}
        onIconClick={() => onClickIcon(item.node.nid)}
      >
        {Array.isArray(item.children)
          ? item.children.map(node => {
              if (node.children === undefined || node.children?.length === 0) {
                return <></>;
              }
              return renderTree(node);
            })
          : null}
      </StyledTreeItem>
    );
  };

  // @ts-ignore
  const onNodeToggle = (event: any, nodeIds: array) => {
    if (nodeIds !== undefined && nodeIds.length > 0) {
      let newNodes = [...nodeIds].filter(i => !expandedIds?.includes(i));
      const node = dfs(toolsData?.[0], `${newNodes?.[0]}`);
      const temp = [];
      if (node != undefined) {
        temp.push(`${node.node.nid}`);
        if (node.node.pnid != undefined) {
          temp.push(`${node.node.pnid}`);
        }
        if (node.children != undefined && node.children.length > 0) {
          const children = node.children;
          for (const child of children) {
            if (child.node.link === undefined) {
              temp.push(`${child.node.pnid}`);
            }
          }
        }
      }

      setExpandedIds(['1', ...temp]);
    } else {
      setExpandedIds([...nodeIds]);
    }
  };

  // @ts-ignore
  const onTreeItemClick = (event: any, nodeId: string) => {
    setselectedCategoryNid(`${nodeId}`);

    setIsOpen(false);
  };

  const additionalOpsOnSearchText = (query: string) => {
    setSelectedNode(undefined);
    setselectedCategoryNid(undefined);
    if (query !== undefined && String(query).trim().length === 0) {
      getCBREMostViewedLinks();
      return;
    }
  };

  const getResultsForSearchFromApi = query => {
    toolboxApi
      .searchTools(query)
      .then((value: IToolboxItem[] | undefined) => {
        setToolsToRender(value || []);

        if (value == undefined || value.length === 0) {
          setContentAreaTextHeading('No tool found');
        } else {
          setContentAreaTextHeading(`Search Results`);
        }
        setLoading(false);
      })
      .catch((error: any) => {
        setToolsToRender([]);
        setLoading(false);
        notificationApi.sendNotification({
          severity: 'error',
          disapperAfterMs: 2000,
          message: `Error occurred while searching tools - ${error.message}`,
        });
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCallback = useCallback(
    debounce((value: any) => {
      getResultsForSearchFromApi(value);
    }, 1000),
    [],
  );

  const onChangeSearchText = (event: any) => {
    const query = event?.target?.value;
    setSearchText(query);
    additionalOpsOnSearchText(query);
    if (query !== undefined && String(query).trim().length === 0) {
      return;
    }
    debounceCallback(query);
  };

  const toggleDrawer = (open: any) => (event: any) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setIsOpen(open);
  };

  return (
    <Page themeId="home">
      <Header
        title="Services & tools"
        subtitle={
          <DevxBreadCrumb
            routes={[
              {
                type: 'link',
                link: '/',
                text: 'Home',
              },
              {
                link: '',
                type: 'text',
                text: 'Services & tools',
              },
            ]}
          />
        }
      ></Header>
      <Content>
        <Box>{loading && <LinearProgress />}</Box>
        <Grid container spacing={2} className={classes.toolboxPage}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <BackButton />
            <HelpComponent helpUrl={HELP_URL.TOOLBOX_PAGE} />
          </Grid>

          <Box id="container-for-tools" className={classes.treeViewbox}>
            <Box className={classes.treeView} component="div">
              <Box>
                {!onlySmallScreen && (
                  <TreeView
                    defaultCollapseIcon={<ExpandMoreIcon id="expand-more" />}
                    expanded={expandedIds}
                    // @ts-ignore
                    selected={[selectedCategoryNid]}
                    defaultExpandIcon={<ChevronRightIcon id="chevron-right" />}
                    defaultEndIcon={<></>}
                    onNodeToggle={onNodeToggle}
                    onNodeSelect={onTreeItemClick}
                  >
                    <>
                      <Box className={classes.treeItem}>
                        <StyledTreeItem
                          // @ts-ignore
                          nodeId={CBRE_MOST_VIEWED_NID}
                          labelText={'CBRE Most Viewed'}
                          labelInfo={`${mostViewedCount}`}
                          // @ts-expect-error
                          labelIcon={undefined}
                        ></StyledTreeItem>
                        <StyledTreeItem
                          // @ts-ignore
                          nodeId={CBRE_VIEW_ALL_TOOLS_NID}
                          labelText={'View All'}
                          labelInfo={`${allToolsCount}`}
                          // @ts-expect-error
                          labelIcon={undefined}
                        ></StyledTreeItem>
                      </Box>
                      <Box sx={{ mt: 1, mb: 2 }}>
                        <Typography style={{ fontWeight: 'bold' }}>
                          Categories
                        </Typography>
                      </Box>
                      <Box className={classes.renderTree}>
                        {renderTree(toolsData?.[0])}
                      </Box>
                    </>
                  </TreeView>
                )}
              </Box>
            </Box>
            <Box className={classes.smallScreenBox}>
              <Box>
                <Box className={classes.flex}>
                  {onlySmallScreen && (
                    <>
                      <IconButton
                        color="primary"
                        onClick={toggleDrawer(true)}
                        // edge="start"
                        className={classes.toggleDraweBtn}
                      >
                        <ListIcon className={classes.listIcon} />
                      </IconButton>
                      <Drawer
                        className={classes.drawer}
                        anchor={'left'}
                        open={isOpen}
                        onClose={toggleDrawer(false)}
                      >
                        <Box className={classes.treeViewWidth}>
                          <TreeView
                            defaultCollapseIcon={
                              <ExpandMoreIcon id="expand-more" />
                            }
                            expanded={expandedIds}
                            // @ts-ignore
                            selected={[selectedCategoryNid]}
                            defaultExpandIcon={
                              <ChevronRightIcon id="chevron-right" />
                            }
                            defaultEndIcon={<></>}
                            onNodeToggle={onNodeToggle}
                            onNodeSelect={onTreeItemClick}
                          >
                            <>
                              <StyledTreeItem
                                // @ts-ignore
                                nodeId={CBRE_MOST_VIEWED_NID}
                                labelText={'CBRE Most Viewed'}
                                labelInfo={`${mostViewedCount}`}
                                // @ts-expect-error
                                labelIcon={undefined}
                              ></StyledTreeItem>
                              <StyledTreeItem
                                // @ts-ignore
                                nodeId={CBRE_VIEW_ALL_TOOLS_NID}
                                labelText={'View All'}
                                labelInfo={`${allToolsCount}`}
                                // @ts-expect-error
                                labelIcon={undefined}
                              ></StyledTreeItem>
                              <Box sx={{ mt: 1, mb: 2 }}>
                                <Typography className={classes.toolsCategories}>
                                  Categories
                                </Typography>
                              </Box>
                              <Box>{renderTree(toolsData?.[0])}</Box>
                            </>
                          </TreeView>
                        </Box>
                      </Drawer>
                    </>
                  )}
                  <Box sx={{ flex: 1 }}>
                    <Paper component="form" className={classes.flex}>
                      <IconButton disabled type="submit" aria-label="search">
                        <SearchIcon />
                      </IconButton>
                      <InputBase
                        style={{ flex: 1 }}
                        id="search-tool"
                        data-testid="search-tool"
                        placeholder="Search"
                        value={searchText}
                        onChange={onChangeSearchText}
                        inputProps={{ 'aria-label': 'search' }}
                      />
                      <IconButton
                        aria-label="search"
                        id="search-tool-btn"
                        data-testid="search-tool-btn"
                        onClick={() => {
                          setSearchText('');
                          additionalOpsOnSearchText('');
                          return;
                        }}
                      >
                        <ClearButton />
                      </IconButton>
                    </Paper>
                  </Box>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                  }}
                >
                  <Box>
                    <Typography style={{ fontWeight: 'bold' }}>
                      {contentAreaTextHeading}{' '}
                      {selectedNode !== undefined &&
                      selectedNode.node !== undefined
                        ? selectedNode?.node?.title
                        : ''}
                    </Typography>
                  </Box>

                  <Grid container className={classes.toolboxGrid}>
                    {toolsToRender !== undefined &&
                      Array.isArray(toolsToRender) &&
                      toolsToRender.length > 0 &&
                      toolsToRender.map(tool => (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={3}
                          lg={3}
                          xl={2}
                          // className={classes.gridTool}
                        >
                          <Tool
                            key={tool?.nid}
                            tool={tool}
                            userEmail={userEmail}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Content>
    </Page>
  );
};
export default ToolboxContainer;
