import { AxiosInstance } from 'axios';
import { createApiRef } from '@backstage/core-plugin-api';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';

export interface MockServiceApi {
  saveMock: (data: any) => Promise<any>;
  deactivateMock: (username: string, mockId: string) => Promise<any>;
  editMock: (
    username: string,
    mockId: string,
    name: string,
    description: string,
    endpoint: string,
    version: string,
    requesttype: string,
    requestbody: string,
    requestresponse: string,
  ) => Promise<any>;
  getMockWithId: (mockId: string) => Promise<any>;
  getAllMocks: () => Promise<any>;
  generateSwagger: (mockId: string) => Promise<any>;
}

export const mockServiceApiRef = createApiRef<MockServiceApi>({
  id: 'cbre.devx.api.mock-service',
});

export class MockServiceApiImpl implements MockServiceApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  async saveMock(data: any): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance
      .post('service/mockapi/savemock', data)
      .then(res => res.data);
  }

  async deactivateMock(username: string, mockId: string): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance
      .put(`service/mockapi/deactivatemock/${mockId}/${username}`, {
        username,
        mockId,
      })
      .then(res => res.data);
  }

  async editMock(
    username: string,
    mockId: string,
    name: string,
    description: string,
    endpoint: string,
    version: string,
    requesttype: string,
    requestbody: string,
    requestresponse: string,
  ): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance
      .put(`service/mockapi/${mockId}`, {
        username,
        mockId,
        name,
        description,
        endpoint,
        version,
        requesttype,
        requestbody,
        requestresponse,
      })
      .then(res => res.data);
  }

  async getMockWithId(mockId: string): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance
      .get(`service/mockapi/getmockwithid/${mockId}`)
      .then(res => res.data);
  }

  async getAllMocks(): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance.get('service/mockapi/getmocks').then(res => res.data);
  }

  async generateSwagger(mockId: string): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance
      .get(`service/mockapi/swagger/${mockId}`)
      .then(res => res.data);
  }
}
