import React, { useContext, useEffect, useState } from 'react';
import { Collapse, Grid } from '@material-ui/core';
import BodyContent from './BodyContent';
import { useStyles } from './styles';
import QueryParams from './QueryParams';
import { PromotionContext } from '../../../../providers/PromotionDataProvider';
import Responses from './Responses';
import Heading from './Heading';
import RequestInfo from './RequestInfo';
import REGISTER_API_CONSTANTS from '../Constants';

const RequestForm = (props: any) => {
  const { type, onReqInput, onReqDelete, clicked, onError } = props;
  const classes = useStyles();

  /* Context */
  const myContext = useContext(PromotionContext);

  // Variable for SubPath
  const [reqPath, setReqPath] = useState('');

  // Variables for reqInfo
  const [reqInfo, setReqInfo] = useState<any>({
    operationId: '',
    description: '',
  });

  // Variables for reqInfo
  const [queryParams, setQueryParams] = useState<any>([]);

  // Variables for reqBody
  const [reqBody, setReqBody] = useState<any>();

  // Variable for Response
  const [responses, setResponses] = useState<any>();

  // Variable for expansion
  const [expanded, setExpanded] = React.useState(true);

  /* Handle delete of response on click of response button */
  const handleDeleteRequest = () => {
    onReqDelete(reqPath, type.toLowerCase());
  };

  const [errors, setErrors] = useState({
    params: false,
  });

  /* Error Handler */
  const handleError = (name: any, hasError: boolean) => {
    setErrors((prevState: any) => {
      return {
        ...prevState,
        [name]: hasError,
      };
    });
  };

  /* Errors from Sub components */
  useEffect(() => {
    const singlePathErrors = !Object.values(errors).every(
      (x: any) => x === false,
    );
    onError(singlePathErrors);
  }, [errors]);

  useEffect(() => {
    // if the form values or response change, construct the path object
    if (reqPath) {
      const pathObject = {
        [reqPath]: {
          [type.toLowerCase()]: {
            ...reqInfo,
            parameters: [...queryParams],
            requestBody: reqBody,
            responses: responses,
          },
        },
      };
      onReqInput(pathObject);
    }
  }, [clicked, responses, reqBody, queryParams]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleReqInput = (data: any) => {
    setReqBody(data);
  };

  const handleResponses = (data: any) => {
    setResponses(data);
  };

  const handleRequestInfo = (data: any) => {
    const { subPath, ...info } = data;
    setReqPath(subPath);
    setReqInfo(info);
  };

  const handleQueryParams = (data: any) => {
    setQueryParams(data);
  };

  return (
    <div className={classes.swaggerForm}>
      {/* Heading */}
      <Heading
        text={`${type} ${reqPath}`}
        onDelete={handleDeleteRequest}
        onExpand={handleExpandClick}
        expanded={expanded}
      />

      {/* The Collapseable Section */}
      <Collapse in={expanded} timeout="auto">
        <Grid
          container
          direction="row"
          spacing={2}
          className={classes.collapse}
        >
          {/* Request Info */}
          <RequestInfo onChange={handleRequestInfo} clicked={clicked} />

          {/* Params Section */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <QueryParams
              onChange={handleQueryParams}
              onError={handleError}
              path={reqPath}
              title={REGISTER_API_CONSTANTS.swaggerApiForm.paramHeading}
              clicked={clicked}
            />
          </Grid>

          {/* Request Body Content */}
          {(type === 'POST' ||
            type === 'DELETE' ||
            type === 'PUT' ||
            type === 'PATCH') && (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                Body Content
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <BodyContent
                  headingVisible={false}
                  onContentInput={(data: any) => handleReqInput(data)}
                  clicked={clicked}
                />
              </Grid>
            </>
          )}
        </Grid>

        {/* Reponses  */}
        <Responses onReponses={handleResponses} clicked={clicked} />
      </Collapse>
    </div>
  );
};
export default RequestForm;
