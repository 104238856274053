import { Button, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from '../../../../styles';
import Add from '@material-ui/icons/Add';
import { useNavigate } from 'react-router';

const EmptyConfigBox = ({
  navigatePath,
  flows,
  isToolAdmin,
  text,
}: {
  navigatePath: string;
  flows: any;
  isToolAdmin: boolean;
  text?: string;
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const navigateToAddConfig = () => {
    navigate(navigatePath);
  };

  const emptyMsg = () => {
    if (flows) {
      return isToolAdmin
        ? 'No configs to show yet. Add config to run test'
        : `No ${text} found`;
    }
    return 'No flows found, navigate to Devops Portal and create new flow for this application';
  };

  return (
    <Paper elevation={0} className={classes.emptyPaper}>
      <Grid className={classes.emptyPageAction}>
        <Typography component="div" className={classes.emptyPageText}>
          {emptyMsg()}
        </Typography>
        <div>
          {isToolAdmin ? (
            <Button
              variant="contained"
              color="primary"
              onClick={navigateToAddConfig}
              disabled={!flows}
            >
              <Add />
              Add Config
            </Button>
          ) : null}
        </div>
      </Grid>
    </Paper>
  );
};

export default EmptyConfigBox;
