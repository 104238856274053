import React from 'react';
import { Grid, Button, ButtonGroup } from '@material-ui/core';

import { useCustomStyles } from './leaderBoardStyles';

const superText = (id: any) => {
  switch (id) {
    case 1:
      return `${id}st`;
    case 2:
      return `${id}nd`;
    case 3:
      return `${id}rd`;
    case 4:
      return `${id}th`;

    default:
      return `${id}st`;
  }
};

const FilterButtons = ({
  selectedBtn,
  handleFilter,
  selectedFilterbtn,
}: any) => {
  const filterByMonth: any = [];
  const filterByQuarter: any = [];
  const filterByYear: any = [];

  const getLastMonths = (n = 4) => {
    const m = [
      'Jan',
      'Feb',
      'Mar',
      'Aprl',
      'May',
      'Jun',
      'July',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const d = new Date();
    for (let i = 0; i < n; i++) {
      const last_n_months =
        m[d.getMonth()] + ' - ' + d.getFullYear().toString();
      filterByMonth.push({
        label: last_n_months,
        value: `m${i}`,
      });

      d.setMonth(d.getMonth() - 1);
    }

    return filterByMonth;
  };

  const getQuarter = () => {
    for (let i = 0; i <= 3; i += 1) {
      const d = new Date();
      let quarter = Math.floor(d.getMonth() / 3);
      let year = d.getFullYear();
      quarter -= i;

      if (quarter < 0) {
        const yearsChanged = Math.ceil(-quarter / 4);
        year -= yearsChanged;

        quarter += 4 * yearsChanged;
      }

      filterByQuarter.push({
        label: superText(quarter + 1) + ' Quarter' + ' ' + year,
        value: `q${i}`,
      });
    }

    return filterByQuarter;
  };

  const getlastyear = () => {
    const date = new Date();
    const year = date.getFullYear() - 1;
    filterByYear.push({
      label: ' Year' + ' ' + year,
      value: 'y1',
    });

    return filterByYear;
  };

  const onClickFilterButton = (selected: string) => {
    handleFilter(selected);
  };

  const classes = useCustomStyles();

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      className={classes.leaderBoardGroupButton}
    >
      <ButtonGroup size="large" variant="outlined">
        {selectedBtn === 'monthly' &&
          getLastMonths()?.map((item: any, index: number) => {
            return index ? (
              <Button
                className={
                  selectedFilterbtn === item.value
                    ? classes.btnSelected
                    : classes.btnDefault
                }
                onClick={() => onClickFilterButton(item.value)}
              >
                {item.label}
              </Button>
            ) : (
              <Button
                className={
                  selectedFilterbtn === 'cm'
                    ? classes.btnSelected
                    : classes.btnDefault
                }
                onClick={() => onClickFilterButton('cm')}
              >
                Current Month
              </Button>
            );
          })}
        {selectedBtn === 'quarterly' &&
          getQuarter()?.map((item: any, index: number) => {
            return index ? (
              <Button
                className={
                  selectedFilterbtn === item.value
                    ? classes.btnSelected
                    : classes.btnDefault
                }
                onClick={() => onClickFilterButton(item.value)}
              >
                {item.label}
              </Button>
            ) : (
              <Button
                className={
                  selectedFilterbtn === 'cq'
                    ? classes.btnSelected
                    : classes.btnDefault
                }
                onClick={() => onClickFilterButton('cq')}
              >
                Current Quarter
              </Button>
            );
          })}
        {selectedBtn === 'y1' && (
          <ButtonGroup>
            <Button
              className={
                selectedFilterbtn === 'cy'
                  ? classes.btnSelected
                  : classes.btnDefault
              }
              onClick={() => onClickFilterButton('cy')}
            >
              Current Year
            </Button>
            {getlastyear()?.map((item: any) => {
              return (
                <Button
                  className={
                    selectedFilterbtn === 'y1'
                      ? classes.btnSelected
                      : classes.btnDefault
                  }
                  onClick={() => onClickFilterButton('y1')}
                >
                  {item.label}
                </Button>
              );
            })}
          </ButtonGroup>
        )}
      </ButtonGroup>
    </Grid>
  );
};

export default FilterButtons;
