import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reviewerContainer: {
      // backgroundColor: theme.palette.primary.main,
      width: '80%',
      minWidth: '200px',
    },
    reviewerContainerFilter: {
      // backgroundColor: theme.palette.primary.main,
      width: '100%',
      minWidth: '100%',
    },
    submitutton: {
      fill: theme.palette.primary.main,
      fontSize: '42px',
      cursor: 'pointer',
    },
    submituttonDisabled: {
      fill: theme.palette.secondary.main,
      fontSize: '42px',
      pointerEvents: 'none',
    },
    reviewerMainContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    addReviewerTextbox: {
      fontSize: '14px',
    },
  }),
);
