/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { adminApiRef } from '../../../apis/adminApi';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';

export const useGetReviwerListHook = () => {
  const adminApi = useApi(adminApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const [reviewerList, setReviewerList] = useState<any>(null);

  const getReviewerList = () => {
    return adminApi
      .getReviewerList()
      .then(data => {
        setReviewerList(data);
      })
      .catch((error: any) => {
        setReviewerList({
          responseData: {},
          status: 'Error',
        });
        notificationApi.sendNotification({
          message: `Error occurred - ${error?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  useEffect(() => {
    getReviewerList();
  }, []);

  return { reviewerList };
};
