import React, { useEffect, useState, useContext } from 'react';
import { Button } from '@backstage/core-components';
import {
  EntityListProvider,
  CatalogFilterLayout,
} from '@backstage/plugin-catalog-react';
// @ts-ignore
import { useApi } from '@backstage/core-plugin-api';
import {
  Box,
  Grid,
  Modal,
  Paper,
  makeStyles,
  createStyles,
  LinearProgress,
  Theme,
} from '@material-ui/core';
import ConfirmSubTreeDelete from './ConfirmSubTreeDelete/ConfirmSubTreeDelete';
import { CapUserListPicker } from './CapUserListPicker';
import CapabilityPicker from './CapabilityPicker';
import { DELETE_TYPES } from './Constants';
import DeletePrompt from './DeletePrompt/DeletePrompt';
import TaxonomyTable from './TaxonomyTable';
import BackButton from '../../utils/backButton';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import { AuthContext } from '../../../providers/AuthProvider';
import { capabilityTaxonomyApiRef } from '../../../apis/capabilityTaxonomyApi';
import {
  notificationApiRef,
  NotificationApi,
} from '../../../apis/notificationApi';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    taxanomyPage: {
      margin: '-24px',

      [theme.breakpoints.down('sm')]: {
        margin: '0',
        padding: '0',
      },
    },
    addCapability: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    capabilityBtn: {
      margin: '10px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    delete: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
      [theme.breakpoints.down('sm')]: {
        width: '94%',
      },
      ['@media (max-width:1280px) and (min-width: 1024px)']: {
        width: '70%',
      },
    },
    subtreeDelete: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
    },
  }),
);

const ManageTaxonomyContainer = () => {
  const [selectedEntity, setSelectedEntity] = useState();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showConfirmTreeDelete, setShowConfirmTreeDelete] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const { isAdmin }: { isAdmin: any } = useContext(AuthContext);
  const classes = useStyles();

  const capabilityTaxonomyApi = useApi(capabilityTaxonomyApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const deleteCapabilityApiCall = (action: string) => {
    const relations = selectedEntity?.relations;
    let parent = null;
    const children = [];
    if (relations) {
      for (let i = 0; i <= relations.length; i += 1) {
        const rel = relations[i];
        if (rel && rel.type === 'childOf') {
          parent = rel.target;
        } else if (rel && rel.type === 'parentOf') {
          children.push(rel.target);
        }
      }
    }

    const data = {
      kind: selectedEntity?.kind || 'Group',
      namespace: selectedEntity?.metadata?.namespace || 'default',
      name: selectedEntity?.metadata?.name,
      deleteType: action,
      parent: parent,
      children: children || [],
      uid: selectedEntity?.metadata?.uid,
    };
    setInProgress(true);

    capabilityTaxonomyApi
      .deleteCapability(data)
      .then((respData: any) => {
        setInProgress(false);

        notificationApi.sendNotification({
          message: `Capability deleted - ${respData?.name}`,
          disapperAfterMs: 2500,
          severity: 'success',
        });
      })
      .catch((err: any) => {
        setInProgress(false);

        notificationApi.sendNotification({
          message: `Error occurred while deleting capability ${err?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  const onDeleteCapability = (entity: any) => {
    setSelectedEntity(entity);
    setShowDeleteDialog(true);
  };

  const onCloseDeletePrompt = (event: any, action: any) => {
    event?.stopPropagation();
    setShowDeleteDialog(false);
    if (!action) {
      setSelectedEntity(undefined);
    } else {
      deleteCapabilityApiCall(action);
    }
  };

  const onCloseConfirmSubtreeDialog = (action: string) => {
    setShowConfirmTreeDelete(false);
    setShowDeleteDialog(false);
    if (!action) {
      setSelectedEntity(undefined);
    } else {
      if (DELETE_TYPES.DELETE_AND_DELETE_COMPLETE_SUBTREE === action) {
        deleteCapabilityApiCall(action);
      }
    }
  };

  return (
    <div className={`codePage ${classes.taxanomyPage}`}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <BackButton />
          <HelpComponent helpUrl={HELP_URL.TAXONOMY_PAGE} />
        </Grid>
      </Grid>
      {inProgress && <LinearProgress />}
      {isAdmin && (
        <Box className={classes.addCapability}>
          <Button
            className={classes.capabilityBtn}
            variant="contained"
            color="primary"
            to={`/taxonomy/edit/component/default/_add_newcap`}
            title="Add Capability"
          >
            Add Capability{' '}
          </Button>
        </Box>
      )}
      <Box>
        <EntityListProvider>
          <CatalogFilterLayout>
            <CatalogFilterLayout.Filters>
              <CapUserListPicker />
              <CapabilityPicker />
            </CatalogFilterLayout.Filters>
            <CatalogFilterLayout.Content>
              <TaxonomyTable
                onDeleteCapability={onDeleteCapability}
                isAdmin={isAdmin}
              />
            </CatalogFilterLayout.Content>
          </CatalogFilterLayout>
        </EntityListProvider>
      </Box>
      {showDeleteDialog && (
        <Modal
          title="Delete"
          onClose={() => {
            setShowDeleteDialog(false);
          }}
          open={showDeleteDialog}
          children={
            <Paper className={classes.delete}>
              <DeletePrompt
                selectedEntity={selectedEntity}
                onClose={onCloseDeletePrompt}
              />
            </Paper>
          }
        ></Modal>
      )}
      {showConfirmTreeDelete && (
        <Modal
          title="Delete"
          onClose={() => {
            setShowConfirmTreeDelete(false);
          }}
          open={showConfirmTreeDelete}
          children={
            <Paper className={classes.subtreeDelete}>
              <ConfirmSubTreeDelete
                selectedEntity={selectedEntity}
                onClose={onCloseConfirmSubtreeDialog}
              />
            </Paper>
          }
        ></Modal>
      )}
    </div>
  );
};
export default ManageTaxonomyContainer;
