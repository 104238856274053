import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const StopWatchTimerIcon = (props: any) => (
  <SvgIcon viewBox={'48 48'} style={{ transform: 'scale(3)' }} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 3C8 2.44772 8.44772 2 9 2H15C15.5523 2 16 2.44772 16 3C16 3.55228 15.5523 4 15 4H9C8.44772 4 8 3.55228 8 3ZM3 14C3 9.02944 7.02944 5 12 5C14.1246 5 16.0784 5.73716 17.6177 6.96815L18.2929 6.29291C18.6834 5.90237 19.3166 5.90236 19.7071 6.29288C20.0976 6.6834 20.0976 7.31656 19.7071 7.70709L19.0319 8.38235C20.2629 9.92158 21 11.8754 21 14C21 18.9706 16.9706 23 12 23C7.02944 23 3 18.9706 3 14ZM16.9498 9.05025C15.6819 7.78239 13.9332 7 12 7C8.134 7 5 10.134 5 14C5 17.866 8.134 21 12 21C15.866 21 19 17.866 19 14C19 12.0668 18.2176 10.3181 16.9498 9.05025ZM12 9C12.5523 9 13 9.44772 13 10V14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14V10C11 9.44772 11.4477 9 12 9Z"
      fill="#69DDC7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6574 9.8589C14.3585 10.1843 14.3801 10.6904 14.7055 10.9892C16.3327 12.4835 16.4404 15.0138 14.9462 16.641C14.6474 16.9664 14.6689 17.4725 14.9943 17.7713C15.3198 18.0702 15.8259 18.0486 16.1247 17.7232C18.2166 15.4452 18.0657 11.9027 15.7877 9.81076C15.4623 9.51191 14.9562 9.53347 14.6574 9.8589Z"
      fill="#003F2D"
    />
    <path
      d="M14.2987 16.2305L13.5911 19.4063L16.6951 18.4312L14.2987 16.2305Z"
      fill="#003F2D"
    />
  </SvgIcon>
);

export default StopWatchTimerIcon;
