/* eslint-disable react-hooks/exhaustive-deps */
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { envScopes } from '../constant';

export const useEnvScopes = () => {
  const configApi = useApi(configApiRef);

  let env = configApi.getString('app.datadogRum.env') as
    | 'internal'
    | 'dev'
    | 'uat'
    | 'prod';

  // This is done for local testing, have no effect env
  const envArray = ['internal', 'dev', 'uat', 'prod'];
  if (!envArray.includes(env)) {
    env = 'internal';
  }

  const domainId = envScopes[env].domainId || '';
  const subDomainId = envScopes[env].subDomainId || '';
  const personaId = envScopes[env].personaId || '';

  return { domainId, subDomainId, personaId, env };
};
