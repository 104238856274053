/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { useContext, useEffect, useState } from 'react';
import { promptApiRef } from '../../../apis/promptManagementApi';
import { AuthContext } from '../../../providers/AuthProvider';

export const useGetPrompts = (
  domaninId: string,
  subDomainId: string,
  personaId: string,
  shouldcall: boolean,
) => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const promptManagementApi = useApi(promptApiRef);

  const [prompts, setPrompts] = useState<any>([]);
  const [myPrompts, setMyPrompts] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { profEmail } = useContext(AuthContext);

  const fetchMyAPI = async () => {
    if (promptManagementApi) {
      setLoading(true);
      try {
        /*  const response = await promptManagementApi.getPromptsByDomain(
          domaninId,
          subDomainId,
          personaId,
        ); */
        const response = await promptManagementApi.getStorePrompts();
        console.log('response', response);
        console.log('response', response?.user_submitted_prompts);
        setPrompts(response?.prompts);
        setMyPrompts(
          [...response?.user_submitted_prompts, ...response?.prompts].filter(
            (prompt: any) =>
              prompt.authoremail.toLowerCase() === profEmail.toLowerCase(),
          ),
        );

        setLoading(false);
      } catch (error) {
        setLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };

  useEffect(() => {
    if (shouldcall) fetchMyAPI();
  }, [shouldcall, profEmail]);

  return [prompts, myPrompts, loading];
};
