import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import { AxiosInstance } from 'axios';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';
import { CatalogApi } from '@backstage/plugin-catalog-react';

interface Document {
  title: string;
  url: string;
  description: string;
  user: string;
  id?: number;
}

interface DocumentApiResponse {
  status: string;
  name: string;
}

export interface ExternalDocsApi {
  getDocumentByName: (docName: string) => Promise<Document>;
  updateDocument: (data: any) => Promise<DocumentApiResponse>;
  addDocument: (data: any) => Promise<DocumentApiResponse>;
  getExternalDocumentation: () => Promise<any>;
  deleteDocument: (docName: string, type: string) => Promise<any>;
  getExternalDocumentLink: (documentName: string) => Promise<any>;
}

export const externalDocsApiRef = createApiRef<ExternalDocsApi>({
  id: 'cbre.devx.docs.external',
});

export class ExternalDocsApiImpl implements ExternalDocsApi {
  private readonly axiosIntancePromise: Promise<AxiosInstance>;
  private readonly catalogApi: CatalogApi;

  constructor(
    axiosInstanceProviderApi: AxiosInstanceProviderApi,
    catalogApi: CatalogApi,
  ) {
    this.axiosIntancePromise = axiosInstanceProviderApi.getInstance();
    this.catalogApi = catalogApi;
  }

  async getDocumentByName(docName: string): Promise<Document> {
    const instance = await this.axiosIntancePromise;

    return instance
      .get('service/discovery/document', {
        params: { documentName: docName },
      })
      .then(res => res?.data);
  }

  async updateDocument(data: Document): Promise<DocumentApiResponse> {
    const instance = await this.axiosIntancePromise;

    return instance.put('service/discovery/document', data).then(res => {
      return res?.data;
    });
  }

  async addDocument(data: Document): Promise<DocumentApiResponse> {
    const instance = await this.axiosIntancePromise;

    return instance.post('service/discovery/document', data).then(res => {
      return res?.data;
    });
  }

  async getExternalDocumentation(): Promise<any> {
    return this.catalogApi
      .getEntities({
        filter: {
          'spec.type': 'extdocumentation',
        },
      })
      .then(res => res?.items);
  }

  async deleteDocument(docName: string, type: string): Promise<any> {
    const instance = await this.axiosIntancePromise;
    return instance
      .delete('service/discovery/document', {
        params: {
          documentName: docName,
          type,
        },
      })
      .then(res => res?.data);
  }

  async getExternalDocumentLink(documentName: string): Promise<any> {
    const instance = await this.axiosIntancePromise;
    return instance
      .get(`service/discovery/document`, {
        params: {
          documentName,
        },
      })
      .then(res => res.data)
      .catch(err => err);
  }
}
