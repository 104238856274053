/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import {
  QuestionDetailsResponse,
  informationExchangeApiRef,
} from '../../../apis/informationExchange';
import { useEffect, useState } from 'react';

export const useGetQuestionDetails = (
  qid: string | undefined,
  filterBy: string,
  shouldcall: Boolean,
) => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const informationExchangeApi = useApi(informationExchangeApiRef);

  const [questionDetails, setQuestionDetails] =
    useState<QuestionDetailsResponse | null>(null);

  const fetchMyAPI = async () => {
    if (informationExchangeApi) {
      try {
        const data = await informationExchangeApi.getQuestionDetails(
          qid!,
          filterBy,
        );
        setQuestionDetails(data!);

        //  setIsLoading(false);
      } catch (error) {
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };

  useEffect(() => {
    if (shouldcall) fetchMyAPI();
  }, [qid, filterBy, shouldcall]);

  return [questionDetails];
};
