import React, { useEffect, useRef, useState } from 'react';
import { Button, Grid, LinearProgress, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { HelpComponent } from '../../../utils/helpComponent';
import HELP_URL from '../../../utils/helpLinkConstant';
import BackButton from '../../../utils/backButton';
import AddIcon from '@material-ui/icons/Add';
import { PageListCard } from './PageListCard';
import AddPagePopup from './AddPagePopup';
import { WhatsNewApi, whatsNewApiRef } from '../../../../apis/whatsNew';
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';
import DeletePopup from '../common/DeletePopup';
import { useStyles } from '../common/styles';

export const PageListPage = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedPageObj, setSelectedPageObj] = useState<any>();
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [tourConfig, setTourConfig] = useState<any>([]);

  const whatsNew: WhatsNewApi = useApi(whatsNewApiRef);

  const addPage = (pageData: any) => {
    setIsLoading(true);
    whatsNew.addPageConfig(pageData).then((responseData: any) => {
      const pageDataResponse = responseData?.data;
      setTourConfig((prevState: any) => {
        return [...prevState, pageDataResponse];
      });
      setIsLoading(false);
    });
  };

  /* Edit Page service call */
  const editPage = (pageData: any) => {
    setIsLoading(true);
    whatsNew.editPageConfig(pageData.id, pageData).then((responseData: any) => {
      const pageDataResponse = responseData?.data;
      setTourConfig(
        tourConfig.map((el: any) =>
          el.id === pageData.id
            ? {
                ...el,
                ...pageDataResponse,
              }
            : el,
        ),
      );
      setIsLoading(false);
    });
  };

  /* Delete Page service call */
  const deletePage = (pageId: string) => {
    setIsLoading(true);
    whatsNew.deletePageConfig(pageId).then((responseData: any) => {
      setTourConfig(tourConfig.filter((el: any) => el.id !== pageId));
      setIsLoading(false);
    });
  };

  /* Methods related to AddEdit Popup */
  /* --------------------------------------------------------- */
  /* handle AddEdit Open */
  const handleAddEditOpen = () => {
    setOpen(true);
  };
  /* handle AddEdit close */
  const handleAddEditClose = () => {
    setSelectedPageObj(null);
    setOpen(false);
  };
  /* handle Add Edit Page */
  const handleAddEditPage = (pageData: any, isEditMode: boolean) => {
    if (isEditMode) {
      editPage(pageData);
    } else {
      addPage(pageData);
    }
    handleAddEditClose();
  };

  /* --------------------------------------------------------- */

  /* Page List Card related Callbacks */
  /* --------------------------------------------------------- */
  /* Handle Edit button Clicked on PageList Card */
  const handleEditClicked = (data: any) => {
    setSelectedPageObj(data);
    handleAddEditOpen();
  };

  /* Handle toggle button Clicked on PageList Card */
  const handleToggleClicked = (pageData: any) => {
    editPage(pageData);
  };

  /* Handle toggle button Clicked on PageList Card */
  const handleDeleteClicked = (pageData: any) => {
    setSelectedPageObj(pageData);

    setShowConfirmDelete(true);
  };

  /* --------------------------------------------------------- */
  /* Delete Popup related Callbacks */
  /* --------------------------------------------------------- */
  const onCloseConfirmPrompt = () => {
    setShowConfirmDelete(false);
    setSelectedPageObj(null);
  };

  const onConfirmDelete = () => {
    deletePage(selectedPageObj.id);
    onCloseConfirmPrompt();
  };

  /* --------------------------------------------------------- */

  /* Get TourConfig */
  const getTourConfig = () => {
    setIsLoading(true);
    whatsNew.getTourConfig().then((responseData: any) => {
      setTourConfig(responseData?.data || []);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getTourConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid container direction="row">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <BackButton />
          <HelpComponent helpUrl={HELP_URL.Whats_New_Pagelist} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {isLoading && <LinearProgress />}
        </Grid>
      </Grid>

      <div className={`codePage ${classes.pageList}`}>
        <Grid container direction="row" className={classes.saveContainer}>
          <Grid item xs={12} sm={12} md={6} lg={6} className={classes.heading}>
            <Typography component="h2" className={classes.pageHeading}>
              Dev-X Pages
            </Typography>
            <Typography component="div" className={classes.pageDesc}>
              Add and Edit Pages/Routes. Enable/Disable Whats New Tour for
              specific pages.
            </Typography>
          </Grid>
          {/* <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            className={classes.ContentHeaderStyle}
          >
            <label htmlFor="search-input" hidden>
              Search Here
            </label>
            <Paper component="form">
              <IconButton disabled type="submit" aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                className={classes.input}
                placeholder="Search"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Paper>
          </Grid> */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.pageBtn}
          >
            <Button
              onClick={handleAddEditOpen}
              startIcon={<AddIcon />}
              className={`${classes.addPageBtn} ${classes.btnFonts}`}
            >
              Add Page
            </Button>
          </Grid>{' '}
        </Grid>
        {!isLoading && tourConfig.length === 0 && <p>No Pages added.</p>}
        <Grid container>
          {tourConfig?.map((pageData: any) => {
            return (
              <Grid item xs={12} sm={12} md={12} lg={12} key={pageData.id}>
                <PageListCard
                  onEditClick={handleEditClicked}
                  onToggleClicked={handleToggleClicked}
                  onDeleteClicked={() => handleDeleteClicked(pageData)}
                  pageData={pageData}
                />
              </Grid>
            );
          })}
        </Grid>

        <AddPagePopup
          open={open}
          onClose={handleAddEditClose}
          onSave={handleAddEditPage}
          data={selectedPageObj}
        />

        <DeletePopup
          isOpen={showConfirmDelete}
          onClose={onCloseConfirmPrompt}
          onConfirm={onConfirmDelete}
          msg="Are you sure you want to delete"
        />
      </div>
    </>
  );
};
