import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import BackButton from '../../../utils/backButton';
import SimilarPromptCard from './SimilarPromptCard';
import { useStyles } from '../styles';
import { IPromptData } from '../interfaces';

const SimilarPrompts = ({
  draftPrompt,
  similarPrompts,
  onForceSave,
  onCopyExistingPrompt,
  onEditClicked,
  onCancel,
}: {
  draftPrompt: IPromptData | [];
  similarPrompts: IPromptData[];
  onForceSave: Function;
  onCopyExistingPrompt: Function;
  onEditClicked: Function;
  onCancel: Function;
}) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid
        container
        className={`${classes.pagePadding} ${classes.flexSpacebetween}`}
      >
        <Typography component="h2" className={classes.h2} variant="h5">
          Add Prompt
        </Typography>
        <Button variant="outlined" onClick={() => onCancel()}>
          Cancel
        </Button>
      </Grid>
      <Grid container className={classes.pagePadding}>
        <Grid item xs={12}>
          <SimilarPromptCard
            promptData={draftPrompt}
            onForceSave={onForceSave}
            onCopyExistingPrompt={onCopyExistingPrompt}
            onEditClicked={onEditClicked}
          />
        </Grid>
        {similarPrompts.map((similarPrompt: any) => (
          <Grid item xs={12}>
            <SimilarPromptCard
              promptData={similarPrompt}
              key={similarPrompt.id}
              onCopyExistingPrompt={onCopyExistingPrompt}
              onForceSave={onForceSave}
              onEditClicked={onEditClicked}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default SimilarPrompts;
