import React, { useContext } from 'react';
import {
  PageWithHeader,
  TabbedLayout,
  InfoCard,
} from '@backstage/core-components';
import { Grid, makeStyles, Divider, LinearProgress } from '@material-ui/core';
import BackButton from '../utils/backButton';
import { HelpComponent } from '../utils/helpComponent';
import HELP_URL from '../utils/helpLinkConstant';
import { Link } from 'react-router-dom';
import noImage from '../../assest/no-image.jpg';
import { dateFormat } from './dateFormat';
import { AuthContext } from '../../providers/AuthProvider';
import { PromotionsBreadCrumb } from './PromotionsBreadCrumb';
import PromotionImage from './PromotionImage';
import { useApi } from '@backstage/core-plugin-api';
import { promotionApiRef } from '../../apis/promotionApi';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';

const useStyles = makeStyles({
  infocardContainer: {
    marginLeft: '2rem',
    marginRight: '2rem',
  },
});
const filterActiveData = (data: any) => {
  return data?.filter((item: any) => item.isActive === true);
};
const routes = [
  { type: 'link', text: 'Home', link: '/' },
  // { type: 'link', text: 'Discover', link: '/catalog/discover' },
  { type: 'text', text: 'Promotions', link: '' },
];

const stringWordHandle = (str: any, count: number) => {
  let str1;

  if (str.length > count) {
    str1 = str.substring(0, count);
  } else {
    str1 = str;
  }
  return str1;
};
const ListItem = (props: any) => {
  let check = props.item?.assetURL === '' || props.item?.assetURL === undefined;

  const SeeMore = (
    <>
      &nbsp;...&nbsp;
      <Link to={`/promotions/${props.item.title}/${props.item.promotionId}`}>
        <span className="see-more-link">see more.</span>
      </Link>
    </>
  );
  const stringSeeMore = (str: any, count: number) => {
    if (str.length >= count) return SeeMore;
    else return null;
  };

  return (
    <Link to={`/promotions/${props.item?.title}/${props.item?.promotionId}`}>
      <li key={props.key}>
        <div className="list-item-container">
          <div className="item-image-container">
            {check ? (
              <img
                src={noImage}
                alt={'promotion' + props.index}
                className="item-image"
              ></img>
            ) : (
              <PromotionImage
                assetURL={props.item?.assetURL}
                promotionId={props.item?.promotionId}
                title={props.item?.title}
                altText={`promotion${props.index}`}
                className="item-image"
              />
            )}
          </div>
          <div className="item-text-area">
            <div className="item-text-wrapper">
              <div className="item-title">{props.item.title}</div>
              <div className="item-description">
                {stringWordHandle(props.item.description, 1000)}
                {stringSeeMore(props.item.description, 1000)}
              </div>
            </div>

            <div className="item-time">
              <span> {dateFormat(props.item.creationDate)}</span>
            </div>
          </div>
        </div>
      </li>
      <Divider></Divider>
    </Link>
  );
};
export const PromotionPage = () => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState();
  const classes = useStyles();
  const { accessToken, apiBaseUrl } = useContext(AuthContext);
  const promotionApi = useApi(promotionApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  React.useEffect(() => {
    setLoading(true);

    promotionApi
      .getPromotionData()
      .then((data: any) => {
        setLoading(false);
        if (Array.isArray(data)) {
          setData(data);
        } else {
          setData([]);
        }
      })
      .catch((err: any) => {
        setLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${err?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  }, [accessToken, apiBaseUrl]);

  return (
    <PageWithHeader
      title={'Promotions'}
      themeId="admin"
      pageTitleOverride="Promotions"
      subtitle={<PromotionsBreadCrumb routes={routes}></PromotionsBreadCrumb>}
    >
      <TabbedLayout>
        <TabbedLayout.Route path="/" title="All Latest Promotions">
          <Grid container direction="row" className="admin-reviewer-page">
            <Grid item md={12} xs={12}>
              <BackButton />
              <HelpComponent helpUrl={HELP_URL.PROMOTION_MARKETING} />
            </Grid>

            {loading && <LinearProgress></LinearProgress>}
            <Grid item md={12} xs={12} className={classes.infocardContainer}>
              <InfoCard
                title={`Promotions (${
                  !loading ? filterActiveData(data)?.length : 0
                })`}
              >
                <ul className="Promotions-list">
                  {filterActiveData(data || [])?.map(
                    (item: any, index: any) => (
                      <ListItem index={index} item={item} />
                    ),
                  )}
                </ul>
              </InfoCard>
            </Grid>
          </Grid>
        </TabbedLayout.Route>
      </TabbedLayout>
    </PageWithHeader>
  );
};
