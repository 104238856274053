import React, { useEffect, useState, useContext } from 'react';
import { Page, Header } from '@backstage/core-components';
import { useLocation } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';
import { HelpComponent } from '../../utils/helpComponent';
import { useCustomStyles } from '../MockStyles';
import { Grid } from '@material-ui/core';
import BackButton from '../../utils/backButton';
import BreadcrumbsNav from '../../common/BreadcrumbsNav/BreadcrumbsNav';
import Constants from '../Constants';
import { mockServiceApiRef } from '../../../apis/mockServiceApi';
import HELP_URL from '../../utils/helpLinkConstant';
import { MockApiDefinitionCard } from './MockApiDefinitionCard';
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';

const MockSwaggerContainer = () => {
  const classes = useCustomStyles();
  //   const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const mockServiceApi = useApi(mockServiceApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const location = useLocation();
  let locSplit = location.pathname.split('/');
  let pageTitle = locSplit[2];

  const [apiPageName, setApiPageName] = useState('');
  const [apiDefinition, setApiDefinition] = useState('');

  //place logic for grabbing definition here, this can then be passed on as a prop to MockApiDefinitionCard
  //plugging in sampleDefinition for now, check results of swagger api result
  useEffect(() => {
    const id = pageTitle;

    setIsLoading(true);

    mockServiceApi
      .getMockWithId(id)
      .then((responseData: any) => {
        setIsLoading(false);
        const resData = responseData.data;
        setApiPageName(resData.name);
      })
      .catch((err: any) => {
        setIsLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${err?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  }, [apiPageName, pageTitle]);

  useEffect(() => {
    const id = pageTitle;
    if (id) {
      mockServiceApi
        .generateSwagger(id)
        .then((responseData: any) => {
          const resData = responseData.data;
          var obj = JSON.stringify(resData, null, 2);
          obj = obj.slice(1, -1);
          obj = obj
            .replace(/(\\)/g, '')
            .replace(/\\n/g, ``)
            .replace(/\n/g, ``)
            .replace(/\t/g, ``)
            .replace(/\s/g, ``);
          setApiDefinition(obj);
        })
        .catch((err: any) => {
          notificationApi.sendNotification({
            message: `Error occurred - ${err?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });
    }
  }, [pageTitle]);

  return (
    <Page themeId="Mock API's">
      <Header
        title={Constants.pageTitle.mockPage}
        pageTitleOverride="Mock APIs"
        subtitle={
          <BreadcrumbsNav
            lastRoutes={[{ path: '/', title: 'Test Mock' }]}
            skipLast={1}
          />
        }
      />
      {isLoading && <LinearProgress />}

      <div className={classes.feedbackPageContainer}>
        <Grid container>
          <div className={classes.PagePaginationContainer}>
            <BackButton />
            <HelpComponent helpUrl={HELP_URL.testMock} />
          </div>
          <div className={classes.PagePaginationContainer}>
            <MockApiDefinitionCard
              definition={apiDefinition}
              title={apiPageName}
            />
          </div>
        </Grid>
      </div>
    </Page>
  );
};

export default MockSwaggerContainer;
