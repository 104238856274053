import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const URLIcon = (props: any) => (
  <SvgIcon
    viewBox={'48 48'}
    style={{ transform: 'scale(3)' }}
    fill="#000000"
    {...props}
  >
    <path
      d="M16 6H13V7.9H16C16.5384 7.9 17.0716 8.00605 17.569 8.21209C18.0664 8.41814 18.5184 8.72014 18.8991 9.10086C19.2799 9.48158 19.5819 9.93356 19.7879 10.431C19.994 10.9284 20.1 11.4616 20.1 12C20.1 12.5384 19.994 13.0716 19.7879 13.569C19.5819 14.0664 19.2799 14.5184 18.8991 14.8991C18.5184 15.2799 18.0664 15.5819 17.569 15.7879C17.0716 15.9939 16.5384 16.1 16 16.1H13V18H16C17.5913 18 19.1174 17.3679 20.2426 16.2426C21.3679 15.1174 22 13.5913 22 12C22 8.68 19.31 6 16 6ZM3.9 12C3.9 10.9126 4.33196 9.86976 5.10086 9.10086C5.86976 8.33196 6.91261 7.9 8 7.9H11V6H8C6.4087 6 4.88258 6.63214 3.75736 7.75736C2.63214 8.88258 2 10.4087 2 12C2 13.5913 2.63214 15.1174 3.75736 16.2426C4.88258 17.3679 6.4087 18 8 18H11V16.1H8C5.74 16.1 3.9 14.26 3.9 12ZM8 13H16V11H8V13Z"
      fill="#42A5F5"
    />
  </SvgIcon>
);

export default URLIcon;
