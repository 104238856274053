import React, { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageWithHeader, Content, InfoCard } from '@backstage/core-components';
import {
  Grid,
  makeStyles,
  Typography,
  TextField,
  InputLabel,
  FormControl,
  Select,
  Input,
  Button,
  MenuItem,
  LinearProgress,
  Theme,
  createStyles,
} from '@material-ui/core';
import { AuthContext } from '../../providers/AuthProvider';
import BackButton from '../utils/backButton';
import { HelpComponent } from '../utils/helpComponent';
import HELP_URL from '../utils/helpLinkConstant';
import { useApi } from '@backstage/core-plugin-api';
import { promotionApiRef } from '../../apis/promotionApi';
import PublishIcon from '@material-ui/icons/Publish';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';
import { IMAGE_MAX_SIZE } from '../utils/constant';
import { validImageFormat } from '../utils/Utils';

const customRegisterPromotions = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      gridArea: 'pageContent',
    },
    FillDetailsForm: {
      maxWidth: 500,
    },
    registerNewPromo: {
      padding: '20px',
      marginTop: '13px',
      marginLeft: '10px',
      fontSize: '13px',
    },
    titleForm: {
      fontSize: '1.0rem',
      fontWeight: 'normal',
    },
    titleInstructionCard: {
      padding: '20px',
      margin: '10px',
    },
    uploadFile: {
      margin: '10px 0px 10px 0px',
      padding: 10,
    },
    instructionCard: {
      minHeight: 550,
    },

    textfieldCommon: {
      marginTop: '18px',
    },
    selectField: {
      margin: 10,
      padding: 10,
      minWidth: 200,
      ['@media (max-width:920px) and (min-width: 280px)']: {
        width: '100%',
      },
    },
    errorMessage: {
      color: 'red',
      margin: 10,
    },
    buttonSubmit: {
      backgroundColor: '#2E5881',
      margin: 10,
      color: '#fff',
    },
    submitBtn: {
      margin: '10px',
      ['@media (max-width:920px) and (min-width: 280px)']: {
        width: '100%',
        margin: '0',
        marginTop: '10px',
      },
    },
    imagePreview: {
      display: 'block',
      margin: '1rem',
      width: '200px',
      height: '180px',
    },
    '@global': {
      'div[class^="MuiCardContent-"]': {
        padding: '16px !important',
      },
    },
    imageUpload: {
      display: 'flex',
      alignItems: 'center',
      gap: `${theme.spacing(1)}px`,
      margin: `${theme.spacing(1.25)}px 0px 0px 0px`,
    },
    button: {
      display: 'flex',
      gap: `${theme.spacing(1)}px`,
    },
  }),
);

const HeaderTitle = (props: any) => {
  return <span className={props.titleClassName}>Fill the details</span>;
};

export const AddPromotion = (props: any) => {
  const classesPromotion = customRegisterPromotions();
  const ref = React.useRef('');

  const { isAdmin } = React.useContext(AuthContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedTemplateType, setSelectedTemplateType] = React.useState('');
  const [handleTitle, setHandleTitle] = React.useState('');
  const [handleDescription, setHandleDescription] = React.useState('');
  const [handleImage, setHandleImage] = React.useState<any>('');
  const [handleRelatedUrl, setHandleRelatedUrl] = React.useState('');
  const [validateImageFile, setValidateImageFile] = React.useState(false);
  const [imagePreviewSrc, setImagePreviewSrc] = React.useState('');
  const [onLoadDisable, setOnLoadDisable] = React.useState(true);
  const [validateRelatedUrl, setValidateRelatedUrl] = React.useState(false);
  const [imageFileName, setImageFilename] = React.useState<any>('');
  const [imageDataBase64, setImageDataBase64] = React.useState<any>('');
  const [titleRequiredField, setTitleRequiredField] = React.useState(false);
  const [descriptionRequiredField, setdescriptionRequiredField] =
    React.useState(false);
  const [templateTypeRequiredField, setTemplateTypeRequiredField] =
    React.useState(false);
  const promotionApi = useApi(promotionApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const validateTheFormRequiredFields = (responceData: any) => {
    if (responceData.title === undefined || responceData.title.trim() === '') {
      setTitleRequiredField(true);
    }
    if (
      responceData.description === undefined ||
      responceData.description.trim() === ''
    ) {
      setdescriptionRequiredField(true);
    }
    if (responceData.templateType.trim() === '') {
      setTemplateTypeRequiredField(true);
    }

    if (
      responceData.templateType.trim() !== '' &&
      responceData.description.trim() !== '' &&
      responceData.title.trim() !== ''
    ) {
      return true;
    } else if (
      responceData.templateType.trim() === '' ||
      responceData.description.trim() === '' ||
      responceData.title.trim() === ''
    ) {
      setOnLoadDisable(true);
    }

    return false;
  };

  const handleFormTypeField = (e: any) => {
    e.preventDefault();
    setOnLoadDisable(false);
    setTemplateTypeRequiredField(false);
    setSelectedTemplateType(e.target.value);
  };

  const handleTitleField = (e: any) => {
    e.preventDefault();
    let value = e.target.value.replace(/\s+/g, ' ');
    setHandleTitle(value);
    if (value !== '' && value.length <= 50) {
      setTitleRequiredField(false);
      setOnLoadDisable(false);
    } else if (value.length === 0 || value === undefined) {
      setTitleRequiredField(true);
    }
  };
  const handleDescriptionField = (e: any) => {
    e.preventDefault();
    setOnLoadDisable(false);
    setdescriptionRequiredField(false);
    setHandleDescription(e.target.value);
  };

  const validateUrl = (value: string) => {
    let expression =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
    let regex = new RegExp(expression);
    if (value.match(regex) || value === '') {
      return true;
    } else {
      return false;
    }
  };
  const handleRelatedUrlField = (e: any) => {
    e.preventDefault();
    let value = e.target.value.trim().replace(/\s/g, '');
    let expression =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
    let regex = new RegExp(expression);
    setHandleRelatedUrl(e.target.value);
    if (e.target.value.match(regex) || value === '') {
      setOnLoadDisable(false);
      setValidateRelatedUrl(false);
    } else {
      setValidateRelatedUrl(true);
    }
  };

  const handleSubmit = () => {
    const validImage =
      imageFileName && imageDataBase64 && imageDataBase64.split(',')[1];
    const responceBody = {
      title: handleTitle?.trim(),
      description: handleDescription?.trim(),
      templateType: selectedTemplateType,
      isActive: true,
      relatedUrl: handleRelatedUrl,
      orderID: '1',
      imageFileName: validImage ? imageFileName : '',
      imageBase64Format: validImage ? imageDataBase64.split(',')[1] : '',
    };

    if (validateTheFormRequiredFields(responceBody)) {
      if (!validateUrl(handleRelatedUrl)) {
        setValidateRelatedUrl(true);
        setOnLoadDisable(true);
      } else {
        setIsLoading(true);
        promotionApi
          .postAddPromotion(responceBody)
          .then(() => {
            setIsLoading(false);
            notificationApi.sendNotification({
              message: `Success! added a new promotion`,
              disapperAfterMs: 2500,
              severity: 'success',
              callback: handleRedirect,
            });
          })
          .catch((error: any) => {
            setIsLoading(false);
            notificationApi.sendNotification({
              message: `Error occurred - ${error?.message}`,
              disapperAfterMs: 2500,
              severity: 'error',
            });
          });
      }
    }
  };

  function getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const imageUploadHandle = (e: any) => {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      if (
        validImageFormat(e.target.files[0].type) &&
        e.target.files[0].size < IMAGE_MAX_SIZE
      ) {
        let imageFile = e.target.files[0];
        const reader = new FileReader();
        setImageFilename(imageFile.name);

        reader.onload = (x: any) => {
          setImagePreviewSrc(x.target?.result);
        };
        getBase64(imageFile).then(data => {
          setImageDataBase64(data);
        });
        reader.readAsDataURL(imageFile);
        setValidateImageFile(false);
        setHandleImage(imageFile);
      } else {
        setValidateImageFile(true);
      }
    } else {
      setValidateImageFile(false);
      setImagePreviewSrc('');
      setHandleImage('');
    }
  };

  useEffect(() => {
    if (isAdmin === false) {
      window.location.replace('/');
    }
  }, [isAdmin]);

  const navigate = useNavigate();

  const handleRedirect = () => {
    if (isAdmin === true) {
      navigate('/admin/manage-promotions');
    } else {
      navigate('/promotions');
    }
  };

  return (
    <Content>
      <Grid
        container
        direction="row"
        className={classesPromotion.gridContainer}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <BackButton />

          <HelpComponent helpUrl={HELP_URL.ADD_PROMOTIONS} />
        </Grid>
      </Grid>
      {isLoading && <LinearProgress />}

      <Grid container direction="row">
        <Grid item xs={12} sm={12} md={6}>
          <InfoCard
            title={<HeaderTitle titleClassName={classesPromotion.titleForm} />}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                className={classesPromotion.textfieldCommon}
                disabled={false}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ maxLength: 50 }}
                placeholder={''}
                id="promo-title"
                data-testid="promo-title"
                label="Title"
                variant="outlined"
                value={handleTitle}
                onChange={handleTitleField}
              />
              {titleRequiredField && (
                <Typography className={classesPromotion.errorMessage}>
                  *Title is a required field
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl
                className={classesPromotion.textfieldCommon}
                variant="outlined"
                fullWidth
              >
                <InputLabel htmlFor="outlined-Template-Type">
                  Template Type:
                </InputLabel>
                <Select
                  value={selectedTemplateType}
                  onChange={handleFormTypeField}
                  label="Template Type"
                  data-testid="template-type"
                  inputProps={{
                    name: 'Template Type',
                    id: 'outlined-Template-Type',
                  }}
                >
                  <MenuItem value={'Short Image'}>Short Image</MenuItem>
                  <MenuItem value={'Poster Image'}>Poster Image</MenuItem>
                </Select>
              </FormControl>
              {templateTypeRequiredField && (
                <Typography className={classesPromotion.errorMessage}>
                  *Template is a required field
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                className={classesPromotion.textfieldCommon}
                InputProps={{
                  minRows: 10,
                  maxRows: 10,
                  style: { height: '200px' },
                }}
                multiline
                placeholder={''}
                id="promo-description"
                data-testid="promo-description"
                label="Description"
                variant="outlined"
                onChange={handleDescriptionField}
              />
              {descriptionRequiredField && (
                <Typography className={classesPromotion.errorMessage}>
                  *Description is a required field
                </Typography>
              )}{' '}
            </Grid>

            {imagePreviewSrc && imagePreviewSrc !== '' && (
              <img
                className={classesPromotion.imagePreview}
                src={imagePreviewSrc}
                style={{ width: 200, height: 150 }}
              ></img>
            )}

            <>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classesPromotion.imageUpload}
              >
                {/* <Input
                  type="file"
                  id="image"
                  data-testid="file-upload"
                  fullWidth
                  className={`${classesPromotion.uploadFile}`}
                  onChange={imageUploadHandle}
                  onClick={() => {
                    setValidateImageFile(false);
                    setImagePreviewSrc('');
                    setHandleImage('');
                  }}
                  ref={ref}
                ></Input> */}
                <Fragment>
                  <Input
                    color="primary"
                    // accept="image/*"
                    type="file"
                    onChange={imageUploadHandle}
                    id="icon-button-file"
                    data-testid="file-upload"
                    style={{ display: 'none' }}
                    onClick={() => {
                      setValidateImageFile(false);
                      setImagePreviewSrc('');
                      setHandleImage('');
                    }}
                    // ref={ref}
                  />
                  <label htmlFor="icon-button-file">
                    <Button
                      variant="contained"
                      component="span"
                      color="primary"
                    >
                      <div className={classesPromotion.button}>
                        <PublishIcon />
                        <span>Upload Image</span>
                      </div>
                    </Button>
                  </label>
                  <span>
                    {imageFileName !== '' ? imageFileName : 'No file chosen'}
                  </span>
                </Fragment>
              </Grid>
              {validateImageFile && (
                <Typography className={classesPromotion.errorMessage}>
                  *Supported image type is jpeg or png format (optional field/no
                  image) and size must less than 15MB!
                </Typography>
              )}
            </>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                className={classesPromotion.textfieldCommon}
                placeholder={''}
                id="promo-related-url"
                data-testid="promo-related-url"
                label="Related Url"
                variant="outlined"
                onChange={handleRelatedUrlField}
              ></TextField>
              {validateRelatedUrl && (
                <Typography className={classesPromotion.errorMessage}>
                  *please provide the proper url link
                </Typography>
              )}
            </Grid>
          </InfoCard>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button
              color="primary"
              variant="contained"
              data-testid="submit-btn"
              onClick={handleSubmit}
              disabled={onLoadDisable}
              className={classesPromotion.submitBtn}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Content>
  );
};
