import React, { useEffect, useState, useContext } from 'react';
import { Grid } from '@material-ui/core';
import { useCustomStyles } from './CardStyles';
import ModifyAdhocPoints from './modifyAdhocPoints';
import { PromotionContext } from '../../../../providers/PromotionDataProvider';
import LeaderBadgesPage from '../../../leaderBoard/leaderBoardContainer';

export const AdhocPointsContainer = ({
  onRouteChanged,
}: {
  onRouteChanged: Function;
}) => {
  const [isModifyClicked, setModifyClicked] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  const classes = useCustomStyles();

  const promotionContextData = useContext(PromotionContext);

  useEffect(() => {
    promotionContextData.setToggleHelpButton(false);
  }, []);

  let routes = [
    {
      type: 'link',
      link: '/',
      text: 'Home',
    },
    {
      type: 'link',
      link: '/admin',
      text: 'Admin',
    },
    {
      type: 'link',
      link: '/admin/gamification-control',
      text: 'Gamification Control',
    },
  ];

  const onclickHandler = (updatedData: any) => {
    routes = [
      ...routes,
      {
        type: 'link',
        link: '/admin/gamification-control/adhoc-points',
        text: 'Adhoc Points',
        // @ts-expect-error("")
        onClick: (): void => {
          setModifyClicked(false);
          setData(undefined);
        },
      },
      {
        type: 'text',
        link: '',
        text: 'Modify Points',
      },
    ];
    onRouteChanged(routes);
    setModifyClicked(true);
    setData(updatedData);
  };

  const handleModifyBack = () => {
    routes = [
      ...routes,
      {
        type: 'text',
        link: '',
        text: 'Adhoc Points',
      },
    ];

    onRouteChanged(routes);
    setModifyClicked(false);
  };

  return (
    <div className={classes.levelAndBadgesContainer}>
      <Grid item xs={12} md={12}>
        {isModifyClicked ? (
          <ModifyAdhocPoints data={data} onBackClicked={handleModifyBack} />
        ) : (
          <LeaderBadgesPage
            isAdhocPointPage={true}
            onclickHandler={onclickHandler}
          />
        )}
      </Grid>
    </div>
  );
};
