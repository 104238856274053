import React, { useMemo } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  sonarQubeApiRef,
  SONARQUBE_PROJECT_KEY_ANNOTATION,
} from '@backstage/plugin-sonarqube-react';
import {
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  EmptyState,
  InfoCard,
  InfoCardVariants,
  MissingAnnotationEmptyState,
  Progress,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { DateTime } from 'luxon';
import BugReport from '@material-ui/icons/BugReport';
// import Lock from '@material-ui/icons/Lock';
// import LockOpen from '@material-ui/icons/LockOpen';
// import Security from '@material-ui/icons/Security';
// import SentimentVeryDissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
// import SentimentVerySatisfied from '@material-ui/icons/SentimentVerySatisfied';
import DataUsage from '@material-ui/icons/DataUsage';
import FilterNone from '@material-ui/icons/FilterNone';
import { Percentage } from './Percentage';
import { Rating } from './Rating';
import { Value } from './Value';
import { useProjectInfo } from './useProjectInfo';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../../apis/notificationApi';
import { DuplicationRating } from './types';
import { CANCEL_AI_RESPONSE_OPTIONS } from '../../../../utils/constant';

const useStyles = makeStyles(theme => ({
  badgeLabel: {
    color: theme.palette.common.white,
  },
  badgeError: {
    margin: 0,
    backgroundColor: theme.palette.error.main,
  },
  badgeSuccess: {
    margin: 0,
    backgroundColor: theme.palette.success.main,
  },
  badgeUnknown: {
    backgroundColor: theme.palette.grey[500],
  },
  header: {
    padding: theme.spacing(2, 2, 2, 2.5),
  },
  action: {
    margin: 0,
  },
  lastAnalyzed: {
    color: theme.palette.text.secondary,
  },
  disabled: {
    backgroundColor: theme.palette.background.default,
  },
  countValueContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  countValue: {
    marginRight: '8px',
  },
}));

const defaultDuplicationRatings: DuplicationRating[] = [
  { greaterThan: 0, rating: '1.0' },
  { greaterThan: 3, rating: '2.0' },
  { greaterThan: 5, rating: '3.0' },
  { greaterThan: 10, rating: '4.0' },
  { greaterThan: 20, rating: '5.0' },
];

const CustomListItem = (props: {
  onClickGoToUrl: string;
  icon: React.ReactNode;
  title: string;
  countOrValue: string | number | undefined;
  rightSideGraphic: React.ReactNode;
}) => {
  const { title, onClickGoToUrl, icon, countOrValue, rightSideGraphic } = props;
  const classes = useStyles();
  return (
    <ListItem button component="a" href={onClickGoToUrl} target="_blank">
      {icon !== undefined && <ListItemIcon>{icon}</ListItemIcon>}

      <ListItemText>{title}</ListItemText>
      <ListItemSecondaryAction>
        <div className={classes.countValueContainer}>
          <div className={classes.countValue}>
            <Value value={countOrValue} />
          </div>

          {rightSideGraphic !== undefined && <>{rightSideGraphic}</>}
        </div>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export const SonarQubeCard = (props: {
  variant?: InfoCardVariants;
  duplicationRatings?: DuplicationRating[];
  selectedBranch: string | undefined;
}) => {
  const {
    variant = 'gridItem',
    duplicationRatings = defaultDuplicationRatings,
    selectedBranch,
  } = props;
  const { entity } = useEntity();
  const sonarQubeApi = useApi(sonarQubeApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const {
    projectKey: projectTitle,
    projectInstance,
    branch,
  } = useProjectInfo(entity);

  const { value, loading, error } = useAsync(
    async () =>
      sonarQubeApi.getFindingSummary({
        componentKey: projectTitle,
        projectInstance: projectInstance,
        // @ts-expect-error
        branch:
          selectedBranch && selectedBranch.includes('default_branch')
            ? 'default_branch'
            : selectedBranch || branch || [],
      }),
    [sonarQubeApi, projectTitle, selectedBranch],
  );

  // if (!loading && error) {
  //   notificationApi.sendNotification({
  //     message: error.message,
  //     severity: 'error',
  //     disapperAfterMs: 2500,
  //   });
  // }

  const deepLink =
    !loading && value
      ? {
          title: 'View more',
          link: value.projectUrl,
        }
      : undefined;

  const classes = useStyles();
  let gateLabel = 'Not computed';
  let gateColor = classes.badgeUnknown;

  let bugCountBlockerAndCritical = 0;
  let BugblockerCount = 0;
  let BugcriticalCount = 0;
  if (value?.componentBugs) {
    value?.componentBugs?.filter((item: any) => {
      if (item?.property === 'severities') {
        item.values.filter((elm: any) => {
          if (elm?.val === 'BLOCKER') {
            BugblockerCount = elm.count;
          } else if (elm?.val === 'CRITICAL') {
            BugcriticalCount = elm.count;
          }
        });
      }
    });

    bugCountBlockerAndCritical = BugblockerCount + BugcriticalCount;

  }

  if (value?.metrics) {
    // @ts-expect-error
    const blockerCount = +(value?.metrics?.blocker_violations || '0');
    // @ts-expect-error
    const criticalCount = +(value?.metrics?.critical_violations || '0');

    const gatePassed = blockerCount + criticalCount === 0;

    gateLabel = gatePassed ? 'Gate passed' : 'Gate failed';
    gateColor = gatePassed ? classes.badgeSuccess : classes.badgeError;
  }

  // if (value?.metrics.alert_status) {
  //   const gatePassed = value.metrics.alert_status === 'OK';
  //   gateLabel = gatePassed ? 'Gate passed' : 'Gate failed';
  //   gateColor = gatePassed ? classes.badgeSuccess : classes.badgeError;
  // }

  const qualityBadge = !loading && value && (
    <Chip
      label={gateLabel}
      classes={{ root: gateColor, label: classes.badgeLabel }}
    />
  );

  const duplicationRating = useMemo(() => {
    if (loading || !value || !value?.metrics?.duplicated_lines_density) {
      return '';
    }

    let rating = '';

    for (const r of duplicationRatings) {
      if (+value.metrics.duplicated_lines_density >= r.greaterThan) {
        rating = r.rating;
      }
    }

    return rating;
  }, [loading, value, duplicationRatings]);

  return (
    <InfoCard
      title="SonarQube"
      subheader={selectedBranch || 'For default branch'}
      deepLink={deepLink}
      variant={variant}
      // headerProps={{
      //   action: qualityBadge,
      //   classes: {
      //     root: classes.header,
      //     action: classes.action,
      //   },
      // }}

      // className={
      //   !loading && (!projectTitle || !value) ? classes.disabled : undefined
      // }
    >
      {loading && <Progress />}

      {!loading && !projectTitle && (
        <MissingAnnotationEmptyState
          annotation={SONARQUBE_PROJECT_KEY_ANNOTATION}
        />
      )}

      {!loading && projectTitle && !value && (
        <EmptyState
          missing="info"
          title="No information to display"
          description={`There is no SonarQube project with key '${projectTitle} ${
            selectedBranch ? `and branch ${selectedBranch}` : ''
          }.`}
        />
      )}

      {!loading && value && (
        <List disablePadding>
          <CustomListItem
            onClickGoToUrl={value.getIssuesUrl('BUG')}
            icon={<BugReport />}
            title="Bugs"
            countOrValue={
              bugCountBlockerAndCritical &&
              bugCountBlockerAndCritical?.toString()
            }
            rightSideGraphic={
              <Rating rating={value?.metrics?.reliability_rating} />
            }
          />
          {/* <CustomListItem
            onClickGoToUrl={value.getIssuesUrl('VULNERABILITY')}
            icon={
              value.metrics.vulnerabilities === '0' ? <Lock /> : <LockOpen />
            }
            title="Vulnerabilities"
            countOrValue={value.metrics.vulnerabilities}
            rightSideGraphic={<Rating rating={value.metrics.security_rating} />}
          /> */}
          {/* <CustomListItem
            onClickGoToUrl={value.getIssuesUrl('CODE_SMELL')}
            icon={
              value.metrics.code_smells === '0' ? (
                <SentimentVerySatisfied />
              ) : (
                <SentimentVeryDissatisfied />
              )
            }
            title="Code Smells"
            countOrValue={value.metrics.code_smells}
            rightSideGraphic={<Rating rating={value.metrics.sqale_rating} />}
          /> */}
          {/* {value.metrics.security_review_rating && (
            <CustomListItem
              onClickGoToUrl={value.getSecurityHotspotsUrl()}
              icon={<Security />}
              title="Hotspots Reviewed"
              countOrValue={
                value.metrics.security_hotspots_reviewed
                  ? `${value.metrics.security_hotspots_reviewed}%`
                  : '-'
              }
              rightSideGraphic={
                <Rating rating={value.metrics.security_review_rating} />
              }
            />
          )} */}
          <CustomListItem
            onClickGoToUrl={value.getComponentMeasuresUrl('COVERAGE')}
            icon={<DataUsage />}
            title="Coverage"
            countOrValue={
              value.metrics.coverage !== undefined
                ? `${value.metrics.coverage}%`
                : '-'
            }
            rightSideGraphic={<Percentage value={value.metrics.coverage} />}
          />
          <CustomListItem
            onClickGoToUrl={value.getComponentMeasuresUrl(
              'DUPLICATED_LINES_DENSITY',
            )}
            icon={<FilterNone />}
            title="Duplications"
            countOrValue={`${value.metrics.duplicated_lines_density || '0'}%`}
            rightSideGraphic={<Rating rating={duplicationRating} hideValue />}
          />
          <ListItem>
            <ListItemText>
              Last analyzed on{' '}
              {DateTime.fromISO(value.lastAnalysis).toLocaleString(
                DateTime.DATETIME_MED,
              )}
            </ListItemText>
          </ListItem>
        </List>
      )}
    </InfoCard>
  );
};
