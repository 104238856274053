import React from 'react';
import { useStyles } from '../../../../../styles/popupStyles';
import { Button, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

interface ErrorValidationSectionProps {
  validationMessage: string;
  onButtonClick: Function;
}

const ErrorValidationSection = ({
  validationMessage,
  onButtonClick,
}: ErrorValidationSectionProps) => {
  const classes = useStyles();

  return (
    <div className={classes.errorContainer}>
      <Typography className={classes.errorHeading}>
        <InfoIcon fontSize="medium" />
        Validation Failed
      </Typography>
      <div className={classes.errorMessage}>{validationMessage}</div>
      <div className={classes.editConfigBtnContainer}>
        <Button
          variant="contained"
          color="primary"
          className={classes.editConfigButton}
          onClick={() => onButtonClick()}
        >
          Edit config
        </Button>
      </div>
    </div>
  );
};

export default ErrorValidationSection;
