import React from 'react';
import { Grid, Button, ListItem } from '@material-ui/core';
import { useCustomStyles } from './leaderBoardStyles';

const LeftFilterSection = ({
  selectedBtn,
  handleCumulative,
  handleClick,
}: any) => {
  const classes = useCustomStyles();

  return (
    <Grid item xs={12} sm={12} md={2} lg={2}>
      <div className={`customFilters ${classes.themeColor}`}>
        <ListItem
          className={
            selectedBtn === 'cumulative' ? 'active' : classes.leftMenuSep
          }
        >
          <Button onClick={() => handleCumulative('cumulative')}>
            Cumulative
          </Button>
        </ListItem>
        <ListItem
          className={selectedBtn === 'monthly' ? 'active' : classes.leftMenuSep}
        >
          <Button onClick={() => handleClick('monthly')}>monthly</Button>
        </ListItem>
        <ListItem
          className={
            selectedBtn === 'quarterly' ? 'active' : classes.leftMenuSep
          }
        >
          <Button onClick={() => handleClick('quarterly')}>quarterly</Button>
        </ListItem>
        <ListItem
          className={selectedBtn === 'y1' ? 'active' : classes.leftMenuSep}
        >
          <Button
            onClick={() => {
              handleClick('y1');
            }}
          >
            Yearly
          </Button>
        </ListItem>
      </div>
    </Grid>
  );
};

export default LeftFilterSection;
