import { useEffect, useState } from 'react';
import { IConfigData } from '../../../../interfaces';

export const useFilteredConfigs = (
  configs: any,
  status: string,
  searchText: string,
  sort: string,
) => {
  const [filteredConfigs, setFilteredConfigs] = useState<IConfigData[]>([]);

  useEffect(() => {
    let localConfigs: IConfigData[] = [];

    if (status === 'All status') localConfigs = [...configs];
    else {
      localConfigs = configs?.filter(
        (config: IConfigData) => config.status === status,
      );
    }

    localConfigs = localConfigs?.filter((config: any) => {
      const logValues = Object.values(config);
      return logValues.some(
        value =>
          typeof value === 'string' &&
          value?.toLowerCase().includes(searchText.toLowerCase()),
      );
    });

    if (sort === 'descending') {
      localConfigs = localConfigs?.sort(
        (a: any, b: any) =>
          (new Date(b.creationTime) as any) - (new Date(a.creationTime) as any),
      );
    }
    if (sort === 'ascending') {
      localConfigs = localConfigs?.sort(
        (a: any, b: any) =>
          (new Date(a.creationTime) as any) - (new Date(b.creationTime) as any),
      );
    }
    setFilteredConfigs(localConfigs);
  }, [configs, status, searchText, sort]);

  return { filteredConfigs };
};
