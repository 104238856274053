import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useCustomStyles = makeStyles((theme: Theme) =>
  createStyles({
    categoryCard: {
      padding: '30px 20px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
      marginBottom: '8px',
      marginLeft: '4px',
      marginRight: '4px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      ['@media (max-width:920px) and (min-width: 280px)']: {
        marginBottom: '0',
      },
    },
    categoryCardLeft: {
      '& svg': {
        width: '60px',
        height: '60px',
      },
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    categoryCardLabel: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginLeft: '10px',
      textTransform: 'capitalize',
    },

    CardContainerFlex: {
      minHeight: '185px',
      height: '95%',
      padding: '30px 20px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
      marginBottom: '16px',
      marginRight: '5px',
      marginLeft: '15px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        width: '90%',
      },
      ['@media (max-width:920px) and (min-width: 280px)']: {
        marginRight: '0',
        marginLeft: '0',
        width: '100%',
        marginBottom: '4px',
      },
    },
    CardContentOwnerText: {},

    CardEntityContainer: {
      padding: '14px 14px',
      minHeight: '70px',
      // height: '70px',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
      marginBottom: '2px',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '95%',
      },
      ['@media (max-width:920px) and (min-width: 280px)']: {
        maxWidth: '100%',
      },
    },
    CardTypeContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        marginTop: '8px',
        justifyContent: 'end',
        alignItems: 'flex-start',
      },
    },
    leaderboardCard: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      ['@media (max-width:920px) and (min-width: 280px)']: {
        flexDirection: 'column',
        alignItems: 'normal',
        paddingTop: '0 !important',
      },
    },
    leaderboardCardTypeContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      ['@media (max-width:920px) and (min-width: 280px)']: {
        flexDirection: 'column',
        alignItems: 'normal',
        paddingTop: '0 !important',
      },
    },
    buttonContainer: {
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'end',
        alignItems: 'flex-end',
      },
    },
    CardTypeContainerFirst: {
      display: 'flex',
      flexDirection: 'column',
      paddingRight: '16px',
      // wordBreak: 'break-all',
    },
    CardTypeFont: {
      fontSize: '11px',
      lineHeight: '16px',
    },

    CardTypeFontBold: {
      fontSize: '14px',
      lineHeight: '18px',
    },
    CardTypeFontPoint: {
      fontSize: '11px',
      lineHeight: '16px',

      color: '#17E88F',
    },

    CardTypeImageAndText: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    leaderBoardProgressBar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'end',
      marginTop: '8px',
    },
    CardTypeText: {
      margin: '0px 16px',
      textTransform: 'capitalize',
      fontSize: '14px',
      lineHeight: '1rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 2,
    },
    IconWithText: {
      alignItems: 'center',
      display: 'flex',
      [theme.breakpoints.down('lg')]: {
        width: '125px',
      },
      [theme.breakpoints.up('lg')]: {
        width: '145px',
      },
    },
    IconText: {
      marginLeft: '-5px',
      fontSize: '0.8rem',
    },
    IconWithTextPlayers: {
      alignItems: 'center',
      display: 'flex',
      marginTop: '16px',
      position: 'absolute',
      bottom: '15px',
      ['@media (max-width:1024px)']: {
        wordBreak: 'break-word',
      },
    },
    ProgressBarDiv: {
      flex: 1,
    },
    progressBarStyle: {
      width: '100%',
      flex: 1,
    },
    ProgressBarLevel: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '11px',
      marginTop: '4px',
    },
    CardTitleText: {
      textTransform: 'capitalize',
      width: '100%',
      fontSize: '14px',
      lineHeight: '1rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 2,
      textAlign: 'center',
    },
    levelEnd: {
      textAlign: 'right',
    },
  }),
);
