/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, createContext } from 'react';

import {
  IFrontendConfigData,
  useFrontendConfig,
} from '../hooks/useFrondendConfig';

const initialState = {
  frontendConfigData: undefined,
};

export interface ITFrontendContext {
  frontendConfigData: IFrontendConfigData | undefined | null;
}

export interface ITProviderProps {
  children: ReactElement;
}

export const FrontendConfigContext =
  createContext<ITFrontendContext>(initialState);

export const FrontendConfigProvider = (props: ITProviderProps) => {
  const [frontendConfigData] = useFrontendConfig(true);

  return (
    <FrontendConfigContext.Provider
      value={{
        frontendConfigData,
      }}
    >
      {props.children}
    </FrontendConfigContext.Provider>
  );
};
