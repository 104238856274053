import React, { useState, useEffect, useContext } from 'react';
import { Entity } from '@backstage/catalog-model';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { ItemCardGrid } from '@backstage/core-components';
import {
  Paper,
  TablePagination,
  InputBase,
  // LinearProgress,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ClearButton from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { AuthContext } from '../../../../providers/AuthProvider';
import AllAssetsCard from './AllAssetsCard';
import * as constant from '../../../utils/constant';
import { NoAssetsFound } from '../../../noAssetsFound/NoAssetsFound';
import { SubscriptionCountProvider } from '../../../common/SubscriptionCount';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      flex: '10',
    },
    PaginationBar: {
      marginTop: '10px',
      marginBottom: '84px',
    },
    ContentHeaderStyle: {
      width: '100%',
      padding: '0px',
      marginBottom: '10px',
      '& > form:first-of-type': {
        display: 'flex',
        '& > button': {
          flex: '0.2',
        },
        '& > div': {
          flex: '10',
        },
      },
    },
    assetsCard: {
      marginTop: '20px',
      paddingTop: '20px',
    },
  }),
);

const AllAssetsCardGrid = ({
  entities,
  anchorElement,
  setAnchorElement,
  isAdmin,
  checkFilterItem,
  starredFilterItem,
  setClickSubscribeIcon,
  isClickSubscribeIcon,
  subscribeData,
  loading,
  mountOn,
  isMyStuffPage,
}: {
  entities: Entity[] | any[];
  anchorElement: any;
  setAnchorElement: any;
  checkFilterItem: any;
  starredFilterItem: any;
  isClickSubscribeIcon: any;
  setClickSubscribeIcon: any;
  subscribeData: any[] | undefined;
  isAdmin: boolean | null;
  loading: boolean | undefined;
  mountOn?: string;
  isMyStuffPage?: boolean | null;
}) => {
  const [filteredEntities, setFilteredEntities] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const classes = useStyles();
  const [_paginatePageNumber, setPaginatePageNumber] = useState(0);
  const _paginateRowsPerPage = 20;
  const [_paginateCount, setPaginateCount] = useState(0);
  const [_matchArray, setMatchArray] = useState<any[]>([]);
  const { profEmail } = useContext(AuthContext);
  const filterString = (str: string) => str.toLowerCase();

  useEffect(() => {
    let filterData = entities
      ? entities.filter(
          (item: any) =>
            constant.COMPONENT_TYPE.includes(item.spec?.type?.toLowerCase()) ||
            item.kind?.toLowerCase() === constant.API.toLowerCase(),
        )
      : [];
    if (checkFilterItem.isCodeFilterClick) {
      filterData =
        entities &&
        entities.filter(
          (item: any) =>
            item.kind?.toLowerCase() === constant.COMPONENT.toLowerCase() &&
            item?.spec?.type?.toLowerCase() !==
              constant.IAC_COMPONENT_TYPE?.toLowerCase() &&
            item?.spec?.type?.toLowerCase() !==
              constant.SALESFORCE_COMPONENT_TYPE.toLowerCase() &&
            (constant.COMPONENT_TYPE.includes(item.spec?.type?.toLowerCase()) ||
              item.kind?.toLowerCase() === constant.API.toLowerCase()),
        );
    }

    if (checkFilterItem.isApiFilterClick) {
      filterData =
        entities &&
        entities.filter(
          (item: any) =>
            item.kind?.toLowerCase() === constant.API.toLowerCase() &&
            (constant.COMPONENT_TYPE.includes(item.spec?.type?.toLowerCase()) ||
              item.kind?.toLowerCase() === constant.API.toLowerCase()),
        );
    }

    if (checkFilterItem.isFavoriteFilterClick) {
      filterData = starredFilterItem;
    }

    if (checkFilterItem.isSubscribeFilterClick) {
      const subscriptionData: any[] = [];
      entities &&
        entities.map((item: any) => {
          subscribeData &&
            subscribeData.map((subscribeItem: any) => {
              if (
                item.metadata?.name.toLowerCase() ===
                  subscribeItem?.assetName.toLowerCase() &&
                subscribeItem?.email.toLowerCase() === profEmail.toLowerCase()
              ) {
                subscriptionData.push(item);
              }
            });
        });

      filterData = subscriptionData;
    }

    if (checkFilterItem.isPackageFilterClick) {
      filterData =
        entities &&
        entities.filter(
          (item: any) =>
            item.kind?.toLowerCase() === constant.COMPONENT?.toLowerCase() &&
            item?.spec?.type?.toLowerCase() ===
              constant.PACKAGE_COMPONENT_TYPE?.toLowerCase() &&
            (constant.COMPONENT_TYPE.includes(item.spec?.type?.toLowerCase()) ||
              item.kind?.toLowerCase() === constant.API.toLowerCase()),
        );
    }

    if (checkFilterItem.isIacFilterClick) {
      filterData =
        entities &&
        entities.filter(
          (item: any) =>
            item.kind?.toLowerCase() === constant.COMPONENT?.toLowerCase() &&
            item?.spec?.type?.toLowerCase() ===
              constant.IAC_COMPONENT_TYPE?.toLowerCase() &&
            (constant.COMPONENT_TYPE.includes(item.spec?.type?.toLowerCase()) ||
              item.kind?.toLowerCase() === constant.API.toLowerCase()),
        );
    }
    if (checkFilterItem.isSalesforceFilterClick) {
      filterData =
        entities &&
        entities.filter(
          (item: any) =>
            item.kind?.toLowerCase() === constant.COMPONENT?.toLowerCase() &&
            item?.spec?.type?.toLowerCase() ===
              constant.SALESFORCE_COMPONENT_TYPE?.toLowerCase() &&
            (constant.COMPONENT_TYPE.includes(item.spec?.type?.toLowerCase()) ||
              item.kind?.toLowerCase() === constant.API.toLowerCase()),
        );
    }

    if (checkFilterItem.isAIFilterClick) {
      filterData =
        entities &&
        entities.filter(
          (item: any) =>
            item.kind?.toLowerCase() === constant.COMPONENT?.toLowerCase() &&
            item?.spec?.type?.toLowerCase() ===
              constant.AI_COMPONENT_TYPE?.toLowerCase(),
        );
    }

    if (checkFilterItem.isExtensionFilterClick) {
      filterData =
        entities &&
        entities.filter(
          (item: any) =>
            item.kind?.toLowerCase() === constant.COMPONENT?.toLowerCase() &&
            item?.spec?.type?.toLowerCase() ===
              constant.EXTENSION_COMPONENT_TYPE?.toLowerCase(),
        );
    }

    if (searchTerm != '') {
      filterData = filterData.filter(entity => {
        let match = false;
        const filteredSearchTerm = filterString(searchTerm);
        if (
          (entity?.spec?.type &&
            filterString(entity?.spec?.type).includes(filteredSearchTerm)) ||
          (entity?.metadata?.name &&
            filterString(entity?.metadata?.name).includes(
              filteredSearchTerm,
            )) ||
          (entity?.spec?.owner &&
            filterString(entity?.spec?.owner).includes(filteredSearchTerm)) ||
          (entity?.metadata?.description &&
            filterString(entity?.metadata?.description).includes(
              filteredSearchTerm,
            )) ||
          (entity?.metadata?.tags &&
            entity?.metadata?.tags.find((tag: string) =>
              tag.toLowerCase().includes(filteredSearchTerm),
            )) ||
          (entity?.metadata?.devxstate &&
            filterString(entity?.metadata?.devxstate).includes(
              filteredSearchTerm,
            )) ||
          (entity?.metadata?.assetstore &&
            filterString(entity?.metadata?.assetstore).includes(
              filteredSearchTerm,
            ))
        ) {
          match = true;
        }
        return match;
      });
    }
    const calcAvgScore = (a: any) => {
      if (!a?.metadata?.rating || a?.metadata?.rating?.length == 0) return 0;
      return (
        a.metadata?.rating?.reduce(
          (a: any, b: any) => (a.value || 0) + (b.value || 0),
          0,
        ) / a.metadata?.rating?.length
      );
    };

    const entityCreationDate = (a: any) => {
      if (!a.metadata?.creationTimestamp) return 0;
      return new Date(a?.metadata?.creationTimestamp).getTime();
    };

    if (checkFilterItem.isTopRatingFilterClick) {
      filterData = filterData.sort(
        (a: any, b: any) => calcAvgScore(b) - calcAvgScore(a),
      );
    }
    else {
      filterData = filterData.sort(
        (a: any, b: any) => entityCreationDate(b) - entityCreationDate(a),
      );
    }

    setPaginatePageNumber(0);
    setMatchArray(filterData || []);
    setPaginateCount(filterData.length);
  }, [checkFilterItem, starredFilterItem, entities, searchTerm]);

  useEffect(() => {
    const begin = _paginatePageNumber * _paginateRowsPerPage;
    const end = begin + _paginateRowsPerPage;
    setFilteredEntities([..._matchArray.slice(begin, end)]);
  }, [_paginatePageNumber, _matchArray]);

  const onPageChange = (
    event: React.SyntheticEvent | null,
    pageNumber: number,
  ): void => {
    if (event) {
      event.preventDefault();
    }
    setPaginatePageNumber(pageNumber);
  };

  return (
    <div>
      <div className={classes.ContentHeaderStyle}>
        <label htmlFor="search-input" hidden>
          Search Here
        </label>
        <Paper component="form">
          <IconButton disabled type="submit" aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            className={classes.input}
            placeholder="Search"
            value={searchTerm}
            onChange={event => {
              setSearchTerm(event?.target?.value);
              return;
            }}
            inputProps={{ 'aria-label': 'search' }}
          />
          <IconButton
            aria-label="search"
            onClick={() => {
              setSearchTerm('');
              return;
            }}
          >
            <ClearButton />
          </IconButton>
        </Paper>
      </div>
      <SubscriptionCountProvider>
        {filteredEntities && filteredEntities?.length ? (
          <>
            <ItemCardGrid>
              {filteredEntities &&
                filteredEntities.map(
                  (entity: Entity, index: number): React.ReactElement => (
                    <AllAssetsCard
                      key={`all-assets-tabbed-card-${index}`}
                      isAdmin={isAdmin}
                      anchorElement={anchorElement}
                      setAnchorElement={setAnchorElement}
                      entity={{ ...entity }}
                      isClickSubscribeIcon={isClickSubscribeIcon}
                      subscribeData={subscribeData}
                      setClickSubscribeIcon={setClickSubscribeIcon}
                      mountOn={mountOn}
                    />
                  ),
                )}
            </ItemCardGrid>
            <div className={classes.PaginationBar}>
              <Paper>
                <TablePagination
                  SelectProps={{
                    title: 'perPageSelect',
                    native: true,
                  }}
                  component="div"
                  page={_paginatePageNumber}
                  rowsPerPage={_paginateRowsPerPage}
                  count={_paginateCount}
                  onPageChange={onPageChange}
                  rowsPerPageOptions={[]}
                />
              </Paper>
            </div>
          </>
        ) : (
          <div className={classes.assetsCard}>
            {loading ? (
              ''
            ) : isMyStuffPage && !searchTerm ? (
              <NoAssetsFound />
            ) : (
              'No Assets Found'
            )}
          </div>
        )}
      </SubscriptionCountProvider>
    </div>
  );
};
export default AllAssetsCardGrid;
