import React, { useEffect, useState, useContext } from 'react';
import * as yup from 'yup';
import { useParams, useNavigate } from 'react-router-dom';
import { Page, Header } from '@backstage/core-components';
import {
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  FormHelperText,
} from '@material-ui/core';
import { datadogRum } from '@datadog/browser-rum';
import { HelpComponent } from '../utils/helpComponent';
import BackButton from '../utils/backButton';
import { useCustomStyles } from './MockStyles';
import { AuthContext } from '../../providers/AuthProvider';
import Constants from './Constants';
import HELP_URL from '../utils/helpLinkConstant';
import { useApi } from '@backstage/core-plugin-api';
import { mockServiceApiRef } from '../../apis/mockServiceApi';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';

export const limitChars = (val: string, limit: number) => {
  if (val) {
    if (val.length > limit) {
      return val.substring(0, limit);
    }
  }
  return val;
};

const RegisterMock = () => {
  const classes = useCustomStyles();
  const { userInfo, profEmail, profileName, isAdmin } = useContext(AuthContext);

  const mockServiceApi = useApi(mockServiceApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const params = useParams();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //api fields
  const [_apiname, setApiName] = useState('');
  const [_apiversion, setApiVersion] = useState<string>('');
  const [_apiendpoint, setApiEndpoint] = useState('');
  const [_apidescription, setApiDescription] = useState('');
  const [_apirequesttype, setApiRequestType] = useState('');
  const [_apirequestbody, setApiRequestBody] = useState<string>('');
  const [_apiresponsebody, setApiResponseBody] = useState<string>('');
  const [_creationdate, setCreationDate] = useState('');
  const [_isdeleted, setIsDeleted] = useState<boolean>(false);
  const [_apiusername, setApiUserName] = useState<string>('');

  let finalEndpoint = '/api/service/mockapi/test/' + _apiendpoint;
  //'/api/service/mockapi/test/' + _apiendpoint + '/' + _apiversion
  // Validation fields

  const [_titleValidationErr, setTitleValidationError] = useState('');
  const [_endpointValidationErr, setEndpointValidationError] = useState('');
  const [_reqBodyValidationErr, setReqBodyValidationError] = useState('');
  const [_resBodyValidationErr, setResBodyValidationError] = useState('');
  const [_versionValidationErr, setVersionValidationError] = useState('');
  const [_typeValidationErr, setTypeValidationError] = useState('');
  const [_descriptionValidationErr, setDescriptionValidationError] =
    useState('');

  const [_showDialog, setShowDialog] = useState(false);
  const [_dialogContent, setDialogContent] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [status, setStatus] = useState('info');

  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/api-mock');
  };

  const userEmail = profEmail?.toLowerCase();

  const handleSubmitMock = () => {
    const data = {
      username: userEmail,
      name: _apiname,
      description: _apidescription,
      endpoint: finalEndpoint.replace(/(\/\/)/g, '/'),
      version: _apiversion,
      requesttype: _apirequesttype,
      requestbody: _apirequestbody.replace(/(\\)/g, ' '),
      requestresponse: _apiresponsebody.replace(/(\\)/g, ' '),
    };

    mockServiceApi
      .saveMock(data)
      .then((respData: any) => {
        setInProgress(false);
        setStatus('success');
        datadogRum.addAction('SubmitMock', {
          id: profEmail,
          name: profileName,
          email: profEmail,
          apiname: _apiname,
          endpoint: finalEndpoint.replace(/(\/\/)/g, '/'),
          requesttype: _apirequesttype,
        });

        notificationApi.sendNotification({
          message: `Mock API ${_apiname} saved successfully, updated version will be available shortly`,
          disapperAfterMs: 2500,
          severity: 'success',
          callback: handleRedirect,
        });
      })
      .catch((err: any) => {
        setStatus('error');

        notificationApi.sendNotification({
          message: `Mock API ${_apiname} did not save successfully, make sure that the response and request body are in valid JSON`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  const handleDeactivateMock = async () => {
    if (params.id) {
      setIsDeleted(true);

      mockServiceApi
        .deactivateMock(userEmail, params.id)
        .then((res: any) => {
          setInProgress(false);
          datadogRum.addAction('DeleteMock', {
            id: profEmail,
            name: profileName,
            email: profEmail,
            apiname: _apiname,
            endpoint: _apiendpoint,
            requesttype: _apirequesttype,
          });
          setStatus('success');

          notificationApi.sendNotification({
            message: `Mock API ${_apiname} deleted successfully, you will now be redirected to the Mock API homepage.`,
            disapperAfterMs: 2500,
            severity: 'success',
            callback: handleRedirect,
          });
        })
        .catch((err: any) => {
          notificationApi.sendNotification({
            message: `Error occurred - ${err?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });
    }
  };

  const handleEditMock = async () => {
    if (params.id) {
      mockServiceApi
        .editMock(
          userEmail,
          params.id,
          _apiname,
          _apidescription,
          _apiendpoint,
          _apiversion,
          _apirequesttype,
          _apirequestbody,
          _apiresponsebody,
        )
        .then((respData: any) => {
          setInProgress(false);
          datadogRum.addAction('EditMock', {
            id: profEmail,
            name: profileName,
            email: profEmail,
            apiname: _apiname,
            endpoint: _apiendpoint,
            requesttype: _apirequesttype,
          });
          setStatus('success');

          notificationApi.sendNotification({
            message: `Mock API ${_apiname} updated successfully, updated version will be available shortly`,
            disapperAfterMs: 2500,
            severity: 'success',
            callback: handleRedirect,
          });
        })
        .catch((err: any) => {
          notificationApi.sendNotification({
            message: `Error occurred - ${err?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });
    }
  };

  const isValidEndpoint = (str: string) => {
    const regTest = /(\/\/)/g;
    if (regTest.test(str)) {
      return false;
    }
    return true;
  };

  const isValidJSONFormat = (str: string) => {
    try {
      JSON.parse(str);
    } catch {
      return false;
    }
    return true;
  };

  ///^\s+|\s+$|\s+(?=\s)/g, ""
  const formatObject = (str: string) => {
    try {
      const parsedString = JSON.parse(str);
      var obj = JSON.stringify(parsedString, null, 2);
      obj = obj.replace(/\\r/g, '');

      return obj;
    } catch {
      return str;
    }
  };

  const prettyPrint = (str: string) => {
    try {
      const parsedString = JSON.parse(str);
      var obj = JSON.stringify(parsedString, null, 2);
      return obj;
    } catch {
      return str;
    }
  };

  useEffect(() => {
    if (params.id) {
      // setIsEdit(true);
      setIsLoading(true);
      mockServiceApi
        .getMockWithId(params.id)
        .then((respData: any) => {
          const responseData = respData.data;
          setIsLoading(false);
          setApiName(responseData.name || '');
          setApiVersion(responseData.version || '');
          setCreationDate(responseData.Creationdatetime);
          setApiDescription(responseData.description || '');
          setApiEndpoint(responseData.endpoint || '');
          setApiRequestType(responseData.requesttype || '');
          setApiRequestBody(responseData.requestbody || '');
          setApiResponseBody(responseData.requestresponse || '');
          setApiUserName(responseData.username);
          setIsDeleted(responseData.isDeleted);
        })
        .catch((err: any) => {
          setIsLoading(false);
          notificationApi.sendNotification({
            message: `Error occurred - ${err?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
            callback: handleRedirect,
          });
        });

      setIsEdit(true);
      //would it be better for admin-related logic to go here?
      if (isAdmin === false && _apiusername.length !== 0) {
        if (_apiusername === userEmail) {
        } else {
          setTimeout(() => {
            handleRedirect();
          }, 1500);
        }
      }
    }
    //Creationdatetime
    //Isdeleted
    return;
  }, [userInfo, _apiusername, isAdmin]);

  useEffect(() => {
    setApiRequestBody(formatObject(_apirequestbody));
    setApiResponseBody(formatObject(_apiresponsebody));

    setApiRequestType(_apirequesttype);
    if (_apirequesttype == 'GET' || _apirequesttype == 'DELETE') {
      let val = formatObject('{}');
      setApiRequestBody(val);
    }
  }, [_apirequestbody, _apirequesttype, _apiresponsebody]);

  //edit by ID - /api-mock-test/edit/:id

  const responsePlaceholder = `{
    "identity": {
      "id": "b06cd03f-75d0-413a-b94b-35e155444d70",
      "login": "John Doe"
    },
    "permissions": {
      "roles": [
        "moderator"
      ]
    }
  }`;

  const reqBodyPlaceholder = `{
    "X-Foo-Bar": "Hello World"
  }`;
  const validateInputs = async () => {
    const valResult = yup
      .string()
      .required('Title is required')
      .min(3, 'At least 3 characters required')
      .max(100, 'Maximum 100 characters')
      .validate(_apiname)
      .then(() => {
        setTitleValidationError('');
        return true;
      })
      .catch(err => {
        setTitleValidationError(err.message);
        return false;
      });
    const versionResult = yup
      .string()
      .required('Version is required')
      .min(1, 'At least 1 characters required')
      .max(3000, 'Maximum 1000 characters')
      .validate(_apiversion)
      .then(() => {
        setVersionValidationError('');
        return true;
      })
      .catch(err => {
        setVersionValidationError(err.message);
        return false;
      });

    const typeResult = yup
      .string()
      .required('Type is required')
      .min(1, 'At least 1 characters required')
      .max(30, 'Maximum 30 characters')
      .validate(_apirequesttype)
      .then(() => {
        setTypeValidationError('');
        return true;
      })
      .catch(err => {
        setTypeValidationError(err.message);
        return false;
      });

    const endpointResult = yup
      .string()
      .required('Endpoint is required')
      .min(1, 'At least 1 character required')
      .max(100, 'Maximum 100 characters')
      .validate(_apiendpoint)
      .then(() => {
        if (isValidEndpoint(_apiendpoint)) {
          setEndpointValidationError('');
          return true;
        }
        setStatus('error');
        setDialogContent(
          `Invalid endpoint, Mock API ${_apiname} unable to save, please input a valid endpoint without double slashes (//).`,
        );
        setShowDialog(true);
      })
      .catch(err => {
        setEndpointValidationError(err.message);
        return false;
      });

    const reqBodyResult = yup
      .string()
      .required('Request body is required')
      .min(1, 'At least 1 characters required')
      .max(30000, 'Maximum 30000 characters')
      .validate(_apirequestbody)
      .then(() => {
        if (_apirequestbody == '{}') {
          setReqBodyValidationError('');
          return true;
        }
        if (isValidJSONFormat(_apirequestbody)) {
          setReqBodyValidationError('');
          return true;
        }
        setStatus('error');
        setDialogContent(
          `Invalid JSON in request body, Mock API ${_apiname} unable to save, please input in valid JSON format.`,
        );
        setShowDialog(true);
      })
      .catch(err => {
        setReqBodyValidationError(err.message);
        return false;
      });

    const resBodyResult = yup
      .string()
      .required('Response body is required')
      .min(1, 'At least 1 characters required')
      .max(30000, 'Maximum 30000 characters')
      .validate(_apiresponsebody)
      .then(() => {
        if (_apiresponsebody == '{}') {
          setResBodyValidationError('');
          return true;
        }
        if (isValidJSONFormat(_apiresponsebody)) {
          setResBodyValidationError('');
          return true;
        }
        setStatus('error');
        setDialogContent(
          `Invalid JSON in response body, Mock API ${_apiname} unable to save, please input in valid JSON format.`,
        );
        setShowDialog(true);
        return;
      })
      .catch(err => {
        setResBodyValidationError(err.message);
        return false;
      });

    const descResult = yup
      .string()
      .required('API description is required')
      .min(3, 'At least 3 characters required')
      .max(500, 'Maximum 500 characters')
      .validate(_apidescription)
      .then(() => {
        setDescriptionValidationError('');
        return true;
      })
      .catch(err => {
        setDescriptionValidationError(err.message);
        return false;
      });
    const allPromises = await Promise.all([
      valResult,
      versionResult,
      endpointResult,
      reqBodyResult,
      resBodyResult,
      typeResult,
      descResult,
    ]);
    return allPromises.reduce((prev, current) => {
      return prev && current;
    });
  };

  const onSubmitRegister = async () => {
    if (isEdit) {
      if (await validateInputs()) {
        handleEditMock();
        setInProgress(true);
      }
    } else {
      if (await validateInputs()) {
        handleSubmitMock();
        setInProgress(true);
      }
    }
  };

  const handleApiRequestType = (event: any) => {
    setApiRequestType(event?.target?.value || '');
  };

  return (
    <Page themeId="mockapi-register">
      <Header
        title={isEdit ? 'Edit Mock' : 'Register Mock'}
        subtitle={
          <DevxBreadCrumb
            routes={[
              {
                type: 'link',
                link: '/',
                text: 'Home',
              },
              {
                type: 'link',
                link: '/api-mock',
                text: 'Mock APIs',
              },
              {
                type: 'text',
                link: '',
                text: isEdit ? 'Edit' : 'Register',
              },
            ]}
          />
        }
      />
      <div className={classes.feedbackPageContainer}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.feedbackPageSearch}
          >
            <BackButton />
            <HelpComponent
              helpUrl={isEdit ? HELP_URL.editMock : HELP_URL.registerMock}
            />
          </Grid>
        </Grid>
        {isLoading && <LinearProgress />}
        <Grid
          container
          direction="row"
          className={classes.FeedbackExtContentStyle}
        >
          <div className={classes.FeedbackExtGridStyle}>
            <section>
              <div>
                {isEdit
                  ? 'Filled out form fields, edit the form below to edit mock and save'
                  : 'Fill out some details regarding the API'}
              </div>

              {!isEdit && (
                <>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      // disabled={isEdit}
                      // className={classes.Feedbackfields}
                      placeholder="Type API name here..."
                      id="api-name"
                      data-testid="api-name"
                      label="API name"
                      onChange={event => {
                        const value = limitChars(event?.target?.value, 200);
                        setApiName(value);
                      }}
                      required
                      {...(_titleValidationErr && {
                        error: true,
                        helperText: _titleValidationErr,
                      })}
                      value={_apiname}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      disabled={isEdit}
                      className={classes.Feedbackfields}
                      placeholder="Type API endpoint here (/api/service/mockapi/test/$endpoint)"
                      id="api-endpoint"
                      data-testid="api-endpoint"
                      label="API endpoint"
                      onChange={event => {
                        const value = limitChars(event?.target?.value, 200);
                        setApiEndpoint(value);
                      }}
                      required
                      {...(_endpointValidationErr && {
                        error: true,
                        helperText: _endpointValidationErr,
                      })}
                      value={_apiendpoint}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      disabled={isEdit}
                      className={classes.Feedbackfields}
                      placeholder="Short description of API..."
                      id="api-description"
                      data-testid="api-description"
                      label="API description"
                      required
                      onChange={event => {
                        const value = limitChars(event?.target?.value, 200);
                        setApiDescription(value);
                      }}
                      {...(_descriptionValidationErr && {
                        error: true,
                        helperText: _descriptionValidationErr,
                      })}
                      value={_apidescription}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      disabled={isEdit}
                      className={classes.Feedbackfields}
                      placeholder="API version..."
                      id="api-version"
                      data-testid="api-version"
                      label="API version"
                      required
                      onChange={event => {
                        //const value = event.target.value.map(e=>JSON.stringify(e).replace(/{|}/g,'').join(',\n'))
                        const value = limitChars(event?.target?.value, 200);
                        setApiVersion(value);
                      }}
                      {...(_versionValidationErr && {
                        error: true,
                        helperText: _versionValidationErr,
                      })}
                      value={_apiversion}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      required
                      {...(_typeValidationErr && {
                        error: true,
                      })}
                    >
                      <InputLabel id="some-id">Type</InputLabel>
                      <Select
                        labelId="some-id"
                        label="Type"
                        id="feedback-register-types"
                        data-testid="feedback-register-types"
                        onChange={handleApiRequestType}
                        disabled={isEdit}
                        value={_apirequesttype || ''}
                        variant="outlined"
                      >
                        {Constants.requestTypeOptions.map(option => (
                          <MenuItem value={option} key={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{_typeValidationErr}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <div>
                    {_apirequesttype == 'POST' && (
                      <>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            fullWidth
                            className={classes.Feedbackfields}
                            disabled={isEdit}
                            required
                            placeholder={reqBodyPlaceholder}
                            InputProps={{
                              minRows: 10,
                              maxRows: 10,
                            }}
                            id="feedback-text"
                            data-testid="feedback-text"
                            label="JSON request body"
                            multiline
                            onChange={event => {
                              //const value = limitChars(event?.target?.value, 1000);
                              let value = event.target.value;
                              setApiRequestBody(formatObject(value));
                              //setApiRequestBody(JSON.stringify(JSON.parse(value), null, 2));
                            }}
                            {...(_reqBodyValidationErr && {
                              error: true,
                              helperText: _reqBodyValidationErr,
                            })}
                            value={prettyPrint(_apirequestbody)}
                            variant="outlined"
                          />
                        </Grid>
                      </>
                    )}
                    {_apirequesttype == 'PATCH' && (
                      <>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            fullWidth
                            className={classes.Feedbackfields}
                            disabled={isEdit}
                            placeholder={reqBodyPlaceholder}
                            InputProps={{
                              minRows: 10,
                              maxRows: 10,
                            }}
                            id="feedback-text"
                            data-testid="feedback-text"
                            label="JSON request body"
                            multiline
                            required
                            onChange={event => {
                              //const value = limitChars(event?.target?.value, 1000);
                              let value = event.target.value;
                              setApiRequestBody(formatObject(value));
                              //setApiRequestBody(JSON.stringify(JSON.parse(value), null, 2));
                            }}
                            {...(_reqBodyValidationErr && {
                              error: true,
                              helperText: _reqBodyValidationErr,
                            })}
                            value={prettyPrint(_apirequestbody)}
                            variant="outlined"
                          />
                        </Grid>
                      </>
                    )}

                    {_apirequesttype == 'PUT' && (
                      <>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            fullWidth
                            className={classes.Feedbackfields}
                            disabled={isEdit}
                            placeholder={reqBodyPlaceholder}
                            required
                            InputProps={{
                              minRows: 10,
                              maxRows: 10,
                            }}
                            id="feedback-text"
                            data-testid="feedback-text"
                            label="JSON request body"
                            multiline
                            onChange={event => {
                              //const value = limitChars(event?.target?.value, 1000);
                              let value = event.target.value;
                              setApiRequestBody(formatObject(value));
                              //setApiRequestBody(JSON.stringify(JSON.parse(value), null, 2));
                            }}
                            {...(_reqBodyValidationErr && {
                              error: true,
                              helperText: _reqBodyValidationErr,
                            })}
                            value={prettyPrint(_apirequestbody)}
                            variant="outlined"
                          />
                        </Grid>
                      </>
                    )}

                    {_apirequesttype == 'GET' && <></>}

                    {_apirequesttype == 'DELETE' && <></>}
                  </div>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      className={classes.Feedbackfields}
                      disabled={isEdit}
                      required
                      placeholder={responsePlaceholder}
                      InputProps={{
                        minRows: 10,
                        maxRows: 10,
                      }}
                      id="feedback-text"
                      data-testid="response-body"
                      label="JSON response body"
                      multiline
                      onChange={event => {
                        let value = event.target.value; //const value = limitChars(event?.target?.value, 500);
                        setApiResponseBody(formatObject(value));
                      }}
                      {...(_resBodyValidationErr && {
                        error: true,
                        helperText: _resBodyValidationErr,
                      })}
                      value={prettyPrint(_apiresponsebody)}
                      variant="outlined"
                    />
                    {/* <pre>
                        {formatObject(_apiresponsebody)}
                      </pre> */}
                  </Grid>

                  {/* <div>
                      <TextField
                              style={{ width: '100%', maxWidth: '524px' }}
                              disabled={isEdit}
                        placeholder={responsePlaceholder}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          minRows: 10,
                          maxRows: 10,
                          style: { height: '200px' },
                        }}
                        id="feedback-text"
                        label="JSON response body testing"
                        multiline
                        onChange={event => {
                          let value = event.target.value                      //const value = limitChars(event?.target?.value, 500);
                          setResBodyTest(formatObject(value));
                        }}
                        helperText={_resBodyValidationErr || ''}
                        FormHelperTextProps={{ style: { color: 'red' } }}
                        value={formatObject(_resbodytest) || ''}
                        variant="outlined"
                      />
                      <pre>
                        {formatObject(_resbodytest)}
                      </pre>

                    </div>

                         */}
                </>
              )}

              {isEdit && (
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      disabled={!isEdit}
                      className={classes.Feedbackfields}
                      placeholder="Type API name here"
                      id="api-name"
                      required
                      label="API name"
                      onChange={event => {
                        setApiName(event.target.value);
                      }}
                      {...(_titleValidationErr && {
                        error: true,
                        helperText: _titleValidationErr,
                      })}
                      value={_apiname}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      disabled={!isEdit}
                      className={classes.Feedbackfields}
                      placeholder="Type API endpoint here (/api/service/mockapi/test/$endpoint)"
                      id="api-endpoint"
                      label="API endpoint"
                      onChange={event => {
                        const value = limitChars(event?.target?.value, 200);
                        setApiEndpoint(value);
                      }}
                      required
                      {...(_endpointValidationErr && {
                        error: true,
                        helperText: _endpointValidationErr,
                      })}
                      value={_apiendpoint}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      disabled={!isEdit}
                      className={classes.Feedbackfields}
                      placeholder="Short description of API..."
                      required
                      id="api-description"
                      label="API description"
                      onChange={event => {
                        const value = limitChars(event?.target?.value, 200);
                        setApiDescription(value);
                      }}
                      {...(_descriptionValidationErr && {
                        error: true,
                        helperText: _descriptionValidationErr,
                      })}
                      value={_apidescription}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      disabled={!isEdit}
                      className={classes.Feedbackfields}
                      placeholder="API version..."
                      required
                      id="api-version"
                      label="API version"
                      onChange={event => {
                        //const value = event.target.value.map(e=>JSON.stringify(e).replace(/{|}/g,'').join(',\n'))
                        const value = limitChars(event?.target?.value, 200);
                        setApiVersion(value);
                      }}
                      {...(_versionValidationErr && {
                        error: true,
                        helperText: _versionValidationErr,
                      })}
                      value={_apiversion}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined" required>
                      <InputLabel id="type-label">Type</InputLabel>

                      <Select
                        labelId="type-label"
                        label="Type"
                        name="Type"
                        id="feedback-register-types"
                        onChange={handleApiRequestType}
                        disabled={!isEdit}
                        value={_apirequesttype || ''}
                      >
                        {Constants.requestTypeOptions.map(option => (
                          <MenuItem value={option} key={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    {_apirequesttype == 'POST' && (
                      <>
                        <TextField
                          fullWidth
                          className={classes.Feedbackfields}
                          disabled={!isEdit}
                          placeholder={reqBodyPlaceholder}
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            minRows: 10,
                            maxRows: 10,
                          }}
                          id="feedback-text"
                          label="JSON request body"
                          multiline
                          onChange={event => {
                            //const value = limitChars(event?.target?.value, 1000);
                            let value = event.target.value; //const value = limitChars(event?.target?.value, 500);
                            setApiRequestBody(value);
                          }}
                          {...(_reqBodyValidationErr && {
                            error: true,
                            helperText: _reqBodyValidationErr,
                          })}
                          value={prettyPrint(_apirequestbody) || ''}
                          variant="outlined"
                        />
                      </>
                    )}

                    {_apirequesttype == 'PATCH' && (
                      <>
                        <TextField
                          fullWidth
                          className={classes.Feedbackfields}
                          disabled={!isEdit}
                          placeholder={reqBodyPlaceholder}
                          required
                          InputProps={{
                            minRows: 10,
                            maxRows: 10,
                          }}
                          id="feedback-text"
                          label="JSON request body"
                          multiline
                          onChange={event => {
                            //const value = limitChars(event?.target?.value, 1000);
                            let value = event.target.value; //const value = limitChars(event?.target?.value, 500);
                            setApiRequestBody(value);
                          }}
                          {...(_reqBodyValidationErr && {
                            error: true,
                            helperText: _reqBodyValidationErr,
                          })}
                          value={prettyPrint(_apirequestbody) || ''}
                          variant="outlined"
                        />
                      </>
                    )}

                    {_apirequesttype == 'PUT' && (
                      <>
                        <TextField
                          fullWidth
                          className={classes.Feedbackfields}
                          disabled={!isEdit}
                          placeholder={reqBodyPlaceholder}
                          required
                          InputProps={{
                            minRows: 10,
                            maxRows: 10,
                          }}
                          id="feedback-text"
                          data-testid="feedback-text"
                          label="JSON request body"
                          multiline
                          onChange={event => {
                            //const value = limitChars(event?.target?.value, 1000);
                            let value = event.target.value; //const value = limitChars(event?.target?.value, 500);
                            setApiRequestBody(value);
                          }}
                          {...(_reqBodyValidationErr && {
                            error: true,
                            helperText: _reqBodyValidationErr,
                          })}
                          value={prettyPrint(_apirequestbody) || ''}
                          variant="outlined"
                        />
                      </>
                    )}
                    {_apirequesttype == 'DELETE' && <></>}
                    {_apirequesttype == 'GET' && <></>}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      className={classes.Feedbackfields}
                      disabled={!isEdit}
                      required
                      placeholder={responsePlaceholder}
                      InputProps={{
                        minRows: 10,
                        maxRows: 10,
                      }}
                      id="feedback-text"
                      label="JSON response body"
                      multiline
                      onChange={event => {
                        let value = event.target.value; //const value = limitChars(event?.target?.value, 500);
                        setApiResponseBody(formatObject(value));
                      }}
                      {...(_resBodyValidationErr && {
                        error: true,
                        helperText: _resBodyValidationErr,
                      })}
                      value={prettyPrint(_apiresponsebody) || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <div>{/* {_isdeleted ? "Deactivated": "Activated"} */}</div>
                </Grid>
              )}

              <Grid item xs={12} sm={12} className={classes.mockSubmitBtn}>
                {isEdit && (
                  <>
                    <Button
                      className={classes.submitRegisterBtn}
                      onClick={handleDeactivateMock}
                    >
                      {_isdeleted ? 'Delete' : 'Delete'}
                    </Button>
                  </>
                )}
                <Button
                  className={classes.submitRegisterBtn}
                  variant="contained"
                  color="primary"
                  data-testid="save-btn"
                  onClick={onSubmitRegister}
                >
                  {isEdit ? 'Save' : 'Submit'}
                </Button>
              </Grid>
            </section>

            <section className={classes.FeedbackDesc}>
              <div className="feedback-info-title">
                {isEdit ? 'Edit your mock' : 'Register your Mock'}
              </div>
              <div className="feedback-spacer" />
              <div className="feedback-info">
                <section>
                  {' '}
                  {
                    'API endpoint: what would be the endpoint to access the mock? Note: All endpoints are already pre-fixed with /api/service/mockapi/test'
                  }
                </section>
                <section className="reg-mt20">
                  {
                    'API version: if this is an iteration of a mock, name it like 1.x'
                  }
                </section>
                <section>
                  {
                    'API request body: Payload for POST,DELETE,PUT,PATCH requests. Write the body on another text editor and copy paste for proper formatting.'
                  }
                </section>
                <section className="reg-mt20">
                  {
                    'API response body: expected response from running the request.  Write the response body on another text editor and copy paste for proper formatting.'
                  }
                </section>
              </div>
            </section>
          </div>
        </Grid>
      </div>
    </Page>
  );
};
export default RegisterMock;
