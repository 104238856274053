interface EntityInfo {
  kind: string;
  namespace: string;
  name: string;
}

/**
 * Utility method to create a URL pattern pointing to view capability details page for input entity
 * @param entityInfo
 */
export const getPathToViewTaxonomyPage = (entityInfo: EntityInfo) => {
  return `/taxonomy/view/${entityInfo.kind || 'Group'}/${
    entityInfo.namespace || 'default'
  }/${entityInfo.name || ''}`;
};
