import { Entity, RELATION_OWNED_BY } from '@backstage/catalog-model';
import {
  Box,
  Checkbox,
  FormControlLabel,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useMemo, useState } from 'react';
// import { useEntityList } from '../../hooks/useEntityListProvider';
// import { EntityOwnerFilter } from '../../filters';
// import { getEntityRelations } from '../../utils';
// import { humanizeEntityRef } from '../EntityRefLink';

import {
  humanizeEntityRef,
  getEntityRelations,
  EntityOwnerFilter,
  useEntityList,
} from '@backstage/plugin-catalog-react';

/** @public */
export type CatalogReactEntityOwnerPickerClassKey = 'input';

const useStyles = makeStyles(
  {
    input: {},
  },
  {
    name: 'CatalogReactEntityOwnerPicker',
  },
);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/** @public */
export const EntityOwnerPicker = () => {
  const classes = useStyles();
  const { updateFilters, backendEntities, filters, queryParameters } =
    useEntityList();

  const queryParamOwners = useMemo(
    () => [queryParameters.owners].flat().filter(Boolean) as string[],
    [queryParameters],
  );

  const [selectedOwners, setSelectedOwners] = useState(
    queryParamOwners.length ? queryParamOwners : filters.owners?.values ?? [],
  );

  // Set selected owners on query parameter updates; this happens at initial page load and from
  // external updates to the page location.

  //   useEffect(() => {
  //     const selectedOwnersAddUser =
  //       selectedOwners &&
  //       selectedOwners?.map(item => {
  //         const customItem = item.replace('user:', '');
  //         return `user:${customItem}`;
  //       });
  //     updateFilters({
  //       owners: selectedOwners.length
  //         ? new EntityOwnerFilter(selectedOwnersAddUser)
  //         : undefined,
  //     });
  //   }, [selectedOwners, updateFilters]);

  useEffect(() => {
    if (queryParamOwners.length) {
      const selectedOwnersAddUser =
        queryParamOwners &&
        queryParamOwners?.map(item => {
          const customItem = item.replace('user:', '');
          return `user:${customItem}`;
        });

      setSelectedOwners(selectedOwnersAddUser);
    }
  }, [queryParamOwners]);

  useEffect(() => {
    updateFilters({
      owners: selectedOwners.length
        ? new EntityOwnerFilter(selectedOwners)
        : undefined,
    });
  }, [selectedOwners, updateFilters]);

  let availableOwners = useMemo(
    () =>
      [
        ...new Set(
          backendEntities
            .flatMap((e: Entity) =>
              getEntityRelations(e, RELATION_OWNED_BY).map(o =>
                humanizeEntityRef(o, { defaultKind: 'group' }),
              ),
            )
            .filter(Boolean) as string[],
        ),
      ].sort(),
    [backendEntities],
  );

  availableOwners =
    availableOwners &&
    availableOwners.map(item => item && item.replace('user:', ''));

  if (!availableOwners.length) return null;

  return (
    <Box pb={1} pt={1}>
      <Typography variant="button">Owner</Typography>
      <Autocomplete
        multiple
        aria-label="Owner"
        options={availableOwners}
        value={
          selectedOwners &&
          selectedOwners?.map(item => item && item.replace('user:', ''))
        }
        onChange={(_: object, value: string[]) =>
          setSelectedOwners(value && value?.map(item => `user:${item}`))
        }
        renderOption={(option, { selected }) => (
          <FormControlLabel
            control={
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                checked={selected}
              />
            }
            label={option}
          />
        )}
        size="small"
        popupIcon={<ExpandMoreIcon data-testid="owner-picker-expand" />}
        renderInput={params => (
          <TextField {...params} className={classes.input} variant="outlined" />
        )}
      />
    </Box>
  );
};
