import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /* Layout Page Styles starts */

    layoutPage: {
      [theme.breakpoints.down('sm')]: {
        padding: '0px',
      },
    },

    /* Layout Page Styles ends */

    /* Dashboard Page Styles starts */

    dashboardLayoutPage: {
      [theme.breakpoints.down('sm')]: {
        padding: '0px',
      },
    },
    dashboardCard: {
      background: theme.palette.background.paper,
      padding: '24px',
      [theme.breakpoints.down('sm')]: {
        padding: '10px',
      },
    },
    dashboard: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
    },
    dashboardTitle: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: ' 32px',
      color: theme.palette.text.secondary,
    },
    dashboardDesc: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: ' 16px',
      color: theme.palette.text.tertiary,
      marginTop: '8px',
    },
    pbiDashboard: {
      width: '100%',
    },
    maxminBut: {
      position: 'absolute',
      right: '50px',
      top: '20px',
      borderRadius: '4px',
      background: 'var(--primary-green-primary-light-2, #DFE6E5)',
      color: theme.palette.text.primary,
      boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
      '&:hover': {
        background: 'var(--primary-green-primary-light-2, #DFE6E5)',
      },
    },
    alertBox: {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.warningBackground,
      border: `1px solid ${theme.palette.warning.main}`,
      borderLeft: `3px solid ${theme.palette.warning.main}`,
    },
    anchorTag: {
      textDecoration: 'underline',
    },

    /* Dashboard Page Styles ends */

    /* DataInsight Card Page Styles starts */

    utilityCard: {
      backgroundColor: theme.palette.background.paper,
      cursor: 'pointer',
      borderRadius: '8px',
      display: 'flex',
      height: '80px',
      width: '256px',
      alignItems: 'center',
      boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.14)',
    },
    placeholderImage: {
      width: '87px',
      height: '56px',
      marginRight: '5px',
    },
    image: {
      width: '60px',
      height: '50px',
    },
    dataInsightTool: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '20px',
    },

    /* DataInsight Card Page Styles starts */
  }),
);
