import { createApiRef } from '@backstage/core-plugin-api';
import { AxiosInstance } from 'axios';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';

export interface IReleaseNotesPostRequest {
  vtype: 'Major' | 'Minor' | undefined;
  vnum: string;
  vname: string;
  releasedate?: string;
  overview?: string;
  displaystate?: 'true' | 'false';
  description?: string;
  additionaldata?: string;
}

export interface IReleaseNotesResponse {
  data: [IReleaseNotes];
  status?: number;
}

export interface IReleaseNoteResponse {
  data: IReleaseNotes;
  stats?: number;
}

export interface IReleaseNotes {
  vtype: 'Major' | 'Minor' | undefined;
  vnum: string;
  vname: string;
  releasedate?: string;
  overview?: string;
  displaystate?: 'true' | 'false';
  description?: string;
  additionaldata?: string;
  id: string;
  Isactive: boolean;
  createdat?: string;
  updatedat?: string;
  tags?: string[];
}

export interface ReleaseNoteApi {
  getAllNotes: () => Promise<never[] | IReleaseNotes>;
  getNoteById: (id: string) => Promise<IReleaseNotes | undefined>;
  updateNote: (id: string, data: IReleaseNotesPostRequest) => Promise<any>;
  createNote: (data: IReleaseNotesPostRequest) => Promise<any>;
}

export const releaseNoteApiRef = createApiRef<ReleaseNoteApi>({
  id: 'cbre.devx.api.releasenote',
});

export class ReleaseNoteApiImpl implements ReleaseNoteApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  async getAllNotes() {
    const instance = await this.axiosInstancePromise;
    return instance
      .get('service/releasenotes')
      .then(({ data }: { data: IReleaseNoteResponse }) => data.data)
      .catch((err: any) => {
        console.log(`Failed to fetch all notes with error `, err);
        return [];
      });
  }

  async getNoteById(id: string) {
    const instance = await this.axiosInstancePromise;
    return instance
      .get('service/releasenotes/' + id)
      .then(({ data }: { data: IReleaseNoteResponse }) => data.data);
  }

  async updateNote(id: string, data: IReleaseNotesPostRequest) {
    const instance = await this.axiosInstancePromise;
    return instance
      .put('service/releasenotes/' + id, { ...data })
      .then((res: IReleaseNoteResponse) => res.data);
  }

  async createNote(data: IReleaseNotesPostRequest) {
    const instance = await this.axiosInstancePromise;
    return instance
      .post('/service/releasenotes', { ...data })
      .then((res: IReleaseNoteResponse) => res.data);
  }
}
