import { useEffect, useMemo, useRef, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import isEqual from 'lodash/isEqual';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef, useEntityList } from '@backstage/plugin-catalog-react';
import { ApiTypeFilter } from './filter';

type EntityTypeReturn = {
  loading: boolean;
  error?: Error;
  availableTypes: string[];
  selectedTypes: string[];
  setSelectedTypes: (types: string[]) => void;
};

type Item = {
  metadata?: Metadata;
};

type Metadata = {
  assetstore?: string;
};

export function useApiEntityTypeFilter(): EntityTypeReturn {
  const catalogApi = useApi(catalogApiRef);
  const {
    filters: { kind: kindFilter, type: typeFilter },
    queryParameters,
    updateFilters,
  } = useEntityList();

  const queryParamTypes = [queryParameters.type]
    .flat()
    .filter(Boolean) as string[];
  const [selectedTypes, setSelectedTypes] = useState(
    queryParamTypes.length ? queryParamTypes : typeFilter?.getTypes() ?? [],
  );

  const [availableTypes, setAvailableTypes] = useState<string[]>([]);
  const kind = useMemo(() => kindFilter?.value, [kindFilter]);

  const {
    error,
    loading,
    value: entities,
  } = useAsync(async () => {
    if (kind) {
      const items = await catalogApi
        .getEntities({
          filter: { kind: 'api' },
          fields: ['metadata.assetstore'],
        })
        .then((response: any) =>
          response.items.map((item: Item) => {
            if (!item.metadata) return { metadata: { assetstore: 'wso2' } };
            return item;
          }),
        )
        .catch(() => {});
      return items;
    }
    return [];
  }, [kind, catalogApi]);

  const entitiesRef = useRef(entities);
  useEffect(() => {
    const oldEntities = entitiesRef.current;
    entitiesRef.current = entities;
    if (loading || !kind || oldEntities === entities) {
      return;
    }
    if (!entities) return;

    // Sort by entity count descending, so the most common types appear on top
    const countByType = entities.reduce((acc: any, entity: any) => {
      if (typeof entity.metadata?.assetstore !== 'string') return acc;

      const entityType = entity.metadata?.assetstore;
      if (!acc[entityType]) {
        acc[entityType] = 0;
      }
      acc[entityType] += 1;
      return acc;
    }, {} as Record<string, number>);

    const newTypes = Object.entries(countByType)
      // @ts-ignore
      .sort(([, count1], [, count2]) => count2 - count1)
      .map(([type]) => type);
    setAvailableTypes(newTypes);

    // Update type filter to only valid values when the list of available types has changed
    const stillValidTypes = selectedTypes.filter((value: any) =>
      newTypes.includes(value),
    );
    if (!isEqual(selectedTypes, stillValidTypes)) {
      setSelectedTypes(stillValidTypes);
    }
  }, [loading, kind, selectedTypes, setSelectedTypes, entities]);

  useEffect(() => {
    updateFilters({
      // @ts-ignore
      ['apiType']: selectedTypes.length
        ? new ApiTypeFilter(selectedTypes)
        : undefined,
    });
  }, [selectedTypes, updateFilters]);

  return {
    loading,
    error,
    availableTypes,
    selectedTypes,
    setSelectedTypes,
  };
}
