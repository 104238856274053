import React, { useState } from 'react';
// @ts-ignore
import { useApi } from '@backstage/core-plugin-api';
import Suggest from './Suggest';
import * as _ from 'lodash';
import { capabilityTaxonomyApiRef } from '../../../../apis/capabilityTaxonomyApi';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';

export interface UserSuggestProps {
  label: string;
  value: any;
  onChange: (params: any) => void;
  id: string;
}

const UserSuggest = ({ label, value, onChange, id }: UserSuggestProps) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const capabilityTaxonomyApi = useApi(capabilityTaxonomyApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const getOptionsFromApi = (value: any) => {
    setLoading(true);
    capabilityTaxonomyApi
      .getUserSuggestions(value)
      .then((respData: any) => {
        if (respData?.value && respData?.value?.length > 0) {
          const data = respData?.value?.map((u: any) => {
            return { label: u?.mail, value: u?.mail };
          });
          setOptions(data || []);
          setLoading(false);
        } else {
          setOptions([]);
          setLoading(false);
        }
      })
      .catch((err: any) => {
        setOptions([]);
        notificationApi.sendNotification({
          message: `Error occurred while getting user suggestions: ${err.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
    setLoading(false);
  };

  const onInputChange = (value: any) => {
    getOptionsFromApi(value);
  };

  // @ts-ignore
  const onValueChange = (event: any, value: any, reason: string) => {
    if (onChange) {
      if (value && value.length > 0) {
        let val = JSON.stringify(value);
        onChange(val);
      } else {
        onChange('[]');
      }
    }
  };

  if (!_.isString(value) && onChange) {
    onValueChange(undefined, value, '');
  }

  const getOptionLabel = (opt: any) => {
    return opt?.label || '';
  };

  return (
    <Suggest
      loading={loading}
      options={options}
      label={label}
      value={value}
      id={id}
      onInputChangeDebounced={onInputChange}
      onChange={onValueChange}
      getOptionLabel={getOptionLabel}
      multiple={true}
    />
  );
};
export default UserSuggest;
