import React from 'react';
import CodeIcon from '../../Root/icons/codeIcon';
import ExtensionIcon from '@material-ui/icons/Extension';
import InfoIcon from '@material-ui/icons/Info';
import SalesforceIcon from '../../Root/icons/salesforceIcon';

const AssetTypeIcon = ({ assetType = 'Component' }) => {
  if ('openapi' === `${assetType}`.toLowerCase()) {
    return <ExtensionIcon />;
  }

  if ('iac' === `${assetType}`.toLowerCase()) {
    return <InfoIcon />;
  }
  if ('salesforce' === `${assetType}`.toLowerCase()) {
    return <SalesforceIcon />;
  }

  return <CodeIcon />;
};
export default AssetTypeIcon;
