import React, { useEffect } from 'react';
import {
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  Button,
} from '@material-ui/core';
import StatisticsCardList from './StatisticsCardList';
import AiAssistantList from './AiAssistantList';
import classNames from 'classnames';
import { useStyles } from '../styles';

import AiServicesSmall from './AiServicesSmall';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { IPhaseCard, IStatisticsCard } from '../types';
import { StatisticsCardProps } from './StatisticsCard';

const PhaseCard = ({
  id,
  image,
  title,
  description,
  statisticsLink,
  aiServicesLink,
  aiServices,
  statistics,
}: IPhaseCard) => {
  const classes = useStyles();

  const [isExpanded, setIsExpanded] = React.useState(false);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setIsExpanded(isMobile);
  }, [isMobile]);

  const [formatedStatistics, setFormatedStatistics] = React.useState<
    StatisticsCardProps[]
  >([]);

  const getIcon = (statName: string) => {
    return (
      aiServices.find((service: any) => service.uniqueId === statName)
        ?.iconSrc || ''
    );
  };

  useEffect(() => {
    const newStatistics: StatisticsCardProps[] = statistics.map(
      (stat: IStatisticsCard) => {
        return {
          ...stat,
          iconSrc: getIcon(stat.name),
        };
      },
    );
    setFormatedStatistics(newStatistics);
  }, [statistics, aiServices]);

  const getExpandClass = () => {
    return statistics.length > 0
      ? `${classes.expanded} ${classes.height500}`
      : `${classes.expanded} ${classes.height300}`;
  };

  return (
    <Box component="section" className={classNames(classes.section)}>
      <Box
        component="header"
        className={classNames(classes.phaseHeader)}
        onClick={() => {
          handleClick();
        }}
      >
        {!isMobile && (
          <img
            loading="lazy"
            src={image}
            alt={title}
            className={classes.headerImage}
          />
        )}
        <Box className={classes.contentWrapper}>
          <Box className={classes.textContent}>
            <Typography variant="h2" className={classes.title}>
              {title}
            </Typography>
            <Typography variant="body1" className={classes.description}>
              {description}
            </Typography>
          </Box>
          {!isMobile && (
            <AiServicesSmall
              text="AI services included:"
              aiServices={aiServices}
            ></AiServicesSmall>
          )}
        </Box>
        {!isMobile ? (
          <Button
            aria-label="expand"
            onClick={handleClick}
            variant="contained"
            className={classes.phaseToggleButton}
          >
            <AddRoundedIcon
              fontSize="large"
              className={classNames(
                classes.transition1second,
                isExpanded ? classes.rotateMinus45 : classes.rotateZero,
              )}
            />
          </Button>
        ) : null}
      </Box>

      <Box
        component="main"
        className={classNames(
          classes.phaseBody,
          isExpanded ? getExpandClass() : classes.collapsed,
        )}
      >
        {statistics.length > 0 ? (
          <StatisticsCardList
            statistics={formatedStatistics}
            link={statisticsLink}
          />
        ) : null}
        <AiAssistantList
          aiServices={aiServices}
          link={aiServicesLink}
          isMobile={isMobile}
        />
      </Box>
    </Box>
  );
};

export default PhaseCard;
