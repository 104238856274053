import React, { useEffect, useState } from 'react';

export const useFilteredStatus = (prompts: any) => {
  const [status, setStatus] = useState<any>([]);

  useEffect(() => {
    if (prompts && prompts.length > 0) {
      setStatus([
        'All status',
        ...new Set(prompts.map((prompt: any) => prompt?.status)),
      ]);
    }
  }, [prompts]);
  return { status };
};
