import React from 'react';

export const handleDelete = (
  h: any,
  defaultTags: string[],
  setTags: React.Dispatch<React.SetStateAction<string[]>>,
  setValidatorForNoTags: React.Dispatch<React.SetStateAction<boolean>>,
  setValidatorDuplicate: React.Dispatch<React.SetStateAction<boolean>>,
  setIsButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  let clone = defaultTags;
  clone.splice(h, 1);

  setTags([...clone]);
  setValidatorForNoTags(false);
  setValidatorDuplicate(false);
  setIsButtonDisabled(false);
  return 'clicked';
};
