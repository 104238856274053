import React from 'react';

const SubscribingAsset = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 7.5H25V10H5V7.5ZM7.5 2.5H22.5V5H7.5V2.5ZM25 12.5H5C3.625 12.5 2.5 13.625 2.5 15V25C2.5 26.375 3.625 27.5 5 27.5H25C26.375 27.5 27.5 26.375 27.5 25V15C27.5 13.625 26.375 12.5 25 12.5ZM25 25H5V15H25V25ZM12.5 15.9125V24.075L20 20L12.5 15.9125Z"
        fill="#17E88F"
      />
    </svg>
  );
};

export default SubscribingAsset;
