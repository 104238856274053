/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { useStarredEntities } from '@backstage/plugin-catalog-react';
import { IApmDetails } from '../interfaces';
import { AuthContext } from '../../../providers/AuthProvider';
import { TPContext } from '../providers/TPProvider';

interface ProjectType {
  assetType: string;
  scope: string;
}

export const useFilteredProjects = (
  projects: IApmDetails[],
  searchText: string,
  bookmarkSelected: boolean,
  myProjectsSelected: boolean,
) => {
  const [filteredProjects, setFilteredProjects] = useState(projects);
  const [type, setType] = useState<string[]>([]);
  const [filterOption, setFilterOption] = useState<ProjectType>({
    assetType: 'All',
    scope: 'All',
  });
  const { isStarredEntity, starredEntities } = useStarredEntities();
  const { profEmail } = useContext(AuthContext);
  const { myApms } = useContext(TPContext);

  useEffect(() => {
    if (projects) {
      const uniqueType = [
        ...new Set(projects.map(project => project.AssetType)),
      ];
      setType(uniqueType);
    }
  }, [projects]);

  useEffect(() => {
    let localProjects = projects.filter((project: IApmDetails) => {
      return (
        (project.Description.toLowerCase().includes(searchText.toLowerCase()) ||
          project.Name.toLowerCase().includes(searchText.toLowerCase()) ||
          project.ApmId.toLowerCase().includes(searchText.toLowerCase()) ||
          (project.ApmNumber.length === 10 &&
            project.ApmNumber.toLowerCase().includes(
              searchText.toLowerCase(),
            )) ||
          (project.ApmId.length === 6 && project.ApmId === searchText)) &&
        (filterOption.assetType === 'All' ||
          filterOption.assetType === project.AssetType) &&
        (filterOption.scope === 'All' ||
          filterOption.scope === project.Recommendation)
      );
    });

    if (bookmarkSelected) {
      localProjects = localProjects.filter((project: IApmDetails) =>
        isStarredEntity(`Project:${project.ApmNumber}`),
      );
    }

    if (myProjectsSelected) {
      localProjects = localProjects.filter(
        (project: IApmDetails) =>
          profEmail.toLowerCase() ===
            project.PrimaryBusinessOwnerEmail.toLowerCase() ||
          profEmail.toLowerCase() ===
            project.PrimarySupportOwnerEmail.toLowerCase() ||
          myApms?.includes(project.ApmNumber),
      );
    }

    setFilteredProjects(localProjects);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    projects,
    searchText,
    bookmarkSelected,
    myProjectsSelected,
    starredEntities,
    filterOption,
  ]);

  return [filteredProjects, type, setFilterOption, filterOption];
};
