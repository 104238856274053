import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import ClearButton from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      flex: 1,
    },
    ContentHeaderStyle: {
      display: 'flex',
      width: '100%',
      marginBottom: '6px',

      '& > div:first-of-type': {
        display: 'flex',
        flex: '1 1 auto',

        '& > div:first-of-type': {
          width: '100%',
          border: '1px solid #cccccc',
          ['@media (max-width:920px) and (min-width: 280px)']: {
            minWidth: '0',
          },

          '& > div': {
            padding: '3px',
          },
        },
      },
    },
  }),
);

const LeaderBoardContentHeader = ({
  setFilterText,
}: {
  setFilterText: any;
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const classes = useStyles();

  return (
    <div className={classes.ContentHeaderStyle}>
      <div>
        <div>
          <label htmlFor="search-input" hidden>
            Search Here
          </label>
          <Paper component="form" className={classes.root}>
            <IconButton disabled type="submit" aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              className={classes.input}
              placeholder="Search"
              value={searchTerm}
              data-testid="search-input"
              onChange={event => {
                setSearchTerm(event?.target?.value);
                setFilterText(event?.target?.value.trim());
                return;
              }}
              inputProps={{ 'aria-label': 'search feedback' }}
            />
            <IconButton
              aria-label="search"
              data-testid="search-clear-btn"
              onClick={() => {
                setSearchTerm('');
                setFilterText('');
                return;
              }}
            >
              <ClearButton />
            </IconButton>
          </Paper>
        </div>
      </div>
    </div>
  );
};
export default LeaderBoardContentHeader;
