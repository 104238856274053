import React, { MouseEvent, useEffect, useMemo } from 'react';
import { stringifyEntityRef } from '@backstage/catalog-model';
import classNames from 'classnames';
import {
  DependencyGraph,
  DependencyGraphTypes,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import {
  ALL_RELATION_PAIRS,
  RelationPairs,
  Direction,
  EntityNode,
} from '@backstage/plugin-catalog-graph';
import { CircularProgress, makeStyles, useTheme } from '@material-ui/core';
import { CustomLabel } from './CustomLabel';
import { CustomNode } from './CustomNode';
import { useEntityRelationNodesAndEdges } from './useEntityRelationNodesAndEdges';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';

const useStyles = makeStyles(theme => ({
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    marginLeft: '-20px',
    marginTop: '-20px',
  },
  container: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  graph: {
    width: '100%',
    // height: 'calc(100% - 400px)',
    // flex: 1,
    // Right now there is no good way to style edges between nodes, we have to
    // fallback to these hacks:
    '& path[marker-end]': {
      transition: 'filter 0.1s ease-in-out',
    },
    '& path[marker-end]:hover': {
      filter: `drop-shadow(2px 2px 4px ${theme.palette.primary.dark});`,
    },
    '& g[data-testid=label]': {
      transition: 'transform 0s',
    },
  },
}));

/**
 * Core building block for custom entity relations diagrams.
 *
 * @public
 */
export const EntityRelationsGraph = ({
  rootEntityNames,
  maxDepth = Number.POSITIVE_INFINITY,
  unidirectional = true,
  mergeRelations = true,
  kinds,
  relations,
  direction = Direction.LEFT_RIGHT,
  onNodeClick,
  relationPairs = ALL_RELATION_PAIRS,
  className,
  zoom = 'enabled',
}: {
  rootEntityNames: any;
  maxDepth?: number;
  unidirectional?: boolean;
  mergeRelations?: boolean;
  kinds?: string[];
  relations?: string[];
  direction?: Direction;
  onNodeClick?: (value: EntityNode, event: MouseEvent<unknown>) => void;
  relationPairs?: RelationPairs;
  className?: string;
  zoom?: 'enabled' | 'disabled' | 'enable-on-click';
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const rootEntityRefs = useMemo(
    () =>
      (Array.isArray(rootEntityNames)
        ? rootEntityNames
        : [rootEntityNames]
      ).map(e => stringifyEntityRef(e)),
    [rootEntityNames],
  );

  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const { loading, error, nodes, edges } = useEntityRelationNodesAndEdges({
    rootEntityRefs,
    maxDepth,
    unidirectional,
    mergeRelations,
    kinds,
    relations,
    onNodeClick,
    relationPairs,
  });

  useEffect(() => {
    if (error) {
      notificationApi.sendNotification({
        message: error.message,
        severity: 'error',
        disapperAfterMs: 2500,
      });
    }
  }, [notificationApi, error]);

  return (
    <div className={classNames(classes.container, className)}>
      {loading && <CircularProgress className={classes.progress} />}
      {nodes && edges && (
        <DependencyGraph
          nodes={nodes}
          edges={edges}
          renderNode={CustomNode}
          renderLabel={CustomLabel}
          direction={direction}
          className={classes.graph}
          paddingX={theme.spacing(4)}
          paddingY={theme.spacing(4)}
          labelPosition={DependencyGraphTypes.LabelPosition.RIGHT}
          labelOffset={theme.spacing(1)}
          zoom={zoom}
        />
      )}
    </div>
  );
};
