import React, { useState, useEffect, useRef } from 'react';
import { Box, Chip, Typography } from '@material-ui/core';
import TextTruncate from 'react-text-truncate';

const Tag = ({
  tag,
  metadata,
  owner,
  count,
  styles,
  id,
}: {
  tag: string;
  metadata: string;
  owner: string;
  count?: string;
  styles?: any;
  id?: any;
}) => {
  return (
    <Box
      sx={{
        flex: 1,
        mr: 3,
        cursor: 'pointer',
        userSelect: 'none',
        zIndex: 100,
        borderRadius: '6px',
        //  color: '#003F2D',
        padding: '15px 12px',
        ...styles,
      }}
    >
      <Box sx={{ height: '24px', padding: '0' }}>
        <Chip
          sx={{
            background: 'rgba(0, 63, 45, 0.1)',
            // color: '#003F2D',
            margin: '0px',
          }}
          key={'tag-page-' + tag}
          label={tag}
          size="small"
        />
      </Box>
      <Box
        sx={{
          height: '16px',
          margin: '13px 0px',
          display: 'flex',
          padding: '0',
        }}
      >
        <Typography
          style={{
            fontSize: '12px',
            lineHeight: '14px',
            height: '98px',
            //  color: '#1A1A1A',
          }}
        >
          <TextTruncate line={6} text={metadata || ''} />
        </Typography>
      </Box>
    </Box>
  );
};

export default Tag;
