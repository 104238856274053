import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { customTypes } from '../../extensions';
import { useTemplateSecrets } from '../../SecretsContext/index';
import { useStyles } from '../../common/styles';

export const CheckHaveARepo = (
  props: customTypes.FieldExtensionComponentProps<string>,
) => {
  const {
    onChange,
    schema: {
      title = 'Have a POC Repository',
      description = 'Unique name of the component',
    },
    formData,
    uiSchema: { 'ui:autofocus': autoFocus },
    idSchema,
    placeholder,
  } = props;
  const { setSecrets } = useTemplateSecrets();
  const classes = useStyles();

  const [checkedRepo, setCheckRepo] = useState(false);
  const handleChange = (event: any) => {
    onChange(event.target.checked);
    setCheckRepo(event.target.checked);
  };

  useEffect(() => {
    setSecrets({ ['checkHaveARepo']: checkedRepo });
  }, [checkedRepo]);

  return (
    <FormControlLabel
      control={
        <Checkbox
          id={idSchema?.$id}
          checked={checkedRepo}
          onClick={e => {
            handleChange(e);
          }}
        />
      }
      value={formData ?? ''}
      id={idSchema?.$id}
      label={title}
      placeholder={placeholder}
      className={classes.checkBoxLabel}
    />
  );
};
