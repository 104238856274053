import React from 'react';
import BackslashEscapeIcon from './icons/backslashEscapeIcon';
import Base64Icon from './icons/base64Icon';
import ColorConverterIcon from './icons/colorConverterIcon';
import CountDownTimerIcon from './icons/countDownTimerIcon';
import CSSIcon from './icons/cssIcon';
import CSVToJSONIcon from './icons/csvToJsonIcon';
import HashIcon from './icons/hashIcon';
import HTMLEntitiesIcon from './icons/htmlEntitiesIcon';
import HTMLIcon from './icons/htmlIcon';
import JavascriptIcon from './icons/javascriptIcon';
import JSONToCSVIcon from './icons/jsonToCsvIcon';
import JSONToYAMLIcon from './icons/jsonToYamlIcon';
import JWTDecoderIcon from './icons/jwtDecoderIcon';
import LoremIpsumIcon from './icons/loremIpsumIcon';
import MarkdownPreviewIcon from './icons/markdownPreviewIcon';
import NumberBaseIcon from './icons/numberBaseIcon';
import SLAConverterIcon from './icons/slaConverterIcon';
import SQLIcon from './icons/sqlIcon';
import StopWatchTimerIcon from './icons/stopWatchTimerIcon';
import StringCaseIcon from './icons/stringCaseIcon';
import TimeConverterIcon from './icons/timeConverterIcon';
import URLIcon from './icons/urlIcon';
import XMLToJSONIcon from './icons/xmlToJsonIcon';
import YAMLToJSONIcon from './icons/yamlToJson';
import { IUtilityTool } from './types';
import QRCodeIcon from './icons/qrCodeIcon';
import RegexIcon from './icons/regexIcon';
import FileDiffIcon from './icons/fileDiffIcon';
import YAMLIcon from './icons/yamlIcon';

export const MISCELLANEOUS_TYPE_OF_TOOLS: IUtilityTool[] = [
  {
    id: `Component:utilitytool/countdowntimer`,
    icon: <CountDownTimerIcon />,
    label: 'Count Down Timer',
    path: 'tool/countdown',
    sidebarIcon: <CountDownTimerIcon style={{ transform: 'scale(1)' }} />,
  },
  {
    id: `Component:utilitytool/stopwatchtimer`,
    icon: <StopWatchTimerIcon />,
    label: 'Stop Watch Timer',
    path: 'tool/stopwatch',
    sidebarIcon: <StopWatchTimerIcon style={{ transform: 'scale(1)' }} />,
  },
  {
    id: `Component:utilitytool/markdownpreview`,
    icon: <MarkdownPreviewIcon />,
    label: 'Markdown Preview',
    path: 'tool/markdown-preview',
    sidebarIcon: <MarkdownPreviewIcon style={{ transform: 'scale(1)' }} />,
  },
  {
    id: `Component:utilitytool/diff`,
    icon: <FileDiffIcon />,
    label: 'File Diff',
    path: 'tool/file-diff',
    sidebarIcon: <FileDiffIcon style={{ transform: 'scale(1)' }} />,
  },
];

export const CONVERT_TYPE_OF_TOOLS: IUtilityTool[] = [
  {
    id: `Component:utilitytool/numberbase`,
    icon: <NumberBaseIcon />,
    label: 'Number Base',
    path: 'tool/number-base-convert',
    sidebarIcon: <NumberBaseIcon style={{ transform: 'scale(1)' }} />,
  },
  {
    id: `Component:utilitytool/csvtojson`,
    icon: <CSVToJSONIcon />,
    label: 'CSV to JSON',
    path: 'tool/csv-to-json-convert',
    sidebarIcon: <CSVToJSONIcon style={{ transform: 'scale(1)' }} />,
  },
  {
    id: `Component:utilitytool/jsontocsv`,
    icon: <JSONToCSVIcon />,
    label: 'JSON to CSV',
    path: 'tool/json-to-csv-convert',
    sidebarIcon: <JSONToCSVIcon style={{ transform: 'scale(1)' }} />,
  },
  {
    id: `Component:utilitytool/xmltojson`,
    icon: <XMLToJSONIcon />,
    label: 'XML to JSON',
    path: 'tool/xml-to-json-convert',
    sidebarIcon: <XMLToJSONIcon style={{ transform: 'scale(1)' }} />,
  },
  {
    id: `Component:utilitytool/jsontoyaml`,
    icon: <JSONToYAMLIcon />,
    label: 'JSON to YAML',
    path: 'tool/json-to-yaml-convert',
    sidebarIcon: <JSONToYAMLIcon style={{ transform: 'scale(1)' }} />,
  },
  {
    id: `Component:utilitytool/yamltojson`,
    icon: <YAMLToJSONIcon />,
    label: 'YAML to JSON',
    path: 'tool/yaml-to-json-convert',
    sidebarIcon: <YAMLToJSONIcon style={{ transform: 'scale(1)' }} />,
  },
  {
    id: `Component:utilitytool/stringcase`,
    icon: <StringCaseIcon />,
    label: 'String Case',
    path: 'tool/string-case-convert',
    sidebarIcon: <StringCaseIcon style={{ transform: 'scale(1)' }} />,
  },
  {
    id: `Component:utilitytool/timeconvert`,
    icon: <TimeConverterIcon />,
    label: 'Time Converter',
    path: 'tool/time-convert',
    sidebarIcon: <TimeConverterIcon style={{ transform: 'scale(1)' }} />,
  },
  {
    id: `Component:utilitytool/colorconverter`,
    icon: <ColorConverterIcon />,
    label: 'Color Converter',
    path: 'tool/color-convert',
    sidebarIcon: <ColorConverterIcon style={{ transform: 'scale(1)' }} />,
  },
  {
    id: `Component:utilitytool/slaconverter`,
    icon: <SLAConverterIcon />,
    label: 'SLA Converter',
    path: 'tool/sla-calculator',
    sidebarIcon: <SLAConverterIcon style={{ transform: 'scale(1)' }} />,
  },
];

export const ENCODE_DECODE_TYPE_OF_TOOLS: IUtilityTool[] = [
  {
    id: `Component:utilitytool/base64`,
    icon: <Base64Icon />,
    label: 'Base64',
    path: 'tool/base64-encode',
    sidebarIcon: <Base64Icon style={{ transform: 'scale(1)' }} />,
  },
  {
    id: `Component:utilitytool/urlencode`,
    icon: <URLIcon />,
    label: 'URL',
    path: 'tool/url-encode',
    sidebarIcon: <URLIcon style={{ transform: 'scale(1)' }} />,
  },
  {
    id: `Component:utilitytool/htmlentities`,
    icon: <HTMLEntitiesIcon />,
    label: 'HTML Entities',
    path: 'tool/html-entity-encode',
    sidebarIcon: <HTMLEntitiesIcon style={{ transform: 'scale(1)' }} />,
  },
  {
    id: `Component:utilitytool/backslashescape`,
    icon: <BackslashEscapeIcon />,
    label: 'Backslash Escape',
    path: 'tool/backslash-encode',
    sidebarIcon: <BackslashEscapeIcon style={{ transform: 'scale(1)' }} />,
  },
  {
    id: `Component:utilitytool/jwtdecoder`,
    icon: <JWTDecoderIcon />,
    label: 'JWT Decoder',
    path: 'tool/jwt-decoder-encode',
    sidebarIcon: <JWTDecoderIcon style={{ transform: 'scale(1)' }} />,
  },
];
export const GENERATE_TYPE_OF_TOOLS: IUtilityTool[] = [
  {
    id: `Component:utilitytool/hashgenerator`,
    icon: <HashIcon />,
    label: 'Hash',
    path: 'tool/hash-generate',
    sidebarIcon: <HashIcon style={{ transform: 'scale(1)' }} />,
  },
  {
    id: `Component:utilitytool/loremipsum`,
    icon: <LoremIpsumIcon />,
    label: 'Lorem Ipsum',
    path: 'tool/lorem-ipsum-generate',
    sidebarIcon: <LoremIpsumIcon style={{ transform: 'scale(1)' }} />,
  },
  {
    id: `Component:utilitytool/qr-code`,
    icon: <QRCodeIcon />,
    label: 'QR Code Generator',
    path: 'tool/qr-code',
    sidebarIcon: <QRCodeIcon style={{ transform: 'scale(1)' }} />,
  },
];
export const FORMAT_TYPE_OF_TOOLS: IUtilityTool[] = [
  {
    id: `Component:utilitytool/jsbeautify`,
    icon: <JavascriptIcon />,
    label: 'JS Beautify',
    path: 'tool/format-js',
    sidebarIcon: <JavascriptIcon style={{ transform: 'scale(1)' }} />,
  },
  {
    id: `Component:utilitytool/htmlbeautify`,
    icon: <HTMLIcon />,
    label: 'HTML Beautify',
    path: 'tool/format-html',
    sidebarIcon: <HTMLIcon style={{ transform: 'scale(1)' }} />,
  },
  {
    id: `Component:utilitytool/cssbeautify`,
    icon: <CSSIcon />,
    label: 'CSS Beautify',
    path: 'tool/format-css',
    sidebarIcon: <CSSIcon style={{ transform: 'scale(1)' }} />,
  },
  {
    id: `Component:utilitytool/sqlbeautify`,
    icon: <SQLIcon />,
    label: 'SQL Beautify',
    path: 'tool/format-sql',
    sidebarIcon: <SQLIcon style={{ transform: 'scale(1)' }} />,
  },
  /*   {
    id: `Component:utilitytool/yamlFormatter`,
    icon: <YAMLIcon />,
    label: 'YAML Beautify',
    path: 'tool/yaml-formatter',
    sidebarIcon: <SQLIcon style={{ transform: 'scale(1)' }} />,
  }, */
];

export const REGULAR_EXPRESSIONS_TYPE_OF_TOOLS: IUtilityTool[] = [
  {
    id: `Component:utilitytool/regexPlayground`,
    icon: <RegexIcon />,
    label: 'RegEx Playground',
    path: 'tool/regex-playground',
    sidebarIcon: <RegexIcon style={{ transform: 'scale(1)' }} />,
  },
];

export const ALL_UTILITY_TOOLS: IUtilityTool[] = [
  ...CONVERT_TYPE_OF_TOOLS,
  ...ENCODE_DECODE_TYPE_OF_TOOLS,
  ...FORMAT_TYPE_OF_TOOLS,
  ...GENERATE_TYPE_OF_TOOLS,
  ...MISCELLANEOUS_TYPE_OF_TOOLS,
  ...REGULAR_EXPRESSIONS_TYPE_OF_TOOLS,
];
