/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { pocHubApiRef } from '../../../apis/pocHubApi';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';

export const useRegisterPocComponent = () => {
  const pocHub = useApi(pocHubApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  //const [reviewerList, setReviewerList] = useState<any>(null);

  const registerPoc = async (
    data: any,
    handleStartOver: any,
    repoLocation: string,
  ) => {
    try {
      const data_2 = await pocHub.regiterPocComponent(data);
      // notificationApi.sendNotification({
      //   message: 'POC Registered Successfully',
      //   disapperAfterMs: 2500,
      //   severity: 'success',
      // });
      handleStartOver(repoLocation);
    } catch (error) {
      notificationApi.sendNotification({
        message: `Error occurred - ${error?.message}`,
        disapperAfterMs: 2500,
        severity: 'error',
      });
      handleStartOver();
    }
  };

  return { registerPoc };
};
