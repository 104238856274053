import { JsonObject } from '@backstage/types';
import {
  Box,
  Button,
  Paper,
  Step as StepUI,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  useApi,
  featureFlagsApiRef,
  useApiHolder,
} from '@backstage/core-plugin-api';
import {
  FormProps,
  IChangeEvent,
  UiSchema,
  withTheme,
  FieldValidation,
} from '@rjsf/core';
import { Theme as MuiTheme } from '@rjsf/material-ui';
import React, { useMemo, useState } from 'react';
import { transformSchemaToProps } from './schema';
import { Content, StructuredMetadataTable } from '@backstage/core-components';
import cloneDeep from 'lodash/cloneDeep';
import * as fieldOverrides from './FieldOverrides';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { FieldExtensionOptions } from '../extensions/types';
import { createAsyncValidators } from './createAsyncValidators';

const useStyles = makeStyles({
  templateBackBtn: {
    ['@media (max-width:920px) and (min-width: 280px)']: {
      width: '100%',
    },
  },
  templateNextBtn: {
    ['@media (max-width:920px) and (min-width: 280px)']: {
      width: '100%',
    },
  },
});
const Form = withTheme(MuiTheme);
type Step = {
  schema: JsonObject;
  title: string;
} & Partial<Omit<FormProps<any>, 'schema'>>;

type Props = {
  /**
   * Steps for the form, each contains title and form schema
   */
  steps: Step[];
  formData: Record<string, any>;
  onChange: (e: IChangeEvent) => void;
  onReset: () => void;
  onFinish?: () => Promise<void>;
  widgets?: FormProps<any>['widgets'];
  fields?: FormProps<any>['fields'];
  customFieldExtensions: FieldExtensionOptions<any, any>[];
};

export function getUiSchemasFromSteps(steps: Step[]): UiSchema[] {
  const uiSchemas: Array<UiSchema> = [];
  steps.forEach(step => {
    const schemaProps = step.schema.properties as JsonObject;
    for (const key in schemaProps) {
      if (schemaProps.hasOwnProperty(key)) {
        const uiSchema = schemaProps[key] as UiSchema;
        uiSchema.name = key;
        uiSchemas.push(uiSchema);
      }
    }
  });
  return uiSchemas;
}

export function getReviewData(formData: Record<string, any>, steps: Step[]) {
  const uiSchemas = getUiSchemasFromSteps(steps);
  const reviewData: Record<string, any> = {};
  for (const key in formData) {
    if (formData.hasOwnProperty(key)) {
      const uiSchema = uiSchemas.find(us => us.name === key);

      if (!uiSchema) {
        reviewData[key] = formData[key];
        continue;
      }

      if (uiSchema['ui:widget'] === 'password') {
        reviewData[key] = '******';
        continue;
      }

      if (!uiSchema['ui:backstage'] || !uiSchema['ui:backstage'].review) {
        reviewData[key] = formData[key];
        continue;
      }

      const review = uiSchema['ui:backstage'].review as JsonObject;
      if (!review.show) {
        continue;
      }

      if (review.mask) {
        reviewData[key] = review.mask;
        continue;
      }
      reviewData[key] = formData[key];
    }
  }

  return reviewData;
}

export const MultistepJsonForm = (props: Props) => {
  const { formData, onChange, onReset, onFinish, fields, widgets } = props;
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles();
  const [disableButtons, setDisableButtons] = useState(false);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const featureFlagApi = useApi(featureFlagsApiRef);
  const featureFlagKey = 'backstage:featureFlag';

  const [isValidating, setValidating] = useState<boolean>(false);
  const [errors, setErrors] = useState<
    undefined | Record<string, FieldValidation>
  >();

  const filterOutProperties = (step: Step): Step => {
    const filteredStep = cloneDeep(step);
    const removedPropertyKeys: Array<string> = [];
    if (filteredStep.schema.properties) {
      filteredStep.schema.properties = Object.fromEntries(
        Object.entries(filteredStep.schema.properties).filter(
          ([key, value]) => {
            if (value[featureFlagKey]) {
              if (featureFlagApi.isActive(value[featureFlagKey])) {
                return true;
              }
              removedPropertyKeys.push(key);
              return false;
            }
            return true;
          },
        ),
      );

      // remove the feature flag property key from required if they are not active
      filteredStep.schema.required = Array.isArray(filteredStep.schema.required)
        ? filteredStep.schema.required?.filter(
            r => !removedPropertyKeys.includes(r as string),
          )
        : filteredStep.schema.required;
    }
    return filteredStep;
  };

  const steps = props.steps
    .filter(step => {
      const featureFlag = step.schema[featureFlagKey];
      return (
        typeof featureFlag !== 'string' || featureFlagApi.isActive(featureFlag)
      );
    })
    .map(filterOutProperties);

  const handleReset = () => {
    setActiveStep(0);
    onReset();
  };

  const validators = useMemo(() => {
    return Object.fromEntries(
      props.customFieldExtensions.map(({ name, validation }) => [
        name,
        validation,
      ]),
    );
  }, [props.customFieldExtensions]);

  const apiHolder = useApiHolder();

  const validation = useMemo(() => {
    if (!steps || !steps[activeStep] || !steps[activeStep]?.schema) {
      return;
    }
    const { schema } = steps[activeStep];
    return createAsyncValidators(schema, validators, {
      apiHolder,
    });
  }, [steps, activeStep, validators, apiHolder]);

  const handleNext = async () => {
    // Get the validation for the fields
    setErrors(undefined);
    setValidating(true);

    let returnedValidation = {};
    if (validation !== undefined) {
      // invoke and wait for the results
      returnedValidation = await validation?.(formData);
    }

    setValidating(false);

    // after successful validation, move to next screen
    const hasErrors = Object.values(returnedValidation).some(i => {
      // @ts-expect-error
      return i?.__errors?.length > 0;
    });

    if (hasErrors) {
      setErrors(returnedValidation);
    } else {
      setErrors(undefined);
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };
  const handleBack = () => setActiveStep(Math.max(activeStep - 1, 0));
  const handleCreate = async () => {
    if (!onFinish) {
      return;
    }

    setDisableButtons(true);
    try {
      await onFinish();
    } catch (err) {
      setDisableButtons(false);
      notificationApi.sendNotification({
        // @ts-expect-error
        message: err?.message,
        severity: 'error',
        disapperAfterMs: 2500,
      });
    }
  };

  return (
    <>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map(({ title, schema, ...formProps }, index) => {
          return (
            <StepUI key={title}>
              <StepLabel
                aria-label={`Step ${index + 1} ${title}`}
                aria-disabled="false"
                tabIndex={0}
              >
                <Typography variant="h6" component="h3">
                  {title}
                </Typography>
              </StepLabel>
              <StepContent key={title}>
                <Form
                  extraErrors={errors}
                  formData={formData}
                  showErrorList={false}
                  fields={{ ...fieldOverrides, ...fields }}
                  widgets={widgets}
                  noHtml5Validate
                  onChange={onChange}
                  onSubmit={handleNext}
                  {...formProps}
                  {...transformSchemaToProps(schema)}
                >
                  <Button
                    className={classes.templateBackBtn}
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                  <Button
                    className={classes.templateNextBtn}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isValidating}
                  >
                    {isValidating ? 'Validating Input...' : 'Next step'}
                  </Button>
                </Form>
              </StepContent>
            </StepUI>
          );
        })}
      </Stepper>
      {activeStep === steps.length && (
        <Content>
          <Paper square elevation={0}>
            <Typography variant="h6">Review and create</Typography>
            <StructuredMetadataTable
              dense
              metadata={getReviewData(formData, steps)}
            />
            <Box mb={4} />
            <Button onClick={handleBack} disabled={disableButtons}>
              Back
            </Button>
            <Button onClick={handleReset} disabled={disableButtons}>
              Reset
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreate}
              disabled={!onFinish || disableButtons}
            >
              Create
            </Button>
          </Paper>
        </Content>
      )}
    </>
  );
};
