import { Box, Typography, Grid } from '@material-ui/core';
import React from 'react';
import { useStyles } from '../styles';

interface InstructionBoxProps {
  // Define your component's props here
  title: string;
  instructions: string[];
}

const InstructionsBox: React.FC<InstructionBoxProps> = ({
  title,
  instructions,
}) => {
  // Implement your component's logic here
  const classes = useStyles();
  const keywords = [
    "Java -> {programming_language}",
    "Cucumber -> {testing_framework}",
    "scenario 'As a user, I want to be able to filter search results by categories to find products I am interested in more easily' -> {user_story}",
    "valid categories -> {positive_test_case}",
    "no results -> {negative_test_case_no_results}",
    "invalid categories -> {negative_test_case_invalid_input}"
  ]

  return (
    <Box component="div" className={classes.instructionBox}>
      <Grid className={classes.instructionBoxTitle}>
        <Typography>
          What is prompt enhancer?
        </Typography>
      </Grid>
      <Grid>
        A prompt enhancer helps you craft clearer instructions for AI tasks, boosting the accuracy and quality of the results. This enhancer is targeted for D&T community and will help with Software related inputs. The output will add context and templatize the prompt so that you can re-use it again as needed and share it with others . It will also call out the intent and the keywords that can be converted to placeholders.
      </Grid>
      <ol>
        {instructions.map((instruction, index) => (
          <li key={index}>{instruction}</li>
        ))}
      </ol>
      <Grid className={classes.instructionBoxTitle}>
        <Typography>
          Here is an example:
        </Typography>
      </Grid>
      <div className={classes.instructionInput}>
      <Grid>
        Input : <br />
        Create Java BDD test cases using Cucumber for the scenario 'As a user, I want to be able to filter search results by categories to find products I am interested in more easily'. Define scenarios for filtering with valid categories, filtering with no results, and attempts to filter with invalid categories. Each scenario should have detailed Given, When, Then steps, including assertions for expected outcomes and handling of no results or errors
      </Grid>
      </div>
     <div className={classes.instructionOutput}>
     <Grid>
        Output:<br />
        Intent: The intent of the original input prompt is to create detailed Java BDD test cases using the Cucumber framework, specifically for a feature that allows users to filter search results by categories.
      </Grid>
      <Grid>
        Keywords and Placeholder Texts:<br />
        <ul>
          {
            keywords.map((keyword, index) => (
              <li key={keyword + index}>{keyword}</li>
            ))
          }
        </ul>
      </Grid>
      <Grid>
        Enhanced Prompt:
      </Grid>
      <Grid>
        {`Create {programming_language} BDD test cases using {testing_framework} for the {user_story}. Define scenarios for {positive_test_case}, {negative_test_case_no_results}, and attempts to {negative_test_case_invalid_input}. Each scenario should have detailed Given, When, Then steps, including assertions for expected outcomes and handling of no results or errors.`}
      </Grid>
     </div>
      
    </Box>
  );
};

export default InstructionsBox;
