import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /*Common Styles */
    spaceBetween: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    divider: {
      width: '100%',
      height: '1px',
      background: '#cccccc',
    },
    swaggerForm: {
      borderRadius: '4px',
      backgroundColor: theme.palette.background.tertiary,
      padding: '12px',
      '&:first-child': {
        marginTop: '10px',
      },
    },
    collapse: {
      marginTop: '5px',
    },
    actionIcons: {
      padding: '5px !important',
    },
    columnDirection: {
      ['@media (max-width:920px) and (min-width: 280px)']: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      },
    },
    /* response  form styles */
    response: {
      backgroundColor: theme.palette.background.default,
      boxShadow: 'inset 2px 2px 8px rgba(0, 0, 0, 0.25)',
    },
    responseSection: {
      marginTop: '10px',
    },
    /* request type styles */
    swaggerTitle: {
      color: theme.palette.text.secondary,
      fontWeight: 500,
      fontStyle: 'normal',
    },
    requestTypeBtns: {
      margin: '10px 0 0 0',
    },
    btnRequestType: {
      marginLeft: '20px',
      '&:first-child': {
        marginLeft: '0px',
      },
      ['@media (max-width:820px) and (min-width: 280px)']: {
        marginTop: '10px',
        width: '100%',
        marginLeft: '0px',
        '&:first-child': {
          marginTop: '0px',
        },
      },
    },
    paramsCheckbox: {
      marginLeft: '10px',
    },
    paramDelIcon: {
      position: 'relative',
      left: '7px',
    },
    paramsSection: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    /* swagger api form (index) */
    request: {
      fontSize: '20px',
      marginTop: '20px',
    },
    swagger: {
      fontSize: '24px',
    },
    successbtn: {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    servers: {
      marginTop: '15px',
    },
    serverContent: {
      marginTop: '20px',
    },
    swaggerApiHeadings: {
      backgroundColor: theme.palette.background.tertiary,
      fontSize: '15px',
      marginTop: '20px',
    },
    info: {
      marginTop: '15px',
    },

    serversDes: {
      width: 'calc(100% - 34px)',
    },
    serversDelBtn: {
      marginTop: '9px',
    },
  }),
);
