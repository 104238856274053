import React from 'react';
import { Entity } from '@backstage/catalog-model';
import { Colors } from '../../utils/constant';
import Constants from '../Constants';
import { Theme } from '@material-ui/core';

export const getUserLink = (data: Entity): string => {
  if (data?.metadata && data?.spec) {
    let user = `${data?.spec?.owner}`;
    if (user.indexOf('user:') == 0) {
      user = user.replace('user:', '');
    }
    return `/catalog/${data?.metadata?.namespace}/user/${user}`;
  }
  return '#';
};

export const getUserName = (data: Entity): string => {
  if (data?.spec?.owner) {
    let user = `${data?.spec?.owner}`;
    if (user.indexOf('user:') == 0) {
      user = user.replace('user:', '');
    }
    return user;
  }
  return '';
};

export const getTitleElement = (name: string): React.ReactElement => {
  if (name) {
    let showName = `${name}`;
    if (name.length > 57) {
      showName = `${showName.substring(0, 57)}...`;
    }
    {
      /*
     @ts-ignore */
    }
    return <span title={name}>{showName}</span>;
  }
  {
    /*
     @ts-ignore */
  }
  return <></>;
};

export const replaceAll = (str: string, find: string, replace: string) => {
  return str.replace(new RegExp(find, 'g'), replace);
};

export const hypenateTitle = (title: string) => {
  if (title) {
    title = title.trim();
    title = title.replace(/[^\w]|_/g, ' ');
    title = replaceAll(title, '  ', ' ');
    title = replaceAll(title, ' ', '-');
    return replaceAll(title, '--', '-');
  }
  return '';
};

export const limitChars = (val: string, limit: number) => {
  if (val) {
    if (val.length > limit) {
      return val.substring(0, limit);
    }
  }
  return val;
};

export const statusColor = (devxStatus: any, theme: Theme) => {
  switch (devxStatus) {
    case Constants.devxStatus.notApproved:
      return Colors.ERROR_MAIN;
    case Constants.devxStatus.approved:
      return Colors.SUCCESS_GREEN;
    case Constants.devxStatus.underReview:
      return theme.palette.info.main;
    case Constants.devxStatus.resubmit:
      return Colors.WARNING_YELLOW;
    default:
      return Colors.INFO_DARK;
  }
};

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
