import React, { useContext, useEffect, useState } from 'react';
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  TextField,
} from '@material-ui/core';
import { customTypes } from '../../extensions';
import { Autocomplete } from '@material-ui/lab';
import { ApmListContext } from '../../providers/ApmListProvider';

/**
 * APMField
 */
export const APMField = (
  props: customTypes.FieldExtensionComponentProps<string>,
) => {
  const {
    onChange,
    required,
    schema: { title = 'APM Number', description = 'APM number of the project' },
    rawErrors,
    formData,
    uiSchema: { 'ui:autofocus': autoFocus },
    idSchema,
    placeholder,
  } = props;

  const apmListContext = useContext(ApmListContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedOption, setSelectedOption] = useState<any>();
  const allApms = apmListContext.allApms;

  const handleOnchange = (_: any, ampObj: any) => {
    onChange(ampObj?.ApmNumber || undefined);
  };

  useEffect(() => {
    setLoading(true);
    if (allApms.length > 0) {
      setLoading(false);
      setSelectedOption(
        allApms.find((apmObj: any) => apmObj.ApmNumber === formData) || '',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allApms, formData]);

  return (
    <FormControl fullWidth>
      <Autocomplete
        id={idSchema?.$id}
        options={allApms.sort(
          (a: any, b: any) => -b?.Name.localeCompare(a?.Name),
        )}
        onChange={handleOnchange}
        getOptionLabel={(option: any) => option?.Name || ''}
        value={selectedOption || null}
        loading={loading}
        renderInput={params => (
          <TextField
            error={rawErrors?.length > 0 && !formData}
            {...params}
            label={`${title} ${required ? '*' : ''}`}
            placeholder={placeholder}
            value={formData ?? ''}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      <FormHelperText>{description}</FormHelperText>
    </FormControl>

    /*     <TextField
      id={idSchema?.$id}
      label={title}
      placeholder={placeholder}
      helperText={description}
      FormHelperTextProps={{
        error: false,
      }}
      required={required}
      value={formData ?? ''}
      onChange={({ target: { value } }) => onChange(value)}
      margin="normal"
      error={rawErrors?.length > 0 && !formData}
      inputProps={{ autoFocus }}
    /> */
  );
};
