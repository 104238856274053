import {
  genPageTheme,
  shapes,
  createUnifiedTheme,
  themes,
} from '@backstage/theme';

const cbreTheme = createUnifiedTheme({
  palette: {
    ...themes.light.getTheme('v5').palette,
    background: {
      default: '#f2f2f2',
      paper: '#fff',
      tertiary: '#F5F7F7',
      secondary: '#E5E5E5',
    },
    primary: {
      main: '#003f2d',
    },
    secondary: {
      main: '#538184',
      active: 'rgba(0, 63, 45, 0.08)',
    },
    error: {
      main: '#A03530',
    },
    warning: {
      main: '#FF9742',
    },
    info: {
      main: '#262698',
    },
    success: {
      main: '#50AF78',
    },
    alert: {
      background: '#E7E7F9',
      border: '#8585E0',
      link: '#262698',
      color:'#262698',
    },
    toolTip: {
      background: '#000',
      textColor: '#fff',
    },
    banner: {
      info: '#012A2D',
      error: '#123456',
      text: '#262626',
      link: '#17E88F',
    },
    border: 'rgba(0, 0, 0, 0.12)',
    successBackground: '#E6F4EC',
    errorBackground: '#FBEEEE',
    warningBackground: '#FFEDDB',
    infoBackground: '#E7E7F9',
    successText: '#50AF78',
    errorText: '#FF543E',
    infoText: '#262698',
    warningText: '#FF9742',
    navigation: {
      background: '#FFFFFF',
      indicator: '#538184',
      color: '#538184',
      selectedColor: '#003f2d',
      navItem: {
        hoverBackground: '#F5F7F7',
      },
      submenu: {
        background: '#F5F7F7',
      },
    },
    text: {
      primary: '#003f2d',
      secondary: '#1A1A1A',
      tertiary: '#767676',
      contrast: '#fff',
      muted: 'rgba(26, 26, 26, 0.6)',
      signIn: '#fff',
      buttonText: '#003f2d',
    },
    customCard: {
      cardHeader: '#1A1A1A',
      tabHighlight: '#003f2d',
      foldBg: '#f2f2f2',
    },
    table: {
      headerBackground: '#DFE6E5',
    },
    textEditor: {
      color: '#000',
    },
    chat: {
      primary: {
        background: '#C0D4CB',
        text: '#1A1A1A',
        hyperText: '#262698',
        button: 'rgba(0, 63, 45, 0.08)',
      },
      secondary: {
        background: '#EFECD2',
        text: '#1A1A1A',
      },
      prompt: {
        background: '#F5F7F7',
        button: 'rgba(0, 63, 45, 0.15)',
        submitButton: '#003F2D',
        promptBackground: '#E6EAEA',
        promptActive: '#C0D4CB',
      },
    },
    infoExchange: {
      badge: {
        labelBg: 'transparent',
        valueBg: '#CBD1D3',
        border: '#CBD1D3',
        text: '#1A1A1A',
      },
    },
    prompt: {
      table: {
        domain: '#E1ECF5',
        subdomain: '#E0EBE6',
        persona: '#F0E7F8',
      },
      loader: {
        primary: '#C0D4CB',
        secondary: '#538184',
      },
      options: {
        background: '#3CD278',
        text: '#003F2D',
        button: '#435254',
        color: '#fff',
        header: '#435254',
        border: '#767676',
        title: '#fff',
        paper: '#fff',
        inputBackground: 'transparent',
        bot: {
          background: '#E6F4EC',
          text: '#1A1A1A',
        },
        user: {
          background: '#EEEEEE',
          text: '#1A1A1A',
        },
      },
      chat: {
        background: 'linear-gradient(180deg, #F6FAFA 18.03%, #FBFFFF 43.45%, #E1EBEB 73.66%)',
      }
    },
    panel: {
      varient1: '#538184',
      varient2: '#3E7CA6',
    },
    bgVariants: {
      variant1: '#d6d6f5',
      variant2: '#fff',
      variant3: '#DFE6E5',
      variant4: '#E1E8E7',
    },

    textVariants: {
      variant1: '#032842',
      variant2: '#435254',
      variant4: '#003f2d',
    },

    colors: {
      purple: '#A388BF',
      darkblue: '#42526E',
      greencyan: '#465855',
      sage: '#538184',
      blue: '#3E7CA6',
      gold: '#B85F00',
      green: '#003f2d',
      white: '#FFFFFF',
      richBlack: '#010c14',
    },
  },
  defaultPageTheme: 'home',
  // fontFamily: 'Calibre',
  fontFamily: 'Calibre-R-Regular',
  fontColor: '#435254',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: ['#012A2D'], shape: shapes.wave }),
    tool: genPageTheme({ colors: ['#012A2D'], shape: shapes.wave }),
    service: genPageTheme({ colors: ['#012A2D'], shape: shapes.wave }),
    website: genPageTheme({ colors: ['#012A2D'], shape: shapes.wave }),
    library: genPageTheme({ colors: ['#012A2D'], shape: shapes.wave }),
    other: genPageTheme({ colors: ['#012A2D'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#012A2D'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#012A2D'], shape: shapes.wave }),
    card: genPageTheme({ colors: ['#012A2D'], shape: shapes.wave }),
    feedback: genPageTheme({ colors: ['#012A2D'], shape: shapes.wave }),
    documentation: genPageTheme({ colors: ['#012A2D'], shape: shapes.wave }),
  },
});

export default cbreTheme;
