import { Content, Header, Page, InfoCard } from '@backstage/core-components';
import { Grid, LinearProgress } from '@material-ui/core';
import { JsonObject, JsonValue } from '@backstage/types';
import { FormValidation, IChangeEvent } from '@rjsf/core';
import qs from 'qs';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import useAsync from 'react-use/lib/useAsync';
import {
  scaffolderApiRef,
  CustomFieldValidator,
  FieldExtensionOptions,
} from '@backstage/plugin-scaffolder-react';
import { SecretsContextPoc } from '../SecretsContext';
import { rootRouteRef, pocHubTaskRouteRef } from '../Routes';
//import { MultistepJsonForm } from '../MultistepJsonForm';
import {
  ApiHolder,
  useApi,
  useApiHolder,
  useRouteRef,
} from '@backstage/core-plugin-api';
import { stringifyEntityRef, Entity } from '@backstage/catalog-model';
import { catalogApiRef, EntityProvider } from '@backstage/plugin-catalog-react';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import BackButton from '../../utils/backButton';

import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { DevxBreadCrumb } from '../../common/BreadcrumbsNav/DevxBreadCrumb';
import { PocCard } from '../common/PocCard';
import { useGetPocComponent } from '../hooks/useGetPocComponent';
import { MultistepJsonForm } from '../../scaffolder/MultistepJsonForm';
import { useStyles } from '../common/styles';

const useTemplateParameterSchema = (templateRef: string) => {
  const scaffolderApi = useApi(scaffolderApiRef);
  const { value, loading, error } = useAsync(
    () => scaffolderApi.getTemplateParameterSchema(templateRef),
    [scaffolderApi, templateRef],
  );
  return { schema: value, loading, error };
};

function isObject(obj: unknown): obj is JsonObject {
  return typeof obj === 'object' && obj !== null && !Array.isArray(obj);
}

export const createValidator = (
  rootSchema: JsonObject,
  validators: Record<string, undefined | CustomFieldValidator<unknown>>,
  context: {
    apiHolder: ApiHolder;
  },
) => {
  function validate(
    schema: JsonObject,
    formData: JsonObject,
    errors: FormValidation,
  ) {
    const schemaProps = schema.properties;
    if (!isObject(schemaProps)) {
      return;
    }

    for (const [key, propData] of Object.entries(formData)) {
      const propValidation = errors[key];

      if (isObject(propData)) {
        const propSchemaProps = schemaProps[key];
        if (isObject(propSchemaProps)) {
          validate(
            propSchemaProps,
            propData as JsonObject,
            propValidation as FormValidation,
          );
        }
      } else {
        const propSchema = schemaProps[key];
        const fieldName =
          isObject(propSchema) && (propSchema['ui:field'] as string);

        if (fieldName && typeof validators[fieldName] === 'function') {
          validators[fieldName]!(
            propData as JsonValue,
            propValidation,
            context,
          );
        }
      }
    }
  }

  return (formData: JsonObject, errors: FormValidation) => {
    validate(rootSchema, formData, errors);
    return errors;
  };
};

const ClonePocPage = ({
  customFieldExtensions = [],
}: {
  customFieldExtensions?: FieldExtensionOptions<any, any>[];
}) => {
  const apiHolder = useApiHolder();
  const classes = useStyles();
  const secretsContext = useContext(SecretsContextPoc);

  const catalogApi = useApi(catalogApiRef);
  const scaffolderApi = useApi(scaffolderApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const templateName = 'poc-clone-template';
  const navigate = useNavigate();
  //const scaffolderTaskRoute = useRouteRef(pocHubTaskRouteRef);
  // const rootRoute = useRouteRef(rootRouteRef);
  const { schema, loading, error } = useTemplateParameterSchema(templateName);

  const [formState, setFormState] = useState<Record<string, any>>(() => {
    const query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    try {
      return JSON.parse(query.formData as string);
    } catch (e) {
      return query.formData ?? {};
    }
  });
  const handleFormReset = () => setFormState({});
  const handleChange = useCallback(
    (e: IChangeEvent) => setFormState(e.formData),
    [setFormState],
  );
  const [entity, setEntity] = useState<Entity | undefined>();

  useEffect(() => {
    if (catalogApi) {
      catalogApi
        .getEntityByRef({
          kind: 'Template',
          name: templateName,
          namespace: 'default',
        })
        .then((value: Entity | undefined) => {
          setEntity(value);
        })
        .catch((err: any) => {
          notificationApi.sendNotification({
            message: `Error occurred - ${err?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });
    }
  }, [catalogApi, schema]);

  const handleCreate = async () => {
    const { taskId } = await scaffolderApi.scaffold({
      templateRef: stringifyEntityRef({
        name: templateName,
        kind: 'template',
        namespace: 'default',
      }),
      values: formState,
      secrets: secretsContext?.secrets,
    });

    const formParams = qs.stringify(
      { formData: formState },
      { addQueryPrefix: true },
    );
    const newUrl = `${window.location.pathname}${formParams}`;
    // We use direct history manipulation since useSearchParams and
    // useNavigate in react-router-dom cause unnecessary extra rerenders.
    // Also make sure to replace the state rather than pushing to avoid
    // extra back/forward slots.
    window.history?.replaceState(null, document.title, newUrl);

    //   navigate(scaffolderTaskRoute({ taskId }));
    navigate(
      `/poc-hub/${cardEntity?.metadata?.name}/clone-poc/tasks/${taskId}`,
      { state: formState },
    );
  };

  if (error) {
    notificationApi.sendNotification({
      message: `Failed to load template, ${error}`,
      disapperAfterMs: 2500,
      severity: 'error',
    });
    return <Navigate to={'/poc-hub'} />;
    // return <Navigate to={rootRoute()} />;
  }
  if (!loading && !schema) {
    notificationApi.sendNotification({
      message: `Template was not found.`,
      disapperAfterMs: 2500,
      severity: 'error',
    });
    return <Navigate to={'/poc-hub'} />;
    //  return <Navigate to={rootRoute()} />;
  }

  const customFieldComponents = Object.fromEntries(
    customFieldExtensions.map(({ name, component }) => [name, component]),
  );

  const customFieldValidators = Object.fromEntries(
    customFieldExtensions.map(({ name, validation }) => [name, validation]),
  );

  const { state }: any = useLocation();
  //  const { getPoc } = useGetPocComponent();
  const [cardEntity, setCardEntity] = useState<Entity | undefined>();
  // const { assetName } = useParams();

  useEffect(() => {
    setCardEntity(state?.data);
    //   getPoc(assetName)?.then(value => setCardEntity(value));
  }, [state]);

  return (
    <Page themeId="home">
      <Header
        pageTitleOverride="Clone POC"
        title="Clone POC"
        subtitle={
          <DevxBreadCrumb
            routes={[
              {
                type: 'link',
                link: '/',
                text: 'Home',
              },
              {
                type: 'link',
                link: '/poc-hub',
                text: 'POC Hub',
              },
              {
                type: 'text',
                link: '',
                text: templateName ?? '',
              },
            ]}
          />
        }
      />
      <Content>
        {loading && <LinearProgress data-testid="loading-progress" />}
        <Grid container className={classes.addPocContainer}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <BackButton />
            <HelpComponent helpUrl={HELP_URL.CLONE_POC_PAGE} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            className={classes.pocCardContainer}
          >
            {cardEntity ? (
              <PocCard
                item={cardEntity}
                checkFilterItem={{
                  isFavoriteFilterClick: false,
                }}
                //   starredFilterItem={starredFilterItem || []}
              />
            ) : null}
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9}>
            {schema && (
              <InfoCard
                title={schema.title}
                noPadding
                titleTypographyProps={{ component: 'h2' }}
              >
                <EntityProvider entity={entity}>
                  <MultistepJsonForm
                    formData={formState}
                    fields={customFieldComponents}
                    onChange={handleChange}
                    onReset={handleFormReset}
                    onFinish={handleCreate}
                    customFieldExtensions={customFieldExtensions}
                    steps={schema.steps.map(step => {
                      return {
                        ...step,
                        validate: createValidator(
                          step.schema,
                          customFieldValidators,
                          { apiHolder },
                        ),
                      };
                    })}
                  />
                </EntityProvider>
              </InfoCard>
            )}
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};

export default ClonePocPage;
