import { Grid, IconButton, TextField, Tooltip } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import REGISTER_API_CONSTANTS from '../Constants';
import { useStyles } from './styles';
import CONSTANTS from './Constants';
import { urlRegex } from '../../../utils/Utils';

const Server = (props: any) => {
  const { onDeleteServer, onChange, onError, clicked } = props;
  const classes = useStyles();

  const initialRender = useRef(true);

  const [formValues, setFormValues] = useState({
    url: '',
    description: '',
  });

  /* Errors */
  const [errors, setErrors] = useState({
    url: '',
  });

  const handleDelete = () => {
    onDeleteServer();
  };

  /* Change Handler for all form inputs */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const validate = () => {
    const errorObj = {
      ...errors,
    };
    errorObj.url = formValues.url.match(urlRegex)
      ? ''
      : CONSTANTS.errorMsgs.servers.url;
    setErrors(errorObj);
    return Object.values(errorObj).every((x: any) => x === '');
  };

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      if (clicked > 0) {
        const serverError = !validate();
        onError(serverError);
        onChange(formValues);
      }
    }
  }, [clicked]);

  return (
    <Grid container spacing={3} direction="row" className={classes.servers}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
          label={REGISTER_API_CONSTANTS.swaggerApiForm.url.label}
          placeholder={REGISTER_API_CONSTANTS.swaggerApiForm.url.placeholder}
          value={formValues.url}
          name="url"
          onChange={handleChange}
          fullWidth
          variant="outlined"
          multiline
          required
          {...(errors.url && {
            error: true,
            helperText: errors.url,
          })}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
          label={REGISTER_API_CONSTANTS.swaggerApiForm.description.label}
          placeholder={
            REGISTER_API_CONSTANTS.swaggerApiForm.description.placeholder
          }
          name="description"
          onChange={handleChange}
          value={formValues.description}
          variant="outlined"
          multiline
          className={classes.serversDes}
        />
        <Tooltip title="Delete">
          <IconButton
            onClick={handleDelete}
            className={`${classes.actionIcons} ${classes.serversDelBtn}`}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default Server;
