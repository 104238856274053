import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from '../common/styles';
import * as yup from 'yup';

const AddPagePopup = ({
  open,
  onClose,
  onSave,
  data,
}: {
  open: boolean;
  onClose: Function;
  onSave: Function;
  data?: any;
}) => {
  const emptyFormValues = useRef({
    isActive: true,
    pageRoute: '',
    duplicates: '',
  });

  const [formValues, setFormValues] = useState(emptyFormValues.current);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  // const { open, closeIconClicked, isEditClick } = props;
  const [_pagerouteValidationErr, setPageRouteValidationError] = useState('');
  const [toggle, setToggle] = useState(false);

  const handleSetToggle = (duplicates: string) => {
    setToggle(duplicates ? true : false);
  };

  useEffect(() => {
    if (data) {
      setIsEditMode(true);
      setFormValues(data);
      handleSetToggle(data?.duplicates);
    } else {
      setIsEditMode(false);
      setFormValues(emptyFormValues.current);
      handleSetToggle(data?.duplicates);
    }
  }, [data]);

  const classes = useStyles();

  const handleToggleClick = () => {
    setToggle(!toggle);
  };

  /* Change Handler for all form inputs */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  /* Function for validating inputs */
  const validateInputs = async () => {
    const descResult = yup
      .string()
      .required('Page Route is required')
      .min(3, 'At least 3 characters required')
      .max(100, 'Maximum 100 characters')
      .validate(formValues.pageRoute)
      .then(() => {
        setPageRouteValidationError('');
        return true;
      })
      .catch(err => {
        setPageRouteValidationError(err.message);
        return false;
      });

    const allPromises = await Promise.all([descResult]);
    return allPromises.reduce((prev, current) => {
      return prev && current;
    });
  };

  const handleSaveClicked = async () => {
    if (await validateInputs()) {
      onSave(formValues, isEditMode);
    }
  };

  useEffect(() => {
    if (!open) {
      setPageRouteValidationError('');
    }
  }, [open]);

  return (
    <Dialog open={open} className={classes.DialogBox} fullWidth>
      <DialogTitle id="dialog-title">
        <div>{data ? 'Edit Page' : 'Add Page'}</div>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => onClose()}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div>
          <TextField
            label="Page Route"
            name="pageRoute"
            required
            fullWidth
            multiline
            variant="outlined"
            onChange={handleChange}
            value={formValues.pageRoute}
            {...(_pagerouteValidationErr && {
              error: true,
              helperText: _pagerouteValidationErr,
            })}
          />
        </div>
        <div className={`${classes.pageListMargin} ${classes.flex}`}>
          <Typography component="div">Duplicate Routes/Page Link</Typography>
          <FormGroup className={classes.toggleSwitch}>
            <FormControlLabel
              control={
                <Switch defaultChecked={toggle} onClick={handleToggleClick} />
              }
              label=""
            />
          </FormGroup>
        </div>
        {toggle && (
          <>
            <div>
              <TextField
                className={classes.pageListMargin}
                label="Routes"
                name="duplicates"
                fullWidth
                multiline
                minRows={3}
                variant="outlined"
                onChange={handleChange}
                value={formValues.duplicates}
              />
            </div>
            <div className={classes.pageListMargin}>
              <Typography
                component="h2"
                className={classes.instructionsHeading}
              >
                Instructions
              </Typography>
              <div>
                1. Copy the duplicate link/route after the devx.cbre.com <br />
                2. Paste one link/route per line, use new line for adding
                another new link, <br />
                Example: If <b>“devx.cbre.com/catalog/discover”</b> is the link,
                then just copy the <b>“/catalog/discover”</b> and paste it in
                the routes text area.
              </div>
            </div>
          </>
        )}
      </DialogContent>

      <DialogActions className={classes.btnsMargin}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.popupBtns}
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.popupBtns}
          onClick={handleSaveClicked}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPagePopup;
