import React, { useState, createContext, useEffect } from 'react';

const initialState = {
  enable: false,
  setEnabled: () => {}
};

export const ChatBotContext = createContext<any>(initialState);

export const ChatBotProvider = (props: any) => {
  const [enabled, setEnabled] = useState<boolean | null>(null);
  
  return (
    <ChatBotContext.Provider
      value={{
        enabled,
        setEnabled,
      }}
    >
      {props.children}
    </ChatBotContext.Provider>
  );
};
