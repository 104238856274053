import React, { useEffect, useState, useContext } from 'react';
import {
  Grid,
  LinearProgress,
} from '@material-ui/core';
import { HelpComponent } from '../../../utils/helpComponent';
import { WarningPanel, CodeSnippet } from '@backstage/core-components';
import BackButton from '../../../utils/backButton';
import HELP_URL from '../../../utils/helpLinkConstant';
import { PromotionContext } from '../../../../providers/PromotionDataProvider';
import PointsCard from './PointsCard';
import { gamificationApiRef } from '../../../../apis/gamificationApis';
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';

const PointsConfigListPage = (props: any) => {
  const { onclickHandler } = props;
  const [Data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const getLeaderBoardData = useApi(gamificationApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  useEffect(() => {
    getLeaderBoardData
      .getRewardsCriteria()
      .then((res: any) => {
        setIsLoading(false)
        setData(res);
      })
      .catch((error: any) => {
        notificationApi.sendNotification({
          message: `Error occurred - ${error?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  }, []);

  return (
    <div className={"classes.leaderBoardPageContainer"} >
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={"classes.leaderBoardPageSearch"}
        >
          <BackButton />
          <HelpComponent
            helpUrl={
              HELP_URL.updateRewardsPoints
            }
          />
        </Grid>
      </Grid>

      {isLoading && <LinearProgress />}

      {
        !isLoading && Data && Data.length === 0 && (<div>
          <WarningPanel
            severity="error"
            title="Could not fetch list"
          >
            <CodeSnippet language="text" text="Error" />
          </WarningPanel>
        </div>)

      }
      <Grid container>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h2>Points Configuration</h2>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          {Data && Data.length > 0 && Data.map((item: any, index: number) =>
            <PointsCard onclickHandler={onclickHandler} data={item} key={index} />)
          }
        </Grid>


      </Grid>
    </div>
  );
};

export default PointsConfigListPage;
