import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  createStyles,
  Grid,
  IconButton,
  InputBase,
  LinearProgress,
  makeStyles,
  Paper,
  TablePagination,
} from '@material-ui/core';

import ClearButton from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import SwapVertIcon from '@material-ui/icons/SwapVert';

import BackButton from '../../utils/backButton';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import AddEditTagModal from './AddEditTagModal';
import TagCard from './TagCard';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import {
  InformationExchangeApi,
  informationExchangeApiRef,
  Tag,
  TagsResponse,
} from '../../../apis/informationExchange';
import { useApi } from '@backstage/core-plugin-api';
import { AuthContext } from '../../../providers/AuthProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tagsPage: {
      [theme.breakpoints.down('sm')]: {
        padding: '0',
        marginTop: '20px',
      },
    },
    tagsCount: {
      display: 'flex',
      alignItems: 'center',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '20px',
      color: theme.palette.text.secondary,
      lineHeight: '24px',
    },
    search: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    root: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '10px',
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        marginRight: '0px',
      },
    },
    btnTabs: {
      borderRadius: '4px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '15px',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    swapIcon: {
      background: '#538184',
      color: 'white',
      cursor: 'unset',
      '&:hover': {
        background: '#538184',
        color: 'white',
      },
      '&.Mui-disabled': {
        color: 'white',
      },
    },
    btnDefault: {
      backgroundColor: 'transparent',
      color: theme.palette.text.secondary,
      textTransform: 'none',
      padding: '6px 10px',
      [theme.breakpoints.down('sm')]: {
        flex: 1,
      },
    },
    btnSelected: {
      padding: '6px 10px',
      backgroundColor: '#CFCFCF',
      color: '#00000094',
      textTransform: 'none',
      [theme.breakpoints.down('sm')]: {
        flex: 1,
      },
    },
    addTagBtn: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      flexGrow: 0,
      marginLeft: '12px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '12px',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        marginLeft: '0px',
      },
    },
    addTag: {
      textTransform: 'initial',
      padding: '11px 11px 11px 11px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    PagePaginationContainer: {
      width: '100%',
      marginTop: '15px',
      marginBottom: '110px',
    },
    tablePaginationRoot: {
      overflow: 'hidden',
    },
    tablePaginationActions: {
      '& button': {
        [theme.breakpoints.down('sm')]: {
          padding: '0',
        },
      },
    },
  }),
);
const TagsPage = () => {
  const classes = useStyles();
  const { isAdmin,profEmail } = useContext(AuthContext);
  const navigate = useNavigate();

  const [tagsData, setTagsData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBtn, setSelectedBtn] = useState('newest');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [contentPerPage, setContentPerPage] = useState(20);
  const [searchText, setSearchText] = useState<string | undefined>();
  const [isEditClick, setEditClick] = useState<boolean>(false);
  const [isDeleteClick, setDeleteClick] = useState<boolean>(false);
  const [edittagData, setEdittagData] = useState<any>({});
  const [subscribeData, setSubscribeDataData] = useState<any>([]);

  const informationExchange: InformationExchangeApi = useApi(
    informationExchangeApiRef,
  );

  const notificationApi: NotificationApi = useApi(notificationApiRef);
  /* Add Tag Modal Popup */
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdittagData({});
    setEditClick(false);
    setDeleteClick(false);
  };

  const onEditClick = (tagData: any) => {
    setEditClick(true);
    setEdittagData(tagData);
    handleOpen();
  };
  const handleDelete = (tagData: any) => {
    setDeleteClick(true);
    setEdittagData(tagData);
    handleOpen();
  };

  const handleSubscribe = (data: any) => {
    if(!data) return;
    const payload = {
      username: profEmail,
      tagname: data.tag,
      tagid: data.id,
      subscribe: !data.subscribe,
    };

    const subscribeText = !data.subscribe ? 'Subcribed' : 'Unsubscribed'
    informationExchange
      .addSubscribeTag(payload)
      .then(() => {
        notificationApi.sendNotification({
          message: `Tag is ${subscribeText} successfully`,
          disapperAfterMs: 2500,
          severity: 'success',
        });
        getSubscribeTag()
      })
      .catch(err => {
        notificationApi.sendNotification({
          message: err?.response?.data?.message,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  const handleSaveTags = (data: any) => {
    if (data?.id) {
      informationExchange
        .editTags(data)
        .then(() => {
          notificationApi.sendNotification({
            message: `Tag is Updated successfully`,
            disapperAfterMs: 2500,
            severity: 'success',
          });
          closeIconClicked();
          getTags({
            page: currentIndex + 1,
            pagesize: contentPerPage,
            sortby: selectedBtn,
          });
        })
        .catch(err => {
          notificationApi.sendNotification({
            message: err?.response?.data?.message,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });
    } else {
      informationExchange
        .addTags(data)
        .then(() => {
          closeIconClicked();
          notificationApi.sendNotification({
            message: `Tag is Added successfully`,
            disapperAfterMs: 2500,
            severity: 'success',
          });
          getTags({
            page: currentIndex + 1,
            pagesize: contentPerPage,
            sortby: selectedBtn,
          });
        })
        .catch(err => {
          notificationApi.sendNotification({
            message: err?.response?.data?.message,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });
    }
  };
  const deleteTag = (data: any) => {
    informationExchange
      .deleteTags(data)
      .then(() => {
        closeIconClicked();
        notificationApi.sendNotification({
          message: `Tag is Deleted successfully`,
          disapperAfterMs: 2500,
          severity: 'success',
        });
        getTags({
          page: currentIndex + 1,
          pagesize: contentPerPage,
          sortby: selectedBtn,
        });
      })
      .catch(err => {
        notificationApi.sendNotification({
          message: err?.message,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  const closeIconClicked = () => {
    handleClose();
  };

  const getTags = (param: any) => {
    setIsLoading(true);
    informationExchange
      .getAllTags(param)
      .then((data: any) => {
       
       
        setTagsData(data);
        setIsLoading(false);
      })
      .catch(() => {
        notificationApi.sendNotification({
          message: `Failed to load Data`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  useEffect(() => {
    const params = {
      page: currentIndex + 1,
      pagesize: contentPerPage,
      sortby: selectedBtn,
      search: searchText,
    };
    getTags(params);
  }, [selectedBtn, currentIndex, contentPerPage]);

  const getSubscribeTag= ()=>{
    if(profEmail)
    informationExchange
      .getSubscribeTag(profEmail)
      .then((data: any) => {       
        setSubscribeDataData(data);       
      })
      .catch(() => {
        notificationApi.sendNotification({
          message: `Failed to load subscribe Data`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  }

  useEffect(() => {  
    getSubscribeTag()
  }, [profEmail]);

  const onChangeSearchText = async (e: any) => {
    setSearchText(e?.target?.value);
    debounceCallback(e.target.value);
  };

  const onPageChange = (
    event: React.SyntheticEvent | null,
    pageNumber: number,
  ): void => {
    if (event) {
      event.preventDefault();
    }
    setCurrentIndex(pageNumber);
  };

  const onRowsPerPageChange = (event: React.SyntheticEvent | null): void => {
    if (event) {
      event.preventDefault();
    }
    setContentPerPage(event?.target?.value);
  };

  const debounce = (fn: any, delay: any) => {
    let timeoutId: any;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => fn.apply(this, args), delay);
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCallback = useCallback(
    debounce((value: any) => {
      if (value !== undefined && String(value).trim().length === 0) {
        getTags({
          page: currentIndex + 1,
          pagesize: contentPerPage,
          sortby: selectedBtn,
          search: value,
        });
      }

      setIsLoading(true);
      getTags({
        page: currentIndex + 1,
        pagesize: contentPerPage,
        sortby: selectedBtn,
        search: value,
      });
    }, 1000),
    [],
  );

  return (
    <>
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <BackButton />
          <HelpComponent helpUrl={HELP_URL.INFO_EXCHANGE_ALL_QUESTION_PAGE} />
        </Grid>
      </Grid>
      {isLoading && <LinearProgress />}

      <div className={`codePage ${classes.tagsPage}`}>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <div className={classes.tagsCount}>{tagsData?.count} Tags</div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={10} className={classes.search}>
            <Paper component="form" className={classes.root}>
              <IconButton disabled type="submit" aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                placeholder="Search "
                fullWidth
                inputProps={{ 'aria-label': 'search ' }}
                value={searchText}
                onChange={e => onChangeSearchText(e)}
              />
              <IconButton
                aria-label="search"
                onClick={() => {
                  setSearchText('');
                  getTags({
                    page: currentIndex + 1,
                    pagesize: contentPerPage,
                    sortby: selectedBtn,
                  });
                  return;
                }}
              >
                <ClearButton />
              </IconButton>
            </Paper>

            <ButtonGroup
              disableElevation
              size="large"
              variant="outlined"
              className={classes.btnTabs}
            >
              <Button disabled className={classes.swapIcon}>
                <SwapVertIcon />
              </Button>
              <Button
                className={
                  selectedBtn === 'popular'
                    ? classes.btnSelected
                    : classes.btnDefault
                }
                onClick={() => setSelectedBtn('popular')}
              >
                Popular
              </Button>
              <Button
                className={
                  selectedBtn === 'name'
                    ? classes.btnSelected
                    : classes.btnDefault
                }
                onClick={() => setSelectedBtn('name')}
              >
                Name
              </Button>
              <Button
                className={
                  selectedBtn === 'newest'
                    ? classes.btnSelected
                    : classes.btnDefault
                }
                onClick={() => setSelectedBtn('newest')}
              >
                Newest
              </Button>
            </ButtonGroup>
            <div className={classes.addTagBtn}>
              {isAdmin && (
                <Button
                  className={classes.addTag}
                  onClick={handleOpen}
                  color="primary"
                  variant="contained"
                  startIcon={<AddIcon />}
                >
                  Tag
                </Button>
              )}
            </div>
            <AddEditTagModal
              open={open}
              setOpen={setOpen}
              closeIconClicked={closeIconClicked}
              handleSaveTags={handleSaveTags}
              tags={edittagData}
              isEditClick={isEditClick}
              isDeleteClick={isDeleteClick}
              deletetag={deleteTag}
            />
          </Grid>
        </Grid>
        <Grid container>
          {tagsData?.tags?.map((tag: Tag) => (
            <Grid item xs={12} sm={12} md={3} lg={3} key={tag.id}>
              <TagCard
                tag={tag}
                handleEdit={onEditClick}
                handleDelete={handleDelete}
                handleSubscribe={handleSubscribe}
                subscribeData={subscribeData}
              />
            </Grid>
          ))}
          {tagsData && tagsData !== undefined && tagsData.length !== 0 && (
            <div className={classes.PagePaginationContainer}>
              <Paper>
                <TablePagination
                  classes={{
                    root: classes.tablePaginationRoot,
                    actions: classes.tablePaginationActions,
                  }}
                  SelectProps={{
                    title: 'perPageSelect',
                    native: true,
                  }}
                  component="div"
                  page={currentIndex}
                  rowsPerPage={contentPerPage}
                  count={tagsData?.count}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onRowsPerPageChange}
                  rowsPerPageOptions={[5, 10, 15, 20]}
                />
              </Paper>
            </div>
          )}
        </Grid>
      </div>
    </>
  );
};

export default TagsPage;
