/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  ReactElement,
  createContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useGetApms } from '../hooks/useGetApms';
import { useLocation } from 'react-router';
import { IApmDetails } from '../interfaces';
import { useGetMyApms } from '../hooks/useGetMyApms';

const initialState = {
  allApms: [],
  apmInfo: null,
  setApmInfo: () => {
    return null;
  },
  myApms: [],
};

export interface ITPContext {
  apmInfo: IApmDetails | null;
  allApms: IApmDetails[];
  setApmInfo: Function;
  myApms: string[] | undefined;
}

export interface ITProviderProps {
  children: ReactElement;
}

export const TPContext = createContext<ITPContext>(initialState);

export const TPProvider = (props: ITProviderProps) => {
  const location = useLocation();
  const localAllApms = useRef([]);

  const [allApms] = useGetApms(
    localAllApms.current.length === 0 &&
      (location.pathname === '/provisioning-center' ||
        location.pathname === '/provisioning-center/'),
  );

  const [myApms] = useGetMyApms(
    location.pathname === '/provisioning-center' ||
      location.pathname === '/provisioning-center/',
  );

  useEffect(() => {
    localAllApms.current = allApms;
  }, [allApms]);

  const [apmInfo, setApmInfo] = useState<IApmDetails | null>(null);

  return (
    <TPContext.Provider
      value={{
        allApms,
        apmInfo,
        setApmInfo,
        myApms,
      }}
    >
      {props.children}
    </TPContext.Provider>
  );
};
