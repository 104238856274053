import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TimeConverterIcon = (props: any) => (
  <SvgIcon viewBox={'48 48'} style={{ transform: 'scale(3)' }} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 7C11.4477 7 11 7.44772 11 8V11.5858L8.29289 14.2929C7.90237 14.6834 7.90237 15.3166 8.29289 15.7071C8.68342 16.0976 9.31658 16.0976 9.70711 15.7071L13 12.4142V8C13 7.44772 12.5523 7 12 7Z"
      fill="#69DDC7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4ZM22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
      fill="#69DDC7"
    />
  </SvgIcon>
);

export default TimeConverterIcon;
