import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import BadgeDisplayImage from '../../../leaderBoard/badgeTypePicker';
import { useCustomStyles } from './CardStyles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

export interface LeaderAndBadgesCardDataProps {
  id?: any;
  name?: any;
  description?: any;
  maxpoints?: any;
}

export interface LeaderAndBadgesCardProps {
  data: LeaderAndBadgesCardDataProps;
  onClickDeleteButton?: (params: any) => void;
  onClickEditButton?: (params: any) => void;
}

const LeaderAndBadgesCard = (props: LeaderAndBadgesCardProps) => {
  const { id, name, description, maxpoints } = props.data;
  const { onClickDeleteButton, onClickEditButton, data } = props;
  const classes = useCustomStyles();
  return (
    <div className={classes.CardEntityContainer}>
      <Grid container className={classes.Container}>
        <Grid item md={2} sm={4} xs={6} className={classes.CardTypeContainer}>
          <Typography className={classes.CardTypeFontBold}>
            <b>Badge Title : </b> {name}
          </Typography>
        </Grid>

        <Grid item md={2} sm={4} xs={6} className={classes.CardTypeContainer}>
          <div className={classes.IconWithText}>
            <Typography className={classes.CardTypeText}>
              <b>Badge Icon : </b>
            </Typography>
            <BadgeDisplayImage type={name} />
          </div>
        </Grid>

        <Grid item md={2} sm={4} xs={6} className={classes.CardTypeContainer}>
          <Typography className={classes.CardTypeFontBold}>
            <b>Level : </b>
            {name}
          </Typography>
        </Grid>

        <Grid item md={3} sm={4} xs={6} className={classes.CardTypeContainer}>
          <Typography className={classes.CardTypeFontBold}>
            <b>Description :</b> {description}
          </Typography>
        </Grid>

        <Grid item md={2} sm={4} xs={9} className={classes.CardTypeContainer}>
          <Typography className={classes.CardTypeFontBold}>
            <b>Points Threshold :</b> {maxpoints}
          </Typography>
        </Grid>

        <Grid item md={1} sm={3} xs={3} className={classes.CardButtonContainer}>
          <Button
            data-testid={`edit-btn-${id}`}
            onClick={() => onClickEditButton?.(data)}
          >
            <EditIcon />
          </Button>
          <Button
            data-testid={`delete-btn-${id}`}
            onClick={() => onClickDeleteButton?.(id)}
          >
            <DeleteIcon />
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default LeaderAndBadgesCard;
