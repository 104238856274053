import React, { useContext, useRef } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { SignInAvatar } from './SignInAvatar';
import { UserSettingsMenu } from './UserSettingsMenu';
import { AuthContext } from '../../../providers/AuthProvider';
import { InfoCard } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { userProfileApiRef } from '../../../apis/userProfileApi';

export const Profile = () => {
  const { profEmail, profileName } = useContext(AuthContext);

  const usernameRef = useRef(null);
  const userProfileApi = useApi(userProfileApiRef);

  const onMouseEnter = () => {
    const email: string | undefined = profEmail;

    userProfileApi.setUserProfileState({
      email,
      anchorRef: usernameRef?.current,
    });
  };

  return (
    <InfoCard title="Profile">
      <Grid container spacing={6}>
        <Grid item>
          <SignInAvatar size={96} />
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography variant="subtitle1" gutterBottom>
                {profileName}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                onMouseEnter={onMouseEnter}
                ref={usernameRef}
              >
                {profEmail}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <UserSettingsMenu />
          </Grid>
        </Grid>
      </Grid>
    </InfoCard>
  );
};
