import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Theme,
  FormHelperText,
  LinearProgress,
  Typography,
  Switch,
  Box,
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from '@backstage/core-plugin-api';
import SaveIcon from '@material-ui/icons/Save';

import { Content } from '@backstage/core-components';
import { HelpComponent } from '../../utils/helpComponent';
import BackButton from '../../utils/backButton';
import { Autocomplete } from '@material-ui/lab';
import RichEditor from '../../common/RichEditor';
import { ReleaseNoteApi, releaseNoteApiRef } from '../../../apis/releaseNotes';

import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { POST_MAX_SIZE } from '../../utils/constant';
import { byteSize } from '../../utils/Utils';
import { AuthContext } from '../../../providers/AuthProvider';
import { formatDateMMDDYYYY, formatDateYYYYMMDD } from '../../utils/dateUtils';
import * as Constants from '../../utils/constant';

interface Tag {
  id?: string;
  metadata?: string;
  tag: string;
  owner?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    askQuestionCard: {
      backgroundColor: theme.palette.background.paper,
      boxShadow:
        '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
      padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
    },
    divider: {
      width: '100%',
      height: '1px',
      background: '#cccccc',
      margin: '15px 9px 0px 9px',
    },
    postQueBtn: {
      margin: '0px 0px 20px 6px',
      padding: '12px 16px',
      textTransform: 'initial',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: '0',
        marginBottom: '12px',
      },
    },
    askquestionsCodePage: {
      marginBottom: '74px',
      [theme.breakpoints.down('sm')]: {
        padding: '0',
        marginTop: '15px',
      },
    },
    descError: {
      marginLeft: '14px',
      marginRight: '14px',
      color: theme.palette.errorText,
      fontSize: ' 0.75rem',
      marginTop: '14px',
    },
    postBtn: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    heading: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '32px',
      color: theme.palette.text.secondary,
      marginTop: '10px',
    },
    para: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      color: theme.palette.text.tertiary,
      marginTop: '10px',
      cursor: 'pointer',
    },
    tagsField: {
      width: '100%',
    },
    answers: {
      padding: '20px',
      border: '1px solid gray',
    },
    popper: {
      width: '100%',
    },
    overview: {
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
        1,
      )}px ${theme.spacing(2)}px`,
      marginTop: theme.spacing(4),
    },
    keyFeature: {
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
        1,
      )}px ${theme.spacing(2)}px`,
      marginTop: theme.spacing(2),
    },
    calendarColor: {
      '& input': {
        colorScheme: theme.palette.type,
      },
    },
    displayState: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

const AddEditPage = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [select, setSelect] = useState(false);
  const inputRef = useRef();

  const [displayState, setDisplayState] = useState<boolean | undefined>(
    undefined,
  );
  const [fields, setFields] = useState<any>({
    vtype: {
      error: '',
      value: '',
      required: true,
    },
    vname: {
      error: '',
      value: '',
      required: true,
    },
    vnum: {
      error: '',
      value: '',
      required: true,
    },
    releasedate: {
      error: '',
      value: formatDateYYYYMMDD(new Date()),
      required: true,
    },
    overview: {
      error: '',
      value: '',
      required: true,
    },
    tags: {
      error: '',
      value: [],
      required: false,
    },
    description: {
      error: '',
      value: '',
      required: true,
    },
    additionaldata: {
      error: '',
      value: '',
      required: false,
    },
  });

  const onChange = (
    fieldName:
      | 'vnum'
      | 'vtype'
      | 'vname'
      | 'releasedate'
      | 'overview'
      | 'tags'
      | 'description'
      | 'additionaldata',
    value: any,
    error: any,
  ) => {
    setFields((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: {
          value: value !== null ? value : fields[fieldName].value,
          error: !value && error ? error : '',
          required: fields[fieldName]?.required,
        },
      };
    });
  };
  const { id } = useParams() || '';
  const fetchNote = async () => {
    setIsLoading(true);
    const res = await releaseNoteApi.getNoteById(id);
    if (res) {
      setIsLoading(false);
      const keys = Object.keys(fields);
      const data = keys.reduce((result: any, item: string) => {
        result[item] = {
          value:
            item === 'releasedate' ? formatDateYYYYMMDD(res[item]) : res[item],
          error: '',
          // @ts-ignore
          required: fields[item].required,
        };
        return result;
      }, {});
      setDisplayState(res.displaystate === Constants.TRUE);
      setFields(data);
    }
  };
  useEffect(() => {
    if (id) {
      fetchNote();
    } else {
      setDisplayState(true);
    }
  }, []);

  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const releaseNoteApi: ReleaseNoteApi = useApi(releaseNoteApiRef);

  const { profEmail } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleEditorChange = (
    fName: 'description' | 'additionaldata',
    value: any,
  ) => {
    if (byteSize(value) > POST_MAX_SIZE) {
      onChange(fName, value, 'File size should not exceeed 5MB');
    } else {
      onChange(fName, value, null);
    }
  };

  const validate = () => {
    const keys = Object.keys(fields);
    console.log(keys);
    console.log(fields);
    console.log(fields['vtype'].required);

    keys?.forEach((k: any) => {
      if (fields[k].required && !fields[k].value) {
        onChange(k, null, 'Field Required');
      }
    });
  };

  const onClickPost = async () => {
    const keys = Object.keys(fields);
    validate();
    const data = keys.reduce((result: any, item: string) => {
      // @ts-ignore
      result[item] = fields[item].value;
      return result;
    }, {});
    let result;
    if (id) {
      result = await releaseNoteApi.updateNote(id, {
        ...data,
        displaystate: displayState ? Constants.TRUE : Constants.FALSE,
      });
    } else {
      result = await releaseNoteApi.createNote({
        ...data,
        displaystate: displayState ? Constants.TRUE : Constants.FALSE,
      });
    }

    if (result.status == 200) {
      notificationApi.sendNotification({
        message: `Release Note ${id ? 'Updated' : 'Created'} Successfully`,
        disapperAfterMs: 2500,
        severity: 'success',
      });
      setTimeout(() => navigate('/admin/release-notes'), 3000);
    }
  };

  console.log(fields.releasedate.value);

  return (
    <Box>
      <Content>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <BackButton />
            <HelpComponent helpUrl="/" />
          </Grid>
        </Grid>
        <div className={`codePage ${classes.askquestionsCodePage}`}>
          {isLoading && <LinearProgress />}
          <Grid container className={classes.askQuestionCard}>
            <Grid container style={{ padding: '0 0 0 8px' }}>
              <Grid item xs={12} sm={6} lg={3}>
                <TextField
                  fullWidth
                  multiline
                  size="small"
                  id="admin-releasenotes-edit-versiontype"
                  label="Version Type"
                  required
                  variant="outlined"
                  value={fields.vtype.value}
                  onChange={e => onChange('vtype', e.target.value, '')}
                  error={Boolean(fields.vtype.error)}
                  helperText={Boolean(fields.vtype.error) && fields.vtype.error}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <TextField
                  fullWidth
                  size="small"
                  id="admin-releasenotes-edit-versionname"
                  label="Version Name"
                  required
                  variant="outlined"
                  value={fields.vname.value}
                  onChange={e => onChange('vname', e.target.value, '')}
                  error={Boolean(fields.vname.error)}
                  helperText={Boolean(fields.vname.error) && fields.vname.error}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <TextField
                  fullWidth
                  size="small"
                  id="admin-releasenotes-edit-versionnumber"
                  label="Version Number"
                  required
                  variant="outlined"
                  value={fields.vnum.value}
                  onChange={e => onChange('vnum', e.target.value, '')}
                  error={Boolean(fields.vnum.error)}
                  helperText={Boolean(fields.vnum.error) && fields.vnum.error}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <TextField
                  className={classes.calendarColor}
                  fullWidth
                  size="small"
                  id="admin-releasenotes-edit-releasedate"
                  label="Release Date"
                  required
                  variant="outlined"
                  ref={inputRef}
                  value={fields.releasedate.value}
                  onChange={(event: React.SyntheticEvent) => {
                    onChange('releasedate', event?.target?.value, '');
                  }}
                  InputProps={{
                    inputProps: {
                      min: '2022-01-01',
                    },
                  }}
                  error={Boolean(fields.releasedate.error)}
                  type="date"
                  helperText={
                    Boolean(fields.releasedate.error) &&
                    fields.releasedate.error
                  }
                  // onSelect={() => setSelect(true)}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.overview}>
              <TextField
                fullWidth
                required
                size="medium"
                id="admin-releasenotes-edit-overview"
                label="Overview"
                variant="outlined"
                minRows={3}
                maxRows={3}
                multiline
                value={fields.overview.value}
                onChange={e => onChange('overview', e.target.value, '')}
                error={Boolean(fields.overview.error)}
                helperText={
                  Boolean(fields.overview.error) && fields.overview.error
                }
              />
            </Grid>
            <Grid container className={classes.keyFeature}>
              <Autocomplete
                className={classes.tagsField}
                freeSolo
                multiple
                options={[]}
                noOptionsText="No Option Available"
                size={'small'}
                onChange={(e: any, tagValues) =>
                  onChange('tags', tagValues, null)
                }
                id="admin-releasenotes-edit-tags"
                value={fields.tags.value || []}
                renderInput={params => (
                  <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, maxLength: 18 }}
                    variant="outlined"
                    error={
                      !!fields.tags.error &&
                      String(fields.tags.error)?.trim().length > 0
                    }
                    label="Key Features Updated"
                  />
                )}
              />
            </Grid>
            <Grid container className={classes.keyFeature}>
              <Typography component="span" className={classes.displayState}>
                Display State
              </Typography>
              {displayState !== undefined && (
                <Switch
                  checked={displayState}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setDisplayState(event.target.checked);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className={classes.heading}>Detail Description</div>
              <div className={classes.para}>
                Please explain the problem clearly with examples.
              </div>
              <RichEditor
                className="error"
                onChange={(value: any) =>
                  handleEditorChange('description', value)
                }
                onError={(err: any) => onChange('description', null, err)}
                error={Boolean(fields.description.error)}
                helperText={
                  Boolean(fields.description.error) && fields.description.error
                }
                data={fields.description.value}
              />
              {fields.description.error && (
                <FormHelperText error className={classes.descError}>
                  {fields.description.error}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className={classes.heading}>Internal Use</div>
              <div className={classes.para}>Only Visible to Admin</div>
              <RichEditor
                className="error"
                onChange={(value: any) =>
                  handleEditorChange('additionaldata', value)
                }
                onError={(err: any) => onChange('additionaldata', null, err)}
                error={Boolean(fields.additionaldata.error)}
                helperText={
                  Boolean(fields.additionaldata.error) &&
                  fields.additionaldata.error
                }
                data={fields.additionaldata.value}
              />
              {fields.additionaldata.error && (
                <FormHelperText error className={classes.descError}>
                  {fields.additionaldata.error}
                </FormHelperText>
              )}
            </Grid>
            <Grid className={classes.postBtn}>
              <Button
                className={classes.postQueBtn}
                variant="contained"
                role="submit"
                color="primary"
                onClick={() => onClickPost()}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
      </Content>
    </Box>
  );
};
export default AddEditPage;
