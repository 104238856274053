/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';

import { useApi } from '@backstage/core-plugin-api';
import { Select } from '@backstage/core-components';
import { useApiEntityTypeFilter } from './useApiEntityTypeFilter';
import { capitalizeFirstLetter } from '../../home/allAssets/util';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';

export interface ApiEntityTypePickerProps {
  initialFilter?: string;
  onChangeAssetStore?: (values: any[]) => void;
  hidden?: boolean;
}

export const ApiEntityTypePicker = (props: ApiEntityTypePickerProps) => {
  const { hidden, initialFilter, onChangeAssetStore } = props;

  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const { error, availableTypes, selectedTypes, setSelectedTypes } =
    useApiEntityTypeFilter();

  useEffect(() => {
    if (error) {
      notificationApi.sendNotification({
        message: `Failed to load api types`,
        severity: 'error',
        disapperAfterMs: 2500,
      });
    }
    if (initialFilter) {
      setSelectedTypes([initialFilter]);
      if (onChangeAssetStore) {
        onChangeAssetStore([initialFilter]);
      }
    }
  }, [error, initialFilter, setSelectedTypes]);

  if (availableTypes.length === 0 || error) return null;

  const items = [
    { value: 'all', label: 'All' },
    ...availableTypes.map((type: string) => ({
      value: type,
      label: capitalizeFirstLetter(type),
    })),
  ];

  return hidden ? null : (
    <Box pb={1} pt={1}>
      <Select
        label="Type"
        items={items}
        selected={(items.length > 1 ? selectedTypes[0] : undefined) ?? 'all'}
        onChange={value => {
          const newValues = value === 'all' ? [] : [String(value)];
          setSelectedTypes(newValues);
          if (onChangeAssetStore) {
            onChangeAssetStore(newValues);
          }
        }}
      />
    </Box>
  );
};
