import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
// @ts-ignore
import { useApi } from '@backstage/core-plugin-api';
import {
  ToolboxApi,
  toolboxApiRef,
  IToolboxItemNode,
} from '../../../../apis/toolboxApi';

export interface IParentCategory {
  label: string;
  id: string | number | undefined;
  firstLevelCategory: string;
  secondLevelCategory: string;
}

export interface IParentCategoryDropdown {
  onChange: (value: any, selectedItem: any) => void;
  error: any;
  value: number | string | undefined;
}

const ParentCategoryDropdown = ({
  onChange,
  error,
  value,
}: IParentCategoryDropdown) => {
  const [options, setOptions] = useState<IParentCategory[]>([]);

  const toolboxApi: ToolboxApi = useApi(toolboxApiRef);

  const getOptionsFromApi = () => {
    toolboxApi.getAllTools().then((value: IToolboxItemNode[] | undefined) => {
      if (value !== undefined && Array.isArray(value) && value.length > 0) {
        const root = value[0];

        const data: IParentCategory[] = [];
        data.push({
          id: root?.node?.nid,
          label: root?.node?.title,
          firstLevelCategory: '',
          secondLevelCategory: '',
        });
        const firstLevelCategories = root.children;
        if (firstLevelCategories != null && firstLevelCategories.length > 0) {
          for (let firstLevel of firstLevelCategories) {
            data.push({
              id: firstLevel?.node?.nid,
              label: firstLevel?.node?.title,
              firstLevelCategory: root?.node?.title,
              secondLevelCategory: firstLevel?.node?.title,
            });

            const secondLevelCategories = firstLevel.children;
            if (
              secondLevelCategories !== undefined &&
              secondLevelCategories.length > 0
            ) {
              for (let secondLevel of secondLevelCategories) {
                data.push({
                  id: secondLevel?.node?.nid,
                  label: secondLevel?.node?.title,
                  firstLevelCategory: firstLevel?.node?.title,
                  secondLevelCategory: secondLevel?.node?.title,
                });
              }
            }
          }
        }
        setOptions(data);
      }
    });
  };

  useEffect(() => {
    getOptionsFromApi();
  }, []);

  const getValue = () => {
    if (value && options && options?.length > 0) {
      for (let item of options) {
        if (
          item !== undefined &&
          item.id !== undefined &&
          `${item.id}` === `${value}`
        ) {
          onChange(undefined, item);
          return item;
        }
      }
    }
    return { label: 'Select', id: undefined };
  };

  return (
    <Autocomplete
      id="parent-category-dropdown"
      data-testid="parent-category-dropdown"
      // disablePortal
      options={options}
      onChange={onChange}
      getOptionLabel={option => option.label}
      value={getValue()}
      renderInput={params => (
        <TextField
          {...params}
          label="Parent"
          error={Boolean(error)}
          helperText={error}
          variant="outlined"
          required
        />
      )}
    />
  );
};

export default ParentCategoryDropdown;
