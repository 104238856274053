import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Theme,
  Typography,
  makeStyles,
  createStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import { useApi } from '@backstage/core-plugin-api';
import { ToolboxApi, toolboxApiRef } from '../../../apis/toolboxApi';
import { useNavigate } from 'react-router-dom';

export interface IToolCardProps {
  imgBase64Content: string | undefined;
  categoryName: string | undefined;
  subCategoryName: string | undefined;
  title: string | undefined;
  link: string | undefined;
  nodeId: string;
  userEmail: string;
  isPreview?: boolean;
  description?: string;
  linktitle?: string;
  links?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolCard: {
      position: 'relative',
      marginTop: '8px',
      //maxHeight: '220px',
      [theme.breakpoints.down('sm')]: {
        maxHeight: 'none',
      },
    },
    root: {
      border: '1px solid #fff',
      borderRadius: '4px',
      height: 'auto',
      minHeight: '270px',
      overflow: 'hidden',
      minWidth: '140px',
      cursor: 'auto',
      padding: '16px',
      transition: 'all 0.5s ease-in',
      '&:hover': {
        background:
          'linear-gradient(180deg, #ABABAB 0%, #C1C1C1 0.01%, #9E9C9C 6.25%, #5C5C5C 36.98%, #282828 100%)',
        boxShadow: ' 0px 6px 14px rgba(0, 0, 0, 0.24)',
        borderRadius: '8px',
        overflow: 'visible',
        zIndex: 100,
        position: 'relative',
        border: '1px solid #A7A7A7',
        '& .title-text': {
          color: '#ffffff',
        },
        '& .desc-text': {
          color: ' rgba(255, 255, 255, 0.6)',
          display: 'block',
          '-webkit-line-clamp': 'none',
          '-webkit-box-orient': 'horizontal',
          overflow: ' visible',
        },
        transition: 'all 0.5s ease-out',
      },
      [theme.breakpoints.down('sm')]: {
        maxHeight: 'none',
      },
    },
    imgRoot: {
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    img: {
      //cursor: 'pointer',
      marginBottom: '8px',
      transition: 'all .4s ease',
      // filter:
      //   'drop-shadow(0px 2px 2px rgba(255, 255, 255, 0.5)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5))',
    },
    categoryRoot: {
      animation: 'slideUpwards ease .4s forwards',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    categoryText: {
      fontSize: '10px',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      color: '#8585E0',
      textAlign: 'center',
      fontStyle: 'normal',
      fontWeight: 'bold',
      lineHeight: '16px',
      textTransform: 'uppercase',
    },
    title: {
      fontWeight: 'bold',
      fontSize: '15px',
      width: '100%',
      flex: '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // animation: 'slideUpwards ease .4s forwards',
    },
    truncatableText: {
      display: 'flex',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      fontWeight: 'bold',
      wordBreak: 'break-word',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.text.primary,
      //cursor: 'pointer',
    },
    dotRoot: {
      fontSize: '20px',
      display: 'flex',
      alignItems: 'center',
    },
    dotText: {
      fontWeight: 'bold',
      marginRight: '8px',
      marginTop: '-15px',
      fontSize: '25px',
    },
    subCategoryText: {
      fontSize: '10px',
      fontWeight: 'bold',
    },
    description: {
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      wordBreak: 'break-word',
      color: theme.palette.text.secondary,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      margin: '10px 15px 0px 0px',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        '-webkit-line-clamp': 'none',
        '-webkit-box-orient': 'horizontal',
        overflow: ' visible',
      },
    },
    supportFields: {
      margin: '8px 0px 8px 0px',
      fontFamily: 'Calibre-R-Regular',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '16px',
      color: ' #FFFFFF',
      [theme.breakpoints.down('sm')]: {
        color: 'black',
      },
    },
    links: {
      padding: '0px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
      },
    },
    supportLinks: {
      textTransform: 'initial',
      fontFamily: 'Calibre-R-Regular',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.005em',
      color: '#17E88F',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
    },
    categories: {
      marginTop: '8px',
    },
    supportIcons: {
      color: 'rgba(255, 255, 255, 0.6)',
      [theme.breakpoints.down('sm')]: {
        color: 'rgba(26, 26, 26, 0.6)',
      },
    },
    toolcardBtns: {
      display: 'flex',
      gap: '8px',
      marginTop: '10px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    openBtn: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0px 12px',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      textTransform: 'capitalize',
      background: '#17E88F',
      color: ' #1A1A1A',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#17E88F',
      },
    },
    exploreBtn: {
      border: '1px solid #17E88F',
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0px 12px',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      textTransform: 'capitalize',
      color: '#17E88F',
      '&:hover': {
        border: '1px solid #17E88F',
      },
    },
  }),
);

const ToolCard = ({
  title,
  imgBase64Content,
  categoryName,
  subCategoryName,
  link,
  nodeId,
  userEmail,
  isPreview = false,
  description,
  linktitle,
  links = [],
}: IToolCardProps) => {
  const classes = useStyles();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showCategories, setShowCategories] = useState<boolean>(false);
  const toolboxApi: ToolboxApi = useApi(toolboxApiRef);
  const ref = useRef(null);
  const navigate = useNavigate();

  const onMouseEnter = () => {
    setShowCategories(true);
  };

  const onMouseLeave = () => {
    setShowCategories(false);
  };

  useEffect(() => {
    setShowCategories(isMobile);
  }, [isMobile]);

  const resizeObserver = new ResizeObserver(entries => {
    window.requestAnimationFrame(() => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }
      for (const entry of entries) {
        if (entry.borderBoxSize) {
          if (showCategories) {
            entry.target.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
            });
          }
        }
      }
    });
  });

  useEffect(() => {
    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
    };
  }, [ref.current, showCategories]);

  const exploreLink = () => {
    navigate(`/toolbox/${nodeId}`);
    toolboxApi.sendAuditData({
      link,
      title: title ?? '',
      nodeId,
      userEmail,
    });

    // if (link !== undefined && String(link).trim().length > 0) {
    //   window.open(link, '_blank');
    //   toolboxApi.sendAuditData({
    //     link,
    //     title: title ?? '',
    //     nodeId,
    //     userEmail,
    //   });
    // }
  };

  const openLink = () => {
    if (link !== undefined && String(link).trim().length > 0) {
      window.open(link, '_blank');
      toolboxApi.sendAuditData({
        link,
        title: title ?? '',
        nodeId,
        userEmail,
      });
    }
  };

  return (
    <div className={classes.toolCard}>
      <Box
        className={classes.root}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        title={link ?? ''}
        ref={ref}
      >
        <Box>
          <Box className={classes.imgRoot}>
            {imgBase64Content !== undefined &&
              String(imgBase64Content).length > 0 && (
                <img
                  className={classes.img}
                  height={showCategories ? '100px' : '80px'}
                  width={showCategories ? '100px' : '80px'}
                  alt="Tool Logo"
                  src={imgBase64Content}
                  loading="lazy"
                />
              )}

            {isPreview && (
              <Typography className={classes.title}>{title}</Typography>
            )}

            {title && !isPreview && (
              <Typography className={`${classes.truncatableText} title-text`}>
                {title ?? ''}
              </Typography>
            )}
          </Box>
        </Box>
        <Box className={classes.categories}>
          {categoryName !== undefined && String(categoryName).length > 0 && (
            <Typography
              className={classes.categoryText}
              data-testid="category-subcategory"
            >
              {subCategoryName
                ? `${categoryName ?? ''} ${
                    subCategoryName !== undefined
                      ? '  \u2022  ' + subCategoryName
                      : ''
                  }` ?? ''
                : `${categoryName ?? ''}` ?? ''}
            </Typography>
          )}
        </Box>

        {showCategories ? (
          <Box className={classes.toolcardBtns}>
            <Button
              color="primary"
              variant="contained"
              className={classes.openBtn}
              endIcon={<OpenInNewOutlinedIcon />}
              onClick={openLink}
            >
              Open
            </Button>
            <Button
              color="primary"
              variant="outlined"
              className={classes.exploreBtn}
              onClick={exploreLink}
            >
              Explore
            </Button>
          </Box>
        ) : null}
        <div className={`${classes.description} desc-text`}>{description}</div>
        {
          //</Box>(isMobile || showCategories) && (
          // <Box className={classes.categoryRoot}>
          //   <div className={classes.supportFields}>{linktitle}</div>
          //   {links &&
          //     links.map(
          //       (item: any, key: number) =>
          //         item?.displayname && (
          //           <Button
          //             startIcon={
          //               item?.type === 'contact' ? (
          //                 <ContactMailOutlinedIcon
          //                   className={classes.supportIcons}
          //                 />
          //               ) : (
          //                 <InsertDriveFileOutlinedIcon
          //                   className={classes.supportIcons}
          //                 />
          //               )
          //             }
          //             className={classes.links}
          //             key={key}
          //           >
          //             <Link
          //               className={classes.supportLinks}
          //               href={
          //                 item?.type === 'contact'
          //                   ? `mailto:${item.link}`
          //                   : item.link
          //               }
          //               target={'_blank'}
          //             >
          //               {item.displayname}
          //             </Link>
          //           </Button>
          //         ),
          //     )}
          //   {/* <Button
          //     startIcon={
          //       <InsertDriveFileOutlinedIcon className={classes.supportIcons} />
          //     }
          //     className={classes.links}
          //   >
          //     <Link className={classes.supportLinks} href="#">
          //       Document
          //     </Link>
          //   </Button> */}
          // </Box>
          // )}
        }
      </Box>
    </div>
  );
};
export default ToolCard;
