import { createApiRef } from '@backstage/core-plugin-api';
import { AxiosInstance } from 'axios';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';

export interface Item {
  id: string | undefined;
  title: string | undefined;
  description: string | undefined;
  url: string | undefined;
  category_id: string | undefined;
  category_name: string | undefined;
}

export interface DataInsightsApi {
  getItems: () => Promise<Item[] | undefined>;
  getItemById: (id: string) => Promise<Item | undefined>;
}

export const DataInsightsApiRef = createApiRef<DataInsightsApi>({
  id: 'cbre.devx.api.datainsights',
});

export class DataInsightsApiImpl implements DataInsightsApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  async getItems(): Promise<Item[] | undefined> {
    const instance = await this.axiosInstancePromise;
    return instance.get('service/datainsights/item').then(res => res.data);
  }

  async getItemById(id: string): Promise<Item | undefined> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`service/datainsights/item/${id}`)
      .then(res => res.data);
  }
}
