import React, { useState, useEffect, useContext } from 'react';
import {
  TableColumn,
  Table,
  WarningPanel,
  CodeSnippet,
  TableProps,
  Progress,
} from '@backstage/core-components';
import {
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
  Button,
  Switch,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import * as columnFactories from '../../utils/devXAdminTableColumns';
import {
  COMPONENT,
  API,
  CHECK_USER_ROLE,
  DELETE_CONFIRMATION_TITLE,
  DELETE_CONFIRMATION_MESSAGE,
} from '../../utils/constant';
import AddAdminReviewer from '../addAdminReviewer';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import { PromotionContext } from '../../../providers/PromotionDataProvider';

const columns: TableColumn<columnFactories.EntityRow>[] = [
  columnFactories.createUserColumn(),
  columnFactories.createFirstNameColumn(),
  columnFactories.createLastNameColumn(),
  columnFactories.createEmailColumn(),
  columnFactories.createDateCreatedColumn(),
];

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import BackButton from '../../utils/backButton';
import { useApi } from '@backstage/core-plugin-api';
import { adminApiRef } from '../../../apis/adminApi';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    DialogBox: {
      minWidth: 300,
    },
    manageReviewers: {
      ['@media (max-width:920px) and (min-width: 280px)']: {
        padding: '0',
      },
    },
    reviewersTable: {
      ['@media (max-width:920px) and (min-width: 280px)']: {
        marginTop: '20px',
      },
    },
    themeColor: {
      '& tr': {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.background.default + '!important',
        '& td ': {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: theme.palette.background.default + '!important',
          '& button ': {
            color: theme.palette.primary.main,
          },
        },
        '& th': {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: theme.palette.background.default + '!important',
        },
      },
      '& div::-webkit-scrollbar-thumb': {
        border: `4px solid ${theme.palette.text.tertiary}`,
      },
    },
    theme: {
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

export interface ManageReviewerProps {
  userEmail?: string;
  userInfo?: {
    componentReviewEnabled: any;
    apiReviewEnabled: any;
  };
}

export const ManageReviewer = (props: ManageReviewerProps) => {
  const { userEmail, userInfo } = props;
  const [isClickCodeSeeMore, setClickCodeSeeMore] = useState(false);
  const [isClickApiSeeMore, setClickApiSeeMore] = useState(false);
  const [isCodeReviewEnabled, setCodeReviewEnabled] = useState(
    userInfo?.componentReviewEnabled?.toLowerCase() ===
      CHECK_USER_ROLE?.toLowerCase(),
  );
  const [isApiReviewEnabled, setApiReviewEnabled] = useState(
    userInfo?.apiReviewEnabled?.toLowerCase() ===
      CHECK_USER_ROLE?.toLowerCase(),
  );

  const [deleteUserInfo, setDeleteUserInfo] = useState<any>();

  const classes = useStyles();
  const [adminList, setAdminList] = useState<any>(null);
  const [open, setOpen] = useState(false);

  const [isAdminAdded, setAdminAdded] = useState<any>(false);

  const adminApi = useApi(adminApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const promotionContext = useContext(PromotionContext);
  useEffect(() => {
    promotionContext.setToggleHelpButton(false);
  }, []);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const getReviewerList = () => {
    return adminApi
      .getReviewerList()
      .then(data => {
        setAdminList({
          responseData: data || {},
          status: 'fetched',
        });
        return data;
      })
      .catch((error: any) => {
        setAdminList({
          responseData: {},
          status: 'Error',
        });
        notificationApi.sendNotification({
          message: `Error occurred - ${error?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  useEffect(() => {
    getReviewerList();
  }, []);

  const data = (adminList && adminList?.responseData) || [];

  const deleteAdminAndReview = async () => {
    adminApi
      .deleteReviewer(deleteUserInfo?.kindType, deleteUserInfo?.user)
      .then(data => {
        notificationApi.sendNotification({
          message: `Record has been successfully deleted`,
          disapperAfterMs: 2500,
          severity: 'success',
          callback: getReviewerList,
        });
        return data;
      })
      .catch(() => {
        notificationApi.sendNotification({
          message: `Record cannot be deleted`,
          disapperAfterMs: 2500,
          severity: 'error',
          callback: getReviewerList,
        });
      });
  };

  const deleteActionButtonClicked = (user: any) => {
    setDeleteUserInfo(user);
    openDialog();
  };

  const YesButtonClicked = () => {
    closeDialog();
    deleteAdminAndReview();
  };

  const defaultActions: TableProps<columnFactories.EntityRow>['actions'] = [
    user => {
      return {
        icon: () => <DeleteIcon />,
        tooltip: 'Delete',
        onClick: () => deleteActionButtonClicked(user),
        disabled:
          user?.user?.toLowerCase() === userEmail?.toLowerCase() ? true : false,
      };
    },
  ];

  const apiReviewerList =
    (data &&
      data.filter(
        (item: any) =>
          item.kindType?.toLowerCase() === API.toLowerCase() &&
          item.isActive?.toLowerCase() === CHECK_USER_ROLE.toLowerCase(),
      )) ||
    [];
  const CodeReviewerList =
    (data &&
      data.filter(
        (item: any) =>
          item.kindType?.toLowerCase() === COMPONENT.toLowerCase() &&
          item.isActive?.toLowerCase() === CHECK_USER_ROLE.toLowerCase(),
      )) ||
    [];

  const clickApiSeeMore = () => {
    setClickApiSeeMore(true);
    setClickCodeSeeMore(false);
  };
  const clickCodeSeeMore = () => {
    setClickApiSeeMore(false);
    setClickCodeSeeMore(true);
  };

  const clickGoBack = () => {
    setClickApiSeeMore(false);
    setClickCodeSeeMore(false);
  };

  const updateReviewer = async (requestData: any) => {
    return adminApi
      .updateReviewer(requestData)
      .then(data => {
        notificationApi.sendNotification({
          message: `Review status updated successfully`,
          disapperAfterMs: 2500,
          severity: 'success',
          callback: getReviewerList,
        });
        return data;
      })
      .catch(() => {
        notificationApi.sendNotification({
          message: `Review status not updated`,
          disapperAfterMs: 2500,
          severity: 'error',
          callback: getReviewerList,
        });
      });
  };

  const handleToggleCode = () => {
    setCodeReviewEnabled(!isCodeReviewEnabled);
    const requestData = {
      kindType: 'Component',
      review: !isCodeReviewEnabled ? 'Y' : 'N',
    };
    updateReviewer(requestData);
  };

  const handleToggleApi = () => {
    setApiReviewEnabled(!isApiReviewEnabled);
    const requestData = {
      kindType: 'API',
      review: !isApiReviewEnabled ? 'Y' : 'N',
    };
    updateReviewer(requestData);
  };

  if (!isClickCodeSeeMore && !isClickApiSeeMore) {
    return (
      <Grid
        item
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item md={12} xs={12}>
          <BackButton />
          <HelpComponent helpUrl={HELP_URL.MANAGE_REVIEWERS} />
        </Grid>

        <Grid item xs={12} md={3}>
          <div className={`infocard-admin ${classes.theme}`}>
            <h1>CODE</h1>
            <p>To add or remove code reviewers click on see more. </p>
            <p>
              To enable or disable all code reviewing use the toggle switch.
            </p>
            <div className="Switch-Admin">
              <label>
                {isCodeReviewEnabled ? 'Review Enabled' : 'Review Disabled'}
              </label>
              <Switch
                edge="end"
                onChange={handleToggleCode}
                checked={isCodeReviewEnabled}
                inputProps={{
                  'aria-labelledby': 'switch-list-label-code',
                }}
              />
            </div>
            <div className="button-see-more">
              <Button
                color="primary"
                variant="contained"
                onClick={clickCodeSeeMore}
              >
                See More
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className={`infocard-admin ${classes.theme}`}>
            <h1>API</h1>
            <p>To add or remove API reviewers click on see more. </p>
            <p>To enable or disable all API reviewing use the toggle switch.</p>
            <div className="Switch-Admin">
              <label>
                {isApiReviewEnabled ? 'Review Enabled' : 'Review Disabled'}
              </label>
              <Switch
                edge="end"
                onChange={handleToggleApi}
                checked={isApiReviewEnabled}
                inputProps={{
                  'aria-labelledby': 'switch-list-label-api',
                }}
              />
            </div>

            <div className="button-see-more">
              <Button
                color="primary"
                variant="contained"
                onClick={clickApiSeeMore}
              >
                See More
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }

  if (isClickCodeSeeMore || isClickApiSeeMore) {
    const loading = adminList?.status === 'idle' ? true : false;

    if (adminList?.status === 'Error') {
      return (
        <div>
          <WarningPanel severity="error" title="Could not fetch Reviewer list">
            <CodeSnippet language="text" text="Error" />
          </WarningPanel>
        </div>
      );
    }

    if (adminList?.status === 'idle' || !data) {
      return <Progress />;
    }

    return (
      <Grid container direction="row" className="admin-reviewer-page">
        <Grid item md={12} xs={12}>
          <BackButton />
          <HelpComponent helpUrl={HELP_URL.MANAGE_REVIEWERS} />
        </Grid>
        {open && (
          <Dialog
            open={open}
            onClose={closeDialog}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            className={classes.DialogBox}
            fullWidth={false}
          >
            <DialogTitle id="dialog-title">
              {DELETE_CONFIRMATION_TITLE}
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={closeDialog}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent>
              <p>{DELETE_CONFIRMATION_MESSAGE}</p>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={closeDialog}
                color="primary"
                variant="contained"
                autoFocus
              >
                No
              </Button>
              <Button
                onClick={YesButtonClicked}
                color="primary"
                variant="contained"
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        )}

        <Grid item xs={12}>
          <div className={`admin-page codePage ${classes.manageReviewers}`}>
            <AddAdminReviewer
              tabId="ReviewerTab"
              setAdminAdded={setAdminAdded}
              isAdminAdded={isAdminAdded}
              kind={isClickApiSeeMore ? 'API' : 'Component'}
              clickGoBack={clickGoBack}
              callback={getReviewerList}
              userListData={
                isClickApiSeeMore ? apiReviewerList : CodeReviewerList
              }
              {...props}
            />
            <div
              className={`mystuff-table ${classes.reviewersTable} ${classes.themeColor}`}
            >
              <Table
                isLoading={loading}
                columns={columns}
                options={{
                  paging: true,
                  pageSize: 10,
                  search: true,
                  actionsColumnIndex: -1,
                  loadingType: 'linear',
                  showEmptyDataSourceMessage: !loading,
                  padding: 'dense',
                  pageSizeOptions: [5],
                }}
                data={isClickApiSeeMore ? apiReviewerList : CodeReviewerList}
                actions={defaultActions}
                title={`${
                  isClickApiSeeMore ? 'All API Reviewers' : 'All Code Reviewers'
                } (${
                  isClickApiSeeMore
                    ? apiReviewerList.length
                    : CodeReviewerList.length
                })`}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }

  return null;
};
