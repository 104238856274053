import React from 'react';
import {
  Grid,
  Button,
  makeStyles,
  createStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import BackButton from '../../utils/backButton';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    DialogBox: {
      minWidth: 300,
    },
    manageReviewers: {
      ['@media (max-width:920px) and (min-width: 280px)']: {
        padding: '0',
      },
    },
    reviewersTable: {
      ['@media (max-width:920px) and (min-width: 280px)']: {
        marginTop: '20px',
      },
    },
    themeColor: {
      '& tr': {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.background.default + '!important',
        '& td ': {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: theme.palette.background.default + '!important',
          '& button ': {
            color: theme.palette.primary.main,
          },
        },
        '& th': {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: theme.palette.background.default + '!important',
        },
      },
      '& div::-webkit-scrollbar-thumb': {
        border: `4px solid ${theme.palette.text.tertiary}`,
      },
    },
    theme: {
      backgroundColor: theme.palette.background.paper,
    },
    goBtn: {
      marginTop: '130px',
      textAlign: 'right',
    },
    margin: {
      margin: '0px 0px 5px 0px',
    },
  }),
);

export const ManageDataInsights = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const navigateToCategoriesPage = () => {
    navigate('manage-categories');
  };
  const naviageItemsPage = () => {
    navigate('manage-items');
  };

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item md={12} xs={12}>
        <BackButton />
        <HelpComponent helpUrl={HELP_URL.ADMIN_DATAINSIGHT_LANDING_PAGE} />
      </Grid>

      <Grid item xs={12} md={3}>
        <div className={`infocard-admin ${classes.theme}`}>
          <h2 className={classes.margin}>Manage Categories </h2>
          <Typography component="div">
            Add, edit and delete categories.
          </Typography>

          <div className={classes.goBtn}>
            <Button
              onClick={navigateToCategoriesPage}
              color="primary"
              variant="contained"
              endIcon={<NavigateNextOutlinedIcon />}
            >
              Go
            </Button>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={3}>
        <div className={`infocard-admin ${classes.theme}`}>
          <h2 className={classes.margin}>Manage Items</h2>
          <Typography component="div">Add, edit and delete items.</Typography>

          <div className={classes.goBtn}>
            <Button
              color="primary"
              variant="contained"
              endIcon={<NavigateNextOutlinedIcon />}
              onClick={naviageItemsPage}
            >
              Go
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
