import { useEffect, useState } from 'react';
import { useApi, identityApiRef } from '@backstage/core-plugin-api';

export const useUserProfile = () => {
  const [profile, setProfile] = useState<any>();
  const [displayName, setDisplayName] = useState<string | undefined>();

  const identityApi = useApi(identityApiRef);

  useEffect(() => {
    Promise.all([
      identityApi.getProfileInfo(),
      identityApi.getBackstageIdentity(),
    ]).then((value: any) => {
      const [profileInfo, userIdentity] = value;

      setProfile(profileInfo);
      let name = profileInfo?.displayName;
      if (name === undefined || name?.trim() === '') {
        name = userIdentity?.userEntityRef;
      }
      setDisplayName(name);
    });
  }, [identityApi]);

  return { profile, displayName };
};
