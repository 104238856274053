import React from 'react';

const Adhoc = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.125 10.625H10.625V15.625H5.625V18.125H10.625V23.125H13.125V18.125H18.125V15.625H13.125V10.625ZM18.75 8.225V10.5L21.875 9.875V23.125H24.375V6.875L18.75 8.225Z"
        fill="#17E88F"
      />
    </svg>
  );
};

export default Adhoc;
