import { useApi, identityApiRef } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  humanizeEntityRef,
} from '@backstage/plugin-catalog-react';
import { TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useCallback, useEffect } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { customTypes } from '../../extensions';

/**
 * The input props that can be specified under `ui:options` for the
 * `EntityPicker` field extension.
 *
 * @public
 */
export interface EntityPickerUiOptions {
  allowedKinds?: string[];
  defaultKind?: string;
  allowArbitraryValues?: boolean;
}

/**
 * The underlying component that is rendered in the form for the `EntityPicker`
 * field extension.
 *
 * @public
 */
export const EntityPicker = (
  props: customTypes.FieldExtensionComponentProps<
    string,
    EntityPickerUiOptions
  >,
) => {
  const {
    onChange,
    schema: { title = 'Entity', description = 'An entity from the catalog' },
    required,
    uiSchema,
    rawErrors,
    formData,
    idSchema,
  } = props;
  const allowedKinds = uiSchema['ui:options']?.allowedKinds;
  const defaultKind = uiSchema['ui:options']?.defaultKind;

  // const identityApi = useApi(identityApiRef);
  const catalogApi = useApi(catalogApiRef);

  const { value: entities, loading } = useAsync(async () => {
    // const tokenResponse = await identityApi.getCredentials();
    return catalogApi.getEntities(
      allowedKinds ? { filter: { kind: allowedKinds } } : undefined,
      // {
      //   token: tokenResponse.token,
      // },
    );
  });

  const entityRefs = entities?.items.map(e =>
    humanizeEntityRef(e, { defaultKind }),
  );

  const onSelect = useCallback(
    (_: any, value: string | null) => {
      onChange(value || '');
    },
    [onChange],
  );

  useEffect(() => {
    if (entityRefs?.length === 1) {
      onChange(entityRefs[0]);
    }
  }, [entityRefs, onChange]);

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <Autocomplete
        disabled={entityRefs?.length === 1}
        id={idSchema?.$id}
        value={(formData as string) || ''}
        loading={loading}
        onChange={onSelect}
        options={entityRefs || []}
        autoSelect
        freeSolo={uiSchema['ui:options']?.allowArbitraryValues ?? true}
        renderInput={params => (
          <TextField
            {...params}
            label={title}
            margin="normal"
            helperText={description}
            variant="outlined"
            required={required}
            InputProps={params.InputProps}
          />
        )}
      />
    </FormControl>
  );
};
