import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ClearIcon = ({ fill }: { fill?: string }) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M5 13H19V11H5V13ZM3 17H17V15H3V17ZM7 7V9H21V7H7Z" fill={fill} />
  </SvgIcon>
);

export default ClearIcon;
