import React, { useEffect, useState, useContext } from 'react';
import * as yup from 'yup';
import ReplyIcon from '@material-ui/icons/Reply';
import {
  Button,
  TextField,
  Grid,
  LinearProgress,
  useTheme,
  Theme,
} from '@material-ui/core';
import { HelpComponent } from '../../../utils/helpComponent';
import { useCustomStyles } from './CardStyles';
import { AuthContext } from '../../../../providers/AuthProvider';
import {
  GamificationApi,
  gamificationApiRef,
} from '../../../../apis/gamificationApis';
import HELP_URL from '../../../utils/helpLinkConstant';
import { PromotionContext } from '../../../../providers/PromotionDataProvider';
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';

export const limitChars = (val: string, limit: number) => {
  if (val) {
    if (val.length > limit) {
      return val.substring(0, limit);
    }
  }
  return val;
};

export interface ModifyAdhocPointsProps {
  data: { email?: string; score?: any };
  onBackClicked: () => void;
}

const ModifyAdhocPoints = (props: ModifyAdhocPointsProps) => {
  const theme = useTheme<Theme>();
  const { email, score } = props.data;
  const { onBackClicked } = props;

  const onclickHelpButton = useContext(PromotionContext);

  const classes = useCustomStyles();
  const { profEmail } = useContext(AuthContext);
  const gamificationApi: GamificationApi = useApi(gamificationApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const userEmail = profEmail?.toLowerCase();

  const [_updatedPoints, setUpdatedPoints] = useState<any>('0');
  const [_description, setDescription] = useState('');

  const [_pointsValidationErr, setPointsValidationError] = useState('');
  const [_descriptionValidationErr, setDescriptionValidationError] =
    useState('');
  const [_showDialog, setShowDialog] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    onclickHelpButton.setToggleHelpButton(false);
  }, []);

  const handleRedirect = () => {
    onBackClicked();
  };

  const correlationid = () => {
    const rendomNumber = Math.floor(Math.random() * Date.now());
    const uuid = `ADHOC-${rendomNumber}`;
    return uuid;
  };

  const handleSubmit = () => {
    setInProgress(true);
    gamificationApi
      .updateAdhocPoints({
        actoremail: userEmail,
        rewardseventid: 'RC7',
        correlationid: correlationid(),
        targetemail: email,
        comments: _description,
        adhocpoints: parseInt(_updatedPoints, 10),
      })
      .then(() => {
        setInProgress(false);
        setShowDialog(true);
        notificationApi.sendNotification({
          message: `Points updated successfully, updated version will be available shortly`,
          disapperAfterMs: 2500,
          severity: 'success',
          callback: handleRedirect,
        });
      })
      .catch((err: any) => {
        setInProgress(false);
        setShowDialog(true);
        notificationApi.sendNotification({
          message: err.message,
          disapperAfterMs: 2500,
          severity: 'error',
          callback: handleRedirect,
        });
      });
  };

  const validateInputs = async () => {
    const valResult = yup
      .number()
      .positive()
      .integer('This must be an integer ')
      .nullable(true)
      .required('Updated points is required')
      .min(1, 'At least 1 characters required')
      .positive('This field must contain a positive number')
      .integer('This field should contain an integer')
      .validate(_updatedPoints)
      .then(() => {
        setPointsValidationError('');
        return true;
      })
      .catch(err => {
        setPointsValidationError(err.message);
        return false;
      });

    const descResult = yup
      .string()
      .required('Reason is required')
      .min(3, 'At least 3 characters required')
      .max(500, 'Maximum 500 characters')
      .validate(_description)
      .then(() => {
        setDescriptionValidationError('');
        return true;
      })
      .catch(err => {
        setDescriptionValidationError(err.message);
        return false;
      });
    const allPromises = await Promise.all([valResult, descResult]);
    return allPromises.reduce((prev, current) => {
      return prev && current;
    });
  };

  const onSubmit = async () => {
    if (await validateInputs()) {
      handleSubmit();
    }
  };

  return (
    <Grid container>
      <Grid item md={12}>
        <Button onClick={handleRedirect} color="primary">
          <ReplyIcon /> Back
        </Button>
        <HelpComponent helpUrl={HELP_URL.updateAdhocPoints} />
      </Grid>

      {inProgress && <LinearProgress />}

      <Grid className={classes.modifyPointsContainer} container>
        <h2>Modify Points</h2>
        <Grid xs={12} item>
          <TextField
            disabled={true}
            style={{ width: '100%' }}
            placeholder="Type here..."
            InputLabelProps={{
              shrink: true,
            }}
            id="existing-points"
            data-testid="existing-points-field"
            label="Existing Points"
            value={score || 0}
            variant="outlined"
          />
        </Grid>
        <Grid xs={12} item>
          <TextField
            disabled={false}
            style={{ width: '100%' }}
            placeholder="Type here..."
            InputLabelProps={{
              shrink: true,
            }}
            id="updated-points"
            data-testid="updated-points-field"
            label="Points to be Added"
            helperText={
              _pointsValidationErr
                ? _pointsValidationErr
                : `Updated points will be  ${
                    _updatedPoints
                      ? parseInt(score) + parseInt(_updatedPoints)
                      : 'show here'
                  }`
            }
            onChange={event => {
              const value = limitChars(event?.target?.value, 5);
              setUpdatedPoints(value);
              setPointsValidationError('');
            }}
            value={_updatedPoints}
            variant="outlined"
            FormHelperTextProps={
              _pointsValidationErr
                ? { style: { color: 'red' } }
                : { style: { color: theme.palette.text.tertiary } }
            }
          />
        </Grid>
        <Grid xs={12} item>
          <TextField
            style={{ width: '100% ' }}
            disabled={false}
            placeholder="Type here..."
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              minRows: 4,
            }}
            id="reason-text"
            data-testid="reason-text-field"
            label="Reason"
            multiline
            onChange={event => {
              const value = limitChars(event?.target?.value, 500);
              setDescription(value);
              setDescriptionValidationError('');
            }}
            helperText={_descriptionValidationErr || ''}
            FormHelperTextProps={{ style: { color: 'red' } }}
            value={_description || ''}
            variant="outlined"
          />
        </Grid>
        <Grid xs={12} item className={classes.saveCancelButtonGroup}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleRedirect}
            className={classes.cancelButton}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            data-testid="submit-btn"
            color="primary"
            onClick={onSubmit}
            disabled={_showDialog}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ModifyAdhocPoints;
