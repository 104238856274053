import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      display: 'flex',
      justifyContent: 'space-between',
      verticalAlign: 'middle',
      alignItems: 'center',
    },
    flexSearch: {
      display: 'flex',
      justifyContent: 'center',
      padding: '12px 24px',
    },
    adminContainer: {
      padding: '24px 24px 12px',
      width: '100%',
    },
    adminfilters: {
      padding: '12px 24px 24px',
      width: '100%',
    },
    filterBackGround: {
      backgroundColor: theme.palette.background.paper,
    },
    addButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.contrast,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.contrast,
      },
    },
    adminCards: {
      margin: `.5px ${theme.spacing(3)}px`,
      marginBottom: theme.spacing(1),
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
    iconButton: {
      color: theme.palette.primary.main,
    },
    label: {
      color: theme.palette.text.tertiary,
    },
    labelType: {
      color: theme.palette.text.secondary,
      fontWeight: 600,
    },
    PagePaginationContainer: {
      margin: `.5px ${theme.spacing(3)}px`,
    },
    tablePaginationRoot: {
      overflow: 'hidden',
    },
    tablePaginationActions: {
      '& button': {
        [theme.breakpoints.down('sm')]: {
          padding: '0',
        },
      },
    },
    filterTitle: {
      margin: '0px 16px 8px',
      fontSize: '1rem',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.25rem',
      },
    },
    calendarColor: {
      backgroundColor: theme.palette.background.paper,
      '& input': {
        colorScheme: theme.palette.type,
        paddingRight: '39px',
      },
    },
  }),
);
