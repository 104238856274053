/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Theme,
  FormHelperText,
  LinearProgress,
  Popper,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from '@backstage/core-plugin-api';

import { Content } from '@backstage/core-components';
import { HelpComponent } from '../utils/helpComponent';
import HELP_URL from '../utils/helpLinkConstant';
import BackButton from '../utils/backButton';
import { Autocomplete } from '@material-ui/lab';
import RichEditor from '../common/RichEditor';
import {
  IAskQuestionItemRequest,
  IEditQuestionItemRequest,
  InformationExchangeApi,
  informationExchangeApiRef,
} from '../../apis/informationExchange';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';
import { additionalConfigApiRef } from '../../apis/additionalConfigApi';
import { POST_MAX_SIZE } from '../utils/constant';
import { byteSize } from '../utils/Utils';
import { AuthContext } from '../../providers/AuthProvider';
import Tag from '../information-exchange/tags-page/Tag';
import { IEContext } from './providers/IEProvider';
import { FrontendConfigContext } from '../../providers/FrontendConfigProvider';

interface Tag {
  id?: string;
  metadata?: string;
  tag: string;
  owner?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    askQuestionCard: {
      backgroundColor: theme.palette.background.paper,
      boxShadow:
        '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
      padding: '15px',
    },
    divider: {
      width: '100%',
      height: '1px',
      background: '#cccccc',
      margin: '15px 9px 0px 9px',
    },
    postQueBtn: {
      margin: '10px 0px 10px 6px',
      padding: '12px 16px',
      textTransform: 'initial',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: '0',
        marginBottom: '12px',
      },
    },
    askquestionsCodePage: {
      marginBottom: '74px',
      [theme.breakpoints.down('sm')]: {
        padding: '0',
        marginTop: '15px',
      },
    },
    descError: {
      marginLeft: '14px',
      marginRight: '14px',
      color: theme.palette.errorText,
      fontSize: ' 0.75rem',
      marginTop: '14px',
    },
    postBtn: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    heading: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '32px',
      color: theme.palette.text.secondary,
      marginTop: '10px',
    },
    para: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      color: theme.palette.text.tertiary,
      marginTop: '10px',
      cursor: 'pointer',
    },
    tagsField: {
      marginTop: '15px',
    },
    answers: {
      padding: '20px',
      border: '1px solid gray',
    },
    popper: {
      width: '100%',
    },
  }),
);

const AskQuestionsPage = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string | undefined>('');
  const [titleError, setTitleError] = useState<string | undefined>('');
  const [tagsError, setTagsError] = useState<string | undefined>('');
  const [defaultTags, setDefaultTags] = useState<Tag[] | undefined>(undefined);
  const [tags, setTags] = useState<any[]>([]);
  const [descriptionError, setDescriptionError] = useState<string | undefined>(
    '',
  );
  const [showBot, setShowBot] = useState(false);
  const [description, setDescription] = useState<string | undefined>(undefined);
  const [allowAiAnswer, setAllowAiAnswer] = useState(false);

  /* use FrontendConfig Context */
  const { frontendConfigData } = useContext(FrontendConfigContext);

  /* IE Context */
  const { setShowAiAnswer, setCachedQuestion } = useContext(IEContext);

  useEffect(() => {
    setCachedQuestion(null);
  }, []);

  useEffect(() => {
    if (frontendConfigData?.openai?.show == 'true') {
      setShowBot(true);
      setAllowAiAnswer(true);
    }
  }, [frontendConfigData?.openai?.show]);

  const informationExchange: InformationExchangeApi = useApi(
    informationExchangeApiRef,
  );
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const PopperComp = function (props) {
    return (
      <Popper {...props} className={classes.popper} placement="bottom-start" />
    );
  };

  const { questionId } = useParams() || '';
  const { profEmail } = useContext(AuthContext);

  const navigate = useNavigate();
  /* tags */
  const onChangeTags = (e: any, tagValues: any) => {
    // const regex = new RegExp(/^[a-zA-Z]+$/);
    // const tagValuesFiltered = tagValues.filter((tagValue: string) =>
    //   regex.test(tagValue),
    // );
    if (tagValues.length <= 5) {
      setTagsError(undefined);
      setTags(tagValues);
    } else {
      setTagsError('Maximum 5 tags');
    }
  };

  useEffect(() => {
    informationExchange
      .getDefaultTags({
        page: 1,
        pagesize: 1000,
      })
      .then((tags: any) => {
        if (!!tags.tags && Array.isArray(tags.tags) && tags?.tags?.length > 0) {
          setDefaultTags(tags.tags);
        } else {
          setDefaultTags([]);
        }
        return;
      });
  }, []);

  const onTitleTextChange = (event: any) => {
    setTitle(event?.target?.value);
    setTitleError(undefined);
  };

  const handleEditorChange = (value: any) => {
    setDescription(value);
    if (byteSize(value) > POST_MAX_SIZE) {
      setDescriptionError('File size should not exceeed 5MB');
    } else {
      setDescriptionError('');
    }
  };

  const validateForm = () => {
    const errors: string[] = [];
    if (
      title == undefined ||
      (title !== undefined && String(title).trim()?.length === 0)
    ) {
      setTitleError('Title is required');
      errors.push('Title is required');
    }
    if (
      description == undefined ||
      description == '<p><br></p>' ||
      (description !== undefined && String(description).trim()?.length === 0)
    ) {
      setDescriptionError('Description is required');
      errors.push('Description is required');
    }
    if (description != undefined && byteSize(description) > POST_MAX_SIZE) {
      setDescriptionError('Description must not exceed 5MB');
      errors.push('Description must not exceed 5MB');
    }

    if (tags?.length === 0 || (tags !== undefined && tags?.length === 0)) {
      setTagsError('Tags is required');
      errors.push('Tags is required');
    }
    return errors;
  };

  const createQuestion = (question: any) => {
    setCachedQuestion({
      ...question,
      createdat: new Date().toISOString(),
      updatedat: new Date().toISOString(),
      votes: 0,
      discussions: 0,
      views: 0,
    });
  };

  const postQuestion = (formValues: IAskQuestionItemRequest) => {
    informationExchange
      .saveAskAQuestion(formValues)
      .then(response => {
        notificationApi.sendNotification({
          message: `Question is Added successfully`,
          disapperAfterMs: 2500,
          severity: 'success',
        });

        const handle = setTimeout(() => {
          clearTimeout(handle);
          if (allowAiAnswer) {
            createQuestion(response?.data);
            navigate(`/information-exchange/question/${response?.data?.id}`);
          } else {
            navigate('/information-exchange');
          }
        }, 10);
      })

      .catch(err => {
        notificationApi.sendNotification({
          message: err?.message,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };
  const editQuestion = (formValues: IEditQuestionItemRequest) => {
    informationExchange
      .editQuestion(formValues)
      .then(() => {
        notificationApi.sendNotification({
          message: `Question is Edited successfully`,
          disapperAfterMs: 2500,
          severity: 'success',
        });

        const handle = setTimeout(() => {
          clearTimeout(handle);
          navigate('/information-exchange/question/' + questionId);
        }, 10);
      })

      .catch(err => {
        notificationApi.sendNotification({
          message: err?.message,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  const onClickPostYourQuestion = (mode: String) => {
    const formValues = {
      tags: tags ? tags.map(tag => (tag.tag ? tag.tag : tag)) : [],
      title: title || '',
      description: description || '',
    };
    const editFormValues = { ...formValues, id: questionId };

    const errors = validateForm();
    if (errors !== undefined && Array.isArray(errors) && errors?.length === 0) {
      mode === 'add' ? postQuestion(formValues) : editQuestion(editFormValues);
    }
  };

  const getQuestionDetails = (qid: String, filterBy: String) => {
    setIsLoading(true);
    informationExchange
      .getQuestionDetails(qid, filterBy)
      .then((data: any) => {
        const question = data?.question;
        setIsLoading(false);
        if (question?.author?.toLowerCase() === profEmail?.toLowerCase()) {
          setTitle(question?.title);
          setDescription(question?.description);
          setTags(question?.tags);
        } else {
          navigate('/information-exchange');
        }
      })
      .catch((error: any) => {
        notificationApi.sendNotification({
          message: `Error occurred - ${error?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  useEffect(() => {
    questionId && getQuestionDetails(questionId, 'newest');
  }, [questionId]);

  const handleCancel = () => {
    navigate('/information-exchange');
  };

  useEffect(() => {
    setShowAiAnswer(allowAiAnswer);
  }, [allowAiAnswer]);

  return (
    <Content>
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <BackButton />

          <HelpComponent helpUrl={HELP_URL.INFO_EXCHANGE_ASK_A_QUESTION_PAGE} />
        </Grid>
      </Grid>
      <div className={`codePage ${classes.askquestionsCodePage}`}>
        {isLoading && <LinearProgress />}
        <Grid container className={classes.askQuestionCard}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className={classes.heading}>Question Title</div>
            <div className={classes.para}>
              Be specific and imagine you are asking another D&T member at CBRE.
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              fullWidth
              multiline
              size="small"
              id="outlined-basic"
              label="Enter Title here"
              variant="outlined"
              value={title ?? ''}
              onChange={e => onTitleTextChange(e)}
              error={Boolean(titleError)}
              helperText={Boolean(titleError) && titleError}
            />
          </Grid>
          {/* <div className={classes.divider}></div> */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className={classes.heading}>Details</div>
            <div className={classes.para}>
              Include all the relevant details that someone might need to answer
              the question either by another human or an AI.
            </div>
            <RichEditor
              className="error"
              onChange={handleEditorChange}
              onError={setDescriptionError}
              error={Boolean(descriptionError)}
              helperText={Boolean(descriptionError) && descriptionError}
              data={description}
            />
            {descriptionError && (
              <FormHelperText error className={classes.descError}>
                {descriptionError}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className={classes.heading}>Tags</div>
            <div className={classes.para}>
              Add up to 5 tags to describe what your question is about.
            </div>
            {tags !== undefined && (
              <Autocomplete
                className={classes.tagsField}
                freeSolo
                multiple
                size={'small'}
                onChange={onChangeTags}
                id="tags-outlined"
                options={defaultTags?.map((t) => t.tag)}
                getOptionLabel={(option: any) =>
                  option.tag ? option.tag : option
                }
                value={tags}
                PopperComponent={PopperComp}
                renderInput={params => (
                  <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, maxLength: 18 }}
                    variant="outlined"
                    error={
                      tagsError !== undefined &&
                      String(tagsError)?.trim().length > 0
                    }
                    label="Type To select tags"
                  />
                )}
                disableCloseOnSelect
                renderOption={(
                  tag: string,
                  b,
                ) => {
                  return (
                    <React.Fragment key={'tag-options-' + tag}>
                      <Tag
                        {...defaultTags?.find(t => t.tag === tag)}
                        styles={{
                          height: '100px',
                        }}
                      />
                    </React.Fragment>
                  )
                }}
              />
            )}
            {tagsError && (
              <FormHelperText error className={classes.descError}>
                {tagsError}
              </FormHelperText>
            )}
          </Grid>
          <Grid className={classes.postBtn}>
            {questionId ? (
              <>
                <Button
                  className={classes.postQueBtn}
                  variant="contained"
                  color="primary"
                  onClick={() => onClickPostYourQuestion('edit')}
                >
                  Save Your Question
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.postQueBtn}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button
                  className={classes.postQueBtn}
                  variant="contained"
                  color="primary"
                  onClick={() => onClickPostYourQuestion('add')}
                >
                  Post Your Question
                </Button>
                {
                  showBot && <FormControlLabel
                  control={
                    <Switch
                      checked={allowAiAnswer}
                      onChange={e => setAllowAiAnswer(e.target.checked)}
                    />
                  }
                    label="Allow AI Answer"
                  labelPlacement="start"
                />
                }
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </Content>
  );
};
export default AskQuestionsPage;
