import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import SlideUp from './SlideUp';
import {
  DiscoveryApi,
  discoveryApiRef,
  useApi,
} from '@backstage/core-plugin-api';

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '-0.01em',
    color: theme.palette.text.secondary,
  },
  dialogTitle: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: ' -0.015em',
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    maxWidth: '50%',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    width: theme.breakpoints.values.md,
  },
}));

const VideoRenderModal = (props: any) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles();
  const { open, setDeleteModal, data } = props;

  const handleClose = () => {
    setDeleteModal(false);
  };

  const discoveryApi: DiscoveryApi = useApi(discoveryApiRef);

  const [videoLink, setVideoLink] = useState('');
  const makeVideoLink = async () => {
    const API_BASE_URL = await discoveryApi.getBaseUrl('');
    setVideoLink(`${API_BASE_URL}proxy/video-bytes/${data.videoLink}`);
  };

  useEffect(() => {
    makeVideoLink();
  }, [data.videoLink]);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        classes={{ paper: classes.paper }}
        open={open}
        onClose={handleClose}
        aria-labelledby="video-byte"
        aria-describedby="video-byte"
        TransitionComponent={SlideUp}
        fullWidth={false}
        maxWidth="md"
      >
        <DialogTitle id="dialog-title">
          <div className={classes.dialogTitle}>{data.title}</div>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <video width="100%" height="auto" controls preload="auto">
            <track kind="captions" />
            <source
              src={videoLink}
              // src="https://cbre.sharepoint.com/teams/DevX/Shared%20Documents/General/Video%20Bytes/Adding%20Code%20to%20Dev%20X.mp4"
              type="video/mp4"
            />
          </video>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default VideoRenderModal;
