import { createApiRef } from '@backstage/core-plugin-api';
import { AxiosInstance, AxiosResponse } from 'axios';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';

export interface UserApi {
  getUserInfoByEmail: (email: string) => Promise<AxiosResponse>;
  refreshToken: () => Promise<AxiosResponse>;
  getImage: (email: string) => Promise<AxiosResponse>;
  getDetails: (email: string) => Promise<AxiosResponse>;
}

export const userApiRef = createApiRef<UserApi>({
  id: 'cbre.devx.api.user',
});

export class UserApiImpl implements UserApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  async getUserInfoByEmail(email: string): Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;
    return instance.get(`service/discovery/userinfo`, {
      params: {
        user: email,
      },
    });
  }

  async refreshToken(): Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get('auth/microsoft/refresh', {
        params: {
          env: 'development',
          optional: true,
        },
        headers: {
          'x-requested-with': 'XMLHttpRequest',
        },
      })
      .then(async res => {
        this.setIdTokenCookie(res.data);
        return res;
      });
  }

  async getImage(email: string): Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;

    return instance
      .get('ms-graph/images', {
        params: {
          email,
        },
      })
      .then(res => res.data)
      .catch(err => {});
  }
  async getDetails(email: string): Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;

    return instance
      .get('ms-graph/user-details', {
        params: {
          email,
        },
      })
      .then(res => res.data)
      .catch(err => {});
  }
  async setIdTokenCookie(data: any) {
    const instance = await this.axiosInstancePromise;

    return instance.get('cookie', {
      headers: {
        Authorization: `Bearer ${data?.backstageIdentity?.token}`,
      },
    });
  }
}
