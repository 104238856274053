import React from 'react';

import {
  Table,
  TableColumn,
  TableProps,
  Link,
} from '@backstage/core-components';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { IToolboxItemNode, IToolboxItem } from '../../../../apis/toolboxApi';
import { useNavigate } from 'react-router-dom';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    themeColor: {
      '& tr': {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.background.default + '!important',
        '& td ': {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: theme.palette.background.default + '!important',
          '& button ': {
            color: theme.palette.primary.main,
          },
        },
        '& th': {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: theme.palette.background.default + '!important',
        },
      },
      '& div::-webkit-scrollbar-thumb': {
        border: `4px solid ${theme.palette.text.tertiary}`,
      },
    },
  }),
);
export interface IToolsTableProps {
  loading: boolean;
  toolsData: IToolboxItemNode[];
  onClickDelete: (params: any) => void;
}

const columns: TableColumn[] = [
  {
    title: 'Title',
    field: 'title',
    render: (params: any) => {
      return <Link to={`/toolbox?q=${params?.title}`}>{params?.title}</Link>;
    },
  },
  {
    title: 'Description',
    field: 'description',
  },
  {
    title: 'Category',
    field: 'category',
  },
  {
    title: 'NID',
    field: 'nid',
  },
  {
    title: 'PNID',
    field: 'pnid',
  },
];

const ToolsTable = ({
  loading,
  toolsData,
  onClickDelete,
}: IToolsTableProps) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const defaultActions: TableProps<IToolboxItem>['actions'] = [];
  // @ts-ignore
  defaultActions.push(params => {
    if (params.nid === 1) {
      return undefined;
    }

    const navigateToEditPage = () => {
      navigate(`/admin/toolbox/edit/${params.nid}`);
    };

    return {
      icon: () => <EditIcon />,
      tooltip: 'Edit Tool Details',
      onClick: navigateToEditPage,
    };
  });
  // @ts-ignore
  defaultActions.push(params => {
    if (params.nid === 1) {
      return undefined;
    }
    // @ts-ignore
    const childRows = params?.tableData?.childRows;
    if (
      childRows !== undefined &&
      Array.isArray(childRows) &&
      childRows.length > 0
    ) {
      return undefined;
    }

    const handleDelete = () => {
      if (onClickDelete) {
        onClickDelete(params);
      }
    };

    return {
      icon: () => <DeleteIcon />,
      tooltip: 'Delete Tool',
      onClick: handleDelete,
    };
  });
  const rows: any[] = [];
  let endNodeCount = 0;

  const getNode = (item: IToolboxItemNode) => {
    if (item.children && item.children?.length > 0) {
      for (const childItem of item.children) {
        const nodeResult = getNode(childItem);
        rows.push(nodeResult);
      }
    } else {
      if (
        item?.node?.isactive &&
        item.node?.link !== undefined &&
        String(item.node?.link).trim().length !== 0
        // (item.node?.links !== undefined &&
        //   Array.isArray(item.node?.links) &&
        //   item.node?.links?.length > 0))
      ) {
        endNodeCount++;
      }
    }
    if (item.node) {
      return item.node;
    }
    return undefined;
  };

  if (toolsData && toolsData?.length > 0) {
    for (const tool of toolsData) {
      const node = getNode(tool);
      if (node !== undefined) {
        rows.push(node);
      }
    }
  }

  const getParentChildData = (row: IToolboxItem, rows: IToolboxItem[]) => {
    let parentRow = undefined;
    for (const item of rows) {
      if (item.nid === row.pnid) {
        parentRow = item;
        break;
      }
    }
    return parentRow;
  };

  return (
    <div className={`mystuff-table  ${classes.themeColor}`}>
      <Table
        isLoading={loading}
        // @ts-ignore
        columns={columns}
        options={{
          paging: true,
          pageSize: 10,
          search: true,
          actionsColumnIndex: -1,
          loadingType: 'linear',
          showEmptyDataSourceMessage: !loading,
          padding: 'dense',
          pageSizeOptions: [20],
          sorting: true,
        }}
        parentChildData={getParentChildData}
        data={rows || []}
        actions={defaultActions}
        title={`${'Available Tools'} (${endNodeCount})`}
      />
    </div>
  );
};
export default ToolsTable;
