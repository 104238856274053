import React, { useEffect, useState, useContext } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import ExternalDocsEmptyState from './ExternalDocsEmptyState';
import ConfirmDelete from './ConfirmDelete';
import ExtDocsContentHeader from './ExtDocsContentHeader';
import ExtDocCardGrid from './Grid/ExtDocCardGrid';
import { Progress } from '@backstage/core-components';
import { HelpComponent } from '../../utils/helpComponent';
import { Grid, LinearProgress } from '@material-ui/core';
import HELP_URL from '../../utils/helpLinkConstant';
import BackButton from '../../utils/backButton';
import { AuthContext } from '../../../providers/AuthProvider';
import { useCustomStyles } from './DocStyles';
import { externalDocsApiRef } from '../../../apis/externalDocsApi';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';

const ExternalDocsContainer = () => {
  const classes = useCustomStyles();
  const [docsData, setDocsData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [_filterKey, setFilterKey] = useState('title');
  const [_filterText, setFilterText] = useState('');
  const [anchorElement, setAnchorElement] = useState(null);
  const showCardItemMenu = Boolean(anchorElement);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState('info');
  const [message, setMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [deleteConfirmMessage, setDeleteConfirmMessage] = useState('');
  const [deleteConfirmDocName, setDeleteConfirmDocName] = useState('');
  const externalDocsApi = useApi(externalDocsApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const { isAdmin } = useContext(AuthContext);

  const refresh = () => {
    setLoadingData(true);

    externalDocsApi
      .getExternalDocumentation()
      .then((entities: any) => {
        setDocsData(entities || []);
        setLoadingData(false);
      })
      .catch((error: any) => {
        setLoadingData(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  useEffect(() => {
    if (externalDocsApi && refresh) {
      refresh();
    }
  }, []);

  const onFilterKeyChange = (event: any): void => {
    setFilterKey(`${event?.target?.value}`);
  };

  const deleteExternalDocs = async (docName: string) => {
    setShowDeleteConfirmDialog(false);
    setDeleteConfirmDocName('');
    setDeleteConfirmMessage('');
    setAnchorElement(null);
    setInProgress(true);
    setOpen(true);
    await externalDocsApi
      .deleteDocument(docName, 'document')
      .then((data: any) => {
        setInProgress(false);
        setStatus('success');
        // setMessage(`Document '${data?.name}' successfully deleted`);
        refresh();
        // setOpen(true);
        notificationApi.sendNotification({
          message: `Document '${data?.name}' successfully deleted`,
          disapperAfterMs: 2500,
          severity: 'success',
        });
      })
      .catch((err: any) => {
        setInProgress(false);
        setStatus('error');
        // setMessage(err.message);
        // setOpen(true);
        notificationApi.sendNotification({
          message: `Error occurred - ${err?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
    refresh();
  };

  const confirmDeleteProps = {
    open: showDeleteConfirmDialog,
    onConfirm: (docName: string) => deleteExternalDocs(docName),
    onReject: () => {
      setAnchorElement(null);
      setShowDeleteConfirmDialog(false);
      setDeleteConfirmDocName('');
      setDeleteConfirmMessage('');
      setOpen(false);
    },
    content: deleteConfirmMessage,
    docName: deleteConfirmDocName,
  };

  const promptUserForConfirmation = (docName: string) => {
    setDeleteConfirmMessage(
      `Document '${docName}' will be permananetly deleted, and cannot be recovered`,
    );
    setDeleteConfirmDocName(docName);
    setShowDeleteConfirmDialog(true);
  };

  // const handleDialogClose = () => {
  //   setOpen(false);
  //   refresh();
  // };

  // const AlertProps = {
  //   open,
  //   status,
  //   setOpen,
  //   inProgress,
  //   message,
  //   handleClose: handleDialogClose,
  // };

  const onDocumentEditBtnClicked = (docName: string) => {
    window.location.replace(`/docs/external/edit/${docName}`);
  };

  if (loadingData) {
    return (
      <div>
        <Progress />
      </div>
    );
  }

  return (
    <div>
      {docsData?.length > 0 ? (
        <>
          <Grid item md={12} xs={12}>
            <BackButton />
            <HelpComponent helpUrl={HELP_URL.ExternalDocumentation} />
          </Grid>
          {inProgress && <LinearProgress />}
          <ExtDocsContentHeader
            onFilterKeyChange={onFilterKeyChange}
            setFilterText={setFilterText}
            filterKey={_filterKey}
          />
          <ExtDocCardGrid
            filterKey={_filterKey}
            filterText={_filterText}
            docsData={docsData}
            anchorElement={anchorElement}
            setAnchorElement={setAnchorElement}
            showCardItemMenu={showCardItemMenu}
            promptUserForConfirmation={promptUserForConfirmation}
            isAdmin={isAdmin}
            onDocumentEditBtnClicked={onDocumentEditBtnClicked}
          />
        </>
      ) : (
        <>
          <div className={classes.backBtn}>
            <BackButton />
          </div>
          <ExternalDocsEmptyState />
        </>
      )}
      {showDeleteConfirmDialog && (
        <ConfirmDelete {...confirmDeleteProps}></ConfirmDelete>
      )}
    </div>
  );
};
export default ExternalDocsContainer;
