import React, { useState } from 'react';

import { InfoCard } from '@backstage/core-components';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { AddShowTags } from './AddShowTags';
import { AboutField } from './ApiEditPage/AboutField';
import { Links } from './Links';
import './EditPageStyle.css';
import { handleSupportEmail } from '../utils/HandleFunctionsInApiEditpage';

const useStyles = makeStyles(theme => ({
  codeDesc: {
    ['@media (max-width:920px) and (min-width: 280px)']: {
      marginTop: '10px',
    },
  },
  descriptionText: {
    padding: '5px 12px',
    margin: '8px 0',
    boxSizing: 'border-box',
    border: '1px solid #f1f1f1',
    color: theme.palette.text.secondary,
    fontSize: '14px',
    fontFamily: 'Calibre-R-Regular',
    width: '100% !important',
    backgroundColor: theme.palette.background.paper,
  },
  validatorSegment: {
    fontSize: '11px',
    color: '#f00',
  },
  editInputText: {
    padding: '5px 12px',
    margin: ' 8px 0',
    boxSizing: 'border-box',
    border: '1px solid #f1f1f1',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    fontSize: '11px',
    width: '100%',
  },
}));
export type AboutCardProps = {
  className?: string;
};

export const AboutCard = (props: {
  className: string | undefined;

  defaultTags: string[];
  defaultTechdocs: string;
  setTechdocs: React.Dispatch<React.SetStateAction<string>>;
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
  setLinks: React.Dispatch<
    React.SetStateAction<
      {
        url: string;
        title: string;
        icon: string;
      }[]
    >
  >;
  defaultLinks: {
    url: string;
    title: string;
    icon: string;
  }[];
  defaultEntityName: string | undefined;
  defaultEntityDescription: string | undefined;
  setIsButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  checkedTechdocs: boolean;
  setCheckedTechdocs: React.Dispatch<React.SetStateAction<boolean>>;
  defaultEntity: any;
  setDefaultEntityDescription: any;
  supportEmail: string;
  setSupportEmail: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const {
    className,
    setLinks,
    defaultLinks,
    defaultTags,
    setTags,
    defaultEntity,
    defaultEntityName,
    defaultEntityDescription,
    setIsButtonDisabled,
    setDefaultEntityDescription,
    supportEmail,
    setSupportEmail
  } = props;

  const handleDiscriptionChange = (e: any) => {
    e.preventDefault();
    setIsButtonDisabled(false);
    const value = e.target.value;
    setDefaultEntityDescription(value);
  };

  const classes = useStyles();

  const [validatorSupportEmail, setValidatorSupportEmail] = useState(false);

  return (
    <InfoCard className={className} title="About">
      <Grid container>
        <AboutField
          label="NAME"
          gridSizes={{ xs: 12, sm: 6, lg: 6 }}
          value={defaultEntityName}
        />{' '}
        <AboutField
          label="DESCRIPTION"
          gridSizes={{ xs: 12, sm: 6, lg: 6 }}
          value=""
        >
          <TextareaAutosize
            id="DESCRIPTION"
            minRows={3}
            maxRows={5}
            onChange={handleDiscriptionChange}
            value={defaultEntityDescription}
            className={classes.descriptionText}
          />
        </AboutField>
        <AboutField
          label="TAGS"
          gridSizes={{ xs: 12, sm: 6, lg: 6 }}
          value={defaultEntityDescription}
        >
          <AddShowTags
            defaultTags={defaultTags}
            setTags={setTags}
            numberOfTags={9}
            numberOfCharacters={15}
            setIsButtonDisabled={setIsButtonDisabled}
            editable={true}
          />
        </AboutField>
        <AboutField label="SUPPORT EMAIL" gridSizes={{ xs: 12, sm: 6, lg: 6 }}>
          <input
            type="text"
            data-testid="test-support-email"
            className={classes.editInputText}
            onKeyUp={event =>
              handleSupportEmail(
                event,
                setSupportEmail,
                setValidatorSupportEmail,
                setIsButtonDisabled,
              )
            }
            defaultValue={
              supportEmail !== ''
                ? supportEmail
                : defaultEntity?.spec?.owneremail
            }
          />
          {validatorSupportEmail && (
            <Typography className={classes.validatorSegment}>
              *email address not valid
            </Typography>
          )}
        </AboutField>
        <AboutField label="LINKS:" gridSizes={{ xs: 12, sm: 12, lg: 12 }} />
      </Grid>

      <Grid container direction="row">
        <Links
          setIsButtonDisabled={setIsButtonDisabled}
          setLinks={setLinks}
          defaultLinks={defaultLinks}
        />
      </Grid>
    </InfoCard>
  );
};
