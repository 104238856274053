import { EntityPicker } from '../fields/EntityPicker/EntityPicker';
import { EntityNamePicker } from '../fields/EntityNamePicker/EntityNamePicker';
import { EntityTagsPicker } from '../fields/EntityTagsPicker/EntityTagsPicker';
import { OwnerPicker } from '../fields/OwnerPicker/OwnerPicker';
import { OwnedEntityPicker } from '../fields/OwnedEntityPicker/OwnedEntityPicker';
import { LoggedInUserPicker } from '../fields/LoggedInUserPicker/LoggedInUserPicker';
import { DevxRepoUrlPicker } from '../fields/DevxRepoPicker/RepoUrlPicker';
import { repoPickerValidation } from '../fields/DevxRepoPicker';
import { ProjectRepoUrlForClone } from '../fields/ProjectRepoUrlForClone';
import { repoFieldValidation } from '../fields/ProjectRepoUrlForClone/validation';
import { CheckHaveARepo } from '../fields/CheckHaveARepo/CheckHaveARepo';
import { DevxRepoUrlPickerClone } from '../fields/DevxRepoPicker/RepoUrlPickerClone';
import { SourceLocationPicker } from '../fields/SourceLocationPicker/SourceLocationPicker';
import { PocNamePicker } from '../fields/PocNamePicker';

export const DEFAULT_SCAFFOLDER_FIELD_EXTENSIONS = [
  {
    component: EntityPicker,
    name: 'EntityPicker',
  },
  {
    component: EntityNamePicker,
    name: 'EntityNamePicker',
  },
  {
    component: EntityTagsPicker,
    name: 'EntityTagsPicker',
  },
  {
    component: OwnerPicker,
    name: 'OwnerPicker',
  },
  {
    component: OwnedEntityPicker,
    name: 'OwnedEntityPicker',
  },
  {
    component: LoggedInUserPicker,
    name: 'DevxUserPicker',
  },
  {
    component: DevxRepoUrlPicker,
    name: 'DevxRepoUrlPicker',
    //validation: repoPickerValidation,
  },
  {
    component: ProjectRepoUrlForClone,
    name: 'ProjectRepoUrlForClone',
    //validation: repoFieldValidation,
  },
  {
    component: CheckHaveARepo,
    name: 'haveAPocRepo',
    
  },
  {
    component: PocNamePicker,
    name: 'PocNamePicker',
    
  },
  {
    component: DevxRepoUrlPickerClone,
    name: 'DevxRepoUrlPickerClone',
    // validation: repoPickerValidation,
  },
  {
    component: SourceLocationPicker,
    name: 'SourceLocationPicker',
  },
];
