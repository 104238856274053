import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  IconButton,
  Typography,
} from '@material-ui/core';
import React from 'react';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { useStyles } from '../../styles';
import { IApmDetails } from '../../interfaces';
import { useStarredEntity } from '@backstage/plugin-catalog-react';

interface ProjectCardProps {
  project: IApmDetails;
  onClick: Function;
}

export const SUSTAIN = 'Sustain';

const ProjectCard = ({ project, onClick }: ProjectCardProps) => {
  const classes = useStyles();

  const { toggleStarredEntity, isStarredEntity } = useStarredEntity(
    `Project:${project.ApmNumber}`,
  );

  const handleBookmarkClick = () => {
    toggleStarredEntity();
  };

  return (
    <Card classes={{ root: classes.projectCard }}>
      <IconButton
        className={`${classes.bookmarkButton}`}
        onClick={handleBookmarkClick}
      >
        {isStarredEntity ? (
          <BookmarkIcon className={classes.bookmarkSvg} />
        ) : (
          <BookmarkBorderIcon className={classes.bookmarkSvg} />
        )}
      </IconButton>
      <CardActionArea
        onClick={() => onClick()}
        className={classes.projectCardActionArea}
      >
        <CardContent className={classes.projectContent}>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            className={classes.cardTitle}
          >
            {project.Name}
          </Typography>
          <div className={classes.cardDescription}>
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: project.Description,
              }}
            />
          </div>

          <Box component="div" className={classes.flexSpacebetween}>
            <div className={`${classes.flexColumn}`}>
              <span className={classes.apmLabel}>Asset Type</span>
              <Typography variant="body2" className={classes.apmValue}>
                {project.AssetType}{' '}
              </Typography>
            </div>
            <div className={`${classes.flexColumn}`}>
              <span className={classes.apmLabel}>APM ID / APM number</span>
              <Typography variant="body2" className={classes.apmValue}>
                {project.ApmId}/{project.ApmNumber}
              </Typography>
            </div>
            <div className={`${classes.flexColumn} ${classes.chipColumn}`}>
              <Chip
                size="small"
                variant="default"
                label={project.Recommendation}
                className={`${classes.scopeChip} ${
                  project.Recommendation === SUSTAIN
                    ? classes.sustainChip
                    : classes.investChip
                }`}
              />
            </div>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ProjectCard;
