import React, { useEffect, useContext } from 'react';
import {
  Grid,
  Button,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useNavigate } from 'react-router-dom';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import { PromotionContext } from '../../../providers/PromotionDataProvider';
import BackButton from '../../utils/backButton';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    theme: {
      backgroundColor: theme.palette.background.paper,
    },
  }),
);
export const GamificationControl = () => {
  const classes = useStyles();
  const promotionContextData = useContext(PromotionContext);
  const navigate = useNavigate();

  useEffect(() => {
    promotionContextData.setToggleHelpButton(false);
  }, []);

  const navigateToLevelPage = () => {
    navigate('level-and-badges');
  };
  const navigateToAdhocPage = () => {
    navigate('adhoc-points');
  };
  const navigateToPointsConfigPage = () => {
    navigate('points-configuration');
  };

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item md={12} xs={12}>
        <BackButton />
        <HelpComponent helpUrl={HELP_URL.gamificationControl} />
      </Grid>

      <Grid item xs={12} md={3}>
        <div
          className={`infocard-admin infocard-admin-position-relative ${classes.theme}`}
        >
          <h1>Level And Badges</h1>
          <p>Modify levels And Badges </p>

          <div className="button-see-more editButton-gamification">
            <Button
              onClick={navigateToLevelPage}
              color="primary"
              data-testid="edit-level-page-btn"
              variant="contained"
            >
              Edit <EditOutlinedIcon />
            </Button>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={3}>
        <div
          className={`infocard-admin infocard-admin-position-relative ${classes.theme}`}
        >
          <h1>Adhoc Points</h1>
          <p>Modify points for an individual based of contribution </p>
          <div className="button-see-more editButton-gamification">
            <Button
              color="primary"
              onClick={navigateToAdhocPage}
              data-testid="adhoc-points-page-btn"
              variant="contained"
            >
              Edit <EditOutlinedIcon />
            </Button>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={3}>
        <div
          className={`infocard-admin infocard-admin-position-relative ${classes.theme}`}
        >
          <h1>Points Configuration</h1>
          <p>Points for each action can be modified </p>
          <div className="button-see-more editButton-gamification">
            <Button
              color="primary"
              onClick={navigateToPointsConfigPage}
              data-testid="points-page-btn"
              variant="contained"
            >
              Edit <EditOutlinedIcon />
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
