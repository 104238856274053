/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { toolsProvisioningApiRef } from '../../../apis/toolsProvisioningApi';
import { IAdmin } from '../interfaces';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../providers/AuthProvider';

export const useAdminCrud = (apmNumber: string, shouldcall: boolean) => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const toolsprovisioningApi = useApi(toolsProvisioningApiRef);

  const [admins, setAdmins] = useState<IAdmin[]>([]);
  const [loading, setLoading] = useState(false);
  const { profEmail } = useContext(AuthContext);

  const createAdmin = async (email: string) => {
    setLoading(true);
    if (toolsprovisioningApi) {
      try {
        const response = await toolsprovisioningApi.createAdmin({
          email: email,
          added_by_email: profEmail,
          apm_key: apmNumber,
        });
        if (response?.id) {
          setLoading(false);
          setAdmins(prevState => [...prevState, response]);
          notificationApi.sendNotification({
            message: 'New admin added', // response?.reason || 'Success',
            disapperAfterMs: 2500,
            severity: 'success',
          });
        }
      } catch (error) {
        setLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
    return null;
  };

  const deleteAdmin = async (adminId: string) => {
    setLoading(true);
    if (toolsprovisioningApi) {
      try {
        const response = await toolsprovisioningApi.deleteAdmin(adminId);
        if (response) {
          setLoading(false);
          setAdmins(prevState =>
            prevState.filter(admin => admin.id !== adminId),
          );

          notificationApi.sendNotification({
            message: response?.message || 'Deleted successfully',
            disapperAfterMs: 2500,
            severity: 'success',
          });
        }
      } catch (error) {
        setLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
    return null;
  };

  const getAllAdmins = async () => {
    setLoading(true);
    if (toolsprovisioningApi) {
      try {
        const response = await toolsprovisioningApi.getAllAdmins(apmNumber);
        setLoading(false);
        if (response) {
          setAdmins(response);
        }
      } catch (error) {
        setLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
    return null;
  };

  useEffect(() => {
    if (shouldcall) getAllAdmins();
  }, [shouldcall]);

  return [admins, loading, createAdmin, deleteAdmin] as const;
};
