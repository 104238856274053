import React, { useEffect, useState } from 'react';
// @ts-ignore
import { useApi } from '@backstage/core-plugin-api';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import {
  notificationApiRef,
  NotificationApi,
  INotificationMessage,
} from '../../apis/notificationApi';

function AppNotifications() {
  const [notifications, setNotifications] = useState<INotificationMessage[]>(
    [],
  );

  const notificationApi: NotificationApi = useApi(notificationApiRef);

  useEffect(() => {
    let subscription: { unsubscribe: any } | undefined = undefined;
    if (notificationApi) {
      subscription = notificationApi
        .publisher$()
        .subscribe((value: INotificationMessage) => {
          setNotifications(arr => arr.concat(value));
        });
    }
    return () => {
      if (subscription !== undefined) {
        subscription.unsubscribe();
      }
    };
  }, [notificationApi]);

  const [firstNotification] = notifications;

  useEffect(() => {
    if (firstNotification) {
      const timeoutInMs = firstNotification.disapperAfterMs ?? 2000;
      const timeout = setTimeout(() => {
        if (firstNotification.callback) {
          firstNotification.callback();
        }
        setNotifications(arr => {
          const newArr = arr.filter(item => item !== firstNotification);
          return newArr.length === arr.length ? arr : newArr;
        });
      }, timeoutInMs);
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [firstNotification]);

  if (notifications.length === 0) {
    return null;
  }

  const handleClose = () => {
    if (firstNotification.callback) {
      firstNotification.callback();
    }
    setNotifications(arr => arr.filter(item => item !== firstNotification));
  };

  return (
    <Snackbar open anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert
        action={
          <IconButton
            color="inherit"
            size="small"
            onClick={handleClose}
            data-testid="error-button-close"
          >
            <CloseIcon />
          </IconButton>
        }
        severity={firstNotification.severity}
      >
        <Typography component="span">
          {String(firstNotification.message)}
        </Typography>
      </Alert>
    </Snackbar>
  );
}
export default AppNotifications;
