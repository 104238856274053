import React, { useState } from 'react';
import { Chip, Input } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffSharpIcon from '@material-ui/icons/HighlightOffSharp';
import { handleDelete } from '../utils/handleDeleteTags';

const useStyles = makeStyles({
  root: {
    border: '1px  solid #2E5881',
    color: '#2E5881',
  },
  rootC: {
    backgroundColor: '#2E5881',
  },
  typography: {
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  tagStyle: {
    fontSize: '11px',
    color: '#f00',
  },
});

export const AddShowTags = (props: {
  defaultTags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
  numberOfTags?: number;
  numberOfCharacters?: number;
  setIsButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  editable?: boolean
}) => {
  // const chipclasses = useStyle();
  const {
    defaultTags,
    setTags,
    numberOfTags,
    numberOfCharacters,
    setIsButtonDisabled,
    editable
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState('');
  const [validatorForNoTags, setValidatorForNoTags] = useState(false);
  const [validateSpecialCharacter, setValidateSpecialCharacter] =
    useState(false);
  const [validateNoOfCharacter, setValidateNoOfCharacter] = useState(false);
  const [validatorDuplicate, setValidatorDuplicate] = useState(false);

  // TypeScript error as but as it is a JSX syntax.
  const handleClickPopover = (event: any) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const popoverHandleclose = () => {
    setAnchorEl(null);
  };

  // handleclose will push the values to the state
  const handleClose = () => {
    const str: string = value !== null ? value.replace(/\s/g, '') : '';
    setAnchorEl(null);
    if (str === '' || value === undefined) {
      setTags([...defaultTags]);
    } else {
      if (
        defaultTags?.indexOf(value.toString().toLowerCase()) === -1 &&
        defaultTags?.length <= (numberOfTags || 9)
      ) {
        setTags([...defaultTags, value.toString().toLowerCase()]);
        setValidatorDuplicate(false);
        setValidatorForNoTags(false);
        setIsButtonDisabled(false);
      } else if (defaultTags?.indexOf(value.toString().toLowerCase()) !== -1) {
        setValidatorDuplicate(true);
      } else if (defaultTags?.length > (numberOfTags || 9)) {
        setValidatorForNoTags(true);
      }
    }
    setValue('');
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleInput = (event: {
    preventDefault: () => void;
    target: { value: any };
  }) => {
    event.preventDefault();
    const value1 = event.target.value;
    const pattern = /[^a-zA-Z]/gi;
    const regex = new RegExp(pattern);
    if (value1.length <= (numberOfCharacters || 15) && !value1.match(regex)) {
      setValidateNoOfCharacter(false);
      setValidateSpecialCharacter(false);
      setValue(event.target.value.replace(/[^a-zA-Z]/gi, ''));
    } else if (value1.length > (numberOfCharacters || 15)) {
      setValidateNoOfCharacter(true);
    } else if (value1.match(regex)) {
      setValidateSpecialCharacter(true);
    }
  };
  const classes = useStyles();
  return (
    <>
      {(
        defaultTags?.filter(
          defaultTag => !(defaultTag === '' || defaultTag === null),
        ) || []
      ).map((item, index) => (
        <Chip
          key={index}
          label={item}
          color="primary"
          size="small"
          variant="outlined"
          onDelete={() =>
            handleDelete(
              index,
              defaultTags,
              setTags,
              setValidatorForNoTags,
              setValidatorDuplicate,
              setIsButtonDisabled,
            )
          }
        />
      ))}

      {editable && !validatorForNoTags && (
        <Chip
          aria-describedby={id}
          icon={<AddIcon />}
          label="ADD MORE"
          onClick={handleClickPopover}
          color="primary"
          variant="outlined"
          size="small"
        />
      )}

      {editable && validatorForNoTags && (
        <Chip
          aria-describedby={id}
          icon={<AddIcon />}
          label="ADD MORE"
          onClick={handleClickPopover}
          color="primary"
          variant="outlined"
          size="small"
          disabled
          data-testid="add-more-tags"
        />
      )}
      {validatorForNoTags && (
        <Typography className={classes.tagStyle}>
          *Maximum 10 tags supported.
        </Typography>
      )}
      {validatorDuplicate && (
        <Typography className={classes.tagStyle}>
          *Duplicate Tags not allowed.
        </Typography>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={popoverHandleclose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.typography} component="div">
          {' '}
          <Input
            value={value}
            color="primary"
            onChange={handleInput}
            data-testid="type-text-for-tabs"
          />{' '}
          <CheckCircleOutlineIcon
            onClick={handleClose}
            data-testid="handle-check"
          />{' '}
          <HighlightOffSharpIcon
            onClick={popoverHandleclose}
            data-testid="handle-close"
          />
        </Typography>
        {validateSpecialCharacter && (
          <Typography className={classes.tagStyle}>
            {' '}
            *special characters/space/numbers not accepted
          </Typography>
        )}
        {validateNoOfCharacter && (
          <Typography className={classes.tagStyle}>
            {' '}
            *no of characters exceeded
          </Typography>
        )}
      </Popover>
    </>
  );
};
