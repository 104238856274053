import React from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  makeStyles,
  Paper,
  useTheme,
} from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import { Link, useContent } from '@backstage/core-components';
import { useApi, useRouteRef } from '@backstage/core-plugin-api';
import { CatalogSearchResultListItem } from '@backstage/plugin-catalog';
import {
  catalogApiRef,
  // CATALOG_FILTER_EXISTS,
} from '@backstage/plugin-catalog-react';
import {
  DefaultResultListItem,
  SearchBar,
  // SearchFilter,
  SearchResult,
  SearchResultPager,
  useSearch,
  // SearchType,
} from '@backstage/plugin-search-react';
import { searchPlugin } from '@backstage/plugin-search';
import { TechDocsSearchResultListItem } from '@backstage/plugin-techdocs';

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: 0,
    display: 'flex',
  },
  filter: {
    '& + &': {
      marginTop: theme.spacing(2.5),
    },
  },
  filters: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  input: {
    flex: 1,
    margin: '0px',
  },
  dialogActionsContainer: { padding: theme.spacing(1, 3) },
  viewResultsLink: { verticalAlign: '0.5em' },
}));

export const SearchModal = ({ toggleModal }: { toggleModal: () => void }) => {
  const getSearchLink = useRouteRef(searchPlugin.routes.root);
  const classes = useStyles();

  const catalogApi = useApi(catalogApiRef);
  const { term, types } = useSearch();
  const { focusContent } = useContent();
  const { transitions } = useTheme();

  const handleResultClick = () => {
    toggleModal();
    setTimeout(focusContent, transitions.duration.leavingScreen);
  };

  const handleKeyPress = () => {
    handleResultClick();
  };

  return (
    <>
      <DialogTitle>
        <Paper className={classes.container}>
          <SearchBar className={classes.input} />
        </Paper>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Grid item align="right">
            <Link
              onClick={() => {
                toggleModal();
                setTimeout(focusContent, transitions.duration.leavingScreen);
              }}
              to={`${getSearchLink()}?query=${term}`}
            >
              <span className={classes.viewResultsLink}>View Full Results</span>
              <LaunchIcon color="primary" />
            </Link>
          </Grid>
          {/* <Grid item>
            <SearchType.Tabs
              defaultValue="software-catalog"
              types={[
                {
                  value: 'software-catalog',
                  name: 'Software Catalog',
                },
                {
                  value: 'techdocs',
                  name: 'Documentation',
                },
              ]}
            />
          </Grid> */}
          {/* <Grid item container>
            {types.includes('techdocs') && (
              <Grid item xs={2}>
                <SearchFilter.Select
                  className={classes.filter}
                  label="Entity"
                  name="name"
                  values={async () => {
                    // Return a list of entities which are documented.
                    const { items } = await catalogApi.getEntities({
                      fields: ['metadata.name'],
                      filter: {
                        'metadata.annotations.backstage.io/techdocs-ref':
                          CATALOG_FILTER_EXISTS,
                      },
                    });

                    const names = items.map(entity => entity.metadata.name);
                    names.sort();
                    return names;
                  }}
                />
              </Grid>
            )}
            <Grid item xs={2}>
              <SearchFilter.Select
                className={classes.filter}
                label="Kind"
                name="kind"
                values={['Component', 'Template']}
              />
            </Grid>
            <Grid item xs={2}>
              <SearchFilter.Select
                className={classes.filter}
                label="Lifecycle"
                name="lifecycle"
                values={['experimental', 'production']}
              />
            </Grid>
            <Grid
              item
              xs={types.includes('techdocs') ? 6 : 8}
              container
              direction="row-reverse"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Link
                  onClick={() => {
                    toggleModal();
                    setTimeout(
                      focusContent,
                      transitions.duration.leavingScreen,
                    );
                  }}
                  to={`${getSearchLink()}?query=${term}`}
                >
                  <span className={classes.viewResultsLink}>
                    View Full Results
                  </span>
                  <LaunchIcon color="primary" />
                </Link>
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item xs>
            <SearchResult>
              {({ results }) => (
                <List>
                  {results
                    ?.filter((item: any) => item?.document?.kind !== 'Location')
                    .map(({ type, document }) => {
                      if (document?.kind === 'Template') {
                        document.location = document.location.replace(
                          '/catalog/default/template',
                          'software-templates/templates',
                        );
                      }

                      if (document?.type === 'capability') {
                        document.location = `taxonomy/view/Group/default/${document?.title}`;
                        document.lifecycle = '';
                      }

                      if (document?.type === 'extdocumentation') {
                        document.location = '/docs/external';
                      }

                      if (document?.type === 'toolbox-item') {
                        document.location = `/toolbox?q=${document?.title}`;
                      }

                      let resultItem;
                      switch (type) {
                        case 'software-catalog':
                          resultItem = (
                            <CatalogSearchResultListItem
                              key={document.location}
                              result={document}
                            />
                          );
                          break;
                        case 'techdocs':
                          resultItem = (
                            <TechDocsSearchResultListItem
                              key={document.location}
                              result={document}
                            />
                          );
                          break;
                        default:
                          resultItem = (
                            <DefaultResultListItem
                              key={document.location}
                              result={document}
                            />
                          );
                      }
                      return (
                        <div
                          role="button"
                          tabIndex={0}
                          key={`${document.location}-btn`}
                          onClick={handleResultClick}
                          onKeyPress={handleKeyPress}
                          className="searchModalResult"
                        >
                          {resultItem}
                        </div>
                      );
                    })}
                </List>
              )}
            </SearchResult>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActionsContainer}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <SearchResultPager />
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};
