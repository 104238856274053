import React, { useEffect, useState } from 'react';
import { Progress, SelectItem } from '@backstage/core-components';
import FormControl from '@material-ui/core/FormControl';
import { useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import { scaffolderApiRef } from '@backstage/plugin-scaffolder-react';
import { InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles(_theme => ({
  templateSelect: {
    '& div[class^="MuiFormControl-root"]': {
      [_theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
  },
}));
export const RepoUrlPickerHost = (props: {
  host?: string;
  hosts?: string[];
  onChange: (host: string) => void;
  rawErrors: string[];
  label?: string;
}) => {
  const classes = useStyles();
  const { host, hosts, onChange, rawErrors, label } = props;
  const scaffolderApi = useApi(scaffolderApiRef);

  const { value: { integrations } = { integrations: [] }, loading } = useAsync(
    async () => {
      return await scaffolderApi.getIntegrationsList({
        allowedHosts: hosts ?? [],
      });
    },
  );

  useEffect(() => {
    // If there is no host chosen currently
    if (!host) {
      // Set the first of the allowedHosts option if that available
      if (hosts?.length) {
        onChange(hosts[0]);
        // if there's no hosts provided, fallback to using the first integration
      } else if (integrations?.length) {
        onChange(integrations[0].host);
      }
    }
  }, [hosts, host, onChange, integrations]);

  // If there are no allowedHosts provided, then show all integrations. Otherwise, only show integrations
  // that are provided in the dropdown for the user to choose from.
  let hostsOptions: SelectItem[] = integrations
    ? integrations
        .filter(i => (hosts?.length ? hosts?.includes(i.host) : true))
        .map(i => ({ label: i.title, value: i.host }))
    : [{ label: 'Loading...', value: 'loading' }];

  if (
    hostsOptions &&
    hostsOptions?.length > 0 &&
    hostsOptions[0].value !== 'loading'
  ) {
    hostsOptions = [{ label: 'Select', value: 'select' }, ...hostsOptions];
  }

  if (loading) {
    return <Progress />;
  }

  const handleOnChange = (event: any) => {
    const s = event.target.value;
    onChange(String(Array.isArray(s) ? s[0] : s));
  };

  return (
    <>
      <FormControl
        margin="normal"
        required
        variant="outlined"
        error={
          rawErrors?.length > 0 && (!host || host.toLowerCase() === 'select')
        }
        className={classes.templateSelect}
        disabled={hosts?.length === 1 ?? false}
      >
        <InputLabel id="target-label">{label}</InputLabel>
        <Select
          label={label}
          onChange={event => handleOnChange(event)}
          labelId="target-label"
          id="host-select"
          value={host}
          data-testid="host-select"
        >
          {hostsOptions?.map(hostOption => (
            <MenuItem value={hostOption.value} key={`repo-picker-${hostOption.label}`}>{hostOption.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
