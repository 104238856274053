import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SalesforceIcon = () => (
  <SvgIcon viewBox="0 0 30 30">
    <path d="M12.3753 9.14031C13.1425 8.42356 14.2116 7.97015 15.3898 7.97015C16.9609 7.97015 18.3223 8.76119 19.0531 9.93102C19.6838 9.6757 20.3868 9.5355 21.1306 9.5355C23.9626 9.5355 26.25 11.6202 26.25 14.1911C26.25 16.7619 23.9496 18.847 21.1228 18.847C20.7853 18.8466 20.4487 18.8162 20.1178 18.7563C19.4783 19.7862 18.2544 20.4863 16.8473 20.4863C16.2627 20.4863 15.7055 20.3624 15.2121 20.1516C14.5636 21.5276 13.0471 22.5 11.2835 22.5C9.4472 22.5 7.87613 21.4536 7.27701 19.9786C7.00945 20.0282 6.73699 20.0531 6.46384 20.0529C4.27214 20.0499 2.50001 18.4354 2.50001 16.4327C2.49839 15.8 2.68057 15.1781 3.0284 14.6287C3.37623 14.0794 3.87758 13.6218 4.48248 13.3016C4.23579 12.799 4.0989 12.2386 4.0989 11.6453C4.08963 9.36283 6.16336 7.50001 8.70266 7.50001C9.41356 7.49901 10.1151 7.64652 10.7522 7.93099C11.3893 8.21546 11.9447 8.62915 12.3749 9.13964" />
  </SvgIcon>
);

export default SalesforceIcon;
