/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { toolsProvisioningApiRef } from '../../../apis/toolsProvisioningApi';
import { useEffect, useState } from 'react';
import { IApmDetails } from '../interfaces';

export const useGetApmInfoById = (
  apmNumber: string | undefined,
  allApms: IApmDetails[],
) => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const toolsprovisioningApi = useApi(toolsProvisioningApiRef);

  const [apmInfo, setApmInfo] = useState<IApmDetails | null>(null);

  const fetchMyAPI = async () => {
    if (toolsprovisioningApi && apmNumber) {
      try {
        const response = await toolsprovisioningApi.getApmInfoById(apmNumber);
        if (response?.ApmId) setApmInfo(response);
      } catch (error) {
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };

  useEffect(() => {
    if (apmNumber === undefined) return;
    if (apmNumber !== apmInfo?.ApmNumber) {
      setApmInfo(null);
    }
    if (apmNumber) {
      if (allApms.length > 0) {
        setApmInfo(
          allApms.find(
            (apmObj: IApmDetails | null) => apmObj?.ApmNumber === apmNumber,
          ) || null,
        );
      } else fetchMyAPI();
    }
  }, [apmNumber]);

  return [apmInfo];
};
