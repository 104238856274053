import React, { useContext, useEffect, useState } from 'react';
import {
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import REGISTER_API_CONSTANTS from '../Constants';
import { useStyles } from './styles';
import { PromotionContext } from '../../../../providers/PromotionDataProvider';
import Heading from './Heading';

const BodyContent = (props: any) => {
  const {
    onContentInput,
    onSecDelete,
    data,
    className,
    headingVisible = true,
    clicked,
  } = props;
  const classes = useStyles();

  const contentTypes = [
    {
      value: 'application/json',
      label: 'application/json',
    },
    {
      value: 'application/xml',
      label: 'application/xml',
    },
  ];

  /* Context */
  const myContext = useContext(PromotionContext);

  const [formValues, setFormValues] = useState({
    description: '',
    content: 'application/json',
    schema: '',
  });

  /* Change Handler for all form inputs */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  /* Validate */
  const validate = () => {
    try {
      JSON.parse(formValues.schema);
      return true;
    } catch (e) {
      return false; // error in the above string (in this case, yes)!
    }
  };

  // On Change of formValues
  useEffect(() => {
    if (validate()) {
      const responseObj = {
        description: formValues.description,
        content: {
          [formValues.content]: {
            schema: formValues.schema && JSON.parse(formValues.schema),
          },
        },
      };
      onContentInput(responseObj);
    }
  }, [clicked]);

  // Variable for expansion
  const [expanded, setExpanded] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  /* Handle delete of response on click of response button */
  const handleDeleteResponse = () => {
    onSecDelete(data.code);
  };

  return (
    <div className={className}>
      {/* Heading */}
      {headingVisible && (
        <Heading
          text={`${REGISTER_API_CONSTANTS.swaggerApiForm.responseHeading} 
        ${data.code}`}
          onDelete={handleDeleteResponse}
          onExpand={handleExpandClick}
          expanded={expanded}
        />
      )}
      {/* collapsable section */}
      <Collapse in={expanded} timeout="auto">
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              label={REGISTER_API_CONSTANTS.swaggerApiForm.description.label}
              placeholder={
                REGISTER_API_CONSTANTS.swaggerApiForm.description.placeholder
              }
              value={formValues.description}
              name="description"
              onChange={handleChange}
              fullWidth
              variant="outlined"
              multiline
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="api-type-label">Content Type </InputLabel>
              <Select
                disabled
                labelId="api-type-label"
                label={REGISTER_API_CONSTANTS.swaggerApiForm.contentType.label}
                name="content"
                value={formValues.content}
                onChange={(event: any) => {
                  handleChange(event);
                }}
              >
                {contentTypes?.map((contentType: any) => {
                  return (
                    <MenuItem value={contentType.value} key={contentType}>
                      {contentType.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              InputProps={{
                minRows: 3,
                maxRows: 3,
              }}
              label={REGISTER_API_CONSTANTS.swaggerApiForm.schema.label}
              placeholder={
                REGISTER_API_CONSTANTS.swaggerApiForm.schema.placeholder
              }
              value={formValues.schema}
              name="schema"
              onChange={handleChange}
              multiline
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Collapse>
    </div>
  );
};
export default BodyContent;
