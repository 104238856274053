import React from 'react';
import {
  Box,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { UtilityToolCard } from './toolcard';
import { IUtilityTool } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '24px',
    },
    utilityToolCard: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  }),
);
export interface IUtilityToolGroupProps {
  title: string;
  tools: IUtilityTool[];
}

export const UtilityToolGroup = ({ title, tools }: IUtilityToolGroupProps) => {
  const classes = useStyles();

  return (
    <Box sx={{ mb: 2 }}>
      <Box>
        <Typography className={classes.title}>{title}</Typography>
      </Box>
      <Box sx={{ mt: 2 }} className={classes.utilityToolCard}>
        {tools !== undefined &&
          Array.isArray(tools) &&
          tools.length > 0 &&
          tools.map((tool: IUtilityTool) => (
            <UtilityToolCard key={tool?.path} tool={tool} />
          ))}
      </Box>
    </Box>
  );
};
