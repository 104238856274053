import { createApiRef } from '@backstage/core-plugin-api';
import { AxiosInstance } from 'axios';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';

export interface ICreateCAVariableRequest {
  assetkind: string;
  assettype: string | undefined;
  defaultcomplexity: boolean | undefined;
  complexity: string;
  manhours: number;
  blendedrate: number;
  coefficient: number;
  costavoidanceformula: string | undefined;
  useremail: string | undefined;
  coefficient2?: any;
  active?: boolean;
}

export type ManageDataInsightItem = {
  id?: string;
  title?: string;
  description?: string;
  url?: string;
  imageurl?: string;
  category_name?: string;
  category_id?: string;
  imageFileName?: string;
};

export type ManageDataCategory = {
  id?: string;
  name?: string;
  description?: string;
};

type ActionResponse = {
  message?: string;
  status?: number;
};

export interface AdminApi {
  getAdminData: () => Promise<any>;
  deleteAdmin: (adminUser: string) => Promise<any>;
  getReviewerList: () => Promise<any>;
  deleteReviewer: (kindType: string, reviewer: string) => Promise<any>;
  updateReviewer: (data: any) => Promise<any>;
  getUserList: (queryString: string) => Promise<any>;
  addAdmin: (data: any) => Promise<any>;
  addReviewer: (data: any) => Promise<any>;
  addCAVariable: (data: ICreateCAVariableRequest) => Promise<any>;
  deleteCAVariable: (id: string) => Promise<any>;
  getCAVariablesData: () => Promise<any>;
  getCAVariableById: (id: string | number) => Promise<any>;
  updateCAVariable: (id: any, data: any) => Promise<any>;
  getComplexityCatalogInfo: () => Promise<any>;
  getComplexityCatalogInfoById: (id: any) => Promise<any>;
  updateAssetComplexityInCatalogInfo: (data: any) => Promise<any>;
  getComplexityCatalogInfoByAssetName: (assetName: string) => Promise<any>;
  getExclusionListData: () => Promise<any>;
  deleteExclusionList: (adminUser: string) => Promise<any>;
  addExclusionList: (data: any) => Promise<any>;
  //Manage data Insights Categories
  getCategories: () => Promise<ManageDataCategory[]>;
  addCategory: (data: ManageDataCategory) => Promise<ActionResponse>;
  updateCategory: (data: ManageDataCategory) => Promise<ActionResponse>;
  deleteCategory: (data: ManageDataCategory) => Promise<ActionResponse>;
  //Manage data insights Items
  getItems: () => Promise<ManageDataInsightItem[]>;
  addItem: (data: ManageDataInsightItem) => Promise<ActionResponse>;
  updateItem: (data: ManageDataInsightItem) => Promise<ActionResponse>;
  deleteItem: (data: string) => Promise<ActionResponse>;
  getImagebyItemId: (data: string) => Promise<ActionResponse>;
  addReviewerInAssets: (data: any) => Promise<ActionResponse>;
  
}

export const adminApiRef = createApiRef<AdminApi>({
  id: 'cbre.devx.api.admin',
});

export class AdminApiImpl implements AdminApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  async getAdminData(): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance.get('service/discovery/admin').then(res => res.data);
  }

  async deleteAdmin(adminUser: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .delete('service/discovery/admin', {
        params: {
          admin: adminUser,
        },
      })
      .then(res => res.data);
  }

  async getReviewerList(): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance.get('service/discovery/reviewer').then(res => res.data);
  }

  async deleteReviewer(kindType: string, reviewer: string): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance
      .delete('service/discovery/reviewer', {
        params: {
          kindType,
          reviewer,
        },
      })
      .then(res => res.data);
  }

  async updateReviewer(data: any): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance.put('service/discovery/review', data).then(res => res.data);
  }

  async getUserList(queryString: string): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance
      .get('ms-graph/user', {
        params: {
          q: queryString,
        },
      })
      .then(res => res.data);
  }

  async addAdmin(data: any): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance.post('service/discovery/admin', data).then(res => res.data);
  }

  async addReviewer(data: any): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance
      .post('service/discovery/reviewer', data)
      .then(res => res.data);
  }

  async getCAVariablesData(): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance.get('service/costavoidance').then(res => res.data);
  }

  async getCAVariableById(id: string | number): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance.get(`service/costavoidance/${id}`).then(res => res.data);
  }

  async addCAVariable(data: ICreateCAVariableRequest): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance.post(`service/costavoidance`, data).then(res => res.data);
  }

  async deleteCAVariable(id: string): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance.delete(`service/costavoidance/${id}`).then(res => res.data);
  }

  async updateCAVariable(id: any, data: any): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance
      .put(`service/costavoidance/${id}`, data)
      .then(res => res.data);
  }

  async getComplexityCatalogInfo(): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance.get('service/discovery/cataloginfo').then(res => res.data);
  }

  async getComplexityCatalogInfoById(id: any): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`service/discovery/cataloginfo/${id}`)
      .then(res => res.data);
  }

  async getComplexityCatalogInfoByAssetName(assetName: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`service/discovery/cataloginfo/complexity/${assetName}`)
      .then(res => res.data);
  }

  async updateAssetComplexityInCatalogInfo(data: any): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance
      .put(`service/discovery/cataloginfo/complexity`, data)
      .then(res => res.data);
  }

  async getExclusionListData(): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get('service/rewards/gamification/exclude')
      .then(res => res.data);
  }

  async deleteExclusionList(userEmail: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .delete(`service/rewards/gamification/exclude/${userEmail}`)
      .then(res => res.data);
  }

  async addExclusionList(data: any): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .post('service/rewards/gamification/exclude', data)
      .then(res => res.data);
  }

  //Manage data Insights Categories
  async getCategories(): Promise<ManageDataCategory[]> {
    const instance = await this.axiosInstancePromise;
    return instance.get('service/datainsights/category').then(res => res.data);
  }
  async addCategory(data: ManageDataCategory) {
    const instance = await this.axiosInstancePromise;
    return instance
      .post('service/datainsights/category', data)
      .then(res => res.data);
  }
  async updateCategory(data: ManageDataCategory) {
    const instance = await this.axiosInstancePromise;
    return instance
      .put('service/datainsights/category', data)
      .then(res => res.data);
  }
  async deleteCategory(data: ManageDataCategory) {
    const instance = await this.axiosInstancePromise;
    return instance
      .delete('service/datainsights/category/' + data.id)
      .then(res => res.data);
  }
  //Manage data insights Items
  async getItems() {
    const instance = await this.axiosInstancePromise;
    return instance.get('service/datainsights/item').then(res => res.data);
  }

  async addItem(data: any) {
    const formData = new FormData();
    formData.append('payload', data);

    const instance = await this.axiosInstancePromise;
    return instance
      .post('service/datainsights/item', formData)
      .then(res => res.data);
  }
  async updateItem(data: any) {
    const formData = new FormData();
    formData.append('payload', data);
   //formData.payload=data;

   console.log("formData --- edit",  formData)
    const instance = await this.axiosInstancePromise;
    return instance
      .put('service/datainsights/item', formData)
      .then(res => res.data);
  }
  async deleteItem(data: string) {
    const instance = await this.axiosInstancePromise;
    return instance
      .delete('service/datainsights/item/' + data)
      .then(res => res.data);
  }

  // GetImagebyItemId
  async getImagebyItemId(data: string) {
    const instance = await this.axiosInstancePromise;
    return instance
      .get('service/datainsights/image/' + data)
      .then(res => res.data);
  }

  async addReviewerInAssets(data: any): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance.put('service/discovery/conversion/v2', data).then(res => res.data);
  };
}
