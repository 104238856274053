import React from 'react';
import { useStyles } from '../../../../../styles/popupStyles';
import { Typography } from '@material-ui/core';
import { ISecrets } from '../../../../../interfaces';

interface ISecretsSectionProps {
  secrets: ISecrets[];
}
const SecretsSection = ({ secrets }: ISecretsSectionProps) => {
  console.log(secrets);
  const classes = useStyles();

  return (
    <div className={classes.sectionContainer}>
      <Typography className={classes.sectionHeading}>Secrets</Typography>
      {secrets?.map((secret: ISecrets) => {
        return (
          <div>
            <div className={classes.labelAndValue}>
              <div className={classes.label}>Name:</div>
              <div>{secret?.name}</div>
            </div>
            <div className={classes.labelAndValue}>
              <div className={classes.label}>Value:</div>
              <div>{secret?.value}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SecretsSection;
