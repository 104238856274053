import React from 'react';
import { Grid, useTheme } from '@material-ui/core';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import FeedbackType from '../FeedbackType';
import FeedbackCard from './FeedbackCard';
import Constants from '../Constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    feedbackContainer: {
      paddingTop: '1%',
    },
    feedbackTypeContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      marginTop: '10px ',
      ['@media (max-width:920px) and (min-width: 280px)']: {
        marginBottom: '6px ',
      },
    },
    feedbackTypes: {
      ['@media (max-width:920px) and (min-width: 280px)']: {
        paddingTop: '8px',

        flexBasis: '33%',
      },
    },
    flexContainer: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '60%',
      },

      ['@media (max-width:920px) and (min-width: 280px)']: {
        // flexDirection: 'row',
        // alignItems: 'flex-start',
        flexWrap: 'wrap',
        justifyContent: 'left',
        marginLeft: '3%',
      },
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '450px',
    },
  }),
);

const FeedbackGrid = ({
  content,
  isAdmin,
  allContent,
}: {
  content: any;
  isAdmin: boolean | null;
  allContent: any;
}) => {
  const theme = useTheme<Theme>();
  const classes = useStyles();
  const types = Object.keys(Constants.feedbackTypes);

  return (
    <>
      <Grid container className={classes.feedbackContainer} alignItems="center">
        {content &&
          content.map((feedback: any, idx: string) => (
            <Grid
              key={`feedback-card-${idx}`}
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <FeedbackCard feedback={feedback} isAdmin={isAdmin} />
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default FeedbackGrid;
