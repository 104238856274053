import React, { useEffect, useState, useContext } from 'react';
import { Grid } from '@material-ui/core';
import { PromotionContext } from '../../../../providers/PromotionDataProvider';
import PointsConfigListPage from './PointsConfigListPage';
import { useCustomStyles } from './CardStyles';
import ModifyPoints from './modifPoints';

export const PointsConfigContainer = ({
  onRouteChanged,
}: {
  onRouteChanged: Function;
}) => {
  const [isModifyClicked, setModifyClicked] = useState(false);
  const [data, setData] = useState<any>({});
  const classes = useCustomStyles();
  const onclickHelpButton = useContext(PromotionContext);
  useEffect(() => {
    onclickHelpButton.setToggleHelpButton(false);
  }, []);

  let routes = [
    {
      type: 'link',
      link: '/',
      text: 'Home',
    },
    {
      type: 'link',
      link: '/admin',
      text: 'Admin',
    },
    {
      type: 'link',
      link: '/admin/gamification-control',
      text: 'Gamification Control',
    },
  ];

  const onclickHandler = (updatedData: any) => {
    routes = [
      ...routes,
      {
        type: 'link',
        link: '/admin/gamification-control/points-configuration',
        text: 'Points Config',
        // @ts-expect-error("")
        onClick: (): void => {
          setModifyClicked(false);
          setData(undefined);
        },
      },
      {
        type: 'text',
        link: '',
        text: 'Modify Points',
      },
    ];
    onRouteChanged(routes);
    setModifyClicked(true);
    setData(updatedData);
  };

  const handleModifyBack = () => {
    routes = [
      ...routes,
      {
        type: 'text',
        link: '',
        text: 'Points Config',
      },
    ];

    onRouteChanged(routes);
    setModifyClicked(false);
  };

  return (
    <div className={classes.levelAndBadgesContainer}>
      <Grid item xs={12} md={12}>
        {isModifyClicked ? (
          <ModifyPoints data={data} onBackClicked={handleModifyBack} />
        ) : (
          <PointsConfigListPage onclickHandler={onclickHandler} />
        )}
      </Grid>
    </div>
  );
};
