import React, { useState } from 'react';
import { useStyles } from '../common/styles';

export const LinkPreview: any = ({ children, url }: any) => {
  const classes = useStyles();
  const [showPreview, setShowPreview] = useState(false);

  const handleMouseEnter = (): any => {
    return setShowPreview(true);
  };

  const handleMousehLeave = (): any => {
    return setShowPreview(false);
  };

  return (
    <>
      <span
        //     onMouseEnter={handleMouseEnter}
        //    onMouseLeave={handleMousehLeave}
        className={classes.preview}
      >
        {children}
        {showPreview && (
          <iframe
            title="Preview"
            src={url}
            className={classes.iframe}
            allowFullScreen
          />
        )}
      </span>
    </>
  );
};
