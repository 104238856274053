import React from 'react';

const AnswerVoteGiven = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 2.5H5C3.625 2.5 2.5 3.625 2.5 5V27.5L7.5 22.5H25C26.375 22.5 27.5 21.375 27.5 20V5C27.5 3.625 26.375 2.5 25 2.5ZM25 20H6.4625L5 21.4625V5H25V20Z"
        fill="#17E88F"
      />
      <path
        d="M9.46872 15.032L10.0607 16.648H8.87672L9.46872 15.032ZM10.9247 19H12.4447L10.2607 13.256H8.72471L6.53271 19H8.02071L8.42871 17.888H10.5167L10.9247 19Z"
        fill="#17E88F"
      />
      <path
        d="M18.0196 13.256H16.5716V16.416L14.3476 13.256H12.9476V19H14.3956V15.64L16.8036 19H18.0196V13.256Z"
        fill="#17E88F"
      />
      <path
        d="M18.5573 17.296C18.7653 18.424 19.7013 19.096 21.0773 19.096C22.3333 19.096 23.3253 18.416 23.3253 17.24C23.3253 16.36 22.7813 15.808 21.7973 15.584L20.6773 15.32C20.3733 15.248 20.1973 15.112 20.1973 14.864C20.1973 14.552 20.4853 14.36 20.9093 14.36C21.4053 14.36 21.6773 14.6 21.7973 14.92H23.2693C23.1093 13.92 22.3173 13.16 20.9013 13.16C19.6373 13.16 18.6933 13.896 18.6933 15.024C18.6933 16 19.3333 16.44 20.1813 16.616L21.2773 16.856C21.6453 16.944 21.8293 17.064 21.8293 17.336C21.8293 17.688 21.5093 17.888 21.0533 17.888C20.5813 17.888 20.2453 17.696 20.1173 17.296H18.5573Z"
        fill="#17E88F"
      />
      <g clipPath="url(#clip0_72450_60614)">
        <path
          d="M16.3333 12.3333H17.6666V8.33334H16.3333V12.3333ZM23.6666 8.66667C23.6666 8.30001 23.3666 8.00001 22.9999 8.00001H20.8966L21.2133 6.47667L21.2233 6.37001C21.2233 6.23334 21.1666 6.10667 21.0766 6.01667L20.7233 5.66667L18.5299 7.86334C18.4066 7.98334 18.3333 8.15001 18.3333 8.33334V11.6667C18.3333 12.0333 18.6333 12.3333 18.9999 12.3333H21.9999C22.2766 12.3333 22.5133 12.1667 22.6133 11.9267L23.6199 9.57667C23.6499 9.50001 23.6666 9.42001 23.6666 9.33334V8.66667Z"
          fill="#17E88F"
        />
      </g>
      <defs>
        <clipPath id="clip0_72450_60614">
          <rect width="8" height="8" fill="white" transform="translate(16 5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AnswerVoteGiven;
