import { FieldValidation } from '@rjsf/core';
import { JsonObject } from '@backstage/types';
import { ApiHolder } from '@backstage/core-plugin-api';

import { Draft07 as JSONSchema } from 'json-schema-library';
import { CustomFieldValidator } from '../extensions/types';
import { createFieldValidation } from './schema';

export const createAsyncValidators = (
  rootSchema: JsonObject,
  validators: Record<string, undefined | CustomFieldValidator<unknown>>,
  context: {
    apiHolder: ApiHolder;
  },
) => {
  async function validate(formData: JsonObject, pathPrefix: string = '#') {
    const parsedSchema = new JSONSchema(rootSchema);
    const formValidation: Record<string, FieldValidation> = {};
    for (const [key, value] of Object.entries(formData)) {
      const definitionInSchema = parsedSchema.getSchema(
        `${pathPrefix}/${key}`,
        formData,
      );

      if (definitionInSchema && 'ui:field' in definitionInSchema) {
        const validator = validators[definitionInSchema['ui:field']];
        if (validator) {
          const fieldValidation = createFieldValidation();
          try {
            await validator(value, fieldValidation, context);
          } catch (ex) {
            // @ts-expect-error
            fieldValidation.addError(ex?.message);
          }
          formValidation[key] = fieldValidation;
        }
      }
    }

    return formValidation;
  }

  return async (formData: JsonObject) => {
    return await validate(formData);
  };
};
