import React from 'react';

const Bronze = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0001 1L19.6352 10.392L30 11.8982L22.5 19.2089L24.2706 29.5317L15.0001 24.6579L5.72951 29.5317L7.5 19.2089L0 11.8982L10.3648 10.392L15.0001 1Z"
        fill="#FFA861"
      />
      <path
        d="M21.2547 14.8517L16.933 14.2233L15 10.3065L13.0668 14.2233L8.74512 14.8517L11.8721 17.9L11.1343 22.2048L15 20.1725L18.8655 22.2048L18.1277 17.9L21.2547 14.8517Z"
        fill="#B85F00"
      />
      <path
        d="M15.0001 24.6579V1L10.3648 10.392L0 11.8982L7.5 19.2089L5.72951 29.5317L15.0001 24.6579Z"
        fill="#EA8F44"
      />
      <path
        d="M15 20.1725V10.3065L13.0668 14.2233L8.74512 14.8517L11.8721 17.9L11.1343 22.2048L15 20.1725Z"
        fill="#FFA861"
      />
    </svg>
  );
};

export default Bronze;
