import React, { MouseEvent, useEffect, useMemo, useState } from 'react';
import { stringifyEntityRef } from '@backstage/catalog-model';
import classNames from 'classnames';
import {
  DependencyGraph,
  DependencyGraphTypes,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import {
  ALL_RELATION_PAIRS,
  RelationPairs,
  Direction,
  EntityNode,
} from '@backstage/plugin-catalog-graph';
import { Box, CircularProgress, makeStyles, useTheme } from '@material-ui/core';
import { CustomLabel } from './Graph/CustomLabel';
import { CustomNode } from './Graph/CustomNode';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles(theme => ({
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    marginLeft: '-20px',
    marginTop: '-20px',
  },
  container: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  graph: {
    width: '100%',
    // height: 'calc(100% - 400px)',
    // flex: 1,
    // Right now there is no good way to style edges between nodes, we have to
    // fallback to these hacks:
    '& path[marker-end]': {
      transition: 'filter 0.1s ease-in-out',
    },
    '& path[marker-end]:hover': {
      filter: `drop-shadow(2px 2px 4px ${theme.palette.primary.dark});`,
    },
    '& g[data-testid=label]': {
      transition: 'transform 0s',
    },
  },
  graphView: {
    background: '#fff',
    position: 'relative',
    padding: '10px',
  },
  directionToggleBtn: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    zIndex: 100,
  },
}));

/**
 * Core building block for custom entity relations diagrams.
 *
 * @public
 */

// {
//     rootEntityNames,
//     maxDepth = Number.POSITIVE_INFINITY,
//     unidirectional = true,
//     mergeRelations = true,
//     kinds,
//     relations,
//     direction = Direction.LEFT_RIGHT,
//     onNodeClick,
//     relationPairs = ALL_RELATION_PAIRS,
//     className,
//     zoom = 'enabled',
//   }: {
//     rootEntityNames: any;
//     maxDepth?: number;
//     unidirectional?: boolean;
//     mergeRelations?: boolean;
//     kinds?: string[];
//     relations?: string[];
//     direction?: Direction;
//     onNodeClick?: (value: EntityNode, event: MouseEvent<unknown>) => void;
//     relationPairs?: RelationPairs;
//     className?: string;
//     zoom?: 'enabled' | 'disabled' | 'enable-on-click';
//   }

export const RenderGraphView = ({ data }: any) => {
  const theme = useTheme();
  const classes = useStyles();

  const graphData: any = {
    nodes: [],
    edges: [],
  };

  const [graphDirection, setGraphDirection] = useState('vertical');
  const [direction, setDirection] = useState(Direction.TOP_BOTTOM);
  const [renderData, setRenderData] = useState(graphData);

  const renderTree = (nodesData: any) => {
    const node = {
      id: nodesData?.id,
      label: nodesData?.name,
    };
    const edge = {
      to: nodesData?.id,
      from: nodesData?.parentid,
    };
    graphData.nodes.push(node);
    graphData.edges.push(edge);

    if (Array.isArray(nodesData?.children)) {
      // nodesData.children = nodesData.nextnode;
      nodesData?.children.map((node: any) => renderTree(node));
    }
    setRenderData(graphData);
    return graphData;
  };

  useEffect(() => {
    renderTree(data);
  }, [data]);

  useEffect(() => {
    renderTree(data);
    if (graphDirection && graphDirection === 'vertical') {
      setDirection(Direction.TOP_BOTTOM);
    } else if (graphDirection && graphDirection === 'horizontal') {
      setDirection(Direction.LEFT_RIGHT);
    }
  }, [graphDirection]);

  return (
    <div className={classes.graphView}>
      <Box className={classes.directionToggleBtn}>
        <ToggleButtonGroup
          exclusive
          value={graphDirection}
          onChange={(event, newValue) => {
            event.stopPropagation();
            setGraphDirection(newValue);
          }}
          size="small"
        >
          <ToggleButton value="vertical">
            <ArrowDownwardIcon />
          </ToggleButton>
          <ToggleButton value="horizontal">
            <ArrowForwardIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {data && (
        <DependencyGraph
          nodes={renderData.nodes || []}
          edges={renderData.edges || []}
          renderNode={CustomNode}
          //  renderLabel={CustomLabel}
          direction={direction}
          className={classes.graph}
          paddingX={theme.spacing(4)}
          paddingY={theme.spacing(4)}
          labelPosition={DependencyGraphTypes.LabelPosition.RIGHT}
          labelOffset={theme.spacing(1)}
          zoom={'disabled'}
        />
      )}
    </div>
  );
};

export default RenderGraphView;
