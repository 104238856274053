/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @backstage/no-undeclared-imports */
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { compact, isEmpty } from 'lodash';
import {
  configApiRef,
  IconComponent,
  useApi,
} from '@backstage/core-plugin-api';
import {
  useEntityList,
  useStarredEntities,
  useEntityOwnership,
  UserListFilter,
  UserListFilterKind,
} from '@backstage/plugin-catalog-react';
import {
  Card,
  List,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  MenuItem,
  Theme,
  Typography,
} from '@material-ui/core';
import * as constant from '../utils/constant';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { reduceEntityFilters } from './filters';

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    margin: theme.spacing(1, 0, 1, 0),
  },
  title: {
    margin: theme.spacing(1, 0, 0, 1),
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 'bold',
  },
  listIcon: {
    minWidth: 30,
    color: 'inherit',
  },
  menuItem: {
    minHeight: theme.spacing(6),
    background: 'inherit !important',
  },
  themeColor: {
    margin: theme.spacing(1, 1, 2, 1),
    backgroundColor: theme.palette.background.paper,
    '& li': {
      '&:hover': {
        color: theme.palette.text.primary,
        // borderLeftColor: theme.palette.primary.main,
        backgroundColor: `${theme.palette.background.tertiary} !important`,
      },
      '& .Mui-selected': {
        borderLeft: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: `${theme.palette.background.tertiary} !important`,
      },

      '& .Mui-selected, & .Mui-selected ~ div': {
        color: theme.palette.text.primary,
      },
    },
  },
}));

export type ButtonGroup = {
  name: string;
  items: {
    id: 'owned' | 'starred' | 'all';
    label: string;
    icon?: IconComponent;
  }[];
};

function getFilterGroups(orgName: string | undefined): ButtonGroup[] {
  return [
    {
      name: 'Personal',
      items: [
        {
          id: 'owned',
          label: 'Owned',
          icon: AccountCircleIcon,
        },
        {
          id: 'starred',
          label: 'Bookmark',
          icon: BookmarkIcon,
        },
      ],
    },
    {
      name: orgName ?? 'Company',
      items: [
        {
          id: 'all',
          label: 'All',
        },
      ],
    },
  ];
}
type AddtionalFilter = {
  assetStore: string;
};

type UserListPickerProps = {
  initialFilter?: UserListFilterKind;
  parentName?: string;
  addtionalFilter?: AddtionalFilter;
};

export const UserListPicker = ({
  initialFilter,
  parentName,
  addtionalFilter,
}: UserListPickerProps) => {
  const classes = useStyles();
  const configApi = useApi(configApiRef);
  const orgName = configApi.getOptionalString('organization.name') ?? 'Company';
  const filterGroups = getFilterGroups(orgName);
  const { isStarredEntity } = useStarredEntities();
  const { isOwnedEntity } = useEntityOwnership();
  const [selectedUserFilter, setSelectedUserFilter] = useState(initialFilter);

  // Static filters; used for generating counts of potentially unselected kinds
  const ownedFilter = useMemo(
    () => new UserListFilter('owned', isOwnedEntity, isStarredEntity),
    [isOwnedEntity, isStarredEntity],
  );
  const starredFilter = useMemo(
    () => new UserListFilter('starred', isOwnedEntity, isStarredEntity),
    [isOwnedEntity, isStarredEntity],
  );

  const { filters, updateFilters, backendEntities } = useEntityList();

  useEffect(() => {
    updateFilters({
      user: selectedUserFilter
        ? new UserListFilter(selectedUserFilter, isOwnedEntity, isStarredEntity)
        : undefined,
    });
  }, [selectedUserFilter, isOwnedEntity, isStarredEntity, updateFilters]);

  // To show proper counts for each section, apply all other frontend filters _except_ the user
  // filter that's controlled by this picker.
  const [entitiesWithoutUserFilter, setEntitiesWithoutUserFilter] =
    useState(backendEntities);
  useEffect(() => {
    const filterFn = reduceEntityFilters(
      compact(Object.values({ ...filters, user: undefined })),
    );
    const filterNotApproved = entity => {
      if (parentName === 'code') {
        return (
          !isEmpty(entity?.metadata?.devxstate) &&
          entity?.metadata?.devxstate?.toLowerCase() !==
            constant.NOT_APPROVED.toLowerCase() &&
          constant.COMPONENT_TYPE.includes(entity?.spec?.type?.toLowerCase())
        );
      } else if (parentName === 'techdocs') {
        return entity.spec.type !== 'extdocumentation';
      } else if (parentName === 'api') {
        const itemAssetStore = entity?.metadata?.assetstore || 'wso2';
        const assetStore = addtionalFilter?.assetStore;
        return assetStore === 'all'
          ? !isEmpty(entity?.metadata?.devxstate) &&
              entity?.metadata?.devxstate?.toLowerCase() !==
                constant.NOT_APPROVED?.toLowerCase()
          : !isEmpty(entity?.metadata?.devxstate) &&
              entity?.metadata?.devxstate?.toLowerCase() !==
                constant.NOT_APPROVED?.toLowerCase() &&
              itemAssetStore === assetStore;
      }
      return (
        !isEmpty(entity?.metadata?.devxstate) &&
        entity?.metadata?.devxstate?.toLowerCase() !==
          constant.NOT_APPROVED.toLowerCase()
      );
    };
    setEntitiesWithoutUserFilter(
      backendEntities.filter(filterFn).filter(filterNotApproved),
    );
  }, [filters, backendEntities, addtionalFilter]);

  function getFilterCount(id: UserListFilterKind) {
    switch (id) {
      case 'owned':
        return entitiesWithoutUserFilter.filter(entity =>
          ownedFilter.filterEntity(entity),
        ).length;
      case 'starred':
        return entitiesWithoutUserFilter.filter(entity =>
          starredFilter.filterEntity(entity),
        ).length;
      default:
        return entitiesWithoutUserFilter.length;
    }
  }

  return (
    <Card className={classes.root}>
      {filterGroups.map(group => (
        <Fragment key={group.name}>
          <Typography variant="subtitle2" className={classes.title}>
            {group.name}
          </Typography>
          <Card className={classes.themeColor}>
            <List disablePadding dense>
              {group.items.map(item => (
                <MenuItem
                  key={item.id}
                  color="primary"
                  button
                  divider
                  onClick={() => setSelectedUserFilter(item.id)}
                  selected={item.id === filters.user?.value}
                  className={classes.menuItem}
                >
                  {item.icon && (
                    <ListItemIcon className={classes.listIcon}>
                      <item.icon fontSize="small" />
                    </ListItemIcon>
                  )}
                  <ListItemText>
                    <Typography
                      variant="body1"
                      data-testid={`user-picker-${item.id}`}
                    >
                      {item.label}
                    </Typography>
                  </ListItemText>
                  <ListItemSecondaryAction>
                    {getFilterCount(item.id) ?? '-'}
                  </ListItemSecondaryAction>
                </MenuItem>
              ))}
            </List>
          </Card>
        </Fragment>
      ))}
    </Card>
  );
};
