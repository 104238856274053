import React, { useState } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useApi } from '@backstage/core-plugin-api';
import { promotionApiRef } from '../../apis/promotionApi';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function DeleteModal(props) {
  const classes = useStyles();
  const {
    id,
    deleteModalOpen,
    setDeleteModal,
    setPromotionData,
    promotionData,
    assestURL,
  } = props;
  const [deleteProgressMessage, setDeleteProgressMessage] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [getStatus, setGetStatus] = useState();
  const promotionApi = useApi(promotionApiRef);
  const notificationApi = useApi(notificationApiRef);

  const handleClose = () => {
    setDeleteModal(false);
    setInProgress(false);
    setTimeout(() => {
      setDeleteProgressMessage(false);
      setGetStatus('');
    }, 500);
  };

  const handleDelete = () => {
    setInProgress(true);
    setDeleteProgressMessage(true);

    promotionApi
      .deletePromotion(id, assestURL)
      .then(() => {
        setInProgress(false);
        setGetStatus(null);
        setPromotionData(
          promotionData?.filter(item => item?.promotionId !== id),
        );
      })
      .catch(error => {
        setInProgress(false);
        setGetStatus(error);
        notificationApi.sendNotification({
          message: `Error occurred - ${error?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={deleteModalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={deleteModalOpen}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">
              {!deleteProgressMessage &&
                'Do You Want To Delete the Promotion ?'}
              {deleteProgressMessage && inProgress && 'InProgress....'}
              {deleteProgressMessage &&
                !inProgress &&
                getStatus === null &&
                'Success!'}
              {deleteProgressMessage &&
                !inProgress &&
                getStatus !== null &&
                'Error!'}
            </h2>
            <p
              id="transition-modal-description"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              {!deleteProgressMessage && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleDelete}
                  style={{ margin: 5 }}
                >
                  Yes
                </Button>
              )}
              {!inProgress && getStatus !== null && (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleClose}
                  style={{ margin: 5 }}
                >
                  Cancel
                </Button>
              )}
              {!inProgress && getStatus === null && (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleClose}
                >
                  Close
                </Button>
              )}
            </p>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
